import { get, isEmpty } from "lodash/fp";
import { useAppSelector } from "store/hooks";
import { useAppDispatch } from "store/hooks";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { findTagByReferenceKey, getPeriods } from "../../utils";
import { actions, selectors } from "../../redux";
import PeriodStatusFilter from "./PeriodStatusFilter";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const currentFilter = useAppSelector(selectors.getSearchTagsById("absenceImpact"));
    const searchType = useAppSelector(selectors.getCurrentSearch);
    const filters = useMemo(() => getPeriods(searchType, currentFilter), [currentFilter, searchType]);
    const Header = useMemo(() => t("search.searchQuery.filterName.absenceStatus"), [t]);

    const Items = useMemo(() => filters.map((x) => ({ ...x, label: t(x.label) })), [filters, t]);

    const Tags = useMemo(() => {
        const getCheckedItems = filters.filter(get("checked"));
        if (isEmpty(getCheckedItems)) return "";
        return getCheckedItems.map(({ label }) => t(label)).join(", ");
    }, [filters, t]);

    const onChange = useCallback(
        (value: string) => {
            const element = findTagByReferenceKey(value, filters);
            if (!element) return;
            dispatch(actions.updateRadioSearchFilter(element));
        },
        [dispatch, filters],
    );
    return <PeriodStatusFilter items={Items} header={Header} onChange={onChange} tags={Tags} />;
}
