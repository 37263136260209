import Icon from "components/Icon";
import { isArray } from "lodash/fp";
import { SearchTypes } from "pages/FollowUp/utils";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ITable, ITableResult, SearchTableResult } from "../../types";
import DepartmentCell from "../DepartmentsCell";
import { ColumnTextRow, DegreeRow, EndStartDateCell, ProfileRow, PropertiesCell, RowWrapper } from "../SearchTableRows";
interface IElement extends ITableResult {
    firstElement: boolean;
    lastElement: boolean;
    searchType: SearchTypes;
}
const Element = ({
    type,
    header,
    body,
    texts,
    id,
    text,
    degrees,
    firstElement,
    endStartDate,
    lastElement,
    properties,
    employeeNumber,
    searchType,
}: IElement) => {
    let element: JSX.Element | null = null;
    switch (type) {
        case "ColumnText": {
            element = <ColumnTextRow header={header} body={body} />;
            break;
        }
        case "DepartmentsCell": {
            element = <DepartmentCell texts={texts} />;
            break;
        }
        case "DegreeCell": {
            element = <DegreeRow degrees={degrees} />;
            break;
        }
        case "EndStartDate": {
            element = <EndStartDateCell endStartDate={endStartDate} searchType={searchType} />;
            break;
        }
        case "PropertiesCell": {
            element = <PropertiesCell properties={properties} />;
            break;
        }
        case "ProfileRow": {
            element = <ProfileRow header={header} body={body} archived={text} employeeNumber={employeeNumber} />;
            break;
        }
        case "Button": {
            element = (
                <Link to={`/followup/employee/${id}/absence`}>
                    <div className="h-full pt-[7px]">
                        <Icon icon="caretRight" size={10} />
                    </div>
                </Link>
            );
            break;
        }
        default: {
            element = <ColumnTextRow header={header} body={body} />;
            break;
        }
    }
    return (
        <RowWrapper firstElement={firstElement} lastElement={lastElement}>
            {element}
        </RowWrapper>
    );
};
const useRows = (tableProps: ITable, type: SearchTypes) => {
    const navigate = useNavigate();
    const getElement = useCallback(
        (cells: SearchTableResult[]) => {
            let elements: {
                data: JSX.Element[];
                id: string;
            }[] = [];
            let index = 0;
            cells.forEach((value) => {
                const firstElement = index === 0;
                const lastElement = index === cells.length - 1;
                if (isArray(value.data)) {
                    elements = [
                        ...elements,
                        {
                            data: value.data.map((value, index) => (
                                <div key={`data-table-rows-${value.id}-${index}`}>
                                    <Element
                                        firstElement={firstElement}
                                        lastElement={lastElement}
                                        searchType={type}
                                        {...value}
                                    />
                                </div>
                            )),
                            id: value?.id || "",
                        },
                    ];
                } else {
                    if (value.data) {
                        elements = [
                            ...elements,
                            {
                                data: [
                                    <div key={`data-table-rows-${value.id}-${index}`}>
                                        <Element
                                            firstElement={firstElement}
                                            lastElement={lastElement}
                                            searchType={type}
                                            {...value.data}
                                        />
                                    </div>,
                                ],
                                id: value.id || "",
                            },
                        ];
                    }
                }
                index += 1;
            });
            return elements;
        },
        [type],
    );
    const onRowClick = useCallback(
        (id?: string | null) => {
            if (id) {
                const isCaseType = type === "cases" || type === "activities";
                navigate(`/followup/employee/${id}/${isCaseType ? "case" : "absence"}`);
            }
        },
        [navigate, type],
    );
    const rows = useMemo(
        () =>
            tableProps?.data.map((props) => ({
                cells: getElement(props.cells),
                rowProps: {
                    onClick: props.id ? () => onRowClick(props.id) : undefined,
                    theme: props.theme,
                },
            })),
        [getElement, onRowClick, tableProps?.data],
    );
    return rows;
};

export default useRows;
