import Icon from "components/Icon";
import { useElementClicked } from "hooks";
import { IAdminSearchCompProps } from "pages/Administration/types";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

const AdminSearchResult = ({
    searchResults,
    setSearchResultOpen,
    onSelectUserClick,
    isExistingUser,
}: IAdminSearchCompProps) => {
    const { t } = useTranslation("administration");
    const ref = useElementClicked(setSearchResultOpen);
    return (
        <div
            ref={ref}
            className={twMerge(
                "max-h-90 overflow-auto absolute shadow-md bg-white z-10 w-full xxs:w-[308px]",
                isExistingUser ? "p-2" : "",
            )}
        >
            {searchResults.map((searchResult) => (
                <div
                    key={`${searchResult.userAccountId}${searchResult.socialSecurityNumber}`}
                    className="flex flex-col cursor-pointer"
                    onClick={() => onSelectUserClick(searchResult.userAccountId ?? -1)}
                >
                    {isExistingUser && <p className="mb-2">{t("userExists")}</p>}
                    <div className={twMerge(isExistingUser ? "" : "border-b")}>
                        <div className={twMerge("flex items-center", isExistingUser ? "" : "p-4")}>
                            <div className="mr-2 h-10 w-10 bg-mhgrey-light rounded-full flex items-center justify-center">
                                <Icon icon="person" />
                            </div>
                            <div className="font-bold text-mhdarkgreen-original flex flex-col">
                                <div>{searchResult.name}</div>
                                <div className="font-normal">{searchResult.socialSecurityNumber}</div>
                                <p>{searchResult.primaryCompany?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AdminSearchResult;
