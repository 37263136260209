import { useEffect, useCallback } from "react";
import { isNumber, isUndefined, flow, isEmpty } from "lodash/fp";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FileStatus, useFileUploader } from "../../hooks";
import { postFile } from "../../redux/medicalCertificateAsyncActions";
import AddMedicalCertificateMetadata from "../AddMedicalCertificateMetadata";
import UploadFileMedicalCertificate from "../UploadFileMedicalCertificate";
import { ModalFrame } from "@medhelp/ui";
import { ReactComponent as CheckMarkIcon } from "../../../../icons/checkMarkBlack.svg";
import ModalCommonFooter from "@medhelp/ui/Modal/ModalCommonFooter";
import { useMemo, useState } from "react";
import { IDoubleDatepickerProps } from "@medhelp/ui/Datepicker/types";
import { toast } from "react-toastify";
import { useAppDispatch } from "store/hooks";

const degree = ["100%", "75%", "50%", "25%"];

interface IProps {
    close: () => void;
    openModal: boolean;
    setOpen: () => void;
    certificateDatePickerProps: IDoubleDatepickerProps;
}
const UploadDoctorCertificate = ({ close, openModal, setOpen, certificateDatePickerProps }: IProps) => {
    const [child, setChild] = useState<number | undefined>(undefined);
    const { routeEmploymentGuid } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { files, getInputProps, getRootProps, open, removeFile, clearFiles, status, checkMedicalCertificateLimit } =
        useFileUploader();

    useEffect(() => {
        checkMedicalCertificateLimit();
    }, [checkMedicalCertificateLimit]);

    useEffect(() => {
        if (status === FileStatus.LimitExceeded) {
            toast(t(status), { type: "error" });
        }
    }, [status, t]);

    const Degree = useMemo(() => degree.concat(t("absenceVaryOption")), [t]);
    const GetDegree = useMemo(() => (isNumber(child) ? Degree[child] : undefined), [child, Degree]);
    const startDate = useMemo(() => certificateDatePickerProps.range?.from, [certificateDatePickerProps.range?.from]);

    const endDate = useMemo(() => certificateDatePickerProps.range?.to, [certificateDatePickerProps.range?.to]);

    const saveFile = useCallback(() => {
        if (!startDate || !endDate || isUndefined(GetDegree)) return;
        dispatch(
            postFile({
                startDate: startDate,
                endDate: endDate,
                degree: GetDegree,
                files,
                employmentGuid: routeEmploymentGuid,
            }),
        );
    }, [dispatch, endDate, files, routeEmploymentGuid, startDate, GetDegree]);

    const setCurrentChild = useCallback((index: number) => {
        setChild(index);
    }, []);

    const content = useMemo(
        () => (
            <div className="flex flex-col gap-10">
                <AddMedicalCertificateMetadata
                    certificateDatePickerProps={certificateDatePickerProps}
                    setCurrentChild={setCurrentChild}
                    child={child}
                    heading={isUndefined(GetDegree) ? "" : GetDegree}
                    values={Degree}
                />
                <UploadFileMedicalCertificate
                    getInputProps={getInputProps}
                    getRootProps={getRootProps}
                    open={open}
                    files={files}
                    removeFile={(v) => {
                        removeFile(v);
                        checkMedicalCertificateLimit();
                    }}
                />
            </div>
        ),
        [
            Degree,
            GetDegree,
            certificateDatePickerProps,
            checkMedicalCertificateLimit,
            child,
            files,
            getInputProps,
            getRootProps,
            open,
            removeFile,
            setCurrentChild,
        ],
    );
    return (
        <ModalFrame
            id="upload-doctor-certificate-modal"
            header={<h1>{t("uploadMedicalCertificate")}</h1>}
            content={content}
            open={openModal}
            setOpen={setOpen}
            footer={
                <ModalCommonFooter
                    tertiaryText={t("abort")}
                    tertiaryOnClick={flow(clearFiles, close)}
                    primaryText={t("save")}
                    primaryOnClick={saveFile}
                    primaryDisabled={
                        isEmpty(files) ||
                        Boolean(!startDate) ||
                        Boolean(!endDate) ||
                        Boolean(!isNumber(child)) ||
                        status === FileStatus.LimitExceeded
                    }
                    primaryRightIcon={<CheckMarkIcon stroke="currentcolor" />}
                />
            }
        />
    );
};

export default UploadDoctorCertificate;
