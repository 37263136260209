import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { getCaseNotes } from "../../../../redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { CaseNoteItem } from "pages/FollowUp/components/HealthCaseManagement";
import { Status } from "@medhelp/ui/LoadableComponent";
import { Error } from "@medhelp/ui";
import CaseNotesLoading from "./CaseNotesLoading";

interface IProps {
    healthCase: IHealthCase;
}

const CaseNotesContent = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const caseNotes = useAppSelector(getCaseNotes(healthCase.id));

    if (healthCase.healthCaseSubStatus.documents === Status.PENDING) {
        return <CaseNotesLoading />;
    }

    if (healthCase.healthCaseSubStatus.documents === Status.ERROR) {
        return <Error text={t("couldNotFetchContent")} />;
    }

    return (
        <>
            {caseNotes.map((note) => (
                <CaseNoteItem key={`case-note-item-${note.id}`} healthCase={healthCase} note={note} />
            ))}
        </>
    );
};

export default CaseNotesContent;
