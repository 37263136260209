import { orderBy } from "lodash";
import { MedHelpHCMWebApiResponseTemplateFormDocumentResponse } from "swagger/healthCaseManagement";

export const sortTemplateFormDocuments = (templates: MedHelpHCMWebApiResponseTemplateFormDocumentResponse[]) =>
    orderBy(templates, ["ownerReferenceType", "title"]);

type OrganizationNumberConfig = {
    [key: string]: {
        placeholder: string;
        rules: {
            minLength: number;
            maxLength: number;
            pattern: { value: RegExp; message: string };
        };
    };
};

export const organizationNumberConfigByCountry: OrganizationNumberConfig = {
    Sweden: {
        placeholder: "123456-1234",
        rules: {
            minLength: 11,
            maxLength: 11,
            pattern: { value: /^[0-9]{6}-[0-9]{4}$/, message: "" },
        },
    },
    Denmark: {
        placeholder: "12345678",
        rules: {
            minLength: 8,
            maxLength: 8,
            pattern: { value: /^[0-9]{8}$/, message: "" },
        },
    },
};
