import Icon from "components/Icon";
import { twMerge } from "tailwind-merge";

export interface IRadioButtonProps {
    text?: string;
    checked: boolean;
    setChecked: () => void;
    disabled?: boolean;
    size?: number;
    className?: string;
}
const RadioButton = ({ text, checked, setChecked, disabled, size, className }: IRadioButtonProps) => {
    return (
        <div className={twMerge("flex items-center justify-between", className)}>
            {text && <p className={`mr-4 ${disabled ? "opacity-50" : ""}`}>{text}</p>}
            <button
                type="button"
                className={`h-6 w-6 flex items-center justify-center ${
                    disabled ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                onClick={() => {
                    if (!checked && !disabled) setChecked();
                }}
            >
                <Icon
                    icon={checked ? "radioActive" : "radio"}
                    size={size ?? 15}
                    stroke={"#192D05"}
                    fill={"#192D05"}
                    className={disabled ? "opacity-50" : ""}
                />
            </button>
        </div>
    );
};

export default RadioButton;
