import { compact, isEmpty } from "lodash/fp";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSingleDatepickerProps } from "hooks";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getDateDisplayValue, getEndOfDateString, newDateString } from "utils/date";
import { actions, selectors } from "../../redux";
import PeriodFilter from "./PeriodFilter";
import { IDate } from "../DateFilter";

interface DateSelection {
    startDate: string | undefined;
    endDate: string | undefined;
}

export default function Index() {
    const { t } = useTranslation(["followup", "translation"]);
    const dipsatch = useAppDispatch();
    const periodFrom = useAppSelector(selectors.getSearchDateById("from"));
    const periodTo = useAppSelector(selectors.getSearchDateById("to"));

    const periodFilter = useMemo(
        () => ({
            from: periodFrom?.date || undefined,
            to: periodTo?.date || undefined,
        }),
        [periodFrom?.date, periodTo?.date],
    );

    const StartPeriod = useMemo(() => periodFilter?.from ?? undefined, [periodFilter?.from]);
    const EndPeriod = useMemo(() => {
        return periodFilter?.to ?? undefined;
    }, [periodFilter?.to]);

    const setStartPeriod = useCallback(
        (value: string | undefined) => {
            dipsatch(
                actions.updateSearchFilter({
                    id: "from",
                    date: value ?? null,
                    referenceKey: "from",
                }),
            );
        },
        [dipsatch],
    );
    const setEndPeriod = useCallback(
        (value: string | undefined) => {
            dipsatch(
                actions.updateSearchFilter({
                    id: "to",
                    date: getEndOfDateString("days", value) ?? newDateString(),
                    referenceKey: "to",
                }),
            );
        },
        [dipsatch],
    );
    const setDateRangePeriod = useCallback(
        (arg: DateSelection) => {
            setStartPeriod(arg.startDate);
            setEndPeriod(arg.endDate);
        },
        [setEndPeriod, setStartPeriod],
    );
    const Periods: IDate[] = [
        {
            label: t("periodStarts"),
            singleDatepickerProps: useSingleDatepickerProps({
                onChangeDate: setStartPeriod,
                givenSelectedDate: StartPeriod,
                placeholder: t("addDate", {
                    ns: "translation",
                }),
            }),
        },
        {
            minDate: StartPeriod,
            label: t("periodEnds"),
            singleDatepickerProps: useSingleDatepickerProps({
                onChangeDate: setEndPeriod,
                givenSelectedDate: EndPeriod,
                placeholder: t("addDate", {
                    ns: "translation",
                }),
            }),
        },
    ];

    const DateRanges = useMemo(
        () => ({
            startDate: StartPeriod,
            endDate: EndPeriod,
            onChange: setDateRangePeriod,
        }),
        [EndPeriod, StartPeriod, setDateRangePeriod],
    );
    const Header = useMemo(() => t("period"), [t]);

    const Body = useMemo(() => {
        const startPeriodText = StartPeriod ? getDateDisplayValue(StartPeriod) : undefined;
        const endPeriodText = EndPeriod ? getDateDisplayValue(EndPeriod) : undefined;
        const texts = compact([startPeriodText, endPeriodText]);
        return !isEmpty(texts) ? texts.join(" - ") : "";
    }, [EndPeriod, StartPeriod]);

    return <PeriodFilter periods={Periods} dateRanges={DateRanges} header={Header} body={Body} />;
}
