import { useTranslation } from "react-i18next";

const Subcontractors = () => {
    const { t } = useTranslation("about");

    const listOfSubcontractors = [
        {
            name: "MedHelp Sjukvårdsrådgivning AB",
            org: "556587-1448",
            location: "sweden",
        },
        {
            name: "Generic Mobile Systems Sweden AB",
            org: "556462-7213",
            location: "sweden",
        },
        {
            name: "Amazon Web Service Sweden AB",
            org: "556833-3503",
            location: "ireland",
        },
        {
            name: "Voice Provider Sweden AB",
            org: "556598-3276 ",
            location: "sweden",
        },
        {
            name: "Svensk E-identitet AB",
            org: "556776-6992",
            location: "sweden",
        },
        {
            name: "Hubspot Ireland Ltd",
            org: "9849471F",
            location: "EU",
        },
    ];

    return (
        <>
            <h1 className="w-full">{t("subcontractors")}</h1>
            <div className="w-full flex  justify-center border-b pb-24">
                <div className="w-full mt-10 shadow-lg bg-primaryBg p-6">
                    <div className="hidden md:flex justify-between font-bold pb-3 border-b break-words">
                        <div className="w-5/12 px-2">{t("subcontractor")}</div>
                        <div className="w-4/12 px-2">{t("organizationNumber")}</div>
                        <div className="w-3/12 px-2">{t("dataProcessingLocation")}</div>
                    </div>
                    {listOfSubcontractors.map((item) => (
                        <div key={item.name} className="md:flex justify-between py-3 items-center border-b">
                            <div className="md:w-5/12 px-2">
                                <p>{item.name}</p>
                            </div>
                            <div className="md:w-4/12 px-2">
                                <p>{item.org}</p>
                            </div>
                            <div className="md:w-3/12 px-2">
                                <p>{t(item.location)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <p className="mt-6">{t("lastUpdated")}: 2022-03-09</p>
        </>
    );
};

export default Subcontractors;
