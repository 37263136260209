import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface ITabsObject {
    tabName: string;
    tabContent: React.ReactNode;
}

interface ITabsProps {
    className?: string;
    tabs: ITabsObject[];
    activeTab: string;
    setActiveTab: (activeTab: string) => void;
    children: ReactNode;
}

const Tabs: React.FunctionComponent<ITabsProps> = (props) => {
    const { className, tabs, activeTab, setActiveTab, children } = props;
    const { t } = useTranslation("login");

    return (
        <div className={`flex flex-col h-full`}>
            <ul className="mx-10 border-b border-grey-300 flex">
                {tabs?.map((x) => (
                    <li
                        key={x.tabName}
                        className={`w-1/2 flex justify-center ${
                            activeTab === x.tabName && "border-b-2 border-black text-shadow"
                        }`}
                    >
                        <button
                            type="button"
                            className="w-full py-2 shadow-none-important"
                            onClick={() => {
                                setActiveTab(x.tabName);
                            }}
                        >
                            <p className={`text-sm text-bold`}>{t(x.tabName)}</p>
                        </button>
                    </li>
                ))}
            </ul>
            <div className={`w-full h-full mt-12 ${className}`}>
                {tabs.find((c) => c.tabName === activeTab)?.tabContent}
                {children}
            </div>
        </div>
    );
};

export default Tabs;
