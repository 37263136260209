import * as React from "react";
import { setOverflow } from "../utils/setOverflow";

/**
 * Detect if user click outside the given HTMLElement
 */
const useDetectOutsideClick = (initialState = false, setScroll = true) => {
    const [isOutside, setIsOutside] = React.useState(initialState);
    const [ref, setRef] = React.useState<HTMLElement | null>();
    const mountedRef = React.useRef(true);

    const setContainer = React.useCallback((node: HTMLElement | null) => {
        if (node) {
            setRef(node);
        }
    }, []);

    React.useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    React.useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e?.key && e.key === "Escape" && mountedRef.current) {
                setIsOutside(!isOutside);
            }
        };

        const onClick = (e: MouseEvent) => {
            if (!ref?.contains(e.target as Node) && mountedRef.current) {
                setIsOutside(!isOutside);
            }
        };
        if (isOutside) {
            window.addEventListener("click", onClick, true);
            window.addEventListener("keydown", onKeyDown, true);
            if (setScroll) setOverflow("hidden");
        }
        return () => {
            window.removeEventListener("click", onClick, false);
            window.removeEventListener("keydown", onKeyDown, false);
            if (setScroll) setOverflow("auto");
        };
    }, [isOutside, ref, setScroll]);

    return { isOutside, setIsOutside, setContainer };
};
export default useDetectOutsideClick;
