import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchOrganizaionTreeView as fetchOrganizaionTreeViewAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchOrganizaionTreeView = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchOrganizaionTreeViewAction.fulfilled, (state, action) => {
            if (action.payload) state.organizationTreeRehabRights.data = action.payload;
            state.organizationTreeRehabRights.status = Status.OK;
        })
        .addCase(fetchOrganizaionTreeViewAction.pending, (state) => {
            state.organizationTreeRehabRights.status = Status.PENDING;
        })
        .addCase(fetchOrganizaionTreeViewAction.rejected, (state) => {
            state.organizationTreeRehabRights.status = Status.ERROR;
        });

export default fetchOrganizaionTreeView;
