import { DayPicker } from "react-day-picker";
import { getDate, getDateLocale, getDateOrUndefined, getStringFromDate } from "utils/date";
import CustomDay from "../CustomDay";
import MonthNavigation from "../MonthNavigation";

interface SinglePickerComponentProps {
    setSelectedDate: (selectedDate: string | undefined) => void;
    selectedDate: string | undefined;
    disableDatesBefore: string;
    disableDatesAfter: string;
    selectedLang: string | undefined;
    className: string;
    month: string;
    setMonth: (month: string) => void;
}

const SinglePickerComponent = ({
    setSelectedDate,
    selectedDate,
    disableDatesBefore,
    disableDatesAfter,
    selectedLang,
    className,
    month,
    setMonth,
}: SinglePickerComponentProps) => {
    const locale = getDateLocale(selectedLang);
    return (
        <DayPicker
            mode="single"
            locale={locale}
            selected={getDateOrUndefined(selectedDate)}
            onSelect={(v) => v && setSelectedDate(getStringFromDate(v))}
            disabled={{
                before: getDate(disableDatesBefore),
                after: getDate(disableDatesAfter),
            }}
            showWeekNumber
            captionLayout="dropdown-buttons"
            fromYear={getDate(disableDatesBefore).getFullYear()}
            toYear={getDate(disableDatesAfter).getFullYear()}
            month={getDate(month)}
            components={{
                Caption: () => (
                    <MonthNavigation
                        month={month}
                        setMonth={setMonth}
                        disableDatesBefore={disableDatesBefore}
                        disableDatesAfter={disableDatesAfter}
                    />
                ),
                Day: (props) => <CustomDay {...props} />,
            }}
            className={className}
        />
    );
};

export default SinglePickerComponent;
