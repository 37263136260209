/* tslint:disable */
/* eslint-disable */
/**
 * Business Support Facade API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbsenceFollowUpConfiguration
 */
export interface AbsenceFollowUpConfiguration {
    /**
     * 
     * @type {number}
     * @memberof AbsenceFollowUpConfiguration
     */
    'companyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceFollowUpConfiguration
     */
    'socialInsuranceReporting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceFollowUpConfiguration
     */
    'medicalCertificateMonitoring'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceFollowUpConfiguration
     */
    'mandatoryAdvisoryCallback'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AbsenceFollowUpConfiguration
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceFollowUpConfiguration
     */
    'isConfigured'?: boolean;
}
/**
 * 
 * @export
 * @interface AccessRightConfigurationPostModel
 */
export interface AccessRightConfigurationPostModel {
    /**
     * 
     * @type {number}
     * @memberof AccessRightConfigurationPostModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessRightConfigurationPostModel
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {AccessRightType}
     * @memberof AccessRightConfigurationPostModel
     */
    'accessRightType'?: AccessRightType;
    /**
     * 
     * @type {boolean}
     * @memberof AccessRightConfigurationPostModel
     */
    'hierarchical'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessRightConfigurationPostModel
     */
    'type'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AccessRightType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22
} as const;

export type AccessRightType = typeof AccessRightType[keyof typeof AccessRightType];


/**
 * 
 * @export
 * @interface AdvisoryService
 */
export interface AdvisoryService {
    /**
     * 
     * @type {string}
     * @memberof AdvisoryService
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdvisoryService
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdvisoryService
     */
    'nameIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdvisoryService
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface AdvisoryServiceResult
 */
export interface AdvisoryServiceResult {
    /**
     * 
     * @type {Array<AdvisoryService>}
     * @memberof AdvisoryServiceResult
     */
    'advisoryServices'?: Array<AdvisoryService> | null;
    /**
     * 
     * @type {Array<CustomerCompanyAdvisoryService>}
     * @memberof AdvisoryServiceResult
     */
    'customerCompanyAdvisoryServices'?: Array<CustomerCompanyAdvisoryService> | null;
}
/**
 * 
 * @export
 * @interface BillableErpProduct
 */
export interface BillableErpProduct {
    /**
     * 
     * @type {number}
     * @memberof BillableErpProduct
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillableErpProduct
     */
    'name'?: string | null;
    /**
     * 
     * @type {BillingPostType}
     * @memberof BillableErpProduct
     */
    'billingPostType'?: BillingPostType;
    /**
     * 
     * @type {number}
     * @memberof BillableErpProduct
     */
    'healthServiceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BillableErpProduct
     */
    'retailerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BillableErpProduct
     */
    'brandedServiceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillableErpProduct
     */
    'erpNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillableErpProduct
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BillableErpProduct
     */
    'standardPrice'?: number;
}


/**
 * 
 * @export
 * @interface BillingBasisModel
 */
export interface BillingBasisModel {
    /**
     * 
     * @type {number}
     * @memberof BillingBasisModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingBasisModel
     */
    'effectiveDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingBasisModel
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingBasisModel
     */
    'toDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingBasisModel
     */
    'creationDate'?: string;
    /**
     * 
     * @type {Array<BillingBasisPostModel>}
     * @memberof BillingBasisModel
     */
    'billingBasisPosts'?: Array<BillingBasisPostModel> | null;
}
/**
 * 
 * @export
 * @interface BillingBasisPostModel
 */
export interface BillingBasisPostModel {
    /**
     * 
     * @type {number}
     * @memberof BillingBasisPostModel
     */
    'departmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingBasisPostModel
     */
    'cost'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingBasisPostModel
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BillingBasisPostModel
     */
    'manuallyAdded'?: boolean;
}
/**
 * 
 * @export
 * @interface BillingConfiguration
 */
export interface BillingConfiguration {
    /**
     * 
     * @type {number}
     * @memberof BillingConfiguration
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingConfiguration
     */
    'retailerId'?: number;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof BillingConfiguration
     */
    'billingPeriodType'?: BillingPeriod;
    /**
     * 
     * @type {number}
     * @memberof BillingConfiguration
     */
    'billingDayInMonth'?: number;
    /**
     * 
     * @type {GroupType}
     * @memberof BillingConfiguration
     */
    'groupType'?: GroupType;
    /**
     * 
     * @type {boolean}
     * @memberof BillingConfiguration
     */
    'groupedOnTopLevelDepartments'?: boolean;
    /**
     * 
     * @type {ExportedContentLevel}
     * @memberof BillingConfiguration
     */
    'exportedContentLevel'?: ExportedContentLevel;
}


/**
 * 
 * @export
 * @interface BillingConfigurationModel
 */
export interface BillingConfigurationModel {
    /**
     * 
     * @type {number}
     * @memberof BillingConfigurationModel
     */
    'billingPeriodType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BillingConfigurationModel
     */
    'billingDayInMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingConfigurationModel
     */
    'groupType'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BillingConfigurationModel
     */
    'groupedOnTopLevelDepartments'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BillingConfigurationModel
     */
    'exportedContentLevel'?: number | null;
}
/**
 * 
 * @export
 * @interface BillingErpOption
 */
export interface BillingErpOption {
    /**
     * 
     * @type {string}
     * @memberof BillingErpOption
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingErpOption
     */
    'nonTranslatedDescription'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BillingPeriod = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type BillingPeriod = typeof BillingPeriod[keyof typeof BillingPeriod];


/**
 * 
 * @export
 * @interface BillingPostConfigurationModel
 */
export interface BillingPostConfigurationModel {
    /**
     * 
     * @type {number}
     * @memberof BillingPostConfigurationModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingPostConfigurationModel
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingPostConfigurationModel
     */
    'activationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof BillingPostConfigurationModel
     */
    'overridingPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BillingPostConfigurationModel
     */
    'billableErpProductId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingPostConfigurationModel
     */
    'billingPostType'?: number;
}
/**
 * 
 * @export
 * @interface BillingPostConfigurationPostModel
 */
export interface BillingPostConfigurationPostModel {
    /**
     * 
     * @type {number}
     * @memberof BillingPostConfigurationPostModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingPostConfigurationPostModel
     */
    'retailerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof BillingPostConfigurationPostModel
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingPostConfigurationPostModel
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BillingPostConfigurationPostModel
     */
    'billingPostType': string;
    /**
     * 
     * @type {string}
     * @memberof BillingPostConfigurationPostModel
     */
    'overridingPrice'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BillingPostConfigurationPostModel
     */
    'level'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BillingPostType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26
} as const;

export type BillingPostType = typeof BillingPostType[keyof typeof BillingPostType];


/**
 * 
 * @export
 * @interface BillingPosts
 */
export interface BillingPosts {
    /**
     * 
     * @type {number}
     * @memberof BillingPosts
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof BillingPosts
     */
    'retailerId'?: number;
    /**
     * 
     * @type {Array<BillingPostConfigurationModel>}
     * @memberof BillingPosts
     */
    'configurations'?: Array<BillingPostConfigurationModel> | null;
}
/**
 * 
 * @export
 * @interface ClassDecription
 */
export interface ClassDecription {
    /**
     * 
     * @type {string}
     * @memberof ClassDecription
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<PropertyDescription>}
     * @memberof ClassDecription
     */
    'propertyDescriptions'?: Array<PropertyDescription> | null;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'number'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'regionId'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyConfigurationModel
 */
export interface CompanyConfigurationModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyConfigurationModel
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyConfigurationModel
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyConfigurationModel
     */
    'country': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyConfigurationModel
     */
    'maskSsn'?: boolean;
}
/**
 * 
 * @export
 * @interface CompanyDeactivationContract
 */
export interface CompanyDeactivationContract {
    /**
     * 
     * @type {number}
     * @memberof CompanyDeactivationContract
     */
    'customerId'?: number;
    /**
     * 
     * @type {Array<CompanyDeactivations>}
     * @memberof CompanyDeactivationContract
     */
    'companyDeactivations'?: Array<CompanyDeactivations> | null;
}
/**
 * 
 * @export
 * @interface CompanyDeactivations
 */
export interface CompanyDeactivations {
    /**
     * 
     * @type {number}
     * @memberof CompanyDeactivations
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDeactivations
     */
    'deactivationDate'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyInfoModel
 */
export interface CompanyInfoModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyInfoModel
     */
    'industryCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInfoModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyInfoModel
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyInfoModel
     */
    'newReporting'?: boolean;
}
/**
 * 
 * @export
 * @interface CompanyMainDetailList
 */
export interface CompanyMainDetailList {
    /**
     * 
     * @type {number}
     * @memberof CompanyMainDetailList
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyMainDetailList
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyMainDetailList
     */
    'number'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyMainDetailList
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyMainDetailList
     */
    'customerName'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyModel
 */
export interface CompanyModel {
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'number'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    'retailerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'customerErpNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'sizeClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'industryCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyModel
     */
    'newReporting'?: boolean;
    /**
     * 
     * @type {Region}
     * @memberof CompanyModel
     */
    'region'?: Region;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'product'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'subcategory'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanySelectCustomer
 */
export interface CompanySelectCustomer {
    /**
     * 
     * @type {number}
     * @memberof CompanySelectCustomer
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySelectCustomer
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanySelectCustomer
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySelectCustomer
     */
    'selectedCompanyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanySelectCustomer
     */
    'retailerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanySelectCustomer
     */
    'partner'?: string | null;
    /**
     * 
     * @type {BillingConfigurationModel}
     * @memberof CompanySelectCustomer
     */
    'billingConfiguration'?: BillingConfigurationModel;
    /**
     * 
     * @type {Array<InvoicePost>}
     * @memberof CompanySelectCustomer
     */
    'invoicePosts'?: Array<InvoicePost> | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySelectCustomer
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySelectCustomer
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {Array<BillingErpOption>}
     * @memberof CompanySelectCustomer
     */
    'billingErpOptions'?: Array<BillingErpOption> | null;
}
/**
 * 
 * @export
 * @interface CompanySelectOrganization
 */
export interface CompanySelectOrganization {
    /**
     * 
     * @type {string}
     * @memberof CompanySelectOrganization
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySelectOrganization
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {Region}
     * @memberof CompanySelectOrganization
     */
    'region'?: Region;
    /**
     * 
     * @type {Array<EmploymentGroup>}
     * @memberof CompanySelectOrganization
     */
    'employmentGroups'?: Array<EmploymentGroup> | null;
}
/**
 * 
 * @export
 * @interface CompanySelectedEmpAbsence
 */
export interface CompanySelectedEmpAbsence {
    /**
     * 
     * @type {string}
     * @memberof CompanySelectedEmpAbsence
     */
    'industry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanySelectedEmpAbsence
     */
    'industryCode'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanySelectedServiceUtil
 */
export interface CompanySelectedServiceUtil {
    /**
     * 
     * @type {Array<ServiceContractModel>}
     * @memberof CompanySelectedServiceUtil
     */
    'serviceContracts'?: Array<ServiceContractModel> | null;
}
/**
 * 
 * @export
 * @interface CompanySelectedUser
 */
export interface CompanySelectedUser {
    /**
     * 
     * @type {LoginConfiguration}
     * @memberof CompanySelectedUser
     */
    'loginConfiguration'?: LoginConfiguration;
    /**
     * 
     * @type {Array<HierarchyAccessRight>}
     * @memberof CompanySelectedUser
     */
    'hierarchyAccessRights'?: Array<HierarchyAccessRight> | null;
    /**
     * 
     * @type {Array<OnBoardingManagerAccessRight>}
     * @memberof CompanySelectedUser
     */
    'managerAccessRights'?: Array<OnBoardingManagerAccessRight> | null;
}
/**
 * 
 * @export
 * @interface CustomerCompany
 */
export interface CustomerCompany {
    /**
     * 
     * @type {number}
     * @memberof CustomerCompany
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompany
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompany
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompany
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompany
     */
    'deactivationDate'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerCompanyAdvisoryService
 */
export interface CustomerCompanyAdvisoryService {
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyAdvisoryService
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyAdvisoryService
     */
    'customerId': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyAdvisoryService
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyAdvisoryService
     */
    'activationDate': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyAdvisoryService
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyAdvisoryService
     */
    'advisoryServiceId': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyAdvisoryService
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerCompanyContract
 */
export interface CustomerCompanyContract {
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyContract
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyContract
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyContract
     */
    'companyName'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerCompanyInfo
 */
export interface CustomerCompanyInfo {
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyInfo
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyInfo
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyInfo
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyInfo
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyInfo
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyInfo
     */
    'customerNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyInfo
     */
    'customerDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyInfo
     */
    'retailerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyInfo
     */
    'erp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyInfo
     */
    'regionId'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerCompanyOnboarding
 */
export interface CustomerCompanyOnboarding {
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyOnboarding
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyOnboarding
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyOnboarding
     */
    'customerNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyOnboarding
     */
    'retailerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyOnboarding
     */
    'retailerName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyOnboarding
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyOnboarding
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyOnboarding
     */
    'companyNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyOnboarding
     */
    'type'?: string | null;
    /**
     * 
     * @type {Region}
     * @memberof CustomerCompanyOnboarding
     */
    'region'?: Region;
}
/**
 * 
 * @export
 * @interface CustomerInfo
 */
export interface CustomerInfo {
    /**
     * 
     * @type {number}
     * @memberof CustomerInfo
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerInfo
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerInfo
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerInfo
     */
    'customerNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerInfo
     */
    'customerErpNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerModel
 */
export interface CustomerModel {
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'retailerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'customerNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'customerErpNumber'?: string | null;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof CustomerModel
     */
    'billingPeriodKey'?: BillingPeriod;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'billingDayInMonth'?: number;
    /**
     * 
     * @type {Array<CustomerCompanyContract>}
     * @memberof CustomerModel
     */
    'customerCompanyKeys'?: Array<CustomerCompanyContract> | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerModel
     */
    'customerGroupType'?: number;
    /**
     * 
     * @type {Array<BillingBasisModel>}
     * @memberof CustomerModel
     */
    'billingBasises'?: Array<BillingBasisModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerModel
     */
    'useCostCenter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerModel
     */
    'groupedOnTopLevelDepartments'?: boolean;
    /**
     * 
     * @type {ExportedContentLevel}
     * @memberof CustomerModel
     */
    'exportedContentLevel'?: ExportedContentLevel;
}


/**
 * 
 * @export
 * @interface CustomerOnboarding
 */
export interface CustomerOnboarding {
    /**
     * 
     * @type {number}
     * @memberof CustomerOnboarding
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOnboarding
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOnboarding
     */
    'customerNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerOnboarding
     */
    'retailerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerOnboarding
     */
    'product'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOnboarding
     */
    'subcategory'?: string | null;
    /**
     * 
     * @type {BillingConfigurationModel}
     * @memberof CustomerOnboarding
     */
    'billingConfiguration'?: BillingConfigurationModel;
    /**
     * 
     * @type {Array<InvoicePost>}
     * @memberof CustomerOnboarding
     */
    'invoicePosts'?: Array<InvoicePost> | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerOnboarding
     */
    'retailerName'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerProductModel
 */
export interface CustomerProductModel {
    /**
     * 
     * @type {number}
     * @memberof CustomerProductModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerProductModel
     */
    'product'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerProductModel
     */
    'subcategory'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomersModel
 */
export interface CustomersModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof CustomersModel
     */
    'customerIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DeleteEmploymentGroupsModel
 */
export interface DeleteEmploymentGroupsModel {
    /**
     * 
     * @type {number}
     * @memberof DeleteEmploymentGroupsModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteEmploymentGroupsModel
     */
    'employmentGroupIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface EmploymentGroup
 */
export interface EmploymentGroup {
    /**
     * 
     * @type {number}
     * @memberof EmploymentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentGroup
     */
    'nameIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentGroup
     */
    'nameString'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmploymentGroup
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {Company}
     * @memberof EmploymentGroup
     */
    'company'?: Company;
    /**
     * 
     * @type {Region}
     * @memberof EmploymentGroup
     */
    'region'?: Region;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentGroup
     */
    'isReadOnly'?: boolean;
}
/**
 * 
 * @export
 * @interface EmploymentGroupModel
 */
export interface EmploymentGroupModel {
    /**
     * 
     * @type {string}
     * @memberof EmploymentGroupModel
     */
    'nameString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentGroupModel
     */
    'regionId'?: string | null;
}
/**
 * 
 * @export
 * @interface EmploymentGroupsRequest
 */
export interface EmploymentGroupsRequest {
    /**
     * 
     * @type {number}
     * @memberof EmploymentGroupsRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<EmploymentGroup>}
     * @memberof EmploymentGroupsRequest
     */
    'employmentGroups'?: Array<EmploymentGroup> | null;
}
/**
 * 
 * @export
 * @interface EmploymentInfo
 */
export interface EmploymentInfo {
    /**
     * 
     * @type {string}
     * @memberof EmploymentInfo
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentInfo
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentInfo
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentInfo
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentInfo
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentInfo
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface EmploymentInfosModel
 */
export interface EmploymentInfosModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof EmploymentInfosModel
     */
    'referenceKeys'?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExportedContentLevel = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type ExportedContentLevel = typeof ExportedContentLevel[keyof typeof ExportedContentLevel];


/**
 * 
 * @export
 * @interface FollowUpAdvisoryConfiguration
 */
export interface FollowUpAdvisoryConfiguration {
    /**
     * 
     * @type {string}
     * @memberof FollowUpAdvisoryConfiguration
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FollowUpAdvisoryConfiguration
     */
    'followUpAdvisorySickDay'?: number;
    /**
     * 
     * @type {string}
     * @memberof FollowUpAdvisoryConfiguration
     */
    'absenceReportType'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GroupType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type GroupType = typeof GroupType[keyof typeof GroupType];


/**
 * 
 * @export
 * @enum {string}
 */

export const HealthServiceModel = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type HealthServiceModel = typeof HealthServiceModel[keyof typeof HealthServiceModel];


/**
 * 
 * @export
 * @interface HierarchyAccessRight
 */
export interface HierarchyAccessRight {
    /**
     * 
     * @type {number}
     * @memberof HierarchyAccessRight
     */
    'customerConfigurationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof HierarchyAccessRight
     */
    'accessRightType'?: string | null;
}
/**
 * 
 * @export
 * @interface IdNameMap
 */
export interface IdNameMap {
    /**
     * 
     * @type {number}
     * @memberof IdNameMap
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdNameMap
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface InboundCustomerBillingConfiguration
 */
export interface InboundCustomerBillingConfiguration {
    /**
     * 
     * @type {number}
     * @memberof InboundCustomerBillingConfiguration
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboundCustomerBillingConfiguration
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboundCustomerBillingConfiguration
     */
    'retailerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InboundCustomerBillingConfiguration
     */
    'billableErpProductId'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboundCustomerBillingConfiguration
     */
    'billingDayInMonth'?: number | null;
    /**
     * 
     * @type {BillingPeriod}
     * @memberof InboundCustomerBillingConfiguration
     */
    'billingPeriodType'?: BillingPeriod;
    /**
     * 
     * @type {GroupType}
     * @memberof InboundCustomerBillingConfiguration
     */
    'groupType'?: GroupType;
    /**
     * 
     * @type {boolean}
     * @memberof InboundCustomerBillingConfiguration
     */
    'groupedOnTopLevelDepartments'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InboundCustomerBillingConfiguration
     */
    'overridingPrice'?: string | null;
    /**
     * 
     * @type {BillingPostType}
     * @memberof InboundCustomerBillingConfiguration
     */
    'billingPostType'?: BillingPostType;
    /**
     * 
     * @type {string}
     * @memberof InboundCustomerBillingConfiguration
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InboundCustomerBillingConfiguration
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {ExportedContentLevel}
     * @memberof InboundCustomerBillingConfiguration
     */
    'exportedContentLevel'?: ExportedContentLevel;
}


/**
 * 
 * @export
 * @interface InvoicePost
 */
export interface InvoicePost {
    /**
     * 
     * @type {number}
     * @memberof InvoicePost
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicePost
     */
    'erpNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicePost
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoicePost
     */
    'standardPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicePost
     */
    'customerPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicePost
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicePost
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoicePost
     */
    'billingPostType'?: number;
}
/**
 * 
 * @export
 * @interface LoginConfiguration
 */
export interface LoginConfiguration {
    /**
     * 
     * @type {boolean}
     * @memberof LoginConfiguration
     */
    'usernameFromEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginConfiguration
     */
    'usernameFromSsn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginConfiguration
     */
    'office365'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LoginConfiguration
     */
    'companyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoginConfiguration
     */
    'isConfigured'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationConfigurationModel
 */
export interface NotificationConfigurationModel {
    /**
     * 
     * @type {string}
     * @memberof NotificationConfigurationModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationConfigurationModel
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationConfigurationModel
     */
    'customerId': number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationConfigurationModel
     */
    'handleMedicalReminder'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationConfigurationModel
     */
    'handleEstimatedBackAtWork'?: boolean;
}
/**
 * 
 * @export
 * @interface ObjectResult
 */
export interface ObjectResult {
    /**
     * 
     * @type {any}
     * @memberof ObjectResult
     */
    'value'?: any | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof ObjectResult
     */
    'formatters'?: Array<object> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ObjectResult
     */
    'contentTypes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ObjectResult
     */
    'declaredType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ObjectResult
     */
    'statusCode'?: number | null;
}
/**
 * 
 * @export
 * @interface OnBoardingManagerAccessRight
 */
export interface OnBoardingManagerAccessRight {
    /**
     * 
     * @type {string}
     * @memberof OnBoardingManagerAccessRight
     */
    'managerType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OnBoardingManagerAccessRight
     */
    'customerConfigurationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OnBoardingManagerAccessRight
     */
    'accessRightType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OnBoardingManagerAccessRight
     */
    'hierarchical'?: boolean;
}
/**
 * 
 * @export
 * @interface PerformedByInfo
 */
export interface PerformedByInfo {
    /**
     * 
     * @type {number}
     * @memberof PerformedByInfo
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PerformedByInfo
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PerformedByInfo
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PerformedByInfo
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface PerformedServiceDetail
 */
export interface PerformedServiceDetail {
    /**
     * 
     * @type {string}
     * @memberof PerformedServiceDetail
     */
    'user'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PerformedServiceDetail
     */
    'registrationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PerformedServiceDetail
     */
    'referencePerformedDate'?: string;
}
/**
 * 
 * @export
 * @interface PerformedServiceModel
 */
export interface PerformedServiceModel {
    /**
     * 
     * @type {string}
     * @memberof PerformedServiceModel
     */
    'service'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PerformedServiceModel
     */
    'usage'?: number;
    /**
     * 
     * @type {Array<PerformedServiceDetail>}
     * @memberof PerformedServiceModel
     */
    'details'?: Array<PerformedServiceDetail> | null;
}
/**
 * 
 * @export
 * @interface PropertyDescription
 */
export interface PropertyDescription {
    /**
     * 
     * @type {string}
     * @memberof PropertyDescription
     */
    'name'?: string | null;
    /**
     * 
     * @type {PropertyType}
     * @memberof PropertyDescription
     */
    'type'?: PropertyType;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyDescription
     */
    'values'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDescription
     */
    'isReadOnly'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PropertyType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type PropertyType = typeof PropertyType[keyof typeof PropertyType];


/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'language'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'currency'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Region
     */
    'isDefault'?: boolean;
}
/**
 * 
 * @export
 * @interface RehabBaseActivity
 */
export interface RehabBaseActivity {
    /**
     * 
     * @type {number}
     * @memberof RehabBaseActivity
     */
    'carryOutDay'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RehabBaseActivity
     */
    'partOfRehabChain'?: boolean;
}
/**
 * 
 * @export
 * @interface RehabConfiguration
 */
export interface RehabConfiguration {
    /**
     * 
     * @type {number}
     * @memberof RehabConfiguration
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<RehabBaseActivity>}
     * @memberof RehabConfiguration
     */
    'rehabBaseActivities'?: Array<RehabBaseActivity> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RehabConfiguration
     */
    'isConfigured'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RehabFormDocumentTemplateReferenceType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type RehabFormDocumentTemplateReferenceType = typeof RehabFormDocumentTemplateReferenceType[keyof typeof RehabFormDocumentTemplateReferenceType];


/**
 * 
 * @export
 * @interface RehabTemplate
 */
export interface RehabTemplate {
    /**
     * 
     * @type {number}
     * @memberof RehabTemplate
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabTemplate
     */
    'title'?: string | null;
    /**
     * 
     * @type {RehabFormDocumentTemplateReferenceType}
     * @memberof RehabTemplate
     */
    'ownerReferenceType'?: RehabFormDocumentTemplateReferenceType;
    /**
     * 
     * @type {string}
     * @memberof RehabTemplate
     */
    'pdfDocumentReferenceKey'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabTemplate
     */
    'companyId'?: number | null;
}


/**
 * 
 * @export
 * @interface RehabTemplatesConfiguration
 */
export interface RehabTemplatesConfiguration {
    /**
     * 
     * @type {number}
     * @memberof RehabTemplatesConfiguration
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<RehabTemplate>}
     * @memberof RehabTemplatesConfiguration
     */
    'rehabTemplates'?: Array<RehabTemplate> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RehabTemplatesConfiguration
     */
    'isConfigured'?: boolean;
}
/**
 * 
 * @export
 * @interface RetailerIdsPostModel
 */
export interface RetailerIdsPostModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof RetailerIdsPostModel
     */
    'retailIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface SaveEmploymentGroupsModel
 */
export interface SaveEmploymentGroupsModel {
    /**
     * 
     * @type {number}
     * @memberof SaveEmploymentGroupsModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<EmploymentGroupModel>}
     * @memberof SaveEmploymentGroupsModel
     */
    'employmentGroups'?: Array<EmploymentGroupModel> | null;
}
/**
 * 
 * @export
 * @interface SaveFollowUpAdvisoryConfigurationModel
 */
export interface SaveFollowUpAdvisoryConfigurationModel {
    /**
     * 
     * @type {string}
     * @memberof SaveFollowUpAdvisoryConfigurationModel
     */
    'workOrderConfigurationId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SaveFollowUpAdvisoryConfigurationModel
     */
    'days'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface SaveWorkOrderConfigurationModel
 */
export interface SaveWorkOrderConfigurationModel {
    /**
     * 
     * @type {string}
     * @memberof SaveWorkOrderConfigurationModel
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SaveWorkOrderConfigurationModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SaveWorkOrderConfigurationModel
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveWorkOrderConfigurationModel
     */
    'allowDirectAdvisoryCallback'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveWorkOrderConfigurationModel
     */
    'mandatoryAdvisoryCallback'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveWorkOrderConfigurationModel
     */
    'localePreference'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchCompanyByNameOrOrgNrModel
 */
export interface SearchCompanyByNameOrOrgNrModel {
    /**
     * 
     * @type {string}
     * @memberof SearchCompanyByNameOrOrgNrModel
     */
    'searchString'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchCompanyByNameOrOrgNrModel
     */
    'retailerId'?: number;
}
/**
 * 
 * @export
 * @interface SearchCompanyModel
 */
export interface SearchCompanyModel {
    /**
     * 
     * @type {string}
     * @memberof SearchCompanyModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchCompanyModel
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchCompanyModel
     */
    'retailerId'?: number;
}
/**
 * 
 * @export
 * @interface ServiceContractModel
 */
export interface ServiceContractModel {
    /**
     * 
     * @type {number}
     * @memberof ServiceContractModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceContractModel
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceContractModel
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceContractModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ServiceContractModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceContractModel
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceContractModel
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceContractModel
     */
    'ivrPhoneNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ServiceContractModel
     */
    'ivrCustomerReferenceKey'?: number | null;
    /**
     * 
     * @type {HealthServiceModel}
     * @memberof ServiceContractModel
     */
    'healthService'?: HealthServiceModel;
}


/**
 * 
 * @export
 * @interface UpdateCompanyModel
 */
export interface UpdateCompanyModel {
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyModel
     */
    'number'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyModel
     */
    'newReporting'?: boolean;
    /**
     * 
     * @type {Region}
     * @memberof UpdateCompanyModel
     */
    'region'?: Region;
}
/**
 * 
 * @export
 * @interface WorkOrderConfiguration
 */
export interface WorkOrderConfiguration {
    /**
     * 
     * @type {string}
     * @memberof WorkOrderConfiguration
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderConfiguration
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderConfiguration
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkOrderConfiguration
     */
    'allowDirectAdvisoryCallback'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkOrderConfiguration
     */
    'mandatoryAdvisoryCallback'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderConfiguration
     */
    'localePreference'?: string | null;
    /**
     * 
     * @type {Array<FollowUpAdvisoryConfiguration>}
     * @memberof WorkOrderConfiguration
     */
    'followUpAdvisoryConfigurations'?: Array<FollowUpAdvisoryConfiguration> | null;
}

/**
 * AccessRightConfigurationApi - axios parameter creator
 * @export
 */
export const AccessRightConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccessRightConfigurationPostModel} [accessRightConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccessRightConfigurationDeletePost: async (accessRightConfigurationPostModel?: AccessRightConfigurationPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AccessRightConfiguration/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessRightConfigurationPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccessRightConfigurationGet: async (customerId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AccessRightConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccessRightConfigurationPostModel} [accessRightConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccessRightConfigurationPost: async (accessRightConfigurationPostModel?: AccessRightConfigurationPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AccessRightConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessRightConfigurationPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccessRightConfigurationApi - functional programming interface
 * @export
 */
export const AccessRightConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccessRightConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AccessRightConfigurationPostModel} [accessRightConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccessRightConfigurationDeletePost(accessRightConfigurationPostModel?: AccessRightConfigurationPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccessRightConfigurationDeletePost(accessRightConfigurationPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccessRightConfigurationGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccessRightConfigurationGet(customerId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AccessRightConfigurationPostModel} [accessRightConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccessRightConfigurationPost(accessRightConfigurationPostModel?: AccessRightConfigurationPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccessRightConfigurationPost(accessRightConfigurationPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccessRightConfigurationApi - factory interface
 * @export
 */
export const AccessRightConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccessRightConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {AccessRightConfigurationPostModel} [accessRightConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccessRightConfigurationDeletePost(accessRightConfigurationPostModel?: AccessRightConfigurationPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccessRightConfigurationDeletePost(accessRightConfigurationPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccessRightConfigurationGet(customerId?: number, companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccessRightConfigurationGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccessRightConfigurationPostModel} [accessRightConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccessRightConfigurationPost(accessRightConfigurationPostModel?: AccessRightConfigurationPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccessRightConfigurationPost(accessRightConfigurationPostModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccessRightConfigurationApi - object-oriented interface
 * @export
 * @class AccessRightConfigurationApi
 * @extends {BaseAPI}
 */
export class AccessRightConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {AccessRightConfigurationPostModel} [accessRightConfigurationPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRightConfigurationApi
     */
    public apiAccessRightConfigurationDeletePost(accessRightConfigurationPostModel?: AccessRightConfigurationPostModel, options?: AxiosRequestConfig) {
        return AccessRightConfigurationApiFp(this.configuration).apiAccessRightConfigurationDeletePost(accessRightConfigurationPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRightConfigurationApi
     */
    public apiAccessRightConfigurationGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return AccessRightConfigurationApiFp(this.configuration).apiAccessRightConfigurationGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccessRightConfigurationPostModel} [accessRightConfigurationPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccessRightConfigurationApi
     */
    public apiAccessRightConfigurationPost(accessRightConfigurationPostModel?: AccessRightConfigurationPostModel, options?: AxiosRequestConfig) {
        return AccessRightConfigurationApiFp(this.configuration).apiAccessRightConfigurationPost(accessRightConfigurationPostModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BusinessSupportApi - axios parameter creator
 * @export
 */
export const BusinessSupportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EmploymentGroupsRequest} [employmentGroupsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportDeleteEmploymentGroupsPost: async (employmentGroupsRequest?: EmploymentGroupsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/DeleteEmploymentGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentGroupsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetAbsenceFollowUpConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetAbsenceFollowUpConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetAllHealthServicesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetAllHealthServices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RetailerIdsPostModel} [retailerIdsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetBillableErpProductsByRetailIdsPost: async (retailerIdsPostModel?: RetailerIdsPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetBillableErpProductsByRetailIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retailerIdsPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetCompanyInfoGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetCompanyInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetCompanyMainDetailListGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetCompanyMainDetailList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetCompanyServiceContractsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetCompanyServiceContracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetEmploymentGroupsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetEmploymentGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetGroupDescriptionGet: async (companyId?: number, groupName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetGroupDescription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (groupName !== undefined) {
                localVarQueryParameter['groupName'] = groupName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetLoginConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetLoginConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetRegionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetRegions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetRehabConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetRehabConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetRehabTemplatesConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetRehabTemplatesConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetWorkOrderConfigurationGet: async (customerId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/GetWorkOrderConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [rangeStart] 
         * @param {string} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportPerformedServicesForCompanyGet: async (companyId?: number, rangeStart?: string, rangeEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/PerformedServicesForCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (rangeStart !== undefined) {
                localVarQueryParameter['rangeStart'] = (rangeStart as any instanceof Date) ?
                    (rangeStart as any).toISOString() :
                    rangeStart;
            }

            if (rangeEnd !== undefined) {
                localVarQueryParameter['rangeEnd'] = (rangeEnd as any instanceof Date) ?
                    (rangeEnd as any).toISOString() :
                    rangeEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceFollowUpConfiguration} [absenceFollowUpConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveAbsenceFollowUpConfigurationPost: async (absenceFollowUpConfiguration?: AbsenceFollowUpConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveAbsenceFollowUpConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceFollowUpConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BillableErpProduct} [billableErpProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveBillableErpProductPost: async (billableErpProduct?: BillableErpProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveBillableErpProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billableErpProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyInfoModel} [companyInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveCompanyInfoPost: async (companyInfoModel?: CompanyInfoModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveCompanyInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyInfoModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveCompanyPost: async (companyModel?: CompanyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerCompanyInfo} [customerCompanyInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveCustomerCompanyPost: async (customerCompanyInfo?: CustomerCompanyInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveCustomerCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerCompanyInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmploymentGroupsRequest} [employmentGroupsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveEmploymentGroupsPost: async (employmentGroupsRequest?: EmploymentGroupsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveEmploymentGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentGroupsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveFollowUpAdvisoryConfigurationModel} [saveFollowUpAdvisoryConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveFollowupConfigurationPut: async (saveFollowUpAdvisoryConfigurationModel?: SaveFollowUpAdvisoryConfigurationModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveFollowupConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveFollowUpAdvisoryConfigurationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginConfiguration} [loginConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveLoginConfigurationPost: async (loginConfiguration?: LoginConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveLoginConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RehabConfiguration} [rehabConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveRehabConfigurationPost: async (rehabConfiguration?: RehabConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveRehabConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rehabConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RehabTemplatesConfiguration} [rehabTemplatesConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveRehabTemplateConfigurationPost: async (rehabTemplatesConfiguration?: RehabTemplatesConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveRehabTemplateConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rehabTemplatesConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceContractModel} [serviceContractModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveServiceContractPost: async (serviceContractModel?: ServiceContractModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveServiceContract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceContractModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveWorkOrderConfigurationModel} [saveWorkOrderConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveWorkOrderConfigurationPut: async (saveWorkOrderConfigurationModel?: SaveWorkOrderConfigurationModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SaveWorkOrderConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveWorkOrderConfigurationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchCompanyByNameOrOrgNrModel} [searchCompanyByNameOrOrgNrModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSearchCompaniesByNameOrOrgNrPost: async (searchCompanyByNameOrOrgNrModel?: SearchCompanyByNameOrOrgNrModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SearchCompaniesByNameOrOrgNr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchCompanyByNameOrOrgNrModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchCompanyModel} [searchCompanyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSearchCompanyPost: async (searchCompanyModel?: SearchCompanyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/SearchCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchCompanyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportUpdateCompanyPost: async (companyModel?: CompanyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/UpdateCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [countryCode] 
         * @param {string} [language] 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportUpdateCompanyRegionPut: async (companyId?: number, countryCode?: string, language?: string, currency?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/UpdateCompanyRegion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportUpdateCustomerCompanyDetailsPost: async (companyModel?: CompanyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/BusinessSupport/UpdateCustomerCompanyDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BusinessSupportApi - functional programming interface
 * @export
 */
export const BusinessSupportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BusinessSupportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EmploymentGroupsRequest} [employmentGroupsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportDeleteEmploymentGroupsPost(employmentGroupsRequest?: EmploymentGroupsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportDeleteEmploymentGroupsPost(employmentGroupsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetAbsenceFollowUpConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceFollowUpConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetAbsenceFollowUpConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetAllHealthServicesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdNameMap>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetAllHealthServicesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RetailerIdsPostModel} [retailerIdsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetBillableErpProductsByRetailIdsPost(retailerIdsPostModel?: RetailerIdsPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillableErpProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetBillableErpProductsByRetailIdsPost(retailerIdsPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetCompanyInfoGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetCompanyInfoGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetCompanyMainDetailListGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyMainDetailList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetCompanyMainDetailListGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetCompanyServiceContractsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceContractModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetCompanyServiceContractsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetEmploymentGroupsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmploymentGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetEmploymentGroupsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetGroupDescriptionGet(companyId?: number, groupName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClassDecription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetGroupDescriptionGet(companyId, groupName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetLoginConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetLoginConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetRegionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Region>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetRegionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetRehabConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RehabConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetRehabConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetRehabTemplatesConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RehabTemplatesConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetRehabTemplatesConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportGetWorkOrderConfigurationGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportGetWorkOrderConfigurationGet(customerId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [rangeStart] 
         * @param {string} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportPerformedServicesForCompanyGet(companyId?: number, rangeStart?: string, rangeEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerformedServiceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportPerformedServicesForCompanyGet(companyId, rangeStart, rangeEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceFollowUpConfiguration} [absenceFollowUpConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveAbsenceFollowUpConfigurationPost(absenceFollowUpConfiguration?: AbsenceFollowUpConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveAbsenceFollowUpConfigurationPost(absenceFollowUpConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BillableErpProduct} [billableErpProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveBillableErpProductPost(billableErpProduct?: BillableErpProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveBillableErpProductPost(billableErpProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyInfoModel} [companyInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveCompanyInfoPost(companyInfoModel?: CompanyInfoModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveCompanyInfoPost(companyInfoModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveCompanyPost(companyModel?: CompanyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveCompanyPost(companyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerCompanyInfo} [customerCompanyInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveCustomerCompanyPost(customerCompanyInfo?: CustomerCompanyInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveCustomerCompanyPost(customerCompanyInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmploymentGroupsRequest} [employmentGroupsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveEmploymentGroupsPost(employmentGroupsRequest?: EmploymentGroupsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveEmploymentGroupsPost(employmentGroupsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveFollowUpAdvisoryConfigurationModel} [saveFollowUpAdvisoryConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveFollowupConfigurationPut(saveFollowUpAdvisoryConfigurationModel?: SaveFollowUpAdvisoryConfigurationModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveFollowupConfigurationPut(saveFollowUpAdvisoryConfigurationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginConfiguration} [loginConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveLoginConfigurationPost(loginConfiguration?: LoginConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveLoginConfigurationPost(loginConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RehabConfiguration} [rehabConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveRehabConfigurationPost(rehabConfiguration?: RehabConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveRehabConfigurationPost(rehabConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RehabTemplatesConfiguration} [rehabTemplatesConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveRehabTemplateConfigurationPost(rehabTemplatesConfiguration?: RehabTemplatesConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveRehabTemplateConfigurationPost(rehabTemplatesConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ServiceContractModel} [serviceContractModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveServiceContractPost(serviceContractModel?: ServiceContractModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveServiceContractPost(serviceContractModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveWorkOrderConfigurationModel} [saveWorkOrderConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSaveWorkOrderConfigurationPut(saveWorkOrderConfigurationModel?: SaveWorkOrderConfigurationModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSaveWorkOrderConfigurationPut(saveWorkOrderConfigurationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchCompanyByNameOrOrgNrModel} [searchCompanyByNameOrOrgNrModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSearchCompaniesByNameOrOrgNrPost(searchCompanyByNameOrOrgNrModel?: SearchCompanyByNameOrOrgNrModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSearchCompaniesByNameOrOrgNrPost(searchCompanyByNameOrOrgNrModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchCompanyModel} [searchCompanyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportSearchCompanyPost(searchCompanyModel?: SearchCompanyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportSearchCompanyPost(searchCompanyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportUpdateCompanyPost(companyModel?: CompanyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportUpdateCompanyPost(companyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [countryCode] 
         * @param {string} [language] 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportUpdateCompanyRegionPut(companyId?: number, countryCode?: string, language?: string, currency?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportUpdateCompanyRegionPut(companyId, countryCode, language, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBusinessSupportUpdateCustomerCompanyDetailsPost(companyModel?: CompanyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ObjectResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBusinessSupportUpdateCustomerCompanyDetailsPost(companyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BusinessSupportApi - factory interface
 * @export
 */
export const BusinessSupportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BusinessSupportApiFp(configuration)
    return {
        /**
         * 
         * @param {EmploymentGroupsRequest} [employmentGroupsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportDeleteEmploymentGroupsPost(employmentGroupsRequest?: EmploymentGroupsRequest, options?: any): AxiosPromise<ObjectResult> {
            return localVarFp.apiBusinessSupportDeleteEmploymentGroupsPost(employmentGroupsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetAbsenceFollowUpConfigurationGet(companyId?: number, options?: any): AxiosPromise<AbsenceFollowUpConfiguration> {
            return localVarFp.apiBusinessSupportGetAbsenceFollowUpConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetAllHealthServicesGet(options?: any): AxiosPromise<Array<IdNameMap>> {
            return localVarFp.apiBusinessSupportGetAllHealthServicesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RetailerIdsPostModel} [retailerIdsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetBillableErpProductsByRetailIdsPost(retailerIdsPostModel?: RetailerIdsPostModel, options?: any): AxiosPromise<Array<BillableErpProduct>> {
            return localVarFp.apiBusinessSupportGetBillableErpProductsByRetailIdsPost(retailerIdsPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetCompanyInfoGet(companyId?: number, options?: any): AxiosPromise<CompanyInfoModel> {
            return localVarFp.apiBusinessSupportGetCompanyInfoGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetCompanyMainDetailListGet(companyId?: number, options?: any): AxiosPromise<CompanyMainDetailList> {
            return localVarFp.apiBusinessSupportGetCompanyMainDetailListGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetCompanyServiceContractsGet(companyId?: number, options?: any): AxiosPromise<Array<ServiceContractModel>> {
            return localVarFp.apiBusinessSupportGetCompanyServiceContractsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetEmploymentGroupsGet(companyId?: number, options?: any): AxiosPromise<Array<EmploymentGroup>> {
            return localVarFp.apiBusinessSupportGetEmploymentGroupsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [groupName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetGroupDescriptionGet(companyId?: number, groupName?: string, options?: any): AxiosPromise<ClassDecription> {
            return localVarFp.apiBusinessSupportGetGroupDescriptionGet(companyId, groupName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetLoginConfigurationGet(companyId?: number, options?: any): AxiosPromise<LoginConfiguration> {
            return localVarFp.apiBusinessSupportGetLoginConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetRegionsGet(options?: any): AxiosPromise<Array<Region>> {
            return localVarFp.apiBusinessSupportGetRegionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetRehabConfigurationGet(companyId?: number, options?: any): AxiosPromise<RehabConfiguration> {
            return localVarFp.apiBusinessSupportGetRehabConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetRehabTemplatesConfigurationGet(companyId?: number, options?: any): AxiosPromise<RehabTemplatesConfiguration> {
            return localVarFp.apiBusinessSupportGetRehabTemplatesConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportGetWorkOrderConfigurationGet(customerId?: number, companyId?: number, options?: any): AxiosPromise<WorkOrderConfiguration> {
            return localVarFp.apiBusinessSupportGetWorkOrderConfigurationGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [rangeStart] 
         * @param {string} [rangeEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportPerformedServicesForCompanyGet(companyId?: number, rangeStart?: string, rangeEnd?: string, options?: any): AxiosPromise<Array<PerformedServiceModel>> {
            return localVarFp.apiBusinessSupportPerformedServicesForCompanyGet(companyId, rangeStart, rangeEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceFollowUpConfiguration} [absenceFollowUpConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveAbsenceFollowUpConfigurationPost(absenceFollowUpConfiguration?: AbsenceFollowUpConfiguration, options?: any): AxiosPromise<ObjectResult> {
            return localVarFp.apiBusinessSupportSaveAbsenceFollowUpConfigurationPost(absenceFollowUpConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BillableErpProduct} [billableErpProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveBillableErpProductPost(billableErpProduct?: BillableErpProduct, options?: any): AxiosPromise<void> {
            return localVarFp.apiBusinessSupportSaveBillableErpProductPost(billableErpProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyInfoModel} [companyInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveCompanyInfoPost(companyInfoModel?: CompanyInfoModel, options?: any): AxiosPromise<ObjectResult> {
            return localVarFp.apiBusinessSupportSaveCompanyInfoPost(companyInfoModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveCompanyPost(companyModel?: CompanyModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiBusinessSupportSaveCompanyPost(companyModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerCompanyInfo} [customerCompanyInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveCustomerCompanyPost(customerCompanyInfo?: CustomerCompanyInfo, options?: any): AxiosPromise<void> {
            return localVarFp.apiBusinessSupportSaveCustomerCompanyPost(customerCompanyInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmploymentGroupsRequest} [employmentGroupsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveEmploymentGroupsPost(employmentGroupsRequest?: EmploymentGroupsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiBusinessSupportSaveEmploymentGroupsPost(employmentGroupsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveFollowUpAdvisoryConfigurationModel} [saveFollowUpAdvisoryConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveFollowupConfigurationPut(saveFollowUpAdvisoryConfigurationModel?: SaveFollowUpAdvisoryConfigurationModel, options?: any): AxiosPromise<WorkOrderConfiguration> {
            return localVarFp.apiBusinessSupportSaveFollowupConfigurationPut(saveFollowUpAdvisoryConfigurationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginConfiguration} [loginConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveLoginConfigurationPost(loginConfiguration?: LoginConfiguration, options?: any): AxiosPromise<ObjectResult> {
            return localVarFp.apiBusinessSupportSaveLoginConfigurationPost(loginConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RehabConfiguration} [rehabConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveRehabConfigurationPost(rehabConfiguration?: RehabConfiguration, options?: any): AxiosPromise<ObjectResult> {
            return localVarFp.apiBusinessSupportSaveRehabConfigurationPost(rehabConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RehabTemplatesConfiguration} [rehabTemplatesConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveRehabTemplateConfigurationPost(rehabTemplatesConfiguration?: RehabTemplatesConfiguration, options?: any): AxiosPromise<ObjectResult> {
            return localVarFp.apiBusinessSupportSaveRehabTemplateConfigurationPost(rehabTemplatesConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ServiceContractModel} [serviceContractModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveServiceContractPost(serviceContractModel?: ServiceContractModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiBusinessSupportSaveServiceContractPost(serviceContractModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveWorkOrderConfigurationModel} [saveWorkOrderConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSaveWorkOrderConfigurationPut(saveWorkOrderConfigurationModel?: SaveWorkOrderConfigurationModel, options?: any): AxiosPromise<string> {
            return localVarFp.apiBusinessSupportSaveWorkOrderConfigurationPut(saveWorkOrderConfigurationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchCompanyByNameOrOrgNrModel} [searchCompanyByNameOrOrgNrModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSearchCompaniesByNameOrOrgNrPost(searchCompanyByNameOrOrgNrModel?: SearchCompanyByNameOrOrgNrModel, options?: any): AxiosPromise<Array<Company>> {
            return localVarFp.apiBusinessSupportSearchCompaniesByNameOrOrgNrPost(searchCompanyByNameOrOrgNrModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchCompanyModel} [searchCompanyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportSearchCompanyPost(searchCompanyModel?: SearchCompanyModel, options?: any): AxiosPromise<Array<Company>> {
            return localVarFp.apiBusinessSupportSearchCompanyPost(searchCompanyModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportUpdateCompanyPost(companyModel?: CompanyModel, options?: any): AxiosPromise<ObjectResult> {
            return localVarFp.apiBusinessSupportUpdateCompanyPost(companyModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [countryCode] 
         * @param {string} [language] 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportUpdateCompanyRegionPut(companyId?: number, countryCode?: string, language?: string, currency?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiBusinessSupportUpdateCompanyRegionPut(companyId, countryCode, language, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBusinessSupportUpdateCustomerCompanyDetailsPost(companyModel?: CompanyModel, options?: any): AxiosPromise<ObjectResult> {
            return localVarFp.apiBusinessSupportUpdateCustomerCompanyDetailsPost(companyModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BusinessSupportApi - object-oriented interface
 * @export
 * @class BusinessSupportApi
 * @extends {BaseAPI}
 */
export class BusinessSupportApi extends BaseAPI {
    /**
     * 
     * @param {EmploymentGroupsRequest} [employmentGroupsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportDeleteEmploymentGroupsPost(employmentGroupsRequest?: EmploymentGroupsRequest, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportDeleteEmploymentGroupsPost(employmentGroupsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetAbsenceFollowUpConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetAbsenceFollowUpConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetAllHealthServicesGet(options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetAllHealthServicesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RetailerIdsPostModel} [retailerIdsPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetBillableErpProductsByRetailIdsPost(retailerIdsPostModel?: RetailerIdsPostModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetBillableErpProductsByRetailIdsPost(retailerIdsPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetCompanyInfoGet(companyId?: number, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetCompanyInfoGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetCompanyMainDetailListGet(companyId?: number, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetCompanyMainDetailListGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetCompanyServiceContractsGet(companyId?: number, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetCompanyServiceContractsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetEmploymentGroupsGet(companyId?: number, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetEmploymentGroupsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [groupName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetGroupDescriptionGet(companyId?: number, groupName?: string, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetGroupDescriptionGet(companyId, groupName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetLoginConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetLoginConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetRegionsGet(options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetRegionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetRehabConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetRehabConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetRehabTemplatesConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetRehabTemplatesConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportGetWorkOrderConfigurationGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportGetWorkOrderConfigurationGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [rangeStart] 
     * @param {string} [rangeEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportPerformedServicesForCompanyGet(companyId?: number, rangeStart?: string, rangeEnd?: string, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportPerformedServicesForCompanyGet(companyId, rangeStart, rangeEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceFollowUpConfiguration} [absenceFollowUpConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveAbsenceFollowUpConfigurationPost(absenceFollowUpConfiguration?: AbsenceFollowUpConfiguration, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveAbsenceFollowUpConfigurationPost(absenceFollowUpConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillableErpProduct} [billableErpProduct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveBillableErpProductPost(billableErpProduct?: BillableErpProduct, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveBillableErpProductPost(billableErpProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyInfoModel} [companyInfoModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveCompanyInfoPost(companyInfoModel?: CompanyInfoModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveCompanyInfoPost(companyInfoModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyModel} [companyModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveCompanyPost(companyModel?: CompanyModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveCompanyPost(companyModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerCompanyInfo} [customerCompanyInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveCustomerCompanyPost(customerCompanyInfo?: CustomerCompanyInfo, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveCustomerCompanyPost(customerCompanyInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmploymentGroupsRequest} [employmentGroupsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveEmploymentGroupsPost(employmentGroupsRequest?: EmploymentGroupsRequest, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveEmploymentGroupsPost(employmentGroupsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveFollowUpAdvisoryConfigurationModel} [saveFollowUpAdvisoryConfigurationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveFollowupConfigurationPut(saveFollowUpAdvisoryConfigurationModel?: SaveFollowUpAdvisoryConfigurationModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveFollowupConfigurationPut(saveFollowUpAdvisoryConfigurationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginConfiguration} [loginConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveLoginConfigurationPost(loginConfiguration?: LoginConfiguration, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveLoginConfigurationPost(loginConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RehabConfiguration} [rehabConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveRehabConfigurationPost(rehabConfiguration?: RehabConfiguration, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveRehabConfigurationPost(rehabConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RehabTemplatesConfiguration} [rehabTemplatesConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveRehabTemplateConfigurationPost(rehabTemplatesConfiguration?: RehabTemplatesConfiguration, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveRehabTemplateConfigurationPost(rehabTemplatesConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ServiceContractModel} [serviceContractModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveServiceContractPost(serviceContractModel?: ServiceContractModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveServiceContractPost(serviceContractModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveWorkOrderConfigurationModel} [saveWorkOrderConfigurationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSaveWorkOrderConfigurationPut(saveWorkOrderConfigurationModel?: SaveWorkOrderConfigurationModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSaveWorkOrderConfigurationPut(saveWorkOrderConfigurationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchCompanyByNameOrOrgNrModel} [searchCompanyByNameOrOrgNrModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSearchCompaniesByNameOrOrgNrPost(searchCompanyByNameOrOrgNrModel?: SearchCompanyByNameOrOrgNrModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSearchCompaniesByNameOrOrgNrPost(searchCompanyByNameOrOrgNrModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchCompanyModel} [searchCompanyModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportSearchCompanyPost(searchCompanyModel?: SearchCompanyModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportSearchCompanyPost(searchCompanyModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyModel} [companyModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportUpdateCompanyPost(companyModel?: CompanyModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportUpdateCompanyPost(companyModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [countryCode] 
     * @param {string} [language] 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportUpdateCompanyRegionPut(companyId?: number, countryCode?: string, language?: string, currency?: string, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportUpdateCompanyRegionPut(companyId, countryCode, language, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyModel} [companyModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessSupportApi
     */
    public apiBusinessSupportUpdateCustomerCompanyDetailsPost(companyModel?: CompanyModel, options?: AxiosRequestConfig) {
        return BusinessSupportApiFp(this.configuration).apiBusinessSupportUpdateCustomerCompanyDetailsPost(companyModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [retailerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomersByRetailerGet: async (retailerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/CustomersByRetailer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (retailerId !== undefined) {
                localVarQueryParameter['retailerId'] = retailerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/Customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyDeactivationContract} [companyDeactivationContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerDeactivateCompanyPost: async (companyDeactivationContract?: CompanyDeactivationContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/DeactivateCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDeactivationContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerGetCompaniesByCustomerIdGet: async (customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/GetCompaniesByCustomerId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomersModel} [customersModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerGetCompaniesByCustomerIdsPost: async (customersModel?: CustomersModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/GetCompaniesByCustomerIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customersModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [retailerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerGetCustomersGet: async (dateFrom?: string, dateTo?: string, retailerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/GetCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (retailerId !== undefined) {
                localVarQueryParameter['retailerId'] = retailerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InboundCustomerBillingConfiguration} [inboundCustomerBillingConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveBillingConfigurationPost: async (inboundCustomerBillingConfiguration?: InboundCustomerBillingConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/SaveBillingConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inboundCustomerBillingConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BillingPostConfigurationPostModel} [billingPostConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveBillingPostConfigurationPost: async (billingPostConfigurationPostModel?: BillingPostConfigurationPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/SaveBillingPostConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingPostConfigurationPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BillingPosts} [billingPosts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveBillingPostConfigurationsPost: async (billingPosts?: BillingPosts, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/SaveBillingPostConfigurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingPosts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BillingConfiguration} [billingConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveCustomerBillingPost: async (billingConfiguration?: BillingConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/SaveCustomerBilling`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerInfo} [customerInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveCustomerInfoPost: async (customerInfo?: CustomerInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/SaveCustomerInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [retailerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCustomersByRetailerGet(retailerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCustomersByRetailerGet(retailerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCustomersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCustomersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyDeactivationContract} [companyDeactivationContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerDeactivateCompanyPost(companyDeactivationContract?: CompanyDeactivationContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerDeactivateCompanyPost(companyDeactivationContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerGetCompaniesByCustomerIdGet(customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerGetCompaniesByCustomerIdGet(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomersModel} [customersModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerGetCompaniesByCustomerIdsPost(customersModel?: CustomersModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerGetCompaniesByCustomerIdsPost(customersModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [retailerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerGetCustomersGet(dateFrom?: string, dateTo?: string, retailerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerGetCustomersGet(dateFrom, dateTo, retailerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InboundCustomerBillingConfiguration} [inboundCustomerBillingConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerSaveBillingConfigurationPost(inboundCustomerBillingConfiguration?: InboundCustomerBillingConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerSaveBillingConfigurationPost(inboundCustomerBillingConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BillingPostConfigurationPostModel} [billingPostConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerSaveBillingPostConfigurationPost(billingPostConfigurationPostModel?: BillingPostConfigurationPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerSaveBillingPostConfigurationPost(billingPostConfigurationPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BillingPosts} [billingPosts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerSaveBillingPostConfigurationsPost(billingPosts?: BillingPosts, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerSaveBillingPostConfigurationsPost(billingPosts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BillingConfiguration} [billingConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerSaveCustomerBillingPost(billingConfiguration?: BillingConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerSaveCustomerBillingPost(billingConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerInfo} [customerInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerSaveCustomerInfoPost(customerInfo?: CustomerInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerSaveCustomerInfoPost(customerInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [retailerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomersByRetailerGet(retailerId?: number, options?: any): AxiosPromise<Array<CustomerModel>> {
            return localVarFp.apiCustomerCustomersByRetailerGet(retailerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCustomersGet(options?: any): AxiosPromise<Array<CustomerModel>> {
            return localVarFp.apiCustomerCustomersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyDeactivationContract} [companyDeactivationContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerDeactivateCompanyPost(companyDeactivationContract?: CompanyDeactivationContract, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerDeactivateCompanyPost(companyDeactivationContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerGetCompaniesByCustomerIdGet(customerId?: number, options?: any): AxiosPromise<Array<CustomerCompany>> {
            return localVarFp.apiCustomerGetCompaniesByCustomerIdGet(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomersModel} [customersModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerGetCompaniesByCustomerIdsPost(customersModel?: CustomersModel, options?: any): AxiosPromise<Array<CustomerCompany>> {
            return localVarFp.apiCustomerGetCompaniesByCustomerIdsPost(customersModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {number} [retailerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerGetCustomersGet(dateFrom?: string, dateTo?: string, retailerId?: number, options?: any): AxiosPromise<Array<CustomerModel>> {
            return localVarFp.apiCustomerGetCustomersGet(dateFrom, dateTo, retailerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InboundCustomerBillingConfiguration} [inboundCustomerBillingConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveBillingConfigurationPost(inboundCustomerBillingConfiguration?: InboundCustomerBillingConfiguration, options?: any): AxiosPromise<number> {
            return localVarFp.apiCustomerSaveBillingConfigurationPost(inboundCustomerBillingConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BillingPostConfigurationPostModel} [billingPostConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveBillingPostConfigurationPost(billingPostConfigurationPostModel?: BillingPostConfigurationPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerSaveBillingPostConfigurationPost(billingPostConfigurationPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BillingPosts} [billingPosts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveBillingPostConfigurationsPost(billingPosts?: BillingPosts, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerSaveBillingPostConfigurationsPost(billingPosts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BillingConfiguration} [billingConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveCustomerBillingPost(billingConfiguration?: BillingConfiguration, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerSaveCustomerBillingPost(billingConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerInfo} [customerInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSaveCustomerInfoPost(customerInfo?: CustomerInfo, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiCustomerSaveCustomerInfoPost(customerInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @param {number} [retailerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerCustomersByRetailerGet(retailerId?: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerCustomersByRetailerGet(retailerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerCustomersGet(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerCustomersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyDeactivationContract} [companyDeactivationContract] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerDeactivateCompanyPost(companyDeactivationContract?: CompanyDeactivationContract, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerDeactivateCompanyPost(companyDeactivationContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerGetCompaniesByCustomerIdGet(customerId?: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerGetCompaniesByCustomerIdGet(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomersModel} [customersModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerGetCompaniesByCustomerIdsPost(customersModel?: CustomersModel, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerGetCompaniesByCustomerIdsPost(customersModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {number} [retailerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerGetCustomersGet(dateFrom?: string, dateTo?: string, retailerId?: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerGetCustomersGet(dateFrom, dateTo, retailerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InboundCustomerBillingConfiguration} [inboundCustomerBillingConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerSaveBillingConfigurationPost(inboundCustomerBillingConfiguration?: InboundCustomerBillingConfiguration, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerSaveBillingConfigurationPost(inboundCustomerBillingConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillingPostConfigurationPostModel} [billingPostConfigurationPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerSaveBillingPostConfigurationPost(billingPostConfigurationPostModel?: BillingPostConfigurationPostModel, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerSaveBillingPostConfigurationPost(billingPostConfigurationPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillingPosts} [billingPosts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerSaveBillingPostConfigurationsPost(billingPosts?: BillingPosts, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerSaveBillingPostConfigurationsPost(billingPosts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillingConfiguration} [billingConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerSaveCustomerBillingPost(billingConfiguration?: BillingConfiguration, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerSaveCustomerBillingPost(billingConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerInfo} [customerInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiCustomerSaveCustomerInfoPost(customerInfo?: CustomerInfo, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiCustomerSaveCustomerInfoPost(customerInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OnboardingApi - axios parameter creator
 * @export
 */
export const OnboardingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingDeleteCompanyConfigurationDelete: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/DeleteCompanyConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteEmploymentGroupsModel} [deleteEmploymentGroupsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingDeleteEmploymentGroupsPost: async (deleteEmploymentGroupsModel?: DeleteEmploymentGroupsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/DeleteEmploymentGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmploymentGroupsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetAdvisoryServicesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetAdvisoryServices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetCompanyConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetCompanyConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetCustomerCompanyAdvisoryServicesGet: async (customerId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetCustomerCompanyAdvisoryServices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetCustomerGet: async (customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetCustomer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetCustomerInfoGet: async (companyId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetCustomerInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetEmploymentAbsenceInfoGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetEmploymentAbsenceInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetFileImportConfigGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetFileImportConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetNotificationConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetNotificationConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetOrganizationInfoGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetOrganizationInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetRehabConfigGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetRehabConfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetRehabTemplatesGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetRehabTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetServiceContractsGet: async (customerId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetServiceContracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetServiceUtilizationInfoGet: async (companyId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetServiceUtilizationInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetUserInfoGet: async (companyId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetWorkOrderInfoGet: async (companyId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/GetWorkOrderInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyConfigurationModel} [companyConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSaveCompanyConfigurationPost: async (companyConfigurationModel?: CompanyConfigurationModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/SaveCompanyConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyConfigurationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerCompanyAdvisoryService} [customerCompanyAdvisoryService] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSaveCustomerCompanyAdvisoryServicePost: async (customerCompanyAdvisoryService?: CustomerCompanyAdvisoryService, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/SaveCustomerCompanyAdvisoryService`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerCompanyAdvisoryService, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SaveEmploymentGroupsModel} [saveEmploymentGroupsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSaveEmploymentGroupsPost: async (saveEmploymentGroupsModel?: SaveEmploymentGroupsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/SaveEmploymentGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveEmploymentGroupsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationConfigurationModel} [notificationConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSaveNotificationConfigurationPost: async (notificationConfigurationModel?: NotificationConfigurationModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/SaveNotificationConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationConfigurationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSearchCustomersAndCompaniesGet: async (searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/SearchCustomersAndCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCompanyModel} [updateCompanyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingUpdateCompanyPost: async (updateCompanyModel?: UpdateCompanyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/UpdateCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerProductModel} [customerProductModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingUpdateCustomerProductPost: async (customerProductModel?: CustomerProductModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Onboarding/UpdateCustomerProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerProductModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardingApi - functional programming interface
 * @export
 */
export const OnboardingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OnboardingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingDeleteCompanyConfigurationDelete(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingDeleteCompanyConfigurationDelete(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteEmploymentGroupsModel} [deleteEmploymentGroupsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingDeleteEmploymentGroupsPost(deleteEmploymentGroupsModel?: DeleteEmploymentGroupsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingDeleteEmploymentGroupsPost(deleteEmploymentGroupsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetAdvisoryServicesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdvisoryService>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetAdvisoryServicesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetCompanyConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyConfigurationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetCompanyConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetCustomerCompanyAdvisoryServicesGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdvisoryServiceResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetCustomerCompanyAdvisoryServicesGet(customerId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetCustomerGet(customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerOnboarding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetCustomerGet(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetCustomerInfoGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySelectCustomer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetCustomerInfoGet(companyId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetEmploymentAbsenceInfoGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySelectedEmpAbsence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetEmploymentAbsenceInfoGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetFileImportConfigGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetFileImportConfigGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetNotificationConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationConfigurationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetNotificationConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetOrganizationInfoGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySelectOrganization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetOrganizationInfoGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetRehabConfigGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RehabConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetRehabConfigGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetRehabTemplatesGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RehabTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetRehabTemplatesGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetServiceContractsGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySelectedServiceUtil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetServiceContractsGet(customerId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetServiceUtilizationInfoGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceContractModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetServiceUtilizationInfoGet(companyId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetUserInfoGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanySelectedUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetUserInfoGet(companyId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingGetWorkOrderInfoGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingGetWorkOrderInfoGet(companyId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyConfigurationModel} [companyConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingSaveCompanyConfigurationPost(companyConfigurationModel?: CompanyConfigurationModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingSaveCompanyConfigurationPost(companyConfigurationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerCompanyAdvisoryService} [customerCompanyAdvisoryService] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingSaveCustomerCompanyAdvisoryServicePost(customerCompanyAdvisoryService?: CustomerCompanyAdvisoryService, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingSaveCustomerCompanyAdvisoryServicePost(customerCompanyAdvisoryService, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SaveEmploymentGroupsModel} [saveEmploymentGroupsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingSaveEmploymentGroupsPost(saveEmploymentGroupsModel?: SaveEmploymentGroupsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmploymentGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingSaveEmploymentGroupsPost(saveEmploymentGroupsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NotificationConfigurationModel} [notificationConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingSaveNotificationConfigurationPost(notificationConfigurationModel?: NotificationConfigurationModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingSaveNotificationConfigurationPost(notificationConfigurationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingSearchCustomersAndCompaniesGet(searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerCompanyOnboarding>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingSearchCustomersAndCompaniesGet(searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateCompanyModel} [updateCompanyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingUpdateCompanyPost(updateCompanyModel?: UpdateCompanyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingUpdateCompanyPost(updateCompanyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CustomerProductModel} [customerProductModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOnboardingUpdateCustomerProductPost(customerProductModel?: CustomerProductModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOnboardingUpdateCustomerProductPost(customerProductModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OnboardingApi - factory interface
 * @export
 */
export const OnboardingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OnboardingApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingDeleteCompanyConfigurationDelete(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOnboardingDeleteCompanyConfigurationDelete(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteEmploymentGroupsModel} [deleteEmploymentGroupsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingDeleteEmploymentGroupsPost(deleteEmploymentGroupsModel?: DeleteEmploymentGroupsModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiOnboardingDeleteEmploymentGroupsPost(deleteEmploymentGroupsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetAdvisoryServicesGet(options?: any): AxiosPromise<Array<AdvisoryService>> {
            return localVarFp.apiOnboardingGetAdvisoryServicesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetCompanyConfigurationGet(companyId?: number, options?: any): AxiosPromise<CompanyConfigurationModel> {
            return localVarFp.apiOnboardingGetCompanyConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetCustomerCompanyAdvisoryServicesGet(customerId?: number, companyId?: number, options?: any): AxiosPromise<AdvisoryServiceResult> {
            return localVarFp.apiOnboardingGetCustomerCompanyAdvisoryServicesGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetCustomerGet(customerId?: number, options?: any): AxiosPromise<CustomerOnboarding> {
            return localVarFp.apiOnboardingGetCustomerGet(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetCustomerInfoGet(companyId?: number, customerId?: number, options?: any): AxiosPromise<CompanySelectCustomer> {
            return localVarFp.apiOnboardingGetCustomerInfoGet(companyId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetEmploymentAbsenceInfoGet(companyId?: number, options?: any): AxiosPromise<CompanySelectedEmpAbsence> {
            return localVarFp.apiOnboardingGetEmploymentAbsenceInfoGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetFileImportConfigGet(companyId?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiOnboardingGetFileImportConfigGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetNotificationConfigurationGet(companyId?: number, options?: any): AxiosPromise<NotificationConfigurationModel> {
            return localVarFp.apiOnboardingGetNotificationConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetOrganizationInfoGet(companyId?: number, options?: any): AxiosPromise<CompanySelectOrganization> {
            return localVarFp.apiOnboardingGetOrganizationInfoGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetRehabConfigGet(companyId?: number, options?: any): AxiosPromise<RehabConfiguration> {
            return localVarFp.apiOnboardingGetRehabConfigGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetRehabTemplatesGet(companyId?: number, options?: any): AxiosPromise<RehabTemplate> {
            return localVarFp.apiOnboardingGetRehabTemplatesGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetServiceContractsGet(customerId?: number, companyId?: number, options?: any): AxiosPromise<CompanySelectedServiceUtil> {
            return localVarFp.apiOnboardingGetServiceContractsGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetServiceUtilizationInfoGet(companyId?: number, customerId?: number, options?: any): AxiosPromise<Array<ServiceContractModel>> {
            return localVarFp.apiOnboardingGetServiceUtilizationInfoGet(companyId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetUserInfoGet(companyId?: number, customerId?: number, options?: any): AxiosPromise<CompanySelectedUser> {
            return localVarFp.apiOnboardingGetUserInfoGet(companyId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingGetWorkOrderInfoGet(companyId?: number, customerId?: number, options?: any): AxiosPromise<WorkOrderConfiguration> {
            return localVarFp.apiOnboardingGetWorkOrderInfoGet(companyId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyConfigurationModel} [companyConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSaveCompanyConfigurationPost(companyConfigurationModel?: CompanyConfigurationModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiOnboardingSaveCompanyConfigurationPost(companyConfigurationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerCompanyAdvisoryService} [customerCompanyAdvisoryService] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSaveCustomerCompanyAdvisoryServicePost(customerCompanyAdvisoryService?: CustomerCompanyAdvisoryService, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiOnboardingSaveCustomerCompanyAdvisoryServicePost(customerCompanyAdvisoryService, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SaveEmploymentGroupsModel} [saveEmploymentGroupsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSaveEmploymentGroupsPost(saveEmploymentGroupsModel?: SaveEmploymentGroupsModel, options?: any): AxiosPromise<Array<EmploymentGroup>> {
            return localVarFp.apiOnboardingSaveEmploymentGroupsPost(saveEmploymentGroupsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationConfigurationModel} [notificationConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSaveNotificationConfigurationPost(notificationConfigurationModel?: NotificationConfigurationModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiOnboardingSaveNotificationConfigurationPost(notificationConfigurationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingSearchCustomersAndCompaniesGet(searchString?: string, options?: any): AxiosPromise<Array<CustomerCompanyOnboarding>> {
            return localVarFp.apiOnboardingSearchCustomersAndCompaniesGet(searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCompanyModel} [updateCompanyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingUpdateCompanyPost(updateCompanyModel?: UpdateCompanyModel, options?: any): AxiosPromise<number> {
            return localVarFp.apiOnboardingUpdateCompanyPost(updateCompanyModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerProductModel} [customerProductModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOnboardingUpdateCustomerProductPost(customerProductModel?: CustomerProductModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiOnboardingUpdateCustomerProductPost(customerProductModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnboardingApi - object-oriented interface
 * @export
 * @class OnboardingApi
 * @extends {BaseAPI}
 */
export class OnboardingApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingDeleteCompanyConfigurationDelete(companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingDeleteCompanyConfigurationDelete(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteEmploymentGroupsModel} [deleteEmploymentGroupsModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingDeleteEmploymentGroupsPost(deleteEmploymentGroupsModel?: DeleteEmploymentGroupsModel, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingDeleteEmploymentGroupsPost(deleteEmploymentGroupsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetAdvisoryServicesGet(options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetAdvisoryServicesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetCompanyConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetCompanyConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetCustomerCompanyAdvisoryServicesGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetCustomerCompanyAdvisoryServicesGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetCustomerGet(customerId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetCustomerGet(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetCustomerInfoGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetCustomerInfoGet(companyId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetEmploymentAbsenceInfoGet(companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetEmploymentAbsenceInfoGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetFileImportConfigGet(companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetFileImportConfigGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetNotificationConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetNotificationConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetOrganizationInfoGet(companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetOrganizationInfoGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetRehabConfigGet(companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetRehabConfigGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetRehabTemplatesGet(companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetRehabTemplatesGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetServiceContractsGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetServiceContractsGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetServiceUtilizationInfoGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetServiceUtilizationInfoGet(companyId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetUserInfoGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetUserInfoGet(companyId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingGetWorkOrderInfoGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingGetWorkOrderInfoGet(companyId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyConfigurationModel} [companyConfigurationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingSaveCompanyConfigurationPost(companyConfigurationModel?: CompanyConfigurationModel, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingSaveCompanyConfigurationPost(companyConfigurationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerCompanyAdvisoryService} [customerCompanyAdvisoryService] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingSaveCustomerCompanyAdvisoryServicePost(customerCompanyAdvisoryService?: CustomerCompanyAdvisoryService, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingSaveCustomerCompanyAdvisoryServicePost(customerCompanyAdvisoryService, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SaveEmploymentGroupsModel} [saveEmploymentGroupsModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingSaveEmploymentGroupsPost(saveEmploymentGroupsModel?: SaveEmploymentGroupsModel, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingSaveEmploymentGroupsPost(saveEmploymentGroupsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationConfigurationModel} [notificationConfigurationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingSaveNotificationConfigurationPost(notificationConfigurationModel?: NotificationConfigurationModel, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingSaveNotificationConfigurationPost(notificationConfigurationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingSearchCustomersAndCompaniesGet(searchString?: string, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingSearchCustomersAndCompaniesGet(searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCompanyModel} [updateCompanyModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingUpdateCompanyPost(updateCompanyModel?: UpdateCompanyModel, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingUpdateCompanyPost(updateCompanyModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerProductModel} [customerProductModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public apiOnboardingUpdateCustomerProductPost(customerProductModel?: CustomerProductModel, options?: AxiosRequestConfig) {
        return OnboardingApiFp(this.configuration).apiOnboardingUpdateCustomerProductPost(customerProductModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusInformationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status/Information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusInformationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusInformationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusInformationGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusInformationGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusInformationGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusInformationGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupportToolApi - axios parameter creator
 * @export
 */
export const SupportToolApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSupportToolGetAllCustomersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SupportTool/GetAllCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmploymentInfosModel} [employmentInfosModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSupportToolGetEmploymentInfosPost: async (employmentInfosModel?: EmploymentInfosModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SupportTool/GetEmploymentInfos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentInfosModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [userAccountIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSupportToolGetPerformedByInfosGet: async (userAccountIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SupportTool/GetPerformedByInfos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (userAccountIds) {
                localVarQueryParameter['userAccountIds'] = userAccountIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportToolApi - functional programming interface
 * @export
 */
export const SupportToolApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportToolApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSupportToolGetAllCustomersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSupportToolGetAllCustomersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmploymentInfosModel} [employmentInfosModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSupportToolGetEmploymentInfosPost(employmentInfosModel?: EmploymentInfosModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmploymentInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSupportToolGetEmploymentInfosPost(employmentInfosModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [userAccountIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSupportToolGetPerformedByInfosGet(userAccountIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerformedByInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSupportToolGetPerformedByInfosGet(userAccountIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupportToolApi - factory interface
 * @export
 */
export const SupportToolApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportToolApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSupportToolGetAllCustomersGet(options?: any): AxiosPromise<Array<CustomerModel>> {
            return localVarFp.apiSupportToolGetAllCustomersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmploymentInfosModel} [employmentInfosModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSupportToolGetEmploymentInfosPost(employmentInfosModel?: EmploymentInfosModel, options?: any): AxiosPromise<Array<EmploymentInfo>> {
            return localVarFp.apiSupportToolGetEmploymentInfosPost(employmentInfosModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [userAccountIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSupportToolGetPerformedByInfosGet(userAccountIds?: Array<number>, options?: any): AxiosPromise<Array<PerformedByInfo>> {
            return localVarFp.apiSupportToolGetPerformedByInfosGet(userAccountIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportToolApi - object-oriented interface
 * @export
 * @class SupportToolApi
 * @extends {BaseAPI}
 */
export class SupportToolApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportToolApi
     */
    public apiSupportToolGetAllCustomersGet(options?: AxiosRequestConfig) {
        return SupportToolApiFp(this.configuration).apiSupportToolGetAllCustomersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmploymentInfosModel} [employmentInfosModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportToolApi
     */
    public apiSupportToolGetEmploymentInfosPost(employmentInfosModel?: EmploymentInfosModel, options?: AxiosRequestConfig) {
        return SupportToolApiFp(this.configuration).apiSupportToolGetEmploymentInfosPost(employmentInfosModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [userAccountIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportToolApi
     */
    public apiSupportToolGetPerformedByInfosGet(userAccountIds?: Array<number>, options?: AxiosRequestConfig) {
        return SupportToolApiFp(this.configuration).apiSupportToolGetPerformedByInfosGet(userAccountIds, options).then((request) => request(this.axios, this.basePath));
    }
}


