import { useAppSelector } from "store/hooks";
import { compact, isEmpty, isNaN } from "lodash/fp";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import { actions, selectors } from "../../redux";
import AbsenceDaysFilter from "./AbsenceDaysFilter";

export default function Index() {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const maxDays = useAppSelector(selectors.getSearchNumberById("maxDays"));
    const minDays = useAppSelector(selectors.getSearchNumberById("minDays"));
    const setMinDays = useCallback(
        (number: string) => {
            dispatch(
                actions.updateSearchFilter({
                    value: isEmpty(number) ? null : parseInt(number, 10),
                    id: "minDays",
                    referenceKey: "minDays",
                }),
            );
        },
        [dispatch],
    );
    const setMaxDays = useCallback(
        (number: string) => {
            dispatch(
                actions.updateSearchFilter({
                    value: isEmpty(number) ? null : parseInt(number, 10),
                    id: "maxDays",
                    referenceKey: "maxDays",
                }),
            );
        },
        [dispatch],
    );

    const getLabel = useCallback((value: string) => t("search.numberOfDays", { value }), [t]);
    const items = useMemo(
        () => [
            {
                value: minDays?.value?.toString() || "",
                setValue: setMinDays,
                label: getLabel("min"),
                name: "days-filter",
            },
            {
                value: maxDays?.value?.toString() || "",
                setValue: setMaxDays,
                label: getLabel("max"),
                name: "days-filter",
            },
        ],
        [getLabel, maxDays, minDays, setMaxDays, setMinDays],
    );
    const Header = useMemo(() => t("search.searchQuery.filters.accordionNumberOfAbsence"), [t]);

    const Body = useMemo(() => {
        const currentMinDays = isNaN(minDays?.value) ? undefined : minDays?.value?.toString();

        const currentMaxDays = isNaN(maxDays?.value) ? undefined : maxDays?.value?.toString();

        const minDaysText = currentMinDays && !isEmpty(currentMinDays) ? `min ${currentMinDays}` : undefined;
        const maxDaysText = currentMaxDays && !isEmpty(currentMaxDays) ? `max ${currentMaxDays}` : undefined;
        const texts = compact([minDaysText, maxDaysText]);
        return !isEmpty(texts) ? texts.join(", ") : "";
    }, [maxDays, minDays]);

    return <AbsenceDaysFilter items={items} body={Body} header={Header} />;
}
