import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { thunkActions } from "pages/FollowUp/redux";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { toast } from "react-toastify";
import { ModalCommonFooter, ModalFrame } from "@medhelp/ui";
import Select from "components/Select";
import Icon from "components/Icon";

interface IProps {
    healthCase: IHealthCase;
    modalIsOpen: boolean;
    setModalIsOpen: () => void;
}
const DeclineCaseModal = (props: IProps) => {
    const { healthCase, modalIsOpen, setModalIsOpen } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const [declineReason, setDeclineReason] = useState<string | undefined>(undefined);

    const handleSaveDeclineCase = () => {
        if (healthCase?.id && declineReason) {
            dispatch(
                thunkActions.declineHealthCase({
                    healthCaseId: healthCase?.id ?? "",
                    reason: declineReason,
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("recommendationDeclined")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("recommendationNotDeclined")}`, {
                        type: "error",
                    }),
                );
        }
        setModalIsOpen();
    };

    const selectValues = [
        {
            value: "ongoingCase",
            description: t("ongoingCase"),
        },
        {
            value: "recentlyCompletedCase",
            description: t("recentlyCompletedCase"),
        },
        {
            value: "waitingForMoreSignals",
            description: t("waitingForMoreSignals"),
        },
        {
            value: "other",
            description: t("other"),
        },
    ];

    return (
        <ModalFrame
            id="decline-case-modal"
            header={<h1>{`${t("declineRecommendation")}?`}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            content={
                <div className="px-3 sm:px-0">
                    <p className="mb-6">{t("declineCaseInfo")}</p>
                    <h5 className="font-bold pt-8 pb-2">{`${t("chooseReason")} *`}</h5>
                    <Select
                        id="select-reason"
                        placeholder={t("chooseReason")}
                        selectedValue={declineReason ?? ""}
                        onChange={setDeclineReason}
                        hidePlaceHolderInList
                        values={selectValues}
                    />
                </div>
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    tertiaryOnClick={setModalIsOpen}
                    primaryText={t("save")}
                    primaryOnClick={handleSaveDeclineCase}
                    primaryDisabled={!declineReason}
                    primaryRightIcon={<Icon icon="checkMark" stroke="#192D05" />}
                />
            }
        />
    );
};

export default DeclineCaseModal;
