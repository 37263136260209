import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { templateBuilder } from "./builders";
import { WorkOrderReservationViewModel } from "swagger/medicaladvisory";
import { getWorkOrder, saveWorkOrder } from "./workOrderAsyncActions";

export interface IWorkOrderSlice {
    isLoading: boolean | null;
    isError: boolean | null;
    isSaving: boolean | null;
    isSaved: boolean | null;
    isSavedError: boolean | null;
    status: string | null;
    workOrderReservation: WorkOrderReservationViewModel | null;
}

const initialWorkOrderState: IWorkOrderSlice = {
    isLoading: true,
    isError: null,
    isSaved: false,
    isSaving: false,
    isSavedError: false,
    status: null,
    workOrderReservation: null,
};

const workOrderSlice = createSlice({
    name: "workorder",
    initialState: initialWorkOrderState,
    reducers: {
        setIsLoading(state) {
            state.isLoading = true;
        },
        setIsSaving(state) {
            state.isSaving = true;
        },
        resetStatus(state) {
            state.status = null;
        },
    },
    extraReducers: (builder) => {
        templateBuilder(builder);
        builder
            .addCase(getWorkOrder.fulfilled, (state, action: PayloadAction<WorkOrderReservationViewModel>) => {
                state.status = "reqHasBeenSent";
                state.isError = false;
                state.isLoading = false;
                state.workOrderReservation = action.payload;
            })
            .addCase(getWorkOrder.rejected, (state) => {
                state.status = "apiCallRejected";
                state.isError = true;
                state.isLoading = false;
            })

            .addCase(saveWorkOrder.fulfilled, (state) => {
                state.status = "reqHasBeenSent";
                state.isSavedError = false;
                state.isSaved = true;
            })
            .addCase(saveWorkOrder.rejected, (state) => {
                state.status = "apiCallRejected";
                state.isSavedError = true;
                state.isSaved = false;
                state.isSaving = false;
            });
    },
});

export const workOrderActions = workOrderSlice.actions;

export default workOrderSlice.reducer;
