import { WidgetType } from "pages/dashboard/utils/dashboardTypes";

export interface IWidgetMap {
    heading: string;
    style: string;
    filterTypes: FilterType[];
    accessRight: WidgetAccessRightType;
    rehabFilter: boolean;
    timeSpan?: TimeSpan;
    defaultTimeSpan?: TimeSpan;
    startOfMonth?: boolean;
    requiredFilters?: FilterType[];
}

export type WidgetAccessRightType = "absenceWidget" | "statisticsWidget" | "followUpWidget" | "rehabWidget";

export type TimeSpan =
    | "ongoing"
    | "currentMonth"
    | "threeMonths"
    | "sixMonths"
    | "twelveMonths"
    | "sevenDays"
    | "dynamic";

export type FilterType =
    | "departments"
    | "employmentGroups"
    | "genders"
    | "absenceTypes"
    | "absencePeriods"
    | "companies"
    | "timeInterval"
    | "absenceCauses"
    | "hcmTypes";

export const widgetMap: Map<WidgetType, IWidgetMap> = new Map<WidgetType, IWidgetMap>([
    [
        "ongoing-absence",
        {
            heading: "ongoingAbsence",
            style: "secondary",
            rehabFilter: false,
            filterTypes: ["companies", "departments", "employmentGroups", "genders", "absenceTypes", "absencePeriods"],
            accessRight: "absenceWidget",
            timeSpan: "ongoing",
        },
    ],
    [
        "last-reported-absence",
        {
            heading: "lastReportedAbsence",
            style: "secondary",
            rehabFilter: false,
            filterTypes: ["companies", "departments", "employmentGroups", "genders", "absenceTypes", "absencePeriods"],
            accessRight: "absenceWidget",
        },
    ],
    [
        "absence-percent-per-month",
        {
            heading: "absencePercentPerMonth",
            style: "primary",
            rehabFilter: false,
            filterTypes: ["companies", "employmentGroups", "genders", "absenceTypes", "timeInterval", "absencePeriods"],
            accessRight: "statisticsWidget",
            timeSpan: "dynamic",
            defaultTimeSpan: "sixMonths",
            startOfMonth: true,
        },
    ],
    [
        "absence-percent-last-6-months",
        {
            heading: "absencePercentLast6Months",
            style: "primary",
            rehabFilter: false,
            filterTypes: ["companies", "employmentGroups", "genders", "absenceTypes", "timeInterval", "absencePeriods"],
            accessRight: "statisticsWidget",
            timeSpan: "dynamic",
            defaultTimeSpan: "sixMonths",
        },
    ],

    [
        "work-related-absence",
        {
            heading: "workRelatedAbsence",
            style: "primary",
            rehabFilter: false,
            filterTypes: ["companies", "departments", "employmentGroups", "genders"],
            accessRight: "followUpWidget",
            timeSpan: "ongoing",
        },
    ],
    [
        "estimated-upcoming-absence",
        {
            heading: "estimatedUpcomingAbsence",
            style: "primary",
            rehabFilter: false,
            filterTypes: ["companies", "departments", "employmentGroups"],
            accessRight: "absenceWidget",
            timeSpan: "sevenDays",
        },
    ],
    [
        "recurring-absence-last-12-months",
        {
            heading: "recurringSickLeave",
            style: "primary",
            rehabFilter: false,
            filterTypes: ["companies", "departments", "employmentGroups", "genders", "timeInterval"],
            accessRight: "followUpWidget",
            timeSpan: "dynamic",
            defaultTimeSpan: "twelveMonths",
        },
    ],
    [
        "ongoing-rehab",
        {
            heading: "ongoingRehab",
            style: "primary",
            rehabFilter: true,
            filterTypes: ["companies", "departments", "employmentGroups", "genders"],
            accessRight: "rehabWidget",
            timeSpan: "ongoing",
        },
    ],
    [
        "last-activated-rehab",
        {
            heading: "lastActivatedRehab",
            style: "secondary",
            rehabFilter: true,
            filterTypes: ["companies", "departments", "employmentGroups", "genders"],
            accessRight: "rehabWidget",
        },
    ],
    [
        "hcm-status-overview",
        {
            heading: "hcmStatusOverview",
            style: "primary",
            rehabFilter: false,
            filterTypes: [
                "companies",
                "departments",
                "employmentGroups",
                "genders",
                // Filter hcmTypes will be used in later version of the widget
                // "hcmTypes",
            ],
            accessRight: "rehabWidget",
        },
    ],
    [
        "hcm-todo",
        {
            heading: "hcmTodo",
            style: "secondary",
            rehabFilter: false,
            filterTypes: ["companies", "departments", "employmentGroups", "genders"],
            accessRight: "rehabWidget",
        },
    ],
    [
        "absence-forecast-current-month",
        {
            heading: "absenceForecastCurrentMonth",
            style: "secondary",
            rehabFilter: false,
            filterTypes: ["companies"],
            accessRight: "statisticsWidget",
            timeSpan: "currentMonth",
        },
    ],
    [
        "absence-cause-proportion",
        {
            heading: "absenceCauseProportion",
            style: "primary",
            rehabFilter: false,
            filterTypes: ["companies", "employmentGroups", "genders", "timeInterval", "absenceCauses"],
            accessRight: "statisticsWidget",
            timeSpan: "dynamic",
            defaultTimeSpan: "twelveMonths",
            startOfMonth: true,
            requiredFilters: ["absenceCauses"],
        },
    ],
]);
