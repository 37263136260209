import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { getCaseDocuments } from "pages/FollowUp/redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { CaseDocumentItem } from "pages/FollowUp/components/HealthCaseManagement";
import { Status } from "@medhelp/ui/LoadableComponent";
import { Error } from "@medhelp/ui";
import CaseDocumentsLoading from "./CaseDocumentsLoading";

interface IProps {
    healthCase: IHealthCase;
}

const CaseDocumentsContent = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const caseDocuments = useAppSelector(getCaseDocuments(healthCase.id));

    if (healthCase.healthCaseSubStatus.documents === Status.PENDING) {
        return <CaseDocumentsLoading />;
    }

    if (healthCase.healthCaseSubStatus.documents === Status.ERROR) {
        return <Error text={t("couldNotFetchContent")} />;
    }

    return (
        <>
            {caseDocuments.map((document) => (
                <CaseDocumentItem
                    key={`case-document-item-${document.id}`}
                    healthCase={healthCase}
                    document={document}
                />
            ))}
        </>
    );
};

export default CaseDocumentsContent;
