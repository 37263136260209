import {
    companyHasErasureActiveConfig,
    getErasureActiveIsLoading,
    getSelectedCompanyId,
} from "pages/Administration/redux/administrationSelectors";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ErasureActive from "./ErasueActive";
import { useMemo } from "react";
import { getCompanyDataErasureActiveConfiguration } from "pages/Administration/redux/administrationAsyncActions";
import { Spinner } from "components/Spinner";

export default function Index() {
    const dispatch = useAppDispatch();

    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const isLoading = useAppSelector(getErasureActiveIsLoading);
    const hasConfig = useAppSelector(companyHasErasureActiveConfig);

    useMemo(() => {
        if (selectedCompanyId) dispatch(getCompanyDataErasureActiveConfiguration(selectedCompanyId));
    }, [dispatch, selectedCompanyId]);

    return isLoading ? <Spinner /> : <ErasureActive companyId={selectedCompanyId ?? 0} hasConfig={hasConfig} />;
}
