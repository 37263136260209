import React from "react";
import {
    MedHelpAbsenceReportingDomainFieldTemplate,
    MedHelpAbsenceReportingDomainTemplate,
    MedHelpAbsenceReportingWebApiModelsAbsenceReport,
    MedHelpAbsenceReportingWebApiModelsAbsenceReportField,
} from "swagger/absencereportapi";
import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import AbsenceCauseDKComponent from "../AbsenceCauseDK";

interface IProps {
    templateField: MedHelpAbsenceReportingDomainFieldTemplate;
    allFields: MedHelpAbsenceReportingWebApiModelsAbsenceReportField[];
    template: MedHelpAbsenceReportingDomainTemplate;
    absenceReport: MedHelpAbsenceReportingWebApiModelsAbsenceReport;
    absenceReportFields: IFieldTemplateExtended[];
    isEdit?: boolean;
    valueChanged?: (name: string, value: string) => void;
}
const GetWidget = ({ template, templateField, absenceReport, absenceReportFields, isEdit, valueChanged }: IProps) => {
    if (templateField.widget === "absenceCauseDK") {
        if (templateField.name === "AbsenceCause") {
            return (
                <AbsenceCauseDKComponent
                    absenceReport={absenceReport}
                    template={template}
                    isEdit={isEdit}
                    absenceReportFields={absenceReportFields}
                    valueChanged={valueChanged}
                />
            );
        }
    }
    return null;
};
export default GetWidget;
