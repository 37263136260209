import { useTranslation } from "react-i18next";
import { upperCase } from "lodash/fp";

const ArchiveTag = () => {
    const { t } = useTranslation("followup");

    return (
        <div className="-mt-1">
            <p className=" bg-mhred-alert4 text-mhred-alert3 inline-block px-1 text-xs font-bold">
                {upperCase(t("followUpArchived"))}
            </p>
        </div>
    );
};

export default ArchiveTag;
