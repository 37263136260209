import { useAppSelector } from "store/hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectors } from "store/organization";
import EmployeeSearchResult from "./EmployeeSearchResult";
import Preview from "../Preview";
import EmployeeDesktopTable from "../EmployeeDesktopTable";
import { lowerCase } from "lodash/fp";
import EmployeeMobileTable from "../EmployeeMobileTable";

export default function Index() {
    const { t } = useTranslation("followup");
    const searchStatus = useAppSelector(selectors.getCompaniesDepartmentsSearchStatus);
    const totalCount = useAppSelector(selectors.getCurrentCompaniesDepartmentsTotalCount);
    const preview = useMemo(() => {
        if (searchStatus.employee === "no-data")
            return <Preview header={t("searchPreview.header")} body={t("searchPreview.body")} />;
        return undefined;
    }, [searchStatus.employee, t]);
    const result = useMemo(
        () => ({
            desktop: <EmployeeDesktopTable />,
            mobile: <EmployeeMobileTable />,
        }),
        [],
    );
    const header = useMemo(() => {
        if (searchStatus.employee !== "ok") return undefined;
        return (
            <div className="pb-6">
                {totalCount} {lowerCase(t("coworker"))}
            </div>
        );
    }, [searchStatus.employee, totalCount, t]);
    return (
        <EmployeeSearchResult
            result={result}
            preview={preview}
            errorMessage={searchStatus.employee === "error" ? t("500ErrorText") : null}
            noData={searchStatus.employee === "no-data"}
            header={header}
        />
    );
}
