import { useCallback, useMemo, useState } from "react";
import { IDateTimePickerHookProps, IDateTimepickerProps } from "@medhelp/ui/Datepicker/types";
import { setArrayFromDateString, setDateStringFromArray } from "@medhelp/ui/Datepicker/utils/util";
import { useSingleDatepickerProps } from "hooks";
import _ from "lodash";

const useDateTimepickerProps = ({
    onChangeDateTime,
    givenSelectedDateTime,
    placeholder,
    noTimeSelected,
}: IDateTimePickerHookProps) => {
    const [selectedDateAsNumbers, setSelectedDateAsNumbers] = useState<number[]>(
        setArrayFromDateString(givenSelectedDateTime, undefined, noTimeSelected),
    );

    const validateDate = useCallback(
        (newDate: number[]) => {
            if (!newDate.includes(-1)) {
                const date = setDateStringFromArray(newDate, true);
                // Will only return a date if date is valid
                if (date) onChangeDateTime(date);
            } else onChangeDateTime(undefined);
        },
        [onChangeDateTime],
    );

    const handleDateChange = useCallback(
        (dateString: string | undefined) => {
            let newDate = setArrayFromDateString(dateString, selectedDateAsNumbers);
            setSelectedDateAsNumbers(newDate);
            validateDate(newDate);
        },
        [selectedDateAsNumbers, validateDate],
    );

    const handleHourMinuteChange = useCallback(
        (selectedValue: number, isValid: boolean, isHour = false) => {
            if (isValid) {
                let newTime = _.cloneDeep(selectedDateAsNumbers);
                isHour ? (newTime[3] = selectedValue) : (newTime[4] = selectedValue);
                setSelectedDateAsNumbers(newTime);
                validateDate(newTime);
            }
        },
        [selectedDateAsNumbers, validateDate],
    );
    const datepickerProps = useSingleDatepickerProps({
        onChangeDate: handleDateChange,
        givenSelectedDate: setDateStringFromArray(selectedDateAsNumbers),
        placeholder,
    });

    const dateTimepickerProps: IDateTimepickerProps = useMemo(
        () => ({
            datepickerProps,
            selectedDateAsNumbers,
            handleHourMinuteChange,
        }),
        [datepickerProps, handleHourMinuteChange, selectedDateAsNumbers],
    );
    return dateTimepickerProps;
};
export default useDateTimepickerProps;
