import ArchiveTag from "pages/FollowUp/components/ArchiveTag";

interface IProps {
    id: string;
    name: string;
    ssn?: React.ReactElement;
    companyName?: React.ReactElement;
    closestManager: React.ReactElement;
    children?: React.ReactNode;
    isArchived?: boolean;
}
const ContactInfromation = ({ id, ssn, name, companyName, closestManager, children, isArchived }: IProps) => (
    <div
        id={id}
        className="bg-primaryBg xs:bg-pageBg xl:p-0 p-5 mb-10"
        data-cy={id}
        data-testid="absence-report-header-container"
    >
        <div className="px-0">
            <div className="flex items-center gap-4 mb-1 xs:mb-6">
                <h2 data-dd-privacy="mask">{name}</h2>
                {isArchived && <ArchiveTag />}
            </div>
            <div className={`flex flex-col xxs:justify-center xs:justify-between lg:flex-row`}>
                <div className=" text-grey-700 xs:p-0 md:flex w-full justify-between" data-dd-privacy="mask">
                    <div className="flex flex-col xs:flex-row">
                        <div className="mr-12 mb-5">{ssn}</div>

                        <div className="mr-12 mb-5">{companyName}</div>
                        {closestManager}
                    </div>
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default ContactInfromation;
