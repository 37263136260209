import { useEffect } from "react";
import ErrorWidget from "../ErrorWidget";
import { useTranslation } from "react-i18next";
import { getUserContext } from "store/userSelectors";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { ListChart } from "pages/dashboard/components/Charts";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import LastActivatedRehabWidgetLoader from "./LastActivatedRehabWidgetLoader";
import { getLastActivatedRehab, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const LastActivatedRehabWidget = ({ id, type }: Widget) => {
    const { t } = useTranslation("dashboard");
    const dispatch = useAppDispatch();
    const userContext = useAppSelector(getUserContext);
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getLastActivatedRehab(id));

    useEffect(() => {
        !isDragging && dispatch(fetchWidgetData({ id, type }));
    }, [dispatch, id, type, isDragging]);

    if (status === Status.PENDING) {
        return <LastActivatedRehabWidgetLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <ListChart
            locale={userContext.user?.userPreferences?.language!}
            keyHeading={t("employee")}
            dataHeading={t("startDateWidget")}
            hasDate={true}
            hasModal={false}
            color={"secondary"}
            id={"last-activated-rehab"}
            data={
                data?.map((x) => ({
                    heading: x.employeeName || "",
                    data: x.initiationDate || "",
                })) ?? []
            }
        />
    );
};

export default LastActivatedRehabWidget;
