import * as React from "react";
import { setOverflow } from "../utils/setOverflow";

const useDetectOutsideClickModal = (
    element: React.RefObject<HTMLElement>,
    open: boolean,
    setOpen: (open: boolean) => void,
    keepOpen?: boolean,
): void => {
    React.useEffect(() => {
        let mounted = true;
        if (mounted) {
            const onKeyDown = (e: KeyboardEvent) => {
                if (keepOpen) {
                    return;
                }
                if (!keepOpen && e?.key && e.key === "Escape") {
                    setOpen(false);
                }
            };
            const useHandleClickOutside = (event: any) => {
                if (keepOpen) {
                    return;
                }
                if (!keepOpen && element.current && !element.current.contains(event.target)) {
                    setOpen(false);
                }
            };
            if (open && !keepOpen) {
                document.addEventListener("mousedown", useHandleClickOutside, true);
                document.addEventListener("keydown", onKeyDown, true);
                setOverflow("hidden");
            }

            return () => {
                document.removeEventListener("mousedown", useHandleClickOutside, false);
                document.removeEventListener("keydown", onKeyDown, false);
                setOverflow("auto");
            };
        }
        return () => {
            mounted = false;
        };
    }, [element, open, keepOpen, setOpen]);
};

export default useDetectOutsideClickModal;
