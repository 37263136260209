import { useAppSelector } from "store/hooks";
import { useCallback, useMemo } from "react";
import { get, isEmpty, lowerFirst } from "lodash/fp";
import { useAppDispatch } from "store/hooks";
import { useTranslation } from "react-i18next";
import LongtermRulesFilter from "./LongtermRulesFilter";
import { getNumberFromLongtermCodes as getRule, getExtraRule, findTagByReferenceKey } from "../../utils";
import { actions, selectors } from "../../redux";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const filters = useAppSelector(selectors.getSearchTagByIdSorted("codes", "asc"));

    const Header = useMemo(() => t("search.searchQuery.filters.accordionLongtermCodesRule"), [t]);
    const Tags = useMemo(() => {
        const getCheckedItems = filters.filter(get("checked"));
        if (isEmpty(getCheckedItems)) return "";
        return getCheckedItems
            .map((x) => `${t("longtermRules", { days: getRule(x) })} ${lowerFirst(t(getExtraRule(x) || ""))}`)
            .join(", ");
    }, [filters, t]);

    const Items = useMemo(() => {
        return filters.map((value) => ({
            ...value,
            label: `${t("longtermRules", {
                days: getRule(value),
            })} ${lowerFirst(t(getExtraRule(value) || ""))}`,
        }));
    }, [filters, t]);

    const onChange = useCallback(
        (value: string) => {
            const element = findTagByReferenceKey(value, filters);
            if (!element) return;
            dispatch(
                actions.updateSearchFilter({
                    ...element,
                    checked: !element.checked,
                }),
            );
        },
        [dispatch, filters],
    );
    return <LongtermRulesFilter items={Items} header={Header} onChange={onChange} tags={Tags} />;
}
