import { Card } from "@medhelp/ui";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import MobileSearchResult from "./MobileSearchResult";
import { selectors } from "../../redux";
import PaginatorTableFooter from "../PaginatorTableFooter";
import SearchAbsenceRow from "../SearchAbsenceRow";
import useRows from "./Row";
import Loader from "../Loader";

export default function Index() {
    const { t } = useTranslation("followup");
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const searchStatus = useAppSelector(selectors.getSearchStatus);
    const pageNumber = useAppSelector(selectors.getPaginatorPageNumber(currentSearch));
    const currentSearchPageNumber = useAppSelector(selectors.getSearchPageNumber(currentSearch));
    const mobileSearchProps = useAppSelector(
        selectors.getTableSearchProperties(currentSearch, currentSearchPageNumber, pageNumber, t, true),
    );
    const rows = useRows(mobileSearchProps, currentSearch);
    return (
        <Card className="px-0 p-0">
            <MobileSearchResult
                rows={rows}
                footer={<PaginatorTableFooter />}
                loading={Boolean(currentSearch && searchStatus[currentSearch] === "pending")}
                // loading
                placeholder={<Loader />}
                RowContainer={SearchAbsenceRow}
            />
        </Card>
    );
}
