import Icon from "components/Icon";
import { isValidElement, useMemo, useState } from "react";

export interface IProps {
    heading: string | JSX.Element;
    children: React.ReactNode;
    icon?: "caret" | "arrow" | "caretFull";
    iconSize?: number;
    setOpen?: (value: boolean) => void;
    open?: boolean;
}

const Accordion = ({
    heading,
    children,
    icon = "caret",
    iconSize = 12,
    open: openParent,
    setOpen: setOpenParent,
}: IProps) => {
    const [openSelf, setOpenSelf] = useState(false);
    const [open, setOpen] = useMemo(
        () => [openParent ?? openSelf, setOpenParent ?? setOpenSelf],
        [openParent, openSelf, setOpenParent],
    );
    const Header = useMemo(() => {
        if (isValidElement(heading)) {
            return heading;
        }
        return <p className="font-bold text-sm">{heading}</p>;
    }, [heading]);
    return (
        <div className="border-b select-none">
            <div
                className={`flex items-start justify-between cursor-pointer pl-6 pr-4 pt-5 ${open ? "pb-2" : "pb-5"}`}
                onClick={() => setOpen(!open)}
            >
                {Header}
                <div className="pointer-events-none">
                    {open ? <Icon icon={`${icon}Up`} size={iconSize} /> : <Icon icon={`${icon}Down`} size={iconSize} />}
                </div>
            </div>
            {open && (
                <div>
                    <div className="text-sm">{children}</div>
                </div>
            )}
        </div>
    );
};

export default Accordion;
