import {
    getCompanyGroup,
    getCompanyId,
    getCostCalculationStatus,
    getCostCalculations,
} from "pages/FollowUp/redux/followUpSelectors";
import { useEffect, useState } from "react";
import { Status } from "pages/FollowUp/redux";
import { useTranslation } from "react-i18next";
import { SpinnerWithOverlay } from "components/Spinner";
import { useAppDispatch, useAppSelector } from "store/hooks";
import AbsenceCostCalculationForm from "./AbsenceCostCalculationForm";
import { fetchCompanyGroup, fetchCostCalculation } from "pages/FollowUp/redux/followUpAsyncActions";

import DefinitionModal from "./DefinitonModal/DefinitionModal";

const AbsenceCostCalculation = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const companyId = useAppSelector(getCompanyId);
    const { data: companyGroup } = useAppSelector(getCompanyGroup);
    const { data: costCalculations } = useAppSelector(getCostCalculations);
    const costCalculationStatus = useAppSelector(getCostCalculationStatus);
    const [year, setYear] = useState(new Date().getFullYear());
    const [company, setCompany] = useState(companyId);
    const companyName = companyGroup?.find((item) => item.id === company)?.name;
    const [isOpen, setIsOpen] = useState(false);

    const currentYear = new Date().getFullYear();
    const yearOptions = [currentYear - 1, currentYear, currentYear + 1, currentYear + 2];

    useEffect(() => {
        setCompany(companyId);
        if (companyId) {
            dispatch(fetchCompanyGroup(companyId));
        }
    }, [dispatch, companyId]);

    useEffect(() => {
        if (company) {
            dispatch(fetchCostCalculation({ companyId: company, year: year }));
        }
    }, [dispatch, company, year]);

    return (
        <div className="px-4 xl:px-0">
            <div className="flex flex-col md:flex-row justify-between mb-12">
                <div className="flex flex-wrap items-center">
                    <h3>{t("absenceCostCalculation")}</h3>
                    <div className="ml-2">({companyName})</div>
                </div>
                <div className="flex items-center">
                    <div className="mr-2">{t("selectYear")}</div>
                    <select
                        className="hover:cursor-pointer border p-1"
                        onChange={(e) => setYear(Number(e.target.value))}
                        value={year}
                    >
                        {yearOptions.map((value) => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center">
                    <div className="mr-2">{t("selectCompany")}</div>
                    <select
                        className="hover:cursor-pointer border p-1"
                        onChange={(e) => setCompany(Number(e.target.value))}
                        value={company}
                    >
                        {companyGroup?.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {costCalculationStatus === Status.PENDING ? (
                <SpinnerWithOverlay />
            ) : (
                <div className="flex flex-wrap gap-y-3 justify-between">
                    {costCalculations.map((item) => (
                        <AbsenceCostCalculationForm
                            key={item.id}
                            costCalculation={item}
                            year={year}
                            setIsOpen={() => setIsOpen(true)}
                        />
                    ))}
                </div>
            )}
            {isOpen && <DefinitionModal isOpen={isOpen} setIsOpen={setIsOpen} />}
        </div>
    );
};

export default AbsenceCostCalculation;
