import { createSelector } from "@reduxjs/toolkit";
import { get, isEqual, flow, uniqBy, sortBy, reverse } from "lodash/fp";
import { RootState } from "store";
import { MedicalCertificateModals } from "./medicalCertificateSlice";
import * as Utils from "../utils";
import { isBeforeToday, isSameOrAfterToday } from "utils/date";

export const getFiles = (state: RootState) => state.medicalCertificate.file.pages;

export const getModal = (modal: MedicalCertificateModals) => (state: RootState) =>
    state.medicalCertificate.modals[modal];

export const getPendingDelete = (state: RootState) => state.medicalCertificate.pendingDelete;

export const getFileStatus = (state: RootState) => state.medicalCertificate.status;

export const medicalCertificateFileStatus = (state: RootState) => state.medicalCertificate.status;

export const getAddedFile = (state: RootState) => state.medicalCertificate.addedFile;

export const getCurrentCertificates = createSelector(getFiles, (files) =>
    uniqBy(
        get("groupKey"),
        files.filter(({ metaData }) => {
            const data = metaData?.find(flow(get("name"), isEqual("to")));
            if (!data) return false;
            return isSameOrAfterToday(data.value);
        }),
    ),
);

export const getCurrentCertificate = createSelector(
    getCurrentCertificates,
    (currentCertificates) => currentCertificates[0],
);

export const getCertificatesByGroupKey = (state: RootState) => (groupKey: string) =>
    createSelector(getFiles, (files) => files.filter(flow(get("groupKey"), isEqual(groupKey))))(state);

export const getHistoryCertificateFiles = createSelector(getFiles, (files) =>
    uniqBy(
        get("groupKey"),
        files.filter(({ metaData }) => {
            const data = metaData?.find(flow(get("name"), isEqual("to")));
            if (!data) return false;
            return isBeforeToday(data.value);
        }),
    ),
);

type CertificateType = "history" | "current";
export const getSortedCertificates = (type: CertificateType, sortValue: Utils.certificates.MetadataKeys) =>
    createSelector(getHistoryCertificateFiles, getCurrentCertificates, (historyCertificates, currentCertificates) => {
        const sorting =
            sortValue !== Utils.certificates.MetadataKeys.degree
                ? flow(sortBy(Utils.certificates.getSpecificMetadataValueFromCertificates(sortValue)), reverse)
                : sortBy(Utils.certificates.getSpecificMetadataValueFromCertificates(sortValue));
        switch (type) {
            case "history": {
                return sorting(historyCertificates);
            }
            case "current": {
                return sorting(currentCertificates);
            }
            default: {
                return sorting(historyCertificates);
            }
        }
    });
