import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ServiceSelect from "../ServiceSelect";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ServiceInputContainer } from "../ServiceInput";
import { ServiceAccordion, AccordionStringContent } from "../ServiceAccordion";
import { saveBillingConfiguration } from "pages/service/redux/serviceAsyncActions";
import { getBillingConfiguration, IBillingConfigurationStringified } from "pages/service/redux/serviceSelectors";

const translationKeys = {
    billingPeriodType: "billingPeriodTypeKey",
    groupType: "groupTypeKey",
    exportedContentLevel: "exportedContentLevelKey",
};

const BillingConfiguration = () => {
    const { t } = useTranslation("service");
    const billingConfiguration = useAppSelector(getBillingConfiguration);
    const { billingPeriodType, billingDayInMonth, groupType, groupedOnTopLevelDepartments, exportedContentLevel } =
        billingConfiguration;
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm({ defaultValues: billingConfiguration });

    useEffect(() => {
        reset(billingConfiguration);
    }, [billingConfiguration, reset]);

    const onSubmit = (data: IBillingConfigurationStringified) => {
        setIsLoading(true);
        dispatch(saveBillingConfiguration(data))
            .then(unwrapResult)
            .then(() => {
                setIsLoading(false);
                toast(t("saved"));
            })
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                reset(billingConfiguration);
                setIsLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("billingSpecification")}
                reset={() => reset(billingConfiguration)}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <div>
                        <AccordionStringContent
                            heading={t("interval")}
                            value={t(`${translationKeys.billingPeriodType}.${billingPeriodType}`)}
                        />
                        <AccordionStringContent heading={t("billingDayInMonth")} value={t(billingDayInMonth || "")} />
                        <AccordionStringContent
                            heading={t("invoicingPer")}
                            value={t(`${translationKeys.groupType}.${groupType}`)}
                        />
                        <AccordionStringContent
                            heading={t("groupedOnTopLevelDepartments")}
                            value={t(groupedOnTopLevelDepartments === "true" ? "true" : "false")}
                        />
                        <AccordionStringContent
                            heading={t("exportedContentLevel")}
                            value={t(`${translationKeys.exportedContentLevel}.${exportedContentLevel}`)}
                        />
                    </div>
                }
            >
                <ServiceInputContainer>
                    <ServiceSelect
                        label={t("interval")}
                        register={register("billingPeriodType")}
                        options={["0", "1", "2"].map((i) => ({
                            value: i,
                            label: t(`${translationKeys.billingPeriodType}.${i}`),
                        }))}
                    />
                    <ServiceSelect
                        label={t("billingDayInMonth")}
                        register={register("billingDayInMonth")}
                        options={Array.from({ length: 31 }).map((_, i) => ({
                            value: (i + 1).toString(),
                        }))}
                    />
                    <ServiceSelect
                        label={t("invoicingPer")}
                        register={register("groupType")}
                        options={["0", "1", "2"].map((i) => ({
                            value: i,
                            label: t(`${translationKeys.groupType}.${i}`),
                        }))}
                    />
                    <ServiceSelect
                        label={t("groupedOnTopLevelDepartments")}
                        register={register("groupedOnTopLevelDepartments")}
                        options={["true", "false"].map((i) => ({
                            value: i,
                            label: t(i),
                        }))}
                    />
                    <ServiceSelect
                        label={t("exportedContentLevel")}
                        register={register("exportedContentLevel")}
                        options={["0", "1", "2", "3", "4"].map((i) => ({
                            value: i,
                            label: t(`${translationKeys.exportedContentLevel}.${i}`),
                        }))}
                    />
                </ServiceInputContainer>
            </ServiceAccordion>
        </form>
    );
};
export default BillingConfiguration;
