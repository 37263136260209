import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { ServiceCheckboxContainer } from "../ServiceCheckbox";
import useSubmitDeviantRule from "pages/service/hooks/useSubmitDeviantRule";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { ServiceFieldArray, ServiceFieldArrayAppend } from "../ServiceFieldArray";
import { MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule } from "swagger/absencefollowup";
import { getDeviantAbsenceMaxLengthRecurring, getServiceSlice } from "pages/service/redux/serviceSelectors";

const DeviantAbsenceMaxLengthRecurring = () => {
    const { t } = useTranslation("service");
    const { companyId, customerId } = useAppSelector(getServiceSlice);
    const deviantAbsenceMaxLengthRecurring = useAppSelector(getDeviantAbsenceMaxLengthRecurring);
    const [submitDeviantRules, isLoading, itemsToDelete, setItemsToDelete] = useSubmitDeviantRule();

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isDirty },
    } = useForm({
        defaultValues: { deviantAbsenceMaxLengthRecurring },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "deviantAbsenceMaxLengthRecurring",
        // necessary because useFieldArray overwrites the id property
        keyName: "keyName",
    });

    useEffect(() => {
        reset({ deviantAbsenceMaxLengthRecurring });
    }, [deviantAbsenceMaxLengthRecurring, reset]);

    const onSubmit = (data: {
        deviantAbsenceMaxLengthRecurring: MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule[];
    }) => {
        submitDeviantRules(data.deviantAbsenceMaxLengthRecurring, () => reset({ deviantAbsenceMaxLengthRecurring }));
    };

    const {
        register: registerInput,
        getValues: getInputValues,
        resetField: resetInputField,
        formState: { isValid: inputIsValid },
    } = useForm({
        defaultValues: { value: undefined, interval: undefined },
    });

    const checkForDuplicateValues = (value?: string, interval?: string) =>
        getValues("deviantAbsenceMaxLengthRecurring").some((x) => x.value === value && x.interval === interval);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("subscriptionLongTermAbsence")}
                reset={() => reset({ deviantAbsenceMaxLengthRecurring })}
                disabled={!isDirty}
                isLoading={isLoading}
                content={fields.map(({ value, interval }) => (
                    <AccordionBooleanContent
                        key={value}
                        label={
                            t("afterDays", { count: Number(value) }) +
                            " " +
                            t("withDaysInbetween", { count: Number(interval) })
                        }
                    />
                ))}
            >
                <ServiceCheckboxContainer
                    heading={t("notificationInCaseOfLongTermAbsence")}
                    checkboxHeading={t("E-post/SMS")}
                >
                    {fields?.map(({ value, interval, id }, index) => (
                        <ServiceFieldArray
                            key={index}
                            index={index}
                            label={
                                t("afterDays", { count: Number(value) }) +
                                " " +
                                t("withDaysInbetween", { count: Number(interval) })
                            }
                            onClick={() => {
                                setItemsToDelete([...itemsToDelete, String(id)]);
                                remove(index);
                            }}
                        />
                    ))}
                    <ServiceFieldArrayAppend
                        buttonText={t("addNotification")}
                        onClick={() => {
                            append({
                                absenceType: "Sick",
                                companyId: companyId,
                                customerId: customerId,
                                ruleName: "AbsenceMaxLengthRecurring",
                                interval: getInputValues("interval"),
                                value: getInputValues("value"),
                            });
                            resetInputField("value");
                            resetInputField("interval");
                        }}
                        label={t("numberOfDays")}
                        secondaryLabel={t("daysInbetween")}
                        register={registerInput("value", {
                            required: true,
                            validate: () =>
                                !checkForDuplicateValues(getInputValues("value"), getInputValues("interval")),
                            min: 1,
                            max: 999,
                        })}
                        secondaryRegister={registerInput("interval", {
                            required: true,
                            validate: () =>
                                !checkForDuplicateValues(getInputValues("value"), getInputValues("interval")),
                            min: 0,
                            max: 999,
                        })}
                        disabled={!inputIsValid}
                        type="number"
                    />
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default DeviantAbsenceMaxLengthRecurring;
