import { useTranslation } from "react-i18next";
import { IContactInfoProps } from "pages/Administration/types";
import AddUserPhoneNumberComp from "../PhoneNumber";
import AdminInput from "../../AdminInput";

const ContactInfo = ({
    privatePhone,
    workPhone,
    homePhone,
    email,
    workEmailErrorMsg,
    onChange,
    onValidateEmail,
    onValidatePhone,
}: IContactInfoProps) => {
    const { t } = useTranslation("administration");

    return (
        <div className="border-b">
            <div className="p-10 flex flex-col">
                <h3 className="mb-10">{t("contactInfo")}</h3>
                <div className="flex flex-col">
                    <AddUserPhoneNumberComp
                        headingClassName="mb-3 text-lg"
                        heading={t("privateMobile")}
                        infoText={t("privateMobileInfo")}
                        value={privatePhone}
                        onChange={(v) => onChange("mobileNumber", v)}
                        validateFields
                        isValid={(v) => onValidatePhone("privatePhoneErrorMsg", v)}
                    />
                    <AddUserPhoneNumberComp
                        headingClassName="mb-3 text-lg"
                        heading={t("workMobile")}
                        infoText={t("workMobileInfo")}
                        value={workPhone}
                        onChange={(v) => onChange("secondaryMobileNumber", v)}
                        validateFields
                        isValid={(v) => onValidatePhone("workPhoneErrorMsg", v)}
                    />
                    <AddUserPhoneNumberComp
                        headingClassName="mb-3 text-lg"
                        heading={t("homePhone")}
                        infoText={t("homePhoneInfo")}
                        value={homePhone}
                        onChange={(v) => onChange("telephoneNumber", v)}
                    />

                    <AdminInput
                        heading={t("workEmail")}
                        infoText={t("workEmailInfo")}
                        className="mr-4"
                        savedValue={email}
                        onChange={(v) => {
                            onChange("email", v);
                            onValidateEmail("workEmailErrorMsg", v);
                        }}
                        errorMessage={workEmailErrorMsg}
                    />
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
