import { lowerCase } from "lodash/fp";
import { TFunction } from "i18next";
import CountryCodes from "./CountryCodes.json";

export interface IPhonePrefix {
    code: string;
    name: string;
    dial_code: string;
    hidePlaceholder?: boolean;
    isPlaceholder?: boolean;
    hideCountryCode?: boolean;
}
/**
 *
 * @param search
 * @returns all values which contain any
 * letters from the search query compare
 * with `code (se,..etc)`, `name (Sweden, ...etc)`, `dial_code (+46..etc)`
 */
export const filterCorrectPhonePrefix = (search: string) => (item: IPhonePrefix) =>
    lowerCase(item.name).includes(lowerCase(search)) ||
    item.dial_code.includes(search) ||
    lowerCase(item.code).includes(lowerCase(search));

export const getCustomCountryList = (t: TFunction): IPhonePrefix[] => [
    {
        name: t("denmark"),
        dial_code: "+45",
        code: "DK",
        hideCountryCode: true,
    },
    {
        name: t("sweden"),
        dial_code: "+46",
        code: "SE",
        hideCountryCode: true,
    },
    {
        name: t("other"),
        dial_code: "+",
        code: "",
        hideCountryCode: true,
    },
];

export const isDialCode = (value: string): boolean => CountryCodes.some((code) => code.dial_code === value);
