import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchCaseActivityList as fetchCaseActivityListAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchCaseActivityList = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchCaseActivityListAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                action.payload.data.map((x) => {
                    const matchedActivity = currentHealthCase.healthCaseActivities.find(({ id }) => id === x.id);
                    if (matchedActivity) {
                        return;
                    } else {
                        currentHealthCase.healthCaseActivities.push({
                            ...x,
                            status: Status.OK,
                        });
                    }
                });
                currentHealthCase.healthCaseSubStatus.activities = Status.OK;
            }
        })
        .addCase(fetchCaseActivityListAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find((healthCase) => healthCase.id === action.meta.arg);
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.activities = Status.PENDING;
        })
        .addCase(fetchCaseActivityListAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find((healthCase) => healthCase.id === action.meta.arg);
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.activities = Status.ERROR;
        });

export default fetchCaseActivityList;
