import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { selectors } from "../../redux";
import { getColumns, selectReportSearchColumns } from "../../utils";
import PaginatorTableFooter from "../PaginatorTableFooter";
import Loader from "../Loader";
import SearchTableHeader from "../SearchTableHeader";
import SearchAbsenceRow from "../SearchAbsenceRow";
import useRows from "./Rows";
import DesktopSearchResult from "./DesktopSearchResult";

export default function Index() {
    const { t } = useTranslation("followup");
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const searchStatus = useAppSelector(selectors.getSearchStatus);
    const pageNumber = useAppSelector(selectors.getPaginatorPageNumber(currentSearch));
    const currentSearchPageNumber = useAppSelector(selectors.getSearchPageNumber(currentSearch));

    const tableProps = useAppSelector(
        selectors.getTableSearchProperties(currentSearch, currentSearchPageNumber, pageNumber, t),
    );
    const Rows = useRows(tableProps, currentSearch);

    const ColumnData = useMemo(
        () => ({
            employee: {
                text: t("search.tableHeaders.employee"),
            },
            type: {
                text: t("search.tableHeaders.type"),
            },
            reimbursableType: {
                text: t("reimbursableType"),
            },
            endStartDate: {
                text:
                    currentSearch === "ongoingAbsence"
                        ? t("search.tableHeaders.startAndEndDateOngoing")
                        : t("search.tableHeaders.startAndEndDate"),
            },
            deviantFrom: {
                text:
                    currentSearch === "medicalCertificate" ? t("validFromText") : t("search.tableHeaders.deviantFrom"),
            },
            deviantTo: {
                text: currentSearch === "medicalCertificate" ? t("validTo") : t("search.tableHeaders.deviantTo"),
            },
            degrees: {
                text: t("search.tableHeaders.degree"),
            },
            numberOfDays: {
                text: t("search.tableHeaders.numberOfDays"),
            },
            affectedDepartments: {
                text: t("search.tableHeaders.department"),
                onClick: undefined,
            },
            caseType: {
                text: t("caseType"),
            },
            start: {
                text: currentSearch === "cases" ? t("startDate") : t("date"),
            },
            caseManager: {
                text: t("caseManager"),
            },
            caseStatus: {
                text: t("status"),
            },
            activity: {
                text: t("activity"),
            },
        }),
        [currentSearch, t],
    );

    const SelectedColumns = useMemo(
        () => selectReportSearchColumns(getColumns(currentSearch)).map((x) => ColumnData[x]),
        [ColumnData, currentSearch],
    );
    const Columns = useMemo(
        () => SelectedColumns.map(({ text }) => <SearchTableHeader text={text} />, []),
        [SelectedColumns],
    );

    const FirstElementWidth = useMemo(() => {
        switch (currentSearch) {
            default: {
                return undefined;
            }
        }
    }, [currentSearch]);
    return (
        <DesktopSearchResult
            rows={Rows}
            footer={<PaginatorTableFooter />}
            columns={Columns}
            loading={Boolean(currentSearch && searchStatus[currentSearch] === "pending")}
            // loading
            placeholder={<Loader />}
            firstElementWidth={FirstElementWidth}
            RowContainer={SearchAbsenceRow}
        />
    );
}
