import { useRef } from "react";
import { DayProps, useDayRender } from "utils/date";
import { getDayButtonCss } from "./utils/util";

interface ICustomDayProps extends DayProps {
    isDoubledatePicker?: boolean;
}

const CustomDay = ({ date, displayMonth, isDoubledatePicker }: ICustomDayProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dayRender = useDayRender(date, displayMonth, buttonRef);
    if (dayRender.isHidden) return <></>;
    if (!dayRender.isButton) <div {...dayRender.divProps} />;
    let dayCss = dayRender.buttonProps.className ?? "";
    dayCss = dayCss + getDayButtonCss(dayRender.activeModifiers, isDoubledatePicker);
    return (
        <div
            id="day-container"
            className={`my-0.5 
            `}
        >
            <button {...dayRender.buttonProps} className={dayCss} data-testid="datepicker-day" />
        </div>
    );
};

export default CustomDay;
