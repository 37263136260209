import { IDynamicData, Tag } from "./types";
import { reduce, isEmpty } from "lodash/fp";
import { Traverser } from "domain/ogranization";

export const selectCheckedItemsLabel: (data: IDynamicData[], labels: Tag[]) => Tag[] = (data, labels) =>
    reduce(
        (prev: Tag[], current: IDynamicData) => {
            if (current.checked) {
                const newArray: Tag[] = [
                    ...prev,
                    {
                        label: current.label,
                        referenceKey: current.referenceKey,
                        selectable: current.selectable,
                    },
                ];
                return current.children ? selectCheckedItemsLabel(current.children, newArray) : newArray;
            } else if (current.children) {
                return selectCheckedItemsLabel(current.children, prev);
            }
            return prev;
        },
        labels,
        data,
    );

export const searchList: (search: string, data: IDynamicData[]) => IDynamicData[] = (search, data) =>
    Traverser.mapChildren(
        (x: IDynamicData) => {
            if (x.match) return x;
            return {
                ...x,
                showChildren: true,
                show: true,
            };
        },
        Traverser.findAncestorsWithChildrenAndMatch((x) => x.label.toLowerCase().includes(search.toLowerCase()), data),
    );

export const showData = (data: IDynamicData) => {
    return data.show ?? true;
};
export const showDataWithSearch = (searchParams: string, data: IDynamicData[]) => {
    if (!isEmpty(searchParams)) {
        const value = searchList(searchParams, data).filter(showData);
        return value;
    }

    return data.filter(showData);
};

export const hasOnlyOneSelected: (data: IDynamicData[]) => boolean = (data) =>
    Boolean(Traverser.findAllByProperty("checked", data)?.length === 1);
