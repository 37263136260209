import Box from "./Box";
import Files from "./Files";
import Button from "./Button";
import { useState } from "react";
import { getLocalTime } from "utils/date";
import StatusMessage from "./StatusMessage";
import ImportSummary from "./ImportSummary";
import { useTranslation } from "react-i18next";
import DifferenceTable from "./DifferenceTable";
import { EventStateType, calculateStatus } from "../utils";
import {
    useGetQueueQueuedQuery,
    FileImportConfiguration,
    usePostQueueApproveMutation,
} from "store/rtk-apis/fileImport/fileImportApi";

const UpcomingImport = ({ customerKey, maxAllowedChanges }: FileImportConfiguration) => {
    const { t } = useTranslation("fileImport");
    const [approve] = usePostQueueApproveMutation();

    const [isApproving, setIsApproving] = useState(false);

    // TODO Implement condifitonal polling (only poll if processing)
    const { data, isLoading, isError, refetch } = useGetQueueQueuedQuery(
        { customerKey },
        {
            pollingInterval: 60000,
        },
    );

    if (isLoading) return <></>;
    if (isError) return <></>;
    if (!data) return <Box className="text-center">{t("noUploadedFiles")}</Box>;

    const { id, startDate, importId, eventState, requiresApproval, approvedByUserAccountId } = data;

    const status = calculateStatus(eventState as EventStateType);

    const handleApprove = async () => {
        setIsApproving(true);
        try {
            await approve({ customerKey, id }).unwrap();
            setTimeout(() => refetch(), 3000);
        } catch (error) {
            console.error("rejected", error);
        } finally {
            setIsApproving(false);
        }
    };

    // show approve message if import is queued and requires approval and not yet approved
    const showApproveMessage = status == "queued" && requiresApproval && !approvedByUserAccountId;

    return (
        <>
            <ImportSummary
                customerKey={customerKey}
                importId={importId}
                status={status}
                importStart={startDate ? getLocalTime(startDate) : "-"}
            >
                <>
                    {status === "failed" && (
                        <StatusMessage
                            title={t("statusMessages.failed.title")}
                            text={t("statusMessages.failed.text")}
                            icon="xCircle"
                        />
                    )}
                    {showApproveMessage && (
                        <StatusMessage
                            title={t("statusMessages.paused.title")}
                            text={t("statusMessages.paused.text", { maxAllowedChanges })}
                            icon="pauseCircle"
                            button={<Button disabled={isApproving} text={t("approve")} onClick={handleApprove} />}
                        />
                    )}
                    {importId && (
                        <DifferenceTable
                            customerKey={customerKey}
                            importId={importId}
                            maxAllowedChanges={maxAllowedChanges}
                        />
                    )}
                </>
            </ImportSummary>

            {importId && <Files customerKey={customerKey} importId={importId} />}
        </>
    );
};

export default UpcomingImport;
