import Classification from "pages/service/components/Classification";
import CompanyInformation from "pages/service/components/CompanyInformation";
import EmploymentGroups from "pages/service/components/EmploymentGroups";
import LoginConfiguration from "pages/service/components/LoginConfiguration";

const GeneralTab = () => (
    <>
        <CompanyInformation />
        <Classification />
        <EmploymentGroups />
        <LoginConfiguration />
    </>
);

export default GeneralTab;
