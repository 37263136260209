import Icon from "components/Icon";
import { useTranslation } from "react-i18next";
import { useGetMergeResultQuery } from "store/rtk-apis/fileImport/fileImportApi";

interface DifferenceTableProps {
    customerKey: number;
    importId: string;
    maxAllowedChanges?: number;
}

const DifferenceTable = ({ customerKey, importId, maxAllowedChanges }: DifferenceTableProps) => {
    const { t } = useTranslation("fileImport");
    const {
        data: departments,
        error: departmentsError,
        isLoading: departmentsIsLoading,
    } = useGetMergeResultQuery({
        importId,
        customerKey,
        mergeType: "departments",
    });
    const {
        data: employees,
        error: employeesError,
        isLoading: employeesIsLoading,
    } = useGetMergeResultQuery({ importId, customerKey, mergeType: "employments" });

    if (departmentsError || employeesError) return <></>;

    if (departmentsIsLoading || employeesIsLoading) return <></>;

    const tableHeaders = [t("file"), t("added"), t("changed"), t("deleted")];

    const tableData = [
        {
            File: t("organizationFile"),
            Added: departments?.numNewInPercent,
            Changed: departments?.numUpdatedInPercent,
            Deleted: departments?.numDeletedInPercent,
        },
        {
            File: t("employeeFile"),
            Added: employees?.numNewInPercent,
            Changed: employees?.numUpdatedInPercent,
            Deleted: employees?.numDeletedInPercent,
        },
    ];

    return (
        <div className="p-6 bg-gray-50">
            <div className="pb-6 font-bold">{t("differencesFromLastSuccessfulImport")}</div>
            <table className="w-full text-sm">
                <thead>
                    <tr>
                        {tableHeaders.map((header, index) => (
                            <th key={index} className="text-left text-gray-700 font-normal">
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((row, index) => (
                        <tr key={index}>
                            {Object.values(row).map((cell, index) => (
                                <td className="font-bold" key={index}>
                                    <div className="flex items-center gap-2">
                                        {cell}
                                        {index > 0 && "%"}
                                        {maxAllowedChanges && Number(cell) > maxAllowedChanges && (
                                            <Icon icon="warningCircle" size={16} color="red" />
                                        )}
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DifferenceTable;
