import { IDynamicComponent } from "./IDynamicComponent";
import { DateTimepicker } from "@medhelp/ui";
import { IDateTimepickerProps } from "@medhelp/ui/Datepicker/types";

const DynamicDatetime = (
    props: IDynamicComponent<string | undefined, "date"> & {
        children?: React.ReactNode;
    },
) => {
    const { children, ...rest } = props;
    const dateTimepickerProps = { ...rest } as IDateTimepickerProps;
    return (
        <div data-testid="dynamic-datetime">
            <DateTimepicker {...dateTimepickerProps} />
            {children}
        </div>
    );
};
export default DynamicDatetime;
