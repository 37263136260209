import { FiltersInViewType } from "../../utils";
import AbsenceDaysFilter from "../AbsenceDaysFilter";
import AbsenceTypeFilter from "../AbsenceTypeFilter";
import WorkRelatedFilter from "../WorkRelatedFIlter";
import PeriodFilter from "../PeriodFilter";
import AbsenceStatusFilter from "../AbsenceStatusFilter";
import EmploymentGroupsFilter from "../EmploymentGroupsFilter";
import NumberOfAbsenceFilter from "../NumberOfAbsenceFilter";
import SortingAndShowFilter from "../SortingAndShowFilter";
import PeriodStatusFilter from "../PeriodStatusFilter";
import ReimbursibleTypeFilter from "../ReimbursibleTypeFilter";
import LongtermRulesFilter from "../LongtermRulesFilter";
import StatusFilter from "../CaseStatusFilter";
import CauseFilter from "../CauseFilter";
import CaseTypeFilter from "../CaseTypeFilter";
import ActivityStatusFilter from "../ActivityStatusFilter";

interface IProps extends FiltersInViewType {}

const AllFilters = ({
    from,
    to,
    maxDays,
    minDays,
    occurrences,
    absenceStatus,
    absenceType,
    workRelated,
    employmentGroups,
    sortingFilter,
    absenceImpact,
    reimbursableType,
    codes,
    causes,
    caseStatus,
    caseType,
    activityStatus,
}: IProps) => {
    return (
        <>
            {(from || to) && <PeriodFilter />}
            {absenceType && <AbsenceTypeFilter />}
            {workRelated && <WorkRelatedFilter />}
            {absenceStatus && <AbsenceStatusFilter />}
            {(maxDays || minDays) && <AbsenceDaysFilter />}
            {occurrences && <NumberOfAbsenceFilter />}
            {employmentGroups && <EmploymentGroupsFilter />}
            {codes && <LongtermRulesFilter />}
            {absenceImpact && <PeriodStatusFilter />}
            {reimbursableType && <ReimbursibleTypeFilter />}
            {sortingFilter && <SortingAndShowFilter />}
            {causes && <CauseFilter />}
            {caseStatus && <StatusFilter />}
            {caseType && <CaseTypeFilter />}
            {activityStatus && <ActivityStatusFilter />}
        </>
    );
};
export default AllFilters;
