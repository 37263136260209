import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { thunkActions } from "pages/FollowUp/redux";
import { getHealthCase } from "../../../../redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { CaseContentContainer } from "../../../../components/HealthCaseManagement";
import CaseLogContent from "./CaseLogContent";

interface IProps {
    healthCase?: IHealthCase;
}

const CaseLog = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const { routeCaseId } = useParams();
    const dispatch = useAppDispatch();
    const selectedCase = useAppSelector(getHealthCase(routeCaseId ?? ""));
    const currentCase = healthCase ? healthCase : selectedCase;

    useEffect(() => {
        if (currentCase?.employmentId && currentCase?.id)
            dispatch(
                thunkActions.fetchCaseNotifications({
                    employmentId: currentCase?.employmentId,
                    healthCaseId: currentCase?.id,
                }),
            );
    }, [currentCase?.employmentId, currentCase?.id, dispatch]);

    return currentCase ? (
        <CaseContentContainer heading={t("caseLog")} text={t("logInfo")} healthCase={currentCase}>
            <CaseLogContent healthCase={currentCase} />
        </CaseContentContainer>
    ) : null;
};

export default CaseLog;
