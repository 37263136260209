const Spinner = () => (
    <div className="flex h-full">
        <div className="lds-ring m-auto">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default Spinner;
