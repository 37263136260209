import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { NotificationConfigurationModel } from "swagger/businessupport";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import { getNotificationConfiguration } from "pages/service/redux/serviceSelectors";
import { saveNotificationConfiguration } from "pages/service/redux/serviceAsyncActions";

const NotificationConfiguration = () => {
    const { t } = useTranslation("service");
    const notificationConfiguration = useAppSelector(getNotificationConfiguration);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const {
        handleSubmit,
        reset,
        register,
        formState: { isDirty },
    } = useForm({
        defaultValues: notificationConfiguration,
    });

    useEffect(() => {
        reset(notificationConfiguration);
    }, [notificationConfiguration, reset]);

    const onSubmit = (data: NotificationConfigurationModel) => {
        setIsLoading(true);
        dispatch(saveNotificationConfiguration(data))
            .then(unwrapResult)
            .then(() => {
                setIsLoading(false);
                toast(t("saved"));
            })
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                setIsLoading(false);
                reset(notificationConfiguration);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("reminders")}
                reset={() => reset(notificationConfiguration)}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <>
                        {notificationConfiguration?.handleMedicalReminder && (
                            <AccordionBooleanContent label={t("medicalCerificateReminder")} />
                        )}

                        {notificationConfiguration?.handleEstimatedBackAtWork && (
                            <AccordionBooleanContent label={t("expectedBackTomorrowReminder")} />
                        )}
                    </>
                }
            >
                <ServiceCheckboxContainer heading={t("reminders")} checkboxHeading="SMS">
                    <ServiceCheckboxRow
                        label={t("medicalCerificateReminder")}
                        register={register("handleMedicalReminder")}
                    />
                    <ServiceCheckboxRow
                        label={t("expectedBackTomorrowReminder")}
                        register={register("handleEstimatedBackAtWork")}
                    />
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default NotificationConfiguration;
