import { isEmpty } from "lodash/fp";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import { useAppSelector } from "store/hooks";
import { actions, selectors } from "../../redux";
import NumberOfAbsenceFilter, { IItems } from "./NumberOfAbsenceFilter";

export default function Index() {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const occurrences = useAppSelector(selectors.getSearchNumberById("occurrences"));

    const setValue = useCallback(
        (number: string) => {
            dispatch(
                actions.updateSearchFilter({
                    value: isEmpty(number) ? null : parseInt(number, 10),
                    id: "occurrences",
                    referenceKey: "occurrences",
                }),
            );
        },
        [dispatch],
    );
    const items: IItems[] = useMemo(
        () => [
            {
                value: occurrences?.value?.toString() || "",
                setValue,
                label: `${t("numberOfAbsenceFilter.numberOfAbsence")} (min)`,
                name: "days-filter",
                className: "w-full",
            },
        ],
        [occurrences?.value, setValue, t],
    );
    const Header = useMemo(() => t("numberOfAbsenceFilter.numberOfAbsence"), [t]);
    const Body = useMemo(() => {
        return occurrences?.value ? `min ${occurrences?.value?.toString()}` : "";
    }, [occurrences?.value]);

    return <NumberOfAbsenceFilter header={Header} body={Body} items={items} />;
}
