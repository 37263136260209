/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getFiles } from "../medicalCertificateAsyncActions";
import { IMedicalCertificateSlice, FileStatus } from "../medicalCertificateSlice";

const loadBuilder = (builder: ActionReducerMapBuilder<IMedicalCertificateSlice>) =>
    builder
        .addCase(getFiles.fulfilled, (state, action) => {
            if (action.payload.page) {
                state.file.pages = action.payload.page;
            }
            state.status = FileStatus.ok;
        })
        .addCase(getFiles.pending, (state) => {
            state.status = FileStatus.pending;
        })
        .addCase(getFiles.rejected, (state) => {
            state.status = FileStatus.errorLoading;
        });

export default loadBuilder;
