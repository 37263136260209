import { ContactInformation, PasswordChange, UserPreferences } from "swagger/usercontextservice";

export enum UserSettingsComponents {
    DeleteHistoricalAbsenceCauses,
    Language,
    ChangePassword,
    ContactInformation,
    default,
}

export interface IUpdateComponent {
    componentToUpdate: UserSettingsComponents;
    pwdCompValues?: PasswordChange;
    language?: UserPreferences;
    contactCompValues?: ContactInformation;
}

export interface IUserSettingsPageProps {
    hasUsernamePwdLogin: boolean;
    isDkUser: boolean;
    isCustomerSupport: boolean;
}

export interface IUpdateContactInfo {
    newValue: string;
    propToUpdate: keyof IContactInfoInputValues;
}

export interface IContactInfoInputValues {
    workNumber: string;
    workEmail: string;
    privateMobile: string;
    privatePhone: string;
}

export interface IContactInfoProps {
    contactInfoInputValues: IContactInfoInputValues;
    setWorkNumber: (workNumber: string) => void;
    setWorkEmail: (workEmail: string) => void;
    setPrivateMobile: (privateMobile: string) => void;
    setPrivatePhone: (privatePhone: string) => void;
    handleSaveClicked: () => void;
    isLoading: boolean;
}

export interface IUserSettingsProps {
    userSettingPageProps: IUserSettingsPageProps;
    status: string | null;
    handleUpdateComponent: (updateComponent: IUpdateComponent) => void;
    userPreferences: UserPreferences;
    setContactInfoProps: IContactInfoProps;
    isLoading: boolean;
}
