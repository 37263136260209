import { Outlet } from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute";
import ServiceTopLinks from "./components/ServiceTopLinks";
import { AccessRightsCustomerSupport } from "domain/accessRights";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getActivePowerbarSearch } from "store/searchSelectors";
import { actions } from "./redux";
import AddCustomerCompany from "./components/AddCustomerCompany";

const ServicePage = () => {
    const activePowerBarSeach = useAppSelector(getActivePowerbarSearch);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(actions.resetSelectedCompany());
    }, [activePowerBarSeach, dispatch]);

    return (
        <ProtectedRoute accessRightData={AccessRightsCustomerSupport}>
            <>
                <div className="flex justify-between mb-8 mt-6 px-5 xl:px-0 xl:mt-0 max-xl:hidden">
                    <h1>Service</h1>
                    <AddCustomerCompany />
                </div>
                <div className="flex flex-col justify-center items-center">
                    <div className="w-full mb-2">
                        <ServiceTopLinks />
                    </div>
                    <br />
                    <div className="flex xl:hidden dashboard-width ">
                        <AddCustomerCompany />
                    </div>
                </div>

                <Outlet />
            </>
        </ProtectedRoute>
    );
};

export default ServicePage;
