import React from "react";
import { TailSpin } from "react-loader-spinner";
import { twMerge } from "tailwind-merge";

interface IButtonProps
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    id: string;
    text?: string;
    leftIcon?: React.ReactElement;
    rightIcon?: React.ReactElement;
    children?: React.ReactNode;
    isLoading?: boolean;
    dataTestid?: string;
}

const SecondaryButton = (props: IButtonProps) => {
    const {
        text,
        id,
        className,
        disabled = false,
        onClick,
        leftIcon,
        rightIcon,
        children,
        isLoading,
        dataTestid,
        ...rest
    } = props;
    return (
        <button
            type="button"
            id={id}
            data-testid={dataTestid}
            disabled={disabled || isLoading}
            className={twMerge(`h-12 px-8 items-center text-base font-bold break-words rounded-none bg-secondaryButtonPrimary
      border flex text-secondaryButtonText border-secondaryButtonText hover:bg-secondaryButtonSecondary active:bg-secondaryButtonSecondary focus:outline-none focus:ring-1 focus:ring-secondaryButtonText
      ${disabled || isLoading ? `cursor-not-allowed bg-secondaryButtonDisabled text-secondaryButtonDisabledText` : ""}
      ${className}`)}
            onClick={(e) => {
                onClick!(e);
            }}
            onDoubleClick={() => console.log("not implemented")}
            onDoubleClickCapture={() => console.log("not implemented")}
            {...rest}
        >
            {leftIcon && <div className={`mr-2.5 flex items-center `}>{leftIcon}</div>}
            {children ? children : text}
            {rightIcon && <div className={`ml-2.5 flex items-center `}>{rightIcon}</div>}
            {isLoading && (
                <div className="ml-4 flex">
                    <div className="absolute top-0">
                        <TailSpin color="#3b3b36" height={20} width={20} />
                    </div>
                </div>
            )}
        </button>
    );
};
export default SecondaryButton;
