import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getLoginConfiguration } from "pages/service/redux/serviceSelectors";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { saveLoginConfiguration } from "pages/service/redux/serviceAsyncActions";
import { ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import { LoginConfiguration as LoginConfigurationModel } from "swagger/businessupport";

const LoginConfiguration = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const loginConfiguration = useAppSelector(getLoginConfiguration);
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        reset,
        register,
        formState: { isDirty },
    } = useForm({
        defaultValues: loginConfiguration,
    });

    useEffect(() => {
        reset(loginConfiguration);
    }, [loginConfiguration, reset]);

    const onSubmit = (data: LoginConfigurationModel) => {
        setIsLoading(true);
        dispatch(saveLoginConfiguration(data))
            .then(unwrapResult)
            .then(() => {
                setIsLoading(false);
                toast(t("saved"));
            })
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                reset(loginConfiguration);
                setIsLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("loginOptions")}
                reset={() => reset(loginConfiguration)}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <>
                        <AccordionBooleanContent label={t("bankId")} />
                        <AccordionBooleanContent label={t("oneTimePassword")} />
                        {loginConfiguration?.office365 && <AccordionBooleanContent label={t("office365")} />}
                        {loginConfiguration?.usernameFromSsn && (
                            <AccordionBooleanContent label={t("usernameFromSsn")} />
                        )}
                        {loginConfiguration?.usernameFromEmail && (
                            <AccordionBooleanContent label={t("usernameFromEmail")} />
                        )}
                    </>
                }
            >
                <ServiceCheckboxContainer heading={t("loginOptions")} checkboxHeading={t("offOn")}>
                    <ServiceCheckboxRow label={t("bankId")} checked disabled />
                    <ServiceCheckboxRow label={t("oneTimePassword")} checked disabled />
                    <ServiceCheckboxRow label={t("office365")} register={register("office365")} />
                    <ServiceCheckboxRow label={t("usernameFromSsn")} register={register("usernameFromSsn")} />
                    <ServiceCheckboxRow label={t("usernameFromEmail")} register={register("usernameFromEmail")} />
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default LoginConfiguration;
