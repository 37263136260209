import { ILoginAccordionChildProps, ILoginInputSharedProps } from "pages/Administration/types";
import AdminTabs from "../AdminTabs";
import InputControl from "../LoginOptions/InputControl";
import PhoneNumberControl from "../LoginOptions/PhoneNumberControl";
import { useCallback } from "react";
import { getNumberWithoutDash } from "utils/phone";

const AdminAccordionChild = ({
    credentialType,
    inputControls,
    activeTab,
    handleInputOnChange,
    handleButtonClick,
}: ILoginAccordionChildProps) => {
    const isOneTimePass = credentialType === 6;
    const inputs: ILoginInputSharedProps[] = inputControls.filter(
        (c) => c.loginCredential.credentialType === credentialType,
    );

    const handleSavePhoneNumber = useCallback(
        (input: ILoginInputSharedProps) => {
            const newInput: ILoginInputSharedProps = {
                ...input,
                inputValue: getNumberWithoutDash(input.inputValue),
            };
            handleButtonClick("save", newInput);
        },
        [handleButtonClick],
    );

    const getInputControls = (inputControls: ILoginInputSharedProps[]) =>
        inputControls.map((inputControl, i) => (
            <InputControl
                key={inputControl.loginCredential.id ?? 1 + i}
                input={inputControl}
                activeTab={activeTab}
                handleInputOnChange={(v) => handleInputOnChange(inputControl, v)}
                handleButtonClick={(v) => handleButtonClick(v, inputControl)}
            />
        ));

    return (
        <div id="accordion-child">
            {!isOneTimePass && getInputControls(inputs)}
            {isOneTimePass && (
                <AdminTabs
                    tabs={[
                        {
                            tabName: "eMail",
                            tabContent: getInputControls(
                                inputs.filter((c) => c.inputValue.includes("@") || c.loginCredential.id === 0),
                            ),
                        },
                        {
                            tabName: "SMS",
                            tabContent: inputs.map((c, i) => {
                                return (
                                    !c.inputValue.includes("@") && (
                                        <PhoneNumberControl
                                            key={c.loginCredential.id ?? 1 + i}
                                            input={c}
                                            handleInputOnChange={(v) => handleInputOnChange(c, v)}
                                            handleButtonClick={(v) => {
                                                v === "save" ? handleSavePhoneNumber(c) : handleButtonClick(v, c);
                                            }}
                                        />
                                    )
                                );
                            }),
                        },
                    ]}
                    activeTab={activeTab ?? "eMail"}
                    setActiveTab={(v) => handleButtonClick(v === "eMail" ? "eMail" : "SMS")}
                />
            )}
        </div>
    );
};

export default AdminAccordionChild;
