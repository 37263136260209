import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect, useMemo, useState } from "react";
import { ServiceContractModel } from "swagger/businessupport";
import { ServiceInput, ServiceInputContainer } from "../ServiceInput";
import { ServiceAccordion, AccordionStringContent } from "../ServiceAccordion";
import {
    saveServiceContract,
    saveConfigurationField,
    deleteConfigurationField,
    updateConfigurationField,
} from "pages/service/redux/serviceAsyncActions";
import {
    getNursePhoneNumber,
    getNursePhoneNumberId,
    getAbsenceHandlingServiceContract,
} from "pages/service/redux/serviceSelectors";

interface IAdvisoryServicePhoneNumber {
    serviceContract?: ServiceContractModel;
    nursePhoneNumber: string;
}

const AdvisoryServicePhoneNumber = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const nursePhoneNumber = useAppSelector(getNursePhoneNumber);
    const nursePhoneNumberId = useAppSelector(getNursePhoneNumberId);
    const numbers = useAppSelector(getAbsenceHandlingServiceContract);

    const [isLoading, setIsLoading] = useState(false);

    const defaultValues: IAdvisoryServicePhoneNumber = useMemo(
        () => ({ serviceContract: numbers, nursePhoneNumber }),
        [numbers, nursePhoneNumber],
    );

    const {
        reset,
        register,
        resetField,
        handleSubmit,
        formState: { isDirty, dirtyFields },
    } = useForm({ defaultValues });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const onSubmit = (data: IAdvisoryServicePhoneNumber) => {
        setIsLoading(true);
        const promises = [];
        if (dirtyFields.serviceContract && data.serviceContract) {
            promises.push(
                dispatch(saveServiceContract(data.serviceContract))
                    .then(unwrapResult)
                    .then(() => {
                        toast(t("saved"));
                    })
                    .catch((e) => {
                        toast(t("error") + ": " + e.message, { type: "error" });
                        resetField("serviceContract");
                    }),
            );
        }
        if (dirtyFields.nursePhoneNumber) {
            if (data.nursePhoneNumber === "" && nursePhoneNumberId) {
                promises.push(dispatch(deleteConfigurationField(nursePhoneNumberId)));
            } else {
                promises.push(
                    nursePhoneNumberId
                        ? dispatch(
                              updateConfigurationField({
                                  fieldName: "nursePhoneNumber",
                                  fieldValue: data.nursePhoneNumber,
                              }),
                          )
                              .then(unwrapResult)
                              .then(() => {
                                  toast(t(t("nrOnWeb") + " " + t("updated")));
                              })
                              .catch((e) => {
                                  toast(t("error") + ": " + e.message, { type: "error" });
                                  resetField("nursePhoneNumber");
                              })
                        : dispatch(saveConfigurationField({ name: "nursePhoneNumber", value: data.nursePhoneNumber }))
                              .then(() => {
                                  toast(t(t("nrOnWeb") + " " + t("saved")));
                              })
                              .catch((e) => {
                                  toast(t("error") + ": " + e.message, { type: "error" });
                                  resetField("nursePhoneNumber");
                              }),
                );
            }
        }
        Promise.allSettled(promises).then(() => setIsLoading(false));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("advisoryServicePhoneNr")}
                reset={() => reset(defaultValues)}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <>
                        <AccordionStringContent heading={t("nrOnWeb")} value={nursePhoneNumber || ""} />
                        <AccordionStringContent heading={t("customerOwnNr")} value={numbers?.phoneNumber || ""} />

                        <AccordionStringContent
                            heading={t("ivrPhoneNumber")}
                            value={t(numbers?.ivrPhoneNumber?.toString() || "")}
                        />
                        <AccordionStringContent
                            heading={t("ivrCustomerReferenceKey")}
                            value={t(numbers?.ivrCustomerReferenceKey?.toString() || "")}
                        />
                    </>
                }
            >
                <ServiceInputContainer>
                    <ServiceInput label={t("nrOnWeb")} register={register("nursePhoneNumber")} />
                    <ServiceInput label={t("customerOwnNr")} register={register("serviceContract.phoneNumber")} />
                    <ServiceInput label={t("ivrPhoneNumber")} register={register("serviceContract.ivrPhoneNumber")} />
                    <ServiceInput
                        label={t("ivrCustomerReferenceKey")}
                        register={register("serviceContract.ivrCustomerReferenceKey")}
                    />
                </ServiceInputContainer>
            </ServiceAccordion>
        </form>
    );
};

export default AdvisoryServicePhoneNumber;
