import { useNumberOfHiddenElements } from "hooks";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IProps {
    texts?: string[] | null;
}

interface IPropsView extends IProps {}
const DepartmentCell = memo<IPropsView>(({ texts }: IPropsView) => {
    const { t } = useTranslation("followup");
    const [numberOfHiddenElements, setNumberOfHiddenElements] = useState(0);
    const { ref, isOverflowing } = useNumberOfHiddenElements(setNumberOfHiddenElements);
    if (!texts) return null;
    return (
        <div className="flex h-full items-start px-2">
            <div
                ref={ref}
                className={`flex gap-1 flex-wrap overflow-hidden  ${!isOverflowing ? "w-full" : "max-w-[100px]"}`}
            >
                {texts.map((text, index) => (
                    <div className="flex items-center" key={`${index}-${text}`}>
                        <p
                            className={`truncate       ${
                                !isOverflowing ? "w-full" : "max-w-[100px]"
                            } text-sm lg:text-base`}
                        >
                            {text}{" "}
                        </p>
                    </div>
                ))}
            </div>
            {isOverflowing && <div className=" underline">{`(+${numberOfHiddenElements} ${t("dep")}.)`}</div>}
        </div>
    );
});

export default DepartmentCell;
