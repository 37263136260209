const RecurringSickLeaveWidgetLoader = () => {
    return (
        <div className="animate-pulse">
            <div className="flex flex-wrap text-sm chart-selector py-4 px-6">
                <div className="w-full flex items-center">
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                </div>
            </div>
            <div className="chart-container">
                <div className="flex flex-wrap text-sm chart-selector py-4 px-6">
                    <div className="w-1/6 flex items-end">
                        <div className="h-20 bg-gray-200 rounded-sm dark:bg-gray-700 w-10"></div>
                    </div>
                    <div className="w-1/6 flex items-end">
                        <div className="h-36 bg-gray-200 rounded-sm dark:bg-gray-700 w-10"></div>
                    </div>
                    <div className="w-1/6 flex items-end">
                        <div className="h-15 bg-gray-200 rounded-sm dark:bg-gray-700 w-10"></div>
                    </div>
                    <div className="w-1/6 flex items-end">
                        <div className="h-2.5 bg-gray-200 rounded-sm dark:bg-gray-700 w-10"></div>
                    </div>
                    <div className="w-1/6 flex items-end">
                        <div className="h-30 bg-gray-200 rounded-sm dark:bg-gray-700 w-10"></div>
                    </div>
                    <div className="w-1/6 flex items-end">
                        <div className="h-10 bg-gray-200 rounded-sm dark:bg-gray-700 w-10"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecurringSickLeaveWidgetLoader;
