import { useAppDispatch, useAppSelector } from "store/hooks";
import { IPowerbarSearch } from "interfaces/ISearch";
import { isEmpty } from "lodash/fp";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postLatestSearch } from "store/searchActions";
import { getSearchResults } from "store/searchSelectors";
import PowerbarSearchItems, { IProps } from "./PowerbarSearchItems";
import * as UserSelectors from "store/userSelectors";
import { changeUserContext } from "store/userActions";

export default function Index({ setOpen, inputText }: IProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [currentListIndex, setCurrentListIndex] = useState(0);
    const searchResult = useAppSelector(getSearchResults);
    const userRetailers = useAppSelector(UserSelectors.getUserRetailers);
    const userAccountId = useAppSelector(UserSelectors.getUserAccountId);

    const handleSearchItemClicked = useCallback(
        (selectedSearchItem: IPowerbarSearch) => {
            dispatch(postLatestSearch(selectedSearchItem));
            if (!isEmpty(selectedSearchItem.ssn)) {
                navigate(
                    `/followup/employee/${selectedSearchItem.referenceKey ?? selectedSearchItem.employmentId}/absence`,
                );
            }
            if (selectedSearchItem.partner) {
                const selectedUserAccountId = userRetailers?.find(
                    (c) => c.name === selectedSearchItem.partner,
                )?.userAccountId;

                if (selectedUserAccountId && selectedUserAccountId !== userAccountId) {
                    dispatch(
                        changeUserContext({
                            userAccountId: selectedUserAccountId,
                            assume: false,
                        }),
                    );
                }
            }
            setOpen(false);
        },
        [dispatch, navigate, setOpen, userAccountId, userRetailers],
    );

    const handleAssumeUserClicked = useCallback(
        (userAccountId: number) => {
            dispatch(
                changeUserContext({
                    userAccountId: userAccountId,
                    assume: true,
                }),
            );
        },
        [dispatch],
    );

    const handleKeyDown = (e: React.KeyboardEvent, listLength: number) => {
        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
            const nextListIndex = e.key === "ArrowUp" ? currentListIndex - 1 : currentListIndex + 1;
            if (nextListIndex > -1 && nextListIndex < listLength) {
                const newFocusElement = document.getElementById("selectedSearches-" + nextListIndex);
                newFocusElement?.focus();
                setCurrentListIndex(nextListIndex);
            }
        } else if (e.key === "Tab") setCurrentListIndex(currentListIndex + 1);
    };
    return (
        <PowerbarSearchItems
            searchItemClicked={handleSearchItemClicked}
            assumeUserClicked={handleAssumeUserClicked}
            searchResults={searchResult}
            handleKeyDown={handleKeyDown}
            inputText={inputText}
        />
    );
}
