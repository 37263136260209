export const getSsnWithDash = (ssn: string): string => {
    if (!ssn) return "";
    const ssnWithDash = `${ssn.slice(0, ssn.length - 4)}-${ssn.slice(ssn.length - 4)}`;
    return ssnWithDash;
};

export const getSsnWithDashWithReturnNull = (ssn?: string | null | undefined): string | null => {
    if (!ssn) return null;
    const ssnWithDash = `${ssn.slice(0, ssn.length - 4)}-${ssn.slice(ssn.length - 4)}`;
    return ssnWithDash;
};

export const getPhoneNumberWithPrefix = (prefix: string, number: string) =>
    number?.charAt(0) === "0" ? number.replace("0", prefix) : `${prefix}${number}`;
