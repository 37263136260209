import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useFieldArray, useForm } from "react-hook-form";
import { ServiceCheckboxContainer } from "../ServiceCheckbox";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { ServiceFieldArray, ServiceFieldArrayAppend } from "../ServiceFieldArray";
import { getEmploymentGroups, getServiceSlice } from "pages/service/redux/serviceSelectors";
import { deleteEmploymentGroups, saveEmploymentGroups } from "pages/service/redux/serviceAsyncActions";

export const isGlobalEmploymentGroup = (employmentGroup: string) =>
    employmentGroup === "blueCollar" || employmentGroup === "whiteCollar";

const EmploymentGroups = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const { companyId } = useAppSelector(getServiceSlice);
    const employmentGroups = useAppSelector(getEmploymentGroups);
    const [itemsToDelete, setItemsToDelete] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm({
        defaultValues: { employmentGroups },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "employmentGroups",
        // necessary because useFieldArray overwrites the id property
        keyName: "keyName",
    });

    const {
        register: registerInput,
        getValues: getInputValue,
        resetField: resetInputField,
        formState: { isValid: inputIsValid },
    } = useForm({
        defaultValues: { input: undefined },
    });

    useEffect(() => {
        reset({ employmentGroups });
    }, [employmentGroups, reset]);

    const onSubmit = () => {
        setIsLoading(true);
        const itemsToSave = fields.filter((x) => x.id === 0).map((y) => y.nameString || "");
        const promises = [];
        if (itemsToDelete.length > 0)
            promises.push(
                dispatch(deleteEmploymentGroups(itemsToDelete))
                    .then(unwrapResult)
                    .then(() => toast(t("deleted")))
                    .catch((e) => {
                        toast(t("error") + ": " + e.message, { type: "error" });
                        reset({ employmentGroups });
                    }),
            );
        if (itemsToSave.length > 0)
            promises.push(
                dispatch(saveEmploymentGroups(itemsToSave))
                    .then(unwrapResult)
                    .then(() => toast(t("saved")))
                    .catch((e) => {
                        toast(t("error") + ": " + e.message, { type: "error" });
                        reset({ employmentGroups });
                    }),
            );

        Promise.allSettled(promises).finally(() => {
            setItemsToDelete([]);
            setIsLoading(false);
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("employmentGroups")}
                reset={() => reset({ employmentGroups })}
                disabled={!isDirty}
                isLoading={isLoading}
                content={fields.map(({ id, nameString, nameIdentifier }) => (
                    <AccordionBooleanContent
                        key={id}
                        label={
                            nameIdentifier && isGlobalEmploymentGroup(nameIdentifier)
                                ? t(nameIdentifier)
                                : nameString || ""
                        }
                    />
                ))}
            >
                <ServiceCheckboxContainer heading={t("employmentGroups")}>
                    {fields?.map(({ nameIdentifier, nameString, id, companyId }, index) => (
                        <ServiceFieldArray
                            key={index}
                            index={index}
                            label={
                                nameIdentifier && isGlobalEmploymentGroup(nameIdentifier)
                                    ? t(nameIdentifier)
                                    : nameString || ""
                            }
                            disabled={!companyId}
                            onClick={() => {
                                if (id) setItemsToDelete([...itemsToDelete, id]);
                                remove(index);
                            }}
                        />
                    ))}
                    <ServiceFieldArrayAppend
                        buttonText={t("addEmploymentGroup")}
                        onClick={() => {
                            append({ nameString: getInputValue("input"), id: 0, companyId });
                            resetInputField("input");
                        }}
                        register={registerInput("input", { required: true })}
                        disabled={!inputIsValid}
                    />
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default EmploymentGroups;
