import SearchNumberFieldFilter, { IItems } from "../SearchNumberFieldFilter";

interface IPropsView {
    items: IItems[];
    header: string;
    body: string;
}
const AbsenceDaysFilter = ({ items, header, body }: IPropsView) => (
    <SearchNumberFieldFilter body={body} header={header} items={items} />
);

export default AbsenceDaysFilter;
