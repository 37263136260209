/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getAbsenceReportInit } from "../absenceReportsActions";
import { IAbsenceReportSlice } from "../absenceReportsSlice";

const absenceReportInit = (builder: ActionReducerMapBuilder<IAbsenceReportSlice>) =>
    builder
        .addCase(getAbsenceReportInit.fulfilled, (state, action) => {
            state.AbsenceReports = action.payload.absenceReports;
            state.Templates = action.payload.templates;
            state.ConfigurationFields = action.payload.configurationFields;
            state.FirstDayMedicalCertificate = action.payload.firstDayMedicalCertificate;
            state.SelectedAbsenceReport = null;
            state.IsOK = true;
        })
        .addCase(getAbsenceReportInit.rejected, (state) => {
            state.IsOK = false;
        });

export default absenceReportInit;
