import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchRehabServiceContract as fetchRehabServiceContractAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchRehabServiceContract = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchRehabServiceContractAction.fulfilled, (state, action) => {
            if (action.payload) state.hasRehabServiceContract.data = action.payload.isActive ?? false;
            state.hasRehabServiceContract.status = Status.OK;
        })
        .addCase(fetchRehabServiceContractAction.pending, (state) => {
            state.hasRehabServiceContract.status = Status.PENDING;
        })
        .addCase(fetchRehabServiceContractAction.rejected, (state) => {
            state.hasRehabServiceContract.status = Status.ERROR;
        });

export default fetchRehabServiceContract;
