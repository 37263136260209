/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchRehab as fetchRehabAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchActivityLogs = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchRehabAction.fulfilled, (state, action) => {
            if (action.payload) Object.assign(state.rehab, action.payload);

            state.status.rehab = Status.OK;
        })
        .addCase(fetchRehabAction.pending, (state) => {
            state.status.rehab = Status.PENDING;
        })
        .addCase(fetchRehabAction.rejected, (state) => {
            state.status.rehab = Status.ERROR;
        });

export default fetchActivityLogs;
