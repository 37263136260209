import { useAppSelector } from "store/hooks";
import { useCallback, useMemo } from "react";
import { get, isEmpty } from "lodash/fp";
import { useAppDispatch } from "store/hooks";
import { useTranslation } from "react-i18next";
import { sortAbsenceType, findTagByReferenceKey } from "../../utils";
import { actions, selectors } from "../../redux";
import AbsenceTypeFilter from "./AbsenceTypeFilter";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(["followup", "translation"]);
    const filters = useAppSelector(selectors.getSearchTagsById("absenceType"));
    const Header = useMemo(() => t("search.searchQuery.filters.accordionAbsenceType"), [t]);
    const Tags = useMemo(() => {
        const getCheckedItems = filters.filter(get("checked"));
        if (isEmpty(getCheckedItems)) return "";
        return getCheckedItems.map(({ label }) => t(label)).join(", ");
    }, [filters, t]);
    const Items = useMemo(() => {
        return sortAbsenceType(filters).map((value) => ({
            ...value,
            label: t(value.label, { ns: "translation" }),
        }));
    }, [filters, t]);
    const onChange = useCallback(
        (value: string) => {
            const element = findTagByReferenceKey(value, filters);
            if (!element) return;
            dispatch(
                actions.updateSearchFilter({
                    ...element,
                    checked: !element.checked,
                }),
            );
        },
        [dispatch, filters],
    );
    return <AbsenceTypeFilter items={Items} header={Header} onChange={onChange} tags={Tags} />;
}
