import { useEffect } from "react";
import { useRouteState } from "routes/hooks";
import DropdownMenu, { IExternalProps } from "./DropdownMenu";

export default function Index(props: IExternalProps) {
    const { initialRoute } = useRouteState(props.routes);
    useEffect(() => {
        if (props.setCurrentRoute) {
            props.setCurrentRoute(initialRoute);
        }
    }, [initialRoute, props]);
    return <DropdownMenu {...props} activeRoute={initialRoute?.indexRoute || initialRoute.route} />;
}
