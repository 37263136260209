import { useCallback, useLayoutEffect, useState } from "react";

const useNumberOfHiddenElements = (numberOfHiddenElements: (hiddenElement: number) => void) => {
    const [ref, currentRef] = useState<HTMLDivElement | null>();
    const [isOverflowing, setIsOverflowing] = useState(false);

    const setRef = useCallback((node: HTMLDivElement) => {
        currentRef(node);
    }, []);
    const setLayout = useCallback(() => {
        if (ref) {
            let hiddenElementCounter = 0;
            const collection = ref.children;
            const dropdownPosition = ref.getBoundingClientRect().y;
            for (let i = 0; i < collection.length; i += 1) {
                if (collection[i]?.getBoundingClientRect().y > dropdownPosition) hiddenElementCounter += 1;
            }
            setIsOverflowing(Boolean(hiddenElementCounter > 0));
            if (numberOfHiddenElements) {
                numberOfHiddenElements(hiddenElementCounter);
            }
        }
    }, [numberOfHiddenElements, ref]);

    useLayoutEffect(() => {
        setLayout();
    }, [setLayout]);
    return { ref: setRef, isOverflowing };
};

export default useNumberOfHiddenElements;
