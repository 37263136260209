import { selectors } from "../../redux";
import { useAppSelector } from "store/hooks";
import EmployeeCardDegrees from "./EmployeeCardDegrees";
import { ReactElement, useMemo } from "react";
import { head, take } from "lodash/fp";
import { getDateDisplayValue, getDateForwardOrBack } from "utils/date";
import { useTranslation } from "react-i18next";
import { DegreeBody } from "../DegreeColumnText";
import { ColumnText } from "@medhelp/ui";
interface IProps {
    absenceReportId?: string;
    body?: ReactElement;
}
export default function Index(props: IProps) {
    const { t } = useTranslation("followup");
    const currentAbsenceReport = useAppSelector(selectors.getAbsenceItemById(props.absenceReportId));

    const degreeStatus = useAppSelector(selectors.getIfDegreeIsIncreasingOrDecreasing(props.absenceReportId));
    const degrees = useAppSelector(selectors.getSortedDegrees(props.absenceReportId));
    const Header = useMemo(() => `${t("absenceDegree")}:`, [t]);

    const ComingDegree = useMemo(() => {
        const latesDegree = head(degrees);
        return currentAbsenceReport?.currentDegree !== latesDegree?.degree;
    }, [degrees, currentAbsenceReport?.currentDegree]);
    const EndDegree = useMemo(() => {
        if (!ComingDegree && currentAbsenceReport?.endDate) {
            const currentDegrees = take(2, degrees);
            if (currentDegrees.length === 2) {
                const currentDegree = currentDegrees[0];
                if (currentDegree.timeStamp) {
                    return `${getDateDisplayValue(
                        getDateForwardOrBack(1, "days", "backwards", currentDegree.timeStamp),
                    )} - ${getDateDisplayValue(currentAbsenceReport?.endDate)}`;
                }
            }
            return `${getDateDisplayValue(
                currentAbsenceReport?.startDate,
            )} - ${getDateDisplayValue(currentAbsenceReport?.endDate)}`;
        }
        return undefined;
    }, [ComingDegree, degrees, currentAbsenceReport?.endDate, currentAbsenceReport?.startDate]);
    const Body = useMemo(
        () => (
            <ColumnText
                header={Header}
                body={<DegreeBody text={`${currentAbsenceReport?.currentDegree}%`} status={degreeStatus} />}
            />
        ),
        [Header, currentAbsenceReport?.currentDegree, degreeStatus],
    );
    return (
        <EmployeeCardDegrees
            body={props?.body || Body}
            endDate={currentAbsenceReport?.endDate}
            degrees={currentAbsenceReport?.degrees}
            status={degreeStatus}
            endDegree={EndDegree}
        />
    );
}
