import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import PrimaryButton from "components/PrimaryButton";
import { actions, MedicalCertificateModals } from "../../redux";
import { useAppDispatch } from "store/hooks";

const MedicalCertificateHeader = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    return (
        <div className="flex flex-col gap-3 sm:gap-0 sm:max-w-none sm:flex-row justify-between">
            <h2>{t("medicalCertificate")}</h2>
            <PrimaryButton
                id="medical-certificate-header-button"
                onClick={() => dispatch(actions.showModal(MedicalCertificateModals.uploadCertificate))}
            >
                <div className="flex items-center gap-3 justify-center">
                    <Icon icon="plus" />
                    <p className="font-bold">{t("newMedicalCertificate")}</p>
                </div>
            </PrimaryButton>
        </div>
    );
};
export default MedicalCertificateHeader;
