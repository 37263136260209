import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";
import { ReactComponent as WarningIcon } from "icons/login/warning.svg";
import { ReactComponent as CloseIcon } from "icons/login/close.svg";
import { ReactComponent as MhLogo } from "icons/login/mhLogo.svg";
import { ReactComponent as AvonovaLogo } from "icons/avonovaLogo.svg";
import { ReactComponent as FrisklinjenLogo } from "icons/login/frisklinjenLogo.svg";
import { ReactComponent as FeelgoodLogo } from "icons/login/feelgoodLogo.svg";
import { ReactComponent as FalckLogo } from "icons/login/falckLogo.svg";
import HandleCookies from "../HandleCookies";
import LoginModal from "../LoginModal";
import { IRegionData, IWarningBannerData } from "pages/login/types";
import { checkIfNativeApp } from "utils/nativeApp";
import { getDateDisplayValue } from "utils/date";
import CookiePolicy from "pages/about/pages/CookiePolicy";
export interface ILoginContainerProps {
    children: React.ReactNode;
    id: string;
    warningBannerData?: IWarningBannerData | null;
    selectedMarket: keyof IWarningBannerData;
    selectedLanguage: keyof IRegionData;
}
const LoginContainer = ({
    children,
    id,
    warningBannerData,
    selectedMarket,
    selectedLanguage,
}: ILoginContainerProps) => {
    const activeRetailer = useAppSelector(UserSelectors.getUserRetailer);
    const [cookiePolicyOpen, setCookiePolicyOpen] = useState(false);
    const [showWarningBanner, setShowWarningBanner] = useState<null | boolean>(
        warningBannerData ? warningBannerData[selectedMarket]?.display : null,
    );

    const setLogo = (): React.ReactNode => {
        switch (activeRetailer) {
            case "Avonova":
                return <AvonovaLogo className="h-5" />;
            case "Feelgood":
                return <FrisklinjenLogo className="h-6" />;
            case "Falck":
                return <FalckLogo className="h-12" />;
            default:
                return <MhLogo className="h-6 fill-mhdarkgreen-original" />;
        }
    };

    useEffect(() => {
        if (warningBannerData && selectedMarket) setShowWarningBanner(warningBannerData[selectedMarket].display);
    }, [selectedMarket, warningBannerData]);

    const hideCookieModal = useMemo(() => checkIfNativeApp(), []);

    return (
        <div id={id} className="w-full h-full flex flex-col">
            {showWarningBanner && warningBannerData && (
                <div id="warningBanner" className="py-10 pl-5 pr-13 bg-grey-200 relative flex items-start xl:p-11">
                    <div className="pt-1 mr-5">
                        <WarningIcon className="w-8 h-8" />
                    </div>
                    <p className="max-w-4xl">{warningBannerData[selectedMarket!][selectedLanguage]}</p>
                    <div className="absolute right-5 xl:h-full xl:top-0 xl:right-7 xl:flex xl:items-center">
                        <button
                            type="button"
                            className="p-2 xl:p-4 xl:h-11"
                            onClick={() => {
                                if (setShowWarningBanner) setShowWarningBanner(false);
                            }}
                        >
                            <CloseIcon className="h-3" />
                        </button>
                    </div>
                </div>
            )}
            <div className="w-full h-full flex">
                <div id="left-login" className="w-full h-full px-4 py-5 flex flex-col lg:px-10 lg:pt-7 lg:max-w-md">
                    <div className="w-full flex">{setLogo()}</div>

                    <div className="w-full flex justify-center">
                        <div className="w-full max-w-sm flex flex-col">{children}</div>
                    </div>

                    {activeRetailer === "Feelgood" && (
                        <div className="h-full flex justify-center items-end">{<FeelgoodLogo className="w-24" />}</div>
                    )}
                    <div className="h-full flex items-end lg:hidden">
                        <p className="text-sm text-grey-600">{`© ${getDateDisplayValue(
                            new Date(),
                            "yyyy",
                        )} MedHelp Care AB`}</p>
                    </div>
                </div>
                <div
                    id="right-login"
                    className="hidden w-full h-full bg-globalMarketLogin bg-cover bg-center flex-col lg:flex"
                >
                    <div className="h-full mb-6 mr-5 items-end justify-end lg:flex">
                        <p className="text-sm text-mhbeige">{`© ${getDateDisplayValue(
                            new Date(),
                            "yyyy",
                        )} MedHelp Care AB`}</p>
                    </div>
                </div>
            </div>

            {!hideCookieModal && <HandleCookies setCookiePolicyOpen={setCookiePolicyOpen} />}
            {cookiePolicyOpen && (
                <LoginModal open={cookiePolicyOpen} setOpen={() => setCookiePolicyOpen(!cookiePolicyOpen)}>
                    <CookiePolicy />
                </LoginModal>
            )}
        </div>
    );
};

export default LoginContainer;
