import { InformationText, PhoneContact } from "@medhelp/ui";
import { useAppSelector } from "store/hooks";
import { getSelectedEmployeeInfo } from "pages/Absence/pages/absencereporting/redux/absenceReportsSelectors";
import { getUserMainManager, getIsArchived, getUserEmploymentName } from "pages/FollowUp/redux/followUpSelectors";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPhoneNumberWithPrefix, getSsnWithDash } from "utils/numbers";
import EmployeeHeader from "./EmployeeHeader";

export default function Index() {
    const { t } = useTranslation();
    const mainManager = useAppSelector(getUserMainManager);
    const selectedEmployee = useAppSelector(getSelectedEmployeeInfo);
    const isArchived = useAppSelector(getIsArchived);
    const userEmploymentName = useAppSelector(getUserEmploymentName);

    const phoneNumberWithPrefix = useMemo(
        () =>
            mainManager
                ? getPhoneNumberWithPrefix(
                      mainManager?.contactInformation?.secondaryMobileCountryPrefix || "",
                      mainManager?.contactInformation?.secondaryMobileNumber || "",
                  )
                : "",
        [mainManager],
    );

    const ssnWithSwishDash = useMemo(
        () => getSsnWithDash(selectedEmployee?.socialSecurityNumber || ""),
        [selectedEmployee?.socialSecurityNumber],
    );

    const Ssn = useMemo(() => <InformationText top={t("ssn")} bottom={ssnWithSwishDash} />, [ssnWithSwishDash, t]);

    const CompanyName = useMemo(
        () => <InformationText top={t("company")} bottom={selectedEmployee?.companyName || ""} />,
        [selectedEmployee?.companyName, t],
    );

    const ClosestMangager = useMemo(
        () =>
            mainManager?.name ? (
                <PhoneContact
                    header={t("closestManager")}
                    name={mainManager?.name}
                    phoneNumber={phoneNumberWithPrefix}
                />
            ) : (
                <></>
            ),

        [mainManager?.name, phoneNumberWithPrefix, t],
    );

    return (
        <EmployeeHeader
            id="employee-header"
            name={userEmploymentName}
            closestManager={ClosestMangager}
            companyName={CompanyName}
            ssn={Ssn}
            isArchived={isArchived}
        />
    );
}
