import * as React from "react";

interface IProps {
    seen: boolean;
    className?: string;
}
const Notification = ({ seen = false, className }: IProps) => (
    <div
        className={`w-2 h-2 rounded  ${
            seen ? "border-2 border-primary-700 border-solid" : "bg-mhred-alert3 border-none"
        } ${className}`}
    />
);

export default Notification;
