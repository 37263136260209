import { WorkOrderReservationViewModel } from "swagger/medicaladvisory";
import { IWorkOrderParams } from "../types";
import { getServices } from "api/services";
import { TFunction } from "i18next";

export const isQueryParamsValid = (params: IWorkOrderParams): boolean => {
    const queryCheck = { ...params };
    delete queryCheck.from;
    return !Object.values(queryCheck).includes("");
};

export const getBaseParameters = (
    workOrderParams: IWorkOrderParams,
    workOrderReservation: WorkOrderReservationViewModel,
): string => {
    const params: string[] = [];
    params.push(`?AgentID=${workOrderParams.agentId}`);
    params.push(`&AgentName=${workOrderParams.agentName}`);
    params.push(`&AgentSessionID=${workOrderParams.agentSessionId}`);
    params.push(`&BaseServiceUrl=${workOrderParams.baseServiceUrl}`);
    params.push(`&Instance=${workOrderParams.instance}`);
    params.push(`&ServiceID=${workOrderParams.serviceId}`);
    params.push(`&ServiceName=${workOrderParams.serviceName}`);
    params.push(`&Token=${getServices().token}`);
    params.push(`&From=sip:${workOrderReservation?.userAccount?.contactInformation?.mobile}@medhelp.se`);
    params.push(`&CustomerID=${workOrderReservation?.company?.customerKey}`);
    params.push(`&WorkOrderID=${workOrderParams.contactId}`);
    params.push(`&EmploymentID=${workOrderReservation?.employmentId}`);

    return params.join("");
};

export const birthdateAndAge = (t: TFunction, ssn?: string | null) => {
    if (!ssn) return "Unknown";
    const ageCalc = (year: number, month: number, day: number) => {
        const today = new Date(Date.now());
        let age = today.getFullYear() - year;
        age += (today.getMonth() + 1 - month) / 12;
        age += (today.getDate() - day) / 365;
        return Math.floor(age);
    };

    if (ssn === null) {
        return "";
    }
    let ssnYear = 0,
        ssnMonth = 0,
        ssnDay = 0;
    if (ssn.length === 12) {
        // Swedish
        ssnYear = parseInt(ssn.substring(0, 4), 10);
        ssnMonth = parseInt(ssn.substring(4, 6), 10);
        ssnDay = parseInt(ssn.substring(6, 8), 10);
    }
    if (ssn.length === 11) {
        // Finnish
        const ssnYearPart = parseInt(ssn.substring(4, 6), 10);
        const ssnCenturyMark = ssn.substring(6, 7);
        ssnYear = ssnYearPart + (ssnCenturyMark === "+" ? 1800 : ssnCenturyMark === "-" ? 1900 : 2000);
        ssnMonth = parseInt(ssn.substring(2, 4), 10);
        ssnDay = parseInt(ssn.substring(0, 2), 10);
    }
    return (
        `${ssnYear}-${ssnMonth < 10 ? "0" : ""}${ssnMonth}-${ssnDay} (${ageCalc(ssnYear, ssnMonth, ssnDay)} ` +
        t("year") +
        `)`
    );
};
