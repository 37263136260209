import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchTemplateDocumentLink as fetchTemplateDocumentLinkAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchTemplateDocumentLink = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchTemplateDocumentLinkAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload.healthCaseId,
            );
            if (currentHealthCase && action.payload && action.payload.templateDocumentUrl) {
                currentHealthCase.pdfUrl = action.payload.templateDocumentUrl;
                currentHealthCase.annotation = action.payload.annotation || "";
                currentHealthCase.healthCaseSubStatus.pdfDocumentTemplate = Status.OK;
            }
        })
        .addCase(fetchTemplateDocumentLinkAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.pdfDocumentTemplate = Status.PENDING;
        })
        .addCase(fetchTemplateDocumentLinkAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.pdfDocumentTemplate = Status.ERROR;
        });

export default fetchTemplateDocumentLink;
