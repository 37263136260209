export interface IProps {
    label: string;
    value: string;
}
const FormImmutableFormTextInput = ({ label, value }: IProps) => (
    <div className="flex flex-col font-bold mb-10">
        <label className="mb-1.5">{label}</label>
        <input
            className="w-full border border-mhdarkgreen-original bg-transparent py-3 px-4 text-grey-350"
            disabled
            value={value}
        />
    </div>
);

export default FormImmutableFormTextInput;
