import { isEmpty } from "lodash/fp";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StatusTag from "../StatusTag/StatusTag";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ServiceDatePicker from "../ServiceDatePicker";
import { isAfterDate, isBeforeDate } from "utils/date";
import { ServiceContractModel } from "swagger/businessupport";
import { AccordionBooleanContent, ServiceAccordion } from "../ServiceAccordion";
import {
    getProduct,
    getServiceSlice,
    getOrganizationInfo,
    getServiceContracts,
    getDeactivatedServiceContracts,
    getHealthCaseManagementTemplates,
} from "pages/service/redux/serviceSelectors";
import {
    saveServiceContract,
    saveDefaultHCMTemplates,
    saveAccessRightConfiguration,
} from "pages/service/redux/serviceAsyncActions";

const checkActiveStatus = (activationDate?: string, deativationDate?: string) => {
    if (!activationDate || (deativationDate && isBeforeDate(deativationDate))) return "inactive";
    if (isAfterDate(activationDate)) return "activates";
    return "active";
};

const ServiceContracts = () => {
    const dispatch = useAppDispatch();
    const product = useAppSelector(getProduct);
    const { t } = useTranslation("service");
    const { region } = useAppSelector(getOrganizationInfo);
    const serviceContracts = useAppSelector(getServiceContracts);
    const { companyId, customerId } = useAppSelector(getServiceSlice);
    const HCMTemplates = useAppSelector(getHealthCaseManagementTemplates);
    const deactivatedServiceContracts = useAppSelector(getDeactivatedServiceContracts);
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        reset,
        resetField,
        register,
        watch,
        formState: { isDirty, dirtyFields, isValid },
    } = useForm({
        defaultValues: { serviceContracts },
    });

    useEffect(() => {
        reset({ serviceContracts });
    }, [serviceContracts, reset]);

    const onSubmit = (data: { serviceContracts: ServiceContractModel[] }) => {
        setIsLoading(true);
        const serviceContractPromises: Promise<any>[] = [];
        const accessRightPromise: Promise<any>[] = [];
        const HCMPromises: Promise<any>[] = [];
        data.serviceContracts.forEach((serviceContract, index) => {
            const translatedHealthService = t(`healthServicesKey.${serviceContract.healthService}`);
            if (dirtyFields.serviceContracts && dirtyFields.serviceContracts?.[index]) {
                serviceContractPromises.push(
                    dispatch(saveServiceContract(serviceContract))
                        .then(unwrapResult)
                        .then(() => toast(t("saved") + " " + t("healthService") + " " + translatedHealthService))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + translatedHealthService, { type: "error" });
                            resetField(`serviceContracts.${index}`);
                        }),
                );
                if (serviceContract.healthService === 3) {
                    if (!serviceContract.id) {
                        if (companyId && customerId && isEmpty(HCMTemplates)) {
                            HCMPromises.push(
                                dispatch(
                                    saveDefaultHCMTemplates({
                                        companyId,
                                        customerId,
                                        product,
                                        country: region?.country ?? "",
                                    }),
                                )
                                    .then(unwrapResult)
                                    .then(() => toast(t("saved") + " " + t("rehabTemplates")))
                                    .catch((e) =>
                                        toast(t("error") + ": " + e.message + " " + t("rehabTemplates"), {
                                            type: "error",
                                        }),
                                    ),
                            );
                        }

                        accessRightPromise.push(
                            dispatch(
                                saveAccessRightConfiguration({
                                    accessRightType: "RehabProcessManagement",
                                    hierarchical: false,
                                    managerType: "DepartmentManagerAccessRights",
                                }),
                            )
                                .then(unwrapResult)
                                .then(() => toast(t("saved") + " " + t("rehabAccessRight")))
                                .catch((e) => {
                                    toast(t("error") + ": " + e.message + " " + t("accessRight"), { type: "error" });
                                }),
                        );
                    }
                }
            }
        });
        Promise.allSettled([...serviceContractPromises, HCMPromises, accessRightPromise]).finally(() =>
            setIsLoading(false),
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("healthServices")}
                reset={() => reset({ serviceContracts })}
                disabled={!isDirty || !isValid}
                isLoading={isLoading}
                content={serviceContracts.map(
                    ({ id, healthService, activationDate, deactivationDate }) =>
                        checkActiveStatus(activationDate, deactivationDate ?? "") === "active" && (
                            <AccordionBooleanContent key={id} label={t(`healthServicesKey.${healthService}`)} />
                        ),
                )}
            >
                <div className="my-8">
                    {serviceContracts?.map(({ healthService, activationDate, deactivationDate, id }, index) => (
                        <ServiceDatePicker
                            key={id ?? healthService}
                            label={t(`healthServicesKey.${healthService}`)}
                            activationRegister={register(`serviceContracts.${index}.activationDate`, {
                                validate: (value) => {
                                    if (value && !watch(`serviceContracts.${index}.deactivationDate`)) return true;
                                },
                            })}
                            deactivationRegister={register(`serviceContracts.${index}.deactivationDate`, {
                                validate: (value) => {
                                    if (value && !watch(`serviceContracts.${index}.activationDate`)) return false;
                                    if (value && isBeforeDate(value, watch(`serviceContracts.${index}.activationDate`)))
                                        return false;
                                    return true;
                                },
                            })}
                        >
                            <StatusTag status={checkActiveStatus(activationDate, String(deactivationDate))} />
                        </ServiceDatePicker>
                    ))}
                </div>
                <div className="my-8">
                    {deactivatedServiceContracts?.map(({ healthService, activationDate, deactivationDate, id }) => (
                        <ServiceDatePicker
                            key={id ?? healthService}
                            label={t(`healthServicesKey.${healthService}`)}
                            activationDate={activationDate}
                            deactivationDate={deactivationDate}
                        >
                            <StatusTag status={checkActiveStatus(activationDate, String(deactivationDate))} />
                        </ServiceDatePicker>
                    ))}
                </div>
            </ServiceAccordion>
        </form>
    );
};

export default ServiceContracts;
