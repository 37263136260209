import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import TopLinks from "components/TopLinks";
import { useAppSelector } from "store/hooks";
import { getEventMessagesUnreadCount, getSubscriptionMessagesUnreadCount } from "./redux/inboxSelectors";

const Inbox = () => {
    const { t } = useTranslation();
    const eventMessagesUnreadCount = useAppSelector(getEventMessagesUnreadCount);
    const subscriptionMessagesUnreadCount = useAppSelector(getSubscriptionMessagesUnreadCount);

    const Routes = [
        {
            route: "eventMessages",
            name: `${t("myEvents")} (${eventMessagesUnreadCount})`,
            children: [],
        },
        {
            route: "subscriptionsMessages",
            name: `${t("mySubscriptions")} (${subscriptionMessagesUnreadCount})`,
            children: [],
        },
    ];

    return (
        <>
            <TopLinks routes={Routes} headRoute="inbox" header={t("inbox")} />
            <div className="pt-12 pb-20 justify-center xl:px-0 w-full xl:flex">
                <div className="w-full">
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default Inbox;
