import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTransform, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { IPowerbarSearch } from "interfaces/ISearch";
import { CatalogEntryModel } from "swagger/customercompany";
import { MedHelpPeopleDomainLocalSearch } from "swagger/people";
import { getLatestSearches, getPowerbarSearch, postLatestSearch } from "./searchActions";
import Cookies, { CookieAttributes } from "js-cookie";
import { Config } from "config";
import { getDateForwardOrBack } from "utils/date";

const preselectedValue = Cookies.get("customerSupportEmployment");
const preselected = Cookies.get("customerSupportPreSelectCompany");

let preselectedPowerbarSearch: IPowerbarSearch | null = null;
if (preselectedValue && preselected && preselected === "true") {
    const preselectedObj = JSON.parse(preselectedValue);
    if (preselectedObj) {
        preselectedPowerbarSearch = {
            typeOfSearch: "powerbarSearch",
            companyName: preselectedObj.companyName,
            companyId: preselectedObj.companyId,
            customerId: preselectedObj.customerId,
            partner: preselectedObj.retailerName,
            retailerId: preselectedObj.retailerId,
            fullName: preselectedObj.name,
            ssn: preselectedObj.socialSecurityNumber,
            employmentNumber: preselectedObj.employmentNumber,
            departments: preselectedObj.departments,
            referenceKey: preselectedObj.referenceKey,
            employmentId: preselectedObj.employmentId,
        };
    }
}
export interface ISearchData {
    historySearches: IPowerbarSearch[];
    powerbarSearchResult: IPowerbarSearch[];
    activePowerbarSearch: IPowerbarSearch | null;
    isLoading: boolean;
}

const initialSearchState: ISearchData = {
    powerbarSearchResult: [],
    historySearches: [],
    activePowerbarSearch: preselectedPowerbarSearch ? preselectedPowerbarSearch : null,
    isLoading: true,
};

const searchSlice = createSlice({
    name: "search",
    initialState: initialSearchState,
    reducers: {
        setActivePowerbarSearch(state, action: PayloadAction<IPowerbarSearch>) {
            state.activePowerbarSearch = action.payload;
            setPreselectedCookie(action.payload);
        },
        resetPowerbarSearchResult(state) {
            state.powerbarSearchResult = [];
            state.activePowerbarSearch = null;
            removePreselectedCookie();
        },
        reset(state) {
            Object.assign(state, initialSearchState);
        },
        setIsLoading(state) {
            state.isLoading = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLatestSearches.fulfilled, (state, action) => {
                const localSearches: MedHelpPeopleDomainLocalSearch[] = action.payload;
                if (localSearches.length > 0) {
                    const historySearches = localSearches.map((c) => {
                        const json = c.data;
                        const search: IPowerbarSearch = JSON.parse(json!);
                        search.historySearchCreated = c.created;
                        return search;
                    });
                    const sortedByCreationDate = historySearches.sort((a, b) => {
                        const c: any = new Date(a.historySearchCreated!);
                        const d: any = new Date(b.historySearchCreated!);
                        return d - c;
                    });

                    state.historySearches = sortedByCreationDate;
                }
                state.isLoading = false;
            })
            .addCase(getLatestSearches.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(postLatestSearch.fulfilled, (state, action) => {
                const selectedItem = action.payload;
                if (selectedItem) {
                    const exists = state.historySearches.some((c) =>
                        c.referenceKey
                            ? c.referenceKey === selectedItem.referenceKey
                            : c.companyId === selectedItem.companyId,
                    );
                    state.activePowerbarSearch = selectedItem;
                    if (!exists) {
                        if (state.historySearches.length > 4) state.historySearches.pop();
                        state.historySearches = [selectedItem, ...state.historySearches];
                    }
                }
            })
            .addCase(postLatestSearch.rejected, () => {})
            .addCase(getPowerbarSearch.fulfilled, (state, action) => {
                const searchResults: CatalogEntryModel[] = action.payload;
                if (searchResults.length > 0) {
                    const powerbarSearch: IPowerbarSearch[] = searchResults.map((c) => ({
                        typeOfSearch: "powerbarSearch",
                        companyName: c.companyName ?? "",
                        companyId: c.companyId,
                        partner: c.retailerName ?? "",
                        fullName: c.name ?? "",
                        ssn: c.socialSecurityNumber ?? "",
                        employmentNumber: c.employmentNumber ?? "",
                        departments: c.departments ?? [""],
                        phoneNumber: c.telephoneNumbers?.find((c) => c),
                        referenceKey: c.referenceKey ?? undefined,
                        employmentId: c.employmentId ?? 0,
                        retailerId: c.retailerId ?? 1,
                        endedEmployment: c.endedEmployment ?? false,
                        customerId: c.customerId,
                        userAccountId: c.userAccountId ?? undefined,
                    }));
                    state.powerbarSearchResult = powerbarSearch;
                } else state.powerbarSearchResult = [];
                state.isLoading = false;
            })
            .addCase(getPowerbarSearch.rejected, (state) => {
                Object.assign(state, initialSearchState);
                state.isLoading = false;
            });
    },
});

export const searchActions = searchSlice.actions;

const SetTransform = createTransform<any, any, ISearchData, typeof searchSlice.reducer>(
    undefined,
    () => {
        // replace outbund with something else than null from the cookies
        const newOutBoundState = preselectedPowerbarSearch;
        return { ...newOutBoundState };
    },
    {
        whitelist: ["activePowerbarSearch"],
    },
);

const getCookieOptions = (): CookieAttributes => {
    const domainUrl = Config.env === "local" ? "localhost" : ".medhelp.se";
    const cookieOptions: CookieAttributes = {
        expires: getDateForwardOrBack(2, "hours", "forward"),
        path: "/",
        domain: domainUrl,
        secure: true,
    };
    return cookieOptions;
};

const setPreselectedCookie = (selectedPowerbarSearch: IPowerbarSearch) => {
    const cookieCSEmp = {
        id: 0,
        userAccountId: 0,
        socialSecurityNumber: null,
        firstName: null,
        lastName: null,
        name: " ",
        employmentId: 0,
        companyId: Number(selectedPowerbarSearch.companyId),
        companyName: selectedPowerbarSearch.companyName,
        customerId: selectedPowerbarSearch.customerId,
        employmentNumber: null,
        startDate: "0001-01-01T00:00:00",
        endDate: "0001-01-01T00:00:00",
        removalDate: null,
        automaticAnonymizationDate: null,
        telephoneNumbers: [],
        endedEmployment: false,
        displayField: selectedPowerbarSearch.companyName,
        retailerId: selectedPowerbarSearch.retailerId,
        retailerName: selectedPowerbarSearch.partner,
        doNotDelete: false,
        email: null,
        referenceKey: null,
        hasAbsence: false,
        hasRehab: false,
        maxEndPeriod: null,
        minStartPeriod: null,
        erasurePeriod: null,
        departments: null,
    };
    Cookies.set("customerSupportEmployment", JSON.stringify(cookieCSEmp), getCookieOptions());
    Cookies.set("customerSupportPreSelectCompany", "true", getCookieOptions());
    sessionStorage.setItem("customerSupportEmployment", JSON.stringify(cookieCSEmp));
    sessionStorage.setItem("customerSupportPreSelectCompany", "true");
};

const removePreselectedCookie = () => {
    sessionStorage.removeItem("customerSupportEmployment");
    sessionStorage.removeItem("customerSupportPreSelectCompany");
    sessionStorage.removeItem("customerSupportPreSelectEmployment");
    Cookies.remove("customerSupportEmployment", getCookieOptions());
    Cookies.remove("customerSupportPreSelectCompany", getCookieOptions());
    Cookies.remove("customerSupportPreSelectEmployment", getCookieOptions());
};

const persistConfig = {
    key: "search",
    storage: storageSession,
    transforms: [SetTransform],
    whitelist: ["activePowerbarSearch"],
};
export default persistReducer(persistConfig, searchSlice.reducer);
