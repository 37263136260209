import * as React from "react";
import { useAppSelector } from "store/hooks";
import SubscribeTable, { SubscribeCell, SubscribeCheckedCell } from "../../../components/SubscribeTable";
import { selectors } from "../../../redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";

const SubscribesTo = () => {
    const { t } = useTranslation("followup");
    const userSubscription = useAppSelector(selectors.getUserSubscription);
    const userEmployment = useAppSelector(selectors.getUserEmployment);
    const TableCell = React.useMemo(
        () =>
            userSubscription?.map(({ name, contactKey, careOfChild, sick, deviant, rehab }) => ({
                subscribe: <SubscribeCell header={name || ""} body={contactKey} />,
                careOfChild: <SubscribeCheckedCell checked={careOfChild} />,
                sick: <SubscribeCheckedCell checked={sick} />,
                deviant: <SubscribeCheckedCell checked={deviant} />,
                rehab: <SubscribeCheckedCell checked={rehab} />,
            })),
        [userSubscription],
    );
    if (isEmpty(TableCell))
        return (
            <div>
                {t("subscribeToNoone", {
                    name: userEmployment?.name,
                })}
            </div>
        );
    return <div>{TableCell && <SubscribeTable data={TableCell} subscribe={t("followUpSubscribe.subscribing")} />}</div>;
};
export default SubscribesTo;
