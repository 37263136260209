import { lowerFirst } from "lodash";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IPreloadedValues } from "../../ContactSupport";

interface IKeyValuePairs {
    description: string;
    value: number;
}

export interface IProps {
    label: string;
    value: keyof IPreloadedValues;
    options?: IKeyValuePairs[];
    required?: boolean;
    register: UseFormRegister<IPreloadedValues>;
}

const FormSelect = ({ label, value, options, required, register }: IProps) => {
    const { t } = useTranslation("support");
    return (
        <div className="flex flex-col font-bold mb-10">
            <label className="mb-1.5">
                {label} {required ? "*" : ""}
            </label>
            <select
                {...register(value, { required: required })}
                className="py-3 px-4 bg-transparent border border-mhdarkgreen-original"
            >
                <option value="" disabled>
                    {t("select")} {lowerFirst(label)}
                </option>
                {options?.map((item) => (
                    <option key={item.description} value={item.value}>
                        {item.description}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default FormSelect;
