import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectors } from "../../redux";
import { IData } from "../DropdownFilter";
import ShowBasedOnFilter from "./ShowBasedOnFilter";
import Icon from "components/Icon";

export default function Index() {
    const { t } = useTranslation("followup");
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const [open, setOpen] = useState(false);

    const Data = useMemo((): IData[] => {
        const employeeText: IData = {
            text: t("employee"),
            name: "employee",
            field: "showBasedOn",
        };
        switch (currentSearch) {
            case "absencePeriod":
            case "ongoingAbsence": {
                return [
                    employeeText,
                    {
                        text: t("numberOfAbsenceOccasions"),
                        name: "numberOfAbsenceOccasions",
                        field: "showBasedOn",
                    },
                ];
            }
            case "cases": {
                return [
                    employeeText,
                    {
                        text: t("numberOfCases"),
                        name: "numberOfCases",
                        field: "showBasedOn",
                    },
                ];
            }
            case "activities": {
                return [
                    employeeText,
                    {
                        text: t("numberOfActivities"),
                        name: "numberOfActivities",
                        field: "showBasedOn",
                    },
                ];
            }
            default: {
                return [
                    employeeText,
                    {
                        text: t("numberOfAbsenceOccasions"),
                        name: "numberOfAbsenceOccasions",
                        field: "showBasedOn",
                    },
                ];
            }
        }
    }, [currentSearch, t]);

    const Item = useMemo(
        () => (name: string) => (
            <div className="flex items-center text-sm font-sans px-4 py-[6px] hover:bg-mhgrey-light">{name}</div>
        ),
        [],
    );
    const Header = useMemo(
        () => (name: string) => (
            <div onClick={() => setOpen(!open)} className="cursor-pointer select-none">
                <div className="flex items-center gap-[9px]">
                    <p className="text-grey-600 text-sm">{t("showBasedOn")}:</p>
                    <div className="flex items-center gap-[3px]">
                        <p className="text-sm">{t(name)}</p>
                        <Icon icon={open ? "caretUp" : "caretDown"} size={8} />
                    </div>
                </div>
            </div>
        ),
        [open, t],
    );

    return <ShowBasedOnFilter open={open} setOpen={setOpen} header={Header} data={Data} item={Item} />;
}
