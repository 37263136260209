import {
    getServiceSlice,
    getAdvisoryService,
    getOrganizationInfo,
    getSocialInsuranceDeviantRule,
    getSocialInsuranceConfiguration,
    getWorkOrderInfoWithSortedFollowupDays,
} from "pages/service/redux/serviceSelectors";
import {
    saveWorkOrderConfiguration,
    saveAbsenceFollowUpConfiguration,
    saveSocialInsuranceConfiguration,
} from "pages/service/redux/serviceAsyncActions";
import { compact } from "lodash/fp";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useFieldArray, useForm } from "react-hook-form";
import { WorkOrderConfiguration } from "swagger/businessupport";
import useDispatchWithToast from "pages/service/hooks/useDispatchWithToast";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { ServiceFieldArray, ServiceFieldArrayAppend } from "../ServiceFieldArray";
import { ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import { MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel } from "swagger/absencefollowup";

interface IFollowUpServices {
    socialInsuranceConfiguration?: MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel;
    workOrderConfiguration?: WorkOrderConfiguration;
}

const FollowUpServices = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const { region } = useAppSelector(getOrganizationInfo);
    const advisoryService = useAppSelector(getAdvisoryService);
    const { companyId, customerId } = useAppSelector(getServiceSlice);
    const socialInsuranceConfiguration = useAppSelector(getSocialInsuranceConfiguration);
    const workOrderConfiguration = useAppSelector(getWorkOrderInfoWithSortedFollowupDays);
    const { hasSocialInsuranceDeviantRule } = useAppSelector(getSocialInsuranceDeviantRule);
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        control,
        getValues,
        formState: { isDirty, dirtyFields },
    } = useForm({ defaultValues: { socialInsuranceConfiguration, workOrderConfiguration } });

    const {
        register: registerInput,
        getValues: getInputValue,
        resetField: resetInputField,
        formState: { isValid: inputIsValid },
    } = useForm({
        defaultValues: { input: undefined },
    });

    useEffect(() => {
        reset({ socialInsuranceConfiguration, workOrderConfiguration });
    }, [reset, socialInsuranceConfiguration, workOrderConfiguration]);

    const dispatchWithToast = useDispatchWithToast();

    const onSubmit = (data: IFollowUpServices) => {
        setIsLoading(true);
        const promises = [];

        if (dirtyFields.socialInsuranceConfiguration?.socialInsuranceReporting) {
            if (data.socialInsuranceConfiguration?.socialInsuranceReporting) {
                promises.push(
                    dispatchWithToast(
                        saveSocialInsuranceConfiguration({
                            companyId,
                            customerId,
                            socialInsuranceReporting: true,
                        }),
                        t("socialInsuranceReporting"),
                        () => reset({ socialInsuranceConfiguration }),
                    ),
                );
            } else {
                promises.push(
                    dispatchWithToast(
                        saveSocialInsuranceConfiguration({
                            ...socialInsuranceConfiguration,
                            socialInsuranceReporting: false,
                        }),
                        t("socialInsuranceReporting"),
                        () => reset({ socialInsuranceConfiguration }),
                    ),
                );
            }
        }

        const days = compact(
            data.workOrderConfiguration?.followUpAdvisoryConfigurations?.map((x) => x.followUpAdvisorySickDay),
        );

        if (dirtyFields.workOrderConfiguration?.followUpAdvisoryConfigurations) {
            if (data.workOrderConfiguration?.id && data.workOrderConfiguration?.followUpAdvisoryConfigurations) {
                promises.push(
                    dispatchWithToast(
                        saveAbsenceFollowUpConfiguration({
                            id: data.workOrderConfiguration.id,
                            days: days,
                        }),
                        t("followUpServices"),
                        () => reset({ workOrderConfiguration }),
                    ),
                );
            } else {
                // if company does not have a work order configuration, create one
                promises.push(
                    dispatch(
                        saveWorkOrderConfiguration({
                            allowDirectAdvisoryCallback:
                                advisoryService === "dynamicMedicalAdvisory" ||
                                advisoryService === "requestedMedicalAdvisory",
                            mandatoryAdvisoryCallback: advisoryService === "mandatoryCallback",
                        }),
                    )
                        .then(unwrapResult)
                        .then((res) => {
                            toast(t("saved") + " " + "workOrderConfiguration"),
                                dispatch(
                                    saveAbsenceFollowUpConfiguration({
                                        id: res.id,
                                        days: days,
                                    }),
                                )
                                    .then(unwrapResult)
                                    .then(() => toast(t("saved") + " " + t("followUpServices")))
                                    .catch(() => {
                                        toast(t("error") + ": " + t("followUpServices"), {
                                            type: "error",
                                        });
                                        reset({ workOrderConfiguration });
                                    });
                        })
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + "workOrderConfiguration", { type: "error" });
                            reset({ workOrderConfiguration });
                        }),
                );
            }
        }

        Promise.allSettled(promises).finally(() => setIsLoading(false));
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: "workOrderConfiguration.followUpAdvisoryConfigurations",
    });

    const checkForDuplicateValue = (value?: number) =>
        !getValues("workOrderConfiguration.followUpAdvisoryConfigurations")?.some(
            (x) => x.followUpAdvisorySickDay === value,
        );

    const socialInsuranceIsDisabed = () =>
        // disabled if no deviant rule unless already checked
        (!hasSocialInsuranceDeviantRule && !socialInsuranceConfiguration?.socialInsuranceReporting) ||
        // disabled if not sweden unless it is already checked
        (region?.country !== "Sweden" && !socialInsuranceConfiguration?.socialInsuranceReporting);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("followUpServices")}
                reset={() => reset({ socialInsuranceConfiguration, workOrderConfiguration })}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <>
                        {socialInsuranceConfiguration?.socialInsuranceReporting && (
                            <AccordionBooleanContent label={t("socialInsuranceReporting")} />
                        )}
                        {workOrderConfiguration?.followUpAdvisoryConfigurations?.map(
                            ({ id, followUpAdvisorySickDay }) => (
                                <AccordionBooleanContent
                                    key={id}
                                    label={`${t("advisoryFollowUpDay")} ${followUpAdvisorySickDay}`}
                                />
                            ),
                        )}
                    </>
                }
            >
                {
                    <ServiceCheckboxContainer
                        heading={t("socialInsurance")}
                        checkboxHeading={t("offOn")}
                        info={t("needsSocialInsuranceDeviantRule")}
                    >
                        <ServiceCheckboxRow
                            label={t("socialInsuranceReporting")}
                            register={register("socialInsuranceConfiguration.socialInsuranceReporting")}
                            disabled={socialInsuranceIsDisabed()}
                        />
                    </ServiceCheckboxContainer>
                }

                <ServiceCheckboxContainer heading={t("advisoryFollowUpDay")} className="pt-0">
                    {fields.map((item, index) => (
                        <ServiceFieldArray
                            key={index}
                            index={index}
                            label={`${t("followUpDay")}: ${item.followUpAdvisorySickDay}`}
                            onClick={() => remove(index)}
                        />
                    ))}
                    <ServiceFieldArrayAppend
                        buttonText={t("addDay")}
                        onClick={() => {
                            append({
                                absenceReportType: "Sick",
                                followUpAdvisorySickDay: getInputValue("input"),
                            });
                            resetInputField("input");
                        }}
                        type="number"
                        register={registerInput("input", {
                            valueAsNumber: true,
                            required: true,
                            validate: (value) => checkForDuplicateValue(value),
                            min: 1,
                            max: 999,
                        })}
                        disabled={!inputIsValid || advisoryService === "fullyAutomatic"}
                    />
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default FollowUpServices;
