import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback } from "react";
import SearchTypesDropdown from "./SearchTypesDropdown";
import { selectors, actions } from "../../redux";
import { SearchTypes } from "../../utils";

export default function Index() {
    const dispatch = useAppDispatch();
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const searchTypes = useAppSelector(selectors.getAvailableSearchTypes);
    const searchCaseTypes = useAppSelector(selectors.getAvailableCaseSearchTypes);
    const setCurrentSearch = useCallback(
        (value: SearchTypes | null) => {
            dispatch(actions.updateCurrentSearch(value || "absencePeriod"));
        },
        [dispatch],
    );
    return (
        <SearchTypesDropdown
            data={currentSearch}
            setData={setCurrentSearch}
            searchTypes={searchTypes}
            searchCaseTypes={searchCaseTypes}
        />
    );
}
