import _ from "lodash";
import { ActiveModifiers, DateRange } from "react-day-picker";
import { getDateOrUndefined, getStringFromDate, isAfterDate, isBeforeDate } from "utils/date";
import { StringDateRange } from "../types";

export const getDateRangeFromString = (stringDateRange: StringDateRange): DateRange => {
    const dateRange = {
        from: getDateOrUndefined(stringDateRange.from),
        to: getDateOrUndefined(stringDateRange.to),
    };
    return dateRange;
};

export const setDateStringFromArray = (dateArray: number[], createDateTime?: boolean): string | undefined => {
    let date = undefined;
    // To create a date in date-fns you need atleast 3 numbers: years, months and days, new Date(2023, 02, 24).
    // If you want hours and minutes you add another two numbers: new Date(2023, 02, 24, 12, 45) = 2023-02-24 12:45.
    // The dateArray can be translated to this: [Year, month, day, hour, minute]
    // If dateArray contains 3 numbers we know its a valid date and can cast it to a date.
    if (dateArray?.length > 2 && dateArray[0] !== -1) {
        const dateWithoutHoursOrMinutes = _.cloneDeep(dateArray);
        dateWithoutHoursOrMinutes.length = 3;
        const stringArray = dateWithoutHoursOrMinutes.map((value) => {
            const stringValue = value.toString();
            if (stringValue.length < 2) return `0${stringValue}`;
            return stringValue;
        });
        const dateObject = stringArray.join("-");
        date = new Date(dateObject);
        // If createDateTime is true we know the array also contains hour and minute so we add hours and minutes to the date.
        if (createDateTime) date = new Date(date.setHours(dateArray[3], dateArray[4]));
    }
    return date ? getStringFromDate(date) : date;
};

export const setArrayFromDateString = (
    dateString: string | undefined,
    dateAsNumbers?: number[],
    noTimeSelected?: boolean,
): number[] => {
    // To check if a datetime is valid we cast it to an array with 5 numbers.
    // if the array contains -1 the datetime is not valid.
    // dateString is the new date selected from the datepicker.
    // dateAsNumbers is the selected dateTime (datepicker + timepicker values).
    let dateArray = [-1, -1, -1, -1, -1];
    const date = getDateOrUndefined(dateString);
    if (date) {
        dateArray = [date.getFullYear(), date.getMonth() + 1, date.getDate(), -1, -1];
        if (noTimeSelected) return dateArray;

        dateArray[3] = dateAsNumbers ? dateAsNumbers[3] : date.getHours();
        dateArray[4] = dateAsNumbers ? dateAsNumbers[4] : date.getMinutes();
    }
    return dateArray;
};

export const getNewValue = (
    newDate: string,
    range: StringDateRange | undefined,
    focusedDisplayValue: string | null,
): StringDateRange => {
    let newDateRangeValue: StringDateRange = {
        from: range?.from ? range.from : undefined,
        to: range?.to ? range.to : undefined,
    };
    if (focusedDisplayValue === "from") {
        newDateRangeValue.from = newDate;
        if (isAfterDate(newDateRangeValue.from, newDateRangeValue.to)) newDateRangeValue.to = newDateRangeValue.from;
    } else if (focusedDisplayValue === "to") {
        newDateRangeValue.to = newDate;
        if (isBeforeDate(newDateRangeValue.to, newDateRangeValue.from)) newDateRangeValue.from = newDateRangeValue.to;
    } else {
        newDateRangeValue.from = newDate;
    }
    return newDateRangeValue;
};

export const getActiveModifiersAsCssClasses = (activeModifiers: ActiveModifiers): string => {
    let cssClasses = "";
    if (activeModifiers.range_start) cssClasses = cssClasses + " range_start";
    if (activeModifiers.range_middle) cssClasses = cssClasses + " range_middle";
    if (activeModifiers.range_end) cssClasses = cssClasses + " range_end";
    if (activeModifiers.hover_range) cssClasses = cssClasses + " hover_range";
    if (activeModifiers.hover_range_start) cssClasses = cssClasses + " hover_range_start";
    if (activeModifiers.hover_range_end) cssClasses = cssClasses + " hover_range_end";

    return cssClasses;
};

export const getDayButtonCss = (activeModifiers: ActiveModifiers, isDoubledatePicker: boolean | undefined) => {
    let buttonCss = " hover:!bg-[#bbc0b3] hover:rounded-full";
    if (activeModifiers.disabled) buttonCss = buttonCss + " text-grey-350 cursor-not-allowed";
    else {
        if (!activeModifiers.selected && activeModifiers.today && !activeModifiers.hover_range)
            buttonCss = buttonCss + " border !border-grey-300";
        if (!isDoubledatePicker && activeModifiers.selected)
            buttonCss = buttonCss + " !text-grey-200 !bg-inputDatepicker";
        if (isDoubledatePicker) {
            if (activeModifiers.range_start || activeModifiers.range_end) {
                buttonCss = buttonCss + " !text-mhbeige !bg-inputDatepicker !rounded-full";
            }
            if (activeModifiers.range_middle) buttonCss = buttonCss + " !text-mhdarkgreen-original !bg-transparent";
        }
    }
    return buttonCss;
};

export const getRangedBackground = (activeModifiers: ActiveModifiers): string => {
    if (
        activeModifiers.range_start ||
        activeModifiers.range_middle ||
        activeModifiers.range_end ||
        activeModifiers.hover_range
    )
        return "bg-grey-200";
    return "";
};

export const getRangedBorderRadius = (activeModifiers: ActiveModifiers): string => {
    if (activeModifiers.range_start) {
        if (activeModifiers.range_end && activeModifiers.hover_range_end) {
            return "rounded-full";
        }
        return "rounded-l-full";
    }
    if (activeModifiers.range_end && !activeModifiers.hover_range) {
        return "rounded-r-full";
    }
    if (activeModifiers.hover_range_end && !activeModifiers.range_middle) return "rounded-r-full";
    return "";
};
