import { useTranslation } from "react-i18next";
import { IEmploymentSearchResult } from "../../interfaces/IEmploymentSearchResult";
import SearchResult from "components/SearchResult/SearchResult";
import ArchiveTag from "pages/FollowUp/components/ArchiveTag/ArchiveTag";

export interface ISearchResultUserProps {
    searchResult: IEmploymentSearchResult;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isArchived?: boolean;
}

const SearchResultUser = ({ searchResult, onClick, isArchived }: ISearchResultUserProps) => {
    const { t } = useTranslation();
    return (
        <button
            type="button"
            data-cy={`searchresult-${searchResult.id}`}
            data-testid={`searchresult-${searchResult.id}`}
            className="w-full flex items-center list-hover px-6 border-t border-grey-200"
            onClick={onClick}
        >
            <SearchResult
                id={`${searchResult.id}`}
                icon="person"
                header={
                    <div className="flex gap-4">
                        <p className="text-mhdarkgreen-original">{`${searchResult.name} - ${searchResult.companyName}`}</p>
                        {isArchived && <ArchiveTag />}
                    </div>
                }
                searchResultItems={[
                    `${t("socialSecurityNr")}: ${searchResult.socialSecurityNumber}`,
                    `${t("employmentNr")}: ${searchResult.employmentNumber || ""}`,
                    `${t("department")}: ${
                        searchResult.departmentNames && searchResult.departmentNames.length > 0
                            ? searchResult.departmentNames[0]
                            : ""
                    }`,
                ]}
            />
        </button>
    );
};
export default SearchResultUser;
