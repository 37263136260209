import { useAppSelector } from "store/hooks";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { canShowMedicalAppendix } from "store/userSelectors";

const useAppendixAccessRights = () => {
    const { routeEmploymentGuid } = useParams();
    const canShowAppendix = useAppSelector(canShowMedicalAppendix);

    return useMemo(() => {
        return !routeEmploymentGuid || canShowAppendix;
    }, [canShowAppendix, routeEmploymentGuid]);
};
export default useAppendixAccessRights;
