import { useCallback, useEffect, useState } from "react";

/**
 *
 * @param callback triggers on click and returns if click was inside or outside.
 * @returns ref function. Add it to the html element you want to detect ie ref={setNode}.
 */
export const useElementClicked = (callback: (elementClicked: boolean) => void) => {
    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    const setNode = useCallback((node: HTMLDivElement) => {
        setContainer(node);
    }, []);

    useEffect(() => {
        if (container) {
            const onElementClick = (event: MouseEvent) => {
                if (event.target) {
                    callback(container.contains(event.target as Node));
                }
            };
            window.addEventListener("click", onElementClick, true);
            return () => {
                window.removeEventListener("click", onElementClick, true);
            };
        }
    }, [callback, container]);

    return setNode;
};
