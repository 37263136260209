import Select from "@medhelp/ui/Select";
import { useTranslation } from "react-i18next";
import UserAccordion, { IUserAccordionDisplayProps } from "../UserAccordion/UserAccordion";
import { useState } from "react";
import { IUserSettingsProps, UserSettingsComponents } from "pages/UserSettings/types";
import { ISelectValue } from "@medhelp/ui/Select/Select";
import Icon from "components/Icon";

const SetLanguage = ({ handleUpdateComponent, userPreferences, isLoading }: IUserSettingsProps) => {
    const { t } = useTranslation("userSettings");
    const userPrefLang = userPreferences.language;

    const languageOptions: ISelectValue[] = [
        {
            description: "",
            value: "sv",
            nonTranslatedDesc: "sv",
        },
        {
            description: "",
            value: "da",
            nonTranslatedDesc: "dk",
        },
        {
            description: "",
            value: "no",
            nonTranslatedDesc: "no",
        },
        {
            description: "",
            value: "en",
            nonTranslatedDesc: "en",
        },
    ];

    const getUserPrefAsLangObject = (): ISelectValue => {
        return (
            languageOptions.find((c) => {
                if (userPrefLang === "da") return c.nonTranslatedDesc === "dk";
                return c.nonTranslatedDesc === userPrefLang;
            }) || {
                description: t("chooseLanguage"),
                value: "",
                nonTranslatedDesc: "chooseLanguage",
            }
        );
    };

    const [language, setLanguage] = useState<ISelectValue>(getUserPrefAsLangObject());

    const languageDisplayProps: IUserAccordionDisplayProps[] = [
        {
            description: t("chosenLanguage"),
            value: t(language.nonTranslatedDesc!) || t("notSelected"),
        },
    ];

    return (
        <div>
            <UserAccordion
                heading={t("language")}
                displayProps={languageDisplayProps}
                saveClicked={() =>
                    handleUpdateComponent({
                        componentToUpdate: UserSettingsComponents.Language,
                        language: { language: language.value },
                    })
                }
                onClose={() => setLanguage(getUserPrefAsLangObject())}
                disableButton={language.value === userPreferences.language}
                isLoading={isLoading}
            >
                <Select
                    containerClassName="xs:w-60"
                    options={languageOptions}
                    selectedValue={{ ...language, icon: <Icon icon="globe" /> }}
                    onChange={(v) => setLanguage(v)}
                    placeholder=""
                    translationKey={"userSettings"}
                />
            </UserAccordion>
        </div>
    );
};

export default SetLanguage;
