import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchPrivacyPolicy } from "../redux/aboutActions";
import { getPrivacyPolicy } from "../redux/aboutSelectors";

const PrivacyPolicy = () => {
    const dispatch = useAppDispatch();
    const privacyPolicyContent = useAppSelector(getPrivacyPolicy);

    useEffect(() => {
        !privacyPolicyContent && dispatch(fetchPrivacyPolicy());
    }, [dispatch, privacyPolicyContent]);

    return privacyPolicyContent?.value ? (
        <div
            dangerouslySetInnerHTML={{
                __html: privacyPolicyContent.value,
            }}
        />
    ) : null;
};

export default PrivacyPolicy;
