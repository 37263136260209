import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSubmitAccessRights from "pages/service/hooks/useSubmitAccessRights";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { IAccessRightConfiguration } from "pages/service/redux/serviceAsyncActions";
import { ServiceCheckbox, ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import { getDepartmentManagerAccessRights } from "pages/service/redux/serviceSelectors";

const DepartmentManagerAccessRights = () => {
    const { t } = useTranslation("service");
    const departmentManagerAccessRights = useAppSelector(getDepartmentManagerAccessRights);
    const [submitAccessRights, isLoading] = useSubmitAccessRights();

    const {
        handleSubmit,
        reset,
        resetField,
        getValues,
        setValue,
        register,
        formState: { isDirty, dirtyFields },
    } = useForm({
        defaultValues: { departmentManagerAccessRights },
    });

    useEffect(() => {
        reset({ departmentManagerAccessRights });
    }, [departmentManagerAccessRights, reset]);

    const onSubmit = (data: { departmentManagerAccessRights: IAccessRightConfiguration[] }) => {
        submitAccessRights(
            data.departmentManagerAccessRights,
            dirtyFields,
            //@ts-ignore
            resetField,
            "departmentManagerAccessRights",
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("departmentManagerAccessRights")}
                reset={() => reset({ departmentManagerAccessRights })}
                disabled={!isDirty}
                isLoading={isLoading}
                content={departmentManagerAccessRights?.map(
                    ({ accessRightType, checked }) =>
                        checked && <AccordionBooleanContent key={accessRightType} label={t(accessRightType)} />,
                )}
            >
                <ServiceCheckboxContainer
                    heading={t("accessRight")}
                    checkboxHeading={t("offOn")}
                    secondaryCheckBoxHeading={t("hierarchical")}
                >
                    {departmentManagerAccessRights?.map((item, index) => (
                        <ServiceCheckboxRow key={item.accessRightType} label={t(item.accessRightType || "")}>
                            <ServiceCheckbox
                                register={register(`departmentManagerAccessRights.${index}.checked`, {
                                    onChange: (e) =>
                                        !e.target.checked &&
                                        setValue(`departmentManagerAccessRights.${index}.hierarchical`, false),
                                })}
                                disabled={item.disabled}
                            />
                            <fieldset disabled={!getValues(`departmentManagerAccessRights.${index}.checked`)}>
                                <ServiceCheckbox
                                    register={register(`departmentManagerAccessRights.${index}.hierarchical`)}
                                />
                            </fieldset>
                        </ServiceCheckboxRow>
                    ))}
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default DepartmentManagerAccessRights;
