import * as React from "react";
import { useCallback, useState } from "react";
import AdminInput from "../../AdminInput";
import { getChannelText } from "pages/Administration/utils";
import { RadioButton } from "../..";
import { useTranslation } from "react-i18next";
import { UpdateProps } from "../CreateNewSubscription/CreateNewSubscription";
import AddUserPhoneNumberComp from "../../AddUser/PhoneNumber/AddUserPhoneNumberComp";
import PrimaryButton from "components/PrimaryButton";
import { isValidEmail } from "pages/login/utils";
import { SubscriptionLookup } from "swagger/employeeattendance";
import { Heading } from "@medhelp/ui";

export interface INewExternalSubscriberStateProps {
    name: string;
    searchIsLoading: boolean;
    newSubscriberExists?: SubscriptionLookup;
    selectedChannel: number;
}
export interface INewExternalSubscriberPageProps extends INewExternalSubscriberStateProps {
    newSubscriptionOnChange: (propName: UpdateProps, value: string | number) => void;
}

const NewExternalSubscriber = ({
    selectedChannel,
    newSubscriptionOnChange,
    searchIsLoading,
    newSubscriberExists,
}: INewExternalSubscriberPageProps) => {
    const { t } = useTranslation("administration");
    // This will be used later when name can be added to user
    // const [name, setName] = useState(newSubscriberExists?.name ?? "");
    const [email, setEmail] = useState("");
    const [invalidEmailText, setInvalidEmailText] = useState<string | undefined>();
    const [smsNumber, setSmsNumber] = useState("+46");
    const [phoneIsValid, setPhoneIsValid] = useState(false);
    const handleEmailOnChange = useCallback(
        (v: string) => {
            let errorMessage: string | undefined = undefined;
            if (v.length > 0) errorMessage = isValidEmail(v) ? "" : t("invalidEmail");
            if (!errorMessage) setEmail(v);
            setInvalidEmailText(errorMessage);
        },
        [t],
    );

    return (
        <div className="flex flex-col">
            {/* This will be used later when name can be added to user */}
            {/* <AdminInput
                heading={t("name")}
                savedValue={name}
                onChange={setName}
                onBlur={() =>
                    newSubscriptionOnChange("newExternalSubName", name)
                }
                disabled={newSubscriberExists !== undefined}
            /> */}
            <div className="my-6">
                <Heading heading={t("notificationBy")} mandatory />
                <div className="flex">
                    {[1, 2].map((channel) => (
                        <div className="flex" key={channel}>
                            <RadioButton
                                checked={selectedChannel === channel}
                                setChecked={() => newSubscriptionOnChange("newExternalSubChannel", channel)}
                            />
                            <p className="mr-4">{t(getChannelText(channel))}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="relative">
                {selectedChannel === 1 && (
                    <div>
                        <Heading heading={t("eMail")} mandatory />
                        <AdminInput savedValue={email} onChange={handleEmailOnChange} errorMessage={invalidEmailText} />
                    </div>
                )}
                {selectedChannel === 2 && (
                    <AddUserPhoneNumberComp
                        value={smsNumber}
                        onChange={setSmsNumber}
                        validateFields
                        isValid={setPhoneIsValid}
                        isMandatory
                    />
                )}
                {newSubscriberExists && <p className="pt-6">{t("selectedUserExist")}</p>}
                <PrimaryButton
                    id="add-user-save"
                    className={selectedChannel === 1 ? "mt-10" : "mt-2"}
                    text={t("select")}
                    onClick={() => {
                        const value = selectedChannel === 1 ? email : smsNumber;
                        // This will be used later when name can be added to user
                        // newSubscriptionOnChange(
                        //     "checkIfNewExternalUserExists",
                        //     value,
                        // );
                        newSubscriptionOnChange("externalUserContactKey", value);
                    }}
                    disabled={
                        selectedChannel === 1
                            ? invalidEmailText === undefined || !!invalidEmailText
                            : !smsNumber || smsNumber.length < 5 || !phoneIsValid
                    }
                    isLoading={searchIsLoading}
                />
            </div>
        </div>
    );
};

export default NewExternalSubscriber;
