import Icon from "components/Icon";
import { ChangeEvent, useCallback, useMemo } from "react";
import {
    getDateDisplayValue,
    useNavigation,
    getEachInterval,
    getStartOfDate,
    getEndOfDate,
    getDate,
    getStringFromDate,
    isAfterDate,
    getDateForwardOrBack,
} from "utils/date";

interface IMonthNavigationProps {
    disableDatesBefore: string;
    disableDatesAfter: string;
    month: string;
    setMonth: (month: string) => void;
}

const MonthNavigation = ({ disableDatesBefore, disableDatesAfter, month, setMonth }: IMonthNavigationProps) => {
    const { nextMonth, previousMonth } = useNavigation();

    const months: Date[] = useMemo(
        () =>
            getEachInterval({
                timeUnit: "months",
                fromDate: getStartOfDate("years"),
                toDate: getEndOfDate("years"),
            }),
        [],
    );

    const years: Date[] = useMemo(() => {
        const fromDate = getDate(disableDatesBefore);
        const toDate = getDate(disableDatesAfter);
        return getEachInterval({
            timeUnit: "years",
            fromDate: fromDate,
            toDate: isAfterDate(toDate, fromDate) ? toDate : getDateForwardOrBack(1, "months", "forward", fromDate),
        });
    }, [disableDatesBefore, disableDatesAfter]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const yearOrMonth = Number(event.target.value);
            const selectedDate =
                yearOrMonth > 12
                    ? new Date(yearOrMonth, getDate(month).getMonth())
                    : new Date(getDate(month).getFullYear(), yearOrMonth);
            setMonth(getStringFromDate(selectedDate));
        },
        [month, setMonth],
    );

    return (
        <div id="custom-navigation-container" className="w-full p-4 flex justify-between">
            <button onClick={() => previousMonth && setMonth(getStringFromDate(previousMonth))}>
                <Icon
                    icon="caretLeft"
                    size={12}
                    className={
                        previousMonth?.getMonth() === getDate(month).getMonth()
                            ? "text-grey-350 cursor-not-allowed"
                            : ""
                    }
                />
            </button>
            <div className="w-auto flex justify-center">
                <select className="mr-4" name="month" onChange={handleChange} value={getDate(month).getMonth()}>
                    {months.map((month) => (
                        <option className="w-auto flex" key={month.toDateString()} value={month.getMonth()}>
                            {getDateDisplayValue(month, "MMM")}
                        </option>
                    ))}
                </select>
                <select name="year" onChange={handleChange} value={getDate(month).getFullYear()}>
                    {years.map((year) => (
                        <option key={year.getFullYear()} value={year.getFullYear()}>
                            {year.getFullYear()}
                        </option>
                    ))}
                </select>
            </div>
            <button onClick={() => nextMonth && setMonth(getStringFromDate(nextMonth))}>
                <Icon
                    icon="caretRight"
                    size={12}
                    className={
                        nextMonth?.getMonth() === getDate(month).getMonth() ? "text-grey-350 cursor-not-allowed" : ""
                    }
                />
            </button>
        </div>
    );
};

export default MonthNavigation;
