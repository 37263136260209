import PrimaryButton from "components/PrimaryButton";
import { useTranslation } from "react-i18next";
import BackButtonHeader from "../BackButtonHeader";
import { createInitLoginModel } from "pages/login/utils";
import { ReactComponent as QrTutorialIcon } from "icons/login/qrTutorial.svg";
import { useEffect, useState } from "react";
import { BankIdLoginType, ILoginPageData, LoginUsing } from "pages/login/types";
import BankIdPending from "./BankIdPending";
import { Spinner } from "components/Spinner";

const MobileBankId = (loginPageData: ILoginPageData) => {
    const { isLoading, bankIdProps, loginUsing, returnUrl } = loginPageData;
    const { t } = useTranslation("login");

    const [qrCode, setQrCode] = useState<string>("");

    const getQrCode = () => {
        loginUsing({
            loginUsing: LoginUsing.mobileBankIdQrCode,
            bankIdInitGrandId: createInitLoginModel(BankIdLoginType.bankIdOtherDevice),
        });
    };

    const handleBackClicked = () => {
        loginUsing({
            loginUsing: LoginUsing.default,
            navigateOrAbort: {
                navigateTo: "bankIdOptions",
                resetBankIdData: true,
            },
        });
    };

    useEffect(() => {
        if (bankIdProps.statusResult.qrCode) {
            setQrCode(`data:image/svg+xml;utf8,${encodeURIComponent(bankIdProps.statusResult.qrCode)}`);
        }
    }, [bankIdProps.statusResult.qrCode]);

    const showQrTutorial = !isLoading && qrCode && bankIdProps.totalTime < 30;
    const showQrErrorMsg = bankIdProps.totalTime > 29 || bankIdProps.bankIdRejected;

    return (
        <div className="mb-14">
            <BackButtonHeader heading={t("mobileBankId")} onClick={handleBackClicked} />
            {!bankIdProps.bankIdPending && (
                <>
                    <div className="flex flex-col items-center">
                        {isLoading && (
                            <div className="h-48 w-48">
                                <Spinner />
                            </div>
                        )}
                        {showQrTutorial && (
                            <>
                                <div className="flex h-52 w-52 items-center justify-center">
                                    <img src={qrCode} alt="" />
                                </div>

                                <div className="flex mt-16">
                                    <div className="mr-6 flex">
                                        <QrTutorialIcon className="h-26" />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="font-bold">{t("instructions")}</p>
                                        <ol className="list-decimal ml-5">
                                            <li>{t("instructionOne")}</li>
                                            <li>{t("instructionTwo")}</li>
                                            <li>{t("instructionThree")}</li>
                                        </ol>
                                    </div>
                                </div>
                            </>
                        )}
                        {showQrErrorMsg && (
                            <div className="flex flex-col w-full">
                                <p className="mb-10">
                                    {bankIdProps.bankIdRejected ? t("qrSomethingWentWrong") : t("qrCodeExpired")}
                                </p>
                                <PrimaryButton
                                    id="qrCodeTryAgain"
                                    className="flex justify-center"
                                    onClick={getQrCode}
                                    text={t("tryAgain")}
                                />
                            </div>
                        )}
                    </div>

                    <div className="w-full flex justify-center">
                        <button type="button" className="mt-10 w-max flex" onClick={handleBackClicked}>
                            <p className="font-bold border-b border-linkBorderBottom hover:border-linkBorderBottomHover">
                                {t("cancel")}
                            </p>
                        </button>
                    </div>
                </>
            )}
            {bankIdProps.bankIdPending && <BankIdPending {...{ ...loginPageData, returnUrl }} />}
        </div>
    );
};

export default MobileBankId;
