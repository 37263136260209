import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { thunkActions } from "../../../redux";
import {
    getUserEmployment,
    getCaseResponsibleList,
    getCaseResponsibleListStatus,
} from "../../../redux/followUpSelectors";
import { getUserManagerName } from "../../../../Absence/pages/absencereporting/redux/absenceReportsSelectors";
import { MedHelpHCMWebApiResponseResponsibleResponse } from "swagger/healthCaseManagement";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { toast } from "react-toastify";
import { getDateDisplayValue } from "utils/date";
import { ColumnText, Error, ModalCommonFooter, ModalFrame } from "@medhelp/ui";
import { Status } from "@medhelp/ui/LoadableComponent";
import Icon from "components/Icon";

interface IProps {
    healthCase: IHealthCase;
    modalIsOpen: boolean;
    setModalIsOpen: () => void;
}
const CaseResponsibleModal = (props: IProps) => {
    const { healthCase, modalIsOpen, setModalIsOpen } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const managerName = useAppSelector(getUserManagerName);
    const userEmployment = useAppSelector(getUserEmployment);
    const caseResponsibleList = useAppSelector(getCaseResponsibleList);
    const caseResponsibleListStatus = useAppSelector(getCaseResponsibleListStatus);
    const [caseResponsible, setCaseResponsible] = useState<MedHelpHCMWebApiResponseResponsibleResponse | undefined>(
        undefined,
    );
    const [showDropdown, setShowDropdown] = useState(false);
    const [userInput, setUserInput] = useState("");

    useEffect(() => {
        if (userEmployment?.id) {
            dispatch(thunkActions.fetchCaseResponsibleList(userEmployment.id));
        }
    }, [dispatch, userEmployment?.id]);

    const caseDetails = [
        {
            header: t("reason"),
            body: (
                <div className="flex flex-col">
                    {healthCase?.healthCaseCauses?.map((cause, index) => (
                        <div key={`${healthCase.id}-${cause.cause}-${index}`}>{cause.cause}</div>
                    ))}
                </div>
            ),
            key: `case-responsible-reason-${healthCase?.id}`,
        },
        {
            header: t("startDate"),
            body: healthCase?.start ? getDateDisplayValue(healthCase.start, "d MMM yyyy") : "-",
            key: `case-responsible-start-${healthCase?.id}`,
        },
        {
            header: t("caseResponsibleNow"),
            body: healthCase?.responsible ?? "-",
            key: `case-responsible-now-${healthCase?.id}`,
        },
        {
            header: t("closestManager"),
            body: managerName ?? "-",
            key: `case-responsible-manager-${healthCase?.id}`,
        },
    ];

    const handleUpdateCaseResponsible = () => {
        if (healthCase?.id) {
            dispatch(
                thunkActions.updateCaseResponsible({
                    healthCaseId: healthCase.id,
                    updateCaseResponsibleModel: {
                        userAccountId: caseResponsible?.userAccountId,
                        userName: caseResponsible?.name,
                    },
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("caseResponsibleChanged")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("caseResponsibleNotChanged")}`, {
                        type: "error",
                    }),
                );
        }
        setModalIsOpen();
    };

    const filteredSelectValues = caseResponsibleList.filter(
        (user) => user?.name && user.name.toLowerCase().includes(userInput.toLowerCase()),
    );

    return (
        <ModalFrame
            id="case-responsible-modal"
            header={<h1>{t("changeCaseResponsible")}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            content={
                <div className="px-3 sm:px-0">
                    <div className="flex flex-col sm:flex-row flex-wrap pb-8 items-start">
                        {caseDetails.map((item) => (
                            <ColumnText {...item} className="pl-0" key={item.key} />
                        ))}
                    </div>
                    <p className="mb-6">{t("caseResponsibleInfoText")}</p>
                    {caseResponsibleListStatus === Status.ERROR ? (
                        <Error text={t("couldNotFetchContent")} />
                    ) : (
                        <>
                            <h5 className="font-bold pt-8 pb-2">{t("selectCaseResponsible")}</h5>
                            <div className="flex items-center justify-between h-12 p-2 border border-mhdarkgreen-original">
                                <p>{caseResponsible?.name}</p>
                                <button
                                    type="button"
                                    data-testid="toggle-dropdown"
                                    onClick={() => setShowDropdown(!showDropdown)}
                                >
                                    <Icon icon={showDropdown ? "caretUp" : "caretDown"} />
                                </button>
                            </div>
                            {showDropdown && (
                                <div className="relative z-20">
                                    <div className="shadow-custom w-full bg-pageBg absolute">
                                        <div>
                                            <div className="flex items-center border-mhgrey-medium border-b borderOpacity h-14 pl-4">
                                                <Icon icon="search" />
                                                <input
                                                    data-testid="search-input"
                                                    onChange={(e) => setUserInput(e.target.value)}
                                                    className="w-full search-bar bg-transparent border-none ml-4"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <ul className="pb-4 overflow-auto max-height-400">
                                            {filteredSelectValues?.map((item) => {
                                                return (
                                                    <li key={`case-responsible-search-item-${item.userAccountId}`}>
                                                        <button
                                                            onClick={() => {
                                                                {
                                                                    setCaseResponsible(item);
                                                                    setShowDropdown(!showDropdown);
                                                                }
                                                            }}
                                                            className="w-full text-left p-4 outline-none"
                                                        >
                                                            {`${item.name} - ${item.departments}`}
                                                        </button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    tertiaryOnClick={setModalIsOpen}
                    primaryText={t("save")}
                    primaryOnClick={handleUpdateCaseResponsible}
                    primaryDisabled={!caseResponsible}
                    primaryRightIcon={<Icon icon="checkMark" stroke="#192D05" />}
                />
            }
        />
    );
};

export default CaseResponsibleModal;
