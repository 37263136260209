import { useEffect, useState } from "react";
import { activateCompany, fetchCompanyInfo } from "./fortnoxServices";
import { ErrorMessage } from "./Fortnox";
import { FortnoxCompanyInfo } from "swagger/fortnox";

export const useCreateFortnoxIntegration = (code: string | null) => {
    const [data, setData] = useState<FortnoxCompanyInfo>({
        employees: [],
        fortnoxConfigurationId: "",
    });
    const [isLoading, setIsLoading] = useState(true);
    const [errorKey, setErrorKey] = useState<ErrorMessage | null>(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (!code) return;
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const res = await fetchCompanyInfo(code);
                if (!res.employees || res.employees.length === 0) {
                    setErrorKey("requestFailed");
                } else {
                    setData(res);
                }
            } catch (error) {
                setErrorKey("requestFailed");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [code]);

    const handleSubmit = async (employeeId: string) => {
        try {
            setIsLoading(true);
            if (data.fortnoxConfigurationId && employeeId)
                await activateCompany(data.fortnoxConfigurationId, employeeId);
            setErrorKey(null);
            setSuccess(true);
        } catch (error) {
            setErrorKey("submitError");
        } finally {
            setIsLoading(false);
        }
    };

    return { data, isLoading, errorKey, handleSubmit, success };
};
