import { useAppDispatch } from "store/hooks";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AsyncThunkAction } from "@reduxjs/toolkit";

export const useDispatchWithToast = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");

    const dispatchWithToast = (action: AsyncThunkAction<any, any, any>, text?: string, callback?: () => void) =>
        dispatch(action)
            .unwrap()
            .then(() => {
                toast(t("saved") + ": " + text);
            })
            .catch(() => {
                toast(t("error") + ": " + text, { type: "error" });
                callback && callback();
            });
    return dispatchWithToast;
};
export default useDispatchWithToast;
