import { twMerge } from "tailwind-merge";

export interface ITabs {
    key: string;
    text: string;
}
export interface TabMenuProps {
    tabs: ITabs[];
    activeTab: string;
    setActiveTab: (value: string) => void;
}

const TabMenu = ({ tabs, activeTab, setActiveTab }: TabMenuProps) => (
    <div className="flex gap-8 border-b pt-6 px-6">
        {tabs.map(({ key, text }) => (
            <button
                onClick={() => setActiveTab(key)}
                key={key}
                className={twMerge(
                    "flex gap-1.5 items-center pb-4 focus:shadow-none",
                    key === activeTab && "font-bold border-b-2 border-black -mb-0.5",
                )}
            >
                {text}
            </button>
        ))}
    </div>
);

export default TabMenu;
