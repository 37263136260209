import Icon from "components/Icon";
import { actions } from "../../../redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import SecondaryButton from "components/SecondaryButton";
import { getConfigurationByName } from "../../../redux/absenceReportsSelectors";
import { getCallbackMessage } from "./getCallbackMessage";

export const CallbackMessage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const hasDynamicMedicalAdvisory = useAppSelector(getConfigurationByName("dynamicMedicalAdvisory"));
    const callbackMessage = getCallbackMessage(hasDynamicMedicalAdvisory);

    const handleOnClose = () => {
        navigate("/absencereporting/");
        dispatch(actions.resetSelectedAbsenceReport());
    };

    return (
        <div className="w-full max-w-[668px] pt-10 flex flex-col gap-10">
            <div>
                <h2>{t("medicalAdvicoryHeading")}</h2>
                <div className="py-3">
                    <Icon icon="ellipsis" className="" />
                </div>
                <p>{t("youArePlacedInQueue")}</p>
                <br />
                <p>{t("thankYouForWaiting")}</p>
            </div>
            <div className="p-4 border-1 border-mhdarkgreen-original">
                <p className="text-lg font-bold">{callbackMessage}</p>
                <br />
                <p className="text-lg font-bold">{t("sender")}</p>
                <br />
                <p className="text-lg font-bold">{t("timeZoneCet")}</p>
            </div>
            <div className="flex w-full">
                <SecondaryButton
                    id="confirmation-close-button"
                    text={t("close")}
                    onClick={handleOnClose}
                    className="justify-center w-full xs:w-content"
                />
            </div>
        </div>
    );
};
