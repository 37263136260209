import { getTranslation } from "./excelTitles";
import { SearchTypes } from "./searchApi";
export const deviantAbsenceTypesNO = [
    {
        name: "RecurringPeriod",
        value: "Gjentatt fravær",
    },
    {
        name: "Recurring",
        value: "Gjentatt fravær",
    },
    {
        name: "days",
        value: "dager",
    },
    {
        name: "consecutiveDays",
        value: "påfølgende dager",
    },
    {
        name: "LongTerm",
        value: "Langtidsfravær",
    },
    {
        name: "Longterm",
        value: "Langtidsfravær",
    },
    {
        name: "3/3",
        value: "3 ganger på 3 måneder",
    },
    {
        name: "3/6",
        value: "3 ganger på 6 måneder",
    },
    {
        name: "4/6",
        value: "4 ganger på 6 måneder",
    },
    {
        name: "5/6",
        value: "5 ganger på 6 måneder",
    },
    {
        name: "5/9",
        value: "5 ganger på 9 måneder",
    },
    {
        name: "3/12",
        value: "3 ganger på 12 måneder",
    },
    {
        name: "4/12",
        value: "4 ganger på 12 måneder",
    },
    {
        name: "5/12",
        value: "5 ganger på 12 måneder",
    },
    {
        name: "6/12",
        value: "6 ganger på 12 måneder",
    },
    {
        name: "8/12",
        value: "8 ganger på 12 måneder",
    },
    {
        name: "105/12",
        value: "105 dager på 12 måneder",
    },
    {
        name: "14FK",
        value: "14 dager inkludert gjentakende",
    },
];
export const deviantAbsenceTypesDA = [
    {
        name: "RecurringPeriod",
        value: "Gentaget fravær",
    },
    {
        name: "Recurring",
        value: "Gentaget fravær",
    },
    {
        name: "days",
        value: "dage",
    },
    {
        name: "consecutiveDays",
        value: "på hinanden følgende dage",
    },
    {
        name: "LongTerm",
        value: "Langtidsfravær",
    },
    {
        name: "Longterm",
        value: "Langtidsfravær",
    },
    {
        name: "3/3",
        value: "3 gange på 3 måneder",
    },
    {
        name: "3/6",
        value: "3 gange på 6 måneder",
    },
    {
        name: "4/6",
        value: "4 gange på 6 måneder",
    },
    {
        name: "5/6",
        value: "5 gange på 6 måneder",
    },
    {
        name: "5/9",
        value: "5 gange på 9 måneder",
    },
    {
        name: "3/12",
        value: "3 gange på 12 måneder",
    },
    {
        name: "4/12",
        value: "4 gange på 12 måneder",
    },
    {
        name: "5/12",
        value: "5 gange på 12 måneder",
    },
    {
        name: "6/12",
        value: "6 gange på 12 måneder",
    },
    {
        name: "8/12",
        value: "8 gange på 12 måneder",
    },
    {
        name: "105/12",
        value: "105 dage på 12 måneder",
    },
    {
        name: "14FK",
        value: "14 dage inklusive tilbagevendende",
    },
];

export const deviantAbsenceTypesEN = [
    {
        name: "RecurringPeriod",
        value: "Repeated absence",
    },
    {
        name: "Recurring",
        value: "Repeated absence",
    },
    {
        name: "days",
        value: "days",
    },
    {
        name: "consecutiveDays",
        value: "consecutive days",
    },
    {
        name: "LongTerm",
        value: "Long-term absence",
    },
    {
        name: "Longterm",
        value: "Long-term absence",
    },
    {
        name: "3/3",
        value: "3 times in 3 months",
    },
    {
        name: "3/6",
        value: "3 times in 6 months",
    },
    {
        name: "4/6",
        value: "4 times in 6 months",
    },
    {
        name: "5/6",
        value: "5 times in 6 months",
    },
    {
        name: "5/9",
        value: "5 times in 9 months",
    },
    {
        name: "3/12",
        value: "3 times in 12 months",
    },
    {
        name: "4/12",
        value: "4 times in 12 months",
    },
    {
        name: "5/12",
        value: "5 times in 12 months",
    },
    {
        name: "6/12",
        value: "6 times in 12 months",
    },
    {
        name: "8/12",
        value: "8 times in 12 months",
    },
    {
        name: "105/12",
        value: "105 days in 12 months",
    },
    {
        name: "14FK",
        value: "14 days including recurring",
    },
];
export const deviantAbsenceTypesSV = [
    {
        name: "RecurringPeriod",
        value: "Upprepad frånvaro",
    },
    {
        name: "Recurring",
        value: "Upprepad frånvaro",
    },
    {
        name: "Longterm",
        value: "Långtidsfrånvaro",
    },
    {
        name: "days",
        value: "dagar",
    },
    {
        name: "consecutiveDays",
        value: "dagar i följd",
    },
    {
        name: "LongTerm",
        value: "Långtidsfrånvaro",
    },
    {
        name: "3/3",
        value: "3 ggr på 3 månader",
    },
    {
        name: "3/6",
        value: "3 ggr på 6 månader",
    },
    {
        name: "4/6",
        value: "4 ggr på 6 månader",
    },
    {
        name: "5/6",
        value: "5 ggr på 6 månader",
    },
    {
        name: "5/9",
        value: "5 ggr på 9 månader",
    },
    {
        name: "3/12",
        value: "3 ggr på 12 månader",
    },
    {
        name: "4/12",
        value: "4 ggr på 12 månader",
    },
    {
        name: "5/12",
        value: "5 ggr på 12 månader",
    },
    {
        name: "6/12",
        value: "6 ggr på 12 månader",
    },
    {
        name: "8/12",
        value: "8 ggr på 12 månader",
    },
    {
        name: "105/12",
        value: "105 dagar på 12 månader",
    },
    {
        name: "14FK",
        value: "14 dagar inkl återkommande",
    },
];

export const reimbursableTypesDA = [
    {
        name: "Paragraph56",
        value: "$56",
    },
    {
        name: "PregnancyRelated",
        value: "Graviditetsrelateret",
    },
    {
        name: "WorkPlaceAccident",
        value: "Arbejdsulykke",
    },
    {
        name: "SicknessBenefits",
        value: "Sygedagpenge",
    },
    {
        name: "EmploymentGroup",
        value: "Ansættelsesgruppe",
    },
];

export const reimbursableTypesNO = [
    {
        name: "Paragraph56",
        value: "$56",
    },
    {
        name: "PregnancyRelated",
        value: "Graviditetsrelatert",
    },
    {
        name: "WorkPlaceAccident",
        value: "Arbeidsulykke",
    },
    {
        name: "SicknessBenefits",
        value: "Sykepenger",
    },
    {
        name: "EmploymentGroup",
        value: "Ansettelsesgruppe",
    },
];

export const reimbursableTypesEN = [
    {
        name: "Paragraph56",
        value: "$56",
    },
    {
        name: "PregnancyRelated",
        value: "Pregnancy-related",
    },
    {
        name: "WorkPlaceAccident",
        value: "Workplace accident",
    },
    {
        name: "SicknessBenefits",
        value: "Sick benefits",
    },
    {
        name: "EmploymentGroup",
        value: "Employment group",
    },
];

export const reimbursableTypesSV = [
    {
        name: "Paragraph56",
        value: "$56",
    },
    {
        name: "PregnancyRelated",
        value: "Graviditetsrelaterat",
    },
    {
        name: "WorkPlaceAccident",
        value: "Arbetsplatsolycka",
    },
    {
        name: "SicknessBenefits",
        value: "Sjukförmåner",
    },
    {
        name: "EmploymentGroup",
        value: "Anställningsgrupp",
    },
];

const deviantTypes = {
    sv: deviantAbsenceTypesSV,
    en: deviantAbsenceTypesEN,
    da: deviantAbsenceTypesDA,
    no: deviantAbsenceTypesNO,
};
const reimbursableTypesTranslation = {
    sv: reimbursableTypesSV,
    en: reimbursableTypesEN,
    da: reimbursableTypesDA,
    no: reimbursableTypesNO,
};
const getReimbursibleTypes = (country: "no" | "sv" | "da" | "en") => reimbursableTypesTranslation[country];

const getDeviantAbsenceTypes = (country: "no" | "sv" | "da" | "en") => deviantTypes[country];
export const translation = (workbookTitle: string, country?: string, searchType?: SearchTypes) => ({
    ...getTranslation((country as "no" | "sv" | "da" | "en") ?? "en", searchType),
    deviantAbsenceTypes: getDeviantAbsenceTypes((country as "no" | "sv" | "da" | "en") ?? "en"),
    reimbursableTypes: getReimbursibleTypes((country as "no" | "sv" | "da" | "en") ?? "en"),
    workbookTitle,
});
