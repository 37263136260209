import PrimaryButton from "components/PrimaryButton";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectors, thunkActions } from "../../redux";
import SearchDropdowns from "../SearchDropdowns";

const SearchQuery = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation("followup");
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const hasDataToSearchFor = useAppSelector(selectors.hasDataToSearchFor);

    const onSearch = useCallback(() => {
        if (currentSearch) {
            setIsLoading(true);
            dispatch(
                thunkActions.search({
                    requestType: "search",
                    type: currentSearch,
                    workBookTitle: t(`excelTitle.${currentSearch}`),
                }),
            ).then(() => {
                setIsLoading(false);
            });
        }
    }, [currentSearch, dispatch, t]);
    return (
        <div className="flex flex-col">
            <div
                className="flex flex-col w-full mt-10 justify-between gap-5 xxl:gap-20 lg:flex-row "
                data-testid="page-searchquery"
            >
                <div className="flex gap-1 flex-1 flex-col">
                    <SearchDropdowns />
                </div>
                {currentSearch !== "monthlyReport" && (
                    <div className="flex justify-center items-end">
                        <PrimaryButton
                            className="w-full max-w-[335px] lg:w-auto "
                            id="followup-search-button"
                            onClick={onSearch}
                            isLoading={isLoading}
                            disabled={!hasDataToSearchFor}
                            styleText="justify-center"
                            text={t("searchName")}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SearchQuery;
