import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getHealthPromotionTemplateId } from "../redux/serviceSelectors";
import { MedHelpHCMDomainRulesRuleTemplate } from "swagger/healthCaseManagement";
import { MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule } from "swagger/absencefollowup";
import { deleteDeviantRule, saveDeviantRule, saveTemplateRules } from "../redux/serviceAsyncActions";

const useSubmitDeviantRule = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [itemsToDelete, setItemsToDelete] = useState<string[]>([]);
    // const rehabTemplateId = useAppSelector(getRebabTemplateId);
    const healthPromotionTemplateId = useAppSelector(getHealthPromotionTemplateId);
    const { t } = useTranslation("service");

    const submitDeviantRules = (data: MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule[], reset: () => void) => {
        setIsLoading(true);
        const promises: Promise<any>[] = [];
        const healthPromotionTemplateRules: MedHelpHCMDomainRulesRuleTemplate[] = [];
        // const rehabTemplateRules: MedHelpHCMDomainRulesRuleTemplate[] = [];
        data.forEach((rule) => {
            if (!rule.id) {
                promises.push(
                    dispatch(saveDeviantRule(rule))
                        .then(unwrapResult)
                        .then(() => toast("Avvikande regel skapad"))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message, { type: "error" });
                            reset();
                        }),
                );
                if (rule.ruleName === "AbsencesPerMonths") {
                    healthPromotionTemplateRules.push({
                        ruleType: "initiation",
                        ruleName: "DeviantInitiation",
                        params: `recurringperiod,${rule.value}/${rule.interval}`,
                        startDirect: false,
                    });
                }

                // We will not create rehab cases based on longterm rules by default for now - might be a feature for danish customers

                // if (rule.ruleName === "AbsenceMaxLengthRecurring") {
                //     rehabTemplateRules.push({
                //         ruleType: "initiation",
                //         ruleName: "DeviantInitiation",
                //         params: `longterm,${rule.value}/${rule.interval}`,
                //         startDirect: false,
                //     });
                // }
            }
        });

        if (itemsToDelete.length > 0) {
            itemsToDelete.forEach((rule) =>
                promises.push(
                    dispatch(deleteDeviantRule(rule))
                        .then(unwrapResult)
                        .then(() => toast("Avvikanderegel borttagen"))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message, { type: "error" });
                            reset();
                        }),
                ),
            );
        }

        Promise.allSettled(promises).finally(() => {
            setIsLoading(false);
            setItemsToDelete([]);
        });

        // if (rehabTemplateRules.length > 0 && rehabTemplateId) {
        //     promises.push(
        //         dispatch(saveTemplateRules({ templateId: rehabTemplateId, templateRules: { rules: rehabTemplateRules } }))
        //             .then(unwrapResult)
        //             .then(() => {
        //                 toast(t("HCM regel skapad"));
        //             })
        //             .catch(() => {
        //                 toast(t("error") + ": " + "HCM regel", { type: "error" });
        //             }),
        //     );
        // }

        if (healthPromotionTemplateRules.length > 0 && healthPromotionTemplateId) {
            promises.push(
                dispatch(
                    saveTemplateRules({
                        templateId: healthPromotionTemplateId,
                        templateRules: { rules: healthPromotionTemplateRules },
                    }),
                )
                    .then(unwrapResult)
                    .then(() => {
                        toast("HCM regel skapad");
                    })
                    .catch(() => {
                        toast(t("error") + ": " + "HCM regel", { type: "error" });
                    }),
            );
        }
    };
    return [submitDeviantRules, isLoading, itemsToDelete, setItemsToDelete] as const;
};

export default useSubmitDeviantRule;
