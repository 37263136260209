import Icon from "components/Icon";

const InfoBox = ({ text }: { text: string }) => (
    <div className="bg-grey-100 py-8 px-4 gap-4 rounded-lg flex">
        <div>
            <Icon icon="lightBulb" />
        </div>
        <div className="max-w-xl">{text}</div>
    </div>
);

export default InfoBox;
