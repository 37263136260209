import { SearchTypes } from "../../utils";
import AffectedDepartmentFilter from "../AffectedDepartmentFilter";
import ShowBasedOnFilter from "../ShowBasedOnFilter";

interface IProps {
    searchType: SearchTypes;
}
const ShowAffectedBasedOnFilter = ({ searchType }: IProps) => (
    <div className="flex gap-8">
        {(searchType === "absencePeriod" || searchType === "ongoingAbsence") && <AffectedDepartmentFilter />}
        {(searchType === "absencePeriod" ||
            searchType === "ongoingAbsence" ||
            searchType === "cases" ||
            searchType === "activities") && <ShowBasedOnFilter />}
    </div>
);

export default ShowAffectedBasedOnFilter;
