/* tslint:disable */
/* eslint-disable */
/**
 * My Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AbsenceViewModel
 */
export interface AbsenceViewModel {
    /**
     * 
     * @type {string}
     * @memberof AbsenceViewModel
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbsenceViewModel
     */
    'absenceType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceViewModel
     */
    'absenceDegree'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceViewModel
     */
    'estimatedDateBack'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceViewModel
     */
    'contactReason'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceViewModel
     */
    'absenceCause'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyViewModel
 */
export interface CompanyViewModel {
    /**
     * 
     * @type {number}
     * @memberof CompanyViewModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyViewModel
     */
    'customerKey'?: string | null;
}
/**
 * 
 * @export
 * @interface ContactInformationViewModel
 */
export interface ContactInformationViewModel {
    /**
     * 
     * @type {string}
     * @memberof ContactInformationViewModel
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationViewModel
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationViewModel
     */
    'address'?: string | null;
}
/**
 * 
 * @export
 * @interface DepartmentViewModel
 */
export interface DepartmentViewModel {
    /**
     * 
     * @type {number}
     * @memberof DepartmentViewModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentViewModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface EmploymentInformationViewModel
 */
export interface EmploymentInformationViewModel {
    /**
     * 
     * @type {EmploymentInformationViewModelEmployment}
     * @memberof EmploymentInformationViewModel
     */
    'employment'?: EmploymentInformationViewModelEmployment | null;
}
/**
 * 
 * @export
 * @interface EmploymentInformationViewModelEmployment
 */
export interface EmploymentInformationViewModelEmployment {
    /**
     * 
     * @type {number}
     * @memberof EmploymentInformationViewModelEmployment
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentInformationViewModelEmployment
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {WorkOrderReservationViewModelCompany}
     * @memberof EmploymentInformationViewModelEmployment
     */
    'company'?: WorkOrderReservationViewModelCompany | null;
    /**
     * 
     * @type {Array<DepartmentViewModel>}
     * @memberof EmploymentInformationViewModelEmployment
     */
    'departments'?: Array<DepartmentViewModel> | null;
}
/**
 * 
 * @export
 * @interface EmploymentViewModel
 */
export interface EmploymentViewModel {
    /**
     * 
     * @type {number}
     * @memberof EmploymentViewModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentViewModel
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {WorkOrderReservationViewModelCompany}
     * @memberof EmploymentViewModel
     */
    'company'?: WorkOrderReservationViewModelCompany | null;
    /**
     * 
     * @type {Array<DepartmentViewModel>}
     * @memberof EmploymentViewModel
     */
    'departments'?: Array<DepartmentViewModel> | null;
}
/**
 * 
 * @export
 * @interface MedicalAdvisoryWorkOrderViewModel
 */
export interface MedicalAdvisoryWorkOrderViewModel {
    /**
     * 
     * @type {number}
     * @memberof MedicalAdvisoryWorkOrderViewModel
     */
    'workOrderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalAdvisoryWorkOrderViewModel
     */
    'workOrderType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalAdvisoryWorkOrderViewModel
     */
    'workOrderStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalAdvisoryWorkOrderViewModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalAdvisoryWorkOrderViewModel
     */
    'localePreference'?: string | null;
    /**
     * 
     * @type {WorkOrderReservationViewModelUserAccount}
     * @memberof MedicalAdvisoryWorkOrderViewModel
     */
    'user'?: WorkOrderReservationViewModelUserAccount | null;
    /**
     * 
     * @type {Array<EmploymentInformationViewModel>}
     * @memberof MedicalAdvisoryWorkOrderViewModel
     */
    'employmentInformations'?: Array<EmploymentInformationViewModel> | null;
}
/**
 * 
 * @export
 * @interface UserAccountViewModel
 */
export interface UserAccountViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserAccountViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccountViewModel
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {UserAccountViewModelContactInformation}
     * @memberof UserAccountViewModel
     */
    'contactInformation'?: UserAccountViewModelContactInformation | null;
}
/**
 * 
 * @export
 * @interface UserAccountViewModelContactInformation
 */
export interface UserAccountViewModelContactInformation {
    /**
     * 
     * @type {string}
     * @memberof UserAccountViewModelContactInformation
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccountViewModelContactInformation
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccountViewModelContactInformation
     */
    'address'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkOrder
 */
export interface WorkOrder {
    /**
     * 
     * @type {number}
     * @memberof WorkOrder
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkOrder
     */
    'workOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkOrder
     */
    'referenceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkOrder
     */
    'referenceUserAccountId'?: number;
    /**
     * 
     * @type {WorkOrderType}
     * @memberof WorkOrder
     */
    'workOrderType'?: WorkOrderType;
    /**
     * 
     * @type {WorkOrderStatus}
     * @memberof WorkOrder
     */
    'workOrderStatus'?: WorkOrderStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrder
     */
    'localePreference'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkOrderEventLogPostModel
 */
export interface WorkOrderEventLogPostModel {
    /**
     * 
     * @type {number}
     * @memberof WorkOrderEventLogPostModel
     */
    'workOrderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderEventLogPostModel
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkOrderPatch
 */
export interface WorkOrderPatch {
    /**
     * 
     * @type {WorkOrderStatus}
     * @memberof WorkOrderPatch
     */
    'workOrderStatus'?: WorkOrderStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderPatch
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderPatch
     */
    'userAccountId'?: number;
}
/**
 * 
 * @export
 * @interface WorkOrderReservationResult
 */
export interface WorkOrderReservationResult {
    /**
     * 
     * @type {boolean}
     * @memberof WorkOrderReservationResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {WorkOrderReservationResultWorkOrderReservation}
     * @memberof WorkOrderReservationResult
     */
    'workOrderReservation'?: WorkOrderReservationResultWorkOrderReservation | null;
}
/**
 * 
 * @export
 * @interface WorkOrderReservationResultWorkOrderReservation
 */
export interface WorkOrderReservationResultWorkOrderReservation {
    /**
     * 
     * @type {number}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'workOrderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'workOrderStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'workOrderType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'localePreference'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'reservationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'reservedAgo'?: number;
    /**
     * 
     * @type {WorkOrderReservationViewModelUserAccount}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'userAccount'?: WorkOrderReservationViewModelUserAccount | null;
    /**
     * 
     * @type {WorkOrderReservationViewModelCompany}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'company'?: WorkOrderReservationViewModelCompany | null;
    /**
     * 
     * @type {WorkOrderReservationViewModelAbsence}
     * @memberof WorkOrderReservationResultWorkOrderReservation
     */
    'absence'?: WorkOrderReservationViewModelAbsence | null;
}
/**
 * 
 * @export
 * @interface WorkOrderReservationViewModel
 */
export interface WorkOrderReservationViewModel {
    /**
     * 
     * @type {number}
     * @memberof WorkOrderReservationViewModel
     */
    'workOrderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModel
     */
    'workOrderStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModel
     */
    'workOrderType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModel
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModel
     */
    'localePreference'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderReservationViewModel
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModel
     */
    'reservationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderReservationViewModel
     */
    'reservedAgo'?: number;
    /**
     * 
     * @type {WorkOrderReservationViewModelUserAccount}
     * @memberof WorkOrderReservationViewModel
     */
    'userAccount'?: WorkOrderReservationViewModelUserAccount | null;
    /**
     * 
     * @type {WorkOrderReservationViewModelCompany}
     * @memberof WorkOrderReservationViewModel
     */
    'company'?: WorkOrderReservationViewModelCompany | null;
    /**
     * 
     * @type {WorkOrderReservationViewModelAbsence}
     * @memberof WorkOrderReservationViewModel
     */
    'absence'?: WorkOrderReservationViewModelAbsence | null;
}
/**
 * 
 * @export
 * @interface WorkOrderReservationViewModelAbsence
 */
export interface WorkOrderReservationViewModelAbsence {
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModelAbsence
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModelAbsence
     */
    'absenceType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderReservationViewModelAbsence
     */
    'absenceDegree'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModelAbsence
     */
    'estimatedDateBack'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderReservationViewModelAbsence
     */
    'contactReason'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModelAbsence
     */
    'absenceCause'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkOrderReservationViewModelCompany
 */
export interface WorkOrderReservationViewModelCompany {
    /**
     * 
     * @type {number}
     * @memberof WorkOrderReservationViewModelCompany
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModelCompany
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModelCompany
     */
    'customerKey'?: string | null;
}
/**
 * 
 * @export
 * @interface WorkOrderReservationViewModelUserAccount
 */
export interface WorkOrderReservationViewModelUserAccount {
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModelUserAccount
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrderReservationViewModelUserAccount
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {UserAccountViewModelContactInformation}
     * @memberof WorkOrderReservationViewModelUserAccount
     */
    'contactInformation'?: UserAccountViewModelContactInformation | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WorkOrderStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_99: 99
} as const;

export type WorkOrderStatus = typeof WorkOrderStatus[keyof typeof WorkOrderStatus];


/**
 * 
 * @export
 * @interface WorkOrderTimeOutMessage
 */
export interface WorkOrderTimeOutMessage {
    /**
     * 
     * @type {number}
     * @memberof WorkOrderTimeOutMessage
     */
    'workOrderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderTimeOutMessage
     */
    'agentSessionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkOrderTimeOutMessage
     */
    'userAccountId'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WorkOrderType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type WorkOrderType = typeof WorkOrderType[keyof typeof WorkOrderType];



/**
 * MedicalAdvisoryApi - axios parameter creator
 * @export
 */
export const MedicalAdvisoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryFollowUpAdvisoryWorkOrderReservationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MedicalAdvisory/FollowUpAdvisoryWorkOrderReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMedicalAdvisoryIdGet', 'id', id)
            const localVarPath = `/api/MedicalAdvisory/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryMedicalAdvisoryWorkOrderIdGet: async (id: number, employmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMedicalAdvisoryMedicalAdvisoryWorkOrderIdGet', 'id', id)
            const localVarPath = `/api/MedicalAdvisory/MedicalAdvisoryWorkOrder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryMedicalAdvisoryWorkOrderSetAsQueuedPost: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MedicalAdvisory/MedicalAdvisoryWorkOrderSetAsQueued`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} agentSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryMedicalAdvisoryWorkOrderWrapUpAgentSessionIdGet: async (agentSessionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentSessionId' is not null or undefined
            assertParamExists('apiMedicalAdvisoryMedicalAdvisoryWorkOrderWrapUpAgentSessionIdGet', 'agentSessionId', agentSessionId)
            const localVarPath = `/api/MedicalAdvisory/MedicalAdvisoryWorkOrderWrapUp/{agentSessionId}`
                .replace(`{${"agentSessionId"}}`, encodeURIComponent(String(agentSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [agentSessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkOrderDeliveryIdGet: async (id: number, agentSessionId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiMedicalAdvisoryWorkOrderDeliveryIdGet', 'id', id)
            const localVarPath = `/api/MedicalAdvisory/WorkOrderDelivery/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (agentSessionId !== undefined) {
                localVarQueryParameter['agentSessionId'] = agentSessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkOrderEventLogPostModel} [workOrderEventLogPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkOrderLogPost: async (workOrderEventLogPostModel?: WorkOrderEventLogPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MedicalAdvisory/WorkOrderLog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/xml';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workOrderEventLogPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkOrderQueueForDateDateGet: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('apiMedicalAdvisoryWorkOrderQueueForDateDateGet', 'date', date)
            const localVarPath = `/api/MedicalAdvisory/WorkOrderQueueForDate/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkOrderTimeOutMessage} [workOrderTimeOutMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkOrderTimeOutPost: async (workOrderTimeOutMessage?: WorkOrderTimeOutMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MedicalAdvisory/WorkOrderTimeOut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workOrderTimeOutMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} workorderid 
         * @param {WorkOrderPatch} [workOrderPatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkorderidPatch: async (workorderid: number, workOrderPatch?: WorkOrderPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'workorderid' is not null or undefined
            assertParamExists('apiMedicalAdvisoryWorkorderidPatch', 'workorderid', workorderid)
            const localVarPath = `/api/MedicalAdvisory/{workorderid}`
                .replace(`{${"workorderid"}}`, encodeURIComponent(String(workorderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workOrderPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalAdvisoryApi - functional programming interface
 * @export
 */
export const MedicalAdvisoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalAdvisoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryFollowUpAdvisoryWorkOrderReservationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderReservationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryFollowUpAdvisoryWorkOrderReservationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryMedicalAdvisoryWorkOrderIdGet(id: number, employmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalAdvisoryWorkOrderViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryMedicalAdvisoryWorkOrderIdGet(id, employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryMedicalAdvisoryWorkOrderSetAsQueuedPost(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryMedicalAdvisoryWorkOrderSetAsQueuedPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} agentSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryMedicalAdvisoryWorkOrderWrapUpAgentSessionIdGet(agentSessionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryMedicalAdvisoryWorkOrderWrapUpAgentSessionIdGet(agentSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [agentSessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryWorkOrderDeliveryIdGet(id: number, agentSessionId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkOrderReservationViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryWorkOrderDeliveryIdGet(id, agentSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WorkOrderEventLogPostModel} [workOrderEventLogPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryWorkOrderLogPost(workOrderEventLogPostModel?: WorkOrderEventLogPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryWorkOrderLogPost(workOrderEventLogPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryWorkOrderQueueForDateDateGet(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryWorkOrderQueueForDateDateGet(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WorkOrderTimeOutMessage} [workOrderTimeOutMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryWorkOrderTimeOutPost(workOrderTimeOutMessage?: WorkOrderTimeOutMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryWorkOrderTimeOutPost(workOrderTimeOutMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} workorderid 
         * @param {WorkOrderPatch} [workOrderPatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiMedicalAdvisoryWorkorderidPatch(workorderid: number, workOrderPatch?: WorkOrderPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiMedicalAdvisoryWorkorderidPatch(workorderid, workOrderPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicalAdvisoryApi - factory interface
 * @export
 */
export const MedicalAdvisoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalAdvisoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryFollowUpAdvisoryWorkOrderReservationGet(options?: any): AxiosPromise<WorkOrderReservationResult> {
            return localVarFp.apiMedicalAdvisoryFollowUpAdvisoryWorkOrderReservationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryIdGet(id: number, options?: any): AxiosPromise<WorkOrder> {
            return localVarFp.apiMedicalAdvisoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryMedicalAdvisoryWorkOrderIdGet(id: number, employmentId?: number, options?: any): AxiosPromise<MedicalAdvisoryWorkOrderViewModel> {
            return localVarFp.apiMedicalAdvisoryMedicalAdvisoryWorkOrderIdGet(id, employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryMedicalAdvisoryWorkOrderSetAsQueuedPost(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiMedicalAdvisoryMedicalAdvisoryWorkOrderSetAsQueuedPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} agentSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryMedicalAdvisoryWorkOrderWrapUpAgentSessionIdGet(agentSessionId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiMedicalAdvisoryMedicalAdvisoryWorkOrderWrapUpAgentSessionIdGet(agentSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} [agentSessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkOrderDeliveryIdGet(id: number, agentSessionId?: number, options?: any): AxiosPromise<WorkOrderReservationViewModel> {
            return localVarFp.apiMedicalAdvisoryWorkOrderDeliveryIdGet(id, agentSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkOrderEventLogPostModel} [workOrderEventLogPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkOrderLogPost(workOrderEventLogPostModel?: WorkOrderEventLogPostModel, options?: any): AxiosPromise<string> {
            return localVarFp.apiMedicalAdvisoryWorkOrderLogPost(workOrderEventLogPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkOrderQueueForDateDateGet(date: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiMedicalAdvisoryWorkOrderQueueForDateDateGet(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkOrderTimeOutMessage} [workOrderTimeOutMessage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkOrderTimeOutPost(workOrderTimeOutMessage?: WorkOrderTimeOutMessage, options?: any): AxiosPromise<void> {
            return localVarFp.apiMedicalAdvisoryWorkOrderTimeOutPost(workOrderTimeOutMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} workorderid 
         * @param {WorkOrderPatch} [workOrderPatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiMedicalAdvisoryWorkorderidPatch(workorderid: number, workOrderPatch?: WorkOrderPatch, options?: any): AxiosPromise<void> {
            return localVarFp.apiMedicalAdvisoryWorkorderidPatch(workorderid, workOrderPatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicalAdvisoryApi - object-oriented interface
 * @export
 * @class MedicalAdvisoryApi
 * @extends {BaseAPI}
 */
export class MedicalAdvisoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryFollowUpAdvisoryWorkOrderReservationGet(options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryFollowUpAdvisoryWorkOrderReservationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryIdGet(id: number, options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryMedicalAdvisoryWorkOrderIdGet(id: number, employmentId?: number, options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryMedicalAdvisoryWorkOrderIdGet(id, employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryMedicalAdvisoryWorkOrderSetAsQueuedPost(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryMedicalAdvisoryWorkOrderSetAsQueuedPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} agentSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryMedicalAdvisoryWorkOrderWrapUpAgentSessionIdGet(agentSessionId: number, options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryMedicalAdvisoryWorkOrderWrapUpAgentSessionIdGet(agentSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} [agentSessionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryWorkOrderDeliveryIdGet(id: number, agentSessionId?: number, options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryWorkOrderDeliveryIdGet(id, agentSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkOrderEventLogPostModel} [workOrderEventLogPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryWorkOrderLogPost(workOrderEventLogPostModel?: WorkOrderEventLogPostModel, options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryWorkOrderLogPost(workOrderEventLogPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryWorkOrderQueueForDateDateGet(date: string, options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryWorkOrderQueueForDateDateGet(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkOrderTimeOutMessage} [workOrderTimeOutMessage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryWorkOrderTimeOutPost(workOrderTimeOutMessage?: WorkOrderTimeOutMessage, options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryWorkOrderTimeOutPost(workOrderTimeOutMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} workorderid 
     * @param {WorkOrderPatch} [workOrderPatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalAdvisoryApi
     */
    public apiMedicalAdvisoryWorkorderidPatch(workorderid: number, workOrderPatch?: WorkOrderPatch, options?: AxiosRequestConfig) {
        return MedicalAdvisoryApiFp(this.configuration).apiMedicalAdvisoryWorkorderidPatch(workorderid, workOrderPatch, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status/GetStatusAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGetStatusAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusGetStatusAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusGetStatusAllGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGetStatusAllGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusGetStatusAllGet(options).then((request) => request(this.axios, this.basePath));
    }
}


