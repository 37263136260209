import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { saveCaseActivity as saveCaseActivityAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const saveCaseActivity = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(saveCaseActivityAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                currentHealthCase.healthCaseActivities.push({
                    ...action.payload,
                    status: Status.OK,
                });
                currentHealthCase.healthCaseSubStatus.activities = Status.OK;
            }
        })
        .addCase(saveCaseActivityAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.activities = Status.PENDING;
        })
        .addCase(saveCaseActivityAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.activities = Status.ERROR;
        });

export default saveCaseActivity;
