import { ReactNode } from "react";
import { InitLoginModel, ReturnModel, StatusResult } from "swagger/authentication";
import { PasswordResetViewModel } from "swagger/usercontextservice";
import { ITranslationModel } from "utils/functions";
import { ILoginSlice } from "../redux/loginSlice";

export interface IGetUserAccounts {
    type: number;
    token: string;
    username?: string;
}

export interface UserAccount {
    userAccountId: number;
    companyId: number;
    companyName: string;
    requirePassword: boolean;
}

export interface IBankIdProps {
    statusResult: StatusResult;
    autoStartToken: string;
    bankIdAuthenticated: boolean;
    loginGrandIdGuid: string;
    username: string;
    totalTime: number;
    bankIdRejected: boolean;
    bankIdPending: boolean;
}

export interface IOtpLoginSliceProps {
    activeTab: string;
    emailInput: string;
    phoneNumber: string;
    otpInput: string;
    currentStep: string;
    identifier: string;
    cacheIdentifier: string;
}

export interface IOtpCompProps extends IOtpLoginSliceProps, ILoginSharedProps {
    validInput: boolean;
    selectedMarket: string;
    setOtpPropValue: (newValue: string, otpProp: keyof IOtpLoginSliceProps) => void;
    handleOtpButtonClicked: (isSubmitPassButton?: boolean) => void;
    translations: ITranslationModel<typeof OtpTranslations>;
    countdownComp: ReactNode;
}

export interface IReturnUrl {
    returnUrl: string | null | undefined;
}

export interface ILoginSharedProps {
    isLoading: boolean;
    navigate: (navigateTo: string) => void;
}

export interface IUsernameAndPassSliceProps {
    usernameInput: string;
    passwordInput: string;
}

export interface IUsernameAndPropsCompProps extends IUsernameAndPassSliceProps, ILoginSharedProps {
    setUsernameAndPassPropValue: (newValue: string, prop: keyof IUsernameAndPassSliceProps) => void;
    selectedMarket: string;
    translations: ITranslationModel<typeof UsernameAndPassTranslations>;
    handleSubmit: () => void;
    submitDisabled: boolean;
    failedAt: string;
}

export interface IResetPassSliceProps {
    currentStep: string;
    userIdentifier: string;
    newPass: string;
    confirmNewPass: string;
    pwdMissing: string[];
    passResetViewModel: PasswordResetViewModel;
}
export interface IResetPassCompProps extends IResetPassSliceProps, ILoginSharedProps {
    formIsInvalid: boolean;
    newPassError: boolean;
    newPassErrorMessage: string;
    confirmNewPassError: boolean;
    setResetPassPropValue: (newValue: string, prop: keyof IResetPassSliceProps) => void;
    handleSubmit: (isResetInit?: boolean) => void;
    translations: ITranslationModel<typeof ResetPassTranslations>;
    renderPasswordStrength: ReactNode;
    failedAt: string;
    userIdentifierInvalid: boolean;
}

export enum LoginUsing {
    azure = 0,
    bankIdSameDevice = 1,
    mobileBankIdQrCode = 2,
    mobileBankIdSsn = 3,
    selectedUserAccount = 4,
    default,
}

interface INavigateOrAbort {
    navigateTo: string;
    resetBankIdData?: boolean;
}

export interface ILoginUsingProps {
    loginUsing: LoginUsing;
    inputValue?: string;
    selectedUserAccountId?: number;
    bankIdInitGrandId?: InitLoginModel;
    navigateOrAbort?: INavigateOrAbort;
    returnUrl?: string;
}

export interface ILoginPageProps {
    language: string;
    setLanguage: (language: string) => void;
    handleMarketChange: (selectedMarket: string) => void;
}

export interface ILoginPageData extends ILoginSlice {
    returnUrl?: string;
    loginPageProps: ILoginPageProps;
    loginUsing: (loginUsingProps: ILoginUsingProps) => void;
    activeMobileBankIdTab: string;
    setActiveMobileBankIdTab: (activeMobileBankIdTab: string) => void;
}

export const initialIBankIdProps: IBankIdProps = {
    statusResult: { sessionGuid: "", hasErrors: false, error: "", qrCode: "" },
    autoStartToken: "",
    bankIdAuthenticated: false,
    loginGrandIdGuid: "",
    username: "",
    totalTime: 0,
    bankIdRejected: false,
    bankIdPending: false,
};

export const initialReturnModelProps: ReturnModel = {
    statusCode: 100,
    returnUrl: "",
    accessToken: "",
    allowCrossSessionToken: false,
    identifier: "",
    loginFailed: false,
};

export const initialOtpProps: IOtpLoginSliceProps = {
    activeTab: "eMail",
    emailInput: "",
    phoneNumber: "",
    otpInput: "",
    currentStep: "requestPass",
    identifier: "",
    cacheIdentifier: "",
};

export const initialUsernameAndPassProps: IUsernameAndPassSliceProps = {
    usernameInput: "",
    passwordInput: "",
};

export const initialResetPassProps: IResetPassSliceProps = {
    currentStep: "",
    userIdentifier: "",
    newPass: "",
    confirmNewPass: "",
    pwdMissing: [],
    passResetViewModel: {
        accountIdentifier: "",
        requestIdentifier: "",
        newPassword: "",
        newPasswordMatch: "",
    },
};

export interface IGetStatus {
    isQr: boolean;
    sessionGuid: string;
}

export interface IReturnModelExtended extends ReturnModel {
    lockout: number;
    userAccounts?: UserAccount[];
}

export enum BankIdLoginType {
    none = 0,
    bankIdSameDevice = 1,
    bankIdOtherDevice = 2,
    bankIdChoiceDevice = 3,
}

export interface IRegionData {
    display: boolean;
    sv: string;
    da: string;
    en: string;
    no: string;
}

export interface IWarningBannerData {
    global: IRegionData;
    se: IRegionData;
    dk: IRegionData;
}

export const OtpTranslations = [
    "eMail",
    "work",
    "selectCountryForNumber",
    "mobileNumber",
    "oneTimePass",
    "somethingWentWrongTryAgainIn",
    "requestPass",
    "enterOneTimePass",
    "login",
    "noCodeReceived",
    "noCodeExplanationStart",
    "noCodeExplanationEnd",
    "requestNewPass",
    "loginWithBankId",
    "smsInputPlaceholder",
] as const;

export const UsernameAndPassTranslations = [
    "usernameAndPass",
    "ssnOrEmail",
    "password",
    "loginFailed",
    "forgotPass",
    "login",
] as const;

export const ResetPassTranslations = [
    "resetPass",
    "resetPassInfo",
    "ssnOrEmail",
    "resetLinkSent",
    "resetLinkInfoStart",
    "resetLinkInfoEnd",
    "newPassword",
    "saveNewPass",
    "confirmNewPass",
    "newPass",
    "passNotMatching",
    "newPwdMustContain",
    "atleastSmallLetter",
    "atleastCapitalLetter",
    "atleastOneNumber",
    "altlestSpecialChar",
    "atleastNineChars",
    "failedToGetLink",
    "smallLetter",
    "capitalLetter",
    "number",
    "specialChar",
    "length",
    "pwdIsMissingA",
    "passChangedFailed",
    "resetSuccessfulRedirect",
    "resetSuccessful",
    "backToLogin",
    "mustStateNewPass",
] as const;
