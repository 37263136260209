import PendingProgressBar from "components/PendingProgressBar";
import { ReactComponent as BankIdIcon } from "icons/login/bankId.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ILoginPageData, LoginUsing } from "pages/login/types";
import { launchBankID } from "pages/login/utils";
import Countdown from "../Countdown";

const BankIdPending = ({ isLoading, bankIdProps, loginUsing, returnUrl }: ILoginPageData) => {
    const { t } = useTranslation("login");
    const [timeToNavigate, setTimeToNavigate] = useState<number>(-1);
    useEffect(() => {
        if (bankIdProps.bankIdRejected) {
            setTimeToNavigate(5);
        }
    }, [bankIdProps.bankIdRejected]);

    return (
        <div>
            {isLoading && (
                <div className="flex flex-col items-center">
                    <BankIdIcon className="w-12" />
                    <button
                        type="button"
                        className="mt-4 mb-8"
                        onClick={() => launchBankID(bankIdProps.autoStartToken)}
                    >
                        <p className="font-bold border-b border-linkBorderBottom hover:border-linkBorderBottomHover">
                            {!bankIdProps.statusResult.qrCode ? t("startBankIdApp") : t("loggingIn")}
                        </p>
                    </button>
                    <PendingProgressBar />
                    <button
                        type="button"
                        className="mt-10 w-max flex"
                        onClick={() =>
                            loginUsing({
                                loginUsing: LoginUsing.default,
                                navigateOrAbort: {
                                    navigateTo: "bankIdOptions",
                                    resetBankIdData: bankIdProps.bankIdPending || isLoading,
                                },
                                returnUrl,
                            })
                        }
                    >
                        <p className="font-bold border-b border-linkBorderBottom hover:border-linkBorderBottomHover">
                            {t("cancel")}
                        </p>
                    </button>
                </div>
            )}
            {!isLoading && (
                <Countdown
                    message={t("somethingWentWrongTryAgain", {
                        time: timeToNavigate,
                    })}
                    time={timeToNavigate}
                    setTime={setTimeToNavigate}
                    navigateTo="/login/bankIdOptions"
                    isError
                />
            )}
        </div>
    );
};

export default BankIdPending;
