import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { uploadFileWithTemplate as uploadFileWithTemplateAction } from "../followUpAsyncActions";
import { IState, Status } from "..";
import { IHealthCaseDocument } from "../followUpStateTypes";

const uploadFileWithTemplate = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(uploadFileWithTemplateAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                if (!action.meta.arg.isEdit) {
                    const newDocument: IHealthCaseDocument = {
                        id: action.payload.id,
                        templateDocumentId: action.meta.arg.documentTemplateId,
                        status: Status.PENDING,
                        type: "file",
                        title: action.meta.arg.title,
                        createdByUserName: action.payload.createdByUserName,
                    };
                    currentHealthCase.healthCaseDocuments = [...currentHealthCase.healthCaseDocuments, newDocument];
                } else {
                    const document = currentHealthCase.healthCaseDocuments.find((x) => x.id === action.payload.id);

                    if (document) {
                        document.annotation = action.meta.arg.annotation;
                        document.createdByUserName = action.payload.createdByUserName;
                        document.updated = action.payload.updated;
                    }
                }
                currentHealthCase.healthCaseSubStatus.pdfDocumentBasedOnTemplateSaved = Status.OK;
            }
        })
        .addCase(uploadFileWithTemplateAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase)
                currentHealthCase.healthCaseSubStatus.pdfDocumentBasedOnTemplateSaved = Status.PENDING;
        })
        .addCase(uploadFileWithTemplateAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.pdfDocumentBasedOnTemplateSaved = Status.ERROR;
        });

export default uploadFileWithTemplate;
