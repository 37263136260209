import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { sendContactRequest as sendContactRequestAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const sendContactRequest = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(sendContactRequestAction.fulfilled, (state) => {
            state.status.partnerContactRequest = Status.OK;
        })
        .addCase(sendContactRequestAction.pending, (state) => {
            state.status.partnerContactRequest = Status.PENDING;
        })
        .addCase(sendContactRequestAction.rejected, (state) => {
            state.status.partnerContactRequest = Status.ERROR;
        });

export default sendContactRequest;
