/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getCompanySpecificAbsenceConfirmation } from "../absenceReportsActions";
import { IAbsenceReportSlice } from "../absenceReportsSlice";

const companySpecificAbsenceConfirmation = (builder: ActionReducerMapBuilder<IAbsenceReportSlice>) =>
    builder
        .addCase(getCompanySpecificAbsenceConfirmation.fulfilled, (state, action) => {
            state.CompanySpecificAbsenceConfirmation = action.payload.value ? action.payload.value : null;
        })
        .addCase(getCompanySpecificAbsenceConfirmation.rejected, (state) => {
            state.CompanySpecificAbsenceConfirmation = null;
        });

export default companySpecificAbsenceConfirmation;
