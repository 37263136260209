import { useBreakpoints } from "hooks";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { selectors } from "../../redux";
import { FiltersInView } from "../../utils";
import FollowUpSearchFilters from "./FollowUpSearchFilters";

export default function Index() {
    const searchTypeFilters = useAppSelector(selectors.getSearchTypeFilterBySearchType);
    const { isMediumScreen } = useBreakpoints();
    const Filters = useMemo(
        () =>
            searchTypeFilters.reduce(
                (v, curr) => ({
                    ...v,
                    [curr]: true,
                }),
                FiltersInView,
            ),
        [searchTypeFilters],
    );
    return <FollowUpSearchFilters {...Filters} sortingFilter={isMediumScreen} />;
}
