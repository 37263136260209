import { ColumnText } from "@medhelp/ui";
import EmployeeCardDegrees from "../EmployeeCardDegrees";

interface IProps {
    header: string;
    list: ({ header?: string; body?: string; type?: string } | null)[];
    absenceReportId?: string;
    currentDegree?: number;
}

const FollowUpAbsenceColumnText = ({ header, list, absenceReportId }: IProps) => (
    <div className="w-full">
        <h2 className="text-lg">{header}</h2>
        <div className="mt-5 flex flex-wrap justify-between">
            {list.map((props, index) => {
                if (props?.type === "degree") {
                    return (
                        <EmployeeCardDegrees
                            key={`${props.header}-followupabsencecolumntext-degree-${index}-${absenceReportId}`}
                            absenceReportId={absenceReportId}
                        />
                    );
                }
                return props && <ColumnText {...props} key={`${props.header}-followupabsencecolumntext-${index}`} />;
            })}
        </div>
    </div>
);
export default FollowUpAbsenceColumnText;
