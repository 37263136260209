import { ReactNode } from "react";
import Icon from "components/Icon/Icon";

export type TableHeader = {
    label: string;
    accessor?: string;
};

interface ITable {
    children: ReactNode;
    headers: TableHeader[];
    caption?: string | ReactNode;
    handleSort?: (accessor: any) => void;
    className?: string;
    extra?: ReactNode;
}

const Table = ({ caption, headers, handleSort, children, className, extra }: ITable) => (
    <div className={className}>
        <table className="w-full h-full text-grey-600 border-b">
            {caption && <caption className="text-left lg:mb-10 p-4">{caption}</caption>}
            <thead className="hidden lg:table-header-group">
                <tr>
                    {headers.map(({ label, accessor }) => (
                        <th key={label} className="text-left px-6 pb-5 pt-8">
                            <div className="flex">
                                {label}
                                {accessor && handleSort && (
                                    <button className="ml-1.5" onClick={() => handleSort(accessor)}>
                                        <Icon icon="sort" color="black" />
                                    </button>
                                )}
                            </div>
                        </th>
                    ))}
                    <th>{extra && extra}</th>
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    </div>
);

export default Table;
