import SearchNumberFieldFilter, { IItems as ISearchNumberFieldFilter } from "../SearchNumberFieldFilter";

export interface IItems extends ISearchNumberFieldFilter {}
interface IPropsView {
    items: IItems[];
    header: string;
    body: string;
    separator?: JSX.Element;
}
const NumberOfAbsenceFilter = ({ items, header, body, separator }: IPropsView) => (
    <SearchNumberFieldFilter items={items} header={header} body={body} separator={separator} />
);

export default NumberOfAbsenceFilter;
