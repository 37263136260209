import { uniqueId } from "lodash";
import Icon from "components/Icon/Icon";
import ErrorWidget from "../ErrorWidget";
import { useRetailerColors } from "hooks";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { NumberChart } from "pages/dashboard/components/Charts";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import OngoingAbsenceWidgetLoader from "./OngoingAbsenceWidgetLoader";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import { getOngoingAbsence, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const VALUES = ["percent", "numberOfEmployees", "sumFTE"];

const OngoingAbsenceWidget = ({ id, type }: Widget) => {
    const uuid = uniqueId();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("dashboard");
    const { pageBackground } = useRetailerColors();
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getOngoingAbsence(id));

    const [currentlySelected, setCurrentlySelected] = useState(1);

    useEffect(() => {
        !isDragging && dispatch(fetchWidgetData({ id, type }));
    }, [dispatch, id, type, isDragging]);

    const ongoingAbsenceDegreeSum = data.ongoingAbsenceDegreeSum ?? 0;
    const ongoingAbsenceEmploymentCount = data.ongoingAbsenceEmploymentCount ?? 0;
    const employmentCount = data.employmentCount ?? 0;

    const selectedWidget = useMemo(() => {
        switch (currentlySelected) {
            case 0:
                return (
                    <NumberChart
                        modalHeading={t("ongoingAbsencesInSelection")}
                        id={"ongoing-absence-percent"}
                        data={
                            employmentCount === 0
                                ? "0"
                                : ((ongoingAbsenceDegreeSum / employmentCount) * 100)
                                      .toFixed(1)
                                      .toString()
                                      .replace(".", ",") + "%" || "0"
                        }
                        employeeDetails={data?.employeeDetails}
                        widgetDescription={t("percentOfEmployees")}
                    />
                );
            case 1:
                return (
                    <NumberChart
                        modalHeading={t("ongoingAbsencesInSelection")}
                        id={"ongoing-absence-number"}
                        data={ongoingAbsenceEmploymentCount.toString() ?? "0"}
                        employeeDetails={data?.employeeDetails}
                        widgetDescription={t("noOfEmployeesInSelection")}
                    />
                );
            case 2:
                return (
                    <NumberChart
                        modalHeading={t("ongoingAbsencesInSelection")}
                        id={"ongoing-absence-FTE-sum"}
                        data={ongoingAbsenceDegreeSum?.toString().replace(".", ",") ?? "0"}
                        employeeDetails={data?.employeeDetails}
                        widgetDescription={t("sumOfAbsences")}
                    />
                );
            default:
                return null;
        }
    }, [
        currentlySelected,
        t,
        employmentCount,
        ongoingAbsenceDegreeSum,
        data?.employeeDetails,
        ongoingAbsenceEmploymentCount,
    ]);

    if (status === Status.PENDING) {
        return <OngoingAbsenceWidgetLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <>
            <div className="flex w-full justify-between text-sm pt-5 pb-4 px-6">
                {VALUES.map((value, index) => (
                    <div key={value} className="flex items-center">
                        <label
                            htmlFor={uuid + value}
                            onChange={() => setCurrentlySelected(index)}
                            className="flex items-center cursor-pointer p-2"
                        >
                            <input className="hidden" type="radio" id={uuid + value} name="numberChart" />
                            <div>
                                <Icon
                                    icon={index === currentlySelected ? "radioActive" : "radio"}
                                    fill={pageBackground}
                                    stroke={pageBackground}
                                />
                            </div>
                            <p className="text-xs font-sans pl-1 text-pageBg">{t(value)}</p>
                        </label>
                    </div>
                ))}
            </div>
            {selectedWidget}
        </>
    );
};

export default OngoingAbsenceWidget;
