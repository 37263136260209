import { Dropdown } from "@medhelp/ui";

interface IProps {
    header: JSX.Element;
    items: JSX.Element;
    open: boolean;
    setOpen: (value: boolean) => void;
}

const AffectedDepartmentFilter = ({ header, items, open }: IProps) => (
    <Dropdown preset="minimal" heading={header} items={items} open={open} />
);

export default AffectedDepartmentFilter;
