import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "store/hooks";
import _ from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import RadioButtonGroup from "components/RadioButtonGroup";
import Accordion from "components/Accordion/Accordion";
import * as UserSelectors from "store/userSelectors";
import AbsenceReportFieldWrapper from "../AbsenceReportFieldWrapper";
import AbsenceReportDegreeLineChart from "../AbsenceReportDegreeLineChart";
import { useDoubleDatepickerProps, useRetailerColors, useSingleDatepickerProps } from "hooks";
import { selectors as absenceReportsSelectors } from "../../../absencereporting/redux";
import { DoubleDateTimepicker, SingleDatepicker } from "@medhelp/ui";
import { getDateDisplayValue, getDateStringForwardOrBack } from "utils/date";
import { StringDateRange } from "@medhelp/ui/Datepicker/types";
import useDoubleDateTimepickerProps from "hooks/useDoubleDateTimepickerProps";
import { DateRangePicker } from "components/DatePicker/DateRangePicker";

interface IAbsenceReportBaseEntity {
    isUpdate: boolean;
    isEdit: boolean;
    isWizard?: boolean;
    absenceReportType?: string;
    onComplete?: (absenceReport: IAbsenceReportExtended) => void;
    onUpdate: (fieldName: string, value: any, endDate?: any, timeStamp?: string) => void;
}

const AbsenceReportBase = ({
    isUpdate,
    isEdit,
    absenceReportType,
    isWizard,
    onComplete,
    onUpdate,
}: IAbsenceReportBaseEntity) => {
    const { t } = useTranslation();
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const userLang = useAppSelector(UserSelectors.getUserLanguage);
    const isManager = useAppSelector(UserSelectors.getIsAbsenceReportAdmin);
    const selectedAbsenceReport = useAppSelector(absenceReportsSelectors.getSelectedAbsenceReport);
    const showReportHealthyTime = useAppSelector(
        absenceReportsSelectors.getConfigurationByName("showReportHealthyTime"),
    );
    const initialStartDate = useAppSelector(absenceReportsSelectors.getInitialStartDate);
    const initialEndDate = useAppSelector(absenceReportsSelectors.getInitialEndDate);
    const initialDegree = useAppSelector(absenceReportsSelectors.getInitialDegree);
    const isOwnReport = useAppSelector(absenceReportsSelectors.getIsOwnReport);
    const { primary, secondary } = useRetailerColors();
    const [timeStamp, setTimeStamp] = useState<string | undefined>(undefined);
    const [absenceDegreeChanged, setAbsenceDegreeChanged] = useState(false);
    const [absencePeriodRange, setAbsencePeriodRange] = useState<StringDateRange | undefined>({
        from: initialStartDate,
        to: initialEndDate,
    });
    const [selectedAbsenceDegree, setSelectedAbsenceDegree] = useState(initialDegree);

    useEffect(() => {
        if (absencePeriodRange?.from && absencePeriodRange.to && selectedAbsenceDegree && !isUpdate) {
            const absenceReportBase: IAbsenceReportExtended = {
                start: absencePeriodRange.from,
                end: absencePeriodRange.to,
                degree: Number(selectedAbsenceDegree),
            };
            onComplete!(absenceReportBase);
        }
    }, [absencePeriodRange, selectedAbsenceDegree, showReportHealthyTime, isUpdate, onComplete]);

    const handleAbsencePeriodChange = useCallback(
        (absencePeriod: StringDateRange | undefined) => {
            if (absencePeriod) {
                if (isUpdate) {
                    if (absencePeriod.from !== absencePeriodRange?.from)
                        onUpdate("startDate", absencePeriod.from, absencePeriod?.to);
                    if (absencePeriod.to !== absencePeriodRange?.to) {
                        onUpdate("backAtWork", absencePeriod.from, absencePeriod.to);
                    }
                }
                setAbsencePeriodRange(absencePeriod);
            } else setAbsencePeriodRange(undefined);
        },
        [absencePeriodRange?.from, absencePeriodRange?.to, isUpdate, onUpdate],
    );

    const handleAbsenceDegreeChange = useCallback(
        (degree?: string) => {
            setSelectedAbsenceDegree(degree!);
            setAbsenceDegreeChanged(true);
            if (isUpdate && !isEdit) {
                onUpdate("degree", degree);
            }
            if (absenceDegreeChanged && timeStamp && isEdit) {
                onUpdate("degree", degree, undefined, timeStamp);
            }
        },
        [absenceDegreeChanged, isEdit, isUpdate, onUpdate, timeStamp],
    );

    const handleAbsenceDegreeDateChange = useCallback(
        (timeStamp: string | undefined) => {
            setTimeStamp(timeStamp);
            if (absenceDegreeChanged && timeStamp && isEdit) {
                onUpdate("degree", selectedAbsenceDegree, undefined, timeStamp);
            }
        },
        [absenceDegreeChanged, isEdit, onUpdate, selectedAbsenceDegree],
    );

    const degreeDatepickerProps = useSingleDatepickerProps({
        onChangeDate: handleAbsenceDegreeDateChange,
        givenSelectedDate: timeStamp,
        placeholder: t("selectDate"),
    });

    const disableDatesBefore = useMemo(() => {
        if (userContext.HasCustomerSupport) {
            return getDateStringForwardOrBack(36, "months", "backwards");
        } else if (userContext.HasAbsenceAdministration) {
            return getDateStringForwardOrBack(12, "months", "backwards");
        } else if (userContext.HasMedicalAdvisory) {
            return getDateStringForwardOrBack(2, "months", "backwards");
        } else {
            return getDateStringForwardOrBack(1, "months", "backwards");
        }
    }, [userContext.HasAbsenceAdministration, userContext.HasCustomerSupport, userContext.HasMedicalAdvisory]);

    const showChangeDegree = useMemo(
        () => selectedAbsenceReport?.degree && absenceDegreeChanged && isEdit,
        [absenceDegreeChanged, isEdit, selectedAbsenceReport?.degree],
    );

    const doubleDateTimepickerProps = useDoubleDateTimepickerProps({
        onChangeDateTimeRange: handleAbsencePeriodChange,
        givenDateTimeRange: absencePeriodRange,
        placeholderFrom: t("firstAbsenceDay"),
        placeholderTo: t("estimatedBack"),
        isMandatory: true,
    });
    const doubleDatepickerProps = useDoubleDatepickerProps({
        onChangeDateRange: handleAbsencePeriodChange,
        givenDateRange: absencePeriodRange,
        placeholderFrom: t("firstAbsenceDay"),
        placeholderTo: t("estimatedBack"),
        isMandatory: true,
    });

    const absencePeriodNode = showReportHealthyTime ? (
        <DoubleDateTimepicker
            {...doubleDateTimepickerProps}
            disableDatesBefore={disableDatesBefore}
            timepickerHeading={t("whatTimeAbsenceStart")}
            timepickerIsMandatory={true}
        />
    ) : (
        <DateRangePicker {...doubleDatepickerProps} disableDatesBefore={disableDatesBefore} edit={true} />
    );

    const radioButtonsNode = (
        <RadioButtonGroup
            id="absenceDegreeRadioGroup"
            values={[
                { value: "100", description: "100%" },
                { value: "75", description: "75%" },
                { value: "50", description: "50%" },
                { value: "25", description: "25%" },
            ]}
            clicked={handleAbsenceDegreeChange}
            selectedValue={selectedAbsenceDegree}
            isHorizontal
        />
    );

    const changeDegreeNode = (
        <div className="flex flex-col mt-8">
            <p className={`mb-2 text-black `}>{t("changeDateDegree")} *</p>
            <SingleDatepicker
                {...degreeDatepickerProps}
                disableDatesBefore={selectedAbsenceReport?.start}
                disableDatesAfter={absencePeriodRange?.to || disableDatesBefore}
                heading={t("changeDateDegree")}
                isMandatory
            />
        </div>
    );

    if (isManager && !isOwnReport) {
        return (
            <>
                <div className="pt-8">{absencePeriodNode}</div>

                <AbsenceReportFieldWrapper
                    heading={t("absenceDegree")}
                    isMandatory
                    modalTopContent={<Trans i18nKey={t("absenceDegreeInfoAdmin")} />}
                >
                    {selectedAbsenceReport &&
                        selectedAbsenceReport.degrees &&
                        selectedAbsenceReport.degrees.length > 0 &&
                        isEdit && (
                            <AbsenceReportDegreeLineChart
                                absenceReport={selectedAbsenceReport}
                                primaryColor={primary}
                                secondaryColor={secondary}
                            />
                        )}
                    {radioButtonsNode}
                    {showChangeDegree && changeDegreeNode}
                </AbsenceReportFieldWrapper>
            </>
        );
    }

    if (isUpdate && !isWizard) {
        return (
            <>
                <Accordion
                    className="border-t"
                    heading={t("kindOfAbsence")}
                    disabled={true}
                    value={absenceReportType ? t(_.camelCase(absenceReportType)) : ""}
                    isMandatory
                    dataCy="absenceType"
                />
                <Accordion
                    heading={t("absenceDegree")}
                    value={`${selectedAbsenceDegree}%`}
                    isMandatory
                    dataCy="absenceDegree"
                >
                    {radioButtonsNode}
                    {showChangeDegree && changeDegreeNode}
                </Accordion>
                <Accordion
                    heading={t("absencePeriod")}
                    value={`${
                        absencePeriodRange?.from
                            ? getDateDisplayValue(absencePeriodRange.from, "eee d MMM", userLang)
                            : t("firstAbsenceDay")
                    } - ${
                        absencePeriodRange?.to
                            ? getDateDisplayValue(absencePeriodRange.to, "eee d MMM", userLang)
                            : t("estimatedBack")
                    }`}
                    isMandatory
                    dataCy="absencePeriod"
                >
                    {absencePeriodNode}
                </Accordion>
            </>
        );
    } else {
        return (
            <>
                {absencePeriodNode}
                <AbsenceReportFieldWrapper
                    heading={t("absenceDegree")}
                    isMandatory
                    modalTopContent={<Trans i18nKey={t("absenceDegreeInfoAdmin")} />}
                >
                    {radioButtonsNode}
                </AbsenceReportFieldWrapper>
            </>
        );
    }
};
export default AbsenceReportBase;
