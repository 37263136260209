import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { isEmpty, uniqBy } from "lodash/fp";
import { DynamicData, Traverser } from "domain/ogranization";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import { CompanyRegion, TreeViewNode } from "swagger/employeeattendance";
import { filterAccessRightBy, hasTopDeparments } from "domain/accessRights";
import { fetchSearchInit } from "../followUpAsyncActions";
import { EmployementGroup } from "../followUpStateTypes";
import { ITags } from "../../types";
import { ReportSearch } from "../../utils";
import { getAccessRightBySearchType } from "../../accessRights";
import { IState, Status } from "..";

const fetchSearch = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchSearchInit.fulfilled, (state, action) => {
            const { companyId, employementGuid } = action.meta.arg;
            const {
                companiesWithRegion,
                organizationTree,
                employmentGroups,
                accessRights,
                hasCustomerSupport,
                followUpAccessRights,
            } = action.payload;
            let companies = undefined;
            state.userGuard = employementGuid;
            if (employmentGroups) {
                const employmentGroupsAsTag = uniqBy(
                    (x) => x.label && x.referenceKey,
                    employmentGroups.map((x) => {
                        const value: ITags = {
                            label: x.code || "none",
                            referenceKey: x.codeId.toString(),
                            checked: false,
                            id: "employmentGroups",
                        };
                        return value;
                    }),
                );
                ReportSearch.forEach((key) => {
                    state.searchFilter[key].filters = state.searchFilter[key].filters
                        .filter((x) => x.id !== "employmentGroups")
                        .concat(employmentGroupsAsTag);
                    if (key === "cases") {
                        state.searchFilter[key].showBasedOn = "numberOfCases";
                    } else if (key === "activities") {
                        state.searchFilter[key].showBasedOn = "numberOfActivities";
                    } else {
                        state.searchFilter[key].showBasedOn = "numberOfAbsenceOccasions";
                    }
                });
            }
            let companiesByAccessRights: CompanyRegion[] | null = null;
            if (companiesWithRegion) {
                state.companiesWithRegion = companiesWithRegion;
                companiesByAccessRights = hasCustomerSupport
                    ? companiesWithRegion
                    : getAccessRightBySearchType(
                          filterAccessRightBy(accessRights || []),
                          "companyId",
                          followUpAccessRights || [],
                          companiesWithRegion,
                      );

                companies = companiesWithRegion
                    .map<IDynamicData>((company) => ({
                        label: company.name || "",
                        referenceKey: company.id?.toString() || "",
                        id: company.id || "",
                        checked: DynamicData.getCheckedById(
                            company.id?.toString() || "",
                            false,
                            state.searchCompaniesDepartments.companies || [],
                        ),
                        children: [],
                        selectable: true,
                    }))
                    .map((x) => {
                        if (companiesByAccessRights?.length === 1) {
                            const oneCompaniesWithAccessRights = companiesByAccessRights[0];
                            return x.id === oneCompaniesWithAccessRights.id
                                ? {
                                      ...x,
                                      checked: true,
                                  }
                                : { ...x, checked: false };
                        }
                        return x;
                    });
            }

            const currentOrganizationTree: TreeViewNode[] = organizationTree || [];

            const currentEmployementGroups: EmployementGroup[] = employmentGroups || [];

            state.organizationTree = currentOrganizationTree;
            state.employmentGroups = currentEmployementGroups;
            const newCompanies =
                isEmpty(companies) || !companies ? state.searchCompaniesDepartments.companies : companies;
            const selectedCompanies = Traverser.filterChildren((x) => x.checked === true, newCompanies || []);
            let departments: IDynamicData[] = [];
            if (selectedCompanies?.length === 1) {
                let selectAll = false;
                const currentSelectedCompany = selectedCompanies[0];
                if (companiesByAccessRights?.length === 1) {
                    selectAll = Boolean(accessRights?.find((x) => x.externalId === companiesByAccessRights?.[0].id));
                }
                const accessRightForSelectedCompanies = accessRights?.find((x) => {
                    if (x.companyId === currentSelectedCompany.id) {
                        return hasTopDeparments(x);
                    }
                });
                departments = DynamicData.convertTreeViewNodeToDynamicData(
                    true,
                    (selectAll || Boolean(accessRightForSelectedCompanies)) &&
                        isEmpty(state.searchCompaniesDepartments.storedDepartments),
                    currentOrganizationTree as IDynamicData[],
                    state.searchCompaniesDepartments.storedDepartments,
                );
            } else {
                departments = [];
            }
            state.searchCompaniesDepartments = {
                companies: newCompanies,
                storedDepartments: state.searchCompaniesDepartments.storedDepartments,
                departments,
                currentCompanySearchId: companyId || null,
            };
            state.status.companiesWithRegion = Status.OK;
            state.status.organizationTree = Status.OK;
            state.status.employmentGroups = Status.OK;
        })
        .addCase(fetchSearchInit.pending, (state) => {
            state.status.companiesWithRegion = Status.PENDING;
            state.status.organizationTree = Status.PENDING;
            state.status.employmentGroups = Status.PENDING;
        })
        .addCase(fetchSearchInit.rejected, (state, action) => {
            const { employementGuid } = action.meta.arg;
            state.status.companiesWithRegion = Status.ERROR;
            state.status.organizationTree = Status.ERROR;
            state.status.employmentGroups = Status.ERROR;
            state.userGuard = employementGuid;
        });

export default fetchSearch;
