export const checkIfNativeApp = () => {
    if (window) {
        return window.MedhelpNativeApp === "true" || window.MedhelpNativeApp === true;
    }
    return false;
};

const checkNativePlatformOS = () => {
    if (window) {
        return window.MedhelpNativeDevice;
    }
    return undefined;
};
export const getMedhelpNativeDeeplink = () => {
    const nativeAppType = checkNativePlatformOS();
    if (nativeAppType === "android") {
        return "null";
    }
    if (nativeAppType === "ios") {
        return encodeURI("mymedhelpapp://");
    }
    return "null";
};
