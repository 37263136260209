import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const getRootIndex = (pathname: string): number => {
    const rootPath = pathname.split("/")[1];
    if (rootPath === "followup") return 2;
    return 1;
};

const ScrollToTop = () => {
    const { pathname } = useLocation();
    const rootPath = pathname.split("/")[getRootIndex(pathname)];
    const [previousRootPath, setPreviousRootPath] = useState(rootPath);

    useEffect(() => {
        if (rootPath !== previousRootPath) {
            setPreviousRootPath(rootPath);
            window.scroll(0, 0);
        }
    }, [pathname, previousRootPath, rootPath]);

    return null;
};

export default ScrollToTop;
