import * as React from "react";
import {
    CurrentCertificates,
    LoadMedicalCertificate,
    MedicalCertificateHeader,
    HistoryCertificates,
    Modals,
} from "@medhelp/medical-certificate/components";
import FollowUpMedicalCertificate from "../../../components/FollowUpMedicalCertificate";
import { useAppSelector } from "store/hooks";
import { canDeleteMedicalCertificate, getHasBaseProduct } from "store/userSelectors";

const MedicalCertificate = () => {
    const canModifieOrDeleteCertificate = useAppSelector(canDeleteMedicalCertificate);
    const hasBaseProduct = useAppSelector(getHasBaseProduct);

    return (
        <LoadMedicalCertificate>
            <div>
                <div className="h-2" />
                {!hasBaseProduct && (
                    <>
                        <FollowUpMedicalCertificate />
                        <div className="h-12" />
                    </>
                )}
                <MedicalCertificateHeader />
                <div className="h-4" />
                <CurrentCertificates manager={canModifieOrDeleteCertificate} />
                <HistoryCertificates manage={canModifieOrDeleteCertificate} />
            </div>
            <Modals />
        </LoadMedicalCertificate>
    );
};
export default MedicalCertificate;
