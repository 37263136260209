import IconModal from "components/IconModal";

interface IWizardHeader {
    isMandatory: boolean;
    heading: string | null | undefined;
    subHeading?: string | null | undefined;
    infoModalHeading?: string;
    infoModalSubHeading?: string;
}

const WizardHeader = ({ isMandatory, heading, subHeading, infoModalHeading, infoModalSubHeading }: IWizardHeader) => {
    const showIconModal = infoModalHeading || isMandatory;

    return (
        <>
            <div className="flex">
                <h2>
                    {heading ?? ""}
                    {isMandatory ? " *" : ""}
                </h2>
                <div className="flex">
                    {showIconModal && (
                        <IconModal
                            id="wizard-info-modal"
                            heading={infoModalHeading}
                            topContent={infoModalSubHeading ?? ""}
                            className="mt-2"
                        />
                    )}
                </div>
            </div>
            {subHeading && <p className="whitespace-pre-wrap pt-2">{subHeading}</p>}
        </>
    );
};

export default WizardHeader;
