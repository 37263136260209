import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchCaseTemplateList as fetchCaseTemplateListAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchCaseTemplateList = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchCaseTemplateListAction.fulfilled, (state, action) => {
            if (action.payload) state.caseTemplateList.data = action.payload;
            state.caseTemplateList.status = Status.OK;
        })
        .addCase(fetchCaseTemplateListAction.pending, (state) => {
            state.caseTemplateList.status = Status.PENDING;
        })
        .addCase(fetchCaseTemplateListAction.rejected, (state) => {
            state.caseTemplateList.status = Status.ERROR;
        });

export default fetchCaseTemplateList;
