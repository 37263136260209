import { Config } from "config";
import { CookieAttributes } from "js-cookie";
import { InitLoginModel, InitModel, LoginModel3 } from "swagger/authentication";
import { getDateForwardOrBack, getDateStringForwardOrBack } from "utils/date";
import { checkIfNativeApp, getMedhelpNativeDeeplink } from "utils/nativeApp";

export const isValidSsn = (ssnToValidate: string): boolean => {
    const cleanedString = ssnToValidate.replace(/[^0-9]/g, "");
    const validSsnRegex = new RegExp(
        "^(19|20)\\d{2}(01|02|03|04|05|06|07|08|09|10|11|12)((0[1-9])|(1|2)[0-9]|(30|31))\\d{4}$",
    );
    const valid = validSsnRegex.test(cleanedString);
    return valid;
};

export const isValidEmail = (emailToValidate: string): boolean => {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = emailRegex.test(emailToValidate);
    return valid;
};

export const getMobileNumberErrorMsg = (nrToValidate: string, t: any, isDanish = false): string => {
    const isZero = new RegExp(/^0$/).test(nrToValidate);
    const isInvalidDen = !new RegExp(/^(?=2|3[01]|4[012]|4911|5[0-3]|6[01]|[78]1|9[123])\d{8}$/).test(nrToValidate);
    const isInvalidSwe = !new RegExp(/^7[02369] *\d{7}$/).test(nrToValidate);

    if (isZero) return t("noZero");

    if (isDanish) {
        if (isInvalidDen) return t("invalidMobileNumber", { country: t("danish") });
    } else {
        if (isInvalidSwe) return t("invalidMobileNumber", { country: t("swedish") });
    }
    return "";
};

export const formatPhoneNumber = (countryPrefix: string, phoneNumber: string): string => {
    return countryPrefix === "+46" ? 0 + phoneNumber : phoneNumber;
};

export const getRetailerBg = (retailer: string, isTailwind?: boolean): string => {
    switch (retailer) {
        case "Medhelp":
            return isTailwind ? "bg-mhbeige" : "#fffaf5";
        default:
            return isTailwind ? "bg-white" : "#FFFFFF";
    }
};

export const getCookieOptions = (): CookieAttributes => {
    const domainUrl = Config.env === "local" ? "localhost" : ".medhelp.se";
    const cookieOptions: CookieAttributes = {
        expires: getDateForwardOrBack(14, "hours", "forward"),
        path: "/",
        domain: domainUrl,
        secure: true,
    };
    return cookieOptions;
};

export const launchBankID = (autoStartToken: string) => {
    let url = `bankid:///?autostarttoken=${autoStartToken}&redirect=`;
    if (checkIfNativeApp()) {
        url += getMedhelpNativeDeeplink();
        window.ReactNativeWebView.postMessage(`launchBankID$v${url}`);
        return;
    }
    const useChrome = navigator.userAgent.match("CriOS") || navigator.userAgent.indexOf("Chrome") !== -1;
    const useSafari = navigator.userAgent.indexOf("Safari") !== -1;
    const ios = /^(iPhone|iPad|iPod)/.test(navigator.platform);
    if (ios) {
        if (useChrome) {
            url += encodeURI("googlechrome:///");
        } else if (useSafari) {
            url += "null";
        }
    } else {
        url += "null";
    }
    window.open(url, "_self");
};

export const createInitLoginModel = (type: number, ssn?: string): InitLoginModel => {
    const showQrCode = !ssn && type === 2;
    const loginModel: InitLoginModel = {
        authenticateServiceType: type,
        socialSecurityNumber: ssn ?? "",
        returnUrl: "",
        qrCode: showQrCode,
    };
    return loginModel;
};

export const createInitModel = (identifier: string, returnUrl?: string): InitModel => {
    return {
        identifier,
        returnUrl,
    };
};

export const createLoginModel3 = (cacheIdentifier: string, passCode: string, returnUrl?: string): LoginModel3 => {
    return {
        cacheIdentifier,
        passCode,
        returnUrl,
        preferredRetailerId: 0, // behövs?
    };
};

export const handleSessionStorage = (): void => {
    if (sessionStorage.getItem("mhSession")) sessionStorage.removeItem("mhSession");
    sessionStorage.setItem("mhSession", getDateStringForwardOrBack(2, "hours", "forward"));
};
