import { Icons } from "components/Icon";
import { WidgetType } from "pages/dashboard/utils/dashboardTypes";

export interface IAddWidgetType {
    type: WidgetType;
    key: string;
    icon: Icons;
}
export const listOfWidgetsToAdd: Array<IAddWidgetType> = [
    {
        type: "absence-forecast-current-month",
        key: "absenceForecastCurrentMonth",
        icon: "forecastWidget",
    },
    {
        type: "absence-percent-last-6-months",
        key: "absencePercentLast6Months",
        icon: "barChartPercentWidget",
    },
    {
        type: "estimated-upcoming-absence",
        key: "estimatedUpcomingAbsence",
        icon: "barChartNumberWeekWidget",
    },
    {
        type: "absence-percent-per-month",
        key: "absencePercentPerMonth",
        icon: "lineChartPercentWidget",
    },
    {
        type: "ongoing-rehab",
        key: "ongoingRehab",
        icon: "tableWidget",
    },
    {
        type: "ongoing-absence",
        key: "ongoingAbsence",
        icon: "digitWidget",
    },
    {
        type: "last-activated-rehab",
        key: "lastActivatedRehab",
        icon: "tableWidget",
    },
    {
        type: "last-reported-absence",
        key: "lastReportedAbsence",
        icon: "tableWidget",
    },
    {
        type: "work-related-absence",
        key: "workRelatedAbsence",
        icon: "tableWidget",
    },
    {
        type: "recurring-absence-last-12-months",
        key: "recurringSickLeave",
        icon: "barChartNumberWidget",
    },
    {
        type: "absence-cause-proportion",
        key: "absenceCauseProportion",
        icon: "lineChartPercentWidget",
    },
    {
        type: "hcm-status-overview",
        key: "hcmStatusOverview",
        icon: "statusOverviewWidget",
    },
    {
        type: "hcm-todo",
        key: "hcmTodo",
        icon: "todoWidget",
    },
];
