import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { ModalFrame } from "@medhelp/ui";
import { useAppDispatch } from "store/hooks";
import AddWidgetCard from "./AddWidgetCard";
import { useTranslation } from "react-i18next";
import { getUserContext } from "store/userSelectors";
import PrimaryButton from "components/PrimaryButton";
import { listOfWidgetsToAdd } from "./listOfWidgetsToAdd";
import { WidgetType } from "pages/dashboard/utils/dashboardTypes";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { createWidget } from "pages/dashboard/redux/dashboardActions";
import { dashboardActions } from "pages/dashboard/redux/dashboardSlice";
import { filterWidgetsToAddByAccessRights } from "pages/dashboard/utils/dashboardAccessRights";
import { getCreateWidgetStatus, getHCMTemplates } from "pages/dashboard/redux/dashboardSelectors";
import { getHasBaseProduct } from "store/userSelectors";

const AddWidget = ({ testId }: { testId: string }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("dashboard");
    const templates = useAppSelector(getHCMTemplates);
    const userContext = useAppSelector(getUserContext);
    const hasBaseProduct = useAppSelector(getHasBaseProduct);
    const [openAddModal, setOpenAddModal] = useState(false);
    const createWidgetStatus = useAppSelector(getCreateWidgetStatus);

    const handleAddWidget = (type: WidgetType) => {
        dispatch(dashboardActions.setIsDragging(false));
        dispatch(createWidget(type));
        setOpenAddModal(false);
    };

    const filteredListOfWidgets = filterWidgetsToAddByAccessRights(
        userContext.userAccount?.accessRights ?? [],
        listOfWidgetsToAdd,
        templates,
        hasBaseProduct,
    );

    return (
        <>
            <PrimaryButton
                id={`${testId}-primary-button`}
                text={t("addWidget")}
                className={"w-full mx-12 sm:mx-0 sm:w-auto flex justify-center"}
                onClick={() => setOpenAddModal(true)}
                disabled={createWidgetStatus !== Status.OK}
            />
            <ModalFrame
                id="add-widget-modal"
                open={openAddModal}
                setOpen={() => setOpenAddModal(false)}
                header={<h1>{t("addWidget")}</h1>}
                preset="full"
                content={
                    <div className="flex flex-col w-full">
                        {filteredListOfWidgets.map((widget) => (
                            <AddWidgetCard
                                id={widget.type}
                                key={widget.type}
                                icon={widget.icon}
                                heading={t(`widgetHeading.${widget.key}`)}
                                widgetInfo={t(`widgetChartInfo.${widget.key}`)}
                                description={t(`widgetDescription.${widget.key}`)}
                                handleAddWidget={(type) => handleAddWidget(type)}
                            />
                        ))}
                    </div>
                }
            />
        </>
    );
};

export default AddWidget;
