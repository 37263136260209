/* tslint:disable */
/* eslint-disable */
/**
 * Messenger API
 * Add/Remove device token
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddRoleSubscriptionConfigration
 */
export interface AddRoleSubscriptionConfigration {
    /**
     * 
     * @type {NotificationType}
     * @memberof AddRoleSubscriptionConfigration
     */
    'notificationType'?: NotificationType;
    /**
     * 
     * @type {string}
     * @memberof AddRoleSubscriptionConfigration
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddRoleSubscriptionConfigration
     */
    'role'?: string | null;
    /**
     * 
     * @type {Channel}
     * @memberof AddRoleSubscriptionConfigration
     */
    'channel'?: Channel;
}


/**
 * 
 * @export
 * @interface AddUserRole
 */
export interface AddUserRole {
    /**
     * 
     * @type {number}
     * @memberof AddUserRole
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof AddUserRole
     */
    'userAccountId': number;
    /**
     * 
     * @type {string}
     * @memberof AddUserRole
     */
    'role': string;
    /**
     * 
     * @type {number}
     * @memberof AddUserRole
     */
    'scope': number;
    /**
     * 
     * @type {string}
     * @memberof AddUserRole
     */
    'targetId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Channel = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type Channel = typeof Channel[keyof typeof Channel];


/**
 * 
 * @export
 * @interface CompanySubscriptionConfiguration
 */
export interface CompanySubscriptionConfiguration {
    /**
     * 
     * @type {number}
     * @memberof CompanySubscriptionConfiguration
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySubscriptionConfiguration
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanySubscriptionConfiguration
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {NotificationType}
     * @memberof CompanySubscriptionConfiguration
     */
    'notificationType'?: NotificationType;
    /**
     * 
     * @type {number}
     * @memberof CompanySubscriptionConfiguration
     */
    'retailerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanySubscriptionConfiguration
     */
    'customerId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CompanySubscriptionConfiguration
     */
    'channels'?: Array<number> | null;
}


/**
 * 
 * @export
 * @interface ConfigurationField
 */
export interface ConfigurationField {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationField
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationField
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ConfigurationField
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationField
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationField
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface ContactInformationNotification
 */
export interface ContactInformationNotification {
    /**
     * 
     * @type {string}
     * @memberof ContactInformationNotification
     */
    'originTransactionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationNotification
     */
    'transactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactInformationNotification
     */
    'performedBy'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationNotification
     */
    'messageReportedVia'?: string | null;
    /**
     * 
     * @type {Origin}
     * @memberof ContactInformationNotification
     */
    'origin'?: Origin;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationNotification
     */
    'notificationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationNotification
     */
    'employmentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactInformationNotification
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ContactInformationNotification
     */
    'companyId'?: number;
    /**
     * 
     * @type {NotificationType}
     * @memberof ContactInformationNotification
     */
    'notificationType'?: NotificationType;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactInformationNotification
     */
    'receivers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationNotification
     */
    'createdDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationNotification
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationNotification
     */
    'referenceId'?: string;
}


/**
 * 
 * @export
 * @interface ContactInformationRequest
 */
export interface ContactInformationRequest {
    /**
     * 
     * @type {number}
     * @memberof ContactInformationRequest
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationRequest
     */
    'healthCaseId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactInformationRequest
     */
    'retailerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationRequest
     */
    'employmentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ContactInformationRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContactInformationRequest
     */
    'city'?: string | null;
}
/**
 * 
 * @export
 * @interface DeleteSubscriptionRequest
 */
export interface DeleteSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteSubscriptionRequest
     */
    'receiver'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeleteSubscriptionRequest
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeleteSubscriptionRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteSubscriptionRequest
     */
    'scope'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeleteSubscriptionRequest
     */
    'targetId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteSubscriptionRequest
     */
    'isRoleSubscription'?: boolean;
}
/**
 * 
 * @export
 * @interface DeleteUserRole
 */
export interface DeleteUserRole {
    /**
     * 
     * @type {number}
     * @memberof DeleteUserRole
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof DeleteUserRole
     */
    'userAccountId': number;
    /**
     * 
     * @type {string}
     * @memberof DeleteUserRole
     */
    'role': string;
    /**
     * 
     * @type {number}
     * @memberof DeleteUserRole
     */
    'scope': number;
    /**
     * 
     * @type {string}
     * @memberof DeleteUserRole
     */
    'targetId': string;
}
/**
 * 
 * @export
 * @interface DetailedMessage
 */
export interface DetailedMessage {
    /**
     * 
     * @type {string}
     * @memberof DetailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {Channel}
     * @memberof DetailedMessage
     */
    'channel'?: Channel;
    /**
     * 
     * @type {string}
     * @memberof DetailedMessage
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedMessage
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedMessage
     */
    'receiver'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedMessage
     */
    'notificationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DetailedMessage
     */
    'timestamp'?: string;
    /**
     * 
     * @type {SubscriberType}
     * @memberof DetailedMessage
     */
    'subscriberType'?: SubscriberType;
    /**
     * 
     * @type {string}
     * @memberof DetailedMessage
     */
    'subscriptionId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedMessage
     */
    'sent'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DetailedMessage
     */
    'notifiedUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DetailedMessage
     */
    'notifiedReason'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DetailedMessage
     */
    'isSubscriptionMessage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DetailedMessage
     */
    'status'?: string | null;
}


/**
 * 
 * @export
 * @interface HealthCaseNotification
 */
export interface HealthCaseNotification {
    /**
     * 
     * @type {string}
     * @memberof HealthCaseNotification
     */
    'notificationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCaseNotification
     */
    'healthCaseId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCaseNotification
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthCaseNotification
     */
    'content'?: string | null;
    /**
     * 
     * @type {NotificationType}
     * @memberof HealthCaseNotification
     */
    'notificationType'?: NotificationType;
    /**
     * 
     * @type {Array<string>}
     * @memberof HealthCaseNotification
     */
    'receivers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof HealthCaseNotification
     */
    'createdDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HealthCaseNotification
     */
    'customerId'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HttpStatusCode = {
    NUMBER_100: 100,
    NUMBER_101: 101,
    NUMBER_102: 102,
    NUMBER_103: 103,
    NUMBER_200: 200,
    NUMBER_201: 201,
    NUMBER_202: 202,
    NUMBER_203: 203,
    NUMBER_204: 204,
    NUMBER_205: 205,
    NUMBER_206: 206,
    NUMBER_207: 207,
    NUMBER_208: 208,
    NUMBER_226: 226,
    NUMBER_300: 300,
    NUMBER_301: 301,
    NUMBER_302: 302,
    NUMBER_303: 303,
    NUMBER_304: 304,
    NUMBER_305: 305,
    NUMBER_306: 306,
    NUMBER_307: 307,
    NUMBER_308: 308,
    NUMBER_400: 400,
    NUMBER_401: 401,
    NUMBER_402: 402,
    NUMBER_403: 403,
    NUMBER_404: 404,
    NUMBER_405: 405,
    NUMBER_406: 406,
    NUMBER_407: 407,
    NUMBER_408: 408,
    NUMBER_409: 409,
    NUMBER_410: 410,
    NUMBER_411: 411,
    NUMBER_412: 412,
    NUMBER_413: 413,
    NUMBER_414: 414,
    NUMBER_415: 415,
    NUMBER_416: 416,
    NUMBER_417: 417,
    NUMBER_421: 421,
    NUMBER_422: 422,
    NUMBER_423: 423,
    NUMBER_424: 424,
    NUMBER_426: 426,
    NUMBER_428: 428,
    NUMBER_429: 429,
    NUMBER_431: 431,
    NUMBER_451: 451,
    NUMBER_500: 500,
    NUMBER_501: 501,
    NUMBER_502: 502,
    NUMBER_503: 503,
    NUMBER_504: 504,
    NUMBER_505: 505,
    NUMBER_506: 506,
    NUMBER_507: 507,
    NUMBER_508: 508,
    NUMBER_510: 510,
    NUMBER_511: 511
} as const;

export type HttpStatusCode = typeof HttpStatusCode[keyof typeof HttpStatusCode];


/**
 * 
 * @export
 * @interface InboxMessage
 */
export interface InboxMessage {
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    'referenceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    'notificationType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    'readAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessage
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InboxMessage
     */
    'channel'?: number;
}
/**
 * 
 * @export
 * @interface InboxResponse
 */
export interface InboxResponse {
    /**
     * 
     * @type {Array<InboxMessage>}
     * @memberof InboxResponse
     */
    'inbox'?: Array<InboxMessage> | null;
    /**
     * 
     * @type {number}
     * @memberof InboxResponse
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface InboxUnreadCountResponse
 */
export interface InboxUnreadCountResponse {
    /**
     * 
     * @type {number}
     * @memberof InboxUnreadCountResponse
     */
    'eventMessagesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof InboxUnreadCountResponse
     */
    'subscriptionMessagesCount'?: number;
}
/**
 * 
 * @export
 * @interface MessageChannel
 */
export interface MessageChannel {
    /**
     * 
     * @type {Channel}
     * @memberof MessageChannel
     */
    'channel'?: Channel;
    /**
     * 
     * @type {string}
     * @memberof MessageChannel
     */
    'receiver'?: string | null;
}


/**
 * 
 * @export
 * @interface MessageDetails
 */
export interface MessageDetails {
    /**
     * 
     * @type {string}
     * @memberof MessageDetails
     */
    'sentStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageDetails
     */
    'sentStatusType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageDetails
     */
    'statusReason'?: string | null;
}
/**
 * 
 * @export
 * @interface MessageRecord
 */
export interface MessageRecord {
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    'messageId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageRecord
     */
    'eventId'?: number;
    /**
     * 
     * @type {MessageType}
     * @memberof MessageRecord
     */
    'messageType'?: MessageType;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    'provider'?: string | null;
    /**
     * 
     * @type {Channel}
     * @memberof MessageRecord
     */
    'channel'?: Channel;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    'receiver'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    'timestamp'?: string;
}


/**
 * 
 * @export
 * @interface MessageTemplateContract
 */
export interface MessageTemplateContract {
    /**
     * 
     * @type {number}
     * @memberof MessageTemplateContract
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageTemplateContract
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageTemplateContract
     */
    'templateText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageTemplateContract
     */
    'retailerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MessageTemplateContract
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MessageTemplateContract
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MessageTemplateContract
     */
    'channel'?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageTemplateContract
     */
    'language'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MessageTemplateContract
     */
    'notificationEventTypeKey'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MessageTemplateContract
     */
    'allowSms'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageTemplateContract
     */
    'missingCompanySubscriptionType'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MessageType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24
} as const;

export type MessageType = typeof MessageType[keyof typeof MessageType];


/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'referenceId'?: string;
    /**
     * 
     * @type {NotificationType}
     * @memberof Notification
     */
    'notificationType'?: NotificationType;
    /**
     * 
     * @type {NotificationType}
     * @memberof Notification
     */
    'reminderNotificationType'?: NotificationType;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'originalEvent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'employmentId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Notification
     */
    'templateMetas'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {ReportVia}
     * @memberof Notification
     */
    'reportVia'?: ReportVia;
    /**
     * 
     * @type {NotificationStatus}
     * @memberof Notification
     */
    'status'?: NotificationStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'isReminder'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'reportBy'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'timestamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'customerId'?: number;
}


/**
 * 
 * @export
 * @interface NotificationConfiguration
 */
export interface NotificationConfiguration {
    /**
     * 
     * @type {number}
     * @memberof NotificationConfiguration
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationConfiguration
     */
    'referenceKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationConfiguration
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationConfiguration
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationConfiguration
     */
    'retailerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationConfiguration
     */
    'valueType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationConfiguration
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationConfiguration
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface NotificationConfigurationUiModel
 */
export interface NotificationConfigurationUiModel {
    /**
     * 
     * @type {string}
     * @memberof NotificationConfigurationUiModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationConfigurationUiModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationConfigurationUiModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationConfigurationUiModel
     */
    'handleMedicalReminder'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationConfigurationUiModel
     */
    'handleEstimatedBackAtWork'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type NotificationStatus = typeof NotificationStatus[keyof typeof NotificationStatus];


/**
 * 
 * @export
 * @interface NotificationSubscription
 */
export interface NotificationSubscription {
    /**
     * 
     * @type {number}
     * @memberof NotificationSubscription
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationSubscription
     */
    'language'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationSubscription
     */
    'subscribingUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationSubscription
     */
    'subscribingUserCountry'?: string | null;
    /**
     * 
     * @type {Array<MessageChannel>}
     * @memberof NotificationSubscription
     */
    'messageChannels'?: Array<MessageChannel> | null;
    /**
     * 
     * @type {SubscriberType}
     * @memberof NotificationSubscription
     */
    'subscriberType'?: SubscriberType;
    /**
     * 
     * @type {string}
     * @memberof NotificationSubscription
     */
    'subscriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationSubscription
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationSubscription
     */
    'validTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationSubscription
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationSubscription
     */
    'subscriptionScope'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationSubscription
     */
    'targetId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationSubscription
     */
    'notificationType'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSubscription
     */
    'isRoleSubscription'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_40: 40,
    NUMBER_1000: 1000,
    NUMBER_1001: 1001,
    NUMBER_1002: 1002,
    NUMBER_1003: 1003,
    NUMBER_2000: 2000
} as const;

export type NotificationType = typeof NotificationType[keyof typeof NotificationType];


/**
 * 
 * @export
 * @interface NotificationsRequest
 */
export interface NotificationsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof NotificationsRequest
     */
    'notificationTypes'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationsRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRequest
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationsRequest
     */
    'to'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationsRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsRequest
     */
    'pageSize'?: number;
}
/**
 * 
 * @export
 * @interface NotificationsResponse
 */
export interface NotificationsResponse {
    /**
     * 
     * @type {number}
     * @memberof NotificationsResponse
     */
    'totalRows'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsResponse
     */
    'currentPage'?: number;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof NotificationsResponse
     */
    'notifications'?: Array<Notification> | null;
}
/**
 * 
 * @export
 * @interface Origin
 */
export interface Origin {
    /**
     * 
     * @type {string}
     * @memberof Origin
     */
    'service'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Origin
     */
    'className'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Origin
     */
    'methodName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Origin
     */
    'requestType'?: string | null;
}
/**
 * 
 * @export
 * @interface PartnerContact
 */
export interface PartnerContact {
    /**
     * 
     * @type {string}
     * @memberof PartnerContact
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerContact
     */
    'retailer_Id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PartnerContact
     */
    'standard_Email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartnerContact
     */
    'contact_Description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartnerContact
     */
    'language'?: string | null;
}
/**
 * 
 * @export
 * @interface ReportNotification
 */
export interface ReportNotification {
    /**
     * 
     * @type {string}
     * @memberof ReportNotification
     */
    'notificationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNotification
     */
    'absenceReportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNotification
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNotification
     */
    'text'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportNotification
     */
    'notificationType'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportNotification
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNotification
     */
    'contactKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportNotification
     */
    'channel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportNotification
     */
    'notificationEventType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportNotification
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportNotification
     */
    'notifiedUserAccountId'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportVia = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type ReportVia = typeof ReportVia[keyof typeof ReportVia];


/**
 * 
 * @export
 * @interface RoleSubscriptionConfigration
 */
export interface RoleSubscriptionConfigration {
    /**
     * 
     * @type {string}
     * @memberof RoleSubscriptionConfigration
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RoleSubscriptionConfigration
     */
    'companyId'?: number;
    /**
     * 
     * @type {NotificationType}
     * @memberof RoleSubscriptionConfigration
     */
    'notificationType'?: NotificationType;
    /**
     * 
     * @type {string}
     * @memberof RoleSubscriptionConfigration
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleSubscriptionConfigration
     */
    'role'?: string | null;
    /**
     * 
     * @type {Channel}
     * @memberof RoleSubscriptionConfigration
     */
    'channel'?: Channel;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriberType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type SubscriberType = typeof SubscriberType[keyof typeof SubscriberType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionScope = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type SubscriptionScope = typeof SubscriptionScope[keyof typeof SubscriptionScope];


/**
 * 
 * @export
 * @interface SuppressedListResponse
 */
export interface SuppressedListResponse {
    /**
     * 
     * @type {string}
     * @memberof SuppressedListResponse
     */
    'emailAdress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuppressedListResponse
     */
    'reason'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuppressedListResponse
     */
    'lastUpdated'?: string;
}
/**
 * 
 * @export
 * @interface TemporarySubscription
 */
export interface TemporarySubscription {
    /**
     * 
     * @type {string}
     * @memberof TemporarySubscription
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TemporarySubscription
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof TemporarySubscription
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TemporarySubscription
     */
    'defaultLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemporarySubscription
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TemporarySubscription
     */
    'receiver'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TemporarySubscription
     */
    'subscribingUserAccountId'?: number | null;
    /**
     * 
     * @type {Channel}
     * @memberof TemporarySubscription
     */
    'channel'?: Channel;
    /**
     * 
     * @type {string}
     * @memberof TemporarySubscription
     */
    'departmentName'?: string | null;
}


/**
 * 
 * @export
 * @interface UpsertSubscriptionRequest
 */
export interface UpsertSubscriptionRequest {
    /**
     * 
     * @type {number}
     * @memberof UpsertSubscriptionRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {SubscriptionScope}
     * @memberof UpsertSubscriptionRequest
     */
    'scope'?: SubscriptionScope;
    /**
     * 
     * @type {string}
     * @memberof UpsertSubscriptionRequest
     */
    'targetId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpsertSubscriptionRequest
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertSubscriptionRequest
     */
    'externalReceiver'?: string | null;
    /**
     * 
     * @type {Array<NotificationSubscription>}
     * @memberof UpsertSubscriptionRequest
     */
    'subscriptions'?: Array<NotificationSubscription> | null;
}


/**
 * 
 * @export
 * @interface UserMobileApp
 */
export interface UserMobileApp {
    /**
     * 
     * @type {string}
     * @memberof UserMobileApp
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileApp
     */
    'deviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileApp
     */
    'fireBaseToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMobileApp
     */
    'snsEndpointArn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMobileApp
     */
    'language'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMobileApp
     */
    'update'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileApp
     */
    'deviceType'?: string | null;
}
/**
 * 
 * @export
 * @interface UserMobileAppInfo
 */
export interface UserMobileAppInfo {
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppInfo
     */
    'deviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppInfo
     */
    'deviceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppInfo
     */
    'fireBaseToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserMobileAppInfo
     */
    'languageCode'?: string | null;
}
/**
 * 
 * @export
 * @interface UserRole
 */
export interface UserRole {
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'role'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserRole
     */
    'scope'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRole
     */
    'created'?: string;
}

/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationCompanyCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiV1ConfigurationCompanyCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/api/v1/Configuration/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationCompanyCompanyIdRolesGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiV1ConfigurationCompanyCompanyIdRolesGet', 'companyId', companyId)
            const localVarPath = `/api/v1/Configuration/company/{companyId}/roles`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {Array<AddRoleSubscriptionConfigration>} [addRoleSubscriptionConfigration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationCompanyCompanyIdRolesPost: async (companyId: number, addRoleSubscriptionConfigration?: Array<AddRoleSubscriptionConfigration>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiV1ConfigurationCompanyCompanyIdRolesPost', 'companyId', companyId)
            const localVarPath = `/api/v1/Configuration/company/{companyId}/roles`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRoleSubscriptionConfigration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationCustomerCustomerIdGet: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiV1ConfigurationCustomerCustomerIdGet', 'customerId', customerId)
            const localVarPath = `/api/v1/Configuration/customer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConfigurationCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanySubscriptionConfiguration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConfigurationCompanyCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConfigurationCompanyCompanyIdRolesGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleSubscriptionConfigration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConfigurationCompanyCompanyIdRolesGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {Array<AddRoleSubscriptionConfigration>} [addRoleSubscriptionConfigration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConfigurationCompanyCompanyIdRolesPost(companyId: number, addRoleSubscriptionConfigration?: Array<AddRoleSubscriptionConfigration>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleSubscriptionConfigration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConfigurationCompanyCompanyIdRolesPost(companyId, addRoleSubscriptionConfigration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConfigurationCustomerCustomerIdGet(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanySubscriptionConfiguration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConfigurationCustomerCustomerIdGet(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationCompanyCompanyIdGet(companyId: number, options?: any): AxiosPromise<Array<CompanySubscriptionConfiguration>> {
            return localVarFp.apiV1ConfigurationCompanyCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationCompanyCompanyIdRolesGet(companyId: number, options?: any): AxiosPromise<Array<RoleSubscriptionConfigration>> {
            return localVarFp.apiV1ConfigurationCompanyCompanyIdRolesGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {Array<AddRoleSubscriptionConfigration>} [addRoleSubscriptionConfigration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationCompanyCompanyIdRolesPost(companyId: number, addRoleSubscriptionConfigration?: Array<AddRoleSubscriptionConfigration>, options?: any): AxiosPromise<Array<RoleSubscriptionConfigration>> {
            return localVarFp.apiV1ConfigurationCompanyCompanyIdRolesPost(companyId, addRoleSubscriptionConfigration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationCustomerCustomerIdGet(customerId: number, options?: any): AxiosPromise<Array<CompanySubscriptionConfiguration>> {
            return localVarFp.apiV1ConfigurationCustomerCustomerIdGet(customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public apiV1ConfigurationCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).apiV1ConfigurationCompanyCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public apiV1ConfigurationCompanyCompanyIdRolesGet(companyId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).apiV1ConfigurationCompanyCompanyIdRolesGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {Array<AddRoleSubscriptionConfigration>} [addRoleSubscriptionConfigration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public apiV1ConfigurationCompanyCompanyIdRolesPost(companyId: number, addRoleSubscriptionConfigration?: Array<AddRoleSubscriptionConfigration>, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).apiV1ConfigurationCompanyCompanyIdRolesPost(companyId, addRoleSubscriptionConfigration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public apiV1ConfigurationCustomerCustomerIdGet(customerId: number, options?: AxiosRequestConfig) {
        return ConfigurationApiFp(this.configuration).apiV1ConfigurationCustomerCustomerIdGet(customerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationFieldApi - axios parameter creator
 * @export
 */
export const ConfigurationFieldApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationFieldGet: async (customerId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ConfigurationField`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationFieldIdDeleteDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1ConfigurationFieldIdDeleteDelete', 'id', id)
            const localVarPath = `/api/v1/ConfigurationField/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfigurationField} [configurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationFieldPost: async (configurationField?: ConfigurationField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ConfigurationField`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configurationField, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfigurationField} [configurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationFieldPut: async (configurationField?: ConfigurationField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ConfigurationField`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configurationField, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationFieldApi - functional programming interface
 * @export
 */
export const ConfigurationFieldApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationFieldApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConfigurationFieldGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigurationField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConfigurationFieldGet(customerId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConfigurationFieldIdDeleteDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConfigurationFieldIdDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfigurationField} [configurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConfigurationFieldPost(configurationField?: ConfigurationField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConfigurationFieldPost(configurationField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfigurationField} [configurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ConfigurationFieldPut(configurationField?: ConfigurationField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ConfigurationFieldPut(configurationField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationFieldApi - factory interface
 * @export
 */
export const ConfigurationFieldApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationFieldApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationFieldGet(customerId?: number, companyId?: number, options?: any): AxiosPromise<Array<ConfigurationField>> {
            return localVarFp.apiV1ConfigurationFieldGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationFieldIdDeleteDelete(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1ConfigurationFieldIdDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfigurationField} [configurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationFieldPost(configurationField?: ConfigurationField, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1ConfigurationFieldPost(configurationField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfigurationField} [configurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ConfigurationFieldPut(configurationField?: ConfigurationField, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1ConfigurationFieldPut(configurationField, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationFieldApi - object-oriented interface
 * @export
 * @class ConfigurationFieldApi
 * @extends {BaseAPI}
 */
export class ConfigurationFieldApi extends BaseAPI {
    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationFieldApi
     */
    public apiV1ConfigurationFieldGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return ConfigurationFieldApiFp(this.configuration).apiV1ConfigurationFieldGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationFieldApi
     */
    public apiV1ConfigurationFieldIdDeleteDelete(id: string, options?: AxiosRequestConfig) {
        return ConfigurationFieldApiFp(this.configuration).apiV1ConfigurationFieldIdDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfigurationField} [configurationField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationFieldApi
     */
    public apiV1ConfigurationFieldPost(configurationField?: ConfigurationField, options?: AxiosRequestConfig) {
        return ConfigurationFieldApiFp(this.configuration).apiV1ConfigurationFieldPost(configurationField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfigurationField} [configurationField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationFieldApi
     */
    public apiV1ConfigurationFieldPut(configurationField?: ConfigurationField, options?: AxiosRequestConfig) {
        return ConfigurationFieldApiFp(this.configuration).apiV1ConfigurationFieldPut(configurationField, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactInformationApi - axios parameter creator
 * @export
 */
export const ContactInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ContactInformationRequest} [contactInformationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContactInformationSendContactRequestPost: async (contactInformationRequest?: ContactInformationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/ContactInformation/SendContactRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactInformationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactInformationApi - functional programming interface
 * @export
 */
export const ContactInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ContactInformationRequest} [contactInformationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ContactInformationSendContactRequestPost(contactInformationRequest?: ContactInformationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactInformationNotification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ContactInformationSendContactRequestPost(contactInformationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactInformationApi - factory interface
 * @export
 */
export const ContactInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {ContactInformationRequest} [contactInformationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ContactInformationSendContactRequestPost(contactInformationRequest?: ContactInformationRequest, options?: any): AxiosPromise<Array<ContactInformationNotification>> {
            return localVarFp.apiV1ContactInformationSendContactRequestPost(contactInformationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactInformationApi - object-oriented interface
 * @export
 * @class ContactInformationApi
 * @extends {BaseAPI}
 */
export class ContactInformationApi extends BaseAPI {
    /**
     * 
     * @param {ContactInformationRequest} [contactInformationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactInformationApi
     */
    public apiV1ContactInformationSendContactRequestPost(contactInformationRequest?: ContactInformationRequest, options?: AxiosRequestConfig) {
        return ContactInformationApiFp(this.configuration).apiV1ContactInformationSendContactRequestPost(contactInformationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthCaseApi - axios parameter creator
 * @export
 */
export const HealthCaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} healthCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet: async (employmentId: string, healthCaseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet', 'employmentId', employmentId)
            // verify required parameter 'healthCaseId' is not null or undefined
            assertParamExists('apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet', 'healthCaseId', healthCaseId)
            const localVarPath = `/api/v1/HealthCase/Notifications/Employment/{employmentId}/HealthCase/{healthCaseId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)))
                .replace(`{${"healthCaseId"}}`, encodeURIComponent(String(healthCaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCaseApi - functional programming interface
 * @export
 */
export const HealthCaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} healthCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet(employmentId: string, healthCaseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HealthCaseNotification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet(employmentId, healthCaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthCaseApi - factory interface
 * @export
 */
export const HealthCaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCaseApiFp(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} healthCaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet(employmentId: string, healthCaseId: string, options?: any): AxiosPromise<Array<HealthCaseNotification>> {
            return localVarFp.apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet(employmentId, healthCaseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCaseApi - object-oriented interface
 * @export
 * @class HealthCaseApi
 * @extends {BaseAPI}
 */
export class HealthCaseApi extends BaseAPI {
    /**
     * 
     * @param {string} employmentId 
     * @param {string} healthCaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCaseApi
     */
    public apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet(employmentId: string, healthCaseId: string, options?: AxiosRequestConfig) {
        return HealthCaseApiFp(this.configuration).apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet(employmentId, healthCaseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InboxApi - axios parameter creator
 * @export
 */
export const InboxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxEventMessagesGet: async (offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Inbox/eventMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxEventMessagesReadAllPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Inbox/eventMessages/readAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxMessageIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1InboxMessageIdDelete', 'id', id)
            const localVarPath = `/api/v1/Inbox/message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxSubscriptionMessagesGet: async (offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Inbox/subscriptionMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxSubscriptionMessagesReadAllPut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Inbox/subscriptionMessages/readAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxTypeIdReadPut: async (type: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiV1InboxTypeIdReadPut', 'type', type)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1InboxTypeIdReadPut', 'id', id)
            const localVarPath = `/api/v1/Inbox/{type}/{id}/read`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxUnreadGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Inbox/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InboxApi - functional programming interface
 * @export
 */
export const InboxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InboxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InboxEventMessagesGet(offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InboxEventMessagesGet(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InboxEventMessagesReadAllPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InboxEventMessagesReadAllPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InboxMessageIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InboxMessageIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InboxSubscriptionMessagesGet(offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InboxSubscriptionMessagesGet(offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InboxSubscriptionMessagesReadAllPut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InboxSubscriptionMessagesReadAllPut(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InboxTypeIdReadPut(type: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InboxTypeIdReadPut(type, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InboxUnreadGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxUnreadCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InboxUnreadGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InboxApi - factory interface
 * @export
 */
export const InboxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InboxApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxEventMessagesGet(offset?: number, limit?: number, options?: any): AxiosPromise<InboxResponse> {
            return localVarFp.apiV1InboxEventMessagesGet(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxEventMessagesReadAllPut(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1InboxEventMessagesReadAllPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxMessageIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1InboxMessageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [offset] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxSubscriptionMessagesGet(offset?: number, limit?: number, options?: any): AxiosPromise<InboxResponse> {
            return localVarFp.apiV1InboxSubscriptionMessagesGet(offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxSubscriptionMessagesReadAllPut(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1InboxSubscriptionMessagesReadAllPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxTypeIdReadPut(type: string, id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1InboxTypeIdReadPut(type, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InboxUnreadGet(options?: any): AxiosPromise<InboxUnreadCountResponse> {
            return localVarFp.apiV1InboxUnreadGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InboxApi - object-oriented interface
 * @export
 * @class InboxApi
 * @extends {BaseAPI}
 */
export class InboxApi extends BaseAPI {
    /**
     * 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public apiV1InboxEventMessagesGet(offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).apiV1InboxEventMessagesGet(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public apiV1InboxEventMessagesReadAllPut(options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).apiV1InboxEventMessagesReadAllPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public apiV1InboxMessageIdDelete(id: string, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).apiV1InboxMessageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [offset] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public apiV1InboxSubscriptionMessagesGet(offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).apiV1InboxSubscriptionMessagesGet(offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public apiV1InboxSubscriptionMessagesReadAllPut(options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).apiV1InboxSubscriptionMessagesReadAllPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public apiV1InboxTypeIdReadPut(type: string, id: string, options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).apiV1InboxTypeIdReadPut(type, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InboxApi
     */
    public apiV1InboxUnreadGet(options?: AxiosRequestConfig) {
        return InboxApiFp(this.configuration).apiV1InboxUnreadGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageTemplateApi - axios parameter creator
 * @export
 */
export const MessageTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<MessageTemplateContract>} [messageTemplateContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessageTemplateSaveListPost: async (messageTemplateContract?: Array<MessageTemplateContract>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/MessageTemplate/SaveList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageTemplateContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MessageTemplateContract} [messageTemplateContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessageTemplateUpsertPut: async (messageTemplateContract?: MessageTemplateContract, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/MessageTemplate/Upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageTemplateContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageTemplateApi - functional programming interface
 * @export
 */
export const MessageTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<MessageTemplateContract>} [messageTemplateContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MessageTemplateSaveListPost(messageTemplateContract?: Array<MessageTemplateContract>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MessageTemplateSaveListPost(messageTemplateContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MessageTemplateContract} [messageTemplateContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MessageTemplateUpsertPut(messageTemplateContract?: MessageTemplateContract, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MessageTemplateUpsertPut(messageTemplateContract, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageTemplateApi - factory interface
 * @export
 */
export const MessageTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<MessageTemplateContract>} [messageTemplateContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessageTemplateSaveListPost(messageTemplateContract?: Array<MessageTemplateContract>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MessageTemplateSaveListPost(messageTemplateContract, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MessageTemplateContract} [messageTemplateContract] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessageTemplateUpsertPut(messageTemplateContract?: MessageTemplateContract, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MessageTemplateUpsertPut(messageTemplateContract, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageTemplateApi - object-oriented interface
 * @export
 * @class MessageTemplateApi
 * @extends {BaseAPI}
 */
export class MessageTemplateApi extends BaseAPI {
    /**
     * 
     * @param {Array<MessageTemplateContract>} [messageTemplateContract] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageTemplateApi
     */
    public apiV1MessageTemplateSaveListPost(messageTemplateContract?: Array<MessageTemplateContract>, options?: AxiosRequestConfig) {
        return MessageTemplateApiFp(this.configuration).apiV1MessageTemplateSaveListPost(messageTemplateContract, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MessageTemplateContract} [messageTemplateContract] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageTemplateApi
     */
    public apiV1MessageTemplateUpsertPut(messageTemplateContract?: MessageTemplateContract, options?: AxiosRequestConfig) {
        return MessageTemplateApiFp(this.configuration).apiV1MessageTemplateUpsertPut(messageTemplateContract, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessengerApi - axios parameter creator
 * @export
 */
export const MessengerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessengerGetMessageDetailsGet: async (messageId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Messenger/GetMessageDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessengerGetMessageRecordsCompanyCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiV1MessengerGetMessageRecordsCompanyCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/api/v1/Messenger/GetMessageRecords/Company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessengerGetMessageRecordsEmployerEmployerIdGet: async (employerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employerId' is not null or undefined
            assertParamExists('apiV1MessengerGetMessageRecordsEmployerEmployerIdGet', 'employerId', employerId)
            const localVarPath = `/api/v1/Messenger/GetMessageRecords/Employer/{employerId}`
                .replace(`{${"employerId"}}`, encodeURIComponent(String(employerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessengerGetMessageRecordsGet: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Messenger/GetMessageRecords`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessengerApi - functional programming interface
 * @export
 */
export const MessengerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessengerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MessengerGetMessageDetailsGet(messageId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MessengerGetMessageDetailsGet(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MessengerGetMessageRecordsCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MessengerGetMessageRecordsCompanyCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MessengerGetMessageRecordsEmployerEmployerIdGet(employerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MessengerGetMessageRecordsEmployerEmployerIdGet(employerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MessengerGetMessageRecordsGet(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MessengerGetMessageRecordsGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessengerApi - factory interface
 * @export
 */
export const MessengerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessengerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessengerGetMessageDetailsGet(messageId?: string, options?: any): AxiosPromise<MessageDetails> {
            return localVarFp.apiV1MessengerGetMessageDetailsGet(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessengerGetMessageRecordsCompanyCompanyIdGet(companyId: number, options?: any): AxiosPromise<Array<MessageRecord>> {
            return localVarFp.apiV1MessengerGetMessageRecordsCompanyCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessengerGetMessageRecordsEmployerEmployerIdGet(employerId: number, options?: any): AxiosPromise<Array<MessageRecord>> {
            return localVarFp.apiV1MessengerGetMessageRecordsEmployerEmployerIdGet(employerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MessengerGetMessageRecordsGet(email?: string, options?: any): AxiosPromise<Array<MessageRecord>> {
            return localVarFp.apiV1MessengerGetMessageRecordsGet(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessengerApi - object-oriented interface
 * @export
 * @class MessengerApi
 * @extends {BaseAPI}
 */
export class MessengerApi extends BaseAPI {
    /**
     * 
     * @param {string} [messageId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessengerApi
     */
    public apiV1MessengerGetMessageDetailsGet(messageId?: string, options?: AxiosRequestConfig) {
        return MessengerApiFp(this.configuration).apiV1MessengerGetMessageDetailsGet(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessengerApi
     */
    public apiV1MessengerGetMessageRecordsCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig) {
        return MessengerApiFp(this.configuration).apiV1MessengerGetMessageRecordsCompanyCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessengerApi
     */
    public apiV1MessengerGetMessageRecordsEmployerEmployerIdGet(employerId: number, options?: AxiosRequestConfig) {
        return MessengerApiFp(this.configuration).apiV1MessengerGetMessageRecordsEmployerEmployerIdGet(employerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessengerApi
     */
    public apiV1MessengerGetMessageRecordsGet(email?: string, options?: AxiosRequestConfig) {
        return MessengerApiFp(this.configuration).apiV1MessengerGetMessageRecordsGet(email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MobileNotificationApi - axios parameter creator
 * @export
 */
export const MobileNotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileNotificationGetDeviceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/MobileNotification/GetDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserMobileAppInfo} [userMobileAppInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileNotificationRemoveDeviceDelete: async (userMobileAppInfo?: UserMobileAppInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/MobileNotification/RemoveDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMobileAppInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserMobileAppInfo} [userMobileAppInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileNotificationUpsertDevicePost: async (userMobileAppInfo?: UserMobileAppInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/MobileNotification/UpsertDevice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userMobileAppInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MobileNotificationApi - functional programming interface
 * @export
 */
export const MobileNotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MobileNotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileNotificationGetDeviceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserMobileApp>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileNotificationGetDeviceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserMobileAppInfo} [userMobileAppInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileNotificationRemoveDeviceDelete(userMobileAppInfo?: UserMobileAppInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileNotificationRemoveDeviceDelete(userMobileAppInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserMobileAppInfo} [userMobileAppInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MobileNotificationUpsertDevicePost(userMobileAppInfo?: UserMobileAppInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MobileNotificationUpsertDevicePost(userMobileAppInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MobileNotificationApi - factory interface
 * @export
 */
export const MobileNotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MobileNotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileNotificationGetDeviceGet(options?: any): AxiosPromise<Array<UserMobileApp>> {
            return localVarFp.apiV1MobileNotificationGetDeviceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserMobileAppInfo} [userMobileAppInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileNotificationRemoveDeviceDelete(userMobileAppInfo?: UserMobileAppInfo, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MobileNotificationRemoveDeviceDelete(userMobileAppInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserMobileAppInfo} [userMobileAppInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MobileNotificationUpsertDevicePost(userMobileAppInfo?: UserMobileAppInfo, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MobileNotificationUpsertDevicePost(userMobileAppInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MobileNotificationApi - object-oriented interface
 * @export
 * @class MobileNotificationApi
 * @extends {BaseAPI}
 */
export class MobileNotificationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileNotificationApi
     */
    public apiV1MobileNotificationGetDeviceGet(options?: AxiosRequestConfig) {
        return MobileNotificationApiFp(this.configuration).apiV1MobileNotificationGetDeviceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserMobileAppInfo} [userMobileAppInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileNotificationApi
     */
    public apiV1MobileNotificationRemoveDeviceDelete(userMobileAppInfo?: UserMobileAppInfo, options?: AxiosRequestConfig) {
        return MobileNotificationApiFp(this.configuration).apiV1MobileNotificationRemoveDeviceDelete(userMobileAppInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserMobileAppInfo} [userMobileAppInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MobileNotificationApi
     */
    public apiV1MobileNotificationUpsertDevicePost(userMobileAppInfo?: UserMobileAppInfo, options?: AxiosRequestConfig) {
        return MobileNotificationApiFp(this.configuration).apiV1MobileNotificationUpsertDevicePost(userMobileAppInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationGetNotificationsForReportGet: async (absenceReportId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Notification/GetNotificationsForReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (absenceReportId !== undefined) {
                localVarQueryParameter['absenceReportId'] = absenceReportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationGetNotificationsForReportGet(absenceReportId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportNotification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationGetNotificationsForReportGet(absenceReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationGetNotificationsForReportGet(absenceReportId?: string, options?: any): AxiosPromise<Array<ReportNotification>> {
            return localVarFp.apiV1NotificationGetNotificationsForReportGet(absenceReportId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {string} [absenceReportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiV1NotificationGetNotificationsForReportGet(absenceReportId?: string, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiV1NotificationGetNotificationsForReportGet(absenceReportId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationConfigurationApi - axios parameter creator
 * @export
 */
export const NotificationConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationCompanyGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/NotificationConfiguration/Company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationConfigurationUiModel} [notificationConfigurationUiModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationCreatePost: async (notificationConfigurationUiModel?: NotificationConfigurationUiModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/NotificationConfiguration/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationConfigurationUiModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/NotificationConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationIdDeleteDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1NotificationConfigurationIdDeleteDelete', 'id', id)
            const localVarPath = `/api/v1/NotificationConfiguration/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationConfigurationUiModel} [notificationConfigurationUiModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationUpdatePut: async (notificationConfigurationUiModel?: NotificationConfigurationUiModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/NotificationConfiguration/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationConfigurationUiModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationConfigurationApi - functional programming interface
 * @export
 */
export const NotificationConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationConfigurationCompanyGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationConfigurationCompanyGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NotificationConfigurationUiModel} [notificationConfigurationUiModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationConfigurationCreatePost(notificationConfigurationUiModel?: NotificationConfigurationUiModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationConfigurationCreatePost(notificationConfigurationUiModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationConfigurationGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationConfigurationGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationConfigurationIdDeleteDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationConfigurationIdDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NotificationConfigurationUiModel} [notificationConfigurationUiModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1NotificationConfigurationUpdatePut(notificationConfigurationUiModel?: NotificationConfigurationUiModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1NotificationConfigurationUpdatePut(notificationConfigurationUiModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationConfigurationApi - factory interface
 * @export
 */
export const NotificationConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationCompanyGet(companyId?: number, options?: any): AxiosPromise<NotificationConfiguration> {
            return localVarFp.apiV1NotificationConfigurationCompanyGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationConfigurationUiModel} [notificationConfigurationUiModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationCreatePost(notificationConfigurationUiModel?: NotificationConfigurationUiModel, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1NotificationConfigurationCreatePost(notificationConfigurationUiModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationGet(id?: string, options?: any): AxiosPromise<NotificationConfiguration> {
            return localVarFp.apiV1NotificationConfigurationGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationIdDeleteDelete(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1NotificationConfigurationIdDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationConfigurationUiModel} [notificationConfigurationUiModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1NotificationConfigurationUpdatePut(notificationConfigurationUiModel?: NotificationConfigurationUiModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1NotificationConfigurationUpdatePut(notificationConfigurationUiModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationConfigurationApi - object-oriented interface
 * @export
 * @class NotificationConfigurationApi
 * @extends {BaseAPI}
 */
export class NotificationConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiV1NotificationConfigurationCompanyGet(companyId?: number, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiV1NotificationConfigurationCompanyGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationConfigurationUiModel} [notificationConfigurationUiModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiV1NotificationConfigurationCreatePost(notificationConfigurationUiModel?: NotificationConfigurationUiModel, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiV1NotificationConfigurationCreatePost(notificationConfigurationUiModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiV1NotificationConfigurationGet(id?: string, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiV1NotificationConfigurationGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiV1NotificationConfigurationIdDeleteDelete(id: string, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiV1NotificationConfigurationIdDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationConfigurationUiModel} [notificationConfigurationUiModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationConfigurationApi
     */
    public apiV1NotificationConfigurationUpdatePut(notificationConfigurationUiModel?: NotificationConfigurationUiModel, options?: AxiosRequestConfig) {
        return NotificationConfigurationApiFp(this.configuration).apiV1NotificationConfigurationUpdatePut(notificationConfigurationUiModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PartnerContactApi - axios parameter creator
 * @export
 */
export const PartnerContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} retailerId 
         * @param {number} companyId 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet: async (retailerId: number, companyId: number, language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'retailerId' is not null or undefined
            assertParamExists('apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet', 'retailerId', retailerId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet', 'companyId', companyId)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet', 'language', language)
            const localVarPath = `/api/v1/PartnerContact/GetPartnerContactInformationByRetailerAndLanguage/{retailerId}/{company_id}/{language}`
                .replace(`{${"retailerId"}}`, encodeURIComponent(String(retailerId)))
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} retailerId 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdLanguageGet: async (retailerId: number, language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'retailerId' is not null or undefined
            assertParamExists('apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdLanguageGet', 'retailerId', retailerId)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdLanguageGet', 'language', language)
            const localVarPath = `/api/v1/PartnerContact/GetPartnerContactInformationByRetailerAndLanguage/{retailerId}/{language}`
                .replace(`{${"retailerId"}}`, encodeURIComponent(String(retailerId)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerContactApi - functional programming interface
 * @export
 */
export const PartnerContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} retailerId 
         * @param {number} companyId 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet(retailerId: number, companyId: number, language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet(retailerId, companyId, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} retailerId 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdLanguageGet(retailerId: number, language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdLanguageGet(retailerId, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerContactApi - factory interface
 * @export
 */
export const PartnerContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerContactApiFp(configuration)
    return {
        /**
         * 
         * @param {number} retailerId 
         * @param {number} companyId 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet(retailerId: number, companyId: number, language: string, options?: any): AxiosPromise<PartnerContact> {
            return localVarFp.apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet(retailerId, companyId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} retailerId 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdLanguageGet(retailerId: number, language: string, options?: any): AxiosPromise<PartnerContact> {
            return localVarFp.apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdLanguageGet(retailerId, language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerContactApi - object-oriented interface
 * @export
 * @class PartnerContactApi
 * @extends {BaseAPI}
 */
export class PartnerContactApi extends BaseAPI {
    /**
     * 
     * @param {number} retailerId 
     * @param {number} companyId 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerContactApi
     */
    public apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet(retailerId: number, companyId: number, language: string, options?: AxiosRequestConfig) {
        return PartnerContactApiFp(this.configuration).apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet(retailerId, companyId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} retailerId 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerContactApi
     */
    public apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdLanguageGet(retailerId: number, language: string, options?: AxiosRequestConfig) {
        return PartnerContactApiFp(this.configuration).apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdLanguageGet(retailerId, language, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPingPingGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ping/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPingPongGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ping/pong`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPingPingGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPingPingGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPingPongGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPingPongGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPingPingGet(apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPingPingGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPingPongGet(apiVersion?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPingPongGet(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public apiPingPingGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).apiPingPingGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public apiPingPongGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).apiPingPongGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionCompanyCompanyIdGet: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiV1SubscriptionCompanyCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/api/v1/Subscription/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<DeleteSubscriptionRequest>} [deleteSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionDelete: async (deleteSubscriptionRequest?: Array<DeleteSubscriptionRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionDepartmentDepartmentIdGet: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('apiV1SubscriptionDepartmentDepartmentIdGet', 'departmentId', departmentId)
            const localVarPath = `/api/v1/Subscription/department/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionEmploymentEmploymentIdGet: async (employmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('apiV1SubscriptionEmploymentEmploymentIdGet', 'employmentId', employmentId)
            const localVarPath = `/api/v1/Subscription/employment/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyid 
         * @param {string} receiver 
         * @param {boolean} [exactMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionExternalCompanyidReceiverGet: async (companyid: number, receiver: string, exactMatch?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyid' is not null or undefined
            assertParamExists('apiV1SubscriptionExternalCompanyidReceiverGet', 'companyid', companyid)
            // verify required parameter 'receiver' is not null or undefined
            assertParamExists('apiV1SubscriptionExternalCompanyidReceiverGet', 'receiver', receiver)
            const localVarPath = `/api/v1/Subscription/external/{companyid}/{receiver}`
                .replace(`{${"companyid"}}`, encodeURIComponent(String(companyid)))
                .replace(`{${"receiver"}}`, encodeURIComponent(String(receiver)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (exactMatch !== undefined) {
                localVarQueryParameter['exactMatch'] = exactMatch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpsertSubscriptionRequest} [upsertSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionPost: async (upsertSubscriptionRequest?: UpsertSubscriptionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertSubscriptionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionUserCompanyIdUserAccountIdGet: async (companyId: number, userAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiV1SubscriptionUserCompanyIdUserAccountIdGet', 'companyId', companyId)
            // verify required parameter 'userAccountId' is not null or undefined
            assertParamExists('apiV1SubscriptionUserCompanyIdUserAccountIdGet', 'userAccountId', userAccountId)
            const localVarPath = `/api/v1/Subscription/user/{companyId}/{userAccountId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userAccountId"}}`, encodeURIComponent(String(userAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionCompanyCompanyIdGet(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionCompanyCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<DeleteSubscriptionRequest>} [deleteSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionDelete(deleteSubscriptionRequest?: Array<DeleteSubscriptionRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionDelete(deleteSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionDepartmentDepartmentIdGet(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionDepartmentDepartmentIdGet(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionEmploymentEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionEmploymentEmploymentIdGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyid 
         * @param {string} receiver 
         * @param {boolean} [exactMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionExternalCompanyidReceiverGet(companyid: number, receiver: string, exactMatch?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionExternalCompanyidReceiverGet(companyid, receiver, exactMatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpsertSubscriptionRequest} [upsertSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionPost(upsertSubscriptionRequest?: UpsertSubscriptionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionPost(upsertSubscriptionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SubscriptionUserCompanyIdUserAccountIdGet(companyId: number, userAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SubscriptionUserCompanyIdUserAccountIdGet(companyId, userAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionCompanyCompanyIdGet(companyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SubscriptionCompanyCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<DeleteSubscriptionRequest>} [deleteSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionDelete(deleteSubscriptionRequest?: Array<DeleteSubscriptionRequest>, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SubscriptionDelete(deleteSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionDepartmentDepartmentIdGet(departmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SubscriptionDepartmentDepartmentIdGet(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionEmploymentEmploymentIdGet(employmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SubscriptionEmploymentEmploymentIdGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyid 
         * @param {string} receiver 
         * @param {boolean} [exactMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionExternalCompanyidReceiverGet(companyid: number, receiver: string, exactMatch?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SubscriptionExternalCompanyidReceiverGet(companyid, receiver, exactMatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpsertSubscriptionRequest} [upsertSubscriptionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionPost(upsertSubscriptionRequest?: UpsertSubscriptionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SubscriptionPost(upsertSubscriptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SubscriptionUserCompanyIdUserAccountIdGet(companyId: number, userAccountId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SubscriptionUserCompanyIdUserAccountIdGet(companyId, userAccountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiV1SubscriptionCompanyCompanyIdGet(companyId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiV1SubscriptionCompanyCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<DeleteSubscriptionRequest>} [deleteSubscriptionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiV1SubscriptionDelete(deleteSubscriptionRequest?: Array<DeleteSubscriptionRequest>, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiV1SubscriptionDelete(deleteSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiV1SubscriptionDepartmentDepartmentIdGet(departmentId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiV1SubscriptionDepartmentDepartmentIdGet(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiV1SubscriptionEmploymentEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiV1SubscriptionEmploymentEmploymentIdGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyid 
     * @param {string} receiver 
     * @param {boolean} [exactMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiV1SubscriptionExternalCompanyidReceiverGet(companyid: number, receiver: string, exactMatch?: boolean, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiV1SubscriptionExternalCompanyidReceiverGet(companyid, receiver, exactMatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpsertSubscriptionRequest} [upsertSubscriptionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiV1SubscriptionPost(upsertSubscriptionRequest?: UpsertSubscriptionRequest, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiV1SubscriptionPost(upsertSubscriptionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {number} userAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiV1SubscriptionUserCompanyIdUserAccountIdGet(companyId: number, userAccountId: number, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiV1SubscriptionUserCompanyIdUserAccountIdGet(companyId, userAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupportToolsApi - axios parameter creator
 * @export
 */
export const SupportToolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SupportToolsMessagesNotificationNotificationIdGet: async (notificationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('apiV1SupportToolsMessagesNotificationNotificationIdGet', 'notificationId', notificationId)
            const localVarPath = `/api/v1/SupportTools/Messages/Notification/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SupportToolsMessagesNotificationResentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/SupportTools/Messages/Notification/resent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationsRequest} [notificationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SupportToolsNotificationsPost: async (notificationsRequest?: NotificationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/SupportTools/Notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SupportToolsSubscriptionConfigurationCompanyCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiV1SupportToolsSubscriptionConfigurationCompanyCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/api/v1/SupportTools/SubscriptionConfiguration/Company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportToolsApi - functional programming interface
 * @export
 */
export const SupportToolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportToolsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SupportToolsMessagesNotificationNotificationIdGet(notificationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetailedMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SupportToolsMessagesNotificationNotificationIdGet(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SupportToolsMessagesNotificationResentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SupportToolsMessagesNotificationResentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NotificationsRequest} [notificationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SupportToolsNotificationsPost(notificationsRequest?: NotificationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SupportToolsNotificationsPost(notificationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SupportToolsSubscriptionConfigurationCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanySubscriptionConfiguration>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SupportToolsSubscriptionConfigurationCompanyCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupportToolsApi - factory interface
 * @export
 */
export const SupportToolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportToolsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SupportToolsMessagesNotificationNotificationIdGet(notificationId: string, options?: any): AxiosPromise<Array<DetailedMessage>> {
            return localVarFp.apiV1SupportToolsMessagesNotificationNotificationIdGet(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SupportToolsMessagesNotificationResentGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SupportToolsMessagesNotificationResentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsRequest} [notificationsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SupportToolsNotificationsPost(notificationsRequest?: NotificationsRequest, options?: any): AxiosPromise<NotificationsResponse> {
            return localVarFp.apiV1SupportToolsNotificationsPost(notificationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SupportToolsSubscriptionConfigurationCompanyCompanyIdGet(companyId: number, options?: any): AxiosPromise<Array<CompanySubscriptionConfiguration>> {
            return localVarFp.apiV1SupportToolsSubscriptionConfigurationCompanyCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportToolsApi - object-oriented interface
 * @export
 * @class SupportToolsApi
 * @extends {BaseAPI}
 */
export class SupportToolsApi extends BaseAPI {
    /**
     * 
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportToolsApi
     */
    public apiV1SupportToolsMessagesNotificationNotificationIdGet(notificationId: string, options?: AxiosRequestConfig) {
        return SupportToolsApiFp(this.configuration).apiV1SupportToolsMessagesNotificationNotificationIdGet(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportToolsApi
     */
    public apiV1SupportToolsMessagesNotificationResentGet(options?: AxiosRequestConfig) {
        return SupportToolsApiFp(this.configuration).apiV1SupportToolsMessagesNotificationResentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsRequest} [notificationsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportToolsApi
     */
    public apiV1SupportToolsNotificationsPost(notificationsRequest?: NotificationsRequest, options?: AxiosRequestConfig) {
        return SupportToolsApiFp(this.configuration).apiV1SupportToolsNotificationsPost(notificationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportToolsApi
     */
    public apiV1SupportToolsSubscriptionConfigurationCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig) {
        return SupportToolsApiFp(this.configuration).apiV1SupportToolsSubscriptionConfigurationCompanyCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SuppressionApi - axios parameter creator
 * @export
 */
export const SuppressionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuppressionGetSuppressedListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Suppression/GetSuppressedList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [recipient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuppressionRemoveRecipientDelete: async (recipient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Suppression/RemoveRecipient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (recipient !== undefined) {
                localVarQueryParameter['recipient'] = recipient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuppressionSearchSuppressedListGet: async (searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/Suppression/SearchSuppressedList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuppressionApi - functional programming interface
 * @export
 */
export const SuppressionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SuppressionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SuppressionGetSuppressedListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuppressedListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SuppressionGetSuppressedListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [recipient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SuppressionRemoveRecipientDelete(recipient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HttpStatusCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SuppressionRemoveRecipientDelete(recipient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SuppressionSearchSuppressedListGet(searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SuppressedListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SuppressionSearchSuppressedListGet(searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SuppressionApi - factory interface
 * @export
 */
export const SuppressionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SuppressionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuppressionGetSuppressedListGet(options?: any): AxiosPromise<Array<SuppressedListResponse>> {
            return localVarFp.apiV1SuppressionGetSuppressedListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [recipient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuppressionRemoveRecipientDelete(recipient?: string, options?: any): AxiosPromise<HttpStatusCode> {
            return localVarFp.apiV1SuppressionRemoveRecipientDelete(recipient, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SuppressionSearchSuppressedListGet(searchString?: string, options?: any): AxiosPromise<Array<SuppressedListResponse>> {
            return localVarFp.apiV1SuppressionSearchSuppressedListGet(searchString, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SuppressionApi - object-oriented interface
 * @export
 * @class SuppressionApi
 * @extends {BaseAPI}
 */
export class SuppressionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppressionApi
     */
    public apiV1SuppressionGetSuppressedListGet(options?: AxiosRequestConfig) {
        return SuppressionApiFp(this.configuration).apiV1SuppressionGetSuppressedListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [recipient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppressionApi
     */
    public apiV1SuppressionRemoveRecipientDelete(recipient?: string, options?: AxiosRequestConfig) {
        return SuppressionApiFp(this.configuration).apiV1SuppressionRemoveRecipientDelete(recipient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuppressionApi
     */
    public apiV1SuppressionSearchSuppressedListGet(searchString?: string, options?: AxiosRequestConfig) {
        return SuppressionApiFp(this.configuration).apiV1SuppressionSearchSuppressedListGet(searchString, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemporarySubscriptionApi - axios parameter creator
 * @export
 */
export const TemporarySubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} absenceReportId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemporarySubscriptionAbsenceReportIdSendPost: async (absenceReportId: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'absenceReportId' is not null or undefined
            assertParamExists('apiV1TemporarySubscriptionAbsenceReportIdSendPost', 'absenceReportId', absenceReportId)
            const localVarPath = `/api/v1/TemporarySubscription/{absenceReportId}/send`
                .replace(`{${"absenceReportId"}}`, encodeURIComponent(String(absenceReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemporarySubscriptionIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1TemporarySubscriptionIdDelete', 'id', id)
            const localVarPath = `/api/v1/TemporarySubscription/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemporarySubscriptionListCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiV1TemporarySubscriptionListCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/api/v1/TemporarySubscription/list/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TemporarySubscription} [temporarySubscription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemporarySubscriptionPost: async (temporarySubscription?: TemporarySubscription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/TemporarySubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(temporarySubscription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemporarySubscriptionApi - functional programming interface
 * @export
 */
export const TemporarySubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemporarySubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} absenceReportId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TemporarySubscriptionAbsenceReportIdSendPost(absenceReportId: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TemporarySubscriptionAbsenceReportIdSendPost(absenceReportId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TemporarySubscriptionIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TemporarySubscriptionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TemporarySubscriptionListCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemporarySubscription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TemporarySubscriptionListCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TemporarySubscription} [temporarySubscription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1TemporarySubscriptionPost(temporarySubscription?: TemporarySubscription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1TemporarySubscriptionPost(temporarySubscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemporarySubscriptionApi - factory interface
 * @export
 */
export const TemporarySubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemporarySubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} absenceReportId 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemporarySubscriptionAbsenceReportIdSendPost(absenceReportId: string, requestBody?: Array<string>, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1TemporarySubscriptionAbsenceReportIdSendPost(absenceReportId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemporarySubscriptionIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1TemporarySubscriptionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemporarySubscriptionListCompanyIdGet(companyId: number, options?: any): AxiosPromise<Array<TemporarySubscription>> {
            return localVarFp.apiV1TemporarySubscriptionListCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TemporarySubscription} [temporarySubscription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1TemporarySubscriptionPost(temporarySubscription?: TemporarySubscription, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1TemporarySubscriptionPost(temporarySubscription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemporarySubscriptionApi - object-oriented interface
 * @export
 * @class TemporarySubscriptionApi
 * @extends {BaseAPI}
 */
export class TemporarySubscriptionApi extends BaseAPI {
    /**
     * 
     * @param {string} absenceReportId 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemporarySubscriptionApi
     */
    public apiV1TemporarySubscriptionAbsenceReportIdSendPost(absenceReportId: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return TemporarySubscriptionApiFp(this.configuration).apiV1TemporarySubscriptionAbsenceReportIdSendPost(absenceReportId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemporarySubscriptionApi
     */
    public apiV1TemporarySubscriptionIdDelete(id: string, options?: AxiosRequestConfig) {
        return TemporarySubscriptionApiFp(this.configuration).apiV1TemporarySubscriptionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemporarySubscriptionApi
     */
    public apiV1TemporarySubscriptionListCompanyIdGet(companyId: number, options?: AxiosRequestConfig) {
        return TemporarySubscriptionApiFp(this.configuration).apiV1TemporarySubscriptionListCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TemporarySubscription} [temporarySubscription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemporarySubscriptionApi
     */
    public apiV1TemporarySubscriptionPost(temporarySubscription?: TemporarySubscription, options?: AxiosRequestConfig) {
        return TemporarySubscriptionApiFp(this.configuration).apiV1TemporarySubscriptionPost(temporarySubscription, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserRoleApi - axios parameter creator
 * @export
 */
export const UserRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddUserRole} [addUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserRoleAddPost: async (addUserRole?: AddUserRole, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/UserRole/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUserRole} [deleteUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserRoleDeletePost: async (deleteUserRole?: DeleteUserRole, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/UserRole/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRoleApi - functional programming interface
 * @export
 */
export const UserRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddUserRole} [addUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserRoleAddPost(addUserRole?: AddUserRole, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRole>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserRoleAddPost(addUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUserRole} [deleteUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1UserRoleDeletePost(deleteUserRole?: DeleteUserRole, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1UserRoleDeletePost(deleteUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserRoleApi - factory interface
 * @export
 */
export const UserRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRoleApiFp(configuration)
    return {
        /**
         * 
         * @param {AddUserRole} [addUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserRoleAddPost(addUserRole?: AddUserRole, options?: any): AxiosPromise<UserRole> {
            return localVarFp.apiV1UserRoleAddPost(addUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUserRole} [deleteUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1UserRoleDeletePost(deleteUserRole?: DeleteUserRole, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1UserRoleDeletePost(deleteUserRole, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRoleApi - object-oriented interface
 * @export
 * @class UserRoleApi
 * @extends {BaseAPI}
 */
export class UserRoleApi extends BaseAPI {
    /**
     * 
     * @param {AddUserRole} [addUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public apiV1UserRoleAddPost(addUserRole?: AddUserRole, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).apiV1UserRoleAddPost(addUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUserRole} [deleteUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleApi
     */
    public apiV1UserRoleDeletePost(deleteUserRole?: DeleteUserRole, options?: AxiosRequestConfig) {
        return UserRoleApiFp(this.configuration).apiV1UserRoleDeletePost(deleteUserRole, options).then((request) => request(this.axios, this.basePath));
    }
}


