import * as UserSelectors from "store/userSelectors";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { useTranslation } from "react-i18next";
import MiniMenu from "components/MiniMenu";
import * as styles from "./AbsenceReportHistoryCard.styles";
import AbsenceReportEditFinished from "../AbsenceReportEditFinished";
import { getDateDisplayValue } from "utils/date";
import { useAppSelector } from "store/hooks";

interface IProps {
    id: string;
    preset: styles.IAbsenceReportHistoryCardPresets;
    className?: string;
    sortedFinishedAbsenceReports: IAbsenceReportExtended[];
    getMiniMenuItems: (absenceReport: IAbsenceReportExtended) => {
        text: string;
        onClick: () => void;
    }[];
}
const SmallHistoryCard = ({ id, preset, className, sortedFinishedAbsenceReports, getMiniMenuItems }: IProps) => {
    const { t } = useTranslation();
    const medicalOrCustomOrAdminAcces = useAppSelector(UserSelectors.getMedicalOrCustomOrAdminAccess);
    return (
        <div id={id} className={`${styles.absenceReportHistoryCardPresets[preset]} ${className}`}>
            {sortedFinishedAbsenceReports.map((finishedAR) => {
                if (finishedAR.isEdit)
                    return (
                        <div key={finishedAR.id}>
                            <AbsenceReportEditFinished absenceReport={finishedAR} preset={preset} id={finishedAR.id!} />
                        </div>
                    );
                return (
                    <div>
                        <div className="border-b border-mhgrey-mediumdisabled mt-5" key={`border-${finishedAR.id}`} />
                        <div className="w-full px-6">
                            <div className="flex">
                                <p className="w-1/2 mt-6 font-bold">
                                    {finishedAR.type === "Sick" ? t("sick") : t("careOfChild")}
                                </p>
                                {medicalOrCustomOrAdminAcces && (
                                    <MiniMenu
                                        buttonValues={getMiniMenuItems(finishedAR)}
                                        id={id + finishedAR.id}
                                        preset="white"
                                        buttonRight
                                        containerClassName="mt-4 w-1/2 flex justify-end"
                                    />
                                )}
                            </div>
                            <p className="mb-3">
                                {`${getDateDisplayValue(finishedAR.start)} - ${getDateDisplayValue(
                                    finishedAR.end ?? undefined,
                                )} | ${finishedAR.degree}%`}
                            </p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SmallHistoryCard;
