import { ILoginPageData, LoginUsing } from "pages/login/types";
import { useTranslation } from "react-i18next";
import BackButtonHeader from "../BackButtonHeader";
import BankIdPending from "./BankIdPending";

const BankIdSameDevice = (loginPageData: ILoginPageData) => {
    const { t } = useTranslation("login");
    const { loginUsing, isLoading, bankIdProps } = loginPageData;

    return (
        <div className="mb-14">
            <BackButtonHeader
                heading={t("bankIdOnThisUnit")}
                onClick={() =>
                    loginUsing({
                        loginUsing: LoginUsing.default,
                        navigateOrAbort: {
                            navigateTo: "bankIdOptions",
                            resetBankIdData: bankIdProps.bankIdPending || isLoading,
                        },
                        returnUrl: loginPageData.returnUrl,
                    })
                }
            />
            {loginPageData.bankIdProps.bankIdPending && <BankIdPending {...loginPageData} />}
        </div>
    );
};

export default BankIdSameDevice;
