import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback, useEffect } from "react";
import { modalActions } from "store/modal/modalSlice";
import * as UserSelectors from "store/userSelectors";
import ModalFrame, { IProps } from "./ModalFrame";

export default function Index(props: IProps) {
    const activeRetailer = useAppSelector(UserSelectors.getUserRetailer);

    const dispatch = useAppDispatch();
    const setOpen = useCallback(
        (value: boolean, afterClose?: boolean) => {
            dispatch(modalActions.setModal({ type: "allModals", value: value }));
            if (afterClose) return;
            props.setOpen(value);
        },
        [dispatch, props],
    );

    useEffect(() => {
        if (props.open === true) dispatch(modalActions.setModal({ type: "allModals", value: true }));
    }, [dispatch, props.open]);
    return <ModalFrame {...props} activeRetailer={activeRetailer} setOpen={setOpen} />;
}

export const getRetailerBg = (retailer: string, isTailwind?: boolean): string => {
    if (!retailer) return "";
    switch (retailer) {
        case "Medhelp":
            return isTailwind ? "bg-mhbeige" : "#fffaf5";
        default:
            return isTailwind ? "bg-white" : "#FFFFFF";
    }
};
