import DropdownFilter, { IData } from "../DropdownFilter";

interface IPropsView {
    open: boolean;
    setOpen: (value: boolean) => void;
    item: (name: string) => JSX.Element;
    data: IData[];
    header: (name: string) => JSX.Element;
}

const ShowBasedOnFilter = ({ header, item, data, setOpen, open }: IPropsView) => (
    <DropdownFilter
        data={data}
        preset="minimal"
        item={item}
        customHeader={header}
        setOpen={setOpen}
        open={open}
        type="showBasedOn"
    />
);

export default ShowBasedOnFilter;
