import { isEmpty } from "lodash/fp";
import { widgetMap } from "../components/Widget";
import { hasCustomerSupport } from "utils/accessRights";
import { IAccessRightTypeEnum } from "interfaces/IAccountAccessRights";
import { AccountAccessRightViewModel } from "swagger/usercontextservice";
import { MedHelpHCMDomainTemplate } from "swagger/healthCaseManagement";
import { IAddWidgetType } from "../components/AddWidget/listOfWidgetsToAdd";
import { IDynamicData, IWidget, isWidgetBase } from "./dashboardTypes";

const getAccessRightTypes = (widgetAccessRight: string) => {
    switch (widgetAccessRight) {
        case "absenceWidget":
            return [IAccessRightTypeEnum.absenceFollowup, IAccessRightTypeEnum.absenceView];
        case "followUpWidget":
            return [IAccessRightTypeEnum.absenceFollowup];
        case "rehabWidget":
            return [IAccessRightTypeEnum.rehabDepartmentManagement];
        case "statisticsWidget":
            return [IAccessRightTypeEnum.absenceStatistics];
        default:
            return [];
    }
};

export function filterWidgetsByAccessRights(
    accessRights: Array<AccountAccessRightViewModel>,
    widgetData: IWidget[],
    templates: MedHelpHCMDomainTemplate[],
    hasBaseProduct: boolean,
): IWidget[] {
    if (hasCustomerSupport(accessRights)) {
        return widgetData;
    } else {
        const widgetsFilteredByAccessRight = widgetData.filter((widget: IWidget) => {
            const widgetType = widgetMap.get(widget.type);
            const accessRightTypes = getAccessRightTypes(widgetType?.accessRight || "");
            if (widgetType?.accessRight === "statisticsWidget") {
                return accessRights?.some(
                    (accessRight) =>
                        accessRightTypes.includes(accessRight.accessRightType?.valueOf() || 0) &&
                        accessRight.name?.includes("**TopDepartment**"),
                );
            } else if (
                //Special case for access only if hcm - can be removed once all companies with rehabcontract have hcm
                widget.type === "hcm-todo" ||
                widget.type === "hcm-status-overview"
            ) {
                return (
                    !isEmpty(templates) &&
                    accessRights?.some((accessRight) =>
                        accessRightTypes.includes(accessRight.accessRightType?.valueOf() || 0),
                    )
                );
            } else if (
                //Special case for access only if rehab - can be removed once all companies with rehabcontract have hcm
                widget.type === "last-activated-rehab" ||
                widget.type === "ongoing-rehab"
            ) {
                return (
                    isEmpty(templates) &&
                    accessRights?.some((accessRight) =>
                        accessRightTypes.includes(accessRight.accessRightType?.valueOf() || 0),
                    )
                );
            } else {
                return accessRights?.some((accessRight) =>
                    accessRightTypes.includes(accessRight.accessRightType?.valueOf() || 0),
                );
            }
        });

        const widgetsFilteredByAccessRightAndProduct = hasBaseProduct
            ? widgetsFilteredByAccessRight.filter((widget) => isWidgetBase(widget.type))
            : widgetsFilteredByAccessRight;

        return widgetsFilteredByAccessRightAndProduct;
    }
}

export function filterWidgetsToAddByAccessRights(
    accessRights: Array<AccountAccessRightViewModel>,
    addWidgetList: IAddWidgetType[],
    templates: MedHelpHCMDomainTemplate[],
    hasBaseProduct: boolean,
): IAddWidgetType[] {
    if (hasCustomerSupport(accessRights)) {
        return addWidgetList;
    } else {
        const widgetsFilteredByAccessRight = addWidgetList.filter((widget: IAddWidgetType) => {
            const widgetType = widgetMap.get(widget.type);
            const accessRightTypes = getAccessRightTypes(widgetType?.accessRight || "");
            if (widgetType?.accessRight === "statisticsWidget") {
                return accessRights?.some(
                    (accessRight) =>
                        accessRightTypes.includes(accessRight.accessRightType?.valueOf() || 0) &&
                        accessRight.name?.includes("**TopDepartment**"),
                );
            } else if (
                //Special case for access only if hcm - can be removed once all companies with rehabcontract have hcm
                widget.type === "hcm-todo" ||
                widget.type === "hcm-status-overview"
            ) {
                return (
                    !isEmpty(templates) &&
                    accessRights?.some((accessRight) =>
                        accessRightTypes.includes(accessRight.accessRightType?.valueOf() || 0),
                    )
                );
            } else if (
                //Special case for access only if rehab - can be removed once all companies with rehabcontract have hcm
                widget.type === "last-activated-rehab" ||
                widget.type === "ongoing-rehab"
            ) {
                return (
                    isEmpty(templates) &&
                    accessRights?.some((accessRight) =>
                        accessRightTypes.includes(accessRight.accessRightType?.valueOf() || 0),
                    )
                );
            } else {
                return accessRights?.some((accessRight) =>
                    accessRightTypes.includes(accessRight.accessRightType?.valueOf() || 0),
                );
            }
        });

        const widgetsFilteredByAccessRightAndProduct = hasBaseProduct
            ? widgetsFilteredByAccessRight.filter((widget) => isWidgetBase(widget.type))
            : widgetsFilteredByAccessRight;

        return widgetsFilteredByAccessRightAndProduct;
    }
}

export function filterCompaniesByAccessRights(
    accessRights: Array<AccountAccessRightViewModel>,
    companies: Array<IDynamicData>,
    widgetAccessRight: string,
) {
    const accessRightTypes = getAccessRightTypes(widgetAccessRight);

    const companiesWithAccessRights = hasCustomerSupport(accessRights)
        ? companies
        : companies.filter((company: IDynamicData) =>
              accessRights?.some(
                  (accessRight) =>
                      accessRight.companyId === company.id &&
                      accessRightTypes.includes(
                          accessRight.accessRightType?.valueOf() ||
                              (0 &&
                                  widgetAccessRight === "statisticsWidget" &&
                                  accessRight.name?.includes("**TopDepartment**")),
                      ),
              ),
          );
    return companiesWithAccessRights;
}
