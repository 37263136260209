import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServices } from "api/services";
import { AxiosResponse } from "axios";
import { flow, get, isEqual, some, isNumber, isUndefined, isEmpty, compact, includes } from "lodash/fp";
import { RootState } from "store";
import {
    MedHelpAbsenceFollowUpWebApiResponseAbsence,
    MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse,
} from "swagger/absencefollowup";
import * as UserSelectors from "store/userSelectors";
import {
    CompanyRegion,
    CostCalculationBasisViewModel,
    EmploymentEraseSettings,
    TreeViewNode,
} from "swagger/employeeattendance";
import { HealthCaseType, IUserSubscription } from "../types";
import * as SearchApi from "../utils";
import {
    getActiveRequiredMedicalCertificate,
    getAllSortingFilters,
    getOnlyActiveSearchFilters,
} from "./followUpSelectors";
import { getUserLanguage } from "store/userSelectors";
import { IRehab, ISearchPayload, selectors, actions } from ".";
import { EmployementGroup } from "./followUpStateTypes";
import { SearchTypes, SEARCH_PAGE_SIZE } from "../utils";
import { getAccessRightForFollowUp, FollowUpReportSearchTypeAccessRights } from "../accessRights";
import { IAccessRightData } from "domain/accessRights";
import {
    MedHelpHCMWebApiModelsCreateHealthCaseActivityModel,
    MedHelpHCMWebApiModelsCreateHealthCaseModel,
    MedHelpHCMWebApiModelsCreateTemplate,
    MedHelpHCMWebApiModelsExportHealthCaseModel,
    MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel,
    MedHelpHCMWebApiModelsSetHealthCaseStatusModel,
    MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel,
    MedHelpHCMWebApiModelsUpdateHealthCaseDocument,
} from "swagger/healthCaseManagement";
import { AccountAccessRightViewModel } from "swagger/usercontextservice";
import { CompanyRegion as CompanyRegionUtils } from "domain/ogranization";
import { MedHelpHCMWebApiModelsCreateHealthCaseDocument } from "swagger/healthCaseManagement";
import { getIfFollowUpRoute } from "routes";
import { getDate, newDateString, getStringFromDate } from "utils/date";

const selectCompanyIds = CompanyRegionUtils.getCompanyIdsByAccessRight;
interface IFetchAbsenceReports {
    employmentGuid: string;
}

export interface IDeviant {
    absenceReports: string[];
    code: string;
    type: string;
    validFrom: string;
    validTo: string | null;
}

export interface IDeviantResponse {
    data: IDeviant[];
}

interface IFetchFullfieldAbsencePayload {
    deviant: IDeviant[];
    absenceOverview: MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse[];
    absenceList: MedHelpAbsenceFollowUpWebApiResponseAbsence[];
}
interface IRejectedFetchOverviewPayload {
    deviant?: { data: IDeviant[] };
    absenceOverview?: {
        data: MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse[];
    };
    absenceList?: { data: MedHelpAbsenceFollowUpWebApiResponseAbsence[] };
}

interface IFetchSearchInitPayload {
    companyId?: number;
    fetch?: "departments" | "companies" | "all" | "employmentGroups";
    selectAllDepartments?: boolean;
    employementGuid: string;
    currentSearch?: SearchTypes;
}

interface IFetchFullfieldSearchInitResponse {
    companiesWithRegion?: CompanyRegion[];
    organizationTree?: TreeViewNode[];
    employmentGroups?: EmployementGroup[];
    accessRights?: AccountAccessRightViewModel[] | undefined;
    followUpAccessRights: readonly IAccessRightData[] | null;
    hasCustomerSupport: boolean;
}

export const fetchSearchInit = createAsyncThunk<IFetchFullfieldSearchInitResponse, IFetchSearchInitPayload>(
    "fetchSearchInit",
    async (payload: IFetchSearchInitPayload, thunkApi) => {
        const { companyId: givenCompanyId, fetch, currentSearch: givenSearch } = payload;
        let companyId = givenCompanyId;
        const followUpPage = getIfFollowUpRoute();
        const state = thunkApi.getState() as RootState;
        const hasCustomerSupport = UserSelectors.getHasCustomerSupport(state);
        const userAccessRights = UserSelectors.getUserAccessRights(state);
        const companyAccessRights = UserSelectors.getUserCompanyAccessRights(state);
        const organizationTreeViewNode = selectors.getOrganizationTree(state);
        const currentSearch = givenSearch || selectors.getCurrentSearch(state);
        const companiesWithRegion = selectors.getCompanies(state);
        const searchCompanies = selectors.getSearchCompanies(state);
        let thunkResponse = {
            companiesWithRegion,
            organizationTree: organizationTreeViewNode,
        };
        if (isUndefined(companyId)) {
            throw Error("cant fetch with undefined company id");
        }
        let companies: CompanyRegion[] | null = companiesWithRegion || null;
        if (fetch === "companies" || fetch === "all") {
            companies = await SearchApi.requestCompanyOrganization(companyId).then(({ data }) =>
                hasCustomerSupport
                    ? data
                    : data.filter((x) =>
                          includes(
                              x.id,
                              companyAccessRights?.map((x) => x.id),
                          ),
                      ),
            );
            thunkResponse = {
                ...thunkResponse,
                companiesWithRegion: companies || [],
            };
        }
        const getSelectCompanyIds = selectCompanyIds(
            FollowUpReportSearchTypeAccessRights[currentSearch],
            hasCustomerSupport,
            userAccessRights,
        );
        const companyIds = getSelectCompanyIds(searchCompanies || companies || []);
        if (
            (fetch === "departments" || isEmpty(organizationTreeViewNode) || fetch === "all") &&
            fetch !== "employmentGroups"
        ) {
            let departmentCompanyId = companyId;
            if (companyIds.length === 1) {
                departmentCompanyId = companyIds[0];
            }
            const departments = await SearchApi.requestDepartments(departmentCompanyId).then(get("data"));
            thunkResponse = {
                ...thunkResponse,
                organizationTree: departments || [],
            };
        }
        // @ts-expect-error
        const employmentGroups: EmployementGroup[] | undefined = isEmpty(companyIds)
            ? null
            : await SearchApi.requestEmployementGroups(compact(companyIds))
                  .then((v) => {
                      return get("data", v);
                  })
                  .catch(() => null);

        const definedFollowUpPage = followUpPage ?? "reports";
        const followUpAccessRights = definedFollowUpPage
            ? getAccessRightForFollowUp(definedFollowUpPage, currentSearch)
            : null;

        return {
            ...thunkResponse,
            employmentGroups,
            accessRights: userAccessRights,
            followUpAccessRights,
            currentSearch,
            hasCustomerSupport,
        };
    },
);

export const fetchAbsenceOverview = createAsyncThunk<
    IFetchFullfieldAbsencePayload,
    IFetchAbsenceReports,
    {
        rejectValue: IRejectedFetchOverviewPayload;
    }
>("fetchAbsenceOverview", async (payload: IFetchAbsenceReports, thunkApi) => {
    const { employmentGuid } = payload;
    const deviantReq = getServices()
        .clients.deviant.deviantAllEmploymentIdGet(employmentGuid)
        .then((value) => ({ key: "deviant", ...value }));
    const absenceOverviewReq = getServices()
        .clients.absence.absenceOverviewEmploymentIdGet(employmentGuid)
        .then((value) => ({ key: "absenceOverview", ...value }));
    const absenceListReq = getServices()
        .clients.absence.absenceListEmploymentIdGet(employmentGuid)
        .then((value) => ({ key: "absenceList", ...value }));

    const response = await Promise.allSettled([deviantReq, absenceOverviewReq, absenceListReq]);

    const values = response.filter(flow(get("status"), isEqual("fulfilled"))).map(get("value"));

    const notAll = some(({ status }) => status === "rejected", response);
    if (notAll) {
        const deviant = values.find(flow(get("key"), isEqual("deviant")));
        const absenceOverview = values.find(flow(get("key"), isEqual("absenceOverview")));
        const absenceList = values.find(flow(get("key"), isEqual("absenceList")));
        return thunkApi.rejectWithValue({
            deviant,
            absenceOverview,
            absenceList,
        });
    }
    return {
        deviant: values[0].data as IDeviant[],
        absenceOverview: values[1].data as MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse[],
        absenceList: values[2].data as MedHelpAbsenceFollowUpWebApiResponseAbsence[],
    };
});

export type ActivityEvents =
    | "absenceEventReportedAbsence"
    | "absenceEventReportedRecovery"
    | "absenceEventAbsenceReportedToSocialInsurance"
    | "ReportedRecovery"
    | "ReportedVABRecovery"
    | "absenceEventEditedAbsence"
    | "EditedVABReport"
    | "absenceEventReported"
    | "AbsenceReminder"
    | "ReportedSick"
    | "MedicalCertificateMessage"
    | "EditedSickReport"
    | "ReportedVAB"
    | "FollowUpAdvisoryProcessedNotOk"
    | "workorderProcessedOk"
    | "workorderProcessedNotOk";

interface IContacts {
    name: string;
    contactKey: string;
}
export interface IEditValues {
    newValue: string;
    oldValue: string | null;
    propertyName: string;
    propertyType: string;
}

export type ReportedVia = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "0";
export interface IActivityLog {
    contacts: IContacts[];
    date: string;
    editValues: IEditValues[] | undefined;
    eventType: ActivityEvents;
    performedBy: string;
    performedByUserAccountId: number;
    reportedVia: ReportedVia;
    text: string | null;
    workOrderStatus: number;
}
interface IActivityLogResponse {
    data: IActivityLog[];
}
export const fetchAbsenceActivityLogs = createAsyncThunk(
    "fetchAbsenceActivityLogs",
    async (absenceReportId: string) => {
        try {
            // @ts-expect-error not typed?
            const activityLogs: IActivityLogResponse =
                await getServices().clients.employment.apiEmploymentGetAbsenceLogsGet(absenceReportId);
            return { absenceReportId, activityLogs: activityLogs.data };
        } catch (error) {
            return { absenceReportId, activityLogs: [] };
        }
    },
);

export const fetchEmployment = createAsyncThunk("fetchEmployment", async (empId: string) => {
    try {
        const userEmployment = await getServices().clients.employment.apiEmploymentGetUserEmploymentGet(
            undefined,
            empId,
        );

        return userEmployment.data;
    } catch (error) {
        console.error({ error });
        return false;
    }
});

export const fetchRehab = createAsyncThunk("fetchRehab", async (employmentId: number | null, thunkApi) => {
    try {
        if (employmentId) {
            const rehab = await getServices().clients.rehab.apiRehabGet(employmentId);
            const data: any = rehab.data;
            return data as IRehab;
        }
    } catch (error) {
        console.error({ error });
        return thunkApi.rejectWithValue(false);
    }
});

interface ISubscriptionResponse {
    data: IUserSubscription[];
}
interface IFetchSubscribePayload {
    companyId: number;
    employmentId: number;
}

export const fetchSubscription = createAsyncThunk<
    { subscriber: any; subscription: any },
    IFetchSubscribePayload,
    { rejectValue: { code: string } }
>("fetchSubscription", async ({ companyId, employmentId }: IFetchSubscribePayload, { rejectWithValue }) => {
    try {
        // @ts-expect-error response is not typed
        const subscription: ISubscriptionResponse =
            await getServices().clients.subscribeEmployment.apiSubscribersGetSubscriberTableItemsGet(
                companyId,
                employmentId,
            );

        // @ts-expect-error response is not typed
        const subscriber: ISubscriptionResponse =
            await getServices().clients.subscribeEmployment.apiSubscribersGetSubscriptionTableItemsGet(employmentId, 1);

        return {
            subscriber: subscriber.data,
            subscription: subscription.data,
        };
    } catch (error: any) {
        if (!error.response) {
            throw error;
        }
        const code = error?.response?.status.toString() || "unknown";
        return rejectWithValue({ code });
    }
});

interface ISaveEmploymentEraseSettings {
    doNotEraseEmployment: boolean;
    reason?: string;
}
export const saveEmploymentEraseSettings = createAsyncThunk(
    "saveEmploymentEraseSettings",
    async ({ doNotEraseEmployment, reason }: ISaveEmploymentEraseSettings, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const user = UserSelectors.getUserSlice(state);
        const currentUserCompanyId = selectors.getUserCompanyId(state);
        const curentUserEmploymentId = selectors.getUserEmploymentId(state);
        const userDeleteText = selectors.getUserDeleteMessage(state);

        const companyId: number | undefined = isNumber(currentUserCompanyId) ? currentUserCompanyId : undefined;

        const employmentId: number | undefined = isNumber(curentUserEmploymentId) ? curentUserEmploymentId : undefined;

        const employmentEraseSettings: EmploymentEraseSettings = {
            companyId,
            employmentId,
            doNotEraseEmployment,
            performedByUserAccountId: user.userContextExtended?.userAccount?.id || undefined,
            doNotDeleteText: reason || userDeleteText,
        };
        try {
            await getServices().clients.employment.apiEmploymentSaveEmploymentEraseSettingsPost(
                employmentEraseSettings,
            );
            return { doNotEraseEmployment, reason };
        } catch (error) {
            console.error({ error });
            return {
                doNotEraseEmployment: !doNotEraseEmployment,
                reason: null,
            };
        }
    },
);

interface IMedicalCertificate {
    employmentGuid: string;
}

export const fetchFirstDayMedicalCertificate = createAsyncThunk(
    "fetchFirstDayMedicalCertificate",
    async ({ employmentGuid }: IMedicalCertificate) => {
        try {
            const res =
                await getServices().clients.firstDayCertificateRequired.medicalCertificatesGetByEmploymentIdGet(
                    employmentGuid,
                );
            // no data returns empty string
            if (res.status === 204) {
                return [];
            }
            return res.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    },
);

interface IFirstDayMedicalCertificateRequired {
    employmentId: string;
    startDate: string;
    endDate: string;
}
export const saveFirstDayMedicalCertificateRequired = createAsyncThunk(
    "saveFirstDayMedicalCertificateRequired",
    async (payload: IFirstDayMedicalCertificateRequired, thunkApi) => {
        try {
            const state = thunkApi.getState() as RootState;
            const currentFirstDayRequiredMedicalCertificate = getActiveRequiredMedicalCertificate(state);
            const firstDayRequiredMedicalCertificate = currentFirstDayRequiredMedicalCertificate?.[0];

            if (firstDayRequiredMedicalCertificate?.id) {
                const res = await getServices().clients.firstDayCertificateRequired.medicalCertificatesIdPut(
                    firstDayRequiredMedicalCertificate.id,
                    payload,
                );
                return res.data;
            } else {
                const res = await getServices().clients.firstDayCertificateRequired.medicalCertificatesPost(payload);
                return res.data;
            }
        } catch (error) {
            console.error(error);
            return "";
        }
    },
);
export const removeFirstDayMedicalCertificateRequired = createAsyncThunk(
    "removeFirstDayMedicalCertificateRequired",
    async (_, thunkApi) => {
        try {
            const state = thunkApi.getState() as RootState;
            const currentFirstDayRequiredMedicalCertificate = getActiveRequiredMedicalCertificate(state);
            const firstDayRequiredMedicalCertificate = currentFirstDayRequiredMedicalCertificate?.[0];

            if (firstDayRequiredMedicalCertificate?.id) {
                await getServices().clients.firstDayCertificateRequired.medicalCertificatesIdDeleteDelete(
                    firstDayRequiredMedicalCertificate.id,
                );
                return firstDayRequiredMedicalCertificate.id;
            } else {
                console.error("No id provide on delet first day medical certificate");
            }
        } catch (error) {
            console.error(error);
            return "";
        }
    },
);

interface IUpdateUserProfile {
    email?: string;
    /**
     * Work number
     */
    mobile?: string;
    /**
     *  Private Phonenumber
     */
    secondaryMobile?: string;
    /**
     * Home number
     */
    telephoneNumber: string;
}
export const updateUserProfile = createAsyncThunk(
    "updateUserProfile",
    async (payload: IUpdateUserProfile, thunkApi) => {
        try {
            const state = thunkApi.getState() as RootState;
            const userEmployment = selectors.getUserEmployment(state);
            const customerAccountId: number | undefined = isNumber(userEmployment?.userAccountId)
                ? userEmployment?.userAccountId
                : undefined;
            const userCompanyId: number | undefined = isNumber(userEmployment?.companyId)
                ? userEmployment?.companyId
                : undefined;

            await getServices().clients.customerUserSupport.apiUserUpdateEmployeeContactDetailsPost(
                customerAccountId,
                userCompanyId,
                payload,
            );
            return payload;
        } catch (error) {
            console.error(error);
        }
    },
);

interface ITranslation {
    workbookTitle: string;
    titles: {
        value: string;
        name:
            | "startdate"
            | "enddate"
            | "backatwork"
            | "absencetype"
            | "absencedegree"
            | "absencecause"
            | "workrelated"
            | "workplaceaccident";
    }[];
    absenceTypes: {
        name: "Sick" | "CareOfChild";
        value: string;
    }[];
    absenceCauses: {
        name: string;
        value: string;
    }[];
    booleanValues: {
        name: "true" | "false";
        value: string;
    }[];
}
interface IExportHistoricalAbsenceReport {
    months?: number;
    employmentId: string | null;
    companyId: number | null;
    translation: ITranslation;
}
export const exportHistoricalAbsenceReport = createAsyncThunk(
    "exportHistoricalAbsenceReport",
    async ({ months = 12, employmentId, companyId, translation }: IExportHistoricalAbsenceReport) => {
        if (employmentId && companyId) {
            try {
                const body = {
                    months,
                    employmentId,
                    translation,
                };
                await getServices().clients.excel.excelAbsenceHistoryPost(body);

                return true;
            } catch (error) {
                return false;
            }
        }
        return false;
    },
);

interface IGetEmploymentGroups {
    companyIds: number[];
    customerId: number | null;
}
export const getEmploymentGroup = createAsyncThunk(
    "getEmploymentGroup",
    async ({ companyIds, customerId }: IGetEmploymentGroups) => {
        try {
            await getServices().clients.absenceAttendance.apiAbsenceGetEmploymentGroupsPost({
                companyIds,
                customerId,
            });
        } catch (error) {
            console.error(error);
        }
    },
);

const searchTypeEndpoints = () => ({
    absencePeriod: {
        excel: getServices().clients.excel.excelPeriodsPost,
        search: getServices().clients.searchAbsence.searchAbsencePeriodsPost,
    },
    longtermAbsence: {
        excel: getServices().clients.excel.excelLongtermPost,
        search: getServices().clients.searchDeviant.searchDeviantLongtermPost,
    },
    medicalCertificate: {
        excel: getServices().clients.excel.excelMedicalCertPost,
        search: getServices().clients.searchAbsence.searchAbsenceMedicalCertPost,
    },
    ongoingAbsence: {
        excel: getServices().clients.excel.excelOngoingPost,
        search: getServices().clients.searchAbsence.searchAbsenceOngoingPost,
    },
    recurringAbsence: {
        excel: getServices().clients.excel.excelRecurringPost,
        search: getServices().clients.searchDeviant.searchDeviantRecurringPost,
    },
    monthlyReport: {
        excel: getServices().clients.excel.excelMonthlyPost,
        search: getServices().clients.searchDeviant.searchDeviantRecurringPost,
    },
    reimbursable: {
        excel: getServices().clients.excel.excelReimbursablePost,
        search: getServices().clients.searchAbsence.searchAbsenceReimbursablePost,
    },
    cases: {
        excel: getServices().clients.excelHealthCase.excelHeathCasesPost,
        search: getServices().clients.searchHealthCase.searchHealthCaseSearchHealthCasePost,
    },
    activities: {
        excel: getServices().clients.excelHealthCase.excelHeathCaseActivitiesPost,
        search: getServices().clients.searchHealthCase.searchHealthCaseSearchHealthCaseActivitiesPost,
    },
});

interface ISearch {
    type: SearchApi.SearchTypes;
    requestType: "excel" | "search";
    pageNumber?: number;
    workBookTitle: string;
    nextPage?: boolean;
}
export const search = createAsyncThunk(
    "search",
    async ({ type, pageNumber = 1, requestType, workBookTitle, nextPage = false }: ISearch, thunkApi) => {
        try {
            const state = thunkApi.getState() as RootState;
            const companies = selectors.searchComapniesFiltered(state);
            const departments = selectors.searchDepartmentsFiltered(state);
            const topDepartment = selectors.getTopDepartment(state);
            const caseTemplates = selectors.getCaseTemplates(state);
            const country = UserSelectors.getUserLanguage(state);
            const onlyActiveFilters = getOnlyActiveSearchFilters(state);
            const getSortingFilters = getAllSortingFilters(type)(state);
            if (companies) {
                const res: AxiosResponse<ISearchPayload, any> = await searchTypeEndpoints()
                    // eslint-disable-next-line no-unexpected-multiline
                    [type].search(
                        SearchApi.requestFollowUpData(
                            companies,
                            departments,
                            topDepartment,
                            onlyActiveFilters,
                            getSortingFilters,
                            pageNumber,
                            type,
                            requestType,
                            workBookTitle,
                            caseTemplates,
                            country ?? "en",
                        ),
                    )
                    .catch((error) => error);
                if (!res?.data) {
                    return thunkApi.rejectWithValue(false);
                }
                const data = !isEmpty(res.data.page)
                    ? res.data
                    : {
                          page: [],
                          pageNumber: 1,
                          pageSize: 0,
                          totalCount: 0,
                      };
                if (!nextPage) {
                    thunkApi.dispatch(actions.updatePaginatorPageNumber(1));
                    thunkApi.dispatch(actions.updateSearchPageNumber(Math.ceil((1 * 10) / SEARCH_PAGE_SIZE)));
                }
                thunkApi.dispatch(actions.addSearch({ ...data, type }));
                return data;
            }
            throw Error("No companies trying to fetch search followup");
        } catch (error) {
            console.error({ error });
        }
    },
);

export const exportToExcel = createAsyncThunk(
    "exportToExcel",
    async ({ type, pageNumber = 1, requestType, workBookTitle }: ISearch, thunkApi) => {
        try {
            const state = thunkApi.getState() as RootState;
            const companies = selectors.searchComapniesFiltered(state);
            const departments = selectors.searchDepartmentsFiltered(state);
            const topDepartment = selectors.getTopDepartment(state);
            const caseTemplates = selectors.getCaseTemplates(state);
            const country = UserSelectors.getUserLanguage(state);
            const onlyActiveFilters = getOnlyActiveSearchFilters(state);
            const getSortingFilters = getAllSortingFilters(type)(state);
            if (companies) {
                await searchTypeEndpoints()[type].excel(
                    SearchApi.requestFollowUpData(
                        companies,
                        departments,
                        topDepartment,
                        onlyActiveFilters,
                        getSortingFilters,
                        pageNumber,
                        type,
                        requestType,
                        workBookTitle,
                        caseTemplates,
                        country ?? "en",
                    ),
                );
                return true;
            }
            throw Error("No companies trying to fetch excel file");
        } catch (error) {
            console.error(error);
            return false;
        }
    },
);

interface ISaveHealthCase {
    type: HealthCaseType;
    createHealthCaseModel: MedHelpHCMWebApiModelsCreateHealthCaseModel;
}

export const saveHealthCase = createAsyncThunk(
    "saveHealthCase",
    async ({ type, createHealthCaseModel }: ISaveHealthCase) => {
        const res = await getServices().clients.healthCase.healthCasePost(createHealthCaseModel);
        return {
            ...createHealthCaseModel,
            id: res.data,
            initiationCause: "manually",
            type,
        };
    },
);

export const saveCaseTemplate = createAsyncThunk(
    "saveCaseTemplate",
    async (createCaseTemplateModel: MedHelpHCMWebApiModelsCreateTemplate) => {
        const res = await getServices().clients.template.templatePost(createCaseTemplateModel);
        return res.data;
    },
);

export const saveCaseActivity = createAsyncThunk(
    "saveCaseActivity",
    async (createCaseActivityModel: MedHelpHCMWebApiModelsCreateHealthCaseActivityModel, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const userName = UserSelectors.getUserFullName(state);
        thunkApi.dispatch(actions.updateActivitiesSubstatus(createCaseActivityModel.healthCaseId ?? ""));
        const res = await getServices().clients.healthCaseActivity.healthCaseActivityPost(createCaseActivityModel);
        return {
            ...createCaseActivityModel,
            id: res.data,
            createdByUserName: userName,
            source: "user",
        };
    },
);

interface ISaveCaseDocument {
    healthCaseId: string;
    createDocumentModel: MedHelpHCMWebApiModelsCreateHealthCaseDocument;
}

export const saveCaseDocument = createAsyncThunk(
    "saveCaseDocument",
    async ({ healthCaseId, createDocumentModel }: ISaveCaseDocument, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const userName = UserSelectors.getUserFullName(state);
        thunkApi.dispatch(actions.updateDocumentsSubstatus(healthCaseId));
        const res = await getServices().clients.healthCaseDocument.healthCaseDocumentHealthCaseIdPost(
            healthCaseId,
            createDocumentModel,
        );
        return {
            healthCaseId,
            data: {
                ...createDocumentModel,
                id: res.data,
                createdByUserName: userName,
            },
        };
    },
);

interface IFetchHealthCaseList {
    employmentId: string;
    language: string | undefined;
}

export const fetchHealthCaseList = createAsyncThunk(
    "fetchHealthCaseList",
    async ({ employmentId, language }: IFetchHealthCaseList, thunkApi) => {
        try {
            const healthCaseListRes = await getServices().clients.healthCase.healthCaseEmploymentIdListGet(
                employmentId,
                language,
            );
            let data = healthCaseListRes.data;

            const userAccountIds = data.flatMap(
                (healthCase) =>
                    healthCase.healthCaseEvents
                        ?.filter((event) => !event.createdByUserName && event.type !== "chain")
                        ?.map((user) => user.userAccountId ?? 0) ?? [],
            );

            if (isEmpty(userAccountIds)) {
                return data;
            } else {
                try {
                    const userRehabRes = await getServices().clients.userRehab.apiUserRehabaccessPost(userAccountIds);

                    const healthCases = data.map((healthCase) => {
                        const eventsWithCreatedByUserName = healthCase.healthCaseEvents?.map((event) => {
                            const matchingUser = userRehabRes.data.find(({ id }) => id === event.userAccountId);
                            return {
                                ...event,
                                createdByUserName: matchingUser?.user?.name ?? event.createdByUserName,
                            };
                        });

                        return {
                            ...healthCase,
                            healthCaseEvents: eventsWithCreatedByUserName,
                        };
                    });

                    data = healthCases;
                } catch (error) {
                    console.error(error);
                }
                return data;
            }
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    },
);

interface IFetchCaseTemplateList {
    customerId: number;
    companyId: number | undefined;
    language: string | undefined;
}

export const fetchCaseTemplateList = createAsyncThunk(
    "fetchCaseTemplateList",
    async ({ customerId, companyId, language }: IFetchCaseTemplateList) => {
        const res = await getServices().clients.template.templateListCustomerIdGet(customerId, companyId, language);
        return res.data;
    },
);

export const fetchCaseActivityList = createAsyncThunk(
    "fetchCaseActivityList",
    async (healthCaseId: string, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const language = getUserLanguage(state);
        try {
            const activityListRes =
                await getServices().clients.healthCaseActivity.healthCaseActivityHealthCaseIdListGet(
                    healthCaseId,
                    language,
                );
            let data = activityListRes.data;
            const missingCreatedByUserName = data.filter((user) => !user.createdByUserName);

            if (isEmpty(missingCreatedByUserName)) {
                return { healthCaseId, data };
            } else {
                const userAccountIds = missingCreatedByUserName.map((user) => user.createdBy ?? 0);

                try {
                    const userRehabRes = await getServices().clients.userRehab.apiUserRehabaccessPost(userAccountIds);

                    const activitiesWithCreatedByUserName = data.map((activity) => {
                        const matchingUser = userRehabRes.data.find(({ id }) => id === activity.createdBy);
                        return {
                            ...activity,
                            createdByUserName: matchingUser?.user?.name ?? activity.createdByUserName,
                        };
                    });
                    data = activitiesWithCreatedByUserName;
                } catch (error) {
                    console.error(error);
                }
                return { healthCaseId, data };
            }
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    },
);

export const fetchCaseDocumentList = createAsyncThunk(
    "fetchCaseDocumentList",
    async (healthCaseId: string, thunkApi) => {
        try {
            const documentListRes =
                await getServices().clients.healthCaseDocument.healthCaseDocumentHealthCaseIdListGet(healthCaseId);
            let data = documentListRes.data;
            const missingCreatedByUserName = data.filter((user) => !user.createdByUserName);

            if (isEmpty(missingCreatedByUserName)) {
                return { healthCaseId, data };
            } else {
                const userAccountIds = missingCreatedByUserName.map((user) => user.createdBy ?? 0);

                try {
                    const userRehabRes = await getServices().clients.userRehab.apiUserRehabaccessPost(userAccountIds);
                    const documentsWithCreatedByUserName = data.map((document) => {
                        const matchingUser = userRehabRes.data.find(({ id }) => id === document.createdBy);
                        return {
                            ...document,
                            createdByUserName: matchingUser?.user?.name ?? document.createdByUserName,
                        };
                    });
                    data = documentsWithCreatedByUserName;
                } catch (error) {
                    console.error(error);
                }
                return { healthCaseId, data };
            }
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    },
);

export const fetchCaseResponsibleList = createAsyncThunk(
    "fetchCaseResponsibleList",
    async (employmentId: string, thunkApi) => {
        try {
            const responsibleListRes =
                await getServices().clients.responsible.responsibleEmploymentIdListGet(employmentId);
            let data = responsibleListRes.data;
            const missingCreatedByUserName = data.filter((user) => !user.name);

            if (isEmpty(missingCreatedByUserName)) {
                return data;
            } else {
                const userAccountIds = missingCreatedByUserName.map((user) => user.userAccountId ?? 0);

                try {
                    const userRehabRes = await getServices().clients.userRehab.apiUserRehabaccessPost(userAccountIds);
                    const responsibleWithCreatedByUserName = data
                        .map((responsible) => {
                            const matchingUser = userRehabRes.data.find(({ id }) => id === responsible.userAccountId);
                            return {
                                ...responsible,
                                name: matchingUser?.user?.name ?? responsible.name,
                            };
                        })
                        .filter((responsible) => responsible.name);
                    data = responsibleWithCreatedByUserName;
                } catch (error) {
                    console.error(error);
                }
                return data;
            }
        } catch (error) {
            return thunkApi.rejectWithValue(error);
        }
    },
);

interface IFetchCaseEvents {
    employmentId: string;
    healthCaseId: string;
}

export const fetchCaseNotifications = createAsyncThunk(
    "fetchCaseEvents",
    async ({ employmentId, healthCaseId }: IFetchCaseEvents) => {
        const res =
            await getServices().clients.healthCaseMessenger.apiV1HealthCaseNotificationsEmploymentEmploymentIdHealthCaseHealthCaseIdGet(
                employmentId,
                healthCaseId,
            );
        return {
            healthCaseId,
            data: res.data,
        };
    },
);

export const fetchRehabServiceContract = createAsyncThunk("fetchRehabServiceContract", async (companyId: number) => {
    const res = await getServices().clients.serviceContract.apiServiceContractPartialCompanyIdGet(companyId);
    return res.data;
});

export const fetchRehabRoutineDocuments = createAsyncThunk("fetchRehabRoutineDocuments", async (companyId: number) => {
    const res = await getServices().clients.rehab.apiRehabGetRehabRoutineDocumentsGet(companyId);
    return res.data;
});

interface IActivateHealthCase {
    healthCaseId: string;
    start: string;
}

export const activateHealthCase = createAsyncThunk(
    "activateHealthCase",
    async ({ healthCaseId, start }: IActivateHealthCase) => {
        const res = await getServices().clients.healthCase.healthCaseHealthCaseIdActivatePost(healthCaseId, start);
        return res.data;
    },
);

interface IDeclineHealthCase {
    healthCaseId: string;
    reason: string;
}

export const declineHealthCase = createAsyncThunk(
    "declineHealthCase",
    async ({ healthCaseId, reason }: IDeclineHealthCase) => {
        const res = await getServices().clients.healthCase.healthCaseHealthCaseIdDeclinePost(healthCaseId, reason);
        return res.data;
    },
);

interface IUpdateCaseActivity {
    healthCaseId: string;
    activityModel: MedHelpHCMWebApiModelsUpdateHealthCaseActivityModel;
}

export const updateCaseActivity = createAsyncThunk(
    "updateCaseActivity",
    async ({ healthCaseId, activityModel }: IUpdateCaseActivity) => {
        await getServices().clients.healthCaseActivity.healthCaseActivityPut(activityModel);
        return {
            healthCaseId,
            data: {
                ...activityModel,
                updated: newDateString(),
            },
        };
    },
);

interface IUpdateCaseActivityPerformed {
    healthCaseId: string;
    activityId: string;
}

export const updateCaseActivityPerformed = createAsyncThunk(
    "updateCaseActivityPerformed",
    async ({ healthCaseId, activityId }: IUpdateCaseActivityPerformed, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const userAccountId = UserSelectors.getUserAccountId(state);
        await getServices().clients.healthCaseActivity.healthCaseActivityIdPerformedPut(activityId);
        return { healthCaseId, activityId, userAccountId };
    },
);

interface IUpdateCaseActivityUndoPerformed {
    healthCaseId: string;
    activityId: string;
}

export const updateCaseActivityUndoPerformed = createAsyncThunk(
    "updateCaseActivityUndoPerformed",
    async ({ healthCaseId, activityId }: IUpdateCaseActivityUndoPerformed, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const userAccountId = UserSelectors.getUserAccountId(state);
        await getServices().clients.healthCaseActivity.healthCaseActivityIdUndoperformedPut(activityId);
        return { healthCaseId, activityId, userAccountId };
    },
);

interface IUpdateCaseDocument {
    healthCaseId: string;
    updateDocumentModel: MedHelpHCMWebApiModelsUpdateHealthCaseDocument;
}

export const updateCaseDocument = createAsyncThunk(
    "updateCaseDocument",
    async ({ healthCaseId, updateDocumentModel }: IUpdateCaseDocument) => {
        await getServices().clients.healthCaseDocument.healthCaseDocumentPut(updateDocumentModel);
        return {
            healthCaseId,
            data: {
                ...updateDocumentModel,
                updated: getStringFromDate(getDate()),
            },
        };
    },
);

interface IUpdateCaseResponsible {
    healthCaseId: string;
    updateCaseResponsibleModel: MedHelpHCMWebApiModelsSetHealthCaseResponsibleModel;
}

export const updateCaseResponsible = createAsyncThunk(
    "updateCaseResponsible",
    async ({ healthCaseId, updateCaseResponsibleModel }: IUpdateCaseResponsible) => {
        await getServices().clients.healthCase.healthCaseHealthCaseIdSetresponsiblePost(
            healthCaseId,
            updateCaseResponsibleModel,
        );
        return { healthCaseId, data: updateCaseResponsibleModel };
    },
);

interface IDownloadFileDocument {
    pdfDocumentReferenceKey: string;
    healthCaseId: string;
    employmentId?: string;
}

export const fetchTemplateDocumentLink = createAsyncThunk(
    "fetchTemplateDocumentLink",
    async ({
        pdfDocumentReferenceKey,
        // healthCaseId needed for builder
        healthCaseId,
        employmentId,
    }: IDownloadFileDocument) => {
        const { data } =
            await getServices().clients.healthCaseDocument.healthCaseDocumentFetchtemplatedocumentlinkPdfDocumentReferenceKeyGet(
                pdfDocumentReferenceKey,
                employmentId,
            );

        return { ...data, healthCaseId };
    },
);

interface IDownloadFileWithTemplate {
    healthCaseId: string;
    documentId: string;
}

export const downloadFileWithTemplate = createAsyncThunk(
    "downloadFileWithTemplate",
    async ({ healthCaseId, documentId }: IDownloadFileWithTemplate) => {
        const res =
            await getServices().clients.healthCaseDocument.healthCaseDocumentHealthCaseIdDownloadfilewithtemplateDocumentIdGet(
                healthCaseId,
                documentId,
            );
        return res.data;
    },
);

interface IUploadFileWithTemplate {
    isEdit?: boolean;
    healthCaseId: string;
    documentTemplateId: string;
    annotation: string;
    type: string;
    title: string;
    documentId?: string;
}

export const uploadFileWithTemplate = createAsyncThunk(
    "uploadFileWithTemplate",
    async (
        {
            isEdit,
            healthCaseId,
            documentTemplateId,
            annotation,
            type,
            documentId,
            // Title is used in builder -> Setting document title
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            title,
        }: IUploadFileWithTemplate,
        thunkApi,
    ) => {
        const state = thunkApi.getState() as RootState;
        const userName = UserSelectors.getUserFullName(state);
        let res;
        if (isEdit && documentId) {
            res =
                await getServices().clients.healthCaseDocument.healthCaseDocumentHealthCaseIdUpdatefilewithtemplateDocumentIdPut(
                    healthCaseId,
                    documentId,
                    {
                        annotation,
                    },
                );
        } else {
            res =
                await getServices().clients.healthCaseDocument.healthCaseDocumentHealthCaseIdUploadfilewithtemplatePost(
                    healthCaseId,
                    { documentTemplateId, annotation, type },
                );
        }
        return {
            id: res.data,
            createdByUserName: userName,
            updated: newDateString(),
        };
    },
);

interface IUploadFileDocument {
    healthCaseId: string;
    file: File;
    type: string;
}

export const uploadFileDocument = createAsyncThunk(
    "uploadFileDocument",
    async ({ healthCaseId, file, type }: IUploadFileDocument, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const userName = UserSelectors.getUserFullName(state);
        thunkApi.dispatch(actions.updateDocumentsSubstatus(healthCaseId));
        const res = await getServices().clients.healthCaseDocument.healthCaseDocumentHealthCaseIdUploadfilePost(
            healthCaseId,
            file,
            type,
        );
        return {
            healthCaseId,
            data: {
                id: res.data,
                type,
                title: file.name,
                createdByUserName: userName,
            },
        };
    },
);

interface IEndHealthCase {
    healthCaseId: string;
    endDate: string;
    reason: string;
    comment: string;
}

export const endHealthCase = createAsyncThunk(
    "endHealthCase",
    async ({ healthCaseId, endDate, reason, comment }: IEndHealthCase) => {
        await getServices().clients.healthCase.healthCaseHealthCaseIdPost(healthCaseId, { endDate, reason, comment });
        return { healthCaseId, endDate };
    },
);

export const deleteHealthCase = createAsyncThunk("deleteHealthCase", async (id: string) => {
    const res = await getServices().clients.healthCase.healthCaseHealthCaseIdDelete(id);
    return res.data;
});

interface IDeleteCaseActivity {
    healthCaseId: string;
    activityId: string;
}

export const deleteCaseActivity = createAsyncThunk(
    "deleteCaseActivity",
    async ({ healthCaseId, activityId }: IDeleteCaseActivity) => {
        await getServices().clients.healthCaseActivity.healthCaseActivityIdDelete(activityId);
        return { healthCaseId, activityId };
    },
);

interface IDeleteCaseDocument {
    healthCaseId: string;
    documentId: string;
}

export const deleteCaseDocument = createAsyncThunk(
    "deleteCaseDocument",
    async ({ healthCaseId, documentId }: IDeleteCaseDocument) => {
        await getServices().clients.healthCaseDocument.healthCaseDocumentIdDelete(documentId);
        return { healthCaseId, documentId };
    },
);

export const downloadCaseDocument = createAsyncThunk(
    "downloadCaseDocument",
    async ({ healthCaseId, documentId }: IDeleteCaseDocument) => {
        const res =
            await getServices().clients.healthCaseDocument.healthCaseDocumentHealthCaseIdDownloadfileDocumentIdPost(
                healthCaseId,
                documentId,
                {
                    responseType: "blob",
                },
            );
        const url = URL.createObjectURL(res.data);
        window.open(url, "_blank");
    },
);

interface IDownloadRehabRoutineDocument {
    assetKey: string;
    companyId: number;
}

export const downloadRehabRoutineDocument = createAsyncThunk(
    "downloadRehabRoutineDocument",
    async ({ assetKey, companyId }: IDownloadRehabRoutineDocument) => {
        const res = await getServices().clients.rehab.apiRehabFileDownloadRoutineDocumentGet(assetKey, companyId, {
            responseType: "blob",
        });
        const url = URL.createObjectURL(res.data);
        window.open(url, "_blank");
    },
);

export const fetchCompanyGroup = createAsyncThunk("fetchCompanyGroupFromCompanyId", async (companyId: number) => {
    const { data } = await getServices().clients.organization.apiOrganizationGetCompanyGroupForCompanyIdGet(companyId);
    return data;
});

interface IFetchCostCalculation {
    companyId: number;
    year: number;
}

export const fetchCostCalculation = createAsyncThunk(
    "fetchCostCalculation",
    async ({ companyId, year }: IFetchCostCalculation) => {
        const { data } = await getServices().clients.costCalculation.apiCostCalculationBasisCompanyIdYearGet(
            companyId,
            year,
        );
        return data;
    },
);

export const saveCostCalculation = createAsyncThunk(
    "saveCostCalculation",
    async (costCalculation: CostCalculationBasisViewModel) => {
        if (costCalculation.companyId) {
            const { data } = await getServices().clients.costCalculation.apiCostCalculationBasisPost({
                ...costCalculation,
                companyId: costCalculation.companyId,
            });
            return data;
        }
    },
);

interface IFetchOrganizaionTreeView {
    companyId: number;
    typeOfSearch: string;
}

export const fetchOrganizaionTreeView = createAsyncThunk(
    "fetchOrganizaionTreeView",
    async ({ companyId, typeOfSearch }: IFetchOrganizaionTreeView) => {
        const { data } = await getServices().clients.organization.apiOrganizationGetOrganizationTreeViewGet(
            companyId,
            typeOfSearch,
        );
        return data;
    },
);

interface IExportHealthCase {
    healthCaseId: string;
    exportHealthCaseModel: MedHelpHCMWebApiModelsExportHealthCaseModel;
}
export const exportHealthCase = createAsyncThunk(
    "exportHealthCase",
    async ({ healthCaseId, exportHealthCaseModel }: IExportHealthCase) => {
        await getServices().clients.healthCase.healthCaseHealthCaseIdExportPost(healthCaseId, exportHealthCaseModel);
    },
);

interface IUpdateCaseStatus {
    healthCaseId: string;
    statusModel: MedHelpHCMWebApiModelsSetHealthCaseStatusModel;
}

export const updateCaseStatus = createAsyncThunk(
    "updateCaseStatus",
    async ({ healthCaseId, statusModel }: IUpdateCaseStatus) => {
        await getServices().clients.healthCase.healthCaseHealthCaseIdStatusPost(healthCaseId, statusModel);
        return { healthCaseId, status: statusModel.status };
    },
);

interface IFetchConfigurationFields {
    customerId: number;
    companyId?: number;
}

export const fetchConfigurationFields = createAsyncThunk(
    "fetchConfigurationFields",
    async ({ customerId, companyId }: IFetchConfigurationFields) => {
        const { data } = await getServices().clients.configurationField.apiV1ConfigurationFieldGet(
            customerId,
            companyId,
        );
        return data;
    },
);
interface IFetchPartnerContactInformation {
    retailerId: number;
    language: string;
    companyId: number;
}

export const fetchPartnerContactInformation = createAsyncThunk(
    "fetchPartnerContactInformation",
    async ({ retailerId, language, companyId }: IFetchPartnerContactInformation) => {
        const { data } =
            await getServices().clients.partnerContact.apiV1PartnerContactGetPartnerContactInformationByRetailerAndLanguageRetailerIdCompanyIdLanguageGet(
                retailerId,
                companyId,
                language,
            );
        return data;
    },
);

interface ISendContactRequest {
    userAccountId: number;
    employmentId: string;
    companyId: number;
    city: string;
    healthCaseId: string;
    retailerId: number;
}

export const sendContactRequest = createAsyncThunk("sendContactRequest", async (model: ISendContactRequest) => {
    await getServices().clients.contactInformation.apiV1ContactInformationSendContactRequestPost(model);
});
