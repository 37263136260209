export enum IAccessRightTypeEnum {
    absenceReporting = 1,
    absenceFollowup = 2,
    absenceAdministration = 3,
    customerCompanyAdministration = 4,
    customerSupport = 5,
    absenceReportingAssistance = 6,
    medicalAdvice = 7,
    absenceArchive = 8,
    medicalAdvising = 9,
    departmentAdministration = 10,
    rehabDepartmentManagement = 11,
    absenceView = 12,
    viewBillingBasis = 13,
    exportBillingBasis = 14,
    absenceStatistics = 15,
    absenceBoard = 16,
    medicalAdvisoryAbsenceAdministration = 17,
    ivrAbsenceReportingProcess = 18,
    dataErasure = 19,
    companyLevelStatistics = 20,
    openApiAdmin = 21,
    openApi = 22,
    customerOrganizationAdministration = 23,
    fileImportAdministration = 24,
}

export enum IAddedAccessRights {
    reactWeb = 1337,
}
