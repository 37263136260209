import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { editHistoricalAbsenceReport as editHistoricalAbsenceReportAction } from "../absenceReportsActions";
import { IAbsenceReportSlice } from "../absenceReportsSlice";

const editHistoricalAbsenceReport = (builder: ActionReducerMapBuilder<IAbsenceReportSlice>) =>
    builder
        .addCase(editHistoricalAbsenceReportAction.fulfilled, (state, action) => {
            const absenceReport = state?.AbsenceReports?.find(({ id }) => id === action.payload?.id);
            if (absenceReport) {
                absenceReport.start = action.payload?.start;
                absenceReport.end = action.payload?.end;
                absenceReport.backAtWork = action.payload?.backAtWork;
                absenceReport.degrees = action.payload?.degrees;
                absenceReport.degree = action.payload?.degree;
            }
        })
        .addCase(editHistoricalAbsenceReportAction.rejected, (state) => {
            state.IsOK = false;
        });

export default editHistoricalAbsenceReport;
