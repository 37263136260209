import { ReactComponent as TriangleContainer } from "icons/trianglePrimary.svg";

/**
 * Triangle container is based on `Up`
 */
interface IProps {
    className?: string;
}
export const TriangleLeft = ({ className, ...rest }: IProps) => (
    <TriangleContainer {...rest} className={`-rotate-90 ${className}`} />
);

export const TriangleRight = ({ className, ...rest }: IProps) => (
    <TriangleContainer {...rest} className={`rotate-90 ${className}`} />
);

export const TriangleDown = ({ className, ...rest }: IProps) => (
    <TriangleContainer {...rest} className={`rotate-180 ${className}`} />
);

export const TriangleUp = ({ className, ...rest }: IProps) => <TriangleContainer {...rest} className={className} />;
