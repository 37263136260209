const EmployeeSummaryLoader = () => {
    return (
        <div className="shadow-lg bg-primaryBg dashboard-width animate-pulse">
            <div className="h-17 border-b font-bold flex items-center justify-center xs:justify-start xs:pl-6">
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-56"></div>
            </div>
            <div className="flex summary-column flex-col justify-between px-8 pt-5 text-sm">
                <div className="flex pb-4">
                    <div className="w-10">
                        <div className="h-10 bg-gray-200 rounded-full dark:bg-gray-700 w-10"></div>
                    </div>
                    <div className="pl-9 font-bold ">
                        <div className="mb-3">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                        </div>

                        <div className="mb-3">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
                        </div>
                        <div className="mb-3">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                        </div>
                    </div>
                </div>
                <div className="flex pb-4">
                    <div className="w-10">
                        <div className="h-10 bg-gray-200 rounded-full dark:bg-gray-700 w-10"></div>
                    </div>
                    <div className="pl-9 font-bold ">
                        <div className="mb-3">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                        </div>

                        <div className="mb-3">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
                        </div>
                        <div className="mb-3">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                        </div>
                    </div>
                </div>
                <div className="flex pb-4">
                    <div className="w-10">
                        <div className="h-10 bg-gray-200 rounded-full dark:bg-gray-700 w-10"></div>
                    </div>
                    <div className="pl-9 font-bold ">
                        <div className="mb-3">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                        </div>

                        <div className="mb-3">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
                        </div>
                        <div className="mb-3">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-40"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeSummaryLoader;
