import { useAppSelector } from "store/hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectors } from "store/organization";
import useRows from "./Rows";
import { getColumns, selectEmployeeSearchColumns } from "../../utils";
import SearchTableHeader from "../SearchTableHeader";
import EmployeeSearchFooter from "../EmployeeSearchFooter";
import Loader from "../Loader";
import EmployeeDesktopTable from "./EmployeeDesktopTable";
import SearchAbsenceRow from "../SearchAbsenceRow";

export default function Index() {
    const { t } = useTranslation("followup");
    const currentSearch = useAppSelector(selectors.getCompaniesDepartmentsSearchType);
    const searchStatus = useAppSelector(selectors.searchCompaniesDepartmentsStatus);
    const tableProps = useAppSelector(selectors.getTableEmployeeSearchProperties(currentSearch, t));
    const Rows = useRows(tableProps);
    const ColumnData = useMemo(
        () => ({
            employee: {
                text: t("search.tableHeaders.employee"),
            },
            company: {
                text: t("search.tableHeaders.company"),
            },
            affectedDepartments: {
                text: t("search.tableHeaders.department"),
            },
        }),
        [t],
    );

    const SelectedColumns = useMemo(
        () => selectEmployeeSearchColumns(getColumns(null, "employee")).map((value) => ColumnData[value]),
        [ColumnData],
    );
    const Columns = useMemo(
        () => SelectedColumns.map((props) => <SearchTableHeader {...props} />, []),
        [SelectedColumns],
    );

    const FirstElementWidth = useMemo(() => {
        switch (currentSearch) {
            default: {
                return undefined;
            }
        }
    }, [currentSearch]);

    return (
        <EmployeeDesktopTable
            rows={Rows}
            footer={<EmployeeSearchFooter />}
            columns={Columns}
            loading={Boolean(searchStatus === "pending")}
            // loading
            placeholder={<Loader />}
            firstElementWidth={FirstElementWidth}
            RowContainer={SearchAbsenceRow}
        />
    );
}
