/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { saveFirstDayMedicalCertificateRequired as saveFirstDayMedicalCertificateRequiredAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchSubscriptionBuilder = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(saveFirstDayMedicalCertificateRequiredAction.fulfilled, (state, action) => {
            const { employmentId, startDate, endDate } = action.meta.arg;
            const newFirstDayMedicalCertificates = state.medicalCertificate.firstDayMedicalCertificate
                ?.filter(({ id }) => id !== action.payload)
                .concat({
                    id: action.payload,
                    employmentId,
                    startDate,
                    endDate,
                    deletedBy: 0,
                    deletedTime: null,
                });
            if (newFirstDayMedicalCertificates) {
                state.medicalCertificate.firstDayMedicalCertificate = newFirstDayMedicalCertificates;
            }
            state.status.medicalCertificate = Status.OK;
        })
        .addCase(saveFirstDayMedicalCertificateRequiredAction.pending, (state) => {
            state.status.medicalCertificate = Status.PENDING;
        })
        .addCase(saveFirstDayMedicalCertificateRequiredAction.rejected, (state) => {
            state.status.medicalCertificate = Status.ERROR;
        });
export default fetchSubscriptionBuilder;
