import MedicalCertificateComp from "./MedicalCertificate";

const Enhanced = () => {
    return (
        <div data-testid="medical-certificate-page-in-ar">
            <MedicalCertificateComp />
        </div>
    );
};
const MedicalCertificate = Enhanced;
export { MedicalCertificate };
