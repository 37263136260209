import { capitalize } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import SupportAccordion from "./components/SupportAccordion";
import SupportSubAccordion from "./components/SupportSubAccordion/SupportSubAccordion";
import * as AccessRightSelectors from "store/accessRightSelectors";

export interface ITranslatedObject {
    heading: string;
    content: string[];
}

const HelpGuide = () => {
    const { t } = useTranslation("support");
    const dashboardContent = t("dashboard.content", {
        returnObjects: true,
    }) as ITranslatedObject[];
    const arContent = t("absencereporting.content", {
        returnObjects: true,
    }) as ITranslatedObject[];
    const followUpEmployeeContent = t("followup/employee.content", {
        returnObjects: true,
    }) as ITranslatedObject[];
    const followUpSearchContent = t("followup/search.content", {
        returnObjects: true,
    }) as ITranslatedObject[];

    const hasSupportAdministration = useAppSelector(AccessRightSelectors.hasSupportAdministration);

    return (
        <>
            <SupportAccordion heading={t("dashboard.heading")} intro={t("dashboard.intro")}>
                {dashboardContent?.map((item: ITranslatedObject, i: number) => (
                    <SupportSubAccordion
                        key={item.heading}
                        heading={item.heading}
                        content={item.content}
                        last={i === dashboardContent.length - 1}
                    />
                ))}
            </SupportAccordion>

            <SupportAccordion heading={t("absencereporting.heading")} intro={t("absencereporting.intro")}>
                {arContent?.map((item: ITranslatedObject, i: number) => (
                    <SupportSubAccordion
                        key={item.heading}
                        heading={item.heading}
                        content={item.content}
                        last={i === arContent.length - 1}
                    />
                ))}
            </SupportAccordion>
            <SupportAccordion heading={t("followup/employee.heading")} intro={t("followup/employee.intro")}>
                {followUpEmployeeContent?.map((item: ITranslatedObject, i: number) => (
                    <SupportSubAccordion
                        key={item.heading}
                        heading={item.heading}
                        content={item.content}
                        last={i === arContent.length - 1}
                    />
                ))}
            </SupportAccordion>
            <SupportAccordion heading={t("followup/search.heading")} intro={t("followup/search.intro")}>
                {followUpSearchContent?.map((item: ITranslatedObject, i: number) => (
                    <SupportSubAccordion
                        key={item.heading}
                        heading={item.heading}
                        content={item.content}
                        last={i === arContent.length - 1}
                    />
                ))}
            </SupportAccordion>
            {hasSupportAdministration && (
                <SupportAccordion
                    heading={capitalize(t("administration"))}
                    intro={
                        <a
                            className="underline"
                            target="_blank"
                            href="https://medhelpcare-instructions.s3.eu-west-1.amazonaws.com/SWE-Lathund-administration.pdf"
                        >
                            {t("helpGuide")} - {capitalize(t("administration"))}
                        </a>
                    }
                />
            )}
        </>
    );
};

export default HelpGuide;
