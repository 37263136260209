/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { flow, get } from "lodash/fp";
import { isNotEqual } from "utils/functions";
import { removeCertificate as removeCertificateAction } from "../medicalCertificateAsyncActions";
import { IMedicalCertificateSlice, FileStatus } from "../medicalCertificateSlice";

const loadBuilder = (builder: ActionReducerMapBuilder<IMedicalCertificateSlice>) =>
    builder
        .addCase(removeCertificateAction.fulfilled, (state, action) => {
            const { groupKey } = action.meta.arg;
            state.file.pages = state.file.pages.filter(flow(get("groupKey"), isNotEqual(groupKey)));
            state.status = FileStatus.ok;
            Object.assign(state.pendingDelete, { groupKey: null, owner: null });
            state.modals.deleteMedicalCertificateConfirm = false;
        })
        .addCase(removeCertificateAction.pending, (state) => {
            state.status = FileStatus.pending;
        })
        .addCase(removeCertificateAction.rejected, (state) => {
            state.status = FileStatus.error;
        });

export default loadBuilder;
