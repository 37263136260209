import React, { useState, useRef } from "react";
import { ReactComponent as AccordionArrowDown } from "../../icons/accordionArrowDown.svg";
import { ReactComponent as AccordionArrowUp } from "../../icons/accordionArrowUp.svg";
import IconModal from "../IconModal";

export interface IAbsenceData {
    heading: string;
    dataCy?: string;
    value?: string;
    subHeading?: string;
    className?: string;
    disabled?: boolean;
    isMandatory?: boolean;
    isAdmin?: boolean;
    icon?: JSX.Element;
    date?: string;
    children?: React.ReactNode;
}

const Accordion = ({
    heading,
    value,
    subHeading,
    isMandatory,
    className,
    disabled,
    children,
    dataCy,
    icon,
    isAdmin,
    date,
}: IAbsenceData) => {
    const [open, setOpen] = useState(false);
    const scrollRef = useRef<null | HTMLDivElement>(null);

    const toggleOpen = () => {
        setOpen(!open);

        if (!open)
            setTimeout(() => {
                if (
                    typeof scrollRef.current === "object" &&
                    scrollRef &&
                    scrollRef.current &&
                    "scrollIntoView" in scrollRef.current
                ) {
                    scrollRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                    });
                }
            }, 100);
    };

    return (
        <div
            className={`flex flex-col min-h-20 items-center w-full border-b border-mhgrey-light bg-pageBg py-4 ${className}`}
            data-cy={`accordion-${dataCy}`}
        >
            <div className={`w-full flex  flex-col ${isAdmin ? "" : "px-6"}`}>
                <div
                    onClick={() => toggleOpen()}
                    onKeyDown={() => toggleOpen()}
                    role="button"
                    tabIndex={0}
                    className="flex justify-between items-center shadow-none-important"
                >
                    <div>
                        <div className="flex">
                            {icon && <div className="pr-2">{icon}</div>}
                            <p className="font-bold">
                                {heading}
                                {isMandatory ? "*" : ""}
                            </p>
                            <IconModal id={`${heading}IconModal`} heading={heading} topContent={subHeading!} />
                        </div>
                        <div>
                            {value && (
                                <p className={disabled ? "text-mhgrey-dark text-opacity-50" : ""}>
                                    {value && (value.length < 40 ? value : `${value.substring(0, 37)}...`)}
                                </p>
                            )}
                            {date && <p className="text-xs text-mhgrey-darkdisabled pt-2">{date}</p>}
                        </div>
                    </div>
                    <div className="flex justify-center pl-4">
                        <button className="px-4 py-4" type="button" onClick={() => setOpen(!open)}>
                            {!open ? <AccordionArrowDown /> : <AccordionArrowUp />}
                        </button>
                    </div>
                </div>
                {open && (
                    <div ref={scrollRef} className="pt-8 pb-4">
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Accordion;
