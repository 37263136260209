import { useRetailerColors } from "hooks";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import AbsenceReportDegreeLineChart from "../AbsenceReportDegreeLineChart";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { getAbsenceReportCardDetails } from "../../redux/absenceReportsSelectors";

const AbsenceReportCardDetail = ({ absenceReport }: { absenceReport: IAbsenceReportExtended }) => {
    const { t } = useTranslation();
    const { primary, secondary } = useRetailerColors();
    const absenceReportCardDetails = useAppSelector(getAbsenceReportCardDetails(absenceReport));

    return (
        <div className="p-6 xxs:p-8">
            {absenceReport.degrees && absenceReport.degrees?.length > 1 && (
                <>
                    <div className="text-sm opacity-75">{t("absenceDegree")}</div>
                    <AbsenceReportDegreeLineChart
                        absenceReport={absenceReport}
                        primaryColor={primary}
                        secondaryColor={secondary}
                    />
                </>
            )}
            <div className="flex flex-col gap-2">
                {absenceReportCardDetails?.map((field) => (
                    <div key={field?.id}>
                        <p className="text-sm opacity-75">{field?.header}:</p>
                        <p className="font-bold text-grey-700">{field?.value}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AbsenceReportCardDetail;
