import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectors } from "../../redux";
import DisplayFilters from "./DisplayFilters";
import { searchFilterStrings } from "../../utils";
export default function Index() {
    const { t } = useTranslation("followup");
    const searchFilters = useAppSelector(selectors.getOnlyActiveSearchFilters);
    const searchType = useAppSelector(selectors.getCurrentSearch);
    const Filters = useMemo(() => searchFilterStrings(searchFilters, searchType, t), [searchFilters, t, searchType]);
    return <DisplayFilters header={t("filter")} filters={Filters} />;
}
