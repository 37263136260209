import * as React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import TabMenu from "components/TabMenu";
import { selectors } from "../../redux";
import { isEmpty } from "lodash/fp";
import { Shadow } from "@medhelp/ui";

const FollowUpSubscribeMenu = () => {
    const { t } = useTranslation("followup");
    const userEmployment = useAppSelector(selectors.getUserEmployment);
    const { routeEmploymentGuid } = useParams();
    const navigate = useNavigate();

    const Routes = React.useMemo(
        () =>
            routeEmploymentGuid
                ? [
                      {
                          route: `followup/employee/${routeEmploymentGuid}/subscribe`,
                          name: "test",
                          indexRoute: "subscriber",
                          children: [
                              {
                                  route: "subscriber",
                                  name: t("followUpSubscribe.subscribers", {
                                      name: userEmployment?.name?.split(" ")[0],
                                  }),
                                  children: [],
                              },
                              {
                                  route: "subscribes",
                                  name: t("followUpSubscribe.subscribesTo", {
                                      name: userEmployment?.name?.split(" ")[0],
                                  }),
                                  children: [],
                              },
                          ],
                      },
                  ]
                : [],
        [routeEmploymentGuid, t, userEmployment?.name],
    );

    if (isEmpty(Routes)) {
        navigate("/absencereporting");
        return null;
    }

    return (
        <div className="flex w-full">
            <Shadow />
            <TabMenu routes={Routes} subMenuTo={`followup/employee/${routeEmploymentGuid}/subscribe`} width="full">
                <div className="mt-4">
                    <Outlet />
                </div>
            </TabMenu>
        </div>
    );
};
export default FollowUpSubscribeMenu;
