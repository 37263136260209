/* tslint:disable */
/* eslint-disable */
/**
 * MedHelp.Fortnox
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Activation
 */
export interface Activation {
    /**
     * 
     * @type {string}
     * @memberof Activation
     */
    'fortnoxConfigurationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Activation
     */
    'adminEmployeeId'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyInformation
 */
export interface CompanyInformation {
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyInformation
     */
    'databaseNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'visitAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'visitCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'visitCountryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'visitZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyInformation
     */
    'zipCode'?: string | null;
}
/**
 * 
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'employeeId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'personalIdentityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'address1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'address2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'postCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'phone1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'phone2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'employmentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'employmentForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'salaryForm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'personelType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'scheduleId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'foraType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'monthlySalary'?: number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'hourlyPay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'taxAllowance'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'taxTable'?: number;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'taxColumn'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    'autoNonRecurringTax'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    'nonRecurringTax'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'clearingNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'bankAccountNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'employedTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'averageWeeklyHours'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    'averageHourlyWage'?: string | null;
}
/**
 * 
 * @export
 * @interface FortnoxCompanyInfo
 */
export interface FortnoxCompanyInfo {
    /**
     * 
     * @type {string}
     * @memberof FortnoxCompanyInfo
     */
    'fortnoxConfigurationId'?: string;
    /**
     * 
     * @type {CompanyInformation}
     * @memberof FortnoxCompanyInfo
     */
    'companyInformation'?: CompanyInformation;
    /**
     * 
     * @type {Array<Employee>}
     * @memberof FortnoxCompanyInfo
     */
    'employees'?: Array<Employee> | null;
}
/**
 * 
 * @export
 * @interface PingModel
 */
export interface PingModel {
    /**
     * 
     * @type {string}
     * @memberof PingModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PingModel
     */
    'timestamp'?: string;
}

/**
 * FortnoxApi - axios parameter creator
 * @export
 */
export const FortnoxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Activation} [activation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fortnoxActivationPost: async (activation?: Activation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fortnox/activation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fortnoxCompanyInfoGet: async (code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Fortnox/companyInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FortnoxApi - functional programming interface
 * @export
 */
export const FortnoxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FortnoxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Activation} [activation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fortnoxActivationPost(activation?: Activation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fortnoxActivationPost(activation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fortnoxCompanyInfoGet(code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FortnoxCompanyInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fortnoxCompanyInfoGet(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FortnoxApi - factory interface
 * @export
 */
export const FortnoxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FortnoxApiFp(configuration)
    return {
        /**
         * 
         * @param {Activation} [activation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fortnoxActivationPost(activation?: Activation, options?: any): AxiosPromise<void> {
            return localVarFp.fortnoxActivationPost(activation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fortnoxCompanyInfoGet(code?: string, options?: any): AxiosPromise<FortnoxCompanyInfo> {
            return localVarFp.fortnoxCompanyInfoGet(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FortnoxApi - object-oriented interface
 * @export
 * @class FortnoxApi
 * @extends {BaseAPI}
 */
export class FortnoxApi extends BaseAPI {
    /**
     * 
     * @param {Activation} [activation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FortnoxApi
     */
    public fortnoxActivationPost(activation?: Activation, options?: AxiosRequestConfig) {
        return FortnoxApiFp(this.configuration).fortnoxActivationPost(activation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FortnoxApi
     */
    public fortnoxCompanyInfoGet(code?: string, options?: AxiosRequestConfig) {
        return FortnoxApiFp(this.configuration).fortnoxCompanyInfoGet(code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PingModel} [pingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPost: async (pingModel?: PingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PingModel} [pingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingPost(pingModel?: PingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingPost(pingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet(options?: any): AxiosPromise<void> {
            return localVarFp.pingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PingModel} [pingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPost(pingModel?: PingModel, options?: any): AxiosPromise<void> {
            return localVarFp.pingPost(pingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingGet(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).pingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PingModel} [pingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingPost(pingModel?: PingModel, options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).pingPost(pingModel, options).then((request) => request(this.axios, this.basePath));
    }
}


