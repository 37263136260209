import { useTranslation } from "react-i18next";
import AdminInput from "../../AdminInput";
import { Heading, Select } from "@medhelp/ui";
import { IPersonalInfoProps } from "pages/Administration/types";
import { genderOptions, getSelectValue } from "pages/Administration/utils";
import { AdminSearchResult } from "../..";

const PersonalInfo = ({
    firstName,
    lastName,
    ssn,
    ssnPlaceholder,
    ssnErrorMsg,
    birthYear,
    gender,
    disableInputs,
    ssnSearchProps,
    onChange,
    onValidateSsn,
}: IPersonalInfoProps) => {
    const { t } = useTranslation("administration");

    return (
        <div className="border-b">
            <div className="p-10">
                <h3 className="mb-10">{t("userInfo")}</h3>
                <div className="mb-4 flex">
                    <div className="relative">
                        <AdminInput
                            heading={t("ssn")}
                            savedValue={ssn}
                            onChange={(v) => {
                                onChange("socialSecurityNumber", v);
                                onValidateSsn("ssnErrorMsg", v);
                            }}
                            placeholder={ssnPlaceholder}
                            errorMessage={ssnErrorMsg}
                            isMandatory
                            isLoading={ssnSearchProps.searchIsLoading}
                        />
                        {ssnSearchProps.searchResultOpen && <AdminSearchResult {...ssnSearchProps} isExistingUser />}
                    </div>
                </div>
                <div className="mb-4 flex">
                    <AdminInput
                        heading={t("firstName")}
                        className="mr-4"
                        savedValue={firstName}
                        onChange={(v) => onChange("firstName", v)}
                        isMandatory
                    />
                    <AdminInput
                        heading={t("surname")}
                        savedValue={lastName}
                        onChange={(v) => onChange("lastName", v)}
                        isMandatory
                    />
                </div>
                <div className="flex">
                    <AdminInput
                        heading={t("birthYear")}
                        className="mr-4"
                        savedValue={birthYear?.toString() ?? ""}
                        onChange={(v) => {
                            if (!isNaN(Number(v))) onChange("birthYear", Number(v));
                        }}
                        disabled={disableInputs}
                        isMandatory={!disableInputs}
                    />
                    <div className="flex flex-col">
                        <Heading heading={t("gender")} disabled={disableInputs} mandatory={!disableInputs} />
                        <Select
                            containerClassName="w-full xxs:w-[308px]"
                            options={genderOptions}
                            selectedValue={getSelectValue(gender.toString(), "choseGender")}
                            onChange={(v) => {
                                if (!isNaN(Number(v.value))) onChange("gender", Number(v.value));
                            }}
                            translationKey={"administration"}
                            disabled={disableInputs}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfo;
