import { useEffect } from "react";
import { Error } from "@medhelp/ui";
import { Status } from "store/userStateTypes";
import { useTranslation } from "react-i18next";
import Employees from "./components/Employees";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SpinnerWithOverlay } from "components/Spinner";
import { getStatus } from "./redux/fortnoxAdministrationSelectors";
import { initFortnoxAdmin } from "./redux/fortnoxAdministrationActions";
import FortnoxNewsModal from "./components/FortnoxNewsModal/FortnoxNewsModal";

const FortnoxAdministration = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const status = useAppSelector(getStatus).initFortnoxAdmin;

    useEffect(() => {
        dispatch(initFortnoxAdmin());
    }, [dispatch]);

    if (status === Status.PENDING) return <SpinnerWithOverlay />;
    if (status === Status.ERROR) return <Error text={t("500ErrorText")} />;

    return (
        <>
            <FortnoxNewsModal />
            <Employees />
        </>
    );
};

export default FortnoxAdministration;
