export interface IProps {
    text: string;
}
const SearchTableHeader = ({ text }: IProps) => {
    return (
        <div className="flex pb-5 pt-8 gap-1 items-center px-2">
            <p className="font-bold text-sm lg:text-base">{text}</p>
        </div>
    );
};
export default SearchTableHeader;
