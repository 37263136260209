import Icon, { Icons } from "components/Icon";
import { NotificationType } from "swagger/messenger";

type CaseOperations =
    | "added"
    | "updated"
    | "deleted"
    | "created"
    | "declined"
    | "expired"
    | "ended"
    | "changeresponsible"
    | "reopen"
    | "automaticenddate"
    | "activated"
    | "deactivated";

const CaseEventIconMap: {
    [key in CaseOperations]: Icons;
} = {
    added: "plusCircle",
    updated: "notes",
    deleted: "trashCan",
    created: "checkCircle",
    declined: "xCircle",
    expired: "xCircle",
    ended: "xCircle",
    changeresponsible: "notes",
    reopen: "plusCircle",
    automaticenddate: "xCircle",
    activated: "checkCircle",
    deactivated: "xCircle",
};

const isCaseOperation = (caseOperation: string | NotificationType): caseOperation is CaseOperations =>
    caseOperation === "added" ||
    caseOperation === "updated" ||
    caseOperation === "deleted" ||
    caseOperation === "created" ||
    caseOperation === "declined" ||
    caseOperation === "expired" ||
    caseOperation === "ended" ||
    caseOperation === "changeresponsible" ||
    caseOperation === "reopen" ||
    caseOperation === "automaticenddate" ||
    caseOperation === "activated" ||
    caseOperation === "deactivated";

interface IProps {
    caseOperation: string | NotificationType;
}

export const CaseEventIcon = (props: IProps) => {
    const { caseOperation } = props;

    const iconType = isCaseOperation(caseOperation) ? CaseEventIconMap[caseOperation] : "chatNotification";

    return <Icon icon={iconType} size={24} />;
};
