import Icon from "components/Icon";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "components/PrimaryButton";
import TertiaryButton from "components/TertiaryButton";
import { FieldValues, UseFormReset } from "react-hook-form";

export interface IServiceAccordion {
    heading: string;
    disabled: boolean;
    isLoading: boolean;
    content?: ReactNode;
    children?: ReactNode;
    reset: UseFormReset<FieldValues>;
    onOpen?: () => void;
}

export const ServiceAccordion = ({
    heading,
    disabled,
    isLoading,
    content,
    children,
    reset,
    onOpen,
}: IServiceAccordion) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation("service");

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    return (
        <div
            className={`flex flex-col min-h-20 items-center w-full border-b border-grey-200 ${open ? "bg-pageBg" : ""}`}
            data-cy="service-accordion"
        >
            <div className="w-full flex flex-col px-4 xs:px-8">
                <div className="flex justify-between pt-8 shadow-none-important">
                    <div className="w-full flex flex-col">
                        <div className="w-full flex items-center justify-between">
                            <h3>{heading}</h3>
                            <div className="flex flex-row justify-end">
                                {open && (
                                    <div className="hidden xs:flex">
                                        <TertiaryButton
                                            id="modalActionButton"
                                            text={t("cancel")}
                                            className="mr-6 flex self-center"
                                            onClick={() => handleClose()}
                                        />
                                        <PrimaryButton
                                            id="searchedUser"
                                            data-cy="accordion-save"
                                            text={t("save")}
                                            className="flex self-center"
                                            isLoading={isLoading}
                                            disabled={disabled}
                                            type="submit"
                                        />
                                    </div>
                                )}
                                <div className="flex ml-4">
                                    <button
                                        aria-label="toggle-open"
                                        className="h-10 w-10 flex justify-center items-center"
                                        type="button"
                                        onClick={() => {
                                            setOpen(!open);
                                            onOpen && onOpen();
                                        }}
                                    >
                                        {!open ? <Icon icon="caretDown" /> : <Icon icon="caretUp" />}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {!open && <div className="mt-5 mb-8">{content}</div>}
                    </div>
                </div>
                {open && (
                    <div>
                        <div id="serviceAccordionChildren" className="pb-5">
                            {children}
                        </div>
                        <div className="flex xs:hidden justify-center py-8 border-t">
                            <TertiaryButton
                                id="modalActionButton"
                                text={t("cancel")}
                                className="mr-6 flex self-center"
                                onClick={() => setOpen(false)}
                            />
                            <PrimaryButton
                                type="submit"
                                id="serviceAccordionSubmit"
                                text={t("save")}
                                className="flex self-center"
                                isLoading={isLoading}
                                disabled={disabled}
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

interface IAccordionStringContent {
    heading?: string | number;
    value: string;
}

export const AccordionStringContent = ({ heading, value }: IAccordionStringContent) => {
    const { t } = useTranslation("service");
    return (
        <div className="flex">
            {heading && <p>{heading}:</p>}
            <p className="ml-1 font-bold">{value || t("notSet")}</p>
        </div>
    );
};

export const AccordionBooleanContent = ({ label }: { label: string }) => (
    <div className="flex items-center">
        <div>
            <Icon icon="checkCircle" size={15} />
        </div>
        <div className="ml-2">{label}</div>
    </div>
);
