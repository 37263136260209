import * as React from "react";

interface IProps {
    id: string;
    children: React.ReactNode;
    className?: string;
}
const Tag = ({ id, children, className }: IProps) => (
    <div id={id} data-testid={id} className={`flex items-center px-3 ${className}`}>
        {children}
    </div>
);

export default Tag;
