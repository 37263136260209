import PrimaryButton from "components/PrimaryButton";
import SearchModal from "components/SearchModal";
import { IEmploymentSearchResult } from "interfaces/IEmploymentSearchResult";
import { ReportEmploymentContractPage } from "swagger/employeeattendance";
import EmployeeSearchResult from "../../../components/EmployeeSearchResult";
import EmployeeSearchDropdowns from "../../../components/EmployeeSearchDropdowns";

interface IProps {
    searchHeader: string;
    recentEmploymentSearches: any;
    employmentSearch?: ReportEmploymentContractPage;
    onChange: (query: string) => void;
    searchResult: (searchItem: IEmploymentSearchResult, index: number) => JSX.Element;
    placeholder: string;
    buttonText: string;
    onSearch: () => void;
    isLoading: boolean;
    searchResultTitle: string;
}
const Employee = ({
    searchHeader,
    recentEmploymentSearches,
    employmentSearch,
    onChange,
    searchResult,
    placeholder,
    buttonText,
    onSearch,
    isLoading,
    searchResultTitle,
}: IProps) => (
    <div className="flex flex-col px-4 xl:px-0">
        <div className="pb-6 xs:pt-6 w-full max-w-screen-xxxxl">
            <SearchModal
                id="SearchModalAbsenceReport"
                preset="large"
                searchHistory={recentEmploymentSearches}
                searchResult={employmentSearch}
                isLoading={false}
                onInputChange={onChange}
                placeholder={placeholder}
                searchResultItemComponent={searchResult}
                searchResultTitle={searchResultTitle}
            />
        </div>
        <hr className=" my-12 md:my-0 md:mt-8 md:mb-12" />
        <div className="text-2xl mb-6">{searchHeader}</div>
        <div className="flex gap-3 flex-wrap">
            <EmployeeSearchDropdowns />
            <div className="flex justify-center items-end">
                <PrimaryButton
                    className="w-full max-w-[335px] lg:w-auto h-[50px] "
                    id="followup-search-button"
                    onClick={onSearch}
                    isLoading={isLoading}
                    styleText="justify-center"
                    text={buttonText}
                />
            </div>
        </div>
        <div className="mt-12" />
        <EmployeeSearchResult />
    </div>
);

export default Employee;
