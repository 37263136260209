import { capitalize } from "lodash/fp";
import { getDateDisplayValue, getDateForwardOrBack, getStartOfDate } from "utils/date";
import { IWidgetMap } from "../components/Widget";
import { TFunction } from "i18next";

export const getTimeSpanLabel = (
    currentWidget: IWidgetMap,
    t: TFunction,
    userLanguage: string,
    timeInterval?: string,
) => {
    const { timeSpan, defaultTimeSpan, startOfMonth } = currentWidget;
    const dynamicTimeSpan = timeSpan === "dynamic" ? timeInterval ?? defaultTimeSpan : timeSpan;
    const dateFrom = startOfMonth ? getStartOfDate("months") : undefined;

    switch (dynamicTimeSpan) {
        case "ongoing":
            return t("ongoingRightNow");
        case "sevenDays":
            return `${getDateDisplayValue()} - ${getDateDisplayValue(getDateForwardOrBack(6, "days", "forward"))}`;
        case "currentMonth":
            return `${capitalize(getDateDisplayValue(new Date(), "MMMM yyyy", userLanguage))}`;
        case "threeMonths":
            return `${getDateDisplayValue(getDateForwardOrBack(3, "months", "backwards", dateFrom))} - ${getDateDisplayValue()}`;
        case "sixMonths":
            return `${getDateDisplayValue(getDateForwardOrBack(6, "months", "backwards", dateFrom))} - ${getDateDisplayValue()}`;
        case "twelveMonths":
            return `${getDateDisplayValue(getDateForwardOrBack(12, "months", "backwards", dateFrom))} - ${getDateDisplayValue()}`;
        default:
            return t("notSelected");
    }
};
