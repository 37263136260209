import { UseFormRegisterReturn } from "react-hook-form";

interface ServiceRadioButton {
    label: string;
    value?: string;
    register: UseFormRegisterReturn<string>;
}

const ServiceRadioButton = ({ label, value, register }: ServiceRadioButton) => (
    <label className="flex hover:cursor-pointer leading-5">
        <div>
            <input className="accent-black hover:cursor-pointer h-5 w-5 p-3" type="radio" {...register} value={value} />
        </div>
        <div className="pl-2">{label}</div>
    </label>
);

export default ServiceRadioButton;
