import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const getLoginSlice = (state: RootState) => state.login;

export const isLoading = createSelector(getLoginSlice, (login) => login.isLoading);
export const failedAt = createSelector(getLoginSlice, (login) => login.failedAt);
export const selectedMarket = createSelector(getLoginSlice, (login) => login.selectedMarket);
export const userAccounts = createSelector(getLoginSlice, (login) => login.userAccounts);
export const getIsAuthByLogin = createSelector(getLoginSlice, (login) => login.isAuthenticated);
export const countdownTimer = createSelector(getLoginSlice, (login) => login.countdownTimer);

export const getUsernameAndPassProps = createSelector(getLoginSlice, (login) => login.usernameAndPassProps);
export const usernameInput = createSelector(getUsernameAndPassProps, (props) => props.usernameInput);
export const passwordInput = createSelector(getUsernameAndPassProps, (props) => props.passwordInput);

export const getResetPassProps = createSelector(getLoginSlice, (login) => login.resetPassProps);

export const userIdentifier = createSelector(getResetPassProps, (props) => props.userIdentifier);
export const pwdMissing = createSelector(getResetPassProps, (props) => props.pwdMissing);
export const newPass = createSelector(getResetPassProps, (props) => props.newPass);
export const confirmNewPass = createSelector(getResetPassProps, (props) => props.confirmNewPass);
