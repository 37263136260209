import { ITableRowProps } from "../SearchTable";
const ArchiveRow = ({ children, rowProps, key }: ITableRowProps) => (
    <tr
        className={`h-full text-left border-b-1 border-b-solid border-b-grey-300 bg-background-400/5  cursor-pointer`}
        {...rowProps}
        key={key}
    >
        {children}
    </tr>
);
export default ArchiveRow;
