import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PhoneIcon } from "icons/phone.svg";
import * as utils from "./AbsenceReportCallNurse.utils";
import { useAppSelector } from "store/hooks";
import { selectors as AbsenceReportsSelectors } from "../../redux";
const AbsenceReportCallNurse = () => {
    const { t } = useTranslation();
    const configurationFields = useAppSelector(AbsenceReportsSelectors.getConfigurationFields);
    const getNursePhoneNumber = React.useCallback(
        (getCountryPrefix?: boolean) => utils.getNursePhoneNumber(configurationFields, getCountryPrefix),
        [configurationFields],
    );
    return (
        <div className="flex flex-col items-center p-10 mb-10 bg-mhgrey-light bg-opacity-25">
            <h2 className="mb-4">{t("reportYourAbsence")}</h2>
            <p className="mb-6 text-center">{t("absenceByCallNurse")}</p>
            <a className="xs:flex items-center " href={`tel:${getNursePhoneNumber(true)}`}>
                <PhoneIcon className="hidden xs:flex text-black h-6 w-6" />
                <p className="hidden xs:flex ml-2 text-3xl font-bold border-b border-transparent hover:border-mhdarkgreen-original">
                    {getNursePhoneNumber()}
                </p>
                <div className="xs:hidden ml-2 border-b border-transparent hover:border-mhdarkgreen-original h-10 items-center px-8 bg-primaryButtonPrimary hover:bg-primaryButtonSecondary">
                    <p className="text-primaryButtonText font-bold flex pt-2">{t("callNurse")}</p>
                </div>
            </a>
        </div>
    );
};
export default AbsenceReportCallNurse;
