import { Card } from "@medhelp/ui";

interface IProps {
    body: string;
}
const NoData = ({ body }: IProps) => (
    <div className="mt-9">
        <Card>
            <div className="flex flex-col max-w-[480px] gap-5 justify-center items-center">
                <div className="text-center">{body}</div>
            </div>
        </Card>
    </div>
);

export default NoData;
