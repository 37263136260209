import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { IUserContextExtended } from "interfaces/IUserContextExtended";
import {
    changeUserContextByLogin,
    setUserContextExtended,
    getWelcomeMessage,
    updateContactInformation,
    updateUserLangPreferences,
    changeUserContext,
    updateUserLang,
} from "./userActions";
import { MedHelpPeopleDomainContent } from "swagger/people";
import { Config } from "config";
import { ContactInformation, UserContext, UserPreferences } from "swagger/usercontextservice";
import { getCookieOptions } from "pages/login/utils";
import { Status } from "@medhelp/ui/LoadableComponent";

const mhToken = Cookies.get("mhToken")
    ? Cookies.get("mhToken")
    : sessionStorage.getItem("mhToken")
      ? sessionStorage.getItem("mhToken")!
      : null;

const mhAssume = Cookies.get("mhAssume") ? Cookies.get("mhAssume") : undefined;

export interface IUserState {
    isAuthenticated: boolean;
    token: string | null | undefined;
    welcomeMessage: MedHelpPeopleDomainContent | null;
    userContextExtended: IUserContextExtended;
    userContext: UserContext | null;
    status: Status;
    assume: boolean;
}

export const initialUserState: IUserState = {
    isAuthenticated: mhToken ? true : false,
    token: mhToken,
    assume: mhAssume === "true",
    welcomeMessage: null,
    userContextExtended: {
        user: {},
        userAccount: {},
        companyEmployment: {},
        retailers: [],
        activeRetailer: sessionStorage.getItem("activeRetailer") || "",
        brand: "",
        newReporting: false,
        HasAbsenceReporting: false,
        HasAbsenceFollowup: false,
        HasAbsenceAdministration: false,
        HasCustomerCompanyAdministration: false,
        HasCustomerSupport: false,
        HasAbsenceArchive: false,
        HasDepartmentAdministration: false,
        HasRehabDepartmentManagement: false,
        HasAbsenceView: false,
        HasAbsenceStatistics: false,
        HasAbsenceBoard: false,
        HasMedicalAdvisory: false,
        HasCustomerOrganizationAdministration: false,
        HasTopLevelAbsenceStatistics: false,
        EmploymentGuid: "",
        Product: "",
        Subcategory: "",
    },
    userContext: null,
    status: Status.OK,
};
export const clearCookiesAndStorage = () => {
    localStorage.removeItem("mhSession");
    localStorage.removeItem("identifier");
    sessionStorage.removeItem("newToken");
    sessionStorage.removeItem("mhToken");
    sessionStorage.removeItem("brand");
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("customerSupportEmployment");
    sessionStorage.removeItem("customerSupportPreSelectCompany");
    sessionStorage.removeItem("customerSupportPreSelectEmployment");
    sessionStorage.removeItem("retailer");
    sessionStorage.removeItem("retailerId");
    sessionStorage.removeItem("mhSession");
    const domainUrl = Config.env === "local" ? "localhost" : ".medhelp.se";
    Cookies.remove("mhToken", {
        domain: domainUrl,
        path: "/",
    });
    Cookies.remove("newToken", {
        domain: domainUrl,
        path: "/",
    });
    Cookies.remove("customerSupportEmployment", {
        domain: domainUrl,
        path: "/",
    });
    Cookies.remove("customerSupportPreSelectRetailer", {
        domain: domainUrl,
        path: "/",
    });
    Cookies.remove("customerSupportPreSelectCompany", {
        domain: domainUrl,
        path: "/",
    });
    Cookies.remove("customerSupportPreSelectEmployment", {
        domain: domainUrl,
        path: "/",
    });
    Cookies.remove("mhSession", {
        domain: domainUrl,
        path: "/",
    });
    Cookies.remove("mhAssume", {
        domain: domainUrl,
        path: "/",
    });
    Cookies.remove("migrated", {
        domain: domainUrl,
        path: "/",
    });
};

export interface IUserContextChange {
    token: string;
    userContext: UserContext;
}

export interface IUserContextChangeExtended {
    token: string;
    userContext: IUserContextExtended;
}

const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        signIn: (state) => {
            state.isAuthenticated = sessionStorage.getItem("mhToken") ? true : false;
            state.token = sessionStorage.getItem("mhToken") ? sessionStorage.getItem("mhToken")! : "";
        },
        signOut: (state) => {
            clearCookiesAndStorage();
            state.isAuthenticated = false;
            state.token = null;
            window.location.href = `${Config.web_login_url ?? ""}`;
        },
        setUserRetailer(state, action: PayloadAction<string>) {
            sessionStorage.setItem("activeRetailer", action.payload);
            state.userContextExtended.activeRetailer = action.payload;
        },
        loginFromApp(state, action) {
            const { token } = action.payload;
            Cookies.set("mhToken", token, getCookieOptions());
            Cookies.set("migrated", "true", getCookieOptions());
            sessionStorage.setItem("mhToken", token);
            state.isAuthenticated = true;
        },
        updateContactInformation(state, action: PayloadAction<string | undefined | null>) {
            if (state.userContextExtended.user?.contactInformation && action.payload) {
                state.userContextExtended.user.contactInformation.mobileCountryPrefix = action.payload.split("-")[0];
                state.userContextExtended.user.contactInformation.mobileNumber = action.payload.split("-")[1];
                state.userContextExtended.user.contactInformation.mobile = action.payload.replace("-", "");
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setUserContextExtended.fulfilled, (state, action: PayloadAction<IUserContextExtended>) => {
                state.userContextExtended = action.payload;
                state.status = Status.OK;
            })
            .addCase(setUserContextExtended.rejected, (state) => {
                state.isAuthenticated = false;
                state.token = null;
                clearCookiesAndStorage();
                window.location.href = `${Config.web_login_url}`;
            })
            .addCase(getWelcomeMessage.fulfilled, (state, action: PayloadAction<MedHelpPeopleDomainContent>) => {
                state.welcomeMessage = action.payload;
            })
            .addCase(changeUserContextByLogin.fulfilled, (state, action) => {
                const { token, userContext } = action.payload;
                state.token = token;
                state.userContext = userContext;
                Cookies.set("mhToken", token, getCookieOptions());
                Cookies.set("migrated", "true", getCookieOptions());
                sessionStorage.setItem("mhToken", token);
                state.isAuthenticated = true;
            })
            .addCase(changeUserContextByLogin.rejected, (state) => {
                state.token = "";
                state.isAuthenticated = false;
                clearCookiesAndStorage();
            })
            .addCase(changeUserContext.fulfilled, (state, action) => {
                if (action.meta.arg.assume) {
                    Cookies.set("mhAssume", "true", getCookieOptions());
                    state.assume = true;
                }
                Cookies.set("migrated", "true", getCookieOptions());
                Cookies.set("mhToken", action.payload.token, getCookieOptions());
                sessionStorage.setItem("mhToken", action.payload.token);
                state.token = action.payload.token;
                // IMPORTANT -> OK status set in setUserContextExtended
            })
            .addCase(changeUserContext.pending, (state) => {
                state.status = Status.PENDING;
            })
            .addCase(updateUserLangPreferences.fulfilled, (state, action: PayloadAction<UserPreferences>) => {
                const { language } = action.payload;
                if (state.userContextExtended.user?.userPreferences?.language) {
                    state.userContextExtended.user.userPreferences.language = language;
                    Cookies.remove("NEXT_LOCALE");
                    Cookies.set("NEXT_LOCALE", language!, getCookieOptions());
                }
            })
            .addCase(updateContactInformation.fulfilled, (state, action: PayloadAction<ContactInformation>) => {
                if (state.userContextExtended.user?.contactInformation) {
                    const contactInfo: ContactInformation = action.payload;

                    state.userContextExtended.user.contactInformation = contactInfo;
                }
            })
            .addCase(updateUserLang.fulfilled, (state, action) => {
                if (state.userContextExtended.user?.userPreferences?.language)
                    state.userContextExtended.user.userPreferences.language = action.payload.language;
            });
    },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
