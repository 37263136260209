const SpinnerPage = () => (
    <div className="flex flex-col h-80vh pt-4">
        <div className="flex m-auto">
            <div className="lds-ring -mt-20">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
);

export default SpinnerPage;
