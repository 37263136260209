import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { isArray, isEmpty } from "lodash/fp";
import { isSameOrAfterToday } from "utils/date";
import { postFile } from "../medicalCertificateAsyncActions";
import { IMedicalCertificateSlice, FileStatus, IFileState } from "../medicalCertificateSlice";

const postBuilder = (builder: ActionReducerMapBuilder<IMedicalCertificateSlice>) =>
    builder
        .addCase(postFile.fulfilled, (state, action) => {
            const { endDate } = action.meta.arg;
            const { values, groupKey } = action.payload;
            state.status = FileStatus.ok;
            const uploadModalActive = state.modals.uploadCertificate;
            if (uploadModalActive) {
                state.modals.uploadCertificate = false;
            }
            if (action.payload) {
                state.file.pages = [...state.file.pages, ...values];
            }
            if (isSameOrAfterToday(endDate)) {
                state.addedFile = {
                    success: "current",
                    groupKey: groupKey || null,
                };
                return;
            }
            state.addedFile = {
                success: "history",
                groupKey: groupKey || null,
            };
        })
        .addCase(postFile.pending, (state) => {
            state.status = FileStatus.pending;
        })
        .addCase(postFile.rejected, (state, action) => {
            state.status = FileStatus.error;
            const { values } = action.payload as {
                values: IFileState[];
                groupKey: string;
            };
            if (isEmpty(values) && !isArray(values)) {
                state.status = FileStatus.error;
            } else {
                state.status = FileStatus.ok;
                if (state.modals.uploadCertificate) {
                    state.modals.uploadCertificate = false;
                }
                state.modals.someUploadedFilesFailed = true;
                state.file.pages = [...state.file.pages, ...values];
            }
        });

export default postBuilder;
