import { createAsyncThunk } from "@reduxjs/toolkit";
import * as UserSelectors from "store/userSelectors";
import { Guid } from "utils/guid";
import { S3FileInfoPagedResponse, MetaData } from "swagger/storage";
import { getServices } from "api/services";
import { RootState } from "store";
import { IFileState } from "./medicalCertificateSlice";
import { IFile } from "../hooks";
import * as utils from "../utils";

interface IPostFileAsyncAction {
    startDate: string;
    endDate: string;
    degree: string;
    files: IFile[];
    employmentGuid?: string;
}
export const postFile = createAsyncThunk(
    "postFile",
    async ({ startDate, endDate, degree, files, employmentGuid }: IPostFileAsyncAction, thunkApi) => {
        const userContext = UserSelectors.getUserContext(thunkApi.getState() as RootState);
        const token = UserSelectors.token(thunkApi.getState() as RootState) ?? "";

        const groupKey = Guid.newGuid().toString();

        const degrees = ["100%", "75%", "50%", "25%"];
        const currentDegree = degrees.includes(degree) ? degree : "0";
        const metaData: MetaData[] = [
            {
                name: "from",
                value: startDate,
            },
            {
                name: "to",
                value: endDate,
            },
            {
                name: "degree",
                value: currentDegree,
            },
        ];

        const Forms = utils.files.getFileFormData({
            files,
            type: "doctorCert",
            owner: employmentGuid || userContext.EmploymentGuid,
            metaData,
            groupKey,
        });
        let values: IFileState[] = [];
        for await (const form of Forms) {
            const response = await getServices().clients.storage.filesPost(
                form.owner,
                form.type,
                1,
                form.groupKey,
                form.file,
                form.metaData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    },
                    timeout: 120000,
                },
            );

            if (response.data && response.status === 200) {
                values.push(
                    utils.files.createLocalFile({
                        file: form.fileData,
                        groupKey: form.groupKey,
                        type: form.type,
                        metaData,
                        owner: form.owner,
                    }),
                );
            } else return thunkApi.rejectWithValue({ values, groupKey });
        }
        return { values, groupKey };
    },
);

interface IGetFilesAction {
    employmentGuid?: string;
}
export const getFiles = createAsyncThunk<S3FileInfoPagedResponse, IGetFilesAction>(
    "getFiles",
    async ({ employmentGuid }, thunkApi) => {
        const userContext = UserSelectors.getUserContext(thunkApi.getState() as RootState);
        const res = await getServices().clients.storage.filesGet(
            employmentGuid || userContext.EmploymentGuid,
            "doctorCert",
        );
        // Weird it says it return an array but it doesnt
        const data = res.data as S3FileInfoPagedResponse;
        return data;
    },
);

interface IGetFile {
    id: string | undefined;
}
export const getSpecificFile = createAsyncThunk("getSpecificFile", async ({ id }: IGetFile) => {
    const res = await getServices().clients.storage.filesIdDownloadGet(id || "", { responseType: "blob" });
    return res.data;
});

interface IRemoveCertificate {
    owner: string;
    groupKey: string;
}
export const removeCertificate = createAsyncThunk(
    "removeCertificate",
    async ({ owner, groupKey }: IRemoveCertificate) => {
        try {
            await getServices().clients.storage.filesDelete(owner, groupKey);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },
);

interface IEditMedicalCertificateMetadata {
    owner: string;
    groupKey: string;
    metaData: { name: string; value: string }[];
}
export const editMedicalCertificateMetadata = createAsyncThunk(
    "removeCertificate",
    async ({ metaData, owner, groupKey }: IEditMedicalCertificateMetadata) => {
        try {
            const res = await getServices().clients.storage.filesPut({
                owner,
                groupKey,
                metaData: JSON.stringify(metaData),
            });

            console.log({ res });
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    },
);
