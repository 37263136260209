import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import ServiceSelect from "../ServiceSelect";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { Region } from "swagger/businessupport";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ServiceInput, ServiceInputContainer } from "../ServiceInput";
import {
    getCompanyInformation,
    getCustomerData,
    getRetailerContactAvailable,
    getServiceSlice,
} from "pages/service/redux/serviceSelectors";
import { ServiceAccordion, AccordionStringContent } from "../ServiceAccordion";
import {
    saveCompanyInformation,
    saveMessengerConfigurationFieldPost,
    saveMessengerConfigurationFieldPut,
} from "pages/service/redux/serviceAsyncActions";
import { organizationNumberConfigByCountry } from "pages/service/utils/serviceHelperFunctions";

export interface ICompanyInformation {
    companyName: string;
    organizationNumber: string;
    region: Region;
}

export interface IRetailerContactAvailable {
    retailerContactAvailable: {
        id: string | undefined;
        key: string;
        checked: string;
    };
}

const CompanyInformation = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const companyInformation = useAppSelector(getCompanyInformation);
    const { companyName, organizationNumber, region } = companyInformation;
    const retailerContactAvailable = useAppSelector(getRetailerContactAvailable);
    const { companyId, customerId } = useAppSelector(getServiceSlice);
    const { retailerName } = useAppSelector(getCustomerData);

    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = useMemo(
        () => ({
            companyName,
            organizationNumber,
            region,
            retailerContactAvailable,
        }),
        [companyName, organizationNumber, region, retailerContactAvailable],
    );

    const {
        register,
        handleSubmit,
        reset,
        resetField,
        formState: { isDirty, isValid, dirtyFields },
    } = useForm({ defaultValues });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const onSubmit = (data: ICompanyInformation & IRetailerContactAvailable) => {
        setIsLoading(true);
        const promises = [];
        if (dirtyFields.retailerContactAvailable) {
            promises.push(
                dispatch(
                    data.retailerContactAvailable.id
                        ? saveMessengerConfigurationFieldPut({
                              id: data.retailerContactAvailable.id,
                              companyId: companyId,
                              customerId: customerId,
                              name: data.retailerContactAvailable.key,
                              value: data.retailerContactAvailable.checked,
                          })
                        : saveMessengerConfigurationFieldPost({
                              companyId: companyId,
                              customerId: customerId,
                              name: data.retailerContactAvailable.key,
                              value: data.retailerContactAvailable.checked,
                          }),
                )
                    .then(unwrapResult)
                    .then(() => {
                        toast(t("saved"));
                    })
                    .catch((e) => {
                        toast(t("error") + ": " + e.message, { type: "error" });
                        resetField("retailerContactAvailable");
                    }),
            );
        }
        if (dirtyFields.companyName || dirtyFields.organizationNumber || dirtyFields.region) {
            promises.push(
                dispatch(saveCompanyInformation(data))
                    .then(unwrapResult)
                    .then(() => {
                        toast(t("saved"));
                    })
                    .catch((e) => {
                        toast(t("error") + ": " + e.message, { type: "error" });
                        reset(companyInformation);
                    }),
            );
        }
        Promise.allSettled(promises).finally(() => setIsLoading(false));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("companyInformation")}
                reset={() => reset(defaultValues)}
                disabled={!isDirty || !isValid}
                isLoading={isLoading}
                content={
                    <div className="flex flex-col gap-10 md:flex-row md:gap-20 flex-wrap">
                        <div>
                            <AccordionStringContent heading={t("company")} value={companyName || ""} />
                            <AccordionStringContent heading={t("orgNr")} value={organizationNumber || ""} />
                        </div>
                        <div>
                            <AccordionStringContent heading={t("country")} value={t(region?.country || "")} />
                            <AccordionStringContent heading={t("language")} value={t(region?.language || "")} />
                            <AccordionStringContent heading={t("currency")} value={region?.currency || ""} />
                        </div>
                        <div>
                            <AccordionStringContent
                                heading={t("showContactForm")}
                                value={retailerContactAvailable.checked === "True" ? t("Yes") : t("No")}
                            />
                        </div>
                    </div>
                }
            >
                <ServiceInputContainer heading={t("companyInfo")} borderBottom>
                    <ServiceInput
                        label={t("companyName")}
                        register={register("companyName", { required: true })}
                        required
                    />
                    <ServiceInput
                        required
                        label={t("organizationNumber")}
                        placeholder={
                            (region.country && organizationNumberConfigByCountry[region.country]?.placeholder) || ""
                        }
                        register={register("organizationNumber", {
                            required: true,
                            ...(region.country ? organizationNumberConfigByCountry[region.country]?.rules : {}),
                        })}
                    />
                </ServiceInputContainer>
                <ServiceInputContainer heading={t("countryAndLanguageForCompany")}>
                    <ServiceSelect
                        label={t("country")}
                        register={register("region.country")}
                        options={[
                            {
                                value: companyInformation.region.country || "",
                                label: t(companyInformation.region.country || ""),
                            },
                        ]}
                        // options={uniqBy("country", regions).map(({ country }) => ({
                        //     value: country || "",
                        //     label: t(country || ""),
                        // }))}
                        disabled
                    />
                    <ServiceSelect
                        label={t("language")}
                        register={register("region.language")}
                        options={[
                            {
                                value: companyInformation.region.language || "",
                                label: t(companyInformation.region.language || ""),
                            },
                        ]}
                        // options={uniqBy("language", regions).map(({ language }) => ({
                        //     value: language || "",
                        //     label: t(language || ""),
                        // }))}
                        disabled
                    />
                    <ServiceSelect
                        label={t("currency")}
                        register={register("region.currency")}
                        options={[
                            {
                                value: companyInformation.region.currency || "",
                                label: t(companyInformation.region.currency || ""),
                            },
                        ]}
                        // options={uniqBy("currency", regions).map(({ currency }) => ({ value: currency || "" }))}
                        disabled
                    />
                </ServiceInputContainer>
                <ServiceInputContainer heading={t("partnerContact")}>
                    <ServiceSelect
                        testId="retailer-contact-select"
                        label={t("showContactForm") + " " + `(${retailerName})`}
                        register={register("retailerContactAvailable.checked")}
                        options={[
                            { value: "True", label: t("Yes") },
                            { value: "False", label: t("No") },
                        ]}
                    />
                </ServiceInputContainer>
            </ServiceAccordion>
        </form>
    );
};

export default CompanyInformation;
