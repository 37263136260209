import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { uploadFileDocument as uploadFileDocumentAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const uploadFileDocument = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(uploadFileDocumentAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                currentHealthCase.healthCaseDocuments.push({
                    ...action.payload.data,
                    status: Status.OK,
                });
                currentHealthCase.healthCaseSubStatus.documents = Status.OK;
            }
        })
        .addCase(uploadFileDocumentAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.documents = Status.PENDING;
        })
        .addCase(uploadFileDocumentAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.documents = Status.ERROR;
        });

export default uploadFileDocument;
