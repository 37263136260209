import _, { isEmpty } from "lodash";
import {
    MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport,
    MedHelpAbsenceReportingDomainTemplate,
    MedHelpAbsenceReportingDomainFieldTemplate,
    MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel,
} from "swagger/absencereportapi";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import { t } from "i18next";
import { Config } from "config";
import { getNumberWithoutDash } from "utils/phone";
import { isBeforeDate, isSameOrBeforeDateDay } from "utils/date";

export const getAbsenceReportValueByFieldName = (
    fieldName: string,
    fields: IFieldTemplateExtended[] | null | undefined,
): string => {
    if (!fields) return "";
    const field = fields.find((field) => field.name === fieldName);
    return field?.value ?? "";
};

export const getAbsenceReportFieldByName = (
    fields: IFieldTemplateExtended[],
    fieldName: string,
): IFieldTemplateExtended | undefined => {
    const field = fields.find((field: IFieldTemplateExtended) => field.name === fieldName);
    return field;
};

export const getAbsenceReportTemplateByName = (
    templates: MedHelpAbsenceReportingDomainTemplate[] | null,
    name: string | null | undefined,
) => {
    if (!name || !templates || isEmpty(templates)) return undefined;
    const template = templates.find(
        (template: MedHelpAbsenceReportingDomainTemplate) =>
            template.absenceReportType?.toLowerCase() === name.toLowerCase(),
    );
    return template;
};

export const getAbsenceReportFieldValueByName = (
    fieldName: string,
    absenceReportsFields: IFieldTemplateExtended[],
): string => {
    if (!absenceReportsFields) return "";
    const field = absenceReportsFields.find((field: IFieldTemplateExtended) => field.name === fieldName);
    return field && field?.value ? field?.value : "";
};

/**
 *
 * @param field
 * @returns { string } - Returns the display value of an absence report field based on the field type, name or datatype
 */
export const getAbsenceReportFieldDisplayValue = (templateField?: IFieldTemplateExtended): string => {
    if (!templateField || !templateField.value) return t("noValue");
    if (templateField.value === "Confidential" || templateField.confidential) return t("Confidential");
    if (templateField.name === "AbsenceCause" && templateField.value) {
        return (
            templateField.options?.find((option) => option.value === templateField.value)?.description || t("unknown")
        );
    }
    if (templateField.name === "Mobile") return getNumberWithoutDash(templateField?.value);
    if (templateField.dataType === "bool") return templateField.value === "true" ? t("yes") : t("no");
    return templateField.value;
};

export const mapAbsenceReportFieldsWithDefaultValue = (
    fields: IFieldTemplateExtended[],
    value: string,
): IFieldTemplateExtended[] => {
    const absenceReportfields = fields.map((templateField: MedHelpAbsenceReportingDomainFieldTemplate) => {
        return {
            ...templateField,
            value,
        };
    });
    return absenceReportfields;
};

export const mapAbsenceReportFieldWithValue = (
    fields: IFieldTemplateExtended[],
    fieldName: string,
    value: string,
): IFieldTemplateExtended[] => {
    const absenceReportfields = fields.map((field: IFieldTemplateExtended) => {
        if (field.name === fieldName) {
            return {
                ...field,
                value,
            };
        }
        return field;
    });
    return absenceReportfields;
};

export const createUpdatedAbsenceReportDateModel = (
    fieldName: string,
    value: string | undefined,
    endDate?: string | undefined,
): MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport => {
    let updateDateModel: MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport = {};
    if (endDate) {
        switch (fieldName) {
            case "startDate": {
                updateDateModel = {
                    start: value,
                    backAtWork: endDate,
                };
                return updateDateModel;
            }
            case "backAtWork": {
                updateDateModel = {
                    start: value,
                    backAtWork: endDate,
                };
                return updateDateModel;
            }
        }
    }
    return updateDateModel;
};

export const createUpdatedAbsenceReportFieldModel = (
    absenceReportId: string,
    field: IFieldTemplateExtended,
    value: string,
): IFieldTemplateExtended => {
    const absenceReportField = {
        absenceReportId: absenceReportId,
        name: _.upperFirst(field?.name!),
        templateId: field?.templateId,
        confidential: field?.confidential,
        value,
    };
    return absenceReportField;
};

export const isAbsenceReportStatusFinishedBasedOnEndDate = (endDate: string): boolean => isBeforeDate(endDate);

export const absenceReportIsValid = (absenceReport: IAbsenceReportExtended | null): boolean => {
    const degreeIsValid = (absenceReport?.degree && absenceReport?.degree > 0 && absenceReport?.degree <= 100) || false;
    const startDateIsValid =
        absenceReport?.end || absenceReport?.backAtWork
            ? isSameOrBeforeDateDay(
                  absenceReport?.start,
                  absenceReport?.end ? absenceReport.end : absenceReport?.backAtWork,
              )
            : false;
    return degreeIsValid && startDateIsValid;
};

export const absenceReportMandatoryfieldsIsValid = (
    selectedAbsenceReport: IAbsenceReportExtended | null | undefined,
    templateFields: MedHelpAbsenceReportingDomainFieldTemplate[] | null | undefined,
): boolean => {
    if (
        !selectedAbsenceReport ||
        !selectedAbsenceReport.start ||
        !selectedAbsenceReport.backAtWork ||
        !selectedAbsenceReport.degrees
    ) {
        return false;
    }

    const mandatoryFields = templateFields?.filter((field: IFieldTemplateExtended) => field.mandatory);
    if (!mandatoryFields || mandatoryFields.length === 0) return true;

    const mandatoryFieldsHaveValue = mandatoryFields.every((mandatoryField: IFieldTemplateExtended) =>
        selectedAbsenceReport?.fields?.find(
            (field: IFieldTemplateExtended) =>
                field.name?.toLowerCase() === mandatoryField.name?.toLowerCase() && field.value,
        ),
    );
    return mandatoryFieldsHaveValue;
};

export const createAbsenceReportSubmitModel = (
    selectedAbsenceReport: IAbsenceReportExtended,
    templateId: string,
): MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel => {
    const absenceReportSubmitModal = {
        id: selectedAbsenceReport.id,
        employmentId: selectedAbsenceReport.employmentId,
        start: selectedAbsenceReport.start,
        backAtWork: selectedAbsenceReport.backAtWork,
        templateId: templateId,
        fields: selectedAbsenceReport.fields,
        degrees: selectedAbsenceReport.degrees,
        suppressNotification: selectedAbsenceReport.suppressNotification,
    };
    return absenceReportSubmitModal;
};

export const redirectUserToOldAbsenceReportWeb = (hasMedicalAdvisory: boolean, employmentId: number) => {
    let redirectUrl = "";
    if (hasMedicalAdvisory) {
        redirectUrl = `${Config.web_medicalAdvisory_url}?employmentid=${employmentId}&princessDomain=${Config.princessDomain}`;
    } else {
        redirectUrl = `${Config.web_oldAbsenceReporting_url}?employmentid=${employmentId}`;
    }
    window.location.href = redirectUrl;
};
