import { DragEvent, useEffect, useState } from "react";

interface DragAndDropCallbacks {
    onDragStart?: (index: number) => void;
    onDrop?: (index: number, items: any) => void;
}

/**
 * Reusable hook for drag and drop functionality
 * @param initialItems works with any array
 * @param callbacks - optional callbacks for onDragStart and onDrop
 * @returns items - the items in the new order
 * @returns hoveredIndex & draggingIndex - for styling
 */

const useDragAndDrop = <T>(initialItems: T[], callbacks?: DragAndDropCallbacks) => {
    const [items, setItems] = useState(initialItems);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

    useEffect(() => {
        setItems(initialItems);
    }, [initialItems]);

    const handleDragStart = (index: number) => {
        setDraggingIndex(index);
        callbacks?.onDragStart && callbacks.onDragStart(index);
    };

    const handleDrop = (index: number) => {
        if (draggingIndex !== null && draggingIndex !== index) {
            const newItems = [...items];
            const draggedItem = newItems[draggingIndex];
            newItems.splice(draggingIndex, 1);
            newItems.splice(index, 0, draggedItem);
            setItems(newItems);
            callbacks?.onDrop && callbacks.onDrop(index, newItems);
        }
        setHoveredIndex(null);
        setDraggingIndex(null);
    };

    const handleDragOver = (e: DragEvent<HTMLDivElement>, index: number) => {
        e.preventDefault();
        if (index !== draggingIndex) setHoveredIndex(index);
    };

    const handleDragLeave = () => {
        setHoveredIndex(null);
    };

    return {
        items,
        handleDragStart,
        handleDrop,
        handleDragOver,
        handleDragLeave,
        hoveredIndex,
        draggingIndex,
    };
};

export default useDragAndDrop;
