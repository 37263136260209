import { useTranslation } from "react-i18next";
import * as NumberUtils from "utils/numbers";
import { selectors as AbsenceReportsSelectors } from "../../redux";
import * as utils from "./AbsenceReportHeader.utils";
import { Link } from "react-router-dom";
import * as UserSelectors from "store/userSelectors";
import { useAppSelector } from "store/hooks";
import Icon from "components/Icon/Icon";
import { useState } from "react";

const AbsenceReportHeader = () => {
    const { t } = useTranslation();
    const { SelectedEmployee } = useAppSelector(AbsenceReportsSelectors.getAbsenceReportsSlice);
    const hasFollowUpRights = useAppSelector(UserSelectors.getUserFollowUpRights);

    const [showContactInfo, setShowContactInfo] = useState(false);

    if (!SelectedEmployee) return null;

    const {
        firstName,
        lastName,
        name,
        socialSecurityNumber,
        companyName,
        mainManagerFirstName,
        mainManagerLastName,
        mainManagerPhoneNumber,
        referenceKey,
    } = SelectedEmployee;

    const employeeName = name ? name : `${firstName} ${lastName}`;

    const userManagerName = `${mainManagerFirstName} ${mainManagerLastName}`;

    const ssnWithSwishDash = NumberUtils.getSsnWithDash(socialSecurityNumber || "");

    const phoneNumberWithPrefix = utils.getPhoneNumberWithPrefix(SelectedEmployee);

    return (
        <div className="bg-primaryBg xs:bg-pageBg xl:p-0 p-6 mb-6" data-testid="absence-report-header-container">
            <div className="flex justify-between items-center">
                <h2 className="mb-1 xs:mb-6" data-dd-privacy="mask">
                    {employeeName}
                </h2>
                <div className="xs:hidden" onClick={() => setShowContactInfo(!showContactInfo)}>
                    <Icon icon={showContactInfo ? "caretUp" : "caretDown"} />
                </div>
            </div>
            <div className="flex flex-col xxs:justify-center xs:justify-between lg:flex-row">
                <div className="text-grey-700 xs:p-0 md:flex w-full justify-between" data-dd-privacy="mask">
                    <div className={`xs:flex xs:flex-row ${showContactInfo ? "flex flex-col" : "hidden"}`}>
                        {socialSecurityNumber && (
                            <div className="mr-12 mb-5">
                                <p className="font-bold text-xs">{t("ssn")}:</p>
                                <p>{ssnWithSwishDash}</p>
                            </div>
                        )}
                        {companyName && (
                            <div className="mr-12 mb-5">
                                <p className="font-bold text-xs">{t("company")}:</p>
                                <p>{companyName}</p>
                            </div>
                        )}
                        {mainManagerFirstName && mainManagerLastName && userManagerName.trim() && (
                            <div className="mb-5">
                                <p className="font-bold text-xs">{t("closestManager")}:</p>
                                <div className="flex">
                                    <p className="hidden mr-2 lg:flex">{userManagerName}</p>
                                    {mainManagerPhoneNumber && mainManagerPhoneNumber != "" && (
                                        <a href={`tel:${phoneNumberWithPrefix}`}>
                                            <div className="flex items-center">
                                                <Icon icon="phone" />
                                                <p className="flex ml-2 border-b border-grey-700 lg:hidden">
                                                    {userManagerName}:
                                                </p>
                                                <p className="hidden ml-2 border-b border-grey-700 lg:flex">
                                                    {phoneNumberWithPrefix}
                                                </p>
                                            </div>
                                        </a>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {hasFollowUpRights && (
                        <div className="inline-block">
                            <Link
                                to={`/followup/employee/${referenceKey}/absence`}
                                data-testid="followup-employeecard-link"
                                className="flex items-center focus:shadow-none"
                            >
                                <div className="font-bold mr-2 underline">{t("employeeCard")}</div>
                                <Icon icon="caretRight" size={12} />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AbsenceReportHeader;
