import {
    getIsOk,
    getCustomerData,
    getAdvisoryService,
    getFileImportConfig,
    getServiceSlice,
    getOrganizationInfo,
} from "../../redux/serviceSelectors";
import { useEffect, useState } from "react";
import { actions } from "pages/service/redux";
import { useTranslation } from "react-i18next";
import { SpinnerPage } from "components/Spinner";
import { useAppDispatch, useAppSelector } from "store/hooks";
import BasicInfo from "../../components/BasicInfo";
import { getActivePowerbarSearch } from "store/searchSelectors";
import { fetchCompanyData } from "../../redux/serviceAsyncActions";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const TABS = ["general", "services", "accessRights", "notifications"];

const CompanyConfiguration = () => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation("service");
    const isOk = useAppSelector(getIsOk);
    const { product } = useAppSelector(getCustomerData);
    const advisoryService = useAppSelector(getAdvisoryService);
    const { companyName } = useAppSelector(getOrganizationInfo);
    const fileImportConfig = useAppSelector(getFileImportConfig);
    const { companyId, customerId } = useAppSelector(getServiceSlice);
    const activePowerbarSearch = useAppSelector(getActivePowerbarSearch);
    const { customerName, retailerName } = useAppSelector(getCustomerData);
    const [isLoading, setIsLoading] = useState(false);

    const route = pathname.split("/").pop();

    useEffect(() => {
        const companyIdFromParams = Number(params.companyId);
        const customerIdFromParams = Number(params.customerId);
        if (companyIdFromParams && customerIdFromParams) {
            setIsLoading(true);
            dispatch(actions.resetSelectedCompany());
            dispatch(
                fetchCompanyData({
                    companyId: companyIdFromParams,
                    customerId: customerIdFromParams,
                }),
            ).then(() => setIsLoading(false));
        }
    }, [dispatch, params.companyId, params.customerId]);

    useEffect(() => {
        if (
            !params.companyId &&
            activePowerbarSearch &&
            activePowerbarSearch.companyId &&
            activePowerbarSearch.customerId &&
            companyId !== activePowerbarSearch.companyId
        ) {
            setIsLoading(true);
            dispatch(actions.resetSelectedCompany());
            dispatch(
                fetchCompanyData({
                    companyId: activePowerbarSearch.companyId,
                    customerId: activePowerbarSearch.customerId,
                }),
            ).then(() => setIsLoading(false));
        }
    }, [activePowerbarSearch, companyId, dispatch, params]);

    const basicInfo = [
        { title: t("companyId"), value: companyId || "" },
        { title: t("customerId"), value: customerId || "" },
        { title: t("customer"), value: customerName || "" },
        { title: t("partner"), value: retailerName || "" },
        { title: t("product"), value: t(product || "legacy") },
        { title: t("advisory"), value: t(advisoryService) },
        { title: "Org", value: fileImportConfig ? t("fromFile") : t("manually") },
    ];

    return (
        <div>
            {isLoading && <SpinnerPage />}
            {!isLoading && isOk && (
                <div>
                    <BasicInfo name={companyName || ""} basicInfo={basicInfo} />
                    <div className="border bg-[#FAF6F0]">
                        <div className="h-16 px-8 flex items-center gap-8 border-b overflow-x-scroll scrollbar-hide">
                            {TABS.map((tab) => (
                                <button
                                    key={tab}
                                    onClick={() => navigate(tab)}
                                    className={`focus:shadow-none ${
                                        tab === route ? "font-bold underline underline-offset-[25px] decoration-2" : ""
                                    }`}
                                >
                                    {t(tab)}
                                </button>
                            ))}
                        </div>
                        <Outlet />
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompanyConfiguration;
