import { Shadow } from "@medhelp/ui";
import DropdownMenu from "components/DropdownMenu";
import TabMenu from "components/TabMenu";
import { useBreakpoints } from "hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IRoute } from "routes/hooks";

interface ITopLinks {
    routes: IRoute[];
    headRoute: string;
    header?: string;
}

const TopLinks = ({ routes, headRoute, header }: ITopLinks) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isXLargeScreen } = useBreakpoints();
    const Render = useMemo(
        () => (
            <>
                <Shadow />
                {isXLargeScreen ? (
                    <DropdownMenu routes={routes} subMenuTo={headRoute} header={t(header || "")} />
                ) : (
                    <TabMenu routes={routes} subMenuTo={headRoute} />
                )}
            </>
        ),
        [headRoute, header, isXLargeScreen, routes, t],
    );
    if (routes && routes.length < 1) {
        navigate(`/${headRoute}`);
        return null;
    }
    return Render;
};
export default TopLinks;
