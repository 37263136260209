import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const getInboxState = (state: RootState) => state.inbox;

export const getEventMessages = createSelector(getInboxState, (inbox) => inbox.eventMessages.messages);

export const getSubscriptionMessages = createSelector(getInboxState, (inbox) => inbox.subscriptionMessages.messages);

export const getEventMessagesTotalCount = createSelector(getEventMessages, (messages) => messages?.totalCount ?? 0);

export const getSubscriptionMessagesTotalCount = createSelector(
    getSubscriptionMessages,
    (messages) => messages?.totalCount ?? 0,
);

export const getEventMessagesPageCount = createSelector(getEventMessagesTotalCount, (totalCount) =>
    Math.ceil((totalCount ?? 0) / 10),
);

export const getSubscriptionMessagesPageCount = createSelector(getSubscriptionMessagesTotalCount, (totalCount) =>
    Math.ceil((totalCount ?? 0) / 10),
);

export const getEventMessagesPageNumber = createSelector(getInboxState, (inbox) => inbox.eventMessages.pageNumber);

export const getSubscriptionMessagesPageNumber = createSelector(
    getInboxState,
    (inbox) => inbox.subscriptionMessages.pageNumber,
);

export const getEventMessagesCurrentPage = createSelector(
    getEventMessages,
    getEventMessagesPageNumber,
    (messages, activePage) => {
        const pageItems = messages?.inbox?.find((x) => x.page === activePage ?? 1)?.inbox;
        return pageItems;
    },
);

export const getSubscriptionMessagesCurrentPage = createSelector(
    getSubscriptionMessages,
    getSubscriptionMessagesPageNumber,
    (messages, activePage) => {
        const pageItems = messages?.inbox?.find((x) => x.page === activePage ?? 1)?.inbox;
        return pageItems;
    },
);

export const getEventMessagesStatus = createSelector(getInboxState, (inbox) => inbox.eventMessages.status);

export const getSubscriptionMessagesStatus = createSelector(
    getInboxState,
    (inbox) => inbox.subscriptionMessages.status,
);

export const getUnreadCount = createSelector(
    getInboxState,
    (inbox) => inbox.eventMessages.unreadCount + inbox.subscriptionMessages.unreadCount,
);

export const getInboxHasUnread = createSelector(
    getInboxState,
    (inbox) => inbox.eventMessages.unreadCount + inbox.subscriptionMessages.unreadCount > 0,
);

export const getEventMessagesUnreadCount = createSelector(getInboxState, (inbox) => inbox.eventMessages.unreadCount);

export const getSubscriptionMessagesUnreadCount = createSelector(
    getInboxState,
    (inbox) => inbox.subscriptionMessages.unreadCount,
);

export const getMessageIsExpanded = (id: string | undefined, type: "eventMessages" | "subscriptionMessages") =>
    createSelector(getInboxState, (inbox) => {
        if (!id) return false;
        const messages = type === "eventMessages" ? inbox.eventMessages.messages : inbox.subscriptionMessages.messages;
        return (
            messages?.inbox?.flatMap((page) => page?.inbox)?.find((message) => message?.id === id)?.expanded ?? false
        );
    });
