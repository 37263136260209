import { OngoingRehabFilter } from "swagger/rehab";
import { IDashboardSlice } from "../redux";
import { IDynamicData, IWidget, IWidgetFilter, WidgetType } from "./dashboardTypes";
import { getStartOfDateString, getDateStringForwardOrBack, getStringStartOfDateString } from "utils/date";
import { IWidgetMap } from "../components/Widget/widgetMap";
import { MedHelpHCMDomainTemplate } from "swagger/healthCaseManagement";
import { isEmpty } from "lodash/fp";

const flattenAndFilter = (departments: IDynamicData[], isRehabWidget: boolean): number[] | string[] => {
    let result = [] as any;
    departments?.forEach((element: IDynamicData) => {
        if (element.selectable && element.checked) result.push(isRehabWidget ? element.id : element.referenceKey);
        if (element.children) result = result.concat(flattenAndFilter(element.children, isRehabWidget));
    });
    return result;
};

const getWidgetFilterRequestInitModel = (
    widgetId: string,
    widgetState: IWidget[],
    isRehabWidget: boolean,
): IWidgetFilter | OngoingRehabFilter => {
    const currentWidget = widgetState.find(({ id }) => id === widgetId);
    const widgetFilter = {
        companyIds: currentWidget?.filter?.companyIds,
        departmentIds: currentWidget?.filter?.departmentIds,
        absenceTypes: currentWidget?.filter?.absenceType,
        genders: currentWidget?.filter?.gender,
        employmentGroupIdentifiers: currentWidget?.filter?.employmentGroupIdentifiers,
        absencePeriods: currentWidget?.filter?.absencePeriod,
        timeInterval: currentWidget?.filter?.timeInterval,
        absenceCauses: currentWidget?.filter?.absenceCauses,
        hcmTypes: currentWidget?.filter?.hcmTypes,
    };

    return isRehabWidget ? (widgetFilter as OngoingRehabFilter) : (widgetFilter as IWidgetFilter);
};

const getWidgetFilterRequestSelectionModel = (
    widgetId: string,
    dashboardState: IDashboardSlice,
    isRehabWidget: boolean,
): IWidgetFilter | OngoingRehabFilter => {
    const currentWidget = dashboardState.Widgets?.find(({ id }) => id === widgetId);
    const checkedCompanies = currentWidget?.companies
        ?.filter((item: IDynamicData) => item.checked)
        .map((item: IDynamicData) => item.referenceKey);

    const checkedDepartments = flattenAndFilter(currentWidget?.departments ?? [], isRehabWidget);

    const checkedEmploymentGroups = currentWidget?.employmentGroups
        ?.filter((item: IDynamicData) => item.checked)
        .map((item: IDynamicData) => item.referenceKey);

    const checkedGenders = currentWidget?.genders
        ?.filter((item: IDynamicData) => item.checked)
        .map((item: IDynamicData) => item.referenceKey);

    const checkedAbsenceTypes = currentWidget?.absenceTypes
        ?.filter((item: IDynamicData) => item.checked)
        .map((item: IDynamicData) => item.referenceKey);

    const checkedAbsencePeriods = currentWidget?.absencePeriods
        ?.filter((item: IDynamicData) => item.checked)
        .map((item: IDynamicData) => item.referenceKey);

    const checkedTimeInterval = currentWidget?.timeInterval?.find((item: IDynamicData) => item.checked)
        ?.referenceKey as string;

    const checkedAbsenceCauses = currentWidget?.absenceCauses?.find((item: IDynamicData) => item.checked)
        ?.referenceKey as string;

    const checkedHCMTypes = currentWidget?.hcmTypes
        ?.filter((item: IDynamicData) => item.checked)
        .map((item: IDynamicData) => item.referenceKey);

    const hcmTypes = isEmpty(checkedHCMTypes) ? ["rehab", "healthpromotion"] : checkedHCMTypes;

    const companies = currentWidget?.companies;
    let widgetFilter;
    if (companies && companies.length > 1 && checkedCompanies?.length === 0) {
        widgetFilter = {
            companyIds: [],
            departmentIds: [],
            genders: [],
            employmentGroupIdentifiers: [],
            absenceTypes: [],
            absencePeriods: [],
            timeInterval: "",
            absenceCauses: "",
            hcmTypes: [],
        };
    } else {
        widgetFilter = {
            companyIds: checkedCompanies,
            departmentIds: checkedDepartments,
            genders: checkedGenders,
            employmentGroupIdentifiers: checkedEmploymentGroups,
            absenceTypes: checkedAbsenceTypes,
            absencePeriods: checkedAbsencePeriods,
            timeInterval: checkedTimeInterval,
            absenceCauses: checkedAbsenceCauses,
            hcmTypes: hcmTypes,
        };
    }
    return isRehabWidget ? (widgetFilter as OngoingRehabFilter) : (widgetFilter as IWidgetFilter);
};

export const getWidgetFilterRequestModel = (
    widgetId: string,
    dashboardState: IDashboardSlice,
    isRehab: boolean,
): IWidgetFilter | OngoingRehabFilter => {
    if (dashboardState.Widgets?.find(({ id }) => id === widgetId)?.companies) {
        return getWidgetFilterRequestSelectionModel(widgetId, dashboardState, isRehab);
    } else {
        return getWidgetFilterRequestInitModel(widgetId, dashboardState?.Widgets || [], isRehab);
    }
};

/**
 *
 * @param widgetFilter
 * @param companyId
 * @param currentWidget
 * @returns transformed statistics widget request model
 */
export const getWidgetStatisticsRequestModel = (
    widgetFilter: IWidgetFilter,
    companyId: number,
    currentWidget?: IWidgetMap,
) => {
    const numberOfMonths = getNumberOfMonths(widgetFilter.timeInterval || currentWidget?.defaultTimeSpan);

    const from = getStringStartOfDateString(
        "months",
        getDateStringForwardOrBack(numberOfMonths, "months", "backwards"),
    );

    const to = getStartOfDateString("days");

    const companyIds =
        widgetFilter?.companyIds && widgetFilter.companyIds?.length > 0 ? widgetFilter.companyIds : [companyId];

    const widgetStatisticsRequestModel = {
        companyIds: companyIds,
        from,
        to,
        previousYear: true,
        sameIndustry: true,
        sameSize: true,
        genders: widgetFilter?.genders,
        absenceTypes:
            widgetFilter?.absenceTypes && widgetFilter.absenceTypes.length > 0
                ? widgetFilter.absenceTypes
                : ["sick", "careOfChild"],
        // statistics service expects employment groups in lowercase
        employmentGroups: widgetFilter?.employmentGroupIdentifiers?.map((employmentGroup) =>
            employmentGroup.toLowerCase(),
        ),
        // absenceCauses is named cause in statistics service
        cause: widgetFilter?.absenceCauses,
        // absencePeriods is named absenceLengths in statistics service
        absenceLengths: widgetFilter.absencePeriods,
    };
    return widgetStatisticsRequestModel;
};

export const getWidgetHCMRequestModel = (
    widgetFilter: IWidgetFilter,
    templates: MedHelpHCMDomainTemplate[],
    companyId: number,
) => {
    const companyIds =
        widgetFilter?.companyIds && widgetFilter.companyIds?.length > 0 ? widgetFilter.companyIds : [companyId];
    const hcmTypes =
        widgetFilter.hcmTypes && !isEmpty(widgetFilter.hcmTypes) ? widgetFilter.hcmTypes : ["rehab", "healthpromotion"];
    const templateIds = templates
        .filter(
            (template) =>
                companyIds?.includes(template.companyId ?? 0) && hcmTypes.includes(template.healthCaseType ?? ""),
        )
        .map((template) => template.id ?? "");

    const widgetStatisticsRequestModel = {
        templateIds: templateIds,
        departmentIds: widgetFilter.departmentIds ?? [],
        genders: widgetFilter.genders ?? [],
        employmentGroups: widgetFilter.employmentGroupIdentifiers ?? [],
    };
    return widgetStatisticsRequestModel;
};

export const getWidgetRequestModelWithCompanyId = (
    widgetFilter: IWidgetFilter | OngoingRehabFilter,
    companyId: number,
) => {
    const companyIds =
        widgetFilter?.companyIds && widgetFilter.companyIds?.length > 0 ? widgetFilter.companyIds : [companyId];
    const widgetRequestModel = {
        ...widgetFilter,
        companyIds,
    };
    return widgetRequestModel;
};

export const getNumberOfMonths = (timeInterval?: string): number => {
    if (timeInterval === "threeMonths") return 3;
    else if (timeInterval === "twelveMonths") return 12;
    return 6;
};

export const isRehab = (type: WidgetType): boolean => {
    const widgetTypes = ["ongoing-rehab", "last-activated-rehab"];
    return widgetTypes.includes(type);
};
