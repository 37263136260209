import Navigation, { IExternalProps, useNavigation } from "./Navigation";

export { useNavigation };
export default function Index(props: IExternalProps) {
    const nav = useNavigation(props.routes, props.active, props.subMenuTo, props.params);
    return (
        <Navigation
            routes={nav.getNavigationRoutes}
            createNavigationLink={nav.createNavigationLink}
            setScrollRefState={props.setScrollRefState}
            updateButtonsState={props.updateButtonsState}
        >
            {props.children}
        </Navigation>
    );
}
