import Paginator from "../Paginator";

interface IProps {
    columns: number;
    pages: number;
    onBackButtonClick: (value: number) => void;
    onForwardButtonClick: (value: number) => void;
    onPageClick: (value: number) => void;
    activePage: number;
    leftText: string;
}
const EmployeeSearchFooter = ({
    columns,
    pages,
    onBackButtonClick,
    onForwardButtonClick,
    onPageClick,
    activePage,
    leftText,
}: IProps) => (
    <td colSpan={columns + 1} className="px-6">
        <div className="flex flex-1 flex-col items-center justify-between py-7 md:flex-row">
            <div className="pb-4 md:pb-0">{leftText}</div>
            <Paginator
                activePage={activePage}
                pages={pages}
                onBackButtonClick={onBackButtonClick}
                onPageClick={onPageClick}
                onForwardButtonClick={onForwardButtonClick}
            />
            <div />
        </div>
    </td>
);
export default EmployeeSearchFooter;
