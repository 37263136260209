import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { thunkActions } from "../../../redux";
import { MedHelpHCMWebApiModelsUpdateHealthCaseDocument } from "swagger/healthCaseManagement";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { PageTypes } from "../OngoingCaseItem/OngoingCaseItem";
import { toast } from "react-toastify";
import { ModalCommonFooter } from "@medhelp/ui";
import ModalFrame from "@medhelp/ui/Modal/ModalFrame";
import Icon from "components/Icon";

interface IProps {
    isEdit: boolean;
    healthCase: IHealthCase;
    modalIsOpen: boolean;
    setModalIsOpen: () => void;
    setPage?: (page: PageTypes) => void;
    note?: MedHelpHCMWebApiModelsUpdateHealthCaseDocument;
}

const CaseNoteModal = (props: IProps) => {
    const { isEdit, healthCase, modalIsOpen, setModalIsOpen, setPage, note } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const [title, setTitle] = useState(note?.title);
    const [description, setDescription] = useState(note?.description);

    const noteIsValid = title && description && (title !== note?.title || description !== note?.description);

    const handleSaveNote = () => {
        setModalIsOpen();
        setPage && setPage("caseNotes");

        if (isEdit) {
            dispatch(
                thunkActions.updateCaseDocument({
                    healthCaseId: healthCase?.id ?? "",
                    updateDocumentModel: {
                        id: note?.id,
                        title: title,
                        description: description,
                    },
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("noteUpdated")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("noteNotUpdated")}`, {
                        type: "error",
                    }),
                );
        } else {
            dispatch(
                thunkActions.saveCaseDocument({
                    healthCaseId: healthCase?.id ?? "",
                    createDocumentModel: {
                        type: "note",
                        title: title,
                        description: description,
                    },
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("noteSaved")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("noteNotSaved")}`, {
                        type: "error",
                    }),
                );
        }
    };

    return (
        <ModalFrame
            id="case-note-modal"
            header={<h1>{isEdit ? t("editNote") : t("addNote")}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            content={
                <div className="px-3 sm:px-0">
                    <p className="pb-8">{t("noteModalInfo")}</p>
                    <label htmlFor="subject" className="font-bold">{`${t("subject")} *`}</label>
                    <input
                        id="subject"
                        className={"bg-transparent border border-mhgrey-dark p-2 mt-2 w-full"}
                        value={title ?? ""}
                        onChange={(e) => setTitle(e.target.value)}
                        type="text"
                    />
                    <div className={"flex flex-col pt-8"}>
                        <label htmlFor="note-description" className="font-bold">{`${t("note")} *`}</label>
                        <textarea
                            id="note-description"
                            className="bg-transparent border border-mhgrey-dark p-2 mt-2"
                            rows={5}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description ?? ""}
                        />
                    </div>
                </div>
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    tertiaryOnClick={setModalIsOpen}
                    primaryText={t("save")}
                    primaryOnClick={handleSaveNote}
                    primaryRightIcon={<Icon icon="checkMark" stroke="#192D05" />}
                    primaryDisabled={!noteIsValid}
                />
            }
        />
    );
};

export default CaseNoteModal;
