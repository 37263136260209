import { fileImportBaseApi as api } from "./fileImportBaseApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        postAbsenceValidateAbsences: build.mutation<
            PostAbsenceValidateAbsencesApiResponse,
            PostAbsenceValidateAbsencesApiArg
        >({
            query: (queryArg) => ({
                url: `/Absence/validateAbsences`,
                method: "POST",
                body: queryArg.importAbsenceRows,
            }),
        }),
        postAbsenceImportAbsences: build.mutation<
            PostAbsenceImportAbsencesApiResponse,
            PostAbsenceImportAbsencesApiArg
        >({
            query: (queryArg) => ({
                url: `/Absence/importAbsences`,
                method: "POST",
                body: queryArg.importAbsenceRows,
            }),
        }),
        getChangeDepartments: build.query<GetChangeDepartmentsApiResponse, GetChangeDepartmentsApiArg>({
            query: (queryArg) => ({
                url: `/Change/departments`,
                params: {
                    companyId: queryArg.companyId,
                    importId: queryArg.importId,
                },
            }),
        }),
        getChangeEmployments: build.query<GetChangeEmploymentsApiResponse, GetChangeEmploymentsApiArg>({
            query: (queryArg) => ({
                url: `/Change/employments`,
                params: {
                    companyId: queryArg.companyId,
                    importId: queryArg.importId,
                },
            }),
        }),
        getChangeRoles: build.query<GetChangeRolesApiResponse, GetChangeRolesApiArg>({
            query: (queryArg) => ({
                url: `/Change/roles`,
                params: {
                    companyId: queryArg.companyId,
                    importId: queryArg.importId,
                },
            }),
        }),
        getChangeUsers: build.query<GetChangeUsersApiResponse, GetChangeUsersApiArg>({
            query: (queryArg) => ({
                url: `/Change/users`,
                params: {
                    companyId: queryArg.companyId,
                    importId: queryArg.importId,
                },
            }),
        }),
        getConfigurationById: build.query<GetConfigurationByIdApiResponse, GetConfigurationByIdApiArg>({
            query: (queryArg) => ({ url: `/Configuration/${queryArg.id}` }),
        }),
        getConfigurationCustomersByCustomerId: build.query<
            GetConfigurationCustomersByCustomerIdApiResponse,
            GetConfigurationCustomersByCustomerIdApiArg
        >({
            query: (queryArg) => ({
                url: `/Configuration/customers/${queryArg.customerId}`,
            }),
        }),
        postConfiguration: build.mutation<PostConfigurationApiResponse, PostConfigurationApiArg>({
            query: (queryArg) => ({
                url: `/Configuration`,
                method: "POST",
                body: queryArg.createConfiguration,
            }),
        }),
        deleteConfigurationByIdDelete: build.mutation<
            DeleteConfigurationByIdDeleteApiResponse,
            DeleteConfigurationByIdDeleteApiArg
        >({
            query: (queryArg) => ({
                url: `/Configuration/${queryArg.id}/delete`,
                method: "DELETE",
            }),
        }),
        postConfigurationEmploymentgroups: build.mutation<
            PostConfigurationEmploymentgroupsApiResponse,
            PostConfigurationEmploymentgroupsApiArg
        >({
            query: (queryArg) => ({
                url: `/Configuration/employmentgroups`,
                method: "POST",
                body: queryArg.addEmploymentGroup,
            }),
        }),
        deleteConfigurationEmploymentgroupsById: build.mutation<
            DeleteConfigurationEmploymentgroupsByIdApiResponse,
            DeleteConfigurationEmploymentgroupsByIdApiArg
        >({
            query: (queryArg) => ({
                url: `/Configuration/employmentgroups/${queryArg.id}`,
                method: "DELETE",
                params: { customerKey: queryArg.customerKey },
            }),
        }),
        postConfigurationAutoimport: build.mutation<
            PostConfigurationAutoimportApiResponse,
            PostConfigurationAutoimportApiArg
        >({
            query: (queryArg) => ({
                url: `/Configuration/autoimport`,
                method: "POST",
                params: {
                    customerKey: queryArg.customerKey,
                    autoImport: queryArg.autoImport,
                },
            }),
        }),
        postEvent: build.mutation<PostEventApiResponse, PostEventApiArg>({
            query: (queryArg) => ({
                url: `/Event`,
                method: "POST",
                body: queryArg.eventFilter,
            }),
        }),
        getFile: build.query<GetFileApiResponse, GetFileApiArg>({
            query: (queryArg) => ({
                url: `/File`,
                params: { importId: queryArg.importId },
            }),
        }),
        getInvalidRows: build.query<GetInvalidRowsApiResponse, GetInvalidRowsApiArg>({
            query: (queryArg) => ({
                url: `/InvalidRows`,
                params: {
                    customerKey: queryArg.customerKey,
                    importId: queryArg.importId,
                },
            }),
        }),
        getMergeResult: build.query<GetMergeResultApiResponse, GetMergeResultApiArg>({
            query: (queryArg) => ({
                url: `/MergeResult`,
                params: {
                    customerKey: queryArg.customerKey,
                    importId: queryArg.importId,
                    mergeType: queryArg.mergeType,
                },
            }),
        }),
        getPing: build.query<GetPingApiResponse, GetPingApiArg>({
            query: () => ({ url: `/Ping` }),
        }),
        postPing: build.mutation<PostPingApiResponse, PostPingApiArg>({
            query: (queryArg) => ({
                url: `/Ping`,
                method: "POST",
                body: queryArg.pingModel,
            }),
        }),
        getQueueQueued: build.query<GetQueueQueuedApiResponse, GetQueueQueuedApiArg>({
            query: (queryArg) => ({
                url: `/Queue/queued`,
                params: { customerKey: queryArg.customerKey },
            }),
        }),
        getQueueFinished: build.query<GetQueueFinishedApiResponse, GetQueueFinishedApiArg>({
            query: (queryArg) => ({
                url: `/Queue/finished`,
                params: { customerKey: queryArg.customerKey },
            }),
        }),
        getQueue: build.query<GetQueueApiResponse, GetQueueApiArg>({
            query: (queryArg) => ({
                url: `/Queue`,
                params: { customerKey: queryArg.customerKey },
            }),
        }),
        postQueueApprove: build.mutation<PostQueueApproveApiResponse, PostQueueApproveApiArg>({
            query: (queryArg) => ({
                url: `/Queue/approve`,
                method: "POST",
                params: { customerKey: queryArg.customerKey, id: queryArg.id },
            }),
        }),
        getValidationError: build.query<GetValidationErrorApiResponse, GetValidationErrorApiArg>({
            query: (queryArg) => ({
                url: `/ValidationError`,
                params: {
                    customerKey: queryArg.customerKey,
                    importId: queryArg.importId,
                },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as fileImportApi };
export type PostAbsenceValidateAbsencesApiResponse = unknown;
export type PostAbsenceValidateAbsencesApiArg = {
    importAbsenceRows: ImportAbsenceRows;
};
export type PostAbsenceImportAbsencesApiResponse = unknown;
export type PostAbsenceImportAbsencesApiArg = {
    importAbsenceRows: ImportAbsenceRows;
};
export type GetChangeDepartmentsApiResponse = /** status 200 OK */ DepartmentChange[];
export type GetChangeDepartmentsApiArg = {
    companyId?: number;
    importId?: string;
};
export type GetChangeEmploymentsApiResponse = /** status 200 OK */ EmploymentChange[];
export type GetChangeEmploymentsApiArg = {
    companyId?: number;
    importId?: string;
};
export type GetChangeRolesApiResponse = /** status 200 OK */ RoleChange[];
export type GetChangeRolesApiArg = {
    companyId?: number;
    importId?: string;
};
export type GetChangeUsersApiResponse = /** status 200 OK */ UserChange[];
export type GetChangeUsersApiArg = {
    companyId?: number;
    importId?: string;
};
export type GetConfigurationByIdApiResponse = /** status 200 OK */ FileImportConfiguration;
export type GetConfigurationByIdApiArg = {
    id: string;
};
export type GetConfigurationCustomersByCustomerIdApiResponse = /** status 200 OK */ FileImportConfiguration;
export type GetConfigurationCustomersByCustomerIdApiArg = {
    customerId: number;
};
export type PostConfigurationApiResponse = /** status 200 OK */ string;
export type PostConfigurationApiArg = {
    createConfiguration: CreateConfiguration;
};
export type DeleteConfigurationByIdDeleteApiResponse = /** status 200 OK */ string;
export type DeleteConfigurationByIdDeleteApiArg = {
    id: string;
};
export type PostConfigurationEmploymentgroupsApiResponse = /** status 200 OK */ EmploymentGroup;
export type PostConfigurationEmploymentgroupsApiArg = {
    addEmploymentGroup: AddEmploymentGroup;
};
export type DeleteConfigurationEmploymentgroupsByIdApiResponse = /** status 200 OK */ string;
export type DeleteConfigurationEmploymentgroupsByIdApiArg = {
    customerKey?: number;
    id: string;
};
export type PostConfigurationAutoimportApiResponse = unknown;
export type PostConfigurationAutoimportApiArg = {
    customerKey?: number;
    autoImport?: boolean;
};
export type PostEventApiResponse = /** status 200 OK */ FileImportEvent[];
export type PostEventApiArg = {
    eventFilter: EventFilter;
};
export type GetFileApiResponse = /** status 200 OK */ FileInfo[];
export type GetFileApiArg = {
    importId?: string;
};
export type GetInvalidRowsApiResponse = /** status 200 OK */ InvalidRow[];
export type GetInvalidRowsApiArg = {
    customerKey?: number;
    importId?: string;
};
export type GetMergeResultApiResponse = /** status 200 OK */ MergeResult;
export type GetMergeResultApiArg = {
    customerKey?: number;
    importId?: string;
    mergeType?: string;
};
export type GetPingApiResponse = unknown;
export type GetPingApiArg = void;
export type PostPingApiResponse = unknown;
export type PostPingApiArg = {
    pingModel: PingModel;
};
export type GetQueueQueuedApiResponse = /** status 200 OK */ FileImportQueueRead;
export type GetQueueQueuedApiArg = {
    customerKey?: number;
};
export type GetQueueFinishedApiResponse = /** status 200 OK */ FileImportQueueRead;
export type GetQueueFinishedApiArg = {
    customerKey?: number;
};
export type GetQueueApiResponse = /** status 200 OK */ FileImportQueueRead[];
export type GetQueueApiArg = {
    customerKey?: number;
};
export type PostQueueApproveApiResponse = unknown;
export type PostQueueApproveApiArg = {
    customerKey?: number;
    id?: string;
};
export type GetValidationErrorApiResponse = /** status 200 OK */ ValidationResult;
export type GetValidationErrorApiArg = {
    customerKey?: number;
    importId?: string;
};
export type ImportColumnType = 0 | 2 | 3 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26;
export type ImportColumn = {
    type?: ImportColumnType;
    value?: any | null;
};
export type ImportRow = {
    ignore?: boolean;
    columns?: ImportColumn[] | null;
};
export type ImportAbsenceRows = {
    customerId: number;
    performedByUserAccountId: number;
    longTerm: boolean;
    rows: ImportRow[];
};
export type DepartmentChange = {
    id?: string;
    importId?: string;
    companyId?: number;
    customerKey?: number;
    operation?: string | null;
    departmentId?: number | null;
    externalDepartmentId?: string | null;
    name?: string | null;
    nameChanged?: boolean;
    costCenter?: string | null;
    costCenterChanged?: boolean;
    parentExternalDepartmentId?: string | null;
    parentExternalDepartmentIdChanged?: boolean;
    path?: string | null;
    managerExternalEmploymentId?: string | null;
    managerExternalEmploymentIdChanged?: boolean;
};
export type EmploymentChange = {
    id?: string;
    importId?: string;
    companyId?: number;
    customerKey?: number;
    operation?: string | null;
    externalEmploymentId?: string | null;
    employmentNumber?: string | null;
    employmentNumberChanged?: boolean;
    employmentGroup?: string | null;
    employmentGroupId?: number | null;
    employmentGroupChanged?: boolean;
    startDate?: string;
    startDateChanged?: boolean;
    endDate?: string | null;
    endDateChanged?: boolean;
    costCenter?: string | null;
    costCenterChanged?: boolean;
    organizationNumber?: string | null;
    organizationNumberChanged?: boolean;
    managerExternalEmploymentId?: string | null;
    managerExternalEmploymentIdChanged?: boolean;
    externalDepartmentId?: string | null;
    previousExternalDepartmentId?: string | null;
    externalDepartmentIdChanged?: boolean;
    external?: boolean;
    externalChanged?: boolean;
    userAccountId?: number | null;
    userId?: number | null;
};
export type RoleChange = {
    id?: string;
    importId?: string;
    companyId?: number;
    customerKey?: number;
    operation?: string | null;
    externalEmploymentId?: string | null;
    roleIdentifier?: string | null;
    roleType?: string | null;
    externalId?: string | null;
    externalIdChanged?: boolean;
};
export type UserChange = {
    id?: string;
    importId?: string;
    companyId?: number;
    customerKey?: number;
    operation?: string | null;
    externalEmploymentId?: string | null;
    socialSecurityNumber?: string | null;
    socialSecurityNumberChanged?: boolean;
    firstName?: string | null;
    firstNameChanged?: boolean;
    lastName?: string | null;
    lastNameChanged?: boolean;
    homeAddress?: string | null;
    homeAddressChanged?: boolean;
    countryCode?: string | null;
    countryCodeChanged?: boolean;
    phoneNumber?: string | null;
    phoneNumberChanged?: boolean;
    mobileNumberPrivate?: string | null;
    mobileNumberPrivateChanged?: boolean;
    mobileNumberWork?: string | null;
    mobileNumberWorkChanged?: boolean;
    emailPrivate?: string | null;
    emailPrivateChanged?: boolean;
    emailWork?: string | null;
    emailWorkChanged?: boolean;
};
export type EmploymentGroup = {
    id?: string;
    fileImportConfigurationId?: string;
    externalEmploymentGroup?: string | null;
    employmentGroupId?: number;
};
export type RoleType = 0 | 1 | 2 | 3 | 4;
export type NotificationChannelType = 0 | 1 | 2 | 3;
export type SubscriptionType = {
    id?: string;
    fileImportConfiguration?: FileImportConfiguration;
    role?: RoleType;
    notificationEventType?: number;
    eventCode?: string | null;
    notificationChannel?: NotificationChannelType;
};
export type FileImportConfiguration = {
    id?: string;
    customerKey: number;
    customerId: number;
    customerName: string;
    retailerId: number;
    plugin: string;
    pluginVersion: string;
    secretName?: string | null;
    openApiEnabled: boolean;
    maxAllowedChanges?: number;
    autoImportEnabled?: boolean;
    employmentGroups?: EmploymentGroup[] | null;
    subscriptionTypes?: SubscriptionType[] | null;
};
export type CreateConfiguration = {
    customerId?: number;
    customerKey?: number;
    plugin?: string | null;
    maxAllowedChanges?: number;
    retailerId?: number;
};
export type AddEmploymentGroup = {
    fileImportConfigurationId?: string;
    externalEmploymentGroup?: string | null;
    employmentGroupId?: number;
};
export type FileImportEvent = {
    id?: string;
    customerKey: number;
    importId?: string | null;
    fileType: string;
    timeStamp: string;
    event: string;
};
export type EventFilter = {
    customerKey?: number;
    from?: string;
    to?: string;
};
export type FileInfo = {
    id?: string;
    type?: string | null;
    bucket?: string | null;
    key?: string | null;
    totalRows?: number;
};
export type InvalidRow = {
    id?: string;
    fileId?: string;
    companyId?: number;
    customerKey?: number;
    line?: number;
    rowData?: string | null;
    reason?: string | null;
};
export type MergeResult = {
    id?: string;
    stagedImportId?: string;
    mergeType?: string | null;
    numNew?: number;
    numNewInPercent?: number;
    numDeleted?: number;
    numDeletedInPercent?: number;
    numUpdated?: number;
    numUpdatedInPercent?: number;
    numUpdatedDepartmentAssociations?: number;
};
export type PingModel = {
    message?: string | null;
    timestamp?: string;
};
export type FileImportQueue = {
    id?: string;
    importId?: string;
    customerKey?: number;
    customerId?: number;
    retailerId?: number;
    fileType?: string | null;
    startDate?: string | null;
    requiresApproval?: boolean;
    approvedByUserAccountId?: number | null;
    createdDate?: string;
    updateDate?: string;
    eventState?: string | null;
    numUsersToSend?: number;
    numEmploymentToSend?: number;
    numDepartmentsToSend?: number;
    numDepartmentAssociationsToSend?: number;
    ackUsers?: number;
    ackEmployments?: number;
    ackDepartments?: number;
    ackDepartmentAssociations?: number;
};
export type FileImportQueueRead = {
    id?: string;
    importId?: string;
    customerKey?: number;
    customerId?: number;
    retailerId?: number;
    fileType?: string | null;
    startDate?: string | null;
    requiresApproval?: boolean;
    approvedByUserAccountId?: number | null;
    createdDate?: string;
    updateDate?: string;
    eventState?: string | null;
    numUsersToSend?: number;
    numEmploymentToSend?: number;
    numDepartmentsToSend?: number;
    numDepartmentAssociationsToSend?: number;
    ackUsers?: number;
    ackEmployments?: number;
    ackDepartments?: number;
    ackDepartmentAssociations?: number;
    usersDone?: boolean;
    employmentsDone?: boolean;
    departmentsDone?: boolean;
    departmentAssociationsDone?: boolean;
};
export type ValidationError = {
    id?: string;
    code?: string | null;
    externalId?: string | null;
    validationResult?: ValidationResult;
};
export type ValidationResult = {
    id?: string;
    stagedImportId?: string;
    importId?: string;
    customerKey?: number;
    errors?: ValidationError[] | null;
};
export const {
    usePostAbsenceValidateAbsencesMutation,
    usePostAbsenceImportAbsencesMutation,
    useGetChangeDepartmentsQuery,
    useGetChangeEmploymentsQuery,
    useGetChangeRolesQuery,
    useGetChangeUsersQuery,
    useGetConfigurationByIdQuery,
    useGetConfigurationCustomersByCustomerIdQuery,
    usePostConfigurationMutation,
    useDeleteConfigurationByIdDeleteMutation,
    usePostConfigurationEmploymentgroupsMutation,
    useDeleteConfigurationEmploymentgroupsByIdMutation,
    usePostConfigurationAutoimportMutation,
    usePostEventMutation,
    useGetFileQuery,
    useGetInvalidRowsQuery,
    useGetMergeResultQuery,
    useGetPingQuery,
    usePostPingMutation,
    useGetQueueQueuedQuery,
    useGetQueueFinishedQuery,
    useGetQueueQuery,
    usePostQueueApproveMutation,
    useGetValidationErrorQuery,
} = injectedRtkApi;
