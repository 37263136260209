/* tslint:disable */
/* eslint-disable */
/**
 * MedHelp.AbsenceReporting.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingDomainFieldTemplate
 */
export interface MedHelpAbsenceReportingDomainFieldTemplate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'dataType'?: string | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingDomainOptionField>}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'options'?: Array<MedHelpAbsenceReportingDomainOptionField> | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'order'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'confidential'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'widget'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingDomainFieldTemplate
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingDomainOptionField
 */
export interface MedHelpAbsenceReportingDomainOptionField {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainOptionField
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainOptionField
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainOptionField
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainOptionField
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingDomainTemplate
 */
export interface MedHelpAbsenceReportingDomainTemplate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingDomainTemplate
     */
    'absenceReportType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingDomainTemplate
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingDomainTemplate
     */
    'customerId'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingDomainFieldTemplate>}
     * @memberof MedHelpAbsenceReportingDomainTemplate
     */
    'fields'?: Array<MedHelpAbsenceReportingDomainFieldTemplate> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAbsenceReport
 */
export interface MedHelpAbsenceReportingWebApiModelsAbsenceReport {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'degree'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree>}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'degrees'?: Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'end'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'backAtWork'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'isPreliminary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'updated'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'employmentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'revision'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReport
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree
 */
export interface MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree
     */
    'degree'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree
     */
    'timeStamp'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent
 */
export interface MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent
     */
    'timeStamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent
     */
    'reportedVia'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent
     */
    'reactNativeAppVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent
     */
    'reactNativeAppDevice'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAbsenceReportField
 */
export interface MedHelpAbsenceReportingWebApiModelsAbsenceReportField {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportField
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportField
     */
    'absenceReportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportField
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportField
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportField
     */
    'templateId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportField
     */
    'confidential'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
 */
export interface MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents {
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent>}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'events'?: Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportEvent> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'degree'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree>}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'degrees'?: Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'end'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'backAtWork'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'isPreliminary'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'updated'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'employmentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'revision'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree
 */
export interface MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree
     */
    'absenceReportId'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree
     */
    'degree'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree
     */
    'timeStamp'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate
 */
export interface MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField
 */
export interface MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField
     */
    'fieldName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField
     */
    'fieldValue'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest
 */
export interface MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest
     */
    'anonymizeUserAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest
     */
    'originalUserAccountId'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel
 */
export interface MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel
     */
    'fields'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsConfigurationField
 */
export interface MedHelpAbsenceReportingWebApiModelsConfigurationField {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsConfigurationField
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsConfigurationField
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsConfigurationField
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport
 */
export interface MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport
     */
    'backAtWork'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport
     */
    'degree'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsCreateConfigurationField
 */
export interface MedHelpAbsenceReportingWebApiModelsCreateConfigurationField {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateConfigurationField
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateConfigurationField
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateConfigurationField
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateConfigurationField
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
 */
export interface MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'dataType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingWebApiModelsOptionField>}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'optionFields'?: Array<MedHelpAbsenceReportingWebApiModelsOptionField> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'mandatory'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'confidential'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'order'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate
     */
    'page'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport
 */
export interface MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport
     */
    'end'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport
     */
    'backAtWork'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport
     */
    'absenceCause'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport
     */
    'degree'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport
     */
    'sendToSocialInsurance'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription
 */
export interface MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription
     */
    'fieldName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription
     */
    'language'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsCreateTemplate
 */
export interface MedHelpAbsenceReportingWebApiModelsCreateTemplate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplate
     */
    'absenceReportType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplate
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplate
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplate
     */
    'region'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription
 */
export interface MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription
     */
    'templateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription
     */
    'fieldName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription
     */
    'language'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField
 */
export interface MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport
 */
export interface MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport
     */
    'end'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport
     */
    'backAtWork'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsMedicalCertificate
 */
export interface MedHelpAbsenceReportingWebApiModelsMedicalCertificate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsMedicalCertificate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsMedicalCertificate
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsMedicalCertificate
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsMedicalCertificate
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsMedicalCertificate
     */
    'deletedBy'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsMedicalCertificate
     */
    'deletedTime'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsOptionField
 */
export interface MedHelpAbsenceReportingWebApiModelsOptionField {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsOptionField
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsOptionField
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsOptionField
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport
 */
export interface MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport
     */
    'backAtWork'?: string;
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportField>}
     * @memberof MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport
     */
    'reportFields'?: Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportField> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport
     */
    'absenceDegrees'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport
     */
    'reportFieldsToDelete'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport
 */
export interface MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport
     */
    'to'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport
     */
    'status'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel
 */
export interface MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel
     */
    'templateId': string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsTemplateFieldModel
 */
export interface MedHelpAbsenceReportingWebApiModelsTemplateFieldModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsTemplateFieldModel
     */
    'templateId': string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsTemplateFieldModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsTemplateFieldModel
     */
    'region': string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport
 */
export interface MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport
     */
    'backAtWork'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate
 */
export interface MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsV2AbsenceReportDegree
 */
export interface MedHelpAbsenceReportingWebApiModelsV2AbsenceReportDegree {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2AbsenceReportDegree
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2AbsenceReportDegree
     */
    'degree'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2AbsenceReportDegree
     */
    'timeStamp'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsV2AbsenceReportField
 */
export interface MedHelpAbsenceReportingWebApiModelsV2AbsenceReportField {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2AbsenceReportField
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2AbsenceReportField
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2AbsenceReportField
     */
    'value'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2AbsenceReportField
     */
    'confidential'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel
 */
export interface MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel
     */
    'employmentId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel
     */
    'suppressNotification'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel
 */
export interface MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel
     */
    'backAtWork'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel
     */
    'templateId'?: string;
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingWebApiModelsV2AbsenceReportField>}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel
     */
    'fields'?: Array<MedHelpAbsenceReportingWebApiModelsV2AbsenceReportField> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingWebApiModelsV2AbsenceReportDegree>}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel
     */
    'degrees'?: Array<MedHelpAbsenceReportingWebApiModelsV2AbsenceReportDegree> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel
     */
    'suppressNotification'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel
 */
export interface MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel
     */
    'end'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel
     */
    'backAtWork'?: string;
    /**
     * 
     * @type {Array<MedHelpAbsenceReportingWebApiModelsV2AbsenceReportDegree>}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel
     */
    'degrees'?: Array<MedHelpAbsenceReportingWebApiModelsV2AbsenceReportDegree> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel
     */
    'suppressNotification'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceReportingWebApiPingModel
 */
export interface MedHelpAbsenceReportingWebApiPingModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiPingModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceReportingWebApiPingModel
     */
    'timestamp'?: string;
}

/**
 * AbsenceReportDegreesApi - axios parameter creator
 * @export
 */
export const AbsenceReportDegreesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree} [medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportDegreesPost: async (medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree?: MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AbsenceReportDegrees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportDegreesReportIdIdDelete: async (reportId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('absenceReportDegreesReportIdIdDelete', 'reportId', reportId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absenceReportDegreesReportIdIdDelete', 'id', id)
            const localVarPath = `/AbsenceReportDegrees/{reportId}/{id}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbsenceReportDegreesApi - functional programming interface
 * @export
 */
export const AbsenceReportDegreesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AbsenceReportDegreesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree} [medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportDegreesPost(medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree?: MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportDegreesPost(medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportDegreesReportIdIdDelete(reportId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportDegreesReportIdIdDelete(reportId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AbsenceReportDegreesApi - factory interface
 * @export
 */
export const AbsenceReportDegreesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AbsenceReportDegreesApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree} [medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportDegreesPost(medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree?: MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree, options?: any): AxiosPromise<string> {
            return localVarFp.absenceReportDegreesPost(medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportDegreesReportIdIdDelete(reportId: string, id: string, options?: any): AxiosPromise<string> {
            return localVarFp.absenceReportDegreesReportIdIdDelete(reportId, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AbsenceReportDegreesApi - object-oriented interface
 * @export
 * @class AbsenceReportDegreesApi
 * @extends {BaseAPI}
 */
export class AbsenceReportDegreesApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree} [medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportDegreesApi
     */
    public absenceReportDegreesPost(medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree?: MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree, options?: AxiosRequestConfig) {
        return AbsenceReportDegreesApiFp(this.configuration).absenceReportDegreesPost(medHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reportId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportDegreesApi
     */
    public absenceReportDegreesReportIdIdDelete(reportId: string, id: string, options?: AxiosRequestConfig) {
        return AbsenceReportDegreesApiFp(this.configuration).absenceReportDegreesReportIdIdDelete(reportId, id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AbsenceReportFieldsApi - axios parameter creator
 * @export
 */
export const AbsenceReportFieldsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportFieldsGet: async (absenceReportId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AbsenceReportFields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (absenceReportId !== undefined) {
                localVarQueryParameter['absenceReportId'] = absenceReportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportFieldsIdDeleteDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absenceReportFieldsIdDeleteDelete', 'id', id)
            const localVarPath = `/AbsenceReportFields/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAbsenceReportField} [medHelpAbsenceReportingWebApiModelsAbsenceReportField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportFieldsPost: async (medHelpAbsenceReportingWebApiModelsAbsenceReportField?: MedHelpAbsenceReportingWebApiModelsAbsenceReportField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AbsenceReportFields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsAbsenceReportField, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbsenceReportFieldsApi - functional programming interface
 * @export
 */
export const AbsenceReportFieldsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AbsenceReportFieldsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportFieldsGet(absenceReportId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportFieldsGet(absenceReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportFieldsIdDeleteDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportFieldsIdDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAbsenceReportField} [medHelpAbsenceReportingWebApiModelsAbsenceReportField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportFieldsPost(medHelpAbsenceReportingWebApiModelsAbsenceReportField?: MedHelpAbsenceReportingWebApiModelsAbsenceReportField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportFieldsPost(medHelpAbsenceReportingWebApiModelsAbsenceReportField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AbsenceReportFieldsApi - factory interface
 * @export
 */
export const AbsenceReportFieldsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AbsenceReportFieldsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportFieldsGet(absenceReportId?: string, options?: any): AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportField>> {
            return localVarFp.absenceReportFieldsGet(absenceReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportFieldsIdDeleteDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.absenceReportFieldsIdDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAbsenceReportField} [medHelpAbsenceReportingWebApiModelsAbsenceReportField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportFieldsPost(medHelpAbsenceReportingWebApiModelsAbsenceReportField?: MedHelpAbsenceReportingWebApiModelsAbsenceReportField, options?: any): AxiosPromise<string> {
            return localVarFp.absenceReportFieldsPost(medHelpAbsenceReportingWebApiModelsAbsenceReportField, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AbsenceReportFieldsApi - object-oriented interface
 * @export
 * @class AbsenceReportFieldsApi
 * @extends {BaseAPI}
 */
export class AbsenceReportFieldsApi extends BaseAPI {
    /**
     * 
     * @param {string} [absenceReportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportFieldsApi
     */
    public absenceReportFieldsGet(absenceReportId?: string, options?: AxiosRequestConfig) {
        return AbsenceReportFieldsApiFp(this.configuration).absenceReportFieldsGet(absenceReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportFieldsApi
     */
    public absenceReportFieldsIdDeleteDelete(id: string, options?: AxiosRequestConfig) {
        return AbsenceReportFieldsApiFp(this.configuration).absenceReportFieldsIdDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsAbsenceReportField} [medHelpAbsenceReportingWebApiModelsAbsenceReportField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportFieldsApi
     */
    public absenceReportFieldsPost(medHelpAbsenceReportingWebApiModelsAbsenceReportField?: MedHelpAbsenceReportingWebApiModelsAbsenceReportField, options?: AxiosRequestConfig) {
        return AbsenceReportFieldsApiFp(this.configuration).absenceReportFieldsPost(medHelpAbsenceReportingWebApiModelsAbsenceReportField, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AbsenceReportsApi - axios parameter creator
 * @export
 */
export const AbsenceReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsGet: async (employmentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AbsenceReports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdDeleteDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absenceReportsIdDeleteDelete', 'id', id)
            const localVarPath = `/AbsenceReports/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport} [medHelpAbsenceReportingWebApiModelsFinishAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdFinishPost: async (id: string, medHelpAbsenceReportingWebApiModelsFinishAbsenceReport?: MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absenceReportsIdFinishPost', 'id', id)
            const localVarPath = `/AbsenceReports/{id}/finish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsFinishAbsenceReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absenceReportsIdGet', 'id', id)
            const localVarPath = `/AbsenceReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdOpenPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absenceReportsIdOpenPost', 'id', id)
            const localVarPath = `/AbsenceReports/{id}/open`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport} [medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdPut: async (id: string, medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport?: MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absenceReportsIdPut', 'id', id)
            const localVarPath = `/AbsenceReports/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport} [medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdResetPost: async (id: string, medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport?: MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absenceReportsIdResetPost', 'id', id)
            const localVarPath = `/AbsenceReports/{id}/reset`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdSubmitPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('absenceReportsIdSubmitPost', 'id', id)
            const localVarPath = `/AbsenceReports/{id}/submit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport} [medHelpAbsenceReportingWebApiModelsCreateAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsPost: async (medHelpAbsenceReportingWebApiModelsCreateAbsenceReport?: MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AbsenceReports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsCreateAbsenceReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport} [medHelpAbsenceReportingWebApiModelsSearchAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsSearchPost: async (medHelpAbsenceReportingWebApiModelsSearchAbsenceReport?: MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AbsenceReports/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsSearchAbsenceReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel} [medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AbsenceReportsHistoricalPut: async (medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel?: MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/AbsenceReports/historical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel} [medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AbsenceReportsOpenPut: async (medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel?: MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/AbsenceReports/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel} [medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AbsenceReportsSubmitPost: async (medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel?: MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/AbsenceReports/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbsenceReportsApi - functional programming interface
 * @export
 */
export const AbsenceReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AbsenceReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsGet(employmentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsAbsenceReport>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsIdDeleteDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsIdDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport} [medHelpAbsenceReportingWebApiModelsFinishAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsIdFinishPost(id: string, medHelpAbsenceReportingWebApiModelsFinishAbsenceReport?: MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsIdFinishPost(id, medHelpAbsenceReportingWebApiModelsFinishAbsenceReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceReportingWebApiModelsAbsenceReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsIdOpenPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsIdOpenPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport} [medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsIdPut(id: string, medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport?: MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsIdPut(id, medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport} [medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsIdResetPost(id: string, medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport?: MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsIdResetPost(id, medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsIdSubmitPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsIdSubmitPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport} [medHelpAbsenceReportingWebApiModelsCreateAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsPost(medHelpAbsenceReportingWebApiModelsCreateAbsenceReport?: MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsPost(medHelpAbsenceReportingWebApiModelsCreateAbsenceReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport} [medHelpAbsenceReportingWebApiModelsSearchAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceReportsSearchPost(medHelpAbsenceReportingWebApiModelsSearchAbsenceReport?: MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceReportsSearchPost(medHelpAbsenceReportingWebApiModelsSearchAbsenceReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel} [medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AbsenceReportsHistoricalPut(medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel?: MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AbsenceReportsHistoricalPut(medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel} [medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AbsenceReportsOpenPut(medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel?: MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AbsenceReportsOpenPut(medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel} [medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2AbsenceReportsSubmitPost(medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel?: MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2AbsenceReportsSubmitPost(medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AbsenceReportsApi - factory interface
 * @export
 */
export const AbsenceReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AbsenceReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsGet(employmentId?: string, options?: any): AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsAbsenceReport>> {
            return localVarFp.absenceReportsGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdDeleteDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.absenceReportsIdDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport} [medHelpAbsenceReportingWebApiModelsFinishAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdFinishPost(id: string, medHelpAbsenceReportingWebApiModelsFinishAbsenceReport?: MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport, options?: any): AxiosPromise<string> {
            return localVarFp.absenceReportsIdFinishPost(id, medHelpAbsenceReportingWebApiModelsFinishAbsenceReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdGet(id: string, options?: any): AxiosPromise<MedHelpAbsenceReportingWebApiModelsAbsenceReport> {
            return localVarFp.absenceReportsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdOpenPost(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.absenceReportsIdOpenPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport} [medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdPut(id: string, medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport?: MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport, options?: any): AxiosPromise<void> {
            return localVarFp.absenceReportsIdPut(id, medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport} [medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdResetPost(id: string, medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport?: MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport, options?: any): AxiosPromise<string> {
            return localVarFp.absenceReportsIdResetPost(id, medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsIdSubmitPost(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.absenceReportsIdSubmitPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport} [medHelpAbsenceReportingWebApiModelsCreateAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsPost(medHelpAbsenceReportingWebApiModelsCreateAbsenceReport?: MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport, options?: any): AxiosPromise<string> {
            return localVarFp.absenceReportsPost(medHelpAbsenceReportingWebApiModelsCreateAbsenceReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport} [medHelpAbsenceReportingWebApiModelsSearchAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceReportsSearchPost(medHelpAbsenceReportingWebApiModelsSearchAbsenceReport?: MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport, options?: any): AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsAbsenceReportIncludingEvents>> {
            return localVarFp.absenceReportsSearchPost(medHelpAbsenceReportingWebApiModelsSearchAbsenceReport, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel} [medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AbsenceReportsHistoricalPut(medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel?: MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel, options?: any): AxiosPromise<MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel> {
            return localVarFp.v2AbsenceReportsHistoricalPut(medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel} [medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AbsenceReportsOpenPut(medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel?: MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel, options?: any): AxiosPromise<MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel> {
            return localVarFp.v2AbsenceReportsOpenPut(medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel} [medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2AbsenceReportsSubmitPost(medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel?: MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel, options?: any): AxiosPromise<MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel> {
            return localVarFp.v2AbsenceReportsSubmitPost(medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AbsenceReportsApi - object-oriented interface
 * @export
 * @class AbsenceReportsApi
 * @extends {BaseAPI}
 */
export class AbsenceReportsApi extends BaseAPI {
    /**
     * 
     * @param {string} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsGet(employmentId?: string, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsIdDeleteDelete(id: string, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsIdDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport} [medHelpAbsenceReportingWebApiModelsFinishAbsenceReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsIdFinishPost(id: string, medHelpAbsenceReportingWebApiModelsFinishAbsenceReport?: MedHelpAbsenceReportingWebApiModelsFinishAbsenceReport, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsIdFinishPost(id, medHelpAbsenceReportingWebApiModelsFinishAbsenceReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsIdGet(id: string, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsIdOpenPost(id: string, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsIdOpenPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport} [medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsIdPut(id: string, medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport?: MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsIdPut(id, medHelpAbsenceReportingWebApiModelsUpdateAbsenceReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport} [medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsIdResetPost(id: string, medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport?: MedHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsIdResetPost(id, medHelpAbsenceReportingWebApiModelsResetOngoingAbsenceReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsIdSubmitPost(id: string, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsIdSubmitPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport} [medHelpAbsenceReportingWebApiModelsCreateAbsenceReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsPost(medHelpAbsenceReportingWebApiModelsCreateAbsenceReport?: MedHelpAbsenceReportingWebApiModelsCreateAbsenceReport, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsPost(medHelpAbsenceReportingWebApiModelsCreateAbsenceReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport} [medHelpAbsenceReportingWebApiModelsSearchAbsenceReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public absenceReportsSearchPost(medHelpAbsenceReportingWebApiModelsSearchAbsenceReport?: MedHelpAbsenceReportingWebApiModelsSearchAbsenceReport, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).absenceReportsSearchPost(medHelpAbsenceReportingWebApiModelsSearchAbsenceReport, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel} [medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public v2AbsenceReportsHistoricalPut(medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel?: MedHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).v2AbsenceReportsHistoricalPut(medHelpAbsenceReportingWebApiModelsV2UpdateHistoricalAbsenceModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel} [medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public v2AbsenceReportsOpenPut(medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel?: MedHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).v2AbsenceReportsOpenPut(medHelpAbsenceReportingWebApiModelsV2OpenHistoricalAbsenceModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel} [medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceReportsApi
     */
    public v2AbsenceReportsSubmitPost(medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel?: MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel, options?: AxiosRequestConfig) {
        return AbsenceReportsApiFp(this.configuration).v2AbsenceReportsSubmitPost(medHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationFieldsApi - axios parameter creator
 * @export
 */
export const ConfigurationFieldsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField} [medHelpAbsenceReportingWebApiModelsDeleteConfigurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsDeleteDelete: async (medHelpAbsenceReportingWebApiModelsDeleteConfigurationField?: MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ConfigurationFields/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsDeleteConfigurationField, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsGet: async (customerId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ConfigurationFields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsIdDeleteDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('configurationFieldsIdDeleteDelete', 'id', id)
            const localVarPath = `/ConfigurationFields/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateConfigurationField} [medHelpAbsenceReportingWebApiModelsCreateConfigurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsPost: async (medHelpAbsenceReportingWebApiModelsCreateConfigurationField?: MedHelpAbsenceReportingWebApiModelsCreateConfigurationField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ConfigurationFields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsCreateConfigurationField, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField} [medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsPut: async (medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField?: MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ConfigurationFields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationFieldsApi - functional programming interface
 * @export
 */
export const ConfigurationFieldsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationFieldsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField} [medHelpAbsenceReportingWebApiModelsDeleteConfigurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationFieldsDeleteDelete(medHelpAbsenceReportingWebApiModelsDeleteConfigurationField?: MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationFieldsDeleteDelete(medHelpAbsenceReportingWebApiModelsDeleteConfigurationField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationFieldsGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsConfigurationField>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationFieldsGet(customerId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationFieldsIdDeleteDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationFieldsIdDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateConfigurationField} [medHelpAbsenceReportingWebApiModelsCreateConfigurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationFieldsPost(medHelpAbsenceReportingWebApiModelsCreateConfigurationField?: MedHelpAbsenceReportingWebApiModelsCreateConfigurationField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationFieldsPost(medHelpAbsenceReportingWebApiModelsCreateConfigurationField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField} [medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationFieldsPut(medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField?: MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.configurationFieldsPut(medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationFieldsApi - factory interface
 * @export
 */
export const ConfigurationFieldsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationFieldsApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField} [medHelpAbsenceReportingWebApiModelsDeleteConfigurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsDeleteDelete(medHelpAbsenceReportingWebApiModelsDeleteConfigurationField?: MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField, options?: any): AxiosPromise<void> {
            return localVarFp.configurationFieldsDeleteDelete(medHelpAbsenceReportingWebApiModelsDeleteConfigurationField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsGet(customerId?: number, companyId?: number, options?: any): AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsConfigurationField>> {
            return localVarFp.configurationFieldsGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsIdDeleteDelete(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.configurationFieldsIdDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateConfigurationField} [medHelpAbsenceReportingWebApiModelsCreateConfigurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsPost(medHelpAbsenceReportingWebApiModelsCreateConfigurationField?: MedHelpAbsenceReportingWebApiModelsCreateConfigurationField, options?: any): AxiosPromise<string> {
            return localVarFp.configurationFieldsPost(medHelpAbsenceReportingWebApiModelsCreateConfigurationField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField} [medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationFieldsPut(medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField?: MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField, options?: any): AxiosPromise<void> {
            return localVarFp.configurationFieldsPut(medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationFieldsApi - object-oriented interface
 * @export
 * @class ConfigurationFieldsApi
 * @extends {BaseAPI}
 */
export class ConfigurationFieldsApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField} [medHelpAbsenceReportingWebApiModelsDeleteConfigurationField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationFieldsApi
     */
    public configurationFieldsDeleteDelete(medHelpAbsenceReportingWebApiModelsDeleteConfigurationField?: MedHelpAbsenceReportingWebApiModelsDeleteConfigurationField, options?: AxiosRequestConfig) {
        return ConfigurationFieldsApiFp(this.configuration).configurationFieldsDeleteDelete(medHelpAbsenceReportingWebApiModelsDeleteConfigurationField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationFieldsApi
     */
    public configurationFieldsGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return ConfigurationFieldsApiFp(this.configuration).configurationFieldsGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationFieldsApi
     */
    public configurationFieldsIdDeleteDelete(id: string, options?: AxiosRequestConfig) {
        return ConfigurationFieldsApiFp(this.configuration).configurationFieldsIdDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsCreateConfigurationField} [medHelpAbsenceReportingWebApiModelsCreateConfigurationField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationFieldsApi
     */
    public configurationFieldsPost(medHelpAbsenceReportingWebApiModelsCreateConfigurationField?: MedHelpAbsenceReportingWebApiModelsCreateConfigurationField, options?: AxiosRequestConfig) {
        return ConfigurationFieldsApiFp(this.configuration).configurationFieldsPost(medHelpAbsenceReportingWebApiModelsCreateConfigurationField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField} [medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationFieldsApi
     */
    public configurationFieldsPut(medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField?: MedHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField, options?: AxiosRequestConfig) {
        return ConfigurationFieldsApiFp(this.configuration).configurationFieldsPut(medHelpAbsenceReportingWebApiModelsAddOrUpdateConfigurationField, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataAnonymizationApi - axios parameter creator
 * @export
 */
export const DataAnonymizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationCompanyCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('dataAnonymizationCompanyCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/DataAnonymization/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest} [medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationEmploymentEmploymentIdDelete: async (employmentId: string, medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest?: MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('dataAnonymizationEmploymentEmploymentIdDelete', 'employmentId', employmentId)
            const localVarPath = `/DataAnonymization/employment/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataAnonymizationApi - functional programming interface
 * @export
 */
export const DataAnonymizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataAnonymizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataAnonymizationCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataAnonymizationCompanyCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest} [medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataAnonymizationEmploymentEmploymentIdDelete(employmentId: string, medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest?: MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataAnonymizationEmploymentEmploymentIdDelete(employmentId, medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataAnonymizationApi - factory interface
 * @export
 */
export const DataAnonymizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataAnonymizationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationCompanyCompanyIdGet(companyId: number, options?: any): AxiosPromise<Array<number>> {
            return localVarFp.dataAnonymizationCompanyCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest} [medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataAnonymizationEmploymentEmploymentIdDelete(employmentId: string, medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest?: MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.dataAnonymizationEmploymentEmploymentIdDelete(employmentId, medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataAnonymizationApi - object-oriented interface
 * @export
 * @class DataAnonymizationApi
 * @extends {BaseAPI}
 */
export class DataAnonymizationApi extends BaseAPI {
    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public dataAnonymizationCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).dataAnonymizationCompanyCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employmentId 
     * @param {MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest} [medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public dataAnonymizationEmploymentEmploymentIdDelete(employmentId: string, medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest?: MedHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).dataAnonymizationEmploymentEmploymentIdDelete(employmentId, medHelpAbsenceReportingWebApiModelsAnonymizeAbsenceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FieldTemplateApi - axios parameter creator
 * @export
 */
export const FieldTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTemplateAvailableGet: async (region?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/FieldTemplate/Available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel} [medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTemplateBoilerplatePost: async (medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel?: MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/FieldTemplate/Boilerplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [templateId] 
         * @param {string} [fieldTemplateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTemplateDelete: async (templateId?: string, fieldTemplateId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/FieldTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }

            if (fieldTemplateId !== undefined) {
                localVarQueryParameter['fieldTemplateId'] = fieldTemplateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsTemplateFieldModel} [medHelpAbsenceReportingWebApiModelsTemplateFieldModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTemplateFieldPost: async (medHelpAbsenceReportingWebApiModelsTemplateFieldModel?: MedHelpAbsenceReportingWebApiModelsTemplateFieldModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/FieldTemplate/Field`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsTemplateFieldModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate} [medHelpAbsenceReportingWebApiModelsCreateFieldTemplate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        fieldTemplatePost: async (medHelpAbsenceReportingWebApiModelsCreateFieldTemplate?: MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/FieldTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsCreateFieldTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldTemplateApi - functional programming interface
 * @export
 */
export const FieldTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldTemplateAvailableGet(region?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTemplateAvailableGet(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel} [medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldTemplateBoilerplatePost(medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel?: MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTemplateBoilerplatePost(medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [templateId] 
         * @param {string} [fieldTemplateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldTemplateDelete(templateId?: string, fieldTemplateId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTemplateDelete(templateId, fieldTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsTemplateFieldModel} [medHelpAbsenceReportingWebApiModelsTemplateFieldModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldTemplateFieldPost(medHelpAbsenceReportingWebApiModelsTemplateFieldModel?: MedHelpAbsenceReportingWebApiModelsTemplateFieldModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTemplateFieldPost(medHelpAbsenceReportingWebApiModelsTemplateFieldModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate} [medHelpAbsenceReportingWebApiModelsCreateFieldTemplate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async fieldTemplatePost(medHelpAbsenceReportingWebApiModelsCreateFieldTemplate?: MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldTemplatePost(medHelpAbsenceReportingWebApiModelsCreateFieldTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FieldTemplateApi - factory interface
 * @export
 */
export const FieldTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTemplateAvailableGet(region?: string, options?: any): AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel>> {
            return localVarFp.fieldTemplateAvailableGet(region, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel} [medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTemplateBoilerplatePost(medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel?: MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel, options?: any): AxiosPromise<string> {
            return localVarFp.fieldTemplateBoilerplatePost(medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [templateId] 
         * @param {string} [fieldTemplateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTemplateDelete(templateId?: string, fieldTemplateId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.fieldTemplateDelete(templateId, fieldTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsTemplateFieldModel} [medHelpAbsenceReportingWebApiModelsTemplateFieldModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldTemplateFieldPost(medHelpAbsenceReportingWebApiModelsTemplateFieldModel?: MedHelpAbsenceReportingWebApiModelsTemplateFieldModel, options?: any): AxiosPromise<string> {
            return localVarFp.fieldTemplateFieldPost(medHelpAbsenceReportingWebApiModelsTemplateFieldModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate} [medHelpAbsenceReportingWebApiModelsCreateFieldTemplate] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        fieldTemplatePost(medHelpAbsenceReportingWebApiModelsCreateFieldTemplate?: MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate, options?: any): AxiosPromise<string> {
            return localVarFp.fieldTemplatePost(medHelpAbsenceReportingWebApiModelsCreateFieldTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FieldTemplateApi - object-oriented interface
 * @export
 * @class FieldTemplateApi
 * @extends {BaseAPI}
 */
export class FieldTemplateApi extends BaseAPI {
    /**
     * 
     * @param {string} [region] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldTemplateApi
     */
    public fieldTemplateAvailableGet(region?: string, options?: AxiosRequestConfig) {
        return FieldTemplateApiFp(this.configuration).fieldTemplateAvailableGet(region, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel} [medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldTemplateApi
     */
    public fieldTemplateBoilerplatePost(medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel?: MedHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel, options?: AxiosRequestConfig) {
        return FieldTemplateApiFp(this.configuration).fieldTemplateBoilerplatePost(medHelpAbsenceReportingWebApiModelsTemplateFieldBoilerplateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [templateId] 
     * @param {string} [fieldTemplateId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldTemplateApi
     */
    public fieldTemplateDelete(templateId?: string, fieldTemplateId?: string, options?: AxiosRequestConfig) {
        return FieldTemplateApiFp(this.configuration).fieldTemplateDelete(templateId, fieldTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsTemplateFieldModel} [medHelpAbsenceReportingWebApiModelsTemplateFieldModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldTemplateApi
     */
    public fieldTemplateFieldPost(medHelpAbsenceReportingWebApiModelsTemplateFieldModel?: MedHelpAbsenceReportingWebApiModelsTemplateFieldModel, options?: AxiosRequestConfig) {
        return FieldTemplateApiFp(this.configuration).fieldTemplateFieldPost(medHelpAbsenceReportingWebApiModelsTemplateFieldModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate} [medHelpAbsenceReportingWebApiModelsCreateFieldTemplate] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof FieldTemplateApi
     */
    public fieldTemplatePost(medHelpAbsenceReportingWebApiModelsCreateFieldTemplate?: MedHelpAbsenceReportingWebApiModelsCreateFieldTemplate, options?: AxiosRequestConfig) {
        return FieldTemplateApiFp(this.configuration).fieldTemplatePost(medHelpAbsenceReportingWebApiModelsCreateFieldTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HistoricalAbsenceReportApi - axios parameter creator
 * @export
 */
export const HistoricalAbsenceReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport} [medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicalAbsenceReportPost: async (medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport?: MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/HistoricalAbsenceReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoricalAbsenceReportApi - functional programming interface
 * @export
 */
export const HistoricalAbsenceReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoricalAbsenceReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport} [medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async historicalAbsenceReportPost(medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport?: MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.historicalAbsenceReportPost(medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HistoricalAbsenceReportApi - factory interface
 * @export
 */
export const HistoricalAbsenceReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoricalAbsenceReportApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport} [medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicalAbsenceReportPost(medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport?: MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport, options?: any): AxiosPromise<string> {
            return localVarFp.historicalAbsenceReportPost(medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HistoricalAbsenceReportApi - object-oriented interface
 * @export
 * @class HistoricalAbsenceReportApi
 * @extends {BaseAPI}
 */
export class HistoricalAbsenceReportApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport} [medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalAbsenceReportApi
     */
    public historicalAbsenceReportPost(medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport?: MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport, options?: AxiosRequestConfig) {
        return HistoricalAbsenceReportApiFp(this.configuration).historicalAbsenceReportPost(medHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicalCertificatesApi - axios parameter creator
 * @export
 */
export const MedicalCertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MedicalCertificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesGetByEmploymentIdGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MedicalCertificates/GetByEmploymentId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesIdDeleteDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('medicalCertificatesIdDeleteDelete', 'id', id)
            const localVarPath = `/MedicalCertificates/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate} [medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesIdPut: async (id: string, medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate?: MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('medicalCertificatesIdPut', 'id', id)
            const localVarPath = `/MedicalCertificates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate} [medHelpAbsenceReportingWebApiModelsAddMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesPost: async (medHelpAbsenceReportingWebApiModelsAddMedicalCertificate?: MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MedicalCertificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsAddMedicalCertificate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalCertificatesApi - functional programming interface
 * @export
 */
export const MedicalCertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalCertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceReportingWebApiModelsMedicalCertificate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesGetByEmploymentIdGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsMedicalCertificate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesGetByEmploymentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesIdDeleteDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesIdDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate} [medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesIdPut(id: string, medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate?: MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesIdPut(id, medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate} [medHelpAbsenceReportingWebApiModelsAddMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesPost(medHelpAbsenceReportingWebApiModelsAddMedicalCertificate?: MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesPost(medHelpAbsenceReportingWebApiModelsAddMedicalCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicalCertificatesApi - factory interface
 * @export
 */
export const MedicalCertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalCertificatesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesGet(id?: string, options?: any): AxiosPromise<MedHelpAbsenceReportingWebApiModelsMedicalCertificate> {
            return localVarFp.medicalCertificatesGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesGetByEmploymentIdGet(id?: string, options?: any): AxiosPromise<Array<MedHelpAbsenceReportingWebApiModelsMedicalCertificate>> {
            return localVarFp.medicalCertificatesGetByEmploymentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesIdDeleteDelete(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.medicalCertificatesIdDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate} [medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesIdPut(id: string, medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate?: MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate, options?: any): AxiosPromise<string> {
            return localVarFp.medicalCertificatesIdPut(id, medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate} [medHelpAbsenceReportingWebApiModelsAddMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesPost(medHelpAbsenceReportingWebApiModelsAddMedicalCertificate?: MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate, options?: any): AxiosPromise<string> {
            return localVarFp.medicalCertificatesPost(medHelpAbsenceReportingWebApiModelsAddMedicalCertificate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicalCertificatesApi - object-oriented interface
 * @export
 * @class MedicalCertificatesApi
 * @extends {BaseAPI}
 */
export class MedicalCertificatesApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesGet(id?: string, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesGetByEmploymentIdGet(id?: string, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesGetByEmploymentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesIdDeleteDelete(id: string, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesIdDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate} [medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesIdPut(id: string, medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate?: MedHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesIdPut(id, medHelpAbsenceReportingWebApiModelsUpdateMedicalCertificate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate} [medHelpAbsenceReportingWebApiModelsAddMedicalCertificate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesPost(medHelpAbsenceReportingWebApiModelsAddMedicalCertificate?: MedHelpAbsenceReportingWebApiModelsAddMedicalCertificate, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesPost(medHelpAbsenceReportingWebApiModelsAddMedicalCertificate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OptionFieldDescriptionsApi - axios parameter creator
 * @export
 */
export const OptionFieldDescriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription} [medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionFieldDescriptionsPost: async (medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription?: MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/OptionFieldDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OptionFieldDescriptionsApi - functional programming interface
 * @export
 */
export const OptionFieldDescriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OptionFieldDescriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription} [medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optionFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription?: MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optionFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OptionFieldDescriptionsApi - factory interface
 * @export
 */
export const OptionFieldDescriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OptionFieldDescriptionsApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription} [medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optionFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription?: MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription, options?: any): AxiosPromise<string> {
            return localVarFp.optionFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OptionFieldDescriptionsApi - object-oriented interface
 * @export
 * @class OptionFieldDescriptionsApi
 * @extends {BaseAPI}
 */
export class OptionFieldDescriptionsApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription} [medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OptionFieldDescriptionsApi
     */
    public optionFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription?: MedHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription, options?: AxiosRequestConfig) {
        return OptionFieldDescriptionsApiFp(this.configuration).optionFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateOptionFieldDescription, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiPingModel} [medHelpAbsenceReportingWebApiPingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPost: async (medHelpAbsenceReportingWebApiPingModel?: MedHelpAbsenceReportingWebApiPingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiPingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiPingModel} [medHelpAbsenceReportingWebApiPingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingPost(medHelpAbsenceReportingWebApiPingModel?: MedHelpAbsenceReportingWebApiPingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingPost(medHelpAbsenceReportingWebApiPingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet(options?: any): AxiosPromise<void> {
            return localVarFp.pingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiPingModel} [medHelpAbsenceReportingWebApiPingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPost(medHelpAbsenceReportingWebApiPingModel?: MedHelpAbsenceReportingWebApiPingModel, options?: any): AxiosPromise<void> {
            return localVarFp.pingPost(medHelpAbsenceReportingWebApiPingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingGet(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).pingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiPingModel} [medHelpAbsenceReportingWebApiPingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingPost(medHelpAbsenceReportingWebApiPingModel?: MedHelpAbsenceReportingWebApiPingModel, options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).pingPost(medHelpAbsenceReportingWebApiPingModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateFieldDescriptionsApi - axios parameter creator
 * @export
 */
export const TemplateFieldDescriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription} [medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateFieldDescriptionsPost: async (medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription?: MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/TemplateFieldDescriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateFieldDescriptionsApi - functional programming interface
 * @export
 */
export const TemplateFieldDescriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateFieldDescriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription} [medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templateFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription?: MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templateFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateFieldDescriptionsApi - factory interface
 * @export
 */
export const TemplateFieldDescriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateFieldDescriptionsApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription} [medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templateFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription?: MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription, options?: any): AxiosPromise<string> {
            return localVarFp.templateFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateFieldDescriptionsApi - object-oriented interface
 * @export
 * @class TemplateFieldDescriptionsApi
 * @extends {BaseAPI}
 */
export class TemplateFieldDescriptionsApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription} [medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateFieldDescriptionsApi
     */
    public templateFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription?: MedHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription, options?: AxiosRequestConfig) {
        return TemplateFieldDescriptionsApiFp(this.configuration).templateFieldDescriptionsPost(medHelpAbsenceReportingWebApiModelsCreateTemplateFieldDescription, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplatesApi - axios parameter creator
 * @export
 */
export const TemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {string} [type] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesCustomerGet: async (customerId?: number, companyId?: number, type?: string, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Templates/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [employmentId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesGet: async (employmentId?: string, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesIdGet: async (id: string, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('templatesIdGet', 'id', id)
            const localVarPath = `/Templates/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} [companyId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesListCustomerIdGet: async (customerId: number, companyId?: number, language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('templatesListCustomerIdGet', 'customerId', customerId)
            const localVarPath = `/Templates/list/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateTemplate} [medHelpAbsenceReportingWebApiModelsCreateTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesPost: async (medHelpAbsenceReportingWebApiModelsCreateTemplate?: MedHelpAbsenceReportingWebApiModelsCreateTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceReportingWebApiModelsCreateTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesTemplateIdDelete: async (templateId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('templatesTemplateIdDelete', 'templateId', templateId)
            const localVarPath = `/Templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplatesApi - functional programming interface
 * @export
 */
export const TemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {string} [type] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templatesCustomerGet(customerId?: number, companyId?: number, type?: string, language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceReportingDomainTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templatesCustomerGet(customerId, companyId, type, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [employmentId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templatesGet(employmentId?: string, language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceReportingDomainTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templatesGet(employmentId, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templatesIdGet(id: string, language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceReportingDomainTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templatesIdGet(id, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} [companyId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templatesListCustomerIdGet(customerId: number, companyId?: number, language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceReportingDomainTemplate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templatesListCustomerIdGet(customerId, companyId, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateTemplate} [medHelpAbsenceReportingWebApiModelsCreateTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templatesPost(medHelpAbsenceReportingWebApiModelsCreateTemplate?: MedHelpAbsenceReportingWebApiModelsCreateTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templatesPost(medHelpAbsenceReportingWebApiModelsCreateTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templatesTemplateIdDelete(templateId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templatesTemplateIdDelete(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplatesApi - factory interface
 * @export
 */
export const TemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplatesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {string} [type] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesCustomerGet(customerId?: number, companyId?: number, type?: string, language?: string, options?: any): AxiosPromise<Array<MedHelpAbsenceReportingDomainTemplate>> {
            return localVarFp.templatesCustomerGet(customerId, companyId, type, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [employmentId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesGet(employmentId?: string, language?: string, options?: any): AxiosPromise<Array<MedHelpAbsenceReportingDomainTemplate>> {
            return localVarFp.templatesGet(employmentId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesIdGet(id: string, language?: string, options?: any): AxiosPromise<MedHelpAbsenceReportingDomainTemplate> {
            return localVarFp.templatesIdGet(id, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} [companyId] 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesListCustomerIdGet(customerId: number, companyId?: number, language?: string, options?: any): AxiosPromise<Array<MedHelpAbsenceReportingDomainTemplate>> {
            return localVarFp.templatesListCustomerIdGet(customerId, companyId, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceReportingWebApiModelsCreateTemplate} [medHelpAbsenceReportingWebApiModelsCreateTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesPost(medHelpAbsenceReportingWebApiModelsCreateTemplate?: MedHelpAbsenceReportingWebApiModelsCreateTemplate, options?: any): AxiosPromise<string> {
            return localVarFp.templatesPost(medHelpAbsenceReportingWebApiModelsCreateTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templatesTemplateIdDelete(templateId: string, options?: any): AxiosPromise<string> {
            return localVarFp.templatesTemplateIdDelete(templateId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplatesApi - object-oriented interface
 * @export
 * @class TemplatesApi
 * @extends {BaseAPI}
 */
export class TemplatesApi extends BaseAPI {
    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [companyId] 
     * @param {string} [type] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public templatesCustomerGet(customerId?: number, companyId?: number, type?: string, language?: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).templatesCustomerGet(customerId, companyId, type, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [employmentId] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public templatesGet(employmentId?: string, language?: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).templatesGet(employmentId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public templatesIdGet(id: string, language?: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).templatesIdGet(id, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} [companyId] 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public templatesListCustomerIdGet(customerId: number, companyId?: number, language?: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).templatesListCustomerIdGet(customerId, companyId, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceReportingWebApiModelsCreateTemplate} [medHelpAbsenceReportingWebApiModelsCreateTemplate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public templatesPost(medHelpAbsenceReportingWebApiModelsCreateTemplate?: MedHelpAbsenceReportingWebApiModelsCreateTemplate, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).templatesPost(medHelpAbsenceReportingWebApiModelsCreateTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesApi
     */
    public templatesTemplateIdDelete(templateId: string, options?: AxiosRequestConfig) {
        return TemplatesApiFp(this.configuration).templatesTemplateIdDelete(templateId, options).then((request) => request(this.axios, this.basePath));
    }
}


