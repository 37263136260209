import { twMerge } from "tailwind-merge";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

export type FileImportStatus = "queued" | "processing" | "flagged" | "failed" | "success";

type StatusConfig = {
    [K in FileImportStatus]: {
        text: string;
        backgroundColor: string;
    };
};

const StatusTag = ({ status }: { status: FileImportStatus }) => {
    const { t } = useTranslation("fileImport");

    const statusConfig: StatusConfig = {
        queued: {
            text: t("queued"),
            backgroundColor: "bg-statusTagScheduled",
        },
        processing: {
            text: t("processing"),
            backgroundColor: "bg-statusTagProcessing",
        },
        flagged: { text: t("flagged"), backgroundColor: "bg-[#F0D902]" },
        failed: { text: t("failed"), backgroundColor: "bg-[#FF1E2D]" },
        success: { text: t("success"), backgroundColor: "bg-[#11B05A]" },
    };

    const config = statusConfig[status];

    if (!config) return null;
    return (
        <div
            data-testid="status-tag"
            className={twMerge(
                "flex gap-2.5 items-center w-content rounded text-white font-bold px-1.5 py-0.5",
                config.backgroundColor,
            )}
        >
            {config.text}
            {status === "processing" && <Oval height={14} width={14} color="white" />}
        </div>
    );
};

export default StatusTag;
