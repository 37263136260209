import { IDateRange } from "pages/FollowUp/types";
import DateFilter, { IDate } from "../DateFilter";
interface IPropsView {
    periods: IDate[];
    dateRanges: IDateRange;
    header: string;
    body: string;
}
const PeriodFilter = ({ periods, header, body, dateRanges }: IPropsView) => (
    <DateFilter dates={periods} header={header} body={body} dateRanges={dateRanges} />
);

export default PeriodFilter;
