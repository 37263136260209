import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getDynamicData } from "../../utils/dashboardSliceUtils";
import { WidgetDropdownTypeKeys } from "../../utils/dashboardTypes";
import { IDashboardSlice } from "../dashboardSlice";
import { fetchWidgetDropdownItems } from "../dashboardActions";

const departmentAndEmploymentsGroups: WidgetDropdownTypeKeys[] = ["departments", "employmentGroups", "absenceCauses"];

/**
 * Switch `departments` `employmentGroups` whenever a new company is selected
 */
const switchDashboardData = (builder: ActionReducerMapBuilder<IDashboardSlice>) => {
    builder
        // loop through derpatments employmentGroups
        .addCase(fetchWidgetDropdownItems.fulfilled, (state, action) => {
            const { widgetId, departments, employmentGroups } = action.payload;
            departmentAndEmploymentsGroups.forEach((value) => {
                const widgetState = state.Widgets?.find(({ id }) => id === widgetId);
                if (widgetState) {
                    const dynamicData = getDynamicData(widgetState[value] || [], departments, employmentGroups, value);
                    widgetState[value] = dynamicData;
                }
            });
            return state;
        });
};

export default switchDashboardData;
