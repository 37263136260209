import { Trans, useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { TemporarySubscription } from "swagger/messenger";
import { Custom500 } from "pages/500/500";
import { ModalFrame } from "@medhelp/ui";
import SearchModal from "components/SearchModal";
import SearchResult from "components/SearchResult";
import SecondaryButton from "components/SecondaryButton";
import ModalCommonFooter from "@medhelp/ui/Modal/ModalCommonFooter";

interface IProps {
    isError: boolean;
    userManagerName: string;
    absenceNotificationModalIsOpen: boolean;
    typeOfRegistration: string;
    notificationSent: boolean;
    searchResult: TemporarySubscription[] | null;
    selectedColleagues: TemporarySubscription[];
    clearSearch: boolean;
    setClearSearch: Dispatch<SetStateAction<boolean>>;
    handleOnSearchInputChange: (query: string) => void;
    renderSearchResults: (searchItem: TemporarySubscription) => JSX.Element;
    removeColleagueToNotify: (colleague: TemporarySubscription) => void;
    handleSendNotification: () => void;
    handleOnClick: () => void;
}

const AbsenceNotificationModalView = (props: IProps) => {
    const {
        isError,
        userManagerName,
        absenceNotificationModalIsOpen,
        typeOfRegistration,
        notificationSent,
        searchResult,
        selectedColleagues,
        clearSearch,
        setClearSearch,
        handleOnSearchInputChange,
        renderSearchResults,
        removeColleagueToNotify,
        handleSendNotification,
        handleOnClick,
    } = props;
    const { t } = useTranslation();

    if (isError) {
        return (
            <ModalFrame
                id="absence-notification-modal"
                open={absenceNotificationModalIsOpen}
                setOpen={handleOnClick}
                header={<h1>{t(`notifyColleagueModalHeading.${typeOfRegistration}`)}</h1>}
                content={<Custom500 errorText={t("notificationErrorMessage")} />}
                footer={<ModalCommonFooter tertiaryText={t("close")} tertiaryOnClick={handleOnClick} />}
            />
        );
    }

    return notificationSent ? (
        <ModalFrame
            id="absence-notification-modal"
            open={absenceNotificationModalIsOpen}
            setOpen={handleOnClick}
            header={<h1>{t(`notifyColleagueModalHeading.${typeOfRegistration}`)}</h1>}
            content={
                <div className="h-full">
                    <div>
                        <h2>{t("notificationSent")}</h2>
                    </div>
                    <div className="pt-4">
                        <p>{t("notificationConfirmationText")}</p>
                    </div>
                </div>
            }
            footer={<ModalCommonFooter tertiaryText={t("close")} tertiaryOnClick={handleOnClick} />}
        />
    ) : (
        <ModalFrame
            id="absence-notification-modal"
            open={absenceNotificationModalIsOpen}
            setOpen={handleOnClick}
            header={<h1>{t(`notifyColleagueModalHeading.${typeOfRegistration}`)}</h1>}
            content={
                <div className="flex justify-center w-full">
                    <div className="flex flex-col px-6 flex-grow relative">
                        <div className="flex flex-col pt-10 ">
                            <div className="px-0 mb-16">
                                <div className="flex">
                                    <h2>{t(`notifyColleagueHeading.${typeOfRegistration}`)}</h2>
                                </div>
                                <div className="flex flex-col justify-center pt-4">
                                    <div className="break-words">
                                        <p>
                                            {
                                                <Trans
                                                    i18nKey={`notifyColleagueText.${typeOfRegistration}`}
                                                    values={{
                                                        closestManager: userManagerName,
                                                    }}
                                                />
                                            }
                                        </p>
                                    </div>
                                    <div className="pt-10">
                                        <SearchModal
                                            id="searchAndResultSearchModal"
                                            preset="medium"
                                            searchHistory={[]}
                                            searchResult={searchResult}
                                            isLoading={false}
                                            onInputChange={handleOnSearchInputChange}
                                            placeholder={t("addColleagueToNotify")}
                                            searchResultItemComponent={renderSearchResults}
                                            className="w-full max-w-none"
                                            searchResultTitle={t("suggestedColleagues")}
                                            modalOverlay={false}
                                            clearSearch={clearSearch}
                                            setClearSearch={setClearSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="py-2 text-grey-600 border-b border-grey-200" key="selectedColleagues">
                                <span className="font-bold">{selectedColleagues.length} </span>
                                <span>
                                    {selectedColleagues.length === 1 ? t("colleagueSelected") : t("colleaguesSelected")}
                                </span>
                            </div>
                            {selectedColleagues.map((selectedSearchItem: any) => (
                                <div
                                    key={`${selectedSearchItem.id}-search-result-colleague`}
                                    className="w-full flex flex-col justify-between xs:flex-row xs:items-center px-6 border-b border-grey-200 py-5 z-60"
                                >
                                    <SearchResult
                                        id={selectedSearchItem.id ?? ""}
                                        icon="person"
                                        header={selectedSearchItem.name ?? ""}
                                        searchResultItems={[
                                            selectedSearchItem.name ?? "",
                                            `${t("department")}: ${selectedSearchItem.name}`,
                                        ]}
                                    />
                                    <SecondaryButton
                                        id="add-remove-colleague"
                                        text={t("delete")}
                                        className="w-23 px-3  mx-12 xs:mx-0 justify-center"
                                        onClick={() => removeColleagueToNotify(selectedSearchItem)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("close")}
                    tertiaryOnClick={handleOnClick}
                    primaryText={t("sendNotification")}
                    primaryOnClick={handleSendNotification}
                    primaryDisabled={selectedColleagues?.length === 0}
                />
            }
        />
    );
};

export default AbsenceNotificationModalView;
