import { ColumnText } from "@medhelp/ui";
import { twMerge } from "tailwind-merge";

export interface IProps {
    header: string;
    body: string;
    icon?: React.ReactElement;
    className?: string;
}
const DeviantAbsence = ({ icon, header, body, className }: IProps) => {
    return (
        <div
            className={twMerge(
                "flex justify-between items-center border-b-1 border-b-solid border-grey-200 py-[13px]",
                className,
            )}
        >
            <ColumnText header={header} body={body} boldHeader />
            {icon && <div className="px-7 py-5">{icon}</div>}
        </div>
    );
};

export default DeviantAbsence;
