import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { getAllCaseEvents } from "pages/FollowUp/redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { Error, RadioButtonFilter } from "@medhelp/ui";
import { CaseLogItem } from "pages/FollowUp/components/HealthCaseManagement";
import { Status } from "@medhelp/ui/LoadableComponent";
import CaseLogLoading from "./CaseLogLoading";

interface IProps {
    healthCase: IHealthCase;
}

const CaseLogContent = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const caseEvents = useAppSelector(getAllCaseEvents(healthCase));
    const [open, setOpen] = useState(false);
    const [selectedFilterType, setSelectedFilterType] = useState<
        "all" | "notification" | "activity" | "note" | "document" | "file" | "healthcase"
    >("all");

    const buttonValues = [
        {
            text: t("all"),
            onClick: () => setSelectedFilterType("all"),
            checked: selectedFilterType === "all",
            key: "filter-events-button-all",
        },
        {
            text: t("notifications"),
            onClick: () => setSelectedFilterType("notification"),
            checked: selectedFilterType === "notification",
            key: "filter-events-button-notifications",
        },
        {
            text: t("activities"),
            onClick: () => setSelectedFilterType("activity"),
            checked: selectedFilterType === "activity",
            key: "filter-events-button-activities",
        },
        {
            text: t("notes"),
            onClick: () => setSelectedFilterType("note"),
            checked: selectedFilterType === "note",
            key: "filter-events-button-notes",
        },
        {
            text: t("documents"),
            onClick: () => setSelectedFilterType("document"),
            checked: selectedFilterType === "document",
            key: "filter-events-button-documents",
        },
        {
            text: t("templates"),
            onClick: () => setSelectedFilterType("file"),
            checked: selectedFilterType === "file",
            key: "filter-events-button-templates",
        },
        {
            text: t("details"),
            onClick: () => setSelectedFilterType("healthcase"),
            checked: selectedFilterType === "healthcase",
            key: "filter-events-button-details",
        },
    ];

    const getFilteredCaseEvents = () => {
        if (selectedFilterType === "all") {
            return caseEvents;
        }
        return caseEvents?.filter((event) => event.type === selectedFilterType);
    };

    if (healthCase?.healthCaseSubStatus.notifications === Status.PENDING) {
        return <CaseLogLoading />;
    }

    if (healthCase?.healthCaseSubStatus.notifications === Status.ERROR) {
        <Error text={t("couldNotFetchContent")} />;
    }

    return (
        <>
            <div className="px-8">
                <RadioButtonFilter
                    filterText={t("showTypeOfActivity")}
                    buttonValues={buttonValues}
                    open={open}
                    setOpen={() => setOpen(!open)}
                />
            </div>
            {getFilteredCaseEvents().map((event) => (
                <CaseLogItem key={`case-log-item-${event.id}`} caseEvent={event} />
            ))}
        </>
    );
};

export default CaseLogContent;
