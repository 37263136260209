import { Card } from "@medhelp/ui";

const HistoryCasesLoading = () => {
    return (
        <Card className="animate-pulse">
            <div className="flex flex-col w-full">
                <div className={`w-full flex justify-between`}>
                    <div className="pl-0 pr-6">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-26"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-60 mt-2"></div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default HistoryCasesLoading;
