import { useHover } from "hooks";
import { IDegrees } from "utils/degrees";
import { TooltipVariedDegrees } from "@medhelp/ui";
import Icon from "components/Icon";

export type DegreeStatus = "None" | "Up" | "Down" | "Empty";

export interface IProps {
    body: JSX.Element;
    degrees?: IDegrees | null;
    currentDegree?: number;
    endDegree?: string;
    status?: DegreeStatus;
    endDate?: string | null;
}

export const DegreeBody = ({ text, status }: Pick<IProps, "status"> & { text: string }) => (
    <div className="flex gap-2">
        {status !== "Empty" ||
            (!status && (
                <div className="self-center mb-[2px]">
                    <Icon
                        icon={status === "Up" ? "arrowUp" : status === "None" ? "arrowRight" : "arrowDown"}
                        size={12}
                    />
                </div>
            ))}
        <p className="font-bold">{text}</p>
    </div>
);

const DegreeColumnText = ({ body, degrees, endDate }: IProps) => {
    const [hovered, eventHandlers] = useHover();

    return (
        <div {...eventHandlers}>
            {body}
            {hovered && degrees && <TooltipVariedDegrees degrees={degrees} endDate={endDate} />}
        </div>
    );
};

export default DegreeColumnText;
