export const ProductTypes = ["base", "plus", "plusAdvisory", "legacy"] as const;

export type ProductType = (typeof ProductTypes)[number];

export type AbsenceTemplateType = "Sick" | "CareOfChild";

export const AdvisoryServiceTypes = [
    "fullyAutomatic",
    "callNurse",
    "mandatoryCallback",
    "requestedMedicalAdvisory",
    "dynamicMedicalAdvisory",
] as const;

export type AdvisoryServiceType = (typeof AdvisoryServiceTypes)[number];

export const ConfigurationFieldTypes = [
    "callNurse",
    "showReportHealthyTime",
    "dynamicMedicalAdvisory",
    "temporarySubscriber",
    "mandatoryCallback",
    "careOfChildViaIvrAvailable",
    "nursePhoneNumber",
] as const;

export type ConfigurationFieldType = (typeof ConfigurationFieldTypes)[number];

export const AccessRightTypes = [
    "AbsenceView",
    "AbsenceAdministration",
    "AbsenceFollowup",
    "DepartmentAdministration",
    "AbsenceStatistics",
    "RehabProcessManagement",
] as const;

export type AccessRightType = (typeof AccessRightTypes)[number];

export const SickReportingFieldTypes = [
    "AbsenceCause",
    "Consent",
    "Confidential",
    "WorkRelated",
    "WorkplaceAccident",
    "Message",
    "Mobile",
    "RequestedMedicalAdvisory",
    "RiskMedicalAdvisoryRequest",
] as const;

export type SickReportingFieldType = (typeof SickReportingFieldTypes)[number];

export const CareOfChildFieldTypes = ["ChildBirthDate", "Message", "Mobile"] as const;

export type CareOfChildFieldType = (typeof CareOfChildFieldTypes)[number];

export const DenmarkTemplateFieldTypes = ["Paragraph56", "PregnancyRelated"] as const;

export type DenmarkTemplateFieldType = (typeof DenmarkTemplateFieldTypes)[number];

export type FieldType = SickReportingFieldType | CareOfChildFieldType | DenmarkTemplateFieldType;

export const HealthServiceTypes = [1, 2, 3, 4, 5] as const;

export type HealthServiceType = (typeof HealthServiceTypes)[number];

export const industryCodeTypes = [
    "A", // Jordbruk, skogsbruk och fiske
    "B", // Utvinning av mineral
    "C", // Tillverkning
    "D", // Försörjning av el, gas, värme och kyla
    "E", // Vattenförsörjning; avloppsrening, avfallshantering och sanering
    "F", // Byggverksamhet
    "G", // Handel, reparation av motorfordon och motorcyklar
    "H", // Transport och magasinering
    "I", // Hotell- och restaurangverksamhet
    "J", // Informations- och kommunikationsverksamhet
    "K", // Finans- och försäkringsverksamhet
    "L", // Fastighetsverksamhet
    "M", // Verksamhet inom juridik, ekonomi, vetenskap och teknik
    "N", // Uthyrning, fastighetsservice, resetjänster och andra stödtjänster
    "O", // Offentlig förvaltning och försvar; obligatorisk socialförsäkring
    "P", // Utbildning
    "Q", // Vård och omsorg; sociala tjänster
    "R", // Kultur, nöje och fritid
    "S", // Annan serviceverksamhet
    "T", // Förvärvsarbete i hushåll, hushållens produktion av diverse varor och tjänster för eget bruk
    "U", // Verksamhet vid internationella organisationer, utländska ambassader o.d.
] as const;

export type IndustryCodeType = (typeof industryCodeTypes)[number];

export const billingPostTypes = [
    "100", // Fast pris anställda innevarande månad
    "101", // Frånvaroanmälningar föregående månad
    "102", // VAB föregående månad
    "104", // Sms föregående månad
    "107", // Rehabkollen innevarande månad
    "111", // Utbildning
    "112", // Frakt/porto
    "113", // Resekostnader
    "115", // Fri sjukvårdsrådgivning innevarande månad
    "116", // Redigeringsärenden föregående månad
    "117", // Tilläggstjänst, sjukvårdsrådgivn, familjemedl
    "118", // Inreg. tidrapporteringssystem
    "122", // Rabatt fast pris anställda innevarande månad
    "123", // Teknik timmar
    "124", // Uppstartskostnad
    "125", // Fakturaavgift
    "126", // Övrigt
    "129", // Tillägg tjänst Briljant
    "132", // Tillval sjuksköterska
    "133", // Rehabkollen per ärende
    "136", // Rabatt Rehabkollen
    "137", // Fast pris anst. innev. månad inkl. Rehabkollen
    "141", // Frånvarokollen
    "142", // Anmälningar On Demand
    "143", // Rabatt Fri sjukvårdsrådgivning
    "144", // Fast pris
    "145", // Sjukanmälningar föregående månad
    "146", // Fast pris
] as const;

export type BillingPostType = (typeof billingPostTypes)[number];

export interface ICreateCustomerOrCompany {
    type: "companyToGroup" | "standAloneCompany";
    customerId: number | undefined;
    companyName: string;
    organizationNumber: string;
    industryCode: IndustryCodeType;
    regionId: string;
    activationDate: string;
    product: ProductType;
    advisoryService: AdvisoryServiceType;
    healthServices: { healthService: HealthServiceType; checked: boolean }[];
}

export const LangServiceTypes = ["sv", "da", "no", "en"] as const;

export type LangServiceType = (typeof LangServiceTypes)[number];
