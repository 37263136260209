import { Dispatch } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { IWidgetMap } from "../Widget";
import Icon from "components/Icon";
import { IDynamicData, WidgetDropdownTypeKeys } from "pages/dashboard/utils/dashboardTypes";

interface IRecursiveInput {
    data: IDynamicData;
    handleClick: (id: WidgetDropdownTypeKeys, referenceKey: string) => void;
    hasChildren: boolean;
    showChildren: boolean;
    setShowChildren: Dispatch<boolean>;
    translate: boolean;
    id: WidgetDropdownTypeKeys;
    currentWidget: IWidgetMap;
}

const RecursiveInput = ({
    data,
    handleClick,
    hasChildren,
    showChildren,
    setShowChildren,
    translate,
    id,
}: IRecursiveInput) => {
    const { t } = useTranslation();
    return (
        <li className="flex items-center py-1 pl-2">
            <button type="button" className="mr-2 px-1 py-2" onClick={() => setShowChildren(!showChildren)}>
                {hasChildren ? (
                    <div className="w-3">
                        <Icon icon={!showChildren ? "triangleRight" : "triangleDown"} size={12} />
                    </div>
                ) : (
                    <div className="w-3" />
                )}
            </button>
            <label
                htmlFor={data.referenceKey as string}
                className="flex items-center hover:bg-mhgrey-light cursor-pointer"
            >
                <input
                    id={data.referenceKey as string}
                    className="hidden"
                    type="checkbox"
                    checked={data.checked}
                    onChange={() => handleClick(id, data.referenceKey as string)}
                    disabled={!data.selectable}
                />
                {data.selectable && data.checked ? (
                    <Icon icon="checkBoxChecked" fill="#192D05" />
                ) : (
                    <Icon icon="checkBoxUncheched" stroke={data.selectable ? "#192D05" : "#BBBDAC"} />
                )}
                <p className={`pl-2 ${!data.selectable && "text-mhgrey-medium"}`}>
                    {translate ? t(_.camelCase(data.label)) : data.label}
                </p>
            </label>
        </li>
    );
};

export default RecursiveInput;
