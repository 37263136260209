import SecondaryButton from "components/SecondaryButton";
import { ILoginPageData, LoginUsing } from "pages/login/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BackButtonHeader from "../BackButtonHeader";

const MoreLoginOptions = ({ selectedMarket, loginUsing, returnUrl }: ILoginPageData) => {
    const { t } = useTranslation("login");
    const navigate = useNavigate();
    return (
        <div data-cy="more-login-options" className="flex flex-col mb-11">
            <BackButtonHeader heading={t("selectLoginType")} onClick={() => navigate(`/login/${selectedMarket}`)} />
            <SecondaryButton
                id="microsoftLoginButton"
                text="Microsoft"
                className="h-12 w-full justify-center"
                onClick={() =>
                    loginUsing({
                        loginUsing: LoginUsing.azure,
                        returnUrl,
                    })
                }
            />
            <SecondaryButton
                id="usernameAndPassButton"
                text={t("usernameAndPass")}
                className="h-12 mt-3 w-full justify-center"
                onClick={() => {
                    if (returnUrl) navigate(`/login/userAndPass?returnUrl=${returnUrl}`);
                    else navigate("/login/userAndPass");
                }}
            />
        </div>
    );
};

export default MoreLoginOptions;
