import {
    AccessRightsCustomerSupport,
    findAccessRightsBasedOnGivenRights,
    getEnumsFromAccessRightData,
    IAccessRightData,
    IAccessRightTypeEnum,
} from "domain/accessRights";
import { firstElementMatch } from "domain/ogranization/iterateChildren";
import { isUndefined } from "lodash";
import { get, has, includes } from "lodash/fp";
import { AccountAccessRightViewModel } from "swagger/usercontextservice";
import { SearchTypes } from "../utils";

const FollowUpRoutes = ["reports", "employee"] as const;

export type FollowUpPages = (typeof FollowUpRoutes)[number];
export const FollowUpAccessRights = [
    IAccessRightTypeEnum.customerSupport,
    IAccessRightTypeEnum.absenceFollowup,
    IAccessRightTypeEnum.absenceView,
] as const;

const AccessRightsAbsenceView = [{ value: IAccessRightTypeEnum.absenceView, top: false }];
const AccessRightsFollowUp = [{ value: IAccessRightTypeEnum.absenceFollowup, top: false }];
const AccessRightsSearchTypeStatistic = [
    { value: IAccessRightTypeEnum.absenceFollowup, top: true },
    { value: IAccessRightTypeEnum.absenceView, top: true },
];
const AccessRightsCompanyAdministration = [{ value: IAccessRightTypeEnum.customerCompanyAdministration, top: false }];

const AccessRightsRehabDepartmentManagement = [{ value: IAccessRightTypeEnum.rehabDepartmentManagement, top: false }];

const AccessRightsDepartmentAdministration = [{ value: IAccessRightTypeEnum.departmentAdministration, top: false }];

const FollowUpAndAbsenceView = [...AccessRightsAbsenceView, ...AccessRightsFollowUp];
export const AllFollowUpAccess = [...AccessRightsCustomerSupport, ...FollowUpAndAbsenceView] as const;

export const FollowUpReportAccessRights = [...AccessRightsCustomerSupport, ...AllFollowUpAccess] as const;

export const FollowUpEmployeeAccessRights = [...AccessRightsCustomerSupport, ...AccessRightsFollowUp] as const;

export const CompanyAdministrationAccessRights = [
    ...AccessRightsCustomerSupport,
    ...AccessRightsCompanyAdministration,
] as const;

export const RehabProcessManagementAccessRights = [
    ...AccessRightsCustomerSupport,
    ...AccessRightsRehabDepartmentManagement,
] as const;

export const DepartmentAdministrationAccessRights = [
    ...AccessRightsCustomerSupport,
    ...AccessRightsDepartmentAdministration,
] as const;

export const Test = Object.values(AllFollowUpAccess.values);

export type FollowUpAccessRightsType = (typeof FollowUpAccessRights)[number];

export const FollowUpReportSearchTypeAccessRights: {
    [key in SearchTypes]: IAccessRightData[];
} = {
    absencePeriod: FollowUpAndAbsenceView,
    ongoingAbsence: FollowUpAndAbsenceView,
    recurringAbsence: AccessRightsFollowUp,
    longtermAbsence: FollowUpAndAbsenceView,
    reimbursable: AccessRightsFollowUp,
    medicalCertificate: FollowUpAndAbsenceView,
    monthlyReport: AccessRightsSearchTypeStatistic,
    cases: AccessRightsRehabDepartmentManagement,
    activities: AccessRightsRehabDepartmentManagement,
};

export const checkIfOnFollowUpRoute = (value?: string): value is FollowUpPages => includes(value, FollowUpRoutes);

export const followUpIndexRoutes = (value: string | undefined): FollowUpPages | undefined => {
    const page = value === "search" ? "reports" : value;
    if (checkIfOnFollowUpRoute(page)) {
        return page;
    }
    return undefined;
};

export const getAccessRightForFollowUp = (page: FollowUpPages, type?: SearchTypes) => {
    if (page === "reports") {
        if (isUndefined(type)) {
            throw Error("No search type when trying to access report from follow up");
        }
        return getCorrectAccessRightBySearchType(type);
    }
    return FollowUpEmployeeAccessRights;
};
export const getCorrectAccessRightBySearchType = (type: SearchTypes) => FollowUpReportSearchTypeAccessRights[type];

function IdGuard<T>(value: T): value is T & { id: number } {
    const currentValue = value as T & { id: number };
    return has("id", currentValue);
}

export const getAccessRightBasedOnSetRights = (
    accessRightsFilter: (arg: (value: AccountAccessRightViewModel) => boolean) => AccountAccessRightViewModel[],
    accessRights: readonly IAccessRightData[],
) => accessRightsFilter((x) => includes(x.accessRightType, getEnumsFromAccessRightData(accessRights)));
export const getAccessRightBySearchType = <T>(
    accessRightsFilter: (arg: (value: AccountAccessRightViewModel) => boolean) => AccountAccessRightViewModel[],
    property: keyof AccountAccessRightViewModel,
    accessRights: readonly IAccessRightData[],
    data: T[],
) => {
    const value = getAccessRightBasedOnSetRights(accessRightsFilter, accessRights);
    return firstElementMatch((x) => {
        if (IdGuard(x)) {
            return includes(x.id, value.map(get(property)).map(Number));
        }
        return false;
    }, data);
};

export const compareSearchTypeByAccessRight = (
    accessRights: AccountAccessRightViewModel[],
    searchTypes: SearchTypes[],
) =>
    searchTypes.filter((x) =>
        findAccessRightsBasedOnGivenRights(FollowUpReportSearchTypeAccessRights[x], accessRights),
    );
