import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import { getAbsenceReportFieldValueByName } from "pages/Absence/pages/absencereporting/utils/absenceReports";
import { TFunction } from "i18next";
import { MedHelpAbsenceReportingDomainFieldTemplate } from "swagger/absencereportapi";
import { ContactInformation } from "swagger/usercontextservice";
import { phone } from "phone";

export const formatMobileNumberFe = (phoneNumber: string): string => {
    return phoneNumber.substring(0, 1) === "0" ? phoneNumber.substring(1) : phoneNumber;
};

export const formatMobileNumberBe = (countryPrefix: string, phoneNumber: string): string => {
    return countryPrefix === "+46" ? phoneNumber : phoneNumber;
};

export const getWorkMobileInitialValue = (userContactInfo: ContactInformation, userCountryCode: string): string => {
    if (userContactInfo && userCountryCode) {
        let initialValue = "";
        if (userContactInfo?.secondaryMobileCountryPrefix) initialValue = userContactInfo.secondaryMobileCountryPrefix;
        else if (userCountryCode === "SE" || userCountryCode === "DK")
            initialValue = userCountryCode === "SE" ? "+46" : "+45";
        initialValue = userContactInfo?.secondaryMobileNumber
            ? `${initialValue}-${formatMobileNumberFe(userContactInfo.secondaryMobileNumber)}`
            : initialValue;

        return initialValue;
    } else {
        return "";
    }
};

export const getPrivateMobileInitialValue = (userContactInfo: ContactInformation, userCountryCode: string): string => {
    let initialValue = "";
    if (userContactInfo?.mobileCountryPrefix) initialValue = userContactInfo.mobileCountryPrefix;
    else if (userCountryCode === "SE" || userCountryCode === "DK")
        initialValue = userCountryCode === "SE" ? "+46" : "+45";
    initialValue = userContactInfo?.mobileNumber
        ? `${initialValue}-${formatMobileNumberFe(userContactInfo.mobileNumber)}`
        : initialValue;
    return initialValue;
};

export const getPrivatePhoneInitialValue = (userContactInfo: ContactInformation, userCountryCode: string): string => {
    let initialValue = "";
    if (userContactInfo?.telephoneCountryPrefix) initialValue = userContactInfo.telephoneCountryPrefix;
    else if (userCountryCode === "SE" || userCountryCode === "DK")
        initialValue = userCountryCode === "SE" ? "+46" : "+45";
    initialValue = userContactInfo?.telephoneNumber
        ? `${initialValue}-${formatMobileNumberFe(userContactInfo.telephoneNumber)}`
        : initialValue;

    return initialValue;
};

export const formatSavedPhoneNumber = (number: string): string => {
    const phoneObject = phone(number);
    if (phoneObject.isValid) {
        const countryCode = phoneObject.countryCode;
        return `${countryCode}-${number.replace(countryCode, "")}`;
    } else return `+-${number}`;
};

export const getNumberWithoutDash = (mobileWithDash: string): string => {
    return mobileWithDash ? mobileWithDash.replace("-", "") : "";
};

export const getCountryPrefix = (value: string): string => {
    return value.split("-")?.[0] ?? "";
};

export const getPhoneNumber = (value: string): string => {
    return value.split("-")?.[1] ?? "";
};

export interface IErrorModel {
    errorMessage: string;
    isPrefixError?: boolean;
    isValid: boolean;
}

const createErrorModel = (errorMsg: string, isPrefix = false, isValid = false): IErrorModel => ({
    errorMessage: errorMsg,
    isPrefixError: isPrefix,
    isValid,
});

export const getMobileNumberErrorMsg = (nrToValidate: string, t: TFunction, prefix: string): IErrorModel => {
    const isZero = new RegExp(/^0.*$/).test(nrToValidate);
    if (isZero) return createErrorModel(t("noZero"));

    const validated = phone(prefix + nrToValidate);
    if (validated.isValid) return { errorMessage: "", isValid: true };
    else {
        let countryMessage = t("incorrectNumber");
        if (prefix === "+45") countryMessage = t("danish");
        else if (prefix === "+46") countryMessage = t("swedish");

        return createErrorModel(
            t("invalidMobileNumber", {
                country: countryMessage,
            }),
        );
    }
};

export const validateMobileNumber = (
    number: string,
    t: TFunction<"login", undefined>,
    allowEmptyNumber?: boolean,
): IErrorModel => {
    const prefix = getCountryPrefix(number);
    const mobileNumber = getPhoneNumber(number);
    if (!prefix || prefix === "+") return createErrorModel(t("youMustSelectCountryCode"), true);
    else if (!mobileNumber)
        return allowEmptyNumber ? { errorMessage: "", isValid: true } : createErrorModel(t("noEmptyNr"));
    else return getMobileNumberErrorMsg(mobileNumber, t, prefix);
};

export const isValidMobileNumber = (number: string): boolean => {
    const prefix = getCountryPrefix(number);
    const mobileNumber = getPhoneNumber(number);
    const validNumber = phone(prefix + mobileNumber).isValid;
    const valid = !!prefix && prefix !== "+" && !!mobileNumber && validNumber;
    return valid;
};

export const isValidMobileField = (
    currentFields: MedHelpAbsenceReportingDomainFieldTemplate[] | null,
    absenceReportFields: IFieldTemplateExtended[] | null,
    userContactInfo: ContactInformation | undefined,
    userCountryCode: string,
    t: TFunction<"login", undefined>,
): boolean => {
    let isValidPhone = true;
    const mobileField = currentFields?.find((c) => c.name === "Mobile");
    if (mobileField) {
        const fieldValue = getAbsenceReportFieldValueByName(mobileField.name!, absenceReportFields ?? []);

        const mobileFieldValue = fieldValue
            ? fieldValue
            : getPrivateMobileInitialValue(userContactInfo!, userCountryCode);

        const errorMessage = validateMobileNumber(mobileFieldValue, t).errorMessage;

        if (!mobileFieldValue || !!errorMessage) isValidPhone = false;
    }
    return isValidPhone;
};

export enum ContactInfoPropToUpdate {
    workNumber,
    privateMobile,
    privatePhone,
}

export const updateContactInfoModelProps = (
    phoneNumber: string,
    clonedContactInfo: ContactInformation,
    propToUpdate?: ContactInfoPropToUpdate,
): ContactInformation => {
    const model = clonedContactInfo;
    switch (propToUpdate) {
        case ContactInfoPropToUpdate.workNumber:
            model.secondaryMobileCountryPrefix = getCountryPrefix(phoneNumber);
            model.secondaryMobileNumber = formatMobileNumberBe(
                model.secondaryMobileCountryPrefix,
                getPhoneNumber(phoneNumber),
            );
            model.secondaryMobile = getNumberWithoutDash(phoneNumber);
            break;

        case ContactInfoPropToUpdate.privatePhone:
            model.telephoneCountryPrefix = getCountryPrefix(phoneNumber);
            model.telephoneNumber = formatMobileNumberBe(model.telephoneCountryPrefix, getPhoneNumber(phoneNumber));
            model.telephone = getNumberWithoutDash(phoneNumber);
            break;

        default:
            model.mobileCountryPrefix = getCountryPrefix(phoneNumber);
            model.mobileNumber = formatMobileNumberBe(model.mobileCountryPrefix, getPhoneNumber(phoneNumber));
            model.mobile = getNumberWithoutDash(phoneNumber);
            break;
    }
    return model;
};
