import { useMemo } from "react";
import CompaniesDropdown from "../CompaniesDropdown";
import DepartmentsDropdown from "../DepartmentsDropdown";
import SearchTypesDropdown from "../SearchTypesDropdown";
import MonthsDropdown from "../MonthDropdown";
import YearsDropdown from "../YearsDropdown";

interface IPropsView {
    companies: boolean;
    departments: boolean;
    years: boolean;
    months: boolean;
}
const SearchDropdowns = ({ companies, departments, years, months }: IPropsView) => {
    const Content = useMemo(() => {
        return (
            <>
                <SearchTypesDropdown />
                {companies && <CompaniesDropdown />}
                {departments && <DepartmentsDropdown />}
                {years && <YearsDropdown />}
                {months && <MonthsDropdown />}
            </>
        );
    }, [companies, departments, months, years]);
    return (
        <div className="flex flex-wrap flex-col items-center gap-6 lg:items-start lg:gap-1 lg:flex-row">{Content}</div>
    );
};
export default SearchDropdowns;
