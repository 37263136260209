import { IWidget, IDynamicData, WidgetDropdownTypeKeys } from "../utils/dashboardTypes";
import {
    fetchNews,
    fetchHCMTodo,
    deleteWidget,
    createWidget,
    fetchHealthCases,
    fetchHCMTemplates,
    updateWidgetOrder,
    fetchOngoingRehab,
    fetchOngoingAbsence,
    fetchAbsenceSummary,
    fetchAbsencesPerCause,
    fetchHCMStatusOverview,
    fetchWorkRelatedAbsence,
    fetchLastActivatedRehab,
    IUpdateAllDropDownItems,
    fetchLastReportedAbsence,
    fetchAbsenceSummaryDetails,
    fetchAbsencePercentPerMonth,
    fetchEstimatedUpcomingAbsence,
    fetchAbsencePercentLast6Months,
    fetchAbsenceForecastCurrentMonth,
    fetchRecurringAbsenceLast12Months,
} from "./dashboardActions";
import {
    MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse,
    MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse,
} from "swagger/absencefollowup";
import {
    MedHelpHCMDomainTemplate,
    MedHelpHCMWebApiResponseSearchHealthCaseResponse,
} from "swagger/healthCaseManagement";
import { Status } from "./dashboardStateTypes";
import { TreeViewNode } from "swagger/employeeattendance";
import { setChecked } from "../utils/dashboardSliceUtils";
import { IUpdateDropDownItems } from "./dashboardActions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initDashboard, switchDashboardData } from "./builders";
import { recursiveMapCheckedState } from "../utils/dashboardSliceUtils";
import { INews, medhelpNews } from "../components/NewsWidget/medhelpNews";

export interface IDashboardSlice {
    Widgets: IWidget[] | [];
    DashboardInitStatus: Status;
    CreateWidgetStatus: Status;
    CompanyGroupForCompany: IDynamicData[] | null;
    OrganizationTreeView: TreeViewNode[] | null;
    HCMTemplates: {
        data: MedHelpHCMDomainTemplate[];
        status: Status;
    };
    AbsenceCauses: string[];
    CaseSummary: {
        data: MedHelpHCMWebApiResponseSearchHealthCaseResponse[];
        status: Status;
    };
    News: {
        data: INews[];
        status: Status;
    };
    AbsenceSummary: {
        data: MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse | null;
        status: Status;
    };
    AbsenceSummaryDetails: {
        data: MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse[];
        status: Status;
    };
    DragAndDrop: {
        isDragging: boolean;
        droppedOnId: string;
    };
}
export interface IReturnValueFromWidgetTransformData {
    data: {
        filter: any;
        id: string;
    };
}

export enum TransformWidgetFilter {
    absencePeriod = "absencePeriods",
    departmentIds = "departments",
    employmentGroupIdentifiers = "employmentGroups",
    gender = "genders",
    absenceType = "absenceTypes",
    companyIds = "companies",
    timeInterval = "timeInterval",
    absenceCauses = "absenceCauses",
    hcmTypes = "hcmTypes",
}

export const isTransformWidgetFilter = (value: string): value is keyof typeof TransformWidgetFilter =>
    Object.keys(TransformWidgetFilter).includes(value);

interface IUpdateWidgetHeading {
    id: string;
    heading: string;
}

export const initialWidgetFilterState = {
    companies: null,
    departments: null,
    employmentGroups: null,
    absenceCauses: null,
    hcmTypes: [],
    genders: [
        {
            id: 1,
            referenceKey: "male",
            label: "male",
            checked: false,
            selectable: true,
        },
        {
            id: 2,
            referenceKey: "female",
            label: "female",
            checked: false,
            selectable: true,
        },
    ],
    absenceTypes: [
        {
            id: "sick",
            referenceKey: "sick",
            label: "absenceTypeSick",
            checked: false,
            selectable: true,
        },
        {
            id: "careOfChild",
            referenceKey: "careOfChild",
            label: "absenceTypeCareOfChild",
            checked: false,
            selectable: true,
        },
    ],
    absencePeriods: [
        {
            id: "long",
            referenceKey: "long",
            label: "long",
            checked: false,
            selectable: true,
        },
        {
            id: "short",
            referenceKey: "short",
            label: "short",
            checked: false,
            selectable: true,
        },
    ],
    timeInterval: [
        {
            id: "threeMonths",
            referenceKey: "threeMonths",
            label: "threeMonths",
            checked: false,
            selectable: true,
        },
        {
            id: "sixMonths",
            referenceKey: "sixMonths",
            label: "sixMonths",
            checked: false,
            selectable: true,
        },
        {
            id: "twelveMonths",
            referenceKey: "twelveMonths",
            label: "twelveMonths",
            checked: false,
            selectable: true,
        },
    ],
};

/**
 * Type guard to check if type `WidgetDropdownProperties`
 */
const checkIfWidgetDropdownProperties = (value: string): value is WidgetDropdownTypeKeys =>
    value === "companies" ||
    value === "departments" ||
    value === "employmentGroups" ||
    value === "genders" ||
    value === "absenceTypes" ||
    value === "absencePeriods" ||
    value === "timeInterval" ||
    value === "absenceCauses" ||
    value === "hcmTypes";

export const initialDashboardState: IDashboardSlice = {
    Widgets: [],
    DashboardInitStatus: Status.PENDING,
    CreateWidgetStatus: Status.OK,
    CompanyGroupForCompany: null,
    OrganizationTreeView: null,
    HCMTemplates: {
        data: [],
        status: Status.PENDING,
    },
    AbsenceSummary: {
        data: null,
        status: Status.PENDING,
    },
    AbsenceSummaryDetails: {
        data: [],
        status: Status.PENDING,
    },
    AbsenceCauses: [],
    CaseSummary: {
        data: [],
        status: Status.PENDING,
    },
    News: {
        data: [],
        status: Status.PENDING,
    },
    DragAndDrop: {
        isDragging: false,
        droppedOnId: "",
    },
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialDashboardState,
    reducers: {
        reset(state) {
            Object.assign(state, initialDashboardState);
        },
        resetWidgetDropdown(state, action: PayloadAction<IUpdateDropDownItems>) {
            const { widgetId } = action.payload;
            const widgetDropdown = state?.Widgets?.find(({ id }) => id === widgetId);

            if (widgetDropdown) {
                Object.keys(widgetDropdown).forEach((value) => {
                    if (checkIfWidgetDropdownProperties(value)) {
                        if (value === "absenceCauses") {
                            // @ts-ignore fix for later
                            widgetDropdown[value] = widgetDropdown[value].map((x) => ({ ...x, checked: false }));
                        } else if (value !== "companies") {
                            // @ts-ignore fix for later
                            widgetDropdown[value] = initialWidgetFilterState[value];
                        }
                    }
                });
            }
        },
        updateCheckedDropdownItems(state, action: PayloadAction<IUpdateDropDownItems>) {
            const { widgetId } = action.payload;
            const dropdownState = state?.Widgets?.find(({ id }) => id === widgetId)?.[action.payload.id];

            const itemToUpdate = dropdownState?.find((i) => i.referenceKey === action.payload.referenceKey);
            if (dropdownState) {
                if (action.payload.id === "departments") {
                    recursiveMapCheckedState(dropdownState as IDynamicData[], action.payload.referenceKey as string);
                } else {
                    itemToUpdate && (itemToUpdate.checked = !itemToUpdate.checked);
                }
            }
        },
        updateRadioSelectedDropdownItem(state, action: PayloadAction<IUpdateDropDownItems>) {
            const { widgetId } = action.payload;
            const dropdownState = state?.Widgets?.find(({ id }) => id === widgetId)?.[action.payload.id];

            const itemToUpdate = dropdownState?.find((i) => i.referenceKey === action.payload.referenceKey);
            if (dropdownState) {
                itemToUpdate && (itemToUpdate.checked = true);
                dropdownState.forEach((item) => {
                    if (item.referenceKey !== action.payload.referenceKey) {
                        item.checked = false;
                    }
                });
            }
        },
        updateAllCheckedDropdownItems(state, action: PayloadAction<IUpdateAllDropDownItems>) {
            const { widgetId } = action.payload;
            const dropdownState = state?.Widgets?.find(({ id }) => id === widgetId)?.[action.payload.id];
            if (dropdownState) {
                setChecked(dropdownState, action.payload.allSelected);
            }
        },
        updateAllDropdownCompanies(state) {
            if (state.CompanyGroupForCompany) {
                const checkCompanies = state.CompanyGroupForCompany.map((x: IDynamicData) => {
                    x.checked = !x.checked;
                    return x;
                });
                state.CompanyGroupForCompany = checkCompanies;
                state.Widgets?.forEach((widget) => {
                    if (widget.type !== "absence-forecast-current-month") {
                        widget.companies ? widget.companies.map((x: IDynamicData) => (x.checked = !x.checked)) : null;
                    }
                });
            }
        },
        updateCompanyGroupForCompanySelection(state, action: PayloadAction<number>) {
            if (state.CompanyGroupForCompany) {
                const checkCompanies = state.CompanyGroupForCompany.map((x: IDynamicData) => {
                    if (x.id === action.payload) {
                        x.checked = !x.checked;
                    }
                    return x;
                });
                state.CompanyGroupForCompany = checkCompanies;
                state.Widgets?.forEach((widget) => {
                    const itemToUpdate =
                        widget.companies && widget.companies.find((x: IDynamicData) => x.id === action.payload);
                    itemToUpdate ? (itemToUpdate.checked = !itemToUpdate?.checked) : null;
                });
            }
        },
        updateWidgetHeading(state, action: PayloadAction<IUpdateWidgetHeading>) {
            const currentWidgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
            currentWidgetState && (currentWidgetState.customHeading = action.payload.heading);
        },
        setIsDragging(state, action: PayloadAction<boolean>) {
            state.DragAndDrop.isDragging = action.payload;
        },
        setDroppedOnId(state, action: PayloadAction<string>) {
            state.DragAndDrop.droppedOnId = action.payload;
        },
        resetSummaryDetails(state) {
            state.AbsenceSummaryDetails.data = [];
        },
        resetCaseSummaryDetails(state) {
            state.CaseSummary.data = [];
        },
        resetCaseSummary(state) {
            state.CaseSummary.data = [];
            state.CaseSummary.status = Status.PENDING;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOngoingAbsence.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchOngoingAbsence.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchOngoingAbsence.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchLastReportedAbsence.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchLastReportedAbsence.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchLastReportedAbsence.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchAbsencePercentLast6Months.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchAbsencePercentLast6Months.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchAbsencePercentLast6Months.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchAbsencePercentPerMonth.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchAbsencePercentPerMonth.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchAbsencePercentPerMonth.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchAbsencesPerCause.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchAbsencesPerCause.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchAbsencesPerCause.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchWorkRelatedAbsence.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchWorkRelatedAbsence.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchWorkRelatedAbsence.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchEstimatedUpcomingAbsence.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchEstimatedUpcomingAbsence.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchEstimatedUpcomingAbsence.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchRecurringAbsenceLast12Months.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchRecurringAbsenceLast12Months.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchRecurringAbsenceLast12Months.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchOngoingRehab.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchOngoingRehab.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchOngoingRehab.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchLastActivatedRehab.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchLastActivatedRehab.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchLastActivatedRehab.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchHCMStatusOverview.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchHCMStatusOverview.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchHCMStatusOverview.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchHCMTodo.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchHCMTodo.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchHCMTodo.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchAbsenceForecastCurrentMonth.fulfilled, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.payload.id);
                widgetState ? (widgetState.chartData = action.payload.data) : null;
                widgetState ? (widgetState.status = Status.OK) : null;
            })
            .addCase(fetchAbsenceForecastCurrentMonth.pending, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.PENDING) : null;
            })
            .addCase(fetchAbsenceForecastCurrentMonth.rejected, (state, action) => {
                const widgetState = state.Widgets?.find(({ id }) => id === action.meta.arg.id);
                widgetState ? (widgetState.status = Status.ERROR) : null;
            })
            .addCase(fetchAbsenceSummary.fulfilled, (state, action) => {
                state.AbsenceSummary.data = action.payload;
                state.AbsenceSummary.status = Status.OK;
            })
            .addCase(fetchAbsenceSummary.pending, (state) => {
                state.AbsenceSummary.status = Status.PENDING;
            })
            .addCase(fetchAbsenceSummary.rejected, (state) => {
                state.AbsenceSummary.status = Status.ERROR;
            })
            .addCase(fetchAbsenceSummaryDetails.fulfilled, (state, action) => {
                state.AbsenceSummaryDetails.data = action.payload;
                state.AbsenceSummaryDetails.status = Status.OK;
            })
            .addCase(fetchAbsenceSummaryDetails.pending, (state) => {
                state.AbsenceSummaryDetails.status = Status.PENDING;
            })
            .addCase(fetchAbsenceSummaryDetails.rejected, (state) => {
                state.AbsenceSummaryDetails.status = Status.ERROR;
            })
            .addCase(fetchHealthCases.fulfilled, (state, action) => {
                if (action.payload) {
                    state.CaseSummary.data = action.payload;
                    state.CaseSummary.status = Status.OK;
                }
            })
            .addCase(fetchHealthCases.pending, (state) => {
                state.CaseSummary.status = Status.PENDING;
            })
            .addCase(fetchHealthCases.rejected, (state) => {
                state.CaseSummary.status = Status.ERROR;
            })
            .addCase(fetchHCMTemplates.fulfilled, (state, action) => {
                if (action.payload) {
                    state.HCMTemplates.data = action.payload;
                    state.HCMTemplates.status = Status.OK;
                }
            })
            .addCase(fetchHCMTemplates.pending, (state) => {
                state.HCMTemplates.status = Status.PENDING;
            })
            .addCase(fetchHCMTemplates.rejected, (state) => {
                state.HCMTemplates.status = Status.ERROR;
            })
            .addCase(fetchNews.fulfilled, (state, action) => {
                state.News.data = action.payload;
                state.News.status = Status.OK;
            })
            .addCase(fetchNews.pending, (state) => {
                state.News.status = Status.PENDING;
            })
            .addCase(fetchNews.rejected, (state) => {
                // return local data if request fails
                state.News.data = medhelpNews;
                state.News.status = Status.OK;
            })
            .addCase(deleteWidget.fulfilled, (state, action) => {
                if (action.payload) state.Widgets = action.payload;
            })
            .addCase(createWidget.fulfilled, (state, action) => {
                state.CreateWidgetStatus = Status.OK;
                state.Widgets = action.payload;
            })
            .addCase(createWidget.pending, (state) => {
                state.CreateWidgetStatus = Status.PENDING;
            })
            .addCase(updateWidgetOrder.fulfilled, (state, action) => {
                state.Widgets = action.payload;
            });

        switchDashboardData(builder);
        initDashboard(builder);
    },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
