import PrimaryButton from "components/PrimaryButton";
import ReactModal from "react-modal";

interface IProps {
    isOpen: boolean;
    setOpen: () => void;
    text: string | null;
    header: string;
    context: string;
}
const ExcelUploaded = ({ isOpen, setOpen, text, header, context }: IProps) => {
    return (
        <ReactModal
            id="absencePercentage-info-modal"
            isOpen={isOpen}
            onRequestClose={setOpen}
            className="bg-white h-auto top-52 absolute xl:ml-65"
        >
            <div className={`${context} relative p-8`}>
                {text && (
                    <div className="flex flex-col">
                        <h2>{header}</h2>
                        <p className="max-w-72">{text}</p>
                        <div className="p-2">
                            <PrimaryButton onClick={setOpen} text="Ok" id="cool" />
                        </div>
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

export default ExcelUploaded;
