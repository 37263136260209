import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { useBreakpoints } from "hooks";
import Icon, { Icons } from "components/Icon";
import { useTranslation } from "react-i18next";
import PrimaryButton from "components/PrimaryButton";
import { WidgetType } from "pages/dashboard/utils/dashboardTypes";
import { getWidgets } from "pages/dashboard/redux/dashboardSelectors";

export interface IAddWidgetCard {
    id: WidgetType;
    heading: string;
    widgetInfo: string;
    description: string;
    icon: Icons;
    handleAddWidget: (id: WidgetType) => void;
}
const AddWidgetCard = ({ id, heading, widgetInfo, description, icon, handleAddWidget }: IAddWidgetCard) => {
    const { t } = useTranslation("dashboard");
    const { isSmallscreen } = useBreakpoints();
    const widgets = useAppSelector(getWidgets) || [];
    const [openWidgetDescription, setOpenWidgetDescription] = useState(false);

    const disabled = widgets && widgets?.length > 23;

    return isSmallscreen ? (
        <div className="flex flex-col items-center border-b-1 py-2 px-6 text-primaryText">
            <div className="flex mt-6">
                <div>
                    <Icon icon={icon} size={78} />
                </div>
                <div className="ml-4">
                    <p className="font-bold">{heading}</p>
                    <p className="mb-6">{widgetInfo}</p>
                </div>
            </div>
            <div className="w-full mb-5">
                <button
                    className="flex flex-row items-center focus:ring-0"
                    onClick={() => setOpenWidgetDescription(!openWidgetDescription)}
                >
                    <Icon icon={openWidgetDescription ? "triangleDown" : "triangleRight"} size={12} />
                    <p className="text-sm ml-2">{openWidgetDescription ? t("showLess") : t("showMore")}</p>
                </button>
            </div>
            {openWidgetDescription && (
                <div>
                    <p className="mb-5">{description}</p>
                </div>
            )}
            <div className="w-full">
                <PrimaryButton
                    id={`add-widget-button-${id}`}
                    text={t("add")}
                    className="w-full mb-5 flex justify-center"
                    onClick={() => handleAddWidget(id)}
                    disabled={disabled}
                />
            </div>
        </div>
    ) : (
        <div className="flex items-center justify-between border-1 py-2 px-12 text-primaryText">
            <div className="flex">
                <div className="mx-4">
                    <Icon icon={icon} size={200}></Icon>
                </div>
                <div className="mx-4 py-3 max-w-2xl">
                    <p className="font-bold mb-2">{heading}</p>
                    <p className="mb-6">{widgetInfo}</p>
                    <p>{description}</p>
                </div>
            </div>
            <div className="self-start mt-10 mx-4">
                <PrimaryButton
                    id={`add-widget-button-${id}`}
                    text={t("add")}
                    onClick={() => handleAddWidget(id)}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
export default AddWidgetCard;
