import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchCaseNotifications as fetchCaseNotificationsAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchCaseNotifications = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchCaseNotificationsAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                currentHealthCase.healthCaseNotifications = action.payload.data;
                currentHealthCase.healthCaseSubStatus.notifications = Status.OK;
            }
        })
        .addCase(fetchCaseNotificationsAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.notifications = Status.PENDING;
        })
        .addCase(fetchCaseNotificationsAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.notifications = Status.ERROR;
        });

export default fetchCaseNotifications;
