import { RootState } from "store";
import { fileImportApi } from "./fileImportApi";
import { getFileImportCustomerId } from "pages/Administration/redux/administrationSelectors";

// This enhances existing auto generated endpoint
export const enhancedFileImportApi = fileImportApi.enhanceEndpoints({
    endpoints: {
        postConfigurationAutoimport: {
            onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
                const state = getState() as RootState;
                const customerId = getFileImportCustomerId(state);

                // optimistic update (update autoImportEnabled property in configuration cache if fullfilled, else undo)
                if (customerId) {
                    const update = dispatch(
                        fileImportApi.util.updateQueryData(
                            "getConfigurationCustomersByCustomerId",
                            { customerId },
                            (draft) => {
                                console.log("draft");
                                draft.autoImportEnabled = !draft.autoImportEnabled;
                            },
                        ),
                    );
                    queryFulfilled.catch(update.undo);
                }
            },
        },
    },
});

export const { usePostConfigurationAutoimportMutation } = enhancedFileImportApi;
