import Icon from "components/Icon";
import { UseFormRegisterReturn } from "react-hook-form";

interface IServiceToggle {
    register: UseFormRegisterReturn<string>;
    checked: boolean;
}

const ServiceToggle = ({ checked, register }: IServiceToggle) => (
    <label className="hover:cursor-pointer">
        <input className="hidden" {...register} type="checkbox" />
        <Icon icon={checked ? "switchToggled" : "switchUntoggled"} size={48} />
    </label>
);

export default ServiceToggle;
