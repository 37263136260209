import { createSelector } from "@reduxjs/toolkit";
import { getSelectedName } from "components/PowerbarSearch";
import { RootState } from "./index";

export const getPowerbarSearch = (state: RootState) => state.search.powerbarSearchResult;
export const getHistorySearch = (state: RootState) => state.search.historySearches;
export const getSearchSlice = (state: RootState) => state.search;

export const getActivePowerbarSearch = (state: RootState) => state.search.activePowerbarSearch;

export const getActiveSearchName = createSelector(getActivePowerbarSearch, (activeSearch) => {
    if (!activeSearch) return null;
    return getSelectedName(activeSearch);
});
export const getActiveSearchCompanyId = createSelector(getActivePowerbarSearch, (activeSearch) => {
    if (!activeSearch?.companyId) return undefined;
    return activeSearch?.companyId;
});
export const getActiveSearchCustomerId = createSelector(getActivePowerbarSearch, (activeSearch) => {
    if (!activeSearch?.customerId) return undefined;
    return activeSearch?.customerId;
});
export const getSearchResults = createSelector(
    getPowerbarSearch,
    getHistorySearch,
    (powerbarSearchResult, historySearches) =>
        powerbarSearchResult.length > 0 ? powerbarSearchResult : historySearches,
);
