const CaseLogLoading = () => {
    return (
        <div className="flex justify-between items-start border-t border-grey-300 py-5 px-8 h-20 animate-pulse">
            <div className="flex">
                <div className="h-12 bg-gray-200 rounded-full dark:bg-gray-700 mr-8 w-12"></div>
                <div>
                    <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-56 mt-4"></div>
                </div>
            </div>
            <div className="flex h-14 py-4">
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-44"></div>
            </div>
        </div>
    );
};

export default CaseLogLoading;
