import { Dropdown, DropdownPreset } from "@medhelp/ui";
import { SortingTypes } from "../../utils";
import { ShowBasedOn, ShowDepartments, ISearchFieldValues } from "../../types";

interface IShareProps {
    preset?: DropdownPreset;
    className?: string;
    text?: JSX.Element;
}
export interface IData {
    name: ShowBasedOn | SortingTypes | ShowDepartments;
    text: string;
    field: ISearchFieldValues;
}
export interface IProps extends IShareProps {
    item: (name: string) => JSX.Element;
    customHeader: (name: string) => JSX.Element;
    data: IData[];
    type: ISearchFieldValues;
    setOpen?: (value: boolean) => void;
    open?: boolean;
}
interface IPropsView extends IShareProps {
    header: JSX.Element;

    items: JSX.Element;
    open: boolean;
    setOpen: (value: boolean) => void;
    isCustomOpen: boolean;
}

const DropdownFilter = ({
    header,
    items,
    open,
    setOpen,
    text,
    preset = "minimal",
    className,
    isCustomOpen,
}: IPropsView) => (
    <div className={`flex gap-2 ${className}`}>
        {text}
        <Dropdown
            preset={preset}
            heading={header}
            items={items}
            open={open}
            setOpen={(event) => {
                if (isCustomOpen) return;
                event.stopPropagation();
                event.preventDefault();
                setOpen(!open);
            }}
        />
    </div>
);

export default DropdownFilter;
