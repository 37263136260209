import { FieldError, UseFormRegister } from "react-hook-form";
import { IPreloadedValues } from "../../ContactSupport";

export interface IProps {
    label: string;
    value: keyof IPreloadedValues;
    prefix?: "prefix";
    required?: boolean;
    textarea?: boolean;
    register: UseFormRegister<IPreloadedValues>;
    caseNumber?: FieldError;
}

const FormTextInput = ({ label, value, prefix, required, textarea, register, caseNumber }: IProps) => (
    <div className="flex flex-col font-bold mb-10">
        <label className="mb-1.5">
            {label} {required ? "*" : ""}
        </label>

        {caseNumber && value === "caseNumber" && <p className="text-mhred-alert1 pb-4">{caseNumber!.message}</p>}
        <div className="flex w-full">
            {prefix && (
                <input
                    className="w-18 border border-r-0 border-mhdarkgreen-original bg-transparent py-2 px-4"
                    {...register(prefix)}
                />
            )}
            {textarea ? (
                <textarea
                    className={`w-full border border-mhdarkgreen-original bg-transparent py-3 px-4 resize-none h-44`}
                    {...register(value, {
                        required: required,
                    })}
                />
            ) : (
                <input
                    className={`w-full border border-mhdarkgreen-original bg-transparent py-3 px-4 ${
                        caseNumber && value === "caseNumber" ? "border-mhred-alert1 border" : ""
                    }`}
                    {...register(value, { required: required })}
                />
            )}
        </div>
    </div>
);

export default FormTextInput;
