import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServices } from "api/services";
import { WorkOrderPatch, WorkOrderReservationViewModel, WorkOrderStatus } from "swagger/medicaladvisory";

interface IWorkOrderGet {
    workOrderId: number;
    agentSessionId: number;
}

export const getWorkOrder = createAsyncThunk("getWorkOrder", async (workOrderGetParams: IWorkOrderGet, thunkApi) => {
    const { data, status } = await getServices().clients.medicaladvisory.apiMedicalAdvisoryWorkOrderDeliveryIdGet(
        workOrderGetParams.workOrderId,
        workOrderGetParams.agentSessionId,
    );
    if (status === 200) {
        return data as WorkOrderReservationViewModel;
    }
    return thunkApi.rejectWithValue("rejected");
});

export const saveWorkOrder = createAsyncThunk("saveWorkOrder", async (workOrderGetParams: IWorkOrderGet, thunkApi) => {
    await getServices().clients.medicaladvisory.apiMedicalAdvisoryMedicalAdvisoryWorkOrderWrapUpAgentSessionIdGet(
        workOrderGetParams.agentSessionId,
    );
    const workOrderPatch: WorkOrderPatch = {
        // ProcessedOK
        workOrderStatus: WorkOrderStatus.NUMBER_2,
        notes: "Answered",
    };
    const { data, status } = await getServices().clients.medicaladvisory.apiMedicalAdvisoryWorkorderidPatch(
        workOrderGetParams.workOrderId,
        workOrderPatch,
    );

    if (status === 200) {
        return data;
    }

    return thunkApi.rejectWithValue("rejected");
});
