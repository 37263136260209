import { useState } from "react";
import Icon from "components/Icon";
import ErrorRow, { ErrorRowProps } from "./ErrorRow";

interface ErrorDetailsProps {
    title: string;
    rows: ErrorRowProps[];
    alertType: "warning" | "error";
}

const ErrorDetails = ({ title, rows, alertType }: ErrorDetailsProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="flex flex-col gap-2">
            <div
                onClick={() => setIsOpen(!isOpen)}
                className="flex justify-between font-bold hover:bg-[#F9FAF9] hover:cursor-pointer p-2"
            >
                <div className="flex gap-4">
                    <Icon icon="warningCircle" size={24} color={alertType === "error" ? "#FF5637" : "#D6B710"} />
                    <div>{title}</div>
                </div>
                <div className="flex gap-4 items-center">
                    <div>{rows.length}</div>
                    <div>
                        <Icon icon={isOpen ? "caretUp" : "caretDown"} size={12} />
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="md:px-10">
                    <div className="flex flex-col gap-3 bg-[#F9FAF9]">
                        {rows?.map(({ text, line }, i) => <ErrorRow key={text + i} text={text} line={line} />)}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ErrorDetails;
