import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { ICaseEvent } from "pages/FollowUp/redux/followUpSelectors";
import { isValidJson } from "pages/FollowUp/utils/case";
import { getDateTimeDisplayValue } from "utils/date";
import { CaseEventIcon } from "./CaseLogItem.utils";
import { isEmpty } from "lodash/fp";
import Icon from "components/Icon";

interface IProps {
    caseEvent: ICaseEvent;
}

const CaseLogItem = (props: IProps) => {
    const { caseEvent } = props;
    const { t } = useTranslation("followup");
    const [open, setOpen] = useState(false);

    const caseEventDescription = isValidJson(caseEvent.description ?? "")
        ? JSON.parse(caseEvent.description ?? "")
        : caseEvent.description;

    const getDescription = () => {
        if (caseEvent.operation === "expired") {
            return t("noActionWasTaken");
        } else if (caseEvent.operation === "declined") {
            return `${t("reason")}: ${t(caseEventDescription.newValue)}`;
        } else {
            return "";
        }
    };

    return (
        <div className="flex justify-between items-start border-t border-grey-300 py-3 px-5 sm:px-8">
            <div className="flex">
                <div className="mr-3 sm:mr-8 bg-grey-300 rounded-full p-3 flex relative w-12 h-12">
                    <CaseEventIcon caseOperation={caseEvent?.operation ?? ""} />
                </div>
                <div>
                    <div>
                        <p>
                            {
                                <Trans
                                    t={t}
                                    i18nKey={`healthCaseEvent.${caseEvent.type}.${caseEvent.operation}`}
                                    values={{
                                        name:
                                            caseEvent?.reportedVia === "system"
                                                ? t("theSystem")
                                                : caseEvent?.createdByUserName || t("createdByUnknown"),
                                        oldvalue: t(caseEventDescription?.oldValue) || t("createdByUnknown"),
                                        newvalue: t(caseEventDescription?.newValue) || t("createdByUnknown"),
                                    }}
                                />
                            }
                        </p>
                        {caseEvent.type === "notification" ? (
                            <div>
                                <div className="flex">
                                    <button className="self-center mr-1" onClick={() => setOpen(!open)}>
                                        <Icon icon={open ? "caretFullDown" : "caretFullRight"} size={12} />
                                    </button>
                                    <p className="text-sm">{open ? t("hideMessage") : t("showMessage")}</p>
                                </div>
                                {open && !isEmpty(caseEvent?.message) && (
                                    <div>
                                        <p className="my-4 font-bold">{t("message")}</p>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: caseEvent.message ?? "",
                                            }}
                                        ></p>
                                        <p className="my-4 font-bold">{t("receiver")}</p>
                                        {caseEvent.receivers?.map((receiver) => <p className="my-4">{receiver}</p>)}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p className="text-sm">{getDescription()}</p>
                        )}
                    </div>
                    <div className="sm:hidden items-center">
                        <p className="text-sm">
                            {getDateTimeDisplayValue(caseEvent.timeStamp ?? "", "eee d MMM yyyy, HH:mm")}
                        </p>
                    </div>
                </div>
            </div>
            <div className="hidden sm:flex items-center ml-3 whitespace-nowrap">
                <p className="text-sm">{getDateTimeDisplayValue(caseEvent.timeStamp ?? "", "eee d MMM yyyy, HH:mm")}</p>
            </div>
        </div>
    );
};

export default CaseLogItem;
