import { Card, CardAlign } from "@medhelp/ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Icon from "components/Icon";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    getMissingSubscriptionAccessRightsForUser,
    getStatus,
    getUserEmployment,
    getUserSubscriber,
    getUserSubscription,
} from "pages/FollowUp/redux/followUpSelectors";
import { fetchSubscription } from "pages/FollowUp/redux/followUpAsyncActions";
import { Status } from "pages/FollowUp/redux";
import FollowUpSubscribeMenu from "pages/FollowUp/components/FollowUpSubscribeMenu";

const Subscribe = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const status = useAppSelector(getStatus);
    const userEmployment = useAppSelector(getUserEmployment);
    const userSubscriber = useAppSelector(getUserSubscriber);
    const userSubscription = useAppSelector(getUserSubscription);
    const missingAccessRightsForUser = useAppSelector(getMissingSubscriptionAccessRightsForUser);

    useEffect(() => {
        if (userEmployment?.employmentId && userEmployment?.companyId && !userSubscription && !userSubscriber) {
            dispatch(
                fetchSubscription({
                    employmentId: userEmployment.employmentId,
                    companyId: userEmployment.companyId,
                }),
            );
        }
    }, [dispatch, userEmployment?.companyId, userEmployment?.employmentId, userSubscriber, userSubscription]);
    return (
        <div>
            <Card align={CardAlign.start}>
                {missingAccessRightsForUser ? (
                    t("missingAccessRights")
                ) : status.userSubscription === Status.PENDING ? (
                    <div className="flex flex-1 justify-center">
                        <Icon icon="loader" size={35} />
                    </div>
                ) : (
                    <FollowUpSubscribeMenu />
                )}
            </Card>
        </div>
    );
};
export default Subscribe;
