import { IDynamicComponent } from "./IDynamicComponent";
import { SingleDatepicker } from "@medhelp/ui";
import { ISingleDatepickerProps } from "@medhelp/ui/Datepicker/types";

const DynamicDate = (
    props: IDynamicComponent<string | undefined, "date"> & {
        children?: React.ReactNode;
    },
) => {
    const { children, ...rest } = props;
    const singleDatepickerProps = { ...rest } as ISingleDatepickerProps;
    return (
        <div data-testid="dynamic-date">
            <SingleDatepicker {...singleDatepickerProps} />
            {children}
        </div>
    );
};
export default DynamicDate;
