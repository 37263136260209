import { ILoginPageData, LoginUsing } from "pages/login/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Select from "@medhelp/ui/Select";
import { ISelectValue } from "@medhelp/ui/Select/Select";

const SelectCompany = ({ userAccounts, loginUsing, returnUrl }: ILoginPageData) => {
    const { t } = useTranslation("login");
    const navigate = useNavigate();

    let options: ISelectValue[] = [];
    if (userAccounts) {
        options = userAccounts!.map((c) => ({
            description: c.companyName,
            value: c.userAccountId.toString(),
        }));
    }

    useEffect(() => {
        if (!userAccounts || userAccounts?.length < 1) navigate("/login/bankIdOptions");
    }, [navigate, userAccounts]);

    return (
        <div data-cy="select-company">
            <h1 className="mb-14">{t("selectCompany")}</h1>
            <Select
                options={options}
                placeholder={t("selectCompany")}
                onChange={(v) =>
                    loginUsing({
                        loginUsing: LoginUsing.selectedUserAccount,
                        selectedUserAccountId: Number(v.value),
                        returnUrl: returnUrl,
                    })
                }
            />
        </div>
    );
};

export default SelectCompany;
