import { useCallback, useEffect, useMemo, useState } from "react";
import { newDateString } from "utils/date";
import * as UserSelectors from "store/userSelectors";
import { useAppSelector } from "store/hooks";
import { ISingleDatePickerHookProps, ISingleDatepickerProps } from "@medhelp/ui/Datepicker/types";

const useSingleDatepickerProps = ({
    onChangeDate,
    givenSelectedDate,
    placeholder = "",
}: ISingleDatePickerHookProps) => {
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<string | undefined>();
    const [month, setMonth] = useState("");
    const userLang = useAppSelector(UserSelectors.getUserLanguage) ?? "sv";
    const handleOnDateChange = useCallback(
        (selectedDate: string | undefined) => {
            setSelectedDate(selectedDate);
            onChangeDate(selectedDate);
            setOpen(false);
        },
        [onChangeDate],
    );

    useEffect(() => {
        setSelectedDate(givenSelectedDate);
        setMonth(givenSelectedDate ?? newDateString());
    }, [givenSelectedDate]);

    const singleDatepickerProps: ISingleDatepickerProps = useMemo(
        () => ({
            open,
            setOpen,
            selectedDate,
            setSelectedDate: handleOnDateChange,
            month,
            setMonth,
            selectedLang: userLang,
            disableDateFrom: undefined,
            disableDateTo: undefined,
            placeholder,
        }),
        [handleOnDateChange, month, open, placeholder, selectedDate, userLang],
    );
    return singleDatepickerProps;
};
export default useSingleDatepickerProps;
