import React, { useLayoutEffect } from "react";

const useIsOverflow = (element: HTMLElement | undefined, callback?: (value: boolean) => void) => {
    const [isOverflow, setIsOverflow] = React.useState<boolean | undefined>(undefined);

    useLayoutEffect(() => {
        const trigger = () => {
            if (element) {
                const hasOverflow = element?.scrollHeight > element?.clientHeight;

                setIsOverflow(hasOverflow);

                if (callback) callback(hasOverflow);
            }
        };

        if (element) {
            trigger();
        }
    }, [callback, element]);

    return isOverflow;
};
export default useIsOverflow;
