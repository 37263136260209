const OngoingAbsenceWidgetLoader = () => {
    return (
        <div className="animate-pulse">
            <div className="flex w-full justify-between text-sm pt-5 pb-4 px-6">
                <div className="w-1/3 pr-2 flex items-center justify-center">
                    <div className="h-2.5 bg-gray-600 rounded-full dark:bg-gray-700 w-20"></div>
                </div>
                <div className="w-1/3 pr-2 flex items-center justify-center">
                    <div className="h-2.5 bg-gray-600 rounded-full dark:bg-gray-700 w-20"></div>
                </div>
                <div className="w-1/3 pr-2 flex items-center justify-center">
                    <div className="h-2.5 bg-gray-600 rounded-full dark:bg-gray-700 w-20"></div>
                </div>
            </div>
            <div className="flex flex-col h-full items-center">
                <div className="w-32 h-32 flex items-center justify-center">
                    <div className="h-20 bg-gray-600 rounded-full dark:bg-gray-700 w-20"></div>
                </div>
                <div className="text-center px-8 mt-4 leading-5">
                    <div className="h-2.5 bg-gray-600 rounded-full dark:bg-gray-700 w-40"></div>
                </div>
            </div>
        </div>
    );
};

export default OngoingAbsenceWidgetLoader;
