import { useMemo, useState } from "react";

/**
 *
 * @returns hovered state and eventHandlers.
 *
 * const [hovered, eventHandlers] = useHover();
 * Add eventHandlers to hover target: <Element {...eventHandlers} />
 * Add hovered condition to the dynamic element.
 */
const useHover = (): [boolean, { onMouseOver(): void; onMouseOut(): void }] => {
    const [hovered, setHovered] = useState(false);

    const eventHandlers = useMemo(
        () => ({
            onMouseOver() {
                setHovered(true);
            },
            onMouseOut() {
                setHovered(false);
            },
        }),
        [],
    );

    return [hovered, eventHandlers];
};
export default useHover;
