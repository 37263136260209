import Icon from "components/Icon";
import { useTranslation } from "react-i18next";

type Status = "active" | "inactive" | "activates";

const StatusTag = ({ status }: { status: Status }) => {
    const { t } = useTranslation("service");
    const presets = {
        active: {
            text: t("active"),
            backgroundColor: "bg-primary-1000",
            textColor: "text-mhbeige",
            icon: <Icon icon="checkCircle" className="text-mhbeige h-5 w-5" />,
        },
        inactive: {
            text: t("inactive"),
            backgroundColor: "bg-grey-300",
            textColor: "text-grey-600",
            icon: <Icon icon="checkCircle" className="text-grey-600 h-5 w-5" />,
        },
        activates: {
            text: t("activates"),
            backgroundColor: "bg-grey-500",
            textColor: "text-mhbeige",
            icon: <Icon icon="checkCircle" className="text-mhbeige h-5 w-5" />,
        },
    };
    return (
        <span className={`py-1.5 px-2.5 inline-flex items-center ${presets[status].backgroundColor}`}>
            <p className={`font-bold mr-2 ${presets[status].textColor}`}>{presets[status].text}</p>
            {presets[status].icon}
        </span>
    );
};

export default StatusTag;
