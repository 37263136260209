import PrimaryButton from "components/PrimaryButton";
import { RadioButton } from "pages/Administration/components";
import {
    ActiveErasurePropNames,
    IErasureActiveSettingsStateProps,
    activeErasurePropNames,
    activeErasureTimespanOptions,
} from "pages/Administration/types";
import { useTranslation } from "react-i18next";

interface IActiveErasureSettingsPageProps extends IErasureActiveSettingsStateProps {
    onPropChange: (propName: ActiveErasurePropNames, value: number | null) => void;
    isDirty: boolean;
    onSaveClicked: () => void;
}

export const ErasureSettings = ({
    activeErasureConfig,
    onPropChange,
    isDirty,
    onSaveClicked,
    isLoading,
}: IActiveErasureSettingsPageProps) => {
    const { t } = useTranslation("administration");

    return (
        <div>
            <h2 className="border-b pb-4">{t("erasureSettings")}</h2>
            <div className="p-6 border-b">
                <h3 className="mb-6">{t("timeLength")}</h3>

                <div className="mt-6 flex">
                    <div className="mr-6 pt-4">
                        {activeErasurePropNames.map((propName) => (
                            <div className="mt-4" key={propName}>
                                <p>{t(propName)}</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-grow">
                        <div className="w-full flex">
                            {activeErasureTimespanOptions.map((option) => (
                                <div key={option} className="w-26 flex flex-col">
                                    <p className="mb-2">
                                        {option
                                            ? t("noMonths", {
                                                  months: option,
                                              })
                                            : t("never")}
                                    </p>
                                    {activeErasurePropNames.map((propName) => (
                                        <div key={"rBtn-" + option + propName} className="mb-4">
                                            <RadioButton
                                                text=""
                                                checked={activeErasureConfig[propName] === option}
                                                setChecked={() => onPropChange(propName, option)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <PrimaryButton
                id="erasure-save-button"
                text={t("save")}
                onClick={onSaveClicked}
                disabled={!isDirty}
                isLoading={isLoading}
                className="m-6"
            />
        </div>
    );
};

export default ErasureSettings;
