import { getCredentialTranslation } from "pages/Administration/utils";
import { useTranslation } from "react-i18next";
import AdminInput from "../../AdminInput";
import { IInputControlProps } from "pages/Administration/types";
import Icon from "components/Icon";

const InputControl = ({ input, activeTab, handleInputOnChange, handleButtonClick }: IInputControlProps) => {
    const { t } = useTranslation("administration");
    const isOneTimePass = input.loginCredential.credentialType === 6;

    const inputHeading = isOneTimePass
        ? t(activeTab ?? "")
        : t(getCredentialTranslation(input.loginCredential.credentialType ?? 3));

    return (
        <>
            <div className="relative flex items-end">
                <AdminInput
                    heading={inputHeading}
                    savedValue={input.inputValue}
                    onChange={handleInputOnChange}
                    disabled={input.disabled}
                    errorMessage={t(input.errorMessage)}
                    placeholder={input.placeholder ? t(input.placeholder) : ""}
                    isLoading={input.isLoading}
                >
                    <button
                        className={`h-12 w-10 mx-3 flex items-center justify-center ${
                            input.errorMessage
                                ? "opacity-50 cursor-not-allowed bg-primaryButtonDisabled text-primaryButtonDisabledText"
                                : "bg-primaryButtonPrimary text-primaryButtonText cursor-pointer"
                        }`}
                        onClick={() => handleButtonClick(input.isDirty ? "save" : "edit")}
                        disabled={!!input.errorMessage}
                    >
                        <Icon icon={input.isDirty ? "save" : "edit"} />
                    </button>
                    {input.loginCredential.credentialType !== 2 && (
                        <button
                            className="h-12 w-10 bg-mhneongreen-original flex items-center justify-center"
                            onClick={() => handleButtonClick("delete")}
                        >
                            <Icon icon="trashCan" />
                        </button>
                    )}
                </AdminInput>
            </div>
        </>
    );
};

export default InputControl;
