import { t } from "i18next";
import { toZonedTime } from "date-fns-tz";

/**
 * @param dynamicMedicalAdvisory boolean
 * @returns The custom callback message depending on the time the report was made and the type of advisory service.
 */

export const getCallbackMessage = (dynamicMedicalAdvisory: boolean): string => {
    // Opening hourse constants
    const LEGACY_OPENING_TIME = 9;
    const LEGACY_CLOSING_TIME = 18;

    const DYNAMIC_OPENING_TIME = 9;
    const DYNAMIC_CLOSING_TIME = 24;

    // Current datetime in Swedish time zone
    const swedishDateTime = toZonedTime(new Date(), "Europe/Stockholm");

    // Current hour in Swedish time zone
    const swedishHour = swedishDateTime.getHours();

    if (dynamicMedicalAdvisory) {
        // Check if dynamic MHS is open
        const DYNAMIC_MHS_IS_OPEN = swedishHour >= DYNAMIC_OPENING_TIME && swedishHour < DYNAMIC_CLOSING_TIME;
        return DYNAMIC_MHS_IS_OPEN
            ? t("immediateCallbackMessage", { closingTime: "00:00" })
            : t("nextDayCallbackMessage", { callingHours: `${String(DYNAMIC_OPENING_TIME)}:00 - 00:00` });
    }

    // legacy product only open on weekdays
    const isWeekday = swedishDateTime.getDay() >= 1 && swedishDateTime.getDay() <= 5;

    // Check if legacy mhs is open
    const LEGACY_MHS_IS_OPEN = swedishHour >= LEGACY_OPENING_TIME && swedishHour < LEGACY_CLOSING_TIME && isWeekday;

    return LEGACY_MHS_IS_OPEN
        ? t("immediateCallbackMessage", { closingTime: `${String(LEGACY_CLOSING_TIME)}:00` })
        : t("nextDayCallbackMessage", {
              callingHours: `${String(LEGACY_OPENING_TIME)}:00 - ${String(LEGACY_CLOSING_TIME)}:00`,
          });
};
