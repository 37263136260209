import { take, isEmpty } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { Card, CardAlign } from "@medhelp/ui";
import { selectors } from "../../redux";
import { certificates as utilsC } from "../../utils";
import MedicalCertificateInfo from "../MedicalCertificateInfo";
import ManageCertificate from "../ManageCertificate";
import { useAppSelector } from "store/hooks";

interface IProps {
    /** set max number of `CurrentCertificate` to display */
    max?: number;
    manager?: boolean;
}
const CurrentCertificate = ({ max, manager }: IProps) => {
    const { t } = useTranslation();
    const certificates = useAppSelector(selectors.getSortedCertificates("current", utilsC.MetadataKeys.to));
    const certificatesByGroupKey = useAppSelector(selectors.getCertificatesByGroupKey);
    if (!certificates) return null;

    if (isEmpty(certificates))
        return (
            <Card testId="no-current-certificate-test">
                <p>{t("noCurrentCertificates")}</p>
            </Card>
        );
    return (
        <>
            {take(
                max ? max : certificates.length,
                certificates.map(
                    (value, index: number) =>
                        value.metaData &&
                        value.groupKey && (
                            <Card
                                testId="current-certificates-card"
                                key={`current-certificates-card-${value.groupKey}`}
                                align={CardAlign.start}
                                className="mb-8 p-0"
                            >
                                <div key={`currentCertificates-${index}`} className="w-full flex-1 flex flex-col">
                                    <div className="p-8">
                                        <MedicalCertificateInfo
                                            text={t("currentCertificates")}
                                            fileData={value.metaData}
                                            images={certificatesByGroupKey(value.groupKey)}
                                        />
                                    </div>
                                    {manager && (
                                        <div className="w-full flex-1 flex flex-col">
                                            <hr className="w-full flex-1" />
                                            <ManageCertificate
                                                groupKey={value.groupKey}
                                                // @ts-expect-error
                                                owner={value.owner}
                                                metaData={value.metaData}
                                                files={certificatesByGroupKey(value.groupKey)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Card>
                        ),
                ),
            )}
        </>
    );
};
export default CurrentCertificate;
