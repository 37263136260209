import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { actions, thunkActions } from "pages/Inbox/redux";
import {
    getEventMessages,
    getEventMessagesPageCount,
    getEventMessagesCurrentPage,
    getEventMessagesPageNumber,
    getEventMessagesStatus,
    getEventMessagesTotalCount,
} from "../../redux/inboxSelectors";
import { isEmpty } from "lodash/fp";
import { Status } from "pages/Inbox/types";
import { Card, CardAlign, Error, PaginationFooter, Table } from "@medhelp/ui";
import MiniMenu from "components/MiniMenu/MiniMenu";
import MessageCard from "../../components/MessageCard";
import InboxLoading from "../../InboxLoading";

const EventMessages = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const messages = useAppSelector(getEventMessages);
    const currentPage = useAppSelector(getEventMessagesCurrentPage);
    const messageCount = useAppSelector(getEventMessagesTotalCount);
    const pageCount = useAppSelector(getEventMessagesPageCount);
    const pageNumber = useAppSelector(getEventMessagesPageNumber);
    const status = useAppSelector(getEventMessagesStatus);
    const MESSAGES_LIMIT = 10;
    const headers = [
        { label: t("eventType"), accessor: "eventType" },
        { label: t("subject"), accessor: "subject" },
        { label: t("date"), accessor: "date" },
    ];
    const actionValues = [
        {
            text: t("markAllAsRead"),
            onClick: () => dispatch(thunkActions.updateEventMessagesReadAll()),
        },
    ];

    useEffect(() => {
        dispatch(
            thunkActions.fetchEventMessages({
                offset: 0,
                limit: MESSAGES_LIMIT,
            }),
        );
    }, [dispatch]);

    const onPageChange = (page: number) => {
        const existingPage = messages?.inbox?.find((message) => message.page === page);
        if (!existingPage) {
            dispatch(
                thunkActions.fetchEventMessages({
                    offset: (page - 1) * 10,
                    limit: MESSAGES_LIMIT,
                }),
            );
        }
        dispatch(
            actions.setMessagesPageNumber({
                pageNumber: page,
                type: "eventMessages",
            }),
        );
    };

    if (status === Status.PENDING) {
        return <InboxLoading />;
    }

    if (status === Status.ERROR) {
        return <Error text={t("couldNotFetchContent")} />;
    }

    return (
        <Card align={CardAlign.start} className="p-0 block">
            {isEmpty(currentPage) ? (
                <div className="text-center p-8">{t("noMessages")}</div>
            ) : (
                <>
                    <Table
                        headers={headers}
                        extra={
                            <div className="px-6 pb-5 pt-8">
                                <MiniMenu
                                    id="event-messages-actions"
                                    preset="white"
                                    buttonValues={actionValues}
                                    buttonRight
                                    containerClassName="flex"
                                />
                            </div>
                        }
                    >
                        {currentPage?.map((message) => (
                            <MessageCard key={message.id} message={message} type="eventMessages" />
                        ))}
                    </Table>
                    <PaginationFooter
                        itemsCount={messageCount}
                        activePage={pageNumber}
                        pageCount={pageCount}
                        onClick={onPageChange}
                    />
                </>
            )}
        </Card>
    );
};

export default EventMessages;
