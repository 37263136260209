import { useTranslation } from "react-i18next";
import { ModalFrame } from "@medhelp/ui";

interface DefinitionModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

type DefinitionTraProps = {
    heading: string;
    definition: string;
    list?: string[];
};

function DefinitionModal({ isOpen, setIsOpen }: DefinitionModalProps) {
    const { t } = useTranslation("followup");
    const definition: DefinitionTraProps[] = t("definitions", { returnObjects: true });
    return (
        <div>
            <ModalFrame
                open={isOpen}
                setOpen={() => setIsOpen(false)}
                header={<h1>{t("definition")}</h1>}
                content={
                    <div>
                        {Array.isArray(definition) &&
                            definition.length > 0 &&
                            definition.map((item) => (
                                <div key={item?.heading} className="py-4">
                                    <h3>{item?.heading}</h3>
                                    <h5>{item?.definition}</h5>
                                    <div className="pt-2">
                                        {Array.isArray(item.list) &&
                                            item?.list.length > 0 &&
                                            item?.list.map((listItem: string) => <li key={listItem}>{listItem}</li>)}
                                    </div>
                                </div>
                            ))}
                    </div>
                }
            />
        </div>
    );
}

export default DefinitionModal;
