import { ReactNode } from "react";

interface IProps {
    id: string;
    highlight: boolean;
    children: ReactNode;
}
const CertificateContainer = ({ id, children, highlight }: IProps) => {
    return (
        <div id={id} className={`${highlight ? "border-2 border-solid border-black" : "border-0"}`}>
            {children}
        </div>
    );
};

export default CertificateContainer;
