import { useTranslation } from "react-i18next";
import { useBreakpoints } from "hooks";
import { thunkActions, actions } from "pages/Inbox/redux";
import { getMessageIsExpanded } from "../../redux/inboxSelectors";
import {
    getIconForNotificationType,
    getNotificationTypeTranslation,
    getNotificationTypeValue,
} from "utils/notificationTypes";
import { ExpandedInboxMessage } from "pages/Inbox/types";
import { getDateDisplayValue } from "utils/date";
import { TableCell } from "@medhelp/ui";
import Icon from "components/Icon/Icon";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface IProps {
    message: ExpandedInboxMessage;
    type: "eventMessages" | "subscriptionMessages";
}

const MessageCard = (props: IProps) => {
    const { message, type } = props;
    const { t } = useTranslation();
    const breakpoints = useBreakpoints();
    const dispatch = useAppDispatch();
    const isExpanded = useAppSelector(getMessageIsExpanded(message.id, type));

    const getMessageTypeOrName = () => {
        {
            if (type === "subscriptionMessages") {
                return message.employeeName === "nameNotAvailable" ? t("nameNotAvailable") : message.employeeName;
            }

            return getNotificationTypeTranslation(
                getNotificationTypeValue(message.notificationType),
                message.eventCode ?? "",
                t,
            );
        }
    };

    const renderMessageContent = () => {
        if (message.notificationType === "FileReadyForDownload") {
            const contentArray = message.content?.split("&quot;");
            if (!contentArray) return "";
            return (
                <div className="flex flex-wrap gap-1">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: contentArray[0],
                        }}
                    />
                    <div
                        onClick={() => {
                            dispatch(thunkActions.downloadFile(message.referenceId ?? ""));
                        }}
                        className="text-blue-500 hover:underline hover:cursor-pointer"
                    >
                        {`"${contentArray[1]}"`}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: contentArray[2],
                        }}
                    />
                </div>
            );
        } else {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            message.content
                                // Remove <code>, </code>, <pre> and </pre> tags
                                ?.replaceAll(/<\/?code>|<\/?pre>/g, "")
                                // Replace HTML entities for <br> tag with actual <br /> tag
                                .replaceAll(/&lt;br\s?\/&gt;/g, "<br />")
                                // Replace all <h1>-<h6> tags with <strong> tags
                                .replaceAll(/<h[1-6]>/g, "<strong>")
                                // Replace all </h1>-</h6> tags with </strong> tags
                                .replaceAll(/<\/h[1-6]>/g, "</strong>") ?? "",
                    }}
                />
            );
        }
    };

    return (
        <>
            <tr
                key={message.id}
                className={`border-t align-center cursor-pointer hover:bg-grey-50 ${
                    message.readAt ? "bg-background-200" : ""
                }`}
                onClick={() => {
                    if (message.id) {
                        if (!message.readAt) {
                            dispatch(
                                thunkActions.updateMessageRead({
                                    id: message.id,
                                    type,
                                }),
                            );
                        }
                        dispatch(
                            actions.setExpanded({
                                id: message.id,
                                type,
                            }),
                        );
                    }
                }}
            >
                <>
                    {breakpoints.isLargeScreen ? (
                        <TableCell showOnMobile>
                            <div className="flex items-center">
                                <div className="bg-grey-300 rounded-full p-3 mr-4">
                                    {getIconForNotificationType(message.notificationType)}
                                </div>
                                <div className={message.readAt ? "" : "font-bold"}>
                                    <div>
                                        {type === "subscriptionMessages"
                                            ? message.employeeName
                                            : getNotificationTypeTranslation(
                                                  getNotificationTypeValue(message.notificationType),
                                                  message.eventCode ?? "",
                                                  t,
                                              )}
                                    </div>
                                    <div>{message.subject}</div>
                                    <div>{getDateDisplayValue(message.timestamp, "d MMM yyyy, HH:mm")}</div>
                                </div>
                            </div>
                        </TableCell>
                    ) : (
                        <>
                            <TableCell>
                                <div className="flex items-center">
                                    <div className="bg-grey-300 rounded-full p-3 flex relative w-12 h-12 mr-4">
                                        {getIconForNotificationType(message.notificationType)}
                                    </div>
                                    <p className={message.readAt ? "" : "font-bold"}>{getMessageTypeOrName()}</p>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={message.readAt ? "" : "font-bold"}>{message.subject}</div>
                            </TableCell>
                            <TableCell>
                                <div className={message.readAt ? "" : "font-bold"}>
                                    {getDateDisplayValue(message.timestamp, "d MMM yyyy, HH:mm")}
                                </div>
                            </TableCell>
                        </>
                    )}
                    <TableCell showOnMobile className="w-10">
                        <Icon icon={isExpanded ? "caretUp" : "caretDown"} />
                    </TableCell>
                </>
            </tr>
            {isExpanded && (
                <tr>
                    <td className="p-6" colSpan={4}>
                        {renderMessageContent()}
                    </td>
                </tr>
            )}
        </>
    );
};

export default MessageCard;
