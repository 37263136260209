import { useMemo } from "react";
import { SearchTypes, StartEndDatePeriodType } from "pages/FollowUp/utils";
import { useTranslation } from "react-i18next";
import ColumnTextRow from "./ColumnTextRow";
import { getDateDisplayValue } from "utils/date";
import { getHasBaseProduct } from "store/userSelectors";
import { useAppSelector } from "store/hooks";

interface IProps {
    endStartDate: StartEndDatePeriodType | null | undefined;
    searchType: SearchTypes;
}

const EndStartDateCell = ({ endStartDate, searchType }: IProps) => {
    const { t } = useTranslation("followup");
    const hasBaseProduct = useAppSelector(getHasBaseProduct);

    const header = useMemo(() => getDateDisplayValue(endStartDate?.startDate, "d MMM yyyy"), [endStartDate?.startDate]);
    const text = useMemo(() => {
        if (hasBaseProduct && searchType === "ongoingAbsence") {
            return "-";
        }
        return endStartDate?.endDate ? getDateDisplayValue(endStartDate.endDate, "d MMM yyyy") : t("ongoing");
    }, [endStartDate?.endDate, hasBaseProduct, searchType, t]);

    return <ColumnTextRow header={header} body={text} />;
};
export default EndStartDateCell;
