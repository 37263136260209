import { ReactComponent as Arrow } from "icons/arrow.svg";

/**
 * Arrow container is based on `Up`
 */
interface IProps {
    className?: string;
}
export const ArrowLeft = ({ className, ...rest }: IProps) => <Arrow {...rest} className={`-rotate-90 ${className}`} />;

export const ArrowRight = ({ className, ...rest }: IProps) => <Arrow {...rest} className={`rotate-90 ${className}`} />;

export const ArrowDown = ({ className, ...rest }: IProps) => <Arrow {...rest} className={`rotate-180 ${className}`} />;

export const ArrowUp = ({ className, ...rest }: IProps) => <Arrow {...rest} className={className} />;
