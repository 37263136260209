import { groupByReason } from "../utils";
import ErrorDetails from "./ErrorDetails";
import { useTranslation } from "react-i18next";
import { useGetInvalidRowsQuery } from "../../../store/rtk-apis/fileImport/fileImportApi";

interface RowDetailsProps {
    customerKey: number;
    importId: string;
    fileId?: string;
}

const RowDetails = ({ customerKey, importId, fileId }: RowDetailsProps) => {
    const { t } = useTranslation("fileImport");
    const { data: invalidRows } = useGetInvalidRowsQuery({ customerKey, importId });

    if (!invalidRows || invalidRows.length < 1) return <></>;

    const filteredByFile = invalidRows.filter((row) => row.fileId === fileId);

    if (filteredByFile.length < 1) return <></>;

    const groupedInvalidRows = groupByReason(filteredByFile);

    return (
        <div className="flex flex-col gap-6">
            <div className="flex font-bold">{t("excludedFromImport")}</div>
            {Object.entries(groupedInvalidRows).map(([reason, rows], i) => (
                <ErrorDetails
                    key={reason + i}
                    title={t(`validationErrors.${reason}`)}
                    rows={rows}
                    alertType="warning"
                />
            ))}
        </div>
    );
};

export default RowDetails;
