import * as React from "react";

const PendingProgressBar = () => {
    return (
        <div
            data-cy="pending-progress-bar"
            className="w-full h-2 bg-grey-200 relative text-left rounded overflow-hidden"
        >
            <div className="h-full w-0 bg-primaryButtonPrimary animate-progress-bar"></div>
        </div>
    );
};

export default PendingProgressBar;
