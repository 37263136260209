import {
    CompanyDataErasureAvailableConfiguration,
    CompanyUser,
    CostCenter,
    CreateOrUpdateAccessRights,
    CreateOrUpdateEmployment,
    CreateOrUpdateUserAccount,
    CredentialType,
    EmploymentGroup,
    LoginCredentials,
} from "swagger/customercompany";
import { LoginConfiguration } from "swagger/businessupport";
import { ISingleDatepickerProps } from "@medhelp/ui/Datepicker/types";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import { ISelectValue } from "@medhelp/ui/Select/Select";
import {
    AddRoleSubscriptionConfigration,
    CompanySubscriptionConfiguration,
    RoleSubscriptionConfigration,
} from "swagger/messenger";
import { INewRoleSubscriptionProps } from "../components/RoleSubscriptions/NewRow/RoleSubscriptionNewRow";
import {
    CompanyRegion,
    EventSubscriptionPostItem,
    SubscriberTableItem,
    SubscriptionLookup,
    SubscriptionTableItem,
    SubscriptionType,
} from "swagger/employeeattendance";
import { ISubscriptionTabCompProps } from "../components/Subscriptions/SubscriptionTabs/SubscriptionTabs";
import { ReactNode } from "react";
import {
    ICreateSubscriptionStateProps,
    UpdateProps,
} from "../components/Subscriptions/CreateNewSubscription/CreateNewSubscription";

export interface IAdminCompProps {
    companyId: string | undefined;
    companyName: string;
    companyOrgNumber?: string;
    userId?: number;
    userName?: string;
    userEmployment?: string;
    isCoaOrCs: boolean;
}

export interface IUsersCompProps {
    tabsDisabled: boolean;
    companyId: number;
    userId: number;
    isCs: boolean;
}

export interface IDisplayValues {
    identifierTranslation: string;
    identifierValue?: string;
}

export interface ILoginsData {
    allLoginsConfig: LoginCredentials[];
    loginConfig: LoginConfiguration;
}
export interface ILoginInputSharedProps {
    inputValue: string;
    disabled: boolean;
    isDirty: boolean;
    errorMessage: string;
    loginCredential: LoginCredentials;
    isLoading: boolean;
    placeholder?: string;
}

export interface ILoginAccordionSharedProps {
    accordionName: keyof ILoginAccordions;
    heading: string;
    open: boolean;
    credentialType: CredentialType;
    inputProps: ILoginInputSharedProps[];
    displayValues: IDisplayValues[];
    disableButton?: boolean;
    activeTab: string;
    loginTypeExistsInCompanyConfig: boolean;
}

export interface ILoginAccordions {
    usernameAndPassAccordion: ILoginAccordionSharedProps;
    bankIdAccordion: ILoginAccordionSharedProps;
    microsoftAccordion: ILoginAccordionSharedProps;
    oneTimePassAccordion: ILoginAccordionSharedProps;
}

export interface ILoginsStateProps {
    accordionProps: ILoginAccordions;
}

export interface ILoginsPageProps extends ILoginsStateProps {
    userAccountId: number;
    userId: number;
    handleInputOnChange: (accordionName: keyof ILoginAccordions, input: ILoginInputSharedProps, value: string) => void;
    handleButtonClick: (
        accordionName: keyof ILoginAccordions,
        button: "add" | "open" | "edit" | "delete" | "save" | "eMail" | "SMS",
        input?: ILoginInputSharedProps,
        open?: boolean,
    ) => void;
}

export interface ISetUserStringProp {
    propKey:
        | "firstName"
        | "lastName"
        | "socialSecurityNumber"
        | "country"
        | "language"
        | "mobileNumber"
        | "mobileCountryPrefix"
        | "telephoneNumber"
        | "telephoneCountryPrefix"
        | "email"
        | "homeAddress"
        | "secondaryMobileCountryPrefix"
        | "secondaryMobileNumber"
        | "secondaryEmail"
        | "periodActivationDate"
        | "periodDeactivationDate";
    value: string;
}
export interface ISetUserNumberProp {
    propKey: "id" | "userId" | "companyId" | "gender" | "birthYear" | "customerId";
    value: number;
}

export interface IErrorMessages {
    ssnErrorMsg: string;
    workEmailErrorMsg: string;
    privateEmailErrorMsg: string;
    privatePhoneErrorMsg: string;
    workPhoneErrorMsg: string;
}

export interface IAdminSearchStateProps {
    searchIsLoading: boolean;
    searchResultOpen: boolean;
    searchResults: CompanyUser[];
    isExistingUser?: boolean;
}

export interface IAddUserStateProps {
    userProps: CreateOrUpdateUserAccount;
    errorMessages: IErrorMessages;
    ssnSearchProps: IAdminSearchStateProps;
    saveButtonLoading: boolean;
    selectedCompanyRegion: CompanyRegion | null;
}

interface IAddUserOnChange {
    onChange: (propKey: keyof CreateOrUpdateUserAccount, value: string | number) => void;
}

export const countryTypes = ["SE", "NO", "DK", "FI", "OTH"] as const;
export type Country = (typeof countryTypes)[number];

export interface ICountryLanguageProps extends IAddUserOnChange {
    country: Country;
    language: string;
}

export interface IAdminSearchCompProps extends IAdminSearchStateProps {
    setSearchResultOpen: (searchResultOpen: boolean) => void;
    onSelectUserClick: (selectedUserAccountId: number) => void;
}

export interface IPersonalInfoProps extends IAddUserOnChange {
    firstName: string;
    lastName: string;
    ssn: string;
    ssnPlaceholder: string;
    ssnErrorMsg: string;
    ssnSearchProps: IAdminSearchCompProps;
    birthYear: number | null;
    gender: number;
    disableInputs: boolean;
    onValidateSsn: (propKey: keyof IErrorMessages, value: string) => void;
}

export interface IPeriodStateProps {
    activationDate: string;
    deActivationDate: string;
}
export interface IPeriodCompProps {
    activationDatepickProps: ISingleDatepickerProps;
    deActivationDatepickProps: ISingleDatepickerProps;
}

export interface IContactInfoProps extends IAddUserOnChange {
    privatePhone: string;
    workPhone: string;
    homePhone: string;
    email: string;
    workEmailErrorMsg: string;
    onValidatePhone: (propKey: keyof IErrorMessages, isValid: boolean) => void;
    onValidateEmail: (propKey: keyof IErrorMessages, value: string) => void;
}

export interface IAddUserPageProps {
    countryAndLangCompProps: ICountryLanguageProps;
    personaInfoCompProps: IPersonalInfoProps;
    periodCompProps: IPeriodCompProps;
    contactCompProps: IContactInfoProps;
    handleSubmit: () => void;
    saveButtonDisabled: boolean;
    saveButtonLoading: boolean;
}

export interface ILoginAccordionChildProps {
    credentialType: CredentialType;
    inputControls: ILoginInputSharedProps[];
    activeTab?: string;
    handleInputOnChange: (input: ILoginInputSharedProps, value: string) => void;
    handleButtonClick: (button: "edit" | "delete" | "save" | "eMail" | "SMS", input?: ILoginInputSharedProps) => void;
}

export interface IInputControlProps {
    input: ILoginInputSharedProps;
    activeTab?: string;
    handleInputOnChange: (value: string) => void;
    handleButtonClick: (button: "edit" | "delete" | "save") => void;
}

export interface IGetLoginConfigurationModel {
    companyId: string;
    office365: boolean;
    usernameFromEmail: boolean;
    usernameFromSsn: boolean;
}

export enum LoginCredentialType {
    password = 1,
    bankId = 2,
    // google = 3,    // Currently not supported
    // facebook = 4,  // Currently not supported
    microsoft = 5,
    oneTimePassword = 6,
}

export interface ICreateOrUpdateUserAccountExtended {
    id: number;
    type: string;
    allLoginsConfig?: LoginCredentials[];
}

export interface ILoginCredentialsExtended {
    success: boolean;
    duplicate: boolean;
    inputCredential: LoginCredentials;
    loginCredentials: LoginCredentials[];
}

export interface ICompanyUserFromAccountEditModel {
    userAccountId: number;
    companyId: number;
}

export interface IEmploymentData {
    companyEmploymentGroups: EmploymentGroup[];
    companyCostCenters: CostCenter[];
    employment: CreateOrUpdateEmployment;
}

interface IEmploymentOnChange {
    onChange: (propKey: keyof CreateOrUpdateEmployment, value: string) => void;
}

export interface IEmployedAtSelectProps {
    selectedValue: ISelectValue;
    options: ISelectValue[];
}

export interface IEmploymentInfoCompProps extends IEmploymentOnChange {
    employedOn: string;
    employmentNumber: string;
    employmentGroupValues: IEmployedAtSelectProps;
    disableAll: boolean;
}

export interface IBelongingPageProps extends IEmploymentOnChange {
    mainManagagerSearchValue: string | undefined;
    mainManagagerSelectedName: string | undefined;
    mainManagerSearchProps: IAdminSearchCompProps;
    costCenterValues: IEmployedAtSelectProps;
    displayList: IDynamicData[];
    disableAll: boolean;
    organizationNumber: string;
    organizationNumberErrorMsg: string;
}

export interface IUserEmploymentStateProps {
    userEmployment: CreateOrUpdateEmployment | null;
    mainManagagerSearchValue: string | undefined;
}

export interface IEmployementPageProps {
    employmentInfoAtProps: IEmploymentInfoCompProps;
    belongingPageProps: IBelongingPageProps;
    disableSaveButton: boolean;
    saveButtonLoading: boolean;
    handleSubmit: () => void;
}

export interface IEmploymentStateProps {
    mainManagerSearchProps: IAdminSearchStateProps;
    organizationNumberErrorMsg: string;
    saveButtonLoading: boolean;
}

export const tabs = ["userSubsOn", "subsOnUser"] as const;
export type Tab = (typeof tabs)[number];

export const subscriptionHeadings = ["status", "absence", "deviant", "cases"] as const;
export type SubscriptionHeading = (typeof subscriptionHeadings)[number];

export interface ISubscriptionData {
    userSubsOnTableItems: SubscriberTableItem[];
    subsOnUserTableItems: SubscriptionTableItem[];
    defaultSubscriptionTypes: SubscriptionType[];
    searchData: SubscriptionLookup[] | undefined;
}

export interface IPermissionData {
    selectedCompanyAccessRights: CreateOrUpdateAccessRights;
    companyAccessRightsRecursive: CompanyAccessRightsRecursive;
}
export interface IPermissionsStateProps {
    accordionProps: IPermissionAccordions;
    currentAccordion?: keyof IPermissionAccordions;
}

export interface IPermissionsAccordionSharedProps {
    name: keyof IPermissionAccordions;
    open: boolean;
    isCheckbox: boolean;
    checked?: boolean;
    infoText: string;
    depDisplayList: IDynamicData[];
    isDirty?: boolean;
    isLoading: boolean;
}

export interface IPermissionAccordions {
    absenceReporting: IPermissionsAccordionSharedProps;
    absenceAdministration: IPermissionsAccordionSharedProps;
    absenceView: IPermissionsAccordionSharedProps;
    absenceFollowup: IPermissionsAccordionSharedProps;
    absenceStatistics: IPermissionsAccordionSharedProps;
    rehabProcessManagement: IPermissionsAccordionSharedProps;
    departmentAdministration: IPermissionsAccordionSharedProps;
    absenceBoard: IPermissionsAccordionSharedProps;
    absenceArchive: IPermissionsAccordionSharedProps;
    customerCompanyAdministration: IPermissionsAccordionSharedProps;
    customerOrganizationAdministration: IPermissionsAccordionSharedProps;
    medicalAdvising: IPermissionsAccordionSharedProps;
}

export interface IPermissionsPageProps extends IPermissionsStateProps {
    companyName: string;
    onAccordionClick: (accordionName: keyof IPermissionAccordions, open: boolean) => void;
    onValueChange: (accordionName: keyof IPermissionAccordions, value: boolean | string) => void;
    onSaveButtonClick: (accordionName: keyof IPermissionAccordions) => void;
}

export interface ISetAccessRightBooleanValue {
    accessRightKey:
        | "absenceReporting"
        | "absenceArchive"
        | "customerCompanyAdministration"
        | "customerOrganizationAdministration"
        | "medicalAdvising";
    newValue: boolean;
}
export interface ISetAccessRightStringValue {
    accessRightKey:
        | "absenceAdministration"
        | "absenceView"
        | "absenceFollowup"
        | "absenceStatistics"
        | "rehabProcessManagement"
        | "departmentAdministration"
        | "absenceBoard";
    newValue: string;
}

export interface CompanyAccessRightsRecursive {
    absenceFollowup: boolean;
    absenceAdministration: boolean;
    departmentAdministration: boolean;
    rehabProcessManagement: boolean;
    absenceView: boolean;
    absenceStatistics: boolean;
    absenceBoard: boolean;
}

export type ValueOf<T> = T[keyof T];
export interface ICompanyErasureConfig {
    companyId: number;
    automatical: boolean;
    noAbsenceHistoryOrRehabErasureMonthInterval: number | null;
    absenceHistoryErasureMonthInterval: number | null;
    rehabErasureMonthInterval: number | null;
    workRelatedAbsenceErasureMonthInterval: number | null;
    enableHistoricalDataErasure: boolean;
}
export interface IErasureFinishedSettingsStateProps {
    companyErasureConfig: ICompanyErasureConfig;
    activeInput: EmployeeHistory | undefined;
    isLoading: boolean;
}

export const automaticErasureOptions = [3, 12, null] as const;
export type ErasureOption = (typeof automaticErasureOptions)[number];

export const employeeHistory = [
    "noAbsenceHistoryOrRehabErasureMonthInterval",
    "absenceHistoryErasureMonthInterval",
    "rehabErasureMonthInterval",
    "workRelatedAbsenceErasureMonthInterval",
] as const;
export type EmployeeHistory = (typeof employeeHistory)[number];

export interface IErasureActiveSettingsStateProps {
    activeErasureConfig: CompanyDataErasureAvailableConfiguration;
    isLoading: boolean;
}

export const activeErasureTimespanOptions = [12, 24, 36, null] as const;
export type ActiveErasureTimespanOptions = (typeof activeErasureTimespanOptions)[number];

export const activeErasurePropNames = ["absenceHistory", "caseDocumentation", "medicalCertificates"] as const;
export type ActiveErasurePropNames = (typeof activeErasurePropNames)[number];

export enum NotificationTypes {
    ReportedSick = 0,
    EditedSickReport = 1,
    ReportedRecovery = 2,
    ReportedVAB = 3,
    EditedVABReport = 4,
    ReportedVABRecovery = 5,
    AbsenceReminder = 6,
    DeviantAbsenceSickInShortPeriods = 7,
    DeviantAbsenceLongerThenXDays = 8,
    DeviantAbsenceXDaysInYMonths = 16,
    RehabProcessPlannedActivityReminder = 9,
    AbsenceReportedToSocialInsurrance = 10,
    MedicalCertificateMessageReminder = 11,
    RehabProcessInitiated = 12,
    MedicalCertificate = 13,
    RetroActivelyEditedAbsence = 14,
    ChangedUserAccessRights = 15,
    RehabProcessClosingReminder = 17,
    FileReadyForDownload = 18,
    DocumentUploaded = 19,
    ReportHealthyReminder = 20,
    MedicalCertificateUploaded = 21,
    MedicalCertificateExpired = 22,
    HealthCaseRehab = 23,
    HealthCaseHealthPromotion = 24,
    HealthCaseRehabReminder = 25,
    HealthCaseHealthPromotionReminder = 26,
    HealthCaseActivityReminder = 27,
    HealthCaseClosingReminder = 28,
}
export interface IRoleSubscriptionsSettingsData {
    availableNotifications: CompanySubscriptionConfiguration[];
    selectedRoles: RoleSubscriptionConfigration[];
}

export interface IStandardSubscriptionProps {
    selectedRole: Role | null;
    onRoleChanged: (selectedRole: Role) => void;
    deleteClicked: () => void;
}

export interface IRoleSubscriptionsSettingsStateProps {
    isLoading: boolean;
    selectedRoleSubscriptions: AddRoleSubscriptionConfigration[];
    newRoleSubscription: AddRoleSubscriptionConfigration | null;
    selectedRole: Role | null;
    showStandardSubscriptionOptions: boolean;
    saveIsLoading: boolean;
    notificationSelectOpen: boolean;
}

export interface IRoleSubscriptionGroups {
    groupName: string;
    selectedRoleSubscriptions: AddRoleSubscriptionConfigration[];
}

export interface IRoleSubscriptionsSettingsPageProps extends IRoleSubscriptionsSettingsStateProps {
    roleSubscriptionTypes: IRoleSubscriptionGroups[];
    newRowProps: INewRoleSubscriptionProps;
    enableSave: boolean;
    isDirty: boolean;
    deleteClicked: (subscription?: AddRoleSubscriptionConfigration) => void;
    handleSubmit: () => void;
    addClicked: (isStandard?: boolean) => void;
    onRoleChanged: (selectedRole: Role) => void;
    abortClicked: () => void;
}

export interface IPostCompanyConfigModel {
    companyId: number;
    subscriptionConfig: AddRoleSubscriptionConfigration[];
}

export const roleTypes = ["departmentmanager", "mainmanager", "hr"] as const;
export type Role = (typeof roleTypes)[number];

export interface ICheckBoxStateProps extends EventSubscriptionPostItem {
    checked: boolean;
}
export interface INotificationTypeRow {
    notificationTypeName: keyof typeof NotificationTypes;
    eventCode: string;
    checkboxesProps: ICheckBoxStateProps[];
    isRoleSubscription?: boolean;
}
export interface IAccordionDisplayValues {
    isActive: boolean;
    subscriptionStatuses: string[];
}
export interface ISubscriptionGroup {
    subscriptionId: number;
    groupName: string;
    notificationTypeRows: INotificationTypeRow[];
}

export interface ISubscribeOnUserGroups {
    groupName: string;
    subscriptionLabels: string[];
}

export interface ISubscribeOnUserDisplayProps {
    subscriber: string;
    user: string;
    groups: ISubscribeOnUserGroups[];
}

export interface ISubscriptionOptionsStateProps {
    subscriptionGroups: ISubscriptionGroup[];
    contactKey: string[];
    missingContactKey: string;
    selectedUserName?: string;
    disableEditSubscription?: boolean;
    subscribeOnUserDisplayProps?: ISubscribeOnUserDisplayProps;
}

export interface ISubscriptionOptionsPageProps extends ISubscriptionOptionsStateProps {
    startDatepickerProps: ISingleDatepickerProps;
    endDatepickerProps: ISingleDatepickerProps;
    newSubIsLoading?: boolean;
    isSubsOnUser?: boolean;
    onCheckboxChange: (checkboxProps: ICheckBoxStateProps[]) => void;
    onMissingContactKeyClicked: () => void;
}

export interface ISubscriptionAccordionDisplayProps {
    subscriptionId: number;
    subscriptionName: string;
    contactKey: string[];
    missingContactKey: string;
    displayValues: IAccordionDisplayValues;
    subscribesOnUserName?: string;
}

// public enum MonitoringType
//     {
//         None = 0,
//         Employment = 1,
//         Department = 2,
//         Company = 3
//     }

export enum SubscriptionMonitorType {
    none = 0,
    employment = 1,
    department = 2,
    company = 3,
    externalUser = 4,
    newExternalUser = 5,
}

export interface ISubscriptionAccordionStateProps extends ISubscriptionAccordionDisplayProps {
    open: boolean;
    startDate?: string;
    endDate?: string;
    saveDisabled?: boolean;
    children?: ReactNode;
    saveIsLoading?: boolean;
    postItems: EventSubscriptionPostItem[];
    subscriptionMonitorType: SubscriptionMonitorType | undefined;
    monitoredIds: number[];
    subscribingUserAccountId: number | null;
    isSubsOnUserTab?: boolean;
    canDeleteSubscription: boolean;
    newExternalContactKey: string | null;
    currentTableItemUserAccountId?: number;
    subscriptionOptionProps: ISubscriptionOptionsStateProps;
}

export interface ISubScriptionAccordionProps extends ISubscriptionAccordionStateProps {
    setOpen: () => void;
    deleteClicked: () => void;
    savelicked?: () => void;
}

export interface ISubscriptionModalProps {
    modalOpen: boolean;
    modalHeader: string;
    deleteSubscriptionId?: number;
    newSubscriptonIsValid: boolean;
}
export interface ISubscriptionStateProps extends ISubscriptionModalProps {
    subscriptionIsLoading: boolean;
    activeTab: Tab;
    createOrUpdateSuccessful: boolean | null;
    currentlyEditingAccordion: ISubscriptionAccordionStateProps;
    userSubsOnAccordionProps: ISubscriptionAccordionStateProps[];
    subsOnUserAccordionProps: ISubscriptionAccordionStateProps[];
    newSubscriptionStateProps: ICreateSubscriptionStateProps;
}
export interface ISubscriptionPageProps extends ISubscriptionStateProps {
    tabCompProps: ISubscriptionTabCompProps;
    setAccordionOpen: (accordion: ISubscriptionAccordionStateProps) => void;
    onCreateNewSubscription: () => void;
    setDeleteSubscriptionId: (accordionId: number) => void;
    modalButtonClicked: () => void;
    accordionSaveClicked: () => void;
    onCheckboxChange: (checkboxProps: ICheckBoxStateProps[]) => void;
    startDatepickerProps: ISingleDatepickerProps;
    endDatepickerProps: ISingleDatepickerProps;
    newSubscriptionOnChange: (propName: UpdateProps, value: string | number | SubscriptionLookup) => void;
    onMissingContactKeyClicked: () => void;
}
