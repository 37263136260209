import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { thunkActions } from "../../../redux";
import { getUserLanguage } from "store/userSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { ModalCommonFooter, ModalFrame } from "@medhelp/ui";
import Checkbox from "@medhelp/ui/Checkbox";

interface IProps {
    healthCase: IHealthCase;
    modalIsOpen: boolean;
    setModalIsOpen: () => void;
    setExportStarted: () => void;
}

export const ExportCaseModal = (props: IProps) => {
    const { healthCase, modalIsOpen, setModalIsOpen, setExportStarted } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const userLanguage = useAppSelector(getUserLanguage);
    const [checkBoxes, setCheckBoxes] = useState({
        details: false,
        activities: false,
        notes: false,
        documentsAndTemplates: false,
        caseLog: false,
    });

    const allUnchecked = Object.values(checkBoxes).every((value) => value === false);

    const handleOnExport = () => {
        if (healthCase.id) {
            const exportModel = {
                details: checkBoxes.details,
                activities: checkBoxes.activities,
                notes: checkBoxes.notes,
                documentsAndTemplates: checkBoxes.documentsAndTemplates,
                caseLog: checkBoxes.caseLog,
                language: userLanguage,
            };

            dispatch(
                thunkActions.exportHealthCase({
                    healthCaseId: healthCase.id,
                    exportHealthCaseModel: exportModel,
                }),
            ).then(() => {
                setExportStarted();
                setModalIsOpen();
            });
        }
    };

    return (
        <ModalFrame
            id="export-case-modal"
            header={<h1>{t("exportCase")}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            content={
                <div className="pt-8 pb-2 px-3 sm:px-0">
                    <h2 className="pb-4">{t("selectContentToExport")}</h2>
                    {Object.entries(checkBoxes).map(([key, value]) => (
                        <div key={`export-case-modal-${key}`} className="py-1">
                            <Checkbox
                                checked={value}
                                onChange={() =>
                                    setCheckBoxes({
                                        ...checkBoxes,
                                        [key]: !value,
                                    })
                                }
                                label={<h3 className="px-2">{t(key)}</h3>}
                            />
                        </div>
                    ))}
                </div>
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    tertiaryOnClick={setModalIsOpen}
                    primaryText={t("export")}
                    primaryOnClick={handleOnExport}
                    primaryDisabled={allUnchecked}
                />
            }
        />
    );
};

export default ExportCaseModal;
