import { InboxMessage } from "swagger/messenger";

export interface IInboxState {
    eventMessages: IInboxMessages;
    subscriptionMessages: IInboxMessages;
}

export interface IInboxMessages {
    messages: IInboxResponse | null;
    unreadCount: number;
    pageNumber: number;
    status: Status;
}

export interface IInboxResponse {
    inbox: IInboxPage[] | null | undefined;
    totalCount: number | undefined;
}

export enum Status {
    PENDING = "PENDING",
    ERROR = "ERROR",
    OK = "OK",
}

export interface IInboxPage {
    inbox: ExpandedInboxMessage[] | null | undefined;
    page: number;
}

export interface ExpandedInboxMessage extends InboxMessage {
    employeeName?: string | null;
    expanded: boolean;
}

export interface IFetchInboxMessages {
    offset: number;
    limit: number;
}

export interface ISetPageNumber {
    pageNumber: number;
    type: "eventMessages" | "subscriptionMessages";
}

export interface ISetExpanded {
    id: string;
    type: "eventMessages" | "subscriptionMessages";
}

export interface IUpdateMessageRead {
    id: string;
    type: "eventMessages" | "subscriptionMessages";
}
