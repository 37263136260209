import TabMenu from "components/TabMenu";
import { Config } from "config";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

interface IErasureProps {
    companyId: number;
}

export const ErasureFinished = ({ companyId }: IErasureProps) => {
    const { t } = useTranslation("administration");

    const Routes = useMemo(
        () => [
            {
                route: "administration/users/erasureFinished",
                name: "test",
                indexRoute: "erasureFinished",
                children: [
                    {
                        route: `${Config.web_customerSupport_url}removedemployments/${companyId}`,
                        name: t("erasureFinished"),
                        children: [],
                    },
                    {
                        route: `${Config.web_customerSupport_url}removedemployments/${companyId}`,
                        name: `${t("erasureLog")} `,
                        children: [],
                    },
                    {
                        route: "settings",
                        name: t("settings"),
                        children: [],
                    },
                ],
            },
        ],

        [companyId, t],
    );
    return (
        <TabMenu routes={Routes} subMenuTo="administration/users/erasureFinished">
            <div className="mt-10 p-4 shadow-lg">
                <Outlet />
            </div>
        </TabMenu>
    );
};

export default ErasureFinished;
