import { useTranslation } from "react-i18next";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";

interface IProps {
    healthCase: IHealthCase;
}

const ChainProgressBar = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");

    const chainProgressWidth = healthCase?.chainInformation?.day
        ? `${Math.round((healthCase.chainInformation.day / 550) * 100)}%`
        : "0%";

    return (
        <div className={`mx-8 px-8 mb-6 pb-10 bg-mhbeige ${healthCase.status === "paused" ? "opacity-50" : ""}`}>
            <p className="pt-5 pb-6 text-sm font-bold">{t("rehabChain")}</p>
            <div className="bg-mhgrey-medium flex h-8 relative">
                <div
                    className={"bg-progressBar h-8 flex items-center border-r border-dashed border-white"}
                    style={{ width: chainProgressWidth }}
                >
                    <div className="bg-white h-1 w-full rounded"></div>
                </div>
                <div className="bg-white w-[2px] h-8 absolute left-[16%]"></div>
                <div className="bg-white w-[2px] h-8 absolute left-[33%]"></div>
                <div className="bg-white w-[2px] h-8 absolute left-[66%]"></div>
            </div>
            <div className="flex relative text-sm text-grey-600 mt-1">
                <div className="absolute left-[0%]">0</div>
                <div className="absolute left-[14%]">{`90 ${t("days")}`}</div>
                <div className="absolute left-[31%]">{`180 ${t("days")}`}</div>
                <div className="absolute left-[64%]">{`365 ${t("days")}`}</div>
                <div className="absolute left-[98%]">550..</div>
            </div>
        </div>
    );
};

export default ChainProgressBar;
