import { IState, Status } from "..";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchCostCalculation as fetchCostCalculationAction } from "../followUpAsyncActions";

const fetchCostCalculation = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchCostCalculationAction.fulfilled, (state, action) => {
            state.costCalculation.data = action.payload;
            state.costCalculation.status = Status.OK;
        })
        .addCase(fetchCostCalculationAction.pending, (state) => {
            state.costCalculation.status = Status.PENDING;
        })
        .addCase(fetchCostCalculationAction.rejected, (state) => {
            state.costCalculation.status = Status.ERROR;
        });

export default fetchCostCalculation;
