import { flow, get, intersection, filter, map, sortBy, find, compact, isEqual } from "lodash/fp";
import { filterWithFunc, isNotEmpty, reverseIncludes } from "utils/functions";
import { AccountAccessRightViewModel } from "../../swagger/usercontextservice";
import { AccessType } from "./types";

/**
 * Access rights in medhelp care. For information go to this page
 * @link https://medhelpcare.atlassian.net/wiki/spaces/MEDHELP/pages/2022408189/Access+Rights
 */
export enum IAccessRightTypeEnum {
    absenceReporting = 1,
    absenceFollowup = 2,
    absenceAdministration = 3,
    customerCompanyAdministration = 4,
    customerSupport = 5,
    absenceReportingAssistance = 6,
    medicalAdvice = 7,
    absenceArchive = 8,
    medicalAdvising = 9,
    departmentAdministration = 10,
    rehabDepartmentManagement = 11,
    absenceView = 12,
    viewBillingBasis = 13,
    exportBillingBasis = 14,
    absenceStatistics = 15,
    absenceBoard = 16,
    medicalAdvisoryAbsenceAdministration = 17,
    ivrAbsenceReportingProcess = 18,
    dataErasure = 19,
    companyLevelStatistics = 20,
    openApiAdmin = 21,
    openApi = 22,
    customerOrganizationAdministration = 23,
    fileImportAdministration = 24,
}

export interface IAccessRightData {
    value: IAccessRightTypeEnum;
    top: boolean;
}

export const AccessRightsCustomerSupport = [{ value: IAccessRightTypeEnum.customerSupport, top: false }];

export const AccessRightsCustomerCompanyAdministration = [
    { value: IAccessRightTypeEnum.customerCompanyAdministration, top: false },
];

export const getEnumsFromAccessRightData = (data: readonly IAccessRightData[]) => data.map((x) => x.value);
export const hasTopDeparments = (rights: AccountAccessRightViewModel) =>
    Boolean(rights.name?.includes("**TopDepartment**"));

const selectOnlyTopDepartments = (accessType: AccessType) => (rights: AccountAccessRightViewModel) =>
    accessType !== "top" || hasTopDeparments(rights);
/**
 * Provides typing for the `get` method
 */
const getPropertyFromAccessRight = (property: keyof AccountAccessRightViewModel) => get(property);

export const hasAccessRightTypeIncludes = <T>(order: T[]) =>
    reverseIncludes(order?.map(getPropertyFromAccessRight("accessRightType")));

export const filterAccessRightBy = (order: AccountAccessRightViewModel[] | undefined) => filterWithFunc(order || []);

export const getAllRights: <T>(
    accessRight: Array<AccountAccessRightViewModel>,
) => (accessProtected: readonly T[], accessType: AccessType) => boolean =
    (accessRight) => (accessProtected, accessType) =>
        flow(
            filter(selectOnlyTopDepartments(accessType)),
            map(getPropertyFromAccessRight("accessRightType")),
            intersection(accessProtected),
            isNotEmpty,
        )(accessRight);

/**
 *
 * @param order select order of accessRight first element is highest last lowers
 * @returns the highest access right provide by the order
 */
export const getHighestAccessRight = <T extends IAccessRightTypeEnum>(
    order: readonly T[],
    accessRights: Array<AccountAccessRightViewModel> | undefined,
) =>
    sortBy((x) => find((y) => y === x.accessRightType, order) ?? Infinity, accessRights)[0]?.accessRightType as
        | T
        | undefined;

const getAccessRightType = (accessRights: AccountAccessRightViewModel[]) =>
    compact(accessRights.map((x) => x.accessRightType)) || [];

const checkAccess = (accessRights: IAccessRightTypeEnum[], right: IAccessRightData) =>
    accessRights.some(isEqual(right.value));

export const findTopAccess = (accessRights: AccountAccessRightViewModel[], right: IAccessRightData) =>
    checkAccess(getAccessRightType(accessRights.filter(hasTopDeparments)), right);

export const accessRightsByIAccessRightData =
    (accessRights: AccountAccessRightViewModel[]) => (right: IAccessRightData) =>
        right.top ? findTopAccess(accessRights, right) : checkAccess(getAccessRightType(accessRights), right);

export const findAccessRightsBasedOnGivenRights = (
    rights: IAccessRightData[] | readonly IAccessRightData[],
    accessRights: AccountAccessRightViewModel[],
) => rights.some(accessRightsByIAccessRightData(accessRights));
