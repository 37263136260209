/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IState } from "../organizationSlice";
import { searchEmployee as searchEmployeeAsyncAction } from "../organizationAsyncActions";

const searchEmployee = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(searchEmployeeAsyncAction.fulfilled, (state, action) => {
            state.searchEmployee = action.payload;
        })
        .addCase(searchEmployeeAsyncAction.pending, () => {})
        .addCase(searchEmployeeAsyncAction.rejected, () => {});

export default searchEmployee;
