import { TFunction } from "i18next";
import { MedHelpAbsenceFollowUpWebApiResponseAbsence as IAbsenceListItem } from "swagger/absencefollowup";
import { getDateDisplayValue } from "utils/date";
import { absenceList } from "../../utils";

export const getAbsenceReason = (reason?: string | null) => (reason === "CareOfChild" ? "onlyVabText" : "sick");

export const createAbsenceInfo = (t: TFunction, currentAbsenceReport: IAbsenceListItem, hasBaseProduct: boolean) => {
    const workRelated = currentAbsenceReport?.properties
        ? absenceList.getProperty(currentAbsenceReport.properties, "WorkRelated")
        : null;

    const workplaceAccident = currentAbsenceReport?.properties
        ? absenceList.getProperty(currentAbsenceReport.properties, "WorkplaceAccident")
        : null;
    const absenceInfoList = [
        currentAbsenceReport.startDate
            ? {
                  header: `${t("startDate")}:`,
                  body: getDateDisplayValue(currentAbsenceReport.startDate),
                  type: "startdate",
              }
            : null,
        {
            header: `${t("endDate")}:`,
            body: currentAbsenceReport.endDate ? getDateDisplayValue(currentAbsenceReport.endDate) || "" : t("ongoing"),
            notification: Boolean(!currentAbsenceReport.endDate),
            type: "enddate",
        },
        {
            header: currentAbsenceReport.endDate ? `${t("backAtWork")}:` : `${t("estimatedBack")}:`,
            body: currentAbsenceReport.backAtWork ? getDateDisplayValue(currentAbsenceReport.backAtWork) : t("ongoing"),
            type: "backatwork",
        },
        {
            type: "degree",
        },
        {
            header: `${t("followUpAbsence.workRelated")}:`,
            body: workRelated?.value === "true" ? t("yes") : t("no"),
            type: "workrelated",
        },
        {
            header: `${t("followUpAbsence.workAccident")}:`,
            body: workplaceAccident?.value === "true" ? t("yes") : t("no"),
            type: "workplaceAccident",
        },
    ];

    return hasBaseProduct
        ? absenceInfoList.filter(
              (item) =>
                  item?.type !== "backatwork" && item?.type !== "workrelated" && item?.type !== "workplaceAccident",
          )
        : absenceInfoList;
};
