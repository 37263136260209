import { useAppDispatch, useAppSelector } from "store/hooks";
import { isUndefined } from "lodash/fp";
import { useMemo, useState } from "react";
import { actions, selectors } from "../../redux";
import DropdownFilter, { IProps, IData } from "./DropdownFilter";

export { type IData };
export default function Index({
    text,
    customHeader,
    className,
    preset,
    item,
    data,
    type,
    setOpen: customOpen = undefined,
    open: isCustomOpen = undefined,
}: IProps) {
    const dispatch = useAppDispatch();
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const showBasedOn = useAppSelector(selectors.getSortingOrBasedOn(type, currentSearch));
    const [open, setOpen] = useState(false);
    const Items = useMemo(
        () => (
            <div className="py-2">
                {data
                    .filter(({ name }) => name !== showBasedOn)
                    .map((value) => (
                        <button
                            onClick={() =>
                                dispatch(
                                    actions.updateSearchFilterSorting({
                                        value: value.name,
                                        field: value.field,
                                    }),
                                )
                            }
                            className="w-full"
                        >
                            {item(value.text)}
                        </button>
                    ))}
            </div>
        ),
        [data, dispatch, item, showBasedOn],
    );
    const Header = useMemo(() => customHeader(showBasedOn), [customHeader, showBasedOn]);
    return (
        <DropdownFilter
            preset={preset}
            className={className}
            header={Header}
            items={Items}
            text={text}
            open={isUndefined(isCustomOpen) ? open : isCustomOpen}
            setOpen={isUndefined(customOpen) ? setOpen : customOpen}
            isCustomOpen={!isUndefined(isCustomOpen)}
        />
    );
}
