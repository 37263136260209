import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { FilterType } from "../Widget/widgetMap";
import { widgetMap } from "pages/dashboard/components/Widget";
import WidgetDropdown from "pages/dashboard/components/WidgetDropdown/WidgetDropdown";
import { IDynamicData, Widget, WidgetDropdownTypeKeys } from "pages/dashboard/utils/dashboardTypes";
import {
    getGenders,
    getHCMTypes,
    getCompanies,
    getDepartments,
    getTimeInterval,
    getAbsenceTypes,
    getAbsenceCauses,
    getAbsencePeriods,
    getEmploymentGroups,
} from "pages/dashboard/redux/dashboardSelectors";

interface IWidgetDropdownType {
    id: WidgetDropdownTypeKeys;
    heading: string;
    dynamicData: IDynamicData[];
    hasChildren?: boolean;
    translate?: boolean;
}

const selectCorrectWidgetDropdowns = (
    widgetDropdownItems: Array<IWidgetDropdownType>,
    companies: IDynamicData[],
    filterTypes?: FilterType[],
) => {
    let widgetDropDownKeys = [
        "companies",
        "departments",
        "employmentGroups",
        "genders",
        "absenceTypes",
        "absencePeriods",
        "timeInterval",
        "absenceCauses",
        "hcmTypes",
    ];
    const checkedComponiesLength = companies?.filter(({ checked }) => checked).length;
    if (companies?.length === 1) {
        widgetDropDownKeys = widgetDropDownKeys.filter((item) => item !== "companies");
    } else if (checkedComponiesLength && checkedComponiesLength > 1) {
        widgetDropDownKeys = widgetDropDownKeys.filter((item) => item !== "departments" && item !== "employmentGroups");
    } else if (checkedComponiesLength === 0) {
        widgetDropDownKeys = widgetDropDownKeys.filter((item) => item === "companies");
    }
    return widgetDropdownItems.filter(({ id }) => filterTypes?.includes(id) && widgetDropDownKeys.includes(id));
};

const WidgetDropdownContainer = ({ id, type }: Widget) => {
    const { t } = useTranslation("dashboard");
    const currentWidget = widgetMap.get(type);
    const genders = useAppSelector(getGenders(id));
    const hcmTypes = useAppSelector(getHCMTypes(id));
    const companies = useAppSelector(getCompanies(id));
    const departments = useAppSelector(getDepartments(id));
    const absenceTypes = useAppSelector(getAbsenceTypes(id));
    const timeInterval = useAppSelector(getTimeInterval(id));
    const absenceCauses = useAppSelector(getAbsenceCauses(id));
    const absencePeriods = useAppSelector(getAbsencePeriods(id));
    const employmentGroups = useAppSelector(getEmploymentGroups(id));

    const widgetDropdownItems: Array<IWidgetDropdownType> = useMemo(
        () => [
            {
                id: "companies",
                heading: t("filterCompany"),
                dynamicData: companies,
            },
            {
                id: "departments",
                heading: t("filterDepartment"),
                dynamicData: departments,
                hasChildren: departments?.some((i: IDynamicData) => i.children),
            },
            {
                id: "absenceCauses",
                heading: t("absenceCause"),
                dynamicData: absenceCauses,
                translate: true,
            },
            {
                id: "employmentGroups",
                heading: t("filterEmploymentGroup"),
                dynamicData: employmentGroups,
            },
            {
                id: "genders",
                heading: t("filterGender"),
                dynamicData: genders,
                translate: true,
            },
            {
                id: "absenceTypes",
                heading: t("filterAbsenceType"),
                dynamicData: absenceTypes,
                translate: true,
            },
            {
                id: "absencePeriods",
                heading: t("filterAbsencePeriod"),
                dynamicData: absencePeriods,
                translate: true,
            },
            {
                id: "timeInterval",
                heading: t("timeInterval"),
                dynamicData: timeInterval,
                translate: true,
            },
            {
                id: "hcmTypes",
                heading: t("hcmTypes"),
                dynamicData: hcmTypes,
                dataTestid: "hcmTypes",
                translate: true,
                hasChildren: false,
            },
        ],
        [
            t,
            companies,
            departments,
            employmentGroups,
            genders,
            absenceTypes,
            absencePeriods,
            timeInterval,
            absenceCauses,
            hcmTypes,
        ],
    );

    return (
        <>
            {selectCorrectWidgetDropdowns(widgetDropdownItems, companies, currentWidget?.filterTypes).map((item) => (
                <WidgetDropdown
                    id={item.id}
                    key={item.id}
                    heading={item.heading}
                    dynamicData={item.dynamicData || []}
                    dataTestid={item.id}
                    translate={item.translate}
                    hasChildren={item.hasChildren}
                    currentWidget={currentWidget}
                    widgetId={id}
                    useRadioButtons={
                        type === "absence-forecast-current-month" ||
                        item.id === "timeInterval" ||
                        item.id === "absenceCauses"
                    }
                    required={currentWidget?.requiredFilters?.includes(item.id)}
                />
            ))}
        </>
    );
};

export default WidgetDropdownContainer;
