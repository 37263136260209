import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect, useMemo, useState } from "react";
import { ServiceAccordion } from "../ServiceAccordion";
import ServiceRadioButton from "../ServiceRadioButton";
import { saveProduct } from "pages/service/redux/serviceAsyncActions";
import { ProductType, ProductTypes } from "pages/service/utils/serviceTypes";
import { getProduct, getSubcategory } from "pages/service/redux/serviceSelectors";

interface IProductConfiguration {
    product: ProductType;
    subcategory: string;
}

const ProductConfiguration = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const product = useAppSelector(getProduct);
    const subcategory = useAppSelector(getSubcategory);
    const [isLoading, setIsLoading] = useState(false);

    const defaultValues: IProductConfiguration = useMemo(
        () => ({
            product,
            subcategory,
        }),
        [product, subcategory],
    );

    const {
        reset,
        register,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm({ defaultValues });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const onSubmit = (data: IProductConfiguration) => {
        setIsLoading(true);
        dispatch(saveProduct(data))
            .then(unwrapResult)
            .then(() => toast(t("saved")))
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                reset();
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("product")}
                disabled={!isDirty || !isValid}
                isLoading={isLoading}
                reset={() => reset(defaultValues)}
                content={<div className="font-bold">{t(product)}</div>}
            >
                <div className="py-10">
                    <div className="flex flex-col gap-2">
                        {ProductTypes.map((product) => (
                            <ServiceRadioButton
                                key={product}
                                value={product}
                                register={register("product", {
                                    required: true,
                                })}
                                label={t(product)}
                            />
                        ))}
                    </div>
                </div>
            </ServiceAccordion>
        </form>
    );
};

export default ProductConfiguration;
