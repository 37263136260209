import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const Box = ({ children, className }: { children: ReactNode; className?: string }) => (
    <div className={twMerge("py-8 md:px-8", className)}>
        <div className="p-4 md:p-8 bg-white rounded-lg">{children}</div>
    </div>
);

export default Box;
