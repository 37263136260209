/* tslint:disable */
/* eslint-disable */
/**
 * UserContext API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: V1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountAccessRightViewModel
 */
export interface AccountAccessRightViewModel {
    /**
     * 
     * @type {string}
     * @memberof AccountAccessRightViewModel
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessRightViewModel
     */
    'accessRightType'?: AccountAccessRightViewModelAccessRightTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessRightViewModel
     */
    'externalId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessRightViewModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountAccessRightViewModel
     */
    'recursive'?: boolean;
}

export const AccountAccessRightViewModelAccessRightTypeEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24
} as const;

export type AccountAccessRightViewModelAccessRightTypeEnum = typeof AccountAccessRightViewModelAccessRightTypeEnum[keyof typeof AccountAccessRightViewModelAccessRightTypeEnum];

/**
 * 
 * @export
 * @interface ChangeAccountResponse
 */
export interface ChangeAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof ChangeAccountResponse
     */
    'token'?: string;
    /**
     * 
     * @type {UserContext}
     * @memberof ChangeAccountResponse
     */
    'userContext'?: UserContext;
}
/**
 * 
 * @export
 * @interface CompanyEmployment
 */
export interface CompanyEmployment {
    /**
     * 
     * @type {IdNameMap}
     * @memberof CompanyEmployment
     */
    'primaryCompany'?: IdNameMap;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CompanyEmployment
     */
    'companies'?: Array<IdNameMap>;
    /**
     * 
     * @type {IdNameMap}
     * @memberof CompanyEmployment
     */
    'employment'?: IdNameMap;
    /**
     * 
     * @type {Array<IdNameCompanyMap>}
     * @memberof CompanyEmployment
     */
    'departments'?: Array<IdNameCompanyMap>;
    /**
     * 
     * @type {string}
     * @memberof CompanyEmployment
     */
    'region'?: string;
}
/**
 * 
 * @export
 * @interface ContactInformation
 */
export interface ContactInformation {
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'telephoneCountryPrefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'telephoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'telephone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'mobileCountryPrefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'mobileNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'homeAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryMobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryMobileCountryPrefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryMobileNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface IdNameCompanyMap
 */
export interface IdNameCompanyMap {
    /**
     * 
     * @type {number}
     * @memberof IdNameCompanyMap
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof IdNameCompanyMap
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdNameCompanyMap
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface IdNameMap
 */
export interface IdNameMap {
    /**
     * 
     * @type {number}
     * @memberof IdNameMap
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdNameMap
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface LoginConfiguration
 */
export interface LoginConfiguration {
    /**
     * 
     * @type {number}
     * @memberof LoginConfiguration
     */
    'companyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoginConfiguration
     */
    'usernameFromEmail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginConfiguration
     */
    'usernameFromSsn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginConfiguration
     */
    'office365'?: boolean;
}
/**
 * 
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    'currentPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    'newPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    'newPasswordMatch'?: string;
}
/**
 * 
 * @export
 * @interface PasswordChangeResult
 */
export interface PasswordChangeResult {
    /**
     * 
     * @type {boolean}
     * @memberof PasswordChangeResult
     */
    'successful'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordChangeResult
     */
    'authFailed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordChangeResult
     */
    'changeFailed'?: boolean;
}
/**
 * 
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'accountIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'requestIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    'issuedAt'?: string;
}
/**
 * 
 * @export
 * @interface PasswordResetRequestViewModel
 */
export interface PasswordResetRequestViewModel {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequestViewModel
     */
    'accountIdentifier'?: string;
}
/**
 * 
 * @export
 * @interface PasswordResetViewModel
 */
export interface PasswordResetViewModel {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetViewModel
     */
    'requestIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetViewModel
     */
    'accountIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetViewModel
     */
    'newPassword'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetViewModel
     */
    'newPasswordMatch'?: string;
}
/**
 * 
 * @export
 * @interface Retailer
 */
export interface Retailer {
    /**
     * 
     * @type {number}
     * @memberof Retailer
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Retailer
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Retailer
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Retailer
     */
    'customerSupport'?: boolean;
}
/**
 * 
 * @export
 * @interface TokenUserReturnValue
 */
export interface TokenUserReturnValue {
    /**
     * 
     * @type {UserContext}
     * @memberof TokenUserReturnValue
     */
    'userContext'?: UserContext;
    /**
     * 
     * @type {number}
     * @memberof TokenUserReturnValue
     */
    'tokenException'?: TokenUserReturnValueTokenExceptionEnum;
}

export const TokenUserReturnValueTokenExceptionEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type TokenUserReturnValueTokenExceptionEnum = typeof TokenUserReturnValueTokenExceptionEnum[keyof typeof TokenUserReturnValueTokenExceptionEnum];

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'socialSecurityNumber'?: string;
    /**
     * 
     * @type {ContactInformation}
     * @memberof User
     */
    'contactInformation'?: ContactInformation;
    /**
     * 
     * @type {UserPreferences}
     * @memberof User
     */
    'userPreferences'?: UserPreferences;
}
/**
 * 
 * @export
 * @interface UserAccount
 */
export interface UserAccount {
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    'id'?: number;
    /**
     * 
     * @type {User}
     * @memberof UserAccount
     */
    'user'?: User;
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    'companyId'?: number;
    /**
     * 
     * @type {ContactInformation}
     * @memberof UserAccount
     */
    'contactInformation'?: ContactInformation;
    /**
     * 
     * @type {Array<AccountAccessRightViewModel>}
     * @memberof UserAccount
     */
    'accessRights'?: Array<AccountAccessRightViewModel>;
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    'tenant'?: number;
    /**
     * 
     * @type {IdNameMap}
     * @memberof UserAccount
     */
    'retailer'?: IdNameMap;
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    'customerId'?: number;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof UserAccount
     */
    'companiesAccessRights'?: Array<IdNameMap>;
}
/**
 * 
 * @export
 * @interface UserContext
 */
export interface UserContext {
    /**
     * 
     * @type {User}
     * @memberof UserContext
     */
    'user'?: User;
    /**
     * 
     * @type {UserAccount}
     * @memberof UserContext
     */
    'userAccount'?: UserAccount;
    /**
     * 
     * @type {CompanyEmployment}
     * @memberof UserContext
     */
    'companyEmployment'?: CompanyEmployment;
    /**
     * 
     * @type {Array<Retailer>}
     * @memberof UserContext
     */
    'retailers'?: Array<Retailer>;
    /**
     * 
     * @type {string}
     * @memberof UserContext
     */
    'activeRetailer'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserContext
     */
    'brand'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserContext
     */
    'newReporting'?: boolean;
}
/**
 * 
 * @export
 * @interface UserPreferences
 */
export interface UserPreferences {
    /**
     * 
     * @type {string}
     * @memberof UserPreferences
     */
    'language'?: string;
}

/**
 * PasswordResetApi - axios parameter creator
 * @export
 */
export const PasswordResetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PasswordResetRequestViewModel} [resetRequestViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResetRequest: async (resetRequestViewModel?: PasswordResetRequestViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PasswordReset/CreateResetRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetRequestViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResetRequest: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PasswordReset/GetResetRequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordResetViewModel} [resetViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset: async (resetViewModel?: PasswordResetViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PasswordReset/Reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordResetApi - functional programming interface
 * @export
 */
export const PasswordResetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordResetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PasswordResetRequestViewModel} [resetRequestViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createResetRequest(resetRequestViewModel?: PasswordResetRequestViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createResetRequest(resetRequestViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResetRequest(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResetRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordResetViewModel} [resetViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reset(resetViewModel?: PasswordResetViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordResetRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reset(resetViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordResetApi - factory interface
 * @export
 */
export const PasswordResetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordResetApiFp(configuration)
    return {
        /**
         * 
         * @param {PasswordResetRequestViewModel} [resetRequestViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createResetRequest(resetRequestViewModel?: PasswordResetRequestViewModel, options?: any): AxiosPromise<string> {
            return localVarFp.createResetRequest(resetRequestViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResetRequest(id?: string, options?: any): AxiosPromise<PasswordResetViewModel> {
            return localVarFp.getResetRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordResetViewModel} [resetViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset(resetViewModel?: PasswordResetViewModel, options?: any): AxiosPromise<PasswordResetRequest> {
            return localVarFp.reset(resetViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordResetApi - object-oriented interface
 * @export
 * @class PasswordResetApi
 * @extends {BaseAPI}
 */
export class PasswordResetApi extends BaseAPI {
    /**
     * 
     * @param {PasswordResetRequestViewModel} [resetRequestViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public createResetRequest(resetRequestViewModel?: PasswordResetRequestViewModel, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).createResetRequest(resetRequestViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public getResetRequest(id?: string, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).getResetRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordResetViewModel} [resetViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetApi
     */
    public reset(resetViewModel?: PasswordResetViewModel, options?: AxiosRequestConfig) {
        return PasswordResetApiFp(this.configuration).reset(resetViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status/GetStatusAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async status(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.status(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusAll(options?: any): AxiosPromise<void> {
            return localVarFp.getStatusAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        status(options?: any): AxiosPromise<void> {
            return localVarFp.status(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public getStatusAll(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).getStatusAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public status(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).status(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assumeAccount: async (userAccountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/AssumeAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccountId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccount: async (userAccountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ChangeAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userAccountId, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [jwtToken] 
         * @param {Array<string>} [accessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fastValidateJwtTokenAndAccessRights: async (jwtToken?: string, accessRights?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/FastValidateJwtTokenAndAccessRights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jwtToken !== undefined) {
                localVarQueryParameter['jwtToken'] = jwtToken;
            }

            if (accessRights) {
                localVarQueryParameter['accessRights'] = accessRights;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginConfiguration: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetLoginConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [jwtToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccountFromJwtToken: async (jwtToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserAccountFromJwtToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jwtToken !== undefined) {
                localVarQueryParameter['jwtToken'] = jwtToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [type] 
         * @param {string} [username] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccountsByLoginType: async (type?: number, username?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserAccountsByLoginType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFromJwtToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserFromJwtToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ContactInformation} [information] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactInformation: async (information?: ContactInformation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateContactInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(information, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordChange} [passwordChange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (passwordChange?: PasswordChange, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdatePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserPreferences} [preferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferences: async (preferences?: UserPreferences, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateUserPreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(preferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [jwtToken] 
         * @param {Array<string>} [accessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateJwtTokenAndAccessRights: async (jwtToken?: string, accessRights?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ValidateJwtTokenAndAccessRights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jwtToken !== undefined) {
                localVarQueryParameter['jwtToken'] = jwtToken;
            }

            if (accessRights) {
                localVarQueryParameter['accessRights'] = accessRights;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assumeAccount(userAccountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assumeAccount(userAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAccount(userAccountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangeAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAccount(userAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [jwtToken] 
         * @param {Array<string>} [accessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fastValidateJwtTokenAndAccessRights(jwtToken?: string, accessRights?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenUserReturnValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fastValidateJwtTokenAndAccessRights(jwtToken, accessRights, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginConfiguration(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginConfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [jwtToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccountFromJwtToken(jwtToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccountFromJwtToken(jwtToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [type] 
         * @param {string} [username] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserAccountsByLoginType(type?: number, username?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserAccountsByLoginType(type, username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFromJwtToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserContext>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFromJwtToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ContactInformation} [information] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactInformation(information?: ContactInformation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactInformation(information, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordChange} [passwordChange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(passwordChange?: PasswordChange, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordChangeResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(passwordChange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserPreferences} [preferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPreferences(preferences?: UserPreferences, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPreferences>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPreferences(preferences, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [jwtToken] 
         * @param {Array<string>} [accessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateJwtTokenAndAccessRights(jwtToken?: string, accessRights?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenUserReturnValue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateJwtTokenAndAccessRights(jwtToken, accessRights, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assumeAccount(userAccountId?: number, options?: any): AxiosPromise<ChangeAccountResponse> {
            return localVarFp.assumeAccount(userAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAccount(userAccountId?: number, options?: any): AxiosPromise<ChangeAccountResponse> {
            return localVarFp.changeAccount(userAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [jwtToken] 
         * @param {Array<string>} [accessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fastValidateJwtTokenAndAccessRights(jwtToken?: string, accessRights?: Array<string>, options?: any): AxiosPromise<TokenUserReturnValue> {
            return localVarFp.fastValidateJwtTokenAndAccessRights(jwtToken, accessRights, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginConfiguration(options?: any): AxiosPromise<LoginConfiguration> {
            return localVarFp.getLoginConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [jwtToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccountFromJwtToken(jwtToken?: string, options?: any): AxiosPromise<UserAccount> {
            return localVarFp.getUserAccountFromJwtToken(jwtToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccounts(options?: any): AxiosPromise<void> {
            return localVarFp.getUserAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [type] 
         * @param {string} [username] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAccountsByLoginType(type?: number, username?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUserAccountsByLoginType(type, username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFromJwtToken(options?: any): AxiosPromise<UserContext> {
            return localVarFp.getUserFromJwtToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContactInformation} [information] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactInformation(information?: ContactInformation, options?: any): AxiosPromise<ContactInformation> {
            return localVarFp.updateContactInformation(information, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordChange} [passwordChange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(passwordChange?: PasswordChange, options?: any): AxiosPromise<PasswordChangeResult> {
            return localVarFp.updatePassword(passwordChange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserPreferences} [preferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPreferences(preferences?: UserPreferences, options?: any): AxiosPromise<UserPreferences> {
            return localVarFp.updateUserPreferences(preferences, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [jwtToken] 
         * @param {Array<string>} [accessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateJwtTokenAndAccessRights(jwtToken?: string, accessRights?: Array<string>, options?: any): AxiosPromise<TokenUserReturnValue> {
            return localVarFp.validateJwtTokenAndAccessRights(jwtToken, accessRights, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {number} [userAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public assumeAccount(userAccountId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).assumeAccount(userAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public changeAccount(userAccountId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).changeAccount(userAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [jwtToken] 
     * @param {Array<string>} [accessRights] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public fastValidateJwtTokenAndAccessRights(jwtToken?: string, accessRights?: Array<string>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).fastValidateJwtTokenAndAccessRights(jwtToken, accessRights, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getLoginConfiguration(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getLoginConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [jwtToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserAccountFromJwtToken(jwtToken?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserAccountFromJwtToken(jwtToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserAccounts(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [type] 
     * @param {string} [username] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserAccountsByLoginType(type?: number, username?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserAccountsByLoginType(type, username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserFromJwtToken(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserFromJwtToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContactInformation} [information] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateContactInformation(information?: ContactInformation, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateContactInformation(information, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordChange} [passwordChange] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updatePassword(passwordChange?: PasswordChange, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updatePassword(passwordChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserPreferences} [preferences] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPreferences(preferences?: UserPreferences, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserPreferences(preferences, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [jwtToken] 
     * @param {Array<string>} [accessRights] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public validateJwtTokenAndAccessRights(jwtToken?: string, accessRights?: Array<string>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).validateJwtTokenAndAccessRights(jwtToken, accessRights, options).then((request) => request(this.axios, this.basePath));
    }
}


