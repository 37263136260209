import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { actions, selectors } from "../../redux";
import { get, isEmpty } from "lodash/fp";
import { findTagByReferenceKey } from "../../utils";
import ActivityStatusFilter from "./ActivityStatusFilter";

export default function Index() {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const currentFilter = useAppSelector(selectors.getSearchTagsById("activityStatus"));
    const delayedDays = useAppSelector(selectors.getSearchNumberById("daysActivityDelayed"));
    const Header = useMemo(() => t("search.searchQuery.filterName.activityStatus"), [t]);

    const Items = useMemo(() => currentFilter.map((x) => ({ ...x, label: t(x.label) })), [currentFilter, t]);

    const Tags = useMemo(() => {
        const getCheckedItems = currentFilter.filter(get("checked"));
        if (isEmpty(getCheckedItems)) return "";
        return getCheckedItems.map(({ label }) => t(label)).join(", ");
    }, [currentFilter, t]);

    const showDelayedDaysFilter = useMemo(() => {
        const notcompleted = Items.find((item) => item.referenceKey === "notcompleted");
        return notcompleted?.checked ?? false;
    }, [Items]);

    const setDelayedDays = useCallback(
        (number: string) => {
            dispatch(
                actions.updateSearchFilter({
                    value: isEmpty(number) ? null : parseInt(number, 10),
                    id: "daysActivityDelayed",
                    referenceKey: "daysActivityDelayed",
                }),
            );
        },
        [dispatch],
    );

    const onChange = useCallback(
        (value: string) => {
            const element = findTagByReferenceKey(value, currentFilter);
            if (!element) return;
            if (value !== "notcompleted") {
                setDelayedDays("");
            } else {
                setDelayedDays("14");
            }
            dispatch(actions.updateRadioSearchFilter(element));
        },
        [currentFilter, dispatch, setDelayedDays],
    );

    return (
        <ActivityStatusFilter
            items={Items}
            header={Header}
            tags={Tags}
            delayedText={t("delayedDays")}
            showDelayedDaysFilter={showDelayedDaysFilter}
            delayedDays={delayedDays?.value?.toString() ?? ""}
            setDelayedDays={(v) => setDelayedDays(v.target.value)}
            onChange={onChange}
        />
    );
}
