import Icon from "components/Icon";

interface IProps {
    header?: string | null;
    body?: string | string[] | null;
    archived?: string | boolean;
    employeeNumber?: string | null;
}
const ProfileRow = ({ header, body, archived, employeeNumber }: IProps) => {
    return (
        <div className="w-full flex items-start h-full">
            <div className="pr-2">
                <div className="bg-mhgrey-light rounded-full h-8 w-8 lg:h-10 lg:w-10 flex items-center justify-center">
                    <Icon icon="person" className="w-3 h-3 lg:w-4 lg:h-4" />
                </div>
            </div>
            <div className="flex flex-col text-left">
                <p className="text-mhdarkgreen-original text-sm lg:text-base font-bold">{header}</p>
                <div className="flex flex-wrap max-w-xs">
                    {body && (
                        <div className="text-mhgrey-dark opacity-75 break-all text-sm lg:text-base">{body[0]}</div>
                    )}
                    {employeeNumber && (
                        <>
                            <div className="px-1 text-mhgrey-dark opacity-75 text-sm lg:text-base">|</div>
                            <div className="text-mhgrey-dark opacity-75 break-all text-sm lg:text-base flex">
                                <div>{employeeNumber}</div>
                            </div>
                        </>
                    )}
                </div>
                {archived && (
                    <div>
                        <div>
                            <p className="bg-mhred-alert4 inline-block px-1 text-mhred-alert3 text-xs">{archived}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileRow;
