import * as React from "react";
import { useParams, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IRoute } from "routes/hooks";
import TabMenu from "components/TabMenu";

interface IProps {
    setCurrentRoute: (route: IRoute) => void;
    absenceReports: number;
    medicalCerts: number;
}
const AbsenceMenu = ({ setCurrentRoute, absenceReports, medicalCerts }: IProps) => {
    const { routeEmploymentGuid } = useParams();
    const { t } = useTranslation();
    const SubRoutesCard = React.useMemo(
        () => [
            {
                name: t("absence"),
                route: "absencereporting",
                testId: "absence-reporting-index-route-header",
                indexRoute: "absence",
                children: [
                    {
                        name: `${t("absence")} (${absenceReports})`,
                        route: "absence",
                        testId: "absence-reporting-subroute-header",
                        children: [],
                    },
                    {
                        name: `${t("medicalCertificate")} (${medicalCerts})`,
                        route: "medical-certificate",
                        testId: "absence-medicalcertificate-subroute-header",
                        children: [],
                    },
                ],
            },
        ],
        [absenceReports, medicalCerts, t],
    );

    return (
        <TabMenu
            routes={SubRoutesCard}
            subMenuTo="absencereporting"
            params={routeEmploymentGuid}
            setCurrentRoute={setCurrentRoute}
        >
            <Outlet />
        </TabMenu>
    );
};
export default AbsenceMenu;
