/* tslint:disable */
/* eslint-disable */
/**
 * MedHelp.Authorization.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface InitLoginResponse
 */
export interface InitLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof InitLoginResponse
     */
    'sessionGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof InitLoginResponse
     */
    'autoStartToken'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InitLoginResponse
     */
    'hasErrors'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InitLoginResponse
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'error'?: string | null;
}
/**
 * 
 * @export
 * @interface PingModel
 */
export interface PingModel {
    /**
     * 
     * @type {string}
     * @memberof PingModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PingModel
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface UserRight
 */
export interface UserRight {
    /**
     * 
     * @type {string}
     * @memberof UserRight
     */
    'userId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserRight
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserRight
     */
    'permission'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRight
     */
    'targetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRight
     */
    'scope'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserRight
     */
    'role'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserRight
     */
    'recursive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRight
     */
    'organisationId'?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [socialSecurityNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInitBankIdPost: async (socialSecurityNumber?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Auth/initBankId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (socialSecurityNumber !== undefined) {
                localVarQueryParameter['socialSecurityNumber'] = socialSecurityNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginBankIdPost: async (sessionId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Auth/loginBankId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [socialSecurityNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authInitBankIdPost(socialSecurityNumber?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authInitBankIdPost(socialSecurityNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authInitBankIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginBankIdPost(sessionId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginBankIdPost(sessionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authLoginBankIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [socialSecurityNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInitBankIdPost(socialSecurityNumber?: string, options?: any): AxiosPromise<InitLoginResponse> {
            return localVarFp.authInitBankIdPost(socialSecurityNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginBankIdPost(sessionId?: string, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.authLoginBankIdPost(sessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} [socialSecurityNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authInitBankIdPost(socialSecurityNumber?: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authInitBankIdPost(socialSecurityNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLoginBankIdPost(sessionId?: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLoginBankIdPost(sessionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JwtApi - axios parameter creator
 * @export
 */
export const JwtApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwtUserAccountIdGet: async (userAccountId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userAccountId' is not null or undefined
            assertParamExists('jwtUserAccountIdGet', 'userAccountId', userAccountId)
            const localVarPath = `/Jwt/{userAccountId}`
                .replace(`{${"userAccountId"}}`, encodeURIComponent(String(userAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JwtApi - functional programming interface
 * @export
 */
export const JwtApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JwtApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jwtUserAccountIdGet(userAccountId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jwtUserAccountIdGet(userAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JwtApi.jwtUserAccountIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JwtApi - factory interface
 * @export
 */
export const JwtApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JwtApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jwtUserAccountIdGet(userAccountId: number, options?: any): AxiosPromise<string> {
            return localVarFp.jwtUserAccountIdGet(userAccountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JwtApi - object-oriented interface
 * @export
 * @class JwtApi
 * @extends {BaseAPI}
 */
export class JwtApi extends BaseAPI {
    /**
     * 
     * @param {number} userAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwtApi
     */
    public jwtUserAccountIdGet(userAccountId: number, options?: RawAxiosRequestConfig) {
        return JwtApiFp(this.configuration).jwtUserAccountIdGet(userAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PingModel} [pingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPost: async (pingModel?: PingModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PingApi.pingGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PingModel} [pingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingPost(pingModel?: PingModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingPost(pingModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PingApi.pingPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet(options?: any): AxiosPromise<void> {
            return localVarFp.pingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PingModel} [pingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPost(pingModel?: PingModel, options?: any): AxiosPromise<void> {
            return localVarFp.pingPost(pingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingGet(options?: RawAxiosRequestConfig) {
        return PingApiFp(this.configuration).pingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PingModel} [pingModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingPost(pingModel?: PingModel, options?: RawAxiosRequestConfig) {
        return PingApiFp(this.configuration).pingPost(pingModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserRightApi - axios parameter creator
 * @export
 */
export const UserRightApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} organisationId 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRightOrganisationIdUserAccountIdGet: async (organisationId: string, userAccountId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('userRightOrganisationIdUserAccountIdGet', 'organisationId', organisationId)
            // verify required parameter 'userAccountId' is not null or undefined
            assertParamExists('userRightOrganisationIdUserAccountIdGet', 'userAccountId', userAccountId)
            const localVarPath = `/UserRight/{organisationId}/{userAccountId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)))
                .replace(`{${"userAccountId"}}`, encodeURIComponent(String(userAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {number} userAccountId 
         * @param {Array<UserRight>} [userRight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRightOrganisationIdUserAccountIdPost: async (organisationId: string, userAccountId: number, userRight?: Array<UserRight>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('userRightOrganisationIdUserAccountIdPost', 'organisationId', organisationId)
            // verify required parameter 'userAccountId' is not null or undefined
            assertParamExists('userRightOrganisationIdUserAccountIdPost', 'userAccountId', userAccountId)
            const localVarPath = `/UserRight/{organisationId}/{userAccountId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)))
                .replace(`{${"userAccountId"}}`, encodeURIComponent(String(userAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRight, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRightApi - functional programming interface
 * @export
 */
export const UserRightApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserRightApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} organisationId 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRightOrganisationIdUserAccountIdGet(organisationId: string, userAccountId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRight>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRightOrganisationIdUserAccountIdGet(organisationId, userAccountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRightApi.userRightOrganisationIdUserAccountIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {number} userAccountId 
         * @param {Array<UserRight>} [userRight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRightOrganisationIdUserAccountIdPost(organisationId: string, userAccountId: number, userRight?: Array<UserRight>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserRight>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRightOrganisationIdUserAccountIdPost(organisationId, userAccountId, userRight, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserRightApi.userRightOrganisationIdUserAccountIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserRightApi - factory interface
 * @export
 */
export const UserRightApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserRightApiFp(configuration)
    return {
        /**
         * 
         * @param {string} organisationId 
         * @param {number} userAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRightOrganisationIdUserAccountIdGet(organisationId: string, userAccountId: number, options?: any): AxiosPromise<Array<UserRight>> {
            return localVarFp.userRightOrganisationIdUserAccountIdGet(organisationId, userAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {number} userAccountId 
         * @param {Array<UserRight>} [userRight] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRightOrganisationIdUserAccountIdPost(organisationId: string, userAccountId: number, userRight?: Array<UserRight>, options?: any): AxiosPromise<Array<UserRight>> {
            return localVarFp.userRightOrganisationIdUserAccountIdPost(organisationId, userAccountId, userRight, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserRightApi - object-oriented interface
 * @export
 * @class UserRightApi
 * @extends {BaseAPI}
 */
export class UserRightApi extends BaseAPI {
    /**
     * 
     * @param {string} organisationId 
     * @param {number} userAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRightApi
     */
    public userRightOrganisationIdUserAccountIdGet(organisationId: string, userAccountId: number, options?: RawAxiosRequestConfig) {
        return UserRightApiFp(this.configuration).userRightOrganisationIdUserAccountIdGet(organisationId, userAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organisationId 
     * @param {number} userAccountId 
     * @param {Array<UserRight>} [userRight] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRightApi
     */
    public userRightOrganisationIdUserAccountIdPost(organisationId: string, userAccountId: number, userRight?: Array<UserRight>, options?: RawAxiosRequestConfig) {
        return UserRightApiFp(this.configuration).userRightOrganisationIdUserAccountIdPost(organisationId, userAccountId, userRight, options).then((request) => request(this.axios, this.basePath));
    }
}



