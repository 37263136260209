import "react-day-picker/dist/style.css";

import { format } from "date-fns";

import Icon from "components/Icon";
import { DateRange } from "react-day-picker";
import { getDateLocale } from "utils/date";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

type DateTabProps = {
    isMandatory: boolean;
    heading?: string;
    headingTo?: string;
    setOpen: (open: boolean) => void;
    open: boolean;
    setTabSelected: (tabSelected: string) => void;
    tabSelected: string;
    selected: DateRange | undefined;
    className?: string;
    setDefaultMonth: (month: Date) => void;
    setFirstClick: (firstClick: boolean) => void;
    edit?: boolean;
};

const DateTabs = ({
    isMandatory,
    heading,
    headingTo,
    setOpen,
    open,
    setTabSelected,
    tabSelected,
    selected,
    className,
    setDefaultMonth,
    setFirstClick,
    edit,
}: DateTabProps) => {
    const language = useAppSelector(UserSelectors.getUserLanguage);
    const locale = getDateLocale(language);
    const { t } = useTranslation("support");

    const handleMonthSelectedAndTabSelected = (month: Date | undefined, selectTab: string) => () => {
        month && setDefaultMonth(month);
        setTabSelected(selectTab);

        if (edit || selectTab === "to") {
            setFirstClick(true);
        }
    };

    return (
        <div className={twMerge("max-w-full", className)}>
            <div className="h-14 flex ">
                <div className="flex flex-grow items-center">
                    <span className="font-bold text-sm xxs:text-base">{heading}</span>
                    {isMandatory && <div className="ml-1 text-xl xxs:text-xl leading-6 text-mhred-alert2">*</div>}
                </div>
                <div className="flex flex-grow items-center text-sm xxs:text-base">
                    <span className="font-bold">{headingTo}</span>
                    {isMandatory && <div className="ml-1 text-xl xxs:text-xl leading-6 text-mhred-alert2">*</div>}
                </div>
            </div>
            <div onClick={() => setOpen(true)} className=" h-14 flex">
                <div
                    onClick={handleMonthSelectedAndTabSelected(selected?.from, "from")}
                    className={`flex flex-grow p-4  items-center ${open && tabSelected === "from" ? "bg-inputDatepicker" : "border border-black"} ${tabSelected === "from" ? "bg-inputDatepicker text-white" : ""}  `}
                    data-testid={`range-datepicker-from-tab-value`}
                >
                    {selected?.from ? (
                        format(selected?.from, "P", { locale: locale })
                    ) : (
                        <>
                            <Icon
                                icon="calendar"
                                size={18}
                                className={tabSelected === "from" ? "text-white" : "text-black"}
                                strokeWidth={2}
                            />
                            <span className="pl-2 text-xs md:text-base">{t("select")}</span>
                        </>
                    )}
                </div>
                <div
                    onClick={handleMonthSelectedAndTabSelected(selected?.to, "to")}
                    className={` flex flex-grow p-4 items-center ${open && tabSelected === "to" ? "bg-inputDatepicker" : "border border-black"} ${tabSelected === "to" ? "bg-inputDatepicker text-white" : ""} `}
                    data-testid={`range-datepicker-to-tab-value`}
                >
                    {selected?.to ? (
                        format(selected?.to, "P", { locale: locale })
                    ) : (
                        <>
                            <Icon
                                icon="calendar"
                                size={18}
                                className={tabSelected === "to" ? "text-white" : "text-black"}
                                strokeWidth={2}
                            />
                            <span className="pl-2 text-xs md:text-base">{t("select")}</span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DateTabs;
