import { Trans, useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { TemporarySubscription } from "swagger/messenger";
import SearchModal from "components/SearchModal";
import SearchResult from "components/SearchResult";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";

interface IProps {
    userManagerName: string;
    searchResult: TemporarySubscription[] | null;
    selectedColleagues: TemporarySubscription[];
    clearSearch: boolean;
    setClearSearch: Dispatch<SetStateAction<boolean>>;
    handleOnSearchInputChange: (query: string) => void;
    renderSearchResults: (searchItem: TemporarySubscription) => JSX.Element;
    removeColleagueToNotify: (colleague: TemporarySubscription) => void;
    handleSendNotification: () => void;
    handleOnClick: () => void;
}

const AbsenceNotificationWizardView = (props: IProps) => {
    const {
        userManagerName,
        searchResult,
        selectedColleagues,
        clearSearch,
        setClearSearch,
        handleOnSearchInputChange,
        renderSearchResults,
        removeColleagueToNotify,
        handleSendNotification,
        handleOnClick,
    } = props;
    const { t } = useTranslation();

    return (
        <div
            id="absence-notification-wizard-content"
            className="flex flex-col xs:w-full md:w-10/12 flex-grow wizard-container-notify-colleagues relative"
        >
            <div className="flex flex-col">
                <div className="border border-mhgrey-medium px-8 pt-8 pb-6 mb-16">
                    <div className="flex">
                        <h2>{t("notifyColleagueHeading.createReport")}</h2>
                    </div>
                    <div className="flex flex-col xxl:flex-row gap-12 pt-4">
                        <p className="w-full">
                            <Trans
                                i18nKey="notifyColleagueText.createReport"
                                values={{
                                    closestManager: userManagerName,
                                }}
                            />
                        </p>
                        <div className="w-full">
                            <SearchModal
                                id="searchAndResultSearchModal"
                                preset="medium"
                                searchHistory={[]}
                                searchResult={searchResult}
                                isLoading={false}
                                onInputChange={handleOnSearchInputChange}
                                placeholder={t("addColleagueToNotify")}
                                searchResultItemComponent={renderSearchResults}
                                searchResultTitle={t("suggestedColleagues")}
                                modalOverlay={false}
                                clearSearch={clearSearch}
                                setClearSearch={setClearSearch}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="py-2 text-grey-600 border-b border-grey-200" key="selectedColleagues">
                    <span className="font-bold">{selectedColleagues.length} </span>
                    <span>{selectedColleagues.length === 1 ? t("colleagueSelected") : t("colleaguesSelected")}</span>
                </div>
                {selectedColleagues.map((selectedSearchItem: any) => (
                    <div
                        key={`${selectedSearchItem.id}-search-result-colleague`}
                        className="w-full flex flex-col justify-between xs:flex-row xs:items-center px-6 border-b border-grey-200 py-5 z-60"
                    >
                        <SearchResult
                            id={selectedSearchItem.id ?? ""}
                            icon="person"
                            header={selectedSearchItem.name ?? ""}
                            searchResultItems={[
                                selectedSearchItem.name ?? "",
                                `${t("department")}: ${selectedSearchItem.name}`,
                            ]}
                        />
                        <SecondaryButton
                            id="add-remove-colleague"
                            text={t("delete")}
                            className="w-23 px-3 mx-12 xs:mx-0 justify-center"
                            onClick={() => removeColleagueToNotify(selectedSearchItem)}
                        />
                    </div>
                ))}
            </div>
            <div>
                <div className="flex flex-col xs:flex-row pt-15 xs:pt-20">
                    <SecondaryButton
                        id="searchSecondaryButton"
                        text={t("close")}
                        onClick={handleOnClick}
                        className="w-full xs:w-auto flex justify-center m-1"
                    />
                    <PrimaryButton
                        id="searchPrimaryButton"
                        text={t("sendNotification")}
                        disabled={selectedColleagues?.length === 0}
                        onClick={() => {
                            handleSendNotification();
                        }}
                        className="w-full xs:w-auto flex justify-center m-1"
                    />
                </div>
            </div>
        </div>
    );
};

export default AbsenceNotificationWizardView;
