import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useSubmitAccessRights from "pages/service/hooks/useSubmitAccessRights";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { IAccessRightConfiguration } from "pages/service/redux/serviceAsyncActions";
import { ServiceCheckbox, ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import { getEmploymentManagerAccessRights } from "pages/service/redux/serviceSelectors";

const EmploymentManagerAccessRights = () => {
    const { t } = useTranslation("service");
    const employmentManagerAccessRights = useAppSelector(getEmploymentManagerAccessRights);
    const [submitAccessRights, isLoading] = useSubmitAccessRights();

    const {
        handleSubmit,
        reset,
        resetField,
        getValues,
        setValue,
        register,
        formState: { isDirty, dirtyFields },
    } = useForm({
        defaultValues: { employmentManagerAccessRights },
    });

    useEffect(() => {
        reset({ employmentManagerAccessRights });
    }, [employmentManagerAccessRights, reset]);

    const onSubmit = (data: { employmentManagerAccessRights: IAccessRightConfiguration[] }) => {
        submitAccessRights(
            data.employmentManagerAccessRights,
            dirtyFields,
            //@ts-ignore
            resetField,
            "employmentManagerAccessRights",
        );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("employmentManagerAccessRights")}
                reset={() => reset({ employmentManagerAccessRights })}
                disabled={!isDirty}
                isLoading={isLoading}
                content={employmentManagerAccessRights?.map(
                    ({ checked, accessRightType }) =>
                        checked && <AccordionBooleanContent key={accessRightType} label={t(accessRightType)} />,
                )}
            >
                <ServiceCheckboxContainer
                    heading={t("accessRight")}
                    checkboxHeading={t("offOn")}
                    secondaryCheckBoxHeading={t("hierarchical")}
                >
                    {employmentManagerAccessRights?.map((item, index) => (
                        <ServiceCheckboxRow key={item.accessRightType} label={t(item.accessRightType || "")}>
                            <ServiceCheckbox
                                register={register(`employmentManagerAccessRights.${index}.checked`, {
                                    onChange: (e) =>
                                        !e.target.checked &&
                                        setValue(`employmentManagerAccessRights.${index}.hierarchical`, false),
                                })}
                                disabled={item.disabled}
                            />
                            <fieldset disabled={!getValues(`employmentManagerAccessRights.${index}.checked`)}>
                                <ServiceCheckbox
                                    register={register(`employmentManagerAccessRights.${index}.hierarchical`)}
                                />
                            </fieldset>
                        </ServiceCheckboxRow>
                    ))}
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default EmploymentManagerAccessRights;
