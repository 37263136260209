import { useAppDispatch } from "store/hooks";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import { MedHelpHCMDomainTemplateFormDocument } from "swagger/healthCaseManagement";
import { saveDocumentsTemplatesByTemplateId } from "pages/service/redux/serviceAsyncActions";

interface ITemplateFormDocuments {
    templateId: string;
    documentTemplates: (MedHelpHCMDomainTemplateFormDocument & { checked: boolean })[];
}

const TemplateFormDocuments = ({ templateId, documentTemplates }: ITemplateFormDocuments) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const [isLoading, setIsLoading] = useState(false);

    const {
        reset,
        watch,
        register,
        formState: { isDirty },
    } = useForm({ defaultValues: { documentTemplates } });

    useEffect(() => {
        reset({ documentTemplates });
    }, [documentTemplates, reset]);

    const saveDocumentTemplates = () => {
        setIsLoading(true);

        const documentTemplateIds = watch("documentTemplates")
            ?.filter((x) => x.checked)
            .map((y) => y.id || "");

        dispatch(saveDocumentsTemplatesByTemplateId({ templateId, documentTemplateIds }))
            .then(() => {
                setIsLoading(false);
                toast(t("saved"));
            })
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                reset({ documentTemplates });
                setIsLoading(false);
            });
    };

    return (
        <>
            <ServiceCheckboxContainer heading={t("documentTemplates")}>
                {documentTemplates.map(({ id, title, ownerReferenceType }, index) => (
                    <ServiceCheckboxRow
                        key={id}
                        label={`${title} (${ownerReferenceType})` || ""}
                        register={register(`documentTemplates.${index}.checked`)}
                    />
                ))}
            </ServiceCheckboxContainer>
            <div className="flex justify-center">
                <PrimaryButton
                    id="addDocumentTemplate"
                    onClick={saveDocumentTemplates}
                    isLoading={isLoading}
                    disabled={!isDirty}
                >
                    {t("save")}
                </PrimaryButton>
            </div>
        </>
    );
};

export default TemplateFormDocuments;
