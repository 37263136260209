import { useEffect } from "react";
import ErrorWidget from "../ErrorWidget";
import { ForecastChart } from "../../Charts";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import AbsenceForecastCurrentMonthLoader from "./AbsenceForecastCurrentMonthLoader";
import { getAbsenceForecastCurrentMonth, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const AbsenceForecastCurrentMonth = ({ id, type }: Widget) => {
    const dispatch = useAppDispatch();
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getAbsenceForecastCurrentMonth(id));

    useEffect(() => {
        !isDragging && dispatch(fetchWidgetData({ id, type }));
    }, [dispatch, id, type, isDragging]);

    if (status === Status.PENDING) {
        return <AbsenceForecastCurrentMonthLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return <ForecastChart {...data} />;
};

export default AbsenceForecastCurrentMonth;
