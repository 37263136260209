import IconModal from "components/IconModal";
import PhoneNumberComponent from "components/PhoneNumberComponent";
import { useTranslation } from "react-i18next";

interface IDynamicMobileNumberProps {
    header?: string;
    disabled?: boolean;
    inputValue: string;
    onChange: (value: string) => void;
    infoModalHeading?: string;
    infoModalText?: string;
    isMandatory?: boolean;
    countryHeader: string;
    phoneNumberHeader: string;
}

const DynamicMobileNumber = ({
    header,
    disabled,
    inputValue,
    onChange,
    infoModalHeading,
    infoModalText,
    isMandatory,
    countryHeader,
    phoneNumberHeader,
}: IDynamicMobileNumberProps) => {
    const { t } = useTranslation("login");

    return (
        <div id="dynamic-mobile-container" className="flex flex-col">
            <div className="flex items-center">
                {header && <p>{header}</p>}
                {infoModalHeading && (
                    <IconModal
                        id="DynamicMobileNumber"
                        heading={infoModalHeading}
                        topContent={infoModalText!}
                        isMandatory={isMandatory}
                    />
                )}
            </div>
            <p className="mb-5">{t("leavePhoneNumberReason")}</p>
            <PhoneNumberComponent
                value={inputValue}
                onChange={onChange}
                countryHeader={countryHeader}
                phoneNumberHeader={phoneNumberHeader}
                useCustomCountryList
                validateFields
                disabled={disabled}
                showMandatory={isMandatory}
            />
        </div>
    );
};

export default DynamicMobileNumber;
