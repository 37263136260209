import { useMemo, useState } from "react";
import { Accordion } from "@medhelp/ui";
import { useTranslation } from "react-i18next";
import { SearchTypes } from "../../utils";
import FilterHeading from "../FilterHeading";
import DropdownFilter, { IData } from "../DropdownFilter";

const BasedOnDropdown = () => {
    const { t } = useTranslation("followup");

    const Text = useMemo(() => <p>{t("showBasedOn")}</p>, [t]);
    const Data: IData[] = useMemo(
        () => [
            { text: t("employee"), name: "employee", field: "showBasedOn" },
            {
                text: t("numberOfAbsenceOccasions"),
                name: "numberOfAbsenceOccasions",
                field: "showBasedOn",
            },
        ],
        [t],
    );
    const Item = useMemo(
        () => (name: string) => <div className="text-left pl-2 hover:bg-mhgrey-light py-2 text-base">{name}</div>,
        [],
    );
    const Header = useMemo(() => (name: string) => <div className="text-left text-base">{t(name)}</div>, [t]);
    return (
        <DropdownFilter
            preset="secondary"
            text={Text}
            className="flex-col px-7"
            item={Item}
            customHeader={Header}
            data={Data}
            type="showBasedOn"
        />
    );
};

const AffectedDepartmentsDropdown = () => {
    const { t } = useTranslation("followup");

    const Text = useMemo(() => <p>{t("showDepartments")}</p>, [t]);
    const Data: IData[] = useMemo(
        () => [
            {
                text: t("everyDepartment"),
                name: "everyDepartment",
                field: "showDepartments",
            },
            {
                text: t("onlyLatestDepartment"),
                name: "onlyLatestDepartment",
                field: "showDepartments",
            },
        ],
        [t],
    );
    const Item = useMemo(
        () => (name: string) => <div className="text-left pl-2 hover:bg-mhgrey-light py-2 text-base">{name}</div>,
        [],
    );

    const Header = useMemo(() => (name: string) => <div className="text-left text-base">{t(name)}</div>, [t]);
    return (
        <DropdownFilter
            preset="secondary"
            text={Text}
            className="flex-col px-7"
            item={Item}
            customHeader={Header}
            data={Data}
            type="showDepartments"
        />
    );
};
interface IProps {
    header: string;
    body: string;
    searchType: SearchTypes;
}
const SortingAndShowFilter = ({ header, body, searchType }: IProps) => {
    const [open, setOpen] = useState(false);
    if (searchType === "absencePeriod" || searchType === "ongoingAbsence") {
        return (
            <Accordion
                heading={<FilterHeading header={header} body={body} open={open} />}
                icon="caretFull"
                setOpen={setOpen}
                open={open}
            >
                <div className="flex flex-col gap-6 mt-6">
                    <>
                        {searchType === "absencePeriod" && <BasedOnDropdown />}
                        <AffectedDepartmentsDropdown />
                    </>
                </div>
            </Accordion>
        );
    }
    return <></>;
};

export default SortingAndShowFilter;
