/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IAbsenceReportSlice } from "../absenceReportsSlice";
import { getTemporarySubscriptions, sendTemporarySubscriptions } from "../absenceReportsActions";

const temporarySubscriptions = (builder: ActionReducerMapBuilder<IAbsenceReportSlice>) =>
    builder
        .addCase(getTemporarySubscriptions.fulfilled, (state, action) => {
            state.TemporarySubscriptions = action.payload;
        })
        .addCase(getTemporarySubscriptions.rejected, (state) => {
            state.TemporarySubscriptions = [];
        })
        .addCase(sendTemporarySubscriptions.fulfilled, (state) => {
            state.SendAbsenceNotificationIsError = false;
        })
        .addCase(sendTemporarySubscriptions.rejected, (state) => {
            state.SendAbsenceNotificationIsError = true;
        });

export default temporarySubscriptions;
