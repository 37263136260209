import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { getTemplateFormDocuments } from "../../../redux/followUpSelectors";
import { MedHelpHCMDomainTemplateFormDocument } from "swagger/healthCaseManagement";
import { Search, Select } from "@medhelp/ui";
import Icon from "components/Icon";

interface IProps {
    setSelectedTemplate: (v: MedHelpHCMDomainTemplateFormDocument) => void;
    type?: string | null;
}

const CaseTemplateModalContent = (props: IProps) => {
    const { type, setSelectedTemplate } = props;
    const { t } = useTranslation("followup");
    const templateFormDocuments = useAppSelector(getTemplateFormDocuments(type));
    const [searchInput, setSearchInput] = useState("");
    const [language, setLanguage] = useState({
        description: t("all"),
        value: "all",
    });

    const languageOptions = [
        {
            description: t("all"),
            value: "all",
        },
        {
            description: t("svLanguage"),
            value: "sv",
        },
        {
            description: t("enLanguage"),
            value: "en",
        },
    ];

    const filteredTemplateFormDocuments = () => {
        const filteredByLanguage =
            language.value === "all"
                ? templateFormDocuments
                : templateFormDocuments.filter((x) => x?.language?.toLowerCase() === language.value);
        const filteredBySearch = filteredByLanguage.filter((x) =>
            x?.title?.toLowerCase().includes(searchInput.toLowerCase()),
        );
        const filteredAndTranslated = filteredBySearch.map((x) => {
            return {
                ...x,
                language: t(`${x?.language?.toLowerCase()}Language`),
            };
        });
        return filteredAndTranslated;
    };

    return (
        <div className="px-3 sm:px-0">
            <div className="flex mb-12">
                <Search
                    key="case-template-modal-search"
                    placeholder={t("searchTemplate")}
                    onChange={(e) => setSearchInput(e.target.value)}
                    className="h-11 mr-3"
                />
                <Select
                    options={languageOptions}
                    selectedValue={{ ...language, icon: <Icon icon="globe" /> }}
                    onChange={setLanguage}
                    className="h-11"
                />
            </div>
            {filteredTemplateFormDocuments().map((template, i) => (
                <button
                    key={`case-template-modal-${template.id}`}
                    onClick={() => setSelectedTemplate(template)}
                    className={`flex items-center p-4 w-full border-b ${i === 0 && "border-t"}`}
                >
                    <Icon icon="documentLarge" size={32} className="mr-8" />
                    <div className={"flex flex-col items-start"}>
                        <p className="font-bold mb-1 text-left">{template.title}</p>
                        <div className="flex">
                            <div className="flex items-center bg-mhgrey-light mr-2 px-2 rounded">
                                <Icon icon="globe" size={10} />
                                <p className="pl-1 text-xs">{template.language}</p>
                            </div>
                            <div className="flex items-center bg-mhgrey-light mr-2 px-2 rounded">
                                <p className="text-xs">{template.ownerReferenceType}</p>
                            </div>
                        </div>
                    </div>
                </button>
            ))}
        </div>
    );
};

export default CaseTemplateModalContent;
