import { IEmploymentSearchResult } from "interfaces/IEmploymentSearchResult";
import { Guid } from "utils/guid";

export const employmentSearchResultIsValid = (
    employmentSearchResult: IEmploymentSearchResult,
    routeEmploymentGuid: string | undefined,
): boolean =>
    employmentSearchResult &&
    employmentSearchResult.referenceKey &&
    Guid.isValid(employmentSearchResult.referenceKey) &&
    employmentSearchResult.referenceKey !== routeEmploymentGuid
        ? true
        : false;
