import { CardWithExpand, LoadableComponent } from "@medhelp/ui";
import { useBreakpoints } from "hooks";
import { flow } from "lodash/fp";
import { useCallback, useState } from "react";
import { MedHelpAbsenceFollowUpWebApiResponseAbsence } from "swagger/absencefollowup";
import { Status, thunkActions } from "../../redux";
import { getHasBaseProduct } from "store/userSelectors";
import ActivitiesLogs from "../ActivitiesLogs";
import FollowUpAbsenceColumnText from "../FollowUpAbsenceColumnText";
import FollowUpActivityLogsMobileHeader from "../FollowUpActivityLogsMobileHeader";
import { useAppSelector } from "store/hooks";

interface IListAbsenceInfo extends MedHelpAbsenceFollowUpWebApiResponseAbsence {
    header: string;
    list: ({ header?: string; body?: string; type?: string } | null)[];
}

interface IFollowUpAbsenceItem {
    absenceData: IListAbsenceInfo | null;
    index: number;
    getAbsenceActivityLogsStatus: (absenceReportId: string) => Status;
    getAbsenceActivityLogs: (absenceReportId: string) => thunkActions.IActivityLog[];
    onExpand: (absenceReportId?: string | undefined) => (epxanded: boolean) => boolean;
    absenceReportId?: string;
}

const FollowUpAbsenceItem = ({
    absenceData,
    index,
    getAbsenceActivityLogs,
    getAbsenceActivityLogsStatus,
    onExpand,
    absenceReportId,
}: IFollowUpAbsenceItem) => {
    const hasBaseProduct = useAppSelector(getHasBaseProduct);
    const [isExpanded, setIsExpandedState] = useState(false);
    const breakpoints = useBreakpoints();
    const setIsExpanded = useCallback((expanded: boolean) => {
        if (expanded) {
            setIsExpandedState(true);
        } else {
            setIsExpandedState(false);
        }
        return expanded;
    }, []);
    const Header = useCallback(
        (absenceData: IListAbsenceInfo) =>
            breakpoints.isSmallscreen ? (
                <FollowUpActivityLogsMobileHeader
                    type={absenceData.type}
                    header={absenceData.header}
                    endDate={absenceData.endDate}
                    degree={absenceData.currentDegree}
                    degrees={absenceData.degrees}
                    properties={absenceData.properties}
                    showWorkRelated={hasBaseProduct ? false : isExpanded}
                />
            ) : (
                <FollowUpAbsenceColumnText
                    header={absenceData.header}
                    list={absenceData.list}
                    absenceReportId={absenceReportId}
                />
            ),
        [absenceReportId, breakpoints.isSmallscreen, hasBaseProduct, isExpanded],
    );
    if (!absenceData) return <></>;
    return (
        <>
            <CardWithExpand
                key={`${absenceData.absenceReportId}-absencelist-${index}`}
                index={index}
                headerView={Header(absenceData)}
                onExpand={flow(onExpand(absenceData?.absenceReportId), setIsExpanded)}
            >
                <LoadableComponent
                    status={getAbsenceActivityLogsStatus(absenceData.absenceReportId || "")}
                    errorMessage="Error loading activities for absenceReport"
                >
                    <ActivitiesLogs data={getAbsenceActivityLogs(absenceData.absenceReportId || "")} />
                </LoadableComponent>
            </CardWithExpand>
        </>
    );
};
export default FollowUpAbsenceItem;
