import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IModalSlice {
    allModals: boolean;
}

const initialModalState: IModalSlice = {
    allModals: false,
};

export type ModalTypes = "allModals";

export interface ISetModal {
    type: ModalTypes;
    value: boolean;
}

const modalSlice = createSlice({
    name: "modal",
    initialState: initialModalState,
    reducers: {
        showModal(state, action: PayloadAction<ModalTypes>) {
            state[action.payload] = true;
        },
        hideModal(state, action: PayloadAction<ModalTypes>) {
            state[action.payload] = false;
        },
        setModal(state, action: PayloadAction<ISetModal>) {
            state[action.payload.type] = action.payload.value;
        },
    },
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;
