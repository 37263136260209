import { useMemo } from "react";
import { FindDynamicComponent } from "components/Dynamic";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { useTranslation } from "react-i18next";
import { getAbsenceReportFieldValueByName } from "../../utils/absenceReports";
import {
    MedHelpAbsenceReportingDomainFieldTemplate,
    MedHelpAbsenceReportingDomainOptionField,
    MedHelpAbsenceReportingDomainTemplate,
} from "swagger/absencereportapi";
import AbsenceReportFieldWrapper from "../AbsenceReportFieldWrapper";
import WizardHeader from "components/WizardHeader";
import RadioButtonGroup from "components/RadioButtonGroup";
import PrimaryButton from "components/PrimaryButton";
import GetWidget from "../GetWidget";
import Icon from "components/Icon";
import { ISingleDatepickerProps, IDateTimepickerProps } from "@medhelp/ui/Datepicker/types";

interface IProps {
    currentFields: MedHelpAbsenceReportingDomainFieldTemplate[] | null;
    template: MedHelpAbsenceReportingDomainTemplate | undefined;
    selectedAbsenceReport: IAbsenceReportExtended | null;
    mandatoryFieldsSelected: boolean | undefined;
    showAbsenceCauseToEmployer: "true" | "false" | null;
    handleUpdateField: (fieldName: string, value: string) => void;
    forwardClick: () => void;
    isLastPage: () => boolean;
    datepickerProps: ISingleDatepickerProps;
    dateTimepickerProps: IDateTimepickerProps;
}
const AbsenceReportTemplateFields = ({
    currentFields,
    template,
    selectedAbsenceReport,
    mandatoryFieldsSelected,
    showAbsenceCauseToEmployer,
    handleUpdateField,
    forwardClick,
    isLastPage,
    datepickerProps,
    dateTimepickerProps,
}: IProps): JSX.Element => {
    const { t } = useTranslation();

    const getTemplateField = (fields: number, field: MedHelpAbsenceReportingDomainFieldTemplate): JSX.Element => {
        if (field.widget && selectedAbsenceReport && template?.fields) {
            return (
                <GetWidget
                    absenceReport={selectedAbsenceReport}
                    template={template}
                    allFields={template.fields}
                    templateField={field}
                    absenceReportFields={selectedAbsenceReport?.fields ?? []}
                />
            );
        }
        if (fields > 1 && !field.widget) {
            return (
                <AbsenceReportFieldWrapper
                    heading={field.header!}
                    isMandatory={field.mandatory!}
                    modalHeading={field.header!}
                    modalTopContent={field.description!}
                >
                    <FindDynamicComponent
                        templateField={field}
                        value={getAbsenceReportFieldValueByName(field.name!, selectedAbsenceReport?.fields ?? [])}
                        disabled={!selectedAbsenceReport}
                        updateField={(value: string) => {
                            handleUpdateField(field.name!, value);
                        }}
                        datepickerProps={datepickerProps}
                        dateTimepickerProps={dateTimepickerProps}
                    />
                </AbsenceReportFieldWrapper>
            );
        } else {
            if (field.name === "AbsenceCause" && !field.widget) {
                return (
                    <RadioButtonGroup
                        id="RadioButtonGroup"
                        values={
                            field?.options?.map((option: MedHelpAbsenceReportingDomainOptionField) => {
                                return {
                                    value: option.value!,
                                    description: option.description!,
                                };
                            })!
                        }
                        selectedValue={getAbsenceReportFieldValueByName(
                            field.name!,
                            selectedAbsenceReport?.fields ?? [],
                        )}
                        clicked={(value) => {
                            handleUpdateField(field.name!, value!);
                        }}
                    />
                );
            } else {
                return (
                    <FindDynamicComponent
                        templateField={field}
                        value={getAbsenceReportFieldValueByName(field.name!, selectedAbsenceReport?.fields ?? [])}
                        disabled={!selectedAbsenceReport}
                        updateField={(value: string) => {
                            handleUpdateField(field.name!, value);
                        }}
                        datepickerProps={datepickerProps}
                        dateTimepickerProps={dateTimepickerProps}
                    />
                );
            }
        }
    };

    const header = useMemo(() => {
        const hasDkConsent = currentFields?.find((x) => x.widget === "absenceCauseDK" && x.name === "Consent");
        if (hasDkConsent) {
            return hasDkConsent.header;
        }
        return currentFields && currentFields[0].header;
    }, [currentFields]);

    const getWizardSubHeaderText = (): string => {
        if (!currentFields) {
            return "";
        }
        return currentFields[0].header! === currentFields[0].description! || currentFields.length > 1
            ? ""
            : currentFields[0].description!;
    };

    const currentPageIsMandatory = (): boolean => {
        const mandatoryFields = currentFields?.filter((template) => template.mandatory === true) ?? [];
        return mandatoryFields.length > 0 ? true : false;
    };

    const primaryButton = (className: string) => (
        <PrimaryButton
            id="next-button"
            text={isLastPage() ? t("registerReport") : t("next")}
            rightIcon={<Icon icon="checkMark" stroke="currentcolor" />}
            className={className}
            onClick={forwardClick}
        />
    );
    return (
        <div className="flex flex-col w-full  flex-grow relative max-xxs:pb-10 bg-pageBg">
            <div className="flex flex-col md:w-10/12 lg:w-[624px]">
                <WizardHeader
                    isMandatory={currentPageIsMandatory()}
                    heading={header}
                    subHeading={getWizardSubHeaderText()}
                />
                <div className="flex flex-col justify-center pt-10">
                    {currentFields?.map((field) => (
                        <div key={field.name}>
                            {getTemplateField(currentFields?.length || 0, field)}
                            {field.name === "AbsenceCause" && !field.widget && (
                                <AbsenceReportFieldWrapper
                                    heading={t("confidential")}
                                    isMandatory={!field.confidential!}
                                >
                                    <RadioButtonGroup
                                        isHorizontal
                                        disabled={
                                            getAbsenceReportFieldValueByName(
                                                field.name!,
                                                selectedAbsenceReport?.fields ?? [],
                                            ) === ""
                                        }
                                        clicked={(value: any) => {
                                            handleUpdateField("AbsenceCause", value);
                                        }}
                                        selectedValue={showAbsenceCauseToEmployer}
                                        values={[
                                            {
                                                description: t("yes"),
                                                value: "true",
                                            },
                                            {
                                                description: t("no"),
                                                value: "false",
                                            },
                                        ]}
                                        id="confidential-radio-buttons"
                                    />
                                </AbsenceReportFieldWrapper>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            {mandatoryFieldsSelected && (
                <div className="flex flex-col flex-grow">
                    <div className="flex-grow xxs:h-full xxs:flex xxs:flex-col xxs:justify-between">
                        {primaryButton("hidden fit-content  mt-16 mb-6 xxs:flex")}
                        <div className="flex fixed bottom-0 right-0 left-0 h-24 shadow-top xxs:hidden bg-mhbeige">
                            {primaryButton("full-width-important flex justify-center self-center mx-5 h-14 xxs:hidden")}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AbsenceReportTemplateFields;
