import { MedHelpPeopleDomainContent } from "swagger/people";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServices } from "api/services";
import * as UserSelectors from "store/userSelectors";
import { RootState } from "store";

export const fetchPrivacyPolicy = createAsyncThunk(
    "fetchPrivacyPolicy",
    async (_, { getState }): Promise<MedHelpPeopleDomainContent> => {
        const state = getState() as RootState;
        const region = UserSelectors.getRegion(state);
        const language = state.user.userContextExtended?.user?.userPreferences?.language ?? "en";

        const { data } = await getServices().clients.content.contentV2Get("about", "privacyPolicy", language, region);
        return data;
    },
);
