import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ServiceSelect from "../ServiceSelect";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ServiceInputContainer } from "../ServiceInput";
import { CompanySelectedEmpAbsence } from "swagger/businessupport";
import { getClassification } from "pages/service/redux/serviceSelectors";
import { saveCompanyInfo } from "pages/service/redux/serviceAsyncActions";
import { ServiceAccordion, AccordionStringContent } from "../ServiceAccordion";
import { industryCodeTypes } from "pages/service/utils/serviceTypes";

const Classification = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const classification = useAppSelector(getClassification);
    const { industryCode } = classification;
    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm({ defaultValues: classification });

    useEffect(() => {
        reset(classification);
    }, [classification, reset]);

    const onSubmit = (data: CompanySelectedEmpAbsence) => {
        setIsLoading(true);
        dispatch(saveCompanyInfo(data))
            .then(unwrapResult)
            .then(() => {
                setIsLoading(false);
                toast(t("saved"));
            })
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                reset(classification);
                setIsLoading(false);
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("classification")}
                reset={() => reset(classification)}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <>
                        <AccordionStringContent
                            heading={t("industryCode")}
                            value={t(`industryCodeKey.${industryCode}`)}
                        />
                    </>
                }
            >
                <ServiceInputContainer>
                    <ServiceSelect
                        label={t("industryCode")}
                        register={register("industryCode")}
                        options={industryCodeTypes.map((industryCode) => ({
                            value: industryCode,
                            label: t(`industryCodeKey.${industryCode}`),
                        }))}
                    />
                </ServiceInputContainer>
            </ServiceAccordion>
        </form>
    );
};

export default Classification;
