import { NewsModal } from "@medhelp/ui";
import { Retailers } from "globalTypes";

export interface IProps {
    header: string;
    body: string | string[];
    buttonText: string;
    storage?: string;
}
interface IViewProps extends IProps {
    theme: Retailers;
}
const FollowUpNewsModal = ({ header, body, buttonText, storage, theme }: IViewProps) => (
    <NewsModal storage={storage} header={header} body={body} buttonText={buttonText} theme={theme} />
);

export default FollowUpNewsModal;
