import { useCallback, useEffect, useMemo } from "react";
import Employment from "./Employment";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
    getBelongingProps,
    getEmploymentInfoProps,
    getMainManagerSearchProps,
    getSaveButtonLoading,
    getSelectedCompanyId,
    getSelectedCompanyUserPrimaryCompanyId,
    getSelectedUserAccountId,
    getUserEmploymentProps,
    getUserEmploymentData,
    getUsersIsLoading,
    getSaveOrUpdateSuccessful,
    getEmploymentIsDirty,
    getUserEmployment,
    getSelectedCompanyUserUserId,
} from "pages/Administration/redux/administrationSelectors";
import {
    searchCompanyGroupUserByName,
    createOrUpdateEmployment,
    getEmploymentData,
} from "pages/Administration/redux/administrationAsyncActions";
import { CreateOrUpdateEmployment } from "swagger/customercompany";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import { Spinner } from "components/Spinner";
import { useNavigate } from "react-router-dom";
import { Config } from "config";

export default function Index() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const usersIsLoading = useAppSelector(getUsersIsLoading);
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const selectedUserUserId = useAppSelector(getSelectedCompanyUserUserId);
    const userEmploymentData = useAppSelector(getUserEmploymentData);
    const userEmploymentProps = useAppSelector(getUserEmploymentProps);
    const selectedCompanyUserPrimaryCompanyId = useAppSelector(getSelectedCompanyUserPrimaryCompanyId);
    const selectedUserAccountId = useAppSelector(getSelectedUserAccountId);
    const employmentInfoProps = useAppSelector(getEmploymentInfoProps);
    const belongingProps = useAppSelector(getBelongingProps);
    const mainManagerSearchProps = useAppSelector(getMainManagerSearchProps);
    const userEmployment = useAppSelector(getUserEmployment);
    const employmentIsDirty = useAppSelector(getEmploymentIsDirty);
    const saveButtonLoading = useAppSelector(getSaveButtonLoading);
    const saveOrUpdateSuccessful = useAppSelector(getSaveOrUpdateSuccessful);

    const handleEmploymentOnChange = useCallback(
        (propKey: keyof CreateOrUpdateEmployment, value: string) => {
            dispatch(
                administrationActions.setEmploymentProp({
                    propKey,
                    value,
                }),
            );
            if (propKey === "mainManager" && selectedCompanyId && value) {
                dispatch(
                    searchCompanyGroupUserByName({
                        companyId: selectedCompanyId,
                        searchString: value,
                    }),
                );
            }
            if (propKey === "organizationNumber") {
                dispatch(
                    administrationActions.setEmploymentErrorMessage(
                        value && value.length > 15 ? "organisationNumberToLong" : "",
                    ),
                );
            }
        },
        [dispatch, selectedCompanyId],
    );
    const setSearchOpen = useCallback(
        (open: boolean) => {
            dispatch(administrationActions.setAddUserSsnSearchOpen(open));
        },
        [dispatch],
    );

    const handleMainManagerClick = useCallback(
        (mainManagerUserAccountId: number) => {
            dispatch(administrationActions.setMainManagerModel(mainManagerUserAccountId));
        },
        [dispatch],
    );

    const formIsInValid = useMemo(
        () =>
            !!belongingProps.organizationNumberErrorMsg ||
            !userEmploymentProps.userEmployment?.departments ||
            userEmploymentProps.userEmployment.departments.length < 1 ||
            !employmentIsDirty,
        [belongingProps.organizationNumberErrorMsg, userEmploymentProps.userEmployment?.departments, employmentIsDirty],
    );

    const handleSubmit = useCallback(() => {
        if (userEmployment) dispatch(createOrUpdateEmployment(userEmployment));
    }, [dispatch, userEmployment]);

    useEffect(() => {
        if (
            selectedCompanyId &&
            selectedUserAccountId &&
            selectedCompanyUserPrimaryCompanyId &&
            userEmploymentData === null
        ) {
            dispatch(
                getEmploymentData({
                    companyId: selectedCompanyId,
                    selectedCompanyUserPrimaryCompanyId,
                    selectedUserAccountId,
                }),
            );
        }
    }, [dispatch, selectedCompanyId, selectedCompanyUserPrimaryCompanyId, selectedUserAccountId, userEmploymentData]);

    useEffect(() => {
        if (saveOrUpdateSuccessful) {
            dispatch(administrationActions.resetSubscriptionData());
            dispatch(administrationActions.resetSaveOrUpdateIsSuccessful());
            //Todo uncomment below when new subscription is ready
            // navigate("/administration/users/addUser/subscriptions");
            //Todo remove code below when new subscription is ready
            window.location.href = `${Config.web_customerSupport_url}company/${selectedCompanyId}/addemployment/${2}/${selectedUserUserId}`;
        }
    }, [dispatch, navigate, saveOrUpdateSuccessful, selectedCompanyId, selectedUserUserId]);

    return usersIsLoading ? (
        <Spinner />
    ) : (
        <Employment
            employmentInfoAtProps={{
                ...employmentInfoProps,
                onChange: handleEmploymentOnChange,
            }}
            belongingPageProps={{
                ...belongingProps,
                onChange: handleEmploymentOnChange,
                mainManagerSearchProps: {
                    ...mainManagerSearchProps,
                    setSearchResultOpen: setSearchOpen,
                    onSelectUserClick: handleMainManagerClick,
                },
            }}
            disableSaveButton={formIsInValid}
            handleSubmit={handleSubmit}
            saveButtonLoading={saveButtonLoading}
        />
    );
}
