import { ReactNode } from "react";
import { useAppSelector } from "store/hooks";
import { twMerge } from "tailwind-merge";
import { Dialog } from "@headlessui/react";
import { getUserRetailer } from "store/userSelectors";

interface ModalProps {
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    children: ReactNode;
    className?: string;
    disableOutsideClick?: boolean;
}

const Modal = ({ isOpen, setIsOpen, children, className, disableOutsideClick }: ModalProps) => {
    const retailer = useAppSelector(getUserRetailer);

    return (
        <Dialog
            open={isOpen}
            onClose={disableOutsideClick ? () => null : () => setIsOpen(false)}
            className="relative z-50"
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex w-screen items-center justify-center">
                <Dialog.Panel>
                    <div className={twMerge("bg-pageBg", retailer, className)}>{children}</div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};

export default Modal;
