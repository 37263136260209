const TopLocation = ({ header }: { header: string }) => {
    return (
        <div className="flex flex-1 cursor-pointer" role="button">
            <div className="w-full max-w-screen-xxxxl border-t-grey-500 border-b-grey-500 border flex flex-col">
                <div className="flex flex-1  p-3 justify-center cursor-pointer gap-2 select-none">
                    <div>{header}</div>
                </div>
            </div>
        </div>
    );
};

export default TopLocation;
