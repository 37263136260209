import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { saveEmploymentEraseSettings as saveEmploymentEraseSettingsAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchSubscribeBuilder = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(saveEmploymentEraseSettingsAction.fulfilled, (state, action) => {
            const { reason } = action.meta.arg;
            if (state.userEmployment) {
                state.userEmployment.doNotEraseEmployment = action.payload.doNotEraseEmployment;
                state.userEmployment.doNotDeleteMessage = action.payload ? reason || null : null;
                state.status.userEmployment = Status.OK;
            }
        })
        .addCase(saveEmploymentEraseSettingsAction.pending, (state) => {
            state.status.userEmployment = Status.PENDING;
        })
        .addCase(saveEmploymentEraseSettingsAction.rejected, (state) => {
            state.status.userEmployment = Status.ERROR;
        });
export default fetchSubscribeBuilder;
