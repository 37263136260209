import Icon from "components/Icon";
import { useElementClicked } from "hooks";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { twMerge } from "tailwind-merge";

export interface ISelectValue {
    description: string;
    value: string;
    icon?: React.ReactNode;
    nonTranslatedDesc?: string;
    disabled?: boolean;
}

export interface ISelectProps {
    options: ISelectValue[];
    onChange: (newValue: ISelectValue) => void;
    selectedValue?: ISelectValue;
    containerClassName?: string;
    className?: string;
    placeholder?: string;
    translationKey?: string;
    isLoading?: boolean;
    disabled?: boolean;
}
const Select = ({
    options,
    onChange,
    selectedValue,
    containerClassName,
    className,
    placeholder,
    translationKey,
    isLoading,
    disabled,
}: ISelectProps) => {
    const [open, setOpen] = useState(false);
    const ref = useElementClicked((v) => {
        if (!disabled) setOpen(v);
    });

    const { t } = useTranslation(translationKey);

    const getDescription = useMemo(
        () => selectedValue?.description || t(selectedValue?.nonTranslatedDesc!),
        [t, selectedValue],
    );

    return (
        <div ref={ref} className={twMerge("w-full h-auto relative", containerClassName)}>
            <button
                className={twMerge(
                    "w-full h-12 bg-pageBg border",
                    disabled
                        ? "cursor-not-allowed border-mhgrey-medium text-mhgrey-medium"
                        : "cursor-pointer border-mhdarkgreen-original",
                    className,
                )}
            >
                <div className="w-full flex items-center">
                    {selectedValue?.icon && <div className="ml-3">{selectedValue.icon}</div>}
                    <p data-testid="selectedValue" className="w-full text-left pl-2">
                        {selectedValue?.value ? getDescription : placeholder}
                    </p>
                    <div className="mx-3 flex">
                        <Icon icon={open ? "caretUp" : "caretDown"} size={14} />
                    </div>
                    {isLoading && (
                        <div className="flex absolute left-[250px]">
                            <TailSpin color="#3b3b36" height={20} width={20} />
                        </div>
                    )}
                </div>
            </button>
            {open && (
                <div className="w-full absolute z-10">
                    <div className="mt-1 py-1 flex flex-col bg-white shadow-xl">
                        {options.map((c, i) => (
                            <button
                                key={c.nonTranslatedDesc + i.toString()}
                                type="button"
                                className={twMerge(
                                    "px-3 py-2 hover:bg-grey-100 flex items-center",
                                    c.disabled && "opacity-50",
                                )}
                                disabled={c.disabled}
                                onClick={() => {
                                    onChange(c);
                                    setOpen(!open);
                                }}
                            >
                                <div key={c.nonTranslatedDesc}>{c.icon}</div>
                                <p className="ml-2 text-left">{c.description || t(c.nonTranslatedDesc!)}</p>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
export default Select;
