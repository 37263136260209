import {
    MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse as IAbsenceFollowUpOverview,
    MedHelpAbsenceFollowUpWebApiResponseAbsence as IAbsenceList,
    MedHelpAbsenceFollowUpWebApiControllersResponse,
    MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse,
} from "swagger/absencefollowup";
import {
    INumber,
    IMonth,
    ITags,
    IUserSubscription,
    IDate,
    ISearchFilterTags,
    SortingAttributes,
    ShowBasedOn,
    ShowDepartments,
} from "../types";
import { IDeviant, IActivityLog } from "./followUpAsyncActions";
import { MedHelpAbsenceReportingWebApiModelsMedicalCertificate } from "swagger/absencereportapi";
import { CompanyRegion, TreeViewNode, CostCalculationBasisViewModel, UserEmployment } from "swagger/employeeattendance";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import * as SearchApi from "../utils";
import {
    MedHelpHCMDomainTemplate,
    MedHelpHCMWebApiResponseHealthCase,
    MedHelpHCMWebApiResponseHealthCaseActivity,
    MedHelpHCMWebApiResponseResponsibleResponse,
} from "swagger/healthCaseManagement";
import { MedHelpHCMWebApiResponseHealthCaseDocument } from "swagger/healthCaseManagement";
import { ConfigurationField, HealthCaseNotification, PartnerContact } from "swagger/messenger";
import { RehabRoutineDocumentMetaData } from "swagger/rehab";
import { IdNameMap } from "swagger/customercompany";

export interface EmployementGroup {
    codeId: number;
    code: string | null;
}

export enum Status {
    PENDING,
    ERROR,
    OK,
}
export interface IRehab {
    activeChain: boolean | null;
    daysInRehabChain: number | null;
    allowDeleteDate: string | null;
    employmentId: number | null;
    endDate: string | null;
    initiationDate: string | null;
}

export interface IDeviantPeriods {
    deviantId: string | null;
    type: string | null;
    code: string | null;
    validFrom: string | null;
    validTo: string | null;
    departmentNames: string[] | null;
}
export interface ISearchPayloadPage extends MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse {
    deviantPeriods?: IDeviantPeriods[];
    absenceDegree?: number;
    endDate?: string;
    startDate?: string;
    socialSecurityNumber?: string;
    id?: string;
    name?: string;
    description?: string;
}
export interface ISearchPayload extends MedHelpAbsenceFollowUpWebApiControllersResponse {
    page: ISearchPayloadPage[];
    type?: SearchApi.SearchTypes | null;
}

export interface IHealthCaseDocument extends MedHelpHCMWebApiResponseHealthCaseDocument {
    url?: string;
    annotation?: string;
    status: Status;
}

export interface IHealthCaseSubStatus {
    activities: Status;
    documents: Status;
    notifications: Status;
    pdfDocumentTemplate: Status;
    pdfDocumentBasedOnTemplateSaved: Status;
}

export interface IHealthCaseActivity extends MedHelpHCMWebApiResponseHealthCaseActivity {
    status: Status;
}

export interface IHealthCase extends MedHelpHCMWebApiResponseHealthCase {
    healthCaseActivities: IHealthCaseActivity[];
    healthCaseDocuments: IHealthCaseDocument[];
    healthCaseNotifications: HealthCaseNotification[];
    healthCaseSubStatus: IHealthCaseSubStatus;
    healthCaseStatus: Status;
    pdfUrl?: string;
    annotation?: string;
    expanded: boolean;
}

export interface IUpdateActivityPayload {
    field: "title" | "description" | "date";
    value: string;
    healthCaseId: string;
    activityId: string | undefined;
}

export interface IUpdateNotePayload {
    field: "title" | "description";
    value: string;
    healthCaseId: string;
    noteId?: string;
}

export type SearchStatus = "pending" | "ok" | "error" | "no-data" | "empty";

export type ISeachActionPayloadContent = INumber | IMonth | ITags | IDate;

export interface ISearchFilter {
    filters: ISearchFilterTags;
    showBasedOn: ShowBasedOn;
    showDepartments: ShowDepartments;
    sorting: SortingAttributes;
}
export interface ISearchCompanyDepartmentPayload {
    field: "companies" | "departments";
    value: string;
}
export type SearchFilterState = {
    [value in SearchApi.SearchTypes]: ISearchFilter;
};

type KeysOfInterfaceObject<T, C extends keyof T> = keyof T[C];
export interface IISearchState {
    userGuard: string | null;
    searchStatus: { [value in SearchApi.SearchTypes]: SearchStatus };
    currentSearch: SearchApi.SearchTypes;
    pageNumber: {
        [value in SearchApi.SearchTypes]: {
            currentSearchPageNumber: number;
            paginatorPageNumber: number;
        };
    };
    searchFilter: SearchFilterState;
    searchCompaniesDepartments: {
        companies: IDynamicData[] | null;
        departments: IDynamicData[];
        storedDepartments: string[];
        currentCompanySearchId: number | null;
    };
    search: {
        [value in SearchApi.SearchTypes]: { [key: number]: ISearchPayload };
    };
    searchHasMore: {
        [value in SearchApi.SearchTypes]: { [key: number]: boolean };
    };
    rehabRoutineDocuments: {
        data: RehabRoutineDocumentMetaData[];
        status: Status;
    };
    costCalculation: { data: CostCalculationBasisViewModel[]; status: Status };
    companyGroup: { data: IdNameMap[]; status: Status };
}

export type KeyOfIISearchState<T> = KeysOfInterfaceObject<IISearchState, T extends keyof IISearchState ? T : never>;

export interface IEmployeeCardState {
    medicalCertificate: {
        firstDayMedicalCertificate: MedHelpAbsenceReportingWebApiModelsMedicalCertificate[] | null;
    };
    absenceOverview: IAbsenceFollowUpOverview[];
    absenceList: IAbsenceList[];
    deviant: IDeviant[];
    userEmployment: UserEmployment | null;
    subscription: {
        userSubscription: IUserSubscription[] | null;
        userSubscriber: IUserSubscription[] | null;
        missingSubscriptionAccessRightsForUser: boolean;
    };
    rehab: IRehab;
    absenceActivityLogs: {
        [key: string]: IActivityLog[];
    };
    companiesWithRegion: CompanyRegion[];
    organizationTree: TreeViewNode[];
    employmentGroups: EmployementGroup[];
    currentUser: string;
    healthCases: IHealthCase[];
    caseResponsibleList: MedHelpHCMWebApiResponseResponsibleResponse[];
    partnerContactInformation: PartnerContact | null;
    configurationFields: ConfigurationField[];
    status: {
        absenceOverview: Status;
        absenceList: Status;
        activityLogs: { [key: string]: Status };
        deviant: Status;
        userEmployment: Status;
        userSubscription: Status;
        medicalCertificate: Status;
        rehab: Status;
        companiesWithRegion: Status;
        organizationTree: Status;
        employmentGroups: Status;
        noData: boolean;
        healthCases: Status;
        caseResponsibleList: Status;
        partnerContactInformation: Status;
        configurationFields: Status;
        partnerContactRequest: Status;
    };
}

export interface IState extends IISearchState, IEmployeeCardState {
    caseTemplateList: {
        data: MedHelpHCMDomainTemplate[];
        status: Status;
    };
    hasRehabServiceContract: {
        data: boolean;
        status: Status;
    };
    organizationTreeRehabRights: {
        data: TreeViewNode[];
        status: Status;
    };
}
