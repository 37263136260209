import Icon from "components/Icon";
import * as React from "react";

export enum Status {
    PENDING,
    ERROR,
    OK,
}

interface IProps {
    status: Status;
    errorMessage: string;
    children: React.ReactNode;
    className?: string;
    color?: "white" | "black";
}
const LoadableComponent = ({ status, errorMessage, children, className, color = "black" }: IProps) => {
    if (status === Status.ERROR) {
        return <div>{errorMessage} </div>;
    }
    if (status === Status.PENDING) {
        return <Icon icon="loader" size={24} className={`m-auto ${className}`} fill={color} />;
    }

    return <>{children}</>;
};

export default LoadableComponent;
