import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import { MedHelpHCMWebApiResponseTodoAndOngoingRow } from "swagger/healthCaseManagement";
import { isEmpty } from "lodash";
import { ModalFrame } from "@medhelp/ui";
import SecondaryButton from "components/SecondaryButton";
import Icon from "components/Icon";
import { getDateDisplayValue } from "utils/date";
import HCMTodoTable from "../Tables/HCMTodoTable";

interface ITodoChart {
    data: MedHelpHCMWebApiResponseTodoAndOngoingRow[];
    type: string;
}

const TodoChart = ({ data, type }: ITodoChart) => {
    const { t } = useTranslation("dashboard");
    const [openModal, setOpenModal] = useState(false);

    return isEmpty(data) ? (
        <div className="flex flex-col items-center pt-3 h-full justify-between">
            <div className="pt-8 text-center opacity-60 mx-6 px-6 text-xs border-t border-secondaryBorder">
                {type === "hcmTodo" ? t("noOngoingTodo") : t("noOngoingCases")}
            </div>
        </div>
    ) : (
        <div className="flex flex-col items-center justify-between h-56">
            <div className="flex flex-col w-full px-6 text-sm">
                {data.slice(0, 3).map((item, i) => (
                    <Link
                        key={`${item.employmentId}-${i}`}
                        to={`/followup/employee/${item.employmentId}/case`}
                        className="flex justify-between items-start h-14 border-t py-3 border-secondaryBorder"
                    >
                        <div className="flex flex-col justify-center">
                            <div className="flex gap-2">
                                <p className="font-bold text-xs">{item.name ?? ""}</p>
                            </div>

                            <div className="text-mhbeige text-xs opacity-60">
                                <div className="flex">{t(item.type ?? "")}</div>
                            </div>
                        </div>
                        <div className="flex items-center">
                            {item.type === "plannedactivity" ? (
                                <div className="flex mr-2 text-mhred-alert2">
                                    <Icon icon="warning" fill="#FF4D4D" className="pr-1" />
                                    {getDateDisplayValue(item.date, "d MMM yyyy")}
                                </div>
                            ) : (
                                <div className="mr-2">{getDateDisplayValue(item.date, "d MMM yyyy")}</div>
                            )}
                            <Icon icon="caretRight" size={10} />
                        </div>
                    </Link>
                ))}
            </div>
            <SecondaryButton
                id="show-more-button"
                text={t("moreInformation")}
                className="border border-secondaryText text-secondaryText mb-4"
                onClick={() => setOpenModal(true)}
            />
            <ModalFrame
                id="widget-todo-modal"
                open={openModal}
                setOpen={() => setOpenModal(false)}
                header={<h3>{t(type)}</h3>}
                preset="full"
                content={<HCMTodoTable data={data} type={type} />}
            />
        </div>
    );
};
export default TodoChart;
