import { isEmpty } from "lodash/fp";
import ErrorWidget from "../ErrorWidget";
import { useRetailerColors } from "hooks";
import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { BarChart } from "pages/dashboard/components/Charts";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import TotalAbsenceLastMonthsWidgetLoader from "./TotalAbsenceLastMonthsWidgetLoader";
import { getAbsencePercentLast6Months, getCompanies, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const TotalAbsenceLastMonthsWidget = ({ id, type }: Widget) => {
    const dispatch = useAppDispatch();
    const { primary, secondary } = useRetailerColors();
    const companies = useAppSelector(getCompanies(id));
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getAbsencePercentLast6Months(id));

    const companyIds = useMemo(() => {
        const checkedCompanies = companies?.filter((x) => x.checked) ?? [];
        if (isEmpty(checkedCompanies)) {
            return companies ? [companies[0]?.id] : [];
        }
        return checkedCompanies.map((x) => x?.id);
    }, [companies]);

    useEffect(() => {
        !isDragging && dispatch(fetchWidgetData({ id, type }));
    }, [dispatch, id, type, isDragging]);

    if (status === Status.PENDING) {
        return <TotalAbsenceLastMonthsWidgetLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <BarChart data={data} primaryColor={primary} secondaryColor={secondary} companyIds={companyIds as number[]} />
    );
};

export default TotalAbsenceLastMonthsWidget;
