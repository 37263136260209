import { Traverser } from "domain/ogranization";
import { UnknownAction, createListenerMiddleware, isAnyOf, ListenerEffectAPI, ThunkDispatch } from "@reduxjs/toolkit";
import { isString } from "lodash/fp";
import { RootState } from "store";
import { searchActions } from "store/searchSlice";
import { getUserAccessRights, getUserContext, getHasCustomerSupport } from "store/userSelectors";
import { userActions } from "store/userSlice";
import { filterAccessRightBy } from "domain/accessRights";
import * as RouteStore from "store/route";
import * as thunkActions from "./followUpAsyncActions";
import * as selectors from "./followUpSelectors";

import { followUpActions as actions } from "./followUpSlice";
import { SearchTypes } from "../utils";
import { getAccessRightForFollowUp, getAccessRightBySearchType } from "../accessRights";

const followUpListener = createListenerMiddleware();
const updateCurrentSearchListener = createListenerMiddleware();
const setCurrentSearch = createListenerMiddleware();
const initialSearchFollowupListener = createListenerMiddleware();
const makeSearchOnAction = createListenerMiddleware();
const createAbsencedPeriod = createListenerMiddleware();
const resetFollowUp = createListenerMiddleware();
const createPowerbarSearchListener = createListenerMiddleware();
const resetPowerbarListener = createListenerMiddleware();

interface ILoadCompaniesDepartments {
    fetch: "departments" | "companies" | "all" | "employmentGroups";
    companyId?: number;
    selectAllDepartments?: boolean;
    employementGuid: string;
    currentSearch?: SearchTypes;
}
const loadCompaniesDepartments = async (
    listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, UnknownAction>, unknown>,
    { fetch, companyId, selectAllDepartments, employementGuid, currentSearch }: ILoadCompaniesDepartments,
) => {
    await listenerApi.dispatch(
        thunkActions.fetchSearchInit({
            selectAllDepartments,
            companyId,
            fetch,
            employementGuid,
            currentSearch,
        }),
    );
    return;
};
updateCurrentSearchListener.startListening({
    actionCreator: actions.updateCurrentSearch,
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        const currentSearch = action.payload;
        const state = listenerApi.getState() as RootState;
        const customerSupport = getHasCustomerSupport(state);
        const companyId = selectors.getCompanyIdBasedOnPowerbarSearchOrUser(state);
        const user = getUserContext(state);
        const userAccessRights = getUserAccessRights(state);
        const followUpPage = RouteStore.selectors.getFollowUpCurrentRoute(state);
        const companiesWithRegion = selectors.getCompanies(state);

        const followUpAccessRights = followUpPage ? getAccessRightForFollowUp(followUpPage, currentSearch) : null;
        if (currentSearch) {
            if (customerSupport) {
                await loadCompaniesDepartments(listenerApi, {
                    fetch: "all",
                    companyId: companyId ?? user.userAccount?.companyId,
                    selectAllDepartments: companiesWithRegion?.length === 1,
                    employementGuid: user?.EmploymentGuid,
                });
                return;
            }
            const companies = getAccessRightBySearchType(
                filterAccessRightBy(userAccessRights || []),
                "companyId",
                followUpAccessRights || [],
                companiesWithRegion,
            );
            if (companies.length === 1) {
                const company = companies[0];
                await loadCompaniesDepartments(listenerApi, {
                    fetch: "departments",
                    selectAllDepartments: true,
                    companyId: company.id,
                    employementGuid: user?.EmploymentGuid,
                    currentSearch,
                });
                return;
            }
        }
    },
});
followUpListener.startListening({
    actionCreator: actions.updateCompanyDepartmentSearch,
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners();

        if (action.payload.field === "companies") {
            const state = listenerApi.getState() as RootState;
            const user = getUserContext(state);
            let allCompanies = selectors.getSearchCompanies(state);
            const companies = Traverser.findAllByProperty("checked", allCompanies || []) || [];
            const id = parseInt(companies[0].id.toString() || "");
            const departments = Traverser.findAllByProperty("checked", selectors.getSearchDepartments(state)) || [];
            if (departments.length > 0) {
                listenerApi.dispatch(actions.resetSearchDepartments());
            }
            if (companies.length === 1) {
                await loadCompaniesDepartments(listenerApi, {
                    fetch: "departments",
                    companyId: id,
                    selectAllDepartments: true,
                    employementGuid: user?.EmploymentGuid,
                });
                return;
            }
            await loadCompaniesDepartments(listenerApi, {
                fetch: "employmentGroups",
                companyId: 1,
                employementGuid: user?.EmploymentGuid,
            });
        }
    },
});
initialSearchFollowupListener.startListening({
    actionCreator: actions.initSearchFollowup,
    effect: async (_, listenerApi) => {
        listenerApi.cancelActiveListeners();
        const state = listenerApi.getState() as RootState;
        const user = getUserContext(state);
        const userGuardId = selectors.getUserGuardId(state);
        const companyId = selectors.getCompanyIdBasedOnPowerbarSearchOrUser(state);
        // could be empty
        const companies = selectors.searchComapniesFiltered(state);
        const selectedCompanies = Traverser.findAllByProperty("checked", companies || []);

        let currentCompany: number | null = null;
        if (selectedCompanies?.length === 1) {
            const selectedCompanyId = selectedCompanies[0].id;
            currentCompany = selectedCompanyId
                ? isString(selectedCompanyId)
                    ? parseInt(selectedCompanyId)
                    : selectedCompanyId
                : null;
        } else {
            // first load or none or more than one selected company
            currentCompany = user?.companyEmployment?.primaryCompany?.id || null;
        }
        if (userGuardId !== user.EmploymentGuid) {
            listenerApi.dispatch(actions.resetEmploymentCard());
            listenerApi.dispatch(actions.resetSearch());
        }
        if (currentCompany) {
            await loadCompaniesDepartments(listenerApi, {
                fetch: "all",
                companyId: companyId ?? currentCompany,
                selectAllDepartments: selectedCompanies?.length === 1,
                employementGuid: user?.EmploymentGuid,
            });
            return;
        }
        throw Error("Cant get the correct company id `initialSearchFollowupListener`");
    },
});

makeSearchOnAction.startListening({
    matcher: isAnyOf(actions.showDepartments, actions.updateSearchFilterSorting),
    effect: async (payload, listenerApi) => {
        listenerApi.cancelActiveListeners();
        if (
            payload?.payload === "onlyLatestDepartment" ||
            payload?.payload === "everyDepartment" ||
            // @ts-ignore
            payload?.payload?.field === "showBasedOn"
        ) {
            const state = listenerApi.getState() as RootState;
            const currentSearch = selectors.getCurrentSearch(state);
            await listenerApi.dispatch(
                thunkActions.search({
                    requestType: "search",
                    type: currentSearch,
                    workBookTitle: "",
                }),
            );
        }
    },
});

createAbsencedPeriod.startListening({
    actionCreator: actions.addSearch,
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners();
        const state = listenerApi.getState() as RootState;
        const currentSearch = selectors.getCurrentSearch(state);
        if (currentSearch === "ongoingAbsence") {
            const absencePeriod = selectors.search("absencePeriod", 1)(state);
            if (!absencePeriod) {
                listenerApi.dispatch(
                    actions.addSearch({
                        ...action.payload,
                        type: "absencePeriod",
                    }),
                );
            }
            return;
        }
    },
});
createPowerbarSearchListener.startListening({
    matcher: isAnyOf(
        searchActions.setActivePowerbarSearch,
        searchActions.resetPowerbarSearchResult,
        searchActions.reset,
    ),
    effect: (_, listenerApi) => {
        const pathname = window.location.pathname;
        listenerApi.cancelActiveListeners();
        if (pathname.match(/(\/followup\/search)/)) {
            listenerApi.dispatch(actions.resetSearch());
            const state = listenerApi.getState() as RootState;
            const user = getUserContext(state);
            const currentSearhCompanyId = selectors.getCurrentSearchCompanyId(state);
            const companyId =
                selectors.getCompanyIdBasedOnPowerbarSearchOrUser(state) ?? user?.companyEmployment?.primaryCompany?.id;
            if (currentSearhCompanyId !== companyId) {
                listenerApi.dispatch(actions.initSearchFollowup("all"));
            }
        }
    },
});
createPowerbarSearchListener.startListening({
    matcher: isAnyOf(userActions.signIn, userActions.signOut),
    effect: (_, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(searchActions.reset());
    },
});

resetFollowUp.startListening({
    matcher: isAnyOf(userActions.signIn, userActions.signOut),
    effect: (_, listenerApi) => {
        listenerApi.cancelActiveListeners();
        listenerApi.dispatch(actions.resetFollowUpState());
    },
});
export default [
    followUpListener,
    initialSearchFollowupListener,
    makeSearchOnAction,
    createAbsencedPeriod,
    resetFollowUp,
    createPowerbarSearchListener,
    resetPowerbarListener,
    updateCurrentSearchListener,
    setCurrentSearch,
];
