import { useTranslation } from "react-i18next";
import { orderBy } from "lodash";
import { getDegreePeriodString } from "utils/degrees";
import Tooltip from "pages/dashboard/components/Tooltip";

interface IDegree {
    degree?: number | null;
    timeStamp?: string | null;
}

interface IProps {
    degrees: IDegree[];
    endDate?: string | null;
}

const TooltipVariedDegrees = (props: IProps) => {
    const { degrees, endDate } = props;
    const { t } = useTranslation();
    const sortedDegrees = orderBy(degrees, ["timeStamp"], "desc");

    return (
        <div className="relative">
            <Tooltip heading={t("varyingAbsenceDegree")} className="-ml-26 w-72">
                <>
                    {sortedDegrees.map((item, i, array) => (
                        <div key={`${item.timeStamp}-${i}`} className="flex w-content">
                            <div className="w-13">{item.degree}%</div>
                            <div>
                                {item.timeStamp ? getDegreePeriodString(item.timeStamp, array, i, t, endDate) : ""}
                            </div>
                        </div>
                    ))}
                </>
            </Tooltip>
        </div>
    );
};

export default TooltipVariedDegrees;
