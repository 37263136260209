import { IPreloadedValues } from "pages/Support/pages/ContactSupport/ContactSupport";
import { useAppDispatch, useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";
import { selectors as UserSettingsSelectors } from "./redux";
import { getLoginConfiguration, mailCustomerSupport, updatePassword } from "./redux/userSettingsAsyncActions";
import { IContactInfoInputValues, IUpdateComponent, UserSettingsComponents } from "./types";
import UserSettings from "./UserSettings";
import { userSettingsActions } from "pages/UserSettings/redux/userSettingsSlice";
import { updateContactInformation, updateUserLangPreferences } from "store/userActions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
    ContactInfoPropToUpdate,
    getPrivateMobileInitialValue,
    getPrivatePhoneInitialValue,
    updateContactInfoModelProps,
} from "utils/phone";
import { getDateTimeDisplayValue } from "utils/date";
import { toast } from "react-toastify";

export default function Index() {
    const { t } = useTranslation("userSettings");
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const userContactInfo = useAppSelector(UserSelectors.getUserContactInfo);
    const userCountryCode = useAppSelector(UserSelectors.getUserCountryCode);
    const { isLoading, status, hasUsernamePwdLogin, contactInfoInputs } = useAppSelector(
        UserSettingsSelectors.getUserSettingsSlice,
    );
    const dispatch = useAppDispatch();
    const createEmailModel = (): IPreloadedValues => {
        const emailModel: IPreloadedValues = {
            email: userContext.userAccount?.contactInformation?.email || "",
            prefix: userContext.userAccount?.contactInformation?.mobileCountryPrefix || "",
            phoneNumber: userContext.userAccount?.contactInformation?.telephoneNumber || "",
            caseNumber: "",
            role: "4",
            supportType: "1601",
            subject: "Request to remove user absence causes",
            details: `User with ID: ${
                userContext.EmploymentGuid ?? userContext.user?.id
            } has requested that cause of absence to be removed.
            Put a ticket with high priority in Jira and a developer will remove affected data until ${getDateTimeDisplayValue()}."`,
        };
        return emailModel;
    };

    const handleSaveClicked = (handleCompUpdate: IUpdateComponent) => {
        if (handleCompUpdate.componentToUpdate !== 4) dispatch(userSettingsActions.setIsLoading());

        switch (+handleCompUpdate.componentToUpdate) {
            case UserSettingsComponents.DeleteHistoricalAbsenceCauses:
                dispatch(mailCustomerSupport(createEmailModel()));
                break;
            case UserSettingsComponents.Language:
                dispatch(updateUserLangPreferences(handleCompUpdate.language!));
                break;
            case UserSettingsComponents.ChangePassword:
                dispatch(updatePassword(handleCompUpdate.pwdCompValues!));
                break;
            default:
                break;
        }
    };

    const setNewInputValue = (newValue: string, prop: keyof IContactInfoInputValues) => {
        dispatch(
            userSettingsActions.updateContactInfoInput({
                newValue: newValue,
                propToUpdate: prop,
            }),
        );
    };

    const inputIsDirty = (): boolean => {
        const savedMobile = getPrivateMobileInitialValue(userContactInfo!, userCountryCode!);
        const savedPhone = getPrivatePhoneInitialValue(userContactInfo!, userCountryCode!);
        const isDirty =
            contactInfoInputs?.privateMobile !== savedMobile || contactInfoInputs?.privatePhone !== savedPhone;
        return isDirty;
    };

    const handleUserSettingSave = () => {
        let contactInfoModel = _.cloneDeep(userContactInfo);
        if (inputIsDirty()) {
            contactInfoModel = updateContactInfoModelProps(
                contactInfoInputs?.privateMobile ?? "",
                contactInfoModel!,
                ContactInfoPropToUpdate.privateMobile,
            );
            contactInfoModel = updateContactInfoModelProps(
                contactInfoInputs?.privatePhone ?? "",
                contactInfoModel!,
                ContactInfoPropToUpdate.privatePhone,
            );
            dispatch(updateContactInformation(contactInfoModel!));
        }
    };

    useEffect(() => {
        if (status) {
            const type =
                status === "authFailed" || status === "apiCallRejected" || status === "changeFailed"
                    ? "error"
                    : "success";

            toast[type](t(status));
            dispatch(userSettingsActions.resetStatus());
        }
    }, [dispatch, status, t]);

    useEffect(() => {
        if (hasUsernamePwdLogin === null) {
            dispatch(getLoginConfiguration());
        }
    }, [dispatch, hasUsernamePwdLogin]);

    useEffect(() => {
        if (contactInfoInputs === null && userContactInfo !== undefined)
            dispatch(
                userSettingsActions.setContactInfoInputs({
                    userContactInfo: userContactInfo,
                    userCountryCode: userCountryCode ?? "",
                }),
            );
    }, [dispatch, contactInfoInputs, userContactInfo, userCountryCode]);

    return (
        <UserSettings
            userSettingPageProps={{
                hasUsernamePwdLogin: hasUsernamePwdLogin === true,
                isDkUser: userContext.user?.countryCode === "DK",
                isCustomerSupport: userContext.HasCustomerSupport,
            }}
            status={status}
            handleUpdateComponent={handleSaveClicked}
            userPreferences={userContext.user?.userPreferences!}
            setContactInfoProps={{
                contactInfoInputValues: {
                    workNumber: contactInfoInputs?.workNumber ?? "",
                    workEmail: contactInfoInputs?.workEmail ?? "",
                    privateMobile: contactInfoInputs?.privateMobile ?? "",
                    privatePhone: contactInfoInputs?.privatePhone ?? "",
                },
                setWorkNumber: (v) => setNewInputValue(v, "workNumber"),
                setWorkEmail: (v) => setNewInputValue(v, "workEmail"),
                setPrivateMobile: (v) => setNewInputValue(v, "privateMobile"),
                setPrivatePhone: (v) => setNewInputValue(v, "privatePhone"),
                handleSaveClicked: handleUserSettingSave,
                isLoading: isLoading === true,
            }}
            isLoading={isLoading === true}
        />
    );
}
