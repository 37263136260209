import { useTranslation } from "react-i18next";
import { SubscriptionOptions, RadioButton, NewSubscriptionSearch } from "../..";
import { ICheckBoxStateProps, ISubscriptionOptionsStateProps } from "pages/Administration/types";
import DropdownCheckboxFilter from "pages/FollowUp/components/DropdownCheckboxFilter";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import { getNewSubscriptionRbName } from "pages/Administration/utils";
import { ISingleDatepickerProps } from "@medhelp/ui/Datepicker/types";
import { INewSubscriptionSearchStateProps } from "../NewSubscriptionSearch/NewSubscriptionSearch";
import NewExternalSubscriber, {
    INewExternalSubscriberStateProps,
} from "../NewExternalSubscriber/NewExternalSubscriber";
import { Heading } from "@medhelp/ui";
import { SubscriptionLookup } from "swagger/employeeattendance";

const props = [
    "selectedSearchRadioValue",
    "newSubSearchQuery",
    "selectedDepartment",
    "selectedSearchResult",
    "newExternalSubName",
    "newExternalSubChannel",
    "checkIfNewExternalUserExists",
    "externalUserContactKey",
] as const;

export type UpdateProps = (typeof props)[number];

export interface ICreateSubscriptionStateProps {
    selectedLookupTypeRadioValue: number;
    newSubSearchProps: INewSubscriptionSearchStateProps;
    departmentsDisplayList: IDynamicData[];
    departmentsIsLoading: boolean;
    showSubscriptionOptions: boolean;
    newExternalSubscriber: INewExternalSubscriberStateProps;
    subscriptionOptionProps: ISubscriptionOptionsStateProps;
}
export interface ICreateSubscriptionProps extends ICreateSubscriptionStateProps {
    startDatepickerProps: ISingleDatepickerProps;
    endDatepickerProps: ISingleDatepickerProps;
    isSubsOnTab: boolean;
    userName: string;
    newSubscriptionOnChange: (propName: UpdateProps, value: string | number | SubscriptionLookup) => void;
    onCheckboxChange: (checkboxProps: ICheckBoxStateProps[]) => void;
    onMissingContactKeyClicked: () => void;
}
const CreateNewSubscription = ({
    selectedLookupTypeRadioValue,
    newSubSearchProps,
    departmentsDisplayList,
    departmentsIsLoading,
    newSubscriptionOnChange,
    startDatepickerProps,
    endDatepickerProps,
    onCheckboxChange,
    isSubsOnTab,
    userName,
    showSubscriptionOptions,
    newExternalSubscriber,
    onMissingContactKeyClicked,
    subscriptionOptionProps,
}: ICreateSubscriptionProps) => {
    const { t } = useTranslation("administration");
    let radioButtonValues = isSubsOnTab ? [1, 2, 3] : [1, 4, 5];

    return (
        <div className="flex flex-col">
            <Heading
                heading={
                    isSubsOnTab
                        ? t("addNewSubscriptionOn", { name: userName })
                        : t("addSubscriberFrom", { name: userName })
                }
            />

            <div className="flex flex-col">
                <div className="mb-6 flex">
                    {radioButtonValues.map((radioValue) => (
                        <div className="flex" key={radioValue}>
                            <RadioButton
                                checked={selectedLookupTypeRadioValue === radioValue}
                                setChecked={() => newSubscriptionOnChange("selectedSearchRadioValue", radioValue)}
                            />
                            <p className="mr-4">{t(getNewSubscriptionRbName(radioValue))}</p>
                        </div>
                    ))}
                </div>
                <>
                    {(selectedLookupTypeRadioValue === 1 || selectedLookupTypeRadioValue === 3) && (
                        <div className="flex flex-col">
                            <p className="mb-2">
                                {selectedLookupTypeRadioValue === 3 ? t("searchCompany") : t("searchEmployee")}
                            </p>
                            <NewSubscriptionSearch
                                {...newSubSearchProps}
                                newSubscriptionOnChange={newSubscriptionOnChange}
                            />
                        </div>
                    )}
                    {selectedLookupTypeRadioValue === 2 && (
                        <div className="flex flex-col xxs:w-[632px]">
                            <p className="mb-2">{t("selectDepartment")}</p>

                            <DropdownCheckboxFilter
                                header=""
                                keepClose={false}
                                loading={departmentsIsLoading}
                                viewElements={{}}
                                data={departmentsDisplayList}
                                setData={(v) => newSubscriptionOnChange("selectedDepartment", v)}
                                variants="fullWidth"
                            />
                        </div>
                    )}
                    {selectedLookupTypeRadioValue === 4 && (
                        <div className="flex flex-col">
                            <p className="mb-2">{t("searchExternal")}</p>
                            <NewSubscriptionSearch
                                {...newSubSearchProps}
                                newSubscriptionOnChange={newSubscriptionOnChange}
                                isExternalSearch
                            />
                        </div>
                    )}
                    {selectedLookupTypeRadioValue === 5 && (
                        <NewExternalSubscriber
                            {...newExternalSubscriber}
                            newSubscriptionOnChange={newSubscriptionOnChange}
                        />
                    )}
                </>
                {showSubscriptionOptions && (
                    <div className="mt-10">
                        <SubscriptionOptions
                            {...subscriptionOptionProps}
                            startDatepickerProps={startDatepickerProps}
                            endDatepickerProps={endDatepickerProps}
                            newSubIsLoading={newSubSearchProps.searchIsLoading}
                            onCheckboxChange={onCheckboxChange}
                            onMissingContactKeyClicked={onMissingContactKeyClicked}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateNewSubscription;
