import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "components/Accordion/Accordion";
import RadioButtonGroup from "components/RadioButtonGroup";
import { FindDynamicComponent } from "components/Dynamic";
import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import {
    getAbsenceReportFieldValueByName,
    absenceReportMandatoryfieldsIsValid,
    getAbsenceReportFieldByName,
} from "../../utils/absenceReports";
import { selectors as absenceReportsSelectors, actions as absenceReportsActions, thunkActions } from "../../redux";
import AbsenceReportBase from "../AbsenceReportBase";
import GetWidget from "../GetWidget";
import { ModalFrame } from "@medhelp/ui";
import ModalCommonFooter from "@medhelp/ui/Modal/ModalCommonFooter";
import { useDateTimepickerProps, useSingleDatepickerProps } from "hooks";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface IAbsenceReportEditModal {
    id: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    setOpenAbsenceNotificationModal: () => void;
}

const AbsenceReportEditModal = (props: IAbsenceReportEditModal) => {
    const { id, open, setOpen, setOpenAbsenceNotificationModal } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isChanged, setIsChanged] = useState(false);

    const template = useAppSelector(absenceReportsSelectors.getSelectedTemplate);
    const templateFieldsWithoutRequestedMedicalAdvisory = useAppSelector(
        absenceReportsSelectors.getTemplateFieldsWithoutRequestedMedicalAdvisory,
    );
    const selectedAbsenceReport = useAppSelector(absenceReportsSelectors.getSelectedAbsenceReport);
    const showAbsenceNotification = useAppSelector(absenceReportsSelectors.showAbsenceNotification);

    useEffect(() => {
        if (selectedAbsenceReport && !selectedAbsenceReport.fields) {
            dispatch(thunkActions.getAbsenceReportFields(selectedAbsenceReport.id!, true));
        }
    }, [dispatch, selectedAbsenceReport]);

    const handleOnUpdateAbsenceReportBaseChange = useCallback(
        (fieldName: string, value: any, endDate?: string | undefined, timeStamp?: string | undefined) => {
            if (selectedAbsenceReport && selectedAbsenceReport.id) {
                if (fieldName === "degree") {
                    dispatch(
                        absenceReportsActions.updateSelectedAbsenceReportDegrees({
                            value,
                            timeStamp,
                        }),
                    );
                } else {
                    dispatch(
                        absenceReportsActions.updateSelectedAbsenceReportBaseDates({
                            fieldName,
                            value,
                            endDate,
                        }),
                    );
                }
            }
            setIsChanged(true);
        },
        [dispatch, selectedAbsenceReport],
    );

    const handleUpdateField = useCallback(
        (fieldName: string, value: string) => {
            dispatch(
                absenceReportsActions.updateSelectedAbsenceReportField({
                    fieldName,
                    value,
                    templateFields: template?.fields ?? [],
                }),
            );
            setIsChanged(true);
        },
        [dispatch, template?.fields],
    );

    const handleSubmit = useCallback(() => {
        if (selectedAbsenceReport && template?.id) {
            dispatch(
                thunkActions.submitSelectedAbsenceReport({
                    absenceReport: selectedAbsenceReport,
                    templateId: template.id,
                    resetAbsenceReport: !showAbsenceNotification,
                }),
            );
        }
        setOpen(false);
        if (showAbsenceNotification) {
            setOpenAbsenceNotificationModal();
        }
    }, [
        dispatch,
        selectedAbsenceReport,
        setOpen,
        setOpenAbsenceNotificationModal,
        showAbsenceNotification,
        template?.id,
    ]);

    const handleSetOpen = useCallback(
        (open: boolean) => {
            dispatch(absenceReportsActions.resetSelectedAbsenceReport());
            setOpen(open);
        },
        [dispatch, setOpen],
    );
    const datepickerProps = useSingleDatepickerProps({
        onChangeDate: (v) => handleUpdateField("date", v ?? ""),
        givenSelectedDate: getAbsenceReportFieldValueByName("DynamicDate", selectedAbsenceReport?.fields ?? []),
        placeholder: t("choseDate"),
    });

    const dateTimepickerProps = useDateTimepickerProps({
        onChangeDateTime: (v) => handleUpdateField("datetime", v ?? ""),
        givenSelectedDateTime: getAbsenceReportFieldValueByName("DynamicDateTime", selectedAbsenceReport?.fields ?? []),
        placeholder: t("choseDate"),
    });

    return (
        <div id="userAbsenceEdit">
            <ModalFrame
                id={`${id}-EditArModal`}
                header={<h1>{t("editAr")}</h1>}
                setOpen={handleSetOpen}
                open={open}
                content={
                    <div className="w-full">
                        <AbsenceReportBase
                            isUpdate={true}
                            isEdit={true}
                            absenceReportType={selectedAbsenceReport?.type ?? ""}
                            onUpdate={handleOnUpdateAbsenceReportBaseChange}
                        />
                        {templateFieldsWithoutRequestedMedicalAdvisory?.map(
                            (field: IFieldTemplateExtended, i: number) => {
                                return (
                                    <div key={i}>
                                        {field.widget && selectedAbsenceReport && template?.fields ? (
                                            <GetWidget
                                                absenceReport={selectedAbsenceReport}
                                                template={template}
                                                allFields={template.fields}
                                                isEdit={true}
                                                templateField={field}
                                                valueChanged={() => setIsChanged(true)}
                                                absenceReportFields={selectedAbsenceReport?.fields ?? []}
                                            />
                                        ) : (
                                            <>
                                                <Accordion
                                                    key={`${field.header}-${field.id}`}
                                                    heading={field.header!}
                                                    value={t(
                                                        getAbsenceReportFieldValueByName(
                                                            field.name!,
                                                            selectedAbsenceReport?.fields ?? [],
                                                        ),
                                                    )}
                                                    isMandatory={field.mandatory!}
                                                >
                                                    <FindDynamicComponent
                                                        templateField={field}
                                                        value={getAbsenceReportFieldValueByName(
                                                            field.name!,
                                                            selectedAbsenceReport?.fields ?? [],
                                                        )}
                                                        updateField={(value: string) => {
                                                            handleUpdateField(field.name!, value);
                                                        }}
                                                        datepickerProps={datepickerProps}
                                                        dateTimepickerProps={dateTimepickerProps}
                                                    />
                                                </Accordion>
                                                {field.name === "AbsenceCause" && (
                                                    <Accordion
                                                        heading={t("confidential")}
                                                        value={t(
                                                            String(
                                                                !getAbsenceReportFieldByName(
                                                                    selectedAbsenceReport?.fields ?? [],
                                                                    "AbsenceCause",
                                                                )?.confidential,
                                                            ),
                                                        )}
                                                        isMandatory={!field.confidential!}
                                                    >
                                                        <RadioButtonGroup
                                                            isHorizontal
                                                            disabled={
                                                                getAbsenceReportFieldValueByName(
                                                                    field.name!,
                                                                    selectedAbsenceReport?.fields ?? [],
                                                                ).toLowerCase() === "confidential" ||
                                                                getAbsenceReportFieldValueByName(
                                                                    field.name!,
                                                                    selectedAbsenceReport?.fields ?? [],
                                                                ) === ""
                                                            }
                                                            clicked={(value: any) => {
                                                                handleUpdateField("AbsenceCause", value);
                                                            }}
                                                            selectedValue={String(
                                                                !getAbsenceReportFieldByName(
                                                                    selectedAbsenceReport?.fields ?? [],
                                                                    "AbsenceCause",
                                                                )?.confidential,
                                                            )}
                                                            values={[
                                                                {
                                                                    description: t("yes"),
                                                                    value: "true",
                                                                },
                                                                {
                                                                    description: t("no"),
                                                                    value: "false",
                                                                },
                                                            ]}
                                                            id="AbsenceCause"
                                                        />
                                                    </Accordion>
                                                )}
                                            </>
                                        )}
                                    </div>
                                );
                            },
                        )}
                    </div>
                }
                footer={
                    <ModalCommonFooter
                        primaryOnClick={handleSubmit}
                        primaryText={t("saveChanges")}
                        primaryDisabled={
                            (!isChanged && !selectedAbsenceReport?.isPreliminary) ||
                            !absenceReportMandatoryfieldsIsValid(
                                selectedAbsenceReport,
                                templateFieldsWithoutRequestedMedicalAdvisory,
                            )
                        }
                    />
                }
            />
        </div>
    );
};
export default AbsenceReportEditModal;
