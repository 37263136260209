import { ReactElement } from "react";

interface ITooltip {
    heading?: string;
    text?: string;
    className?: string;
    children?: ReactElement;
}

const Tooltip = ({ heading, text, className = "w-72", children }: ITooltip) => (
    <div className={`z-20 absolute bg-white p-4 shadow-md text-primaryText text-sm ${className}`}>
        {heading && <div className="font-bold mb-4">{heading}</div>}
        {text && <div>{text}</div>}
        {children}
    </div>
);

export default Tooltip;
