import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IReturnUrl, IUsernameAndPassSliceProps, UsernameAndPassTranslations } from "pages/login/types";
import UsernameAndPass from "./UsernameAndPass";
import { useCallback, useEffect, useMemo } from "react";
import { getTranslationsFromArray } from "utils/functions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectors as LoginSelectors } from "./../../redux";
import { loginUsernameAndPass } from "pages/login/redux/loginAsyncActions";
import { loginActions } from "pages/login/redux/loginSlice";

export default function Index({ returnUrl }: IReturnUrl) {
    const { t } = useTranslation("login");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const usernameInput = useAppSelector(LoginSelectors.usernameInput);
    const passwordInput = useAppSelector(LoginSelectors.passwordInput);
    const failedAt = useAppSelector(LoginSelectors.failedAt);
    const isLoading = useAppSelector(LoginSelectors.isLoading);
    const selectedMarket = useAppSelector(LoginSelectors.selectedMarket);
    const userAccounts = useAppSelector(LoginSelectors.userAccounts);
    const countdownTimer = useAppSelector(LoginSelectors.countdownTimer);

    const translations = useMemo(() => getTranslationsFromArray(UsernameAndPassTranslations, t), [t]);
    const submitDisabled = useMemo(
        () => !usernameInput || !passwordInput || isLoading,
        [isLoading, passwordInput, usernameInput],
    );

    const handleSubmit = useCallback(async (): Promise<void> => {
        if (!submitDisabled) {
            dispatch(loginActions.setIsLoading(true));
            dispatch(
                loginUsernameAndPass({
                    username: usernameInput,
                    password: passwordInput,
                    returnUrl: returnUrl ?? "",
                }),
            );
        }
    }, [dispatch, passwordInput, returnUrl, submitDisabled, usernameInput]);

    const handleSetPropValue = useCallback(
        (newValue: string, prop: keyof IUsernameAndPassSliceProps) => {
            dispatch(loginActions.setUsernameAndPassPropValue({ newValue, prop }));
        },
        [dispatch],
    );

    useEffect(() => {
        if (countdownTimer > 0) {
            dispatch(loginActions.setCountdownTimer(0));
        }
    }, [countdownTimer, dispatch]);

    useEffect(() => {
        if (userAccounts) {
            navigate(`/login/selectCompany`);
        }
    }, [navigate, userAccounts]);

    return (
        <UsernameAndPass
            usernameInput={usernameInput}
            passwordInput={passwordInput}
            setUsernameAndPassPropValue={handleSetPropValue}
            selectedMarket={selectedMarket ?? ""}
            translations={translations}
            handleSubmit={handleSubmit}
            submitDisabled={submitDisabled}
            isLoading={isLoading}
            failedAt={failedAt}
            navigate={navigate}
        />
    );
}
