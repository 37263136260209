import { IAccessRightTypeEnum } from "domain/accessRights";
import { AccountAccessRightViewModel } from "swagger/customercompany";

export const isManager = (accessRights: AccountAccessRightViewModel[], topDepartment: number) => {
    // Filter access rights where departmentId matches topDepartment
    const filteredAccessRights = accessRights.filter((accessRight) => accessRight.externalId === topDepartment);

    // Map the filtered access rights to their types
    const accessRightTypes = filteredAccessRights.map((accessRight) => accessRight.accessRightType);

    // Check for specific access rights in the filtered list
    const hasAbsenceFollowUpAccessRight = accessRightTypes.includes(IAccessRightTypeEnum.absenceFollowup);
    const hasStatisticsViewAccessRight = accessRightTypes.includes(IAccessRightTypeEnum.absenceStatistics);
    const hasRehabAccessRight = accessRightTypes.includes(IAccessRightTypeEnum.rehabDepartmentManagement);

    // Return true if all required access rights are found, otherwise false
    return hasAbsenceFollowUpAccessRight && hasStatisticsViewAccessRight && hasRehabAccessRight;
};
