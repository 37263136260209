import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { UseFormRegisterReturn } from "react-hook-form";

export interface IServiceDatePicker {
    label: string;
    activationRegister?: UseFormRegisterReturn<string>;
    deactivationRegister?: UseFormRegisterReturn<string>;
    activationDate?: string;
    deactivationDate?: string;
    children?: ReactNode;
}

const ServiceDatePicker = ({
    label,
    activationRegister,
    deactivationRegister,
    activationDate,
    deactivationDate,
    children,
}: IServiceDatePicker) => {
    const { t } = useTranslation("service");
    return (
        <div
            className={`py-2 min-h-[56px] px-6 flex w-full justify-between items-center border-1 bg-white my-1 flex-wrap ${
                activationDate ? "text-mhgrey-darkdisabled" : ""
            }`}
        >
            <div className="w-80">{label}</div>
            <div className="text-xs flex ">
                <div className={`pr-2 `}>{t("activationDate")}:</div>
                {activationRegister && (
                    <input className="font-bold bg-transparent" type="date" {...activationRegister} />
                )}
                {activationDate && (
                    <input className="font-bold bg-transparent" type="date" value={activationDate} disabled />
                )}
            </div>
            <div className="text-xs flex">
                <div className="pr-2">{t("deactivationDate")}:</div>
                {deactivationRegister && (
                    <input className="font-bold bg-transparent" type="date" {...deactivationRegister} />
                )}
                {deactivationDate && (
                    <input className="font-bold bg-transparent" type="date" value={deactivationDate} disabled />
                )}
            </div>
            {children}
        </div>
    );
};

export default ServiceDatePicker;
