/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchSubscription } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchSubscriptionBuilder = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchSubscription.fulfilled, (state, action) => {
            state.subscription.userSubscription = action.payload.subscription;
            state.subscription.userSubscriber = action.payload.subscriber;
            state.status.userSubscription = Status.OK;
        })
        .addCase(fetchSubscription.pending, (state) => {
            state.status.userSubscription = Status.PENDING;
        })
        .addCase(fetchSubscription.rejected, (state, action) => {
            if (action.payload && action.payload?.code === "403") {
                state.subscription.missingSubscriptionAccessRightsForUser = true;
            } else {
                state.status.userSubscription = Status.ERROR;
            }
        });
export default fetchSubscriptionBuilder;
