import Timepicker from "components/Timepicker";
import { SingleDatepicker } from "../SingleDatepicker";
import { IDateTimepickerProps } from "../types";
import { setDateStringFromArray } from "../utils/util";

const DateTimepicker = ({
    selectedDateAsNumbers,
    handleHourMinuteChange,
    disableDatesBefore,
    disableDatesAfter,
    hideTimepicker,
    timepickerHeading,
    timepickerIsMandatory,
    datepickerProps,
    heading,
    isMandatory,
}: IDateTimepickerProps) => {
    return (
        <div id="datetime-picker">
            <SingleDatepicker
                {...datepickerProps}
                disableDatesBefore={disableDatesBefore}
                disableDatesAfter={disableDatesAfter}
                heading={heading}
                isMandatory={isMandatory}
            />
            <div className="mb-4" />
            {!hideTimepicker && (
                <Timepicker
                    heading={timepickerHeading}
                    hourValue={selectedDateAsNumbers[3] ?? ""}
                    hourOnChange={(v, valid) => handleHourMinuteChange(v, valid, true)}
                    minuteValue={selectedDateAsNumbers[4] ?? ""}
                    minuteOnChange={handleHourMinuteChange}
                    disabled={!setDateStringFromArray(selectedDateAsNumbers)}
                    mandatory={timepickerIsMandatory}
                    className="w-full xs:max-w-[616px]"
                />
            )}
        </div>
    );
};

export default DateTimepicker;
