export type Borders = "Top" | "Bottom" | "Left" | "Right";

interface ICreateBroderForGlobalClass {
    borders: Borders[];
    /** size in pixels  */
    size: number;
    /** as #hex color */
    color: string;
    /** global `className` */
    cssClass: string;
    onlyLastElement: boolean;
}
/**
 * Creates a specific border "Top" | "Right" | "Left" | "Bottom" for a global class
 */
export const changeBorderForGlobalClass = ({
    borders,
    size,
    cssClass,
    onlyLastElement = false,
}: ICreateBroderForGlobalClass) => {
    const collection = document.getElementsByClassName(cssClass);
    if (!collection) return;
    for (let i = 0; i < collection.length; i++) {
        if (onlyLastElement) {
            if (i === collection.length - 1) {
                // @ts-ignore
                collection[i].style.border = "none";
                borders.forEach((border) => {
                    // @ts-ignore
                    collection[i].style[`border${border}`] = `${size}px solid rgba(25, 45, 5,0.5)`;
                });
                return;
            }
            continue;
        }
        // @ts-ignore
        collection[i].style.border = "none";
        borders.forEach((border) => {
            // @ts-ignore
            collection[i].style[`border${border}`] = `${size}px solid rgba(25, 45, 5,0.5)`;
        });
    }
};
