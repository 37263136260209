import Icon from "components/Icon";
import { IEditValues } from "../../redux";

export interface IProps {
    data: IEditValues[] | undefined;
}
const EditAbsenceValue = ({ data }: IProps) => (
    <>
        {data?.map((editValue) => (
            <div className="flex items-center">
                <div className="pr-3">{editValue.oldValue}</div>
                <Icon icon="arrowRight" size={20} />
                <div className=" px-3">{editValue.newValue}</div>
            </div>
        ))}
    </>
);

export default EditAbsenceValue;
