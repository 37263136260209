import * as React from "react";
import { isString } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { selectors, thunkActions } from "pages/FollowUp/redux";
import { getIsArchived } from "pages/FollowUp/redux/followUpSelectors";
import { useAppDispatch, useAppSelector } from "store/hooks";
import SecondaryButton from "components/SecondaryButton";
import Icon from "components/Icon";
import { getUserRetailer } from "store/userSelectors";
import AbsenceHistoryStatistics from "../../../components/AbsenceHistoryStatistics";
import AbsenceChart from "../../../components/AbsenceChart";
import FollowUpAbsenceList from "../../../components/FollowUpAbsenceList";
import FollowUpDeviantAbsenceList from "../../../components/FollowUpDeviantAbsenceList";
import ExcelUploadedModal from "../../../components/ExcelUploaded";

const Absence = () => {
    const [excelUploaded, setExcelUploaded] = React.useState<string | null>(null);
    const userEmployment = useAppSelector(selectors.getUserEmployment);
    const noAbsenceData = useAppSelector(selectors.getNoAbsenceData);
    const context = useAppSelector(getUserRetailer);
    const isArchived = useAppSelector(getIsArchived);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    return (
        <div>
            <div className={`${isArchived ? "opacity-30" : ""}`}>
                <AbsenceHistoryStatistics />
                <div className="flex flex-col w-full gap-8 flex-wrap md:flex-row">
                    <div className="flex-1 mt-8 ">
                        <AbsenceChart />
                    </div>
                    <FollowUpDeviantAbsenceList />
                </div>
            </div>
            <div className="flex mt-20 mb-8 justify-between">
                <div className="flex-1 flex flex-col gap-4 sm:gap-0 sm:flex-row sm:justify-between">
                    <h2>{t("absenceReported")}</h2>
                    {!noAbsenceData && (
                        <SecondaryButton
                            className="max-w-none justify-center sm:max-w-xs"
                            id="export-button-absence-employee-card"
                            onClick={() => {
                                if (userEmployment?.id && userEmployment?.companyId) {
                                    dispatch(
                                        thunkActions.exportHistoricalAbsenceReport({
                                            employmentId: userEmployment.id,
                                            companyId: userEmployment.companyId,
                                            translation: {
                                                workbookTitle: t("absenceHistory"),
                                                titles: [
                                                    {
                                                        name: "startdate",
                                                        value: t("startDate"),
                                                    },
                                                    {
                                                        name: "enddate",
                                                        value: t("endDate"),
                                                    },
                                                    {
                                                        name: "backatwork",
                                                        value: t("backAtWork"),
                                                    },
                                                    {
                                                        name: "absencetype",
                                                        value: t("absenceType"),
                                                    },
                                                    {
                                                        name: "absencedegree",
                                                        value: t("absenceDegree"),
                                                    },
                                                    {
                                                        name: "absencecause",
                                                        value: t("absenceCause"),
                                                    },
                                                    {
                                                        name: "workrelated",
                                                        value: t("workRelated"),
                                                    },
                                                    {
                                                        name: "workplaceaccident",
                                                        value: t("workplaceAccident"),
                                                    },
                                                ],
                                                absenceTypes: [
                                                    {
                                                        name: "Sick",
                                                        value: t("sick"),
                                                    },
                                                    {
                                                        name: "CareOfChild",
                                                        value: t("careOfChild"),
                                                    },
                                                ],
                                                absenceCauses: [
                                                    {
                                                        name: "None",
                                                        value: t("None"),
                                                    },
                                                    {
                                                        name: "Confidential",
                                                        value: t("Confidential"),
                                                    },
                                                    {
                                                        name: "AllergySkin",
                                                        value: t("AllergySkin"),
                                                    },
                                                    {
                                                        name: "ChestHeartVascular",
                                                        value: t("ChestHeartVascular"),
                                                    },
                                                    {
                                                        name: "HeadacheNeurologicalDisorders",
                                                        value: t("HeadacheNeurologicalDisorders"),
                                                    },
                                                    {
                                                        name: "Infection",
                                                        value: t("Infection"),
                                                    },
                                                    {
                                                        name: "FluSymptoms",
                                                        value: t("FluSymptoms"),
                                                    },
                                                    {
                                                        name: "StomachIntestinalAbdomenInconvenience",
                                                        value: t("StomachIntestinalAbdomenInconvenience"),
                                                    },
                                                    {
                                                        name: "LowerRespiratoryTract",
                                                        value: t("LowerRespiratoryTract"),
                                                    },
                                                    {
                                                        name: "EarNoseThroat",
                                                        value: t("EarNoseThroat"),
                                                    },
                                                    {
                                                        name: "MentalSocialHealth",
                                                        value: t("MentalSocialHealth"),
                                                    },
                                                    {
                                                        name: "Musculo",
                                                        value: t("Musculo"),
                                                    },
                                                    {
                                                        name: "Other",
                                                        value: t("Other"),
                                                    },
                                                    {
                                                        name: "Eyes",
                                                        value: t("Eyes"),
                                                    },
                                                ],
                                                booleanValues: [
                                                    {
                                                        name: "true",
                                                        value: t("yes"),
                                                    },
                                                    {
                                                        name: "false",
                                                        value: t("no"),
                                                    },
                                                ],
                                            },
                                        }),
                                    ).then((value) => {
                                        setExcelUploaded(value ? t("excelUploadedOkay") : t("excelUploadedFailed"));
                                    });
                                }
                            }}
                        >
                            <div className="flex items-center gap-3">
                                <Icon icon="excel" />
                                <p className="font-bold">{t("export")}</p>
                            </div>
                        </SecondaryButton>
                    )}
                </div>
            </div>
            <FollowUpAbsenceList />
            <ExcelUploadedModal
                isOpen={isString(excelUploaded)}
                setOpen={() => setExcelUploaded(null)}
                text={excelUploaded}
                header={t("messages")}
                context={context}
            />
        </div>
    );
};
export default Absence;
