import Icon from "components/Icon/Icon";
import MiniMenu from "components/MiniMenu";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { widgetMap } from "../Widget/widgetMap";
import { actions } from "pages/dashboard/redux";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Accordion from "components/Accordion/Accordion";
import { ModalFrame, ModalCommonFooter } from "@medhelp/ui";
import { IWidget } from "pages/dashboard/utils/dashboardTypes";
import WidgetDropdownContainer from "./WidgetDropdownContainer";
import { deleteWidget, updateWidget } from "pages/dashboard/redux/dashboardActions";
import { hasSelectedRequiredFilters } from "pages/dashboard/utils/dashboardSliceUtils";
import { getCompanies, getWidgetHeading } from "pages/dashboard/redux/dashboardSelectors";

const WidgetFilterContainer = (widget: IWidget) => {
    const { id, type } = widget;
    const dispatch = useAppDispatch();
    const { t } = useTranslation("dashboard");
    const currentWidget = widgetMap.get(type);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const widgetHeading = useAppSelector(getWidgetHeading(id));
    const companies = useAppSelector(getCompanies(id));

    const handleModalDelete = useCallback(() => {
        dispatch(deleteWidget(id));
        setOpenDeleteModal(false);
    }, [dispatch, id]);

    const buttons = [
        {
            text: t("editAndFilter"),
            onClick: () => setOpenEditModal(true),
        },
        {
            text: t("deleteWidget"),
            onClick: () => setOpenDeleteModal(true),
        },
    ];

    return (
        <div className="flex justify-center items-center">
            <MiniMenu
                buttonValues={buttons}
                id={`miniMenu-${id}`}
                preset={`${currentWidget?.style === "primary" ? "white" : "opacityHover"}`}
                buttonRight
                containerClassName="flex item-center justify-end"
                iconColor={`${currentWidget?.style === "primary" ? "green" : "beige"}`}
            />
            <ModalFrame
                id="widgetModal"
                open={openEditModal}
                setOpen={() => setOpenEditModal(false)}
                header={<h3>{t("editWidget")}</h3>}
                content={
                    <div className="w-full flex justify-center">
                        <div className="w-full">
                            <Accordion
                                icon={<Icon icon="widgetTitle" />}
                                heading={t("changeWidgetTitle")}
                                value={t(`widgetHeading.${currentWidget?.heading}`)}
                                isMandatory={false}
                                className="widget-accordion-bg"
                            >
                                <div className="flex justify-between w-full border border-mhgrey-light">
                                    <input
                                        onChange={(e) =>
                                            dispatch(actions.updateWidgetHeading({ id, heading: e.target.value }))
                                        }
                                        value={widgetHeading || ""}
                                        type="text"
                                        className="bg-pageBg flex-grow p-4 focus:border-0"
                                        maxLength={48}
                                        data-testid="widget-title-input"
                                    />
                                    <button
                                        type="button"
                                        className="p-3"
                                        onClick={() => {
                                            dispatch(actions.updateWidgetHeading({ id, heading: "" }));
                                        }}
                                    >
                                        {t("resetTitle")}
                                    </button>
                                </div>
                            </Accordion>
                            {type === "absence-forecast-current-month" && companies?.length === 1 ? null : (
                                <Accordion
                                    icon={<Icon icon="widgetFilter" />}
                                    heading={t("widgetFilters")}
                                    value={t("allOrganizations")}
                                    isMandatory={false}
                                    className="widget-accordion-bg"
                                >
                                    <WidgetDropdownContainer id={id} type={type} />
                                </Accordion>
                            )}
                        </div>
                    </div>
                }
                footer={
                    <ModalCommonFooter
                        primaryText={t("saveChanges")}
                        primaryOnClick={() => {
                            dispatch(updateWidget({ id, type }));
                            setOpenEditModal(false);
                        }}
                        primaryDisabled={!hasSelectedRequiredFilters(widget, currentWidget)}
                    />
                }
            />

            <ModalFrame
                id="deleteWidgetModal"
                open={openDeleteModal}
                setOpen={() => setOpenDeleteModal(false)}
                header={<h2>{t("deleteWidget")}</h2>}
                content={
                    <div>
                        <p>{t("deleteModalTopContent")}</p>
                        <br />
                        <p>{t("deleteModalBottomContent")}</p>
                    </div>
                }
                footer={
                    <ModalCommonFooter
                        primaryText={t("delete")}
                        primaryOnClick={handleModalDelete}
                        tertiaryText={t("cancel")}
                        tertiaryOnClick={() => setOpenDeleteModal(false)}
                    />
                }
            />
        </div>
    );
};
export default WidgetFilterContainer;
