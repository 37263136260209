import { useAppSelector } from "store/hooks";
import { useMemo } from "react";
import { getUserRetailer } from "store/userSelectors";

const useRetailerColors = () => {
    const context = useAppSelector(getUserRetailer);

    return useMemo(() => {
        switch (context) {
            case "Avonova": {
                return {
                    primary: "#005646",
                    secondary: "#00AD8C",
                    stackedChartPrimary: "#00AD8C",
                    stackedChartSecondary: "#005646",
                    pageBackground: "#FFFFFF",
                };
            }
            case "Feelgood": {
                return {
                    primary: "#052651",
                    secondary: "#246CCA",
                    stackedChartPrimary: "#246CCA",
                    stackedChartSecondary: "#052651",
                    pageBackground: "#FFFFFF",
                };
            }
            case "Falck": {
                return {
                    primary: "#66182A",
                    secondary: "#FF1E2D",
                    stackedChartPrimary: "#FF1E2D",
                    stackedChartSecondary: "#66182A",
                    pageBackground: "#FFFFFF",
                };
            }
            default: {
                return {
                    primary: "#334311",
                    secondary: "#81943D",
                    stackedChartPrimary: "#839500",
                    stackedChartSecondary: "#626E06",
                    pageBackground: "#F5F5F5",
                };
            }
        }
    }, [context]);
};

export default useRetailerColors;
