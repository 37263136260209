import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { thunkActions } from "pages/FollowUp/redux";
import { getHealthCase } from "pages/FollowUp/redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { CaseContentContainer } from "../../../../components/HealthCaseManagement";
import CaseDocumentsContent from "./CaseDocumentsContent";

interface IProps {
    healthCase?: IHealthCase;
}

const CaseDocuments = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const { routeCaseId } = useParams();
    const dispatch = useAppDispatch();
    const selectedCase = useAppSelector(getHealthCase(routeCaseId ?? ""));
    const currentCase = healthCase ? healthCase : selectedCase;

    useEffect(() => {
        if (currentCase?.id) {
            dispatch(thunkActions.fetchCaseDocumentList(currentCase.id));
        }
    }, [currentCase?.id, dispatch]);

    return currentCase ? (
        <CaseContentContainer heading={t("documentsTemplates")} text={t("documentsInfo")} healthCase={currentCase}>
            <CaseDocumentsContent healthCase={currentCase} />
        </CaseContentContainer>
    ) : null;
};

export default CaseDocuments;
