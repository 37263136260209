import { Card } from "@medhelp/ui";
import ExportSearchExcel from "../ExportSearchExcel";

interface IProps {
    body: string;
    disabled: boolean;
    isLoading: boolean;
}
const MonthlyReport = ({ body, disabled, isLoading }: IProps) => (
    <Card>
        <div className="flex flex-col max-w-md gap-7">
            <p>{body}</p>
            <div className="flex gap-2 self-center">
                <ExportSearchExcel disabled={disabled} isLoading={isLoading} />
            </div>
        </div>
    </Card>
);
export default MonthlyReport;
