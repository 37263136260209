import newsMedicalCertificateENG from "./assets/newsMedicalCertificateENG.pdf";
import newsMedicalCertificateSWE from "./assets/newsMedicalCertificateSWE.pdf";

interface NewsItem {
    header: string;
    text: string;
    link?: string;
    linkText?: string;
}
export interface Languages {
    en: NewsItem;
    sv: NewsItem;
    no?: NewsItem;
    da?: NewsItem;
}
export interface INews extends Languages {
    id: string;
    published: boolean;
    publishedDate: string;
    publisher: string | null;
}

export const medhelpNews: INews[] = [
    {
        id: "medhelpHRReport",
        sv: {
            header: "MedHelps årliga HR-rapport är här!",
            text: "Vi kan glatt meddela att årets HR-rapport är här! I denna rapport har vi för tredje året i rad undersökt synen på sjukfrånvaro, rehabilitering och hälsoarbete på svenska arbetsplatser. Du hittar rapporten",
            link: "https://knowledge.medhelp.se/hubfs/PDF/HR-rapport_2023.pdf",
            linkText: "här.",
        },
        en: {
            header: "MedHelp's yearly HR report",
            text: "We are pleased to announce that this year's HR report is here! In this report, we have, for the third consecutive year, examined the views on sick leave, rehabilitation, and health work in Swedish workplaces. You can find a swedish version of the report",
            link: "https://knowledge.medhelp.se/hubfs/PDF/HR-rapport_2023.pdf",
            linkText: "here.",
        },
        published: true,
        publishedDate: "2023-07-03",
        publisher: null,
    },
    {
        id: "sortableModals",
        sv: {
            header: "Sortera innehållet i widget-modalerna",
            text: "Flera av våra widgets på dashboarden är klickbara, vilket innebär att du t.e.x kan få upp en lista med aktuella personer som döljer sig bakom en siffra. Nu har vi dessutom lagt till en sorteringsfunktion, som möjliggör för dig att sortera listorna på respektive rubrik. Vi hoppas att denna lilla förbättring kommer underlätta ditt dagliga arbete.",
        },
        en: {
            header: "Sort the content in the Widget modals",
            text: "Several of our widgets on the dashboard are clickable, which means that you can, for example, bring up a list of the actual people hiding behind a number. We have now also added a sorting function, which allows you to sort the lists under each heading. We hope that this small improvement will make your daily work easier.",
        },
        published: true,
        publishedDate: "2023-06-29",
        publisher: null,
    },
    {
        id: "reopenHistoricalAbsence",
        sv: {
            header: "Möjlighet att återöppna en avslutad frånvaroanmälan",
            text: "Nu finns det möjlighet för användare som har rätt att redigera historisk frånvaro, att ta bort slutdatumet på en avslutad frånvaroanmälan och alltså göra den pågående igen. \nGå till menyvalet 'Frånvaroanmälan' och sök fram aktuell medarbetare. Under rubriken historisk frånvaro, klickar du på 'tre prickarna' till höger om respektive anmälan för att återöppna anmälan.",
        },
        en: {
            header: "Possibility to reopen a closed absence report",
            text: "Now there is the possibility for users with permission to edit historical absence, to remove the end date of a closed absence report and thus make it ongoing again.Go to the menu option 'Absence report' and search for the relevant employee. Under the heading historical absence, click on 'the three dots' to the right of each report to reopen the report.",
        },
        published: true,
        publishedDate: "2023-06-29",
        publisher: null,
    },
    {
        id: "absenceMessageUpdates",
        sv: {
            header: "Ny hantering av kommentarer i frånvaroanmälan",
            text: "I förebyggande syfte har vi ökat informationssäkerheten avseende hanteringen av meddelanden som medarbetaren kan skicka till sin arbetsgivare i samband med frånvaroanmälan. Från och med nu informerar vi mottagaren via e-post/sms när en medarbetare har skrivit in en kommentar, men för att ta del av meddelandet, behöver mottagaren logga in i plattformen och titta på den aktuella frånvaroanmälan.",
        },
        en: {
            header: "Notifications regarding absence messages",
            text: "We have increased our information security regarding the handling of messages that the employee may send to the employer in connection with their absence notification. From now on, we inform the recipient via e-mail/text message when an employee has entered a comment, but to receive the message, the recipient needs to log in to the platform and look at the current absence report.",
        },
        published: true,
        publishedDate: "2023-05-03",
        publisher: null,
    },
    {
        id: "followUpUpdates",
        sv: {
            header: "Uppdateringar på menyvalet, Uppföljning",
            text: 'På menyvalet Uppföljning har vi bytt namn på två sidor i undermenyn. Från "Sök frånvaro" till "Rapporter" och från "Sök medarbetare" till "Medarbetare". På respektive sida har vi även dolt filtreringsfunktionen under knappen "filtrera resultat" till höger på sidan. Alla rapporter och funktioner från den förra versionen finns kvar sedan tidigare, men utseendet på sökresultatet har uppdaterats för en tydligare översikt.',
        },
        en: {
            header: "Updates in Follow-up menu",
            text: 'We have renamed two pages in the sub-menu in the Follow-up menu option. From "Search absence" to "Reports" and from "Search employees" to "Employees". On each page, we have also hidden the filtering function under the "filter results" button on the right of the page. All the reports and features from the previous version remain, but the appearance of the search results has been updated for a clearer overview.',
        },
        published: true,
        publishedDate: "2023-01-24",
        publisher: null,
    },
    {
        id: "followHealthIndex",
        sv: {
            header: "Följ företagets Hälsoindex och jämför frånvaron per kvartal",
            text: "Nu har vi uppdaterat vår statistikmeny med kvartalsrapporter för ökad jämförbarhet. Här hittar du kategorierna Hälsoindex och frånvaroprocent som båda kan grupperas eller filtreras på antingen avdelning eller kostnadsställe. Hälsoindex är ett nytt KPI och som visar hur stor procentuell andel av era medarbetare som haft mindre än 6 frånvarodagar, de senaste 12 månaderna.",
        },
        en: {
            header: "Follow the company's Health Index and compare absence per quarter",
            text: "We have updated our statistics menu with quarterly reports for increased comparability. Here you will find the categories Health index and absence percentage, both of which can be grouped or filtered by either department or cost center. Health index is a new KPI that measures the percentage of your employees who had less than 6 days of absence in the last 12 months.",
        },
        published: true,
        publishedDate: "2022-10-18",
        publisher: null,
    },
    {
        id: "newVersionProfile",
        sv: {
            header: "Ny version av personkort",
            text: "Nu har vi släppt en ny version av medarbetarens personkort. Överst på sidan finner du en statusbar och på den nedre delen av sidan har vi delat upp information på olika flikar för en bättre överblick. Innehållet på personkortet är till stor del detsamma som tidigare, men vi har lagt till några nya nyckeltal för senaste 12 månaderna och tagit fram en ny graf som visar frånvarotrenden för de senaste 5 åren.",
        },
        en: {
            header: "Updated employee card",
            text: "We have now released a new version of the employee card. At the top of the page, you will now find a status bar and at the bottom of the page, we have divided information into different tabs for a better overview. The content of the personal card is largely the same as before, but we have added some new key figures for the last 12 months and a new graph that shows the absence trend for the last 5 years.",
        },
        published: true,
        publishedDate: "2022-05-31",
        publisher: null,
    },
    {
        id: "dashboardSummary",
        sv: {
            header: "Ny summering på dashboarden",
            text: "Nu visas en ny yta överst på dashboarden där vi summerar frånvaroläget för alla medarbetare som du har behörighet till. Här ser du det totala antalet medarbetare och hur många av dessa som är frånvarande just nu, men även antalet sjuk- & friskanmälningar som påbörjat resp. avslutat sin frånvaro igår och idag. Genom att klicka på en understruken siffra, kan du se vilken medarbetare som döljer sig bakom siffran och få ytterligare information om frånvaron. Om du har behörighet att följa upp frånvaron på den aktuella medarbetaren, kan du dessutom klicka på denne, för att komma till det medarbetarens personkort.",
        },
        en: {
            header: "Summary displayed on dashboard",
            text: "A new area is now displayed at the top of the dashboard where we summarize the absence status for all employees to whom you are authorized to view. Here you can see the total number of employees and the number of absences right now. You can also see many sick & health reports which started yesterday and today. By clicking on an underlined number, you can see the employee behind the number and get further information about the absence. If you have the authority to follow up on the absence of the current employee, you can also click on the employee to view the employee's personal card.",
        },
        published: true,
        publishedDate: "2022-04-21",
        publisher: null,
    },
    {
        id: "widgetLibrary",
        sv: {
            header: "Ny funktion - Widgetbibliotek",
            text: 'Vi har samlat alla widgets i ett bibliotek, så att du som användare ska kunna göra din dashboard ännu mer personlig. Widgetbiblioteket nås via knappen "Lägg till widget" högst upp på dashboarden. Där kan du läsa om våra olika widgets och lägga till de widgets som du vill följa på din personliga dashboard. Som användare kan du lägga till samma widget flera gånger men anpassa innehållet i var och en av dem genom att välja olika filter på respektive widget. Genom att klicka på de tre prickarna på respektive widget, kan du välja att ta bort den aktuella widgeten från din dashboard. Borttagna widgets kan alltid läggas till via widgetbiblioteket, men tänk på att tidigare sparad filtrering kommer vara nollställd.',
        },
        en: {
            header: "Widget library",
            text: 'You can now find all the widgets in a library so that you as a user can make your dashboard even more personal. The widget library is accessed via the "Add widget" button at the top of the dashboard, where you can also read about our different widgets and add the widgets you want to follow on your personal dashboard. As a user, you can add the same widget several times but customize the content of each of them by selecting different filters. By clicking on the three dots on each widget, you can choose to delete the current widget from your dashboard. Deleted widgets can always be added via the widget library, but keep in mind that previously saved filtering will be reset.',
        },
        published: true,
        publishedDate: "2022-04-20",
        publisher: null,
    },
    {
        id: "medicalCertificateRequired",
        sv: {
            header: "Krav på läkarintyg efter 7 dagar",
            text: "De tillfälliga reglerna om läkarintyg som gällt under pandemin upphör att gälla från och med den 1 april 2022. Detta innebär att man återigen måste styrka sin sjukfrånvaro med ett läkarintyg från och med dag 8 i stället för dag 14. Om din första sjukdag inträffade den 31 mars eller tidigare så gäller de tillfälliga reglerna, men om din första sjukdag inträffade den 1 april eller senare gäller de vanliga reglerna.",
        },
        en: {
            header: "Requirement for medical certificate after 7 days",
            text: "The temporary rules regarding medical certificates during the pandemic will cease to apply from 1 April 2022. This means that you must once again prove your sick leave with a medical certificate from day 8 instead of day 14. If your first day of illness occurred on 31 March or earlier, the temporary rules apply, but if your first day of illness occurred on April 1 or later, the ordinary rules apply.",
        },
        published: true,
        publishedDate: "2022-04-01",
        publisher: null,
    },
    {
        id: "newHelpGuide",
        sv: {
            header: "Ny hjälpguide",
            text: "Idag lanserar vi en hjälpguide inne i plattformen. Hjälpguiden kommer dels finnas samlad på sidan Support, och vi kommer även tillgängliggöra relevant information på respektive meny inne i plattformen via knappen ”hjälp”. Införandet kommer ske successivt, där vi börjar med sidorna Dashboard och Frånvaroanmälan.",
        },
        en: {
            header: "New help guide",
            text: 'Today we are launching a help guide inside the platform. The help guide will be located under the Support page, but we will also make relevant information available on each menu inside the platform via the "help" button. Information will be updated gradually starting with the pages Dashboard and Absence report.',
        },
        published: true,
        publishedDate: "2022-03-28",
        publisher: null,
    },
    {
        id: "medicalCertificateUpload",
        sv: {
            header: "Ny funktion - Uppladdning av läkarintyg",
            text: "Vi har uppdaterat frånvaroanmälan och bl.a. lagt till en funktion som möjliggör för medarbetare (och chefer) att ladda upp läkarintyg till plattformen. Läs mer",
            link: newsMedicalCertificateSWE,
            linkText: "här.",
        },
        en: {
            header: "New feature - Upload medical certificate",
            text: "We have launched a new feature that enables employees to upload their medical certificate to their employer. Read more about the feature",
            link: newsMedicalCertificateENG,
            linkText: "here.",
        },
        published: true,
        publishedDate: "2022-02-18",
        publisher: null,
    },
    {
        id: "temporaryMedicalCertificateRules",
        sv: {
            header: "Tillfälligt slopat krav på läkarintyg under sjuklöneperioden",
            text: "Alla sjukanmälningar som görs fr.o.m. 19 januari, tillämpar de tillfälliga reglerna, d.v.s. krav på läkarintyg från dag 15 i stället för dag 7. Texten i våra påminnelser som går ut till medarbetare (sms) och chef (e-post) dag 7, är ändrade sedan den 25/1.",
        },
        en: {
            header: "Temporary rules for medical certificates",
            text: "All sick reports made from January 19, are affected by the temporary rules which means that requirements for a medical certificate is now from day 15 instead of day 7. The text in our reminders that goes out to employees (SMS) and manager (e-mail) on day 7, has been changed since 25/1.",
        },
        published: true,
        publishedDate: "2022-02-04",
        publisher: null,
    },
    {
        id: "longerQueueTimes",
        sv: {
            header: "Längre kötid till MedHelp än vanligt",
            text: "De senaste veckornas utveckling av Covid-19 innebär att antalet samtal till MedHelp har uppnått en extrem nivå under den senaste veckan. Därför är det periodvis svårt att komma fram per telefon i och med långa kötider. Vi beklagar detta och arbetar intensivt med olika åtgärder för att säkerställa god tillgänglighet för våra kunder. Har ni frågor eller synpunkter angående de åtgärder som vi har vidtagit vänligen återkom till vår kundtjänst.",
        },
        en: {
            header: "Longer wait time to MedHelp than usual",
            text: "The development of Covid-19 in recent weeks means that the number of calls to MedHelp has reached an extreme level during the past week. Therefore, it is periodically difficult to reach us due to long queues. We regret this and work intensively with various measures to ensure good accessibility for our customers. If you have any questions or comments, please contact our customer support.",
        },
        published: true,
        publishedDate: "2022-01-18",
        publisher: null,
    },
    {
        id: "quarantineRules",
        sv: {
            header: "Detta gäller vid karantän och/eller smittbärarpenning",
            text: "Vi uppmuntrar er som arbetsgivare att förtydliga till era medarbetare vad som gäller vid karantän och/eller smittbärarpenning. Karantän räknas inte som sjukfrånvaro och skall därför inte anmälas till MedHelp. Vid dessa ärenden hänvisar MedHelp till arbetsgivarens interna riktlinjer. Smittbärarpenning hanteras av Försäkringskassan utan inblandning från MedHelp. Vi vill även passa på att påminna om möjligheten för medarbetare att göra sin redigering och/eller friskanmälan via webben eller MedHelps app.",
        },
        en: {
            header: "Regarding absence due to quarantine and/or disease carrier benefit",
            text: "We encourage you as an employer to clarify to your employees what applies in the event of quarantine and/or disease carrier benefit. Quarantine is not counted as sick leave and should therefore not be reported to MedHelp. In these cases, MedHelp refers to the employer's internal guidelines. Disease carrier benefit is handled by Försäkringskassan without interference from MedHelp. We would also like to remind you of the opportunity for employees to make their editing and/or report healthy via the web or the MedHelp app.",
        },
        published: true,
        publishedDate: "2022-01-18",
        publisher: null,
    },
    {
        id: "christmasOpeningHours",
        sv: {
            header: "Öppettider under Jul",
            text: "Vi vill passa på att tacka alla våra kunder för året som gått och önskar er en riktigt god jul. Vår kundtjänst kommer att ha öppet som vanligt mellan 8-17 förutom på julafton och röda dagar, då vi har stängt. God Jul och Gott Nytt år önskar MedHelp Care!",
        },
        en: {
            header: "Opening hours during Christmas",
            text: "We would like to take this moment to thank all our customers for a great year and wish you a merry Christmas and a happy new year. Our customer support will be open as usual from 8-17 except for on Christmas Eve and all red days when we are closed. Happy Holidays!",
        },
        published: true,
        publishedDate: "2021-12-21",
        publisher: null,
    },
    {
        id: "covidRecommendations",
        sv: {
            header: "Nya rekommendationer from 22 november: alla med symptom bör testa sig för Covid-19",
            text: "Som tidigare uppmanas alla att vara uppmärksamma på symtom och stanna hemma, men från 22 november rekommenderas även vaccinerade att testa sig för covid-19 när de får symtom. Besök 1177.se för mer information.",
        },
        en: {
            header: "New recommendations from November 22: everyone with symptoms should get tested for Covid-19",
            text: "As before, everyone is encouraged to pay attention to symptoms and stay at home, but from 22 November, vaccinated people are also recommended to get tested for covid-19 when they get symptoms. Visit 1177.se for more information.",
        },
        no: {
            header: "Nye anbefalinger fra 22 november: alle med symptomer bør teste for Covid-19",
            text: "Som tidligere oppfordres alle til å ta hensyn til symptomer og holde seg hjemme, men fra 22 november anbefales også vaksinerte å bli testet for covid-19 når de får symptomer. Besøk 1177.se for mer informasjon.",
        },
        da: {
            header: "Nye anbefalinger fra 22 november: Alle med symptomer bør teste for Covid-19",
            text: "Som tidligere opfordrede alle til at være opmærksamma på symtom og stanna hjemme, men fra 22 november anbefaler vi også vaccinerede at testa sig for covid-19, når de får symtom. Besøg 1177.se for mere information.",
        },
        published: true,
        publishedDate: "2021-11-23",
        publisher: null,
    },
    {
        id: "temporaryCovidRules",
        sv: {
            header: "Nu upphör tillfälliga coronaregler",
            text: "Från och med den 1 oktober upphör många tillfälliga regler som regeringen infört under pandemin. Detta innebär att bland annat att det återigen är krav på läkarintyg efter 7 dagar för alla sjukanmälningar som görs fr.o.m. 1.a oktober. För de sjukanmälningar som inkommit t.o.m. 30 september är det krav på läkarintyg efter 21 dagar.",
        },
        en: {
            header: "Temporary covid-rules ends",
            text: "As of 1st October 2021, temporary rules that were introduced during the corona pandemic will end. This means that employees have to send in a doctor's certificate on their 7th sick day after the 1st October 2021. For applications before October 1st the temporary corona-rules still apply where a doctor's certificate is required after day 21.",
        },
        no: {
            header: "Midlertidige koronaregler opphører nå",
            text: "Fra 1 oktober utløper mange midlertidige regler som ble innført av regjeringen under pandemien. Det betyr blant annet at det igjen er krav om legeerklæring etter 7 dager for alle sykemeldinger laget fra 1 oktober. For sykemeldingene som er mottatt til og med 30 september kreves legeerklæring etter 21 dager.",
        },
        da: {
            header: "Midlertidige coronaregler ophører nu",
            text: "Fra 1 oktober udløber mange midlertidige regler, der blev indført af regeringen under pandemien. Det betyder blandt andet, at der igen er krav om lægeerklæring efter 7 dage for alle sygemeldinger foretaget fra 1 oktober. Til de sygemeldinger, der er modtaget til og med 30 september kræves en lægeerklæring efter 21 dage.",
        },
        published: true,
        publishedDate: "2021-10-01",
        publisher: null,
    },
    {
        id: "updatedMenuStructure",
        sv: {
            header: "Uppdaterad menystruktur för enklare navigering",
            text: 'För att göra det enklare att navigera i plattformen har vi uppdaterat vår menystruktur. Menyn har både omstrukturerats och uppdaterats grafiskt för ökad användarupplevelse. Den största förändringen i menyn är att startssidan har tagits bort och att "Hälsoportalen" har ersatts med "Uppföljning".',
        },
        en: {
            header: "Updated menu structure for easier navigation",
            text: 'To make it easier to navigate the platform, we have updated our menu structure. The menu has been both restructured and graphically updated for a better user experience. The biggest change in the menu is that the start page has been removed and that the "Health Portal" has been replaced with "Follow-up".',
        },
        no: {
            header: "Oppdatert menystruktur for enklere navigering",
            text: 'For å gjøre det lettere å navigere på plattformen, har vi oppdatert menystrukturen vår. Menyen har blitt både omstrukturert og grafisk oppdatert for en økt brukeropplevelse. Den største endringen i menyen er at startsiden er slettet og at "Helseportalen" er erstattet med "Oppfølging".',
        },
        da: {
            header: "Opdateret menustruktur for lettere navigation",
            text: 'For at gøre det lettere at navigere på platformen har vi opdateret vores menustruktur. Menuen er både omstruktureret og grafisk opdateret for en øget brugeroplevelse. Den største ændring i menuen er, at startsiden er blevet slettet, og at "Health Portal" er blevet erstattet med "Follow-up".',
        },
        published: true,
        publishedDate: "2021-08-02",
        publisher: null,
    },
    {
        id: "newDashboard",
        sv: {
            header: "Ny dashboard som ger överblick",
            text: "Med hjälp av vår nya dashboard, kan du följa sjukfrånvaron i din organisation på ett överskådligt sätt med hjälp av ett antal widgets. Innehållet i varje widget uppdateras i realtid och du kan själv filtrera innehållet genom att klicka på de 3 prickarna på respektive widget. Filtreringen som du valt behålls varje gång du loggar in tills du väljer en ny filtrering.",
        },
        en: {
            header: "New dashbord for data visualization",
            text: "With our new dashboard, you can track sick leave in your organization in a clear way with the help of several widgets. The content of each widget is updated with real time data, and you can filter the content yourself by clicking on the 3 dots on each widget. The filter you selected is retained each time you log in until you select a new filter.",
        },
        no: {
            header: "Nytt dashbord som gir oversikt",
            text: "Ved hjelp av vårt nye dashbord kan du spore sykefravær i organisasjonen din på en klar måte ved hjelp av en rekke widgets. Innholdet i hver widget oppdateres i sanntid, og du kan filtrere innholdet selv ved å klikke på de 3 prikkene på hver widget. Filteret du valgte beholdes hver gang du logger deg på til du velger et nytt filter.",
        },
        da: {
            header: "Nyt dashboard, der giver overblik",
            text: "Ved hjælp af vores nye dashboard kan du spore sygefravær i din organisation på en klar måde ved hjælp af en række widgets. Indholdet af hver widget opdateres i realtid, og du kan selv filtrere indholdet ved at klikke på de 3 prikker på hver widget. Det valgte filter bevares hver gang du logger ind, indtil du vælger et nyt filter.",
        },
        published: true,
        publishedDate: "2021-08-01",
        publisher: null,
    },
    {
        id: "extendedCovidBenefits",
        sv: {
            header: "Tillfällig hantering av intyg vid vab och sjukpenning förlängs till 30 september",
            text: "Den tillfälliga hanteringen av intyg för vab och läkarintyg för sjukpenning förlängs till den 30 september 2021. Det innebär att medarbetare som ansöker om ersättning för vab ska lämna intyg från sjuksköterska eller läkare från dag 22. Om medarbetaren ansöker om sjukpenning, kommer Försäkringskassan efterfråga läkarintyg dag 22 i sjukperioden.",
        },
        en: {
            header: "Extension of corona-related benefits and measures",
            text: "The decision that a parent who applies for compensation for the care of a sick child can wait until the child ́s 22nd sick day before a certificate from a nurse or a doctor must be sent in has also been extended. This now applies until the 30th September 2021.If a person applies for sickness benefit, Försäkringskassan will continue to demand a doctor ́s certificate from the 22nd sick day, instead of the 8th day. This will also apply until the 30th September 2021.",
        },
        no: {
            header: "Midlertidig håndtering av sertifikater for vab og sykepenger forlenges til 30 september",
            text: "Den midlertidige behandlingen av sertifikater for vab og legeerklæring for sykepenger forlenges til 30 september 2021. Det betyr at ansatte som søker om kompensasjon for vab må levere attester fra sykepleiere eller leger fra dag 22. Dersom den ansatte søker om sykepenger, skal Försäkringskassan vil be om legeerklæring den 22 i sykdomsperioden.",
        },
        da: {
            header: "Midlertidig håndtering af certifikater til vab og sygedagpenge forlænges til 30 september",
            text: "Den midlertidige behandling af certifikater til vab og lægeerklæringer til sygedagpenge forlænges til 30 september 2021. Det betyder, at medarbejdere, der ansøger om kompensation for vab, skal indsende attester fra sygeplejersker eller læger fra dag 22. Hvis medarbejderen ansøger om sygedagpenge, skal Försäkringskassan vil anmode om lægeerklæring den 22 i sygdomsperioden.",
        },
        published: true,
        publishedDate: "2021-06-29",
        publisher: null,
    },
    {
        id: "workplaceAdaptationRegulation",
        sv: {
            header: "Nya föreskrifter om arbetsanpassning",
            text: "Den 1 juni trädde Arbetsmiljöverkets nya föreskrifter om arbetsanpassning i kraft. De ska göra det lättare att jobba förebyggande och hitta lösningar för att undvika ohälsa och sjukskrivning och det är större fokus på individens specifika behov. Läs mer",
            link: "https://bit.ly/3BdLRFx",
            linkText: "här.",
        },
        en: {
            header: "New regulations on work adaptation",
            text: "On 1 June, the Swedish Work Environment Authority's new regulations on work adaptation came into force. They should make it easier to work preventively and find solutions to avoid illness and sick leave, and there is a greater focus on the individual's specific needs. Read more",
            link: "https://bit.ly/3BdLRFx",
            linkText: "here.",
        },
        no: {
            header: "Nytt regelverk om arbeidstilpasning",
            text: "1 juni trådte det svenske arbeidsmiljøetaten sitt nye regelverk om arbeidstilpasning i kraft. De skal gjøre det lettere å jobbe forebyggende og finne løsninger for å unngå dårlig helse og sykefravær, og det er større fokus på den enkeltes spesifikke behov. Les mere",
            link: "https://bit.ly/3BdLRFx",
            linkText: "her.",
        },
        da: {
            header: "Nye regler om arbejdstilpasning",
            text: "1 juni trådte det svenske arbejdsmiljøstyrelses nye regler om arbejdstilpasning i kraft. De skal gøre det lettere at arbejde forebyggende og finde løsninger for at undgå dårligt helbred og sygefravær, og der er større fokus på den enkeltes specifikke behov. Læs mere",
            link: "https://bit.ly/3BdLRFx",
            linkText: "her",
        },
        published: true,
        publishedDate: "2021-06-01",
        publisher: null,
    },
];
