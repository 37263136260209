import { useAppDispatch, useAppSelector } from "store/hooks";
import Logins from "./Logins";
import { ILoginAccordions, ILoginInputSharedProps } from "pages/Administration/types";
import { useCallback, useMemo } from "react";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import {
    createLoginCredentials,
    initialInputProps,
    initialLoginCredentials,
    validateInputValue,
} from "pages/Administration/utils";
import {
    deleteLoginCredential,
    getLoginsData,
    saveLoginConfiguration,
} from "pages/Administration/redux/administrationAsyncActions";
import {
    getActiveTab,
    getSelectedCompanyId,
    getSelectedCompanyUserUserAccountId,
    getSelectedCompanyUserUserId,
    getUsersIsLoading,
    getLoginData,
    getLoginsStateAccordions,
} from "pages/Administration/redux/administrationSelectors";
import { Spinner } from "components/Spinner";

export default function Index() {
    const dispatch = useAppDispatch();

    const userIsLoading = useAppSelector(getUsersIsLoading);
    const loginsData = useAppSelector(getLoginData);
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const selectedUserAccountId = useAppSelector(getSelectedCompanyUserUserAccountId);
    const selectedUserId = useAppSelector(getSelectedCompanyUserUserId);
    const accordionProps = useAppSelector(getLoginsStateAccordions);
    const activeTab = useAppSelector(getActiveTab);
    const handleButtonClick = useCallback(
        (accordionKey: keyof ILoginAccordions, button: string, input?: ILoginInputSharedProps, open?: boolean) => {
            if (button === "save" || button === "delete") {
                dispatch(
                    administrationActions.setInputLoading({
                        accordionKey: accordionKey,
                        inputId: input?.loginCredential.id ?? 0,
                    }),
                );
            }
            switch (button) {
                case "open":
                    dispatch(
                        administrationActions.setAccordionOpen({
                            accordionKey: accordionKey,
                            newValue: open === true,
                        }),
                    );
                    break;

                case "add":
                    dispatch(
                        administrationActions.addInputItem({
                            accordionKey: accordionKey,
                            inputProps: input ?? initialInputProps(),
                        }),
                    );
                    break;

                case "edit":
                    dispatch(
                        administrationActions.setInputEnabled({
                            accordionKey: accordionKey,
                            inputId: input?.loginCredential.id ?? 0,
                        }),
                    );
                    break;

                case "save":
                    dispatch(saveLoginConfiguration(createLoginCredentials(input)));
                    break;

                case "delete":
                    input?.loginCredential.id === 0
                        ? dispatch(administrationActions.removeEmptyInputItem(accordionKey))
                        : dispatch(deleteLoginCredential(input?.loginCredential ?? initialLoginCredentials()));

                    break;
                default:
                    dispatch(administrationActions.setActiveTab(button));
                    break;
            }
        },
        [dispatch],
    );

    const handleInputChange = useCallback(
        (accordionKey: keyof ILoginAccordions, input: ILoginInputSharedProps, value: string) => {
            const inputId = input.loginCredential.id ?? 0;
            dispatch(
                administrationActions.setInputValue({
                    accordionKey: accordionKey,
                    inputId: inputId,
                    newValue: value,
                }),
            );
            if (input.loginCredential.credentialType !== 1) {
                const errorMessage = validateInputValue(value, input, activeTab);
                if (errorMessage) {
                    dispatch(
                        administrationActions.setInputErrorMessage({
                            accordionKey: accordionKey,
                            inputId: inputId,
                            newValue: errorMessage,
                        }),
                    );
                }
            }
        },
        [activeTab, dispatch],
    );

    useMemo(() => {
        if (selectedUserAccountId && selectedCompanyId && loginsData === null) {
            dispatch(
                getLoginsData({
                    companyId: selectedCompanyId,
                    userAccountId: selectedUserAccountId,
                }),
            );
        }
    }, [dispatch, loginsData, selectedCompanyId, selectedUserAccountId]);

    return userIsLoading ? (
        <Spinner />
    ) : (
        <Logins
            userAccountId={selectedUserAccountId ?? 0}
            userId={Number(selectedUserId)}
            accordionProps={accordionProps}
            handleButtonClick={handleButtonClick}
            handleInputOnChange={handleInputChange}
        />
    );
}
