interface IProps {
    top: string;
    bottom: string;
}
const InformationText = ({ top, bottom }: IProps) => (
    <div className="mr-12 mb-5">
        <p className="font-bold text-xs">{top}:</p>
        <p>{bottom}</p>
    </div>
);

export default InformationText;
