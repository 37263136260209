import { isNull } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { selectors } from "../../redux";
import MonthlyReport from "./MonthlyReport";

export default function Index() {
    const { t } = useTranslation("followup");
    const [isLoading, setIsLoading] = useState(false);
    const month = useAppSelector(selectors.getSearchMonthById("month"));
    useEffect(() => {
        if (month.displayMonth !== null || month.year !== null) {
            setIsLoading(true);
            let timeout = setTimeout(() => {
                setIsLoading(false);
            }, 500);
            return () => {
                setIsLoading(false);
                clearTimeout(timeout);
            };
        }
        return;
    }, [month.year, month.displayMonth]);
    return (
        <MonthlyReport
            body={t("monthlyReportExcel")}
            disabled={Boolean(isNull(month.year) || isNull(month.displayMonth))}
            isLoading={isLoading}
        />
    );
}
