import { isEmpty } from "lodash/fp";

interface IPropsView {
    header: string;
    filters: string[];
}

const DisplayFilters = ({ filters, header }: IPropsView) => (
    <div className="flex flex-col flex-wrap my-6 gap-3 items-start md:flex-row md:items-center">
        <p className="font-bold">{header}:</p>
        {filters.map(
            (filter) =>
                !isEmpty(filter) && (
                    <div className="flex items-center bg-mhgrey-light py-1 px-2" key={`display-filter-${filter}`}>
                        <p>{filter}</p>
                    </div>
                ),
        )}
    </div>
);

export default DisplayFilters;
