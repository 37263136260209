const AbsenceForecastCurrentMonthLoader = () => {
    return (
        <div className="h-full flex flex-col justify-between pt-10 pb-5 mx-6 animate-pulse">
            <div>
                <div className="border-b border-secondaryBorder" />
                <div className="items-center h-10 flex border-b justify-between border-secondaryBorder">
                    <div className="h-2.5 bg-gray-500 rounded-full dark:bg-gray-700 w-30"></div>
                    <div className="h-2.5 bg-gray-500 rounded-full dark:bg-gray-700 w-20"></div>
                </div>
                <div className="items-center h-10 flex border-b justify-between border-secondaryBorder">
                    <div className="h-2.5 bg-gray-500 rounded-full dark:bg-gray-700 w-44"></div>
                    <div className="h-2.5 bg-gray-500 rounded-full dark:bg-gray-700 w-20"></div>
                </div>
            </div>

            <div className="flex justify-between text-secondaryText">
                <div className="h-2.5 bg-gray-500 rounded-full dark:bg-gray-700 w-72"></div>
                <div className="h-2.5 bg-gray-500 rounded-full dark:bg-gray-700 w-10"></div>
            </div>
        </div>
    );
};

export default AbsenceForecastCurrentMonthLoader;
