export const styles: any = {
    primary: {
        bg: "widgetBeige",
        text: "#192D05",
        icon: "#192D05",
        loader: "#192D05",
    },
    secondary: {
        bg: "mhdarkgreen-original",
        text: "#FFFAF5",
        icon: "#FFFAF5",
        loader: "#E1F550",
    },
};
