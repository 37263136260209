import { useAppSelector } from "store/hooks";
import { useAppDispatch } from "store/hooks";
import { get, isEmpty } from "lodash/fp";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { findTagByReferenceKey } from "../../utils";
import { actions, selectors } from "../../redux";
import ReimbursibleTypeFilter from "./ReimbursibleTypeFilter";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation(["followup", "translation"]);
    const reimbursibleFilter = useAppSelector(selectors.getSearchTagByIdSorted("reimbursableType", "asc"));

    const Header = useMemo(() => t("search.searchQuery.filterName.reimbursableType"), [t]);

    const Tags = useMemo(() => {
        const getCheckedItems = reimbursibleFilter.filter(get("checked"));
        if (isEmpty(getCheckedItems)) return "";
        return getCheckedItems.map(({ label }) => t(label)).join(", ");
    }, [reimbursibleFilter, t]);
    const Items = useMemo(
        () =>
            reimbursibleFilter.map((value) => ({
                ...value,
                label: t(value.label),
            })),
        [reimbursibleFilter, t],
    );
    const onChange = useCallback(
        (value: string) => {
            const element = findTagByReferenceKey(value, reimbursibleFilter);
            if (!element) return;
            dispatch(actions.updateRadioSearchFilter(element));
        },
        [dispatch, reimbursibleFilter],
    );
    return <ReimbursibleTypeFilter items={Items} header={Header} onChange={onChange} tags={Tags} />;
}
