import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { saveCaseDocument as saveCaseDocumentAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const saveCaseDocument = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(saveCaseDocumentAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                currentHealthCase.healthCaseDocuments.push({
                    ...action.payload.data,
                    status: Status.OK,
                });
                currentHealthCase.healthCaseSubStatus.documents = Status.OK;
            }
        })
        .addCase(saveCaseDocumentAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.documents = Status.PENDING;
        })
        .addCase(saveCaseDocumentAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.documents = Status.ERROR;
        });

export default saveCaseDocument;
