import { useCallback } from "react";
import { useDateTimepickerProps, useSingleDatepickerProps } from "hooks";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectors, thunkActions, actions } from "../../redux";
import AbsenceReportTemplateFields from "./AbsenceReportTemplateFields";
import * as UserSelectors from "store/userSelectors";
import { useMemo } from "react";
import { isValidMobileField } from "utils/phone";
import { useTranslation } from "react-i18next";
import { getAbsenceReportFieldValueByName } from "../../utils/absenceReports";

export default function Index() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const currentPage = useAppSelector(selectors.getAbsenceReportPageNumber);
    const template = useAppSelector(selectors.getSelectedTemplate);
    const templateFieldsByPages = useAppSelector(selectors.getTemplateFieldsByPages);
    const selectedAbsenceReport = useAppSelector(selectors.getSelectedAbsenceReport);
    const absenceReportType = useAppSelector(selectors.getAbsenceReportType);
    const mandatoryFieldsSelected = useAppSelector(selectors.isAllMandatoryFieldsSelected(currentPage));
    const currentFields = useAppSelector(selectors.getCurrentFields);
    const isRiskDynamicMedicalAdvisory = useAppSelector(selectors.isRiskDynamicMedicalAdvisory);
    const showAbsenceCauseToEmployer = useAppSelector(selectors.getShowAbsenceCauseToEmployer);
    const isDynamicMedicalAdvisory = useAppSelector(selectors.getConfigurationByName("dynamicMedicalAdvisory"));
    const userContactInfo = useAppSelector(UserSelectors.getUserContactInfo);
    const userCountryCode = useAppSelector(UserSelectors.getUserCountryCode);

    const handleUpdateField = useCallback(
        (fieldName: string, value: string) => {
            dispatch(
                actions.updateSelectedAbsenceReportField({
                    fieldName,
                    value,
                    templateFields: template?.fields ?? [],
                }),
            );
        },
        [dispatch, template?.fields],
    );

    const isLastPage = (): boolean => {
        return templateFieldsByPages && currentPage === templateFieldsByPages.length + 2 ? true : false;
    };

    const handleSubmit = () => {
        if (selectedAbsenceReport && template?.id) {
            dispatch(
                thunkActions.submitSelectedAbsenceReport({
                    absenceReport: selectedAbsenceReport,
                    templateId: template.id,
                }),
            );
        }
    };

    const handleForwardClick = () => {
        if (isDynamicMedicalAdvisory && absenceReportType === "Sick") {
            handleUpdateField("RiskMedicalAdvisoryRequest", isRiskDynamicMedicalAdvisory ? "true" : "false");
        }
        if (isLastPage()) {
            handleSubmit();
        }
        dispatch(actions.updateAbsenceReportPageNumber("next"));
    };

    const validMobileField = useMemo((): boolean => {
        return isValidMobileField(
            currentFields,
            selectedAbsenceReport?.fields ?? [],
            userContactInfo,
            userCountryCode!,
            t,
        );
    }, [currentFields, selectedAbsenceReport, userContactInfo, userCountryCode, t]);
    const datepickerProps = useSingleDatepickerProps({
        onChangeDate: (v) => handleUpdateField("DynamicDate", v ?? ""),
        givenSelectedDate: getAbsenceReportFieldValueByName("DynamicDate", selectedAbsenceReport?.fields ?? []),
        placeholder: t("choseDate"),
    });

    const dateTimepickerProps = useDateTimepickerProps({
        onChangeDateTime: (v) => handleUpdateField("DynamicDateTime", v ?? ""),
        givenSelectedDateTime: getAbsenceReportFieldValueByName("DynamicDateTime", selectedAbsenceReport?.fields ?? []),
        placeholder: t("choseDate"),
    });
    return (
        <AbsenceReportTemplateFields
            currentFields={currentFields}
            template={template}
            selectedAbsenceReport={selectedAbsenceReport}
            mandatoryFieldsSelected={mandatoryFieldsSelected && validMobileField}
            showAbsenceCauseToEmployer={showAbsenceCauseToEmployer}
            handleUpdateField={handleUpdateField}
            forwardClick={handleForwardClick}
            isLastPage={isLastPage}
            datepickerProps={datepickerProps}
            dateTimepickerProps={dateTimepickerProps}
        />
    );
}
