import { useTranslation } from "react-i18next";
import { CardWithTitle } from "@medhelp/ui";
import { useBreakpoints, useRetailerColors } from "hooks";
import { selectors } from "../../redux";
import Chart from "../Chart";
import { useAppSelector } from "store/hooks";
import { useCallback, useMemo } from "react";

const AbsenceChart = () => {
    const { t } = useTranslation(["followup", "translation"]);
    const breakpoints = useBreakpoints();
    const { primary: primaryColor, secondary: secondaryColor } = useRetailerColors();
    const data = useAppSelector(selectors.getAllSickAbsenceDaysForYearsToChartData);

    const setYlabel = useCallback(
        // @ts-ignore
        (value) => `${value} ${breakpoints.isSmallscreen ? "" : t("days")}`,
        [breakpoints.isSmallscreen, t],
    );
    const Dataset = useMemo(
        () => [
            {
                label: t("sick", { ns: "translation" }),
                data: data,
                parsing: {
                    yAxisKey: "sick",
                },
                backgroundColor: primaryColor,
                borderSkipped: false,
                borderWidth: "24px",
                maxBarThickness: 38,
            },
            {
                label: t("onlyVabText"),
                data: data,
                parsing: {
                    yAxisKey: "careOfChild",
                },
                backgroundColor: secondaryColor,
                borderSkipped: false,
                borderWidth: "24px",
                maxBarThickness: 38,
            },
        ],
        [data, primaryColor, secondaryColor, t],
    );
    const Labels = useMemo(() => data.map(({ x }) => x), [data]);

    return (
        <div>
            <CardWithTitle title={t("absencePerYear")} className=" py-[30.4px]">
                <Chart numberOfData={data?.length} labels={Labels} dataSets={Dataset} yLabel={setYlabel} />
            </CardWithTitle>
        </div>
    );
};

export default AbsenceChart;
