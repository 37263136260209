import { curry, filter, includes, isEmpty, set } from "lodash/fp";
import { TFunction } from "i18next";

export const isNotEqual = curry((a: string, b: string) => a !== b);

export const isNotEmpty = <T>(a: T[]) => !isEmpty(a);

export const reverseIncludes =
    <T>(value: T[] | T) =>
    <T>(compare: T | T[]): boolean =>
        // @ts-ignore
        includes(compare, value);

export const filterWithFunc =
    <T>(value: T[]) =>
    (predict: (value: T) => boolean) =>
        filter(predict, value);

export const get =
    <T>(item: T extends infer Item ? keyof Item : never) =>
    (data: T) =>
        data[item];

export type ITranslationModel<T extends readonly string[]> = {
    [translatioKey in T[number]]: string;
};

export const getTranslationsFromArray = <T extends readonly string[]>(
    translationArray: T,
    t: TFunction,
): ITranslationModel<T> => translationArray.reduce((k, b) => set(b, t(b) as string, k), {} as ITranslationModel<T>);

export function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
}
