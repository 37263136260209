import { isEmpty } from "lodash";
import { TreeViewNode } from "swagger/employeeattendance";

export const getSelectableFlattenedDepartments = (treeViewNode: TreeViewNode[]) => {
    let selectableFlattenedDepartments: TreeViewNode[] = [];

    treeViewNode.forEach((node) => {
        if (node.selectable) selectableFlattenedDepartments.push(node);
        if (node.children && isEmpty(!node.children.length)) {
            selectableFlattenedDepartments = selectableFlattenedDepartments.concat(
                getSelectableFlattenedDepartments(node.children),
            );
        }
    });

    return selectableFlattenedDepartments;
};
