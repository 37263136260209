import { useTranslation } from "react-i18next";
import { SearchTypes } from "../../utils";

interface IProps {
    onClick: (value?: SearchTypes) => void;
    searchTypes: SearchTypes[];
    searchCaseTypes: SearchTypes[];
    healthCaseManagement: boolean;
}
const FollowUpReportsList = ({ onClick, searchTypes, searchCaseTypes, healthCaseManagement }: IProps) => {
    const { t } = useTranslation("followup");
    return (
        <>
            <div className="border-b border-grey-300">
                {healthCaseManagement && <p className="font-bold text-lg p-2">{t("absenceReports")}</p>}
                {searchTypes.map((name, index) => (
                    <data
                        role="button"
                        className="flex p-2 gap-2 items-center"
                        value={name}
                        onClick={() => onClick(name)}
                        key={`${name}-${index}`}
                    >
                        <div>{t(`search.searchOptions.${name}`)}</div>
                    </data>
                ))}
            </div>
            {healthCaseManagement && (
                <div className="border-b border-grey-300">
                    <p className="font-bold text-lg p-2">{t("caseReports")}</p>
                    {searchCaseTypes.map((name, index) => (
                        <data
                            role="button"
                            className="flex p-2 gap-2 items-center"
                            value={name}
                            onClick={() => onClick(name)}
                            key={`${name}-${index}`}
                        >
                            <div>{t(`search.searchOptions.${name}`)}</div>
                        </data>
                    ))}
                </div>
            )}
        </>
    );
};

export default FollowUpReportsList;
