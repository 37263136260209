import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { widgetMap } from "../../components/Widget";
import { filterCompaniesByAccessRights, filterWidgetsByAccessRights } from "../../utils/dashboardAccessRights";
import {
    getValue,
    getWidgetDataByIdWithOnlyFilter,
    setCorrectFilterForCorrespondingWidget,
    getDynamicData,
} from "../../utils/dashboardSliceUtils";
import { IDashboardSlice, initialDashboardState, initialWidgetFilterState } from "../dashboardSlice";
import { Status } from "../dashboardStateTypes";
import { WidgetType, WidgetTypes } from "pages/dashboard/utils/dashboardTypes";
import { fetchDashboardInit } from "../dashboardActions";
import { MedHelpPeopleDomainWidget } from "swagger/people";
import { sortBy } from "lodash/fp";

/**
 * Checks if a widget has a valid id and type
 * @param widget
 * @returns boolean
 */
const isValidWidget = <T extends MedHelpPeopleDomainWidget>(
    widget: T,
): widget is T & { id: string; type: WidgetType } => {
    return typeof widget.id === "string" && Object.values(WidgetTypes).includes(widget.type as WidgetType);
};

const initDashboard = (builder: ActionReducerMapBuilder<IDashboardSlice>) => {
    builder
        .addCase(fetchDashboardInit.fulfilled, (state, action) => {
            const {
                employmentGroups,
                departments,
                widgets,
                companies,
                accessRights,
                absenceCauses,
                templates,
                hasBaseProduct,
            } = action.payload;

            const orderedWidgets = sortBy("order", widgets);

            const parsedWidgetData = orderedWidgets?.filter(isValidWidget).map((widget) => {
                const currentWidget = widgetMap.get(widget.type! as WidgetType);
                const filteredCompaniesByAccessRight = filterCompaniesByAccessRights(
                    accessRights || [],
                    companies,
                    currentWidget?.accessRight || "",
                );
                return {
                    id: widget.id!,
                    type: widget.type! as WidgetType,
                    filter: JSON.parse(widget.filter!),
                    customHeading: widget.customHeading || "",
                    order: widget.order,
                    chartData: null,
                    ...initialWidgetFilterState,
                    companies: filteredCompaniesByAccessRight,
                    status: Status.PENDING,
                };
            });

            const filteredWidgetData = filterWidgetsByAccessRights(
                accessRights || [],
                parsedWidgetData,
                templates,
                hasBaseProduct,
            );

            state.Widgets = filteredWidgetData;

            filteredWidgetData.forEach((widget) => {
                const currentWidget = widgetMap.get(widget.type);
                const widgetState = state.Widgets?.find(({ id }) => id === widget.id);

                if (widgetState) {
                    const widgetDataByKeyWithOnlyFilter = getWidgetDataByIdWithOnlyFilter(widget.id, widgets);
                    if (widgetDataByKeyWithOnlyFilter && widgetDataByKeyWithOnlyFilter.companyIds?.length > 0) {
                        const filteredCompanies = widgetState.companies?.map((item) => {
                            {
                                if (widgetDataByKeyWithOnlyFilter.companyIds.includes(item.id as number)) {
                                    return { ...item, checked: true };
                                } else {
                                    return item;
                                }
                            }
                        });
                        widgetState.companies = filteredCompanies || [];
                    }

                    widgetState.absenceCauses = absenceCauses.map((cause) => ({
                        id: cause,
                        label: cause,
                        referenceKey: cause,
                        checked: widgetDataByKeyWithOnlyFilter?.absenceCauses === cause,
                        selectable: true,
                    }));

                    if (widgetDataByKeyWithOnlyFilter) {
                        Object.keys(widgetDataByKeyWithOnlyFilter).forEach((value) => {
                            let correctCompanyId: number | null = null;
                            const newValue = getValue(value);
                            if (widgetDataByKeyWithOnlyFilter.companyIds?.length > 0) {
                                correctCompanyId = widgetDataByKeyWithOnlyFilter.companyIds[0] || null;
                            } else if (widgetState.companies?.length === 1) {
                                correctCompanyId = widgetState.companies[0].id as number;
                            }

                            const getIDeparments = departments.find(
                                (department) =>
                                    correctCompanyId === department?.companyId &&
                                    department?.typeOfSearch === (currentWidget?.accessRight || ""),
                            )?.data;

                            const getIEmployGroups =
                                employmentGroups.find(
                                    (employentGroup) => correctCompanyId === employentGroup?.companyId,
                                )?.data || [];
                            if (newValue) {
                                const dynamicData = getDynamicData(
                                    widgetState[newValue] || [],
                                    getIDeparments || [],
                                    getIEmployGroups || [],
                                    newValue,
                                );

                                const newCorrectFilterForCorrespondingWidget = setCorrectFilterForCorrespondingWidget(
                                    dynamicData || widgetState[newValue],
                                    widgetDataByKeyWithOnlyFilter,
                                    widget.type,
                                    value,
                                );

                                if (
                                    newCorrectFilterForCorrespondingWidget &&
                                    newCorrectFilterForCorrespondingWidget.length > 0
                                ) {
                                    widgetState[newValue] = newCorrectFilterForCorrespondingWidget;
                                }
                            }
                        });
                    } else {
                        if (widgetState.companies && widgetState.companies?.length > 0) {
                            const correctCompanyId = widgetState.companies[0].id;
                            const getIDeparments = departments.find(
                                (department) =>
                                    correctCompanyId === department?.companyId &&
                                    department?.typeOfSearch === currentWidget?.accessRight,
                            )?.data;
                            const getIEmployGroups =
                                employmentGroups.find(
                                    (employmentGroup) => correctCompanyId === employmentGroup?.companyId,
                                )?.data || [];
                            const dynamicDepartments = getDynamicData(
                                [],
                                getIDeparments || [],
                                getIEmployGroups || [],

                                "departments",
                            );
                            const dynamicEmploymentGroups = getDynamicData(
                                [],
                                getIDeparments || [],
                                getIEmployGroups || [],

                                "employmentGroups",
                            );

                            widgetState.departments = dynamicDepartments;
                            widgetState.employmentGroups = dynamicEmploymentGroups;
                        }
                    }
                }
            });
            state.AbsenceCauses = absenceCauses;
            state.CompanyGroupForCompany = companies;
            state.HCMTemplates.data = templates;
            state.HCMTemplates.status = Status.OK;
            state.CaseSummary = initialDashboardState.CaseSummary;
            state.DashboardInitStatus = Status.OK;
        })
        .addCase(fetchDashboardInit.pending, (state) => {
            state.DashboardInitStatus = Status.PENDING;
        });
};

export default initDashboard;
