import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import Icon from "components/Icon";
import { useTranslation } from "react-i18next";
import * as UserSelectors from "store/userSelectors";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { updateUserLang } from "store/userActions";
import { LangServiceTypes } from "pages/service/utils/serviceTypes";

const MenuNavDropdownMenu = () => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(UserSelectors.getUserLanguage) ?? "en";

    const { t } = useTranslation("userSettings");
    const setValue = (value: string): void => {
        dispatch(updateUserLang({ language: value }));
    };

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild className="border-transparent data-[state=open]:border-black border flex">
                <div>
                    <button className="flex items-center max-xl:hidden cursor-pointer text-menuNavColorSecondary">
                        <Icon icon="globe" size={12} />
                        <span className="max-xl:hidden pl-1.5 text-menuNavColorSecondary text-sm">{t(language)}</span>
                    </button>

                    <button className="xl:hidden cursor-pointer text-menuNavColorPrimary">
                        <Icon icon="globeAltWhite" size={28} />
                    </button>
                </div>
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className="min-w-[100vw] xs:min-w-[220px] bg-white rounded-md p-[5px] shadow-topNavMenuDropdown data-[state=open]:shadow-topNavMenuDropdown data-[state=open]:p-0  will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-11"
                    sideOffset={5}
                >
                    <DropdownMenu.RadioGroup value={language.toString()} onValueChange={setValue}>
                        {LangServiceTypes.map((c, i) => (
                            <DropdownMenu.RadioItem
                                key={i}
                                className="DropdownMenuRadioItem data-[state=checked]:bg-grey-100 leading-none text-violet11 rounded-[3px] flex items-center px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1 hover:bg-grey-100 h-12"
                                value={c}
                            >
                                <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                    <Icon icon="checkMark" />
                                </DropdownMenu.ItemIndicator>
                                <span>{t(c)}</span>
                            </DropdownMenu.RadioItem>
                        ))}
                    </DropdownMenu.RadioGroup>
                    <DropdownMenu.Arrow className="fill-white" />
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default MenuNavDropdownMenu;
