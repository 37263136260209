import { useCallback, useState, useEffect } from "react";
import { useRouteState } from "routes/hooks";
import TabMenu, { IExternalProps } from "./TabMenu";

export default function Index(props: IExternalProps) {
    const { initialRoute } = useRouteState(props.routes);
    const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);

    const scroll = useCallback(
        (direction: "left" | "right") => {
            if (scrollRef) {
                const scrollDistance = scrollRef.scrollWidth / 4;
                scrollRef.scrollLeft += direction === "right" ? scrollDistance : -scrollDistance;
            }
        },
        [scrollRef],
    );

    const setScrollRefState = useCallback((node: HTMLDivElement | null) => {
        if (node) setScrollRef(node);
    }, []);

    const updateButtonsState = useCallback(
        (currentTarget: EventTarget & HTMLDivElement) => {
            const { scrollLeft, scrollWidth, offsetWidth } = currentTarget;
            const endOfScroll = scrollWidth - scrollLeft - 30 <= offsetWidth;
            if (scrollLeft !== 0 && !showLeftButton) setShowLeftButton(true);
            if (scrollLeft === 0 && showLeftButton) setShowLeftButton(false);
            if (endOfScroll && showRightButton) setShowRightButton(false);
            if (!endOfScroll && !showRightButton) setShowRightButton(true);
        },
        [showLeftButton, showRightButton],
    );

    useEffect(() => {
        if (props.setCurrentRoute) {
            props.setCurrentRoute(initialRoute);
        }
    }, [initialRoute, props]);

    return (
        <TabMenu
            {...props}
            activeRoute={props.externalRoute || initialRoute?.indexRoute || initialRoute.route}
            showLeftButton={showLeftButton}
            showRightButton={showRightButton}
            scroll={scroll}
            setScrollRefState={setScrollRefState}
            updateButtonsState={updateButtonsState}
        />
    );
}
