import Icon from "components/Icon";
import PrimaryButton from "components/PrimaryButton";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export interface IContactKeyProps {
    contactKey: string[];
    missingContactKey: string;
    selectedUserName?: string;
    boldText?: boolean;
    hideContactKeyInSubOptions?: boolean;
    onMissingContactKeyClicked?: () => void;
}
const ContactKey = ({
    contactKey,
    missingContactKey,
    selectedUserName,
    boldText,
    onMissingContactKeyClicked,
    hideContactKeyInSubOptions,
}: IContactKeyProps) => {
    const { t } = useTranslation("administration");
    return (
        <div>
            {!hideContactKeyInSubOptions &&
                contactKey.map((key, i) => (
                    <div key={key} className={i === 0 && boldText ? "font-bold" : "font-normal"}>
                        {!!key && `${key.startsWith("+") ? "Sms" : t("eMail")}: ${key}`}
                    </div>
                ))}
            {missingContactKey && (
                <div className="text-mhred-alert1">
                    {/* Accordion */}
                    {!selectedUserName && _.capitalize(t(missingContactKey))}
                    {/* SubscriptionOptions */}
                    {selectedUserName &&
                        t("missingText", {
                            name: selectedUserName,
                            missingContactKey: t(missingContactKey),
                        })}

                    {onMissingContactKeyClicked && (
                        <PrimaryButton
                            id="subscription-option-handle-email"
                            text={t("setEmailAddress")}
                            onClick={onMissingContactKeyClicked}
                            className="mt-6 flex self-center"
                            rightIcon={<Icon icon="caretRight" size={14} />}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default ContactKey;
