import {
    IPermissionAccordions,
    ISetAccessRightBooleanValue,
    ISetAccessRightStringValue,
} from "pages/Administration/types";
import Permissions from "./Permissions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import { useCallback, useMemo } from "react";
import {
    getPermissionsAccordionProps,
    getPermisssionData,
    getSelectedCompanyAccessRights,
    getSelectedCompanyUserPrimaryCompanyId,
    getSelectedCompanyUserPrimaryCompanyName,
    getSelectedUserAccountId,
    getUsersIsLoading,
} from "pages/Administration/redux/administrationSelectors";
import { createAccessRights, getPermissionData } from "pages/Administration/redux/administrationAsyncActions";
import { Spinner } from "components/Spinner";

export default function Index() {
    const dispatch = useAppDispatch();
    const usersIsLoading = useAppSelector(getUsersIsLoading);
    const selectedCompanyAccessRights = useAppSelector(getSelectedCompanyAccessRights);
    const permissionData = useAppSelector(getPermisssionData);
    const selectedCompanyUserPrimaryCompanyId = useAppSelector(getSelectedCompanyUserPrimaryCompanyId);
    const selectedUserAccountId = useAppSelector(getSelectedUserAccountId);
    const accordionProps = useAppSelector(getPermissionsAccordionProps);
    const companyName = useAppSelector(getSelectedCompanyUserPrimaryCompanyName);

    const handleAccordionClick = useCallback(
        (accName: keyof IPermissionAccordions, open: boolean) => {
            dispatch(
                administrationActions.setPermissionAccordionOpen({
                    accordionKey: accName,
                    newValue: open,
                }),
            );
        },
        [dispatch],
    );

    const handleValueChange = useCallback(
        (accName: keyof IPermissionAccordions, value: boolean | string) => {
            if (typeof value === "boolean") {
                dispatch(
                    administrationActions.setAccessRightBoolenValue({
                        accessRightKey: accName,
                        newValue: value,
                    } as ISetAccessRightBooleanValue),
                );
            } else if (typeof value === "string") {
                dispatch(
                    administrationActions.setAccessRightStringValue({
                        accessRightKey: accName,
                        newValue: value,
                    } as ISetAccessRightStringValue),
                );
            }
        },
        [dispatch],
    );

    const handleSaveClicked = useCallback(
        (accordionKey: keyof IPermissionAccordions) => {
            if (selectedCompanyAccessRights) {
                dispatch(administrationActions.setPermissionCurrentAccordion(accordionKey));
                dispatch(createAccessRights(selectedCompanyAccessRights));
            }
        },
        [dispatch, selectedCompanyAccessRights],
    );

    useMemo(() => {
        if (selectedUserAccountId && selectedCompanyUserPrimaryCompanyId && permissionData === null) {
            dispatch(
                getPermissionData({
                    userAccountId: selectedUserAccountId,
                    companyId: selectedCompanyUserPrimaryCompanyId,
                }),
            );
        }
    }, [dispatch, permissionData, selectedCompanyUserPrimaryCompanyId, selectedUserAccountId]);

    return usersIsLoading ? (
        <Spinner />
    ) : (
        <Permissions
            accordionProps={accordionProps}
            onAccordionClick={handleAccordionClick}
            companyName={companyName}
            onValueChange={handleValueChange}
            onSaveButtonClick={handleSaveClicked}
        />
    );
}
