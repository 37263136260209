import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectors as absenceReportSelectors, actions } from "../../redux";
import { MedHelpAbsenceReportingDomainTemplate } from "swagger/absencereportapi";
import AbsenceReportTypeOfAbsence from "./AbsenceReportTypeOfAbsence";
import { absenceReportsActions } from "../../redux/absenceReportsSlice";

export default function Index() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const templates = useAppSelector(absenceReportSelectors.getAbsenceTemplates);
    const selectedAbsenceReportType = useAppSelector(absenceReportSelectors.getAbsenceReportType);

    const getTemplateValues = useMemo((): any => {
        return templates?.map((template: MedHelpAbsenceReportingDomainTemplate) => {
            return {
                value: template.absenceReportType!,
                description: t(_.lowerFirst(template.absenceReportType!)),
            };
        });
    }, [templates, t]);

    return (
        <AbsenceReportTypeOfAbsence
            selectedAbsenceReportType={selectedAbsenceReportType}
            values={getTemplateValues}
            onClick={(v) => dispatch(absenceReportsActions.updateSelectedAbsenceType(v))}
            forwardClick={() => dispatch(actions.updateAbsenceReportPageNumber("next"))}
        />
    );
}
