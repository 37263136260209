import LoginContainer from "pages/login/components/LoginContainer";
import Select from "@medhelp/ui/Select";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { ReactComponent as SweFlagIcon } from "icons/login/sweFlag.svg";
import { ReactComponent as DenFlagIcon } from "icons/login/denFlag.svg";
import { ILoginPageData, IRegionData, IWarningBannerData } from "pages/login/types";
import LoginStart from "pages/login/components/LoginStart";
import OneTimePass from "pages/login/components/OneTimePass";
import Microsoft from "pages/login/components/Microsoft";
import UsernameAndPass from "pages/login/components/UsernameAndPass";
import LoginModal from "pages/login/components/LoginModal";
import SelectCompany from "pages/login/components/SelectCompany";
import MoreLoginOptions from "pages/login/components/MoreLoginOptions";

import { BankIdOptions, BankIdSameDevice, MobileBankId } from "pages/login/components/BankId";
import { ISelectValue } from "@medhelp/ui/Select/Select";
import ResetPass from "pages/login/components/ResetPass";
import Icon from "components/Icon";

const LoginPage = (loginPageData: ILoginPageData) => {
    const { t, i18n } = useTranslation("login");
    const { param } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { loginPageProps, selectedMarket } = loginPageData;
    const [open, setOpen] = useState(false);

    const returnUrl = searchParams.get("returnUrl") ? searchParams.get("returnUrl") : undefined;
    const marketOptions: ISelectValue[] = [
        {
            description: t("Sweden"),
            value: "sv",
            icon: <SweFlagIcon className="h-4 w-4" />,
        },
        {
            description: t("Denmark"),
            value: "dk",
            icon: <DenFlagIcon className="h-4" />,
        },
    ];
    const languageOptions: ISelectValue[] = useMemo(
        () => [
            {
                description: t("sv"),
                value: "sv",
            },
            {
                description: t("dk"),
                value: "da",
            },
            {
                description: t("no"),
                value: "no",
            },
            {
                description: t("en"),
                value: "en",
            },
        ],
        [t],
    );

    // get preselewcted language
    useEffect(() => {
        const parsed = new URLSearchParams(location.search);
        const paramsPOJO = Object.fromEntries(parsed.entries());
        const getLang = languageOptions.find((x) => x.value === paramsPOJO.lang);
        if (getLang) {
            loginPageProps.setLanguage(getLang.value);
        }
    }, [languageOptions, loginPageProps]);
    const setNewLang = useCallback(
        // @ts-ignore *
        (v) => {
            const queries = { lang: v.value };
            setSearchParams(queries);
            loginPageProps.setLanguage(v.value);
        },
        [loginPageProps, setSearchParams],
    );
    const getLoginComponent = () => {
        switch (param) {
            case "bankIdOptions":
                return <BankIdOptions {...{ ...loginPageData, returnUrl: returnUrl! }} />;

            case "bankIdSameDevice":
                return <BankIdSameDevice {...{ ...loginPageData, returnUrl: returnUrl! }} />;

            case "mobileBankId":
                return <MobileBankId {...{ ...loginPageData, returnUrl: returnUrl! }} />;

            case "oneTimePass":
                return <OneTimePass {...{ ...loginPageData, returnUrl: returnUrl! }} />;

            case "moreOptions":
                return <MoreLoginOptions {...{ ...loginPageData, returnUrl: returnUrl! }} />;

            case "microsoft":
                return <Microsoft {...{ ...loginPageData, returnUrl: returnUrl! }} />;

            case "userAndPass":
                return <UsernameAndPass returnUrl={returnUrl} />;

            case "resetPass":
                return <ResetPass />;

            case "selectCompany":
                return <SelectCompany {...{ ...loginPageData, returnUrl: returnUrl! }} />;

            default:
                return <LoginStart {...{ ...loginPageData, returnUrl: returnUrl! }} />;
        }
    };

    useEffect(() => {
        i18n.changeLanguage(loginPageProps.language);
    }, [i18n, loginPageProps.language]);

    return (
        <LoginContainer
            id="loginPage"
            warningBannerData={loginPageData.warningBannerData}
            selectedMarket={selectedMarket === "sv" ? "se" : (selectedMarket as keyof IWarningBannerData)}
            selectedLanguage={loginPageProps.language as keyof IRegionData}
        >
            <div className={`mt-11 mb-24 ${param === "selectCompany" ? "invisible" : "flex"}`}>
                <Select
                    options={marketOptions}
                    selectedValue={marketOptions.find((c) => c.value === selectedMarket)!}
                    onChange={(v) => loginPageProps.handleMarketChange(v.value)}
                    containerClassName="mr-2"
                />
                <Select
                    options={languageOptions}
                    selectedValue={{
                        ...languageOptions.find((c) => c.value === loginPageProps.language)!,
                        icon: <Icon icon="globe" />,
                    }}
                    onChange={setNewLang}
                />
            </div>
            {getLoginComponent()}
            <div className={param === "selectCompany" ? "hidden" : "flex justify-center"}>
                <button
                    type="button"
                    className="w-max border-b border-linkBorderBottom hover:border-linkBorderBottomHover"
                    onClick={() => setOpen(true)}
                >
                    {t("loginHelp")}
                </button>
            </div>
            <LoginModal open={open} setOpen={setOpen} />
        </LoginContainer>
    );
};

export default LoginPage;
