import { findAccessRightsBasedOnGivenRights, getAllRights, IAccessRightData } from "domain/accessRights";
import { AccessType } from "domain/accessRights/types";
import { reduceFilterChildren } from "domain/ogranization/iterateChildren";
import { useAppSelector } from "store/hooks";
import { IAccessRightTypeEnum } from "interfaces/IAccountAccessRights";
import { findIndex, drop, last, slice, isEqual } from "lodash/fp";
import * as React from "react";
import { useLocation, Location } from "react-router-dom";
import { selectUserRights } from "store/userSelectors";
import { AccountAccessRightViewModel } from "swagger/usercontextservice";

export interface IProtected {
    value: IAccessRightTypeEnum[];
    access: AccessType;
}

export interface IRoute {
    name: string;
    route: string;
    indexRoute?: string;
    subMenu?: boolean;
    testId?: string;
    children: IRoute[];
    protected?: IProtected[];
    param?: boolean;
    match?: string;
    top?: boolean;
    whitelist?: IAccessRightData[];
    hidden?: boolean;
    disabled?: boolean;
}

const addNewItemInArray = <T>(index: number, element: T, array: Array<T>) => {
    if (index > array.length - 1) {
        return array;
    }
    const end = index < array.length ? index + 1 : array.length;
    const startArray = slice(0, index, array);
    const endArray = slice(end, array.length, array);
    return [...startArray, element, ...endArray];
};

/**
 * Returns first route by location `pathname` otherwise
 * first route in the `subRoutes` array
 */
const getCorrectedNestedRoutes = (routes: IRoute[], paths: string[], index: number, parent: IRoute): IRoute => {
    const route = routes[index];
    const nestedParentsRoutes = parent.route.split("/");

    if (nestedParentsRoutes.length > 1) {
        const lastRoute = last(nestedParentsRoutes);
        if (lastRoute) {
            const newParent = {
                name: parent.name,
                route: lastRoute,
                indexRoute: parent.indexRoute,
                children: parent.children,
            };
            const newRoutes = addNewItemInArray<IRoute>(index, newParent, routes);

            return getCorrectedNestedRoutes(newRoutes, paths, index, newParent);
        }
    }
    if (!route) return parent;
    const hasRoute = findIndex((path) => route.route === path, paths);
    if (hasRoute === -1) {
        return getCorrectedNestedRoutes(routes, paths, (index += 1), parent);
    }
    const moreChilds = paths.length - 1 - hasRoute;
    if (moreChilds > 0 && route.children) {
        return getCorrectedNestedRoutes(route.children, drop(hasRoute, paths), 0, route);
    }
    return route;
};

export const findRouteByName = (name: string, route: IRoute): IRoute | undefined =>
    isEqual(route.name, name)
        ? route
        : route?.children?.reduce<IRoute | undefined>((_, b) => findRouteByName(name, b), undefined);

export const getCurrentSubRoute = (routes: IRoute[], location: Location) => {
    const paths = location.pathname.split("/");
    const currentRoute = getCorrectedNestedRoutes(routes, paths, 0, routes[0]);
    return currentRoute;
};
export const useRoute = (route: IRoute, name: string) => {
    const selectedRoute = React.useMemo(() => findRouteByName(name, route), [name, route]);
    return selectedRoute;
};

export const hasFollowUpSearch = () => {
    const pathname = window.location.pathname;
    return pathname.match(/(\/followup\/search)/);
};
export const checkWhitelistRoute = (routes: IRoute[], userAccessRights: AccountAccessRightViewModel[]) =>
    reduceFilterChildren(
        (x) => (x.whitelist ? findAccessRightsBasedOnGivenRights(x.whitelist, userAccessRights) : true),
        routes,
    );
export const filterRoutesByHidden = (routes: IRoute[]) => reduceFilterChildren((x) => !x.hidden, routes);
const useRouteState = (routes: IRoute[]) => {
    const location = useLocation();
    const initialRoute = React.useMemo(() => getCurrentSubRoute(routes, location), [location, routes]);
    return { initialRoute };
};
export default useRouteState;

export const useStartRoute = () => {
    const rights = useAppSelector(selectUserRights(getAllRights));
    return React.useMemo(() => {
        if (rights([IAccessRightTypeEnum.medicalAdvice, IAccessRightTypeEnum.medicalAdvising], "normal")) {
            return "/signedin/absencereporting/search";
        }
        if (
            rights(
                [
                    IAccessRightTypeEnum.absenceView,
                    IAccessRightTypeEnum.rehabDepartmentManagement,
                    IAccessRightTypeEnum.absenceFollowup,
                ],
                "normal",
            )
        ) {
            return "/dashboard";
        }

        return "/absencereporting";
    }, [rights]);
};
