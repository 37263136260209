import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getTemporarySubscriber } from "pages/service/redux/serviceSelectors";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import { deleteConfigurationField, saveConfigurationField } from "pages/service/redux/serviceAsyncActions";

interface ITemporarySubscriberConfiguration {
    temporarySubscriber: { id?: string; key: string; checked: boolean };
}

const TemporarySubscriberConfiguration = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const temporarySubscriber = useAppSelector(getTemporarySubscriber);
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        reset,
        resetField,
        register,
        formState: { isDirty },
    } = useForm({
        defaultValues: { temporarySubscriber },
    });

    useEffect(() => {
        reset({ temporarySubscriber });
    }, [temporarySubscriber, reset]);

    const onSubmit = ({ temporarySubscriber }: ITemporarySubscriberConfiguration) => {
        setIsLoading(true);

        if (temporarySubscriber.checked) {
            dispatch(saveConfigurationField({ name: "temporarySubscriber" }))
                .then(unwrapResult)
                .then(() => {
                    toast(t("saved") + " " + t("temporarySubscriber"));
                    setIsLoading(false);
                })
                .catch((e) => {
                    toast(t("error") + ": " + e.message + " " + t("temporarySubscriber"), { type: "error" });
                    resetField("temporarySubscriber.checked");
                    setIsLoading(false);
                });
        } else {
            temporarySubscriber.id &&
                dispatch(deleteConfigurationField(temporarySubscriber.id))
                    .then(unwrapResult)
                    .then(() => {
                        toast(t("saved") + " " + t("temporarySubscriber"));
                        setIsLoading(false);
                    })
                    .catch((e) => {
                        toast(t("error") + ": " + e.message + " " + t("temporarySubscriber"), { type: "error" });
                        resetField("temporarySubscriber.checked");
                        setIsLoading(false);
                    });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("temporarySubscriber")}
                reset={() => reset({ temporarySubscriber })}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <>{temporarySubscriber.checked && <AccordionBooleanContent label={t("temporarySubscriber")} />}</>
                }
            >
                <ServiceCheckboxContainer checkboxHeading={t("email")}>
                    <ServiceCheckboxRow
                        label={t("temporarySubscriber")}
                        register={register("temporarySubscriber.checked")}
                    />
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default TemporarySubscriberConfiguration;
