import { useTranslation } from "react-i18next";

const CookiePolicy = () => {
    const { t } = useTranslation("about");

    return (
        <>
            <div className="border-b pb-24 about">
                <h1 className="mb-16">{t("cookiePolicyInfo.heading")}</h1>
                <div className="lg:flex mb-3 mt-8 flex-col">
                    <h2 className="lg:pr-10 mb-4">{t("cookiePolicyInfo.title1")}</h2>
                    <p>{t("cookiePolicyInfo.paragraph1")}</p>
                </div>
                <div className="lg:flex mb-3 mt-8 flex-col">
                    <h2 className="lg:pr-10 mb-4">{t("cookiePolicyInfo.title2")}</h2>
                    <ul className="pl-6 list-disc">
                        <li>{t("cookiePolicyInfo.paragraph2")}</li>
                        <br />
                        <li>{t("cookiePolicyInfo.paragraph2-1")}</li>
                    </ul>
                </div>
                <div className="lg:flex mb-3 mt-8 flex-col">
                    <h2 className="lg:pr-10 mb-4">{t("cookiePolicyInfo.title3")}</h2>
                    <p>{t("cookiePolicyInfo.paragraph3")}</p>
                </div>
            </div>
            <p className="mt-6">{t("lastUpdated")}: 2022-03-09</p>
        </>
    );
};

export default CookiePolicy;
