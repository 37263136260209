import { useTranslation } from "react-i18next";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import AbsenceReportBase from "../AbsenceReportBase/AbsenceReportBase";
import WizardHeader from "components/WizardHeader";
import PrimaryButton from "components/PrimaryButton";
import Icon from "components/Icon";

interface IAbsenceReportBaseEntity {
    isUpdate: boolean;
    currentPageIsValid: boolean;
    onComplete?: (absenceReport: IAbsenceReportExtended) => void;
    onUpdate: (fieldName: string, value: any, endDate?: any, timeStamp?: string | undefined) => void;
    setForwardClick: () => void;
    isLastPage: boolean;
}

const AbsenceReportBaseWizard = ({
    isUpdate,
    currentPageIsValid,
    onComplete,
    onUpdate,
    setForwardClick,
    isLastPage,
}: IAbsenceReportBaseEntity) => {
    const { t } = useTranslation();

    const primaryButton = (className: string) => (
        <PrimaryButton
            id="next-button"
            text={isLastPage ? t("registerReport") : t("next")}
            rightIcon={<Icon icon="checkMark" stroke="currentcolor" />}
            className={className}
            onClick={setForwardClick}
        />
    );

    return (
        <div
            className="flex flex-col xs:w-full md:w-10/12 flex-grow relative max-xxs:pb-10 bg-pageBg"
            data-testid="ar-base-wizard"
        >
            <div className="flex flex-col">
                <WizardHeader isMandatory={true} heading={t("stateAbsence")} />
                <div className="flex flex-col justify-center pt-10">
                    <AbsenceReportBase
                        isUpdate={isUpdate}
                        isEdit={false}
                        isWizard={true}
                        onComplete={onComplete}
                        onUpdate={onUpdate}
                    />
                </div>
            </div>
            {currentPageIsValid && (
                <div className="flex flex-col flex-grow">
                    <div className="flex-grow xxs:h-full xxs:flex xxs:flex-col xxs:justify-between">
                        {primaryButton("hidden fit-content mt-16 mb-6 xxs:flex")}
                        <div className="flex fixed bottom-0 right-0 left-0 h-24 shadow-top xxs:hidden bg-mhbeige">
                            {primaryButton("full-width-important flex justify-center self-center mx-5 h-14 xxs:hidden")}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AbsenceReportBaseWizard;
