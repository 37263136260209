import { RootState } from "store";
import { AxiosPromise } from "axios";
import { getServices } from "api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IBillingConfigurationStringified } from "./serviceSelectors";
import { generateHCMTemplatePromises } from "./generateHCMTemplatePromises";
import { determineAdvisoryService } from "../utils/determineAdvisoryService";
import { ICompanyInformation } from "../components/CompanyInformation/CompanyInformation";
import { MedHelpHCMDomainTemplate, MedHelpHCMWebApiModelsAddTemplateRules } from "swagger/healthCaseManagement";
import {
    FieldType,
    ProductType,
    AccessRightType,
    AbsenceTemplateType,
    ConfigurationFieldType,
    ICreateCustomerOrCompany,
} from "../utils/serviceTypes";
import {
    MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule,
    MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule,
    MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration,
    MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel,
} from "swagger/absencefollowup";
import {
    MedHelpAbsenceReportingDomainTemplate,
    MedHelpAbsenceReportingDomainFieldTemplate,
    MedHelpAbsenceReportingWebApiModelsConfigurationField,
    MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel,
} from "swagger/absencereportapi";
import {
    InvoicePost,
    CompanyModel,
    BillingPosts,
    RehabTemplate,
    CustomerCompany,
    CompanyInfoModel,
    UpdateCompanyModel,
    CustomerOnboarding,
    RehabConfiguration,
    LoginConfiguration,
    CompanySelectedUser,
    ServiceContractModel,
    BillingConfiguration,
    WorkOrderConfiguration,
    CompanySelectedEmpAbsence,
    CustomerCompanyOnboarding,
    DeleteEmploymentGroupsModel,
    CompanyDeactivationContract,
    NotificationConfigurationModel,
    SaveWorkOrderConfigurationModel,
    SaveFollowUpAdvisoryConfigurationModel,
} from "swagger/businessupport";
import { ConfigurationField } from "swagger/messenger";
import { socialInsuranceDeviantRuleObject, danishLongTermSickLeaveDeviantRuleObject } from "../utils/ruleObjects";

// TODO: Remove this when the backend is fixed
export const convertDateToIsoString = (dateString: string) => `${dateString}T12:00:00.000Z`;

export const fetchCustomerCompanySearchResults = createAsyncThunk(
    "service/searchCustomerCompany",
    async (searchString: string) => {
        const { data } =
            await getServices().clients.onBoarding.apiOnboardingSearchCustomersAndCompaniesGet(searchString);
        return data;
    },
);

export interface ICompanyIds {
    companyId: number;
    customerId: number;
    language?: string;
}

export const fetchCustomerData = createAsyncThunk("service/fetchCustomerData", async (customerId: number) => {
    const { data } = await getServices().clients.onBoarding.apiOnboardingGetCustomerGet(customerId);
    return data;
});

interface IFetchCompanyData {
    customerId: number;
    companyId: number;
}

export const fetchCompanyData = createAsyncThunk(
    "service/fetchCompanyData",
    async ({ customerId, companyId }: IFetchCompanyData, thunkApi) => {
        const { data: organizationInfo } =
            await getServices().clients.onBoarding.apiOnboardingGetOrganizationInfoGet(companyId);
        const response = await Promise.allSettled([
            getServices().clients.templates.templatesCustomerGet(customerId, companyId),
            getServices().clients.deviant.deviantRulesGet(customerId, companyId),
            getServices().clients.onBoarding.apiOnboardingGetNotificationConfigurationGet(companyId),
            getServices().clients.configurationFields.configurationFieldsGet(customerId, companyId),
            getServices().clients.onBoarding.apiOnboardingGetCustomerGet(customerId),
            getServices().clients.onBoarding.apiOnboardingGetEmploymentAbsenceInfoGet(companyId),
            getServices().clients.onBoarding.apiOnboardingGetWorkOrderInfoGet(companyId, customerId),
            getServices().clients.onBoarding.apiOnboardingGetServiceUtilizationInfoGet(companyId, customerId),
            getServices().clients.onBoarding.apiOnboardingGetFileImportConfigGet(companyId),
            getServices().clients.onBoarding.apiOnboardingGetUserInfoGet(companyId, customerId),
            getServices().clients.customer.apiCustomerGetCompaniesByCustomerIdGet(customerId),
            getServices().clients.healthCaseTemplates.templateListCustomerIdGet(customerId, companyId),
            getServices().clients.fieldTemplate.fieldTemplateAvailableGet(organizationInfo.region?.language || "se"),
            getServices().clients.socialInsurance.socialInsuranceConfigurationGet(customerId, companyId),
            getServices().clients.configurationField.apiV1ConfigurationFieldGet(customerId, companyId),
        ]);

        if (response.every((res) => res?.status === "fulfilled")) {
            const okResponse = response.map((res) =>
                res.status === "fulfilled" && res.value.data ? res.value.data : undefined,
            );

            return {
                absenceReportingTemplates: okResponse[0] as MedHelpAbsenceReportingDomainTemplate[],
                deviantAbsenceRules: okResponse[1] as MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule[],
                notificationConfiguration: (okResponse[2] as NotificationConfigurationModel) || {
                    id: "",
                    companyId,
                    customerId,
                    handleEstimatedBackAtWork: false,
                    handleMedicalReminder: false,
                },
                configurationFields: okResponse[3] as MedHelpAbsenceReportingWebApiModelsConfigurationField[],
                customerData: okResponse[4] as CustomerCompanyOnboarding,
                employmentAbsenceInfo: okResponse[5] as CompanySelectedEmpAbsence,
                workOrderInfo: (okResponse[6] as WorkOrderConfiguration) || {
                    allowDirectAdvisoryCallback: false,
                    mandatoryAdvisoryCallback: false,
                },
                serviceUtilizationInfo: okResponse[7] as ServiceContractModel[],
                fileImportConfig: okResponse[8] as boolean,
                userInfo: okResponse[9] as CompanySelectedUser,
                companies: okResponse[10] as CustomerCompany[],
                healthCaseManagementTemplates: okResponse[11] as MedHelpHCMDomainTemplate[],
                availableTemplateFields: okResponse[12] as MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel[],
                socialInsuranceConfiguration:
                    okResponse[13] as MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration,
                messengerConfigurationFields: okResponse[14] as ConfigurationField[],
                organizationInfo,
                companyId,
                customerId,
            };
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

export const saveNewCompany = createAsyncThunk(
    "service/saveNewCompany",
    async (newCompanyData: ICreateCustomerOrCompany, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const regions = state.service.regions;
        const region = regions.find((r) => r.id === newCompanyData.regionId);

        // retailer id cannot be chosen by user, can only be the active retailer in order to have correct access rights
        const retailerId = state.user.userContextExtended?.userAccount?.retailer?.id;

        let customerId = newCompanyData.type === "companyToGroup" ? newCompanyData.customerId : undefined;

        const CompanyModel: CompanyModel = {
            retailerId,
            customerId,
            name: newCompanyData.companyName,
            number: newCompanyData.organizationNumber,
            industryCode: newCompanyData.industryCode,
            product: newCompanyData.product,
            region,
        };

        const { data: companyId } =
            await getServices().clients.businessSupport.apiBusinessSupportSaveCompanyPost(CompanyModel);

        if (companyId) {
            if (!customerId) {
                // fetch newly created customer id
                const { data } =
                    await getServices().clients.businessSupport.apiBusinessSupportGetCompanyMainDetailListGet(
                        companyId,
                    );
                customerId = data.customerId;
                if (!customerId) {
                    return thunkApi.rejectWithValue("Failed to fetch customer id");
                }
            }

            const language = region?.language || "se";

            // ABSENCE REPORTING TEMPLATES && ADVISORY SERVICE

            const templateFieldPromises = [];
            const advisoryServicePromises = [];

            const { data: SickTemplateId } = await getServices().clients.templates.templatesPost({
                absenceReportType: "Sick",
                customerId,
                companyId,
                region: language,
            });

            const { data: CareOfChildTemplateId } = await getServices().clients.templates.templatesPost({
                absenceReportType: "CareOfChild",
                customerId,
                companyId,
                region: language,
            });

            if (newCompanyData.product !== "base") {
                // Absence reporting field boilerplates
                templateFieldPromises.push(
                    getServices().clients.fieldTemplate.fieldTemplateBoilerplatePost({
                        region: language,
                        templateId: SickTemplateId,
                    }),
                );
                templateFieldPromises.push(
                    getServices().clients.fieldTemplate.fieldTemplateBoilerplatePost({
                        region: language,
                        templateId: CareOfChildTemplateId,
                    }),
                );

                // LEGACY
                if (newCompanyData.product === "legacy") {
                    switch (newCompanyData.advisoryService) {
                        case "requestedMedicalAdvisory":
                            advisoryServicePromises.push(
                                getServices().clients.fieldTemplate.fieldTemplateFieldPost({
                                    name: "RequestedMedicalAdvisory",
                                    templateId: SickTemplateId,
                                    region: language,
                                }),
                            );
                            advisoryServicePromises.push(
                                getServices().clients.fieldTemplate.fieldTemplateFieldPost({
                                    name: "RequestedMedicalAdvisory",
                                    templateId: CareOfChildTemplateId,
                                    region: language,
                                }),
                            );
                            break;
                        case "callNurse":
                            advisoryServicePromises.push(
                                getServices().clients.configurationFields.configurationFieldsPost({
                                    customerId,
                                    companyId,
                                    name: "callNurse",
                                    value: "true",
                                }),
                            );
                            advisoryServicePromises.push(
                                getServices().clients.configurationFields.configurationFieldsPost({
                                    customerId,
                                    companyId,
                                    name: "careOfChildViaIvrAvailable",
                                    value: "true",
                                }),
                            );
                            break;
                        case "mandatoryCallback":
                            advisoryServicePromises.push(
                                getServices().clients.configurationFields.configurationFieldsPost({
                                    customerId,
                                    companyId,
                                    name: "mandatoryCallback",
                                    value: "true",
                                }),
                            );
                            break;
                        default:
                            break;
                    }
                }

                // PLUS ADVISORY
                if (newCompanyData.product === "plusAdvisory") {
                    advisoryServicePromises.push(
                        getServices().clients.fieldTemplate.fieldTemplateFieldPost({
                            name: "RequestedMedicalAdvisory",
                            templateId: SickTemplateId,
                            region: language,
                        }),
                    );
                    advisoryServicePromises.push(
                        getServices().clients.fieldTemplate.fieldTemplateFieldPost({
                            name: "RequestedMedicalAdvisory",
                            templateId: CareOfChildTemplateId,
                            region: language,
                        }),
                    );
                    advisoryServicePromises.push(
                        getServices().clients.fieldTemplate.fieldTemplateFieldPost({
                            name: "RiskMedicalAdvisoryRequest",
                            templateId: SickTemplateId,
                            region: language,
                        }),
                    );
                    advisoryServicePromises.push(
                        getServices().clients.configurationFields.configurationFieldsPost({
                            customerId,
                            companyId,
                            name: "dynamicMedicalAdvisory",
                            value: "true",
                        }),
                    );
                }
            }

            // SERVICE CONTRACTS

            const serviceContractPromises = [];

            // LEGACY
            if (newCompanyData.product === "legacy") {
                newCompanyData.healthServices.forEach(({ healthService, checked }) => {
                    if (checked)
                        serviceContractPromises.push(
                            getServices().clients.businessSupport.apiBusinessSupportSaveServiceContractPost({
                                healthService,
                                activationDate: convertDateToIsoString(newCompanyData.activationDate),
                                companyId,
                                customerId,
                            }),
                        );
                });
            }
            if (newCompanyData.product !== "legacy") {
                serviceContractPromises.push(
                    getServices().clients.businessSupport.apiBusinessSupportSaveServiceContractPost({
                        healthService: 3,
                        activationDate: convertDateToIsoString(newCompanyData.activationDate),
                        companyId,
                        customerId,
                    }),
                );
                if (newCompanyData.product === "plus" || newCompanyData.product === "plusAdvisory") {
                    serviceContractPromises.push(
                        getServices().clients.businessSupport.apiBusinessSupportSaveServiceContractPost({
                            healthService: 1,
                            activationDate: convertDateToIsoString(newCompanyData.activationDate),
                            companyId,
                            customerId,
                        }),
                    );
                }
            }

            // --- DEFAULT CONFIGURATIONS --- //

            // SOCIAL INSURANCE

            const socialInsurancePromise = [];

            if (region?.country === "Sweden" && newCompanyData.product !== "base") {
                socialInsurancePromise.push(
                    getServices().clients.socialInsurance.socialInsuranceConfigurationPost({
                        companyId,
                        customerId,
                        socialInsuranceReporting: true,
                    }),
                );
            }

            // ACCESS RIGHTS

            const DEFAULTACCESSRIGHTS: AccessRightType[] = ["AbsenceFollowup", "AbsenceStatistics"];

            const accessRightPromises = [];

            if (newCompanyData.type === "standAloneCompany") {
                DEFAULTACCESSRIGHTS.forEach((accessRight) =>
                    accessRightPromises.push(
                        getServices().clients.accessRights.apiAccessRightConfigurationPost({
                            // @ts-expect-error - Generated types are wrong
                            accessRightType: accessRight,
                            companyId,
                            customerId,
                            hierarchical: false,
                            type: "DepartmentManagerAccessRights",
                        }),
                    ),
                );
            }

            // Absence Administration access right if not base product
            if (newCompanyData.product !== "base") {
                accessRightPromises.push(
                    getServices().clients.accessRights.apiAccessRightConfigurationPost({
                        // @ts-expect-error - Generated types are wrong
                        accessRightType: "AbsenceAdministration",
                        companyId,
                        customerId,
                        hierarchical: false,
                        type: "DepartmentManagerAccessRights",
                    }),
                );
            }

            // Rehab access right only if rehab service contract is selected
            if (
                newCompanyData.healthServices.some(
                    ({ healthService, checked }) =>
                        (checked && healthService === 3) || newCompanyData.product !== "legacy",
                )
            ) {
                accessRightPromises.push(
                    getServices().clients.accessRights.apiAccessRightConfigurationPost({
                        // @ts-expect-error - Generated types are wrong
                        accessRightType: "RehabProcessManagement",
                        companyId,
                        customerId,
                        hierarchical: false,
                        type: "DepartmentManagerAccessRights",
                    }),
                );
            }

            // DEVIANT RULES

            // DEFAULT
            const deviantRulesPromises = [
                getServices().clients.deviant.deviantRulesPost({
                    companyId,
                    customerId,
                    ruleName: "AbsencesPerMonths",
                    value: "3",
                    interval: "6",
                    absenceType: "Sick",
                }),
                getServices().clients.deviant.deviantRulesPost({
                    companyId,
                    customerId,
                    ruleName: "AbsenceMaxLengthRecurring",
                    value: "90",
                    interval: "0",
                    absenceType: "Sick",
                }),
            ];

            // Swedish deviant rule
            if (region?.country === "Sweden") {
                getServices().clients.deviant.deviantRulesPost({
                    companyId,
                    customerId,
                    ruleName: "AbsencesPerMonths",
                    value: "6",
                    interval: "12",
                    absenceType: "Sick",
                });
            }

            // Social insurance deviant rule
            if (newCompanyData.product !== "base" && region?.country === "Sweden") {
                deviantRulesPromises.push(
                    getServices().clients.deviant.deviantRulesPost({
                        companyId,
                        customerId,
                        ...socialInsuranceDeviantRuleObject,
                    }),
                );
            }

            // Danish deviant rules
            if (region?.country === "Denmark") {
                deviantRulesPromises.push(
                    getServices().clients.deviant.deviantRulesPost({
                        customerId,
                        companyId,
                        ...danishLongTermSickLeaveDeviantRuleObject,
                    }),
                    getServices().clients.deviant.deviantRulesPost({
                        companyId,
                        customerId,
                        ruleName: "AbsenceMaxLengthRecurring",
                        value: "14",
                        interval: "0",
                        absenceType: "Sick",
                    }),
                );
            }

            // WORKORDER CONFIGURATION

            const workOrderPromise = [];

            if (
                newCompanyData.advisoryService !== "fullyAutomatic" &&
                newCompanyData.product !== "plus" &&
                newCompanyData.product !== "base"
            ) {
                const { data: workOrderConfigurationId } =
                    await getServices().clients.businessSupport.apiBusinessSupportSaveWorkOrderConfigurationPut({
                        allowDirectAdvisoryCallback:
                            newCompanyData.advisoryService === "requestedMedicalAdvisory" ||
                            newCompanyData.advisoryService === "dynamicMedicalAdvisory" ||
                            newCompanyData.product === "plusAdvisory",
                        mandatoryAdvisoryCallback: newCompanyData.advisoryService === "mandatoryCallback",
                        companyId,
                        customerId,
                    });

                workOrderPromise.push(
                    getServices().clients.businessSupport.apiBusinessSupportSaveFollowupConfigurationPut({
                        workOrderConfigurationId,
                        days: [3],
                    }),
                );
            }

            const notificationConfigurationPromise =
                getServices().clients.onBoarding.apiOnboardingSaveNotificationConfigurationPost({
                    id: "",
                    companyId,
                    customerId,
                    handleMedicalReminder: newCompanyData.product !== "base",
                    handleEstimatedBackAtWork: newCompanyData.product !== "base",
                });

            // HEALTH CARE MANAGEMENT CONFIGURATION

            let healthCaseManagementPromises: AxiosPromise[] = [];

            if (
                newCompanyData.healthServices.some(
                    ({ healthService, checked }) =>
                        (checked && healthService === 3) || newCompanyData.product !== "legacy",
                )
            ) {
                healthCaseManagementPromises = await generateHCMTemplatePromises({
                    customerId,
                    companyId,
                    product: newCompanyData.product,
                    country: region?.country || "",
                });
            }

            const promises = [
                ...templateFieldPromises,
                ...advisoryServicePromises,
                ...serviceContractPromises,
                ...accessRightPromises,
                ...deviantRulesPromises,
                ...workOrderPromise,
                ...healthCaseManagementPromises,
                ...socialInsurancePromise,
                notificationConfigurationPromise,
            ];

            const results = await Promise.allSettled(promises).then((results) =>
                results.map((result) => {
                    if (result.status === "rejected") {
                        return {
                            status: result?.status,
                            url: result?.reason?.config?.url || "unknown",
                            body: result?.reason?.config?.data || "unknown",
                            error: result?.reason?.message || "unknown",
                        };
                    }
                    if (result.status === "fulfilled") {
                        return {
                            status: result?.status,
                            url: result?.value?.request?.responseURL || "unknown",
                            body: result?.value?.config?.data || "unknown",
                        };
                    }
                }),
            );

            return { results, companyId, customerId, companyName: newCompanyData.companyName };
        }
        return thunkApi.rejectWithValue("No company id");
    },
);

interface IGenerateHCMTemplatePromises {
    customerId: number;
    companyId: number;
    product: ProductType;
    country: string;
}

export const saveDefaultHCMTemplates = createAsyncThunk(
    "service/saveDefaultHCMTemplates",
    async ({ customerId, companyId, product, country }: IGenerateHCMTemplatePromises) => {
        const promises = await generateHCMTemplatePromises({ customerId, companyId, product, country });
        const templates: MedHelpHCMDomainTemplate[] = [];
        await Promise.allSettled(promises).then((results) => {
            results.map((result) => {
                if (result.status === "fulfilled") templates.push(result.value.data);
            });
        });
        return templates;
    },
);

export const deleteHCMTemplate = createAsyncThunk("service/deleteHCMTemplate", async (templateId: string) => {
    await getServices().clients.healthCaseTemplates.templateTemplateIdDelete(templateId);
    return templateId;
});

export const fetchRegions = createAsyncThunk("service/fetchOnboardingData", async () => {
    const { data } = await getServices().clients.businessSupport.apiBusinessSupportGetRegionsGet();
    return data;
});

export const fetchCustomersByRetailerId = createAsyncThunk(
    "service/fetchCustomersByRetailerId",
    async (retailerId: number) => {
        const { data } = await getServices().clients.customer.apiCustomerCustomersByRetailerGet(retailerId);
        return data;
    },
);

export const fetchBillableErpProductsByRetailId = createAsyncThunk(
    "service/getBillableErpProductsByRetailId",
    async (retailerId: number) => {
        const { data } =
            await getServices().clients.businessSupport.apiBusinessSupportGetBillableErpProductsByRetailIdsPost({
                retailIds: [retailerId],
            });
        return data;
    },
);

export const saveCompanyInformation = createAsyncThunk(
    "service/saveCompanyInformation",
    async (companyInfo: ICompanyInformation, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { companyId } = state.service;
        const postToBeModel: UpdateCompanyModel = {
            companyId,
            companyName: companyInfo.companyName,
            number: companyInfo.organizationNumber,
            region: companyInfo.region,
            newReporting: true,
        };
        await getServices().clients.onBoarding.apiOnboardingUpdateCompanyPost(postToBeModel);
        return postToBeModel;
    },
);

export const saveCompanyInfo = createAsyncThunk(
    "service/saveCompanyInfo",
    async (classification: CompanyInfoModel, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const postToBeModel: CompanyInfoModel = {
            companyId: state.service.companyId,
            industryCode: classification.industryCode,
        };
        await getServices().clients.businessSupport.apiBusinessSupportSaveCompanyInfoPost(postToBeModel);
        return postToBeModel;
    },
);

export const saveEmploymentGroups = createAsyncThunk(
    "service/saveEmploymentGroups",
    async (values: string[], thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { data } = await getServices().clients.onBoarding.apiOnboardingSaveEmploymentGroupsPost({
            companyId: state.service.companyId,
            employmentGroups: values.map((employmentGroup) => ({
                nameString: employmentGroup,
            })),
        });
        return data;
    },
);

export const deleteEmploymentGroups = createAsyncThunk(
    "service/deleteEmploymentGroups",
    async (ids: number[], thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const postToBeModel: DeleteEmploymentGroupsModel = {
            companyId: state.service.companyId,
            employmentGroupIds: ids,
        };
        await getServices().clients.onBoarding.apiOnboardingDeleteEmploymentGroupsPost(postToBeModel);
        return ids;
    },
);

export const saveLoginConfiguration = createAsyncThunk(
    "service/saveLoginConfiguration",
    async (loginConfiguration: LoginConfiguration) => {
        await getServices().clients.businessSupport.apiBusinessSupportSaveLoginConfigurationPost(loginConfiguration);
        return loginConfiguration;
    },
);

export const saveBillingConfiguration = createAsyncThunk(
    "service/saveBillingConfiguration",
    async (billingConfiguration: IBillingConfigurationStringified, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId, retailerId } = state.service.customerData;
        const postToBeModel: BillingConfiguration = {
            customerId,
            retailerId,
            // @ts-expect-error: billingPeriodType is incorrectly typed in Swagger
            billingPeriodType: Number(billingConfiguration.billingPeriodType),
            billingDayInMonth: Number(billingConfiguration.billingDayInMonth),
            // @ts-expect-error: exportedContentLevel is incorrectly typed in Swagger
            exportedContentLevel: Number(billingConfiguration.exportedContentLevel),
            groupedOnTopLevelDepartments: billingConfiguration.groupedOnTopLevelDepartments === "true",
            // @ts-expect-error: groupType is incorrectly typed in Swagger
            groupType: Number(billingConfiguration.groupType),
        };

        await getServices().clients.customer.apiCustomerSaveCustomerBillingPost(postToBeModel);
        return postToBeModel;
    },
);

export const saveAndFetchBillingPostConfigurations = createAsyncThunk(
    "service/saveBillingPostConfigurations",
    async (billingPostConfigurations: InvoicePost[], thunkApi) => {
        const { customerId, retailerId } = (thunkApi.getState() as RootState).service.customerData;
        const postToBeModel: BillingPosts = {
            customerId,
            retailerId,
            configurations: billingPostConfigurations.map((post) => {
                // endpoint wants erpNumber when editing existing post but billingPostType when creating new post
                if (post.id) {
                    return {
                        id: post.id,
                        activationDate: post.activationDate ? convertDateToIsoString(post.activationDate) : "",
                        deactivationDate: post.deactivationDate
                            ? convertDateToIsoString(post.deactivationDate)
                            : undefined,
                        overridingPrice: post.customerPrice,
                        billableErpProductId: Number(post.erpNumber),
                    };
                } else {
                    return {
                        id: 0,
                        activationDate: post.activationDate ? convertDateToIsoString(post.activationDate) : "",
                        deactivationDate: post.deactivationDate
                            ? convertDateToIsoString(post.deactivationDate)
                            : undefined,
                        overridingPrice: post.customerPrice,
                        billingPostType: post.billingPostType,
                    };
                }
            }),
        };

        await getServices().clients.customer.apiCustomerSaveBillingPostConfigurationsPost(postToBeModel);

        // fetch data immediately since it was too complicated to return the new ids from BE
        const { data: customerData } = await getServices().clients.onBoarding.apiOnboardingGetCustomerGet(customerId);

        return customerData.invoicePosts;
    },
);

export const deleteDeviantRule = createAsyncThunk("service/deleteDeviantRule", async (id: string) => {
    const { data } = await getServices().clients.deviant.deviantRulesIdDelete(id);
    return data;
});

export const saveDeviantRule = createAsyncThunk(
    "service/saveDeviantRule",
    async (deviantRule: MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId, companyId } = state.service;
        const postToBeModel: MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule = {
            customerId,
            companyId,
            ...deviantRule,
        };
        const { data } = await getServices().clients.deviant.deviantRulesPost(postToBeModel);
        return { id: data, ...postToBeModel };
    },
);

export const saveAbsenceFollowUpConfiguration = createAsyncThunk(
    "service/saveAbsenceFollowUpConfiguration",
    async ({ id, days }: SaveFollowUpAdvisoryConfigurationModel & { id: string }) => {
        const { data } = await getServices().clients.businessSupport.apiBusinessSupportSaveFollowupConfigurationPut({
            workOrderConfigurationId: id,
            days: days,
        });
        return data;
    },
);

export const saveWorkOrderConfiguration = createAsyncThunk(
    "service/saveWorkOrderConfiguration",
    async (workOrderConfiguration: SaveWorkOrderConfigurationModel, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId, companyId } = state.service;
        const { data } = await getServices().clients.businessSupport.apiBusinessSupportSaveWorkOrderConfigurationPut({
            ...workOrderConfiguration,
            companyId,
            customerId,
        });
        return { id: data, configuration: workOrderConfiguration };
    },
);

export const saveNotificationConfiguration = createAsyncThunk(
    "service/saveNotificationConfiguration",
    async (notificationConfiguration: NotificationConfigurationModel) => {
        await getServices().clients.onBoarding.apiOnboardingSaveNotificationConfigurationPost(
            notificationConfiguration,
        );
        return notificationConfiguration;
    },
);

export const saveRehabConfiguration = createAsyncThunk(
    "service/saveRehabConfiguration",
    async (rehabConfiguration: RehabConfiguration) => {
        await getServices().clients.businessSupport.apiBusinessSupportSaveRehabConfigurationPost(rehabConfiguration);
        return rehabConfiguration;
    },
);

export interface RehabTemplateWithCheckAndDisabled extends RehabTemplate {
    checked?: boolean;
    disabled?: boolean;
}

export const saveRehabTemplatesConfiguration = createAsyncThunk(
    "service/saveRehabTemplatesConfiguration",
    async (rehabTemplates: RehabTemplate[], thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { companyId } = state.service;
        await getServices().clients.businessSupport.apiBusinessSupportSaveRehabTemplateConfigurationPost({
            companyId,
            rehabTemplates,
        });
        return rehabTemplates;
    },
);

export interface IAccessRightConfiguration {
    accessRightType: string;
    checked?: boolean;
    hierarchical: boolean;
    managerType: string;
}

export const saveAccessRightConfiguration = createAsyncThunk(
    "service/saveAccessRightConfiguration",
    async (accessRightConfiguration: IAccessRightConfiguration, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId, companyId } = state.service;

        const postToBeModel = {
            customerId,
            companyId,
            accessRightType: accessRightConfiguration.accessRightType,
            hierarchical: accessRightConfiguration.hierarchical,
            type: accessRightConfiguration.managerType,
        };
        await getServices().clients.accessRights.apiAccessRightConfigurationPost(
            // @ts-expect-error: accessRightType is incorrectly typed in Swagger
            postToBeModel,
        );
        return postToBeModel;
    },
);

export const deleteAccessRightConfiguration = createAsyncThunk(
    "service/deleteAccessRightConfiguration",
    async (accessRightConfiguration: IAccessRightConfiguration, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId, companyId } = state.service;
        await getServices().clients.accessRights.apiAccessRightConfigurationDeletePost({
            customerId,
            companyId,
            // @ts-expect-error: accessRightType is incorrectly typed in Swagger
            accessRightType: accessRightConfiguration.accessRightType,
            hierarchical: accessRightConfiguration.hierarchical,
            type: accessRightConfiguration.managerType,
        });
        return accessRightConfiguration;
    },
);

interface ISaveConfigurationField {
    name: ConfigurationFieldType;
    value?: string;
}

export const saveConfigurationField = createAsyncThunk(
    "service/saveConfigurationField",
    async ({ name, value = "true" }: ISaveConfigurationField, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId, companyId } = state.service;

        const { data } = await getServices().clients.configurationFields.configurationFieldsPost({
            customerId,
            companyId,
            name,
            value,
        });
        return { name, value, id: data };
    },
);

interface IUpdateConfigurationField {
    fieldName: ConfigurationFieldType;
    fieldValue: string;
}

export const updateConfigurationField = createAsyncThunk(
    "service/updateConfigurationField",
    async ({ fieldName, fieldValue }: IUpdateConfigurationField, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId, companyId } = state.service;

        await getServices().clients.configurationFields.configurationFieldsPut({
            customerId,
            companyId,
            fieldName,
            fieldValue,
        });
        return { fieldName, fieldValue };
    },
);

export const deleteConfigurationField = createAsyncThunk("service/deleteConfigurationFieldById", async (id: string) => {
    await getServices().clients.configurationFields.configurationFieldsIdDeleteDelete(id);
    return id;
});

interface ISaveFieldTemplate {
    templateId: string;
    name: FieldType;
}

export const saveFieldTemplate = createAsyncThunk(
    "service/saveFieldTemplate",
    async (fieldTemplate: ISaveFieldTemplate, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { region } = state.service.organizationInfo;
        const { data } = await getServices().clients.fieldTemplate.fieldTemplateFieldPost({
            ...fieldTemplate,
            region: region?.language || "se",
        });
        return { fieldTemplate, fieldTemplateId: data };
    },
);

export const deleteFieldTemplate = createAsyncThunk(
    "service/deleteFieldTemplate",
    async ({ templateId, id }: MedHelpAbsenceReportingDomainFieldTemplate) => {
        await getServices().clients.fieldTemplate.fieldTemplateDelete(templateId, id);
        return id;
    },
);

export const saveServiceContract = createAsyncThunk(
    "service/saveServiceContract",
    async (serviceContract: ServiceContractModel, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId, companyId } = state.service;

        const { data } = await getServices().clients.businessSupport.apiBusinessSupportSaveServiceContractPost({
            ...serviceContract,
            activationDate: serviceContract.activationDate
                ? convertDateToIsoString(serviceContract.activationDate)
                : undefined,
            deactivationDate: serviceContract.deactivationDate
                ? convertDateToIsoString(serviceContract.deactivationDate)
                : undefined,
            customerId,
            companyId,
        });

        return { serviceContract: { ...serviceContract, id: data }, newContract: serviceContract.id === undefined };
    },
);

export const deactivateCompanies = createAsyncThunk(
    "service/deactivateCompanies",
    async (customerCompanies: CustomerCompany[], thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId } = state.service.customerData;

        const postToBeModel: CompanyDeactivationContract = {
            customerId,
            companyDeactivations: customerCompanies.map(({ companyId, deactivationDate }) => ({
                companyId,
                deactivationDate: deactivationDate ? convertDateToIsoString(deactivationDate) : undefined,
            })),
        };

        await getServices().clients.customer.apiCustomerDeactivateCompanyPost(postToBeModel);
        return customerCompanies;
    },
);

export const deleteHealthCaseManagementTemplate = createAsyncThunk(
    "service/deleteHealthCaseManagementTemplate",
    async (templateId: string) => {
        await getServices().clients.healthCaseTemplates.templateTemplateIdDelete(templateId);
    },
);

interface IFetchTemplateFormDocuments {
    retailerId: number;
    customerId?: number;
    region?: string;
}

export const fetchTemplateFormDocuments = createAsyncThunk(
    "service/fetchTemplateFormDocuments",
    async ({ retailerId, customerId, region }: IFetchTemplateFormDocuments) => {
        const { data } = await getServices().clients.templateFormDocument.templateFormDocumentListRetailerIdGet(
            retailerId,
            customerId,
            region,
        );
        return data;
    },
);

interface ISaveDocumentsTemplatesByTemplateId {
    templateId: string;
    documentTemplateIds: string[];
}

export const saveDocumentsTemplatesByTemplateId = createAsyncThunk(
    "service/saveDocumentsTemplatesByTemplateId",
    async ({ templateId, documentTemplateIds }: ISaveDocumentsTemplatesByTemplateId) => {
        const { data } = await getServices().clients.template.templateDocumentsTemplateIdPost(templateId, {
            documentIds: documentTemplateIds,
        });
        return data;
    },
);

interface ISaveTemplateRules {
    templateId: string;
    templateRules: MedHelpHCMWebApiModelsAddTemplateRules;
}

export const saveTemplateRules = createAsyncThunk(
    "service/saveTemplateRule",
    async ({ templateId, templateRules }: ISaveTemplateRules) => {
        const { data } = await getServices().clients.healthCaseTemplates.templateRulesTemplateIdPost(
            templateId,
            templateRules,
        );
        return data;
    },
);

interface IDeleteTemplateRule {
    templateId: string;
    templateRuleId: string;
}

export const deleteTemplateRule = createAsyncThunk(
    "service/deleteTemplateRule",
    async ({ templateId, templateRuleId }: IDeleteTemplateRule) => {
        await getServices().clients.healthCaseTemplates.templateRulesTemplateIdRuleIdDelete(templateId, templateRuleId);
        return { templateId, templateRuleId };
    },
);

interface ISaveProduct {
    product: string;
    subcategory: string;
}

export const saveProduct = createAsyncThunk(
    "service/saveProduct",
    async ({ product, subcategory }: ISaveProduct, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId } = state.service.customerData;
        getServices().clients.onBoarding.apiOnboardingUpdateCustomerProductPost({
            customerId,
            product,
            subcategory,
        });

        return product;
    },
);

export const fetchCompaniesByCustomerId = createAsyncThunk(
    "service/fetchCompaniesByCustomerId",
    async (customerId: number) => {
        const { data } = await getServices().clients.customer.apiCustomerGetCompaniesByCustomerIdGet(customerId);
        return data;
    },
);

interface IFetchCustomerCompanyAdvisoryServiceData {
    customerId: number;
    companies: CustomerCompany[];
}

export const fetchCustomerCompanyAdvisoryServices = createAsyncThunk(
    "service/fetchAdvisoryServiceData",
    async ({ customerId, companies }: IFetchCustomerCompanyAdvisoryServiceData) => {
        const requestArray: { companyId: number; companyName: string; promises: AxiosPromise[] }[] = [];

        companies.forEach(({ companyId, companyName }) => {
            if (companyId && companyName) {
                requestArray.push({
                    companyId,
                    companyName,
                    promises: [
                        getServices().clients.configurationFields.configurationFieldsGet(customerId, companyId),
                        getServices().clients.onBoarding.apiOnboardingGetWorkOrderInfoGet(companyId, customerId),
                        getServices().clients.templates.templatesCustomerGet(customerId, companyId),
                    ],
                });
            }
        });

        const results: Array<
            Promise<{
                companyId: number;
                companyName: string;
                advisoryService: string;
            }>
        > = requestArray.map(async ({ companyId, companyName, promises }) => {
            return await Promise.all(promises)
                .then((results) => {
                    const configurationFields = results[0]
                        .data as MedHelpAbsenceReportingWebApiModelsConfigurationField[];
                    const workOrderConfiguration = results[1].data as WorkOrderConfiguration;
                    const templates = results[2].data as MedHelpAbsenceReportingDomainTemplate[];
                    const sickTemplateFields =
                        templates.find((template) => template.absenceReportType === "Sick")?.fields || [];
                    const careOfChildTemplate = templates.find(
                        (template) => template.absenceReportType === "CareOfChild",
                    );
                    const careOfChildTemplateFields = careOfChildTemplate?.fields || [];

                    return {
                        companyId,
                        companyName,
                        advisoryService: determineAdvisoryService({
                            configurationFields,
                            workOrderConfiguration,
                            sickTemplateFields,
                            careOfChildTemplateFields,
                            careOfChildTemplate,
                        }),
                    };
                })
                .catch(() => {
                    return {
                        companyId,
                        companyName,
                        advisoryService: "error",
                    };
                });
        });

        return await Promise.all(results);
    },
);

export const saveAbsenceTemplate = createAsyncThunk(
    "service/saveAbsenceTemplate",
    async (absenceReportType: AbsenceTemplateType, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const { customerId, companyId } = state.service;
        const language = state.service.organizationInfo.region?.language || "se";

        const postToBeModel = {
            absenceReportType,
            customerId,
            companyId,
            region: language,
        };

        const { data } = await getServices().clients.templates.templatesPost(postToBeModel);
        return {
            id: data,
            ...postToBeModel,
        };
    },
);

export const deleteAbsenceTemplate = createAsyncThunk("service/deleteAbsenceTemplate", async (templateId: string) => {
    await getServices().clients.templates.templatesTemplateIdDelete(templateId);
    return templateId;
});

export const saveCustomerData = createAsyncThunk(
    "service/saveCustomerData",
    async (customerInfo: CustomerOnboarding) => {
        await getServices().clients.customer.apiCustomerSaveCustomerInfoPost(customerInfo);
        return customerInfo;
    },
);

export const saveSocialInsuranceConfiguration = createAsyncThunk(
    "service/saveSocialInsuranceConfiguration",
    async (config: MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel) => {
        await getServices().clients.socialInsurance.socialInsuranceConfigurationPost(config);
        return config;
    },
);

export const saveMessengerConfigurationFieldPost = createAsyncThunk(
    "service/saveMessengerConfigurationFieldPost",
    async (configurationField: ConfigurationField) => {
        const { data } = await getServices().clients.configurationField.apiV1ConfigurationFieldPost(configurationField);
        return { ...configurationField, id: data };
    },
);

export const saveMessengerConfigurationFieldPut = createAsyncThunk(
    "service/saveMessengerConfigurationFieldPut",
    async (configurationField: ConfigurationField) => {
        await getServices().clients.configurationField.apiV1ConfigurationFieldPut(configurationField);
        return configurationField;
    },
);
