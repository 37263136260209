import { ReactNode, useState } from "react";
import { ReactComponent as AccordionArrow } from "icons/serviceAccordionArrow.svg";
import TertiaryButton from "components/TertiaryButton";
import PrimaryButton from "components/PrimaryButton";
import { useTranslation } from "react-i18next";

export interface IUserAccordionProps {
    heading: string;
    saveClicked?: () => void;
    onClose?: () => void;
    children: ReactNode;
    displayProps?: IUserAccordionDisplayProps[];
    disableButton?: boolean;
    hideSave?: boolean;
    isLoading?: boolean;
}

export interface IUserAccordionDisplayProps {
    description: string;
    value: string;
}

const UserAccordion = ({
    heading,
    saveClicked,
    onClose,
    children,
    displayProps,
    disableButton,
    hideSave,
    isLoading,
}: IUserAccordionProps) => {
    const { t } = useTranslation("userSettings");
    const [open, setOpen] = useState(false);
    const showProps = !open && !!displayProps;

    const handleOpenAndClose = () => {
        if (onClose && open) onClose();
        setOpen(!open);
    };

    return (
        <div
            className="w-full border-b border-grey-200 flex flex-col items-center relative bg-primaryBg"
            id="user-accordion"
        >
            <div className={`w-full flex flex-col ${open ? "pb-6" : ""}`}>
                <div onClick={handleOpenAndClose} className="flex p-6">
                    <div className="w-full flex flex-col">
                        <div className="w-full flex items-center">
                            <h3 className="break-all">{heading}</h3>
                            <button type="button" className="absolute right-0 p-6" onClick={handleOpenAndClose}>
                                {!open ? (
                                    <AccordionArrow className="fill-none w-4" />
                                ) : (
                                    <AccordionArrow className="fill-none w-4 transform rotate-180" />
                                )}
                            </button>
                        </div>
                        {showProps &&
                            displayProps.map((c) => (
                                <div
                                    key={c.description + c.value}
                                    className="mt-3 text-grey-700 flex flex-col md:flex-row"
                                >
                                    <p className="text-sm mr-1">{c.description}:</p>
                                    <p className="font-bold text-sm">{c.value}</p>
                                </div>
                            ))}
                    </div>
                </div>
                {open && (
                    <>
                        <div id="user-accordion-children" className="px-6 mb-10 md:mb-0">
                            {children}
                        </div>
                        <div className="flex justify-center md:absolute md:top-6 md:right-20">
                            <TertiaryButton
                                id="modalActionButton"
                                text={t("abort")}
                                className={`flex self-center ${hideSave ? "" : "mr-6"}`}
                                onClick={handleOpenAndClose}
                            />
                            {!hideSave && (
                                <PrimaryButton
                                    id="searchedUser"
                                    data-cy="accordion-save"
                                    text={t("save")}
                                    disabled={disableButton}
                                    onClick={saveClicked}
                                    className="flex self-center"
                                    isLoading={isLoading}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default UserAccordion;
