interface IProps {
    children: React.ReactNode;
    firstElement: boolean;
    lastElement: boolean;
}
const RowWrapper = ({ children, firstElement, lastElement }: IProps) => (
    <div className="min-h-[110px]">
        <div className={`pt-4 pb-2 h-full ${firstElement && "pl-6"} ${lastElement && "pr-6"}`}>{children}</div>
    </div>
);

export default RowWrapper;
