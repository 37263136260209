/* tslint:disable */
/* eslint-disable */
/**
 * MedHelp.Statistics.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Absence
 */
export interface Absence {
    /**
     * 
     * @type {string}
     * @memberof Absence
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Absence
     */
    'absenceReportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Absence
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Absence
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof Absence
     */
    'end'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Absence
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Absence
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Absence
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Absence
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof Absence
     */
    'updated'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AbsenceCause = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_13: 13
} as const;

export type AbsenceCause = typeof AbsenceCause[keyof typeof AbsenceCause];


/**
 * 
 * @export
 * @interface AbsenceInPercentPerCostCenterQuarterlyRequest
 */
export interface AbsenceInPercentPerCostCenterQuarterlyRequest {
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'toDate'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<StatisticsDepartmentInPercentModel>}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'departments'?: Array<StatisticsDepartmentInPercentModel> | null;
    /**
     * 
     * @type {Array<CompanyMeta>}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'companiesMeta'?: Array<CompanyMeta> | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'workingDays'?: number;
    /**
     * 
     * @type {Array<GenderEnum>}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'gender'?: Array<GenderEnum> | null;
    /**
     * 
     * @type {PeriodEnum}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'period'?: PeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'days'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'employmentGroup'?: string | null;
    /**
     * 
     * @type {AbsenceType}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'absenceType'?: AbsenceType;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'maxDepartmentBarsOnTopLevel'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'employmentGroupIdentifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'quarter'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AbsenceInPercentPerCostCenterQuarterlyRequest
     */
    'costCenters'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface AbsenceInPercentPerDepartmentQuarterlyRequest
 */
export interface AbsenceInPercentPerDepartmentQuarterlyRequest {
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'toDate'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<StatisticsDepartmentInPercentModel>}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'departments'?: Array<StatisticsDepartmentInPercentModel> | null;
    /**
     * 
     * @type {Array<CompanyMeta>}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'companiesMeta'?: Array<CompanyMeta> | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'workingDays'?: number;
    /**
     * 
     * @type {Array<GenderEnum>}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'gender'?: Array<GenderEnum> | null;
    /**
     * 
     * @type {PeriodEnum}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'period'?: PeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'days'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'employmentGroup'?: string | null;
    /**
     * 
     * @type {AbsenceType}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'absenceType'?: AbsenceType;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'maxDepartmentBarsOnTopLevel'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'employmentGroupIdentifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'quarter'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AbsenceInPercentPerDepartmentQuarterlyRequest
     */
    'costCenters'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface AbsenceInPercentPerDepartmentRequest
 */
export interface AbsenceInPercentPerDepartmentRequest {
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'toDate'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<StatisticsDepartmentInPercentModel>}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'departments'?: Array<StatisticsDepartmentInPercentModel> | null;
    /**
     * 
     * @type {Array<CompanyMeta>}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'companiesMeta'?: Array<CompanyMeta> | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'workingDays'?: number;
    /**
     * 
     * @type {Array<GenderEnum>}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'gender'?: Array<GenderEnum> | null;
    /**
     * 
     * @type {PeriodEnum}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'period'?: PeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'days'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'employmentGroup'?: string | null;
    /**
     * 
     * @type {AbsenceType}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'absenceType'?: AbsenceType;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'maxDepartmentBarsOnTopLevel'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'employmentGroupIdentifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'quarter'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AbsenceInPercentPerDepartmentRequest
     */
    'costCenters'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface AbsencePerCauseResponseItem
 */
export interface AbsencePerCauseResponseItem {
    /**
     * 
     * @type {number}
     * @memberof AbsencePerCauseResponseItem
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsencePerCauseResponseItem
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsencePerCauseResponseItem
     */
    'proportion'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AbsenceType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type AbsenceType = typeof AbsenceType[keyof typeof AbsenceType];


/**
 * 
 * @export
 * @interface AbsenceWorkRatioForecastResponse
 */
export interface AbsenceWorkRatioForecastResponse {
    /**
     * 
     * @type {number}
     * @memberof AbsenceWorkRatioForecastResponse
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsenceWorkRatioForecastResponse
     */
    'current'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsenceWorkRatioForecastResponse
     */
    'forecast'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceWorkRatioForecastResponse
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface AbsenceWorkRatioRequest
 */
export interface AbsenceWorkRatioRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof AbsenceWorkRatioRequest
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof AbsenceWorkRatioRequest
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbsenceWorkRatioRequest
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceWorkRatioRequest
     */
    'previousYear'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceWorkRatioRequest
     */
    'sameIndustry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceWorkRatioRequest
     */
    'sameSize'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AbsenceWorkRatioRequest
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AbsenceWorkRatioRequest
     */
    'absenceTypes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AbsenceWorkRatioRequest
     */
    'employmentGroups'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AbsenceWorkRatioResponse
 */
export interface AbsenceWorkRatioResponse {
    /**
     * 
     * @type {Array<AbsenceWorkRatioResponseItem>}
     * @memberof AbsenceWorkRatioResponse
     */
    'mySelection'?: Array<AbsenceWorkRatioResponseItem> | null;
    /**
     * 
     * @type {Array<AbsenceWorkRatioResponseItem>}
     * @memberof AbsenceWorkRatioResponse
     */
    'previousYear'?: Array<AbsenceWorkRatioResponseItem> | null;
    /**
     * 
     * @type {Array<AbsenceWorkRatioResponseItem>}
     * @memberof AbsenceWorkRatioResponse
     */
    'sameIndustry'?: Array<AbsenceWorkRatioResponseItem> | null;
    /**
     * 
     * @type {Array<AbsenceWorkRatioResponseItem>}
     * @memberof AbsenceWorkRatioResponse
     */
    'sameSize'?: Array<AbsenceWorkRatioResponseItem> | null;
}
/**
 * 
 * @export
 * @interface AbsenceWorkRatioResponseItem
 */
export interface AbsenceWorkRatioResponseItem {
    /**
     * 
     * @type {number}
     * @memberof AbsenceWorkRatioResponseItem
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsenceWorkRatioResponseItem
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsenceWorkRatioResponseItem
     */
    'ratio'?: number;
}
/**
 * 
 * @export
 * @interface AbsencesPerCauseResponse
 */
export interface AbsencesPerCauseResponse {
    /**
     * 
     * @type {Array<AbsencePerCauseResponseItem>}
     * @memberof AbsencesPerCauseResponse
     */
    'mySelection'?: Array<AbsencePerCauseResponseItem> | null;
    /**
     * 
     * @type {Array<AbsencePerCauseResponseItem>}
     * @memberof AbsencesPerCauseResponse
     */
    'previousYear'?: Array<AbsencePerCauseResponseItem> | null;
}
/**
 * 
 * @export
 * @interface AggregatedCompanyDailySize
 */
export interface AggregatedCompanyDailySize {
    /**
     * 
     * @type {Array<number>}
     * @memberof AggregatedCompanyDailySize
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof AggregatedCompanyDailySize
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedCompanyDailySize
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof AggregatedCompanyDailySize
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AggregatedCompanyDailySize
     */
    'employmentGroup'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyMeta
 */
export interface CompanyMeta {
    /**
     * 
     * @type {number}
     * @memberof CompanyMeta
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyMeta
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CompareOperation = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type CompareOperation = typeof CompareOperation[keyof typeof CompareOperation];


/**
 * 
 * @export
 * @interface DashboardMetricCauseFilter
 */
export interface DashboardMetricCauseFilter {
    /**
     * 
     * @type {Array<number>}
     * @memberof DashboardMetricCauseFilter
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardMetricCauseFilter
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardMetricCauseFilter
     */
    'absenceTypes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardMetricCauseFilter
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardMetricCauseFilter
     */
    'employmentGroupIdentifiers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardMetricCauseFilter
     */
    'absencePeriods'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardMetricCauseFilter
     */
    'timeInterval'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DashboardMetricCauseFilter
     */
    'cause'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EntityType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type EntityType = typeof EntityType[keyof typeof EntityType];


/**
 * 
 * @export
 * @enum {string}
 */

export const GenderEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type GenderEnum = typeof GenderEnum[keyof typeof GenderEnum];


/**
 * 
 * @export
 * @interface GenericAbsencePoint
 */
export interface GenericAbsencePoint {
    /**
     * 
     * @type {string}
     * @memberof GenericAbsencePoint
     */
    'label'?: string | null;
    /**
     * 
     * @type {Array<GenericAbsenceValues>}
     * @memberof GenericAbsencePoint
     */
    'values'?: Array<GenericAbsenceValues> | null;
    /**
     * 
     * @type {any}
     * @memberof GenericAbsencePoint
     */
    'point'?: any | null;
    /**
     * 
     * @type {number}
     * @memberof GenericAbsencePoint
     */
    'totalSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof GenericAbsencePoint
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenericAbsencePoint
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenericAbsencePoint
     */
    'reference'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GenericAbsencePoint
     */
    'orderBy'?: number;
}
/**
 * 
 * @export
 * @interface GenericAbsenceStatisticsItem
 */
export interface GenericAbsenceStatisticsItem {
    /**
     * 
     * @type {ReportType}
     * @memberof GenericAbsenceStatisticsItem
     */
    'type'?: ReportType;
    /**
     * 
     * @type {EntityType}
     * @memberof GenericAbsenceStatisticsItem
     */
    'entity'?: EntityType;
    /**
     * 
     * @type {string}
     * @memberof GenericAbsenceStatisticsItem
     */
    'label'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GenericAbsenceStatisticsItem
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericAbsenceStatisticsItem
     */
    'compareTotal'?: number;
    /**
     * 
     * @type {Array<GenericAbsencePoint>}
     * @memberof GenericAbsenceStatisticsItem
     */
    'points'?: Array<GenericAbsencePoint> | null;
}


/**
 * 
 * @export
 * @interface GenericAbsenceValues
 */
export interface GenericAbsenceValues {
    /**
     * 
     * @type {number}
     * @memberof GenericAbsenceValues
     */
    'value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GenericAbsenceValues
     */
    'label'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof GenericAbsenceValues
     */
    'employmentIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof GenericAbsenceValues
     */
    'gender'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PeriodEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type PeriodEnum = typeof PeriodEnum[keyof typeof PeriodEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const ReportType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ReportType = typeof ReportType[keyof typeof ReportType];


/**
 * 
 * @export
 * @interface StatisticsDepartmentInPercentModel
 */
export interface StatisticsDepartmentInPercentModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof StatisticsDepartmentInPercentModel
     */
    'id'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsDepartmentInPercentModel
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface StatisticsDepartmentModel
 */
export interface StatisticsDepartmentModel {
    /**
     * 
     * @type {string}
     * @memberof StatisticsDepartmentModel
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsDepartmentModel
     */
    'selected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatisticsDepartmentModel
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface StatisticsReportBody
 */
export interface StatisticsReportBody {
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'companySizeClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'companyIndustryCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof StatisticsReportBody
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<StatisticsDepartmentModel>}
     * @memberof StatisticsReportBody
     */
    'departments'?: Array<StatisticsDepartmentModel> | null;
    /**
     * 
     * @type {Array<CompanyMeta>}
     * @memberof StatisticsReportBody
     */
    'companiesMeta'?: Array<CompanyMeta> | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'employmentGroupIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'employmentGroupId'?: string | null;
    /**
     * 
     * @type {PeriodEnum}
     * @memberof StatisticsReportBody
     */
    'period'?: PeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'workingDays'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsReportBody
     */
    'includeHistoricalDepartments'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof StatisticsReportBody
     */
    'departmentsForAverigePercentPerCompanies'?: Array<number> | null;
    /**
     * 
     * @type {Array<StatisticsDepartmentInPercentModel>}
     * @memberof StatisticsReportBody
     */
    'departmentsForDepartmentInPercent'?: Array<StatisticsDepartmentInPercentModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsReportBody
     */
    'calculateOnCompanyLevel'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'maxDepartmentBarsOnTopLevel'?: number | null;
    /**
     * 
     * @type {StatisticsReportType}
     * @memberof StatisticsReportBody
     */
    'statisticsReportType'?: StatisticsReportType;
    /**
     * 
     * @type {Array<GenderEnum>}
     * @memberof StatisticsReportBody
     */
    'gender'?: Array<GenderEnum> | null;
    /**
     * 
     * @type {Array<AbsenceCause>}
     * @memberof StatisticsReportBody
     */
    'absenceCause'?: Array<AbsenceCause> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsReportBody
     */
    'areAllDepartmentsSelected'?: boolean;
    /**
     * 
     * @type {AbsenceType}
     * @memberof StatisticsReportBody
     */
    'absenceType'?: AbsenceType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StatisticsReportBody
     */
    'texts'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatisticsReportBody
     */
    'xAxisTitles'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'valueTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'quarter'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatisticsReportBody
     */
    'costCenters'?: Array<string> | null;
    /**
     * 
     * @type {Array<CompareOperation>}
     * @memberof StatisticsReportBody
     */
    'excelCompareOperation'?: Array<CompareOperation> | null;
    /**
     * 
     * @type {CompareOperation}
     * @memberof StatisticsReportBody
     */
    'compareOperation'?: CompareOperation;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const StatisticsReportType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23
} as const;

export type StatisticsReportType = typeof StatisticsReportType[keyof typeof StatisticsReportType];



/**
 * AbsenceWorkRatioApi - axios parameter creator
 * @export
 */
export const AbsenceWorkRatioApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AbsenceWorkRatioRequest} [absenceWorkRatioRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceWorkRatioPost: async (absenceWorkRatioRequest?: AbsenceWorkRatioRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/AbsenceWorkRatio`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceWorkRatioRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbsenceWorkRatioApi - functional programming interface
 * @export
 */
export const AbsenceWorkRatioApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AbsenceWorkRatioApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AbsenceWorkRatioRequest} [absenceWorkRatioRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceWorkRatioPost(absenceWorkRatioRequest?: AbsenceWorkRatioRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceWorkRatioResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceWorkRatioPost(absenceWorkRatioRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AbsenceWorkRatioApi - factory interface
 * @export
 */
export const AbsenceWorkRatioApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AbsenceWorkRatioApiFp(configuration)
    return {
        /**
         * 
         * @param {AbsenceWorkRatioRequest} [absenceWorkRatioRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceWorkRatioPost(absenceWorkRatioRequest?: AbsenceWorkRatioRequest, options?: any): AxiosPromise<AbsenceWorkRatioResponse> {
            return localVarFp.absenceWorkRatioPost(absenceWorkRatioRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AbsenceWorkRatioApi - object-oriented interface
 * @export
 * @class AbsenceWorkRatioApi
 * @extends {BaseAPI}
 */
export class AbsenceWorkRatioApi extends BaseAPI {
    /**
     * 
     * @param {AbsenceWorkRatioRequest} [absenceWorkRatioRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceWorkRatioApi
     */
    public absenceWorkRatioPost(absenceWorkRatioRequest?: AbsenceWorkRatioRequest, options?: AxiosRequestConfig) {
        return AbsenceWorkRatioApiFp(this.configuration).absenceWorkRatioPost(absenceWorkRatioRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyAbsenceApi - axios parameter creator
 * @export
 */
export const CompanyAbsenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAbsenceGet: async (companyId?: number, from?: string, to?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CompanyAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString() :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyAbsenceApi - functional programming interface
 * @export
 */
export const CompanyAbsenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyAbsenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyAbsenceGet(companyId?: number, from?: string, to?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Absence>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyAbsenceGet(companyId, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyAbsenceApi - factory interface
 * @export
 */
export const CompanyAbsenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyAbsenceApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyAbsenceGet(companyId?: number, from?: string, to?: string, options?: any): AxiosPromise<Array<Absence>> {
            return localVarFp.companyAbsenceGet(companyId, from, to, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyAbsenceApi - object-oriented interface
 * @export
 * @class CompanyAbsenceApi
 * @extends {BaseAPI}
 */
export class CompanyAbsenceApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [from] 
     * @param {string} [to] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyAbsenceApi
     */
    public companyAbsenceGet(companyId?: number, from?: string, to?: string, options?: AxiosRequestConfig) {
        return CompanyAbsenceApiFp(this.configuration).companyAbsenceGet(companyId, from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyInfoApi - axios parameter creator
 * @export
 */
export const CompanyInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInfoAggregatedCompanyDailySizeGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CompanyInfo/AggregatedCompanyDailySize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AggregatedCompanyDailySize} [aggregatedCompanyDailySize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInfoAggregatedCompanyDailySizePost: async (aggregatedCompanyDailySize?: AggregatedCompanyDailySize, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/CompanyInfo/AggregatedCompanyDailySize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aggregatedCompanyDailySize, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyInfoApi - functional programming interface
 * @export
 */
export const CompanyInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInfoAggregatedCompanyDailySizeGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInfoAggregatedCompanyDailySizeGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AggregatedCompanyDailySize} [aggregatedCompanyDailySize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInfoAggregatedCompanyDailySizePost(aggregatedCompanyDailySize?: AggregatedCompanyDailySize, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInfoAggregatedCompanyDailySizePost(aggregatedCompanyDailySize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyInfoApi - factory interface
 * @export
 */
export const CompanyInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyInfoApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInfoAggregatedCompanyDailySizeGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.companyInfoAggregatedCompanyDailySizeGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AggregatedCompanyDailySize} [aggregatedCompanyDailySize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInfoAggregatedCompanyDailySizePost(aggregatedCompanyDailySize?: AggregatedCompanyDailySize, options?: any): AxiosPromise<void> {
            return localVarFp.companyInfoAggregatedCompanyDailySizePost(aggregatedCompanyDailySize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyInfoApi - object-oriented interface
 * @export
 * @class CompanyInfoApi
 * @extends {BaseAPI}
 */
export class CompanyInfoApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyInfoApi
     */
    public companyInfoAggregatedCompanyDailySizeGet(companyId?: number, options?: AxiosRequestConfig) {
        return CompanyInfoApiFp(this.configuration).companyInfoAggregatedCompanyDailySizeGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AggregatedCompanyDailySize} [aggregatedCompanyDailySize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyInfoApi
     */
    public companyInfoAggregatedCompanyDailySizePost(aggregatedCompanyDailySize?: AggregatedCompanyDailySize, options?: AxiosRequestConfig) {
        return CompanyInfoApiFp(this.configuration).companyInfoAggregatedCompanyDailySizePost(aggregatedCompanyDailySize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardMetricApi - axios parameter creator
 * @export
 */
export const DashboardMetricApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DashboardMetricCauseFilter} [dashboardMetricCauseFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricAbsencesPerCausePost: async (dashboardMetricCauseFilter?: DashboardMetricCauseFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/AbsencesPerCause`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dashboardMetricCauseFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricCausesByRegionPost: async (region?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/CausesByRegion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardMetricApi - functional programming interface
 * @export
 */
export const DashboardMetricApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardMetricApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DashboardMetricCauseFilter} [dashboardMetricCauseFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricAbsencesPerCausePost(dashboardMetricCauseFilter?: DashboardMetricCauseFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsencesPerCauseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricAbsencesPerCausePost(dashboardMetricCauseFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricCausesByRegionPost(region?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricCausesByRegionPost(region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardMetricApi - factory interface
 * @export
 */
export const DashboardMetricApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardMetricApiFp(configuration)
    return {
        /**
         * 
         * @param {DashboardMetricCauseFilter} [dashboardMetricCauseFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricAbsencesPerCausePost(dashboardMetricCauseFilter?: DashboardMetricCauseFilter, options?: any): AxiosPromise<AbsencesPerCauseResponse> {
            return localVarFp.dashboardMetricAbsencesPerCausePost(dashboardMetricCauseFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricCausesByRegionPost(region?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.dashboardMetricCausesByRegionPost(region, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardMetricApi - object-oriented interface
 * @export
 * @class DashboardMetricApi
 * @extends {BaseAPI}
 */
export class DashboardMetricApi extends BaseAPI {
    /**
     * 
     * @param {DashboardMetricCauseFilter} [dashboardMetricCauseFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricAbsencesPerCausePost(dashboardMetricCauseFilter?: DashboardMetricCauseFilter, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricAbsencesPerCausePost(dashboardMetricCauseFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [region] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricCausesByRegionPost(region?: string, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricCausesByRegionPost(region, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExcelApi - axios parameter creator
 * @export
 */
export const ExcelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelStatisticsPost: async (statisticsReportBody?: StatisticsReportBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/Statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statisticsReportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExcelApi - functional programming interface
 * @export
 */
export const ExcelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExcelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelStatisticsPost(statisticsReportBody?: StatisticsReportBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelStatisticsPost(statisticsReportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExcelApi - factory interface
 * @export
 */
export const ExcelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExcelApiFp(configuration)
    return {
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelStatisticsPost(statisticsReportBody?: StatisticsReportBody, options?: any): AxiosPromise<void> {
            return localVarFp.excelStatisticsPost(statisticsReportBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExcelApi - object-oriented interface
 * @export
 * @class ExcelApi
 * @extends {BaseAPI}
 */
export class ExcelApi extends BaseAPI {
    /**
     * 
     * @param {StatisticsReportBody} [statisticsReportBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelStatisticsPost(statisticsReportBody?: StatisticsReportBody, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelStatisticsPost(statisticsReportBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ForecastApi - axios parameter creator
 * @export
 */
export const ForecastApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastGet: async (companyId?: number, date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Forecast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString() :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForecastApi - functional programming interface
 * @export
 */
export const ForecastApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForecastApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastGet(companyId?: number, date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbsenceWorkRatioForecastResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastGet(companyId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForecastApi - factory interface
 * @export
 */
export const ForecastApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForecastApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastGet(companyId?: number, date?: string, options?: any): AxiosPromise<AbsenceWorkRatioForecastResponse> {
            return localVarFp.forecastGet(companyId, date, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForecastApi - object-oriented interface
 * @export
 * @class ForecastApi
 * @extends {BaseAPI}
 */
export class ForecastApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [date] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastGet(companyId?: number, date?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastGet(companyId, date, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet(options?: any): AxiosPromise<void> {
            return localVarFp.pingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingGet(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).pingGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AbsenceInPercentPerDepartmentRequest} [absenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsencesPercentPerDepartmentsPost: async (absenceInPercentPerDepartmentRequest?: AbsenceInPercentPerDepartmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/AbsencesPercentPerDepartments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceInPercentPerDepartmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetStatisticsReportPost: async (statisticsReportBody?: StatisticsReportBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/GetStatisticsReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statisticsReportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceInPercentPerCostCenterQuarterlyRequest} [absenceInPercentPerCostCenterQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyAbsencesPerCostCenterPost: async (absenceInPercentPerCostCenterQuarterlyRequest?: AbsenceInPercentPerCostCenterQuarterlyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/QuarterlyAbsencesPerCostCenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceInPercentPerCostCenterQuarterlyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceInPercentPerDepartmentQuarterlyRequest} [absenceInPercentPerDepartmentQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyAbsencesPerDepartmentPost: async (absenceInPercentPerDepartmentQuarterlyRequest?: AbsenceInPercentPerDepartmentQuarterlyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/QuarterlyAbsencesPerDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceInPercentPerDepartmentQuarterlyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceInPercentPerCostCenterQuarterlyRequest} [absenceInPercentPerCostCenterQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyHealthIndexPerCostCenterPost: async (absenceInPercentPerCostCenterQuarterlyRequest?: AbsenceInPercentPerCostCenterQuarterlyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/QuarterlyHealthIndexPerCostCenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceInPercentPerCostCenterQuarterlyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceInPercentPerDepartmentQuarterlyRequest} [absenceInPercentPerDepartmentQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyHealthIndexPerDepartmentPost: async (absenceInPercentPerDepartmentQuarterlyRequest?: AbsenceInPercentPerDepartmentQuarterlyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/QuarterlyHealthIndexPerDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceInPercentPerDepartmentQuarterlyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AbsenceInPercentPerDepartmentRequest} [absenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsAbsencesPercentPerDepartmentsPost(absenceInPercentPerDepartmentRequest?: AbsenceInPercentPerDepartmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericAbsenceStatisticsItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsAbsencesPercentPerDepartmentsPost(absenceInPercentPerDepartmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsGetStatisticsReportPost(statisticsReportBody?: StatisticsReportBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericAbsenceStatisticsItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsGetStatisticsReportPost(statisticsReportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceInPercentPerCostCenterQuarterlyRequest} [absenceInPercentPerCostCenterQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsQuarterlyAbsencesPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest?: AbsenceInPercentPerCostCenterQuarterlyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsQuarterlyAbsencesPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceInPercentPerDepartmentQuarterlyRequest} [absenceInPercentPerDepartmentQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsQuarterlyAbsencesPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest?: AbsenceInPercentPerDepartmentQuarterlyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsQuarterlyAbsencesPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceInPercentPerCostCenterQuarterlyRequest} [absenceInPercentPerCostCenterQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsQuarterlyHealthIndexPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest?: AbsenceInPercentPerCostCenterQuarterlyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsQuarterlyHealthIndexPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceInPercentPerDepartmentQuarterlyRequest} [absenceInPercentPerDepartmentQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsQuarterlyHealthIndexPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest?: AbsenceInPercentPerDepartmentQuarterlyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsQuarterlyHealthIndexPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {AbsenceInPercentPerDepartmentRequest} [absenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsencesPercentPerDepartmentsPost(absenceInPercentPerDepartmentRequest?: AbsenceInPercentPerDepartmentRequest, options?: any): AxiosPromise<Array<GenericAbsenceStatisticsItem>> {
            return localVarFp.statisticsAbsencesPercentPerDepartmentsPost(absenceInPercentPerDepartmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetStatisticsReportPost(statisticsReportBody?: StatisticsReportBody, options?: any): AxiosPromise<Array<GenericAbsenceStatisticsItem>> {
            return localVarFp.statisticsGetStatisticsReportPost(statisticsReportBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceInPercentPerCostCenterQuarterlyRequest} [absenceInPercentPerCostCenterQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyAbsencesPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest?: AbsenceInPercentPerCostCenterQuarterlyRequest, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsQuarterlyAbsencesPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceInPercentPerDepartmentQuarterlyRequest} [absenceInPercentPerDepartmentQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyAbsencesPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest?: AbsenceInPercentPerDepartmentQuarterlyRequest, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsQuarterlyAbsencesPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceInPercentPerCostCenterQuarterlyRequest} [absenceInPercentPerCostCenterQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyHealthIndexPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest?: AbsenceInPercentPerCostCenterQuarterlyRequest, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsQuarterlyHealthIndexPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceInPercentPerDepartmentQuarterlyRequest} [absenceInPercentPerDepartmentQuarterlyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyHealthIndexPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest?: AbsenceInPercentPerDepartmentQuarterlyRequest, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsQuarterlyHealthIndexPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @param {AbsenceInPercentPerDepartmentRequest} [absenceInPercentPerDepartmentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsAbsencesPercentPerDepartmentsPost(absenceInPercentPerDepartmentRequest?: AbsenceInPercentPerDepartmentRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsAbsencesPercentPerDepartmentsPost(absenceInPercentPerDepartmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsReportBody} [statisticsReportBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsGetStatisticsReportPost(statisticsReportBody?: StatisticsReportBody, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsGetStatisticsReportPost(statisticsReportBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceInPercentPerCostCenterQuarterlyRequest} [absenceInPercentPerCostCenterQuarterlyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsQuarterlyAbsencesPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest?: AbsenceInPercentPerCostCenterQuarterlyRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsQuarterlyAbsencesPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceInPercentPerDepartmentQuarterlyRequest} [absenceInPercentPerDepartmentQuarterlyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsQuarterlyAbsencesPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest?: AbsenceInPercentPerDepartmentQuarterlyRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsQuarterlyAbsencesPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceInPercentPerCostCenterQuarterlyRequest} [absenceInPercentPerCostCenterQuarterlyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsQuarterlyHealthIndexPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest?: AbsenceInPercentPerCostCenterQuarterlyRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsQuarterlyHealthIndexPerCostCenterPost(absenceInPercentPerCostCenterQuarterlyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceInPercentPerDepartmentQuarterlyRequest} [absenceInPercentPerDepartmentQuarterlyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsQuarterlyHealthIndexPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest?: AbsenceInPercentPerDepartmentQuarterlyRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsQuarterlyHealthIndexPerDepartmentPost(absenceInPercentPerDepartmentQuarterlyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


