import { MiniModal } from "@medhelp/ui/Modals";
import PrimaryButton from "components/PrimaryButton";
import { RadioButton } from "pages/Administration/components";
import {
    EmployeeHistory,
    ICompanyErasureConfig,
    IErasureFinishedSettingsStateProps,
    automaticErasureOptions,
    employeeHistory,
} from "pages/Administration/types";
import { getDisplayValue } from "pages/Administration/utils";
import { ChangeEvent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IErasureSettingsPageProps extends IErasureFinishedSettingsStateProps {
    onPropChange: (propKey: keyof ICompanyErasureConfig, value: number | null | boolean) => void;
    setActiveInput: (activeInput?: EmployeeHistory) => void;
    isDirty: boolean;
    onSaveClicked: () => void;
}

export const ErasureSettings = ({
    companyErasureConfig,
    onPropChange,
    isDirty,
    onSaveClicked,
    activeInput,
    setActiveInput,
    isLoading,
}: IErasureSettingsPageProps) => {
    const { t } = useTranslation("administration");

    // Handle backspace press on input fields to clear the value of the input field if it is a single digit number
    const handleUserKeyPress = useCallback(
        (event: KeyboardEvent) => {
            const { key } = event;
            if (!activeInput) return;
            if (key !== "Backspace") return;

            // typescript is not smart enough to know that activeInput is not null here
            if (companyErasureConfig[activeInput] === null) return;
            if (companyErasureConfig[activeInput]! >= 10) return;
            onPropChange(activeInput, null);
        },
        [activeInput, onPropChange, companyErasureConfig],
    );

    // Register the event listener for keydown, this is to handle backspace press on input fields
    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    // Handle the internal change of the input fields, this is to handle the leading zero of the input fields
    const internalPropChange = (propKey: keyof ICompanyErasureConfig, e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.startsWith("0") && e.target.value.length == 2) e.target.value = e.target.value.slice(1);
        onPropChange(propKey, Number(e.target.value));
    };

    return (
        <div>
            <h2 className="border-b pb-4">{t("erasureSettings")}</h2>
            <div className="p-6 border-b flex flex-col">
                <div className="flex items-center">
                    <RadioButton
                        checked={!companyErasureConfig.automatical}
                        setChecked={() => onPropChange("automatical", !companyErasureConfig.automatical)}
                    />
                    <p className="mr-2">{t("manualErasure")}</p>

                    <MiniModal heading={t("manualErasure")} size={18}>
                        <p className="opacity-50">{t("manualErasureInfo")}</p>
                    </MiniModal>
                </div>
                <div className="py-2 flex items-center">
                    <RadioButton
                        checked={companyErasureConfig.automatical}
                        setChecked={() => onPropChange("automatical", !companyErasureConfig.automatical)}
                    />
                    <p className="mr-2">{t("automaticErasure")}</p>
                    <MiniModal heading={t("automaticErasure")} size={18}>
                        <p className="opacity-50">{t("automaticErasureInfo")}</p>
                    </MiniModal>
                </div>
            </div>
            {companyErasureConfig.automatical && (
                <div className="p-6 border-b">
                    <h3 className="mb-6">{t("automaticErasure")}</h3>
                    <p>{t("erasureListSettingInfo")}</p>

                    <div className="mt-6 flex">
                        <div className="mr-6 pt-4">
                            {employeeHistory.map((empHistory) => (
                                <div className="mt-4" key={empHistory}>
                                    <p>{t(empHistory)}</p>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-grow">
                            <div className="w-full flex">
                                {automaticErasureOptions.map((option) => (
                                    <div key={option} className="w-26 flex flex-col">
                                        <p className="mb-2">
                                            {option
                                                ? t("noMonths", {
                                                      months: option,
                                                  })
                                                : t("noErasure")}
                                        </p>
                                        {employeeHistory.map((empHistory) => (
                                            <div key={"rBtn-" + option + empHistory} className="mb-4">
                                                <RadioButton
                                                    text=""
                                                    checked={companyErasureConfig[empHistory] === option}
                                                    setChecked={() => onPropChange(empHistory, option)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                                <div>
                                    <p className="mb-2">{t("custom")}</p>
                                    {employeeHistory.map((empHistory) => (
                                        <div key={"input-" + empHistory} className="mb-2">
                                            <input
                                                type="number"
                                                className="w-full h-8 pl-2 border border-mhdarkgreen-original text-black cursor-text whitespace-pre-wrap bg-pageBg xxs:w-16"
                                                value={getDisplayValue(
                                                    companyErasureConfig[empHistory],
                                                    empHistory === activeInput,
                                                )}
                                                onChange={(e) => internalPropChange(empHistory, e)}
                                                onFocus={() => setActiveInput(empHistory)}
                                                onBlur={() => setActiveInput()}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <PrimaryButton
                id="erasure-save-button"
                text={t("save")}
                onClick={onSaveClicked}
                disabled={!isDirty}
                isLoading={isLoading}
                className="m-6"
            />
        </div>
    );
};

export default ErasureSettings;
