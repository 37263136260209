import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServices } from "api/services";
import { getIdFromSelected } from "domain/ogranization/dynamicData";
import { selectors } from "pages/FollowUp/redux";
import { getUserPrimaryCompany } from "store/userSelectors";
import { RootState } from "store";
import { getActiveSearchCompanyId } from "store/searchSelectors";

export const searchEmployee = createAsyncThunk("searchEmployee", async (query: string, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const companies = getUserPrimaryCompany(state);
    const powerbarSearch = getActiveSearchCompanyId(state);
    const res = await getServices().clients.organization.apiOrganizationSearchEmploymentsGet(
        query,
        true,
        powerbarSearch || companies?.id,
    );
    return res.data;
});
interface IGetEmploymentByCompanyIdsOrDepartments {
    pageNumber?: number;
}
export const getEmploymentByCompanyIdsOrDepartments = createAsyncThunk(
    "getEmploymentByCompanyIdsOrDepartments",
    async ({ pageNumber = 1 }: IGetEmploymentByCompanyIdsOrDepartments, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const companies = selectors.getSearchCompanies(state);
        const departments = selectors.getSearchDepartments(state);
        const topDepartment = selectors.getTopDepartment(state);
        const allDepartments = topDepartment
            ? [...getIdFromSelected(departments), topDepartment?.id]
            : getIdFromSelected(departments);

        const activeCompanyId = getActiveSearchCompanyId(state);
        const companyIds = activeCompanyId ? [activeCompanyId] : getIdFromSelected(companies || []).map(Number);
        const res =
            await getServices().clients.organization.apiOrganizationGetEmploymentsByCompanyIdsOrDepartmentIdsPost({
                companyIds,
                departmentIds: allDepartments.map(Number),
                pageNumber,
                pageSize: 50,
            });

        return res.data;
    },
);
export {};
