import Icon from "components/Icon";
import { CSVLink } from "react-csv";
import { useDownloadFileQuery } from "store/rtk-apis/storage/storageBaseApi";

interface DownloadCsvProps {
    id: string;
    filename: string;
}

const DownloadCsv = ({ id, filename }: DownloadCsvProps) => {
    const { data, error } = useDownloadFileQuery(id);

    if (error || !data) return <></>;

    return (
        <CSVLink data={data} filename={filename} className="rounded-full hover:opacity-50">
            <Icon icon="download" size={40} />
        </CSVLink>
    );
};

export default DownloadCsv;
