import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getDateDisplayValue } from "utils/date";
import { IFileData } from "../../redux";
import { certificates as cUtils } from "../../utils";

interface IProps {
    metaData: IFileData[];
}

const CertificatesMetadata = ({ metaData }: IProps) => {
    const { t } = useTranslation();
    const getMetadataByKey = React.useCallback(
        (key: cUtils.MetadataKeys, value: IFileData[] | undefined) => cUtils.getMetadataValueByKey(key, value),
        [],
    );

    const from = useMemo(() => getMetadataByKey(cUtils.MetadataKeys.from, metaData), [getMetadataByKey, metaData]);
    const to = useMemo(() => getMetadataByKey(cUtils.MetadataKeys.to, metaData), [getMetadataByKey, metaData]);

    // Add precentage to app degree certificates
    const getDegreeMatch = React.useCallback(
        (degree: string | undefined) => {
            if (degree && degree.indexOf("%") === -1 && degree !== t("absenceVaryOption")) {
                if (degree === "0") {
                    return t("absenceVaryOption");
                } else {
                    return degree.concat("%");
                }
            }
            return degree;
        },
        [t],
    );
    return (
        <div className="flex flex-col sm:flex-row sm:gap-16">
            <div className="flex gap-1 sm:flex-col sm:gap-0">
                <p className="text-grey-700">{t("startDate")}:</p>
                <p className="font-bold text-grey-700">{getDateDisplayValue(from, "d MMMM yyyy")}</p>
            </div>
            <div className="flex gap-1 sm:flex-col sm:gap-0">
                <p className="text-grey-700">{t("endDate")}:</p>
                <p className="font-bold text-grey-700">{getDateDisplayValue(to, "d MMMM yyyy")}</p>
            </div>
            <div className="flex gap-1 sm:flex-col sm:gap-0">
                <p className="text-grey-700">{t("absenceDegree")}:</p>
                <p className="font-bold text-grey-700">
                    {getDegreeMatch(getMetadataByKey(cUtils.MetadataKeys.degree, metaData))}
                </p>
            </div>
        </div>
    );
};

export default CertificatesMetadata;
