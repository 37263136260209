import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { saveHealthCase as saveHealthCaseAction } from "../followUpAsyncActions";
import { IState, Status } from "..";
import { newDateString } from "utils/date";

const saveHealthCase = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(saveHealthCaseAction.fulfilled, (state, action) => {
            if (action.payload) {
                state.healthCases.push({
                    ...action.payload,
                    start: action.payload.startDate || newDateString(),
                    status: "ongoing",
                    responsible: action.payload.responsibleName,
                    created: newDateString(),
                    healthCaseEvents: [
                        {
                            type: action.payload.type,
                            operation: "created",
                            userAccountId: action.payload.responsibleUserAccountId,
                        },
                    ],
                    healthCaseActivities: [],
                    healthCaseDocuments: [],
                    healthCaseNotifications: [],
                    healthCaseSubStatus: {
                        activities: Status.PENDING,
                        documents: Status.PENDING,
                        notifications: Status.PENDING,
                        pdfDocumentTemplate: Status.PENDING,
                        pdfDocumentBasedOnTemplateSaved: Status.PENDING,
                    },
                    healthCaseStatus: Status.OK,
                    expanded: true,
                });
            }
            state.status.healthCases = Status.OK;
        })
        .addCase(saveHealthCaseAction.pending, (state) => {
            state.status.healthCases = Status.PENDING;
        })
        .addCase(saveHealthCaseAction.rejected, (state) => {
            state.status.healthCases = Status.ERROR;
        });

export default saveHealthCase;
