/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IState } from "../organizationSlice";
import { getEmploymentByCompanyIdsOrDepartments as action } from "../organizationAsyncActions";
import { isEmpty, isNumber, keys, reduce, times } from "lodash/fp";
import { SEARCH_PAGE_SIZE } from "pages/FollowUp/utils";

const getEmploymentByCompanyIdsOrDepartments = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(action.fulfilled, (state, action) => {
            const { pageNumber, pageSize, totalCount } = action.payload;

            if (!pageNumber) {
                state.empoloyee.pageNumber.employee = 1;
            }
            if (action.payload) {
                if (pageNumber !== undefined && isNumber(totalCount) && isNumber(pageSize)) {
                    // reset data
                    if (totalCount === 0) {
                        state.empoloyee.search.employee = {};
                        state.empoloyee.searchHasMore.employee = {};
                        state.empoloyee.searchStatus.employee = "empty";
                        return;
                    }
                    state.empoloyee.search.employee[pageNumber] = action.payload;
                    const numberOfPages = Math.ceil(totalCount / SEARCH_PAGE_SIZE);
                    times((value) => {
                        const hasValue = state.empoloyee.search.employee[value + 1];
                        if (isEmpty(hasValue)) {
                            state.empoloyee.searchHasMore.employee[value + 1] = false;
                        }
                    }, numberOfPages);
                    state.empoloyee.searchHasMore.employee[pageNumber] = true;
                    let newSearch = reduce(
                        (prev, current) => {
                            const currentAsInt = parseInt(current);
                            if (pageNumber >= currentAsInt) {
                                return {
                                    ...prev,
                                    [current]: state.empoloyee.search.employee[currentAsInt],
                                };
                            }
                            return prev;
                        },
                        {},
                        keys(state.empoloyee.search.employee),
                    );
                    let searchHasMore = reduce(
                        (prev, current) => {
                            const currentAsInt = parseInt(current);
                            if (pageNumber >= currentAsInt) {
                                return {
                                    ...prev,
                                    [current]: state.empoloyee.searchHasMore.employee[currentAsInt],
                                };
                            }
                            return prev;
                        },
                        {},
                        keys(state.empoloyee.searchHasMore.employee),
                    );
                    Object.assign(state.empoloyee.search.employee, newSearch);
                    state.empoloyee.searchHasMore.employee = searchHasMore;
                    state.empoloyee.searchStatus.employee = "ok";
                }
            }
        })
        .addCase(action.pending, (state) => {
            state.empoloyee.searchStatus.employee = "pending";
        })
        .addCase(action.rejected, (state) => {
            state.empoloyee.searchStatus.employee = "error";
        });

export default getEmploymentByCompanyIdsOrDepartments;
