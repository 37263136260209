import { useBreakpoints } from "hooks";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SEARCH_PAGE_SIZE, getColumns } from "../../utils";
import { selectors, thunkActions, actions } from "../../redux";
import PaginatorTableFooter from "./PaginatorTableFooter";
import { useAppDispatch, useAppSelector } from "store/hooks";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const breakpoints = useBreakpoints();

    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const searchStatus = useAppSelector(selectors.getSearchStatus);
    const pageNumber = useAppSelector(selectors.getPaginatorPageNumber(currentSearch));
    const currentSearchPageNumber = useAppSelector(selectors.getSearchPageNumber(currentSearch));
    const searchCount = useAppSelector(selectors.getSearchCount(currentSearch, currentSearchPageNumber));

    const checkNextPage = useAppSelector(selectors.getSearchHasMoreByPage(currentSearch));
    const requestNextPage = useCallback(
        (value: number) => {
            if (currentSearch && searchStatus[currentSearch] === "ok") {
                const searchPageNumber = Math.ceil((value * 10) / SEARCH_PAGE_SIZE);
                const hasNextPage = checkNextPage(searchPageNumber);
                if (!hasNextPage) {
                    dispatch(
                        thunkActions.search({
                            type: currentSearch,
                            requestType: "search",
                            pageNumber: searchPageNumber,
                            workBookTitle: t(`excelTitle.${currentSearch}`),
                            nextPage: true,
                        }),
                    );
                }
            }
        },
        [checkNextPage, currentSearch, dispatch, searchStatus, t],
    );

    const setCurrentPageNumber = useCallback(
        (value: number) => {
            requestNextPage(value);
            dispatch(actions.updatePaginatorPageNumber(value));
            dispatch(actions.updateSearchPageNumber(Math.ceil((value * 10) / SEARCH_PAGE_SIZE)));
        },
        [dispatch, requestNextPage],
    );

    const onClick = useCallback(
        (value: number) => {
            setCurrentPageNumber(value);
        },
        [setCurrentPageNumber],
    );

    const Columns = useMemo(() => {
        return !breakpoints.isMediumScreen ? getColumns(currentSearch).length : 1;
    }, [breakpoints.isMediumScreen, currentSearch]);

    const firstElement = (pageNumber - 1) * 10;
    const lastPage = firstElement + 10;
    if (!searchCount) return <></>;
    return (
        <PaginatorTableFooter
            onBackButtonClick={onClick}
            onForwardButtonClick={onClick}
            onPageClick={onClick}
            activePage={pageNumber}
            pages={Math.ceil(searchCount / 10)}
            columns={Columns}
            leftText={t("search.numberOfSearchReports", {
                showing: `${firstElement + 1}-${lastPage > searchCount ? searchCount : lastPage}`,
                amount: searchCount,
            })}
        />
    );
}
