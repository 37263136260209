import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ServiceAccordion } from "../ServiceAccordion";
import { CustomerCompany } from "swagger/businessupport";
import { deactivateCompanies } from "pages/service/redux/serviceAsyncActions";
import { getCompaniesByCustomerId } from "pages/service/redux/serviceSelectors";

const DeactivateCompany = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const companies = useAppSelector(getCompaniesByCustomerId);
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        reset,
        register,
        formState: { isDirty },
    } = useForm({
        defaultValues: { companies },
    });

    useEffect(() => {
        reset({ companies });
    }, [companies, reset]);

    const onSubmit = (data: { companies: CustomerCompany[] }) => {
        setIsLoading(true);
        dispatch(deactivateCompanies(data.companies))
            .then(unwrapResult)
            .then(() => {
                setIsLoading(false);
                toast(t("saved"));
            })
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                reset({ companies });
                setIsLoading(false);
            });
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("deactivateCompany")}
                reset={() => reset({ companies })}
                disabled={!isDirty}
                isLoading={isLoading}
            >
                <div className="py-10">
                    <div className="border-b pb-2 hidden md:flex">
                        <div className="w-2/5">{t("company")}</div>
                        <div className="w-1/5">{t("customerId")}</div>
                        <div className="w-1/5">{t("companyId")}</div>
                        <div className="w-1/5">{t("deactivationDate")}</div>
                    </div>
                    {companies.map((company, index) => (
                        <div key={company.id} className="flex py-4 border-b flex-col md:flex-row">
                            <div className="md:w-2/5">{company.companyName}</div>
                            <div className="md:w-1/5 hidden md:flex">{company.customerId}</div>
                            <div className="md:w-1/5 hidden md:flex">{company.companyId}</div>
                            <div className="md:w-1/5">
                                <input
                                    className="font-bold bg-transparent"
                                    type="date"
                                    {...register(`companies.${index}.deactivationDate`)}
                                    min={new Date().toISOString().split("T")[0]}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </ServiceAccordion>
        </form>
    );
};

export default DeactivateCompany;
