import { CardWithTitle } from "@medhelp/ui";

interface IItem {
    header: string;
    body: string;
    key: string;
    Icon?: React.ReactElement;
}
interface IProps {
    items: IItem[];
    title: string;
}

const AbsenceHistoryStatisticsMobile = ({ items, title }: IProps) => {
    return (
        <CardWithTitle title={title} className="px-0 pl-4 pr-4">
            <div className="">
                {items.map(({ header, body }) => (
                    <div key={header}>
                        <div className="flex justify-between p-4">
                            <div className="flex-1">{header}</div>
                            <div className="flex-1/2">{body}</div>
                        </div>
                        <hr />
                    </div>
                ))}
            </div>
        </CardWithTitle>
    );
};

export default AbsenceHistoryStatisticsMobile;
