import { Heading } from "@medhelp/ui";
import PhoneNumberComponent from "components/PhoneNumberComponent";
import { useTranslation } from "react-i18next";

export interface IPhoneNumberProps {
    heading?: string;
    infoText?: string;
    value: string;
    onChange: (value: string) => void;
    validateFields?: boolean;
    isValid?: (isValid: boolean) => void;
    headingClassName?: string;
    isMandatory?: boolean;
}
const PhoneNumber = ({
    heading,
    infoText,
    value,
    onChange,
    validateFields,
    isValid,
    headingClassName,
    isMandatory,
}: IPhoneNumberProps) => {
    const { t } = useTranslation("administration");
    return (
        <div className="flex flex-col">
            {!!heading && <Heading heading={heading} infoText={infoText} className={headingClassName} />}
            <PhoneNumberComponent
                countryHeader={t("selectCountryForNumber")}
                phoneNumberHeader={t("phoneNumber")}
                value={value}
                onChange={onChange}
                validateFields={validateFields}
                className="mb-2"
                isValid={isValid}
                allowEmptyNumber
                showMandatory={isMandatory}
            />
        </div>
    );
};

export default PhoneNumber;
