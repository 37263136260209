import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { getOngoingCases, getHealthCasesStatus } from "../../../redux/followUpSelectors";
import { Status } from "pages/FollowUp/redux/followUpStateTypes";
import { isEmpty } from "lodash/fp";
import { Error } from "@medhelp/ui";
import { OngoingCaseItem } from "..";
import OngoingCasesLoading from "./OngoingCasesLoading";

const OngoingCases = () => {
    const { t } = useTranslation("followup");
    const ongoingCases = useAppSelector(getOngoingCases);
    const healthCasesStatus = useAppSelector(getHealthCasesStatus);

    if (healthCasesStatus === Status.PENDING) {
        return <OngoingCasesLoading />;
    }

    if (healthCasesStatus === Status.ERROR) {
        return <Error text={t("couldNotFetchContent")} />;
    }

    return isEmpty(ongoingCases) ? null : (
        <div>
            {ongoingCases.map((healthCase) =>
                healthCase.healthCaseStatus === Status.PENDING ? (
                    <OngoingCasesLoading key={`ongoing-cases-loading-${healthCase.id}`} />
                ) : (
                    <OngoingCaseItem key={`ongoing-case-item-${healthCase.id}`} healthCase={healthCase} />
                ),
            )}
        </div>
    );
};

export default OngoingCases;
