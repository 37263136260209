import { hasTopDeparments, IAccessRightData } from "domain/accessRights";
import { isNumber } from "lodash/fp";
import { CompanyRegion } from "swagger/employeeattendance";
import { AccountAccessRightViewModel } from "swagger/usercontextservice";
import { isDynmaicData } from "./dynamicData";
import { IDynamicData } from "./types";

const returnNumbersAsInt = (id: number | string) => (isNumber(id) ? id : parseInt(id));

const findCompanyAccessRightById =
    (accessRights: readonly IAccessRightData[], userAccessRights: AccountAccessRightViewModel[] | undefined) =>
    (companies: CompanyRegion | IDynamicData) =>
        Boolean(
            userAccessRights?.find((y) => {
                if (companies.id === y.companyId) {
                    const accessData = accessRights.find((x) => x.value === y.accessRightType);
                    return accessData?.top ? hasTopDeparments(y) : accessData;
                }
            }),
        );
const selectIdsFromCompanies = (companies: Array<CompanyRegion | IDynamicData>) =>
    companies.reduce<number[]>((prev, current) => {
        const id = current.id;
        if (isDynmaicData(current)) {
            if (current.checked && id) {
                return [...prev, returnNumbersAsInt(id)];
            }
        }
        if (id) {
            return [...prev, returnNumbersAsInt(id)];
        }
        return prev;
    }, []);

export const getCompanyIdsByAccessRight =
    (
        accessRights: readonly IAccessRightData[],
        hasCustomerSupport: boolean,
        userAccessRights: AccountAccessRightViewModel[] | undefined,
    ) =>
    (companies: Array<IDynamicData | CompanyRegion>) => {
        let currentCompanies = companies;
        if (!hasCustomerSupport) {
            currentCompanies = currentCompanies.filter(findCompanyAccessRightById(accessRights, userAccessRights));
        }
        return selectIdsFromCompanies(currentCompanies);
    };
