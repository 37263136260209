import { useState, useMemo } from "react";
import { Accordion } from "@medhelp/ui";
import FilterHeading, { IProps as IFilterHeading } from "../FilterHeading";
import FollowUpDateView from "../FollowUpDateView";
import { IDateRange } from "pages/FollowUp/types";
import { getDateStringForwardOrBack } from "utils/date";
import { ISingleDatepickerProps } from "@medhelp/ui/Datepicker/types";

export interface IDate {
    label: string;
    singleDatepickerProps: ISingleDatepickerProps;
    minDate?: string | undefined;
}
export interface IProps extends Omit<IFilterHeading, "open"> {
    dates: IDate[];
    header: string;
    body: string;
    dateRanges?: IDateRange;
}
interface IPropsView extends IProps {
    preset: "single" | "range";
}
const DateFilter = ({ dates, header, body }: IPropsView) => {
    const [open, setOpen] = useState(false);
    const View = useMemo(
        () => (
            <div className="px-6 flex flex-col gap-4 mb-4">
                {dates.map(({ label, singleDatepickerProps, minDate }, i) => (
                    <div key={label + i}>
                        <p className="text-sm">{label}</p>
                        <FollowUpDateView
                            singleDatepickerProps={singleDatepickerProps}
                            disableDateMin={minDate}
                            disableDateMax={getDateStringForwardOrBack(1, "years", "forward")}
                        />
                    </div>
                ))}
            </div>
        ),
        [dates],
    );
    return (
        <Accordion
            heading={<FilterHeading header={header} body={body} open={open} />}
            icon="caretFull"
            setOpen={setOpen}
            open={open}
        >
            {View}
        </Accordion>
    );
};

export default DateFilter;
