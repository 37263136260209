import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
    currentRoute: string;
}
const initialState: IState = {
    currentRoute: "",
};
const route = createSlice({
    name: "route",
    initialState,
    reducers: {
        changeRoute(state, action: PayloadAction<string>) {
            state.currentRoute = action.payload;
        },
    },
});

export const actions = route.actions;

export default route.reducer;
