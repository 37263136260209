import { ContactInformation } from "@medhelp/ui";
interface IProps {
    id: string;
    name: string;
    ssn?: React.ReactElement;
    companyName?: React.ReactElement;
    closestManager: React.ReactElement;
    isArchived?: boolean;
}
const EmployeeHeader = (props: IProps) => <ContactInformation {...props} />;

export default EmployeeHeader;
