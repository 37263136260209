import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { search as searchAction } from "../followUpAsyncActions";
import { IState, Status } from "..";
import { addToSearchFilters, createFilterOptionsFromSearch } from "../../utils";
import { IMonth, INumber, ITags, IDate } from "pages/FollowUp/types";

const fetchSubscribeBuilder = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(searchAction.fulfilled, (state, action) => {
            const { type } = action.meta.arg;
            if (type === "longtermAbsence" && action.payload?.filterOptions) {
                const currentFilters = state.searchFilter[type].filters;
                const createFilterFromFilterOptions = createFilterOptionsFromSearch(action.payload.filterOptions);
                state.searchFilter[type].filters = createFilterFromFilterOptions.reduce(
                    (x: (ITags | IMonth | IDate | INumber)[], y: ITags) => {
                        const hasValue = x.find((x) => x.referenceKey === y.referenceKey);
                        if (!hasValue) {
                            return addToSearchFilters(y, x);
                        }
                        return x;
                    },
                    currentFilters,
                );
            }
        })
        .addCase(searchAction.pending, (state, action) => {
            const { type } = action.meta.arg;
            state.searchStatus[type] = "pending";
            state.status.userEmployment = Status.PENDING;
        })
        .addCase(searchAction.rejected, (state, action) => {
            const { type } = action.meta.arg;
            state.searchStatus[type] = "error";
            state.status.userEmployment = Status.ERROR;
        });
export default fetchSubscribeBuilder;
