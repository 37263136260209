import * as React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import Icon from "components/Icon";
import SubscribeTable, { SubscribeCell, SubscribeCheckedCell } from "../../../components/SubscribeTable";
import { selectors, Status } from "../../../redux";
import { isEmpty } from "lodash/fp";

const Subscriber = () => {
    const { t } = useTranslation("followup");
    const userSubscriber = useAppSelector(selectors.getUserSubscriber);
    const userEmployment = useAppSelector(selectors.getUserEmployment);
    const status = useAppSelector(selectors.getStatus);
    const TableCell = React.useMemo(
        () =>
            userSubscriber?.map(({ name, contactKey, careOfChild, sick, deviant, rehab }) => ({
                subscribe: <SubscribeCell header={name || ""} body={contactKey} />,
                careOfChild: <SubscribeCheckedCell checked={careOfChild} />,
                sick: <SubscribeCheckedCell checked={sick} />,
                deviant: <SubscribeCheckedCell checked={deviant} />,
                rehab: <SubscribeCheckedCell checked={rehab} />,
            })),
        [userSubscriber],
    );
    if (status.userSubscription === Status.PENDING)
        return (
            <div className="flex flex-1 justify-center">
                <Icon icon="loader" size={35} />
            </div>
        );

    if (isEmpty(TableCell))
        return (
            <div>
                {t("subscriberNoone", {
                    name: userEmployment?.name,
                })}
            </div>
        );
    return <div>{TableCell && <SubscribeTable data={TableCell} subscribe={t("followUpSubscribe.subscribed")} />}</div>;
};
export default Subscriber;
