import { ModalFrame } from "@medhelp/ui";
import { isNumber, isUndefined, isEqual } from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import { S3FileInfo } from "swagger/storage";
import { FileStatus, IFile, useFileUploader } from "../../hooks";
import { thunkActions } from "../../redux";
import AddMedicalCertificateMetadata from "../AddMedicalCertificateMetadata";
import UploadFileMedicalCertificate from "../UploadFileMedicalCertificate";
import { ReactComponent as CheckMarkIcon } from "../../../../icons/checkMarkBlack.svg";
import ModalCommonFooter from "@medhelp/ui/Modal/ModalCommonFooter";
import { IDoubleDatepickerProps } from "@medhelp/ui/Datepicker/types";
import { toast } from "react-toastify";

const degree = ["100%", "75%", "50%", "25%"];

interface IProps {
    certificateDatePickerProps: IDoubleDatepickerProps;
    close: () => void;
    openModal: boolean;
    setOpen: () => void;
    groupKey: string;
    owner: string;
    degree?: string;
    files: S3FileInfo[];
}
const AdjustMedicalCertificateMetadata = ({
    certificateDatePickerProps,
    close,
    openModal,
    setOpen,
    degree: currentDegree,
    groupKey,
    owner,
    files: currentFiles,
}: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [child, setChild] = useState<number | undefined>(undefined);

    const prependFiles = useCallback(
        (setFile: React.Dispatch<React.SetStateAction<IFile[]>>) => {
            const preFilesFixed = currentFiles.map(async (file) =>
                dispatch(thunkActions.getSpecificFile({ id: file.id })).then((data) => {
                    return {
                        preview: URL.createObjectURL(data.payload),
                        name: file.fileName,
                        type: "text/plain",
                        path: URL.createObjectURL(data.payload),
                        file: data.payload,
                    };
                }),
            );
            Promise.all(preFilesFixed).then((data) => {
                setFile(data);
            });
        },
        [dispatch, currentFiles],
    );
    const { files, getInputProps, getRootProps, open, removeFile, status, checkMedicalCertificateLimit } =
        useFileUploader(prependFiles);

    useEffect(() => {
        checkMedicalCertificateLimit();
    }, [checkMedicalCertificateLimit]);

    useEffect(() => {
        if (status === FileStatus.LimitExceeded) {
            toast(t(status), { type: "error" });
        }
    }, [status, t]);

    const Degree = useMemo(() => degree.concat(t("absenceVaryOption")), [t]);
    const GetDegree = useMemo(() => (isNumber(child) ? Degree[child] : undefined), [child, Degree]);

    const setCurrentChild = useCallback((index: number) => {
        setChild(index);
    }, []);
    const onSave = useCallback(() => {
        dispatch(
            thunkActions.editMedicalCertificateMetadata({
                groupKey,
                owner,
                metaData: [
                    {
                        name: "to",
                        value: certificateDatePickerProps?.range?.to ?? "",
                    },
                    {
                        name: "from",
                        value: certificateDatePickerProps?.range?.from ?? "",
                    },
                    {
                        name: "degree",
                        value: GetDegree || "",
                    },
                ],
            }),
        );
    }, [
        GetDegree,
        certificateDatePickerProps?.range?.from,
        certificateDatePickerProps?.range?.to,
        dispatch,
        groupKey,
        owner,
    ]);

    const content = (
        <div className="flex flex-col gap-10">
            <AddMedicalCertificateMetadata
                certificateDatePickerProps={certificateDatePickerProps}
                setCurrentChild={setCurrentChild}
                child={child}
                heading={isUndefined(GetDegree) ? "" : GetDegree}
                values={Degree}
            />
            <UploadFileMedicalCertificate
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                open={open}
                files={files}
                removeFile={(v) => {
                    removeFile(v);
                    checkMedicalCertificateLimit();
                }}
            />
        </div>
    );

    // add initial degree on adjust
    useEffect(() => {
        if (isUndefined(child)) {
            setCurrentChild(degree.findIndex(isEqual(currentDegree)));
        }
    }, [child, currentDegree, setCurrentChild]);

    return (
        <ModalFrame
            id="adjust-medical-certificate-metadata-modal"
            open={openModal}
            setOpen={setOpen}
            header={<h1>{t("uploadMedicalCertificate")}</h1>}
            content={content}
            footer={
                <ModalCommonFooter
                    tertiaryText={t("abort")}
                    tertiaryOnClick={close}
                    primaryOnClick={onSave}
                    primaryText={t("save")}
                    primaryDisabled={
                        Boolean(!certificateDatePickerProps?.range?.from) ||
                        Boolean(!certificateDatePickerProps?.range?.to) ||
                        Boolean(!isNumber(child))
                    }
                    primaryRightIcon={<CheckMarkIcon stroke="currentcolor" />}
                />
            }
        />
    );
};

export default AdjustMedicalCertificateMetadata;
