import { ColumnText } from "@medhelp/ui";

export interface IProps {
    header: string;
    body: string;
    open: boolean;
}
const FilterHeading = ({ header, body, open }: IProps) => (
    <ColumnText
        header={header}
        body={body}
        showBody={!open}
        className="px-0 h-auto justify-start"
        boldHeader
        noHeight
        fontSize="sm"
    />
);
export default FilterHeading;
