const CaseHealthContactLoading = () => {
    return (
        <div className="mb-8 hidden sm:block animate-pulse">
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 mr-8 w-full mb-2"></div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 mr-8 w-3/4 mb-2"></div>
        </div>
    );
};

export default CaseHealthContactLoading;
