import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useBreakpoints } from "hooks";
import { getHealthCase } from "../../../../redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { getDateDisplayValue } from "utils/date";
import { ColumnText } from "@medhelp/ui";
import {
    CaseRelatedAbsence,
    ChainProgressBar,
    CaseContentContainer,
} from "../../../../components/HealthCaseManagement";
import { useAppSelector } from "store/hooks";

interface IProps {
    healthCase?: IHealthCase;
}

const CaseDetails = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const { routeCaseId } = useParams();
    const breakpoints = useBreakpoints();
    const selectedCase = useAppSelector(getHealthCase(routeCaseId ?? ""));
    const currentCase = healthCase ? healthCase : selectedCase;

    const getCaseDetails = () => {
        const caseDetails = [
            {
                header: t("reason"),
                body: (
                    <div className="flex flex-col">
                        {currentCase?.healthCaseCauses ? (
                            currentCase?.healthCaseCauses?.map((cause) => (
                                <div key={`${currentCase.id}-${cause.cause}`}>{cause.cause}</div>
                            ))
                        ) : (
                            <div>{t(currentCase?.initiationCause ?? "")}</div>
                        )}
                    </div>
                ),
                key: "current-case-reason",
            },
            {
                header: t("caseInitiated"),
                body: currentCase?.created ? getDateDisplayValue(currentCase.created, "d MMM yyyy") : "-",
                key: "current-case-initiated",
            },
            {
                header: t("caseStart"),
                body: currentCase?.start ? getDateDisplayValue(currentCase.start, "d MMM yyyy") : "-",
                key: "current-case-caseStart",
            },
            {
                header: t("caseEnd"),
                body: currentCase?.end ? getDateDisplayValue(currentCase.end, "d MMM yyyy") : t("ongoing"),
                key: "current-case-caseEnd",
            },
            {
                header: t("numberOfDays"),
                body: currentCase?.chainInformation?.day ? `${t("day")} ${currentCase.chainInformation.day}` : "-",
                key: "current-case-numberOfDays",
            },
            {
                header: t("caseManager"),
                body: currentCase?.responsible ?? "-",
                key: "current-case-caseManager",
            },
        ];

        return caseDetails;
    };

    return currentCase ? (
        <CaseContentContainer heading={t("details")} healthCase={currentCase} showTag={true}>
            <div className="flex flex-wrap pt-3 pb-8 items-start">
                {getCaseDetails().map((item, index) => (
                    <ColumnText {...item} className="w-56 mb-4" key={`${item.key}-${index}`} />
                ))}
            </div>
            {currentCase.type === "rehab" && !breakpoints.isSmallscreen && (
                <ChainProgressBar healthCase={currentCase} />
            )}
            <CaseRelatedAbsence healthCaseId={currentCase.id ?? ""} />
        </CaseContentContainer>
    ) : null;
};

export default CaseDetails;
