import * as React from "react";
import useWindowSize from "./useWindowSize";
export enum Breakpoints {
    xxs = 415 - 1,
    xs = 546 - 1,
    sm = 640 - 1,
    xmd = 712 - 1, // can be found in custom css
    md = 768 - 1,
    lg = 1024 - 1,
    xl = 1280 - 1,
    xxl = 1400 - 1,
}
const useBreakpoints = () => {
    const { width } = useWindowSize();
    const breakpoints = React.useMemo(() => {
        if (width <= Breakpoints.xxs) {
            return Breakpoints.xxs;
        } else if (width <= Breakpoints.xs) {
            return Breakpoints.xs;
        } else if (width <= Breakpoints.sm) {
            return Breakpoints.sm;
        } else if (width <= Breakpoints.xmd) {
            return Breakpoints.xmd;
        } else if (width <= Breakpoints.md) {
            return Breakpoints.md;
        } else if (width <= Breakpoints.lg) {
            return Breakpoints.lg;
        } else if (width <= Breakpoints.xl) {
            return Breakpoints.xl;
        } else if (width <= Breakpoints.xxl) {
            return Breakpoints.xxl;
        }
    }, [width]);
    return {
        /**
         * Current breakpoint
         */
        breakpoints,
        /**
         * Is `639px` and down
         */
        isSmallscreen: Boolean(breakpoints ? breakpoints <= Breakpoints.sm : false),
        /**
         * Is `711px` and down
         */
        isXMediumscreen: Boolean(breakpoints ? breakpoints <= Breakpoints.xmd : false),
        /**
         * Is `767px` and down
         */
        isMediumScreen: Boolean(breakpoints ? breakpoints <= Breakpoints.md : false),
        /**
         *  Is `1023px` and down
         */
        isLargeScreen: Boolean(breakpoints ? breakpoints <= Breakpoints.lg : false),
        /**
         * Is `1279px` and down
         */
        isXLargeScreen: Boolean(breakpoints ? breakpoints <= Breakpoints.xl : false),
        /**
         * Is all size
         */
        isXXLargeScreen: Boolean(breakpoints ? breakpoints <= Breakpoints.xxl : false),
    };
};

export default useBreakpoints;
