import InfoBox from "./components/InfoBox";
import { useTranslation } from "react-i18next";
import ImportContainer from "./components/ImportContainer";

const FileImport = () => {
    const { t } = useTranslation("fileImport");
    return (
        <div className="px-4 xl:px-0">
            <h2 className="pb-4 md:pb-8">{t("fileImport")}</h2>
            <InfoBox text={t("fileImportDescription")} />
            <ImportContainer />
        </div>
    );
};

export default FileImport;
