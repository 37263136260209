import DepartmentManagerAccessRights from "pages/service/components/DepartmentManagerAccessRights";
import EmploymentManagerAccessRights from "pages/service/components/EmploymentManagerAccessRights";

const RightsTab = () => (
    <>
        <EmploymentManagerAccessRights />
        <DepartmentManagerAccessRights />
    </>
);

export default RightsTab;
