import Cookies from "js-cookie";
import { UserContext } from "swagger/usercontextservice";
import { getServices } from "api/services";
import { getCookieOptions } from "pages/login/utils";

interface DateRangePickerProps {
    userContext: UserContext;
    thunkApi: any;
    token: string;
}

interface DateRangePickerPropsResponse {
    overrideUserContextIfNewToken: UserContext;
    overrideTokenIfNewToken: string;
}

const userActionsNewTokenUtil = async ({
    userContext,
    thunkApi,
    token,
}: DateRangePickerProps): Promise<DateRangePickerPropsResponse> => {
    const mhToken = Cookies.get("mhToken")
        ? Cookies.get("mhToken")
        : sessionStorage.getItem("mhToken")
          ? sessionStorage.getItem("mhToken")!
          : null;

    const newTokenStored = Cookies.get("newToken")
        ? Cookies.get("newToken")
        : sessionStorage.getItem("newToken")
          ? sessionStorage.getItem("newToken")!
          : null;

    if (localStorage.getItem("newJwtToken") === null) {
        localStorage.setItem("newJwtToken", "false");
    }

    if (
        localStorage &&
        localStorage.getItem("newJwtToken") === "true" &&
        (!newTokenStored || newTokenStored !== mhToken)
    ) {
        const userAccountId = userContext.userAccount && userContext.userAccount.id;
        /* tslint:disable-next-line */
        // @ts-expect-error: Let's ignore a compile error like this unreachable code
        const { status: statusNewToken, data: newToken } =
            userAccountId && (await getServices().clients.jwt.jwtUserAccountIdGet(userAccountId));
        if (statusNewToken !== 200) return thunkApi.rejectWithValue("rejected newToken");

        if (newToken) {
            await getServices().setServicesJwtToken(newToken.toString());
            let { status: status2, data: userContext2 } = await getServices().clients.user.getUserFromJwtToken();
            if (status2 !== 200) return thunkApi.rejectWithValue("rejected getUserFromJwtToken with newToken");

            userContext = userContext2;
            token = newToken;
            sessionStorage.setItem("newToken", newToken);
            Cookies.set("mhToken", newToken, getCookieOptions());
            Cookies.set("newToken", newToken, getCookieOptions());
        }
    }

    return Promise.resolve({ overrideUserContextIfNewToken: userContext, overrideTokenIfNewToken: token });
};

export default userActionsNewTokenUtil;
