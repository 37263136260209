import { Trans, useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { thunkActions } from "../../../../redux";
import { getHealthCase } from "../../../../redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { CaseContentContainer } from "../../../../components/HealthCaseManagement";
import CaseActivitiesContent from "./CaseActivitiesContent";

interface IProps {
    healthCase?: IHealthCase;
}

const CaseActivities = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const { routeCaseId } = useParams();
    const dispatch = useAppDispatch();
    const selectedCase = useAppSelector(getHealthCase(routeCaseId ?? ""));
    const currentCase = healthCase ? healthCase : selectedCase;

    useEffect(() => {
        if (currentCase?.id && currentCase.status === undefined) {
            dispatch(thunkActions.fetchCaseActivityList(currentCase.id));
        }
    }, [dispatch, currentCase?.id, currentCase?.status]);

    return currentCase ? (
        <CaseContentContainer
            heading={t("activities")}
            text={
                <Trans
                    t={t}
                    i18nKey={currentCase?.type === "rehab" ? t("activitiesInfoRehab") : t("activitiesInfo")}
                    components={{
                        linkTag: (
                            <a
                                href="https://www.forsakringskassan.se/"
                                style={{
                                    textDecoration: "underline",
                                }}
                            />
                        ),
                    }}
                    values={{
                        linkTag: "www.forsakringskassan.se",
                    }}
                />
            }
            healthCase={currentCase}
        >
            <CaseActivitiesContent healthCase={currentCase} />
        </CaseContentContainer>
    ) : null;
};

export default CaseActivities;
