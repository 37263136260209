import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { getCurrentUser, getSortedHistoryHealthCases, getHealthCasesLoading } from "../../../redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { getDateDisplayValue } from "utils/date";
import { getTagProps } from "pages/FollowUp/utils/case";
import { isEmpty } from "lodash/fp";
import { Card, CardAlign, ColumnText } from "@medhelp/ui";
import { StatusTag } from "@medhelp/ui/Tag";
import Icon from "components/Icon";
import HistoryCasesLoading from "./HistoryCasesLoading";

const HistoryCases = () => {
    const { t } = useTranslation("followup");
    const employmentId = useAppSelector(getCurrentUser);
    const historyHealthCases = useAppSelector(getSortedHistoryHealthCases);
    const currentHealthCasesLoading = useAppSelector(getHealthCasesLoading);

    const getHeader = (healthCase: IHealthCase) => {
        return healthCase.status === "declined" || healthCase.status === "expired"
            ? t("healthCasePromotionRecommended")
            : `${t(healthCase.type ?? "")}`;
    };

    const getBody = (healthCase: IHealthCase) => {
        return healthCase.status === "declined" || healthCase.status === "expired"
            ? `${t("wasRecommended")}: ${getDateDisplayValue(healthCase.created ?? "", "d MMM yyyy")}`
            : `${getDateDisplayValue(healthCase.start ?? "", "d MMM yyyy")} - ${
                  healthCase.end ? getDateDisplayValue(healthCase.end, "d MMM yyyy") : t("endDateNotSet")
              }`;
    };

    if (currentHealthCasesLoading) {
        return <HistoryCasesLoading />;
    }

    return (
        <div>
            <h2 className="mt-18 mb-6">{t("history")}</h2>
            {isEmpty(historyHealthCases) ? (
                <Card>
                    <p>{t("noCaseHistory")}</p>
                </Card>
            ) : (
                <Card align={CardAlign.start} className="p-0">
                    <div className="flex flex-col w-full">
                        {historyHealthCases.map((healthCase, index) => (
                            <div
                                key={`history-case-${healthCase.id}`}
                                className={`w-full flex flex-row sm:flex-row justify-between px-8 py-6 ${
                                    index > 0 && "border-t-2 border-solid border-mhgrey-border"
                                }`}
                            >
                                <div
                                    key={`history-case-${healthCase.id}`}
                                    className={`w-full flex flex-col sm:flex-row justify-between`}
                                >
                                    <ColumnText
                                        header={getHeader(healthCase)}
                                        body={getBody(healthCase)}
                                        className="pl-0 pr-6 pb-2"
                                        boldHeader
                                    />
                                    <div className="flex items-center">
                                        <StatusTag
                                            id={`case-status-${healthCase.id}`}
                                            text={t(`caseStatus.${healthCase.status}`)}
                                            preset={getTagProps(healthCase?.status)?.color ?? "grey"}
                                            icon={getTagProps(healthCase?.status)?.icon}
                                        />
                                        <Link
                                            to={`/followup/employee/${employmentId}/case/${healthCase.id}`}
                                            className="self-center ml-8 hidden sm:block"
                                        >
                                            <Icon icon="caretRight" size={16} />
                                        </Link>
                                    </div>
                                </div>
                                <Link
                                    to={`/followup/employee/${employmentId}/case/${healthCase.id}`}
                                    className="self-center ml-8 block sm:hidden"
                                >
                                    <Icon icon="caretRight" size={16} />
                                </Link>
                            </div>
                        ))}
                    </div>
                </Card>
            )}
        </div>
    );
};

export default HistoryCases;
