export interface IRowProps {
    onClick: (() => void) | undefined;
    theme?: "archive" | "default" | undefined;
}

export interface IRow {
    cells: { data: JSX.Element[]; id: string; className?: string }[];
    id?: string;
    rowProps?: IRowProps;
}
export interface IProps extends IRow {
    children: React.ReactNode;
    key: string;
}

const DefaultTableRow = ({ children, rowProps, key }: IProps) => {
    const clickable = Boolean(rowProps?.onClick);
    return (
        <tr
            className={`h-full text-left border-b-1 border-b-solid border-b-grey-300 hover:bg-background-200 ${
                clickable && "cursor-pointer"
            }`}
            key={key}
            {...rowProps}
        >
            {children}
        </tr>
    );
};

export default DefaultTableRow;
