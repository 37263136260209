import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { flow } from "lodash/fp";
import { S3FileInfo, MetaData } from "swagger/storage";
import { postBuilder, loadFiles, removeCertificate } from "./builders";

export interface IFileData extends MetaData {}

export interface IFileState extends S3FileInfo {}
export enum MedicalCertificateModals {
    uploadCertificate = "uploadCertificate",
    someUploadedFilesFailed = "someUploadedFilesFailed",
    deleteMedicalCertificateConfirm = "deleteMedicalCertificateConfirm",
    adjustMedicalCertificateMetadata = "adjustMedicalCertificateMetadata",
}

export enum FileStatus {
    pending,
    error,
    ok,
    errorLoading,
}

interface IDeleteMedicalCertificateAction {
    owner: string;
    groupKey: string;
}

type KeysModal = keyof typeof MedicalCertificateModals;

interface IAddedFile {
    groupKey: string | null;
    success: "history" | "current" | null;
}
export interface IMedicalCertificateSlice {
    file: { pages: S3FileInfo[] };
    modals: { [key in KeysModal]: boolean };
    status: FileStatus;
    pendingDelete: {
        owner: string | null;
        groupKey: string | null;
    };
    addedFile: IAddedFile;
}
const initialPendingDelete = {
    owner: null,
    groupKey: null,
};
const initialmedicalCertificateState: IMedicalCertificateSlice = {
    file: { pages: [] },
    modals: {
        uploadCertificate: false,
        someUploadedFilesFailed: false,
        deleteMedicalCertificateConfirm: false,
        adjustMedicalCertificateMetadata: false,
    },
    status: FileStatus.ok,
    pendingDelete: initialPendingDelete,
    addedFile: {
        groupKey: null,
        success: null,
    },
};
const medicalCertificateSlice = createSlice({
    name: "absenceReports",
    initialState: initialmedicalCertificateState,
    reducers: {
        showModal(state, action: PayloadAction<MedicalCertificateModals>) {
            state.modals[MedicalCertificateModals[action.payload]] = true;
        },
        hideModal(state, action: PayloadAction<MedicalCertificateModals>) {
            state.modals[MedicalCertificateModals[action.payload]] = false;
        },
        resetFileError(state) {
            state.status = FileStatus.ok;
        },
        deleteMedicalCertificate(state, action: PayloadAction<IDeleteMedicalCertificateAction>) {
            state.pendingDelete = action.payload;
            state.modals.deleteMedicalCertificateConfirm = true;
        },
        cancelDeleteMedicalCertificate(state) {
            state.modals.deleteMedicalCertificateConfirm = false;
            Object.assign(state.pendingDelete, initialPendingDelete);
        },
        setSuccess(state, action: PayloadAction<IAddedFile>) {
            state.addedFile = action.payload;
        },
    },
    extraReducers: (builder) => {
        flow(postBuilder, loadFiles, removeCertificate)(builder);
    },
});

export const medicalCertificateActions = medicalCertificateSlice.actions;

export default medicalCertificateSlice.reducer;
