import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IAbsenceReportSlice } from "../absenceReportsSlice";
import { fetchAbsenceReports } from "../absenceReportsActions";

const absenceReports = (builder: ActionReducerMapBuilder<IAbsenceReportSlice>) =>
    builder
        .addCase(fetchAbsenceReports.fulfilled, (state, action) => {
            state.AbsenceReports = action.payload.absenceReports;
            state.IsOK = true;
        })
        .addCase(fetchAbsenceReports.rejected, (state) => {
            state.IsOK = false;
        });

export default absenceReports;
