import { useEffect } from "react";
import { capitalize } from "lodash/fp";
import ErrorWidget from "../ErrorWidget";
import { useRetailerColors } from "hooks";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { StaticBarChart } from "pages/dashboard/components/Charts";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import { IWidgetFilter, Widget } from "pages/dashboard/utils/dashboardTypes";
import RecurringSickLeaveWidgetLoader from "./RecurringSickLeaveWidgetLoader";
import { getNumberOfMonths } from "pages/dashboard/utils/dashboardActionsUtils";
import { getWidgetFilter, getRecurringAbsences, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const RecurringSickLeaveWidget = ({ id, type }: Widget) => {
    const dispatch = useAppDispatch();
    const { primary } = useRetailerColors();
    const { t } = useTranslation("dashboard");
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getRecurringAbsences(id));
    const widgetFilter = useAppSelector(getWidgetFilter(false, id)) as IWidgetFilter;

    const selectedNumberOfMonths = getNumberOfMonths(widgetFilter.timeInterval ?? "twelveMonths");

    useEffect(() => {
        !isDragging && dispatch(fetchWidgetData({ id, type }));
    }, [dispatch, id, type, isDragging]);

    if (status === Status.PENDING) {
        return <RecurringSickLeaveWidgetLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <StaticBarChart
            id={"recurring-absence-last-12-months"}
            mySelection={t("numberOfRecurringAbsence", { months: selectedNumberOfMonths })}
            secondaryColor={primary}
            data={data?.map((item) => ({
                name: t(item.groupName),
                value: item.employees.length,
                modalHeading: t("recurringAbsenceModal", {
                    groupName: t(item.groupName),
                    months: selectedNumberOfMonths,
                }),
                tooltipHeading: t("widgetHeading.recurringSickLeave"),
                tooltipText: `${item.employees.length} ${t(
                    item.employees.length === 1 ? "employeeHasHad" : "employeesHaveHad",
                )} ${t(item.groupName)} ${t("recurringAbsenceTooltip", {
                    months: selectedNumberOfMonths,
                })}`,
                employeeDetails: item.employees,
            }))}
            selectedNumberOfMonths={selectedNumberOfMonths}
            xAxisLabel={t("noOfOccasions")}
            yAxisLabel={capitalize(t("noOfEmployees"))}
        />
    );
};

export default RecurringSickLeaveWidget;
