import Icon from "components/Icon";
import { toLower } from "lodash/fp";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect, useMemo, useState } from "react";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import { AdvisoryServiceType, AdvisoryServiceTypes } from "pages/service/utils/serviceTypes";
import {
    saveFieldTemplate,
    deleteFieldTemplate,
    saveConfigurationField,
    deleteConfigurationField,
    saveWorkOrderConfiguration,
} from "pages/service/redux/serviceAsyncActions";
import {
    getCallNurse,
    getWorkOrderInfo,
    getSickTemplateId,
    getAdvisoryService,
    getNursePhoneNumber,
    getMandatoryCallback,
    getCareOfChildTemplateId,
    getDynmaicMedicalAdvisory,
    getMandatoryAdvisoryCallback,
    getAllowDirectAdvisoryCallback,
    getRiskMedicalAdvisoryRequestTemplateField,
    getRequestedMedicalAdvisorySickTemplateField,
    getRequestedMedicalAdvisoryCareOfChildTemplateField,
} from "pages/service/redux/serviceSelectors";

interface IChecked {
    id?: string;
    key: string;
    checked: boolean;
}
interface IAdvisoryServices {
    callNurse: IChecked;
    mandatoryCallback: IChecked;
    dynamicMedicalAdvisory: IChecked;
    mandatoryAdvisoryCallback: IChecked;
    riskMedicalAdvisoryRequest: IChecked;
    allowDirectAdvisoryCallback: IChecked;
    requestedMedicalAdvisorySick: IChecked;
    requestedMedicalAdvisoryCareOfChild: IChecked;
}

type AdvisoryServiceKeys = keyof IAdvisoryServices;

const shouldDirtyAndValidate = {
    shouldDirty: true,
    shouldValidate: true,
};

const AdvisoryServices = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const callNurse = useAppSelector(getCallNurse);
    const sickTemplateId = useAppSelector(getSickTemplateId);
    const advisoryService = useAppSelector(getAdvisoryService);
    const nursePhoneNumber = useAppSelector(getNursePhoneNumber);
    const mandatoryCallback = useAppSelector(getMandatoryCallback);
    const workOrderConfiguration = useAppSelector(getWorkOrderInfo);
    const careOfChildTemplateId = useAppSelector(getCareOfChildTemplateId);
    const dynamicMedicalAdvisory = useAppSelector(getDynmaicMedicalAdvisory);
    const mandatoryAdvisoryCallback = useAppSelector(getMandatoryAdvisoryCallback);
    const allowDirectAdvisoryCallback = useAppSelector(getAllowDirectAdvisoryCallback);
    const riskMedicalAdvisoryRequest = useAppSelector(getRiskMedicalAdvisoryRequestTemplateField);
    const requestedMedicalAdvisorySick = useAppSelector(getRequestedMedicalAdvisorySickTemplateField);
    const requestedMedicalAdvisoryCareOfChild = useAppSelector(getRequestedMedicalAdvisoryCareOfChildTemplateField);
    const [showDetails, setShowDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const defaultValues: IAdvisoryServices = useMemo(
        () => ({
            callNurse,
            mandatoryCallback,
            dynamicMedicalAdvisory,
            mandatoryAdvisoryCallback,
            riskMedicalAdvisoryRequest,
            allowDirectAdvisoryCallback,
            requestedMedicalAdvisorySick,
            requestedMedicalAdvisoryCareOfChild,
        }),
        [
            callNurse,
            mandatoryCallback,
            dynamicMedicalAdvisory,
            mandatoryAdvisoryCallback,
            riskMedicalAdvisoryRequest,
            allowDirectAdvisoryCallback,
            requestedMedicalAdvisorySick,
            requestedMedicalAdvisoryCareOfChild,
        ],
    );

    const {
        watch,
        reset,
        register,
        setValue,
        resetField,
        handleSubmit,
        formState: { isDirty, isValid, dirtyFields },
    } = useForm({
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const handleDispatchResponse = (promise: Promise<any>, reset: any) => {
        return promise
            .then(unwrapResult)
            .then(() => toast(t("saved")))
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                reset();
            });
    };

    const onSubmit = (data: IAdvisoryServices) => {
        setIsLoading(true);
        const advisoryServicePromises = [];

        if (dirtyFields.allowDirectAdvisoryCallback || dirtyFields.mandatoryAdvisoryCallback) {
            handleDispatchResponse(
                dispatch(
                    saveWorkOrderConfiguration({
                        id: workOrderConfiguration.id ? workOrderConfiguration.id : undefined,
                        allowDirectAdvisoryCallback: data.allowDirectAdvisoryCallback.checked,
                        mandatoryAdvisoryCallback: data.mandatoryAdvisoryCallback.checked,
                    }),
                ),
                () => {
                    resetField("allowDirectAdvisoryCallback.checked");
                    resetField("mandatoryAdvisoryCallback.checked");
                },
            );
        }

        if (dirtyFields.callNurse) {
            advisoryServicePromises.push(
                handleDispatchResponse(
                    !data.callNurse.id
                        ? dispatch(saveConfigurationField({ name: "callNurse" }))
                        : dispatch(deleteConfigurationField(data.callNurse.id)),
                    resetField("callNurse.checked"),
                ),
            );
        }

        if (dirtyFields.dynamicMedicalAdvisory) {
            advisoryServicePromises.push(
                handleDispatchResponse(
                    !data.dynamicMedicalAdvisory.id
                        ? dispatch(saveConfigurationField({ name: "dynamicMedicalAdvisory" }))
                        : dispatch(deleteConfigurationField(data.dynamicMedicalAdvisory.id)),
                    resetField("dynamicMedicalAdvisory.checked"),
                ),
            );
        }

        if (dirtyFields.mandatoryCallback) {
            advisoryServicePromises.push(
                handleDispatchResponse(
                    !data.mandatoryCallback.id
                        ? dispatch(saveConfigurationField({ name: "mandatoryCallback" }))
                        : dispatch(deleteConfigurationField(data.mandatoryCallback.id)),
                    resetField("mandatoryCallback.checked"),
                ),
            );
        }

        if (dirtyFields.requestedMedicalAdvisorySick && sickTemplateId) {
            advisoryServicePromises.push(
                handleDispatchResponse(
                    !data.requestedMedicalAdvisorySick.id
                        ? dispatch(saveFieldTemplate({ templateId: sickTemplateId, name: "RequestedMedicalAdvisory" }))
                        : dispatch(
                              deleteFieldTemplate({
                                  templateId: sickTemplateId,
                                  id: data.requestedMedicalAdvisorySick.id,
                              }),
                          ),
                    resetField("requestedMedicalAdvisorySick.checked"),
                ),
            );
        }

        if (dirtyFields.requestedMedicalAdvisoryCareOfChild && careOfChildTemplateId) {
            advisoryServicePromises.push(
                handleDispatchResponse(
                    !data.requestedMedicalAdvisoryCareOfChild.id
                        ? dispatch(
                              saveFieldTemplate({
                                  templateId: careOfChildTemplateId,
                                  name: "RequestedMedicalAdvisory",
                              }),
                          )
                        : dispatch(
                              deleteFieldTemplate({
                                  templateId: careOfChildTemplateId,
                                  id: data.requestedMedicalAdvisoryCareOfChild.id,
                              }),
                          ),
                    resetField("requestedMedicalAdvisoryCareOfChild.checked"),
                ),
            );
        }

        if (dirtyFields.riskMedicalAdvisoryRequest && sickTemplateId) {
            advisoryServicePromises.push(
                handleDispatchResponse(
                    !data.riskMedicalAdvisoryRequest.id
                        ? dispatch(
                              saveFieldTemplate({ templateId: sickTemplateId, name: "RiskMedicalAdvisoryRequest" }),
                          )
                        : dispatch(
                              deleteFieldTemplate({
                                  templateId: sickTemplateId,
                                  id: data.riskMedicalAdvisoryRequest.id,
                              }),
                          ),
                    resetField("riskMedicalAdvisoryRequest.checked"),
                ),
            );
        }

        Promise.allSettled(advisoryServicePromises).finally(() => setIsLoading(false));
    };

    const determineAdvisoryService = () => {
        const careOfChildCondition = careOfChildTemplateId
            ? watch("requestedMedicalAdvisoryCareOfChild.checked")
            : true;

        if (watch("callNurse.checked")) return "callNurse";
        if (watch("mandatoryCallback.checked") && watch("mandatoryAdvisoryCallback.checked"))
            return "mandatoryCallback";
        if (
            watch("dynamicMedicalAdvisory.checked") &&
            watch("riskMedicalAdvisoryRequest.checked") &&
            watch("allowDirectAdvisoryCallback.checked") &&
            watch("requestedMedicalAdvisorySick.checked") &&
            careOfChildCondition
        )
            return "dynamicMedicalAdvisory";
        if (
            watch("allowDirectAdvisoryCallback.checked") &&
            watch("requestedMedicalAdvisorySick.checked") &&
            careOfChildCondition
        )
            return "requestedMedicalAdvisory";

        if (
            !watch("callNurse.checked") &&
            !watch("mandatoryCallback.checked") &&
            !watch("dynamicMedicalAdvisory.checked") &&
            !watch("mandatoryAdvisoryCallback.checked") &&
            !watch("allowDirectAdvisoryCallback.checked") &&
            !watch("riskMedicalAdvisoryRequest.checked") &&
            !watch("requestedMedicalAdvisorySick.checked") &&
            careOfChildTemplateId
                ? !watch("requestedMedicalAdvisoryCareOfChild.checked")
                : true
        )
            return "fullyAutomatic";

        return "invalidConfiguration";
    };

    const mutuallyExclusiveConfigurations: { [key in AdvisoryServiceKeys]: AdvisoryServiceKeys[] } = useMemo(
        () => ({
            callNurse: [
                "mandatoryCallback",
                "dynamicMedicalAdvisory",
                "mandatoryAdvisoryCallback",
                "riskMedicalAdvisoryRequest",
                "allowDirectAdvisoryCallback",
                "requestedMedicalAdvisorySick",
                "requestedMedicalAdvisoryCareOfChild",
            ],
            mandatoryCallback: [
                "callNurse",
                "dynamicMedicalAdvisory",
                "riskMedicalAdvisoryRequest",
                "allowDirectAdvisoryCallback",
                "requestedMedicalAdvisorySick",
                "requestedMedicalAdvisoryCareOfChild",
            ],
            mandatoryAdvisoryCallback: [
                "callNurse",
                "dynamicMedicalAdvisory",
                "riskMedicalAdvisoryRequest",
                "allowDirectAdvisoryCallback",
                "requestedMedicalAdvisorySick",
                "requestedMedicalAdvisoryCareOfChild",
            ],
            requestedMedicalAdvisorySick: ["callNurse", "mandatoryCallback", "mandatoryAdvisoryCallback"],
            requestedMedicalAdvisoryCareOfChild: ["callNurse", "mandatoryCallback", "mandatoryAdvisoryCallback"],
            allowDirectAdvisoryCallback: ["callNurse", "mandatoryCallback", "mandatoryAdvisoryCallback"],
            riskMedicalAdvisoryRequest: ["callNurse", "mandatoryCallback", "mandatoryAdvisoryCallback"],
            dynamicMedicalAdvisory: ["callNurse", "mandatoryCallback", "mandatoryAdvisoryCallback"],
        }),
        [],
    );

    const disabled = (key: keyof IAdvisoryServices) =>
        !watch(`${key}.checked`) && mutuallyExclusiveConfigurations[key]?.some((config) => watch(`${config}.checked`));

    const handleChangeAdvisoryService = (newAdvisoryService: AdvisoryServiceType) => {
        setValue("callNurse.checked", false, shouldDirtyAndValidate);
        setValue("mandatoryCallback.checked", false, shouldDirtyAndValidate);
        setValue("dynamicMedicalAdvisory.checked", false, shouldDirtyAndValidate);
        setValue("mandatoryAdvisoryCallback.checked", false, shouldDirtyAndValidate);
        setValue("riskMedicalAdvisoryRequest.checked", false, shouldDirtyAndValidate);
        setValue("allowDirectAdvisoryCallback.checked", false, shouldDirtyAndValidate);
        setValue("requestedMedicalAdvisorySick.checked", false, shouldDirtyAndValidate);
        setValue("requestedMedicalAdvisoryCareOfChild.checked", false, shouldDirtyAndValidate);

        switch (newAdvisoryService) {
            case "callNurse":
                setValue("callNurse.checked", true, shouldDirtyAndValidate);
                break;
            case "mandatoryCallback":
                setValue("mandatoryCallback.checked", true, shouldDirtyAndValidate);
                setValue("mandatoryAdvisoryCallback.checked", true, shouldDirtyAndValidate);
                break;
            case "dynamicMedicalAdvisory":
                setValue("dynamicMedicalAdvisory.checked", true, shouldDirtyAndValidate);
                setValue("riskMedicalAdvisoryRequest.checked", true, shouldDirtyAndValidate);
                setValue("allowDirectAdvisoryCallback.checked", true, shouldDirtyAndValidate);
                setValue("requestedMedicalAdvisorySick.checked", true, shouldDirtyAndValidate);
                setValue("requestedMedicalAdvisoryCareOfChild.checked", true, shouldDirtyAndValidate);
                break;
            case "requestedMedicalAdvisory":
                setValue("allowDirectAdvisoryCallback.checked", true, shouldDirtyAndValidate);
                setValue("requestedMedicalAdvisorySick.checked", true, shouldDirtyAndValidate);
                careOfChildTemplateId &&
                    setValue("requestedMedicalAdvisoryCareOfChild.checked", true, shouldDirtyAndValidate);
                break;
            case "fullyAutomatic":
                break;
            default:
                break;
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("advisoryService")}
                reset={() => reset(defaultValues)}
                disabled={!isDirty || !isValid}
                isLoading={isLoading}
                content={<AccordionBooleanContent label={t(advisoryService)} />}
            >
                <div className="py-10">
                    <div className="flex my-12 text-xl">
                        <div className="flex flex-col gap-3 p-4 border border-black">
                            <div>
                                {t("current")} {toLower(t("advisoryService"))}: {t(advisoryService)}
                            </div>
                            {isDirty && (
                                <div>
                                    {t("new")} {t("advisoryService")}: {t(determineAdvisoryService())}
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        {[...AdvisoryServiceTypes].map((service) => {
                            return (
                                <label
                                    key={service}
                                    className="flex font-bold p-4 justify-between items-center border-b border-grey-300"
                                >
                                    {t(service)}
                                    <input
                                        checked={determineAdvisoryService() === service}
                                        name="advisoryService"
                                        type="radio"
                                        className="hover:cursor-pointer h-6 w-6 accent-mhdarkgreen-original"
                                        onChange={() => handleChangeAdvisoryService(service)}
                                    />
                                </label>
                            );
                        })}
                    </div>
                    <div
                        onClick={() => setShowDetails(!showDetails)}
                        className="flex p-4 items-center mt-10 hover:cursor-pointer"
                    >
                        <div className="pr-2">{t("seeAllConfigurations")}</div>
                        <Icon icon={showDetails ? "caretUp" : "caretDown"} size={12} />
                    </div>

                    <div className={showDetails ? "" : "hidden"}>
                        <ServiceCheckboxContainer
                            heading={t("callNurse")}
                            className="py-2"
                            warning={watch("callNurse.checked") && !nursePhoneNumber ? "Saknar nummer på webben" : ""}
                        >
                            <ServiceCheckboxRow
                                label="callNurse (configuration field)"
                                register={register("callNurse.checked")}
                                disabled={disabled("callNurse")}
                            />
                        </ServiceCheckboxContainer>
                        <ServiceCheckboxContainer heading={t("mandatoryCallback")} className="py-2">
                            <ServiceCheckboxRow
                                label="mandatoryCallback (configuration field)"
                                register={register("mandatoryCallback.checked", {
                                    required: watch("mandatoryAdvisoryCallback.checked"),
                                })}
                                disabled={disabled("mandatoryCallback")}
                            />
                            <ServiceCheckboxRow
                                label="mandatoryAdvisoryCallback (work order)"
                                register={register("mandatoryAdvisoryCallback.checked", {
                                    required: watch("mandatoryCallback.checked"),
                                })}
                                disabled={disabled("mandatoryAdvisoryCallback")}
                            />
                        </ServiceCheckboxContainer>
                        <ServiceCheckboxContainer
                            heading={t("requestedMedicalAdvisory") + " / " + t("dynamicMedicalAdvisory")}
                            info={t("requiredForRequestedAndDynamic")}
                            className="py-2"
                        >
                            <ServiceCheckboxRow
                                label="RequestedMedicalAdvisory (template field - sick)"
                                register={register("requestedMedicalAdvisorySick.checked", {
                                    required:
                                        watch("allowDirectAdvisoryCallback.checked") ||
                                        watch("requestedMedicalAdvisoryCareOfChild.checked"),
                                })}
                                disabled={disabled("requestedMedicalAdvisorySick")}
                            />
                            <ServiceCheckboxRow
                                label="RequestedMedicalAdvisory (template field - care of child)"
                                register={register("requestedMedicalAdvisoryCareOfChild.checked", {
                                    required:
                                        careOfChildTemplateId &&
                                        (watch("dynamicMedicalAdvisory.checked") ||
                                            watch("riskMedicalAdvisoryRequest.checked") ||
                                            watch("allowDirectAdvisoryCallback.checked") ||
                                            watch("requestedMedicalAdvisorySick.checked")),
                                })}
                                disabled={!careOfChildTemplateId || disabled("requestedMedicalAdvisoryCareOfChild")}
                            />
                            <ServiceCheckboxRow
                                label="allowDirectAdvisoryCallback (work order)"
                                register={register("allowDirectAdvisoryCallback.checked", {
                                    required:
                                        watch("dynamicMedicalAdvisory.checked") ||
                                        watch("riskMedicalAdvisoryRequest.checked") ||
                                        watch("requestedMedicalAdvisorySick.checked") ||
                                        watch("requestedMedicalAdvisoryCareOfChild.checked"),
                                })}
                                disabled={disabled("allowDirectAdvisoryCallback")}
                            />
                        </ServiceCheckboxContainer>
                        <ServiceCheckboxContainer heading={t("dynamicMedicalAdvisory")} className="py-2">
                            <ServiceCheckboxRow
                                label="RiskMedicalAdvisoryRequest (template field)"
                                register={register("riskMedicalAdvisoryRequest.checked", {
                                    required: watch("dynamicMedicalAdvisory.checked"),
                                })}
                                disabled={disabled("riskMedicalAdvisoryRequest")}
                            />
                            <ServiceCheckboxRow
                                label="dynamicMedicalAdvisory (configuration field)"
                                register={register("dynamicMedicalAdvisory.checked", {
                                    required: watch("riskMedicalAdvisoryRequest.checked"),
                                })}
                                disabled={disabled("dynamicMedicalAdvisory")}
                            />
                        </ServiceCheckboxContainer>
                    </div>
                </div>
            </ServiceAccordion>
        </form>
    );
};

export default AdvisoryServices;
