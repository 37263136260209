import React from "react";
import { ReactComponent as LightBulbIcon } from "icons/menu/about.svg";
import { useTranslation } from "react-i18next";
function ProductSettingsInfoBox() {
    const { t } = useTranslation("administration");
    return (
        <div className="bg-grey-100 p-8 px-4 gap-4 rounded-tl-lg flex lg:mx-0 mx-6">
            <LightBulbIcon />
            <div className="lg:w-3/5 w-full ml-4 text-lg">{t("manageSettingsDescription")}</div>
        </div>
    );
}

export default ProductSettingsInfoBox;
