import { flow } from "lodash/fp";
// import { persistReducer } from "redux-persist";
// import storageSession from "redux-persist/lib/storage/session";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportEmploymentContractPage } from "swagger/employeeattendance";
import * as Builder from "./builder";
import { EmployeeColumnTypeTypes, EmployeeSearchTypes, SearchStatus } from "pages/FollowUp/utils";

export interface ISortUpdate {
    selectedColumn: EmployeeColumnTypeTypes;
    value: "asc" | "desc";
}
interface IEmployeeState {
    search: {
        [value in EmployeeSearchTypes]: {
            [key: number]: ReportEmploymentContractPage;
        };
    };
    searchHasMore: {
        [value in EmployeeSearchTypes]: { [key: number]: boolean };
    };
    searchStatus: { [value in EmployeeSearchTypes]: SearchStatus };
    pageNumber: {
        [value in EmployeeSearchTypes]: number;
    };
    searchType: EmployeeSearchTypes;
    filters: {
        [value in EmployeeSearchTypes]: {
            sorting: {
                [keys in EmployeeColumnTypeTypes]: "asc" | "desc";
            };
            selectedColumn: EmployeeColumnTypeTypes;
        };
    };
}

export interface IState {
    searchEmployee?: ReportEmploymentContractPage;
    empoloyee: IEmployeeState;
}
const initialEmployee: IEmployeeState = {
    search: {
        employee: {},
    },
    searchHasMore: {
        employee: {},
    },
    pageNumber: {
        employee: 1,
    },
    searchStatus: {
        employee: "no-data",
    },
    searchType: "employee",
    filters: {
        employee: {
            sorting: {
                employee: "asc",
                company: "asc",
                affectedDepartments: "asc",
            },
            selectedColumn: "employee",
        },
    },
};
const initialState: IState = {
    searchEmployee: undefined,
    empoloyee: initialEmployee,
};
const organization = createSlice({
    name: "organization",
    initialState,
    reducers: {
        updateSearchFilterSorting(state, action: PayloadAction<ISortUpdate>) {
            let searchType = state.empoloyee.searchType;
            let selectedColumn = action.payload.selectedColumn;
            state.empoloyee.filters[searchType].selectedColumn = action.payload.selectedColumn;
            state.empoloyee.filters[searchType].sorting[selectedColumn] = action.payload.value;
        },
        updatePaginatorPageNumber(state, action: PayloadAction<number>) {
            let searchType = state.empoloyee.searchType;
            state.empoloyee.pageNumber[searchType] = action.payload;
        },
        resetPageNumber(state) {
            Object.assign(state.empoloyee, initialEmployee);
        },
    },
    extraReducers: (builder) => {
        flow(Builder.searchEmployee, Builder.getEmploymentByCompanyIdsOrDepartments)(builder);
    },
});

export const actions = organization.actions;

// presist all the stuf in store
// const persistConfig = {
//     key: "organization",
//     storage: storageSession,
// };
// export default persistReducer(persistConfig, organization.reducer);

export default organization.reducer;
