import { useState } from "react";
import { Accordion } from "@medhelp/ui";
import FilterHeading from "../FilterHeading";
import Checkbox from "@medhelp/ui/Checkbox";

interface IPropsView {
    items: {
        label: string;
        onChange: (value: string) => void;
        checked: boolean;
        name: string;
    }[];
    header: string;
    body: string;
}

const SearchCheckboxFilter = ({ items, header, body }: IPropsView) => {
    const [open, setOpen] = useState(false);
    return (
        <Accordion
            heading={<FilterHeading header={header} body={body} open={open} />}
            icon="caretFull"
            setOpen={setOpen}
            open={open}
        >
            <div className="pl-4 pr-3 gap-6 flex flex-col my-6">
                {items.map(({ label, onChange, checked, name }) => (
                    <div
                        className="flex justify-between select-none cursor-pointer"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            onChange(name);
                        }}
                        key={`${name}-${label}`}
                    >
                        <p className={`pl-2 truncate text-sm`}>{label}</p>
                        <div className="ml-1 flex items-center justify-center">
                            <Checkbox checked={checked} onChange={() => {}} name={name} />
                        </div>
                    </div>
                ))}
            </div>
        </Accordion>
    );
};

export default SearchCheckboxFilter;
