import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { activateHealthCase as activateHealthCaseAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const activateHealthCase = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(activateHealthCaseAction.fulfilled, (state, action) => {
            const currentCase = state.healthCases.find(({ id }) => id === action.payload);
            if (currentCase) {
                currentCase.status = "ongoing";
                currentCase.healthCaseStatus = Status.OK;
                currentCase.expanded = true;
            }
        })
        .addCase(activateHealthCaseAction.pending, (state, action) => {
            const currentCase = state.healthCases.find(({ id }) => id === action.meta.arg.healthCaseId);
            if (currentCase) currentCase.healthCaseStatus = Status.PENDING;
        })
        .addCase(activateHealthCaseAction.rejected, (state, action) => {
            const currentCase = state.healthCases.find(({ id }) => id === action.meta.arg.healthCaseId);
            if (currentCase) currentCase.healthCaseStatus = Status.ERROR;
        });

export default activateHealthCase;
