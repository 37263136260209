import Icon from "components/Icon/Icon";

interface IProps {
    text: string;
}

const Error = (props: IProps) => {
    const { text } = props;

    return (
        <div className="flex flex-col h-full items-center justify-center pt-3 pb-10">
            <div>
                <Icon icon="close" size={100} />
            </div>
            <p>{text}</p>
        </div>
    );
};

export default Error;
