import { useNavigate } from "react-router-dom";
import SecondaryButton from "components/SecondaryButton";
import Icon from "components/Icon";

const SuccessMessage = () => {
    const navigate = useNavigate();
    return (
        <>
            <h1 className="text-[40px]">Snart klart!</h1>
            <p className="text-lg">
                Vi jobbar på att sätta upp ert kundkonto men det kan ta några minuter innan det är klart. Vi mailar till
                angiven e-post när det är klart och admin kan logga in med BankID. Observera att det till en början bara
                är admin som kan logga in.
            </p>
            <div>
                <SecondaryButton
                    id="fortnox-go-to-login"
                    onClick={() => {
                        navigate("/login/bankIdOptions");
                    }}
                    text="Gå till inloggning"
                    rightIcon={<Icon icon="caretRight" size={12} />}
                />
            </div>
        </>
    );
};

export default SuccessMessage;
