interface StepperProps {
    numberOfSteps?: number;
    currentStep: number;
}

const Stepper = ({ numberOfSteps, currentStep }: StepperProps) => (
    <div className="w-full absolute top-[5.875-rem] xl:top-[3.5rem] left-0">
        <div className="flex flex-row shadow-inner">
            {Array.from(Array(numberOfSteps)).map((_, i) => (
                <div
                    className={`h-2 flex flex-grow items-center justify-center xxs:h-3 ${
                        currentStep >= i + 1 && numberOfSteps && numberOfSteps !== 1
                            ? "bg-stepper"
                            : "bg-mhgrey-light bg-opacity-50"
                    }`}
                />
            ))}
        </div>
    </div>
);

export default Stepper;
