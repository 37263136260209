import { Table } from "@medhelp/ui";
import { useAppSelector } from "store/hooks";
import Employee from "../Employee/Employee";
import { useTranslation } from "react-i18next";
import { getUsers } from "pages/FortnoxAdministration/redux/fortnoxAdministrationSelectors";
import { useSortableData } from "pages/dashboard/components/Tables/hooks/useSortableData";

const Employees = () => {
    const { t } = useTranslation();
    const employees = useAppSelector(getUsers);
    const [sortedData, handleSort] = useSortableData(employees, "name");

    return (
        <div className="flex flex-col pt-8">
            <Table
                headers={[
                    {
                        label: t("name"),
                        accessor: "name",
                    },
                    {
                        label: t("socialSecurityNumber"),
                        accessor: "socialSecurityNumber",
                    },
                    {
                        label: t("manager"),
                    },
                    {
                        label: "Admin",
                    },
                ]}
                handleSort={handleSort}
            >
                {sortedData.map((employee) => (
                    <Employee key={employee.userId} employee={employee} />
                ))}
            </Table>
        </div>
    );
};

export default Employees;
