import { useTranslation } from "react-i18next";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "store/hooks";
import { thunkActions } from "../../../redux";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { getDateTimeDisplayValue } from "utils/date";
import { toast } from "react-toastify";
import { DeclineCaseModal, ConfirmRehabCaseModal } from "..";
import EventTag from "@medhelp/ui/Tag/EventTag";
import PrimaryButton from "components/PrimaryButton";
import Icon from "components/Icon";
import SecondaryButton from "components/SecondaryButton";

interface IProps {
    healthCase: IHealthCase;
}

const CaseRecommendation = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const [confirmCaseModalIsOpen, setConfirmCaseModalIsOpen] = useState(false);
    const [declineCaseModalIsOpen, setDeclineCaseModalIsOpen] = useState(false);

    const handleStartCase = () => {
        if (healthCase.id && healthCase.start) {
            dispatch(
                thunkActions.activateHealthCase({
                    healthCaseId: healthCase.id,
                    start: healthCase.start,
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("caseStarted")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("caseNotStarted")}`, {
                        type: "error",
                    }),
                );
        }
    };

    return (
        <div className="border-l-8 border-mhred-alert3 mx-8 mb-12 py-8 bg-white">
            <div className="flex flex-col sm:flex-row justify-between ml-5 mr-5 sm:mr-8">
                <div className="flex">
                    <div className="mr-4 mt-1">
                        <Icon icon="info" stroke="#FF5637" fill="none" />
                    </div>
                    <p className="font-bold">{`${t("newCase")} - ${healthCase.initiationCause}`}</p>
                </div>
                <div>
                    <EventTag id="case-recommendation-event" text={t("triggeredByTheSystem")} />
                </div>
            </div>
            <div className="mt-2 mx-5 sm:mx-13 sm:w-3/4">
                <p className="text-sm text-grey-600 mb-4">{`${t("sent")}: ${getDateTimeDisplayValue(
                    healthCase.created,
                    "d MMM yyyy, HH:mm",
                )}`}</p>
                <p className="mb-5">
                    {t(`caseRecommendation.${healthCase.type}`, {
                        cause: healthCase.initiationCause,
                        code: healthCase.initiationCauseCode,
                    })}
                </p>
                <div className="flex flex-col sm:flex-row gap-2 sm:gap-0">
                    <PrimaryButton
                        id="case-recommendation-primary-button"
                        text={healthCase.type === "healthpromotion" ? t("start") : t("confirmCase")}
                        onClick={
                            healthCase.type === "healthpromotion"
                                ? handleStartCase
                                : () => setConfirmCaseModalIsOpen(!confirmCaseModalIsOpen)
                        }
                        className="sm:mr-3 w-full flex justify-center sm:w-65"
                    />
                    {healthCase.type === "healthpromotion" && (
                        <SecondaryButton
                            id="case-recommendation-secondary-button"
                            text={t("declineRecommendation")}
                            onClick={() => setDeclineCaseModalIsOpen(!declineCaseModalIsOpen)}
                            className="w-full flex justify-center sm:w-65"
                        />
                    )}
                </div>
            </div>
            {declineCaseModalIsOpen && (
                <DeclineCaseModal
                    healthCase={healthCase}
                    modalIsOpen={declineCaseModalIsOpen}
                    setModalIsOpen={() => setDeclineCaseModalIsOpen(!declineCaseModalIsOpen)}
                />
            )}
            {confirmCaseModalIsOpen && (
                <ConfirmRehabCaseModal
                    healthCase={healthCase}
                    modalIsOpen={confirmCaseModalIsOpen}
                    setModalIsOpen={() => setConfirmCaseModalIsOpen(!confirmCaseModalIsOpen)}
                />
            )}
        </div>
    );
};

export default CaseRecommendation;
