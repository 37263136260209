import Icon from "components/Icon/Icon";

const NewsWidgetLoader = () => {
    return (
        <div className="widget shadow-lg flex flex-col bg-primaryBg text-primaryText animate-pulse">
            <div className="flex flex-row items-center justify-between border-b border-mhgrey-light pl-6 pr-3 widget-container-header">
                <div>
                    <p className="font-bold">Medhelp News</p>
                </div>
            </div>
            <div className="height-276">
                <NewsWidgetLoaderAccordion />
                <NewsWidgetLoaderAccordion />
            </div>
        </div>
    );
};

export default NewsWidgetLoader;

const NewsWidgetLoaderAccordion = () => (
    <>
        <div className="flex h-30 items-center w-full border-b border-mhgrey-light bg-pageBg px-8">
            <div className="pt-6 h-full w-full">
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10/12 my-3"></div>
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 my-3"></div>
                <div className="h-1.5 bg-gray-100 rounded-full dark:bg-gray-700 w-1/4 my-3"></div>
            </div>
            <div className="flex justify-center items-center">
                <Icon icon="caretDown" />
            </div>
        </div>
    </>
);
