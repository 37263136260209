import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { getCaseActivities } from "../../../../redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { Error, RadioButtonFilter } from "@medhelp/ui";
import { Status } from "@medhelp/ui/LoadableComponent";
import { CaseActivityItem } from "pages/FollowUp/components/HealthCaseManagement";
import CaseActivitiesLoading from "./CaseActivitiesLoading";

interface IProps {
    healthCase: IHealthCase;
}

const CaseContentContainer = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const caseActivities = useAppSelector(getCaseActivities(healthCase.id));
    const [open, setOpen] = useState(false);
    const [selectedFilterType, setSelectedFilterType] = useState<"all" | "chain" | "user">("all");

    const buttonValues = [
        {
            text: t("all"),
            onClick: () => setSelectedFilterType("all"),
            checked: selectedFilterType === "all",
            key: "filter-activities-button-all",
        },
        {
            text: t("chain"),
            onClick: () => setSelectedFilterType("chain"),
            checked: selectedFilterType === "chain",
            key: "filter-activities-button-chain",
        },
        {
            text: t("manual"),
            onClick: () => setSelectedFilterType("user"),
            checked: selectedFilterType === "user",
            key: "filter-activities-button-manual",
        },
    ];

    const getFilteredCaseActivities = () => {
        if (selectedFilterType === "all") {
            return caseActivities;
        }
        return caseActivities.filter((activity) => activity.source === selectedFilterType);
    };

    if (healthCase.healthCaseSubStatus.activities === Status.PENDING) {
        return <CaseActivitiesLoading />;
    }

    if (healthCase.healthCaseSubStatus.activities === Status.ERROR) {
        return <Error text={t("couldNotFetchContent")} />;
    }

    return (
        <>
            <div className="px-8">
                <RadioButtonFilter
                    filterText={t("showTypeOfActivity")}
                    buttonValues={buttonValues}
                    open={open}
                    setOpen={() => setOpen(!open)}
                />
            </div>
            {getFilteredCaseActivities().map((activity) => (
                <CaseActivityItem
                    key={`case-activity-item-${activity.id}`}
                    activity={activity}
                    healthCase={healthCase}
                />
            ))}
        </>
    );
};

export default CaseContentContainer;
