import { IFile } from "../../hooks";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { useTranslation } from "react-i18next";
import Appendix from "../Appendix";
import UploadButton from "../UploadButton";

interface IProps {
    getInputProps: () => DropzoneInputProps;
    getRootProps: ({ className }: { className: string }) => DropzoneRootProps;
    removeFile: (name: string) => void;
    open: () => void;
    files: IFile[];
}
const UploadFileMedicalCertificate = ({ getInputProps, getRootProps, removeFile, open, files }: IProps) => {
    const { t } = useTranslation();
    return (
        <>
            <UploadButton
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                open={open}
                text={t("uploadButton")}
            />
            <Appendix
                images={files.map(({ name, preview }) => ({
                    fileName: name,
                    url: preview,
                }))}
                onClick={removeFile}
            />
        </>
    );
};

export default UploadFileMedicalCertificate;
