import { useTranslation } from "react-i18next";
import { ReactComponent as CompanyIcon } from "../../icons/building.svg";
import { ReactComponent as PersonIcon } from "../../icons/person.svg";
import Icon from "components/Icon";
import { getSsnWithDash } from "utils/numbers";
import { ReactComponent as PhoneIcon } from "icons/phone.svg";
import { IPowerbarSearch } from "interfaces/ISearch";
import { useCallback, useMemo } from "react";
import { getSelectedName } from "components/PowerbarSearch";
import TertiaryButton from "components/TertiaryButton";

interface IProps extends IPowerbarSearch {
    inputText: string;
    assumeUserClicked: (userAccountId: number) => void;
}
const PowerbarSearchItem = (searchResult: IProps) => {
    const { t } = useTranslation();
    const { inputText, assumeUserClicked } = searchResult;

    const searchText = useMemo(() => getSelectedName(searchResult), [searchResult]);

    const getBoldedSearch = useCallback((searchResult: string, query: string) => {
        const index = searchResult.toUpperCase().indexOf(query.toUpperCase());
        if (!query.toUpperCase() || index === -1) return searchResult;
        const length = query.toUpperCase().length;
        return (
            <p>
                {searchResult.substring(0, index)}
                <b>{searchResult.substring(index, index + length)}</b>
                {searchResult.substring(index + length)}
            </p>
        );
    }, []);

    const boldSearchQuery = useCallback(
        (searchResultText: string, isSsn = false, query = "") => {
            if (searchResultText.toLowerCase()) {
                if (isSsn) return <p className="font-bold">{getSsnWithDash(searchResultText)}</p>;
                else {
                    return getBoldedSearch(searchResultText, query);
                }
            } else {
                return isSsn ? <p>{getSsnWithDash(searchResultText)}</p> : <p>{searchResultText}</p>;
            }
        },
        [getBoldedSearch],
    );

    const isCompany = !searchResult.ssn;

    return (
        <div className="w-full p-3.5 border-b border-grey-200 flex">
            <div className="mr-2 mt-1">
                <div className="bg-mhgrey-light rounded-full h-10 w-10 flex items-center justify-center">
                    {isCompany ? <CompanyIcon className="w-4 h-4" /> : <PersonIcon className="w-4 h-4" />}
                </div>
            </div>
            <div className="w-full text-left flex flex-col">
                {boldSearchQuery(searchText, false, inputText)}
                {searchResult.endedEmployment && (
                    <div className="flex">
                        <p className="text-xs rounded-md p-1 bg-mhgrey-pale text-white">
                            Avslutad anställning / Ended employment
                        </p>
                    </div>
                )}
                <div className="text-grey-500 lg:flex">
                    {isCompany && <p>{`Partner: ${searchResult.partner}`}</p>}
                    {!isCompany && (
                        <>
                            {boldSearchQuery(searchResult.ssn!, true)}
                            <div className="lg:before:content-['\|'] lg:mx-2" />
                            <p>{`${t("employNr")}: ${searchResult.employmentNumber}`}</p>
                            <div className="lg:before:content-['\|'] lg:mx-2" />
                            <p>{`${t("dep")}: ${searchResult.departments?.join(", ")}`}</p>
                            <div className="lg:before:content-['\|'] lg:mx-2" />

                            <div className="flex items-center">
                                <PhoneIcon className="h-3 mr-2" />
                                {boldSearchQuery(searchResult.phoneNumber ?? "")}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {!isCompany && searchResult.userAccountId && (
                <TertiaryButton
                    id="powerbar-search-assume-user"
                    className="px-2 bg-mhgrey-medium border-none rounded-md"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                        assumeUserClicked(searchResult.userAccountId!);
                    }}
                >
                    <Icon icon="switchAccount" size={24} className=" text-mhdarkgreen-original" />
                </TertiaryButton>
            )}
        </div>
    );
};
export default PowerbarSearchItem;
