import ErasureSettings from "./Settings";
import { Spinner } from "components/Spinner";
import { useCallback, useMemo } from "react";
import { EmployeeHistory, ICompanyErasureConfig } from "pages/Administration/types";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import {
    getErasureSettingPageProps,
    getErasureFinishedSettingsData,
    getSelectedCompanyId,
} from "pages/Administration/redux/administrationSelectors";
import {
    getCompanyDataErasureFinishedConfiguration,
    postCompanyDataErasureFinishedConfiguration,
} from "pages/Administration/redux/administrationAsyncActions";

export default function Index() {
    const dispatch = useAppDispatch();
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);

    const erasureSettingsData = useAppSelector(getErasureFinishedSettingsData);
    const erasureSettingsProps = useAppSelector(getErasureSettingPageProps);

    const handleOnCheckedChange = useCallback(
        (propKey: keyof ICompanyErasureConfig, value: number | null | boolean) => {
            if (typeof value === "number" && value < 0) return;
            dispatch(
                administrationActions.setErasureFinishedValue({
                    key: propKey,
                    value,
                }),
            );
        },
        [dispatch],
    );

    const handleActiveInputOnChange = useCallback(
        (input?: EmployeeHistory) => {
            dispatch(administrationActions.setFinishedErasureActiveInput(input));
        },
        [dispatch],
    );

    const handleSaveClicked = useCallback(() => {
        if (selectedCompanyId)
            dispatch(
                postCompanyDataErasureFinishedConfiguration({
                    ...erasureSettingsProps.companyErasureConfig,
                    companyId: selectedCompanyId,
                }),
            );
    }, [dispatch, erasureSettingsProps.companyErasureConfig, selectedCompanyId]);

    useMemo(() => {
        if (selectedCompanyId) dispatch(getCompanyDataErasureFinishedConfiguration(selectedCompanyId));
    }, [dispatch, selectedCompanyId]);

    return !erasureSettingsData ? (
        <Spinner />
    ) : (
        <ErasureSettings
            {...erasureSettingsProps}
            onPropChange={handleOnCheckedChange}
            setActiveInput={handleActiveInputOnChange}
            onSaveClicked={handleSaveClicked}
        />
    );
}
