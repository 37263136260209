import Icon from "components/Icon";
import PhoneNumberComponent from "components/PhoneNumberComponent";
import { ILoginInputSharedProps } from "pages/Administration/types";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { formatSavedPhoneNumber } from "utils/phone";

export interface IProps {
    input: ILoginInputSharedProps;
    handleInputOnChange: (value: string) => void;
    handleButtonClick: (button: "edit" | "delete" | "save") => void;
}
const PhoneNumberControl = ({ input, handleButtonClick, handleInputOnChange }: IProps) => {
    const { t } = useTranslation("administration");

    const handleSavedNumber = (): string => {
        const value = input.inputValue;
        if (!value) return "+";
        else if (value.includes("-")) return value;
        else return formatSavedPhoneNumber(value);
    };

    return (
        <div id="phone-number-control">
            <PhoneNumberComponent
                countryHeader={t("country")}
                phoneNumberHeader={t("mobileNo")}
                value={handleSavedNumber()}
                onChange={handleInputOnChange}
                useCustomCountryList
                className="mb-6"
                validateFields
                disabled={input.disabled}
            >
                <div className="mt-8 flex">
                    {input.isLoading && (
                        <div className="ml-3 flex absolute left-[274px]">
                            <div className="mt-3.5">
                                <TailSpin color="#3b3b36" height={20} width={20} />
                            </div>
                        </div>
                    )}
                    <button
                        className={`h-12 w-10 mx-3 flex items-center justify-center ${
                            input.errorMessage
                                ? "opacity-50 cursor-not-allowed bg-primaryButtonDisabled text-mhgrey-disabled"
                                : "bg-mhneongreen-original cursor-pointer"
                        }`}
                        onClick={() => handleButtonClick(input.isDirty ? "save" : "edit")}
                        disabled={!!input.errorMessage}
                    >
                        <Icon icon={input.isDirty ? "save" : "edit"} className="text-mhdarkgreen-original" />
                    </button>
                    {input.loginCredential.credentialType !== 2 && (
                        <button
                            className="h-12 w-10 bg-mhneongreen-original flex items-center justify-center"
                            onClick={() => handleButtonClick("delete")}
                        >
                            <Icon icon="trashCan" className="text-mhdarkgreen-original" />
                        </button>
                    )}
                </div>
            </PhoneNumberComponent>
        </div>
    );
};

export default PhoneNumberControl;
