import { isEmpty } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import NoUserDataMessage from "components/NoUserDataMessage";
import * as AbsenceReportSelectors from "../../pages/absencereporting/redux/absenceReportsSelectors";
import * as Types from "./ongoingAbsenceReports.types";
import OngoingNotPreliminaryAbsenceReport from "./OngoingNotPreliminaryAbsenceReport";
import OngoingPreliminaryAbsenceReport from "./OngoingPreliminaryAbsenceReport";
import { useAppSelector } from "store/hooks";

export interface IProps extends Types.ISharedProps {
    handleAbsenceReportHealthyModal: (absenceReports: IAbsenceReportExtended) => void;
    handleAbsenceReportShowMore: (absenceReports: IAbsenceReportExtended) => void;
    isCallNurse: boolean;
}
const OngoingAbsenceReports = ({
    handleAbsenceReportEdit,
    handleAbsenceReportDeleteModal,
    handleAbsenceReportHealthyModal,
    isCallNurse,
}: IProps) => {
    const { t } = useTranslation();

    const ongoingAbsenceReports = useAppSelector(AbsenceReportSelectors.getOngoingReports);
    const onGoingAbsenceReportsIsPreliminary = useAppSelector(AbsenceReportSelectors.getAbsencesBasedOnPreliminary());
    const onGoingAbsenceReportsIsNotPreliminary = useAppSelector(
        AbsenceReportSelectors.getAbsencesBasedOnPreliminary(false),
    );
    const selectedAbsenceReport = useAppSelector(AbsenceReportSelectors.getSelectedAbsenceReport);

    if (isEmpty(ongoingAbsenceReports))
        return <NoUserDataMessage id="noOngoingAbsenceDataMessage" message={t("noOngoingAbsence")} />;
    return (
        <div>
            <div>
                {onGoingAbsenceReportsIsPreliminary?.map((item, index) => (
                    <OngoingPreliminaryAbsenceReport
                        key={`ongoingPreliminaryAbasenceReport-${index}`}
                        item={item}
                        handleAbsenceReportEdit={handleAbsenceReportEdit}
                        handleAbsenceReportDeleteModal={handleAbsenceReportDeleteModal}
                        isCallNurse={isCallNurse}
                    />
                ))}
            </div>
            {onGoingAbsenceReportsIsNotPreliminary?.map((item, index) => (
                <OngoingNotPreliminaryAbsenceReport
                    key={`ongoingNotPreliminaryAbasenceReport-${index}`}
                    absenceReport={item}
                    handleAbsenceReportHealthyModal={handleAbsenceReportHealthyModal}
                    handleAbsenceReportEdit={handleAbsenceReportEdit}
                    handleAbsenceReportDeleteModal={handleAbsenceReportDeleteModal}
                    selectedAbsenceReport={selectedAbsenceReport!}
                />
            ))}
        </div>
    );
};

export default OngoingAbsenceReports;
