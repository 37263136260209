import { selectors as AbsenceReportsSelectors } from "../redux";
import * as UserSelectors from "store/userSelectors";
import { Guid } from "utils/guid";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

export enum AbsenceLoadStatus {
    DONE,
    LOADING,
    ERROR,
}
const useAbsenceLoadStatus = () => {
    const { routeEmploymentGuid } = useParams();
    const [status, setStatus] = useState(AbsenceLoadStatus.LOADING);
    const absenceReportsSlice = useAppSelector(AbsenceReportsSelectors.getAbsenceReportsSlice);
    const userContext = useAppSelector(UserSelectors.getUserContext);
    useEffect(() => {
        // If employee -> Have employment guid
        if (
            Guid.isValid(userContext.EmploymentGuid) &&
            (userContext.HasAbsenceReporting || userContext.HasCustomerSupport || userContext.HasMedicalAdvisory)
        ) {
            if (
                absenceReportsSlice.AbsenceReports &&
                absenceReportsSlice.Templates &&
                absenceReportsSlice.ConfigurationFields &&
                absenceReportsSlice.SelectedEmployee
            ) {
                setStatus(AbsenceLoadStatus.DONE);
            } else {
                setStatus(AbsenceLoadStatus.LOADING);
            }
        } else if (
            Guid.isValid(userContext.EmploymentGuid) &&
            !userContext.HasAbsenceReporting &&
            !userContext.HasCustomerSupport &&
            !userContext.HasMedicalAdvisory &&
            userContext.HasAbsenceAdministration
        ) {
            if (!routeEmploymentGuid) {
                setStatus(AbsenceLoadStatus.DONE);
            } else {
                if (absenceReportsSlice.SelectedEmployee) {
                    setStatus(AbsenceLoadStatus.DONE);
                } else {
                    setStatus(AbsenceLoadStatus.LOADING);
                }
            }
        } else {
            // If not employee
            if (
                absenceReportsSlice.AbsenceReports &&
                absenceReportsSlice.Templates &&
                absenceReportsSlice.ConfigurationFields &&
                absenceReportsSlice.SelectedEmployee
            ) {
                setStatus(AbsenceLoadStatus.DONE);
            } else {
                if (!absenceReportsSlice.SelectedEmployee) {
                    setStatus(AbsenceLoadStatus.DONE);
                } else {
                    setStatus(AbsenceLoadStatus.LOADING);
                }
            }
        }
    }, [absenceReportsSlice, userContext, routeEmploymentGuid]);

    useEffect(() => {
        if (absenceReportsSlice.IsOK !== null && !absenceReportsSlice.IsOK) {
            setStatus(AbsenceLoadStatus.ERROR);
        }
    }, [absenceReportsSlice]);
    return status;
};

export default useAbsenceLoadStatus;
