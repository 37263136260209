import { hasOnlyOneSelected } from "@medhelp/ui/List/CheckboxList/utils";
import { useAppSelector } from "store/hooks";
import { isEmpty } from "lodash/fp";
import { useMemo } from "react";
import { selectors, Status } from "../redux";
const useDropdownFilters = (alwaysShowCompanies = false) => {
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const status = useAppSelector(selectors.getStatus);
    const onlyShowDepartments = useAppSelector(selectors.onlyShowDepartments);
    const departments = useAppSelector(selectors.searchDepartmentsFiltered);
    const companies = useAppSelector(selectors.searchComapniesFiltered);
    const hasOneCompanySelected = useMemo(() => hasOnlyOneSelected(companies || []), [companies]);
    return useMemo(() => {
        const allFilters = {
            companies: false,
            departments: false,
            months: false,
            years: false,
            pending: status.companiesWithRegion === Status.PENDING,
        };
        switch (currentSearch) {
            case "medicalCertificate":
            case "absencePeriod":
            case "longtermAbsence":
            case "ongoingAbsence":
            case "reimbursable":
            case "recurringAbsence":
            case "cases":
            case "activities": {
                const showDeparments = (hasOneCompanySelected || onlyShowDepartments) && !isEmpty(departments);

                return {
                    ...allFilters,
                    companies: alwaysShowCompanies || !onlyShowDepartments,
                    departments: showDeparments,
                };
            }
            case "monthlyReport": {
                return {
                    ...allFilters,
                    months: true,
                    years: true,
                    companies: companies.length > 1,
                };
            }
            default: {
                return allFilters;
            }
        }
    }, [
        companies.length,
        currentSearch,
        alwaysShowCompanies,
        departments,
        hasOneCompanySelected,
        onlyShowDepartments,
        status.companiesWithRegion,
    ]);
};
export default useDropdownFilters;
