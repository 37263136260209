import { LoadableComponent } from "@medhelp/ui";
import { useBreakpoints, useSingleDatepickerProps } from "hooks";
import { isUndefined } from "lodash/fp";
import { selectors, thunkActions } from "pages/FollowUp/redux";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getEndOfDateString, isSameOrBeforeDate } from "utils/date";
import FollowUpMedicalCertificate from "./FollowUpMedicalCertificate";
import { useAppDispatch, useAppSelector } from "store/hooks";
export default function Index() {
    const dispatch = useAppDispatch();
    const { routeEmploymentGuid } = useParams();
    const [value, setValue] = useState<boolean | undefined>(undefined);
    const [startDate, setStartDate] = useState<string | undefined>(undefined);
    const [endDate, setEndDate] = useState<string | undefined>(undefined);
    const { t } = useTranslation(["followup", "translation"]);
    const status = useAppSelector(selectors.getStatus);
    const requiredMedicalCertificates = useAppSelector(selectors.getActiveRequiredMedicalCertificate);
    const MedicalRequireCertificate = useMemo(() => requiredMedicalCertificates?.[0], [requiredMedicalCertificates]);
    const breakpoints = useBreakpoints();
    const { isXMediumscreen } = breakpoints;

    const StartDate = useMemo(
        () => startDate || MedicalRequireCertificate?.startDate,
        [MedicalRequireCertificate?.startDate, startDate],
    );
    const EndDate = useMemo(
        () => endDate || MedicalRequireCertificate?.endDate,
        [MedicalRequireCertificate?.endDate, endDate],
    );

    const startDatepickerProps = useSingleDatepickerProps({
        onChangeDate: setStartDate,
        givenSelectedDate: StartDate,
        placeholder: t("addDate", { ns: "translation" }),
    });
    const endDatepickerProps = useSingleDatepickerProps({
        onChangeDate: (d) => setEndDate(d ? getEndOfDateString("days", d) : d),
        givenSelectedDate: EndDate,
        placeholder: t("addDate", { ns: "translation" }),
    });

    const SwitchValue = useMemo(
        () => (isUndefined(value) ? Boolean(MedicalRequireCertificate?.id) : value),
        [MedicalRequireCertificate?.id, value],
    );

    const HasEdited = useMemo(() => Boolean(startDate || endDate), [endDate, startDate]);

    const onChange = useCallback(() => {
        const newValue = !value;
        if (!newValue) {
            setEndDate(undefined);
            setStartDate(undefined);
        }
        if (MedicalRequireCertificate?.id) {
            dispatch(thunkActions.removeFirstDayMedicalCertificateRequired()).then(() => {
                setValue(newValue);
            });
            return;
        } else {
            setValue(newValue);
        }
    }, [MedicalRequireCertificate?.id, dispatch, value]);
    const resetDates = useCallback((event: SyntheticEvent) => {
        setEndDate(undefined);
        setStartDate(undefined);
        return event;
    }, []);
    const onSubmit = useCallback(
        (event: SyntheticEvent) => {
            if (routeEmploymentGuid && StartDate && EndDate) {
                dispatch(
                    thunkActions.saveFirstDayMedicalCertificateRequired({
                        employmentId: routeEmploymentGuid,
                        startDate: StartDate,
                        endDate: EndDate,
                    }),
                );
                if (isSameOrBeforeDate(EndDate)) {
                    setValue(undefined);
                }
                return event;
            }
            return event;
        },
        [dispatch, routeEmploymentGuid, StartDate, EndDate],
    );
    useEffect(() => {
        if (isUndefined(value)) {
            setValue(Boolean(MedicalRequireCertificate?.id));
        }
    }, [MedicalRequireCertificate?.id, value]);

    return (
        <LoadableComponent status={status.medicalCertificate} errorMessage="Error saving required medical certificate">
            <FollowUpMedicalCertificate
                active={SwitchValue}
                header={t(`medicalRequired`, {
                    value: SwitchValue ? t("yes", { ns: "translation" }) : t("no", { ns: "translation" }),
                })}
                startDatepickerProps={startDatepickerProps}
                EndDatepickerProps={endDatepickerProps}
                onChange={onChange}
                hasEdited={HasEdited}
                resetDates={resetDates}
                onSubmit={onSubmit}
                isXMediumscreen={isXMediumscreen}
            />
        </LoadableComponent>
    );
}
