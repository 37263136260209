import TertiaryButton from "components/TertiaryButton";
import PrimaryButton from "components/PrimaryButton";
import { useTranslation } from "react-i18next";
import Icon, { Size } from "components/Icon";
import { ISubScriptionAccordionProps } from "pages/Administration/types";
import { getIconByMonitorType } from "pages/Administration/utils";
import ContactKey from "../ContactKey";

const Accordion = ({
    subscriptionName,
    contactKey,
    missingContactKey,
    open,
    setOpen,
    deleteClicked,
    savelicked,
    saveDisabled,
    children,
    displayValues,
    saveIsLoading,
    subscriptionMonitorType,
    canDeleteSubscription,
    isSubsOnUserTab,
}: ISubScriptionAccordionProps) => {
    const { t } = useTranslation("administration");

    return (
        <div id="subscription-accordion" className="border-b border-grey-200">
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex flex-col">
                    <div className="px-10 py-6 w-full flex items-center">
                        <div className="w-1/2 pr-6 flex items-center">
                            <div>
                                <Icon
                                    icon={getIconByMonitorType(subscriptionMonitorType, contactKey[0], isSubsOnUserTab)}
                                    size={Size.lg}
                                />
                            </div>
                            <div className="ml-4 flex flex-col">
                                <p className="font-bold">{subscriptionName}</p>
                                {isSubsOnUserTab && (
                                    <ContactKey
                                        contactKey={contactKey}
                                        missingContactKey={missingContactKey}
                                        boldText={!subscriptionName}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="w-1/2 flex items-center">
                            <div className="w-1/4">{displayValues.isActive ? t("active") : t("inactive")}</div>
                            {displayValues.subscriptionStatuses.map((statusText, i) => (
                                <div key={statusText + i} className="w-1/4 flex">
                                    <p className="py-1 px-2 border rounded-md">{t(statusText)}</p>
                                </div>
                            ))}
                        </div>
                        <div className="flex">
                            <button
                                type="button"
                                className="h-16 w-16 flex items-center justify-center"
                                onClick={setOpen}
                            >
                                <Icon icon={open ? "caretUp" : "caretDown"} size={16} />
                            </button>
                            {canDeleteSubscription ? (
                                <button
                                    type="button"
                                    className="h-16 w-16 flex items-center justify-center"
                                    onClick={() => {
                                        if (deleteClicked) deleteClicked();
                                    }}
                                >
                                    <Icon icon="trashCan" size={24} />
                                </button>
                            ) : (
                                <span className="px-8" />
                            )}
                        </div>
                    </div>
                    {open && (
                        <div className="mx-10 mb-10 flex flex-col">
                            <div id="user-accordion-children">{children}</div>
                            <div className="my-10 flex justify-end md:mb-0">
                                <TertiaryButton
                                    id="modalActionButton"
                                    text={t("abort")}
                                    className={`flex self-center mr-6`}
                                    onClick={setOpen}
                                />
                                <PrimaryButton
                                    id="admin-accordion-save"
                                    text={t("save")}
                                    disabled={saveDisabled}
                                    onClick={savelicked}
                                    className="flex self-center"
                                    isLoading={saveIsLoading}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
