import { useAppSelector } from "store/hooks";
import { NewsModal } from "@medhelp/ui";
import { Trans, useTranslation } from "react-i18next";
import { getUserRetailer } from "store/userSelectors";

const DashboardNewsModal = () => {
    const { t } = useTranslation("dashboard");
    const activeRetailer = useAppSelector(getUserRetailer);
    const listItems: string[] = t("news.listItems", { returnObjects: true });

    return (
        <NewsModal
            header={t("news.header")}
            body={
                <>
                    <p className="font-bold text-lg text-start mb-6">{t("news.subHeader")}</p>
                    <ul className="list-disc list-outside ml-7 text-lg text-start">
                        {listItems.map((item) => (
                            <li key={item} className="mt-2">
                                <Trans components={{ italic: <i /> }}>{item}</Trans>
                            </li>
                        ))}
                    </ul>
                </>
            }
            buttonText={t("news.buttonText")}
            theme={activeRetailer}
            storage="hide-dashboard-news"
            icon="dashboardSketch"
        />
    );
};

export default DashboardNewsModal;
