import { last } from "lodash/fp";
import { followUpIndexRoutes } from "pages/FollowUp/accessRights";

export const getExternalLink = (link: string) => {
    if (link.match("://")) {
        const splitAddress = link.split("://");
        const getLinkType = last(splitAddress?.[0]?.split("/"));
        if (splitAddress?.[1] && getLinkType) {
            return `${getLinkType}://${splitAddress?.[1]}`;
        }
        return "";
    }
    return "";
};

export const getLastRouteInPathname = (route: string) => last(route.split("/"));

export const getPathname = () => window.location.pathname;

export const getIfFollowUpRoute = () => {
    const route = getPathname();
    if (!route) return undefined;
    if (route.match(/(\/followup\/search)/)) {
        return followUpIndexRoutes(getLastRouteInPathname(route)) ?? "reports";
    }
};
