import Icon from "components/Icon";
import { ChangeEvent } from "react";

interface IProps {
    placeholder: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    setRef?: (node: HTMLInputElement) => void;
    setFocus?: (value: boolean) => void;
    className?: string;
}
const Search = ({ placeholder, onChange, setRef, setFocus, className, ...rest }: IProps) => {
    return (
        <div
            className={`flex items-center pl-2 border border-grey-500 top-0 left-0 bg-pageBg w-full relative ${className}`}
        >
            <Icon icon="magnifier" className="w-4 h-4 fill-none" />
            <input
                className="w-full ml-4 search-bar bg-pageBg"
                type="text"
                data-cy="searchbarinput"
                onChange={onChange}
                ref={setRef}
                onFocus={() => setFocus && setFocus(true)}
                onBlur={() => setFocus && setFocus(false)}
                placeholder={placeholder}
                {...rest}
            />
        </div>
    );
};

export default Search;
