import { IProps, Column } from "./SearchTable";
import DefaultTableRow, { ITableRowProps, IRow, IRowProps } from "./DefaultTableRow";
import SearchTable from "./SearchTable";

export type { IRow, Column, ITableRowProps, IRowProps };

export { DefaultTableRow };
export default function Index(props: IProps) {
    return <SearchTable {...props} />;
}
