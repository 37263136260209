import { useCallback } from "react";
import { LoadableComponentStatus } from "@medhelp/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { actions, selectors } from "../../redux";
import CompaniesDropdown from "./CompaniesDropdown";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const status = useAppSelector(selectors.getStatus);
    const companies = useAppSelector(selectors.searchComapniesFiltered);
    const hasOneCompany = useAppSelector(selectors.hasOnlyOneCompany);
    const onlyShowDepartments = useAppSelector(selectors.onlyShowDepartments);

    const setCompaniesData = useCallback(
        (value: string) => {
            dispatch(
                actions.updateCompanyDepartmentSearch({
                    field: "companies",
                    value,
                }),
            );
        },
        [dispatch],
    );
    return (
        <div className="w-[335px] lg:w-[260px]" data-testid="companies-dropdown-reports">
            <CompaniesDropdown
                header={t("company")}
                data={companies || []}
                setData={setCompaniesData}
                viewElements={{
                    selectAll: !onlyShowDepartments ? t("selectAll") : undefined,
                    search: !onlyShowDepartments,
                }}
                keepClose={hasOneCompany}
                loading={status.companiesWithRegion === LoadableComponentStatus.PENDING}
            />
        </div>
    );
}
