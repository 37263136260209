/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { updateUserProfile as updateUserProfileAction } from "../followUpAsyncActions";
import { IState } from "..";

const updateUserProfile = (builder: ActionReducerMapBuilder<IState>) =>
    builder.addCase(updateUserProfileAction.fulfilled, (state, action) => {
        if (state.userEmployment) {
            state.userEmployment.contactInformation = {
                ...state.userEmployment.contactInformation,
                ...action.payload,
            };
        }
    });

export default updateUserProfile;
