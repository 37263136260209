import { IAbsenceReportEmploymentExtended } from "interfaces/IAbsenceReportEmployment";

export const getPhoneNumberWithPrefix = (selectedEmployment: IAbsenceReportEmploymentExtended): string => {
    const prefix = selectedEmployment.mainManagerPhoneNumberPrefix
        ? selectedEmployment.mainManagerPhoneNumberPrefix
        : "";
    const number =
        selectedEmployment.mainManagerPhoneNumber?.charAt(0) === "0"
            ? selectedEmployment.mainManagerPhoneNumber?.replace("0", prefix)
            : prefix + selectedEmployment.mainManagerPhoneNumber;

    return number;
};
