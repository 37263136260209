import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { debounce } from "lodash";
import { TailSpin } from "react-loader-spinner";
import { IPowerbarSearch } from "interfaces/ISearch";
import PowerbarSearchItems from "components/PowerbarSearchItems";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { ReactComponent as Magnifier } from "icons/magnifier.svg";

import Icon from "components/Icon/Icon";
export interface IPowerbarSearchProps {
    isLoading: boolean;
    handleOpenSearch: () => void;
    powerbarSearchResult: IPowerbarSearch[];
    open: boolean;
    setOpen: (open: boolean) => void;
    onChange: (value: string) => void;
    activeSearchName: string | null;
    onClose: (value: string) => void;
    noResult: boolean;
    inputText: string;
}

const PowerbarSearch = ({
    isLoading,
    handleOpenSearch,
    powerbarSearchResult,
    open,
    setOpen,
    onChange,
    activeSearchName,
    onClose,
    noResult,
    inputText,
}: IPowerbarSearchProps) => {
    const { t } = useTranslation();
    const [os, setOs] = useState<string | null>(null);
    let [inputSearchRef, setInputRef] = useState<HTMLInputElement | undefined>(undefined);
    const debounceOnChange = useMemo(() => {
        return debounce(onChange, 300);
    }, [onChange]);

    const onSearchChange = useCallback((node: HTMLInputElement) => {
        setInputRef(node);
    }, []);

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e && e.key === "s" && e.metaKey) {
                const openSearch = os === "Mac" ? e.shiftKey : e.altKey;
                if (openSearch) handleOpenSearch();
            }
        };
        document.addEventListener("keydown", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [handleOpenSearch, os]);

    useEffect(() => {
        if (os === null) {
            const os = ["Win", "Mac"];
            const currentOs = os.find((v) => navigator.userAgent.indexOf(v) >= 0);
            setOs(currentOs ?? "");
        }
    }, [os]);

    return (
        <div className="w-full  h-full max-w-lg">
            {!open && (
                <div className="w-full items-center  h-full relative flex justify-center group">
                    <div
                        className={`max-xl:hidden xl:pl-5 w-full text-black  border-r h-full border-grey-300 flex items-center bg-menuNavInputBg ${
                            activeSearchName ? " bg-grey-50" : " hover:bg-grey-100 menuNavInputBg"
                        }`}
                    >
                        <button
                            className="w-full h-full flex items-center rounded-none cursor-pointer"
                            onClick={handleOpenSearch}
                        >
                            <Magnifier className="w-4 h-4 ml-4 fill-none" />

                            <p className="pl-4 text-left">{activeSearchName ?? t("searchCompanyOrEmp")}</p>
                        </button>
                        {activeSearchName && (
                            <button
                                tabIndex={-1}
                                aria-hidden="true"
                                className="h-9 w-10 flex items-center justify-center"
                                onClick={() => onClose("")}
                            >
                                <CloseIcon className="w-3 text-mhbeige fill-mhbeige" />
                            </button>
                        )}
                    </div>

                    <div
                        className="xl:hidden w-12 h-12 flex justify-center items-center cursor-pointer text-menuNavColorPrimary"
                        onClick={handleOpenSearch}
                    >
                        <button>
                            <Icon icon="searchMenu" size={24} />
                        </button>
                    </div>

                    <div className="absolute z-20 top-11 text-white hidden flex-col items-center xl:group-hover:flex">
                        <div className="h-2.5 w-2.5 bg-menuNavBg transform rotate-45 absolute" />
                        <div className="p-2 mt-1.5 rounded bg-menuNavBg flex flex-col items-center text-menuNavColorPrimary">
                            <p>{t("openSearchWith")}</p>
                            <div className="flex mt-1 text-menuNavColorPrimary ">
                                {os === "Mac" && (
                                    <>
                                        <Icon icon="macLogo" className="border rounded px-1" size={27} />

                                        <Icon icon="shift" className="border mx-2 rounded px-1" size={27} />
                                    </>
                                )}
                                {os === "Win" && (
                                    <>
                                        <Icon icon="winLogo" className="border rounded px-1" size={26} />
                                        <p className="border rounded mx-2 px-1">Alt</p>
                                    </>
                                )}
                                <p className="border rounded px-1">S</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ReactModal
                id="powerbar-search-modal"
                isOpen={open}
                className="absolute w-full top-1 max-w-3xl xl:ml-65 rounded-md"
                style={{
                    overlay: {
                        zIndex: 10,
                        backgroundColor: "rgba(94, 95, 86, 0.5)",
                    },
                    content: { background: "#FFFFFF" },
                }}
                onRequestClose={() => setOpen(false)}
            >
                <div id="search-open" className="flex flex-col">
                    <div className="h-15 flex items-center border-b border-grey-350 text-menuNavColorPrimary">
                        <Magnifier className="w-4 h-4 ml-4 fill-none" />

                        <input
                            ref={onSearchChange}
                            className="w-full ml-4 hide-outline"
                            type="text"
                            placeholder={t("openSearchPlaceholder")}
                            defaultValue={activeSearchName || ""}
                            onChange={(e) => debounceOnChange(e.target.value)}
                            autoFocus
                        />
                        {isLoading && <TailSpin color="#3b3b36" height={20} width={20} />}
                        <button
                            className="h-9 w-10 flex items-center justify-center"
                            onClick={() => {
                                if (inputSearchRef) {
                                    inputSearchRef.value = "";
                                }
                                onChange("");
                            }}
                        >
                            <CloseIcon className="w-3 text-mhdarkgreen-original" />
                        </button>
                    </div>

                    <div className="max-h-md overflow-auto rounded-md">
                        <div className="border-b border-grey-200">
                            {noResult && (
                                <p className="ml-4 my-3 text-mhred-alert2 underline flex text-lg">{t("noResult")}</p>
                            )}
                            {!noResult && (
                                <p className="ml-4 my-3 text-grey-600">
                                    {powerbarSearchResult.length > 0 ? t("suggestedSearches") : t("latestChosen")}
                                </p>
                            )}
                        </div>
                        {!noResult && <PowerbarSearchItems setOpen={setOpen} inputText={inputText} />}
                    </div>
                </div>
            </ReactModal>
        </div>
    );
};

export default PowerbarSearch;
