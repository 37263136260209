import { Card } from "@medhelp/ui";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import EmployeeMobileTable from "./EmployeeMobileTable";
import { selectors } from "store/organization";
import EmployeeSearchFooter from "../EmployeeSearchFooter";
import useRows from "./Rows";
import Loader from "../Loader";
import SearchAbsenceRow from "../SearchAbsenceRow";

export default function Index() {
    const { t } = useTranslation("followup");
    const searchStatus = useAppSelector(selectors.getCompaniesDepartmentsSearchStatus);
    const mobileSearchProps = useAppSelector(selectors.getTableEmployeeSearchProperties("employee", t));
    const rows = useRows(mobileSearchProps);
    return (
        <Card className="px-0 p-0">
            <EmployeeMobileTable
                rows={rows}
                footer={<EmployeeSearchFooter />}
                loading={Boolean(searchStatus.employee && searchStatus.employee === "pending")}
                // loading
                placeholder={<Loader />}
                RowContainer={SearchAbsenceRow}
            />
        </Card>
    );
}
