import Icon from "components/Icon";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import {
    RoleSubscriptionNewRow,
    RoleSubscriptionSavedRow,
    RoleSubscriptionStandardSettings,
} from "pages/Administration/components";
import { IRoleSubscriptionsSettingsPageProps } from "pages/Administration/types";
import { useTranslation } from "react-i18next";

const RoleSubscriptionsSettings = ({
    selectedRoleSubscriptions,
    deleteClicked,
    roleSubscriptionTypes,
    newRowProps,
    showStandardSubscriptionOptions,
    onRoleChanged,
    selectedRole,
    enableSave,
    isDirty,
    saveIsLoading,
    handleSubmit,
    addClicked,
    abortClicked,
}: IRoleSubscriptionsSettingsPageProps) => {
    const { t } = useTranslation("administration");
    return (
        <div id="department-manager-settings-page-container">
            <h3>{t("roleSubscriptionSettings")}</h3>
            {selectedRoleSubscriptions.length < 1 && (
                <p className="h-14 w-full items-center flex">
                    {showStandardSubscriptionOptions ? t("createStandardSubscriptionOn") : t("noSavedSubscriptions")}
                </p>
            )}
            {roleSubscriptionTypes.length > 0 && (
                <RoleSubscriptionSavedRow roleSubscriptionTypes={roleSubscriptionTypes} deleteClicked={deleteClicked} />
            )}
            {newRowProps.newRow && <RoleSubscriptionNewRow {...newRowProps} />}
            {showStandardSubscriptionOptions && (
                <RoleSubscriptionStandardSettings
                    selectedRole={selectedRole}
                    onRoleChanged={onRoleChanged}
                    deleteClicked={() => deleteClicked(undefined)}
                />
            )}
            <div className="w-full mt-6 flex flex-col xxs:flex-row xxs:justify-between">
                <div className="w-full flex">
                    <SecondaryButton
                        id="subscription-notification-settings-add"
                        text={t("addSubcscription")}
                        leftIcon={<Icon icon="plus" size={22} />}
                        onClick={() => addClicked()}
                        disabled={newRowProps.newRow !== null || isDirty || showStandardSubscriptionOptions}
                    />
                    {selectedRoleSubscriptions.length < 1 && newRowProps.newRow === null && (
                        <SecondaryButton
                            id="subscription-notification-settings-add"
                            text={t("addStandardSubcscriptions")}
                            leftIcon={<Icon icon="plus" size={22} />}
                            onClick={() => addClicked(true)}
                            disabled={
                                newRowProps.newRow !== null ||
                                isDirty ||
                                (showStandardSubscriptionOptions && !selectedRole)
                            }
                            className="ml-3"
                        />
                    )}
                </div>
                <div className="flex">
                    {enableSave && !!selectedRole && (
                        <SecondaryButton
                            id="subscription-notification-settings-add"
                            text={t("abort")}
                            onClick={abortClicked}
                            className="mr-3"
                        />
                    )}
                    <PrimaryButton
                        id="subscription-notification-settings-save"
                        text={t("save")}
                        onClick={handleSubmit}
                        disabled={!enableSave}
                        isLoading={saveIsLoading}
                        styleText="text-center"
                    />
                </div>
            </div>
        </div>
    );
};

export default RoleSubscriptionsSettings;
