import { debounce, flow, get, isEqual } from "lodash/fp";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import Icon from "components/Icon";
import CountryCode from "./CountryCodes.json";
import { useElementClicked } from "hooks";
import PhonePrefixItem from "./PhonePrefixItem";
import { filterCorrectPhonePrefix, IPhonePrefix } from "./PhonePrefix.utils";
import { twMerge } from "tailwind-merge";
interface IProps {
    /**
     *  country phone prefix as string `+country_dial_code`
     */
    value: string;
    /**
     *  React state to controll new values selected
     */
    setValue: (value: string) => void;
    customCountryCodes?: IPhonePrefix[];
    containerClassName?: string;
    className?: string;
    disabled?: boolean;
    closeOnSelect?: boolean;
}
const PhonePrefix = ({
    value,
    setValue,
    customCountryCodes,
    containerClassName,
    className,
    disabled,
    closeOnSelect,
}: IProps) => {
    const [search, setSearch] = useState("");
    const [expanded, setExpanded] = useState(false);
    const [firstClick, setFirstClick] = useState(false);

    const [searchNode, setSearchNodeState] = useState<HTMLInputElement | undefined>(undefined);

    const countryCodes = customCountryCodes ? customCountryCodes : CountryCode;

    const setExpandedClickOutSide = useCallback(
        (value: boolean) => {
            if (expanded && !firstClick) {
                setExpanded(value);
                return;
            }
            if (firstClick) {
                setFirstClick(false);
            }
        },
        [expanded, firstClick],
    );
    const ref = useElementClicked(setExpandedClickOutSide);

    const setSearchNode = useCallback((node: HTMLInputElement) => {
        setSearchNodeState(node);
    }, []);

    const Header = useMemo(() => {
        const header = countryCodes.find(flow(get("dial_code"), isEqual(value)));
        if (!header) return countryCodes.find(flow(get("dial_code"), isEqual("+46")));
        return header;
    }, [countryCodes, value]);
    const setSearchQuery = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }, []);
    const DebounceSearch = useMemo(() => {
        const debounceSearchQuery = debounce(20, setSearchQuery);
        return debounceSearchQuery;
    }, [setSearchQuery]);

    useEffect(() => {
        if (searchNode && expanded && Header) {
            searchNode.focus();
            document.getElementById(`phone-prefix-item-${Header.dial_code}-${Header.name}`)?.scrollIntoView();
            return () => {
                setSearch("");
            };
        }
    }, [Header, expanded, searchNode]);

    if (!Header) return null;
    return (
        <div className={twMerge("relative max-w-[112px]", containerClassName)}>
            <div
                role="button"
                data-testid="phone-prefix"
                ref={ref}
                onClick={() => {
                    if (disabled) return;
                    const newExpanded = !expanded;
                    if (newExpanded) {
                        setFirstClick(true);
                    }
                    setExpanded(!expanded);
                }}
                className={twMerge(
                    "h-12 flex transition-all select-none gap-1 items-center justify-between w-28 border bg-pageBg px-2",
                    disabled ? "border-grey-500 hover:cursor-not-allowed" : "border-mhdarkgreen-original",
                    className,
                )}
            >
                <PhonePrefixItem {...Header} isPlaceholder />
                <Icon icon={expanded ? "caretUp" : "caretDown"} size={8} />
            </div>
            {expanded && (
                <div
                    className={twMerge(
                        "absolute min-w-xs border border-mhdarkgreen-original border-t-0 z-10",
                        customCountryCodes ? "" : "xxs:min-w-lg",
                    )}
                >
                    <div className="bg-white">
                        <div className="p-1">
                            <div className="flex items-center pl-2 border border-mhdarkgreen-original absolute top-0 left-0 bg-white w-full xxs:relative xxs:z-20">
                                <Icon icon="magnifier" className="w-4 h-4 fill-none" />
                                <input
                                    ref={setSearchNode}
                                    className="w-full h-8 ml-4 search-bar"
                                    type="text"
                                    data-cy="searchbarinput"
                                    onChange={DebounceSearch}
                                    placeholder={""}
                                />
                            </div>
                        </div>
                        <div className="max-h-52 overflow-auto z-10">
                            {countryCodes.filter(filterCorrectPhonePrefix(search)).map((item) => (
                                <div
                                    key={`phone-prefix-item-${item.dial_code}-${item.name}`}
                                    id={`phone-prefix-item-${item.dial_code}-${item.name}`}
                                    role="button"
                                    className={twMerge(
                                        "py-2 px-1 cursor-pointer hover:bg-grey-100",
                                        Header.name === item.name ? "bg-grey-100" : "",
                                    )}
                                    onClick={() => {
                                        if (closeOnSelect && expanded) setExpanded(!expanded);
                                        setValue(item.dial_code);
                                    }}
                                >
                                    <PhonePrefixItem {...item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PhonePrefix;
