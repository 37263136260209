import { ReactComponent as QuestionMark } from "icons/questionMark.svg";
import { ReactComponent as TriangleDown } from "icons/triangleDown.svg";
import { ReactComponent as TriangleUp } from "icons/triangleUp.svg";
import { useTranslation, Trans } from "react-i18next";
import { ReactNode, useEffect, useState } from "react";
import { uniqueId } from "lodash";
import { setOverflow } from "utils/setOverflow";
import { useKeyDownEvent, useElementClicked } from "hooks";
import { useLocation } from "react-router-dom";
import { ITranslatedObject } from "pages/Support/pages/HelpGuide/HelpGuide";
import { getModalState } from "store/modal/modalSelectors";
import { useAppSelector } from "store/hooks";
import Icon from "components/Icon";

const HelpMenu = () => {
    const path = useLocation().pathname;
    const [page, setPage] = useState<string | null>(null);
    const { t } = useTranslation("support");
    useEffect(() => {
        if (path.includes("dashboard")) setPage("dashboard");
        else if (path.includes("absencereporting")) setPage("absencereporting");
        else if (path.includes("followup/employee")) setPage("followup/employee");
        else if (path.includes("followup/search")) setPage("followup/search");
        else setPage("");
    }, [path]);

    const translated = t(`${page}.content`, {
        returnObjects: true,
    }) as ITranslatedObject[];
    [];
    const [open, setOpen] = useState(false);
    const setNode = useElementClicked(setOpen);
    useKeyDownEvent("Escape", () => setOpen(false));

    useEffect(() => {
        if (open) setOverflow("hidden");
        return () => {
            setOverflow("auto");
        };
    }, [open]);

    const isWizard = path.includes("me/create");

    const modalIsOpen = useAppSelector(getModalState("allModals"));

    if (!page) {
        return null;
    }

    return (
        <div
            className={`
                ${open ? "fixed h-full w-full bg-mhgrey-dark bg-opacity-50 z-50" : ""}
            `}
        >
            {!open && (
                <div
                    onClick={() => setOpen(!open)}
                    className={`z-10 py-4 fixed top-1/2 w-10 h-24 shadow-lg bg-primaryBg font-bold flex-col justify-evenly items-center cursor-pointer hidden xs:flex ${
                        open ? "right-88" : "right-0"
                    }`}
                >
                    <div className="-rotate-90 py-3 text-menuNavColorSecondary">{t("help")}</div>
                    <div>
                        <QuestionMark />
                    </div>
                </div>
            )}
            {!open && (
                <div
                    onClick={() => setOpen(true)}
                    className={`h-18 w-18 shadow-2xl mr-5 flex xs:hidden z-11 justify-center items-center fixed right-0 bottom-0 rounded-full bg-primaryBg ${
                        isWizard ? "mb-32" : "mb-5"
                    } ${modalIsOpen ? "hidden" : "flex"}`}
                >
                    <div className="flex flex-col items-center text-menuNavColorSecondary">
                        <QuestionMark />
                        <div>{t("help")}</div>
                    </div>
                </div>
            )}

            {open && (
                <div className="h-screen fixed right-0 bg-white w-screen xs:w-88 border overflow-auto">
                    <div className="hidden xs:flex justify-end pr-3 pt-3">
                        <button onClick={() => setOpen(false)} className="p-2 ">
                            <Icon icon="close" size={20} />
                        </button>
                    </div>
                    <div className="flex h-10 bg-pageBg items-center justify-between xs:hidden">
                        <div className="w-4" />
                        <div className="flex items-center">
                            <QuestionMark />
                            <div className="pl-1.5">{t("help")}</div>
                        </div>
                        <button onClick={() => setOpen(false)} className="flex h-10 w-10 items-center justify-center">
                            <Icon icon="close" size={12} />
                        </button>
                    </div>
                    <div ref={setNode}>
                        <div className="px-6 pb-8 pt-6">
                            <h3>{t(`${page}.heading`)}</h3>
                            <p className="text-sm pt-4">{t(`${page}.intro`)}</p>
                        </div>
                        <div className="border-t pb-30">
                            {translated?.map((item) => (
                                <HelpSubMenu heading={item.heading} key={item.heading}>
                                    <ul className="pl-6">
                                        {item.content.map((text: string) => (
                                            <li key={uniqueId()} className="list-disc mb-5">
                                                <Trans i18nKey={text} />
                                            </li>
                                        ))}
                                    </ul>
                                </HelpSubMenu>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

interface IHelpSubMenu {
    heading: string;
    children: ReactNode;
}

const HelpSubMenu = ({ heading, children }: IHelpSubMenu) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="border-b">
            <div
                className="h-15 flex items-center justify-between cursor-pointer pl-6 pr-4"
                onClick={() => setOpen(!open)}
            >
                <p className="font-bold text-sm">{heading}</p>
                <div className="pointer-events-none">{open ? <TriangleUp /> : <TriangleDown />}</div>
            </div>
            {open && (
                <div className="px-6 pt-2">
                    <div className="text-sm">{children}</div>
                </div>
            )}
        </div>
    );
};

export default HelpMenu;
