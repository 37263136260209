import { useTranslation } from "react-i18next";
import DropdownCheckboxFilter from "pages/FollowUp/components/DropdownCheckboxFilter";
import { IBelongingPageProps } from "pages/Administration/types";
import { AdminSearchResult } from "../..";
import { Heading, Select } from "@medhelp/ui";
import { twMerge } from "tailwind-merge";
import { TailSpin } from "react-loader-spinner";
import AdminInput from "../../AdminInput";

const Belonging = ({
    mainManagagerSelectedName,
    mainManagagerSearchValue,
    mainManagerSearchProps,
    costCenterValues,
    displayList,
    onChange,
    disableAll,
    organizationNumber,
    organizationNumberErrorMsg,
}: IBelongingPageProps) => {
    const { t } = useTranslation("administration");

    return (
        <div className="border-b">
            <div className="p-10">
                <h3 className="mb-6">{t("belonging")}</h3>
                <div className="mb-4 flex">
                    <div className="relative">
                        <Heading heading={t("closestManager")} disabled={disableAll} />
                        <input
                            type={"text"}
                            className={twMerge(
                                "w-full h-12 pl-2 border whitespace-pre-wrap bg-pageBg mr-4 xxs:w-[308px]",
                                disableAll
                                    ? "cursor-not-allowed border-mhgrey-medium text-mhgrey-medium"
                                    : "text-black cursor-text border-black",
                            )}
                            value={mainManagagerSearchValue ?? mainManagagerSelectedName}
                            onChange={(e) => {
                                onChange("mainManager", e.target.value);
                            }}
                            disabled={disableAll}
                        />
                        {mainManagerSearchProps.searchIsLoading && (
                            <div className="ml-3 flex absolute top-11 left-[264px]">
                                <TailSpin color="#3b3b36" height={20} width={20} />
                            </div>
                        )}
                        {mainManagerSearchProps.searchResultOpen && <AdminSearchResult {...mainManagerSearchProps} />}
                    </div>
                    <div className="w-full flex flex-col">
                        <Heading heading={t("costCenter")} />
                        <Select
                            {...costCenterValues}
                            onChange={(v) => onChange("costCenter", v.description)}
                            containerClassName="w-full xxs:max-w-[308px]"
                            disabled={disableAll}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <Heading heading={t("departments")} infoText={t("departmentsInfo")} mandatory />
                    <div className="flex xxs:w-[632px]">
                        <DropdownCheckboxFilter
                            header=""
                            keepClose={false}
                            loading={false}
                            viewElements={{}}
                            data={displayList}
                            setData={(v) => onChange("departments", v)}
                            variants="fullWidth"
                        />
                    </div>
                </div>
                <div>
                    <Heading heading={t("organizationNumber")} infoText={t("orgNumberInfo")} />
                    <AdminInput
                        className="mr-4 xxs:w-[632px]"
                        savedValue={organizationNumber}
                        onChange={(v) => onChange("organizationNumber", v)}
                        errorMessage={t(organizationNumberErrorMsg)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Belonging;
