import SearchQuery from "../../../components/SearchQuery";
import SearchTableResult from "../../../components/SearchTableResult";
interface IPropsView {
    preview: JSX.Element | undefined;
    hasData: boolean;
    extras: JSX.Element;
    errorMessage: string | null;
    currentHasNoData: boolean;
    customResult: JSX.Element | undefined;
    result: {
        desktop: JSX.Element;
        mobile: JSX.Element;
    };
}
const Reports = ({ preview, hasData, currentHasNoData, extras, errorMessage, result, customResult }: IPropsView) => (
    <div className="px-4 xl:px-0">
        <SearchQuery />
        <hr className=" my-12 md:my-0 md:mt-8 md:mb-12" />
        <SearchTableResult
            preview={preview}
            extras={extras}
            result={result}
            errorMessage={errorMessage}
            noData={!hasData || currentHasNoData}
            customResult={customResult}
        />
    </div>
);

export default Reports;
