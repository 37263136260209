/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchFirstDayMedicalCertificate as fetchFirstDayMedicalCertificateAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchFirstDayMedicalCertificate = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchFirstDayMedicalCertificateAction.fulfilled, (state, action) => {
            state.medicalCertificate.firstDayMedicalCertificate = action.payload;

            state.status.medicalCertificate = Status.OK;
        })
        .addCase(fetchFirstDayMedicalCertificateAction.pending, (state) => {
            state.status.medicalCertificate = Status.PENDING;
        })
        .addCase(fetchFirstDayMedicalCertificateAction.rejected, (state) => {
            state.status.medicalCertificate = Status.ERROR;
        });
export default fetchFirstDayMedicalCertificate;
