const envSettings = window as any;
export class Config {
    static princessDomain = envSettings.princessDomain;
    static env = envSettings.env;
    static microsoftAppId = envSettings.microsoftAppId;

    static api_absenceFollowup_url = envSettings.absenceFollowupApi;
    static api_absenceReport_url = envSettings.absenceReportApi;
    static api_authService_url = envSettings.authServiceApi;
    static api_businessSupport_url = envSettings.businessSupportApi;
    static api_customerCompany_url = envSettings.customerCompanyApi;
    static api_deviantAbsence_url = envSettings.deviantAbsenceApi;
    static api_employeeAttendance_url = envSettings.employeeAttendanceApi;
    static api_notification_url = envSettings.notificationApi;
    static api_people_url = envSettings.peopleApi;
    static api_rehab_url = envSettings.rehabApi;
    static api_servicesBase_url = envSettings.services_base_url;
    static api_statistics_url = envSettings.statisticsApi;
    static api_userContextService_url = envSettings.userContextServiceApi;
    static api_storage_url = envSettings.storageApi;
    static api_support_url = envSettings.supportApi;
    static api_accessRights_url = envSettings.businessSupportApi;
    static api_messenger_url = envSettings.messengerApi;
    static api_healthCaseManagement_url = envSettings.healthCaseManagementApi;
    static api_medicaladvisory_url = envSettings.medicalAdvisoryApi;
    static api_risk_url = envSettings.riskApi;
    static api_fortnox_url = envSettings.fortnoxApi;
    static api_fileImport_url = envSettings.fileImportApi;
    static api_authorization_url = envSettings.authorizationApi;

    static web_about_url = envSettings.aboutWeb;
    static web_administrationWeb_url = envSettings.administrationWeb;
    static web_dashboard_url = envSettings.dashboardWeb;
    static web_followUp_url = envSettings.followupWeb;
    static web_login_url = envSettings.loginUrlWeb;
    static web_newAbsenceReporting_url = envSettings.newArWeb;
    static web_service_url = envSettings.serviceWeb;

    static web_businessSupport_url = envSettings.businessSupportWeb;
    static web_customerSupport_url = envSettings.customerSupportWeb;
    static web_healthPortal_url = envSettings.healthPortalWeb;
    static web_notification_url = envSettings.notificationWeb;
    static web_medicalAdvisory_url = envSettings.medicalAdvisoryWeb;
    static web_myProfile_url = envSettings.myProfileWeb;
    static web_oldAbsenceReporting_url = envSettings.oldArWeb;
    static web_portal_url = envSettings.portalWeb;
    static web_support_url = envSettings.supportWeb;
    static web_rehab_url = envSettings.rehabWeb;

    static version = envSettings.version;
}
