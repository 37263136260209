import Checkbox from "@medhelp/ui/Checkbox";
import { AdminAccordion, DepartmentSelectorAccChild } from "pages/Administration/components";
import { IPermissionsAccordionSharedProps, IPermissionsPageProps } from "pages/Administration/types";
import { checkIsActive, getAllIsChecked, getPermissionDisplayValues } from "pages/Administration/utils";
import { useTranslation } from "react-i18next";

const Permissions = ({
    accordionProps,
    onAccordionClick,
    companyName,
    onSaveButtonClick,
    onValueChange,
}: IPermissionsPageProps) => {
    const { t } = useTranslation("administration");

    return (
        <>
            <h2 className="border-b pb-4">{t("permissions")}</h2>
            {Object.values(accordionProps).map((accProps: IPermissionsAccordionSharedProps, i) => (
                <AdminAccordion
                    key={accProps.name + i}
                    heading={t(accProps.name)}
                    displayValues={getPermissionDisplayValues(accProps, t)}
                    open={accProps.open}
                    active={checkIsActive(accProps)}
                    setOpen={(v) => onAccordionClick(accProps.name, v)}
                    infoText={t(accProps.infoText)}
                    primaryButtonClicked={() => onSaveButtonClick(accProps.name)}
                    primaryButtonText={t("save")}
                    primaryButtonDisabled={!accProps.isDirty}
                    isLoading={accProps.isLoading}
                >
                    {accProps.isCheckbox ? (
                        <div className="flex items-center">
                            <Checkbox
                                checked={!!accProps.checked}
                                onChange={() => onValueChange(accProps.name, !accProps.checked)}
                                size={24}
                            />
                            <p className="ml-3">{`${t("allow")} ${t(accProps.name).toLowerCase()}`}</p>
                        </div>
                    ) : (
                        <DepartmentSelectorAccChild
                            companyName={companyName}
                            depDisplayList={accProps.depDisplayList}
                            onChange={(v) => onValueChange(accProps.name, v)}
                            allChecked={getAllIsChecked(accProps.depDisplayList)}
                        />
                    )}
                </AdminAccordion>
            ))}
        </>
    );
};

export default Permissions;
