import { useAppSelector } from "store/hooks";
import AdvisoryServices from "pages/service/components/AdvisoryServices";
import FollowUpServices from "pages/service/components/FollowUpServices";
import ServiceContracts from "pages/service/components/ServiceContracts";
import HCMConfiguration from "pages/service/components/HCMConfiguration";
import AdvisoryServicePhoneNumber from "pages/service/components/AdvisoryServicePhoneNumber";
import AbsenceReportingConfiguration from "pages/service/components/AbsenceReportingConfiguration";
import {
    getHasRehabServiceContract,
    getHasAbsenceReportingServiceContract,
} from "pages/service/redux/serviceSelectors";

const ServicesTab = () => {
    const hasRehabServiceContract = useAppSelector(getHasRehabServiceContract);
    const hasAbsenceReportingServiceContract = useAppSelector(getHasAbsenceReportingServiceContract);
    return (
        <>
            <AdvisoryServices />
            {hasAbsenceReportingServiceContract && <AdvisoryServicePhoneNumber />}
            <ServiceContracts />
            <FollowUpServices />
            <AbsenceReportingConfiguration />
            {hasRehabServiceContract && <HCMConfiguration />}
        </>
    );
};

export default ServicesTab;
