import RoleSubscriptionsSettings from "./RoleSubscriptionsSettings";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import {
    getRoleSubscriptionStateProps,
    getSelectedCompanyId,
    getNewRowProps,
    getEnableSave,
    getRoleSubscriptionData,
    getIsDirty,
    getSelectedRoleSubscriptions,
    getRoleSubscriptionsGroups,
} from "pages/Administration/redux/administrationSelectors";
import { Spinner } from "components/Spinner";
import { AddRoleSubscriptionConfigration } from "swagger/messenger";
import {
    getRoleSubscriptionSettingsData,
    saveRoleSubscriptionSettings,
} from "pages/Administration/redux/administrationAsyncActions";
import { Role } from "pages/Administration/types";

export default function Index() {
    const dispatch = useAppDispatch();

    const companyId = useAppSelector(getSelectedCompanyId);
    const roleSubscriptionData = useAppSelector(getRoleSubscriptionData);
    const roleSubscriptionStateProps = useAppSelector(getRoleSubscriptionStateProps);
    const newRowProps = useAppSelector(getNewRowProps);
    const selectedRoleSubscriptionsGroups = useAppSelector(getRoleSubscriptionsGroups);

    const isDirty = useAppSelector(getIsDirty);
    const selectedRoleSubscriptions = useAppSelector(getSelectedRoleSubscriptions);
    const enableSave = useAppSelector(getEnableSave);

    const handleDeleteClicked = useCallback(
        (subscription?: AddRoleSubscriptionConfigration) => {
            dispatch(
                administrationActions.deleteNotificationSetting({
                    subscription,
                }),
            );
        },
        [dispatch],
    );

    const handleAddClicked = useCallback(
        (isStandard?: boolean) => {
            isStandard
                ? dispatch(administrationActions.createStandardRoleOptions())
                : dispatch(administrationActions.createNewRow());
        },
        [dispatch],
    );

    const handleSetOpen = useCallback(
        (open: boolean) => dispatch(administrationActions.setNotificationOpen(open)),
        [dispatch],
    );

    const handleNewRowOnChange = useCallback(
        (row: AddRoleSubscriptionConfigration) => dispatch(administrationActions.updateNewRowValue({ row })),
        [dispatch],
    );
    const handleStandardOnChange = useCallback(
        (role: Role) => dispatch(administrationActions.updateStandardRoleValue(role)),
        [dispatch],
    );

    const handleSubmit = useCallback(() => {
        if (companyId) {
            let subscriptionConfig = selectedRoleSubscriptions;
            if (newRowProps.newRow) {
                if (newRowProps.newRow.channel === 3) {
                    subscriptionConfig = [
                        ...selectedRoleSubscriptions,
                        { ...newRowProps.newRow, channel: 1 },
                        { ...newRowProps.newRow, channel: 2 },
                    ];
                } else subscriptionConfig = [...selectedRoleSubscriptions, newRowProps.newRow];
            }

            dispatch(
                saveRoleSubscriptionSettings({
                    companyId: companyId,
                    subscriptionConfig: subscriptionConfig,
                }),
            );
        }
    }, [companyId, dispatch, newRowProps.newRow, selectedRoleSubscriptions]);

    const handleAbort = useCallback(() => {
        dispatch(administrationActions.removeUnsavedStandardRoleOptions());
    }, [dispatch]);

    useMemo(() => {
        if (companyId) {
            dispatch(administrationActions.wipeSubscriptionData());
            dispatch(getRoleSubscriptionSettingsData(companyId));
        }
    }, [companyId, dispatch]);

    return !roleSubscriptionData ? (
        <Spinner />
    ) : (
        <RoleSubscriptionsSettings
            {...roleSubscriptionStateProps}
            deleteClicked={handleDeleteClicked}
            addClicked={handleAddClicked}
            roleSubscriptionTypes={selectedRoleSubscriptionsGroups}
            newRowProps={{
                ...newRowProps,
                onRowChange: handleNewRowOnChange,
                deleteClicked: () => handleDeleteClicked(undefined),
                setOpen: handleSetOpen,
            }}
            isDirty={isDirty}
            enableSave={enableSave}
            handleSubmit={handleSubmit}
            onRoleChanged={handleStandardOnChange}
            abortClicked={handleAbort}
        />
    );
}
