import { Heading, Select } from "@medhelp/ui";
import { ICountryLanguageProps } from "pages/Administration/types";
import { useTranslation } from "react-i18next";
import { countryOptions, getSelectValue, languageOptions } from "pages/Administration/utils";

const CountryAndLang = ({ country: selectedCountry, language: selectedLanguage, onChange }: ICountryLanguageProps) => {
    const { t } = useTranslation("administration");

    return (
        <div className="border-b">
            <div className="p-10">
                <h3 className="mb-10">{t("countryAndLang")}</h3>
                <div className="flex">
                    <div className="mr-4 flex flex-col">
                        <Heading heading={t("country")} mandatory />
                        <Select
                            containerClassName="w-full xxs:w-[308px]"
                            options={countryOptions}
                            selectedValue={getSelectValue(selectedCountry, "chooseCountry")}
                            onChange={(v) => onChange("country", v.value)}
                            translationKey={"administration"}
                        />
                    </div>
                    <div className="flex flex-col">
                        <Heading heading={t("languagePreference")} mandatory />
                        <Select
                            containerClassName="w-full xxs:w-[308px]"
                            options={languageOptions}
                            selectedValue={getSelectValue(selectedLanguage, "chooseLanguage")}
                            onChange={(v) => onChange("language", v.value)}
                            translationKey={"administration"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CountryAndLang;
