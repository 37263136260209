import {
    getStatus,
    getCompanies,
    getCustomerData,
    getCustomerCompanyAdvisoryServices,
} from "pages/service/redux/serviceSelectors";
import { Spinner } from "components/Spinner";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { searchActions } from "store/searchSlice";
import { Table, TableCell, TableRow } from "@medhelp/ui";
import { Status } from "pages/service/redux/serviceSlice";
import { AccordionBooleanContent, ServiceAccordion } from "../ServiceAccordion";
import { fetchCustomerCompanyAdvisoryServices } from "pages/service/redux/serviceAsyncActions";

const CompanyList = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation("service");
    const companies = useAppSelector(getCompanies);
    const { customerId } = useAppSelector(getCustomerData);
    const { customerCompanyAdvisoryServices: status } = useAppSelector(getStatus);
    const customerCompanyAdvisoryServices = useAppSelector(getCustomerCompanyAdvisoryServices);

    const fetchCustomerCompanyData = () => {
        if (customerId && companies)
            dispatch(
                fetchCustomerCompanyAdvisoryServices({
                    customerId,
                    companies,
                }),
            );
    };

    const handleClick = (companyId: number, companyName: string) => {
        if (customerId && companyId) {
            dispatch(
                searchActions.setActivePowerbarSearch({
                    typeOfSearch: "companySearch",
                    companyName,
                    companyId,
                    customerId,
                }),
            );

            navigate(`/service/company/services`);
        }
    };

    return (
        <ServiceAccordion
            content={
                <>
                    {companies.map((company) => (
                        <AccordionBooleanContent
                            key={company.companyId}
                            label={`${company.companyName} (${company.companyId})`}
                        />
                    ))}
                </>
            }
            heading={t("companies")}
            disabled
            isLoading={false}
            reset={() => {}}
            onOpen={fetchCustomerCompanyData}
        >
            <div className="py-10">
                {status === Status.PENDING && <Spinner />}
                {status === Status.ERROR && <div>Error</div>}
                {status === Status.OK && (
                    <Table
                        headers={[{ label: t("company") }, { label: t("companyId") }, { label: t("advisoryService") }]}
                    >
                        {customerCompanyAdvisoryServices.map(({ companyId, companyName, advisoryService }) => (
                            <TableRow key={companyId} onClick={() => handleClick(companyId, companyName)}>
                                <TableCell showOnMobile>{companyName}</TableCell>
                                <TableCell>{companyId}</TableCell>
                                <TableCell showOnMobile>{t(advisoryService)}</TableCell>
                            </TableRow>
                        ))}
                    </Table>
                )}
            </div>
        </ServiceAccordion>
    );
};

export default CompanyList;
