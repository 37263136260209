import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MedHelpPeopleDomainContent } from "swagger/people";
import { fetchPrivacyPolicy } from "./aboutActions";

export interface IAboutSlice {
    privatePolicyContent: MedHelpPeopleDomainContent | null;
}

const initialAboutState: IAboutSlice = {
    privatePolicyContent: null,
};

const aboutSlice = createSlice({
    name: "about",
    initialState: initialAboutState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPrivacyPolicy.fulfilled, (state, action: PayloadAction<MedHelpPeopleDomainContent>) => {
                state.privatePolicyContent = action.payload;
            })
            .addCase(fetchPrivacyPolicy.rejected, (state) => {
                state.privatePolicyContent = null;
            });
    },
});

export const aboutActions = aboutSlice.actions;

export default aboutSlice.reducer;
