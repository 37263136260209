/* tslint:disable */
/* eslint-disable */
/**
 * My Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ActivityType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type ActivityType = typeof ActivityType[keyof typeof ActivityType];


/**
 * 
 * @export
 * @interface DeleteRehabProcessModel
 */
export interface DeleteRehabProcessModel {
    /**
     * 
     * @type {number}
     * @memberof DeleteRehabProcessModel
     */
    'employmentRehabId'?: number;
}
/**
 * 
 * @export
 * @interface EmployeeDetails
 */
export interface EmployeeDetails {
    /**
     * 
     * @type {string}
     * @memberof EmployeeDetails
     */
    'processType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDetails
     */
    'employeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDetails
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeDetails
     */
    'initationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeDetails
     */
    'daysInRehabChain'?: number | null;
}
/**
 * 
 * @export
 * @interface EndRehabProcessModel
 */
export interface EndRehabProcessModel {
    /**
     * 
     * @type {number}
     * @memberof EndRehabProcessModel
     */
    'employmentRehabId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EndRehabProcessModel
     */
    'endingReason'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EventType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13
} as const;

export type EventType = typeof EventType[keyof typeof EventType];


/**
 * 
 * @export
 * @interface FilteredLatestRehabResponse
 */
export interface FilteredLatestRehabResponse {
    /**
     * 
     * @type {string}
     * @memberof FilteredLatestRehabResponse
     */
    'initiationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilteredLatestRehabResponse
     */
    'employeeName'?: string | null;
}
/**
 * 
 * @export
 * @interface FilteredRehabResponse
 */
export interface FilteredRehabResponse {
    /**
     * 
     * @type {number}
     * @memberof FilteredRehabResponse
     */
    'longTerm'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilteredRehabResponse
     */
    'recurring'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilteredRehabResponse
     */
    'manually'?: number;
    /**
     * 
     * @type {number}
     * @memberof FilteredRehabResponse
     */
    'total'?: number;
    /**
     * 
     * @type {Array<EmployeeDetails>}
     * @memberof FilteredRehabResponse
     */
    'employeeDetails'?: Array<EmployeeDetails> | null;
}
/**
 * 
 * @export
 * @interface FormFieldMetadata
 */
export interface FormFieldMetadata {
    /**
     * 
     * @type {string}
     * @memberof FormFieldMetadata
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormFieldMetadata
     */
    'dataField'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormFieldMetadata
     */
    'defaultValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FormFieldMetadata
     */
    'tabOrder'?: number;
    /**
     * 
     * @type {FormFieldPlacement}
     * @memberof FormFieldMetadata
     */
    'placement'?: FormFieldPlacement | null;
}
/**
 * 
 * @export
 * @interface FormFieldPlacement
 */
export interface FormFieldPlacement {
    /**
     * 
     * @type {number}
     * @memberof FormFieldPlacement
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof FormFieldPlacement
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof FormFieldPlacement
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof FormFieldPlacement
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface OngoingRehabFilter
 */
export interface OngoingRehabFilter {
    /**
     * 
     * @type {Array<number>}
     * @memberof OngoingRehabFilter
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OngoingRehabFilter
     */
    'departmentIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OngoingRehabFilter
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof OngoingRehabFilter
     */
    'employmentGroupIdentifiers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PartialRehabServiceContract
 */
export interface PartialRehabServiceContract {
    /**
     * 
     * @type {boolean}
     * @memberof PartialRehabServiceContract
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialRehabServiceContract
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface PreviousEvent
 */
export interface PreviousEvent {
    /**
     * 
     * @type {string}
     * @memberof PreviousEvent
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PreviousEvent
     */
    'effectiveDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviousEvent
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface RecalculatePostModel
 */
export interface RecalculatePostModel {
    /**
     * 
     * @type {number}
     * @memberof RecalculatePostModel
     */
    'rehabProcessId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RecalculatePostModel
     */
    'employmentId'?: number;
}
/**
 * 
 * @export
 * @interface RehabActivity
 */
export interface RehabActivity {
    /**
     * 
     * @type {number}
     * @memberof RehabActivity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabActivity
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabActivity
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabActivity
     */
    'plannedCarryOutDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabActivity
     */
    'performedDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabActivity
     */
    'performedByUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RehabActivity
     */
    'performedByUserName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RehabActivity
     */
    'partOfRehabChain'?: boolean;
    /**
     * 
     * @type {ActivityType}
     * @memberof RehabActivity
     */
    'activityType'?: ActivityType;
    /**
     * 
     * @type {number}
     * @memberof RehabActivity
     */
    'carryOutDay'?: number | null;
    /**
     * 
     * @type {Array<PreviousEvent>}
     * @memberof RehabActivity
     */
    'previousEvents'?: Array<PreviousEvent> | null;
}
/**
 * 
 * @export
 * @interface RehabActivityChangePostModel
 */
export interface RehabActivityChangePostModel {
    /**
     * 
     * @type {number}
     * @memberof RehabActivityChangePostModel
     */
    'employmentRehabId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabActivityChangePostModel
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof RehabActivityChangePostModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabActivityChangePostModel
     */
    'performedByUserAccountId'?: number;
    /**
     * 
     * @type {EventType}
     * @memberof RehabActivityChangePostModel
     */
    'eventType'?: EventType;
    /**
     * 
     * @type {number}
     * @memberof RehabActivityChangePostModel
     */
    'plannedRehabActivityId'?: number;
}
/**
 * 
 * @export
 * @interface RehabCase
 */
export interface RehabCase {
    /**
     * 
     * @type {string}
     * @memberof RehabCase
     */
    'creationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof RehabCase
     */
    'employmentId'?: number;
    /**
     * 
     * @type {Array<RehabCaseDocumentationEntry>}
     * @memberof RehabCase
     */
    'rehabCaseDocumentationEntries'?: Array<RehabCaseDocumentationEntry> | null;
    /**
     * 
     * @type {RehabCause}
     * @memberof RehabCase
     */
    'rehabCause'?: RehabCause | null;
    /**
     * 
     * @type {Array<RehabCause>}
     * @memberof RehabCase
     */
    'rehabCauses'?: Array<RehabCause> | null;
    /**
     * 
     * @type {number}
     * @memberof RehabCase
     */
    'createdByUserAccountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RehabCase
     */
    'closedByUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RehabCase
     */
    'createdByUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabCase
     */
    'closedByUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabCase
     */
    'endingReason'?: string | null;
}
/**
 * 
 * @export
 * @interface RehabCaseDocumentationEntry
 */
export interface RehabCaseDocumentationEntry {
    /**
     * 
     * @type {string}
     * @memberof RehabCaseDocumentationEntry
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabCaseDocumentationEntry
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabCaseDocumentationEntry
     */
    'effectiveDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof RehabCaseDocumentationEntry
     */
    'eventId'?: number;
    /**
     * 
     * @type {RehabDocument}
     * @memberof RehabCaseDocumentationEntry
     */
    'rehabDocument'?: RehabDocument | null;
    /**
     * 
     * @type {string}
     * @memberof RehabCaseDocumentationEntry
     */
    'assetKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof RehabCaseDocumentationEntry
     */
    'performedByUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RehabCaseDocumentationEntry
     */
    'performedByUserName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabCaseDocumentationEntry
     */
    'eventType'?: number;
}
/**
 * 
 * @export
 * @interface RehabCause
 */
export interface RehabCause {
    /**
     * 
     * @type {number}
     * @memberof RehabCause
     */
    'referenceId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RehabCause
     */
    'detailedCode'?: string | null;
    /**
     * 
     * @type {RehabCauseType}
     * @memberof RehabCause
     */
    'rehabCauseType'?: RehabCauseType;
    /**
     * 
     * @type {string}
     * @memberof RehabCause
     */
    'effectiveDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabCause
     */
    'deviantEventTypeId'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RehabCauseType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type RehabCauseType = typeof RehabCauseType[keyof typeof RehabCauseType];


/**
 * 
 * @export
 * @interface RehabDocument
 */
export interface RehabDocument {
    /**
     * 
     * @type {RehabDocumentType}
     * @memberof RehabDocument
     */
    'rehabDocumentType'?: RehabDocumentType;
    /**
     * 
     * @type {boolean}
     * @memberof RehabDocument
     */
    'formDocument'?: boolean;
}
/**
 * 
 * @export
 * @interface RehabDocumentEntryPostModel
 */
export interface RehabDocumentEntryPostModel {
    /**
     * 
     * @type {number}
     * @memberof RehabDocumentEntryPostModel
     */
    'employmentRehabId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabDocumentEntryPostModel
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof RehabDocumentEntryPostModel
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof RehabDocumentEntryPostModel
     */
    'performedByUserAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RehabDocumentEntryPostModel
     */
    'eventId'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RehabDocumentType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type RehabDocumentType = typeof RehabDocumentType[keyof typeof RehabDocumentType];


/**
 * 
 * @export
 * @interface RehabEvent
 */
export interface RehabEvent {
    /**
     * 
     * @type {string}
     * @memberof RehabEvent
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabEvent
     */
    'note'?: string | null;
    /**
     * 
     * @type {EventType}
     * @memberof RehabEvent
     */
    'eventType'?: EventType;
    /**
     * 
     * @type {string}
     * @memberof RehabEvent
     */
    'eventDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof RehabEvent
     */
    'entryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RehabEvent
     */
    'employmentRehabId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabEvent
     */
    'effectiveDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof RehabEvent
     */
    'rehabCauseType'?: number | null;
}
/**
 * 
 * @export
 * @interface RehabFormDocumentPostModel
 */
export interface RehabFormDocumentPostModel {
    /**
     * 
     * @type {number}
     * @memberof RehabFormDocumentPostModel
     */
    'employmentRehabId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RehabFormDocumentPostModel
     */
    'performedByUserAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabFormDocumentPostModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabFormDocumentPostModel
     */
    'assetKey'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabFormDocumentPostModel
     */
    'templateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabFormDocumentPostModel
     */
    'jsonData'?: string | null;
}
/**
 * 
 * @export
 * @interface RehabFormTemplate
 */
export interface RehabFormTemplate {
    /**
     * 
     * @type {number}
     * @memberof RehabFormTemplate
     */
    'templateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabFormTemplate
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabFormTemplate
     */
    'documentRef'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabFormTemplate
     */
    'ownerReferenceKey'?: number;
    /**
     * 
     * @type {number}
     * @memberof RehabFormTemplate
     */
    'ownerReferenceType'?: number;
    /**
     * 
     * @type {number}
     * @memberof RehabFormTemplate
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<Array<FormFieldMetadata>>}
     * @memberof RehabFormTemplate
     */
    'formFields'?: Array<Array<FormFieldMetadata>> | null;
}
/**
 * 
 * @export
 * @interface RehabProcess
 */
export interface RehabProcess {
    /**
     * 
     * @type {number}
     * @memberof RehabProcess
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof RehabProcess
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabProcess
     */
    'initiationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof RehabProcess
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabProcess
     */
    'responsibleUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RehabProcess
     */
    'responsibleUserName'?: string | null;
    /**
     * 
     * @type {RehabCase}
     * @memberof RehabProcess
     */
    'rehabCase'?: RehabCase | null;
    /**
     * 
     * @type {Array<RehabEvent>}
     * @memberof RehabProcess
     */
    'rehabEvents'?: Array<RehabEvent> | null;
    /**
     * 
     * @type {Array<RehabActivity>}
     * @memberof RehabProcess
     */
    'rehabActivities'?: Array<RehabActivity> | null;
    /**
     * 
     * @type {RehabCauseType}
     * @memberof RehabProcess
     */
    'rehabCause'?: RehabCauseType;
    /**
     * 
     * @type {boolean}
     * @memberof RehabProcess
     */
    'activeChain'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RehabProcess
     */
    'allowDeleteDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabProcess
     */
    'daysInRehabChain'?: number | null;
}
/**
 * 
 * @export
 * @interface RehabResponsibleFromDepartmentIds
 */
export interface RehabResponsibleFromDepartmentIds {
    /**
     * 
     * @type {Array<number>}
     * @memberof RehabResponsibleFromDepartmentIds
     */
    'departmentIds'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof RehabResponsibleFromDepartmentIds
     */
    'userAccountId'?: number;
}
/**
 * 
 * @export
 * @interface RehabRoutineDocumentMetaData
 */
export interface RehabRoutineDocumentMetaData {
    /**
     * 
     * @type {string}
     * @memberof RehabRoutineDocumentMetaData
     */
    'assetKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof RehabRoutineDocumentMetaData
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabRoutineDocumentMetaData
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RehabRoutineDocumentMetaData
     */
    'extension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RehabRoutineDocumentMetaData
     */
    'link'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RehabRoutineDocumentMetaData
     */
    'rehabDocumentType'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RehabSearchResultSorting = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type RehabSearchResultSorting = typeof RehabSearchResultSorting[keyof typeof RehabSearchResultSorting];


/**
 * 
 * @export
 * @interface RehabTranslation
 */
export interface RehabTranslation {
    /**
     * 
     * @type {string}
     * @memberof RehabTranslation
     */
    'workbookTitle'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RehabTranslation
     */
    'titles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SearchFilter
 */
export interface SearchFilter {
    /**
     * 
     * @type {string}
     * @memberof SearchFilter
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchFilter
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchFilter
     */
    'departmentIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchFilter
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchFilter
     */
    'includeCompleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchFilter
     */
    'includeResults'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchFilter
     */
    'onlyMyResponsibility'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchFilter
     */
    'pageNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchFilter
     */
    'pageSize'?: number | null;
    /**
     * 
     * @type {RehabTranslation}
     * @memberof SearchFilter
     */
    'translation'?: RehabTranslation | null;
    /**
     * 
     * @type {number}
     * @memberof SearchFilter
     */
    'initiatedBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchFilter
     */
    'excludedEmploymentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchFilter
     */
    'delayedBy'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchFilter
     */
    'baseActivityTypes'?: Array<number> | null;
    /**
     * 
     * @type {RehabSearchResultSorting}
     * @memberof SearchFilter
     */
    'sorting'?: RehabSearchResultSorting | null;
    /**
     * 
     * @type {string}
     * @memberof SearchFilter
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface StartRehabProcessModel
 */
export interface StartRehabProcessModel {
    /**
     * 
     * @type {number}
     * @memberof StartRehabProcessModel
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StartRehabProcessModel
     */
    'initiationDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof StartRehabProcessModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof StartRehabProcessModel
     */
    'responsibleUserAccountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StartRehabProcessModel
     */
    'createdByUserAccountId'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateRehabResponsible
 */
export interface UpdateRehabResponsible {
    /**
     * 
     * @type {number}
     * @memberof UpdateRehabResponsible
     */
    'employmentRehabId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateRehabResponsible
     */
    'responsibleUserAccountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRehabResponsible
     */
    'performedByUserAccountId'?: number;
}

/**
 * DashboardMetricApi - axios parameter creator
 * @export
 */
export const DashboardMetricApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OngoingRehabFilter} [ongoingRehabFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardMetricGetLatestOngoingRehabPost: async (ongoingRehabFilter?: OngoingRehabFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DashboardMetric/GetLatestOngoingRehab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ongoingRehabFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OngoingRehabFilter} [ongoingRehabFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardMetricGetOngoingRehabPost: async (ongoingRehabFilter?: OngoingRehabFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DashboardMetric/GetOngoingRehab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ongoingRehabFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardMetricApi - functional programming interface
 * @export
 */
export const DashboardMetricApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardMetricApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {OngoingRehabFilter} [ongoingRehabFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardMetricGetLatestOngoingRehabPost(ongoingRehabFilter?: OngoingRehabFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilteredLatestRehabResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardMetricGetLatestOngoingRehabPost(ongoingRehabFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OngoingRehabFilter} [ongoingRehabFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDashboardMetricGetOngoingRehabPost(ongoingRehabFilter?: OngoingRehabFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilteredRehabResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDashboardMetricGetOngoingRehabPost(ongoingRehabFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardMetricApi - factory interface
 * @export
 */
export const DashboardMetricApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardMetricApiFp(configuration)
    return {
        /**
         * 
         * @param {OngoingRehabFilter} [ongoingRehabFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardMetricGetLatestOngoingRehabPost(ongoingRehabFilter?: OngoingRehabFilter, options?: any): AxiosPromise<Array<FilteredLatestRehabResponse>> {
            return localVarFp.apiDashboardMetricGetLatestOngoingRehabPost(ongoingRehabFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OngoingRehabFilter} [ongoingRehabFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDashboardMetricGetOngoingRehabPost(ongoingRehabFilter?: OngoingRehabFilter, options?: any): AxiosPromise<FilteredRehabResponse> {
            return localVarFp.apiDashboardMetricGetOngoingRehabPost(ongoingRehabFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardMetricApi - object-oriented interface
 * @export
 * @class DashboardMetricApi
 * @extends {BaseAPI}
 */
export class DashboardMetricApi extends BaseAPI {
    /**
     * 
     * @param {OngoingRehabFilter} [ongoingRehabFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public apiDashboardMetricGetLatestOngoingRehabPost(ongoingRehabFilter?: OngoingRehabFilter, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).apiDashboardMetricGetLatestOngoingRehabPost(ongoingRehabFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OngoingRehabFilter} [ongoingRehabFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public apiDashboardMetricGetOngoingRehabPost(ongoingRehabFilter?: OngoingRehabFilter, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).apiDashboardMetricGetOngoingRehabPost(ongoingRehabFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetOrganizationTreeViewGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetOrganizationTreeView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetUniqueEmploymentsForCompanyGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetUniqueEmploymentsForCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetOrganizationTreeViewGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetOrganizationTreeViewGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetUniqueEmploymentsForCompanyGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetUniqueEmploymentsForCompanyGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetOrganizationTreeViewGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationGetOrganizationTreeViewGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetUniqueEmploymentsForCompanyGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationGetUniqueEmploymentsForCompanyGet(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetOrganizationTreeViewGet(companyId?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetOrganizationTreeViewGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetUniqueEmploymentsForCompanyGet(companyId?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetUniqueEmploymentsForCompanyGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RehabApi - axios parameter creator
 * @export
 */
export const RehabApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RecalculatePostModel} [recalculatePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabConfirmRecalculatedProcessPost: async (recalculatePostModel?: RecalculatePostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/ConfirmRecalculatedProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recalculatePostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RehabDocumentEntryPostModel} [rehabDocumentEntryPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabCreateRehabEntryPost: async (rehabDocumentEntryPostModel?: RehabDocumentEntryPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/CreateRehabEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rehabDocumentEntryPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RehabFormDocumentPostModel} [rehabFormDocumentPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabCreateRehabFormEntryPost: async (rehabFormDocumentPostModel?: RehabFormDocumentPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/CreateRehabFormEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rehabFormDocumentPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [entryId] 
         * @param {number} [rehabEmploymentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabDeleteRehabEntryGet: async (entryId?: number, rehabEmploymentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/DeleteRehabEntry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (entryId !== undefined) {
                localVarQueryParameter['entryId'] = entryId;
            }

            if (rehabEmploymentId !== undefined) {
                localVarQueryParameter['rehabEmploymentId'] = rehabEmploymentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteRehabProcessModel} [deleteRehabProcessModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabDeleteRehabProcessPost: async (deleteRehabProcessModel?: DeleteRehabProcessModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/DeleteRehabProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRehabProcessModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [assetKey] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabDocumentTemplateDownloadGet: async (assetKey?: string, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/DocumentTemplateDownload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetKey !== undefined) {
                localVarQueryParameter['assetKey'] = assetKey;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EndRehabProcessModel} [endRehabProcessModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabEndRehabProcessPost: async (endRehabProcessModel?: EndRehabProcessModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/EndRehabProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(endRehabProcessModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabExportExcelEventsPost: async (searchFilter?: SearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/ExportExcelEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabExportExcelPeriodsPost: async (searchFilter?: SearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/ExportExcelPeriods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [assetKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabFileDownloadGet: async (assetKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/FileDownload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetKey !== undefined) {
                localVarQueryParameter['assetKey'] = assetKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [assetKey] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabFileDownloadRoutineDocumentGet: async (assetKey?: string, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/FileDownloadRoutineDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetKey !== undefined) {
                localVarQueryParameter['assetKey'] = assetKey;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [employmentRehabId] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabFileUploadPost: async (employmentRehabId?: string, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/FileUpload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (employmentRehabId != null) {
                localVarHeaderParameter['employmentRehabId'] = String(employmentRehabId);
            }


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {number} [specificVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabFormDocumentDocumentIdGet: async (documentId: string, specificVersion?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('apiRehabFormDocumentDocumentIdGet', 'documentId', documentId)
            const localVarPath = `/api/Rehab/FormDocument/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (specificVersion !== undefined) {
                localVarQueryParameter['specificVersion'] = specificVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGet: async (employmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetCompanyConfiguredActivityTypesPost: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/GetCompanyConfiguredActivityTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabDocumentationForEmploymentGet: async (employmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/GetRehabDocumentationForEmployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabEventsPost: async (searchFilter?: SearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/GetRehabEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabHistoryEmploymentIdGet: async (employmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('apiRehabGetRehabHistoryEmploymentIdGet', 'employmentId', employmentId)
            const localVarPath = `/api/Rehab/GetRehabHistory/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabNotificationsGet: async (employmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/GetRehabNotifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabPeriodsPost: async (searchFilter?: SearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/GetRehabPeriods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabRoutineDocumentsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/GetRehabRoutineDocuments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RecalculatePostModel} [recalculatePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabRecalculateProcessPost: async (recalculatePostModel?: RecalculatePostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/RecalculateProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(recalculatePostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RehabActivityChangePostModel} [rehabActivityChangePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabRehabActivityChangePost: async (rehabActivityChangePostModel?: RehabActivityChangePostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/RehabActivityChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rehabActivityChangePostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabServiceContractCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiRehabServiceContractCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/api/Rehab/ServiceContract/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartRehabProcessModel} [startRehabProcessModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabStartRehabProcessPost: async (startRehabProcessModel?: StartRehabProcessModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/StartRehabProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startRehabProcessModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRehabResponsible} [updateRehabResponsible] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabUpdateRehabResponsiblePost: async (updateRehabResponsible?: UpdateRehabResponsible, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Rehab/UpdateRehabResponsible`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRehabResponsible, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RehabApi - functional programming interface
 * @export
 */
export const RehabApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RehabApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RecalculatePostModel} [recalculatePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabConfirmRecalculatedProcessPost(recalculatePostModel?: RecalculatePostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabConfirmRecalculatedProcessPost(recalculatePostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RehabDocumentEntryPostModel} [rehabDocumentEntryPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabCreateRehabEntryPost(rehabDocumentEntryPostModel?: RehabDocumentEntryPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabCreateRehabEntryPost(rehabDocumentEntryPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RehabFormDocumentPostModel} [rehabFormDocumentPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabCreateRehabFormEntryPost(rehabFormDocumentPostModel?: RehabFormDocumentPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabCreateRehabFormEntryPost(rehabFormDocumentPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [entryId] 
         * @param {number} [rehabEmploymentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabDeleteRehabEntryGet(entryId?: number, rehabEmploymentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabDeleteRehabEntryGet(entryId, rehabEmploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteRehabProcessModel} [deleteRehabProcessModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabDeleteRehabProcessPost(deleteRehabProcessModel?: DeleteRehabProcessModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabDeleteRehabProcessPost(deleteRehabProcessModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [assetKey] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabDocumentTemplateDownloadGet(assetKey?: string, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabDocumentTemplateDownloadGet(assetKey, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EndRehabProcessModel} [endRehabProcessModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabEndRehabProcessPost(endRehabProcessModel?: EndRehabProcessModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabEndRehabProcessPost(endRehabProcessModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabExportExcelEventsPost(searchFilter?: SearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabExportExcelEventsPost(searchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabExportExcelPeriodsPost(searchFilter?: SearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabExportExcelPeriodsPost(searchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [assetKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabFileDownloadGet(assetKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabFileDownloadGet(assetKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [assetKey] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabFileDownloadRoutineDocumentGet(assetKey?: string, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabFileDownloadRoutineDocumentGet(assetKey, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [employmentRehabId] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabFileUploadPost(employmentRehabId?: string, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabFileUploadPost(employmentRehabId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} documentId 
         * @param {number} [specificVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabFormDocumentDocumentIdGet(documentId: string, specificVersion?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabFormDocumentDocumentIdGet(documentId, specificVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabGet(employmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RehabProcess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabGetCompanyConfiguredActivityTypesPost(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabGetCompanyConfiguredActivityTypesPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabGetRehabDocumentationForEmploymentGet(employmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabGetRehabDocumentationForEmploymentGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabGetRehabEventsPost(searchFilter?: SearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabGetRehabEventsPost(searchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabGetRehabHistoryEmploymentIdGet(employmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabGetRehabHistoryEmploymentIdGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabGetRehabNotificationsGet(employmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabGetRehabNotificationsGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabGetRehabPeriodsPost(searchFilter?: SearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabGetRehabPeriodsPost(searchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabGetRehabRoutineDocumentsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RehabRoutineDocumentMetaData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabGetRehabRoutineDocumentsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RecalculatePostModel} [recalculatePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabRecalculateProcessPost(recalculatePostModel?: RecalculatePostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabRecalculateProcessPost(recalculatePostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RehabActivityChangePostModel} [rehabActivityChangePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabRehabActivityChangePost(rehabActivityChangePostModel?: RehabActivityChangePostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabRehabActivityChangePost(rehabActivityChangePostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabServiceContractCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabServiceContractCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartRehabProcessModel} [startRehabProcessModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabStartRehabProcessPost(startRehabProcessModel?: StartRehabProcessModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabStartRehabProcessPost(startRehabProcessModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateRehabResponsible} [updateRehabResponsible] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRehabUpdateRehabResponsiblePost(updateRehabResponsible?: UpdateRehabResponsible, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRehabUpdateRehabResponsiblePost(updateRehabResponsible, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RehabApi - factory interface
 * @export
 */
export const RehabApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RehabApiFp(configuration)
    return {
        /**
         * 
         * @param {RecalculatePostModel} [recalculatePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabConfirmRecalculatedProcessPost(recalculatePostModel?: RecalculatePostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabConfirmRecalculatedProcessPost(recalculatePostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RehabDocumentEntryPostModel} [rehabDocumentEntryPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabCreateRehabEntryPost(rehabDocumentEntryPostModel?: RehabDocumentEntryPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabCreateRehabEntryPost(rehabDocumentEntryPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RehabFormDocumentPostModel} [rehabFormDocumentPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabCreateRehabFormEntryPost(rehabFormDocumentPostModel?: RehabFormDocumentPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabCreateRehabFormEntryPost(rehabFormDocumentPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [entryId] 
         * @param {number} [rehabEmploymentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabDeleteRehabEntryGet(entryId?: number, rehabEmploymentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabDeleteRehabEntryGet(entryId, rehabEmploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteRehabProcessModel} [deleteRehabProcessModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabDeleteRehabProcessPost(deleteRehabProcessModel?: DeleteRehabProcessModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabDeleteRehabProcessPost(deleteRehabProcessModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [assetKey] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabDocumentTemplateDownloadGet(assetKey?: string, companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabDocumentTemplateDownloadGet(assetKey, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EndRehabProcessModel} [endRehabProcessModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabEndRehabProcessPost(endRehabProcessModel?: EndRehabProcessModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabEndRehabProcessPost(endRehabProcessModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabExportExcelEventsPost(searchFilter?: SearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabExportExcelEventsPost(searchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabExportExcelPeriodsPost(searchFilter?: SearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabExportExcelPeriodsPost(searchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [assetKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabFileDownloadGet(assetKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabFileDownloadGet(assetKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [assetKey] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabFileDownloadRoutineDocumentGet(assetKey?: string, companyId?: number, options?: any): AxiosPromise<File> {
            return localVarFp.apiRehabFileDownloadRoutineDocumentGet(assetKey, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [employmentRehabId] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabFileUploadPost(employmentRehabId?: string, file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabFileUploadPost(employmentRehabId, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {number} [specificVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabFormDocumentDocumentIdGet(documentId: string, specificVersion?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabFormDocumentDocumentIdGet(documentId, specificVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGet(employmentId?: number, options?: any): AxiosPromise<RehabProcess> {
            return localVarFp.apiRehabGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetCompanyConfiguredActivityTypesPost(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabGetCompanyConfiguredActivityTypesPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabDocumentationForEmploymentGet(employmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabGetRehabDocumentationForEmploymentGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabEventsPost(searchFilter?: SearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabGetRehabEventsPost(searchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabHistoryEmploymentIdGet(employmentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabGetRehabHistoryEmploymentIdGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabNotificationsGet(employmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabGetRehabNotificationsGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchFilter} [searchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabPeriodsPost(searchFilter?: SearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabGetRehabPeriodsPost(searchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabGetRehabRoutineDocumentsGet(companyId?: number, options?: any): AxiosPromise<Array<RehabRoutineDocumentMetaData>> {
            return localVarFp.apiRehabGetRehabRoutineDocumentsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RecalculatePostModel} [recalculatePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabRecalculateProcessPost(recalculatePostModel?: RecalculatePostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabRecalculateProcessPost(recalculatePostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RehabActivityChangePostModel} [rehabActivityChangePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabRehabActivityChangePost(rehabActivityChangePostModel?: RehabActivityChangePostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabRehabActivityChangePost(rehabActivityChangePostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabServiceContractCompanyIdGet(companyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabServiceContractCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartRehabProcessModel} [startRehabProcessModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabStartRehabProcessPost(startRehabProcessModel?: StartRehabProcessModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabStartRehabProcessPost(startRehabProcessModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateRehabResponsible} [updateRehabResponsible] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRehabUpdateRehabResponsiblePost(updateRehabResponsible?: UpdateRehabResponsible, options?: any): AxiosPromise<void> {
            return localVarFp.apiRehabUpdateRehabResponsiblePost(updateRehabResponsible, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RehabApi - object-oriented interface
 * @export
 * @class RehabApi
 * @extends {BaseAPI}
 */
export class RehabApi extends BaseAPI {
    /**
     * 
     * @param {RecalculatePostModel} [recalculatePostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabConfirmRecalculatedProcessPost(recalculatePostModel?: RecalculatePostModel, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabConfirmRecalculatedProcessPost(recalculatePostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RehabDocumentEntryPostModel} [rehabDocumentEntryPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabCreateRehabEntryPost(rehabDocumentEntryPostModel?: RehabDocumentEntryPostModel, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabCreateRehabEntryPost(rehabDocumentEntryPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RehabFormDocumentPostModel} [rehabFormDocumentPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabCreateRehabFormEntryPost(rehabFormDocumentPostModel?: RehabFormDocumentPostModel, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabCreateRehabFormEntryPost(rehabFormDocumentPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [entryId] 
     * @param {number} [rehabEmploymentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabDeleteRehabEntryGet(entryId?: number, rehabEmploymentId?: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabDeleteRehabEntryGet(entryId, rehabEmploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteRehabProcessModel} [deleteRehabProcessModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabDeleteRehabProcessPost(deleteRehabProcessModel?: DeleteRehabProcessModel, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabDeleteRehabProcessPost(deleteRehabProcessModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [assetKey] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabDocumentTemplateDownloadGet(assetKey?: string, companyId?: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabDocumentTemplateDownloadGet(assetKey, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EndRehabProcessModel} [endRehabProcessModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabEndRehabProcessPost(endRehabProcessModel?: EndRehabProcessModel, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabEndRehabProcessPost(endRehabProcessModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchFilter} [searchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabExportExcelEventsPost(searchFilter?: SearchFilter, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabExportExcelEventsPost(searchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchFilter} [searchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabExportExcelPeriodsPost(searchFilter?: SearchFilter, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabExportExcelPeriodsPost(searchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [assetKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabFileDownloadGet(assetKey?: string, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabFileDownloadGet(assetKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [assetKey] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabFileDownloadRoutineDocumentGet(assetKey?: string, companyId?: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabFileDownloadRoutineDocumentGet(assetKey, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [employmentRehabId] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabFileUploadPost(employmentRehabId?: string, file?: File, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabFileUploadPost(employmentRehabId, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentId 
     * @param {number} [specificVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabFormDocumentDocumentIdGet(documentId: string, specificVersion?: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabFormDocumentDocumentIdGet(documentId, specificVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabGet(employmentId?: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabGetCompanyConfiguredActivityTypesPost(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabGetCompanyConfiguredActivityTypesPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabGetRehabDocumentationForEmploymentGet(employmentId?: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabGetRehabDocumentationForEmploymentGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchFilter} [searchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabGetRehabEventsPost(searchFilter?: SearchFilter, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabGetRehabEventsPost(searchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} employmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabGetRehabHistoryEmploymentIdGet(employmentId: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabGetRehabHistoryEmploymentIdGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabGetRehabNotificationsGet(employmentId?: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabGetRehabNotificationsGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchFilter} [searchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabGetRehabPeriodsPost(searchFilter?: SearchFilter, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabGetRehabPeriodsPost(searchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabGetRehabRoutineDocumentsGet(companyId?: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabGetRehabRoutineDocumentsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RecalculatePostModel} [recalculatePostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabRecalculateProcessPost(recalculatePostModel?: RecalculatePostModel, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabRecalculateProcessPost(recalculatePostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RehabActivityChangePostModel} [rehabActivityChangePostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabRehabActivityChangePost(rehabActivityChangePostModel?: RehabActivityChangePostModel, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabRehabActivityChangePost(rehabActivityChangePostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabServiceContractCompanyIdGet(companyId: number, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabServiceContractCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartRehabProcessModel} [startRehabProcessModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabStartRehabProcessPost(startRehabProcessModel?: StartRehabProcessModel, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabStartRehabProcessPost(startRehabProcessModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateRehabResponsible} [updateRehabResponsible] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RehabApi
     */
    public apiRehabUpdateRehabResponsiblePost(updateRehabResponsible?: UpdateRehabResponsible, options?: AxiosRequestConfig) {
        return RehabApiFp(this.configuration).apiRehabUpdateRehabResponsiblePost(updateRehabResponsible, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ServiceContractApi - axios parameter creator
 * @export
 */
export const ServiceContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceContractPartialCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiServiceContractPartialCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/api/ServiceContract/Partial/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ServiceContractApi - functional programming interface
 * @export
 */
export const ServiceContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiServiceContractPartialCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialRehabServiceContract>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiServiceContractPartialCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ServiceContractApi - factory interface
 * @export
 */
export const ServiceContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceContractApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiServiceContractPartialCompanyIdGet(companyId: number, options?: any): AxiosPromise<PartialRehabServiceContract> {
            return localVarFp.apiServiceContractPartialCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ServiceContractApi - object-oriented interface
 * @export
 * @class ServiceContractApi
 * @extends {BaseAPI}
 */
export class ServiceContractApi extends BaseAPI {
    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceContractApi
     */
    public apiServiceContractPartialCompanyIdGet(companyId: number, options?: AxiosRequestConfig) {
        return ServiceContractApiFp(this.configuration).apiServiceContractPartialCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status/GetStatusAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusInformationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status/Information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGetStatusAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusGetStatusAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusInformationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusInformationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusGetStatusAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusInformationGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusInformationGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGetStatusAllGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusGetStatusAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusInformationGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusInformationGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateApi - axios parameter creator
 * @export
 */
export const TemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateFileUploadPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Template/FileUpload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateGetTemplatesByCompanyIdGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Template/GetTemplatesByCompanyId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateGetTemplatesGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Template/GetTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assetKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplatePdfAssetKeyGet: async (assetKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assetKey' is not null or undefined
            assertParamExists('apiTemplatePdfAssetKeyGet', 'assetKey', assetKey)
            const localVarPath = `/api/Template/Pdf/{assetKey}`
                .replace(`{${"assetKey"}}`, encodeURIComponent(String(assetKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RehabFormTemplate} [rehabFormTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateSaveTemplatePost: async (rehabFormTemplate?: RehabFormTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Template/SaveTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rehabFormTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateApi - functional programming interface
 * @export
 */
export const TemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTemplateFileUploadPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTemplateFileUploadPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTemplateGetTemplatesByCompanyIdGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTemplateGetTemplatesByCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTemplateGetTemplatesGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTemplateGetTemplatesGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} assetKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTemplatePdfAssetKeyGet(assetKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTemplatePdfAssetKeyGet(assetKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RehabFormTemplate} [rehabFormTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTemplateSaveTemplatePost(rehabFormTemplate?: RehabFormTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTemplateSaveTemplatePost(rehabFormTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateApi - factory interface
 * @export
 */
export const TemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateFileUploadPost(file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiTemplateFileUploadPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateGetTemplatesByCompanyIdGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTemplateGetTemplatesByCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateGetTemplatesGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTemplateGetTemplatesGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assetKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplatePdfAssetKeyGet(assetKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTemplatePdfAssetKeyGet(assetKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RehabFormTemplate} [rehabFormTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTemplateSaveTemplatePost(rehabFormTemplate?: RehabFormTemplate, options?: any): AxiosPromise<void> {
            return localVarFp.apiTemplateSaveTemplatePost(rehabFormTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateApi - object-oriented interface
 * @export
 * @class TemplateApi
 * @extends {BaseAPI}
 */
export class TemplateApi extends BaseAPI {
    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public apiTemplateFileUploadPost(file?: File, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).apiTemplateFileUploadPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public apiTemplateGetTemplatesByCompanyIdGet(companyId?: number, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).apiTemplateGetTemplatesByCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public apiTemplateGetTemplatesGet(companyId?: number, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).apiTemplateGetTemplatesGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assetKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public apiTemplatePdfAssetKeyGet(assetKey: string, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).apiTemplatePdfAssetKeyGet(assetKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RehabFormTemplate} [rehabFormTemplate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateApi
     */
    public apiTemplateSaveTemplatePost(rehabFormTemplate?: RehabFormTemplate, options?: AxiosRequestConfig) {
        return TemplateApiFp(this.configuration).apiTemplateSaveTemplatePost(rehabFormTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RehabResponsibleFromDepartmentIds} [rehabResponsibleFromDepartmentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetRehabResponsibleForDepartmentIdsAndUserAccountIdPost: async (rehabResponsibleFromDepartmentIds?: RehabResponsibleFromDepartmentIds, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetRehabResponsibleForDepartmentIdsAndUserAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rehabResponsibleFromDepartmentIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RehabResponsibleFromDepartmentIds} [rehabResponsibleFromDepartmentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetRehabResponsibleForDepartmentIdsAndUserAccountIdPost(rehabResponsibleFromDepartmentIds?: RehabResponsibleFromDepartmentIds, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetRehabResponsibleForDepartmentIdsAndUserAccountIdPost(rehabResponsibleFromDepartmentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {RehabResponsibleFromDepartmentIds} [rehabResponsibleFromDepartmentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetRehabResponsibleForDepartmentIdsAndUserAccountIdPost(rehabResponsibleFromDepartmentIds?: RehabResponsibleFromDepartmentIds, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetRehabResponsibleForDepartmentIdsAndUserAccountIdPost(rehabResponsibleFromDepartmentIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {RehabResponsibleFromDepartmentIds} [rehabResponsibleFromDepartmentIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetRehabResponsibleForDepartmentIdsAndUserAccountIdPost(rehabResponsibleFromDepartmentIds?: RehabResponsibleFromDepartmentIds, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetRehabResponsibleForDepartmentIdsAndUserAccountIdPost(rehabResponsibleFromDepartmentIds, options).then((request) => request(this.axios, this.basePath));
    }
}


