import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "config";
import { RootState } from "store";

export const storageBaseApi = createApi({
    reducerPath: "storageBaseApi",
    baseQuery: fetchBaseQuery({
        baseUrl: Config.api_storage_url,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).user.token;
            if (token) headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        downloadFile: builder.query({
            query: (id) => ({
                url: `/FileImport/Download/${id}`,
                responseHandler: (response) => response.text(),
            }),
        }),
    }),
});

export const { useDownloadFileQuery } = storageBaseApi;
