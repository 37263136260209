import { useCallback, useMemo, useState } from "react";
import {
    IDateRangeStartDateAsNumbers,
    IDoubleDateTimepicker,
    IDoubleDateTimePickerHookProps,
    StringDateRange,
} from "@medhelp/ui/Datepicker/types";
import { useDoubleDatepickerProps } from "hooks";
import { setArrayFromDateString, setDateStringFromArray } from "@medhelp/ui/Datepicker/utils/util";
import _ from "lodash";

const useDoubleDateTimepickerProps = ({
    onChangeDateTimeRange,
    givenDateTimeRange,
    placeholderFrom,
    placeholderTo,
    isMandatory,
}: IDoubleDateTimePickerHookProps) => {
    const [selectedDateAsNumbers, setSelectedDateAsNumbers] = useState<IDateRangeStartDateAsNumbers>({
        from: setArrayFromDateString(givenDateTimeRange?.from),
        to: givenDateTimeRange?.to,
    });

    const validateDateTimeRange = useCallback(
        (newDateRange: IDateRangeStartDateAsNumbers | undefined) => {
            if (newDateRange?.from && newDateRange.to && !newDateRange?.from?.includes(-1)) {
                const validDateTimeRange: StringDateRange = {
                    from: setDateStringFromArray(newDateRange?.from, true),
                    to: newDateRange.to,
                };
                // Will only return DateRange if it's valid else undefined
                onChangeDateTimeRange(validDateTimeRange);
            } else onChangeDateTimeRange(undefined);
        },
        [onChangeDateTimeRange],
    );

    const handleDateRangeChange = useCallback(
        (stringDateRange: StringDateRange | undefined) => {
            let newStartDate = setArrayFromDateString(stringDateRange?.from, selectedDateAsNumbers.from);
            setSelectedDateAsNumbers({
                from: newStartDate,
                to: stringDateRange?.to,
            });
            validateDateTimeRange({
                from: newStartDate,
                to: stringDateRange?.to,
            });
        },
        [selectedDateAsNumbers, validateDateTimeRange],
    );

    const handleHourMinuteChange = useCallback(
        (selectedValue: number, isValid: boolean, isHour = false) => {
            if (isValid) {
                let newTime = _.cloneDeep(selectedDateAsNumbers);
                isHour ? (newTime.from[3] = selectedValue) : (newTime.from[4] = selectedValue);
                setSelectedDateAsNumbers(newTime);
                validateDateTimeRange(newTime);
            }
        },
        [selectedDateAsNumbers, validateDateTimeRange],
    );

    const doubleDatepickerProps = useDoubleDatepickerProps({
        onChangeDateRange: handleDateRangeChange,
        givenDateRange: givenDateTimeRange,
        placeholderFrom,
        placeholderTo,
        isMandatory,
        className: "mb-10",
    });

    const doubleDatetimepickerProps: IDoubleDateTimepicker = useMemo(
        () => ({
            doubleDatepickerProps,
            disabledDateBefore: undefined,
            disabledDateAfter: undefined,
            selectedDateAsNumbers: selectedDateAsNumbers.from,
            setselectedDateAsNumbers: handleHourMinuteChange,
        }),
        [doubleDatepickerProps, handleHourMinuteChange, selectedDateAsNumbers.from],
    );
    return doubleDatetimepickerProps;
};
export default useDoubleDateTimepickerProps;
