import { useMemo } from "react";
import ArchiveRow from "../ArchiveRow";
import { DefaultTableRow, ITableRowProps } from "../SearchTable";
const SearchAbsenceRow = ({ children, rowProps, ...rest }: ITableRowProps) => {
    const Row = useMemo(() => {
        switch (rowProps?.theme) {
            case "archive": {
                return ArchiveRow;
            }
            case "default": {
                return DefaultTableRow;
            }
            default: {
                return DefaultTableRow;
            }
        }
    }, [rowProps?.theme]);
    return (
        <Row rowProps={rowProps} {...rest}>
            {children}
        </Row>
    );
};

export default SearchAbsenceRow;
