/* tslint:disable */
/* eslint-disable */
/**
 * My Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from "./common";
// @ts-ignore
import {
    BASE_PATH,
    COLLECTION_FORMATS,
    RequestArgs,
    BaseAPI,
    RequiredError,
} from "./base";

export enum TypeEnum {
    AdaptChange = 1601,
    Other = 1901,
    QuestionInfo = 2301,
    ErrorReport = 2501,
    LeaveAnOpinion = 2601,
    FinanceInvoice = 2701,
}

export enum RoleEnum {
    Administrator = 1,
    Manager = 2,
    Contact = 3,
    Other = 4,
}

/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(
                localVarUrlObj,
                localVarQueryParameter,
                options.query,
            );
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet: async (
            options: any = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/Status/GetStatusAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(
                localVarUrlObj,
                localVarQueryParameter,
                options.query,
            );
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusInformationGet: async (
            options: any = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/Status/Information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(
                localVarUrlObj,
                localVarQueryParameter,
                options.query,
            );
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGet(
            options?: any,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.apiStatusGet(options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGetStatusAllGet(
            options?: any,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.apiStatusGetStatusAllGet(
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusInformationGet(
            options?: any,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.apiStatusInformationGet(
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = StatusApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet(options?: any): AxiosPromise<void> {
            return localVarFp
                .apiStatusGet(options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet(options?: any): AxiosPromise<void> {
            return localVarFp
                .apiStatusGetStatusAllGet(options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusInformationGet(options?: any): AxiosPromise<void> {
            return localVarFp
                .apiStatusInformationGet(options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGet(options?: any) {
        return StatusApiFp(this.configuration)
            .apiStatusGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGetStatusAllGet(options?: any) {
        return StatusApiFp(this.configuration)
            .apiStatusGetStatusAllGet(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusInformationGet(options?: any) {
        return StatusApiFp(this.configuration)
            .apiStatusInformationGet(options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * SupportApi - axios parameter creator
 * @export
 */
export const SupportApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         *
         * @param {string} email
         * @param {string} phone
         * @param {string} description
         * @param {number} [number]
         * @param {RoleEnum} [role]
         * @param {TypeEnum} [type]
         * @param {string} [title]
         * @param {Array<any>} [files]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSupportCreateSupportCasePost: async (
            email: string,
            phone: string,
            description: string,
            number?: number,
            role?: RoleEnum,
            type?: TypeEnum,
            title?: string,
            files?: Array<any>,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists(
                "apiSupportCreateSupportCasePost",
                "email",
                email,
            );
            // verify required parameter 'phone' is not null or undefined
            assertParamExists(
                "apiSupportCreateSupportCasePost",
                "phone",
                phone,
            );
            // verify required parameter 'description' is not null or undefined
            assertParamExists(
                "apiSupportCreateSupportCasePost",
                "description",
                description,
            );
            const localVarPath = `/api/Support/CreateSupportCase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration &&
                configuration.formDataCtor) ||
                FormData)();

            if (email !== undefined) {
                localVarFormParams.append("Email", email as any);
            }

            if (phone !== undefined) {
                localVarFormParams.append("Phone", phone as any);
            }

            if (number !== undefined) {
                localVarFormParams.append("Number", number as any);
            }

            if (role !== undefined) {
                localVarFormParams.append("Role", role as any);
            }

            if (description !== undefined) {
                localVarFormParams.append("Description", description as any);
            }

            if (type !== undefined) {
                localVarFormParams.append("Type", type as any);
            }

            if (title !== undefined) {
                localVarFormParams.append("Title", title as any);
            }

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append("Files", element as any);
                });
            }

            localVarHeaderParameter["Content-Type"] = "multipart/form-data";

            setSearchParams(
                localVarUrlObj,
                localVarQueryParameter,
                options.query,
            );
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * SupportApi - functional programming interface
 * @export
 */
export const SupportApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator =
        SupportApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} email
         * @param {string} phone
         * @param {string} description
         * @param {number} [number]
         * @param {RoleEnum} [role]
         * @param {TypeEnum} [type]
         * @param {string} [title]
         * @param {Array<any>} [files]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSupportCreateSupportCasePost(
            email: string,
            phone: string,
            description: string,
            number?: number,
            role?: RoleEnum,
            type?: TypeEnum,
            title?: string,
            files?: Array<any>,
            options?: any,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.apiSupportCreateSupportCasePost(
                    email,
                    phone,
                    description,
                    number,
                    role,
                    type,
                    title,
                    files,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * SupportApi - factory interface
 * @export
 */
export const SupportApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = SupportApiFp(configuration);
    return {
        /**
         *
         * @param {string} email
         * @param {string} phone
         * @param {string} description
         * @param {number} [number]
         * @param {RoleEnum} [role]
         * @param {TypeEnum} [type]
         * @param {string} [title]
         * @param {Array<any>} [files]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSupportCreateSupportCasePost(
            email: string,
            phone: string,
            description: string,
            number?: number,
            role?: RoleEnum,
            type?: TypeEnum,
            title?: string,
            files?: Array<any>,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .apiSupportCreateSupportCasePost(
                    email,
                    phone,
                    description,
                    number,
                    role,
                    type,
                    title,
                    files,
                    options,
                )
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportApi - object-oriented interface
 * @export
 * @class SupportApi
 * @extends {BaseAPI}
 */
export class SupportApi extends BaseAPI {
    /**
     *
     * @param {string} email
     * @param {string} phone
     * @param {string} description
     * @param {number} [number]
     * @param {RoleEnum} [role]
     * @param {TypeEnum} [type]
     * @param {string} [title]
     * @param {Array<any>} [files]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApi
     */
    public apiSupportCreateSupportCasePost(
        email: string,
        phone: string,
        description: string,
        number?: number,
        role?: RoleEnum,
        type?: TypeEnum,
        title?: string,
        files?: Array<any>,
        options?: any,
    ) {
        return SupportApiFp(this.configuration)
            .apiSupportCreateSupportCasePost(
                email,
                phone,
                description,
                number,
                role,
                type,
                title,
                files,
                options,
            )
            .then((request) => request(this.axios, this.basePath));
    }
}
