import { useAppDispatch, useAppSelector } from "store/hooks";
import OneTimePass from "./OneTimePass";
import { selectors as LoginSelectors } from "./../../redux";
import { IOtpLoginSliceProps, OtpTranslations } from "pages/login/types";
import { loginActions } from "pages/login/redux/loginSlice";
import { loginWithOneTimePass, postOneTimePass } from "pages/login/redux/loginAsyncActions";
import { createInitModel, createLoginModel3, isValidEmail } from "pages/login/utils";
import { getNumberWithoutDash, validateMobileNumber } from "utils/phone";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ILoginPageData } from "pages/login/types";
import { getTranslationsFromArray } from "utils/functions";
import Countdown from "../Countdown";

export default function Index({ returnUrl }: ILoginPageData) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation("login");
    const { oneTimePassProps, isLoading, countdownTimer, selectedMarket, userAccounts } = useAppSelector(
        LoginSelectors.getLoginSlice,
    );

    const translations = useMemo(() => getTranslationsFromArray(OtpTranslations, t), [t]);

    const setPropValue = useCallback(
        (newValue: string, otpProp: keyof IOtpLoginSliceProps) => {
            dispatch(
                loginActions.setOtpPropValue({
                    newValue: newValue as string,
                    otpProp: otpProp,
                }),
            );
        },
        [dispatch],
    );

    const handleButtonClicked = useCallback(
        (isSubmitPassButton?: boolean) => {
            dispatch(loginActions.setIsLoading(true));
            if (isSubmitPassButton) {
                dispatch(
                    loginWithOneTimePass(
                        createLoginModel3(oneTimePassProps.cacheIdentifier, oneTimePassProps.otpInput, returnUrl),
                    ),
                );
            } else {
                const identifier =
                    oneTimePassProps.activeTab === "eMail"
                        ? oneTimePassProps.emailInput
                        : getNumberWithoutDash(oneTimePassProps.phoneNumber);
                setPropValue(identifier, "identifier");
                dispatch(postOneTimePass(createInitModel(identifier, returnUrl)));
            }
        },
        [
            dispatch,
            oneTimePassProps.activeTab,
            oneTimePassProps.cacheIdentifier,
            oneTimePassProps.emailInput,
            oneTimePassProps.otpInput,
            oneTimePassProps.phoneNumber,
            returnUrl,
            setPropValue,
        ],
    );

    const setLockoutTimer = useCallback(
        (time: number) => {
            dispatch(loginActions.setCountdownTimer(time));
        },
        [dispatch],
    );

    const isValidInput = useMemo(() => {
        return (
            (oneTimePassProps.activeTab === "eMail"
                ? isValidEmail(oneTimePassProps.emailInput)
                : validateMobileNumber(oneTimePassProps.phoneNumber, t).isValid) &&
            countdownTimer < 1 &&
            !isLoading
        );
    }, [
        countdownTimer,
        isLoading,
        oneTimePassProps.activeTab,
        oneTimePassProps.emailInput,
        oneTimePassProps.phoneNumber,
        t,
    ]);

    const countdown = useMemo(() => {
        return (
            countdownTimer > 0 && (
                <Countdown
                    message={t("somethingWentWrongTryAgainIn", {
                        time: countdownTimer,
                    })}
                    time={countdownTimer}
                    setTime={setLockoutTimer}
                    isError
                />
            )
        );
    }, [countdownTimer, setLockoutTimer, t]);

    useEffect(() => {
        if (userAccounts) {
            navigate(`/login/selectCompany`);
        }
    }, [navigate, userAccounts]);

    useEffect(() => {
        let prefix = "+";
        if (selectedMarket === "sv") prefix = "+46";
        else if (selectedMarket === "dk") prefix = "+45";
        setPropValue(prefix, "phoneNumber");
    }, [selectedMarket, setPropValue]);

    return (
        <OneTimePass
            {...oneTimePassProps}
            isLoading={isLoading}
            selectedMarket={selectedMarket ?? ""}
            validInput={isValidInput}
            setOtpPropValue={setPropValue}
            handleOtpButtonClicked={handleButtonClicked}
            navigate={navigate}
            translations={translations}
            countdownComp={countdown}
        />
    );
}
