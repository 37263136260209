import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import { useBreakpoints } from "hooks";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { actions, selectors, thunkActions } from "../../redux";
import SearchFilter from "./SearchFilter";
import { useAppDispatch, useAppSelector } from "store/hooks";

export default function Index() {
    const { t } = useTranslation(["followup", "translation"]);
    const dispatch = useAppDispatch();
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const breakpoint = useBreakpoints();
    const search = useAppSelector(selectors.getCurrentSearch);
    const isEmpty = useAppSelector(selectors.currentSearchIsEmpty);
    const isLoading = useAppSelector(selectors.currentSearchIsLoading);
    const [open, setOpen] = useState(false);

    const onSearch = useCallback(() => {
        if (currentSearch === "monthlyReport") return;
        if (currentSearch) {
            dispatch(
                thunkActions.search({
                    requestType: "search",
                    type: currentSearch,
                    workBookTitle: t(`excelTitle.${currentSearch}`),
                }),
            );
        }
    }, [currentSearch, dispatch, t]);
    const BottomContent = useMemo(
        () => (
            <div className="flex items-end justify-center pb-10 gap-4">
                <SecondaryButton
                    id="clear-all-button-search"
                    onClick={() => dispatch(actions.clearSearchFilters())}
                    text={t("search.searchQuery.filters.clearButton")}
                ></SecondaryButton>
                <PrimaryButton
                    id="primary-button-on-search"
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        onSearch();
                        setOpen(false);
                    }}
                    text={t("search.searchQuery.filters.submitButton", {
                        number: 0,
                    })}
                />
            </div>
        ),
        [dispatch, onSearch, t],
    );
    const ISmall = useMemo(() => breakpoint.isMediumScreen, [breakpoint.isMediumScreen]);
    const ButtonText = useMemo(() => {
        return !ISmall ? t("search.filterButton") : t("filterAndSort");
    }, [ISmall, t]);
    return (
        <SearchFilter
            header={t(`search.searchOptions.${search}`)}
            buttonText={ButtonText}
            sideMenuHeader={t("search.searchQuery.filters.filterHeader")}
            bottomContent={BottomContent}
            excelText={t("export", { ns: "translation" })}
            open={open}
            setOpen={setOpen}
            smallScreen={ISmall}
            isEmpty={isEmpty}
            isLoading={isLoading}
            searchType={currentSearch}
        />
    );
}
