import { useTranslation } from "react-i18next";
import BackButtonHeader from "../BackButtonHeader";
import SecondaryButton from "components/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { BankIdLoginType, ILoginPageData, LoginUsing } from "pages/login/types";
import { createInitLoginModel } from "pages/login/utils";

const BankIdOptions = ({ selectedMarket, loginUsing, returnUrl }: ILoginPageData) => {
    const { t } = useTranslation("login");
    const navigate = useNavigate();

    return (
        <div data-cy="bankIdOptions" className="flex flex-col mb-14">
            <BackButtonHeader heading="BankID" onClick={() => navigate(`/login/${selectedMarket}`)} />
            <SecondaryButton
                id="bankIdThisUnitButton"
                text={t("bankIdOnThisUnit")}
                className="h-12 w-full justify-center"
                onClick={() =>
                    loginUsing({
                        loginUsing: LoginUsing.bankIdSameDevice,
                        bankIdInitGrandId: createInitLoginModel(BankIdLoginType.bankIdSameDevice),
                        returnUrl,
                    })
                }
            />
            <SecondaryButton
                id="usernameAndPassButton"
                text={t("mobileBankId")}
                className="h-12 mt-3 w-full justify-center"
                onClick={() => {
                    loginUsing({
                        loginUsing: LoginUsing.mobileBankIdQrCode,
                        bankIdInitGrandId: createInitLoginModel(BankIdLoginType.bankIdOtherDevice),
                        returnUrl,
                    });
                }}
            />
        </div>
    );
};

export default BankIdOptions;
