import { Heading } from "@medhelp/ui";
import { debounce } from "lodash";
import React, { HTMLInputTypeAttribute, useCallback, useMemo, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { twMerge } from "tailwind-merge";

export interface IAdminInput {
    className?: string;
    heading?: string;
    infoText?: string;
    savedValue: string;
    onChange: (inputValue: string) => void;
    isMandatory?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    placeholder?: string;
    isLoading?: boolean;
    children?: React.ReactNode;
    type?: HTMLInputTypeAttribute;
    onBlur?: () => void;
}
const AdminInput = ({
    className,
    heading,
    infoText,
    savedValue,
    onChange,
    isMandatory,
    disabled,
    errorMessage,
    placeholder,
    isLoading,
    children,
    type,
    onBlur,
}: IAdminInput) => {
    const [value, setValue] = useState(savedValue);
    const debounceOnChange = useMemo(() => {
        return debounce(onChange, 500);
    }, [onChange]);
    const handleOnBlur = useCallback(() => {
        if (onBlur) onBlur();
    }, [onBlur]);

    return (
        <div>
            {heading && <Heading heading={heading} infoText={infoText} mandatory={isMandatory} disabled={disabled} />}
            <div className="relative flex">
                <input
                    type={type ?? "text"}
                    className={twMerge(
                        "w-full h-12 pl-2 border whitespace-pre-wrap bg-pageBg xxs:w-[308px]",
                        disabled
                            ? "cursor-not-allowed border-mhgrey-medium text-mhgrey-medium"
                            : "text-black cursor-text border-black",
                        errorMessage ? "border-mhred-alert1 border-3" : "",
                        className,
                    )}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        debounceOnChange(e.target.value);
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                    onBlur={handleOnBlur}
                />
                {isLoading && (
                    <div className="ml-3 flex absolute left-[264px]">
                        <div className="mt-3.5">
                            <TailSpin color="#3b3b36" height={20} width={20} />
                        </div>
                    </div>
                )}
                {children}
            </div>
            <p className="absolute w-full px-2 mt-0.5 text-mhred-alert1 bg-mhbeige shadow-md z-10 xxs:w-[308px]">
                {errorMessage}
            </p>
        </div>
    );
};

export default AdminInput;
