import { ReactNode } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface IServiceInput {
    label: string;
    register: UseFormRegisterReturn<string>;
    bold?: boolean;
    required?: boolean;
    placeholder?: string;
}

export const ServiceInput = ({ label, register, bold, required, placeholder }: IServiceInput) => (
    <div className="w-full flex flex-col md:w-86">
        <label className={bold ? "font-bold" : ""}>
            {label} {required && "*"}
        </label>
        <input
            className="h-12 mt-2 px-3 border-1 border-mhdarkgreen-original bg-mhbeige"
            placeholder={placeholder}
            {...register}
        />
    </div>
);

interface IServiceInputContainer {
    heading?: string;
    borderBottom?: boolean;
    children?: ReactNode;
}

export const ServiceInputContainer = ({ heading, borderBottom, children }: IServiceInputContainer) => (
    <div className={`py-10 ${borderBottom && "border-b-1 border-grey-300"}`}>
        {heading && <p className="font-bold mb-6">{heading}</p>}
        <div className="flex flex-wrap gap-4 gap-y-10">{children}</div>
    </div>
);
