import DropdownMenu from "components/DropdownMenu";
import { isEmpty } from "lodash/fp";
import * as React from "react";
import { IRoute } from "routes/hooks";
import Icon from "components/Icon";
import Navigation from "../Navigation";
import { twMerge } from "tailwind-merge";

export interface IExternalProps {
    routes: IRoute[];
    width?: number | string;
    setCurrentRoute?: (route: IRoute) => void;
    subMenuTo?: string;
    children?: React.ReactNode;
    className?: string;
    styleText?: string;
    params?: string;
    extraElement?: React.ReactElement;
    externalRoute?: string;
}
export interface IOwnProps {
    activeRoute: string;
    showLeftButton: boolean;
    showRightButton: boolean;
    scroll: (direction: "left" | "right") => void;
    setScrollRefState: (node: HTMLDivElement | null) => void;
    updateButtonsState: (currentTarget: EventTarget & HTMLDivElement) => void;
}

interface IProps extends IOwnProps, IExternalProps {}

const TabMenu = ({
    routes,
    width,
    activeRoute,
    subMenuTo,
    children,
    params,
    extraElement,
    className,
    showLeftButton,
    showRightButton,
    scroll,
    setScrollRefState,
    updateButtonsState,
}: IProps) => (
    <div className={`${width && `w-${width}`} relative`}>
        <div className={twMerge("flex justify-between px-4 xl:px-0", className)}>
            {showLeftButton && routes[0].children.length > 2 && (
                <button
                    onClick={() => scroll("left")}
                    className="w-9 flex justify-center items-center border-r-1 border-grey-300 sm:hidden"
                >
                    <Icon icon="caretLeft" size={12} />
                </button>
            )}
            <Navigation
                routes={routes}
                active={activeRoute}
                subMenuTo={subMenuTo}
                params={params}
                setScrollRefState={setScrollRefState}
                updateButtonsState={updateButtonsState}
            >
                {(props) =>
                    !isEmpty(props.route.children) ? (
                        <DropdownMenu
                            routes={props.route.children}
                            header={props.route.name}
                            subMenuTo={props.route.name}
                            layout="no-backdrop"
                        />
                    ) : (
                        <div
                            className={twMerge(
                                props.active
                                    ? "underline decoration-[0.125rem] underline-offset-[1.35rem]"
                                    : "underline-none",
                                props.route.disabled ? "text-opacity-50 cursor-not-allowed" : "",
                            )}
                            data-testid={props.route.testId || "tabmenu-navigation-element"}
                            onClick={(e) => {
                                if (props.route.disabled) e.preventDefault();
                            }}
                        >
                            <p
                                className={`whitespace-nowrap text-sm sm:text-base ${
                                    props.active ? "font-bold" : "font-normal text-gray-600"
                                }`}
                            >
                                {props.route.name}
                            </p>
                        </div>
                    )
                }
            </Navigation>
            {showRightButton && routes[0].children.length > 2 && (
                <button
                    onClick={() => scroll("right")}
                    className="w-9 flex justify-center items-center border-l border-grey-300 sm:hidden"
                >
                    <Icon icon="caretRight" size={12} />
                </button>
            )}
        </div>
        {extraElement && <div className="absolute right-2 top-0">{extraElement}</div>}
        <hr />
        <div className="px-4 xl:px-0">{children}</div>
    </div>
);

export default TabMenu;
