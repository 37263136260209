import SearchTable, { IRow, ITableRowProps } from "../../components/SearchTable";
interface IProps {
    footer: JSX.Element;
}
interface IPropsView extends IProps {
    rows: IRow[];
    columns: JSX.Element[];
    loading: boolean;
    placeholder: JSX.Element;
    firstElementWidth?: string;
    RowContainer: React.FC<ITableRowProps>;
}
const EmployeeDesktopTable = ({
    rows,
    footer,
    columns,
    loading,
    placeholder,
    firstElementWidth,
    RowContainer,
}: IPropsView) => (
    <SearchTable
        data={{ row: rows, column: columns }}
        footer={footer}
        loading={loading}
        placeholder={placeholder}
        firstElementWidth={firstElementWidth}
        RowConatiner={RowContainer}
    />
);
export default EmployeeDesktopTable;
