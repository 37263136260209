import { upperFirst } from "lodash/fp";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { getDateDisplayValue } from "utils/date";
import EditAbsenceValue, { IProps } from "./EditAbsenceValue";

const booleanValues = (propertyName: string, value: string, t: TFunction) => {
    if (["WorkplaceAccident", "RequestedMedicalAdvisory", "WorkRelated"].includes(propertyName) && value) {
        return value === "true" ? t("yes") : t("no");
    }
    return null;
};

const absenceCause = (propertyName: string, value: string, t: TFunction) => {
    if (propertyName === "AbsenceCause") {
        return t(`followUpAbsence.summaryOfAbsence.${upperFirst(value)}`);
    }
    return null;
};
const endDate = (propertyName: string, value: string | null) => {
    if (
        (propertyName === "EndDate" ||
            propertyName === "StartDate" ||
            propertyName === "BackAtWork" ||
            propertyName === "End" ||
            propertyName === "Start") &&
        value
    ) {
        return upperFirst(getDateDisplayValue(value, "eee dd MMM yyyy"));
    }
    return null;
};

const degree = (propertyName: string, value: string | null) => {
    if (propertyName === "Degree" && value) {
        return `${value}%`;
    }
    return null;
};
export default function Index(props: IProps) {
    const { t } = useTranslation("followup");
    const Data = useMemo(
        () =>
            props.data?.map((x) => {
                return {
                    ...x,
                    oldValue:
                        booleanValues(x.propertyName, x.oldValue || "", t) ||
                        absenceCause(x.propertyName, x.oldValue || "", t) ||
                        endDate(x.propertyName, x.oldValue || null) ||
                        degree(x.propertyName, x.oldValue || null) ||
                        x.oldValue,
                    newValue:
                        booleanValues(x.propertyName, x.newValue, t) ||
                        absenceCause(x.propertyName, x.newValue || "", t) ||
                        endDate(x.propertyName, x.newValue || null) ||
                        degree(x.propertyName, x.newValue || null) ||
                        x.newValue,
                };
            }),
        [props.data, t],
    );
    return <EditAbsenceValue data={Data} />;
}
