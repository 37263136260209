import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getDateDisplayValue } from "utils/date";
import { useAppSelector } from "store/hooks";
import { selectors } from "../../redux";
import DisplayPeriod from "./DisplayPeriod";

export default function Index() {
    const { t } = useTranslation("followup");
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const searchResult = useAppSelector(selectors.search(currentSearch, 1));
    const periodFrom = useAppSelector(selectors.getSearchDateById("from"));
    const periodTo = useAppSelector(selectors.getSearchDateById("to"));
    const periodFilter = useMemo(
        () => ({
            from: periodFrom?.date || null,
            to: periodTo?.date || null,
        }),
        [periodFrom, periodTo],
    );
    const NumberOfCases = useMemo(() => searchResult?.totalUniqueCases, [searchResult?.totalUniqueCases]);
    const Employees = useMemo(() => searchResult?.totalUniqueEmployments, [searchResult?.totalUniqueEmployments]);
    const Text = useMemo(() => {
        const employeeText = {
            text: t("employees").toLowerCase(),
            numberOf: Employees,
        };
        switch (currentSearch) {
            case "reimbursable":
            case "absencePeriod":
            case "ongoingAbsence": {
                return [
                    employeeText,
                    {
                        text: t("numberOfAbsenceOccasions").toLowerCase(),
                        numberOf: NumberOfCases,
                    },
                ];
            }
            case "longtermAbsence":
            case "recurringAbsence": {
                return [
                    employeeText,
                    {
                        text: t("numberOfDeviants").toLowerCase(),
                        numberOf: NumberOfCases,
                    },
                ];
            }
            case "medicalCertificate": {
                return [
                    employeeText,
                    {
                        text: t("numberOfMedicalCertificates").toLowerCase(),
                        numberOf: NumberOfCases,
                    },
                ];
            }
            case "cases": {
                return [
                    employeeText,
                    {
                        text: t("numberOfCases").toLowerCase(),
                        numberOf: NumberOfCases,
                    },
                ];
            }
            case "activities": {
                return [
                    employeeText,
                    {
                        text: t("numberOfActivities").toLowerCase(),
                        numberOf: NumberOfCases,
                    },
                ];
            }
            default: {
                return [
                    employeeText,
                    {
                        text: t("numberOfAbsenceOccasions").toLowerCase(),
                        numberOf: NumberOfCases,
                    },
                ];
            }
        }
    }, [t, Employees, currentSearch, NumberOfCases]);
    const Header = useMemo(() => {
        return Text.map((value) => `${value.numberOf || 0} ${value.text}`).join(", ");
    }, [Text]);

    const Body = useMemo(() => {
        const fromText = periodFilter?.from ? getDateDisplayValue(periodFilter.from, "d MMM yyyy") : "";
        const toText = periodFilter?.to ? `- ${getDateDisplayValue(periodFilter.to, "d MMM yyyy")}` : "";
        return currentSearch === "ongoingAbsence" ? t("ongoing") : `${fromText}${toText}`;
    }, [currentSearch, periodFilter?.from, periodFilter?.to, t]);
    return <DisplayPeriod header={Header} text={t("period")} body={Body} />;
}
