import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserResult } from "@medhelp/ui";
import { useDebounceFunction } from "hooks";
import { actions, selectors, thunkActions } from "store/organization";
import { IEmploymentSearchResult } from "interfaces/IEmploymentSearchResult";
import LoadPageData from "../LoadPageData";
import Employee from "./Employee";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

export default function Index() {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const employees = useAppSelector(selectors.getEmployees);
    const navigate = useNavigate();
    const onChange = useDebounceFunction(
        useCallback(
            (query: string) => {
                dispatch(thunkActions.searchEmployee(query));
            },
            [dispatch],
        ),
        50,
    );
    const onSearch = useCallback(() => {
        setIsLoading(true);
        dispatch(actions.resetPageNumber());
        dispatch(
            thunkActions.getEmploymentByCompanyIdsOrDepartments({
                pageNumber: 1,
            }),
        ).then(() => {
            setIsLoading(false);
        });
    }, [dispatch]);

    const onSearchResult = useCallback(
        (searchItem: IEmploymentSearchResult) => {
            navigate(`/followup/employee/${searchItem.referenceKey}/absence`);
        },
        [navigate],
    );
    const searchResult = useCallback(
        (searchItem: IEmploymentSearchResult, index: number) => (
            <UserResult
                index={index}
                searchItem={searchItem}
                onClick={onSearchResult}
                isArchived={searchItem.archived}
            />
        ),
        [onSearchResult],
    );
    return (
        <LoadPageData>
            <Employee
                searchResultTitle={t("suggestedSearches")}
                employmentSearch={employees}
                searchHeader={t("coworkers")}
                recentEmploymentSearches={[]}
                onChange={onChange}
                searchResult={searchResult}
                placeholder={t("search.employee.placeholder")}
                buttonText={t("search.searchQuery.filters.submitButton")}
                onSearch={onSearch}
                isLoading={isLoading}
            />
        </LoadPageData>
    );
}
