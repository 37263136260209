import Card from "@medhelp/ui/Card";

export interface IProps {
    id: string;
    message: string;
}

const NoUserDataMessage = ({ message }: IProps) => (
    <Card className="xs:mt-14 mt-9 mb-6">
        <p>{message}</p>
    </Card>
);

export default NoUserDataMessage;
