import * as React from "react";
import { useParams } from "react-router-dom";
import { loadEmployeeToPrincess } from "utils/postToPrincess";
import { useAppDispatch, useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";
import { selectors as AbsenceReportsSelectors, thunkActions } from "../redux";
import { redirectUserToOldAbsenceReportWeb } from "../utils/absenceReports";

/**
 * Initialize all absence report for `currentUser`
 */
const useLoadAbsenceReport = (onlyLoadAbsenceReport = false) => {
    const { routeEmploymentGuid } = useParams();

    const dispatch = useAppDispatch();

    const { SelectedEmployee } = useAppSelector(AbsenceReportsSelectors.getAbsenceReportsSlice);

    const { referenceKey, customerId, companyId } = useAppSelector(AbsenceReportsSelectors.getSelectedEmployee);
    const templates = useAppSelector(AbsenceReportsSelectors.getAbsenceTemplates);
    const configurationFields = useAppSelector(AbsenceReportsSelectors.getConfigurationFields);
    const isOK = useAppSelector(AbsenceReportsSelectors.getIsOK);

    const userContext = useAppSelector(UserSelectors.getUserContext);
    const isManage = useAppSelector(UserSelectors.getIsAbsenceReportAdmin);

    const initializeAbsenceReport = React.useCallback(() => {
        // Added if check to avoid unnecessary API calls in reporting wizard.
        // When fetching multiple times, the wizard sometimes goes to last page instead of the templates fields because templates fields has not been fetched yet.
        // Consider removing useLoadAbsenceReport(true); from AbsenceReportingMeCreatePage instead if not needed.

        if (templates === null || configurationFields === null || !isOK) {
            const language = userContext.user?.userPreferences?.language || "";
            if (referenceKey && customerId && companyId) {
                dispatch(
                    thunkActions.getAbsenceReportInit({
                        employmentGuid: referenceKey,
                        customerId,
                        companyId,
                        locale: language,
                        isManage,
                    }),
                );
            }
        }
    }, [
        templates,
        configurationFields,
        isOK,
        userContext.user?.userPreferences?.language,
        referenceKey,
        customerId,
        companyId,
        dispatch,
        isManage,
    ]);
    React.useEffect(() => {
        initializeAbsenceReport();
    }, [initializeAbsenceReport]);

    React.useEffect(() => {
        if (SelectedEmployee && !onlyLoadAbsenceReport) {
            if (!SelectedEmployee.newReporting) {
                redirectUserToOldAbsenceReportWeb(
                    userContext.HasMedicalAdvisory,
                    SelectedEmployee.id || SelectedEmployee.employmentId,
                );
            }
            if (userContext.HasMedicalAdvisory) {
                if (Number(routeEmploymentGuid)) {
                    loadEmployeeToPrincess(
                        SelectedEmployee.id.toString() || SelectedEmployee.referenceKey,
                        SelectedEmployee.socialSecurityNumber,
                        SelectedEmployee.name,
                        SelectedEmployee.companyCustomerKey,
                        SelectedEmployee.countryCode,
                        null,
                    );
                } else {
                    loadEmployeeToPrincess(
                        SelectedEmployee.employmentId.toString() || SelectedEmployee.referenceKey,
                        SelectedEmployee.socialSecurityNumber,
                        `${SelectedEmployee.firstName} ${SelectedEmployee.lastName}`,
                        SelectedEmployee.customerKey,
                        SelectedEmployee.countryCode,
                        null,
                    );
                }
            }
        }
    }, [dispatch, SelectedEmployee, userContext.HasMedicalAdvisory, routeEmploymentGuid, onlyLoadAbsenceReport]);
};
export default useLoadAbsenceReport;
