import { useTranslation } from "react-i18next";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import * as UserSelectors from "store/userSelectors";
import AbsenceReportEdit from "../../pages/absencereporting/components/AbsenceReportEdit";
import AbsenceReportCard from "../../pages/absencereporting/components/AbsenceReportCard";
import * as AbsenceReportSelectors from "../../pages/absencereporting/redux/absenceReportsSelectors";
import * as Types from "./ongoingAbsenceReports.types";
import { isBeforeToday } from "utils/date";
import { useAppSelector } from "store/hooks";

interface IProps extends Types.ISharedProps {
    absenceReport: IAbsenceReportExtended;
    handleAbsenceReportHealthyModal: (absenceReports: IAbsenceReportExtended) => void;
    selectedAbsenceReport: IAbsenceReportExtended;
}

const OngoingNotPreliminaryAbsenceReport = ({
    handleAbsenceReportEdit,
    handleAbsenceReportDeleteModal,
    handleAbsenceReportHealthyModal,
    absenceReport,
}: IProps) => {
    const { t } = useTranslation();
    const isEditing = useAppSelector(AbsenceReportSelectors.getIsEditing);
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const isManage = useAppSelector(UserSelectors.getIsAbsenceReportAdmin);
    if (absenceReport.isEdit) {
        return (
            <AbsenceReportEdit
                absenceReportId={absenceReport.id!}
                onCancel={() => handleAbsenceReportEdit(absenceReport)}
                key={absenceReport.id}
            />
        );
    } else {
        return (
            <AbsenceReportCard
                absenceReport={absenceReport}
                complete={absenceReport.end}
                header={`${t("ongoingAbsence")}: ${absenceReport.type === "Sick" ? t("sick") : t("careOfChild")}`}
                key={absenceReport.id}
                isEditable={!isEditing}
                onReportHealthyButtonClick={handleAbsenceReportHealthyModal}
                onDeleteButtonClick={isManage ? handleAbsenceReportDeleteModal : undefined}
                onEditButtonClick={handleAbsenceReportEdit}
                reportHealthyButtonDisabled={
                    absenceReport.isPreliminary &&
                    !userContext.HasCustomerSupport &&
                    !userContext.HasMedicalAdvisory &&
                    !userContext.HasAbsenceAdministration
                }
                reportHealthyButtonId={absenceReport.isPreliminary ? "button-submit" : "button-healthy"}
                reportHealthyButtonText={
                    absenceReport.end
                        ? t("changeReportHealthy")
                        : absenceReport.isPreliminary
                          ? t("approve")
                          : t("reportHealthy")
                }
                infoTextType={
                    isBeforeToday(absenceReport.backAtWork || "")
                        ? "estimatedBackPassed"
                        : absenceReport.end
                          ? "reportedHealthy"
                          : undefined
                }
            />
        );
    }
};

export default OngoingNotPreliminaryAbsenceReport;
