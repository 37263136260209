import { useMemo } from "react";
import { useHover } from "hooks";
import { MedHelpAbsenceFollowUpWebApiResponseDashboardAbsenceDegree } from "swagger/absencefollowup";
import { newDateString, isSameOrBeforeDate } from "utils/date";
import { TooltipVariedDegrees } from "@medhelp/ui";
import Icon from "components/Icon";

const VariedAbsenceDegrees = ({
    degrees,
}: {
    degrees: MedHelpAbsenceFollowUpWebApiResponseDashboardAbsenceDegree[];
}) => {
    const [hovered, eventHandlers] = useHover();

    const filteredDegrees = useMemo(
        () =>
            degrees
                .map((item) => ({
                    ...item,
                    timeStamp: item.timeStamp ?? newDateString(),
                }))
                .filter((item) => isSameOrBeforeDate(item.timeStamp)),
        [degrees],
    );

    return (
        <>
            <div {...eventHandlers} className={`flex items-center ${hovered && "text-mhdarkgreen-original"}`}>
                <Icon
                    icon={
                        filteredDegrees.length > 1 && filteredDegrees[0].degree! > filteredDegrees[1].degree!
                            ? "arrowUp"
                            : filteredDegrees.length > 1 && filteredDegrees[0].degree! < filteredDegrees[1].degree!
                              ? "arrowDown"
                              : "arrowRight"
                    }
                    size={12}
                />
                <div className="ml-1">
                    {filteredDegrees?.length > 0 && filteredDegrees[0]?.degree ? filteredDegrees[0].degree : "N/A"}%
                </div>
            </div>

            {hovered && filteredDegrees?.length > 0 && <TooltipVariedDegrees degrees={filteredDegrees} />}
        </>
    );
};

export default VariedAbsenceDegrees;
