import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { updateCaseActivity as updateCaseActivityAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const updateCaseActivity = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(updateCaseActivityAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.payload?.data.id,
                );
                if (currentActivity) {
                    Object.assign(currentActivity, {
                        title: action.payload?.data.title,
                        description: action.payload?.data.description,
                        date: action.payload?.data.date,
                        updated: action.payload.data.updated,
                    });
                    currentActivity.status = Status.OK;
                }
            }
        })
        .addCase(updateCaseActivityAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.meta.arg.activityModel.id,
                );
                if (currentActivity) {
                    currentActivity.status = Status.PENDING;
                }
            }
        })
        .addCase(updateCaseActivityAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.meta.arg.activityModel.id,
                );
                if (currentActivity) {
                    currentActivity.status = Status.ERROR;
                }
            }
        });

export default updateCaseActivity;
