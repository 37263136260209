import { createServicesUsingToken, getServices } from "api/services";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect } from "react";
import { setUserContextExtended } from "store/userActions";
import * as UserSelectors from "store/userSelectors";
import { checkIfNativeApp } from "utils/nativeApp";

const useOrganizationLogin = (loading: (value: boolean) => void) => {
    const dispatch = useAppDispatch();
    const token = useAppSelector(UserSelectors.token);
    useEffect(() => {
        if (token && token !== "") {
            if (checkIfNativeApp()) {
                window.ReactNativeWebView.postMessage(token);
                return;
            }
            loading(true);
            if (getServices() && getServices().token !== "") {
                getServices().setServicesJwtToken(token);
            } else {
                createServicesUsingToken(token);
            }
            dispatch(setUserContextExtended(token));
        }
    }, [dispatch, loading, token]);
};

export default useOrganizationLogin;
