import SecondaryButton from "components/SecondaryButton";
import TertiaryButton from "components/TertiaryButton";
import { useTranslation } from "react-i18next";
interface ISettingsHeader {
    isExpanded: boolean;
    onCancel: () => void;
    onSave: () => void;
}
const CardHeaderWithButtons = ({ isExpanded, onCancel, onSave }: ISettingsHeader) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-1 justify-between">
            <div>
                <h2>{t("profileSettingsUser")}</h2>
                <p>{t("profileSettingsBody")}</p>
            </div>
            {isExpanded && (
                <div className="hidden sm:flex px-0 gap-2 flex-col xs:px-8 xs:flex-row xs:gap-0">
                    <TertiaryButton id="cool" text={t("abort")} onClick={onCancel} className="mr-2" />
                    <SecondaryButton id="cool" text={t("save")} onClick={onSave} />
                </div>
            )}
        </div>
    );
};
export default CardHeaderWithButtons;
