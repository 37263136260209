import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectors } from "../../../redux";
import { getDate, getDateDisplayValue, getDiffInTimeUnit } from "utils/date";
import { isEmpty } from "lodash/fp";
import { ColumnText } from "@medhelp/ui";
import EmployeeCardDegrees from "../../EmployeeCardDegrees";
import Icon from "components/Icon";
import { getHasBaseProduct } from "store/userSelectors";
import { ReactElement } from "react";

interface IProps {
    healthCaseId: string;
}

interface IAbsenceDetails {
    header: string;
    body: ReactElement;
    key: string;
}

const CaseRelatedAbsence = (props: IProps) => {
    const { healthCaseId } = props;
    const { t } = useTranslation("followup");
    const caseRelatedAbsences = useAppSelector(selectors.getCaseRelatedAbsences(healthCaseId));
    const hasBaseProduct = useAppSelector(getHasBaseProduct);

    const absenceDetails: IAbsenceDetails[] = [
        {
            header: t("absencePeriod"),
            body: (
                <p className="flex flex-col">
                    {caseRelatedAbsences.map((absence) => (
                        <p className="flex text-mhdarkgreen-original text-sm">
                            <Icon
                                icon="calendar"
                                size={12}
                                stroke="#192D05"
                                className="mr-2 self-center"
                                strokeWidth={2}
                            />
                            {`${getDateDisplayValue(absence.startDate ?? "", "d MMM yyyy")} - ${
                                absence.endDate ? getDateDisplayValue(absence.endDate, "d MMM yyyy") : t("ongoing")
                            }`}
                        </p>
                    ))}
                </p>
            ),
            key: "current-case-absencePeriod",
        },
        {
            header: t("numberOfAbsenceDays"),
            body: (
                <p className="flex flex-col">
                    {caseRelatedAbsences.map((absence) => (
                        <p className="text-mhdarkgreen-original text-sm">
                            {`${
                                getDiffInTimeUnit(
                                    "days",
                                    "forward",
                                    absence.startDate ?? getDate(),
                                    absence.endDate ?? getDate(),
                                ) + 1
                            } ${
                                getDiffInTimeUnit(
                                    "days",
                                    "forward",
                                    absence.startDate ?? getDate(),
                                    absence.endDate ?? getDate(),
                                ) +
                                    1 >
                                1
                                    ? t("days")
                                    : t("day").toLowerCase()
                            }`}
                        </p>
                    ))}
                </p>
            ),
            key: "current-case-numberOfAbsenceDays",
        },
        {
            header: t("cause"),
            body: (
                <p className="flex flex-col">
                    {caseRelatedAbsences.map((absence) => (
                        <p className="text-mhdarkgreen-original text-sm">
                            {absence.properties?.find(({ name }) => name === "AbsenceCause")?.value
                                ? t(
                                      `followUpAbsence.summaryOfAbsence.${
                                          absence.properties?.find(({ name }) => name === "AbsenceCause")?.value
                                      }`,
                                  )
                                : "-"}
                        </p>
                    ))}
                </p>
            ),
            key: "current-case-cause",
        },
        {
            header: t("degree"),
            body: (
                <p className="flex flex-col w-18">
                    {caseRelatedAbsences.map((absence) => (
                        <EmployeeCardDegrees
                            body={
                                <p className="text-mhdarkgreen-original text-sm">
                                    {`${absence.currentDegree?.toString()} %`}
                                </p>
                            }
                            absenceReportId={absence.absenceReportId}
                        />
                    ))}
                </p>
            ),
            key: "current-case-degree",
        },
        {
            header: t("workRelated"),
            body: (
                <p className="flex flex-col">
                    {caseRelatedAbsences.map((absence) => (
                        <p className="text-mhdarkgreen-original text-sm">
                            {absence.properties?.find(({ name }) => name === "WorkRelated")?.value === "true"
                                ? t("yes")
                                : t("no")}
                        </p>
                    ))}
                </p>
            ),
            key: "current-case-workRelated",
        },
        {
            header: t("workplaceAccident"),
            body: (
                <p className="flex flex-col">
                    {caseRelatedAbsences.map((absence) => (
                        <p className="text-mhdarkgreen-original text-sm">
                            {absence.properties?.find(({ name }) => name === "WorkplaceAccident")?.value === "true"
                                ? t("yes")
                                : t("no")}
                        </p>
                    ))}
                </p>
            ),
            key: "current-case-workplaceAccident",
        },
    ];

    const absenceDetailsFilter = (absenceDetails: IAbsenceDetails[]) => {
        const filtered = hasBaseProduct
            ? absenceDetails.filter(
                  (item) =>
                      item.key !== "current-case-cause" &&
                      item.key !== "current-case-workRelated" &&
                      item.key !== "current-case-workplaceAccident",
              )
            : absenceDetails;
        return filtered;
    };

    return isEmpty(caseRelatedAbsences) ? null : (
        <div className="border border-grey-350 p-6 pt-4 mx-8 mb-6">
            <h6 className="font-bold">{t("relatedAbsence")}</h6>
            <div className="flex flex-wrap pt-6">
                {absenceDetailsFilter(absenceDetails).map((item, index) => (
                    <ColumnText
                        {...item}
                        fontSize="sm"
                        boldHeader
                        className="pl-0 pr-12 pb-1"
                        key={`${item.key}-${index}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default CaseRelatedAbsence;
