import React from "react";
import { useBreakpoints } from "hooks";
import { useTranslation } from "react-i18next";
import { thunkActions } from "../../redux";
import ActivitiesLog from "../ActivitiesLog";
import MobileActivitesLog from "../ActivitiesLog/ActivitiesLogMobile";

interface IActivityLogsComp {
    data: thunkActions.IActivityLog[];
}

const ActivitiesLogs = ({ data }: IActivityLogsComp) => {
    const { t } = useTranslation();
    const breakpoints = useBreakpoints();
    if (!data) return null;
    return (
        <div className="px-8 pb-12">
            <p className=" text-grey-700 py-3">{t("activityTabName")}:</p>
            <hr />
            {data.map((activity, index) =>
                breakpoints.isSmallscreen ? (
                    <MobileActivitesLog
                        {...activity}
                        key={`acitvity-log-mobile-${activity.performedByUserAccountId}-${index}`}
                    />
                ) : (
                    <ActivitiesLog
                        {...activity}
                        key={`acitvity-log-desktop-${activity.performedByUserAccountId}-${index}`}
                    />
                ),
            )}
        </div>
    );
};
export default ActivitiesLogs;
