import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { thunkActions } from "pages/FollowUp/redux";
import { getCustomerId } from "store/userSelectors";
import {
    getHasAccessToEmployeeRehab,
    getHasManualInitiation,
    getHasRehabServiceContract,
    getPreliminaryCaseExist,
    getUserCompanyId,
} from "pages/FollowUp/redux/followUpSelectors";
import { OngoingCases, NewCase, HistoryCases } from "pages/FollowUp/components/HealthCaseManagement";
import Icon, { Icons } from "components/Icon";
import SecondaryButton from "components/SecondaryButton";

interface ISalesText {
    key: string;
    icon: Icons;
    text: string;
}

const Case = () => {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const hasAccessToEmployeeRehab = useAppSelector(getHasAccessToEmployeeRehab);
    const hasRehabServiceContract = useAppSelector(getHasRehabServiceContract);
    const customerId = useAppSelector(getCustomerId);
    const companyId = useAppSelector(getUserCompanyId);
    const hasPreliminaryHealthCase = useAppSelector(getPreliminaryCaseExist);
    const hasManualInitiation = useAppSelector(getHasManualInitiation);

    useEffect(() => {
        if (customerId) {
            dispatch(
                thunkActions.fetchConfigurationFields({
                    customerId,
                    companyId,
                }),
            );
        }
    }, [companyId, customerId, dispatch]);

    const salesTextList: ISalesText[] = [
        {
            key: "case-document-sales-info",
            icon: "documentSearch",
            text: t("followUpKeepTrackDocument"),
        },
        {
            key: "case-activities-sales-info",
            icon: "calendar",
            text: t("addGetRemindersMarkCompletedActivities"),
        },
        {
            key: "case-notes-sales-info",
            icon: "notes",
            text: t("makeNotes"),
        },
        {
            key: "document-template-sales-info",
            icon: "document",
            text: t("useDocumentTemplates"),
        },
    ];

    return hasRehabServiceContract ? (
        <div>
            <p className="mt-6 mb-8">
                {hasAccessToEmployeeRehab ? t("rehabCaseManagementInfo") : t("noPermissionInfo")}
            </p>
            {hasAccessToEmployeeRehab ? (
                <>
                    <OngoingCases />
                    {!hasPreliminaryHealthCase && hasManualInitiation && <NewCase />}
                    <HistoryCases />
                </>
            ) : null}
        </div>
    ) : (
        <div>
            <p className="mt-10 mb-12">{t("healthCaseManagementMissing")}</p>
            <div className="flex flex-col items-center px-8 py-13 bg-background-200">
                <div className="flex justify-between w-full mb-13">
                    {salesTextList.map((item) => (
                        <div key={item.key} className="flex flex-col items-center w-60">
                            <Icon icon={item.icon} size={30} />
                            <h4 className="mt-5 text-center">{item.text}</h4>
                        </div>
                    ))}
                </div>
                <a href="https://knowledge.medhelp.se/rehabstod">
                    <SecondaryButton
                        id="read-more-about-hcm"
                        text={t("readMoreAboutCaseService")}
                        rightIcon={<Icon icon="externalLink" />}
                    />
                </a>
            </div>
        </div>
    );
};

export default Case;
