import React, { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ExpandableComponent from "components/ExpandableComponent";
import { actions, IFileState, selectors } from "../../redux";
import Appendix from "../Appendix";
import HistoryCertificateHeader from "./HistoryCertificateHeader";
import SecondaryButton from "components/SecondaryButton";
import Icon from "components/Icon";
import { useTranslation } from "react-i18next";

interface IProps {
    certificate: IFileState;
    index: number;
    expanded?: boolean;
}
const HistoryCertificateItem = ({ certificate, index, expanded = false }: IProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(expanded);
    const certificatesByGroupKey = useAppSelector(selectors.getCertificatesByGroupKey);
    const remove = useCallback(() => {
        if (!certificate.owner || !certificate.groupKey) {
            console.error("certficate owner or groupKey is missing");
            return;
        }
        dispatch(
            actions.deleteMedicalCertificate({
                owner: certificate.owner,
                groupKey: certificate.groupKey,
            }),
        );
    }, [certificate.groupKey, certificate.owner, dispatch]);

    if (!certificate.groupKey) return null;
    return (
        <div
            className={`w-full ${isExpanded && "bg-primaryBg"} ${
                index > 0 && "border-t-2  border-solid border-mhgrey-border"
            }`}
        >
            <ExpandableComponent
                headerView={
                    <HistoryCertificateHeader certificate={certificate} remove={isExpanded ? remove : undefined} />
                }
                getExpanded={setIsExpanded}
                onMount={expanded}
            >
                <div className="px-6 pb-8 xs:pt-10 pt-0">
                    <Appendix header="Bilagor" images={certificatesByGroupKey(certificate.groupKey)} />
                </div>
                <div className="block sm:hidden">
                    <hr />
                    <div className="py-4 px-8">
                        <SecondaryButton onClick={remove} id="cool">
                            <div className="flex items-center gap-2 just-center">
                                <Icon icon="trashCan" />
                                {t("delete")}
                            </div>
                        </SecondaryButton>
                    </div>
                </div>
            </ExpandableComponent>
        </div>
    );
};

export default HistoryCertificateItem;
