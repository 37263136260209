import { TreeViewNode } from "swagger/employeeattendance";
import { findChildren, mapChildren } from "./iterateChildren";

/**
 * @returns `TreeViewNode` as selectable based on user `accessRights`
 */
export const makeTreeViewNodesSelecteableByAccessRight = (
    predict: (id: number) => boolean,
    data: TreeViewNode[],
): TreeViewNode[] =>
    mapChildren(
        (x) => ({
            ...x,
            selectable: x?.id ? predict(x?.id) : false,
        }),
        data,
    );

export const hasTopDeparments = (rights: TreeViewNode) => Boolean(rights.label?.includes("**TopDepartment**"));
/**
 * @returns `TreeViewNode` as selectable based on user `accessRights`
 */
export const findTopDepartments = (data: TreeViewNode[]) => findChildren(hasTopDeparments, data);
