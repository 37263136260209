import { useEffect } from "react";
import ErrorWidget from "../ErrorWidget";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { StackedBarChart } from "pages/dashboard/components/Charts";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import EstimatedUpcomingAbsenceWidgetLoader from "./EstimatedUpcomingAbsenceWidgetLoader";
import { getEstimatedUpcomingAbsence, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const EstimatedUpcomingAbsenceWidget = ({ id, type }: Widget) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("dashboard");
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getEstimatedUpcomingAbsence(id));

    useEffect(() => {
        !isDragging && dispatch(fetchWidgetData({ id, type }));
    }, [dispatch, id, type, isDragging]);

    if (status === Status.PENDING) {
        return <EstimatedUpcomingAbsenceWidgetLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <StackedBarChart
            id={"estimated-upcoming-absence"}
            mySelection={t("sumOfUpcomingAbsences")}
            data={
                data?.length > 1 &&
                data?.some(
                    (x) =>
                        (x.estimatedAbsence && x.estimatedAbsence > 0) ||
                        (x.estimatedAbsenceWithPastBackAtWork && x.estimatedAbsenceWithPastBackAtWork > 0),
                )
                    ? data?.map((item) => ({
                          name: t(item?.day).substring(0, 3),
                          firstDatasetValue: item.estimatedAbsence || 0,
                          secondDatasetValue: item.estimatedAbsenceWithPastBackAtWork || 0,
                          modalHeading: `${t("estimatedAbsenceModal")} ${t(item.day).toLowerCase()}`,
                          tooltipHeading: `${t("estimatedAbsence")} ${t(item.day).toLowerCase()}`,
                          tooltipText: `${item.estimatedAbsence + item.estimatedAbsenceWithPastBackAtWork} ${t(
                              "absencesTotally",
                          )}.`,
                          tooltipTextSecondDataset: `${t("estimatedAbsencePassed", {
                              passedBackAtWork: item.estimatedAbsenceWithPastBackAtWork,
                          })}`,
                          employeeDetails: item.employeeDetails,
                      }))
                    : data.map((item) => ({
                          name: t(item.day).substring(0, 3),
                          firstDatasetValue: 0,
                          secondDatasetValue: 0,
                      }))
            }
        />
    );
};

export default EstimatedUpcomingAbsenceWidget;
