import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServices } from "api/services";
import { IFetchInboxMessages, IUpdateMessageRead } from "../types";
import { InboxMessage } from "swagger/messenger";
import { isEmpty, uniq } from "lodash/fp";
import { EmployeeNamesContract } from "swagger/employeeattendance";

export const fetchEventMessages = createAsyncThunk(
    "fetchEventMessages",
    async ({ offset, limit }: IFetchInboxMessages) => {
        const { data } = await getServices().clients.inbox.apiV1InboxEventMessagesGet(offset, limit);
        return {
            inbox: [
                {
                    inbox: data.inbox?.map((message) => ({
                        ...message,
                        expanded: false,
                    })),
                    page: offset / limit + 1,
                },
            ],
            totalCount: data.totalCount,
        };
    },
);

export const fetchSubscriptionMessages = createAsyncThunk(
    "fetchSubscriptionMessages",
    async ({ offset, limit }: IFetchInboxMessages) => {
        const { data } = await getServices().clients.inbox.apiV1InboxSubscriptionMessagesGet(offset, limit);

        const employmentIds = data.inbox?.map((message) => message.employmentId || "");
        let employeeNames: EmployeeNamesContract[] = [];
        try {
            if (!isEmpty(employmentIds)) {
                const uniqueEmploymentIds = uniq(employmentIds);
                const { data } =
                    await getServices().clients.employment.apiEmploymentGetEmployeeNamesPost(uniqueEmploymentIds);
                employeeNames = data;
            }
        } catch (error) {
            console.error("Failed to fetch employee names", error);
        }

        const getEmployeeName = (message: InboxMessage) => {
            const employee = employeeNames?.find(({ referenceKey }) => referenceKey === message.employmentId);
            return employee ? `${employee?.firstName} ${employee?.lastName}` : "nameNotAvailable";
        };

        return {
            inbox: [
                {
                    inbox: data.inbox?.map((message) => ({
                        ...message,
                        employeeName: getEmployeeName(message),
                        expanded: false,
                    })),
                    page: offset / limit + 1,
                },
            ],
            totalCount: data.totalCount,
        };
    },
);

export const fetchUnreadCount = createAsyncThunk("fetchUnreadCount", async () => {
    const { data } = await getServices().clients.inbox.apiV1InboxUnreadGet();
    return data;
});

export const updateMessageRead = createAsyncThunk("updateMessageRead", async ({ type, id }: IUpdateMessageRead) => {
    await getServices().clients.inbox.apiV1InboxTypeIdReadPut(type, id);
    return { type, id };
});

export const updateEventMessagesReadAll = createAsyncThunk("updateEventMessagesReadAll", async () => {
    await getServices().clients.inbox.apiV1InboxEventMessagesReadAllPut();
});

export const updateSubscriptionMessagesReadAll = createAsyncThunk("updateSubscriptionMessagesReadAll", async () => {
    await getServices().clients.inbox.apiV1InboxSubscriptionMessagesReadAllPut();
});

export const downloadFile = createAsyncThunk("downloadFile", async (id: string) => {
    const res = await getServices().clients.storage.filesIdDownloadGet(id, {
        responseType: "blob",
    });
    const url = URL.createObjectURL(res.data);
    window.open(url, "_blank");
});
