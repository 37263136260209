import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { thunkActions } from "pages/FollowUp/redux";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { toast } from "react-toastify";
import { getDateDisplayValue } from "utils/date";
import { ModalCommonFooter, ModalFrame, SingleDatepicker } from "@medhelp/ui";
import { useSingleDatepickerProps } from "hooks";
import Icon from "components/Icon";

interface IProps {
    healthCase: IHealthCase;
    modalIsOpen: boolean;
    setModalIsOpen: () => void;
}

const ConfirmRehabCaseModal = (props: IProps) => {
    const { healthCase, modalIsOpen, setModalIsOpen } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const [date, setDate] = useState(healthCase?.start);

    const handleConfirmStart = () => {
        setModalIsOpen();
        if (healthCase?.id && date) {
            dispatch(
                thunkActions.activateHealthCase({
                    healthCaseId: healthCase.id,
                    start: date,
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("caseStarted")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("caseNotStarted")}`, {
                        type: "error",
                    }),
                );
        }
    };

    const datepickerProps = useSingleDatepickerProps({
        onChangeDate: (v) => setDate(v),
        givenSelectedDate: date,
        placeholder: t("addDate"),
    });

    return (
        <ModalFrame
            id="confirm-rehab-case-modal"
            header={<h1>{t("confirmStart")}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            content={
                <div className="px-3 sm:px-0">
                    <p className="pb-5 mb-14 border-b border-mhgrey-light">{t("confirmRehabCaseModalInfo")}</p>
                    <p>{t("firstAbsenceDayAccordingToCalculation")}</p>
                    <p className="font-bold">{getDateDisplayValue(healthCase?.start, "d MMM yyyy")}</p>
                    <h5 className="font-bold pt-9">{t("enterFirstAbsenceDay")}</h5>
                    <SingleDatepicker {...datepickerProps} />
                </div>
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    tertiaryOnClick={setModalIsOpen}
                    primaryText={t("confirmStartDate")}
                    primaryOnClick={handleConfirmStart}
                    primaryRightIcon={<Icon icon="checkMark" stroke="#192D05" />}
                />
            }
        />
    );
};

export default ConfirmRehabCaseModal;
