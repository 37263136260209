import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { ServiceCheckboxContainer } from "../ServiceCheckbox";
import useSubmitDeviantRule from "pages/service/hooks/useSubmitDeviantRule";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { ServiceFieldArray, ServiceFieldArrayAppend } from "../ServiceFieldArray";
import { MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule } from "swagger/absencefollowup";
import { getDeviantAbsencesPerMonths, getServiceSlice } from "pages/service/redux/serviceSelectors";

const DeviantAbsencesPerMonths = () => {
    const { t } = useTranslation("service");
    const { companyId, customerId } = useAppSelector(getServiceSlice);
    const deviantAbsencesPerMonths = useAppSelector(getDeviantAbsencesPerMonths);
    const [submitDeviantRules, isLoading, itemsToDelete, setItemsToDelete] = useSubmitDeviantRule();

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isDirty },
    } = useForm({
        defaultValues: { deviantAbsencesPerMonths },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "deviantAbsencesPerMonths",
        // necessary because useFieldArray overwrites the id property
        keyName: "keyName",
    });

    useEffect(() => {
        reset({ deviantAbsencesPerMonths });
    }, [deviantAbsencesPerMonths, reset]);

    const onSubmit = (data: {
        deviantAbsencesPerMonths: MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule[];
    }) => {
        submitDeviantRules(data.deviantAbsencesPerMonths, () => reset({ deviantAbsencesPerMonths }));
    };

    const {
        register: registerInput,
        getValues: getInputValues,
        resetField: resetInputField,
        formState: { isValid: inputIsValid },
    } = useForm({
        defaultValues: { value: undefined, interval: undefined },
    });

    const checkForDuplicateValues = (value?: string, months?: string) =>
        getValues("deviantAbsencesPerMonths").some((x) => x.value === value && x.interval === months);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("subscriptionRepeatedAbsence")}
                reset={() => reset({ deviantAbsencesPerMonths })}
                disabled={!isDirty}
                isLoading={isLoading}
                content={fields.map(({ value, interval }) => (
                    <AccordionBooleanContent
                        key={`${value}-${interval}`}
                        label={t("times", { count: Number(value) }) + " " + t("inMonths", { count: Number(interval) })}
                    />
                ))}
            >
                <ServiceCheckboxContainer heading={t("notificationInCaseOfRepeatedAbsence")}>
                    {fields?.map(({ value, interval, id }, index) => (
                        <ServiceFieldArray
                            key={index}
                            index={index}
                            label={
                                t("times", { count: Number(value) }) + " " + t("inMonths", { count: Number(interval) })
                            }
                            onClick={() => {
                                setItemsToDelete([...itemsToDelete, String(id)]);
                                remove(index);
                            }}
                        />
                    ))}
                    <ServiceFieldArrayAppend
                        buttonText={t("addNotification")}
                        onClick={() => {
                            append({
                                absenceType: "Sick",
                                companyId: companyId,
                                customerId: customerId,
                                interval: getInputValues("interval"),
                                ruleName: "AbsencesPerMonths",
                                value: getInputValues("value"),
                            });
                            resetInputField("value");
                            resetInputField("interval");
                        }}
                        label={t("numberOfTimes")}
                        secondaryLabel={t("duringNumberOfMonths")}
                        register={registerInput("value", {
                            required: true,
                            validate: () =>
                                !checkForDuplicateValues(getInputValues("value"), getInputValues("interval")),
                            min: 1,
                        })}
                        secondaryRegister={registerInput("interval", {
                            required: true,
                            validate: () =>
                                !checkForDuplicateValues(getInputValues("value"), getInputValues("interval")),
                            min: 1,
                        })}
                        disabled={!inputIsValid}
                        type="number"
                    />
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default DeviantAbsencesPerMonths;
