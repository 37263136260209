import { useMemo } from "react";
import { Card } from "@medhelp/ui";
import { useBreakpoints } from "hooks";
import Error from "../Error";
import NoData from "../NoData";

interface IProps {
    errorMessage: string | null;
    noData: boolean;
    preview: JSX.Element | undefined;
    extras?: JSX.Element;
    result: {
        desktop: JSX.Element;
        mobile: JSX.Element;
    };
    customResult?: JSX.Element;
}
const SearchTableResult = ({ preview, extras, result, noData, errorMessage, customResult }: IProps) => {
    const breakpoints = useBreakpoints();
    const ResultTable = useMemo(() => {
        if (errorMessage) return <Error body={errorMessage} />;
        if (preview) return preview;
        let currentResult = null;
        if (noData) {
            currentResult = <NoData />;
        }
        if (!currentResult) {
            currentResult = !breakpoints.isMediumScreen ? <Card className="p-0">{result.desktop}</Card> : result.mobile;
        }
        return (
            <>
                {extras && extras}
                {customResult ? customResult : currentResult}
            </>
        );
    }, [
        breakpoints.isMediumScreen,
        errorMessage,
        extras,
        noData,
        preview,
        result.desktop,
        result.mobile,
        customResult,
    ]);
    return ResultTable;
};

export default SearchTableResult;
