/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchAbsenceActivityLogs } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchActivityLogs = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchAbsenceActivityLogs.fulfilled, (state, action) => {
            const { activityLogs, absenceReportId } = action.payload;
            state.absenceActivityLogs[absenceReportId] = activityLogs;
            state.status.activityLogs[action.meta.arg] = Status.OK;
        })
        .addCase(fetchAbsenceActivityLogs.pending, (state, action) => {
            state.status.activityLogs[action.meta.arg] = Status.PENDING;
        })
        .addCase(fetchAbsenceActivityLogs.rejected, (state, action) => {
            state.status.activityLogs[action.meta.arg] = Status.ERROR;
        });

export default fetchActivityLogs;
