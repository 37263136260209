/* tslint:disable */
/* eslint-disable */
/**
 * My Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AccessRightType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23
} as const;

export type AccessRightType = typeof AccessRightType[keyof typeof AccessRightType];


/**
 * 
 * @export
 * @interface AccountAccessChangeEvent
 */
export interface AccountAccessChangeEvent {
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEvent
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessChangeEvent
     */
    'eventDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessChangeEvent
     */
    'effectiveDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEvent
     */
    'companyId'?: number;
    /**
     * 
     * @type {UserAccountOperationType}
     * @memberof AccountAccessChangeEvent
     */
    'userAccountOperationType'?: UserAccountOperationType;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEvent
     */
    'resourceId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEvent
     */
    'groupReferenceId'?: number | null;
}


/**
 * 
 * @export
 * @interface AccountAccessRightGroupPeriod
 */
export interface AccountAccessRightGroupPeriod {
    /**
     * 
     * @type {string}
     * @memberof AccountAccessRightGroupPeriod
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessRightGroupPeriod
     */
    'deactivationDate'?: string | null;
}
/**
 * 
 * @export
 * @interface AccountAccessRightViewModel
 */
export interface AccountAccessRightViewModel {
    /**
     * 
     * @type {string}
     * @memberof AccountAccessRightViewModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {AccessRightType}
     * @memberof AccountAccessRightViewModel
     */
    'accessRightType'?: AccessRightType;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessRightViewModel
     */
    'externalId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessRightViewModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountAccessRightViewModel
     */
    'recursive'?: boolean;
}


/**
 * 
 * @export
 * @interface CatalogEntryModel
 */
export interface CatalogEntryModel {
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryModel
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryModel
     */
    'employmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'removalDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'automaticAnonymizationDate'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogEntryModel
     */
    'telephoneNumbers'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogEntryModel
     */
    'endedEmployment'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'displayField'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryModel
     */
    'retailerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'retailerName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogEntryModel
     */
    'doNotDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'referenceKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogEntryModel
     */
    'hasAbsence'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogEntryModel
     */
    'hasRehab'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'maxEndPeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'minStartPeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryModel
     */
    'erasurePeriod'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CatalogEntryModel
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryModel
     */
    'customerId'?: number;
}
/**
 * 
 * @export
 * @interface ChangeAutoImportPostModel
 */
export interface ChangeAutoImportPostModel {
    /**
     * 
     * @type {number}
     * @memberof ChangeAutoImportPostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ChangeAutoImportPostModel
     */
    'value'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChangeAutoImportPostModel
     */
    'performedByUserAccountId'?: number;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'number'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Company
     */
    'departmentIds'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'automatedOrganizationalChangeProcess'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'isReadOnly'?: boolean;
}
/**
 * 
 * @export
 * @interface CompanyDataErasureAvailableConfiguration
 */
export interface CompanyDataErasureAvailableConfiguration {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureAvailableConfiguration
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureAvailableConfiguration
     */
    'absenceHistory'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureAvailableConfiguration
     */
    'caseDocumentation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureAvailableConfiguration
     */
    'medicalCertificates'?: number | null;
}
/**
 * 
 * @export
 * @interface CompanyDataErasureConfigurationPostModel
 */
export interface CompanyDataErasureConfigurationPostModel {
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureConfigurationPostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDataErasureConfigurationPostModel
     */
    'automatical'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureConfigurationPostModel
     */
    'noAbsenceHistoryOrRehabErasureMonthInterval'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureConfigurationPostModel
     */
    'absenceHistoryErasureMonthInterval'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureConfigurationPostModel
     */
    'rehabErasureMonthInterval'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureConfigurationPostModel
     */
    'workRelatedAbsenceErasureMonthInterval'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyDataErasureConfigurationPostModel
     */
    'performedByUserAccountId'?: number;
}
/**
 * 
 * @export
 * @interface CompanyFilter
 */
export interface CompanyFilter {
    /**
     * 
     * @type {number}
     * @memberof CompanyFilter
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface CompanyUser
 */
export interface CompanyUser {
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    'language'?: string | null;
    /**
     * 
     * @type {AccountAccessRightGroupPeriod}
     * @memberof CompanyUser
     */
    'accountAccessRightGroupPeriod'?: AccountAccessRightGroupPeriod | null;
    /**
     * 
     * @type {PrimaryCompany}
     * @memberof CompanyUser
     */
    'primaryCompany'?: PrimaryCompany | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyUser
     */
    'editable'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {ContactInformation}
     * @memberof CompanyUser
     */
    'contactInformation'?: ContactInformation | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUser
     */
    'maskedSocialSecurityNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    'birthYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyUser
     */
    'gender'?: number | null;
}
/**
 * 
 * @export
 * @interface ContactInformation
 */
export interface ContactInformation {
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'telephoneCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'telephoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'mobileCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'homeAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryMobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryMobileCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryMobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface CostCenter
 */
export interface CostCenter {
    /**
     * 
     * @type {string}
     * @memberof CostCenter
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CostCenter
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CostCenter
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CostCenter
     */
    'isUsed'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateAccessRights
 */
export interface CreateOrUpdateAccessRights {
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAccessRights
     */
    'absenceReporting'?: boolean;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateAccessRights
     */
    'absenceAdministration'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateAccessRights
     */
    'absenceFollowup'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateAccessRights
     */
    'absenceView'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAccessRights
     */
    'customerCompanyAdministration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAccessRights
     */
    'absenceReportingAssistance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAccessRights
     */
    'medicalAdvice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAccessRights
     */
    'absenceArchive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAccessRights
     */
    'medicalAdvising'?: boolean;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateAccessRights
     */
    'departmentAdministration'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateAccessRights
     */
    'rehabProcessManagement'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateAccessRights
     */
    'absenceStatistics'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateAccessRights
     */
    'absenceBoard'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAccessRights
     */
    'showRehabProcessManagement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAccessRights
     */
    'showAbsenceBoard'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAccessRights
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {IdNameMap}
     * @memberof CreateOrUpdateAccessRights
     */
    'topDepartment'?: IdNameMap | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAccessRights
     */
    'customerOrganizationAdministration'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateAccessRights
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateAccessRights
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateAccessRights
     */
    'performedByUserAccountId'?: number;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateCompanyAccessRights
 */
export interface CreateOrUpdateCompanyAccessRights {
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateCompanyAccessRights
     */
    'customerCompanyAdministration'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateCompanyAccessRights
     */
    'absenceArchive'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateCompanyAccessRights
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateCompanyAccessRights
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateCompanyAccessRights
     */
    'performedByUserAccountId'?: number;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateContactInformation
 */
export interface CreateOrUpdateContactInformation {
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateContactInformation
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateContactInformation
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'telephoneCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'telephoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'mobileCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'homeAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'updatedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'secondaryMobileCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'secondaryMobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateContactInformation
     */
    'secondaryEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateDepartmentsAccessRights
 */
export interface CreateOrUpdateDepartmentsAccessRights {
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'departmentId'?: number;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'absenceAdministration'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'absenceFollowup'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'absenceView'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'departmentAdministration'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'rehabProcessManagement'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'absenceStatistics'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'absenceBoard'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateDepartmentsAccessRights
     */
    'performedByUserAccountId'?: number;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateEmployment
 */
export interface CreateOrUpdateEmployment {
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateEmployment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateEmployment
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateEmployment
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEmployment
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEmployment
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateEmployment
     */
    'organizationNumber'?: string | null;
    /**
     * 
     * @type {IdNameCompanyMap}
     * @memberof CreateOrUpdateEmployment
     */
    'mainManager'?: IdNameCompanyMap | null;
    /**
     * 
     * @type {Array<Department>}
     * @memberof CreateOrUpdateEmployment
     */
    'departments'?: Array<Department> | null;
    /**
     * 
     * @type {Array<EmploymentGroup>}
     * @memberof CreateOrUpdateEmployment
     */
    'employmentGroups'?: Array<EmploymentGroup> | null;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateUserAccount
 */
export interface CreateOrUpdateUserAccount {
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateUserAccount
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateUserAccount
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'socialSecurityNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateUserAccount
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'language'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'mobileCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'telephoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'telephoneCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'homeAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'secondaryMobileCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'secondaryMobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'secondaryEmail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateUserAccount
     */
    'gender'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateUserAccount
     */
    'birthYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateOrUpdateUserAccount
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'periodActivationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateUserAccount
     */
    'periodDeactivationDate'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CredentialType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type CredentialType = typeof CredentialType[keyof typeof CredentialType];


/**
 * 
 * @export
 * @interface CustomerBillingConfigurationPartial
 */
export interface CustomerBillingConfigurationPartial {
    /**
     * 
     * @type {CustomerGroupType}
     * @memberof CustomerBillingConfigurationPartial
     */
    'customerGroupType'?: CustomerGroupType;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerBillingConfigurationPartial
     */
    'isConfigured'?: boolean;
}


/**
 * 
 * @export
 * @interface CustomerCompanyCache
 */
export interface CustomerCompanyCache {
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyCache
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerCompanyCache
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerCompanyCache
     */
    'companyName'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerGroupType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CustomerGroupType = typeof CustomerGroupType[keyof typeof CustomerGroupType];


/**
 * 
 * @export
 * @interface CustomerProduct
 */
export interface CustomerProduct {
    /**
     * 
     * @type {Product}
     * @memberof CustomerProduct
     */
    'product'?: Product | null;
    /**
     * 
     * @type {Subcategory}
     * @memberof CustomerProduct
     */
    'subcategory'?: Subcategory | null;
}
/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'path'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'parentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'defaultManagerEmploymentId'?: number | null;
    /**
     * 
     * @type {DepartmentManager}
     * @memberof Department
     */
    'defaultManager'?: DepartmentManager | null;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'businessUnitNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'employees'?: number;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'defaultManagerUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'referenceKey'?: string;
}
/**
 * 
 * @export
 * @interface DepartmentManager
 */
export interface DepartmentManager {
    /**
     * 
     * @type {number}
     * @memberof DepartmentManager
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentManager
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DepartmentManagerChannelType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type DepartmentManagerChannelType = typeof DepartmentManagerChannelType[keyof typeof DepartmentManagerChannelType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DepartmentsEmployeesExportType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type DepartmentsEmployeesExportType = typeof DepartmentsEmployeesExportType[keyof typeof DepartmentsEmployeesExportType];


/**
 * 
 * @export
 * @interface EmploymentGroup
 */
export interface EmploymentGroup {
    /**
     * 
     * @type {number}
     * @memberof EmploymentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentGroup
     */
    'nameIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentGroup
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmploymentGroup
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface Employments
 */
export interface Employments {
    /**
     * 
     * @type {Array<string>}
     * @memberof Employments
     */
    'employmentReferences'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ExportAllCompanyPermissionsModel
 */
export interface ExportAllCompanyPermissionsModel {
    /**
     * 
     * @type {number}
     * @memberof ExportAllCompanyPermissionsModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {PermissionsTranslations}
     * @memberof ExportAllCompanyPermissionsModel
     */
    'translations'?: PermissionsTranslations | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExportAllCompanyPermissionsModel
     */
    'includeDepartmentsAccessRights'?: boolean;
}
/**
 * 
 * @export
 * @interface ExportAllCompanyUsersAndEmployeesModel
 */
export interface ExportAllCompanyUsersAndEmployeesModel {
    /**
     * 
     * @type {number}
     * @memberof ExportAllCompanyUsersAndEmployeesModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {UserTypeExportFilter}
     * @memberof ExportAllCompanyUsersAndEmployeesModel
     */
    'userTypeFilter'?: UserTypeExportFilter;
    /**
     * 
     * @type {UserExportTransations}
     * @memberof ExportAllCompanyUsersAndEmployeesModel
     */
    'translations'?: UserExportTransations | null;
}


/**
 * 
 * @export
 * @interface ExportAllDepartmentEmployeesModel
 */
export interface ExportAllDepartmentEmployeesModel {
    /**
     * 
     * @type {number}
     * @memberof ExportAllDepartmentEmployeesModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportAllDepartmentEmployeesModel
     */
    'departmentId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ExportAllDepartmentEmployeesModel
     */
    'includeChildDepartments'?: boolean;
    /**
     * 
     * @type {UserExportTransations}
     * @memberof ExportAllDepartmentEmployeesModel
     */
    'translations'?: UserExportTransations | null;
    /**
     * 
     * @type {DepartmentsEmployeesExportType}
     * @memberof ExportAllDepartmentEmployeesModel
     */
    'departmentEmployeesType'?: DepartmentsEmployeesExportType;
}


/**
 * 
 * @export
 * @interface ExportAllDepartmentPermissionsModel
 */
export interface ExportAllDepartmentPermissionsModel {
    /**
     * 
     * @type {number}
     * @memberof ExportAllDepartmentPermissionsModel
     */
    'departmentId'?: number;
    /**
     * 
     * @type {PermissionsTranslations}
     * @memberof ExportAllDepartmentPermissionsModel
     */
    'translations'?: PermissionsTranslations | null;
}
/**
 * 
 * @export
 * @interface IdNameCompanyMap
 */
export interface IdNameCompanyMap {
    /**
     * 
     * @type {number}
     * @memberof IdNameCompanyMap
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdNameCompanyMap
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IdNameCompanyMap
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IdNameCompanyMap
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdNameCompanyMap
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdNameCompanyMap
     */
    'recursive'?: boolean;
}
/**
 * 
 * @export
 * @interface IdNameMap
 */
export interface IdNameMap {
    /**
     * 
     * @type {number}
     * @memberof IdNameMap
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof IdNameMap
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdNameMap
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdNameMap
     */
    'recursive'?: boolean;
}
/**
 * 
 * @export
 * @interface ImportColumn
 */
export interface ImportColumn {
    /**
     * 
     * @type {ImportColumnType}
     * @memberof ImportColumn
     */
    'type'?: ImportColumnType;
    /**
     * 
     * @type {object}
     * @memberof ImportColumn
     */
    'value'?: object | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ImportColumnType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17
} as const;

export type ImportColumnType = typeof ImportColumnType[keyof typeof ImportColumnType];


/**
 * 
 * @export
 * @interface ImportErrorsPostModel
 */
export interface ImportErrorsPostModel {
    /**
     * 
     * @type {number}
     * @memberof ImportErrorsPostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportErrorsPostModel
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportErrorsPostModel
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface ImportRow
 */
export interface ImportRow {
    /**
     * 
     * @type {boolean}
     * @memberof ImportRow
     */
    'ignore'?: boolean;
    /**
     * 
     * @type {Array<ImportColumn>}
     * @memberof ImportRow
     */
    'columns'?: Array<ImportColumn> | null;
}
/**
 * 
 * @export
 * @interface ImportSubscriptionDefault
 */
export interface ImportSubscriptionDefault {
    /**
     * 
     * @type {number}
     * @memberof ImportSubscriptionDefault
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportSubscriptionDefault
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportSubscriptionDefault
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImportSubscriptionDefault
     */
    'notificationChannel'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportSubscriptionDefault
     */
    'notificationEventType'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportSubscriptionDefault
     */
    'role'?: number;
}
/**
 * 
 * @export
 * @interface ImportedFile
 */
export interface ImportedFile {
    /**
     * 
     * @type {string}
     * @memberof ImportedFile
     */
    'externalKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImportedFile
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImportedFile
     */
    'companyId'?: number;
}
/**
 * 
 * @export
 * @interface ImportedFileOnHold
 */
export interface ImportedFileOnHold {
    /**
     * 
     * @type {number}
     * @memberof ImportedFileOnHold
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportedFileOnHold
     */
    'externalKey'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginCredentials
 */
export interface LoginCredentials {
    /**
     * 
     * @type {number}
     * @memberof LoginCredentials
     */
    'id'?: number;
    /**
     * 
     * @type {CredentialType}
     * @memberof LoginCredentials
     */
    'credentialType'?: CredentialType;
    /**
     * 
     * @type {string}
     * @memberof LoginCredentials
     */
    'identifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoginCredentials
     */
    'userId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LoginCredentials
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginCredentials
     */
    'allowCrossSessionToken'?: boolean;
}


/**
 * 
 * @export
 * @interface OtpLoginCredential
 */
export interface OtpLoginCredential {
    /**
     * 
     * @type {string}
     * @memberof OtpLoginCredential
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtpLoginCredential
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OtpLoginCredential
     */
    'email'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OtpLoginCredential
     */
    'userId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OtpLoginCredential
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OtpLoginCredential
     */
    'allowCrossSessionToken'?: boolean;
}
/**
 * 
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
    /**
     * 
     * @type {number}
     * @memberof PasswordChange
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    'newPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    'newPasswordMatch'?: string | null;
}
/**
 * 
 * @export
 * @interface PermissionsTranslations
 */
export interface PermissionsTranslations {
    /**
     * 
     * @type {string}
     * @memberof PermissionsTranslations
     */
    'usersCount'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionsTranslations
     */
    'accessRights'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionsTranslations
     */
    'workbookTitle'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PermissionsTranslations
     */
    'titles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PrimaryCompany
 */
export interface PrimaryCompany {
    /**
     * 
     * @type {number}
     * @memberof PrimaryCompany
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrimaryCompany
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrimaryCompany
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PrimaryCompany
     */
    'registrationNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ReactivateUserPostModel
 */
export interface ReactivateUserPostModel {
    /**
     * 
     * @type {number}
     * @memberof ReactivateUserPostModel
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactivateUserPostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReactivateUserPostModel
     */
    'performedByUserAccountId'?: number;
}
/**
 * 
 * @export
 * @interface RemoveDataOnEmployment
 */
export interface RemoveDataOnEmployment {
    /**
     * 
     * @type {string}
     * @memberof RemoveDataOnEmployment
     */
    'employmentReference'?: string;
    /**
     * 
     * @type {number}
     * @memberof RemoveDataOnEmployment
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RemoveDataOnEmployment
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoveDataOnEmployment
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoveDataOnEmployment
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoveDataOnEmployment
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RemoveDataOnEmployment
     */
    'doNotDelete'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RemoveDataOnEmployment
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoveDataOnEmployment
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface ResolveImportErrorsPostModel
 */
export interface ResolveImportErrorsPostModel {
    /**
     * 
     * @type {number}
     * @memberof ResolveImportErrorsPostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ResolveImportErrorsPostModel
     */
    'importIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface RetryImportRowsPostModel
 */
export interface RetryImportRowsPostModel {
    /**
     * 
     * @type {number}
     * @memberof RetryImportRowsPostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RetryImportRowsPostModel
     */
    'traceId'?: string;
}
/**
 * 
 * @export
 * @interface SearchCompanyGroupUserBySsn
 */
export interface SearchCompanyGroupUserBySsn {
    /**
     * 
     * @type {string}
     * @memberof SearchCompanyGroupUserBySsn
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchCompanyGroupUserBySsn
     */
    'includeAllCodes'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SearchCompanyGroupUserBySsn
     */
    'searchString'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchCompanyGroupUserBySsn
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchCompanyGroupUserBySsn
     */
    'selectedCompanyId'?: number;
}
/**
 * 
 * @export
 * @interface SearchCompanyGroupUserModel
 */
export interface SearchCompanyGroupUserModel {
    /**
     * 
     * @type {string}
     * @memberof SearchCompanyGroupUserModel
     */
    'searchString'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchCompanyGroupUserModel
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchCompanyGroupUserModel
     */
    'selectedCompanyId'?: number;
}
/**
 * 
 * @export
 * @interface SearchViewModel
 */
export interface SearchViewModel {
    /**
     * 
     * @type {string}
     * @memberof SearchViewModel
     */
    'searchTerms'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchViewModel
     */
    'multipleRetailers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchViewModel
     */
    'includeHistory'?: boolean;
}
/**
 * 
 * @export
 * @interface SetDepartmentManagerChannelModel
 */
export interface SetDepartmentManagerChannelModel {
    /**
     * 
     * @type {number}
     * @memberof SetDepartmentManagerChannelModel
     */
    'subscriptionTypeId'?: number;
    /**
     * 
     * @type {DepartmentManagerChannelType}
     * @memberof SetDepartmentManagerChannelModel
     */
    'departmentManagerChannelType'?: DepartmentManagerChannelType;
}


/**
 * 
 * @export
 * @interface SkippedRowsPostModel
 */
export interface SkippedRowsPostModel {
    /**
     * 
     * @type {number}
     * @memberof SkippedRowsPostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SkippedRowsPostModel
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SkippedRowsPostModel
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StagedChangeType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type StagedChangeType = typeof StagedChangeType[keyof typeof StagedChangeType];


/**
 * 
 * @export
 * @interface StagedChangesFilter
 */
export interface StagedChangesFilter {
    /**
     * 
     * @type {StagedChangeType}
     * @memberof StagedChangesFilter
     */
    'stagedChangeType'?: StagedChangeType;
    /**
     * 
     * @type {Array<number>}
     * @memberof StagedChangesFilter
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof StagedChangesFilter
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof StagedChangesFilter
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof StagedChangesFilter
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof StagedChangesFilter
     */
    'pageSize'?: number;
}


/**
 * 
 * @export
 * @interface StartManualMergePostModel
 */
export interface StartManualMergePostModel {
    /**
     * 
     * @type {number}
     * @memberof StartManualMergePostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof StartManualMergePostModel
     */
    'importIds'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof StartManualMergePostModel
     */
    'performedByUserAccountId'?: number;
}
/**
 * 
 * @export
 * @interface Subcategory
 */
export interface Subcategory {
    /**
     * 
     * @type {number}
     * @memberof Subcategory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Subcategory
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface TreeViewNode
 */
export interface TreeViewNode {
    /**
     * 
     * @type {number}
     * @memberof TreeViewNode
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TreeViewNode
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreeViewNode
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreeViewNode
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TreeViewNode
     */
    'employees'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TreeViewNode
     */
    'selectable'?: boolean;
    /**
     * 
     * @type {Array<TreeViewNode>}
     * @memberof TreeViewNode
     */
    'children'?: Array<TreeViewNode> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TreeViewNode
     */
    'top'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateSecurityDeleteUserModel
 */
export interface UpdateSecurityDeleteUserModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateSecurityDeleteUserModel
     */
    'employmentSecured'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateSecurityDeleteUserModel
     */
    'employmentUnSecured'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {ContactInformation}
     * @memberof User
     */
    'contactInformation'?: ContactInformation | null;
    /**
     * 
     * @type {UserPreferences}
     * @memberof User
     */
    'userPreferences'?: UserPreferences | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'maskedSocialSecurityNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'gender'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'birthYear'?: number | null;
}
/**
 * 
 * @export
 * @interface UserAccount
 */
export interface UserAccount {
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    'id'?: number;
    /**
     * 
     * @type {User}
     * @memberof UserAccount
     */
    'user'?: User | null;
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserAccount
     */
    'customerId'?: number;
    /**
     * 
     * @type {ContactInformation}
     * @memberof UserAccount
     */
    'contactInformation'?: ContactInformation | null;
    /**
     * 
     * @type {Array<AccountAccessRightViewModel>}
     * @memberof UserAccount
     */
    'accessRights'?: Array<AccountAccessRightViewModel> | null;
    /**
     * 
     * @type {Array<AccountAccessChangeEvent>}
     * @memberof UserAccount
     */
    'accountAccessChangeEvents'?: Array<AccountAccessChangeEvent> | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserAccount
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserAccount
     */
    'absenceViewDepartmentIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserAccount
     */
    'absenceFollowUpDepartmentIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<IdNameMap>}
     * @memberof UserAccount
     */
    'customerSupportRetailers'?: Array<IdNameMap> | null;
    /**
     * 
     * @type {Array<Company>}
     * @memberof UserAccount
     */
    'customerCompanyAdministratorCompanies'?: Array<Company> | null;
    /**
     * 
     * @type {Array<Company>}
     * @memberof UserAccount
     */
    'dataErasureCompanies'?: Array<Company> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccount
     */
    'isCustomerSupport'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccount
     */
    'hasDataErasureForOldData'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccount
     */
    'customerOrganizationAdministration'?: boolean;
    /**
     * 
     * @type {Array<Department>}
     * @memberof UserAccount
     */
    'departmentAdministrationDepartments'?: Array<Department> | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof UserAccount
     */
    'departmentAdministrationDepartmentIds'?: Array<object> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccount
     */
    'customerCompanyAdministration'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserAccountOperationType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6
} as const;

export type UserAccountOperationType = typeof UserAccountOperationType[keyof typeof UserAccountOperationType];


/**
 * 
 * @export
 * @interface UserEmployment
 */
export interface UserEmployment {
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserEmployment
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserEmployment
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserEmployment
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserEmployment
     */
    'costCenters'?: Array<string> | null;
    /**
     * 
     * @type {PrimaryCompany}
     * @memberof UserEmployment
     */
    'primaryCompany'?: PrimaryCompany | null;
}
/**
 * 
 * @export
 * @interface UserExportTransations
 */
export interface UserExportTransations {
    /**
     * 
     * @type {string}
     * @memberof UserExportTransations
     */
    'userWithoutEmployment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserExportTransations
     */
    'employee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserExportTransations
     */
    'associatedEmployee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserExportTransations
     */
    'userFromOtherCompany'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserExportTransations
     */
    'workbookTitle'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserExportTransations
     */
    'titles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserPreferences
 */
export interface UserPreferences {
    /**
     * 
     * @type {string}
     * @memberof UserPreferences
     */
    'language'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type UserType = typeof UserType[keyof typeof UserType];


/**
 * 
 * @export
 * @enum {string}
 */

export const UserTypeExportFilter = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_5: 5
} as const;

export type UserTypeExportFilter = typeof UserTypeExportFilter[keyof typeof UserTypeExportFilter];


/**
 * 
 * @export
 * @interface ValidateEmploymentImportRowsPostModel
 */
export interface ValidateEmploymentImportRowsPostModel {
    /**
     * 
     * @type {number}
     * @memberof ValidateEmploymentImportRowsPostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ValidateEmploymentImportRowsPostModel
     */
    'performedByUserAccountId'?: number;
    /**
     * 
     * @type {Array<ImportRow>}
     * @memberof ValidateEmploymentImportRowsPostModel
     */
    'importRows'?: Array<ImportRow> | null;
}

/**
 * CompanyDataProcessingApi - axios parameter creator
 * @export
 */
export const CompanyDataProcessingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingGetImportCompanyStatusGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyDataProcessing/GetImportCompanyStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingGetViewDataGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyDataProcessing/GetViewData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateEmploymentImportRowsPostModel} [validateEmploymentImportRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingImportEmploymentRowsPost: async (validateEmploymentImportRowsPostModel?: ValidateEmploymentImportRowsPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyDataProcessing/ImportEmploymentRows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateEmploymentImportRowsPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RetryImportRowsPostModel} [retryImportRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingRetryImportRowsPost: async (retryImportRowsPostModel?: RetryImportRowsPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyDataProcessing/RetryImportRows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(retryImportRowsPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateEmploymentImportRowsPostModel} [validateEmploymentImportRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingValidateEmploymentImportRowsPost: async (validateEmploymentImportRowsPostModel?: ValidateEmploymentImportRowsPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CompanyDataProcessing/ValidateEmploymentImportRows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateEmploymentImportRowsPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyDataProcessingApi - functional programming interface
 * @export
 */
export const CompanyDataProcessingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyDataProcessingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataProcessingGetImportCompanyStatusGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataProcessingGetImportCompanyStatusGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataProcessingGetViewDataGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataProcessingGetViewDataGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidateEmploymentImportRowsPostModel} [validateEmploymentImportRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataProcessingImportEmploymentRowsPost(validateEmploymentImportRowsPostModel?: ValidateEmploymentImportRowsPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataProcessingImportEmploymentRowsPost(validateEmploymentImportRowsPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RetryImportRowsPostModel} [retryImportRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataProcessingRetryImportRowsPost(retryImportRowsPostModel?: RetryImportRowsPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataProcessingRetryImportRowsPost(retryImportRowsPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidateEmploymentImportRowsPostModel} [validateEmploymentImportRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompanyDataProcessingValidateEmploymentImportRowsPost(validateEmploymentImportRowsPostModel?: ValidateEmploymentImportRowsPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompanyDataProcessingValidateEmploymentImportRowsPost(validateEmploymentImportRowsPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyDataProcessingApi - factory interface
 * @export
 */
export const CompanyDataProcessingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyDataProcessingApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingGetImportCompanyStatusGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyDataProcessingGetImportCompanyStatusGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingGetViewDataGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyDataProcessingGetViewDataGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateEmploymentImportRowsPostModel} [validateEmploymentImportRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingImportEmploymentRowsPost(validateEmploymentImportRowsPostModel?: ValidateEmploymentImportRowsPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyDataProcessingImportEmploymentRowsPost(validateEmploymentImportRowsPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RetryImportRowsPostModel} [retryImportRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingRetryImportRowsPost(retryImportRowsPostModel?: RetryImportRowsPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyDataProcessingRetryImportRowsPost(retryImportRowsPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateEmploymentImportRowsPostModel} [validateEmploymentImportRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompanyDataProcessingValidateEmploymentImportRowsPost(validateEmploymentImportRowsPostModel?: ValidateEmploymentImportRowsPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompanyDataProcessingValidateEmploymentImportRowsPost(validateEmploymentImportRowsPostModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyDataProcessingApi - object-oriented interface
 * @export
 * @class CompanyDataProcessingApi
 * @extends {BaseAPI}
 */
export class CompanyDataProcessingApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataProcessingApi
     */
    public apiCompanyDataProcessingGetImportCompanyStatusGet(companyId?: number, options?: AxiosRequestConfig) {
        return CompanyDataProcessingApiFp(this.configuration).apiCompanyDataProcessingGetImportCompanyStatusGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataProcessingApi
     */
    public apiCompanyDataProcessingGetViewDataGet(companyId?: number, options?: AxiosRequestConfig) {
        return CompanyDataProcessingApiFp(this.configuration).apiCompanyDataProcessingGetViewDataGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidateEmploymentImportRowsPostModel} [validateEmploymentImportRowsPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataProcessingApi
     */
    public apiCompanyDataProcessingImportEmploymentRowsPost(validateEmploymentImportRowsPostModel?: ValidateEmploymentImportRowsPostModel, options?: AxiosRequestConfig) {
        return CompanyDataProcessingApiFp(this.configuration).apiCompanyDataProcessingImportEmploymentRowsPost(validateEmploymentImportRowsPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RetryImportRowsPostModel} [retryImportRowsPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataProcessingApi
     */
    public apiCompanyDataProcessingRetryImportRowsPost(retryImportRowsPostModel?: RetryImportRowsPostModel, options?: AxiosRequestConfig) {
        return CompanyDataProcessingApiFp(this.configuration).apiCompanyDataProcessingRetryImportRowsPost(retryImportRowsPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ValidateEmploymentImportRowsPostModel} [validateEmploymentImportRowsPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyDataProcessingApi
     */
    public apiCompanyDataProcessingValidateEmploymentImportRowsPost(validateEmploymentImportRowsPostModel?: ValidateEmploymentImportRowsPostModel, options?: AxiosRequestConfig) {
        return CompanyDataProcessingApiFp(this.configuration).apiCompanyDataProcessingValidateEmploymentImportRowsPost(validateEmploymentImportRowsPostModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerCompanyApi - axios parameter creator
 * @export
 */
export const CustomerCompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyDeleteCompanySubscriptionDefaultPost: async (companyId?: number, id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/DeleteCompanySubscriptionDefault`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CostCenter} [costCenter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyDeleteCostCenterPost: async (costCenter?: CostCenter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/DeleteCostCenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(costCenter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyDeleteDepartmentDelete: async (departmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/DeleteDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyDeleteRemovedEmploymentsPost: async (companyId?: number, requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/DeleteRemovedEmployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportAllCompanyUsersAndEmployeesModel} [exportAllCompanyUsersAndEmployeesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyExportAllCompanyUsersAndEmployeesPost: async (exportAllCompanyUsersAndEmployeesModel?: ExportAllCompanyUsersAndEmployeesModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/ExportAllCompanyUsersAndEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportAllCompanyUsersAndEmployeesModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportAllDepartmentEmployeesModel} [exportAllDepartmentEmployeesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyExportAllDepartmentEmployeesPost: async (exportAllDepartmentEmployeesModel?: ExportAllDepartmentEmployeesModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/ExportAllDepartmentEmployees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportAllDepartmentEmployeesModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportAllDepartmentEmployeesModel} [exportAllDepartmentEmployeesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyExportDepartmentsToExcelPost: async (exportAllDepartmentEmployeesModel?: ExportAllDepartmentEmployeesModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/ExportDepartmentsToExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportAllDepartmentEmployeesModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetAbsenceBoardRehabilitationServiceContracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdGet: async (companyId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetAbsenceBoardRehabilitationServiceContractsWithCustomerId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyFilter} [companyFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompaniesForUserPost: async (companyFilter?: CompanyFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCompaniesForUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompaniesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyByIdGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCompanyById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyDataErasureConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCompanyDataErasureConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyImportSubscriptionDefaultsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCompanyImportSubscriptionDefaults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyInfoGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCompanyInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyRecursiveAccessRightsGet: async (companyId?: number, departmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCompanyRecursiveAccessRights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCostCenterGet: async (companyId?: number, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCostCenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCostCentersForCompanyFastGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCostCentersForCompanyFast`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCostCentersForCompanyGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCostCentersForCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCustomerBillingConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCustomerBillingConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCustomerProductByCompanyIdGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCustomerProductByCompanyId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCustomerSupportCompaniesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetCustomerSupportCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetDepartmentInfoGet: async (departmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetDepartmentInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetDepartmentsCostCenterForCompanyGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetDepartmentsCostCenterForCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmployeeCountGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetEmployeeCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmployeeErasedEventsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetEmployeeErasedEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdGet: async (companyId?: number, userAccountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetEmploymentFromCompanyAndUserAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmploymentGroupsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetEmploymentGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {UserType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmploymentsForDepartmentIdGet: async (departmentId?: number, type?: UserType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetEmploymentsForDepartmentId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetOrganizationDepartmentsEventsGet: async (companyId?: number, pageSize?: number, pageNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetOrganizationDepartmentsEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetOrganizationTreeViewGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetOrganizationTreeView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetRehabAndAbsenceErasedEventsGet: async (companyId?: number, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetRehabAndAbsenceErasedEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetRemovedEmploymentsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetRemovedEmployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetStagedImportsCompaniesGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetStagedImportsCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {boolean} [otherCompanies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdGet: async (companyId?: number, otherCompanies?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/GetUsersWithoutEmploymentForCompanyId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (otherCompanies !== undefined) {
                localVarQueryParameter['otherCompanies'] = otherCompanies;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyDataErasureConfigurationPostModel} [companyDataErasureConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySaveCompanyDataErasureConfigurationPost: async (companyDataErasureConfigurationPostModel?: CompanyDataErasureConfigurationPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/SaveCompanyDataErasureConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDataErasureConfigurationPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportSubscriptionDefault} [importSubscriptionDefault] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySaveCompanySubscriptionDefaultPost: async (importSubscriptionDefault?: ImportSubscriptionDefault, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/SaveCompanySubscriptionDefault`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importSubscriptionDefault, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CostCenter} [costCenter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySaveCostCenterPost: async (costCenter?: CostCenter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/SaveCostCenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(costCenter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Department} [department] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySaveDepartmentPost: async (department?: Department, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/SaveDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(department, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchCompanyGroupUserModel} [searchCompanyGroupUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySearchCompanyGroupUserAndDepartmentPost: async (searchCompanyGroupUserModel?: SearchCompanyGroupUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/SearchCompanyGroupUserAndDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchCompanyGroupUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchCompanyGroupUserModel} [searchCompanyGroupUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySearchCompanyGroupUserPost: async (searchCompanyGroupUserModel?: SearchCompanyGroupUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/SearchCompanyGroupUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchCompanyGroupUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {UpdateSecurityDeleteUserModel} [updateSecurityDeleteUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyUpdateRemovedEmploymentsPost: async (companyId?: number, updateSecurityDeleteUserModel?: UpdateSecurityDeleteUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerCompany/UpdateRemovedEmployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSecurityDeleteUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerCompanyApi - functional programming interface
 * @export
 */
export const CustomerCompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerCompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyDeleteCompanySubscriptionDefaultPost(companyId?: number, id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyDeleteCompanySubscriptionDefaultPost(companyId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CostCenter} [costCenter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyDeleteCostCenterPost(costCenter?: CostCenter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyDeleteCostCenterPost(costCenter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyDeleteDepartmentDelete(departmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyDeleteDepartmentDelete(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyDeleteRemovedEmploymentsPost(companyId?: number, requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyDeleteRemovedEmploymentsPost(companyId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportAllCompanyUsersAndEmployeesModel} [exportAllCompanyUsersAndEmployeesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyExportAllCompanyUsersAndEmployeesPost(exportAllCompanyUsersAndEmployeesModel?: ExportAllCompanyUsersAndEmployeesModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyExportAllCompanyUsersAndEmployeesPost(exportAllCompanyUsersAndEmployeesModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportAllDepartmentEmployeesModel} [exportAllDepartmentEmployeesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyExportAllDepartmentEmployeesPost(exportAllDepartmentEmployeesModel?: ExportAllDepartmentEmployeesModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyExportAllDepartmentEmployeesPost(exportAllDepartmentEmployeesModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportAllDepartmentEmployeesModel} [exportAllDepartmentEmployeesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyExportDepartmentsToExcelPost(exportAllDepartmentEmployeesModel?: ExportAllDepartmentEmployeesModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyExportDepartmentsToExcelPost(exportAllDepartmentEmployeesModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdGet(companyId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyFilter} [companyFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCompaniesForUserPost(companyFilter?: CompanyFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCompaniesForUserPost(companyFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCompaniesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCompaniesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCompanyByIdGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCompanyByIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCompanyDataErasureConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCompanyDataErasureConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCompanyImportSubscriptionDefaultsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCompanyImportSubscriptionDefaultsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCompanyInfoGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCompanyInfoGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCompanyRecursiveAccessRightsGet(companyId?: number, departmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCompanyRecursiveAccessRightsGet(companyId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCostCenterGet(companyId?: number, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCostCenterGet(companyId, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCostCentersForCompanyFastGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CostCenter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCostCentersForCompanyFastGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCostCentersForCompanyGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCostCentersForCompanyGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCustomerBillingConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerBillingConfigurationPartial>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCustomerBillingConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCustomerProductByCompanyIdGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCustomerProductByCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetCustomerSupportCompaniesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetCustomerSupportCompaniesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetDepartmentInfoGet(departmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetDepartmentInfoGet(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetDepartmentsCostCenterForCompanyGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetDepartmentsCostCenterForCompanyGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetEmployeeCountGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetEmployeeCountGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetEmployeeErasedEventsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetEmployeeErasedEventsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdGet(companyId?: number, userAccountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrUpdateEmployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdGet(companyId, userAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetEmploymentGroupsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetEmploymentGroupsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {UserType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetEmploymentsForDepartmentIdGet(departmentId?: number, type?: UserType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserEmployment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetEmploymentsForDepartmentIdGet(departmentId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetOrganizationDepartmentsEventsGet(companyId?: number, pageSize?: number, pageNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetOrganizationDepartmentsEventsGet(companyId, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetOrganizationTreeViewGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TreeViewNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetOrganizationTreeViewGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetRehabAndAbsenceErasedEventsGet(companyId?: number, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetRehabAndAbsenceErasedEventsGet(companyId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetRemovedEmploymentsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetRemovedEmploymentsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetStagedImportsCompaniesGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetStagedImportsCompaniesGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {boolean} [otherCompanies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdGet(companyId?: number, otherCompanies?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdGet(companyId, otherCompanies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyDataErasureConfigurationPostModel} [companyDataErasureConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanySaveCompanyDataErasureConfigurationPost(companyDataErasureConfigurationPostModel?: CompanyDataErasureConfigurationPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanySaveCompanyDataErasureConfigurationPost(companyDataErasureConfigurationPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportSubscriptionDefault} [importSubscriptionDefault] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanySaveCompanySubscriptionDefaultPost(importSubscriptionDefault?: ImportSubscriptionDefault, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanySaveCompanySubscriptionDefaultPost(importSubscriptionDefault, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CostCenter} [costCenter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanySaveCostCenterPost(costCenter?: CostCenter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanySaveCostCenterPost(costCenter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Department} [department] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanySaveDepartmentPost(department?: Department, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanySaveDepartmentPost(department, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchCompanyGroupUserModel} [searchCompanyGroupUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanySearchCompanyGroupUserAndDepartmentPost(searchCompanyGroupUserModel?: SearchCompanyGroupUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanySearchCompanyGroupUserAndDepartmentPost(searchCompanyGroupUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchCompanyGroupUserModel} [searchCompanyGroupUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanySearchCompanyGroupUserPost(searchCompanyGroupUserModel?: SearchCompanyGroupUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanySearchCompanyGroupUserPost(searchCompanyGroupUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {UpdateSecurityDeleteUserModel} [updateSecurityDeleteUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerCompanyUpdateRemovedEmploymentsPost(companyId?: number, updateSecurityDeleteUserModel?: UpdateSecurityDeleteUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerCompanyUpdateRemovedEmploymentsPost(companyId, updateSecurityDeleteUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerCompanyApi - factory interface
 * @export
 */
export const CustomerCompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerCompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyDeleteCompanySubscriptionDefaultPost(companyId?: number, id?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyDeleteCompanySubscriptionDefaultPost(companyId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CostCenter} [costCenter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyDeleteCostCenterPost(costCenter?: CostCenter, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyDeleteCostCenterPost(costCenter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyDeleteDepartmentDelete(departmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyDeleteDepartmentDelete(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyDeleteRemovedEmploymentsPost(companyId?: number, requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyDeleteRemovedEmploymentsPost(companyId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportAllCompanyUsersAndEmployeesModel} [exportAllCompanyUsersAndEmployeesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyExportAllCompanyUsersAndEmployeesPost(exportAllCompanyUsersAndEmployeesModel?: ExportAllCompanyUsersAndEmployeesModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyExportAllCompanyUsersAndEmployeesPost(exportAllCompanyUsersAndEmployeesModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportAllDepartmentEmployeesModel} [exportAllDepartmentEmployeesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyExportAllDepartmentEmployeesPost(exportAllDepartmentEmployeesModel?: ExportAllDepartmentEmployeesModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyExportAllDepartmentEmployeesPost(exportAllDepartmentEmployeesModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportAllDepartmentEmployeesModel} [exportAllDepartmentEmployeesModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyExportDepartmentsToExcelPost(exportAllDepartmentEmployeesModel?: ExportAllDepartmentEmployeesModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyExportDepartmentsToExcelPost(exportAllDepartmentEmployeesModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdGet(companyId?: number, customerId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdGet(companyId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyFilter} [companyFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompaniesForUserPost(companyFilter?: CompanyFilter, options?: any): AxiosPromise<Array<Company>> {
            return localVarFp.apiCustomerCompanyGetCompaniesForUserPost(companyFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompaniesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetCompaniesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyByIdGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetCompanyByIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyDataErasureConfigurationGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetCompanyDataErasureConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyImportSubscriptionDefaultsGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetCompanyImportSubscriptionDefaultsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyInfoGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetCompanyInfoGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCompanyRecursiveAccessRightsGet(companyId?: number, departmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetCompanyRecursiveAccessRightsGet(companyId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCostCenterGet(companyId?: number, code?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetCostCenterGet(companyId, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCostCentersForCompanyFastGet(companyId?: number, options?: any): AxiosPromise<Array<CostCenter>> {
            return localVarFp.apiCustomerCompanyGetCostCentersForCompanyFastGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCostCentersForCompanyGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetCostCentersForCompanyGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCustomerBillingConfigurationGet(companyId?: number, options?: any): AxiosPromise<CustomerBillingConfigurationPartial> {
            return localVarFp.apiCustomerCompanyGetCustomerBillingConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCustomerProductByCompanyIdGet(companyId?: number, options?: any): AxiosPromise<CustomerProduct> {
            return localVarFp.apiCustomerCompanyGetCustomerProductByCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetCustomerSupportCompaniesGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetCustomerSupportCompaniesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetDepartmentInfoGet(departmentId?: number, options?: any): AxiosPromise<Department> {
            return localVarFp.apiCustomerCompanyGetDepartmentInfoGet(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetDepartmentsCostCenterForCompanyGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetDepartmentsCostCenterForCompanyGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmployeeCountGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetEmployeeCountGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmployeeErasedEventsGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetEmployeeErasedEventsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdGet(companyId?: number, userAccountId?: number, options?: any): AxiosPromise<CreateOrUpdateEmployment> {
            return localVarFp.apiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdGet(companyId, userAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmploymentGroupsGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetEmploymentGroupsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {UserType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetEmploymentsForDepartmentIdGet(departmentId?: number, type?: UserType, options?: any): AxiosPromise<Array<UserEmployment>> {
            return localVarFp.apiCustomerCompanyGetEmploymentsForDepartmentIdGet(departmentId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetOrganizationDepartmentsEventsGet(companyId?: number, pageSize?: number, pageNumber?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetOrganizationDepartmentsEventsGet(companyId, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetOrganizationTreeViewGet(companyId?: number, options?: any): AxiosPromise<Array<TreeViewNode>> {
            return localVarFp.apiCustomerCompanyGetOrganizationTreeViewGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetRehabAndAbsenceErasedEventsGet(companyId?: number, type?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetRehabAndAbsenceErasedEventsGet(companyId, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetRemovedEmploymentsGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetRemovedEmploymentsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetStagedImportsCompaniesGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetStagedImportsCompaniesGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {boolean} [otherCompanies] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdGet(companyId?: number, otherCompanies?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdGet(companyId, otherCompanies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyDataErasureConfigurationPostModel} [companyDataErasureConfigurationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySaveCompanyDataErasureConfigurationPost(companyDataErasureConfigurationPostModel?: CompanyDataErasureConfigurationPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanySaveCompanyDataErasureConfigurationPost(companyDataErasureConfigurationPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportSubscriptionDefault} [importSubscriptionDefault] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySaveCompanySubscriptionDefaultPost(importSubscriptionDefault?: ImportSubscriptionDefault, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanySaveCompanySubscriptionDefaultPost(importSubscriptionDefault, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CostCenter} [costCenter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySaveCostCenterPost(costCenter?: CostCenter, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanySaveCostCenterPost(costCenter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Department} [department] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySaveDepartmentPost(department?: Department, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanySaveDepartmentPost(department, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchCompanyGroupUserModel} [searchCompanyGroupUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySearchCompanyGroupUserAndDepartmentPost(searchCompanyGroupUserModel?: SearchCompanyGroupUserModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanySearchCompanyGroupUserAndDepartmentPost(searchCompanyGroupUserModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchCompanyGroupUserModel} [searchCompanyGroupUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanySearchCompanyGroupUserPost(searchCompanyGroupUserModel?: SearchCompanyGroupUserModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanySearchCompanyGroupUserPost(searchCompanyGroupUserModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {UpdateSecurityDeleteUserModel} [updateSecurityDeleteUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerCompanyUpdateRemovedEmploymentsPost(companyId?: number, updateSecurityDeleteUserModel?: UpdateSecurityDeleteUserModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCustomerCompanyUpdateRemovedEmploymentsPost(companyId, updateSecurityDeleteUserModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerCompanyApi - object-oriented interface
 * @export
 * @class CustomerCompanyApi
 * @extends {BaseAPI}
 */
export class CustomerCompanyApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyDeleteCompanySubscriptionDefaultPost(companyId?: number, id?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyDeleteCompanySubscriptionDefaultPost(companyId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CostCenter} [costCenter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyDeleteCostCenterPost(costCenter?: CostCenter, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyDeleteCostCenterPost(costCenter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyDeleteDepartmentDelete(departmentId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyDeleteDepartmentDelete(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyDeleteRemovedEmploymentsPost(companyId?: number, requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyDeleteRemovedEmploymentsPost(companyId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportAllCompanyUsersAndEmployeesModel} [exportAllCompanyUsersAndEmployeesModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyExportAllCompanyUsersAndEmployeesPost(exportAllCompanyUsersAndEmployeesModel?: ExportAllCompanyUsersAndEmployeesModel, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyExportAllCompanyUsersAndEmployeesPost(exportAllCompanyUsersAndEmployeesModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportAllDepartmentEmployeesModel} [exportAllDepartmentEmployeesModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyExportAllDepartmentEmployeesPost(exportAllDepartmentEmployeesModel?: ExportAllDepartmentEmployeesModel, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyExportAllDepartmentEmployeesPost(exportAllDepartmentEmployeesModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportAllDepartmentEmployeesModel} [exportAllDepartmentEmployeesModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyExportDepartmentsToExcelPost(exportAllDepartmentEmployeesModel?: ExportAllDepartmentEmployeesModel, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyExportDepartmentsToExcelPost(exportAllDepartmentEmployeesModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdGet(companyId?: number, customerId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetAbsenceBoardRehabilitationServiceContractsWithCustomerIdGet(companyId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyFilter} [companyFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCompaniesForUserPost(companyFilter?: CompanyFilter, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCompaniesForUserPost(companyFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCompaniesGet(options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCompaniesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCompanyByIdGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCompanyByIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCompanyDataErasureConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCompanyDataErasureConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCompanyImportSubscriptionDefaultsGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCompanyImportSubscriptionDefaultsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCompanyInfoGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCompanyInfoGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCompanyRecursiveAccessRightsGet(companyId?: number, departmentId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCompanyRecursiveAccessRightsGet(companyId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCostCenterGet(companyId?: number, code?: string, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCostCenterGet(companyId, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCostCentersForCompanyFastGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCostCentersForCompanyFastGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCostCentersForCompanyGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCostCentersForCompanyGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCustomerBillingConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCustomerBillingConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCustomerProductByCompanyIdGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCustomerProductByCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetCustomerSupportCompaniesGet(options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetCustomerSupportCompaniesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetDepartmentInfoGet(departmentId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetDepartmentInfoGet(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetDepartmentsCostCenterForCompanyGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetDepartmentsCostCenterForCompanyGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetEmployeeCountGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetEmployeeCountGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetEmployeeErasedEventsGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetEmployeeErasedEventsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [userAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdGet(companyId?: number, userAccountId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdGet(companyId, userAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetEmploymentGroupsGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetEmploymentGroupsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [departmentId] 
     * @param {UserType} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetEmploymentsForDepartmentIdGet(departmentId?: number, type?: UserType, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetEmploymentsForDepartmentIdGet(departmentId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetOrganizationDepartmentsEventsGet(companyId?: number, pageSize?: number, pageNumber?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetOrganizationDepartmentsEventsGet(companyId, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetOrganizationTreeViewGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetOrganizationTreeViewGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetRehabAndAbsenceErasedEventsGet(companyId?: number, type?: string, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetRehabAndAbsenceErasedEventsGet(companyId, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetRemovedEmploymentsGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetRemovedEmploymentsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetStagedImportsCompaniesGet(companyId?: number, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetStagedImportsCompaniesGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {boolean} [otherCompanies] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdGet(companyId?: number, otherCompanies?: boolean, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyGetUsersWithoutEmploymentForCompanyIdGet(companyId, otherCompanies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyDataErasureConfigurationPostModel} [companyDataErasureConfigurationPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanySaveCompanyDataErasureConfigurationPost(companyDataErasureConfigurationPostModel?: CompanyDataErasureConfigurationPostModel, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanySaveCompanyDataErasureConfigurationPost(companyDataErasureConfigurationPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportSubscriptionDefault} [importSubscriptionDefault] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanySaveCompanySubscriptionDefaultPost(importSubscriptionDefault?: ImportSubscriptionDefault, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanySaveCompanySubscriptionDefaultPost(importSubscriptionDefault, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CostCenter} [costCenter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanySaveCostCenterPost(costCenter?: CostCenter, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanySaveCostCenterPost(costCenter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Department} [department] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanySaveDepartmentPost(department?: Department, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanySaveDepartmentPost(department, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchCompanyGroupUserModel} [searchCompanyGroupUserModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanySearchCompanyGroupUserAndDepartmentPost(searchCompanyGroupUserModel?: SearchCompanyGroupUserModel, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanySearchCompanyGroupUserAndDepartmentPost(searchCompanyGroupUserModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchCompanyGroupUserModel} [searchCompanyGroupUserModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanySearchCompanyGroupUserPost(searchCompanyGroupUserModel?: SearchCompanyGroupUserModel, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanySearchCompanyGroupUserPost(searchCompanyGroupUserModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {UpdateSecurityDeleteUserModel} [updateSecurityDeleteUserModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerCompanyApi
     */
    public apiCustomerCompanyUpdateRemovedEmploymentsPost(companyId?: number, updateSecurityDeleteUserModel?: UpdateSecurityDeleteUserModel, options?: AxiosRequestConfig) {
        return CustomerCompanyApiFp(this.configuration).apiCustomerCompanyUpdateRemovedEmploymentsPost(companyId, updateSecurityDeleteUserModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerSupportApi - axios parameter creator
 * @export
 */
export const CustomerSupportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSupportSearchCompaniesPost: async (searchViewModel?: SearchViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerSupport/SearchCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSupportSearchCompanyUserByEmailPost: async (searchViewModel?: SearchViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerSupport/SearchCompanyUserByEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSupportSearchCompanyUserByTelephoneNumberPost: async (searchViewModel?: SearchViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerSupport/SearchCompanyUserByTelephoneNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSupportSearchEmploymentsPost: async (searchViewModel?: SearchViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CustomerSupport/SearchEmployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerSupportApi - functional programming interface
 * @export
 */
export const CustomerSupportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerSupportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerSupportSearchCompaniesPost(searchViewModel?: SearchViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerCompanyCache>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerSupportSearchCompaniesPost(searchViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerSupportSearchCompanyUserByEmailPost(searchViewModel?: SearchViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CatalogEntryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerSupportSearchCompanyUserByEmailPost(searchViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerSupportSearchCompanyUserByTelephoneNumberPost(searchViewModel?: SearchViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CatalogEntryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerSupportSearchCompanyUserByTelephoneNumberPost(searchViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCustomerSupportSearchEmploymentsPost(searchViewModel?: SearchViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CatalogEntryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCustomerSupportSearchEmploymentsPost(searchViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerSupportApi - factory interface
 * @export
 */
export const CustomerSupportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerSupportApiFp(configuration)
    return {
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSupportSearchCompaniesPost(searchViewModel?: SearchViewModel, options?: any): AxiosPromise<Array<CustomerCompanyCache>> {
            return localVarFp.apiCustomerSupportSearchCompaniesPost(searchViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSupportSearchCompanyUserByEmailPost(searchViewModel?: SearchViewModel, options?: any): AxiosPromise<Array<CatalogEntryModel>> {
            return localVarFp.apiCustomerSupportSearchCompanyUserByEmailPost(searchViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSupportSearchCompanyUserByTelephoneNumberPost(searchViewModel?: SearchViewModel, options?: any): AxiosPromise<Array<CatalogEntryModel>> {
            return localVarFp.apiCustomerSupportSearchCompanyUserByTelephoneNumberPost(searchViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchViewModel} [searchViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCustomerSupportSearchEmploymentsPost(searchViewModel?: SearchViewModel, options?: any): AxiosPromise<Array<CatalogEntryModel>> {
            return localVarFp.apiCustomerSupportSearchEmploymentsPost(searchViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerSupportApi - object-oriented interface
 * @export
 * @class CustomerSupportApi
 * @extends {BaseAPI}
 */
export class CustomerSupportApi extends BaseAPI {
    /**
     * 
     * @param {SearchViewModel} [searchViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSupportApi
     */
    public apiCustomerSupportSearchCompaniesPost(searchViewModel?: SearchViewModel, options?: AxiosRequestConfig) {
        return CustomerSupportApiFp(this.configuration).apiCustomerSupportSearchCompaniesPost(searchViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchViewModel} [searchViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSupportApi
     */
    public apiCustomerSupportSearchCompanyUserByEmailPost(searchViewModel?: SearchViewModel, options?: AxiosRequestConfig) {
        return CustomerSupportApiFp(this.configuration).apiCustomerSupportSearchCompanyUserByEmailPost(searchViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchViewModel} [searchViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSupportApi
     */
    public apiCustomerSupportSearchCompanyUserByTelephoneNumberPost(searchViewModel?: SearchViewModel, options?: AxiosRequestConfig) {
        return CustomerSupportApiFp(this.configuration).apiCustomerSupportSearchCompanyUserByTelephoneNumberPost(searchViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchViewModel} [searchViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerSupportApi
     */
    public apiCustomerSupportSearchEmploymentsPost(searchViewModel?: SearchViewModel, options?: AxiosRequestConfig) {
        return CustomerSupportApiFp(this.configuration).apiCustomerSupportSearchEmploymentsPost(searchViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataAnonymizationApi - axios parameter creator
 * @export
 */
export const DataAnonymizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {Employments} [employments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationAbsenceDelete: async (companyId?: number, employments?: Employments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataAnonymization/Absence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationAbsenceGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataAnonymization/Absence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationGetCompanyDataErasureAvailableConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataAnonymization/GetCompanyDataErasureAvailableConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {Employments} [employments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationMedicalCertificateDelete: async (companyId?: number, employments?: Employments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataAnonymization/MedicalCertificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationMedicalCertificateGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataAnonymization/MedicalCertificate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {Employments} [employments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationRehabDelete: async (companyId?: number, employments?: Employments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataAnonymization/Rehab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationRehabGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataAnonymization/Rehab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyDataErasureAvailableConfiguration} [companyDataErasureAvailableConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationPost: async (companyDataErasureAvailableConfiguration?: CompanyDataErasureAvailableConfiguration, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DataAnonymization/SaveCompanyDataErasureAvailableConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDataErasureAvailableConfiguration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataAnonymizationApi - functional programming interface
 * @export
 */
export const DataAnonymizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataAnonymizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {Employments} [employments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDataAnonymizationAbsenceDelete(companyId?: number, employments?: Employments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDataAnonymizationAbsenceDelete(companyId, employments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDataAnonymizationAbsenceGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoveDataOnEmployment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDataAnonymizationAbsenceGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDataAnonymizationGetCompanyDataErasureAvailableConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDataErasureAvailableConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDataAnonymizationGetCompanyDataErasureAvailableConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {Employments} [employments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDataAnonymizationMedicalCertificateDelete(companyId?: number, employments?: Employments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDataAnonymizationMedicalCertificateDelete(companyId, employments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDataAnonymizationMedicalCertificateGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoveDataOnEmployment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDataAnonymizationMedicalCertificateGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {Employments} [employments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDataAnonymizationRehabDelete(companyId?: number, employments?: Employments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDataAnonymizationRehabDelete(companyId, employments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDataAnonymizationRehabGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoveDataOnEmployment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDataAnonymizationRehabGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyDataErasureAvailableConfiguration} [companyDataErasureAvailableConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationPost(companyDataErasureAvailableConfiguration?: CompanyDataErasureAvailableConfiguration, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDataErasureAvailableConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationPost(companyDataErasureAvailableConfiguration, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataAnonymizationApi - factory interface
 * @export
 */
export const DataAnonymizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataAnonymizationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {Employments} [employments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationAbsenceDelete(companyId?: number, employments?: Employments, options?: any): AxiosPromise<void> {
            return localVarFp.apiDataAnonymizationAbsenceDelete(companyId, employments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationAbsenceGet(companyId?: number, options?: any): AxiosPromise<Array<RemoveDataOnEmployment>> {
            return localVarFp.apiDataAnonymizationAbsenceGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationGetCompanyDataErasureAvailableConfigurationGet(companyId?: number, options?: any): AxiosPromise<CompanyDataErasureAvailableConfiguration> {
            return localVarFp.apiDataAnonymizationGetCompanyDataErasureAvailableConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {Employments} [employments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationMedicalCertificateDelete(companyId?: number, employments?: Employments, options?: any): AxiosPromise<void> {
            return localVarFp.apiDataAnonymizationMedicalCertificateDelete(companyId, employments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationMedicalCertificateGet(companyId?: number, options?: any): AxiosPromise<Array<RemoveDataOnEmployment>> {
            return localVarFp.apiDataAnonymizationMedicalCertificateGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {Employments} [employments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationRehabDelete(companyId?: number, employments?: Employments, options?: any): AxiosPromise<void> {
            return localVarFp.apiDataAnonymizationRehabDelete(companyId, employments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationRehabGet(companyId?: number, options?: any): AxiosPromise<Array<RemoveDataOnEmployment>> {
            return localVarFp.apiDataAnonymizationRehabGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyDataErasureAvailableConfiguration} [companyDataErasureAvailableConfiguration] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationPost(companyDataErasureAvailableConfiguration?: CompanyDataErasureAvailableConfiguration, options?: any): AxiosPromise<CompanyDataErasureAvailableConfiguration> {
            return localVarFp.apiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationPost(companyDataErasureAvailableConfiguration, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataAnonymizationApi - object-oriented interface
 * @export
 * @class DataAnonymizationApi
 * @extends {BaseAPI}
 */
export class DataAnonymizationApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {Employments} [employments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public apiDataAnonymizationAbsenceDelete(companyId?: number, employments?: Employments, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).apiDataAnonymizationAbsenceDelete(companyId, employments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public apiDataAnonymizationAbsenceGet(companyId?: number, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).apiDataAnonymizationAbsenceGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public apiDataAnonymizationGetCompanyDataErasureAvailableConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).apiDataAnonymizationGetCompanyDataErasureAvailableConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {Employments} [employments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public apiDataAnonymizationMedicalCertificateDelete(companyId?: number, employments?: Employments, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).apiDataAnonymizationMedicalCertificateDelete(companyId, employments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public apiDataAnonymizationMedicalCertificateGet(companyId?: number, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).apiDataAnonymizationMedicalCertificateGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {Employments} [employments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public apiDataAnonymizationRehabDelete(companyId?: number, employments?: Employments, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).apiDataAnonymizationRehabDelete(companyId, employments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public apiDataAnonymizationRehabGet(companyId?: number, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).apiDataAnonymizationRehabGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyDataErasureAvailableConfiguration} [companyDataErasureAvailableConfiguration] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataAnonymizationApi
     */
    public apiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationPost(companyDataErasureAvailableConfiguration?: CompanyDataErasureAvailableConfiguration, options?: AxiosRequestConfig) {
        return DataAnonymizationApiFp(this.configuration).apiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationPost(companyDataErasureAvailableConfiguration, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileImportStatusApi - axios parameter creator
 * @export
 */
export const FileImportStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangeAutoImportPostModel} [changeAutoImportPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusChangeAutoImportPost: async (changeAutoImportPostModel?: ChangeAutoImportPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FileImportStatus/ChangeAutoImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeAutoImportPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusCheckFileImportConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FileImportStatus/CheckFileImportConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportedFileOnHold} [importedFileOnHold] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusDownloadFileOnHoldPost: async (importedFileOnHold?: ImportedFileOnHold, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FileImportStatus/DownloadFileOnHold`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importedFileOnHold, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusGetAutoCompleteStatusGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FileImportStatus/GetAutoCompleteStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusGetCompanyProgressGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FileImportStatus/GetCompanyProgress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusGetLastImportRowChangeEventsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FileImportStatus/GetLastImportRowChangeEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusGetStageInfoGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FileImportStatus/GetStageInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartManualMergePostModel} [startManualMergePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusStartManualMergePost: async (startManualMergePostModel?: StartManualMergePostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/FileImportStatus/StartManualMerge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startManualMergePostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileImportStatusApi - functional programming interface
 * @export
 */
export const FileImportStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileImportStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangeAutoImportPostModel} [changeAutoImportPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileImportStatusChangeAutoImportPost(changeAutoImportPostModel?: ChangeAutoImportPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileImportStatusChangeAutoImportPost(changeAutoImportPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileImportStatusCheckFileImportConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileImportStatusCheckFileImportConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportedFileOnHold} [importedFileOnHold] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileImportStatusDownloadFileOnHoldPost(importedFileOnHold?: ImportedFileOnHold, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileImportStatusDownloadFileOnHoldPost(importedFileOnHold, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileImportStatusGetAutoCompleteStatusGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileImportStatusGetAutoCompleteStatusGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileImportStatusGetCompanyProgressGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileImportStatusGetCompanyProgressGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileImportStatusGetLastImportRowChangeEventsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileImportStatusGetLastImportRowChangeEventsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileImportStatusGetStageInfoGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileImportStatusGetStageInfoGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartManualMergePostModel} [startManualMergePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileImportStatusStartManualMergePost(startManualMergePostModel?: StartManualMergePostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileImportStatusStartManualMergePost(startManualMergePostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileImportStatusApi - factory interface
 * @export
 */
export const FileImportStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileImportStatusApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangeAutoImportPostModel} [changeAutoImportPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusChangeAutoImportPost(changeAutoImportPostModel?: ChangeAutoImportPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileImportStatusChangeAutoImportPost(changeAutoImportPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusCheckFileImportConfigurationGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileImportStatusCheckFileImportConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportedFileOnHold} [importedFileOnHold] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusDownloadFileOnHoldPost(importedFileOnHold?: ImportedFileOnHold, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileImportStatusDownloadFileOnHoldPost(importedFileOnHold, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusGetAutoCompleteStatusGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileImportStatusGetAutoCompleteStatusGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusGetCompanyProgressGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileImportStatusGetCompanyProgressGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusGetLastImportRowChangeEventsGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileImportStatusGetLastImportRowChangeEventsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusGetStageInfoGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileImportStatusGetStageInfoGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartManualMergePostModel} [startManualMergePostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileImportStatusStartManualMergePost(startManualMergePostModel?: StartManualMergePostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileImportStatusStartManualMergePost(startManualMergePostModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileImportStatusApi - object-oriented interface
 * @export
 * @class FileImportStatusApi
 * @extends {BaseAPI}
 */
export class FileImportStatusApi extends BaseAPI {
    /**
     * 
     * @param {ChangeAutoImportPostModel} [changeAutoImportPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportStatusApi
     */
    public apiFileImportStatusChangeAutoImportPost(changeAutoImportPostModel?: ChangeAutoImportPostModel, options?: AxiosRequestConfig) {
        return FileImportStatusApiFp(this.configuration).apiFileImportStatusChangeAutoImportPost(changeAutoImportPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportStatusApi
     */
    public apiFileImportStatusCheckFileImportConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return FileImportStatusApiFp(this.configuration).apiFileImportStatusCheckFileImportConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportedFileOnHold} [importedFileOnHold] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportStatusApi
     */
    public apiFileImportStatusDownloadFileOnHoldPost(importedFileOnHold?: ImportedFileOnHold, options?: AxiosRequestConfig) {
        return FileImportStatusApiFp(this.configuration).apiFileImportStatusDownloadFileOnHoldPost(importedFileOnHold, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportStatusApi
     */
    public apiFileImportStatusGetAutoCompleteStatusGet(companyId?: number, options?: AxiosRequestConfig) {
        return FileImportStatusApiFp(this.configuration).apiFileImportStatusGetAutoCompleteStatusGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportStatusApi
     */
    public apiFileImportStatusGetCompanyProgressGet(companyId?: number, options?: AxiosRequestConfig) {
        return FileImportStatusApiFp(this.configuration).apiFileImportStatusGetCompanyProgressGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportStatusApi
     */
    public apiFileImportStatusGetLastImportRowChangeEventsGet(companyId?: number, options?: AxiosRequestConfig) {
        return FileImportStatusApiFp(this.configuration).apiFileImportStatusGetLastImportRowChangeEventsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportStatusApi
     */
    public apiFileImportStatusGetStageInfoGet(companyId?: number, options?: AxiosRequestConfig) {
        return FileImportStatusApiFp(this.configuration).apiFileImportStatusGetStageInfoGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartManualMergePostModel} [startManualMergePostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportStatusApi
     */
    public apiFileImportStatusStartManualMergePost(startManualMergePostModel?: StartManualMergePostModel, options?: AxiosRequestConfig) {
        return FileImportStatusApiFp(this.configuration).apiFileImportStatusStartManualMergePost(startManualMergePostModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StagingApi - axios parameter creator
 * @export
 */
export const StagingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ImportedFile} [importedFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingDownloadFilePost: async (importedFile?: ImportedFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staging/DownloadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importedFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ImportErrorsPostModel} [importErrorsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetImportErrorsPost: async (importErrorsPostModel?: ImportErrorsPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staging/GetImportErrors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importErrorsPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StagedChangesFilter} [stagedChangesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetImportedFilesPost: async (stagedChangesFilter?: StagedChangesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staging/GetImportedFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stagedChangesFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StagedChangesFilter} [stagedChangesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetLatestEventsDateForProviderPost: async (stagedChangesFilter?: StagedChangesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staging/GetLatestEventsDateForProvider`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stagedChangesFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SkippedRowsPostModel} [skippedRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetSkippedRowsPost: async (skippedRowsPostModel?: SkippedRowsPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staging/GetSkippedRows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(skippedRowsPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StagedChangesFilter} [stagedChangesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetStagedChangesPost: async (stagedChangesFilter?: StagedChangesFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staging/GetStagedChanges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stagedChangesFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResolveImportErrorsPostModel} [resolveImportErrorsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingResolveImportErrorsPost: async (resolveImportErrorsPostModel?: ResolveImportErrorsPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Staging/ResolveImportErrors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resolveImportErrorsPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StagingApi - functional programming interface
 * @export
 */
export const StagingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StagingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ImportedFile} [importedFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStagingDownloadFilePost(importedFile?: ImportedFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStagingDownloadFilePost(importedFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ImportErrorsPostModel} [importErrorsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStagingGetImportErrorsPost(importErrorsPostModel?: ImportErrorsPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStagingGetImportErrorsPost(importErrorsPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StagedChangesFilter} [stagedChangesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStagingGetImportedFilesPost(stagedChangesFilter?: StagedChangesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStagingGetImportedFilesPost(stagedChangesFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StagedChangesFilter} [stagedChangesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStagingGetLatestEventsDateForProviderPost(stagedChangesFilter?: StagedChangesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStagingGetLatestEventsDateForProviderPost(stagedChangesFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SkippedRowsPostModel} [skippedRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStagingGetSkippedRowsPost(skippedRowsPostModel?: SkippedRowsPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStagingGetSkippedRowsPost(skippedRowsPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StagedChangesFilter} [stagedChangesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStagingGetStagedChangesPost(stagedChangesFilter?: StagedChangesFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStagingGetStagedChangesPost(stagedChangesFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResolveImportErrorsPostModel} [resolveImportErrorsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStagingResolveImportErrorsPost(resolveImportErrorsPostModel?: ResolveImportErrorsPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStagingResolveImportErrorsPost(resolveImportErrorsPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StagingApi - factory interface
 * @export
 */
export const StagingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StagingApiFp(configuration)
    return {
        /**
         * 
         * @param {ImportedFile} [importedFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingDownloadFilePost(importedFile?: ImportedFile, options?: any): AxiosPromise<void> {
            return localVarFp.apiStagingDownloadFilePost(importedFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ImportErrorsPostModel} [importErrorsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetImportErrorsPost(importErrorsPostModel?: ImportErrorsPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiStagingGetImportErrorsPost(importErrorsPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StagedChangesFilter} [stagedChangesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetImportedFilesPost(stagedChangesFilter?: StagedChangesFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiStagingGetImportedFilesPost(stagedChangesFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StagedChangesFilter} [stagedChangesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetLatestEventsDateForProviderPost(stagedChangesFilter?: StagedChangesFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiStagingGetLatestEventsDateForProviderPost(stagedChangesFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SkippedRowsPostModel} [skippedRowsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetSkippedRowsPost(skippedRowsPostModel?: SkippedRowsPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiStagingGetSkippedRowsPost(skippedRowsPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StagedChangesFilter} [stagedChangesFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingGetStagedChangesPost(stagedChangesFilter?: StagedChangesFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiStagingGetStagedChangesPost(stagedChangesFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResolveImportErrorsPostModel} [resolveImportErrorsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStagingResolveImportErrorsPost(resolveImportErrorsPostModel?: ResolveImportErrorsPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiStagingResolveImportErrorsPost(resolveImportErrorsPostModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StagingApi - object-oriented interface
 * @export
 * @class StagingApi
 * @extends {BaseAPI}
 */
export class StagingApi extends BaseAPI {
    /**
     * 
     * @param {ImportedFile} [importedFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagingApi
     */
    public apiStagingDownloadFilePost(importedFile?: ImportedFile, options?: AxiosRequestConfig) {
        return StagingApiFp(this.configuration).apiStagingDownloadFilePost(importedFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ImportErrorsPostModel} [importErrorsPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagingApi
     */
    public apiStagingGetImportErrorsPost(importErrorsPostModel?: ImportErrorsPostModel, options?: AxiosRequestConfig) {
        return StagingApiFp(this.configuration).apiStagingGetImportErrorsPost(importErrorsPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StagedChangesFilter} [stagedChangesFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagingApi
     */
    public apiStagingGetImportedFilesPost(stagedChangesFilter?: StagedChangesFilter, options?: AxiosRequestConfig) {
        return StagingApiFp(this.configuration).apiStagingGetImportedFilesPost(stagedChangesFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StagedChangesFilter} [stagedChangesFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagingApi
     */
    public apiStagingGetLatestEventsDateForProviderPost(stagedChangesFilter?: StagedChangesFilter, options?: AxiosRequestConfig) {
        return StagingApiFp(this.configuration).apiStagingGetLatestEventsDateForProviderPost(stagedChangesFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SkippedRowsPostModel} [skippedRowsPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagingApi
     */
    public apiStagingGetSkippedRowsPost(skippedRowsPostModel?: SkippedRowsPostModel, options?: AxiosRequestConfig) {
        return StagingApiFp(this.configuration).apiStagingGetSkippedRowsPost(skippedRowsPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StagedChangesFilter} [stagedChangesFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagingApi
     */
    public apiStagingGetStagedChangesPost(stagedChangesFilter?: StagedChangesFilter, options?: AxiosRequestConfig) {
        return StagingApiFp(this.configuration).apiStagingGetStagedChangesPost(stagedChangesFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResolveImportErrorsPostModel} [resolveImportErrorsPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StagingApi
     */
    public apiStagingResolveImportErrorsPost(resolveImportErrorsPostModel?: ResolveImportErrorsPostModel, options?: AxiosRequestConfig) {
        return StagingApiFp(this.configuration).apiStagingResolveImportErrorsPost(resolveImportErrorsPostModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status/GetStatusAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGetStatusAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusGetStatusAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusGetStatusAllGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGetStatusAllGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusGetStatusAllGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionTypeApi - axios parameter creator
 * @export
 */
export const SubscriptionTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionTypeGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SubscriptionType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetDepartmentManagerChannelModel} [setDepartmentManagerChannelModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionTypePut: async (setDepartmentManagerChannelModel?: SetDepartmentManagerChannelModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SubscriptionType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setDepartmentManagerChannelModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionTypeApi - functional programming interface
 * @export
 */
export const SubscriptionTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionTypeGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionTypeGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetDepartmentManagerChannelModel} [setDepartmentManagerChannelModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionTypePut(setDepartmentManagerChannelModel?: SetDepartmentManagerChannelModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscriptionTypePut(setDepartmentManagerChannelModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionTypeApi - factory interface
 * @export
 */
export const SubscriptionTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionTypeGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscriptionTypeGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetDepartmentManagerChannelModel} [setDepartmentManagerChannelModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionTypePut(setDepartmentManagerChannelModel?: SetDepartmentManagerChannelModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscriptionTypePut(setDepartmentManagerChannelModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionTypeApi - object-oriented interface
 * @export
 * @class SubscriptionTypeApi
 * @extends {BaseAPI}
 */
export class SubscriptionTypeApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionTypeApi
     */
    public apiSubscriptionTypeGet(companyId?: number, options?: AxiosRequestConfig) {
        return SubscriptionTypeApiFp(this.configuration).apiSubscriptionTypeGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetDepartmentManagerChannelModel} [setDepartmentManagerChannelModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionTypeApi
     */
    public apiSubscriptionTypePut(setDepartmentManagerChannelModel?: SetDepartmentManagerChannelModel, options?: AxiosRequestConfig) {
        return SubscriptionTypeApiFp(this.configuration).apiSubscriptionTypePut(setDepartmentManagerChannelModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PasswordChange} [passwordChange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserChangeLoginCredentialsPasswordPost: async (passwordChange?: PasswordChange, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ChangeLoginCredentialsPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateAccessRights} [createOrUpdateAccessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateAccessRightsPost: async (createOrUpdateAccessRights?: CreateOrUpdateAccessRights, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CreateAccessRights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateAccessRights, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateCompanyAccessRights} [createOrUpdateCompanyAccessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateCompanyAccessRightsPost: async (createOrUpdateCompanyAccessRights?: CreateOrUpdateCompanyAccessRights, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CreateCompanyAccessRights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateCompanyAccessRights, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateDepartmentsAccessRights} [createOrUpdateDepartmentsAccessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateDepartmentAccessRightsPost: async (createOrUpdateDepartmentsAccessRights?: CreateOrUpdateDepartmentsAccessRights, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CreateDepartmentAccessRights`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateDepartmentsAccessRights, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateEmployment} [createOrUpdateEmployment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateEmploymentPost: async (createOrUpdateEmployment?: CreateOrUpdateEmployment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CreateEmployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateEmployment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateUserAccount} [createOrUpdateUserAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateUserPost: async (createOrUpdateUserAccount?: CreateOrUpdateUserAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/CreateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateUserAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginCredentials} [loginCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDeleteLoginCredentialPost: async (loginCredentials?: LoginCredentials, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/DeleteLoginCredential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCredentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportAllCompanyPermissionsModel} [exportAllCompanyPermissionsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExportAllCompanyPermissionsPost: async (exportAllCompanyPermissionsModel?: ExportAllCompanyPermissionsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ExportAllCompanyPermissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportAllCompanyPermissionsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportAllDepartmentPermissionsModel} [exportAllDepartmentPermissionsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExportAllDepartmentPermissionsPost: async (exportAllDepartmentPermissionsModel?: ExportAllDepartmentPermissionsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ExportAllDepartmentPermissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportAllDepartmentPermissionsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetAccessRightsForCompanyGroupGet: async (userAccountId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetAccessRightsForCompanyGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetAccessRightsFromUserAccountIdGet: async (userAccountId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetAccessRightsFromUserAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetAllLoginCredentialsGet: async (userAccountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetAllLoginCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetCompanyUserFromUserAccountIdEditGet: async (userAccountId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetCompanyUserFromUserAccountIdEdit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetCompanyUserFromUserAccountIdGet: async (userAccountId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetCompanyUserFromUserAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetCompanyUserFromUserAccountIdInCompanyGroupGet: async (userAccountId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetCompanyUserFromUserAccountIdInCompanyGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetLoginConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetLoginConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetLoginCredentialsFromUserAccountIdGet: async (userAccountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetLoginCredentialsFromUserAccountId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetRemovedUsersGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetRemovedUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUnstartedUsersGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUnstartedUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserAccountIdFromUserIdGet: async (userId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserAccountIdFromUserId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserAccountsAndAccessRightsByCompanyIdGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserAccountsAndAccessRightsByCompanyId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserAccountsAndAccessRightsByDepartmentIdGet: async (departmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserAccountsAndAccessRightsByDepartmentId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserDataGet: async (userAccountId?: number, companyId?: number, departmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReactivateUserPostModel} [reactivateUserPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserReactivateUserAccountPost: async (reactivateUserPostModel?: ReactivateUserPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ReactivateUserAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reactivateUserPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserReserveUserAccountIdsGet: async (count?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/ReserveUserAccountIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateOrUpdateContactInformation} [createOrUpdateContactInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSaveContactInformationPost: async (createOrUpdateContactInformation?: CreateOrUpdateContactInformation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SaveContactInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateContactInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginCredentials} [loginCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSaveLoginCredentialPost: async (loginCredentials?: LoginCredentials, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SaveLoginCredential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCredentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OtpLoginCredential} [otpLoginCredential] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSaveOtpLoginCredentialPost: async (otpLoginCredential?: OtpLoginCredential, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SaveOtpLoginCredential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(otpLoginCredential, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchCompanyGroupUserBySsn} [searchCompanyGroupUserBySsn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchCompanyGroupUserBySsnPost: async (searchCompanyGroupUserBySsn?: SearchCompanyGroupUserBySsn, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SearchCompanyGroupUserBySsn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchCompanyGroupUserBySsn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchCompanyGroupUsersByNameGet: async (companyId?: number, searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SearchCompanyGroupUsersByName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [countryCode] 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchCompanyUserBySsnGet: async (countryCode?: string, companyId?: number, searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SearchCompanyUserBySsn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchCompanyUsersByNameGet: async (companyId?: number, searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SearchCompanyUsersByName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchEmploymentsByNameGet: async (companyId?: number, searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/SearchEmploymentsByName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {ContactInformation} [contactInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateEmployeeContactDetailsPost: async (userAccountId?: number, companyId?: number, contactInformation?: ContactInformation, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateEmployeeContactDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactInformation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PasswordChange} [passwordChange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserChangeLoginCredentialsPasswordPost(passwordChange?: PasswordChange, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserChangeLoginCredentialsPasswordPost(passwordChange, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrUpdateAccessRights} [createOrUpdateAccessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreateAccessRightsPost(createOrUpdateAccessRights?: CreateOrUpdateAccessRights, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreateAccessRightsPost(createOrUpdateAccessRights, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrUpdateCompanyAccessRights} [createOrUpdateCompanyAccessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreateCompanyAccessRightsPost(createOrUpdateCompanyAccessRights?: CreateOrUpdateCompanyAccessRights, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreateCompanyAccessRightsPost(createOrUpdateCompanyAccessRights, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrUpdateDepartmentsAccessRights} [createOrUpdateDepartmentsAccessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreateDepartmentAccessRightsPost(createOrUpdateDepartmentsAccessRights?: CreateOrUpdateDepartmentsAccessRights, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreateDepartmentAccessRightsPost(createOrUpdateDepartmentsAccessRights, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrUpdateEmployment} [createOrUpdateEmployment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreateEmploymentPost(createOrUpdateEmployment?: CreateOrUpdateEmployment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreateEmploymentPost(createOrUpdateEmployment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrUpdateUserAccount} [createOrUpdateUserAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreateUserPost(createOrUpdateUserAccount?: CreateOrUpdateUserAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreateUserPost(createOrUpdateUserAccount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginCredentials} [loginCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserDeleteLoginCredentialPost(loginCredentials?: LoginCredentials, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserDeleteLoginCredentialPost(loginCredentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportAllCompanyPermissionsModel} [exportAllCompanyPermissionsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserExportAllCompanyPermissionsPost(exportAllCompanyPermissionsModel?: ExportAllCompanyPermissionsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserExportAllCompanyPermissionsPost(exportAllCompanyPermissionsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportAllDepartmentPermissionsModel} [exportAllDepartmentPermissionsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserExportAllDepartmentPermissionsPost(exportAllDepartmentPermissionsModel?: ExportAllDepartmentPermissionsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserExportAllDepartmentPermissionsPost(exportAllDepartmentPermissionsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetAccessRightsForCompanyGroupGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetAccessRightsForCompanyGroupGet(userAccountId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetAccessRightsFromUserAccountIdGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetAccessRightsFromUserAccountIdGet(userAccountId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetAllLoginCredentialsGet(userAccountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetAllLoginCredentialsGet(userAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetCompanyUserFromUserAccountIdEditGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetCompanyUserFromUserAccountIdEditGet(userAccountId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetCompanyUserFromUserAccountIdGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetCompanyUserFromUserAccountIdGet(userAccountId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetCompanyUserFromUserAccountIdInCompanyGroupGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetCompanyUserFromUserAccountIdInCompanyGroupGet(userAccountId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetLoginConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetLoginConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetLoginCredentialsFromUserAccountIdGet(userAccountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetLoginCredentialsFromUserAccountIdGet(userAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetRemovedUsersGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetRemovedUsersGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUnstartedUsersGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUnstartedUsersGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserAccountIdFromUserIdGet(userId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserAccountIdFromUserIdGet(userId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserAccountsAndAccessRightsByCompanyIdGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserAccountsAndAccessRightsByCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserAccountsAndAccessRightsByDepartmentIdGet(departmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserAccountsAndAccessRightsByDepartmentIdGet(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetUserDataGet(userAccountId?: number, companyId?: number, departmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetUserDataGet(userAccountId, companyId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReactivateUserPostModel} [reactivateUserPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserReactivateUserAccountPost(reactivateUserPostModel?: ReactivateUserPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserReactivateUserAccountPost(reactivateUserPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserReserveUserAccountIdsGet(count?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserReserveUserAccountIdsGet(count, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateOrUpdateContactInformation} [createOrUpdateContactInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSaveContactInformationPost(createOrUpdateContactInformation?: CreateOrUpdateContactInformation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSaveContactInformationPost(createOrUpdateContactInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginCredentials} [loginCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSaveLoginCredentialPost(loginCredentials?: LoginCredentials, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSaveLoginCredentialPost(loginCredentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OtpLoginCredential} [otpLoginCredential] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSaveOtpLoginCredentialPost(otpLoginCredential?: OtpLoginCredential, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSaveOtpLoginCredentialPost(otpLoginCredential, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchCompanyGroupUserBySsn} [searchCompanyGroupUserBySsn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSearchCompanyGroupUserBySsnPost(searchCompanyGroupUserBySsn?: SearchCompanyGroupUserBySsn, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSearchCompanyGroupUserBySsnPost(searchCompanyGroupUserBySsn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSearchCompanyGroupUsersByNameGet(companyId?: number, searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSearchCompanyGroupUsersByNameGet(companyId, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [countryCode] 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSearchCompanyUserBySsnGet(countryCode?: string, companyId?: number, searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSearchCompanyUserBySsnGet(countryCode, companyId, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSearchCompanyUsersByNameGet(companyId?: number, searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSearchCompanyUsersByNameGet(companyId, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserSearchEmploymentsByNameGet(companyId?: number, searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserSearchEmploymentsByNameGet(companyId, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {ContactInformation} [contactInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUpdateEmployeeContactDetailsPost(userAccountId?: number, companyId?: number, contactInformation?: ContactInformation, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUpdateEmployeeContactDetailsPost(userAccountId, companyId, contactInformation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {PasswordChange} [passwordChange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserChangeLoginCredentialsPasswordPost(passwordChange?: PasswordChange, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserChangeLoginCredentialsPasswordPost(passwordChange, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrUpdateAccessRights} [createOrUpdateAccessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateAccessRightsPost(createOrUpdateAccessRights?: CreateOrUpdateAccessRights, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserCreateAccessRightsPost(createOrUpdateAccessRights, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrUpdateCompanyAccessRights} [createOrUpdateCompanyAccessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateCompanyAccessRightsPost(createOrUpdateCompanyAccessRights?: CreateOrUpdateCompanyAccessRights, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserCreateCompanyAccessRightsPost(createOrUpdateCompanyAccessRights, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrUpdateDepartmentsAccessRights} [createOrUpdateDepartmentsAccessRights] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateDepartmentAccessRightsPost(createOrUpdateDepartmentsAccessRights?: CreateOrUpdateDepartmentsAccessRights, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserCreateDepartmentAccessRightsPost(createOrUpdateDepartmentsAccessRights, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrUpdateEmployment} [createOrUpdateEmployment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateEmploymentPost(createOrUpdateEmployment?: CreateOrUpdateEmployment, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserCreateEmploymentPost(createOrUpdateEmployment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrUpdateUserAccount} [createOrUpdateUserAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreateUserPost(createOrUpdateUserAccount?: CreateOrUpdateUserAccount, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserCreateUserPost(createOrUpdateUserAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginCredentials} [loginCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserDeleteLoginCredentialPost(loginCredentials?: LoginCredentials, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserDeleteLoginCredentialPost(loginCredentials, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportAllCompanyPermissionsModel} [exportAllCompanyPermissionsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExportAllCompanyPermissionsPost(exportAllCompanyPermissionsModel?: ExportAllCompanyPermissionsModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserExportAllCompanyPermissionsPost(exportAllCompanyPermissionsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportAllDepartmentPermissionsModel} [exportAllDepartmentPermissionsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserExportAllDepartmentPermissionsPost(exportAllDepartmentPermissionsModel?: ExportAllDepartmentPermissionsModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserExportAllDepartmentPermissionsPost(exportAllDepartmentPermissionsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetAccessRightsForCompanyGroupGet(userAccountId?: number, companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetAccessRightsForCompanyGroupGet(userAccountId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetAccessRightsFromUserAccountIdGet(userAccountId?: number, companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetAccessRightsFromUserAccountIdGet(userAccountId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetAllLoginCredentialsGet(userAccountId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetAllLoginCredentialsGet(userAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetCompanyUserFromUserAccountIdEditGet(userAccountId?: number, companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetCompanyUserFromUserAccountIdEditGet(userAccountId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetCompanyUserFromUserAccountIdGet(userAccountId?: number, companyId?: number, options?: any): AxiosPromise<CompanyUser> {
            return localVarFp.apiUserGetCompanyUserFromUserAccountIdGet(userAccountId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetCompanyUserFromUserAccountIdInCompanyGroupGet(userAccountId?: number, companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetCompanyUserFromUserAccountIdInCompanyGroupGet(userAccountId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetLoginConfigurationGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetLoginConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetLoginCredentialsFromUserAccountIdGet(userAccountId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetLoginCredentialsFromUserAccountIdGet(userAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetRemovedUsersGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetRemovedUsersGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUnstartedUsersGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUnstartedUsersGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserAccountIdFromUserIdGet(userId?: number, companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUserAccountIdFromUserIdGet(userId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserAccountsAndAccessRightsByCompanyIdGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUserAccountsAndAccessRightsByCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserAccountsAndAccessRightsByDepartmentIdGet(departmentId?: number, options?: any): AxiosPromise<UserAccount> {
            return localVarFp.apiUserGetUserAccountsAndAccessRightsByDepartmentIdGet(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetUserDataGet(userAccountId?: number, companyId?: number, departmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserGetUserDataGet(userAccountId, companyId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReactivateUserPostModel} [reactivateUserPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserReactivateUserAccountPost(reactivateUserPostModel?: ReactivateUserPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserReactivateUserAccountPost(reactivateUserPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [count] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserReserveUserAccountIdsGet(count?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserReserveUserAccountIdsGet(count, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateOrUpdateContactInformation} [createOrUpdateContactInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSaveContactInformationPost(createOrUpdateContactInformation?: CreateOrUpdateContactInformation, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSaveContactInformationPost(createOrUpdateContactInformation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginCredentials} [loginCredentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSaveLoginCredentialPost(loginCredentials?: LoginCredentials, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSaveLoginCredentialPost(loginCredentials, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OtpLoginCredential} [otpLoginCredential] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSaveOtpLoginCredentialPost(otpLoginCredential?: OtpLoginCredential, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSaveOtpLoginCredentialPost(otpLoginCredential, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchCompanyGroupUserBySsn} [searchCompanyGroupUserBySsn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchCompanyGroupUserBySsnPost(searchCompanyGroupUserBySsn?: SearchCompanyGroupUserBySsn, options?: any): AxiosPromise<CompanyUser> {
            return localVarFp.apiUserSearchCompanyGroupUserBySsnPost(searchCompanyGroupUserBySsn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchCompanyGroupUsersByNameGet(companyId?: number, searchString?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSearchCompanyGroupUsersByNameGet(companyId, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [countryCode] 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchCompanyUserBySsnGet(countryCode?: string, companyId?: number, searchString?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSearchCompanyUserBySsnGet(countryCode, companyId, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchCompanyUsersByNameGet(companyId?: number, searchString?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSearchCompanyUsersByNameGet(companyId, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserSearchEmploymentsByNameGet(companyId?: number, searchString?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserSearchEmploymentsByNameGet(companyId, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [userAccountId] 
         * @param {number} [companyId] 
         * @param {ContactInformation} [contactInformation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateEmployeeContactDetailsPost(userAccountId?: number, companyId?: number, contactInformation?: ContactInformation, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserUpdateEmployeeContactDetailsPost(userAccountId, companyId, contactInformation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {PasswordChange} [passwordChange] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserChangeLoginCredentialsPasswordPost(passwordChange?: PasswordChange, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserChangeLoginCredentialsPasswordPost(passwordChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrUpdateAccessRights} [createOrUpdateAccessRights] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreateAccessRightsPost(createOrUpdateAccessRights?: CreateOrUpdateAccessRights, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreateAccessRightsPost(createOrUpdateAccessRights, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrUpdateCompanyAccessRights} [createOrUpdateCompanyAccessRights] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreateCompanyAccessRightsPost(createOrUpdateCompanyAccessRights?: CreateOrUpdateCompanyAccessRights, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreateCompanyAccessRightsPost(createOrUpdateCompanyAccessRights, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrUpdateDepartmentsAccessRights} [createOrUpdateDepartmentsAccessRights] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreateDepartmentAccessRightsPost(createOrUpdateDepartmentsAccessRights?: CreateOrUpdateDepartmentsAccessRights, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreateDepartmentAccessRightsPost(createOrUpdateDepartmentsAccessRights, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrUpdateEmployment} [createOrUpdateEmployment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreateEmploymentPost(createOrUpdateEmployment?: CreateOrUpdateEmployment, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreateEmploymentPost(createOrUpdateEmployment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrUpdateUserAccount} [createOrUpdateUserAccount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreateUserPost(createOrUpdateUserAccount?: CreateOrUpdateUserAccount, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreateUserPost(createOrUpdateUserAccount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginCredentials} [loginCredentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserDeleteLoginCredentialPost(loginCredentials?: LoginCredentials, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserDeleteLoginCredentialPost(loginCredentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportAllCompanyPermissionsModel} [exportAllCompanyPermissionsModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserExportAllCompanyPermissionsPost(exportAllCompanyPermissionsModel?: ExportAllCompanyPermissionsModel, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserExportAllCompanyPermissionsPost(exportAllCompanyPermissionsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportAllDepartmentPermissionsModel} [exportAllDepartmentPermissionsModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserExportAllDepartmentPermissionsPost(exportAllDepartmentPermissionsModel?: ExportAllDepartmentPermissionsModel, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserExportAllDepartmentPermissionsPost(exportAllDepartmentPermissionsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetAccessRightsForCompanyGroupGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetAccessRightsForCompanyGroupGet(userAccountId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetAccessRightsFromUserAccountIdGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetAccessRightsFromUserAccountIdGet(userAccountId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetAllLoginCredentialsGet(userAccountId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetAllLoginCredentialsGet(userAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetCompanyUserFromUserAccountIdEditGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetCompanyUserFromUserAccountIdEditGet(userAccountId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetCompanyUserFromUserAccountIdGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetCompanyUserFromUserAccountIdGet(userAccountId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetCompanyUserFromUserAccountIdInCompanyGroupGet(userAccountId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetCompanyUserFromUserAccountIdInCompanyGroupGet(userAccountId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetLoginConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetLoginConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetLoginCredentialsFromUserAccountIdGet(userAccountId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetLoginCredentialsFromUserAccountIdGet(userAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetRemovedUsersGet(companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetRemovedUsersGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUnstartedUsersGet(companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUnstartedUsersGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserAccountIdFromUserIdGet(userId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUserAccountIdFromUserIdGet(userId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserAccountsAndAccessRightsByCompanyIdGet(companyId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUserAccountsAndAccessRightsByCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserAccountsAndAccessRightsByDepartmentIdGet(departmentId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUserAccountsAndAccessRightsByDepartmentIdGet(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {number} [companyId] 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetUserDataGet(userAccountId?: number, companyId?: number, departmentId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetUserDataGet(userAccountId, companyId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReactivateUserPostModel} [reactivateUserPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserReactivateUserAccountPost(reactivateUserPostModel?: ReactivateUserPostModel, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserReactivateUserAccountPost(reactivateUserPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [count] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserReserveUserAccountIdsGet(count?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserReserveUserAccountIdsGet(count, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateOrUpdateContactInformation} [createOrUpdateContactInformation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSaveContactInformationPost(createOrUpdateContactInformation?: CreateOrUpdateContactInformation, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSaveContactInformationPost(createOrUpdateContactInformation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginCredentials} [loginCredentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSaveLoginCredentialPost(loginCredentials?: LoginCredentials, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSaveLoginCredentialPost(loginCredentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OtpLoginCredential} [otpLoginCredential] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSaveOtpLoginCredentialPost(otpLoginCredential?: OtpLoginCredential, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSaveOtpLoginCredentialPost(otpLoginCredential, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchCompanyGroupUserBySsn} [searchCompanyGroupUserBySsn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSearchCompanyGroupUserBySsnPost(searchCompanyGroupUserBySsn?: SearchCompanyGroupUserBySsn, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSearchCompanyGroupUserBySsnPost(searchCompanyGroupUserBySsn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSearchCompanyGroupUsersByNameGet(companyId?: number, searchString?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSearchCompanyGroupUsersByNameGet(companyId, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [countryCode] 
     * @param {number} [companyId] 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSearchCompanyUserBySsnGet(countryCode?: string, companyId?: number, searchString?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSearchCompanyUserBySsnGet(countryCode, companyId, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSearchCompanyUsersByNameGet(companyId?: number, searchString?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSearchCompanyUsersByNameGet(companyId, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserSearchEmploymentsByNameGet(companyId?: number, searchString?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserSearchEmploymentsByNameGet(companyId, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [userAccountId] 
     * @param {number} [companyId] 
     * @param {ContactInformation} [contactInformation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdateEmployeeContactDetailsPost(userAccountId?: number, companyId?: number, contactInformation?: ContactInformation, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUpdateEmployeeContactDetailsPost(userAccountId, companyId, contactInformation, options).then((request) => request(this.axios, this.basePath));
    }
}


