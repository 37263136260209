import { Link } from "react-router-dom";

interface IMenuItem {
    name?: string;
    url?: string;
    icon?: JSX.Element;
    current?: boolean;
    company?: string;
    reactWeb?: boolean;
}

const MenuItem = ({ name, url, icon, current, company, reactWeb }: IMenuItem) => {
    return (
        <>
            {reactWeb ? (
                <Link to={url ? url : ""}>
                    <div
                        className={`focus:shadow-none pl-6 flex h-12 w-full items-center hover:text-menuTextHover bg-menu cursor-pointer ${
                            current ? "text-menuTextHover" : "text-menuText"
                        } ${company && "h-15"}`}
                    >
                        <div className={`w-6 flex justify-center ${company ? "pb-3" : ""}`}>{icon}</div>
                        <div className="pl-4 text-left">
                            <p className="text-sm font-bold">{name}</p>
                            {company && <p className="text-xs opacity-50">{company}</p>}
                        </div>
                    </div>
                </Link>
            ) : (
                <a
                    href={url}
                    className={`focus:shadow-none pl-6 flex h-12 w-full items-center hover:text-menuTextHover bg-menu ${
                        current ? "text-menuTextHover" : "text-menuText"
                    } ${company && "h-15"}`}
                >
                    <div className={`w-6 flex justify-center ${company ? "pb-3" : ""}`}>{icon}</div>
                    <div className="pl-4 text-left">
                        <p className="text-sm font-bold">{name}</p>
                        {company && <p className="text-xs opacity-50">{company}</p>}
                    </div>
                </a>
            )}
        </>
    );
};

export default MenuItem;
