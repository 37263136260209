import Icon from "components/Icon";
import { isArray, take } from "lodash/fp";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ITable, ITableResult, SearchTableResult } from "../../types";
import DepartmentCell from "../DepartmentsCell";
import { ColumnTextRow, ProfileRow, RowWrapper } from "../SearchTableRows";
interface IElement extends ITableResult {
    firstElement: boolean;
    lastElement: boolean;
}
const Element = ({ type, header, body, texts, id, firstElement, lastElement, text }: IElement) => {
    let element: JSX.Element | null = null;
    switch (type) {
        case "ColumnText": {
            element = <ColumnTextRow header={header} body={body} />;
            break;
        }
        case "DepartmentsCell": {
            element = <DepartmentCell texts={texts} />;
            break;
        }
        case "ProfileRow": {
            element = <ProfileRow header={header} body={body} archived={text} />;
            break;
        }
        case "Button": {
            element = (
                <Link to={`/followup/employee/${id}/absence`}>
                    <div className="h-full pt-[7px]">
                        <Icon icon="caretRight" size={10} />
                    </div>
                </Link>
            );
            break;
        }
        default: {
            element = <ColumnTextRow header={header} body={body} />;
            break;
        }
    }
    return (
        <RowWrapper firstElement={firstElement} lastElement={lastElement}>
            {element}
        </RowWrapper>
    );
};
const useRows = (tableProps: ITable) => {
    const navigate = useNavigate();
    const getElement = useCallback((cells: SearchTableResult[]) => {
        let elements: {
            data: JSX.Element[];
            id: string;
        }[] = [];
        let index = 0;
        cells.forEach((value) => {
            const firstElement = index === 0;
            const lastElement = index === cells.length - 1;
            if (isArray(value.data)) {
                elements = [
                    ...elements,
                    {
                        data: take(
                            1,
                            value.data.map((value) => (
                                <Element firstElement={firstElement} lastElement={lastElement} {...value} />
                            )),
                        ),
                        id: value.id || "",
                    },
                ];
            } else {
                if (value.data) {
                    elements = [
                        ...elements,
                        {
                            data: [<Element firstElement={firstElement} lastElement={lastElement} {...value.data} />],
                            id: value.id || "",
                        },
                    ];
                }
            }
            index += 1;
        });
        return elements;
    }, []);
    const onRowClick = useCallback(
        (id?: string | null) => {
            if (id) {
                navigate(`/followup/employee/${id}/absence`);
            }
        },
        [navigate],
    );
    const rows = useMemo(
        () =>
            tableProps?.data.map((props) => ({
                cells: getElement(props.cells),
                rowProps: {
                    onClick: props.id ? () => onRowClick(props.id) : undefined,
                    theme: props.theme,
                },
            })),
        [getElement, onRowClick, tableProps?.data],
    );
    return rows;
};

export default useRows;
