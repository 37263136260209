import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import TableCell from "../TableCell";
import Icon, { Icons } from "components/Icon/Icon";

interface ITableRow {
    onClick?: () => void;
    icon?: Icons;
    size?: number;
    children: ReactNode;
}

const TableRow = ({ children, onClick, icon, size }: ITableRow) => (
    <tr className={twMerge("border-t align-top", onClick && "cursor-pointer hover:bg-grey-50")} onClick={onClick}>
        {children}
        {onClick && (
            <TableCell className="table-cell w-10">
                <Icon icon={icon ? icon : "caretRight"} size={size ? size : 10} />
            </TableCell>
        )}
    </tr>
);

export default TableRow;
