import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchPartnerContactInformation as fetchPartnerContactInformationAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchPartnerContactInformation = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchPartnerContactInformationAction.fulfilled, (state, action) => {
            if (action.payload) {
                state.partnerContactInformation = action.payload;
            }
            state.status.partnerContactInformation = Status.OK;
        })
        .addCase(fetchPartnerContactInformationAction.pending, (state) => {
            state.status.partnerContactInformation = Status.PENDING;
        })
        .addCase(fetchPartnerContactInformationAction.rejected, (state) => {
            state.status.partnerContactInformation = Status.ERROR;
        });

export default fetchPartnerContactInformation;
