import ErasureSettings from "./Settings";
import { useCallback } from "react";
import { ActiveErasurePropNames } from "pages/Administration/types";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
    getErasureActiveSettingPageProps,
    getSelectedCompanyId,
} from "pages/Administration/redux/administrationSelectors";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import { postCompanyDataErasureActiveConfiguration } from "pages/Administration/redux/administrationAsyncActions";

export default function Index() {
    const dispatch = useAppDispatch();
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);

    const erasureActiveSettingsProps = useAppSelector(getErasureActiveSettingPageProps);

    const handleOnCheckedChange = useCallback(
        (propName: ActiveErasurePropNames, value: number | null) => {
            dispatch(
                administrationActions.setErasureActiveValue({
                    propName,
                    value,
                }),
            );
        },
        [dispatch],
    );

    const handleSaveClicked = useCallback(() => {
        if (selectedCompanyId) {
            dispatch(
                postCompanyDataErasureActiveConfiguration({
                    ...erasureActiveSettingsProps.activeErasureConfig,
                    companyId: selectedCompanyId,
                }),
            );
        }
    }, [dispatch, erasureActiveSettingsProps.activeErasureConfig, selectedCompanyId]);

    return (
        <ErasureSettings
            {...erasureActiveSettingsProps}
            onPropChange={handleOnCheckedChange}
            onSaveClicked={handleSaveClicked}
        />
    );
}
