import Chart from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import _, { uniqueId } from "lodash";
import { useTranslation } from "react-i18next";
import { ReactComponent as RadioActive } from "icons/widget/radioActive.svg";
import { ReactComponent as MySelection } from "icons/widget/radioMySelection.svg";
import { ReactComponent as Radio } from "icons/widget/radio.svg";

interface IValue {
    year?: number;
    month?: number;
    ratio?: number;
}
interface ILineData {
    mySelection: IValue[];
    previousYear: IValue[];
    sameIndustry?: IValue[];
    sameSize?: IValue[];
}
interface ILineChart {
    data: ILineData;
    primaryColor: string;
    secondaryColor: string;
    companyIds: number[];
}

const LineChart = ({ data, primaryColor, secondaryColor, companyIds }: ILineChart) => {
    const chartContainer = useRef(null);
    const { t } = useTranslation("dashboard");

    const [currentlySelected, setCurrentlySelected] = useState<number | null>(1);

    const handleClick = (index: number) => setCurrentlySelected(index);

    const keys = Object.keys(data);
    const values = Object.values(data);

    const getMonth = (number: number) => {
        return ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"][number - 1];
    };

    useEffect(() => {
        if (companyIds.length > 1) {
            setCurrentlySelected(null);
        } else {
            setCurrentlySelected(1);
        }
    }, [companyIds]);

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(chartContainer.current, {
                type: "line",
                data: {
                    labels: values[0].map((value: any) => `${t(getMonth(value.month))} ${value.year}`),
                    datasets: [
                        {
                            label: t("mySelection"),
                            data: values[0].map((value: any) => (value.ratio * 100).toFixed(2)),
                            fill: false,
                            borderColor: primaryColor,
                            pointRadius: 0,
                            pointHitRadius: 6,
                            pointHoverBorderWidth: 2,
                            pointHoverBackgroundColor: "#FFFFFF",
                            pointHoverBorderColor: primaryColor,
                            borderCapStyle: "round",
                            borderWidth: 5,
                            tension: 0.4,
                        },
                        {
                            label: t(keys[currentlySelected!]),
                            data: values[currentlySelected!]?.map((value: any) => (value.ratio * 100).toFixed(2)),
                            fill: false,
                            borderColor: secondaryColor,
                            pointRadius: 0,
                            pointHitRadius: 6,
                            pointHoverBorderWidth: 2,
                            pointHoverBackgroundColor: "#FFFFFF",
                            pointHoverBorderColor: secondaryColor,
                            borderCapStyle: "round",
                            tension: 0.4,
                        },
                    ],
                },
                options: {
                    animation: {
                        duration: 0,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    aspectRatio: 1,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (x: any) => `${` ${x.dataset.label}: ${x.formattedValue}`}%`,
                            },
                            bodySpacing: 8,
                            caretPadding: 16,
                            boxWidth: 6,
                            boxHeight: 6,
                            caretSize: 0,
                            usePointStyle: true,
                            mode: "x",
                            intersect: false,
                            titleColor: "#192D05",
                            bodyColor: "#A0A0A0",
                            backgroundColor: "#FFFFFF",
                            cornerRadius: 0,
                            padding: 20,
                            bodyFont: {
                                size: 12,
                            },
                            titleFont: {
                                size: 12,
                            },
                        },
                        legend: {
                            display: false,
                        },
                    },
                    hover: {
                        mode: "x",
                        intersect: false,
                    },
                    scales: {
                        x: {
                            ticks: {
                                callback: (i: any) => {
                                    const value = t(getMonth(values[0][i].month)).substring(0, 3);
                                    return value;
                                },

                                font: {
                                    size: 10,
                                    // @ts-ignore
                                    font: "LabGrotesque",
                                },
                                color: "#5A5F4E",
                            },
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            min: 0,
                            ticks: {
                                callback: (value: any) => `${value.toFixed(2)}%`,
                                count: 5,
                                font: {
                                    size: 10,
                                    // @ts-ignore
                                    font: "LabGrotesque",
                                },
                                color: "#5A5F4E",
                            },
                            grid: {
                                lineWidth: 0.5,
                                drawBorder: false,
                                color: "#8F957F",
                            },
                        },
                    },
                },
            });
            return () => newChartInstance.destroy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartContainer, currentlySelected, values]);

    const uuid = uniqueId();

    return (
        <div>
            <div className="flex flex-wrap text-sm chart-selector py-4 px-6">
                {Object.entries(data).map(([key], index) =>
                    index === 0 ? (
                        <div key={key} className="w-1/3 flex items-center">
                            <div className="flex">
                                <MySelection fill={primaryColor} />
                                <p className="text-xs font-sans pl-1">{t(_.camelCase(key))}</p>
                            </div>
                        </div>
                    ) : (
                        companyIds.length === 1 && (
                            <div key={key} className="w-1/3 flex items-center">
                                <label
                                    htmlFor={uuid + key}
                                    onChange={() => handleClick(index)}
                                    className="flex items-center cursor-pointer"
                                >
                                    <input className="hidden" type="radio" id={uuid + key} name="barChart" />
                                    <div>
                                        {index === currentlySelected ? (
                                            <RadioActive fill={secondaryColor} stroke={secondaryColor} />
                                        ) : (
                                            <Radio fill={secondaryColor} stroke={secondaryColor} />
                                        )}
                                    </div>
                                    <p className="text-xs font-sans pl-1">{t(_.camelCase(key))}</p>
                                </label>
                            </div>
                        )
                    ),
                )}
            </div>
            <div>
                <div className="chart-container">
                    <canvas ref={chartContainer} />
                </div>
            </div>
        </div>
    );
};

export default LineChart;
