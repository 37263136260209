import { get, isEmpty } from "lodash/fp";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { useAppDispatch } from "store/hooks";
import { actions, selectors } from "../../redux";
import { sortEmployementGroup, findTagByReferenceKey } from "../../utils";
import EmploymentGroupsFilter from "./EmploymentGroupsFilter";

export default function Index() {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectors.getSearchTagByIdSorted("employmentGroups", "asc"));

    const Header = useMemo(() => t("employmentGroupsFilter.employmentGroups"), [t]);

    const Tags = useMemo(() => {
        const getCheckedItems = filters.filter(get("checked"));
        if (isEmpty(getCheckedItems)) return "";
        return getCheckedItems.map((x) => t(x.label)).join(", ");
    }, [filters, t]);

    const onChange = useCallback(
        (value: string) => {
            const element = findTagByReferenceKey(value, filters);
            if (!element) return;
            dispatch(
                actions.updateSearchFilter({
                    ...element,
                    checked: !element.checked,
                }),
            );
        },
        [dispatch, filters],
    );
    const Filters = useMemo(
        () =>
            sortEmployementGroup(filters).map((x) => ({
                ...x,
                label: t(x.label),
            })),
        [filters, t],
    );
    return <EmploymentGroupsFilter onChange={onChange} tags={Tags} header={Header} items={Filters} />;
}
