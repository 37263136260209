import { StringDateRange } from "@medhelp/ui/Datepicker/types";
import { isAfter, isBefore, isEqual } from "date-fns";
import { DateRange } from "react-day-picker";
import { convertDateRangeToStringDateRange } from "utils/date";

interface IHandleSelectProps {
    selected: DateRange | undefined;
    firstClick: boolean;
    tabSelected: string;
    setFirstClick: (firstClick: boolean) => void;
    setSelected: (selected: DateRange | undefined) => void;
    setOpen: (open: boolean) => void;
    setTabSelected: (tabSelected: string) => void;
    setRange: (range: StringDateRange | undefined) => void;
}

const DateRangeHandleSelected =
    ({
        selected,
        firstClick,
        tabSelected,
        setFirstClick,
        setSelected,
        setOpen,
        setTabSelected,
        setRange,
    }: IHandleSelectProps) =>
    (dateRange: DateRange | undefined): void => {
        /* 
            ! Important note that dateRange to and from can have undefined values
            ! This is because the datepicker can give this depending on the tab selected
            ! and were you are coming from. So we need to handle this.

            ! There is logic if you come from to tab you are always first click
            ! If you come from page edit you are also first click
            ! This is set in DateTabs
        */

        // When coming from tab "from" and is first click
        // Should have a from date and a to date as values coming in
        // Should have a from date and a to date selected
        // The new value from should be before the selected to date
        // The new value to should be before the selected to date
        // The new value from should be after the selected from date
        // Should set firstClick to false
        // Should set the selected to the new values from with new from value
        // and the selected to with old selected to value
        // Return

        if (
            dateRange?.to &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            firstClick === true &&
            isBefore(new Date(dateRange.from), new Date(selected.to)) &&
            isBefore(new Date(dateRange.to), new Date(selected.to)) &&
            isAfter(new Date(dateRange.from), new Date(selected.from)) &&
            tabSelected === "from"
        ) {
            //console.log("1");
            setFirstClick(false);
            setSelected({
                from: dateRange?.from,
                to: selected?.to,
            });
            setRange(
                convertDateRangeToStringDateRange({
                    from: dateRange?.from,
                    to: selected?.to,
                }),
            );
            return;
        }

        // When coming from tab "from" and is first click
        // Should have a from date and a to date as values coming in
        // Should have a from date and a to date selected
        // The new value from should be before the selected to date
        // Set firstClick to false
        // Set the selected to the new values from with new from value
        // Return

        if (
            dateRange?.to &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            firstClick === true &&
            isAfter(new Date(dateRange?.to), new Date(selected?.to)) &&
            tabSelected === "from"
        ) {
            //console.log("2");
            setFirstClick(false);
            setSelected({
                from: dateRange?.to,
                to: dateRange?.to,
            });
            setRange(
                convertDateRangeToStringDateRange({
                    from: dateRange?.to,
                    to: dateRange?.to,
                }),
            );
            return;
        }

        // Set firstClick to false
        // Tab selected to false
        // Has a previous from and to date selected
        // It has a new from date that is equal to selected from date
        // Set selected to the old value to and from to the selected from value
        // Set tab selected to false
        // Close the modal
        // Return
        // This is the case you are editing a range and clicking on last to date
        // that is same as before

        if (
            dateRange?.to === undefined &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            firstClick === false &&
            isEqual(new Date(dateRange?.from), new Date(selected?.to)) &&
            tabSelected === "from"
        ) {
            //console.log("3");
            setTabSelected("false");
            setOpen(false);
            setSelected({
                from: selected?.from,
                to: selected?.to,
            });
            setRange(
                convertDateRangeToStringDateRange({
                    from: selected?.from,
                    to: selected?.to,
                }),
            );
            return;
        }

        // If not first click
        // Should have a from date and a to date as values coming in
        // Should have a select from date but not a to date selected
        // Should set firstClick to true
        // Set selected tab to false
        // Close the modal
        // Set the selected with new date properties values
        // Return
        if (dateRange?.from && dateRange?.to && selected?.from && !selected?.to && firstClick === false) {
            //console.log("4");
            setFirstClick(true);
            setTabSelected("false");
            setOpen(false);
            setSelected(dateRange);
            setRange(convertDateRangeToStringDateRange(dateRange));
            return;
        }

        // Is first click
        // Should have a from date and a to date as values coming in
        // Should have a select from date but not a to date selected
        // Should set firstClick to false
        // Set selected tab to false
        // Close the modal
        if (dateRange?.from && dateRange?.to && selected?.from && !selected?.to && firstClick === true) {
            //console.log("5");
            setFirstClick(false);
            setTabSelected("false");
            setOpen(false);
        }

        // Has new from and to date coming in
        // Has a previous from and to date selected
        // New value to should be after the selected to date
        // Set firstClick to true
        // Set selected tab to false
        // Close the modal
        // Set the selected with new date properties values
        // Return

        if (
            dateRange?.to &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            isAfter(new Date(dateRange?.to), new Date(selected?.to))
        ) {
            //console.log("6");
            setFirstClick(true);
            setTabSelected("false");
            setOpen(false);
            setSelected(dateRange);
            setRange(convertDateRangeToStringDateRange(dateRange));
            return;
        }

        // Is first click
        // It has new from and to date coming in
        // Has a previous from and to date selected
        // It has a new from date that is equal to selected from date
        // It has a new to date that is before the selected to date
        // It has a new to date that is after the selected from date
        // Set firstClick to false
        // Selected tab is to
        // Set selected to the new value to and from to the selected to value
        // Return
        // When comming in from the to tab just set the selected to the new value

        if (
            dateRange?.to &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            firstClick === true &&
            tabSelected === "to" &&
            isEqual(new Date(dateRange?.from), new Date(selected?.from)) &&
            isBefore(new Date(dateRange?.to), new Date(selected?.to)) &&
            isAfter(new Date(dateRange?.to), new Date(selected?.from))
        ) {
            //console.log("7");
            setTabSelected("false");
            setFirstClick(false);
            setOpen(false);
            setSelected({
                from: selected?.from,
                to: dateRange?.to,
            });
            setRange(
                convertDateRangeToStringDateRange({
                    from: selected?.from,
                    to: dateRange?.to,
                }),
            );
            return;
        }

        // Is first click
        // It has new from and to date coming in
        // Has a previous from and to date selected
        // It has a new from date that is equal to selected from date
        // It has a new to date that is before the selected to date
        // It has a new to date that is after the selected from date
        // Set firstClick to false
        // Set selected to the new value to and from to the selected to value
        // Return

        if (
            dateRange?.to &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            firstClick === true &&
            isEqual(new Date(dateRange?.from), new Date(selected?.from)) &&
            isBefore(new Date(dateRange?.to), new Date(selected?.to)) &&
            isAfter(new Date(dateRange?.to), new Date(selected?.from))
        ) {
            //console.log("8");
            setFirstClick(false);
            setSelected({
                from: dateRange?.to,
                to: selected?.to,
            });
            setRange(
                convertDateRangeToStringDateRange({
                    from: dateRange?.to,
                    to: selected?.to,
                }),
            );
            return;
        }

        // Is not first click
        // It has new from and to date coming in
        // Has a previous from and to date selected
        // It has a new to date that is after the selected from date
        // It has a new from date that is after the selected from date
        // Set firstClick to true
        // Set selected tab to false
        // Set the selected with new date properties values
        // Close the modal
        // Return
        if (
            dateRange?.to &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            isAfter(new Date(dateRange?.to), new Date(selected?.from)) &&
            isAfter(new Date(dateRange?.from), new Date(selected?.from)) &&
            firstClick === false
        ) {
            //console.log("9");
            setFirstClick(true);
            setTabSelected("false");
            setSelected(dateRange);
            setOpen(false);
            return;
        }

        // Is first click
        // It has new from and to date coming in
        // Has a previous from and to date selected
        // It has a new to date that is after the selected from date
        // It has a new from date that is after the selected from date
        // Set firstClick to false
        // Set selected to the new value to and from to the selected to value
        // Return
        if (
            dateRange?.to &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            isAfter(new Date(dateRange?.to), new Date(selected?.from)) &&
            isAfter(new Date(dateRange?.from), new Date(selected?.from)) &&
            firstClick === true
        ) {
            //console.log("10");
            setFirstClick(false);
            setSelected({
                from: dateRange?.to,
                to: selected?.to,
            });
            setRange(
                convertDateRangeToStringDateRange({
                    from: dateRange?.to,
                    to: selected?.to,
                }),
            );
            return;
        }

        // Is not first click
        // It has new from and to date coming in
        // Has a previous from and to date selected
        // It has a new to date that is before the selected to date
        // Set firstClick to true
        // Set selected to the new properties values
        // Set selected tab to false
        // Close the modal
        // Return
        if (
            dateRange?.to &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            isBefore(new Date(dateRange?.to), new Date(selected?.to)) &&
            firstClick === false
        ) {
            //console.log("11");
            setFirstClick(true);
            setSelected(dateRange);
            setOpen(false);
            setTabSelected("false");
            return;
        }

        // If not first click
        // Tab selected from
        // Does not have a to date coming in
        // Should have a from date incoming
        // Has a previous from and to date selected
        // Set selected tab to false
        // Close the modal
        // Return
        // Editing a range from tab clicking on same date as before
        if (
            dateRange?.to === undefined &&
            dateRange?.from &&
            selected?.to &&
            selected?.from &&
            firstClick === true &&
            isEqual(new Date(dateRange?.from), new Date(selected?.to)) &&
            tabSelected === "to"
        ) {
            //console.log("12");
            setTabSelected("false");
            setFirstClick(false);
            setOpen(false);
            return;
        }
        //console.log("default");

        // If not returned from prevoius checks
        // Set selected to the new properties values
        // Set selected tab to false
        setSelected(dateRange);
        setRange(
            convertDateRangeToStringDateRange({
                from: dateRange?.from,
                to: dateRange?.to,
            }),
        );
        setFirstClick(false);
    };

export default DateRangeHandleSelected;
