import { orderBy } from "lodash/fp";
import { useMemo } from "react";
import { DegreePeriodType } from "../../types";
import DegreeColumnText from "../DegreeColumnText";

interface IProps {
    degrees?: DegreePeriodType;
}
const DegreeRow = ({ degrees }: IProps) => {
    const body = useMemo(() => {
        const latestDegree = orderBy((x) => x.timeStamp, "desc", degrees?.degrees);
        return <div>{latestDegree[0].degree}%</div>;
    }, [degrees]);
    return (
        <div className="flex items-start">
            <div className="flex flex-col max-h-20">
                <DegreeColumnText body={body} degrees={degrees?.degrees} endDate={degrees?.endDate || ""} />
            </div>
        </div>
    );
};
export default DegreeRow;
