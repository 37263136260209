import { useCallback, useMemo } from "react";
import Item from "./Item";
import { IDynamicData } from "./types";
import { DynamicData } from "domain/ogranization";
import { showDataWithSearch } from "./utils";

export interface IProps {
    setData: (data: string) => void;
    data: IDynamicData[];
    searchParams: string;
}

const List = ({ data, setData, searchParams }: IProps) => {
    const onChange = useCallback(
        (referenceKey: string) => {
            setData(referenceKey);
        },
        [setData],
    );
    const hasChildren = useMemo(() => DynamicData.hasAnyChildren(data), [data]);
    return (
        <>
            {showDataWithSearch(searchParams, data).map((item) => (
                <Item
                    {...item}
                    onChange={onChange}
                    key={`${item.referenceKey}-${item.label}`}
                    hasChildren={hasChildren}
                    alt={item.label}
                />
            ))}
        </>
    );
};

export default List;
