import SearchTableResult from "../SearchTableResult";
interface IProps {
    header?: JSX.Element;
    preview: JSX.Element | undefined;
    result: {
        desktop: JSX.Element;
        mobile: JSX.Element;
    };
    errorMessage: string | null;
    noData: boolean;
}
const EmployeeSearchResult = ({ preview, result, errorMessage, noData, header }: IProps) => (
    <>
        {header && header}
        <SearchTableResult preview={preview} result={result} errorMessage={errorMessage} noData={noData} />
    </>
);
export default EmployeeSearchResult;
