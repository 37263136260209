import * as React from "react";
import { ReactComponent as CloseIcon } from "icons/login/close.svg";
import Modal from "../Modal";
import { getRetailerBg } from ".";
import { Retailers } from "globalTypes";

export interface IProps {
    id?: string;
    open: boolean;
    setOpen: (open: boolean, afterClose?: boolean) => void;
    header?: React.ReactNode;
    content?: React.ReactNode;
    footer?: React.ReactNode;
    preset?: "normal" | "full";
    noPaddingTop?: boolean;
}
interface IViewProps extends IProps {
    activeRetailer: Retailers;
}

const ModalFrame = ({
    id,
    open,
    setOpen,
    activeRetailer,
    header,
    content,
    preset = "normal",
    footer,
    noPaddingTop,
}: IViewProps) => {
    const ContentView = React.useMemo(() => {
        switch (preset) {
            case "normal":
                return <div className={`w-full max-w-[712px] ${noPaddingTop ? "" : "py-13"}`}>{content}</div>;
            case "full":
                return <div className="w-full h-full">{content}</div>;
            default:
                break;
        }
    }, [preset, noPaddingTop, content]);

    return (
        <Modal id={id} open={open} setOpen={setOpen} background={getRetailerBg(activeRetailer!)} theme={activeRetailer}>
            <div className={`${activeRetailer} w-full h-full relative`}>
                <div className="h-full flex flex-col">
                    <div className="w-full shadow-md flex justify-center z-10">
                        <div className="w-full h-20 ml-4 xl:ml-0 flex justify-center items-center text-center">
                            {header}
                        </div>
                        <button
                            type="button"
                            className="h-20 w-20 flex justify-center items-center"
                            onClick={() => setOpen(false)}
                        >
                            <CloseIcon className="h-3" />
                        </button>
                    </div>
                    <div id="modalcontent" className="w-full h-full flex flex-col items-center overflow-auto">
                        {ContentView}
                    </div>
                    {footer && <div className="w-full h-40 sm:h-36 react-modal-shadow-top flex">{footer}</div>}
                </div>
            </div>
        </Modal>
    );
};

export default ModalFrame;
