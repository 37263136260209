import Icon from "components/Icon";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ShowDepartments } from "../../types";
import { actions, selectors } from "../../redux";
import AffectedDepartmentFilter from "./AffectedDepartmentFilter";

export default function Index() {
    const dispatch = useAppDispatch();
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const showDepartments = useAppSelector(selectors.getShowDeparments(currentSearch));
    const { t } = useTranslation("followup");
    const [open, setOpen] = useState(false);
    const Items = useMemo(
        () => (
            <>
                {[
                    { text: t("everyDepartment"), name: "everyDepartment" },
                    {
                        text: t("onlyLatestDepartment"),
                        name: "onlyLatestDepartment",
                    },
                ]
                    .filter(({ name }) => name !== showDepartments)
                    .map((value) => {
                        return (
                            <div
                                onClick={() => {
                                    dispatch(actions.showDepartments(value.name as ShowDepartments));
                                }}
                                className="w-full my-2 hover:bg-mhgrey-light"
                                key={`items-affected-department-filter-${value.name}`}
                            >
                                <div className="flex items-center text-sm font-sans px-4 py-[6px] hover:bg-mhgrey-light">
                                    {value.text}
                                </div>
                            </div>
                        );
                    })}
            </>
        ),
        [dispatch, showDepartments, t],
    );
    const Header = useMemo(
        () => (
            <div onClick={() => setOpen(!open)} className="cursor-pointer select-none">
                <div className="flex items-center gap-[9px]">
                    <p className="text-grey-600 text-sm">{t("showDepartments")}:</p>
                    <div className="flex items-center gap-[3px]">
                        <p className="text-sm">{t(showDepartments)}</p>
                        <Icon icon={open ? "caretUp" : "caretDown"} size={8} />
                    </div>
                </div>
            </div>
        ),
        [open, showDepartments, t],
    );
    return <AffectedDepartmentFilter open={open} items={Items} header={Header} setOpen={setOpen} />;
}
