import { createSelector } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { RootState } from "store";
import { getWidgetFilterRequestModel } from "../utils/dashboardActionsUtils";
import { flattenDepartments } from "../utils/dashboardSliceUtils";
import { getfilteredAndOrderedEmployees } from "../utils/dashboardSelectorsUtils";
import { getActivePowerbarSearch } from "store/searchSelectors";
import {
    MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse,
    MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse,
    MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse,
    MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceDashboardResponse,
    MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse,
    MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse,
} from "swagger/absencefollowup";
import { AbsenceWorkRatioForecastResponse, AbsenceWorkRatioResponse } from "swagger/statistics";
import { FilteredLatestRehabResponse, FilteredRehabResponse } from "swagger/rehab";
import {
    MedHelpHCMWebApiResponseStatusOverviewResponse,
    MedHelpHCMWebApiResponseTodoAndOngoingResponse,
} from "swagger/healthCaseManagement";
import { IEstimatedAbsence, IRecurringAbsence } from "../utils/dashboardTypes";
import { camelCase, isEmpty, orderBy } from "lodash";
import { IData } from "../components/Charts/TableChart";

export const getCompanyIdBasedOnPowerbarSearchOrUser = createSelector(
    getActivePowerbarSearch,
    (activePowerbarSearch) => {
        if (activePowerbarSearch?.companyId) {
            return activePowerbarSearch?.companyId;
        }
        return null;
    },
);

export const getCustomerIdBasedOnPowerbarSearchOrUser = createSelector(
    getActivePowerbarSearch,
    (activePowerbarSearch) => {
        if (activePowerbarSearch?.customerId) {
            return activePowerbarSearch?.customerId;
        }
        return null;
    },
);

export const getDashboard = (state: RootState) => state.dashboard;

export const getWidgets = (state: RootState) => state.dashboard.Widgets;

export const getWidget = (widgetId: string) =>
    createSelector(getWidgets, (widgets) => widgets?.find(({ id }) => id === widgetId));

export const getCompanyGroupForCompany = (state: RootState) => state.dashboard.CompanyGroupForCompany;

export const getOngoingAbsence = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse;
        const employeeDetails = widgetDataState?.employeeDetails?.map((x) => ({
            employeeName: `${x.firstName} ${x.lastName}`,
            employmentId: x.employmentId || "",
            hasFollowUp: x.hasFollowUp,
        }));
        return {
            data: {
                ongoingAbsenceDegreeSum: widgetDataState?.ongoingAbsenceDegreeSum,
                ongoingAbsenceEmploymentCount: widgetDataState?.ongoingAbsenceEmploymentCount,
                employmentCount: widgetDataState?.employmentCount,
                employeeDetails: getfilteredAndOrderedEmployees(employeeDetails || []),
            },
            status: widget?.status,
        };
    });

export const getLastReportedAbsence = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse[];
        return { data: widgetDataState, status: widget?.status };
    });

export const getAbsencePercentLast6Months = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as AbsenceWorkRatioResponse;
        return {
            data: {
                mySelection: widgetDataState?.mySelection || [],
                previousYear: widgetDataState?.previousYear || [],
                sameIndustry: widgetDataState?.sameIndustry || [],
                sameSize: widgetDataState?.sameSize || [],
            },
            status: widget?.status,
        };
    });

export const getAbsencePercentPerMonth = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as AbsenceWorkRatioResponse;
        return {
            data: {
                mySelection: widgetDataState?.mySelection || [],
                previousYear: widgetDataState?.previousYear || [],
                sameIndustry: widgetDataState?.sameIndustry || [],
                sameSize: widgetDataState?.sameSize || [],
            },
            status: widget?.status,
        };
    });

export const getWorkRelatedAbsence = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState =
            widget?.chartData as MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse;
        const employeeDetails = widgetDataState?.employeeDetails?.map((x) => ({
            employeeName: `${x.firstName} ${x.lastName}` || "",
            employmentId: x.employmentId || "",
            processType: x.type || "",
            hasFollowUp: x.hasFollowUp,
        }));
        return {
            data: {
                workRelatedOngoing: widgetDataState?.workRelatedOngoing,
                workPlaceAccidentOngoing: widgetDataState?.workPlaceAccidentOngoing,
                employeeDetails: getfilteredAndOrderedEmployees(employeeDetails || []),
            },
            status: widget?.status,
        };
    });

export const getEstimatedUpcomingAbsence = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse[];

        const estimatedAbsence: IEstimatedAbsence[] = [];
        widgetDataState?.map((x) => {
            const employeeDetails = x.employeeDetails?.map((y) => ({
                employeeName: `${y.firstName} ${y.lastName}`,
                employmentId: y.employmentId || "",
                hasFollowUp: y.hasFollowUp,
            }));
            const uniqueAndFilteredEmployees = getfilteredAndOrderedEmployees(employeeDetails || []);
            estimatedAbsence.push({
                day: x.day || "",
                estimatedAbsence: x.estimatedAbsence || 0,
                estimatedAbsenceWithPastBackAtWork: x.estimatedAbsenceWithPastBackAtWork || 0,
                employeeDetails: uniqueAndFilteredEmployees,
            });
        });
        return { data: estimatedAbsence, status: widget?.status };
    });

export const getRecurringAbsences = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const data = widget?.chartData as MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceDashboardResponse;

        const sevenOrMore: IRecurringAbsence = { groupName: "sevenOrMore", employees: [] };
        const lessThanSeven: IRecurringAbsence[] = new Array(4).fill(null).map((_, index) => ({
            groupName: `${index + 3}`,
            employees: [],
        }));

        data?.recurringAbsenceStatistics?.forEach((x) => {
            if (!x.groupName || !x.employees) return;
            const employeeDetails = x.employees?.map((y) => ({
                employeeName: `${y.firstName} ${y.lastName}`,
                employmentId: y.employmentId || "",
                hasFollowUp: y.hasFollowUp,
                absences: x.groupName ? parseInt(x.groupName) : 0,
            }));
            if (parseInt(x.groupName) < 7) {
                const groupIndex = parseInt(x.groupName) - 3;
                lessThanSeven[groupIndex].employees = getfilteredAndOrderedEmployees(employeeDetails);
            } else {
                sevenOrMore.employees.push(...getfilteredAndOrderedEmployees(employeeDetails));
            }
        });

        return { data: [...lessThanSeven, sevenOrMore], status: widget?.status };
    });

export const getOngoingRehab = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as FilteredRehabResponse;
        const employeeDetails = widgetDataState?.employeeDetails?.map((x) => ({
            employeeName: x.employeeName || "",
            employmentId: x.employmentId || "",
            processType: x.processType || "",
            initationDate: x.initationDate || "",
            daysInRehabChain: x.daysInRehabChain || 0,
        }));

        return {
            data: {
                longTerm: widgetDataState?.longTerm,
                recurring: widgetDataState?.recurring,
                manually: widgetDataState?.manually,
                total: widgetDataState?.total,
                employeeDetails: getfilteredAndOrderedEmployees(employeeDetails || []),
            },
            status: widget?.status,
        };
    });

export const getLastActivatedRehab = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as FilteredLatestRehabResponse[];
        return { data: widgetDataState, status: widget?.status };
    });

export const getAbsenceForecastCurrentMonth = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as AbsenceWorkRatioForecastResponse;
        return { data: widgetDataState, status: widget?.status };
    });

export const getHCMStatusOverview = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const { t } = useTranslation("dashboard");
        const statusData: {
            [key: string]: IData[];
        } = {
            preliminary: [],
            declined: [],
            expired: [],
            ongoing: [],
        };
        const widgetDataState = orderBy(
            widget?.chartData as MedHelpHCMWebApiResponseStatusOverviewResponse[],
            "templateName",
            "desc",
        );
        widgetDataState?.map((item) => {
            item?.overviewStatus?.map((status) => {
                if (status.status) {
                    statusData[status.status]?.push({
                        type: item.templateName ?? "",
                        status: status.status ?? "",
                        count: status.totalCount ?? 0,
                        modalHeading: t(`statusOverview.${item.templateName}.${camelCase(status.status)}.heading`),
                    });
                }
            });
        });
        return {
            data: statusData,
            status: widget?.status,
        };
    });

export const getHCMTodoAndOngoing = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as MedHelpHCMWebApiResponseTodoAndOngoingResponse;

        return {
            data: [
                { key: "hcmTodo", data: widgetDataState?.attentions },
                { key: "hcmOngoing", data: widgetDataState?.ongoing },
            ],
            status: widget?.status,
        };
    });

export const getCompanies = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.companies ?? []);

export const getDepartments = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.departments ?? []);

export const getEmploymentGroups = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.employmentGroups ?? []);

export const getGenders = (widgetId: string) => createSelector(getWidget(widgetId), (widget) => widget?.genders ?? []);

export const getAbsenceTypes = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.absenceTypes ?? []);

export const getAbsencePeriods = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.absencePeriods ?? []);

export const getTimeInterval = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.timeInterval ?? []);

export const getAbsenceCauses = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.absenceCauses ?? []);

export const getHCMTypes = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.hcmTypes ?? []);

export const getWidgetFilter = (isRehabWidget: boolean, widgetId: string) =>
    createSelector(getDashboard, (dashboardState) => {
        return getWidgetFilterRequestModel(widgetId, dashboardState, isRehabWidget);
    });

export const getCompaniesFromWidgetList = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.filter?.companyIds);

export const getAbsencesPerCause = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => {
        const widgetDataState = widget?.chartData as MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse;
        return {
            data: {
                mySelection: widgetDataState?.mySelection || [],
                previousYear: widgetDataState?.previousYear || [],
            },
            status: widget?.status,
        };
    });

export const getChosenTimeInterval = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.timeInterval.find((x) => x.checked)?.label);

export const getChosenAbsenceCause = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.absenceCauses?.find((x) => x.checked)?.label);

export const getWidgetHeading = (widgetId: string) =>
    createSelector(getWidget(widgetId), (widget) => widget?.customHeading);

export const getCheckedItems = (widgetId: string) =>
    createSelector(
        getCompanies(widgetId),
        getDepartments(widgetId),
        getEmploymentGroups(widgetId),
        getGenders(widgetId),
        getAbsenceTypes(widgetId),
        getAbsencePeriods(widgetId),
        getTimeInterval(widgetId),
        getAbsenceCauses(widgetId),
        (
            companiesState,
            departmentsState,
            employmentGroupsState,
            gendersState,
            absenceTypeState,
            absencePeriodState,
            timeIntervalState,
            absenceCausesState,
        ) => {
            const { t } = useTranslation("dashboard");
            const checkedCompanies = companiesState?.filter(({ checked }) => checked);
            const checkedDepartments = flattenDepartments(departmentsState || [])?.filter(
                ({ checked, selectable }) => checked && selectable,
            );

            const checkedEmploymentGroups = employmentGroupsState?.filter(({ checked }) => checked);
            const checkedGenders = gendersState?.filter(({ checked }) => checked);
            const checkedAbsenceTypes = absenceTypeState?.filter(({ checked }) => checked);
            const checkedAbsencePeriods = absencePeriodState?.filter(({ checked }) => checked);
            const selectedTimeInterval = timeIntervalState?.filter(({ checked }) => checked);
            const selectedAbsenceCause = absenceCausesState?.filter(({ checked }) => checked);

            const checkedFilterItems = [
                { heading: t("filterCompany"), data: checkedCompanies },
                {
                    heading: t("filterDepartment"),
                    data: checkedDepartments,
                },
                {
                    heading: t("filterEmploymentGroup"),
                    data: checkedEmploymentGroups,
                },
                { heading: t("filterGender"), data: checkedGenders },
                {
                    heading: t("filterAbsenceType"),
                    data: checkedAbsenceTypes,
                },
                {
                    heading: t("filterAbsencePeriod"),
                    data: checkedAbsencePeriods,
                },
                { heading: t("timeInterval"), data: selectedTimeInterval },
                { heading: t("absenceCause"), data: selectedAbsenceCause },
            ];
            return {
                checkedFilterItems,
                numberOfFilters:
                    checkedCompanies?.length +
                    checkedDepartments?.length +
                    checkedEmploymentGroups?.length +
                    checkedGenders?.length +
                    checkedAbsenceTypes?.length +
                    checkedAbsencePeriods?.length +
                    selectedTimeInterval?.length +
                    selectedAbsenceCause?.length,
            };
        },
    );

export const getAbsenceSummary = (state: RootState) => state.dashboard.AbsenceSummary;

export const getAbsenceSummaryDetails = (state: RootState) =>
    state.dashboard.AbsenceSummaryDetails.data.map((item) => ({
        ...item,
        // for sorting
        startDate: item.absencePeriods && item.absencePeriods[0].startDate,
        backAtWork: item.absencePeriods && item.absencePeriods[0].backAtWork,
        type: item.absencePeriods && item.absencePeriods[0].type,
        absenceDegree:
            item.absencePeriods && item.absencePeriods[0].degrees && item.absencePeriods[0].degrees[0].degree,
    }));

export const getAbsenceSummaryDetailsStatus = (state: RootState) => state.dashboard.AbsenceSummaryDetails.status;

export const getHCMTemplates = createSelector(getDashboard, (dashboardState) => dashboardState?.HCMTemplates.data);

export const hasHealthCaseManagement = createSelector(getHCMTemplates, (caseTemplates) => {
    return !isEmpty(caseTemplates);
});

export const getCheckedCompanies = (widgetId: string) =>
    createSelector(
        getCompanies(widgetId),

        (companiesState) => companiesState?.filter(({ checked }) => checked),
    );

export const getIsAnyCompanySelected = (widgetId: string) =>
    createSelector(
        getCompanies(widgetId),
        getCompaniesFromWidgetList(widgetId),
        getCheckedCompanies(widgetId),
        (companies, companiesFromWidgetList, checkedCompanies) =>
            companies?.length === 1 || companiesFromWidgetList?.length > 0 || checkedCompanies?.length > 0,
    );

export const getHealthCases = (state: RootState) =>
    state.dashboard.CaseSummary.data.map((item) => ({
        ...item,
        // for sorting
        numberOfCases: item.healthCases ? item.healthCases.length : 0,
    }));

export const getTotalNumberOfCases = createSelector(getHealthCases, (cases) =>
    cases.reduce((total, obj) => total + (obj.healthCases ? obj.healthCases.length : 0), 0),
);

export const getHealthCaseStatus = (state: RootState) => state.dashboard.CaseSummary.status;

export const getNews = (state: RootState) => state.dashboard.News;

export const getCreateWidgetStatus = (state: RootState) => state.dashboard.CreateWidgetStatus;

export const getDragAndDrop = (state: RootState) => state.dashboard.DragAndDrop;

/**
 * Returns true if the widget is being dragged or dropped on
 * @param widgetId
 * @returns boolean
 */
export const getIsDragging = (widgetId: string) =>
    createSelector(getDragAndDrop, ({ isDragging, droppedOnId }) => {
        return isDragging || widgetId === droppedOnId;
    });
