import Tag from "../Tag";

interface IProps {
    id: string;
    text: string;
}

const EventTag = (props: IProps) => {
    const { id, text } = props;

    return (
        <Tag id={id} className="bg-mhgrey-light py-1 h-6">
            <p className="font-bold text-xs text-grey-600">{text}</p>
        </Tag>
    );
};

export default EventTag;
