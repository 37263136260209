import { Heading } from "@medhelp/ui";
import Icon from "components/Icon";
import PrimaryButton from "components/PrimaryButton";
import TertiaryButton from "components/TertiaryButton";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface IAdminAccordionProps {
    heading: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    primaryButtonClicked?: () => void;
    primaryButtonText: string;
    primaryButtonIcon?: React.ReactElement;
    primaryButtonDisabled?: boolean;
    onClose?: () => void;
    active: boolean;
    children: ReactNode;
    displayValues: string[];
    isLoading?: boolean;
    infoText?: string;
}

const AdminAccordion = ({
    heading,
    open,
    setOpen,
    primaryButtonClicked,
    primaryButtonText,
    primaryButtonIcon,
    primaryButtonDisabled,
    onClose,
    active,
    children,
    displayValues,
    isLoading,
    infoText,
}: IAdminAccordionProps) => {
    const { t } = useTranslation("administration");
    const showProps = !open && !!displayValues;

    const handleOpenAndClose = () => {
        if (onClose && open) onClose();
        setOpen(!open);
    };

    return (
        <div className="w-full border-b border-grey-200 flex flex-col items-center relative" id="user-accordion">
            <div className={`w-full flex flex-col ${open ? "pb-6" : ""}`}>
                <div className="flex px-10 py-6 items-center">
                    <div className="w-full flex flex-col">
                        <div className="flex items-center">
                            <Icon
                                icon={active ? "checkMark" : "close"}
                                size={20}
                                className={active ? "text-[#11B05A]" : "text-mhred-alert1"}
                            />
                            <Heading heading={heading} infoText={infoText} className="ml-3 mb-0" />
                        </div>
                        {showProps &&
                            displayValues.map((value, i) => (
                                <div
                                    key={value + i}
                                    className="ml-8 mr-16 mt-3 text-grey-700 flex flex-col md:flex-row"
                                >
                                    <p className="text-sm mr-1">{value}</p>
                                </div>
                            ))}
                    </div>
                    <button
                        type="button"
                        className="h-16 w-16 flex items-center justify-center"
                        onClick={handleOpenAndClose}
                    >
                        <Icon icon={open ? "caretUp" : "caretDown"} size={16} />
                    </button>
                </div>
                {open && (
                    <>
                        <div id="user-accordion-children" className="ml-[70px] mr-[100px] mb-10 md:mb-0">
                            {children}
                        </div>
                        <div className="flex justify-center md:absolute md:top-9 md:right-30">
                            <TertiaryButton
                                id="modalActionButton"
                                text={t("abort")}
                                className={`flex self-center mr-6`}
                                onClick={handleOpenAndClose}
                            />
                            <PrimaryButton
                                id="admin-accordion-save"
                                text={primaryButtonText}
                                disabled={primaryButtonDisabled}
                                onClick={primaryButtonClicked}
                                className="flex self-center"
                                isLoading={isLoading}
                                leftIcon={primaryButtonIcon}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AdminAccordion;
