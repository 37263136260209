import { MetaData } from "swagger/storage";
import { IFile } from "../hooks";

interface IDefaultFile {
    type: string;
    owner: string;
    groupKey: string;
    metaData: MetaData[];
}

interface IFromFiles extends IDefaultFile {
    files: IFile[];
}
export const getFileFormData = ({ files, owner, groupKey, type, metaData }: IFromFiles) =>
    files.map((file) => ({
        file: file.file,
        owner,
        groupKey,
        type,
        metaData: JSON.stringify(metaData),
        fileData: file,
    }));

interface ICreateFile extends IDefaultFile {
    file: IFile;
}
export const createLocalFile = ({ file, groupKey, type, metaData, owner }: ICreateFile) => ({
    id: "",
    fileName: file.name,
    size: 0,
    created: "",
    groupKey,
    type,
    url: file.preview,
    metaData,
    bucket: null,
    owner,
});
