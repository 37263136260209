import Icon from "components/Icon";
import { useAppSelector } from "store/hooks";
import { ToastContainer } from "react-toastify";
import { getUserContext } from "store/userSelectors";

const Toast = () => {
    const userContext = useAppSelector(getUserContext);
    return (
        <ToastContainer
            position="top-center"
            hideProgressBar
            autoClose={10000}
            toastClassName={(data) =>
                `flex px-2 mb-2 hover:cursor-pointer ${
                    data?.type === "error" ? "bg-mhred-alert3 text-white" : "bg-grey-300 text-mhdarkgreen-original"
                }`
            }
            icon={({ type }) => (type === "error" ? <Icon icon="xCircle" /> : <Icon icon="checkCircle" />)}
            closeButton={({ closeToast }) => (
                <button onClick={closeToast}>
                    <Icon icon="close" size={20} />
                </button>
            )}
            style={{
                width: "fit-content",
                maxWidth: "360px",
                gap: "1px",
                marginTop: userContext.HasCustomerSupport ? "50px" : "0px",
            }}
        />
    );
};

export default Toast;
