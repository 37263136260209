import { useTranslation } from "react-i18next";
import { Config } from "config";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import {
    getUserEmployment,
    getHasAccessToEmployeeRehab,
    getStatus,
    getActiveRequiredMedicalCertificate,
    getRehab,
    getActiveRehab,
    getOngoingCases,
    getHasHealthCaseManagement,
    getHasRehabServiceContract,
    getOngoingAbsenceReports,
} from "pages/FollowUp/redux/followUpSelectors";
import { getIsAbsenceReportAdmin, getHasBaseProduct } from "store/userSelectors";
import { getCurrentCertificate } from "@medhelp/medical-certificate/redux/medicalCertificateSelectors";
import { isUndefined, isEmpty, sortBy, get, isNumber, camelCase } from "lodash/fp";
import { Status } from "@medhelp/ui/LoadableComponent";
import { getDateDisplayValue } from "utils/date";
import { certificates } from "@medhelp/medical-certificate/utils";
import EmployeeCard, { IEmployeeCard } from "./EmployeeCard";
import SecondaryButton from "components/SecondaryButton";
import Icon from "components/Icon";

const EmployeeCards = () => {
    const { t } = useTranslation(["translation", "followup"]);
    const navigate = useNavigate();
    const { routeEmploymentGuid } = useParams();
    const user = useAppSelector(getUserEmployment);
    const ongoingAbsence = useAppSelector(getOngoingAbsenceReports);
    const medicalCertificate = useAppSelector(getCurrentCertificate);
    const status = useAppSelector(getStatus);
    const activeRequiredMedicalCertificate = useAppSelector(getActiveRequiredMedicalCertificate);
    const rehab = useAppSelector(getRehab);
    const activeRehab = useAppSelector(getActiveRehab);
    const isAbsenceReportAdmin = useAppSelector(getIsAbsenceReportAdmin);
    const hasHealthCaseManagement = useAppSelector(getHasHealthCaseManagement);
    const hasRehabServiceContract = useAppSelector(getHasRehabServiceContract);
    const hasAccessToEmployeeRehab = useAppSelector(getHasAccessToEmployeeRehab);
    const ongoingCases = useAppSelector(getOngoingCases);
    const hasBaseProduct = useAppSelector(getHasBaseProduct);

    const getMedicalCertificateDegreesText = useMemo(() => {
        if (!medicalCertificate?.metaData) return "";

        const degrees = ["100%", "75%", "50%", "25%"];
        const degree = certificates.getMetadataValueByKey(
            certificates.MetadataKeys.degree,
            medicalCertificate.metaData,
        );

        return `${t("absenceDegree")}: ${degrees.includes(degree ?? "") ? degree : t("varyingAbsenceDegree")} `;
    }, [medicalCertificate?.metaData, t]);
    const Cards = useMemo(() => {
        const getAbsenceData = () => {
            if (isEmpty(ongoingAbsence)) {
                return [
                    {
                        header: t("noneOngoingAbsence", { ns: "followup" }),
                        text: "",
                    },
                ];
            } else {
                return ongoingAbsence.map((ongoing) => ({
                    header: `${t(camelCase(ongoing.type ?? ""))}, ${ongoing.currentDegree}%`,
                    text:
                        ongoing && !hasBaseProduct
                            ? `${
                                  ongoing?.backAtWork
                                      ? `${t("estimatedBack")}: ${getDateDisplayValue(
                                            ongoing?.backAtWork,
                                            "d MMM yyyy",
                                        )}`
                                      : `${t("estimatedBack")}: ${t("unknown")}`
                              }`
                            : "",
                }));
            }
        };
        const getCaseData = () => {
            if (hasHealthCaseManagement) {
                if (!hasAccessToEmployeeRehab) {
                    return [
                        {
                            header: "",
                            text: t("noAccessToCases", { ns: "followup" }),
                        },
                    ];
                } else if (isEmpty(ongoingCases)) {
                    return [
                        {
                            header: t("noneOngoingCase", { ns: "followup" }),
                            text: "",
                        },
                    ];
                } else {
                    return ongoingCases.map((ongoing) => ({
                        header: t(ongoing.type ?? "", { ns: "followup" }),
                        text: `${t("startDate")}: ${getDateDisplayValue(ongoing.start, "d MMM yyyy")}`,
                    }));
                }
            } else {
                if (!hasAccessToEmployeeRehab) {
                    return [
                        {
                            header: "",
                            text: t("noAccess", { ns: "followup" }),
                        },
                    ];
                } else {
                    return [
                        {
                            header: t("rehabProcess", {
                                ns: "followup",
                                status: activeRehab ? t("ongoing") : t("false"),
                            }),
                            text:
                                isNumber(rehab.daysInRehabChain) && rehab.daysInRehabChain !== 0
                                    ? t("rehabChain", {
                                          ns: "followup",
                                          days: rehab.daysInRehabChain,
                                      })
                                    : null,
                        },
                    ];
                }
            }
        };
        const getMedicalCertificateData = () => {
            const medicalCertificateData = [
                {
                    header: `${t("requiredFirstDayCertificate", {
                        ns: "followup",
                    })} ${
                        isEmpty(activeRequiredMedicalCertificate)
                            ? t("no", { ns: "followup" })
                            : t("yes", { ns: "followup" })
                    }`,
                    text: "",
                },
                {
                    header: medicalCertificate ? t("medicalCertificate") : "",
                    text: getMedicalCertificateDegreesText,
                },
            ];

            if (hasBaseProduct) return medicalCertificateData.slice(1, 2);

            return medicalCertificateData;
        };
        const employeeCardData: IEmployeeCard[] = sortBy(get("order"), [
            {
                data: getAbsenceData(),
                header: t("ongoingAbsence"),
                active: !isEmpty(ongoingAbsence),
                icon: "absence",
                status: status.absenceOverview,
                extra:
                    isAbsenceReportAdmin && !hasBaseProduct ? (
                        <SecondaryButton
                            id="storie-button"
                            onClick={() => {
                                navigate(`/absencereporting/${routeEmploymentGuid}`);
                            }}
                            rightIcon={<Icon size={8} icon="caretRight" className=" text-white" />}
                            className="border-pageBg text-pageBg h-7 px-2 w-32 justify-center"
                        >
                            <p className="text-pageBg text-sm">{t("handle")}</p>
                        </SecondaryButton>
                    ) : null,
                order: 1,
                id: "absence-employee-card",
            },
            {
                data: getCaseData(),
                header: t("ongoingCases", { ns: "followup" }),
                active: hasHealthCaseManagement ? !isEmpty(ongoingCases) : Boolean(activeRehab),
                status: hasAccessToEmployeeRehab ? status.rehab : Status.OK,
                icon: "heart",
                extra:
                    hasAccessToEmployeeRehab && !hasHealthCaseManagement ? (
                        <SecondaryButton
                            id="storie-button-rehab"
                            onClick={() => {
                                if (user?.employmentId) {
                                    window.location.href = `${Config.web_rehab_url}healthportal/RehabProfile/${user.employmentId}`;
                                }
                            }}
                            rightIcon={<Icon size={8} icon="caretRight" className="text-white" />}
                            className="border-pageBg text-pageBg h-7 px-2 w-32 justify-center"
                        >
                            <p className="text-pageBg text-sm">{t("handle")}</p>
                        </SecondaryButton>
                    ) : null,

                order: status.rehab === Status.ERROR ? 4 : 2,
                id: "case-employee-card",
                disabled: !hasAccessToEmployeeRehab,
            },
            {
                data: getMedicalCertificateData(),
                header: t("currentForCertificate", { ns: "followup" }),
                active: !isUndefined(medicalCertificate),
                icon: "documentAdd",
                status: status.medicalCertificate,
                extra: null,
                order: 3,
                id: "medical-certificate-employee-card",
            },
        ]);
        return employeeCardData.filter((data) => (hasRehabServiceContract ? data : data.id !== "case-employee-card"));
    }, [
        t,
        ongoingAbsence,
        status.absenceOverview,
        status.rehab,
        status.medicalCertificate,
        isAbsenceReportAdmin,
        hasHealthCaseManagement,
        ongoingCases,
        activeRehab,
        hasAccessToEmployeeRehab,
        medicalCertificate,
        rehab.daysInRehabChain,
        activeRequiredMedicalCertificate,
        getMedicalCertificateDegreesText,
        navigate,
        routeEmploymentGuid,
        user?.employmentId,
        hasRehabServiceContract,
        hasBaseProduct,
    ]);

    return (
        <div className="flex flex-col flex-wrap md:flex-row">
            {Cards.map((props, index) => (
                <div className="border-1 border-solid border-mhbeige/20 -m-[2px] flex-1" key={`${props.id}-${index}`}>
                    <EmployeeCard {...props} />
                </div>
            ))}
        </div>
    );
};
export default EmployeeCards;
