import { ModalCommonFooter, ModalFrame } from "@medhelp/ui";
import PrimaryButton from "components/PrimaryButton";
import {
    Accordion,
    SubscriptionHeadings,
    SubscriptionOptions,
    SubscriptionTabs,
} from "pages/Administration/components";
import CreateNewSubscription from "pages/Administration/components/Subscriptions/CreateNewSubscription";
import { ISubscriptionPageProps } from "pages/Administration/types";

import { useTranslation } from "react-i18next";

const Subscriptions = ({
    tabCompProps,
    activeTab,
    userSubsOnAccordionProps,
    subsOnUserAccordionProps,
    setAccordionOpen,
    onCheckboxChange,
    modalButtonClicked,
    accordionSaveClicked,
    startDatepickerProps,
    endDatepickerProps,
    modalOpen,
    modalHeader,
    onCreateNewSubscription,
    newSubscriptonIsValid,
    deleteSubscriptionId,
    setDeleteSubscriptionId,
    newSubscriptionStateProps,
    newSubscriptionOnChange,
    currentlyEditingAccordion,
    onMissingContactKeyClicked,
}: ISubscriptionPageProps) => {
    const { t } = useTranslation("administration");
    return (
        <div id="subscription-page-container">
            <h3 className="ml-10 mt-10 mb-2">{t("subscriptions")}</h3>

            <div className="flex flex-col">
                <SubscriptionTabs {...tabCompProps} />
                <div className="pt-10 px-10 w-full flex flex-col">
                    <div className="pb-8">
                        <PrimaryButton
                            id="create-new-sub"
                            text={t("createNewSubscription")}
                            onClick={onCreateNewSubscription}
                        />
                    </div>
                </div>
                {modalOpen && (
                    <ModalFrame
                        header={<h2>{modalHeader}</h2>}
                        content={
                            deleteSubscriptionId !== undefined ? (
                                <div> {t("removeConfirmation")}</div>
                            ) : (
                                <CreateNewSubscription
                                    {...newSubscriptionStateProps}
                                    startDatepickerProps={startDatepickerProps}
                                    endDatepickerProps={endDatepickerProps}
                                    newSubscriptionOnChange={newSubscriptionOnChange}
                                    onCheckboxChange={onCheckboxChange}
                                    isSubsOnTab={activeTab === "userSubsOn"}
                                    userName={tabCompProps.selectedUser}
                                    onMissingContactKeyClicked={onMissingContactKeyClicked}
                                />
                            )
                        }
                        open={modalOpen}
                        setOpen={onCreateNewSubscription}
                        footer={
                            <ModalCommonFooter
                                tertiaryText={t("abort")}
                                tertiaryOnClick={onCreateNewSubscription}
                                primaryOnClick={modalButtonClicked}
                                primaryText={deleteSubscriptionId !== undefined ? t("remove") : t("save")}
                                primaryDisabled={deleteSubscriptionId !== undefined ? false : !newSubscriptonIsValid}
                                isLoading={currentlyEditingAccordion.saveIsLoading}
                            />
                        }
                    />
                )}
                <SubscriptionHeadings
                    subscriptions={
                        activeTab === "userSubsOn" ? userSubsOnAccordionProps.length : subsOnUserAccordionProps.length
                    }
                    user={tabCompProps.selectedUser}
                    activeTab={activeTab}
                />
                {activeTab === "userSubsOn"
                    ? userSubsOnAccordionProps?.map((accordion, i) => (
                          <Accordion
                              key={`${accordion.subscriptionId}-${i}`}
                              {...accordion}
                              setOpen={() => setAccordionOpen(accordion)}
                              deleteClicked={() => setDeleteSubscriptionId(accordion.subscriptionId)}
                              savelicked={accordionSaveClicked}
                          >
                              <SubscriptionOptions
                                  {...accordion.subscriptionOptionProps}
                                  startDatepickerProps={startDatepickerProps}
                                  endDatepickerProps={endDatepickerProps}
                                  onCheckboxChange={onCheckboxChange}
                                  onMissingContactKeyClicked={onMissingContactKeyClicked}
                              />
                          </Accordion>
                      ))
                    : subsOnUserAccordionProps?.map((accordion, i) => (
                          <Accordion
                              key={`${accordion.subscriptionName}-${i}`}
                              {...accordion}
                              setOpen={() => setAccordionOpen(accordion)}
                              deleteClicked={() => setDeleteSubscriptionId(accordion.subscriptionId)}
                              savelicked={accordionSaveClicked}
                              isSubsOnUserTab
                          >
                              <SubscriptionOptions
                                  {...accordion.subscriptionOptionProps}
                                  startDatepickerProps={startDatepickerProps}
                                  endDatepickerProps={endDatepickerProps}
                                  onCheckboxChange={onCheckboxChange}
                                  onMissingContactKeyClicked={onMissingContactKeyClicked}
                                  isSubsOnUser
                              />
                          </Accordion>
                      ))}
            </div>
        </div>
    );
};

export default Subscriptions;
