import { Custom500 } from "pages/500/500";
import { SpinnerPage } from "components/Spinner";
import UploadError from "../UploadError";
import { selectors, FileStatus } from "../../redux";
import { useAppSelector } from "store/hooks";

interface IProps {
    children: React.ReactNode;
}
const LoadMedicalCertificate = ({ children }: IProps) => {
    const fileStatus = useAppSelector(selectors.medicalCertificateFileStatus);
    if (fileStatus === FileStatus.pending) {
        return <SpinnerPage />;
    }
    if (fileStatus === FileStatus.error) {
        return <UploadError />;
    }
    if (fileStatus === FileStatus.errorLoading) {
        return <Custom500 />;
    }
    return <>{children}</>;
};
export default LoadMedicalCertificate;
