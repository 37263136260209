import { IAddUserPageProps } from "pages/Administration/types";
import { ContactInfo, CountryAndLang, Period, PersonalInfo } from "pages/Administration/components";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";

const User = ({
    countryAndLangCompProps,
    personaInfoCompProps,
    periodCompProps,
    contactCompProps,
    handleSubmit,
    saveButtonDisabled,
    saveButtonLoading,
}: IAddUserPageProps) => {
    const { t } = useTranslation("administration");

    return (
        <div id="add-user-page-container" className="flex flex-col">
            <CountryAndLang {...countryAndLangCompProps} />
            <PersonalInfo {...personaInfoCompProps} />
            <Period {...periodCompProps} />
            <ContactInfo {...contactCompProps} />
            <div className="p-10 pb-0">
                <PrimaryButton
                    id="add-user-save"
                    text={t("save")}
                    onClick={handleSubmit}
                    disabled={saveButtonDisabled}
                    isLoading={saveButtonLoading}
                    rightIcon={<Icon icon="caretRight" size={14} />}
                />
            </div>
        </div>
    );
};

export default User;
