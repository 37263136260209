import {
    getAbsenceSummaryDetails,
    getAbsenceSummaryDetailsStatus,
    getCompanyGroupForCompany,
} from "pages/dashboard/redux/dashboardSelectors";
import { useEffect } from "react";
import Icon from "components/Icon";
import { toLower } from "lodash/fp";
import { useNavigate } from "react-router-dom";
import { actions } from "pages/dashboard/redux";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserContext } from "store/userSelectors";
import { SpinnerWithOverlay } from "components/Spinner";
import { Trans, useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import DepartmentContainer from "./components/DepartmentContainer";
import VariedAbsenceDegrees from "./components/VariedAbsenceDegrees";
import { getDateDisplayValue, isBeforeToday, isSameOrBeforeToday } from "utils/date";
import { Table, TableCell, TableRow } from "@medhelp/ui";
import { fetchAbsenceSummaryDetails } from "pages/dashboard/redux/dashboardActions";
import { useSortableData } from "./hooks/useSortableData";
import { filterCompaniesByAccessRights } from "pages/dashboard/utils/dashboardAccessRights";
import { MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod } from "swagger/absencefollowup";

interface IEmployeeSummaryTable {
    keyName: string;
    filter?: string[];
}

const EmployeeSummaryTable = ({ keyName, filter }: IEmployeeSummaryTable) => {
    const { t } = useTranslation("dashboard");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const companies = useAppSelector(getCompanyGroupForCompany);
    const userContext = useAppSelector(getUserContext);
    const data = useAppSelector(getAbsenceSummaryDetails);
    const status = useAppSelector(getAbsenceSummaryDetailsStatus);

    useEffect(() => {
        const filteredCompaniesByAccessRight = filterCompaniesByAccessRights(
            userContext.userAccount?.accessRights || [],
            companies ?? [],
            "absenceWidget",
        );
        dispatch(actions.resetSummaryDetails());
        dispatch(
            fetchAbsenceSummaryDetails({
                keyName: keyName,
                companyIds: filteredCompaniesByAccessRight.map(({ referenceKey }) => referenceKey as number),
            }),
        );
    }, [companies, dispatch, keyName, userContext.userAccount?.accessRights]);

    const filteredData = filter ? data?.filter(({ employmentId }) => filter.includes(employmentId || "")) : data;

    const [sortedData, handleSort] = useSortableData(filteredData, "startDate");

    const ongoingAbsences = filteredData?.reduce((x, y) => x + (y?.absencePeriods?.length ?? 0), 0);

    const headers = [
        { label: t("employee"), accessor: "firstName" },
        { label: t("absenceType"), accessor: "type" },
        { label: t("absenceDegree"), accessor: "absenceDegree" },
        { label: t("absenceStart"), accessor: "startDate" },
        { label: t("estimatedBack"), accessor: "backAtWork" },
    ];

    if (status === Status.PENDING) return <SpinnerWithOverlay />;

    return (
        <Table
            className="lg:p-10"
            caption={getSubHeadingText(t, keyName, ongoingAbsences || 0, filteredData?.length || 0)}
            headers={headers}
            handleSort={handleSort}
        >
            {sortedData?.map(({ employmentId, hasFollowUp, absencePeriods, firstName, lastName, departments }) => (
                <TableRow
                    key={employmentId}
                    onClick={
                        hasFollowUp && employmentId
                            ? () => navigate(`/followup/employee/${employmentId}/absence`)
                            : undefined
                    }
                >
                    <TableCell showOnMobile className="lg:border-r max-w-[260px]">
                        <div key="employee" className="flex items-center">
                            <div className="pr-2">{hasReturnedToWork(absencePeriods)}</div>
                            <div className="pr-2 text-lg lg:text-base">
                                {firstName} {lastName}
                            </div>
                        </div>
                        <DepartmentContainer
                            departments={departments?.map(({ departmentName }) => departmentName || "") ?? []}
                        />
                        <div className="pt-4 pl-4 lg:hidden">
                            {absencePeriods?.length} {toLower(t("ongoingAbsences"))}
                        </div>
                    </TableCell>
                    <TableCell className="lg:flex flex-col">
                        {absencePeriods?.map(({ absenceId, type }) => <span key={absenceId}>{t(type || "")}</span>)}
                    </TableCell>
                    <TableCell>
                        {absencePeriods?.map(({ degrees, absenceId }) =>
                            degrees && degrees?.length > 1 ? (
                                <VariedAbsenceDegrees key={absenceId} degrees={degrees} />
                            ) : (
                                <div className="ml-4" key={absenceId}>
                                    {degrees && degrees[0].degree}%
                                </div>
                            ),
                        )}
                    </TableCell>
                    <TableCell>
                        {absencePeriods?.map(({ absenceId, startDate }) => (
                            <div key={absenceId}>{startDate ? getDateDisplayValue(startDate) : "-"}</div>
                        ))}
                    </TableCell>
                    <TableCell>
                        {absencePeriods?.map(({ absenceId, backAtWork }) => (
                            <div className={isBeforeToday(backAtWork || "") ? "text-mhred-alert3" : ""} key={absenceId}>
                                {backAtWork ? getDateDisplayValue(backAtWork) : "-"}
                            </div>
                        ))}
                    </TableCell>
                </TableRow>
            ))}
        </Table>
    );
};

export default EmployeeSummaryTable;

const hasReturnedToWork = (
    absencePeriods: MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod[] | null | undefined,
) => {
    if (absencePeriods?.every(({ endDate, backAtWork }) => endDate && isSameOrBeforeToday(backAtWork ?? "")))
        return <Icon icon="ring" size={7} stroke="#81943D" />;
    return <Icon icon="dot" size={7} fill="#FF5637" />;
};

const getSubHeadingText = (t: TFunction, keyName: string, number: number, employees: number) => {
    let type;
    if (keyName.includes("back")) type = "reportedBack";
    else if (keyName.includes("ongoing")) type = "ongoingAbsences";
    else type = "startedAbsences";
    return (
        <Trans t={t} i18nKey={"summaryModalSubHeading"} values={{ number, type: t(type).toLowerCase(), employees }} />
    );
};
