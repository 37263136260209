import { useTranslation } from "react-i18next";

const HCMStatusOverviewLoader = () => {
    const { t } = useTranslation("dashboard");
    return (
        <div className="flex h-full justify-center pt-3 pb-10 animate-pulse">
            <ul className="flex flex-col w-full px-6 text-sm">
                <li className="flex justify-between items-end h-10 pb-2">
                    <p className="text-xs">{t("status")}</p>
                    <div className="flex">
                        <p className="flex justify-center text-xs w-18">{t("rehab")}</p>
                        <p className="flex justify-center text-xs w-18">{t("healthpromotion")}</p>
                    </div>
                </li>
                <li className="flex justify-between items-center h-11 border-t px-2 py-3 border-secondaryBorder">
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-30"></div>
                    </div>
                    <div className="flex justify-between">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mr-7 text-center"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mr-3 text-center"></div>
                    </div>
                </li>
                <li className="flex justify-between items-center h-11 border-t px-2 py-3 border-secondaryBorder">
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-30"></div>
                    </div>
                    <div className="flex justify-between">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mr-7 text-center"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mr-3 text-center"></div>
                    </div>
                </li>
                <li className="flex justify-between items-center h-11 border-t px-2 py-3 border-secondaryBorder">
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-30"></div>
                    </div>
                    <div className="flex justify-between">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mr-7 text-center"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mr-3 text-center"></div>
                    </div>
                </li>
                <li className="flex justify-between items-center h-11 border-t px-2 py-3 border-secondaryBorder">
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-30"></div>
                    </div>
                    <div className="flex justify-between">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mr-7 text-center"></div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-10 mr-3 text-center"></div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default HCMStatusOverviewLoader;
