import SearchTable, { IRow, ITableRowProps } from "../../components/SearchTable";

interface IPropsView {
    rows: IRow[];
    footer: JSX.Element;
    loading: boolean;
    placeholder: JSX.Element;
    RowContainer: React.FC<ITableRowProps>;
}
const MobileSearchResult = ({ rows, footer, loading, placeholder, RowContainer }: IPropsView) => (
    <SearchTable
        data={{ row: rows, column: undefined }}
        footer={footer}
        loading={loading}
        placeholder={placeholder}
        RowConatiner={RowContainer}
    />
);
export default MobileSearchResult;
