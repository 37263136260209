import { flow, isEqual, get, find } from "lodash/fp";
import { S3FileInfo } from "swagger/storage";
import { IFileData } from "../redux";

export enum MetadataKeys {
    to,
    from,
    degree,
}
/**
 * Select value from metaData based on given metdata `key`
 */
export const getMetadataValueByKey = (key: MetadataKeys, value: IFileData[] | undefined) =>
    value?.find(flow(get("name"), isEqual(MetadataKeys[key])))?.value;

export const getSpecificMetadataValueFromCertificates: (
    key: MetadataKeys,
) => (file: S3FileInfo) => string | undefined = (key: MetadataKeys) =>
    flow(get("metaData"), find(flow(get("name"), isEqual(MetadataKeys[key]))), get("value"));
