import React, { ChangeEvent, useMemo } from "react";

interface ITimepickerProps {
    id?: string;
    className?: string;
    heading?: string;
    disabled?: boolean;
    hourOnChange: (hourValue: number, isValid: boolean) => void;
    hourValue?: string | number;
    minuteOnChange: (minuteValue: number, isValid: boolean) => void;
    minuteValue?: string | number;
    mandatory?: boolean;
}

const Timepicker = (props: ITimepickerProps & { children?: React.ReactNode }) => {
    const {
        children,
        id,
        className = "",
        heading,
        disabled,
        hourOnChange,
        minuteOnChange,
        hourValue,
        minuteValue,
        mandatory,
        ...rest
    } = props;

    const getHours: string[] = useMemo(() => {
        return ["hh", ...Array(24).keys()].map((i) => i.toString());
    }, []);

    const getMinutes: string[] = useMemo(() => {
        // Only reason why we are display every minute is because a couple of few customers (mainly buss companies) requires it.
        return ["mm", ...Array(60).keys()].map((i) => i.toString());
    }, []);

    const handleOnChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        if (value === "hh" || value === "mm") {
            return;
        }
        event.target.name === "hourPicker"
            ? hourOnChange(Number(value), !!value)
            : minuteOnChange(Number(value), !!value);
    };

    return (
        <div id={id} className={`flex flex-col ${className}`} {...rest}>
            {heading && (
                <div className="flex">
                    <p className="font-bold xs:mb-4">{heading}</p>
                    {mandatory && <div className={"ml-1 text-2xl leading-6 text-mhred-alert2"}>*</div>}
                </div>
            )}
            <div className="flex">
                <select
                    name="hourPicker"
                    className={`h-12 pl-2 border whitespace-pre-wrap w-full bg-transparent text-center ${
                        disabled ? "cursor-not-allowed opacity-50 border-gray-300" : "border-mhdarkgreen-original"
                    } ${className}`}
                    value={hourValue ?? ""}
                    onChange={handleOnChange}
                    disabled={disabled}
                    data-testid="timepicker-hours"
                >
                    {getHours.map((hour) => (
                        <option className="w-auto flex" key={hour} value={hour}>
                            {hour ? `0${hour}`.slice(-2) : "hh"}
                        </option>
                    ))}
                </select>
                <select
                    name="minutePicker"
                    className={`h-12 pl-2 border whitespace-pre-wrap w-full bg-transparent text-center ${
                        disabled ? "cursor-not-allowed opacity-50 border-gray-300" : "border-mhdarkgreen-original"
                    } ${className}`}
                    value={minuteValue ?? ""}
                    onChange={handleOnChange}
                    disabled={disabled}
                    data-testid="timepicker-minutes"
                >
                    {getMinutes.map((minute) => (
                        <option className="w-auto flex" key={minute} value={minute}>
                            {minute ? `0${minute}`.slice(-2) : "mm"}
                        </option>
                    ))}
                </select>
            </div>
            {children}
        </div>
    );
};
export default Timepicker;
