import ExpandableComponent from "components/ExpandableComponent";
import { useAppendixAccessRights } from "@medhelp/medical-certificate/hooks";
import { IFileState, selectors } from "../../redux";
import Appendix from "../Appendix";
import HistoryCertificateHeader from "./HistoryCertificateHeader";
import { useAppSelector } from "store/hooks";
import { useState } from "react";

interface IProps {
    certificate: IFileState;
    index: number;
    expanded?: boolean;
}
const HistoryCertificateItem = ({ certificate, index, expanded = false }: IProps) => {
    const [isExpanded, setIsExpanded] = useState(expanded);
    const certificatesByGroupKey = useAppSelector(selectors.getCertificatesByGroupKey);
    const canShowAppendix = useAppendixAccessRights();

    if (!certificate.groupKey) return null;

    return (
        <div
            className={`w-full ${isExpanded && "bg-primaryBg"} ${
                index > 0 && "border-t-2  border-solid border-mhgrey-border"
            }`}
        >
            <ExpandableComponent
                headerView={<HistoryCertificateHeader certificate={certificate} />}
                getExpanded={setIsExpanded}
                onMount={expanded}
                lock={!canShowAppendix}
            >
                <div className="px-6 pb-8 xs:pt-10 pt-0">
                    <Appendix header="Bilagor" images={certificatesByGroupKey(certificate.groupKey)} />
                </div>
            </ExpandableComponent>
        </div>
    );
};

export default HistoryCertificateItem;
