import { useMemo } from "react";
import RadioButtonFilter, { IProps } from "./RadioButtonFilter";

export default function Index({ items, onChange, header, tags, children }: IProps) {
    const Items = useMemo(
        () =>
            items.map(({ label, referenceKey, checked }) => ({
                label,
                onChange,
                checked,
                name: referenceKey,
            })),
        [items, onChange],
    );
    return <RadioButtonFilter items={Items} header={header} body={tags} children={children} />;
}
