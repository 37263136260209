import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import PrimaryButton from "components/PrimaryButton";
import { SpinnerWithOverlay } from "components/Spinner";
import { Status } from "pages/service/redux/serviceSlice";
import { getCreatedCompanyApiResults, getStatus } from "pages/service/redux/serviceSelectors";

const CreateCompanyResults = ({ handleClick }: { handleClick: () => void }) => {
    const { t } = useTranslation("service");
    const results = useAppSelector(getCreatedCompanyApiResults);
    const status = useAppSelector(getStatus).createdCompanyApiResults;

    if (status === Status.PENDING) return <SpinnerWithOverlay />;

    return (
        <>
            {results.map((result) => (
                <div key={result.url + result.body} className="flex gap-2">
                    <div>
                        {result.error ? <span>{"\u274C"}</span> : <span className="text-green-600 co">{"\u2713"}</span>}
                    </div>
                    <div className="pb-1 text-lg">{result.url.split("/").pop()}</div>
                </div>
            ))}
            <div className="flex flex-end justify-center mt-10">
                <PrimaryButton onClick={handleClick} text={t("close")} id="onboardingSubmit" />
            </div>
        </>
    );
};

export default CreateCompanyResults;
