import Box from "./Box";
import Files from "./Files";
import { getLocalTime } from "utils/date";
import ImportSummary from "./ImportSummary";
import { useTranslation } from "react-i18next";
import DifferenceTable from "./DifferenceTable";
import { EventStateType, calculateStatus } from "../utils";
import { FileImportConfiguration, useGetQueueFinishedQuery } from "../../../store/rtk-apis/fileImport/fileImportApi";

const LastSuccessfulImport = ({ customerKey, maxAllowedChanges }: FileImportConfiguration) => {
    const { t } = useTranslation("fileImport");

    const { data, isLoading, isError } = useGetQueueFinishedQuery({ customerKey });

    if (isLoading) return <></>;
    if (isError) return <></>;
    if (!data) return <Box className="text-center">{t("noActiveFiles")}</Box>;

    const { eventState, importId, startDate, updateDate } = data;

    const status = calculateStatus(eventState as EventStateType);

    return (
        <>
            <ImportSummary
                customerKey={customerKey}
                importId={importId}
                status={status}
                importStart={startDate ? getLocalTime(startDate) : "-"}
                importEnd={updateDate ? getLocalTime(updateDate) : "-"}
            >
                <>
                    {importId && (
                        <DifferenceTable
                            customerKey={customerKey}
                            importId={importId}
                            maxAllowedChanges={maxAllowedChanges}
                        />
                    )}
                </>
            </ImportSummary>

            {importId && <Files customerKey={customerKey} importId={importId} />}
        </>
    );
};

export default LastSuccessfulImport;
