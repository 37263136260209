import { TFunction } from "i18next";
import Icon, { Icons } from "components/Icon";

export enum NotificationTypes {
    ReportedSick = 0,
    EditedSickReport = 1,
    ReportedRecovery = 2,
    ReportedVAB = 3,
    EditedVABReport = 4,
    ReportedVABRecovery = 5,
    AbsenceReminder = 6,
    DeviantAbsenceSickInShortPeriods = 7,
    DeviantAbsenceLongerThenXDays = 8,
    DeviantAbsenceXDaysInYMonths = 16,
    RehabProcessPlannedActivityReminder = 9,
    AbsenceReportedToSocialInsurrance = 10,
    MedicalCertificateMessageReminder = 11,
    RehabProcessInitiated = 12,
    MedicalCertificate = 13,
    RetroActivelyEditedAbsence = 14,
    ChangedUserAccessRights = 15,
    RehabProcessClosingReminder = 17,
    FileReadyForDownload = 18,
    DocumentUploaded = 19,
    ReportHealthyReminder = 20,
    MedicalCertificateUploaded = 21,
    MedicalCertificateExpired = 22,
    HealthCaseRehab = 23,
    HealthCaseHealthPromotion = 24,
    HealthCaseRehabReminder = 25,
    HealthCaseHealthPromotionReminder = 26,
    HealthCaseActivityReminder = 27,
    HealthCaseClosingReminder = 28,
}

const notificationIcons: {
    [key in NotificationTypes]: Icons;
} = {
    [NotificationTypes.ReportedSick]: "default",
    [NotificationTypes.EditedSickReport]: "default",
    [NotificationTypes.ReportedRecovery]: "default",
    [NotificationTypes.ReportedVAB]: "default",
    [NotificationTypes.EditedVABReport]: "default",
    [NotificationTypes.ReportedVABRecovery]: "default",
    [NotificationTypes.AbsenceReminder]: "default",
    [NotificationTypes.DeviantAbsenceSickInShortPeriods]: "default",
    [NotificationTypes.DeviantAbsenceLongerThenXDays]: "default",
    [NotificationTypes.DeviantAbsenceXDaysInYMonths]: "default",
    [NotificationTypes.RehabProcessPlannedActivityReminder]: "heart",
    [NotificationTypes.AbsenceReportedToSocialInsurrance]: "default",
    [NotificationTypes.MedicalCertificateMessageReminder]: "documentAdd",
    [NotificationTypes.RehabProcessInitiated]: "heart",
    [NotificationTypes.MedicalCertificate]: "documentAdd",
    [NotificationTypes.RetroActivelyEditedAbsence]: "default",
    [NotificationTypes.ChangedUserAccessRights]: "adjustments",
    [NotificationTypes.RehabProcessClosingReminder]: "heart",
    [NotificationTypes.FileReadyForDownload]: "document",
    [NotificationTypes.DocumentUploaded]: "documentAdd",
    [NotificationTypes.ReportHealthyReminder]: "default",
    [NotificationTypes.MedicalCertificateUploaded]: "documentAdd",
    [NotificationTypes.MedicalCertificateExpired]: "documentAdd",
    [NotificationTypes.HealthCaseRehab]: "heart",
    [NotificationTypes.HealthCaseHealthPromotion]: "heart",
    [NotificationTypes.HealthCaseRehabReminder]: "heart",
    [NotificationTypes.HealthCaseHealthPromotionReminder]: "heart",
    [NotificationTypes.HealthCaseActivityReminder]: "heart",
    [NotificationTypes.HealthCaseClosingReminder]: "heart",
};

export const getNotificationTypeValue = (key: string | undefined | null) => {
    if (!key) return undefined;
    const enumKey = key as keyof typeof NotificationTypes;
    return NotificationTypes[enumKey];
};

export const getIconForNotificationType = (key: string | undefined | null) => {
    const value = getNotificationTypeValue(key);
    return <Icon icon={value ? notificationIcons[value] : "default"} size={24} />;
};

const getFormat = (notificationType: number, eventCode: string, t: TFunction) => {
    if (eventCode.includes("/")) {
        const [times, months] = eventCode.split("/");
        return t(NotificationTypes[notificationType], {
            times: times || "",
            months: months || "",
        });
    } else {
        return t(NotificationTypes[notificationType], {
            eventCode: eventCode,
        });
    }
};

export const getNotificationTypeTranslation = (
    notificationType: number | undefined,
    eventCode: string,
    t: TFunction,
): string => {
    if (notificationType && !Object.values(NotificationTypes).includes(notificationType)) {
        console.error("Invalid notification type:", notificationType);
        return "";
    }
    switch (notificationType) {
        case NotificationTypes.DeviantAbsenceLongerThenXDays:
        case NotificationTypes.RehabProcessPlannedActivityReminder:
        case NotificationTypes.RehabProcessInitiated:
            return getFormat(notificationType, eventCode ? eventCode : "x", t);
        case NotificationTypes.DeviantAbsenceSickInShortPeriods:
        case NotificationTypes.DeviantAbsenceXDaysInYMonths:
            return getFormat(notificationType, eventCode ? eventCode : "x/y", t);
        default:
            return notificationType !== undefined // Specifically checking against undefined due to NotificationTypes enum containing 0, which is falsy
                ? t(NotificationTypes[notificationType!])
                : "";
    }
};
