import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServices } from "api/services";
import {
    CompanyDataErasureAvailableConfiguration,
    CompanyDataErasureConfigurationPostModel,
    CompanyFilter,
    CompanyUser,
    CostCenter,
    CreateOrUpdateAccessRights,
    CreateOrUpdateEmployment,
    CreateOrUpdateUserAccount,
    EmploymentGroup,
    LoginCredentials,
    SearchCompanyGroupUserBySsn,
} from "swagger/customercompany";
import {
    CompanyAccessRightsRecursive,
    IRoleSubscriptionsSettingsData,
    ILoginCredentialsExtended,
    IPostCompanyConfigModel,
    ICompanyErasureConfig,
    ILoginsData,
    ISubscriptionData,
    ICompanyUserFromAccountEditModel,
    ICreateOrUpdateUserAccountExtended,
} from "../types";
import { RootState } from "store";
import { getSelectedCompanyUserUserAccountId } from "./administrationSelectors";
import {
    EventSubscriptionPostModel,
    RemoveSubscriptions,
    SearchUserAndDepartmentToAddModel,
    SubscriberTableItem,
    SubscriptionInformation,
    SubscriptionLookup,
    SubscriptionLookupType,
    SubscriptionTableItem,
    SubscriptionType,
    TreeViewNode,
} from "swagger/employeeattendance";
import { AxiosPromise } from "axios";
import { CompanySubscriptionConfiguration, RoleSubscriptionConfigration } from "swagger/messenger";
import { LoginConfiguration } from "swagger/businessupport";

export const getCompaniesForUser = createAsyncThunk(
    "administrationGetCompaniesForUser",
    async (model: CompanyFilter) => {
        const { data } = await getServices().clients.customerCompany.apiCustomerCompanyGetCompaniesForUserPost(model);

        return data;
    },
);

export const searchCompanyGroupUserBySsn = createAsyncThunk(
    "administrationSearchCompanyGroupUserBySsn",
    async (model: SearchCompanyGroupUserBySsn) => {
        const { data } = await getServices().clients.customerUserSupport.apiUserSearchCompanyGroupUserBySsnPost(model);

        return data;
    },
);

export const getCompanyUserFromAccountEdit = createAsyncThunk(
    "administrationGetCompanyUserFromAccountEdit",
    async (model: ICompanyUserFromAccountEditModel) => {
        const { data } = await getServices().clients.customerUserSupport.apiUserGetCompanyUserFromUserAccountIdEditGet(
            model.userAccountId,
            model.companyId,
        );

        const converted = data as unknown as CompanyUser;
        return converted;
    },
);
export const getCompanyGroupRegion = createAsyncThunk(
    "administrationGetCompanyGroupRegion",
    async (companyId: number) => {
        const { data } =
            await getServices().clients.organization.apiOrganizationGetCompanyGroupWithRegionForCompanyIdGet(companyId);
        return data;
    },
);

export const createOrUpdateUserAccount = createAsyncThunk(
    "administrationCreateOrUpdateUserAccount",
    async (model: CreateOrUpdateUserAccount, thunkApi) => {
        const { status, data } = await getServices().clients.customerUserSupport.apiUserCreateUserPost(model);

        if (status === 200) {
            let converted = data as unknown as ICreateOrUpdateUserAccountExtended;
            const state = thunkApi.getState() as RootState;
            //Get and update getAllLoginConfiguration if they've been fetched
            if (state.administration.loginsData !== null && converted.type === "Success") {
                const result = await getAllLoginConfigurationPromise(converted.id);
                converted = {
                    ...converted,
                    allLoginsConfig: result.data as unknown as LoginCredentials[],
                };
            }
            return converted;
        }
        if (status === 400) {
            console.log("return errorv cb8ib");
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

// export const getEmploymentFromCompanyAndUserAccountId = createAsyncThunk(
//     "administrationGetEmploymentFromCompanyAndUserAccountId",
//     async (model: ICompanyUserFromAccountEditModel) => {
//         const { data } =
//             await getServices().clients.customerCompany.apiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdGet(
//                 model.companyId,
//                 model.userAccountId,
//             );

//         return data;
//     },
// );

const getOrganizationTreeViewPromise = (companyId: number) =>
    getServices().clients.customerCompany.apiCustomerCompanyGetOrganizationTreeViewGet(companyId);

export const getEmploymentData = createAsyncThunk(
    "administrationGetEmploymentData",
    async (
        model: {
            companyId: number;
            selectedCompanyUserPrimaryCompanyId: number;
            selectedUserAccountId: number;
        },
        thunkApi,
    ) => {
        const getEmploymentGroupsPromise =
            getServices().clients.customerCompany.apiCustomerCompanyGetEmploymentGroupsGet(model.companyId);

        const getCostCentersForCompanyFastPromise =
            getServices().clients.customerCompany.apiCustomerCompanyGetCostCentersForCompanyFastGet(model.companyId);
        const getEmploymentFromCompanyAndUserAccountIdPromise =
            getServices().clients.customerCompany.apiCustomerCompanyGetEmploymentFromCompanyAndUserAccountIdGet(
                model.selectedCompanyUserPrimaryCompanyId,
                model.selectedUserAccountId,
            );

        const employmentPromises: AxiosPromise[] = [
            getEmploymentGroupsPromise,
            getCostCentersForCompanyFastPromise,
            getEmploymentFromCompanyAndUserAccountIdPromise,
            getOrganizationTreeViewPromise(model.companyId),
        ];

        const result = await Promise.allSettled(employmentPromises).then((results) =>
            results.map((result) => {
                if (result.status === "rejected") {
                    return thunkApi.rejectWithValue({
                        status: result.status,
                        url: result.reason.request.responseURL,
                        body: result.reason.config.data,
                        error: result.reason.request.status + " " + result.reason.response.data,
                    });
                } else return result.value.data;
            }),
        );
        const employmentData = {
            employmentData: {
                companyEmploymentGroups: result[0] as EmploymentGroup[],
                companyCostCenters: result[1] as CostCenter[],
                employment: result[2] as CreateOrUpdateEmployment,
            },
            organizationTreeView: result[3] as TreeViewNode[],
        };
        return employmentData;
    },
);

export const searchCompanyGroupUserByName = createAsyncThunk(
    "administrationSearchCompanyGroupUserByName",
    async (model: { companyId: number; searchString: string }, thunkApi) => {
        const { status, data } =
            await getServices().clients.customerUserSupport.apiUserSearchCompanyGroupUsersByNameGet(
                model.companyId,
                model.searchString,
            );

        if (status === 200 || status === 204) {
            return data as unknown as CompanyUser[];
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

export const createOrUpdateEmployment = createAsyncThunk(
    "administrationCreateOrUpdateEmployment",
    async (createEmploymentModel: CreateOrUpdateEmployment, thunkApi) => {
        const { status, data } =
            await getServices().clients.customerUserSupport.apiUserCreateEmploymentPost(createEmploymentModel);

        if (status === 200) {
            return data as unknown as number;
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

const getSubscriberPromise = (companyId: number, userAccountId: number) =>
    getServices().clients.subscribeEmployment.apiSubscribersGetSubscriberTableItemsByCompanyAndUserAccountGet(
        companyId,
        userAccountId,
    );
const getSubscriptionPromise = (employmentId: number, lookupType: SubscriptionLookupType) =>
    getServices().clients.subscribeEmployment.apiSubscribersGetSubscriptionTableItemsGet(employmentId, lookupType);

const getTableItemPromise = (tableModel: {
    companyId: number;
    userAccountId: number;
    employmentId: number;
    lookupType: SubscriptionLookupType;
}) => [
    getSubscriberPromise(tableModel.companyId, tableModel.userAccountId),
    getSubscriptionPromise(tableModel.employmentId, tableModel.lookupType),
];

export const searchUserAndCompanyToAdd = createAsyncThunk(
    "administrationSearchUserAndCompanyToAdd",
    async (searchUserAndCompanyModel: SearchUserAndDepartmentToAddModel) => {
        const { data } =
            await getServices().clients.subscribeEmployment.apiSubscribersSearchUserAndCompanyToAddPost(
                searchUserAndCompanyModel,
            );
        return data;
    },
);

export const getSubscriptionData = createAsyncThunk(
    "administrationGetSubscriptionData",
    async (
        model: {
            companyId: number;
            userAccountId: number;
            employmentId: number;
            lookupType: SubscriptionLookupType;
        },
        thunkApi,
    ) => {
        const tableItemsPromise = getTableItemPromise({
            ...model,
        });

        const defaultSubscriptionsPromise =
            getServices().clients.subscribeEmployment.apiSubscribersGetDefaultSubscriptionTypesGet(model.companyId);
        const subscriptionDataPromises = [...tableItemsPromise, defaultSubscriptionsPromise];
        const result = await Promise.allSettled(subscriptionDataPromises).then((results) =>
            results.map((result) => {
                if (result.status === "rejected") {
                    return thunkApi.rejectWithValue({
                        status: result.status,
                        url: result.reason.request.responseURL,
                        body: result.reason.config.data,
                        error: result.reason.request.status + " " + result.reason.response.data,
                    });
                } else return result.value.data;
            }),
        );
        const subscriptionData: ISubscriptionData = {
            userSubsOnTableItems: result[0] as SubscriberTableItem[],
            subsOnUserTableItems: result[1] as SubscriptionTableItem[],
            defaultSubscriptionTypes: result[2] as SubscriptionType[],
            searchData: undefined,
        };
        return subscriptionData;
    },
);

export const createOrUpdateEventSubscription = createAsyncThunk(
    "administrationCreateOrUpdateEventSubscription",
    async (eventSubscriptionModel: EventSubscriptionPostModel) => {
        const { data } =
            await getServices().clients.subscribeEmployment.apiSubscribersCreateOrUpdateEventSubscriptionPost(
                eventSubscriptionModel,
            );
        //@ts-expect-error
        return (data?.items as unknown as SubscriptionInformation[]) ?? [];
    },
);
export const getSubscriptionTableItems = createAsyncThunk(
    "administrationGetSubscriptionTableItems",
    async (
        model: {
            companyId: number;
            userAccountId: number;
            employmentId: number;
            lookupType: SubscriptionLookupType;
        },
        thunkApi,
    ) => {
        const tableItemsPromise = getTableItemPromise({
            ...model,
        });
        const result = await Promise.allSettled(tableItemsPromise).then((results) =>
            results.map((result) => {
                if (result.status === "rejected") {
                    return thunkApi.rejectWithValue({
                        status: result.status,
                        url: result.reason.request.responseURL,
                        body: result.reason.config.data,
                        error: result.reason.request.status + " " + result.reason.response.data,
                    });
                } else return result.value.data;
            }),
        );
        const tableItemsData = {
            userSubsOnTableItems: result[0] as SubscriberTableItem[],
            subsOnUserTableItems: result[1] as SubscriptionTableItem[],
        };
        return tableItemsData;
    },
);

export const removeSubscription = createAsyncThunk(
    "administrationRemoveSubscription",
    async (postModel: RemoveSubscriptions, thunkApi) => {
        const { data } =
            await getServices().clients.subscribeEmployment.apiSubscribersRemoveSubscriptionsPost(postModel);
        if ((data as unknown as boolean) === true) {
            const adminState = (thunkApi.getState() as RootState).administration;

            const { data } =
                adminState.subscriptionsProps.activeTab === "userSubsOn"
                    ? await getSubscriberPromise(
                          adminState.selectedCompanyId ?? -1,
                          adminState.selectedUserAccountId ?? -1,
                      )
                    : await getSubscriptionPromise(adminState.selectedCompanyUser?.employmentId ?? -1, 1);
            return data;
        }
        return [];
    },
);

export const getSubscriberTableItemsByCompanyAndUserAccount = createAsyncThunk(
    "administrationGetSubscriberTableItemsByCompanyAndUserAccount",
    async (getModel: { companyId: number; userAccountId: number }) => {
        const { data } = await getSubscriberPromise(getModel.companyId, getModel.userAccountId);
        return data;
    },
);
export const getOrganizationTreeView = createAsyncThunk(
    "administrationGetOrganizationTreeView",
    async (companyId: number) => {
        const { data } = await getOrganizationTreeViewPromise(companyId);
        return data;
    },
);

export const checkExternalSubscription = createAsyncThunk(
    "administrationCheckExternalSubscription",
    async (model: { companyId: number; searchString: string }) => {
        const { data } = await getServices().clients.subscribeEmployment.apiSubscribersCheckExternalSubscriptionGet(
            model.companyId,
            model.searchString,
        );
        return data as unknown as SubscriptionLookup;
    },
);

export const getCompanyUserInCompanyGroup = createAsyncThunk(
    "administrationGetCompanyUserInCompanyGroup",
    async (model: { userAccountId: number; companyId: number }) => {
        const { data } =
            await getServices().clients.customerUserSupport.apiUserGetCompanyUserFromUserAccountIdInCompanyGroupGet(
                model.userAccountId,
                model.companyId,
            );
        return data as unknown as CompanyUser;
    },
);

export const getPermissionData = createAsyncThunk(
    "administrationGetPermissionData",
    async (model: { userAccountId: number; companyId: number }, thunkApi) => {
        const getAccessRightsForCompanyGroupPromise =
            getServices().clients.customerUserSupport.apiUserGetAccessRightsForCompanyGroupGet(
                model.userAccountId,
                model.companyId,
            );
        const getCompanyRecursiveAccessRightsPromise =
            getServices().clients.customerCompany.apiCustomerCompanyGetCompanyRecursiveAccessRightsGet(model.companyId);
        const getOrganizationTreeViewPromise =
            getServices().clients.customerCompany.apiCustomerCompanyGetOrganizationTreeViewGet(model.companyId);
        const permissionPromises: AxiosPromise[] = [
            getAccessRightsForCompanyGroupPromise,
            getCompanyRecursiveAccessRightsPromise,
            getOrganizationTreeViewPromise,
        ];
        const result = await Promise.allSettled(permissionPromises).then((results) =>
            results.map((result) => {
                if (result.status === "rejected") {
                    return thunkApi.rejectWithValue({
                        status: result.status,
                        url: result.reason.request.responseURL,
                        body: result.reason.config.data,
                        error: result.reason.request.status + " " + result.reason.response.data,
                    });
                } else return result.value.data;
            }),
        );
        const permissionData = {
            companyAccessRights: result[0] as CreateOrUpdateAccessRights[],
            companyAccessRightsRecursive: result[1] as CompanyAccessRightsRecursive,
            organizationTreeView: result[2] as TreeViewNode[],
        };
        return permissionData;
    },
);

export const createAccessRights = createAsyncThunk(
    "administrationCreateAccessRights",
    async (createModel: CreateOrUpdateAccessRights) => {
        const { data } = await getServices().clients.customerUserSupport.apiUserCreateAccessRightsPost(createModel);
        return data as unknown as number;
    },
);

export const getAllLoginConfigurationPromise = (userAccountId: number) =>
    getServices().clients.customerUserSupport.apiUserGetAllLoginCredentialsGet(userAccountId);

export const getLoginsData = createAsyncThunk(
    "administrationGetLoginsData",
    async (model: { userAccountId: number; companyId: number }, thunkApi) => {
        const getLoginConfigurationPromise = getServices().clients.user.getLoginConfiguration(model.companyId);

        const loginsPromises = [getAllLoginConfigurationPromise(model.userAccountId), getLoginConfigurationPromise];
        const result = await Promise.allSettled(loginsPromises).then((results) =>
            results.map((result) => {
                if (result.status === "rejected") {
                    return thunkApi.rejectWithValue({
                        status: result.status,
                        url: result.reason.request.responseURL,
                        body: result.reason.config.data,
                        error: `${result.reason.request.status} ${result.reason.response.data}`,
                    });
                } else return result.value.data;
            }),
        );
        const loginsData: ILoginsData = {
            allLoginsConfig: result[0] as LoginCredentials[],
            loginConfig: result[1] as LoginConfiguration,
        };
        return loginsData;
    },
);

export const saveLoginConfiguration = createAsyncThunk(
    "administrationSaveLoginConfiguration",
    async (loginCredentials: LoginCredentials, thunkApi) => {
        const { data, status } =
            await getServices().clients.customerUserSupport.apiUserSaveLoginCredentialPost(loginCredentials);

        if (status === 200) {
            //@ts-expect-error
            const { success, duplicate } = data;
            let loginCredentialExtended: ILoginCredentialsExtended = {
                success: success,
                duplicate: duplicate,
                inputCredential: loginCredentials,
                loginCredentials: [],
            };
            if (success) {
                const { data, status } =
                    await getServices().clients.customerUserSupport.apiUserGetAllLoginCredentialsGet(
                        loginCredentials.userAccountId ??
                            getSelectedCompanyUserUserAccountId(thunkApi.getState() as RootState),
                    );
                if (status === 200) {
                    loginCredentialExtended.loginCredentials = data as unknown as LoginCredentials[];
                }
            }
            return loginCredentialExtended;
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

export const deleteLoginCredential = createAsyncThunk(
    "administrationDeleteLoginCredential",
    async (loginCredentials: LoginCredentials, thunkApi) => {
        const { status } =
            await getServices().clients.customerUserSupport.apiUserDeleteLoginCredentialPost(loginCredentials);
        if (status === 200) {
            const { data, status } = await getServices().clients.customerUserSupport.apiUserGetAllLoginCredentialsGet(
                loginCredentials.userAccountId ?? -1,
            );
            if (status === 200) {
                const converted = data as unknown as LoginCredentials[];
                return converted;
            }
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

export const getCompanyDataErasureFinishedConfiguration = createAsyncThunk(
    "administrationGetCompanyDataErasureFinishedConfiguration",
    async (companyId: number) => {
        const { data } =
            await getServices().clients.customerCompany.apiCustomerCompanyGetCompanyDataErasureConfigurationGet(
                companyId,
            );
        return data as unknown as ICompanyErasureConfig;
    },
);
export const postCompanyDataErasureFinishedConfiguration = createAsyncThunk(
    "administrationPostCompanyDataErasureFinishedConfiguration",
    async (configPostModel: CompanyDataErasureConfigurationPostModel) => {
        const { data } =
            await getServices().clients.customerCompany.apiCustomerCompanySaveCompanyDataErasureConfigurationPost(
                configPostModel,
            );
        return data as unknown as ICompanyErasureConfig;
    },
);

export const getCompanyDataErasureActiveConfiguration = createAsyncThunk(
    "administrationGetCompanyDataErasureActiveConfiguration",
    async (companyId: number) => {
        const { data } =
            await getServices().clients.dataAnonymization.apiDataAnonymizationGetCompanyDataErasureAvailableConfigurationGet(
                companyId,
            );
        return data;
    },
);
export const postCompanyDataErasureActiveConfiguration = createAsyncThunk(
    "administrationPostCompanyDataErasureActiveConfiguration",
    async (postModel: CompanyDataErasureAvailableConfiguration) => {
        const { data } =
            await getServices().clients.dataAnonymization.apiDataAnonymizationSaveCompanyDataErasureAvailableConfigurationPost(
                postModel,
            );
        return data;
    },
);

export const getRoleSubscriptionSettingsData = createAsyncThunk(
    "administrationGetRoleSubscriptionSettingsData",
    async (companyId: number, thunkApi) => {
        const availableNotificationsPromise =
            getServices().clients.configuration.apiV1ConfigurationCompanyCompanyIdGet(companyId);
        const selectedRolesPromise =
            getServices().clients.configuration.apiV1ConfigurationCompanyCompanyIdRolesGet(companyId);

        const promises = [availableNotificationsPromise, selectedRolesPromise];
        const result = await Promise.allSettled(promises).then((results) =>
            results.map((result) => {
                if (result.status === "rejected") {
                    return thunkApi.rejectWithValue({
                        status: result.status,
                        url: result.reason.request.responseURL,
                        body: result.reason.config.data,
                        error: `${result.reason.request.status} ${result.reason.response.data}`,
                    });
                } else return result.value.data;
            }),
        );
        const roleSubscriptionSettingsData: IRoleSubscriptionsSettingsData = {
            availableNotifications: result[0] as CompanySubscriptionConfiguration[],
            selectedRoles: result[1] as RoleSubscriptionConfigration[],
        };
        return roleSubscriptionSettingsData;
    },
);

export const saveRoleSubscriptionSettings = createAsyncThunk(
    "administrationSaveRoleSubscriptionSettings",
    async (model: IPostCompanyConfigModel) => {
        const { data } = await getServices().clients.configuration.apiV1ConfigurationCompanyCompanyIdRolesPost(
            model.companyId,
            model.subscriptionConfig,
        );
        return data;
    },
);
