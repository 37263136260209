import { useTranslation } from "react-i18next";
import { useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "store/hooks";
import { thunkActions } from "../../../redux";
import { IHealthCase, IHealthCaseDocument } from "pages/FollowUp/redux/followUpStateTypes";
import { getDateTimeDisplayValue } from "utils/date";
import { activeCaseTypes } from "../../../utils/case";
import { Status } from "@medhelp/ui/LoadableComponent";
import { toast } from "react-toastify";
import { CaseNoteModal } from "..";
import Icon, { Icons } from "components/Icon";
import CaseNotesLoading from "pages/FollowUp/pages/Employee/Case/CaseNotes/CaseNotesLoading";
import MiniMenu from "components/MiniMenu/MiniMenu";

export interface INoteActionValues {
    text: string;
    onClick: () => void;
    icon: Icons;
    key: string;
}

interface IProps {
    note: IHealthCaseDocument;
    healthCase: IHealthCase;
}

const CaseNoteItem = (props: IProps) => {
    const { healthCase, note } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const noteActionValues: INoteActionValues[] = [
        {
            text: t("editNote"),
            onClick: () => setModalIsOpen(true),
            icon: "edit",
            key: `case-note-item-edit-${note?.id}`,
        },
        {
            text: t("deleteNote"),
            onClick: () => {
                if (note.id) {
                    dispatch(
                        thunkActions.deleteCaseDocument({
                            healthCaseId: healthCase?.id ?? "",
                            documentId: note.id,
                        }),
                    )
                        .then(unwrapResult)
                        .then(() => toast(t("noteRemoved")))
                        .catch((e) =>
                            toast(
                                `${e?.response?.status ? `${e.response.status} - ` : ""}${t("noteNotRemoved")}`,

                                {
                                    type: "error",
                                },
                            ),
                        );
                }
            },
            icon: "trashCan",
            key: `case-note-item-delete-${note?.id}`,
        },
    ];

    const getCreatedEditedDateText = () => {
        if (note.updated) {
            return `${`${t("editedBy")} ${
                note.createdByUserName || t("createdByUnknown").toLowerCase()
            }`} ${getDateTimeDisplayValue(note.updated, "d MMM yyyy, HH:mm")}`;
        } else {
            return `${`${t("addedBy")} ${
                note.createdByUserName || t("createdByUnknown").toLowerCase()
            }`} ${getDateTimeDisplayValue(note.created, "d MMM yyyy, HH:mm")}`;
        }
    };

    if (note.status === Status.PENDING) {
        return <CaseNotesLoading />;
    }

    const multilineDescription = note.description?.split("\n");

    return (
        <div className="flex justify-between items-start border-t border-grey-300 py-3 px-5 sm:px-8">
            <div className="flex">
                <div className="mr-3 sm:mr-8 mt-3">
                    <Icon icon="notes" size={25} strokeWidth={1.5} />
                </div>
                <div>
                    <p className="text-xs mt-1">{getCreatedEditedDateText()}</p>
                    <div className="flex">
                        <button className="self-center mr-1" onClick={() => setOpen(!open)}>
                            <Icon icon={open ? "caretFullDown" : "caretFullRight"} size={12} />
                        </button>
                        <p className="font-bold">{note.title}</p>
                    </div>
                    {open &&
                        multilineDescription?.map((line, index) => {
                            if (!line) {
                                return <br key={index} />;
                            }
                            return (
                                <p className="ml-4" key={index}>
                                    {line}
                                </p>
                            );
                        })}
                </div>
            </div>
            <div className="flex items-center">
                {healthCase.status && activeCaseTypes.includes(healthCase.status) && (
                    <MiniMenu
                        id="note-actions"
                        preset="white"
                        buttonValues={noteActionValues}
                        buttonRight
                        containerClassName="flex justify-end"
                    />
                )}
            </div>
            {modalIsOpen && (
                <CaseNoteModal
                    isEdit
                    healthCase={healthCase}
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={() => setModalIsOpen(!modalIsOpen)}
                    note={note}
                />
            )}
        </div>
    );
};

export default CaseNoteItem;
