interface IProps {
    header: string;
    body: string[];
}
const SubscribeCell = ({ header, body }: IProps) => (
    <div className="flex flex-col">
        <div className="flex gap-2">
            <p className="font-bold text-sm truncate">{header}</p>
        </div>
        {body.map((text) => (
            <p className="text-sm truncate">{text}</p>
        ))}
    </div>
);
export default SubscribeCell;
