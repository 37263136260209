import Icon from "components/Icon";
import { useDetectOutsideClick } from "hooks";
import { useState, ChangeEvent } from "react";

interface ISelectWithFilter {
    placeholder: string;
    handleClick: (id: number) => void;
    options: { id: number; name: string }[];
    displayValue: string;
}

const ServiceSelectWithFilter = ({ options, placeholder, handleClick, displayValue }: ISelectWithFilter) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [inputValue, setInputValue] = useState("");

    const {
        isOutside: dropdownOpen,
        setIsOutside: setDropdownOpen,
        setContainer,
    } = useDetectOutsideClick(false, false);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        const inputValue = e.target.value;
        const filtered = options.filter((option) => option.name?.toLowerCase().includes(inputValue.toLowerCase()));
        setFilteredOptions(filtered);
    };

    return (
        <div ref={setContainer} className="w-full flex flex-col md:w-86 hover:cursor-pointer relativ z-10">
            <label className="font-bold">{placeholder} *</label>
            <div className="relative" onClick={() => setDropdownOpen(!dropdownOpen)}>
                <div className="flex items-center w-full mt-2 h-12 px-2 bg-mhbeige border border-mhdarkgreen-original">
                    {displayValue ? displayValue : placeholder}
                </div>
                <div className="absolute right-3 top-6.5">
                    <Icon className="mb-1" icon="caretDown" size={12} stroke="2" />
                </div>
            </div>
            {dropdownOpen && (
                <div className="absolute bg-pageBg mt-20">
                    <div className="flex items-center h-12 border-x p-2 w-full">
                        <Icon icon="search" />
                        <input
                            onChange={handleInputChange}
                            className="w-full pl-2 bg-pageBg search-bar"
                            type="text"
                            value={inputValue}
                        />
                    </div>
                    <div className="border max-h-64 w-full md:w-86 overflow-auto ">
                        {filteredOptions.map(({ id, name }) => (
                            <div
                                key={id}
                                className="p-2 flex items-center hover:bg-grey-100 hover:cursor-pointer"
                                onClick={() => {
                                    setDropdownOpen(false);
                                    handleClick(id);
                                }}
                            >
                                {name + " - " + id}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceSelectWithFilter;
