import { getServices } from "../api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IHistorySearchType, IPowerbarSearch } from "interfaces/ISearch";
import { RootState } from "store";
import { getActivePowerbarSearch } from "./searchSelectors";
import { searchActions } from "./searchSlice";
import { SearchViewModel } from "swagger/customercompany";

export const getLatestSearches = createAsyncThunk(
    "getLatestSearches",
    async (type: keyof IHistorySearchType, thunkApi) => {
        const { status, data } = await getServices().clients.search.localSearchGet(type);
        if (status === 200) return data;

        return thunkApi.rejectWithValue("rejected");
    },
);

export const postLatestSearch = createAsyncThunk(
    "postLatestSearch",
    async (selectedSearch: IPowerbarSearch, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const activePowerbarSearch = getActivePowerbarSearch(state);
        const isSameEmployee = selectedSearch.referenceKey === activePowerbarSearch?.referenceKey;
        const isSameCompany = selectedSearch.companyId === activePowerbarSearch?.companyId;
        if ((selectedSearch.referenceKey && !isSameEmployee) || !isSameCompany) {
            thunkApi.dispatch(searchActions.setActivePowerbarSearch(selectedSearch));
            const { status } = await getServices().clients.search.localSearchPost(
                selectedSearch.referenceKey,
                selectedSearch.typeOfSearch,
                selectedSearch,
            );
            if (status === 200) {
                return selectedSearch;
            }
            return thunkApi.rejectWithValue("rejected");
        }
    },
);

export interface ISearchModelExtended {
    searchViewModel: SearchViewModel;
    isPhoneNumber: boolean;
}
export const getPowerbarSearch = createAsyncThunk(
    "getPowerbarSearch",
    async (searchModel: ISearchModelExtended, thunkApi) => {
        if (searchModel.isPhoneNumber) {
            const { status, data } =
                await getServices().clients.customerSupport.apiCustomerSupportSearchCompanyUserByTelephoneNumberPost(
                    searchModel.searchViewModel,
                );

            if (status === 200) return data;
        } else {
            const { status, data } =
                await getServices().clients.customerSupport.apiCustomerSupportSearchEmploymentsPost(
                    searchModel.searchViewModel,
                );

            if (status === 200) return data;
        }

        return thunkApi.rejectWithValue("rejected");
    },
);
