import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { updateCaseActivityUndoPerformed as updateCaseActivityUndoPerformedAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const updateCaseActivityUndoPerformed = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(updateCaseActivityUndoPerformedAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.payload?.activityId,
                );
                if (currentActivity) {
                    currentActivity.performed = false;
                    currentActivity.performedBy = action.payload?.userAccountId;
                    currentActivity.status = Status.OK;
                }
            }
        })
        .addCase(updateCaseActivityUndoPerformedAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.meta.arg.activityId,
                );
                if (currentActivity) {
                    currentActivity.status = Status.PENDING;
                }
            }
        })
        .addCase(updateCaseActivityUndoPerformedAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.meta.arg.activityId,
                );
                if (currentActivity) {
                    currentActivity.status = Status.ERROR;
                }
            }
        });

export default updateCaseActivityUndoPerformed;
