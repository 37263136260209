import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { updateCaseResponsible as updateCaseResponsibleAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const updateCaseResponsible = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(updateCaseResponsibleAction.fulfilled, (state, action) => {
            const healthCaseToUpdate = state.healthCases.find(({ id }) => id === action.payload?.healthCaseId);
            if (healthCaseToUpdate) {
                healthCaseToUpdate.responsible = action.payload?.data.userName;
                healthCaseToUpdate.healthCaseStatus = Status.OK;
            }
        })
        .addCase(updateCaseResponsibleAction.pending, (state, action) => {
            const healthCaseToUpdate = state.healthCases.find(({ id }) => id === action.meta.arg.healthCaseId);
            if (healthCaseToUpdate) healthCaseToUpdate.healthCaseStatus = Status.PENDING;
        })
        .addCase(updateCaseResponsibleAction.rejected, (state, action) => {
            const healthCaseToUpdate = state.healthCases.find(({ id }) => id === action.meta.arg.healthCaseId);
            if (healthCaseToUpdate) healthCaseToUpdate.healthCaseStatus = Status.ERROR;
        });

export default updateCaseResponsible;
