import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { endHealthCase as endHealthCaseAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const endHealthCase = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(endHealthCaseAction.fulfilled, (state, action) => {
            const currentCase = state.healthCases.find(({ id }) => id === action.payload?.healthCaseId);
            if (currentCase) {
                currentCase.status = "ended";
                currentCase.end = action.payload?.endDate;
                currentCase.healthCaseStatus = Status.OK;
            }
            state.status.healthCases = Status.OK;
        })
        .addCase(endHealthCaseAction.pending, (state, action) => {
            const currentCase = state.healthCases.find(({ id }) => id === action.meta.arg.healthCaseId);
            if (currentCase) currentCase.healthCaseStatus = Status.PENDING;
        })
        .addCase(endHealthCaseAction.rejected, (state, action) => {
            const currentCase = state.healthCases.find(({ id }) => id === action.meta.arg.healthCaseId);
            if (currentCase) currentCase.healthCaseStatus = Status.ERROR;
        });

export default endHealthCase;
