import { useAppSelector } from "store/hooks";
import {
    getAdminIsLoading,
    getSelectedCompanyId,
    getSelectedCompanyUserUserId,
    getSelectedUserAccountId,
} from "pages/Administration/redux/administrationSelectors";
// import { useCallback, useEffect } from "react";
import { Spinner } from "components/Spinner";
import AddUser from "./AddUser";
import { getCustomerSupportAccess } from "store/userSelectors";
// import { useLocation, useNavigate } from "react-router-dom";

export default function Index() {
    // const location = useLocation();
    // const navigate = useNavigate();
    const isLoading = useAppSelector(getAdminIsLoading);
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const selectedUserAccountId = useAppSelector(getSelectedUserAccountId);
    const selectedUserUserId = useAppSelector(getSelectedCompanyUserUserId);
    const isCs = useAppSelector(getCustomerSupportAccess);
    // const route = useCallback(() => {
    //     if (!location.pathname.includes("/addUser/user"))
    //         navigate(`/administration/users/addUser/user`);
    // }, [location.pathname, navigate]);

    // useEffect(() => {
    //     if (!selectedUserAccountId) route();
    // }, [route, selectedUserAccountId]);

    return isLoading ? (
        <Spinner />
    ) : (
        <AddUser
            tabsDisabled={!selectedUserAccountId}
            companyId={selectedCompanyId ?? -1}
            userId={selectedUserUserId ?? -1}
            isCs={isCs}
        />
    );
}
