import { isArray, map, isString } from "lodash/fp";
import { MedHelpPeopleDomainWidget } from "swagger/people";
import { getServices } from "api/services";
import { IWidgetMap, widgetMap } from "../components/Widget";
import {
    IReturnValueFromWidgetTransformData,
    IWidgetDataFilter,
    isWidgetRehabKeys,
    WidgetDropdownTypeKeys,
    WidgetType,
    IWidget,
    IDynamicData,
} from "./dashboardTypes";

import { filterCompaniesByAccessRights } from "./dashboardAccessRights";
import { AccountAccessRightViewModel } from "swagger/usercontextservice";
import { EmploymentGroupContract, TreeViewNode } from "swagger/employeeattendance";
import { WidgetAccessRightType } from "../components/Widget/widgetMap";

export const recursiveMapCheckedState = (dropdownItems: IDynamicData[], key: string) => {
    return dropdownItems.map((i) => {
        const childSelect = (child: IDynamicData[], parent: boolean) => {
            child.forEach((k: IDynamicData) => {
                k.checked = parent;
                if (k.children) childSelect(k.children, parent);
            });
        };
        if (i.referenceKey === key) {
            i.checked = !i.checked;
            if (i.children) childSelect(i.children, i.checked);
        }
        if (i.children) {
            i.children = recursiveMapCheckedState(i.children, key);
        }
        return i;
    });
};

export const setCheckedDynamicData = (
    dropdownItems: IDynamicData[],
    referenceKey: (string | number)[] | string | number,
    setValue = false,
    isRehab = false,
    filterValue: string,
): IDynamicData[] => {
    return dropdownItems.map((item) => {
        if (!isArray(referenceKey)) {
            const ref = isRehab ? item.id : item.referenceKey;
            if (referenceKey === ref) {
                return {
                    ...item,
                    checked: setValue,
                };
            }
            return item;
        }
        if (referenceKey.includes(isRehab ? (item?.id as number) : item?.referenceKey)) {
            if (isArray(item.children)) {
                return {
                    ...item,
                    checked: setValue,
                    children: setCheckedDynamicData(item.children, referenceKey, setValue, isRehab, filterValue),
                };
            }
            return {
                ...item,
                checked: setValue,
            };
        }
        if (isArray(item.children)) {
            return {
                ...item,
                children: setCheckedDynamicData(item.children, referenceKey, setValue, isRehab, filterValue),
            };
        }
        return item;
    });
};

export const flattenDepartments = (dep: IDynamicData[]) => {
    let result: IDynamicData[] = [];

    dep?.forEach((element) => {
        if (element.checked) result.push(element);
        if (element.children) result = result.concat(flattenDepartments(element.children));
    });

    return result;
};

export const setChecked = (data: IDynamicData[], allSelected: boolean) => {
    return data?.map((i) => {
        i.selectable ? (i.checked = !allSelected) : (i.checked = false);
        if (i.children) i.children = setChecked(i.children, allSelected);
        return i;
    });
};

/**
 * Initialize the departments when loading redux state
 */
export const reccursiveDepartments = (children: TreeViewNode[]): IDynamicData[] => {
    return children?.map((e) => ({
        id: e.id || "",
        label: e.label || "",
        referenceKey: e.referenceKey || "",
        checked: false,
        children: e.children ? reccursiveDepartments(e?.children) : [],
        selectable: e.selectable,
    }));
};

export enum TransformWidgetFilter {
    absencePeriods = "absencePeriods",
    departmentIds = "departments",
    employmentGroupIdentifiers = "employmentGroups",
    genders = "genders",
    absenceTypes = "absenceTypes",
    companyIds = "companies",
    timeInterval = "timeInterval",
    absenceCauses = "absenceCauses",
    hcmTypes = "hcmTypes",
}

/**
 * Transform filter given by the widget to the correct key value in the redux state
 */
export const isTransformWidgetFilter = (value: string): value is keyof typeof TransformWidgetFilter =>
    Object.keys(TransformWidgetFilter).includes(value);

export const getParsedWidgetData: (item: MedHelpPeopleDomainWidget[]) => IReturnValueFromWidgetTransformData[] = map(
    (item) => ({
        data: {
            type: item.type as WidgetType,
            filter: isString(item.filter) ? JSON.parse(item?.filter) : null,
        },
    }),
);

export const getWidgetForGivenWidgetId = (widgetId: string, widgetList: MedHelpPeopleDomainWidget[]) => {
    const widget = widgetList.find(({ id }) => id === widgetId);
    if (widget) {
        return {
            filter: isString(widget.filter) && JSON.parse(widget?.filter),
            id: widget.id,
        };
    }
};

export const getWidgetDataByIdWithOnlyFilter = (widgetId: string, item: MedHelpPeopleDomainWidget[]) => {
    return getWidgetForGivenWidgetId(widgetId, item)?.filter;
};

export const setCorrectFilterForCorrespondingWidget = (
    dynamicData: IDynamicData[],
    widgetDataByKeyWithOnlyFilter: IWidgetDataFilter,
    widgetStateName: WidgetType,
    value: string,
) => {
    if (
        //Workaround to support legacy widget filters
        value === "gender" ||
        value === "absenceType" ||
        value === "absencePeriod" ||
        value === "hcmTypes" ||
        isTransformWidgetFilter(value)
    ) {
        return setCheckedDynamicData(
            dynamicData,
            widgetDataByKeyWithOnlyFilter[value],
            true,
            isWidgetRehabKeys(widgetStateName),
            value,
        );
    }
    return [];
};

export const getValue = (value: string) => {
    //Workaround to support legacy widget filters
    if (value === "gender" || value === "genders") {
        return TransformWidgetFilter.genders;
    }
    if (value === "absenceType" || value === "absenceTypes") {
        return TransformWidgetFilter.absenceTypes;
    }
    if (value === "absencePeriod" || value === "absencePeriods") {
        return TransformWidgetFilter.absencePeriods;
    }
    if (isTransformWidgetFilter(value)) {
        return TransformWidgetFilter[value];
    }
    return null;
};

export const getDynamicData = (
    currentData: IDynamicData[],
    departments: TreeViewNode[],
    employmentGroups: EmploymentGroupContract[],
    value: WidgetDropdownTypeKeys,
) => {
    if (value) {
        let data = currentData;
        if (value === "departments") {
            data = reccursiveDepartments(departments[0]?.children || []);
        } else if (value === "employmentGroups") {
            data = employmentGroups.map((e) => ({
                id: e.id || "",
                label: e.nameString || e.nameIdentifier || "",
                referenceKey: e.nameIdentifier || "",
                checked: false,
                selectable: true,
            }));
        }

        return data;
    }
    return currentData;
};

export const fetchCompanyGroupForCompanyId = async (companyId: number) => {
    try {
        const { data } =
            await getServices().clients.organization.apiOrganizationGetCompanyGroupForCompanyIdGet(companyId);

        const companyGroup: IDynamicData[] = data.map((c) => ({
            id: c.id || "",
            label: c.name?.replace("**TopDepartment**", "") || "",
            referenceKey: c.id || "",
            checked: false,
            selectable: true,
        }));

        return companyGroup;
    } catch (error) {
        return [];
    }
};

export const fetchWidgetList = async () => {
    try {
        const { data } = await getServices().clients.widget.widgetListGet();
        return data;
    } catch (error) {
        return [];
    }
};

/**
 *
 * @param widgetData
 * @param userAccessRights
 * @param companies
 * @returns returns an array of objects containing employmentgroups for each companyId
 */
export const getEmploymentGroupsRequest = async (
    widgetData: MedHelpPeopleDomainWidget[],
    userAccessRights: AccountAccessRightViewModel[],
    companies: IDynamicData[],
) => {
    // parse widget data
    const parsedDataWidgetData = getParsedWidgetData(widgetData);

    // initialize empty promises array
    const promises: Promise<{
        companyId: number;
        data: EmploymentGroupContract[];
    }>[] = [];

    // keep track of which company requests have alrady been sent
    const companyRequestSent: number[] = [];

    // loop over each widget and check if user has correct access rights
    parsedDataWidgetData.forEach((widget) => {
        const type = widget.data.type;
        if (!type) return;

        const accessRight = widgetMap.get(type)?.accessRight;
        if (!accessRight) return;

        const companyIds = widget?.data?.filter?.companyIds;
        const filteredCompanies = filterCompaniesByAccessRights(userAccessRights, companies, accessRight);

        let companyId: number | null = null;

        if (companyIds?.length === 1) {
            companyId = companyIds[0];
        } else if (filteredCompanies.length === 1) {
            companyId = filteredCompanies[0].id as number;
        }

        if (!companyId) return;
        if (companyRequestSent.includes(companyId)) return;

        const fetchEmploymentGroups = async (companyId: number) => {
            try {
                const { data } =
                    await getServices().clients.organization.apiOrganizationGetEmploymentGroupsGet(companyId);
                return { companyId, data };
            } catch (error) {
                console.error(`Error fetching employment groups for company ${companyId}:`, error);
                throw error;
            }
        };

        promises.push(fetchEmploymentGroups(companyId));
        companyRequestSent.push(companyId);
    });

    const data = await Promise.allSettled(promises).then((results) => {
        return results
            .filter((result) => result.status === "fulfilled")
            .map((result) => {
                // TODO - this type casting can be removed when updating TS to 5.5.0
                const data = result as PromiseFulfilledResult<{ companyId: number; data: EmploymentGroupContract[] }>;
                return {
                    data: data.value.data,
                    companyId: data.value.companyId,
                };
            });
    });

    return data;
};

/**
 *
 * @param widgetData
 * @param userAccessRights
 * @param companies
 * @returns returns an array of objects containing departments for each companyId
 */

export const getDepartmentsRequest = async (
    widgetData: MedHelpPeopleDomainWidget[],
    userAccessRights: AccountAccessRightViewModel[],
    companies: IDynamicData[],
) => {
    // parse widget data
    const parsedDataWidgetData = getParsedWidgetData(widgetData);

    // initialize empty promises array
    const promises: Promise<{
        data: TreeViewNode[];
        companyId: number | null;
        typeOfSearch: WidgetAccessRightType;
    }>[] = [];

    // keep track of which company requests have alrady been sent
    let companyRequestSent: {
        companyId: number | null;
        typeOfSearch: string | null;
    }[] = [{ companyId: null, typeOfSearch: null }];

    parsedDataWidgetData.forEach((widget) => {
        const type = widget.data.type;
        if (!type) return;

        const accessRight = widgetMap.get(type)?.accessRight;
        if (!accessRight) return;

        const filteredCompanies = filterCompaniesByAccessRights(userAccessRights, companies, accessRight);

        const companyIds = widget?.data?.filter?.companyIds;
        let companyId: number | null = null;

        const typeOfSearch = accessRight;

        if (companyIds?.length === 1) {
            companyId = companyIds[0];
        } else if (filteredCompanies.length === 1) {
            companyId = filteredCompanies[0].id as number;
        }

        if (!companyId) return;
        if (
            companyRequestSent.some(
                (requestSent) => requestSent.companyId === companyId && requestSent.typeOfSearch === typeOfSearch,
            )
        )
            return;

        const fetchDepartments = async (companyId: number) => {
            try {
                const { data } = await getServices().clients.organization.apiOrganizationGetOrganizationTreeViewGet(
                    companyId,
                    accessRight,
                );

                return { companyId, data, typeOfSearch };
            } catch (error) {
                console.error(`Error fetching employment groups for company ${companyId}:`, error);
                throw error;
            }
        };

        promises.push(fetchDepartments(companyId));
        companyRequestSent.push({ companyId, typeOfSearch });
    });

    const data = await Promise.allSettled(promises).then((results) => {
        return results
            .filter((result) => result.status === "fulfilled")
            .map((result) => {
                // TODO - this type casting can be removed when updating TS to 5.5.0
                const data = result as PromiseFulfilledResult<{
                    companyId: number;
                    data: TreeViewNode[];
                    typeOfSearch: WidgetAccessRightType;
                }>;
                return {
                    data: data.value.data,
                    companyId: data.value.companyId,
                    typeOfSearch: data.value.typeOfSearch,
                };
            });
    });

    return data;
};

export const hasSelectedRequiredFilters = (widget: IWidget, currentWidget?: IWidgetMap) => {
    if (!currentWidget?.requiredFilters) return true;
    return currentWidget?.requiredFilters?.every((requiredFilter) =>
        widget[requiredFilter]?.some((filter) => filter.checked),
    );
};
