import axios from "axios";
import { Config } from "../config";

export interface MappedAbsenceCauseFromPrincess {
    label: string;
    value: number;
    contactReason: number;
}

export function postMessageToPrincess(
    operation: "init" | "cancel" | "complete",
    activity: "new" | "edit" | "recovery",
    arId = "UNKNOWN",
    absenceType = "UNKNOWN",
): boolean {
    if (operation && activity && arId && Config.princessDomain) {
        const message = {
            type: "absenceActivity",
            eventData: {
                operation, // init/cancel/complete
                activity, // new/edit/recovery
                reportId: arId, // (GUID), // ID of current report
                absenceType, // (Sick/CareOfChild) (HÅRDKODA KANSKE?),
            },
        };

        if (window.location !== window.parent.location) {
            window.parent.postMessage(message, Config.princessDomain);
        }
        return true;
    }
    return false;
}

export function loadEmployeeToPrincess(
    employmentId: string,
    ssn: string,
    employeeName: string,
    companyCustomerKey: string,
    countryCode: string,
    childSsn: string | null,
): true {
    const message = {
        type: "employeeLoaded",
        eventData: {
            employeeId: employmentId,
            ssn,
            employeeName,
            childSsn,
            countryCode,
            customerKey: companyCustomerKey,
        },
    };

    if (window.location !== window.parent.location) {
        window.parent.postMessage(message, Config.princessDomain);
    }

    return true;
}

export async function getConvertedAbsenceCause(
    contactReasonId: number,
    token: string,
): Promise<MappedAbsenceCauseFromPrincess> {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    const url = `${Config.api_servicesBase_url}absencereporting/api/MedicalAdvisory/GetAbsenceCause?contactReasonId=${contactReasonId}`;
    const { data: princessAbsenceCause } = await axios.get<MappedAbsenceCauseFromPrincess>(url, config);

    return princessAbsenceCause;
}
