import { UseFormRegisterReturn } from "react-hook-form";

export interface IServiceSelect {
    label: string;
    bold?: boolean;
    register?: UseFormRegisterReturn<string>;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    options: {
        value: string | number;
        label?: string;
        disabled?: boolean;
    }[];
    testId?: string;
}

const ServiceSelect = ({ label, register, options, placeholder, bold, required, disabled, testId }: IServiceSelect) => (
    <div className="w-full flex flex-col md:w-86">
        <label className={bold ? "font-bold" : ""}>
            {label}
            {required && " *"}
        </label>
        <select
            data-testid={testId}
            disabled={disabled}
            defaultValue={""}
            className="w-full mt-2 h-12 px-2 overflow-ellipsis bg-mhbeige border border-mhdarkgreen-original hover:cursor-pointer disabled:cursor-not-allowed"
            {...register}
        >
            {placeholder && (
                <option value={""} disabled>
                    {placeholder}
                </option>
            )}
            {options?.map(({ value, label, disabled }) => (
                <option key={value} value={value} disabled={disabled}>
                    {label || value}
                </option>
            ))}
        </select>
    </div>
);

export default ServiceSelect;
