import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateContactInformation, updateUserLangPreferences } from "store/userActions";
import { ContactInformation, PasswordChangeResult } from "swagger/usercontextservice";
import { getPrivateMobileInitialValue, getPrivatePhoneInitialValue, getWorkMobileInitialValue } from "utils/phone";
import { IContactInfoInputValues, IUpdateContactInfo } from "../types";
import { templateBuilder } from "./builders";
import { getLoginConfiguration, mailCustomerSupport, updatePassword } from "./userSettingsAsyncActions";

export interface IUserSettingsSlice {
    isLoading: boolean | null;
    status: string | null;
    hasUsernamePwdLogin: boolean | null;
    contactInfoInputs: IContactInfoInputValues | null;
}

const initialUserSettingsState: IUserSettingsSlice = {
    isLoading: null,
    status: null,
    hasUsernamePwdLogin: null,
    contactInfoInputs: null,
};

interface ISetContactInfoInputs {
    userContactInfo: ContactInformation;
    userCountryCode: string;
}

const userSettingsSlice = createSlice({
    name: "userSettings",
    initialState: initialUserSettingsState,
    reducers: {
        setIsLoading(state) {
            state.isLoading = true;
        },
        resetStatus(state) {
            state.status = null;
        },
        setContactInfoInputs(state, action: PayloadAction<ISetContactInfoInputs>) {
            const initialValues = action.payload;
            state.contactInfoInputs = {
                workNumber: getWorkMobileInitialValue(initialValues.userContactInfo, initialValues.userCountryCode),
                workEmail: initialValues.userContactInfo.email ?? "",
                privateMobile: getPrivateMobileInitialValue(
                    initialValues.userContactInfo,
                    initialValues.userCountryCode,
                ),
                privatePhone: getPrivatePhoneInitialValue(initialValues.userContactInfo, initialValues.userCountryCode),
            };
        },
        updateContactInfoInput(state, action: PayloadAction<IUpdateContactInfo>) {
            if (state.contactInfoInputs) {
                state.contactInfoInputs[action.payload.propToUpdate] = action.payload.newValue;
            }
        },
    },
    extraReducers: (builder) => {
        templateBuilder(builder);
        builder
            .addCase(mailCustomerSupport.fulfilled, (state) => {
                state.status = "reqHasBeenSent";
                state.isLoading = false;
            })
            .addCase(mailCustomerSupport.rejected, (state) => {
                state.status = "apiCallRejected";
                state.isLoading = false;
            })
            .addCase(updateUserLangPreferences.fulfilled, (state) => {
                state.status = "changesSaved";
                state.isLoading = false;
            })
            .addCase(updateUserLangPreferences.rejected, (state) => {
                state.status = "apiCallRejected";
                state.isLoading = false;
            })
            .addCase(getLoginConfiguration.fulfilled, (state, action) => {
                state.hasUsernamePwdLogin = action.payload.usernameFromEmail === true;
                state.isLoading = false;
            })
            .addCase(getLoginConfiguration.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(updatePassword.fulfilled, (state, action: PayloadAction<PasswordChangeResult>) => {
                const { successful, authFailed } = action.payload;
                if (successful) {
                    state.status = "newPassSaved";
                } else {
                    state.status = authFailed ? "authFailed" : "changeFailed";
                }
                state.isLoading = false;
            })
            .addCase(updatePassword.rejected, (state) => {
                state.status = "apiCallRejected";
                state.isLoading = false;
            })
            .addCase(updateContactInformation.fulfilled, (state) => {
                state.status = "changesSaved";
                state.isLoading = false;
            })
            .addCase(updateContactInformation.rejected, (state) => {
                state.status = "apiCallRejected";
                state.isLoading = false;
            });
    },
});

export const userSettingsActions = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
