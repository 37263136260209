import { IDoubleDatepickerProps } from "@medhelp/ui/Datepicker/types";
import { Trans, useTranslation } from "react-i18next";
import Dropdown from "../Dropdown";
import Infobox from "../Infobox";
import { DateRangePicker } from "components/DatePicker/DateRangePicker";

interface IProps {
    certificateDatePickerProps: IDoubleDatepickerProps;
    setCurrentChild: (index: number) => void;
    heading: string;
    values: string[];
    child?: number;
}
const AddMedicalCertificateMetadata = ({
    certificateDatePickerProps,
    setCurrentChild,
    child,
    heading,
    values,
}: IProps) => {
    const { t } = useTranslation();
    return (
        <>
            <Infobox>
                <p>
                    <Trans i18nKey={t("infoMedicalCertificate")} />
                </p>
            </Infobox>
            <DateRangePicker {...certificateDatePickerProps} />
            <div className="flex flex-col">
                <p className="font-bold  mb-4">
                    {t("absenceDegree")} <span className="text-red-500">*</span>
                </p>
                <Dropdown
                    onDropdownClick={setCurrentChild}
                    heading={heading}
                    activeChild={child}
                    data-testid="medical-certificate-degree-metadata"
                >
                    {values.map((value, index) => (
                        <p key={`dropdown-degree-${value}-${index}`} data-test-id={`dropdown-degree-${value}-${index}`}>
                            {value}
                        </p>
                    ))}
                </Dropdown>
            </div>
        </>
    );
};
export default AddMedicalCertificateMetadata;
