import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchHealthCaseList as fetchHealthCaseListAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchHealthCaseList = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchHealthCaseListAction.fulfilled, (state, action) => {
            if (action.payload) {
                state.healthCases = action.payload.map((healthCase) => ({
                    ...healthCase,
                    healthCaseActivities: [],
                    healthCaseDocuments: [],
                    healthCaseNotifications: [],
                    healthCaseSubStatus: {
                        activities: Status.PENDING,
                        documents: Status.PENDING,
                        notifications: Status.PENDING,
                        pdfDocumentTemplate: Status.PENDING,
                        pdfDocumentBasedOnTemplateSaved: Status.PENDING,
                    },
                    healthCaseStatus: Status.OK,
                    expanded: healthCase.status === "preliminary",
                }));
            }
            state.status.healthCases = Status.OK;
        })
        .addCase(fetchHealthCaseListAction.pending, (state) => {
            state.status.healthCases = Status.PENDING;
        })
        .addCase(fetchHealthCaseListAction.rejected, (state) => {
            state.status.healthCases = Status.ERROR;
        });

export default fetchHealthCaseList;
