import { flow, get, lowerFirst, includes, has } from "lodash/fp";
import { MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod } from "swagger/absencefollowup";
import {
    MedHelpHCMWebApiResponseSearchHealthCaseResponse,
    MedHelpHCMWebApiResponseHealthCaseResult,
    MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse,
    MedHelpHCMWebApiResponseHealthCaseActivitiesResult,
} from "swagger/healthCaseManagement";
import { IDeviantPeriods, ISearchPayloadPage } from "../redux";
import { TFunction } from "i18next";
import { ITableResult } from "../types";
import { ReportEmploymentContract } from "swagger/employeeattendance";
import { AbsenceSearch, AllSearchTypes, CaseSearch, EmployeeSearch, SearchTypes } from "./searchApi";
import { checkLongTermCode, extraRuleDecorated, parseDeviantCode } from "./searchSortings";
import { getDateDisplayValue, getDateTimeDisplayValue } from "utils/date";

interface ICause {
    days?: string | null;
    month?: string | null;
    value?: string | null;
}

interface PeriodType {
    type?: string | null;
    cause?: ICause | null;
}

interface BasicPeriodType {
    value?: string | null;
}

interface NumberOfDaysType extends BasicPeriodType {
    ongoing?: boolean;
    type: string | null;
}
export interface StartEndDatePeriodType {
    startDate?: string | null;
    endDate?: string | null;
}

interface DegreeMap {
    degree?: number | null;
    timeStamp?: string | null;
    endDate?: string | null;
}
export interface DegreePeriodType {
    degrees: DegreeMap[] | undefined;
    endDate?: string | null | undefined;
}

interface IProperty {
    value: string;
    name: string;
}
export type IProperties = IProperty[];
interface IDepartments {
    departments?: string[] | null;
}

interface ICaseReport {
    caseType?: null | PeriodType;
    start?: null | BasicPeriodType;
    caseManager?: null | BasicPeriodType;
    caseStatus?: null | BasicPeriodType;
    activity?: null | BasicPeriodType;
    affectedDepartments: IDepartments;
}
export const emptyCaseReport: ICaseReport = {
    caseType: null,
    start: null,
    caseManager: null,
    caseStatus: null,
    activity: null,
    affectedDepartments: {
        departments: null,
    },
};

export type ICase =
    | MedHelpHCMWebApiResponseSearchHealthCaseResponse
    | MedHelpHCMWebApiResponseSearchHealthCaseActivitiesResponse;

interface IPeriod {
    type?: null | PeriodType;
    company?: null | BasicPeriodType;
    deviantFrom?: null | BasicPeriodType;
    deviantTo?: null | BasicPeriodType;
    endStartDate?: null | StartEndDatePeriodType;
    validFrom?: null | BasicPeriodType;
    degrees?: null | DegreePeriodType;
    numberOfDays?: null | BasicPeriodType;
    affectedDepartments: IDepartments;
}

export const emptyPeriod: IPeriod = {
    type: null,
    company: null,
    deviantFrom: null,
    deviantTo: null,
    endStartDate: null,
    validFrom: null,
    degrees: null,
    numberOfDays: null,
    affectedDepartments: {
        departments: null,
    },
};

type PeriodTypes = "deviantPeriods" | "absencePeriods";
type TypeOfPeriods = MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod | IDeviantPeriods;

type SchemaTypePeriods = (type: PeriodTypes) => (period: TypeOfPeriods) => IPeriod;
const isAbsencePeriod = (
    type: PeriodTypes,
    period: TypeOfPeriods,
): period is MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod => type === "absencePeriods";
const isDeviantPeriod = (type: PeriodTypes, period: TypeOfPeriods): period is IDeviantPeriods =>
    type === "deviantPeriods";

const initialDeviantPeriods = (period: IDeviantPeriods) => ({
    type: null,
    endStartDate: null,
    degrees: null,
    numberOfDays: null,
    affectedDepartments: {
        departments: period?.departmentNames ? period?.departmentNames : undefined,
    },
});
const initialAbsencePeriods = (period: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod) => ({
    type: null,
    deviantFrom: null,
    numberOfDays: null,
    affectedDepartments: {
        departments: period?.departments ? (period?.departments.map(get("departmentName")) as string[]) : [],
    },
});

const degreeField = (period: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod) => ({
    degrees: period?.degrees?.map((degree) => ({
        degree: degree.degree,
        timeStamp: degree.timeStamp,
    })),
    endDate: period.endDate,
});
const endStartDateField = (period: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod) => ({
    startDate: period?.startDate,
    endDate: period?.endDate,
});
const backAtWorkAndStartDateField = (period: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod) => ({
    startDate: period?.startDate,
    endDate: period?.backAtWork,
});

const typeCause = (period: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod) => ({
    type: period?.type || "",
    cause: {
        value: period?.properties?.find((value) => value.name === "AbsenceCause")?.value,
    },
});

const numberOfDaysField = (period: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod) => ({
    value: period.totalDays?.toString(),
    ongoing: Boolean(!period.endDate),
});

const affectedDepartmentsField = (period: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod) => ({
    departments: period?.departments ? (period?.departments.map(get("departmentName")) as string[]) : [],
});

const reimbursableType = (period: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod) =>
    period.properties?.filter((x) => x.name !== "AbsenceCause");

const ReimbursibelSchema: SchemaTypePeriods = (type) => (period) => {
    if (isDeviantPeriod(type, period)) return initialDeviantPeriods(period);
    if (isAbsencePeriod(type, period)) {
        return {
            type: typeCause(period),
            endStartDate: endStartDateField(period),
            degrees: degreeField(period),
            numberOfDays: numberOfDaysField(period),
            affectedDepartments: affectedDepartmentsField(period),
            reimbursableType: reimbursableType(period),
        };
    }
    return emptyPeriod;
};

const AbsencePeriodSchema: SchemaTypePeriods = (type) => (period) => {
    if (isDeviantPeriod(type, period)) return initialDeviantPeriods(period);
    if (isAbsencePeriod(type, period)) {
        return {
            type: typeCause(period),
            endStartDate: endStartDateField(period),
            degrees: degreeField(period),
            numberOfDays: numberOfDaysField(period),
            affectedDepartments: affectedDepartmentsField(period),
        };
    }
    return emptyPeriod;
};

const AbsenceSchema: SchemaTypePeriods = (type) => (period) => {
    if (isDeviantPeriod(type, period)) return initialDeviantPeriods(period);
    if (isAbsencePeriod(type, period)) {
        return {
            type: typeCause(period),
            endStartDate: backAtWorkAndStartDateField(period),
            degrees: degreeField(period),
            numberOfDays: numberOfDaysField(period),
            affectedDepartments: affectedDepartmentsField(period),
        };
    }
    return emptyPeriod;
};

const LongtermAbsenceSchema: SchemaTypePeriods = (type) => (period) => {
    if (isAbsencePeriod(type, period)) return initialAbsencePeriods(period);
    if (isDeviantPeriod(type, period)) {
        return {
            type: {
                type: period?.type || "",
                cause: {
                    value: period.code,
                },
            },
            deviantFrom: {
                value: period.validFrom,
            },
            numberOfDays: {
                value: period.code,
                type: period.type,
            },
            affectedDepartments: {
                departments: period?.departmentNames ? period?.departmentNames : undefined,
            },
        };
    }
    return emptyPeriod;
};

const RecurringAbsenceSchema: SchemaTypePeriods = (type) => (period) => {
    if (isAbsencePeriod(type, period)) return initialAbsencePeriods(period);
    if (isDeviantPeriod(type, period))
        return {
            type: {
                type: period?.type || "",
                cause: {
                    value: period.code,
                },
            },
            deviantFrom: {
                value: period.validFrom,
            },
            deviantTo: {
                value: period.validTo,
            },
            affectedDepartments: {
                departments: period?.departmentNames ? period?.departmentNames : undefined,
            },
        };
    return emptyPeriod;
};

const SearchEmployeeSchema = (data: ReportEmploymentContract): IPeriod => {
    return {
        company: {
            value: data?.companyName,
        },
        affectedDepartments: {
            departments: data?.departmentNames ? data?.departmentNames : undefined,
        },
    };
};

const MedicalCertificateSchema = (data: ISearchPayloadPage): IPeriod => ({
    type: {
        type: "search.searchOptions.medicalCertificate",
    },
    deviantFrom: {
        value: data.startDate,
    },
    deviantTo: {
        value: data.endDate,
    },
    degrees: {
        degrees: [
            {
                degree: data.absenceDegree,
                timeStamp: data.startDate,
                endDate: data?.endDate,
            },
        ],
        endDate: null,
    },
    affectedDepartments: {
        departments: null,
    },
});
export const createPeriods = (
    searchTypes: Extract<
        "absencePeriod" | "ongoingAbsence" | "longtermAbsence" | "recurringAbsence" | "reimbursable",
        SearchTypes
    >,
    page: ISearchPayloadPage,
): IPeriod[] => {
    const type = page.deviantPeriods ? "deviantPeriods" : "absencePeriods";
    const periods = page.deviantPeriods ?? page.absencePeriods;
    if (!periods) return [emptyPeriod];
    switch (searchTypes) {
        case "reimbursable": {
            return periods.map(ReimbursibelSchema(type));
        }
        case "absencePeriod": {
            return periods.map(AbsencePeriodSchema(type));
        }
        case "ongoingAbsence": {
            return periods?.map(AbsenceSchema(type));
        }
        case "longtermAbsence": {
            return periods.map(LongtermAbsenceSchema(type));
        }
        case "recurringAbsence": {
            return periods.map(RecurringAbsenceSchema(type));
        }
        default: {
            return [emptyPeriod];
        }
    }
};

const casesSchema = (data: MedHelpHCMWebApiResponseHealthCaseResult): ICaseReport => {
    return {
        caseType: {
            type: data.healthCaseType,
            cause: {
                value: data.cause,
            },
        },
        start: {
            value: getDateTimeDisplayValue(data.startDate, "d MMM yyyy"),
        },
        caseManager: {
            value: data.responsibleName,
        },
        affectedDepartments: {
            departments: data.departments?.map((y) => y.departmentName ?? "") ?? undefined,
        },
        caseStatus: {
            value: data.status,
        },
    };
};

const activitiesSchema = (data: MedHelpHCMWebApiResponseHealthCaseActivitiesResult): ICaseReport => {
    return {
        caseType: {
            type: data.healthCaseType,
            cause: {
                value: data.cause,
            },
        },
        activity: {
            value: data.title,
        },
        start: {
            value: getDateTimeDisplayValue(data.date, "d MMM yyyy"),
        },
        caseManager: {
            value: data.responsibleName,
        },
        affectedDepartments: {
            departments: data.departments?.map((y) => y.departmentName ?? "") ?? undefined,
        },
    };
};

export const createCaseRows = (
    searchTypes: Extract<"cases" | "activities", SearchTypes>,
    page: ICase,
): ICaseReport[] => {
    const isCases = "healthCases" in page;
    const isActivities = "healthCaseActivitiess" in page;

    switch (searchTypes) {
        case "cases": {
            if (isCases && page?.healthCases) {
                return page.healthCases.map(casesSchema);
            } else {
                return [emptyCaseReport];
            }
        }
        case "activities": {
            if (isActivities && page?.healthCaseActivitiess) {
                return page.healthCaseActivitiess.map(activitiesSchema);
            } else {
                return [emptyCaseReport];
            }
        }
        default:
            return [emptyCaseReport];
    }
};
export const createRest = (
    searchTypes: Extract<"medicalCertificate" | "monthlyReport", SearchTypes>,
    page: ISearchPayloadPage,
): IPeriod[] => {
    switch (searchTypes) {
        case "medicalCertificate": {
            return [MedicalCertificateSchema(page)];
        }
        default: {
            return [emptyPeriod];
        }
    }
};

const createSearchReportsRows = (searchTypes: AllSearchTypes | null, page: ISearchPayloadPage) => {
    switch (searchTypes) {
        case "absencePeriod":
        case "ongoingAbsence":
        case "longtermAbsence":
        case "reimbursable":
        case "recurringAbsence": {
            return createPeriods(searchTypes, page);
        }
        case "medicalCertificate":
        case "monthlyReport": {
            return createRest(searchTypes, page);
        }
        default: {
            return [emptyPeriod];
        }
    }
};

const createCaseReportsRows = (searchTypes: AllSearchTypes | null, page: ICase) => {
    switch (searchTypes) {
        case "cases":
        case "activities": {
            return createCaseRows(searchTypes, page);
        }
        default: {
            return [emptyPeriod];
        }
    }
};

const createSearchEmployeeRows = (searchTypes: AllSearchTypes | null, page: ReportEmploymentContract) => {
    switch (searchTypes) {
        case "employee": {
            return [SearchEmployeeSchema(page)];
        }
        default: {
            return [emptyPeriod];
        }
    }
};
const checkIfSearchEmployeeRows = (
    searchTypes: AllSearchTypes | null,
    page: ISearchPayloadPage | ReportEmploymentContract | ICase,
): page is ReportEmploymentContract => includes(searchTypes, EmployeeSearch);

const checkIfSearchReports = (
    searchTypes: AllSearchTypes | null,
    page: ISearchPayloadPage | ReportEmploymentContract | ICase,
): page is ISearchPayloadPage => includes(searchTypes, AbsenceSearch);

const checkIfCaseReports = (
    searchTypes: AllSearchTypes | null,
    page: ISearchPayloadPage | ReportEmploymentContract | ICase,
): page is ICase => includes(searchTypes, CaseSearch);

export const createSearchSchema = <T extends ISearchPayloadPage | ReportEmploymentContract | ICase>(
    searchTypes: AllSearchTypes | null,
    page: T,
) => {
    if (checkIfSearchReports(searchTypes, page)) {
        return createSearchReportsRows(searchTypes, page);
    }
    if (checkIfCaseReports(searchTypes, page)) {
        return createCaseReportsRows(searchTypes, page);
    }
    if (checkIfSearchEmployeeRows(searchTypes, page)) {
        return createSearchEmployeeRows(searchTypes, page);
    }
    return [emptyPeriod];
};

const Type = (t: TFunction) => (type?: PeriodType | null) => {
    if (type?.type === "LongTerm") {
        return {
            header: t(`searchResult.type.${type.type}.header`),
            body: `${t("longtermRules", {
                days: checkLongTermCode(type.cause?.value),
            })} ${lowerFirst(t(extraRuleDecorated(type.cause?.value) || ""))}`,
            type: "ColumnText",
        };
    }
    if (type?.type === "RecurringPeriod") {
        const value = parseDeviantCode(type.cause?.value);
        return {
            header: t(`searchResult.type.${type.type}.header`),
            body: t(`searchResult.type.${type.type}.body`, {
                days: value?.days,
                month: value?.month,
            }),
        };
    }
    return {
        header: t(lowerFirst(type?.type || "")),
        body: !has("cause", type)
            ? undefined
            : type?.cause?.value
              ? t(`followUpAbsence.summaryOfAbsence.${type?.cause?.value || ""}`)
              : t(`followUpAbsence.summaryOfAbsence.Confidential`),
        type: "ColumnText",
    };
};

const EndStartDate = () => (endStartDate?: StartEndDatePeriodType | null) => ({
    endStartDate,
    type: "EndStartDate",
});

const Properties = () => (properties: IProperties) => ({
    properties,
    type: "PropertiesCell",
});

const DeviantFrom = () => (deviantFrom?: BasicPeriodType | null) => ({
    header: getDateDisplayValue(deviantFrom?.value, "d MMM yyyy"),
    type: "ColumnText",
});
const DeviantTo = () => (deviantTo?: BasicPeriodType | null) => ({
    header: getDateDisplayValue(deviantTo?.value, "d MMM yyyy"),

    type: "ColumnText",
});

const Degrees = () => (degrees?: DegreePeriodType | null) => ({
    degrees: degrees,
    type: "DegreeCell",
});

const NumberOfDays = (t: TFunction) => (numberOfDays?: NumberOfDaysType | null) => {
    if (numberOfDays?.type === "LongTerm") {
        return {
            header: `${t("longtermRules", {
                days: checkLongTermCode(numberOfDays?.value),
            })} ${lowerFirst(t(extraRuleDecorated(numberOfDays?.value) || ""))}`,
        };
    }
    return {
        header: numberOfDays?.ongoing
            ? t("search.daysOngoingAbsence", {
                  days: numberOfDays?.value,
              })
            : `${numberOfDays?.value} ${t("days")}`,
        body: numberOfDays?.ongoing
            ? t("ongoing", {
                  ns: "translation",
              })
            : undefined,
        type: "ColumnText",
    };
};
const AffectedDepartments = () => (affectedDepartments: IDepartments) => ({
    texts: affectedDepartments?.departments,
    type: "DepartmentsCell",
});

const Company = () => (value: BasicPeriodType) => ({
    header: value.value,
});

const CaseType = (t: TFunction) => (type?: PeriodType | null) => {
    return {
        header: t(type?.type ?? ""),
        body: type?.cause?.value,
    };
};

const Activity = () => (type?: BasicPeriodType | null) => {
    return {
        header: type?.value,
    };
};

const Start = () => (type?: BasicPeriodType | null) => {
    return {
        header: type?.value,
        type: "ColumnText",
    };
};

const CaseManager = () => (type?: BasicPeriodType | null) => {
    return {
        header: type?.value,
        type: "ColumnText",
    };
};

const CaseStatus = (t: TFunction) => (type?: BasicPeriodType | null) => {
    return {
        header: t(`caseStatus.${type?.value}`),
        type: "ColumnText",
        status: type?.value,
    };
};

const Cells: {
    type: typeof Type;
    company: typeof Company;
    endStartDate: typeof EndStartDate;
    reimbursableType: typeof Properties;
    deviantFrom: typeof DeviantFrom;
    deviantTo: typeof DeviantTo;
    degrees: typeof Degrees;
    numberOfDays: typeof NumberOfDays;
    caseType: typeof CaseType;
    activity: typeof Activity;
    start: typeof Start;
    caseManager: typeof CaseManager;
    affectedDepartments: typeof AffectedDepartments;
    caseStatus: typeof CaseStatus;
} = {
    type: Type,
    company: Company,
    endStartDate: EndStartDate,
    reimbursableType: Properties,
    deviantFrom: DeviantFrom,
    deviantTo: DeviantTo,
    degrees: Degrees,
    numberOfDays: NumberOfDays,
    caseType: CaseType,
    activity: Activity,
    start: Start,
    caseManager: CaseManager,
    affectedDepartments: AffectedDepartments,
    caseStatus: CaseStatus,
};
export type CellType =
    | "type"
    | "company"
    | "endStartDate"
    | "reimbursableType"
    | "deviantFrom"
    | "deviantTo"
    | "degrees"
    | "numberOfDays"
    | "caseType"
    | "activity"
    | "start"
    | "caseManager"
    | "affectedDepartments"
    | "caseStatus";
// type col = typeof keyof IPeriod;
export const selectCell = (t: TFunction, row: CellType, result: IPeriod[]) => {
    const selectedCell = Cells[row];
    return result.flatMap<ITableResult>(flow(get(row), selectedCell(t)));
};
