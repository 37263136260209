import { SetLanguage, SetPassword } from "./components";
import DeleteHistoricalAbsenceCauses from "./components/DeleteHistoricalAbsenceCauses";
import { IUserSettingsProps } from "./types";
import SetContactInfo from "./components/SetContactInfo";

const UserSettings = (userSettingProps: IUserSettingsProps) => {
    const pageProps = userSettingProps.userSettingPageProps;
    return (
        <div id="user-settings-container" className="shadow-lg" data-testid="user-settings-container">
            {pageProps.isDkUser && <DeleteHistoricalAbsenceCauses {...userSettingProps} />}

            <SetLanguage {...userSettingProps} />

            {pageProps.hasUsernamePwdLogin && <SetPassword {...userSettingProps} />}

            <SetContactInfo {...userSettingProps.setContactInfoProps} />
        </div>
    );
};

export default UserSettings;
