import { useEffect } from "react";
import ErrorWidget from "../ErrorWidget";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { ListChart } from "pages/dashboard/components/Charts";
import OngoingRehabWidgetLoader from "./OngoingRehabWidgetLoader";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import { getOngoingRehab, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const OngoingRehabWidget = ({ id, type }: Widget) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("dashboard");
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getOngoingRehab(id));

    useEffect(() => {
        !isDragging && dispatch(fetchWidgetData({ id, type }));
    }, [dispatch, id, type, isDragging]);

    if (status === Status.PENDING) {
        return <OngoingRehabWidgetLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <ListChart
            keyHeading={t("reasonsForRehab")}
            dataHeading={t("noOfProcesses")}
            hasDate={false}
            hasModal={true}
            color={"primary"}
            id={"ongoing-rehab"}
            isRehab={true}
            data={[
                {
                    heading: t("longTermAbsence"),
                    modalHeading: t("longTermAbsenceModal"),
                    data: data.longTerm || 0,
                    employeeDetails: data.employeeDetails?.filter((x) => x.processType === "LongTerm"),
                    type: "LongTerm",
                },
                {
                    heading: t("repeatedAbsence"),
                    modalHeading: t("repeatedAbsenceModal"),
                    data: data.recurring || 0,
                    employeeDetails: data.employeeDetails?.filter((x) => x.processType === "Recurring"),
                    type: "Recurring",
                },
                {
                    heading: t("manuallyInitiatedWithoutAbsence"),
                    modalHeading: t("manuallyInitiatedWithoutAbsenceModal"),
                    data: data.manually || 0,
                    employeeDetails: data.employeeDetails?.filter((x) => x.processType === "Manually"),
                    type: "Manually",
                },
                {
                    heading: t("total"),
                    modalHeading: t("totalModal"),
                    data: data.total || 0,
                    employeeDetails: data.employeeDetails,
                },
            ]}
        />
    );
};

export default OngoingRehabWidget;
