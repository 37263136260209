import { useTranslation } from "react-i18next";
import { ISelectValue } from "components/Select";
import RadioButtonGroup from "components/RadioButtonGroup";
import PrimaryButton from "components/PrimaryButton";
import Icon from "components/Icon";
import WizardHeader from "components/WizardHeader";

interface IProps {
    selectedAbsenceReportType: string | null;
    values: ISelectValue[];
    onClick: (absenceType: string | undefined) => void;
    forwardClick: () => void;
}
const AbsenceReportTypeOfAbsence = (props: IProps) => {
    const { selectedAbsenceReportType, values, onClick, forwardClick } = props;
    const { t } = useTranslation();

    const primaryButton = (className: string) => (
        <PrimaryButton
            id="next-button"
            text={t("next")}
            rightIcon={<Icon icon="checkMark" stroke="currentcolor" />}
            className={className}
            onClick={forwardClick}
        />
    );

    return (
        <div className="flex flex-col w-full flex-grow relative">
            <div className="flex flex-col md:w-10/12 lg:w-[624px]">
                <WizardHeader isMandatory={true} heading={t("kindOfAbsence")} />
                <div className="flex flex-col justify-center pt-10">
                    <RadioButtonGroup
                        id="absenceTypePage"
                        values={values}
                        selectedValue={selectedAbsenceReportType}
                        clicked={onClick}
                    />
                </div>
            </div>
            {selectedAbsenceReportType && (
                <div className="flex flex-col flex-grow">
                    <div className="flex-grow xxs:h-full xxs:flex xxs:flex-col xxs:justify-between">
                        {primaryButton("hidden fit-content mt-16 mb-6 xxs:flex")}
                        <div className="flex fixed bottom-0 right-0 left-0 h-24 shadow-top xxs:hidden bg-mhbeige">
                            {primaryButton("full-width-important flex justify-center self-center mx-5 h-14 xxs:hidden")}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AbsenceReportTypeOfAbsence;
