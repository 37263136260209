import Icon from "components/Icon";
import PowerbarSearch from "components/PowerbarSearch";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";
import MenuNavDropdownMenu from "./MenuNavDropdownMenu";

const MenuNavBar = () => {
    const userContext = useAppSelector(UserSelectors.getUserContext);

    return (
        <>
            <div
                className="
                    px-0 xl:pr-14 items-center justify-between xl:w-full xl:bg-white xl:sticky xl:mt-0 xl:h-14 xl:shadow-topNav z-10 flex"
            >
                <div className="flex h-full xl:w-[340px] items-start">
                    {userContext.HasCustomerSupport && <PowerbarSearch />}
                </div>

                <div className="flex max-xl:hidden items-center gap-8 z-11">
                    <div className="flex justify-center items-center">
                        <MenuNavDropdownMenu />
                    </div>

                    <a
                        href="https://helpcenter.medhelp.se/"
                        className="flex justify-center items-center text-menuNavColorSecondary"
                        target="_blank"
                    >
                        <Icon icon="questionMark" size={12} />
                        <span className="text-menuNavColorSecondary text-sm capitalize pl-1.5">Helpcenter</span>
                    </a>
                </div>
                <div className="flex xl:hidden z-11">
                    <div className="flex justify-center items-center w-12 h-12  ">
                        <MenuNavDropdownMenu />
                    </div>
                    <a
                        aria-hidden="true"
                        tabIndex={-1}
                        href="https://helpcenter.medhelp.se/"
                        className="w-12 h-12 flex justify-center items-center text-menuNavColorPrimary"
                    >
                        <button>
                            <Icon icon="questionMarkCircle" size={28} />
                        </button>
                    </a>
                </div>
            </div>
        </>
    );
};

export default MenuNavBar;
