import Icon from "components/Icon";

interface IProps {
    name: string | null;
}
const FollowUpReportHeader = ({ name }: IProps) => (
    <div className="flex p-2 gap-2 items-center">
        {name && (
            <>
                <Icon icon="notelist" stroke="black" />
                <div className="text-base font-bold">{name}</div>
            </>
        )}
    </div>
);
export default FollowUpReportHeader;
