import PrimaryButton from "components/PrimaryButton";
import { IResetPassCompProps } from "pages/login/types";
import BackButtonHeader from "../BackButtonHeader";
import UsernameOrPwdInput from "../UsernameOrPwdInput";

const ResetPass = ({
    isLoading,
    failedAt,
    userIdentifier,
    newPass,
    confirmNewPass,
    currentStep,
    formIsInvalid,
    newPassError,
    newPassErrorMessage,
    confirmNewPassError,
    setResetPassPropValue,
    handleSubmit,
    translations,
    navigate,
    renderPasswordStrength,
    userIdentifierInvalid,
}: IResetPassCompProps) => {
    return (
        <div className="mb-14">
            {currentStep !== "resetLinkSent" && currentStep !== "setNewPass" && currentStep !== "resetSuccesful" && (
                <>
                    <BackButtonHeader heading={translations.resetPass} onClick={() => navigate(`/login/userAndPass`)} />
                    <p className="mb-6">{translations.resetPassInfo}</p>
                    <UsernameOrPwdInput
                        placeholder={translations.ssnOrEmail}
                        heading={translations.ssnOrEmail}
                        inputValue={userIdentifier}
                        inputChanged={(v) => setResetPassPropValue(v, "userIdentifier")}
                        containerClassName="mb-8 xxs:w-full"
                        className="h-12"
                        type="email"
                        name="username-reset"
                        autoComplete="username"
                    />
                    <PrimaryButton
                        id="resetPassButton"
                        text={translations.resetPass}
                        className="h-12 flex justify-center"
                        onClick={() => handleSubmit(true)}
                        disabled={!userIdentifierInvalid}
                        isLoading={isLoading}
                    />
                    {failedAt === "loadPasswordResetRequest" && (
                        <p className="text-mhred-alert2">{translations.failedToGetLink}</p>
                    )}
                </>
            )}
            {currentStep === "resetLinkSent" && (
                <>
                    <BackButtonHeader heading={translations.resetLinkSent} />
                    <p className="mb-3">{translations.resetLinkInfoStart}</p>
                    <p className="">{translations.resetLinkInfoEnd}</p>
                </>
            )}
            {currentStep === "setNewPass" && (
                <>
                    <BackButtonHeader
                        heading={translations.newPassword}
                        onClick={() => navigate(`/login/userAndPass`)}
                    />
                    <form onSubmit={() => handleSubmit()} className="w-full flex flex-col xs:max-w-xs">
                        <input autoComplete="username" className="hidden" />
                        <UsernameOrPwdInput
                            heading={translations.newPass}
                            inputValue={newPass}
                            inputChanged={(v) => setResetPassPropValue(v, "newPass")}
                            containerClassName={`${newPass ? "" : "mb-8"}`}
                            className="h-12"
                            type="password"
                            name="newPwd-password"
                            submitOnEnter={() => handleSubmit()}
                            isMandatory
                            errorMessage={newPassError ? newPassErrorMessage : ""}
                            disableKeyCode={32}
                            autoComplete="new-password"
                        />
                        {newPass && (
                            <div className="w-full mt-1 relative flex flex-col">
                                <div className="w-full h-1.5 bg-grey-200" />
                                {renderPasswordStrength}
                            </div>
                        )}
                        <UsernameOrPwdInput
                            heading={translations.confirmNewPass}
                            inputValue={confirmNewPass}
                            inputChanged={(v) => setResetPassPropValue(v, "confirmNewPass")}
                            className="h-12"
                            type="password"
                            name="confirmPwd-password"
                            submitOnEnter={() => handleSubmit()}
                            isMandatory
                            errorMessage={confirmNewPassError ? translations.passNotMatching : ""}
                            disableKeyCode={32}
                            autoComplete="new-password"
                        />
                    </form>
                    <div className="w-full h-max my-8 p-6 border border-mhdarkgreen-original flex flex-col">
                        <p className="font-bold">{translations.newPwdMustContain}</p>
                        <ul className="list-disc pl-6">
                            <li>{translations.atleastSmallLetter}</li>
                            <li>{translations.atleastCapitalLetter}</li>
                            <li>{translations.atleastOneNumber}</li>
                            <li>{translations.altlestSpecialChar}</li>
                            <li>{translations.atleastNineChars}</li>
                        </ul>
                    </div>
                    <PrimaryButton
                        id="saveNewPassButton"
                        text={translations.saveNewPass}
                        className="h-12 flex justify-center"
                        onClick={() => handleSubmit()}
                        disabled={formIsInvalid}
                        isLoading={isLoading}
                    />
                    {failedAt === "loadPasswordResetRequest" && (
                        <p className="text-mhred-alert2">{translations.passChangedFailed}</p>
                    )}
                </>
            )}
            {currentStep === "resetSuccesful" && (
                <>
                    <BackButtonHeader
                        heading={translations.resetSuccessful}
                        onClick={() => navigate(`/login/userAndPass`)}
                    />
                    <p className="mb-8">{translations.resetSuccessfulRedirect}</p>

                    <PrimaryButton
                        id="resetSuccessfulRedirectButton"
                        text={translations.backToLogin}
                        className="h-12 flex justify-center"
                        onClick={() => navigate(`/login/userAndPass`)}
                        isLoading={isLoading}
                    />
                </>
            )}
        </div>
    );
};

export default ResetPass;
