import { useState } from "react";
import HCMCaseTable from "./HCMCaseTable";
import { IData } from "../Charts/TableChart";
import HCMCaseDetailsTable from "./HCMCaseDetailsTable";
import { MedHelpHCMWebApiResponseSearchHealthCaseResponse } from "swagger/healthCaseManagement";

const HCMCaseTableContainer = ({ data }: { data: IData | null }) => {
    const [selectedCase, setSelectedCase] = useState<MedHelpHCMWebApiResponseSearchHealthCaseResponse | null>(null);

    return selectedCase ? (
        <HCMCaseDetailsTable data={data} selectedCase={selectedCase ?? {}} setSelectedCase={setSelectedCase} />
    ) : (
        <HCMCaseTable data={data} setSelectedCase={setSelectedCase} />
    );
};

export default HCMCaseTableContainer;
