import { TFunction } from "i18next";
import { flow, get, isEqual, isEmpty, isUndefined, last, orderBy, head, isNumber, includes } from "lodash/fp";
import { MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree } from "swagger/absencefollowup";
import {
    MedHelpAbsenceReportingWebApiModelsAbsenceReport,
    MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree,
    MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree,
} from "swagger/absencereportapi";
import {
    getDateDisplayValue,
    getDateStringForwardOrBack,
    getDateTimeDisplayValue,
    isAfterDate,
    isBeforeDate,
    isSameDateDay,
    isSameOrAfterDate,
} from "utils/date";
interface IDegree {
    degree?: number | null;
    timeStamp?: string | null;
}
export type IDegrees = IDegree[];

type DegreeToAdd = {
    absenceReportId?: string;
    degree: number;
    timeStamp: string;
};
const checkValidDegree = (degreeToAdd: DegreeToAdd, x: MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree) =>
    isEqual(x.degree, degreeToAdd.degree) || isSameDateDay(degreeToAdd.timeStamp, x.timeStamp);
export const canAddNewDegree = (
    currentAbsenceReportReport: MedHelpAbsenceReportingWebApiModelsAbsenceReport,
    degreeToAdd: MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree,
) => {
    if (isUndefined(degreeToAdd?.timeStamp) || isUndefined(degreeToAdd?.degree)) return false;
    const currentTimeStamp = degreeToAdd.timeStamp;
    const degrees = currentAbsenceReportReport.degrees || [];
    const getCurrentDegree = last(
        orderBy(
            get("timeStamp"),
            "asc",
            degrees.filter((x) => isSameOrAfterDate(currentTimeStamp, x.timeStamp)),
        ),
    );
    if (isUndefined(getCurrentDegree)) return false;
    return isEmpty(getCurrentDegree)
        ? degreeToAdd.degree !== currentAbsenceReportReport.degree
        : !checkValidDegree(degreeToAdd as DegreeToAdd, getCurrentDegree);
};

export const orderDegrees: (
    degrees: MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree[],
) => MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree[] = orderBy(get("timeStamp"), "desc");

export const removeDuplicates = (degrees: MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree[]) =>
    orderBy(get("timeStamp"), "asc", degrees).reduce<MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree[]>((x, y) => {
        const lastestElement = last(x);
        if (lastestElement && lastestElement.degree === y.degree) {
            return x;
        }
        return [...x, y];
    }, []);

const selectStatus = (currentDegree: number, previousDegree: number) => {
    if (currentDegree === previousDegree) return "None";

    return currentDegree > previousDegree ? "Up" : "Down";
};
export const getStatus = (
    currentAbsenceReportReport: MedHelpAbsenceReportingWebApiModelsAbsenceReport,
    degrees: MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree[],
) => {
    const hasSameDayDegree = degrees.find((x) => x.timeStamp && isSameDateDay(x.timeStamp, new Date()));
    if (hasSameDayDegree?.degree && currentAbsenceReportReport.degree)
        return selectStatus(currentAbsenceReportReport.degree, hasSameDayDegree.degree);

    const sortedDegrees = orderBy(get("timeStamp"), "asc", degrees);
    const getCurrentDegree = head(sortedDegrees?.filter((x) => isBeforeDate(new Date(), x.timeStamp)));

    const currentDegreesIndex = getCurrentDegree && sortedDegrees.indexOf(getCurrentDegree);
    const currentAbsenceReportDegree =
        currentAbsenceReportReport.degree ??
        // @ts-expect-error
        currentAbsenceReportReport.currentDegree;
    if (currentDegreesIndex === -1 || isUndefined(getCurrentDegree)) {
        return "Empty";
    }
    if (getCurrentDegree.degree && currentAbsenceReportDegree && isNumber(currentDegreesIndex)) {
        if (currentDegreesIndex === 0) {
            return selectStatus(getCurrentDegree.degree, currentAbsenceReportDegree);
        }
        const previousDegree = sortedDegrees[currentDegreesIndex - 1];
        if (previousDegree.degree) {
            return selectStatus(getCurrentDegree.degree, previousDegree.degree);
        }
    }
    return "Empty";
};

const dates = (index: number, array: IDegrees, hasNextArray: IDegrees) => {
    if (index < array.length - 1) {
        return [array[index]?.timeStamp, array[index + 1].timeStamp];
    }
    if (!isEmpty(hasNextArray)) {
        return [array[index].timeStamp, head(hasNextArray)?.timeStamp];
    }
    return [array[index].timeStamp, undefined];
};
const compare = (from: (x: string, y: string) => boolean, compareDate: string) => (x: string) => from(x, compareDate);

const getDegreeOrderBasedOnDate =
    (date: string, degrees: IDegrees) => (diff: (date: string, dateTwo: string) => boolean) =>
        orderBy(get("timeStamp"), "asc", degrees).filter(flow(get("timeStamp"), compare(diff, date as string)));

export const getStatusText = (degrees: IDegrees, selectedDate?: string) => {
    const date = getDateTimeDisplayValue(selectedDate);
    if (isEmpty(degrees)) return [];
    const orderDegrees = getDegreeOrderBasedOnDate(date, degrees);
    const [before, isSame, after] = [isBeforeDate, isSameDateDay, isAfterDate].map(orderDegrees);

    return [
        ...before.map((x, index) => ({
            text: "past",
            degree: x.degree,
            dates: dates(index, before, isSame),
        })),
        ...isSame.map((x, index) => ({
            text: "current",
            degree: x.degree,
            dates: dates(index, isSame, after),
        })),
        ...after.map((x, index) => ({
            text: "comingDegree",
            degree: x.degree,
            dates: dates(index, after, []),
        })),
    ].map((x) => (includes(undefined, x.dates) ? { ...x, text: x.text === "past" ? "current" : x.text } : x));
};

export const filterAndSetFirstDegreeToStartDate = (
    degrees: MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree[],
    start: string,
) => {
    let orderedDegrees = orderBy(get("timeStamp"), "asc", degrees);

    const updatedDegrees = orderedDegrees.reduce((a, b, i) => {
        if (orderedDegrees.length === 1) {
            b.timeStamp = start;
            return [...a, b];
        }
        let latestDegree = last(a);
        if (isAfterDate(b.timeStamp, start) && i === 0) {
            b.timeStamp = start;
            return [...a, b];
        }
        if (latestDegree && isAfterDate(b.timeStamp, start) && isBeforeDate(latestDegree.timeStamp, start)) {
            latestDegree.timeStamp = start;
            return [...a.slice(0, a.length - 1), latestDegree, b];
        }
        return [...a, b];
    }, [] as MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree[]);

    const filteredAndUpdatedDegrees = updatedDegrees.filter(
        (degree) => isAfterDate(degree?.timeStamp ?? "", start) || isSameDateDay(degree?.timeStamp ?? "", start),
    );

    return !isEmpty(filteredAndUpdatedDegrees)
        ? filteredAndUpdatedDegrees
        : [{ ...last(orderedDegrees), timeStamp: start }];
};

export const getCurrentDegree = (degrees: MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree[]) => {
    const orderedDegrees = orderBy((x) => x.timeStamp, "asc", degrees);
    const degreesBeforeOrAtStart = orderedDegrees?.filter(
        (x) => isBeforeDate(x.timeStamp) || isSameDateDay(x.timeStamp),
    );
    const currentDegree = last(degreesBeforeOrAtStart)?.degree;
    return currentDegree ? currentDegree : orderedDegrees[0]?.degree;
};

export const getDegreePeriodString = (
    fromDate: string,
    degrees: IDegree[],
    i: number,
    t: TFunction,
    endDate?: string | null,
): string => {
    if (i === 0) {
        return `${getDateDisplayValue(fromDate)} - ${endDate ? getDateDisplayValue(endDate) : t("ongoing")}`;
    } else {
        const dateBeforeNextDegree = getDateStringForwardOrBack(
            1,
            "days",
            "backwards",
            degrees[i - 1].timeStamp ?? undefined,
        );

        const toDate = isBeforeDate(dateBeforeNextDegree, fromDate) ? fromDate : dateBeforeNextDegree;

        return `${getDateDisplayValue(fromDate)} - ${getDateDisplayValue(toDate)}`;
    }
};
