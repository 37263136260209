import { PhonePrefix } from "@medhelp/ui";
import { useEffect, useState } from "react";

const UserProfilePhonePrefix = ({ value, setValue }: { value: string; setValue: (value: string) => void }) => {
    const [mobilePrefix, setMobilePrefixValue] = useState(value);
    useEffect(() => {
        setValue(mobilePrefix);
    }, [mobilePrefix, setValue]);
    return <PhonePrefix value={mobilePrefix} setValue={setMobilePrefixValue} className="border-r-0" />;
};
export default UserProfilePhonePrefix;
