import Icon from "components/Icon";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import { getRetailerBg, handleSessionStorage } from "pages/login/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { setOverflow } from "utils/setOverflow";
import { userActions } from "store/userSlice";
import { useAppDispatch } from "store/hooks";
import { getDateForwardOrBack, getDiffInTimeUnit, newDate } from "utils/date";
export interface ISessionModalProps {
    activeRetailer: string;
}
const SessionModal = ({ activeRetailer }: ISessionModalProps) => {
    const { t } = useTranslation("login");
    const [open, setOpen] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>(0);
    const dispatch = useAppDispatch();
    const handleClose = () => {
        setOverflow("auto");
        setOpen(false);
    };
    const handleClick = (extendSession?: boolean) => {
        if (extendSession) {
            handleSessionStorage();
            setTimer(0);
        }
        handleClose();
    };
    useEffect(() => {
        if (timer > -1) {
            let interval = 30;
            if (timer === 0) {
                const timeSinceLastAction = getDiffInTimeUnit(
                    "seconds",
                    "forward",
                    newDate(),
                    sessionStorage.getItem("mhSession") ?? getDateForwardOrBack(2, "hours", "forward"),
                );
                timeSinceLastAction < 1 ? dispatch(userActions.signOut()) : setTimer(timeSinceLastAction);
            } else if (timer > 896 && timer < 900) {
                setOpen(true);
                interval = 1;
            } else if (timer > 0 && timer < 936) interval = 1;
            const intervalId = setInterval(() => {
                setTimer((t) => t - interval);
            }, interval * 1000);
            return () => clearInterval(intervalId);
        }
    }, [dispatch, timer]);
    const getTime = (): string => {
        const timerMinutes = Math.floor(timer / 60);
        const minutes = timerMinutes < 10 ? `0${timerMinutes}` : timerMinutes.toString();
        const timerSeconds = Math.floor(timer % 60);
        const seconds = timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds.toString();
        return `${minutes}:${seconds}.`;
    };
    return (
        <ReactModal
            id="loginModal"
            isOpen={open}
            className="w-screen h-screen absolute top-0 sm:mt-18 sm:max-w-xl sm:h-auto"
            style={{
                overlay: {
                    backgroundColor: "rgba(94, 95, 86, 0.5)",
                    zIndex: 10,
                },
            }}
            onAfterOpen={() => setOverflow("hidden")}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className={`${activeRetailer} h-full flex flex-col`}>
                <div className="py-10 w-full flex justify-center items-center bg-grey-100 sm:py-17">
                    <Icon icon="clock" size={150} />
                </div>
                <div
                    className={`${getRetailerBg(
                        activeRetailer,
                        true,
                    )} w-full h-full relative flex justify-center sm:h-96`}
                >
                    <div className="mt-8 px-6 flex flex-col items-center">
                        <h2>{t("sessionAboutToExpire")}</h2>
                        {open && (
                            <>
                                <div className="mt-6 flex">
                                    <p className="mr-1">{t("extendSessionQuestion")}</p>
                                    <p className="w-11">{getTime()}</p>
                                </div>
                                <p>{t("extendIt")}</p>
                            </>
                        )}
                    </div>
                    <div className="w-full px-6 absolute bottom-7 flex flex-col sm:flex-row sm:justify-center">
                        <SecondaryButton
                            id="modalTertButton"
                            text={t("nope")}
                            className={`w-full mb-4 flex justify-center sm:mr-4 sm:-mb:0 sm:w-13`}
                            onClick={() => handleClick()}
                        />
                        <PrimaryButton
                            id="modalPrimary"
                            text={t("extendSession")}
                            className="w-full flex justify-center sm:w-52"
                            onClick={() => handleClick(true)}
                        />
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};
export default SessionModal;
