/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getEmploymentsBySSN } from "../absenceReportsActions";
import { IAbsenceReportSlice } from "../absenceReportsSlice";

const employmentBySSN = (builder: ActionReducerMapBuilder<IAbsenceReportSlice>) =>
    builder
        .addCase(getEmploymentsBySSN.fulfilled, (state, action) => {
            state.SearchResult = action.payload;
        })
        .addCase(getEmploymentsBySSN.rejected, (state) => {
            state.SearchResult = [];
        });

export default employmentBySSN;
