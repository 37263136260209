import { isEmpty } from "lodash/fp";
import { getServices } from "api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { MedHelpPeopleDomainWidget } from "swagger/people";
import {
    MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter,
    MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter,
} from "swagger/absencefollowup";
import { AbsenceWorkRatioRequest } from "swagger/statistics";
import { OngoingRehabFilter } from "swagger/rehab";
import {
    WidgetDropdownTypeKeys,
    isWidgetFollowUp,
    isWidgetRehab,
    isWidgetStatistics,
    isWidgetHCM,
    WidgetType,
    IWidget,
} from "../utils/dashboardTypes";
import { dashboardActions, initialWidgetFilterState } from "./dashboardSlice";
import {
    getWidgetFilterRequestModel,
    getWidgetHCMRequestModel,
    getWidgetRequestModelWithCompanyId,
    getWidgetStatisticsRequestModel,
    isRehab,
} from "../utils/dashboardActionsUtils";
import {
    getEmploymentGroupsRequest,
    getDepartmentsRequest,
    fetchWidgetList,
    fetchCompanyGroupForCompanyId,
} from "../utils/dashboardSliceUtils";
import { AccountAccessRightViewModel } from "swagger/usercontextservice";
import { RootState } from "store";
import { hasCustomerSupport } from "utils/accessRights";
import { widgetMap } from "../components/Widget";
import {
    MedHelpHCMWebApiRequestDashboardFiltersRequest,
    MedHelpHCMWebApiRequestSearchHealthCaseRequest,
    MedHelpHCMWebApiResponseSearchHealthCaseResponse,
} from "swagger/healthCaseManagement";
import {
    getCustomerId,
    getUserLanguage,
    getIsRehabProcessManagement,
    getRegion,
    getHasBaseProduct,
    getHasAbsenceStatistics,
} from "store/userSelectors";
import axios from "axios";
import { INews } from "../components/NewsWidget/medhelpNews";
import { getCompanies, getIsAnyCompanySelected } from "./dashboardSelectors";
import { filterCompaniesByAccessRights } from "../utils/dashboardAccessRights";
import { Status } from "@medhelp/ui/LoadableComponent";
import { EmploymentGroupContract, TreeViewNode } from "swagger/employeeattendance";

export interface IUpdateDropDownItems {
    id: WidgetDropdownTypeKeys;
    referenceKey: number | string;
    widgetId: string;
}

export interface IUpdateAllDropDownItems {
    id: WidgetDropdownTypeKeys;
    allSelected: boolean;
    widgetId: string;
}

export interface IWidgetDropdownItems {
    widgetId: string;
    departments: TreeViewNode[];
    employmentGroups: EmploymentGroupContract[];
}

interface IWidgetDropdownParams {
    companyId: number;
    typeOfSearch: string;
    widgetId: string;
}

interface IFollowUpWidgetParams {
    filter: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter;
    id: string;
}

interface IRehabWidgetParams {
    filter: OngoingRehabFilter;
    id: string;
}

interface IHCMWidgetParams {
    filter: MedHelpHCMWebApiRequestDashboardFiltersRequest;
    id: string;
}

interface IAbsencesPerCause {
    filter: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter;
    id: string;
}

interface IAbsenceWorkRatio {
    filter: AbsenceWorkRatioRequest;
    id: string;
}

interface IAbsenceForecastWidgetParams {
    companyId: number;
    id: string;
}

export interface IHCMTemplateRequest {
    customerId: number;
    language: string;
}

interface IFetchAbsenceSummaryDetails {
    keyName: string;
    companyIds: number[];
}

interface IFetchDashboardInit {
    companyId: number;
    accessRights: Array<AccountAccessRightViewModel>;
}

export const fetchDashboardInit = createAsyncThunk(
    "fetchDashboardInit",
    async ({ companyId, accessRights }: IFetchDashboardInit, { getState }) => {
        const state = getState() as RootState;
        const customerId = getCustomerId(state);
        const language = getUserLanguage(state);
        const isRehabProcessManagement = getIsRehabProcessManagement(state);
        const hasAbsenceStatistics = getHasAbsenceStatistics(state);
        const hasBaseProduct = getHasBaseProduct(state);
        const region = getRegion(state) ?? "sweden";

        const widgets = await fetchWidgetList();

        const companies = await fetchCompanyGroupForCompanyId(companyId);

        const employmentGroups = await getEmploymentGroupsRequest(widgets, accessRights, companies);

        const departments = await getDepartmentsRequest(widgets, accessRights, companies);

        const absenceCauses = hasAbsenceStatistics
            ? await getServices()
                  .clients.statisticsDashboardMetric.dashboardMetricCausesByRegionPost(region)
                  .then((x) => x.data)
                  .catch(() => [])
            : [];

        const templates = isRehabProcessManagement
            ? await getServices()
                  .clients.template.templateListCustomerIdGet(customerId ?? 0, undefined, language)
                  .then((x) => x.data)
                  .catch(() => [])
            : [];

        return {
            widgets,
            companies,
            accessRights,
            departments,
            employmentGroups,
            absenceCauses,
            templates,
            hasBaseProduct,
        };
    },
);

export const fetchNews = createAsyncThunk("fetchNews", async () => {
    // needed in order to exclude auth headers from get request
    const instance = axios.create();
    const { data } = await instance({
        method: "GET",
        url: "https://medhelp-image-assets.s3.eu-west-1.amazonaws.com/news.json",
    });
    return data as INews[];
});

export const searchCompanies = createAsyncThunk("searchCompanies", async (query: string) => {
    const { data: companies } = await getServices().clients.customerSupport.apiCustomerSupportSearchCompaniesPost({
        searchTerms: query,
    });
    return companies;
});

interface IUpdateWidget {
    id: string;
    type: WidgetType;
}

export const updateWidgetOrder = createAsyncThunk("updateWidgetOrder", async (widgets: IWidget[], { getState }) => {
    const state = getState() as RootState;
    const userContext = state.user.userContextExtended;
    const dashboard = state.dashboard;

    const widgetRequestModel = widgets.map((widget, index) => {
        return {
            id: widget.id,
            userAccountId: userContext.userAccount?.id,
            type: widget.type,
            filter: JSON.stringify(getWidgetFilterRequestModel(widget.id, dashboard, isRehab(widget.type))),
            customHeading: widget.customHeading,
            order: index + 1,
        };
    });

    const isCustomerSupport = hasCustomerSupport(userContext.userAccount?.accessRights || []);
    if (!isCustomerSupport) {
        await getServices().clients.widget.widgetPut(widgetRequestModel);
    }

    return widgets;
});

export const updateWidget = createAsyncThunk(
    "updateWidget",
    async ({ id, type }: IUpdateWidget, { getState, dispatch }) => {
        const state = getState() as RootState;
        const dashboard = state.dashboard;

        const widgetFilter = getWidgetFilterRequestModel(id, dashboard, isRehab(type));

        const userContext = state.user.userContextExtended;

        const widgetRequestModel = state.dashboard.Widgets?.map((widget, index) => {
            return {
                id: widget.id,
                userAccountId: userContext.userAccount?.id,
                type: widget.type,
                filter: JSON.stringify(getWidgetFilterRequestModel(widget.id, dashboard, isRehab(widget.type))),
                customHeading: widget.customHeading,
                order: index + 1,
            };
        });

        const isCustomerSupport = hasCustomerSupport(userContext.userAccount?.accessRights || []);
        if (!isCustomerSupport) {
            await getServices().clients.widget.widgetPut(widgetRequestModel);
        } else console.log("not saving widget because user is CS");

        const companies = dashboard.Widgets?.find((widget) => widget.id === id)?.companies || [];

        if (companies.length === 1 || !isEmpty(widgetFilter?.companyIds)) {
            dispatch(fetchWidgetData({ id, type }));
        }
    },
);

export const deleteWidget = createAsyncThunk("deleteWidget", async (id: string, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const newState = state.dashboard.Widgets?.filter((widget) => widget.id !== id).map((widget, index) => ({
        ...widget,
        order: index + 1,
    }));

    const widgetRequestModel: MedHelpPeopleDomainWidget[] =
        newState?.map((widget) => ({
            id: widget.id,
            userAccountId: state.user.userContextExtended?.userAccount?.id,
            type: widget.type,
            filter: widget.filter ? JSON.stringify(widget.filter) : null,
            customHeading: widget.customHeading,
            order: widget.order,
        })) ?? [];

    await getServices().clients.widget.widgetPut(widgetRequestModel);
    return newState;
});

export const fetchOngoingAbsence = createAsyncThunk(
    "fetchOngoingAbsence",
    async ({ filter, id }: IFollowUpWidgetParams) => {
        const { data } = await getServices().clients.dashboardMetric.dashboardMetricOngoingAbsencePost(filter);
        return { data, id };
    },
);

export const fetchLastReportedAbsence = createAsyncThunk(
    "fetchLastReportedAbsence",
    async ({ filter, id }: IFollowUpWidgetParams) => {
        const { data } = await getServices().clients.dashboardMetric.dashboardMetricLastReportedAbsencePost(filter);
        return { data, id };
    },
);

export const fetchAbsencePercentLast6Months = createAsyncThunk(
    "fetchAbsencePercentLast6Months",
    async ({ filter, id }: IAbsenceWorkRatio) => {
        const { data } = await getServices().clients.absenceRatio.absenceWorkRatioPost(filter);
        return { data, id };
    },
);

export const fetchAbsencePercentPerMonth = createAsyncThunk(
    "fetchAbsencePercentPerMonth",
    async ({ filter, id }: IAbsenceWorkRatio) => {
        const { data } = await getServices().clients.absenceRatio.absenceWorkRatioPost(filter);
        return { data, id };
    },
);

export const fetchWorkRelatedAbsence = createAsyncThunk(
    "fetchWorkRelatedAbsence",
    async ({ filter, id }: IFollowUpWidgetParams) => {
        const { data } = await getServices().clients.dashboardMetric.dashboardMetricWorkRelatedAbsencePost(filter);
        return { data, id };
    },
);

export const fetchEstimatedUpcomingAbsence = createAsyncThunk(
    "fetchEstimatedUpcomingAbsence",
    async ({ filter, id }: IFollowUpWidgetParams) => {
        const { data } = await getServices().clients.dashboardMetric.dashboardMetricEstimatedAbsencePost(filter);
        return { data, id };
    },
);

export const fetchRecurringAbsenceLast12Months = createAsyncThunk(
    "fetchRecurringAbsenceLast12Months",
    async ({ filter, id }: IFollowUpWidgetParams) => {
        const { data } = await getServices().clients.dashboardMetric.dashboardMetricRecurringAbsencePost(filter);
        return { data, id };
    },
);

export const fetchHCMStatusOverview = createAsyncThunk(
    "fetchHCMStatusOverview",
    async ({ filter, id }: IHCMWidgetParams) => {
        const { data } = await getServices().clients.healthCaseDashboard.dashboardStatusOverviewPost(filter);
        return { data, id };
    },
);

export const fetchHCMTodo = createAsyncThunk("fetchHCMTodo", async ({ filter, id }: IHCMWidgetParams) => {
    const { data } = await getServices().clients.healthCaseDashboard.dashboardAttentionsAndOngoingPost(filter);
    const attentionIds =
        data.attentions
            ?.filter((responsible) => !responsible.responsibleName)
            .map((responsible) => responsible.responsibleUserAccountId ?? 0) ?? [];
    const ongoingIds =
        data.ongoing
            ?.filter((responsible) => !responsible.responsibleName)
            .map((responsible) => responsible.responsibleUserAccountId ?? 0) ?? [];

    const combinedUserAccountIds = attentionIds.concat(ongoingIds);

    if (isEmpty(combinedUserAccountIds)) {
        return {
            data,
            id,
        };
    } else {
        const userRehabRes = await getServices().clients.userRehab.apiUserRehabaccessPost(combinedUserAccountIds);
        const attentions = data.attentions?.map((responsible) => {
            const matchingUser = userRehabRes.data.find(({ id }) => id === responsible.responsibleUserAccountId);
            if (matchingUser) {
                responsible.responsibleName = matchingUser.user?.name;
            }
            return responsible;
        });
        const ongoing = data.ongoing?.map((responsible) => {
            const matchingUser = userRehabRes.data.find(({ id }) => id === responsible.responsibleUserAccountId);
            if (matchingUser) {
                responsible.responsibleName = matchingUser.user?.name;
            }
            return responsible;
        });
        return {
            data: { attentions, ongoing },
            id,
        };
    }
});

export const fetchOngoingRehab = createAsyncThunk("fetchOngoingRehab", async ({ filter, id }: IRehabWidgetParams) => {
    const { data } = await getServices().clients.rehabMetric.apiDashboardMetricGetOngoingRehabPost(filter);
    return { data, id };
});

export const fetchLastActivatedRehab = createAsyncThunk(
    "fetchLastActivatedRehab",
    async ({ filter, id }: IRehabWidgetParams) => {
        const { data } = await getServices().clients.rehabMetric.apiDashboardMetricGetLatestOngoingRehabPost(filter);
        return { data, id };
    },
);

export const fetchAbsenceForecastCurrentMonth = createAsyncThunk(
    "fetchAbsenceForecastCurrentMonth",
    async ({ companyId, id }: IAbsenceForecastWidgetParams) => {
        const { data } = await getServices().clients.forecast.forecastGet(companyId);
        return { data, id };
    },
);

export const fetchAbsencesPerCause = createAsyncThunk(
    "fetchAbsencesPerCause",
    async ({ filter, id }: IAbsencesPerCause) => {
        const { data } =
            await getServices().clients.statisticsDashboardMetric.dashboardMetricAbsencesPerCausePost(filter);
        return { data, id };
    },
);

export const fetchHCMTemplates = createAsyncThunk(
    "fetchHCMTemplates",
    async ({ customerId, language }: IHCMTemplateRequest) => {
        const { data } = await getServices().clients.template.templateListCustomerIdGet(
            customerId,
            undefined,
            language,
        );
        return data;
    },
);

export const fetchCheckedDropdownItems = createAsyncThunk(
    "fetchCheckedDropdownItems",
    async (dropDownItemArgs: IUpdateDropDownItems & { accessRight?: string }, { getState, dispatch }) => {
        const state = getState() as RootState;
        const companies = getCompanies(dropDownItemArgs.widgetId)(state);

        const checkedCompanies = companies?.filter((company: any) => {
            // checkCompanies contains the same given componie (checked by refrenceKey?) and it is true
            if (dropDownItemArgs.referenceKey === company.referenceKey) {
                return !company.checked;
            }
            return company.checked;
        });

        // remove unchecked companie than check length if one than fetch
        if (checkedCompanies?.length === 1 && dropDownItemArgs.id === "companies") {
            dispatch(
                fetchWidgetDropdownItems({
                    companyId: (checkedCompanies[0].id as number) || 0,
                    typeOfSearch: dropDownItemArgs.accessRight || "",
                    widgetId: dropDownItemArgs?.widgetId,
                }),
            );
        }
        if (isEmpty(checkedCompanies) && companies?.length !== 1) {
            dispatch(
                dashboardActions.resetWidgetDropdown({
                    id: dropDownItemArgs.id,
                    referenceKey: dropDownItemArgs.referenceKey,
                    widgetId: dropDownItemArgs.widgetId,
                }),
            );
        }

        dispatch(dashboardActions.updateCheckedDropdownItems(dropDownItemArgs));
        return dropDownItemArgs;
    },
);

export const fetchWidgetDropdownItems = createAsyncThunk<IWidgetDropdownItems, IWidgetDropdownParams>(
    "fetchWidgetDropdownItems",
    async ({ widgetId, companyId, typeOfSearch }: IWidgetDropdownParams): Promise<IWidgetDropdownItems> => {
        const promises = [
            getServices().clients.organization.apiOrganizationGetOrganizationTreeViewGet(companyId, typeOfSearch),
            getServices().clients.organization.apiOrganizationGetEmploymentGroupsGet(companyId),
        ];
        const values = (await Promise.allSettled(promises)).map((result) => {
            if (result.status === "fulfilled") {
                return result.value.data;
            } else {
                return null;
            }
        });
        return {
            widgetId,
            departments: values[0] as TreeViewNode[],
            employmentGroups: values[1] as EmploymentGroupContract[],
        };
    },
);

export const fetchAbsenceSummary = createAsyncThunk("fetchAbsenceSummary", async (companyIds: number[]) => {
    const { data } = await getServices().clients.dashboardMetric.dashboardMetricSummaryAbsencePost({
        companyIds: companyIds,
    });
    return data;
});

export const fetchAbsenceSummaryDetails = createAsyncThunk(
    "fetchAbsenceSummaryDetails",
    async ({ keyName, companyIds }: IFetchAbsenceSummaryDetails) => {
        const { data } = await getServices().clients.dashboardMetric.dashboardMetricSummaryAbsenceExtensionPost(
            keyName,
            {
                companyIds,
                departmentIds: [],
            },
        );
        return data;
    },
);

export const createWidget = createAsyncThunk("createWidget", async (widgetType: WidgetType, { getState, dispatch }) => {
    const { dashboard, user } = getState() as RootState;
    const userContext = user.userContextExtended;
    const currentWidget = widgetMap.get(widgetType);

    const companies = filterCompaniesByAccessRights(
        userContext.userAccount?.accessRights || [],
        dashboard.CompanyGroupForCompany || [],
        currentWidget?.accessRight || "",
    );

    const absenceCauses = dashboard.AbsenceCauses.map((cause) => ({
        id: cause,
        label: cause,
        referenceKey: cause,
        checked: false,
        selectable: true,
    }));

    const newWidget = {
        id: "",
        type: widgetType,
        filter: null,
        customHeading: "",
        chartData: null,
        order: 0,
        ...initialWidgetFilterState,
        companies: companies,
        absenceCauses: absenceCauses,
        status: Status.PENDING,
    };

    const combinedWidgets = [newWidget, ...(dashboard.Widgets || [])];

    const widgetRequestModel = combinedWidgets.map((widget, index) => ({
        id: widget.id ? widget.id : undefined,
        userAccountId: userContext.userAccount?.id,
        type: widget.type,
        order: index + 1,
        filter:
            widget.id && widget.type && widget.filter
                ? JSON.stringify(getWidgetFilterRequestModel(widget.id, dashboard, isRehab(widget.type)))
                : null,
        customHeading: widget.customHeading,
    }));

    const { data } = await getServices().clients.widget.widgetPut(widgetRequestModel);

    if (data[0].id) combinedWidgets[0].id = data[0].id;

    if (companies.length === 1) {
        if (data[0].id) {
            dispatch(
                fetchWidgetDropdownItems({
                    companyId: companies[0]?.id as number,
                    typeOfSearch: currentWidget?.accessRight || "",
                    widgetId: data[0].id,
                }),
            );
        }
    }

    return combinedWidgets;
});

export const fetchHealthCases = createAsyncThunk(
    "fetchHealthCases",
    async (request: MedHelpHCMWebApiRequestSearchHealthCaseRequest, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const language = getUserLanguage(state);

        try {
            let healthCases: MedHelpHCMWebApiResponseSearchHealthCaseResponse[] = [];
            let page = 1;

            // get all health cases instead of paginated response
            const fetchHealthCases = async () => {
                const { data } = await getServices().clients.searchHealthCase.searchHealthCaseSearchHealthCasePost({
                    ...request,
                    page,
                    language,
                });

                if (data && data.page) {
                    healthCases.push(...data.page);
                }

                if (data.page && data.page.length === 50) {
                    page += 1;
                    await fetchHealthCases();
                }
            };

            await fetchHealthCases();

            const casesWithoutResponsibleName = healthCases?.filter((responsible) => !responsible.name);

            if (!isEmpty(casesWithoutResponsibleName)) {
                try {
                    const userAccountIds = casesWithoutResponsibleName?.map(
                        (responsible) => Number(responsible.id) ?? 0,
                    );
                    const userRehabRes = await getServices().clients.userRehab.apiUserRehabaccessPost(userAccountIds);
                    const responsibleWithCreatedByUserName = healthCases?.map((responsible) => {
                        const matchingUser = userRehabRes.data.find(({ id }) => id === Number(responsible.id));
                        if (matchingUser) {
                            responsible.name = matchingUser.user?.name;
                        }
                        return responsible;
                    });
                    healthCases = responsibleWithCreatedByUserName;
                } catch (error) {
                    console.log(error);
                }
            }

            return healthCases;
        } catch (error) {
            return rejectWithValue("Unable to fetch health cases");
        }
    },
);

interface IFetchWidgetData {
    id: string;
    type: WidgetType;
}

export const fetchWidgetData = createAsyncThunk(
    "fetchWidgetData",
    async ({ id, type }: IFetchWidgetData, { getState, dispatch }) => {
        const state = getState() as RootState;
        const dashboard = state.dashboard;
        const isAnyCompanySelectedSelector = getIsAnyCompanySelected(id);
        const isAnyCompanySelected = isAnyCompanySelectedSelector(state);
        if (!isAnyCompanySelected) return;

        const currentWidget = widgetMap.get(type);
        const widgetFilter = getWidgetFilterRequestModel(id, dashboard, isRehab(type));
        const companies = dashboard.Widgets?.find((widget) => widget.id === id)?.companies || [];
        const widgetFilterWithCompanyId = getWidgetRequestModelWithCompanyId(widgetFilter, companies[0].id as number);

        if (isWidgetFollowUp(widgetFilterWithCompanyId, type)) {
            switch (type) {
                case "ongoing-absence":
                    dispatch(fetchOngoingAbsence({ filter: widgetFilterWithCompanyId, id }));
                    break;
                case "last-reported-absence":
                    dispatch(fetchLastReportedAbsence({ filter: widgetFilterWithCompanyId, id }));
                    break;
                case "work-related-absence":
                    dispatch(fetchWorkRelatedAbsence({ filter: widgetFilterWithCompanyId, id }));
                    break;
                case "estimated-upcoming-absence":
                    dispatch(fetchEstimatedUpcomingAbsence({ filter: widgetFilterWithCompanyId, id }));
                    break;
                case "recurring-absence-last-12-months":
                    dispatch(fetchRecurringAbsenceLast12Months({ filter: widgetFilterWithCompanyId, id }));
                    break;

                default:
                    break;
            }
        }

        if (isWidgetStatistics(widgetFilter, type)) {
            const widgetStatisticsFilter = getWidgetStatisticsRequestModel(
                widgetFilter,
                (companies[0].id as number) ?? 0,
                currentWidget,
            );

            switch (type) {
                case "absence-percent-last-6-months":
                    dispatch(fetchAbsencePercentLast6Months({ filter: widgetStatisticsFilter, id }));
                    break;
                case "absence-percent-per-month":
                    dispatch(fetchAbsencePercentPerMonth({ filter: widgetStatisticsFilter, id }));
                    break;
                case "absence-forecast-current-month":
                    dispatch(fetchAbsenceForecastCurrentMonth({ companyId: widgetStatisticsFilter.companyIds[0], id }));
                    break;
                case "absence-cause-proportion":
                    dispatch(fetchAbsencesPerCause({ filter: widgetStatisticsFilter, id }));
                    break;
                default:
                    break;
            }
        }

        if (isWidgetRehab(widgetFilterWithCompanyId, type)) {
            switch (type) {
                case "last-activated-rehab":
                    dispatch(fetchLastActivatedRehab({ filter: widgetFilterWithCompanyId, id }));
                    break;
                case "ongoing-rehab":
                    dispatch(fetchOngoingRehab({ filter: widgetFilterWithCompanyId, id }));
                    break;
                default:
                    break;
            }
        }

        if (isWidgetHCM(widgetFilterWithCompanyId, type) && !isEmpty(dashboard.HCMTemplates.data)) {
            const widgetHCMFilter = getWidgetHCMRequestModel(
                widgetFilterWithCompanyId,
                dashboard.HCMTemplates.data,
                (companies[0].id as number) ?? 0,
            );
            switch (type) {
                case "hcm-status-overview":
                    dispatch(fetchHCMStatusOverview({ filter: widgetHCMFilter, id }));
                    break;
                case "hcm-todo":
                    dispatch(fetchHCMTodo({ filter: widgetHCMFilter, id }));
                    break;
                default:
                    break;
            }
        }
    },
);
