/** builder for dealing with `async actions` */
import { flow, sortBy, reverse, isEmpty } from "lodash/fp";
import { isUndefined } from "lodash/fp";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchAbsenceOverview } from "../followUpAsyncActions";
import { IState, Status } from "..";
import { MedHelpAbsenceFollowUpWebApiResponseAbsence } from "swagger/absencefollowup";

const fetchAbsenceReports = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchAbsenceOverview.fulfilled, (state, action) => {
            const { absenceOverview, deviant, absenceList } = action.payload;
            if (isEmpty(absenceList)) {
                state.status.noData = true;
            }
            state.absenceOverview = absenceOverview;
            state.status.absenceOverview = Status.OK;
            state.absenceList = flow(
                sortBy((list: MedHelpAbsenceFollowUpWebApiResponseAbsence) => {
                    const dateToCompare = list.endDate ? "endDate" : "backAtWork";
                    return list[dateToCompare];
                }),
                reverse,
            )(absenceList);
            state.status.absenceList = Status.OK;
            state.deviant = deviant;
            state.status.deviant = Status.OK;
        })
        .addCase(fetchAbsenceOverview.pending, (state) => {
            state.status.absenceOverview = Status.PENDING;
            state.status.absenceList = Status.PENDING;
            state.status.deviant = Status.PENDING;
        })
        .addCase(fetchAbsenceOverview.rejected, (state, action) => {
            const absenceOverview = action.payload?.absenceOverview;
            if (!isUndefined(absenceOverview)) {
                state.absenceOverview = absenceOverview.data;
                state.status.absenceOverview = Status.OK;
            } else {
                state.status.absenceOverview = Status.ERROR;
            }
            const absenceList = action.payload?.absenceList;
            if (!isUndefined(absenceList)) {
                state.absenceList = flow(
                    sortBy((list: MedHelpAbsenceFollowUpWebApiResponseAbsence) => {
                        const dateToCompare = list.endDate ? "endDate" : "backAtWork";
                        return list[dateToCompare];
                    }),
                    reverse,
                )(absenceList.data);
                state.status.absenceList = Status.OK;
            } else {
                state.status.absenceList = Status.ERROR;
            }
            const deviant = action.payload?.deviant;
            if (!isUndefined(deviant)) {
                state.deviant = deviant.data;
                state.status.deviant = Status.OK;
            } else {
                state.status.deviant = Status.ERROR;
            }
        });

export default fetchAbsenceReports;
