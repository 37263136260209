import { useEffect } from "react";
import ErrorWidget from "../ErrorWidget";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { ListChart } from "pages/dashboard/components/Charts";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import WorkRelatedAbsenceWidgetLoader from "./WorkRelatedAbsenceWidgetLoader";
import { getIsDragging, getWorkRelatedAbsence } from "pages/dashboard/redux/dashboardSelectors";

const WorkRelatedAbsenceWidget = ({ id, type }: Widget) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("dashboard");
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getWorkRelatedAbsence(id));

    useEffect(() => {
        !isDragging && dispatch(fetchWidgetData({ id, type }));
    }, [dispatch, id, type, isDragging]);

    if (status === Status.PENDING) {
        return <WorkRelatedAbsenceWidgetLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <ListChart
            keyHeading={t("typeOfAbsence")}
            dataHeading={t("amount")}
            hasDate={false}
            hasModal={true}
            color={"primary"}
            id={"work-related-absence"}
            data={[
                {
                    heading: t("workRelated"),
                    modalHeading: t("ongoingWorkRelatedAbsence"),
                    data: data.workRelatedOngoing || 0,
                    employeeDetails: data.employeeDetails?.filter((x) => x.processType.toLowerCase() === "workrelated"),
                    type: "workRelated",
                },
                {
                    heading: t("workPlaceAccidents"),
                    modalHeading: t("ongoingWorkRelatedAbsenceWorkPlaceAccident"),
                    data: data.workPlaceAccidentOngoing || 0,
                    employeeDetails: data.employeeDetails?.filter(
                        (x) => x.processType.toLowerCase() === "workplaceaccident",
                    ),
                    type: "workPlaceAccident",
                },
            ]}
        />
    );
};

export default WorkRelatedAbsenceWidget;
