import { Config } from "config";
import { FortnoxApiFactory } from "swagger/fortnox";

const fortnoxApi = () => FortnoxApiFactory(undefined, Config.api_fortnox_url);

export const fetchCompanyInfo = async (code: string) => {
    const { data } = await fortnoxApi().fortnoxCompanyInfoGet(code);
    return data;
};

export const activateCompany = async (fortnoxConfigurationId: string, adminEmployeeId: string) => {
    const { data } = await fortnoxApi().fortnoxActivationPost({
        fortnoxConfigurationId,
        adminEmployeeId,
    });
    return data;
};
