import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchCaseResponsibleList as fetchCaseResponsibleListAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchCaseResponsibleList = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchCaseResponsibleListAction.fulfilled, (state, action) => {
            if (action.payload) {
                state.caseResponsibleList = action.payload;
            }
            state.status.caseResponsibleList = Status.OK;
        })
        .addCase(fetchCaseResponsibleListAction.pending, (state) => {
            state.status.caseResponsibleList = Status.PENDING;
        })
        .addCase(fetchCaseResponsibleListAction.rejected, (state) => {
            state.status.caseResponsibleList = Status.ERROR;
        });

export default fetchCaseResponsibleList;
