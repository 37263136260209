import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback, useMemo, useEffect } from "react";
import { selectors, actions, thunkActions } from "../../redux";
import * as UserSelectors from "store/userSelectors";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { absenceReportIsValid, getAbsenceReportFieldByName } from "../../utils/absenceReports";
import { isEmpty } from "lodash/fp";
import AbsenceReportBaseWizard from "./AbsenceReportBaseWizard";
import { getPrivateMobileInitialValue } from "utils/phone";

export default function Index() {
    const dispatch = useAppDispatch();
    const employmentGuid = useAppSelector(selectors.getEmploymentGuid);
    const selectedAbsenceReport = useAppSelector(selectors.getSelectedAbsenceReport);
    const isDynamicMedicalAdvisory = useAppSelector(selectors.getConfigurationByName("dynamicMedicalAdvisory"));
    const isRiskDynamicMedicalAdvisory = useAppSelector(selectors.isRiskDynamicMedicalAdvisory);
    const absenceReportType = useAppSelector(selectors.getAbsenceReportType);
    const template = useAppSelector(selectors.getSelectedTemplate);
    const userContactInfo = useAppSelector(UserSelectors.getUserContactInfo);
    const userCountryCode = useAppSelector(UserSelectors.getUserCountryCode);

    useEffect(() => {
        if (isDynamicMedicalAdvisory && absenceReportType === "Sick") {
            dispatch(thunkActions.getIsRisk());
        }
    }, [absenceReportType, dispatch, isDynamicMedicalAdvisory]);

    const handleOnCompleteAbsenceBase = useCallback(
        (absenceReport: IAbsenceReportExtended) => {
            if (absenceReportIsValid(absenceReport)) {
                dispatch(
                    actions.updateSelectedAbsenceReportBase({
                        ...absenceReport,
                        employmentId: employmentGuid,
                    }),
                );
            }
        },
        [dispatch, employmentGuid],
    );

    const handleOnUpdateAbsenceReportBaseChange = useCallback(
        (fieldName: string, value: any, endDate?: string | undefined) => {
            if (selectedAbsenceReport) {
                if (fieldName === "degree") {
                    dispatch(
                        actions.updateSelectedAbsenceReportDegrees({
                            value,
                            timeStamp: selectedAbsenceReport.start,
                        }),
                    );
                } else {
                    if (endDate) {
                        dispatch(
                            actions.updateSelectedAbsenceReportBaseDates({
                                fieldName,
                                value,
                                endDate,
                            }),
                        );
                    }
                }
            }
        },
        [selectedAbsenceReport, dispatch],
    );

    const handleUpdateField = useCallback(
        (fieldName: string, value: string) => {
            dispatch(
                actions.updateSelectedAbsenceReportField({
                    fieldName,
                    value,
                    templateFields: template?.fields ?? [],
                }),
            );
        },
        [dispatch, template?.fields],
    );

    const isLastPage = useMemo(() => {
        return isEmpty(template?.fields) ? true : false;
    }, [template]);

    const handleSubmit = useCallback(() => {
        if (selectedAbsenceReport && template?.id) {
            dispatch(
                thunkActions.submitSelectedAbsenceReport({
                    absenceReport: selectedAbsenceReport,
                    templateId: template.id,
                }),
            );
        }
    }, [dispatch, selectedAbsenceReport, template?.id]);

    const handleForwardClick = useCallback(() => {
        const mobileFieldValue = getAbsenceReportFieldByName(template?.fields ?? [], "Mobile");
        if (mobileFieldValue && userContactInfo && userCountryCode) {
            const mobileValue = getPrivateMobileInitialValue(userContactInfo, userCountryCode);
            handleUpdateField("Mobile", mobileValue);
        }
        if (isDynamicMedicalAdvisory && absenceReportType === "Sick") {
            handleUpdateField("RiskMedicalAdvisoryRequest", isRiskDynamicMedicalAdvisory ? "true" : "false");
        }
        if (isLastPage) {
            handleSubmit();
        }
        dispatch(actions.updateAbsenceReportPageNumber("next"));
    }, [
        absenceReportType,
        dispatch,
        handleSubmit,
        handleUpdateField,
        isDynamicMedicalAdvisory,
        isLastPage,
        isRiskDynamicMedicalAdvisory,
        template?.fields,
        userContactInfo,
        userCountryCode,
    ]);

    const absenceReportBaseIsValid = useMemo(() => {
        return absenceReportIsValid(selectedAbsenceReport);
    }, [selectedAbsenceReport]);

    return (
        <AbsenceReportBaseWizard
            isUpdate={selectedAbsenceReport?.employmentId ? true : false}
            onComplete={handleOnCompleteAbsenceBase}
            onUpdate={handleOnUpdateAbsenceReportBaseChange}
            setForwardClick={handleForwardClick}
            currentPageIsValid={absenceReportBaseIsValid}
            isLastPage={isLastPage}
        />
    );
}
