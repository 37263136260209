import Icon from "components/Icon";

const ErrorWidget = () => {
    return (
        <div className="flex flex-col h-full items-center justify-center pt-3 pb-10">
            <div>
                <Icon icon="close" size={100} />
            </div>
            <p>Ops... Something went wrong</p>
        </div>
    );
};

export default ErrorWidget;
