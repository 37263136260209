import React, { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import "react-day-picker/dist/style.css";
import "./css/index.css";
import "./css/custom.css";
import "./css/custom-components.css";
import "./css/spinner.css";
import "./css/react-day-picker.css";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/index";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from "./locales/en/common.json";
import translationsSV from "./locales/sv/common.json";
import translationsDA from "./locales/da/common.json";
import translationsNO from "./locales/no/common.json";
import aboutTranslationsEN from "./locales/en/about.json";
import aboutTranslationsSV from "./locales/sv/about.json";
import aboutTranslationsDA from "./locales/da/about.json";
import aboutTranslationsNO from "./locales/no/about.json";
import supportTranslationsEN from "./locales/en/support.json";
import supportTranslationsSV from "./locales/sv/support.json";
import supportTranslationsDA from "./locales/da/support.json";
import supportTranslationsNO from "./locales/no/support.json";
import serviceTranslationsEN from "./locales/en/service.json";
import serviceTranslationsSV from "./locales/sv/service.json";
import serviceTranslationsDA from "./locales/da/service.json";
import serviceTranslationsNO from "./locales/no/service.json";
import dashboardTranslationsEN from "./locales/en/dashboard.json";
import dashboardTranslationsSV from "./locales/sv/dashboard.json";
import dashboardTranslationsDA from "./locales/da/dashboard.json";
import dashboardTranslationsNO from "./locales/no/dashboard.json";
import followupTranslationsEN from "./locales/en/followup.json";
import followupTranslationsSV from "./locales/sv/followup.json";
import followupTranslationsDA from "./locales/da/followup.json";
import followupTranslationsNO from "./locales/no/followup.json";
import loginTranslationsEN from "./locales/en/login.json";
import loginTranslationsSV from "./locales/sv/login.json";
import loginTranslationsDA from "./locales/da/login.json";
import loginTranslationsNO from "./locales/no/login.json";
import userSettingsTranslationsEN from "./locales/en/userSettings.json";
import userSettingsTranslationsSV from "./locales/sv/userSettings.json";
import userSettingsTranslationsDA from "./locales/da/userSettings.json";
import userSettingsTranslationsNO from "./locales/no/userSettings.json";
import workOrderTranslationsEN from "./locales/en/workorder.json";
import workOrderTranslationsSV from "./locales/sv/workorder.json";
import workOrderTranslationsDA from "./locales/da/workorder.json";
import workOrderTranslationsNO from "./locales/no/workorder.json";
import administrationTranslationsEN from "./locales/en/administration.json";
import administrationTranslationsSV from "./locales/sv/administration.json";
import administrationTranslationsDA from "./locales/da/administration.json";
import administrationTranslationsNO from "./locales/no/administration.json";
import fileImportTranslationsEN from "./locales/en/fileImport.json";
import fileImportTranslationsSV from "./locales/sv/fileImport.json";
import fileImportTranslationsDA from "./locales/da/fileImport.json";
import fileImportTranslationsNO from "./locales/no/fileImport.json";
import { PersistGate } from "redux-persist/integration/react";
import { isEmpty } from "lodash";
import { createServicesUsingToken } from "api/services";
import { userActions } from "store/userSlice";
import { setUserContextExtended } from "store/userActions";
import ScrollToTop from "components/ScrollToTop";
import { useAppDispatch } from "store/hooks";

// the translations
const resources = {
    en: {
        translation: translationsEN,
        about: aboutTranslationsEN,
        support: supportTranslationsEN,
        service: serviceTranslationsEN,
        dashboard: dashboardTranslationsEN,
        followup: followupTranslationsEN,
        login: loginTranslationsEN,
        userSettings: userSettingsTranslationsEN,
        workOrder: workOrderTranslationsEN,
        administration: administrationTranslationsEN,
        fileImport: fileImportTranslationsEN,
    },
    sv: {
        translation: translationsSV,
        about: aboutTranslationsSV,
        support: supportTranslationsSV,
        service: serviceTranslationsSV,
        dashboard: dashboardTranslationsSV,
        followup: followupTranslationsSV,
        login: loginTranslationsSV,
        userSettings: userSettingsTranslationsSV,
        workOrder: workOrderTranslationsSV,
        administration: administrationTranslationsSV,
        fileImport: fileImportTranslationsSV,
    },
    da: {
        translation: translationsDA,
        about: aboutTranslationsDA,
        support: supportTranslationsDA,
        service: serviceTranslationsDA,
        dashboard: dashboardTranslationsDA,
        followup: followupTranslationsDA,
        login: loginTranslationsDA,
        userSettings: userSettingsTranslationsDA,
        workOrder: workOrderTranslationsDA,
        administration: administrationTranslationsDA,
        fileImport: fileImportTranslationsDA,
    },
    no: {
        translation: translationsNO,
        about: aboutTranslationsNO,
        support: supportTranslationsNO,
        service: serviceTranslationsNO,
        dashboard: dashboardTranslationsNO,
        followup: followupTranslationsNO,
        login: loginTranslationsNO,
        userSettings: userSettingsTranslationsNO,
        workOrder: workOrderTranslationsNO,
        administration: administrationTranslationsNO,
        fileImport: fileImportTranslationsNO,
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    fallbackLng: ["en", "sv"],
    interpolation: {
        escapeValue: false,
    },
});

export type LoginFromAppProps = {
    children: ReactNode;
};

const LoginFromApp = ({ children }: LoginFromAppProps) => {
    const dispatch = useAppDispatch();
    const parsedHash = new URLSearchParams(location.search);
    const paramsPOJO = Object.fromEntries(parsedHash.entries());

    if (isEmpty(paramsPOJO)) {
        return <>{children}</>;
    } else {
        const token = paramsPOJO.token;
        if (token) {
            createServicesUsingToken(token.toString());
            dispatch(setUserContextExtended(token.toString()));
            dispatch(userActions.loginFromApp(token));
            sessionStorage.setItem("hideWelcomeText", "true");
        }
        return <>{children}</>;
    }
};

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <LoginFromApp>
                        <App />
                    </LoginFromApp>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
);
