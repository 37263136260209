export interface BasicInfo {
    title: string;
    value?: string | number | null;
}

interface IBasicInfo {
    name?: string | null;
    basicInfo: BasicInfo[];
}

const BasicInfo = ({ name, basicInfo }: IBasicInfo) => (
    <div className="py-10">
        <h2 className="px-2 md:px-0">{name}</h2>
        <div className="px-2 md:px-0 flex flex-col md:flex-row mt-6 py-6 border-t border-b flex-wrap text-mhgrey-dark">
            {basicInfo?.map(({ title, value }, index) => (
                <div key={title} className="flex">
                    {title}: {value}
                    {index < basicInfo.length - 1 && <span className="px-3 hidden md:flex">|</span>}
                </div>
            ))}
        </div>
    </div>
);

export default BasicInfo;
