import { IUseDropdownFilters } from "../../hooks";
import DepartmentsDropdown from "../DepartmentsDropdown";
import CompaniesDropdown from "../CompaniesDropdown";

interface IProps extends IUseDropdownFilters {}
const EmployeeSearchDropdowns = ({ companies, departments }: IProps) => {
    return (
        <div className="flex flex-wrap">
            {companies && <CompaniesDropdown />}
            {departments && <DepartmentsDropdown />}
        </div>
    );
};
export default EmployeeSearchDropdowns;
