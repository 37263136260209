import { SideMenu } from "@medhelp/ui";
import Icon from "components/Icon";
import SecondaryButton from "components/SecondaryButton";
import { SearchTypes } from "../../utils";
import DisplayFilters from "../DisplayFilters";
import DisplayPeriod from "../DisplayPeriod";
import ExportSearchExcel from "../ExportSearchExcel";
import FollowUpSearchFilters from "../FollowUpSearchFilters";
import ShowAffectedBasedOnFilter from "../ShowAffectedBasedOnFilter";

interface IViewProps {
    header: string;
    buttonText: string;
    sideMenuHeader: string;
    bottomContent: JSX.Element;
    excelText: string;
    open: boolean;
    setOpen: (value: boolean) => void;
    isEmpty: boolean;
    isLoading: boolean;
    smallScreen: boolean;
    searchType: SearchTypes;
}
const SearchFilter = ({
    header,
    buttonText,
    sideMenuHeader,
    bottomContent,
    open,
    setOpen,
    isEmpty,
    isLoading,
    smallScreen,
    searchType,
}: IViewProps) => (
    <div className="flex flex-1 flex-col">
        <div className="text-2xl font-serif mb-[28px]">{header}</div>
        <div className="mt-3 md:mt-0 flex flex-1 items-center">
            <hr className="flex-1 hidden mr-7 md:block" />
            <SecondaryButton
                id="employee-searchfilter-button"
                onClick={() => setOpen(true)}
                leftIcon={<Icon icon="filter" size={12} />}
                className="w-full max-w-[335px] md:max-w-[156px] px-0 flex justify-center items-center h-[36px]"
                text={buttonText}
            >
                <p className="text-sm font-bold">{buttonText}</p>
            </SecondaryButton>
            <SideMenu
                className="flex h-1/2 flex-col"
                heading={sideMenuHeader}
                open={open}
                setOpen={setOpen}
                bottomContent={bottomContent}
                hideOnClickOutside={!smallScreen}
            >
                <div className="flex flex-col flex-1">
                    <FollowUpSearchFilters />
                </div>
            </SideMenu>
        </div>
        {!isLoading && (
            <>
                <DisplayFilters />
                {searchType !== "monthlyReport" && <DisplayPeriod />}
                {!isEmpty && (
                    <div className="hidden flex-col justify-start mb-4 items-center md:flex-row md:justify-between md:flex">
                        <ShowAffectedBasedOnFilter />
                        <div className="flex gap-2">{searchType !== "monthlyReport" && <ExportSearchExcel />}</div>
                    </div>
                )}
            </>
        )}
    </div>
);

export default SearchFilter;
