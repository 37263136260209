import { IDateTimepickerProps, ISingleDatepickerProps } from "@medhelp/ui/Datepicker/types";
import { useTranslation } from "react-i18next";
import { MedHelpAbsenceReportingDomainFieldTemplate } from "../../swagger/absencereportapi";
import InputTextArea from "../InputTextArea";
import RadioButtonGroup from "../RadioButtonGroup";
import DynamicBoolean from "./DynamicBoolean";
import DynamicDate from "./DynamicDate";
import DynamicDatetime from "./DynamicDatetime";
import DynamicMobileNumber from "./DynamicMobileNumber";
import DynamicNumber from "./DynamicNumber";
import DynamicString from "./DynamicString";

interface IFindDynamicComponentProps {
    templateField: MedHelpAbsenceReportingDomainFieldTemplate;
    value: string;
    updateField: (value: string) => void;
    datepickerProps: ISingleDatepickerProps;
    dateTimepickerProps: IDateTimepickerProps;
    withPlaceholder?: boolean;
    disabled?: boolean;
    isHorizontal?: boolean;
}

const FindDynamicComponent = (props: IFindDynamicComponentProps) => {
    const { templateField, value, updateField, datepickerProps, dateTimepickerProps, disabled } = props;
    const { t } = useTranslation();

    if (templateField.options!.length && templateField.options!.length > 1) {
        return (
            <RadioButtonGroup
                values={templateField.options!.map((c) => {
                    return {
                        value: c.value!,
                        description: c.description!,
                    };
                })}
                clicked={(selected) => updateField(selected!)}
                id={`rbg${templateField.name}`}
                selectedValue={value}
                isHorizontal
                withPlaceholder
            />
        );
    }

    switch (templateField.dataType) {
        case "bool":
            return (
                <DynamicBoolean
                    id={`dynamicBool${templateField.name}`}
                    isHorizontal
                    value={value}
                    onChange={updateField}
                    disabled={disabled!}
                />
            );
        case "date":
            return <DynamicDate {...datepickerProps} />;
        case "datetime":
            return (
                <DynamicDatetime
                    {...dateTimepickerProps}
                    timepickerHeading={t("selectTime")}
                    hideTimepicker={!dateTimepickerProps.datepickerProps.selectedDate}
                />
            );
        case "number":
            return (
                <DynamicNumber
                    id={`dynamicNumber${templateField.name}`}
                    placeholder={templateField.description || ""}
                    value={value}
                    disabled={disabled}
                    onChange={updateField}
                />
            );
        case "integer":
            return (
                <DynamicNumber
                    id={`dynamicNumber${templateField.name}`}
                    placeholder={templateField.description || ""}
                    value={value}
                    disabled={disabled}
                    onChange={updateField}
                />
            );
        default:
            if (templateField.name === "Message") {
                return (
                    <InputTextArea
                        data-testid={`inputTextArea-${templateField.name}`}
                        textAreaValue={value}
                        disabled={disabled}
                        onChange={updateField}
                    />
                );
            } else if (templateField.name === "Mobile") {
                return (
                    <DynamicMobileNumber
                        inputValue={value}
                        disabled={disabled}
                        onChange={updateField}
                        countryHeader={t("countryPrefix")}
                        phoneNumberHeader={t("mobileNumber")}
                    />
                );
            }
            return (
                <DynamicString
                    id={`dynamicString${templateField.name}`}
                    placeholder={templateField.description || ""}
                    value={value}
                    disabled={disabled}
                    onChange={updateField}
                />
            );
    }
};
export default FindDynamicComponent;
