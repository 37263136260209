import { Heading, SingleDatepicker } from "@medhelp/ui";
import { MiniModal } from "@medhelp/ui/Modals";
import { IPeriodCompProps } from "pages/Administration/types";
import { useTranslation } from "react-i18next";
import { getDateStringForwardOrBack } from "utils/date";

const Period = ({ activationDatepickProps, deActivationDatepickProps }: IPeriodCompProps) => {
    const { t } = useTranslation("administration");
    return (
        <div className="border-b">
            <div className="p-10">
                <div className="mb-10 flex items-center">
                    <h3 className="mr-3">{t("period")}</h3>
                    <MiniModal heading={t("period")} size={20}>
                        <p className="opacity-50">{t("periodInfo")}</p>
                    </MiniModal>
                </div>
                <div className="flex">
                    <div className="flex flex-col">
                        <Heading heading={t("activationDate")} mandatory />
                        <SingleDatepicker
                            className="mr-4"
                            heading={t("activationDate")}
                            isMandatory
                            {...activationDatepickProps}
                        />
                    </div>
                    <div className="flex flex-col">
                        <p className="mb-2 font-bold">{t("deActivationDate")}</p>
                        <SingleDatepicker
                            heading={t("deActivationDate")}
                            isMandatory
                            {...deActivationDatepickProps}
                            disableDatesBefore={
                                activationDatepickProps.selectedDate
                                    ? getDateStringForwardOrBack(
                                          1,
                                          "days",
                                          "forward",
                                          activationDatepickProps.selectedDate,
                                      )
                                    : undefined
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Period;
