import {
    FieldType,
    ProductType,
    AccessRightType,
    AccessRightTypes,
    HealthServiceTypes,
    AdvisoryServiceType,
    SickReportingFieldType,
} from "../utils/serviceTypes";
import { RootState } from "store";
import { isEmpty, sortBy } from "lodash/fp";
import { createSelector } from "@reduxjs/toolkit";
import { ServiceContractModel } from "swagger/businessupport";
import { sortTemplateFormDocuments } from "../utils/serviceHelperFunctions";
import { getDateDisplayValue, isAfterDate, isBeforeToday } from "utils/date";
import { determineAdvisoryService } from "../utils/determineAdvisoryService";
import { IExtendedTemplate } from "../components/HCMConfiguration/HCMConfiguration";

export const getServiceSlice = (state: RootState) => state.service;

export const getDeviantAbsenceRules = (state: RootState) => state.service.deviantAbsenceRules;

export const getNotificationConfiguration = (state: RootState) => state.service.notificationConfiguration;

export const getConfigurationFields = (state: RootState) => state.service.configurationFields;

export const getAbsenceReportingTemplates = (state: RootState) => state.service.absenceReportingTemplates;

export const getOrganizationInfo = (state: RootState) => state.service.organizationInfo;

export const getEmploymentAbsenceInfo = (state: RootState) => state.service.employmentAbsenceInfo;

export const getUserInfo = (state: RootState) => state.service.userInfo;

export const getFileImportConfig = (state: RootState) => state.service.fileImportConfig;

export const getIsOk = (state: RootState) => state.service.isOk;

export const getServiceUtilizationInfo = (state: RootState) => state.service.serviceUtilizationInfo;

export const getStatus = (state: RootState) => state.service.status;

export const getProduct = (state: RootState) => (state.service.customerData.product || "legacy") as ProductType;

export const getSubcategory = (state: RootState) => state.service.customerData.subcategory || "";

export const getDefaultRegions = (state: RootState) =>
    state.service.regions.filter((x) => x.isDefault).filter((y) => y.country === "Sweden" || y.country === "Denmark");

export const getBillableErpProductsByRetailId = (state: RootState) => state.service.billableErpProductsByRetailId;

export const getCreatedCompanyApiResults = (state: RootState) => state.service.createdCompanyApiResults;

export const getCreatedCompanyIds = (state: RootState) => state.service.createdCompanyIds;

export const getTemplateFormDocuments = (state: RootState) => state.service.templateFormDocuments;

export const getCustomerData = (state: RootState) => state.service.customerData;

export const getCompanies = (state: RootState) => state.service.companies;

export const getCustomerCompanyAdvisoryServices = (state: RootState) => state.service.customerCompanyAdvisoryServices;

export const getSocialInsuranceConfiguration = (state: RootState) => state.service.socialInsuranceConfiguration;

export const getOrderedTemplateFormDocuments = createSelector(getTemplateFormDocuments, (data) =>
    sortTemplateFormDocuments(data),
);

export const getCompaniesByCustomerId = (state: RootState) =>
    state.service.companies.map((company) => ({
        ...company,
        deactivationDate: company.deactivationDate ? getDateDisplayValue(company.deactivationDate, "yyyy-MM-dd") : "",
    }));

export const getWorkOrderInfo = (state: RootState) => state.service.workOrderInfo;

export const getWorkOrderInfoWithSortedFollowupDays = createSelector(getWorkOrderInfo, (data) => ({
    ...data,
    followUpAdvisoryConfigurations: sortBy("followUpAdvisorySickDay", data.followUpAdvisoryConfigurations),
}));

export const getHealthCaseManagementTemplates = (state: RootState) =>
    state.service.healthCaseManagementTemplates
        // Filter because backend returns templates for all customers if no company templates exist
        .filter(({ companyId }) => companyId === state.service.companyId)
        .map((template) => {
            if (template.activityTemplates) {
                return {
                    ...template,
                    activityTemplates: sortBy("day", template.activityTemplates),
                };
            }
            return template;
        });

export const getHasHCMTemplates = createSelector(getHealthCaseManagementTemplates, (data) => data.length > 0);

export const getClassification = createSelector(getEmploymentAbsenceInfo, (data) => data);

export const getSelectedCompanyCurrency = createSelector(getOrganizationInfo, (data) => data.region?.currency);

export const getEmploymentGroups = createSelector(getOrganizationInfo, (data) => data.employmentGroups);

export const getLoginConfiguration = createSelector(getUserInfo, (data) => data.loginConfiguration);

export const getCompanyInformation = createSelector(
    getOrganizationInfo,
    ({ organizationNumber, region, companyName }) => ({
        companyName: companyName || "",
        organizationNumber: organizationNumber || "",
        region: region || {},
    }),
);

export interface IBillingConfigurationStringified {
    billingPeriodType: string; // billingInterval
    billingDayInMonth: string; // billingDay
    groupType: string; // groupingOn
    groupedOnTopLevelDepartments: string;
    exportedContentLevel: string; // invoicingPer
}

export const getBillingConfiguration = createSelector(
    getCustomerData,
    ({ billingConfiguration }) =>
        // converting values to string since the select component only accepts string values
        // beware of the key names, get and post keys are different
        ({
            billingPeriodType: String(billingConfiguration?.billingPeriodType),
            billingDayInMonth: String(billingConfiguration?.billingDayInMonth),
            groupType: String(billingConfiguration?.groupType),
            groupedOnTopLevelDepartments: String(billingConfiguration?.groupedOnTopLevelDepartments),
            exportedContentLevel: String(billingConfiguration?.exportedContentLevel),
        }) as IBillingConfigurationStringified,
);

export const getActiveBillingPosts = createSelector(
    getCustomerData,
    (data) =>
        data.invoicePosts
            ?.filter((y) => !y.deactivationDate || isAfterDate(y.deactivationDate))
            .map((x) => ({
                ...x,
                activationDate: x.activationDate ? getDateDisplayValue(x.activationDate, "yyyy-MM-dd") : "",
                deactivationDate: x.deactivationDate ? getDateDisplayValue(x.deactivationDate, "yyyy-MM-dd") : "",
            })) || [],
);

export const getDeactivatedBillingPosts = createSelector(
    getCustomerData,
    (data) =>
        data.invoicePosts
            ?.filter((y) => y.deactivationDate && isBeforeToday(y.deactivationDate))
            .map((x) => ({
                ...x,
                activationDate: x.activationDate ? getDateDisplayValue(x.activationDate, "yyyy-MM-dd") : "",
                deactivationDate: x.deactivationDate ? getDateDisplayValue(x.deactivationDate, "yyyy-MM-dd") : "",
            })) || [],
);

export const getSocialInsuranceDeviantRule = createSelector(getDeviantAbsenceRules, (data) => {
    const socialInsuranceReporting = data.find((x) => x.ruleName === "AbsenceMaxLengthFK");
    return {
        hasSocialInsuranceDeviantRule: socialInsuranceReporting !== undefined,
        id: socialInsuranceReporting?.id || "",
    };
});

export const getEmploymentManagerAccessRightByType = (accessRightType: AccessRightType) =>
    createSelector(getEmploymentManagerAccessRights, (data) => {
        return data.find((x) => x.accessRightType === accessRightType);
    });

export const getDeviantAbsencesPerMonths = createSelector(getDeviantAbsenceRules, (data) =>
    data.filter((x) => x.ruleName === "AbsencesPerMonths"),
);

export const getDeviantAbsenceMaxLengthRecurring = createSelector(getDeviantAbsenceRules, (data) =>
    data.filter((x) => x.ruleName === "AbsenceMaxLengthRecurring"),
);

export const getDeviantAbsenceMaxDaysMonths = createSelector(getDeviantAbsenceRules, (data) =>
    data.filter((x) => x.ruleName === "AbsenceMaxDaysMonths"),
);

export const getCustomers = (state: RootState) => state.service.customers;

export const getConfigurationFieldByKey = (key: string) =>
    createSelector(getConfigurationFields, (data) => ({
        id: data.find((x) => x.name === key)?.id,
        key: key,
        checked: data.find((x) => x.name === key)?.value === "true",
    }));

export const getCustomersInAlphabeticalOrder = createSelector(getCustomers, (data) => sortBy("name", data));

export const getTemporarySubscriber = createSelector(getConfigurationFields, (data) => ({
    id: data.find((x) => x.name === "temporarySubscriber")?.id,
    key: "temporarySubscriber",
    checked: data.find((x) => x.name === "temporarySubscriber")?.value === "true",
}));

export const getShowReportHealthyTime = createSelector(getConfigurationFields, (data) => ({
    id: data.find((x) => x.name === "showReportHealthyTime")?.id,
    key: "showReportHealthyTime",
    checked: data.find((x) => x.name === "showReportHealthyTime")?.value === "true",
}));

export const getCallNurse = createSelector(getConfigurationFields, (data) => ({
    id: data.find((x) => x.name === "callNurse")?.id,
    key: "callNurse",
    checked: data.find((x) => x.name === "callNurse")?.value === "true",
}));

export const getNursePhoneNumber = createSelector(getConfigurationFields, (data) => {
    return data.find((x) => x.name === "nursePhoneNumber")?.value || "";
});

export const getNursePhoneNumberId = createSelector(getConfigurationFields, (data) => {
    return data.find((x) => x.name === "nursePhoneNumber")?.id || "";
});

export const getDynmaicMedicalAdvisory = createSelector(getConfigurationFields, (data) => ({
    id: data.find((x) => x.name === "dynamicMedicalAdvisory")?.id,
    key: "dynamicMedicalAdvisory",
    checked: data.find((x) => x.name === "dynamicMedicalAdvisory")?.value === "true",
}));

export const getMandatoryCallback = createSelector(getConfigurationFields, (data) => ({
    id: data.find((x) => x.name === "mandatoryCallback")?.id,
    key: "mandatoryCallback",
    checked: data.find((x) => x.name === "mandatoryCallback")?.value === "true",
}));

export const getCareOfChildViaIvrAvailable = createSelector(getConfigurationFields, (data) => ({
    id: data.find((x) => x.name === "careOfChildViaIvrAvailable")?.id,
    key: "careOfChildViaIvrAvailable",
    checked: data.find((x) => x.name === "careOfChildViaIvrAvailable")?.value === "true",
}));

export const getDeactivatedServiceContracts = createSelector(
    getServiceUtilizationInfo,
    (data) =>
        data
            ?.filter((y) => y.deactivationDate && isBeforeToday(y.deactivationDate))
            .map((x) => ({
                ...x,
                activationDate: x.activationDate ? getDateDisplayValue(x.activationDate, "yyyy-MM-dd") : "",
                deactivationDate: x.deactivationDate ? getDateDisplayValue(x.deactivationDate, "yyyy-MM-dd") : "",
            })) || [],
);

export const getServiceContracts = createSelector(getServiceUtilizationInfo, (contracts) => {
    if (!contracts)
        return HealthServiceTypes.map((healthService) => ({
            healthService,
            id: 0,
            activationDate: "",
            deactivationDate: "",
        })) as ServiceContractModel[];
    const filteredContracts = contracts?.filter((y) => !y.deactivationDate || isAfterDate(y.deactivationDate));

    const serviceContracts = HealthServiceTypes.reduce<ServiceContractModel[]>((contracts, type) => {
        const existingContracts = filteredContracts.filter((contract) => contract.healthService === type);

        const contractsToAdd = existingContracts.length > 0 ? existingContracts : [{ healthService: type }];

        return [...contracts, ...contractsToAdd].map((x) => ({
            ...x,
            activationDate: x.activationDate ? getDateDisplayValue(x.activationDate, "yyyy-MM-dd") : "",
            deactivationDate: x.deactivationDate ? getDateDisplayValue(x.deactivationDate, "yyyy-MM-dd") : "",
        }));
    }, []);

    return serviceContracts;
});

export const getHasAbsenceReportingServiceContract = createSelector(getServiceContracts, (data) =>
    data.some((x) => x.healthService === 1 && x.activationDate),
);

export const getHasRehabServiceContract = createSelector(getServiceContracts, (data) =>
    data.some((x) => x.healthService === 3 && x.activationDate),
);

export const getDepartmentManagerAccessRights = createSelector(
    getUserInfo,
    getHasRehabServiceContract,
    getCustomerData,

    ({ managerAccessRights }, hasRehabServiceContract, { product }) => {
        const departmentManagerAccessRights = managerAccessRights?.filter((x) => x.managerType === "DepartmentManager");
        return AccessRightTypes?.map((accessRightType) => {
            const checked = departmentManagerAccessRights?.some((x) => x.accessRightType === accessRightType) || false;
            return {
                accessRightType,
                checked,
                hierarchical:
                    departmentManagerAccessRights?.find((x) => x.accessRightType === accessRightType)?.hierarchical ||
                    false,
                managerType: "DepartmentManagerAccessRights",
                disabled: checked
                    ? false
                    : (!hasRehabServiceContract && accessRightType === "RehabProcessManagement") ||
                      (accessRightType === "AbsenceAdministration" && product === "base"),
            };
        });
    },
);

export const getDepartmentManagerAccessRightByType = (accessRightType: AccessRightType) =>
    createSelector(getDepartmentManagerAccessRights, (data) => {
        return data.find((x) => x.accessRightType === accessRightType);
    });

export const getEmploymentManagerAccessRights = createSelector(
    getUserInfo,
    getHasRehabServiceContract,
    getCustomerData,
    ({ managerAccessRights }, hasRehabServiceContract, { product }) => {
        const employmentManagerAccessRights = managerAccessRights?.filter((x) => x.managerType === "EmploymentManager");
        return AccessRightTypes?.map((accessRightType) => {
            const checked = employmentManagerAccessRights?.some((x) => x.accessRightType === accessRightType) || false;
            return {
                accessRightType,
                checked,
                hierarchical:
                    employmentManagerAccessRights?.find((x) => x.accessRightType === accessRightType)?.hierarchical ||
                    false,
                managerType: "MainManagerAccessRights",
                disabled: checked
                    ? false
                    : (!hasRehabServiceContract && accessRightType === "RehabProcessManagement") ||
                      (accessRightType === "AbsenceAdministration" && product === "base"),
            };
        });
    },
);
export const getAbsenceHandlingServiceContract = createSelector(getServiceContracts, (data) =>
    data.find((x) => x.healthService === 1),
);

export const getSickTemplate = createSelector(getAbsenceReportingTemplates, (data) =>
    data.find((x) => x.absenceReportType === "Sick"),
);

export const getHasSickTemplate = createSelector(getSickTemplate, (data) => ({
    id: data?.id || "",
    key: "Sick",
    checked: !isEmpty(data),
}));

export const getCareOfChildTemplate = createSelector(getAbsenceReportingTemplates, (data) =>
    data.find((x) => x.absenceReportType === "CareOfChild"),
);

export const getHasCareOfChildTemplate = createSelector(getCareOfChildTemplate, (data) => ({
    id: data?.id || "",
    key: "CareOfChild",
    checked: !isEmpty(data),
}));

export const getSickTemplateId = createSelector(getSickTemplate, (data) => data?.id);

export const getCareOfChildTemplateId = createSelector(getCareOfChildTemplate, (data) => data?.id);

export const getSickTemplateFields = createSelector(getSickTemplate, (data) => data?.fields || []);

export const getCareOfChildTemplateFields = createSelector(getCareOfChildTemplate, (data) => data?.fields || []);

export const getAdvisoryService = createSelector(
    getConfigurationFields,
    getSickTemplateFields,
    getCareOfChildTemplateFields,
    getWorkOrderInfo,
    getCareOfChildTemplate,
    (
        configurationFields,
        sickTemplateFields,
        careOfChildTemplateFields,
        workOrderConfiguration,
        careOfChildTemplate,
    ): AdvisoryServiceType | "invalidConfiguration" => {
        return determineAdvisoryService({
            configurationFields,
            workOrderConfiguration,
            sickTemplateFields,
            careOfChildTemplateFields,
            careOfChildTemplate,
        });
    },
);

export const getAllAdvisoryRelatedConfigurations = createSelector(getConfigurationFields, (data) =>
    data?.filter(
        (x) => x.name === "callNurse" || x.name === "dynamicMedicalAdvisory" || x.name === "mandatoryCallback",
    ),
);

export const getAllAdvisoryRelatedTemplates = createSelector(getSickTemplateFields, (data) =>
    data?.filter((x) => x.name === "RequestedMedicalAdvisory" || x.name === "RiskMedicalAdvisoryRequest"),
);

export const getAvailableTemplateFields = (state: RootState) => state.service.availableTemplateFields;

export const getAvailableSickTemplateFields = createSelector(getAvailableTemplateFields, (data) => {
    const fields = data.find((x) => x.type === "sick")?.fields || [];
    return [...fields]
        .sort()
        .filter((x) => x !== "RequestedMedicalAdvisory" && x !== "RiskMedicalAdvisoryRequest") as FieldType[];
});
export const getAvailableCareOfChildTemplateFields = createSelector(getAvailableTemplateFields, (data) => {
    const fields = data.find((x) => x.type === "careofchild")?.fields || [];
    return [...fields].sort() as FieldType[];
});

export const getSickReportingFields = createSelector(
    getSickTemplateFields,
    getAvailableSickTemplateFields,
    (templates, availableTemplateFields) => {
        return availableTemplateFields.map((fieldType) => {
            const template = templates.find((template) => template.name === fieldType);
            if (template) {
                return { key: fieldType, checked: true, id: template.id };
            } else {
                return { key: fieldType, checked: false };
            }
        });
    },
);

export const getCareOfChildFields = createSelector(
    getCareOfChildTemplateFields,
    getAvailableCareOfChildTemplateFields,
    (templates, availableTemplateFields) => {
        return availableTemplateFields.map((fieldType) => {
            const template = templates.find((template) => template.name === fieldType);
            if (template) {
                return { key: fieldType, checked: true, id: template.id };
            } else {
                return { key: fieldType, checked: false };
            }
        });
    },
);

export const getTemplateFieldByName = (name: SickReportingFieldType) =>
    createSelector(getSickTemplateFields, (templates) => {
        const template = templates.find((template) => template.name === name);
        if (template) {
            return { key: name, checked: true, id: template.id };
        } else {
            return { key: name, checked: false };
        }
    });

export const getRequestedMedicalAdvisorySickTemplateField = createSelector(getSickTemplateFields, (templates) => {
    const template = templates.find((template) => template.name === "RequestedMedicalAdvisory");
    if (template) {
        return { key: "RequestedMedicalAdvisory", checked: true, id: template.id };
    } else {
        return { key: "RequestedMedicalAdvisory", checked: false };
    }
});

export const getRequestedMedicalAdvisoryCareOfChildTemplateField = createSelector(
    getCareOfChildTemplateFields,
    (templates) => {
        const template = templates.find((template) => template.name === "RequestedMedicalAdvisory");
        if (template) {
            return { key: "RequestedMedicalAdvisory", checked: true, id: template.id };
        } else {
            return { key: "RequestedMedicalAdvisory", checked: false };
        }
    },
);

export const getRiskMedicalAdvisoryRequestTemplateField = createSelector(getSickTemplateFields, (templates) => {
    const template = templates.find((template) => template.name === "RiskMedicalAdvisoryRequest");
    if (template) {
        return { key: "RiskMedicalAdvisoryRequest", checked: true, id: template.id };
    } else {
        return { key: "RiskMedicalAdvisoryRequest", checked: false };
    }
});

export const getAllowDirectAdvisoryCallback = createSelector(getWorkOrderInfo, (data) =>
    data.allowDirectAdvisoryCallback
        ? { key: "allowDirectAdvisoryCallback", checked: true, id: data.id }
        : { key: "allowDirectAdvisoryCallback", checked: false },
);

export const getMandatoryAdvisoryCallback = createSelector(getWorkOrderInfo, (data) =>
    data.mandatoryAdvisoryCallback
        ? { key: "mandatoryAdvisoryCallback", checked: true, id: data.id }
        : { key: "mandatoryAdvisoryCallback", checked: false },
);

export const getHealthCaseManagementWithDeviant = createSelector(
    getHealthCaseManagementTemplates,
    getOrderedTemplateFormDocuments,
    getDeviantAbsencesPerMonths,
    getDeviantAbsenceMaxLengthRecurring,
    getDeviantAbsenceMaxDaysMonths,
    (
        hcmTemplates,
        retailerTemplateFormDocuments,
        deviantAbsencesPerMoths,
        deviantAbsenceMaxLengthRecurring,
        deviantAbsenceMaxDaysMonths,
    ) => {
        return hcmTemplates.map((template) => {
            const hasSocialInsuranceRule = template.ruleTemplates?.find(
                (rule) => rule.ruleName === "DeviantInitiation" && rule.params === "longterm,14FK",
            );
            const hasRiskInitiationRule = template.ruleTemplates?.find(
                (rule) => rule.ruleName === "RiskInitiation" && rule.ruleType === "initiation",
            );
            const hasManualInitiationRule = template.ruleTemplates?.find(
                (rule) => rule.ruleName === "ManualInitiation" && rule.ruleType === "initiation",
            );

            const extendedTemplates: IExtendedTemplate = {
                ...template,
                templateFormDocuments: retailerTemplateFormDocuments?.map((retailerTemplate) => {
                    const templateFormDocument = template.templateFormDocuments?.find(
                        (template) => template.id === retailerTemplate.id,
                    );
                    if (templateFormDocument) {
                        return { ...retailerTemplate, checked: true };
                    } else {
                        return { ...retailerTemplate, checked: false };
                    }
                }),
                healthPromotionDeviantRules: [
                    ...deviantAbsencesPerMoths.map((deviantRule) => {
                        const serializedDeviantRule = `recurringperiod,${deviantRule.value}/${deviantRule.interval}`;
                        const hcmRule = template.ruleTemplates?.find((rule) => rule.params === serializedDeviantRule);
                        if (hcmRule?.id && template.id) {
                            return {
                                key: serializedDeviantRule,
                                checked: true,
                                id: hcmRule.id,
                                value: deviantRule.value ?? "",
                                interval: deviantRule.interval ?? "",
                            };
                        } else {
                            return {
                                key: serializedDeviantRule,
                                checked: false,
                                id: null,
                                value: deviantRule.value ?? "",
                                interval: deviantRule.interval ?? "",
                            };
                        }
                    }),
                    ...deviantAbsenceMaxDaysMonths.map((deviantRule) => {
                        const serializedDeviantRule = `recurring,${deviantRule.value}/${deviantRule.interval}`;
                        const hcmRule = template.ruleTemplates?.find((rule) => rule.params === serializedDeviantRule);
                        if (hcmRule?.id && template.id) {
                            return {
                                key: serializedDeviantRule,
                                checked: true,
                                id: hcmRule.id,
                                value: deviantRule.value ?? "",
                                interval: deviantRule.interval ?? "",
                            };
                        } else {
                            return {
                                key: serializedDeviantRule,
                                checked: false,
                                id: null,
                                value: deviantRule.value ?? "",
                                interval: deviantRule.interval ?? "",
                            };
                        }
                    }),
                ],

                rehabDeviantRules: deviantAbsenceMaxLengthRecurring.map((deviantRule) => {
                    const serializedDeviantRule = `longterm,${deviantRule.value}`;
                    const hcmRule = template.ruleTemplates?.find((rule) => rule.params === serializedDeviantRule);
                    if (hcmRule?.id && template.id) {
                        return {
                            key: serializedDeviantRule,
                            checked: true,
                            id: hcmRule.id,
                            value: deviantRule.value ?? "",
                            interval: deviantRule.interval ?? "",
                        };
                    } else {
                        return {
                            key: serializedDeviantRule,
                            checked: false,
                            id: null,
                            value: deviantRule.value ?? "",
                            interval: deviantRule.interval ?? "",
                        };
                    }
                }),
                socialInsuranceRule: hasSocialInsuranceRule?.id
                    ? { key: "longterm,14FK", checked: true, id: hasSocialInsuranceRule.id }
                    : { key: "longterm,14FK", checked: false, id: null },
                riskInitiationRule: hasRiskInitiationRule?.id
                    ? { key: "RiskInitiation", checked: true, id: hasRiskInitiationRule.id }
                    : { key: "RiskInitiation", checked: false, id: null },
                manualInitiationRule: hasManualInitiationRule?.id
                    ? { key: "ManualInitiation", checked: true, id: hasManualInitiationRule.id }
                    : { key: "ManualInitiation", checked: false, id: null },
            };
            return extendedTemplates;
        });
    },
);

export const getRehabHCMTemplate = createSelector(getHealthCaseManagementTemplates, (data) => {
    return data.find((x) => x.name === "rehab");
});

export const getHealthPromotionHCMTemplate = createSelector(getHealthCaseManagementTemplates, (data) => {
    return data.find((x) => x.name === "healthpromotion");
});

export const getSocialInsuranceHCMRule = createSelector(getRehabHCMTemplate, (data) => {
    return data?.ruleTemplates?.find((x) => x.ruleName === "DeviantInitiation" && x.params === "longterm,14FK");
});

export const getRiskInitiationHCMRule = createSelector(getHealthPromotionHCMTemplate, (data) => {
    return data?.ruleTemplates?.find((x) => x.ruleName === "RiskInitiation");
});

export const getRebabTemplateId = createSelector(getRehabHCMTemplate, (data) => data?.id);

export const getHealthPromotionTemplateId = createSelector(getHealthPromotionHCMTemplate, (data) => data?.id);

export const getCompanySearch = (state: RootState) =>
    state.service.customerCompanySearchResults.filter(
        ({ companyId, customerId }) => customerId !== 0 && companyId !== 0,
    );

export const getCustomerSearch = (state: RootState) =>
    state.service.customerCompanySearchResults.filter(
        ({ companyId, customerId }) => companyId === 0 && customerId !== 0,
    );

export const getMessengerConfigurationFields = (state: RootState) => state.service.messengerConfigurationFields;

export const getRetailerContactAvailable = createSelector(getMessengerConfigurationFields, (data) => ({
    id: data.find((x) => x.name === "RetailerContactAvailable")?.id,
    key: "RetailerContactAvailable",
    checked: data.find((x) => x.name === "RetailerContactAvailable")?.value === "True" ? "True" : "False",
}));
