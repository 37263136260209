import { useCallback, useMemo, useState } from "react";
import * as UserSelectors from "store/userSelectors";
import { useAppSelector } from "store/hooks";
import useBreakpoints from "./useBreakpoints";
import { IDoubleDatePickerHookProps, IDoubleDatepickerProps, StringDateRange } from "@medhelp/ui/Datepicker/types";

const useDoubleDatepickerProps = ({
    onChangeDateRange,
    givenDateRange,
    placeholderFrom,
    placeholderTo,
    className = "",
    disabled,
    isMandatory,
}: IDoubleDatePickerHookProps) => {
    const [open, setOpen] = useState(false);
    const [range, setRange] = useState<StringDateRange | undefined>(givenDateRange);
    const [mouseHoverDate, setMouseHoverDate] = useState<StringDateRange>();
    const [focusedDisplayValue, setFocusedDisplayValue] = useState<string | null>(null);
    const [month, setMonth] = useState(range?.from ?? "");
    const userLang = useAppSelector(UserSelectors?.getUserLanguage) ?? "sv";
    const breakpoints = useBreakpoints();

    const handleOnDateRangeChange = useCallback(
        (selectedDateRange: StringDateRange | undefined) => {
            setRange(selectedDateRange);
            onChangeDateRange(selectedDateRange);
        },
        [onChangeDateRange],
    );

    const doubleDatepickerProps: IDoubleDatepickerProps = useMemo(
        () => ({
            open,
            setOpen,
            range,
            setRange: handleOnDateRangeChange,
            mouseHoverDate,
            setMouseHoverDate,
            month,
            setMonth,
            focusedDisplayValue,
            setFocusedDisplayValue,
            selectedLang: userLang,
            disableDateFrom: undefined,
            disableDateTo: undefined,
            isSmallscreen: breakpoints.isSmallscreen,
            heading: placeholderFrom,
            headingTo: placeholderTo,
            placeholder: placeholderFrom,
            placeholderTo,
            disabled,
            isMandatory,
            className,
        }),
        [
            breakpoints.isSmallscreen,
            className,
            disabled,
            focusedDisplayValue,
            handleOnDateRangeChange,
            isMandatory,
            month,
            mouseHoverDate,
            open,
            placeholderFrom,
            placeholderTo,
            range,
            userLang,
        ],
    );
    return doubleDatepickerProps;
};
export default useDoubleDatepickerProps;
