import { useMemo, useState } from "react";
import { useAppSelector } from "store/hooks";
import { Trans, useTranslation } from "react-i18next";
import NewsMessage from "components/NewsMessage";
import {
    getHasHealthCaseManagement,
    currentSearchHasAnyData,
    currentSearchHasError,
    getCurrentSearch,
    currentSearchIsEmpty,
} from "../../../redux/followUpSelectors";
import { getIsRehabProcessManagement, getHasBaseProduct } from "store/userSelectors";
import FollowUpNewsModal from "../../../components/FollowUpNewsModal";
import Preview from "../../../components/Preview";
import MonthlyReport from "../../../components/MonthlyReport";
import SearchFilter from "../../../components/SearchFilter";
import DesktopSearchResult from "../../../components/DesktopSearchResult";
import MobileSearchResult from "../../../components/MobileSearchResult";
import LoadPageData from "../LoadPageData";
import Reports from "./Reports";

export default function Index() {
    const { t } = useTranslation("followup");
    const [showInfo, setShowInfo] = useState(true);
    const hasData = useAppSelector(currentSearchHasAnyData);
    const hasError = useAppSelector(currentSearchHasError);
    const currentSearch = useAppSelector(getCurrentSearch);
    const searchIsEmpty = useAppSelector(currentSearchIsEmpty);
    const hasHealthCaseManagement = useAppSelector(getHasHealthCaseManagement);
    const isRehabProcessManagement = useAppSelector(getIsRehabProcessManagement);
    const hasBaseProduct = useAppSelector(getHasBaseProduct);

    const preview = useMemo(() => {
        if (!currentSearch) return <Preview header={t("searchPreview.header")} body={t("searchPreview.body")} />;

        if (currentSearch === "monthlyReport") return undefined;
        if (!hasData) return <Preview header={t("searchPreview.header")} body={t("searchPreview.body")} />;
        return undefined;
    }, [currentSearch, hasData, t]);
    const extras = useMemo(
        () => (
            <>
                {showInfo && (
                    <div className="mb-16">
                        <NewsMessage icon="warning" setClose={() => setShowInfo(false)} leftBg="red">
                            {
                                <Trans
                                    i18nKey={hasBaseProduct ? t("searchWarningMessageBase") : t("searchWarningMessage")}
                                />
                            }
                        </NewsMessage>
                    </div>
                )}
                <SearchFilter />
            </>
        ),
        [hasBaseProduct, showInfo, t],
    );
    const customResult = useMemo(
        () => (currentSearch === "monthlyReport" ? <MonthlyReport /> : undefined),
        [currentSearch],
    );
    const result = useMemo(
        () => ({
            desktop: <DesktopSearchResult />,
            mobile: <MobileSearchResult />,
        }),
        [],
    );
    return (
        <LoadPageData>
            <Reports
                preview={preview}
                hasData={hasData}
                currentHasNoData={searchIsEmpty}
                result={result}
                extras={extras}
                errorMessage={hasError ? t("500ErrorText") : null}
                customResult={customResult}
            />
            {hasHealthCaseManagement && isRehabProcessManagement && (
                <FollowUpNewsModal
                    header={t("news.search.header")}
                    body={t("news.search.body").split(/[.]\s+/)}
                    buttonText={t("news.search.buttonText")}
                    storage="hide-followup-reports-news"
                />
            )}
        </LoadPageData>
    );
}
