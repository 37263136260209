import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { deleteCaseDocument as deleteCaseDocumentAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const deleteCaseDocument = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(deleteCaseDocumentAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                const filteredDocuments = currentHealthCase.healthCaseDocuments.filter(
                    ({ id }) => id !== action.payload?.documentId,
                );
                currentHealthCase.healthCaseDocuments = filteredDocuments;
            }
            state.status.healthCases = Status.OK;
        })
        .addCase(deleteCaseDocumentAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseDocuments.find(
                    ({ id }) => id === action.meta.arg.documentId,
                );
                if (currentActivity) {
                    currentActivity.status = Status.PENDING;
                }
            }
        })
        .addCase(deleteCaseDocumentAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseDocuments.find(
                    ({ id }) => id === action.meta.arg.documentId,
                );
                if (currentActivity) {
                    currentActivity.status = Status.ERROR;
                }
            }
        });

export default deleteCaseDocument;
