import { flow, get, head, includes, drop, orderBy, sortBy, upperFirst, isEmpty, isArray } from "lodash/fp";
import {
    MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod,
    MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty,
} from "swagger/absencefollowup";
import i18n from "i18next";
import { IDeviantPeriods, ISearchPayload, ISearchPayloadPage } from "../redux";
import { IDate, ITags } from "../types";
import { SearchTypes } from "./searchApi";
import { getColumns, COLUMNS, ColumnTypes } from "./searchColumns";

export const replaceDeviantCodeFk = (label?: string | null) => label?.replace("FK", "");
export const parseDeviantCode = (label?: string | null) => {
    const daysAndMonth = label?.split("/");
    if (!daysAndMonth) return null;
    return {
        days: daysAndMonth[0],
        month: daysAndMonth[1],
    };
};
export const checkLongTermCode = (label?: string | null) => {
    const newLabel = label?.match(/\d/g)?.join("");
    if (label === undefined) {
        throw Error("given `ITags` consist of a label with no number");
    }
    return newLabel;
};
export const extraRuleDecorated = (label?: string | null) => {
    const newLabel = label?.split(/\d/g);
    if (newLabel === undefined) {
        throw Error("given `ITags` consist of a label with no number");
    }
    return "recurring";
};
export const getNumberFromLongtermCodes = (tag: ITags) => checkLongTermCode(tag.label);
export const getExtraRule = (tag: ITags) => extraRuleDecorated(tag.label);

export const sortLongtermCodes = (tags: ITags[]) =>
    sortBy((v) => {
        const label = getNumberFromLongtermCodes(v);
        if (label === undefined) {
            throw Error("sortLongtermCodes codes ITags consist of a rule with no number");
        }
        return parseInt(label);
    }, tags);

enum AbsenceStatus {
    Sick,
    CareOfChild,
}
export const sortAbsenceType = (tags: ITags[]) =>
    sortBy((v) => {
        const label = upperFirst(v.label) as "Sick" | "CareOfChild";
        return AbsenceStatus[label];
    }, tags);

enum WorkRelatedSort {
    workRelated,
    workAccident,
}
export const sortWorkRelated = (tags: ITags[]) =>
    sortBy((v) => WorkRelatedSort[v.referenceKey as "workRelated" | "workAccident"], tags);

enum PeriodSort {
    from,
    to,
}
export const sortPeriod = (tags: IDate[]) => sortBy((v) => PeriodSort[v.id], tags);

enum AbsenceStatusSort {
    startedAbsence,
    endedAbsence,
}
export const sortAbsenceStatus = (tags: ITags[]) =>
    sortBy((v) => AbsenceStatusSort[v.referenceKey as "startedAbsence" | "endedAbsence"], tags);

export const sortEmployementGroup = (tags: ITags[]) => sortBy((v) => v.label, tags);

type PeriodStatusFilter = "Affected" | "Started" | "Created" | "Ended";
const periodFilters: { [key in SearchTypes]: PeriodStatusFilter[] } = {
    absencePeriod: ["Started", "Ended", "Affected"],
    longtermAbsence: ["Started", "Affected"],
    recurringAbsence: ["Started", "Affected"],
    monthlyReport: ["Started", "Created", "Ended", "Affected"],
    ongoingAbsence: [],
    reimbursable: [],
    medicalCertificate: [],
    cases: [],
    activities: [],
};
enum PeriodStatusSort {
    Started,
    Affected,
    Created,
    Ended,
}
export const sortPeriodStatus = (tags: ITags[]) =>
    sortBy((v) => PeriodStatusSort[v.referenceKey as PeriodStatusFilter], tags);

export const getPeriodsWithCorrectLabel = (searchType: SearchTypes) => (x: ITags) => {
    if (searchType === "longtermAbsence" || searchType === "recurringAbsence") {
        return {
            ...x,
            label: x.label === "started" ? "Startedrule" : x.label,
        };
    }
    return x;
};
export const getPeriods = (searchType: SearchTypes, tags: ITags[]) =>
    sortPeriodStatus(tags.filter((x) => includes(x.referenceKey, periodFilters[searchType]))).map(
        getPeriodsWithCorrectLabel(searchType),
    );

export const getStatus = (searchType: SearchTypes, tags: ITags[]) =>
    sortPeriodStatus(tags.filter((x) => includes(x.referenceKey, periodFilters[searchType]))).map(
        getPeriodsWithCorrectLabel(searchType),
    );

enum MonthStatusSort {
    monthStarted,
    monthCreated,
    monthEnded,
    monthAffected,
}

export const sortMonthStatus = (tags: ITags[]) =>
    sortBy((v) => MonthStatusSort[v.label as "monthStarted" | "monthCreated" | "monthEnded" | "monthAffected"], tags);
export const orderByNumberOfDaysAbsencePeriods = (
    order: "desc" | "asc",
    value: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod[] | null | undefined,
) => {
    if (value) {
        const highestStartDate = head(orderBy("totalDays", order, value));
        return highestStartDate?.totalDays;
    }
};
export const orderByNumberOfDaysDeviantPeriods = (order: "desc" | "asc", value: IDeviantPeriods[] | undefined) => {
    if (value) {
        const highestStartDate = head(orderBy("code", order, value));
        return highestStartDate?.code;
    }
};

export const orderByTotalDays: (
    order: "desc" | "asc",
    type: SearchTypes | null,
) => (page: ISearchPayloadPage[]) => ISearchPayloadPage[] = (order, type) =>
    orderBy<ISearchPayloadPage>((value) => {
        switch (type) {
            case "absencePeriod":
            case "ongoingAbsence":
            case "reimbursable": {
                return orderByNumberOfDaysAbsencePeriods(order, value.absencePeriods);
            }
            case "longtermAbsence":
            case "recurringAbsence": {
                return orderByNumberOfDaysDeviantPeriods(order, value.deviantPeriods);
            }
            default: {
                return orderByNumberOfDaysAbsencePeriods(order, value.absencePeriods);
            }
        }
    }, order);
export const orderByAbsencePeriodField: (
    order: "desc" | "asc",
    field: "startDate",
) => (page: ISearchPayloadPage[]) => ISearchPayloadPage[] = (order, field) =>
    orderBy<ISearchPayloadPage>((value) => {
        if (value.absencePeriods) {
            const highestStartDate = head(orderBy(get(field), order, value.absencePeriods));
            return highestStartDate?.startDate;
        }
    }, order);

export const orderByEmployeeName: (order: "desc" | "asc") => (page: ISearchPayloadPage[]) => ISearchPayloadPage[] = (
    order,
) => orderBy<ISearchPayload>(get("firstName"), order);

const orderAbsenceDegreeMedicalCertificate = (page: ISearchPayloadPage) => page.absenceDegree;

const orderAbsenceDegreeAbsencePeriod = (
    order: "desc" | "asc",

    value: MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod[] | undefined | null,
) => {
    if (value) {
        const degree = head(value.flatMap((value) => orderBy(get("degree"), order, value.degrees)));
        return degree?.degree;
    }
};
export const orderByAbsenceDegree: (
    order: "desc" | "asc",
    type: SearchTypes | null,
) => (page: ISearchPayloadPage[]) => ISearchPayloadPage[] = (order, type) =>
    orderBy<ISearchPayloadPage>(
        (value) =>
            type === "medicalCertificate"
                ? orderAbsenceDegreeMedicalCertificate(value)
                : orderAbsenceDegreeAbsencePeriod(order, value.absencePeriods),
        order,
    );
export const orderByValidDeviantPeriod = (
    order: "desc" | "asc",
    valid: "validFrom" | "validTo",
    value: IDeviantPeriods[] | undefined,
) => {
    if (value) {
        const timestamp = head(value.map((value) => orderBy(get(valid), order, value.validFrom)));
        return timestamp;
    }
};
export const orderByValidMedicalCertificate = (
    valid: "validFrom" | "validTo",
    value: ISearchPayloadPage | undefined,
) => (valid === "validFrom" ? value?.startDate : value?.endDate);

export const orderByValid: (
    order: "desc" | "asc",
    valid: "validFrom" | "validTo",
    type: SearchTypes | null,
) => (page: ISearchPayloadPage[]) => ISearchPayloadPage[] = (order, valid, type) =>
    orderBy<ISearchPayloadPage>(
        (value) =>
            type === "medicalCertificate"
                ? orderByValidMedicalCertificate(valid, value)
                : orderByValidDeviantPeriod(order, valid, value.deviantPeriods),
        order,
    );

export const sortByType: (type: "CareOfChild" | "Sick") => (page: ISearchPayloadPage[]) => ISearchPayloadPage[] = (
    type,
) =>
    sortBy<ISearchPayloadPage>((value) => {
        if (value?.absencePeriods) {
            const sorted = head(sortBy((value) => (value.type === type ? 2 : 1), value.absencePeriods));

            return sorted?.type === type ? 1 : 2;
        }
    });

export const getByTanslation = (ns: string, extra: string) => (value: string) => {
    const givenKey = `${isEmpty(extra) ? "" : `${extra}.`}${value}`;
    if (i18n && i18n.exists(`${ns}:${givenKey}`)) {
        return i18n.getFixedT(i18n.language, ns)(givenKey);
    }
    return value;
};
const getAbsencePeriodTranslateValue = (property: MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty) =>
    includes(property.name, ["AbsenceCause", "EmploymentGroup"]) ? get("value", property) : get("name", property);
export const translateByAbsencePeriodsProperty = (
    ns: string,
    extra: string,
    property: MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty,
) => flow(getAbsencePeriodTranslateValue, getByTanslation(ns, extra))(property);

const ReimbursibleTypes = [
    "EmploymentGroup",
    "AbsenceCause",
    "PregnancyRelated",
    "PregnancyRelated",
    "Paragraph56",
    "SicknessBenefits",
] as const;

export const getReimbursibleTypeTranslation = (
    property: MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty,
    ns = "followup",
    extra = "reimbursableTypes",
) => (includes(property.name, ReimbursibleTypes) ? translateByAbsencePeriodsProperty(ns, extra, property) : "z");

type OrderValueSelectionFunctions = typeof getReimbursibleTypeTranslation;
type GetOrderValueByColumnTypeObject = {
    [key in ColumnTypes[0]]: OrderValueSelectionFunctions;
};
const getOrderValueByColumnType: GetOrderValueByColumnTypeObject = {
    reimbursableType: getReimbursibleTypeTranslation,
};
const orderByInProperty =
    (type: ColumnTypes, key: string[], order: "asc" | "desc") =>
    (property: MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty): string => {
        const orderFunc = getOrderValueByColumnType[type];
        if (key.length > 1 && isArray(property)) {
            // @ts-ignore
            const sortedProperties = head(property).properties;
            const element = orderBy(orderByInProperty(type, key, order), order, sortedProperties);

            return orderFunc(head(element) as MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty);
        }
        return orderFunc(property as MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty);
    };

type OrderType = typeof orderByInProperty;
// @ts-ignore
const mapHighestKey = (order: "desc" | "asc", type: ColumnTypes, key: string[], selectOrderType: OrderType) => {
    // @ts-ignore
    return (value: any) => {
        if (isEmpty(key)) {
            return value;
        }
        if (isArray(value)) {
            return orderBy(
                flow(mapHighestKey(order, type, key, selectOrderType), selectOrderType(type, key, order)),
                order,
                value,
            );
        }
        const currentKey = head(key);
        if (!currentKey) return value;
        return orderBy(
            flow(mapHighestKey(order, type, drop(1, key), selectOrderType), selectOrderType(type, drop(1, key), order)),
            order,
            value[currentKey],
        );
    };
};

// @ts-ignore
const orderAbsencePeriodsProperties: (
    order: "desc" | "asc",
    type: ColumnTypes,
    selectOrderType: OrderType,
) => (page: ISearchPayloadPage[]) => ISearchPayloadPage[] = (order, type, selectOrderType) =>
    mapHighestKey(order, type, ["absencePeriods", "properties"], selectOrderType);

export const sortingFunctions = (type: SearchTypes | null) => ({
    startDate: orderByAbsencePeriodField("desc", "startDate"),
    endDate: orderByAbsencePeriodField("asc", "startDate"),
    deviantFromAscending: orderByValid("desc", "validFrom", type),
    deviantFromDescending: orderByValid("asc", "validFrom", type),
    deviantToAscending: orderByValid("desc", "validTo", type),
    deviantToDescending: orderByValid("asc", "validTo", type),
    employeeFirstNameAscending: orderByEmployeeName("asc"),
    employeeFirstNameDescending: orderByEmployeeName("desc"),
    mostNumberOfDays: orderByTotalDays("desc", type),
    leastNumberOfDays: orderByTotalDays("asc", type),
    highestAbsence: orderByAbsenceDegree("desc", type),
    lowestAbsence: orderByAbsenceDegree("asc", type),
    careOfChild: sortByType("CareOfChild"),
    sick: sortByType("Sick"),
    reimbursableTypeAscending: orderAbsencePeriodsProperties("asc", "reimbursableType", orderByInProperty),
    reimbursableTypeDescending: orderAbsencePeriodsProperties("desc", "reimbursableType", orderByInProperty),
});
export type SortingTypes = keyof ReturnType<typeof sortingFunctions>;

const sortingValuesForColumns: {
    [value in keyof typeof COLUMNS]: SortingTypes[];
} = {
    employee: ["employeeFirstNameDescending", "employeeFirstNameDescending"],
    type: ["careOfChild", "sick"],
    reimbursableType: ["reimbursableTypeAscending", "reimbursableTypeDescending"],
    company: [],
    endStartDate: ["startDate", "endDate"],
    deviantFrom: ["deviantFromAscending", "deviantFromDescending"],
    deviantTo: ["deviantToAscending", "deviantToDescending"],
    degrees: ["highestAbsence", "lowestAbsence"],
    numberOfDays: ["mostNumberOfDays", "leastNumberOfDays"],
    affectedDepartments: [],
    caseType: [],
    start: [],
    caseStatus: [],
    caseManager: [],
    activity: [],
};

export const sortingBySearchType = (type: SearchTypes) => getColumns(type).flatMap((x) => sortingValuesForColumns[x]);
