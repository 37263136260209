import { createSelector } from "@reduxjs/toolkit";
import { compact, includes } from "lodash/fp";
import { ITableProps } from "pages/FollowUp/types";
import {
    createSearchSchema,
    EmployeeSearchTypes,
    getColumns,
    SEARCH_PAGE_SIZE,
    selectCell,
} from "pages/FollowUp/utils";
import { TFunction } from "i18next";
import { RootState } from "store";
import { getSsnWithDashWithReturnNull } from "utils/numbers";

export const getEmployees = (state: RootState) => state.organization.searchEmployee;
export const getCompaniesDepartmentsPageNumber = (state: RootState) => state.organization.empoloyee.pageNumber;
export const getCompaniesDepartmentsSearchType = (state: RootState) => state.organization.empoloyee.searchType;
export const getCompaniesDepartmentsSearchStatus = (state: RootState) => state.organization.empoloyee.searchStatus;
export const getCompaniesDepartmentsSearch = (state: RootState) => state.organization.empoloyee.search;
export const getCompaniesDepartmentsFilters = (state: RootState) => state.organization.empoloyee.filters;
export const getCompaniesDepartmentsHasMore = (state: RootState) => state.organization.empoloyee.searchHasMore;

export const getCurrentSearchPageNumber = createSelector(
    getCompaniesDepartmentsSearchType,
    getCompaniesDepartmentsPageNumber,
    (type, pageNumber) => Math.ceil((pageNumber[type] * 10) / SEARCH_PAGE_SIZE),
);
export const getCurrentPageNumber = createSelector(
    getCompaniesDepartmentsSearchType,
    getCompaniesDepartmentsPageNumber,
    (type, pageNumber) => pageNumber[type],
);

export const getCurrentCompaniesDepartmentsHasMore = createSelector(
    getCompaniesDepartmentsSearchType,
    getCompaniesDepartmentsHasMore,
    getCurrentPageNumber,
    (type, hasMore, currentPageNumber) => hasMore[type][currentPageNumber],
);

export const getCurrentCompaniesDepartmentsHasMoreWithSetNumber = createSelector(
    getCompaniesDepartmentsSearchType,
    getCompaniesDepartmentsHasMore,
    (type, hasMore) => (givenPageNumber: number) => hasMore[type][givenPageNumber],
);

export const getCurrentCompaniesDepartmentsSearchStatus = createSelector(
    getCompaniesDepartmentsSearchType,
    getCompaniesDepartmentsSearchStatus,
    (type, searchStatus) => searchStatus[type],
);
export const searchCompaniesDepartmentsStatus = createSelector(
    getCompaniesDepartmentsSearchType,
    getCompaniesDepartmentsSearchStatus,
    (searchType, searchStatus) => searchStatus[searchType],
);

export const getSortingFilters = createSelector(
    getCompaniesDepartmentsSearchType,
    getCompaniesDepartmentsFilters,
    (type, filters) => filters[type].sorting,
);

export const getFiltersSelectedColumn = createSelector(
    getCompaniesDepartmentsSearchType,
    getCompaniesDepartmentsFilters,
    (type, filters) => filters[type].selectedColumn,
);

export const getSelectedColumnSortingFilters = createSelector(
    getFiltersSelectedColumn,
    getSortingFilters,
    (column, sorting) => sorting[column],
);
export const searchCompaniesDepartments = createSelector(
    getCompaniesDepartmentsSearchType,
    getCurrentSearchPageNumber,
    getCompaniesDepartmentsSearch,
    (type, pageNumber, search) => search[type][pageNumber],
);

export const getCurrentCompaniesDepartmentsTotalCount = createSelector(
    searchCompaniesDepartments,
    (currentSearchCompaniesDepartments) => currentSearchCompaniesDepartments?.totalCount,
);

export const getCompaniesDepartmentsSearchPage = createSelector(
    searchCompaniesDepartments,
    getCurrentPageNumber,
    (search, pageNumber) => {
        if (!search?.reportEmploymentContracts) return null;
        let slicePage = [];
        if (pageNumber === 1) {
            slicePage = search.reportEmploymentContracts.slice(0, 10);
        } else {
            const pageNumberWithInternval = ((pageNumber - 1) * 10) % SEARCH_PAGE_SIZE;
            slicePage = search.reportEmploymentContracts.slice(pageNumberWithInternval, pageNumberWithInternval + 10);
        }
        return slicePage;
    },
);

export const getTableEmployeeSearchProperties = (type: EmployeeSearchTypes, t: TFunction) =>
    createSelector(
        getCurrentCompaniesDepartmentsTotalCount,
        getCompaniesDepartmentsSearchPage,
        (totalCount, search) => {
            if (!search) return { data: [], numberOfPages: 0, totalCount: 0 };
            const newTable: ITableProps[] = search.map((pageData) => {
                const searchResult = createSearchSchema(type, pageData);
                return {
                    cells: [
                        {
                            data: {
                                header: pageData.name,
                                body: compact([
                                    getSsnWithDashWithReturnNull(
                                        pageData.socialSecurityNumber || pageData.socialSecurityNumber,
                                    ),
                                ]),
                                text: pageData.archived ? t("followUpArchived") : undefined,
                                type: "ProfileRow",
                            },
                            id: "employee",
                            order: 1,
                        },
                        {
                            data: selectCell(t, "company", searchResult),
                            id: "company",
                            order: 2,
                        },
                        {
                            data: selectCell(t, "affectedDepartments", searchResult),
                            id: "affectedDepartments",
                            order: 3,
                        },
                        pageData.id
                            ? {
                                  data: {
                                      id: pageData.referenceKey || null,
                                      type: "Button",
                                  },
                                  id: "employmentId",
                                  order: 4,
                              }
                            : {},
                    ],
                    theme: !(pageData.archived ?? false) ? "default" : "archive",
                    id: pageData.referenceKey || null,
                };
            });
            const filterTableData = newTable.map((value) => ({
                ...value,
                cells: value.cells.filter(
                    (v) => includes(v.id, getColumns(null, "employee")) || v.id === "employmentId",
                ),
            }));
            return {
                data: filterTableData,
                numberOfPages: SEARCH_PAGE_SIZE,
                totalCount: totalCount || 0,
            };
        },
    );
