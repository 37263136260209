import { RootState } from "store";
import { getServices } from "api/services";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateOrUpdateAccessRights, UserAccount, UserEmployment } from "swagger/customercompany";

export const initFortnoxAdmin = createAsyncThunk("fortnoxAdministration/initFortnoxAdmin", async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    // This solution depends on the company only having one department, adding departments will break this implementation
    const departmentId = state.user?.userContextExtended?.companyEmployment?.departments?.[0].id;
    const companyId = state.user?.userContextExtended?.companyEmployment?.primaryCompany?.id;
    const { data } = await getServices().clients.customerCompany.apiCustomerCompanyGetDepartmentInfoGet(departmentId);
    const topDepartmentId = Number(data.path);
    const departmentReferenceKey = data.referenceKey;

    if (departmentId && companyId && topDepartmentId && departmentReferenceKey) {
        const response = await Promise.allSettled([
            getServices().clients.customerCompany.apiCustomerCompanyGetEmploymentsForDepartmentIdGet(departmentId),
            getServices().clients.customerUserSupport.apiUserGetUserAccountsAndAccessRightsByCompanyIdGet(companyId),
            getServices().clients.customerUserSupport.apiUserGetUserAccountsAndAccessRightsByDepartmentIdGet(
                departmentId,
            ),
        ]);

        if (response.every((res) => res?.status === "fulfilled")) {
            const okResponse = response.map((res) =>
                res.status === "fulfilled" && res.value.data ? res.value.data : undefined,
            );

            return {
                employees: okResponse[0] as UserEmployment[],
                companyAccessRights: okResponse[1] as UserAccount[],
                departmentAccesRights: okResponse[2] as UserAccount[],
                topDepartmentId,
                departmentReferenceKey,
            };
        }
        return thunkApi.rejectWithValue(response);
    }
    return thunkApi.rejectWithValue("Error");
});

interface SaveEmployeeAccessRights {
    employee: UserEmployment;
    isManagerChecked: boolean;
    isAdminChecked: boolean;
}

export const saveEmployeeAccessRights = createAsyncThunk(
    "fortnoxAdministration/saveEmployeeAccessRights",
    async ({ employee, isManagerChecked, isAdminChecked }: SaveEmployeeAccessRights, thunkApi) => {
        const state = thunkApi.getState() as RootState;

        const topDepartmentId = Number(state.fortnoxAdministration.topDepartmentId);
        const departmentReferenceKey = state.fortnoxAdministration.departmentReferenceKey;

        const accessRights: CreateOrUpdateAccessRights = {
            absenceAdministration: [],
            absenceFollowup: [],
            absenceView: [],
            departmentAdministration: [],
            rehabProcessManagement: [],
            absenceStatistics: [],
            absenceBoard: [],
            userAccountId: employee.userAccountId,
            companyId: employee.primaryCompany?.id,
            topDepartment: null,
            // admin access rights
            customerCompanyAdministration: isAdminChecked,
            customerOrganizationAdministration: isAdminChecked,
        };

        // Set fortnox manager access rights
        if (isManagerChecked) {
            const departmentAccessRight = { id: topDepartmentId, recursive: true };

            accessRights.absenceFollowup = [departmentAccessRight];
            accessRights.rehabProcessManagement = [departmentAccessRight];
            accessRights.absenceStatistics = [departmentAccessRight];
            accessRights.topDepartment = departmentAccessRight;
        }

        await getServices().clients.customerUserSupport.apiUserCreateAccessRightsPost(accessRights);

        // Set fortnox manager role
        if (employee.primaryCompany?.id && employee.userAccountId && departmentReferenceKey) {
            const userRole = {
                companyId: employee.primaryCompany?.id,
                userAccountId: employee.userAccountId,
                role: "fortnox",
                scope: 2,
                targetId: departmentReferenceKey,
            };
            if (isManagerChecked) {
                await getServices().clients.userRole.apiV1UserRoleAddPost(userRole);
            } else {
                await getServices().clients.userRole.apiV1UserRoleDeletePost(userRole);
            }
        }

        return { userAccountId: employee.userAccountId, isManagerChecked, isAdminChecked };
    },
);
