import { FileImportStatus } from "./components/StatusTag";
import { InvalidRow, ValidationError } from "../../store/rtk-apis/fileImport/fileImportApi";

const EventStateTypes = [
    "queued",
    "user",
    "employment",
    "department",
    "departmentAssociation",
    "roles",
    "mainManager",
    "mainManagerUpdatesForDepartmentAssociation",
    "departmentManager",
    "rejected",
    "finished",
    "error",
] as const;

export type EventStateType = (typeof EventStateTypes)[number];

export const calculateStatus = (eventState: EventStateType): FileImportStatus => {
    switch (eventState) {
        case "queued":
            return "queued";
        case "rejected":
            return "failed";
        case "finished":
            return "success";
        case "error":
            return "failed";
        default:
            return "processing";
    }
};

export const severeErrors = ["duplicateuser", "duplicateemployment", "duplicatedepartment", "parentdepartment"];

/**
 *
 * @param items Array of validation errors. External id is the content of the cell that caused the error.
 * @returns Object where the error code is the key and an object array for each error code where the external id is the text value
 */
export const groupByCode = (items: ValidationError[]) => {
    return items.reduce(
        (result, item) => {
            if (item.code != null) {
                result[item.code] = result[item.code] || [];
                result[item.code].push({ text: item.externalId || "" });
            }
            return result;
        },
        {} as Record<string, { text: string }[]>,
    );
};

/**
 *
 * @param items Array of invalid rows. Line is the row number of the invalid row.
 * @returns Object where the error reason is the key and an object array which contains the relevat faulty data and the line number
 */
export const groupByReason = (items: InvalidRow[]) => {
    return items.reduce(
        (result, item) => {
            if (item.reason != null) {
                result[item.reason] = result[item.reason] || [];
                result[item.reason].push({
                    text: item.rowData || "",
                    line: item.line?.toString() || "",
                });
            }
            return result;
        },
        {} as Record<string, { text: string; line: string }[]>,
    );
};
