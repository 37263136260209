import Icon from "components/Icon";
import { useElementClicked, useKeyDownEvent } from "hooks";

interface IProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    heading: string;
    children?: React.ReactNode;
    className?: string;
    bottomContent?: JSX.Element;
    topContent?: JSX.Element;
    hideOnClickOutside?: boolean;
}

const SideMenu = ({
    open,
    setOpen,
    children,
    heading,
    className,
    bottomContent,
    topContent,
    hideOnClickOutside = true,
}: IProps) => {
    const setNode = useElementClicked((value: boolean) => {
        if (hideOnClickOutside) {
            setOpen(value);
        }
    });
    useKeyDownEvent("Escape", () => setOpen(false));
    if (!open) return <></>;
    return (
        <>
            <div className="hidden left-0 top-0 h-full fixed w-full mr-88 md:flex bg-black opacity-25" />
            <div
                className="h-full fixed left-0 md:left-auto md:right-0 bg-white w-screen md:w-88 border overflow-auto top-0 z-50"
                ref={setNode}
            >
                <div className={className}>
                    <div className="hidden xs:flex justify-end pr-3 pt-3">
                        <button
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setOpen(false);
                            }}
                            className="p-2"
                        >
                            <Icon icon="close" size={14} />
                        </button>
                    </div>
                    <div className="flex h-10 items-center justify-between xs:hidden">
                        <div className="w-4" />
                        <div className="flex items-center">{topContent && topContent}</div>
                        <button
                            onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setOpen(false);
                            }}
                            className="flex h-10 w-10 items-center justify-center"
                        >
                            <Icon icon="close" size={12} />
                        </button>
                    </div>
                    <div>
                        <div className="px-6 pb-8 pt-6">
                            <h3>{heading}</h3>
                        </div>
                        {children && <div className="border-t pb-30">{children}</div>}
                    </div>
                    {bottomContent}
                </div>
            </div>
        </>
    );
};

export default SideMenu;
