import TopLinks from "components/TopLinks";
import { Config } from "config";
import { useTranslation } from "react-i18next";

const ServiceTopLinks = () => {
    const { t } = useTranslation("service");
    const Routes = [
        {
            route: "company",
            name: t("manageCompany"),
            children: [],
        },
        {
            route: "customer",
            name: t("manageCustomer"),
            children: [],
        },
        {
            route: `${Config.web_businessSupport_url}CustomerBilling`,
            name: t("billing"),
            subMenu: true,
            children: [
                {
                    route: `${Config.web_businessSupport_url}CustomerBilling`,
                    name: t("customerBilling"),
                    children: [],
                },
                {
                    route: `${Config.web_businessSupport_url}BillingConfiguration`,
                    name: t("billingConfiguration"),
                    children: [],
                },
            ],
        },
        {
            route: `${Config.web_businessSupport_url}MessageTemplates`,
            name: t("messageTemplates"),
            children: [],
        },
        {
            route: `${Config.web_businessSupport_url}Templates`,
            name: t("rehabTemplates"),
            children: [],
        },
    ];

    return <TopLinks routes={Routes} headRoute="service" header={t("service")} />;
};

export default ServiceTopLinks;
