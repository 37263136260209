import { WorkOrderConfiguration } from "swagger/businessupport";
import {
    MedHelpAbsenceReportingDomainTemplate,
    MedHelpAbsenceReportingDomainFieldTemplate,
    MedHelpAbsenceReportingWebApiModelsConfigurationField,
} from "swagger/absencereportapi";

interface IDetermineAdvisoryService {
    configurationFields: MedHelpAbsenceReportingWebApiModelsConfigurationField[];
    workOrderConfiguration: WorkOrderConfiguration;
    sickTemplateFields: MedHelpAbsenceReportingDomainFieldTemplate[];
    careOfChildTemplateFields: MedHelpAbsenceReportingDomainFieldTemplate[];
    careOfChildTemplate: MedHelpAbsenceReportingDomainTemplate | undefined;
}

export const determineAdvisoryService = ({
    configurationFields,
    workOrderConfiguration,
    sickTemplateFields,
    careOfChildTemplateFields,
    careOfChildTemplate,
}: IDetermineAdvisoryService) => {
    const hasCallNurse = configurationFields.find((x) => x.name === "callNurse")?.value === "true";

    const hasMandatoryCallback = configurationFields.find((x) => x.name === "mandatoryCallback")?.value === "true";

    const hasMandatoryCallbackWorkOrder = workOrderConfiguration.mandatoryAdvisoryCallback;

    const hasDynamicMedicalAdvisory =
        configurationFields.find((x) => x.name === "dynamicMedicalAdvisory")?.value === "true";

    const hasRequestedMedicalAdvisorySick = sickTemplateFields.some((x) => x.name === "RequestedMedicalAdvisory");

    const hasRequestedMedicalAdvisoryCareOfChild = careOfChildTemplateFields.some(
        (x) => x.name === "RequestedMedicalAdvisory",
    );

    const hasRiskMedicalAdvisoryRequest = sickTemplateFields.some((x) => x.name === "RiskMedicalAdvisoryRequest");

    const hasAllowDirectAdvisoryCallbackWorkOrder = workOrderConfiguration.allowDirectAdvisoryCallback;

    // request medical advisory should only be mandatory if the company has care of child template
    const careOfChildCondition = careOfChildTemplate?.id ? hasRequestedMedicalAdvisoryCareOfChild : true;

    if (hasCallNurse) return "callNurse";
    if (hasMandatoryCallback && hasMandatoryCallbackWorkOrder) return "mandatoryCallback";
    if (
        hasDynamicMedicalAdvisory &&
        hasRiskMedicalAdvisoryRequest &&
        hasRequestedMedicalAdvisorySick &&
        careOfChildCondition &&
        hasAllowDirectAdvisoryCallbackWorkOrder
    )
        return "dynamicMedicalAdvisory";
    if (hasRequestedMedicalAdvisorySick && careOfChildCondition && hasAllowDirectAdvisoryCallbackWorkOrder)
        return "requestedMedicalAdvisory";
    if (
        !hasCallNurse &&
        !hasMandatoryCallback &&
        !hasDynamicMedicalAdvisory &&
        !hasRequestedMedicalAdvisorySick &&
        !hasRequestedMedicalAdvisoryCareOfChild
    )
        return "fullyAutomatic";
    return "invalidConfiguration";
};
