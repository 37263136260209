import React, { useState } from "react";
import { useElementClicked } from "hooks";
import Icon from "components/Icon";

interface IProps {
    heading: string;
    children?: React.ReactNode;
    content?: string;
    className?: string;
    size?: number;
}
const MiniModal = (props: IProps) => {
    const { children, heading, content, className } = props;
    const [active, setActive] = useState(false);
    const ref = useElementClicked((clicked) => {
        if (!clicked) {
            setActive(false);
        }
    });
    return (
        <div className="relative">
            <Icon
                icon="info"
                size={props.size ? props.size : 24}
                fill="transparent"
                color="#9C9E8D"
                className="cursor-pointer"
                onClick={(event) => {
                    if (active) {
                        event.stopPropagation();
                        event.preventDefault();
                    }
                    setActive(!active);
                }}
            />
            {active && (
                <div
                    data-cy="mini-modal"
                    className={`absolute z-50 -translate-x-full w-[300px] xs:translate-x-1 xs:max-w-md mt-4 flex flex-col shadow-lg bg-white ${className}`}
                    ref={ref}
                >
                    <div
                        id="mini-modal-header"
                        className="p-2 border-b border-grey-350 flex items-center justify-between"
                    >
                        <div className="ml-2 flex">
                            <Icon icon="info" size={24} fill="transparent" color="#9C9E8D" />
                            <p className="ml-2">{heading}</p>
                        </div>
                        <button
                            type="button"
                            className="h-8 w-8 flex items-center justify-center"
                            onClick={() => setActive(false)}
                        >
                            <Icon icon="close" width="20" height="20" fill="black" />
                        </button>
                    </div>
                    <div>
                        <p className="p-4">{content}</p>
                    </div>
                    <div className="px-4 pb-6">{children}</div>
                </div>
            )}
        </div>
    );
};
export default MiniModal;
