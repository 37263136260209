import { curry, includes } from "lodash/fp";
import { SearchTypes, ReportSearch } from "./searchApi";
import { FollowUpPages } from "../accessRights";

export const COLUMNS = {
    employee: "employee",
    type: "type",
    reimbursableType: "reimbursableType",
    company: "company",
    endStartDate: "endStartDate",
    deviantFrom: "deviantFrom",
    deviantTo: "deviantTo",
    degrees: "degrees",
    numberOfDays: "numberOfDays",
    affectedDepartments: "affectedDepartments",
    caseType: "caseType",
    start: "start",
    caseManager: "caseManager",
    caseStatus: "caseStatus",
    activity: "activity",
} as const;

export type ColumnTypes = keyof typeof COLUMNS;

export type ExtractColumnsTypes<T extends ColumnTypes, C extends ColumnTypes> = Extract<T, C>;

type ReportColumnTypeTypes = ExtractColumnsTypes<
    ColumnTypes,
    | "employee"
    | "type"
    | "reimbursableType"
    | "endStartDate"
    | "deviantFrom"
    | "deviantTo"
    | "degrees"
    | "numberOfDays"
    | "affectedDepartments"
    | "caseType"
    | "start"
    | "caseManager"
    | "caseStatus"
    | "activity"
>;

const ReportColumnTypes: readonly ReportColumnTypeTypes[] = [
    "employee",
    "type",
    "reimbursableType",
    "endStartDate",
    "deviantFrom",
    "deviantTo",
    "degrees",
    "numberOfDays",
    "affectedDepartments",
    "caseType",
    "start",
    "caseManager",
    "caseStatus",
    "activity",
] as const;

export type EmployeeColumnTypeTypes = ExtractColumnsTypes<ColumnTypes, "employee" | "company" | "affectedDepartments">;
const EmployeeColumnTypes: readonly EmployeeColumnTypeTypes[] = ["employee", "company", "affectedDepartments"] as const;

const ARAccessRight: Array<SearchTypes> = [
    "absencePeriod",
    "longtermAbsence",
    "medicalCertificate",
    "monthlyReport",
    "ongoingAbsence",
    "recurringAbsence",
];
const DenmarkARAccessRight: Array<SearchTypes> = [...ARAccessRight, "reimbursable"];

const getAccessRight = (lang?: (string | null | undefined)[]) => {
    if (includes("Denmark", lang)) {
        return DenmarkARAccessRight;
    }
    return ARAccessRight;
};

const getFilters = curry((filters: Array<SearchTypes>, x: SearchTypes) => filters.includes(x));
export const getSearchTypes = (lang?: (string | null | undefined)[]) =>
    [...ReportSearch].filter(getFilters(getAccessRight(lang)));

const getReportColumnType = (value: ColumnTypes): value is ReportColumnTypeTypes => includes(value, ReportColumnTypes);

export const selectReportSearchColumns = (columns: ColumnTypes[]) => columns.filter(getReportColumnType);

const getEmployeeColumnType = (value: ColumnTypes): value is EmployeeColumnTypeTypes =>
    includes(value, EmployeeColumnTypes);

export const selectEmployeeSearchColumns = (columns: ColumnTypes[]) => columns.filter(getEmployeeColumnType);

export const getColumns = (search: SearchTypes | null, page: FollowUpPages = "reports") => {
    if (page === "reports") return reportColumns(search);

    return employeeColumns();
};

const employeeColumns = () => [COLUMNS.employee, COLUMNS.company, COLUMNS.affectedDepartments];

const reportColumns = (search: SearchTypes | null) => {
    switch (search) {
        case "reimbursable": {
            return [
                COLUMNS.employee,
                COLUMNS.type,
                COLUMNS.reimbursableType,
                COLUMNS.endStartDate,
                COLUMNS.degrees,
                COLUMNS.numberOfDays,
                COLUMNS.affectedDepartments,
            ];
        }
        case "absencePeriod":
        case "ongoingAbsence": {
            return [
                COLUMNS.employee,
                COLUMNS.type,
                COLUMNS.endStartDate,
                COLUMNS.degrees,
                COLUMNS.numberOfDays,
                COLUMNS.affectedDepartments,
            ];
        }
        case "longtermAbsence": {
            return [
                COLUMNS.employee,
                COLUMNS.type,
                COLUMNS.deviantFrom,
                COLUMNS.numberOfDays,
                COLUMNS.affectedDepartments,
            ];
        }
        case "recurringAbsence": {
            return [
                COLUMNS.employee,
                COLUMNS.type,
                COLUMNS.deviantFrom,
                COLUMNS.deviantTo,
                COLUMNS.affectedDepartments,
            ];
        }
        case "monthlyReport":
        case "medicalCertificate": {
            return [COLUMNS.employee, COLUMNS.type, COLUMNS.deviantFrom, COLUMNS.deviantTo, COLUMNS.degrees];
        }
        case "cases": {
            return [
                COLUMNS.employee,
                COLUMNS.caseType,
                COLUMNS.start,
                COLUMNS.caseManager,
                COLUMNS.affectedDepartments,
                COLUMNS.caseStatus,
            ];
        }
        case "activities": {
            return [
                COLUMNS.employee,
                COLUMNS.caseType,
                COLUMNS.activity,
                COLUMNS.start,
                COLUMNS.caseManager,
                COLUMNS.affectedDepartments,
            ];
        }
        default: {
            return [
                COLUMNS.employee,
                COLUMNS.type,
                COLUMNS.endStartDate,
                COLUMNS.degrees,
                COLUMNS.numberOfDays,
                COLUMNS.affectedDepartments,
            ];
        }
    }
};
