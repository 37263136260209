import {
    ProductType,
    ProductTypes,
    industryCodeTypes,
    HealthServiceTypes,
    AdvisoryServiceTypes,
    ICreateCustomerOrCompany,
} from "pages/service/utils/serviceTypes";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ServiceSelect from "../ServiceSelect";
import { useTranslation } from "react-i18next";
import { ServiceInput } from "../ServiceInput";
import { useAppDispatch, useAppSelector } from "store/hooks";
import PrimaryButton from "components/PrimaryButton";
import { ServiceCheckbox } from "../ServiceCheckbox";
import ServiceRadioButton from "../ServiceRadioButton";
import { getActiveRetailer } from "store/userSelectors";
import ServiceSelectWithFilter from "../ServiceSelectWithFilter";
import { getCustomers, getDefaultRegions } from "pages/service/redux/serviceSelectors";
import { fetchCustomerData, saveNewCompany } from "pages/service/redux/serviceAsyncActions";
import { organizationNumberConfigByCountry } from "pages/service/utils/serviceHelperFunctions";

const healthServices = HealthServiceTypes
    // health sevice 4 is deprecated
    .filter((hs) => hs !== 4)
    .map((healthService) => ({
        healthService,
        checked: healthService === 1,
    }));

const CreateCustomerCompany = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const customers = useAppSelector(getCustomers);
    const regions = useAppSelector(getDefaultRegions);
    const activeRetailer = useAppSelector(getActiveRetailer);

    const {
        watch,
        register,
        setValue,
        resetField,
        handleSubmit,
        formState: { isValid },
    } = useForm<ICreateCustomerOrCompany>({
        defaultValues: { healthServices },
    });

    const type = watch("type");

    const selectedRegionId = watch("regionId");

    const selectedCountry = regions?.find((x) => x.id === selectedRegionId)?.country;

    const selectedCustomerId = watch("customerId");

    const displayValue = customers.find((x) => x.id === watch("customerId"))?.name || "";

    // CS users's default Retailer is "Intern MedHelp" causing them to accidentally create companies in that retailer
    // Temporary fix to disable create button while the user is in Intern MedHelp
    const isInternMedhelpRetailer = activeRetailer?.name === "Intern Medhelp";

    const handleClick = (id: number) => setValue("customerId", id, { shouldValidate: true });

    const onSubmit = (data: ICreateCustomerOrCompany) => {
        dispatch(saveNewCompany(data));
    };

    useEffect(() => {
        if (selectedCustomerId)
            dispatch(fetchCustomerData(selectedCustomerId))
                .unwrap()
                .then((res) => {
                    setValue("product", (res.product as ProductType) || "legacy");
                });
    }, [dispatch, selectedCustomerId, setValue]);

    useEffect(() => {
        resetField("customerId");
    }, [resetField, type]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6 h-full px-4 md:px-0">
            <div className="flex flex-col md:flex-row gap-6">
                <div>
                    <p className="font-bold mb-2">{t("chooseWhatToAdd")} *</p>
                    <div className="flex flex-col md:flex-row items-center gap-2 md:gap-0 h-12 md:w-86">
                        <div className="w-full">
                            <ServiceRadioButton
                                value="standAloneCompany"
                                register={register("type", { required: true })}
                                label={t("standAloneCompany")}
                            />
                        </div>
                        <div className="w-full">
                            <ServiceRadioButton
                                value="companyToGroup"
                                register={register("type", { required: true })}
                                label={t("companyToGroup")}
                            />
                        </div>
                    </div>
                </div>
                {watch("type") === "companyToGroup" && (
                    <>
                        <input className="hidden" {...register("customerId", { required: true })} />
                        <ServiceSelectWithFilter
                            placeholder={t("chooseCustomerGroup")}
                            options={customers.map(({ id, name }) => ({
                                id: id as number,
                                name: name || "",
                            }))}
                            handleClick={handleClick}
                            displayValue={displayValue}
                        />
                    </>
                )}
            </div>
            <div className="flex gap-6 flex-wrap">
                <ServiceSelect
                    label={t("choosePartner")}
                    bold
                    placeholder={activeRetailer?.name}
                    options={[]}
                    disabled
                    required
                />
                <ServiceSelect
                    label={t("chooseCountry")}
                    bold
                    placeholder={t("chooseCountry")}
                    register={register("regionId", { required: true })}
                    options={regions?.map(({ country, id }) => ({
                        value: id || "",
                        label: t(country || ""),
                    }))}
                    required
                />
            </div>
            <div className="flex gap-6 flex-wrap">
                <ServiceInput bold required label={t("name")} register={register("companyName", { required: true })} />
                <ServiceInput
                    bold
                    required
                    label={t("organizationNumber")}
                    placeholder={
                        (selectedCountry && organizationNumberConfigByCountry[selectedCountry]?.placeholder) || ""
                    }
                    register={register("organizationNumber", {
                        required: true,
                        ...(selectedCountry ? organizationNumberConfigByCountry[selectedCountry]?.rules : {}),
                    })}
                />
            </div>
            <div className="flex gap-6 flex-wrap">
                <ServiceSelect
                    bold
                    required
                    label={t("industryCode")}
                    register={register("industryCode", { required: true })}
                    placeholder={t("chooseIndustryCode")}
                    options={industryCodeTypes.map((industryCode) => ({
                        value: industryCode,
                        label: t(`industryCodeKey.${industryCode}`),
                    }))}
                />
                <ServiceSelect
                    bold
                    required
                    label={t("chooseProduct")}
                    placeholder={t("chooseProduct")}
                    register={register("product", {
                        required: true,
                    })}
                    options={ProductTypes.map((product) => ({
                        value: product,
                        label: t(product),
                    }))}
                    disabled={!!selectedCustomerId}
                />
            </div>
            <div className="flex gap-6 flex-wrap">
                <div className="flex flex-col gap-2 w-full md:w-86">
                    <label className="font-bold">{t("activationDate")} *</label>
                    <input
                        className="border border-mhdarkgreen-original px-2 h-12 bg-transparent"
                        type="date"
                        {...register("activationDate", { required: true })}
                    />
                </div>
            </div>
            {watch("product") === "legacy" && (
                <div className="flex gap-6 flex-wrap">
                    <ServiceSelect
                        label={t("chooseAdvisoryService")}
                        register={register("advisoryService", { required: true })}
                        options={AdvisoryServiceTypes
                            // deprecated
                            .filter((as) => as !== "dynamicMedicalAdvisory")
                            .map((advisoryService) => ({
                                label: t(advisoryService),
                                value: advisoryService,
                            }))}
                        placeholder={t("chooseAdvisoryService")}
                        bold
                        required
                    />

                    <div>
                        <div className="font-bold mb-2">{t("chooseHealthServices")} *</div>
                        <div className="flex flex-col gap-1">
                            {healthServices.map(({ healthService }, index) => (
                                <label key={healthService} className="flex gap-1">
                                    <ServiceCheckbox
                                        register={register(`healthServices.${index}.checked`)}
                                        disabled={
                                            //mandatory
                                            healthService === 1
                                        }
                                    />
                                    <p>{t(`healthServicesKey.${healthService}`)}</p>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <div className="flex flex-end justify-center mt-10">
                <PrimaryButton
                    onClick={() => {}}
                    text={t("create")}
                    id="onboardingSubmit"
                    disabled={!isValid || isInternMedhelpRetailer}
                    type="submit"
                />
            </div>
        </form>
    );
};

export default CreateCustomerCompany;
