import SearchResultUser from "components/SearchResultUser";
interface IProps {
    searchItem: any;
    index: number;
    onClick: (value: any) => void;
    isArchived?: boolean;
}

const UserResult = ({ index, searchItem, onClick, isArchived }: IProps) => (
    <SearchResultUser
        key={index}
        searchResult={searchItem}
        onClick={() => {
            onClick(searchItem);
        }}
        isArchived={isArchived}
    />
);

export default UserResult;
