/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { removeFirstDayMedicalCertificateRequired as removeFirstDayMedicalCertificateRequiredAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchSubscriptionBuilder = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(removeFirstDayMedicalCertificateRequiredAction.fulfilled, (state, action) => {
            const newFirstDayMedicalCertificates = state.medicalCertificate.firstDayMedicalCertificate?.filter(
                ({ id }) => id !== action.payload,
            );
            if (newFirstDayMedicalCertificates) {
                state.medicalCertificate.firstDayMedicalCertificate = newFirstDayMedicalCertificates;
            }
            state.status.medicalCertificate = Status.OK;
        })
        .addCase(removeFirstDayMedicalCertificateRequiredAction.pending, (state) => {
            state.status.medicalCertificate = Status.PENDING;
        })
        .addCase(removeFirstDayMedicalCertificateRequiredAction.rejected, (state) => {
            state.status.medicalCertificate = Status.ERROR;
        });
export default fetchSubscriptionBuilder;
