import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { getUnreadCount } from "./redux/inboxSelectors";
import Icon from "components/Icon";

const InboxLoading = () => {
    const { t } = useTranslation();
    const unreadCount = useAppSelector(getUnreadCount);
    const headers = [
        { label: t("eventType"), accessor: "eventType" },
        { label: t("subject"), accessor: "subject" },
        { label: t("date"), accessor: "date" },
    ];
    const numberOfLoadingRows = Math.min(3 + unreadCount, 10);
    const widths = ["w-56", "w-44", "w-40", "w-48", "w-43", "w-52", "w-40", "w-44", "w-56", "w-48"];
    const loadingRowsWidths = widths.slice(0, numberOfLoadingRows);

    return (
        <div className="bg-primaryBg shadow-lg justify-start p-0 block animate-pulse">
            <div className="flex justify-between border-b">
                <div className="w-full h-23 flex justify-between items-center">
                    {headers.map(({ label }) => (
                        <div key={label} className="text-left px-6 pb-5 pt-8 w-full font-bold">
                            {label}
                        </div>
                    ))}
                </div>
                <div className="flex items-center justify-end px-6 pb-5 pt-8">
                    <Icon icon="miniMenu" />
                </div>
            </div>
            {loadingRowsWidths.map((width, i) => (
                <div key={`inbox-loading-${i}`} className="flex justify-between border-b">
                    <div className="w-full h-23 flex justify-between items-center">
                        <div className="flex items-center w-full">
                            <div className="w-11 h-11 m-6 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                            <div className={`${width} w-52 h-5 m-6 bg-gray-200 rounded-full dark:bg-gray-700`}></div>
                        </div>
                        <div className="w-full">
                            <div className={`${width} w-60 h-5 m-6 bg-gray-200 rounded-full dark:bg-gray-700`}></div>
                        </div>
                        <div className="w-full">
                            <div className="w-40 h-5 m-6 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end">
                        <div className="w-6 h-5 m-6 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                </div>
            ))}
            <div className="flex">
                <div className="w-27 h-5 m-6 mr-90 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="w-52 h-5 m-6 bg-gray-200 rounded-full dark:bg-gray-700"></div>
            </div>
        </div>
    );
};

export default InboxLoading;
