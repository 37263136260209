import Icon from "components/Icon";

interface IPropsView {
    header: string;
    body: string;
    text: string;
}
const DisplayPeriod = ({ text, header, body }: IPropsView) => (
    <div className="flex flex-col justify-between py-6 md:flex-row">
        <div className="flex">{header}</div>
        <div>
            <div className="flex gap-2">
                <Icon icon="calendar" stroke={"#192D05"} size={15} className="h-auto" strokeWidth={2} />
                <div className="flex">
                    <p>{text}:</p>
                    <div className="ml-1">{body}</div>
                </div>
            </div>
        </div>
    </div>
);

export default DisplayPeriod;
