import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServices } from "api/services";
import { IPreloadedValues } from "pages/Support/pages/ContactSupport/ContactSupport";
import { LoginConfiguration } from "swagger/businessupport";
import { PasswordChange } from "swagger/usercontextservice/api";

export const mailCustomerSupport = createAsyncThunk(
    "mailCustomerSupport",
    async (emailModel: IPreloadedValues, thunkApi) => {
        const { status } = await getServices().clients.support.apiSupportCreateSupportCasePost(
            emailModel.email,
            emailModel.prefix + emailModel.phoneNumber,
            emailModel.details,
            Number(emailModel.caseNumber),
            Number(emailModel.role),
            Number(emailModel.supportType),
            emailModel.subject,
            [],
        );
        if (status !== 200) return thunkApi.rejectWithValue("rejected");
    },
);

export const getLoginConfiguration = createAsyncThunk("getLoginConfiguration", async (_, thunkApi) => {
    const { data, status } = await getServices().clients.user.getLoginConfiguration();
    if (status === 200) {
        const converted = data as unknown;
        return converted as LoginConfiguration;
    }
    return thunkApi.rejectWithValue("rejected");
});

export const updatePassword = createAsyncThunk(
    "updatePassword",
    async (passwordChangeModel: PasswordChange, thunkApi) => {
        const { data, status } = await getServices().clients.user.updatePassword(passwordChangeModel);
        if (status === 200) return data;
        return thunkApi.rejectWithValue("rejected");
    },
);
