import Icon from "components/Icon";
import SecondaryButton from "components/SecondaryButton";
import { useDoubleDatepickerProps } from "hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { MetaData, S3FileInfo } from "swagger/storage";
import { actions, IFileData, MedicalCertificateModals, selectors } from "../../redux";
import { certificates as cUtils } from "../../utils";
import AdjustMedicalCertificateMetadata from "../AdjustMedicalCertificateMetadata";

interface IProps {
    groupKey?: string;
    owner?: string;
    metaData: MetaData[];
    files: S3FileInfo[];
}
const ManageCertificate = ({ groupKey, owner, metaData, files }: IProps) => {
    const dispatch = useAppDispatch();
    const adjustMedicalCertificateMetadata = useAppSelector(
        selectors.getModal(MedicalCertificateModals.adjustMedicalCertificateMetadata),
    );

    const getMetadataByKey = useCallback(
        (key: cUtils.MetadataKeys, value: IFileData[] | undefined) => cUtils.getMetadataValueByKey(key, value),
        [],
    );
    const { t } = useTranslation(["translation", "followup"]);

    const certificateDatepickerProps = useDoubleDatepickerProps({
        onChangeDateRange: () => {},
        givenDateRange: {
            from: getMetadataByKey(cUtils.MetadataKeys.from, metaData),
            to: getMetadataByKey(cUtils.MetadataKeys.to, metaData),
        },
        placeholderFrom: t("addDate"),
        placeholderTo: t("addDate"),
        className: "!w-full",
    });
    if (!groupKey || !owner) return null;

    return (
        <div className="flex py-4 px-8 gap-2">
            <SecondaryButton
                leftIcon={<Icon icon="trashCan" />}
                id="remove-medical-certficate"
                onClick={() => {
                    dispatch(actions.deleteMedicalCertificate({ owner, groupKey }));
                }}
                text={t("delete")}
            />
            {adjustMedicalCertificateMetadata && (
                <AdjustMedicalCertificateMetadata
                    certificateDatePickerProps={certificateDatepickerProps}
                    groupKey={groupKey}
                    owner={owner}
                    degree={getMetadataByKey(cUtils.MetadataKeys.degree, metaData)}
                    openModal={adjustMedicalCertificateMetadata}
                    setOpen={() => {
                        dispatch(actions.hideModal(MedicalCertificateModals.adjustMedicalCertificateMetadata));
                    }}
                    close={() => {
                        dispatch(actions.hideModal(MedicalCertificateModals.adjustMedicalCertificateMetadata));
                    }}
                    files={files}
                />
            )}
        </div>
    );
};

export default ManageCertificate;
