import { IHealthCase } from "../redux/followUpStateTypes";
import { TagPresets } from "@medhelp/ui/Tag/StatusTag/StatusTag";
import { Icons } from "components/Icon";

export interface ITagProps {
    id: string;
    color: TagPresets;
    icon?: Icons;
}

const tagProps: ITagProps[] = [
    {
        id: "ongoing",
        color: "green",
        icon: "checkCircle",
    },
    {
        id: "paused",
        color: "grey",
        icon: "pauseCircle",
    },
    {
        id: "ended",
        color: "grey",
        icon: "xCircle",
    },
    {
        id: "declined",
        color: "grey",
        icon: "xCircle",
    },
    {
        id: "preliminary",
        color: "red",
    },
    {
        id: "expired",
        color: "grey",
        icon: "clock",
    },
];

export const getTagProps = (status: string | null | undefined) => {
    return tagProps.find(({ id }) => id === status);
};

export const isManuallyInitiated = (healthCase: IHealthCase) => {
    return healthCase.healthCaseEvents?.find(
        (event) => event.type === "healthcase" && event.operation === "created" && event.userAccountId,
    );
};

export const isValidJson = (value: string) => {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
};

export const activeCaseTypes = ["ongoing", "peliminary", "paused"];

export const inactiveCaseTypes = ["ended", "declined", "expired"];
