import { flow, get, toLower, uniqBy } from "lodash/fp";
import { useState, useEffect, useCallback } from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
export interface IFile {
    preview: string;
    name: string;
    type: string;
    path: string;
    file: File;
}

export enum FileStatus {
    LimitExceeded = "filesExceed",
    ok = "ok",
}

const useFileUploader = (
    prependFiles?: (setFiles: React.Dispatch<React.SetStateAction<IFile[]>>) => void,
    accept: string[] | undefined = [],
) => {
    const [files, setFiles] = useState<Array<IFile>>([]);
    const [status, setStatus] = useState(FileStatus.ok);
    const { open, getInputProps, getRootProps, rootRef, fileRejections } = useDropzone({
        maxFiles: 8,
        multiple: true,
        onDrop: (acceptedFiles) => {
            const files = acceptedFiles.map((file: FileWithPath) => {
                const modifiedFile = new File([file], toLower(file.name), {
                    type: file.type,
                    lastModified: file.lastModified,
                });
                return {
                    preview: URL.createObjectURL(file),
                    name: toLower(file.name),
                    type: file.type,
                    path: file?.path ? toLower(file?.path) : "",
                    file: modifiedFile,
                };
            });
            setFiles((state) => {
                const numberOfFiles = state.length + files.length;
                if (numberOfFiles > 8) {
                    setStatus(FileStatus.LimitExceeded);
                    return state;
                }
                return uniqBy(get("name"), [...state, ...files]);
            });
        },
        accept: [".jpeg", ".png", ".pdf", ".webp", ".heic", ".jpg", ...accept],
    });

    useEffect(() => {
        if (prependFiles) prependFiles(setFiles);
    }, [prependFiles]);
    /**
     * Remove file by `fileName`
     */
    const removeFile = useCallback((name: string) => {
        setFiles((state) => state.filter(flow(get("name"), (filtername) => filtername !== name)));
    }, []);

    const countFileTypes = useCallback((files: IFile[]) => {
        let pdfCount = 0;
        let imageCount = 0;

        for (const file of files) {
            if (file.type === "application/pdf") {
                pdfCount++;
            } else {
                imageCount++;
            }
        }

        return { pdfCount, imageCount };
    }, []);

    const checkMedicalCertificateLimit = useCallback(() => {
        if (fileRejections.length > 1) {
            setStatus(FileStatus.LimitExceeded);
            return;
        }
        const { pdfCount, imageCount } = countFileTypes(files);
        if (pdfCount > 0 && imageCount > 0) {
            setStatus(FileStatus.LimitExceeded);
            return;
        }
        if (pdfCount > 1 || imageCount > 8) {
            setStatus(FileStatus.LimitExceeded);
            return;
        }
        setStatus(FileStatus.ok);
    }, [fileRejections.length, countFileTypes, files]);

    const clearFiles = useCallback(() => setFiles([]), []);

    return {
        removeFile,
        open,
        getInputProps,
        getRootProps,
        files,
        clearFiles,
        status,
        rootRef,
        checkMedicalCertificateLimit,
    };
};
export default useFileUploader;
