import { SingleDatepicker } from "@medhelp/ui";
import { ISingleDatepickerProps } from "@medhelp/ui/Datepicker/types";
import { useEffect } from "react";
import { Borders, changeBorderForGlobalClass } from "utils/dom";

interface IProps {
    singleDatepickerProps: ISingleDatepickerProps;
    changeBorder?: boolean;
    disableDateMin?: string | undefined;
    disableDateMax?: string | undefined;
}
const FollowUpDateView = ({ singleDatepickerProps, changeBorder, disableDateMin, disableDateMax }: IProps) => {
    useEffect(() => {
        if (changeBorder) {
            const borders: Borders[] = ["Left", "Right", "Bottom", "Top"];
            changeBorderForGlobalClass({
                borders,
                size: 0,
                color: "#192d05",
                cssClass: "DateInput_input",
                onlyLastElement: false,
            });
        }
    }, [changeBorder]);

    return (
        <SingleDatepicker
            {...singleDatepickerProps}
            disableDatesBefore={disableDateMin}
            disableDatesAfter={disableDateMax}
        />
    );
};
export default FollowUpDateView;
