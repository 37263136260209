import { isManager } from "../utils";
import { Status } from "store/userStateTypes";
import { createSlice } from "@reduxjs/toolkit";
import { UserEmployment } from "swagger/customercompany";
import { initFortnoxAdmin, saveEmployeeAccessRights } from "./fortnoxAdministrationActions";

export type EmployeeWithAccessRights = UserEmployment & {
    isManager: boolean;
    isAdmin: boolean;
};

interface IFortnoxAdministrationSlice {
    employees: EmployeeWithAccessRights[];
    topDepartmentId?: number;
    departmentReferenceKey?: string;
    status: {
        initFortnoxAdmin?: Status;
    };
}

const initialState: IFortnoxAdministrationSlice = {
    employees: [],
    topDepartmentId: undefined,
    departmentReferenceKey: undefined,
    status: {
        initFortnoxAdmin: undefined,
    },
};

const fortnoxAdministrationSlice = createSlice({
    name: "fortnoxAdministration",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(initFortnoxAdmin.fulfilled, (state, action) => {
                const employees = action.payload?.employees || [];
                const employeesWithAccessRights = employees.map((employee: UserEmployment) => {
                    const companyAccessRights = action.payload?.companyAccessRights.find(
                        (userAccount) => userAccount.id === employee.userAccountId,
                    );

                    const departmentAccesRights = action.payload?.departmentAccesRights.find(
                        (userAccount) => userAccount.id === employee.userAccountId,
                    );

                    return {
                        ...employee,
                        isManager: isManager(departmentAccesRights?.accessRights || [], action.payload.topDepartmentId),
                        isAdmin:
                            (companyAccessRights?.customerCompanyAdministration &&
                                companyAccessRights.customerOrganizationAdministration) ||
                            false,
                    };
                });

                state.employees = employeesWithAccessRights;
                state.topDepartmentId = action.payload.topDepartmentId;
                state.departmentReferenceKey = action.payload.departmentReferenceKey;
                state.status.initFortnoxAdmin = Status.OK;
            })

            .addCase(initFortnoxAdmin.rejected, (state) => {
                state.status.initFortnoxAdmin = Status.ERROR;
            })
            .addCase(initFortnoxAdmin.pending, (state) => {
                state.status.initFortnoxAdmin = Status.PENDING;
            })
            .addCase(saveEmployeeAccessRights.fulfilled, (state, action) => {
                const { userAccountId, isManagerChecked, isAdminChecked } = action.payload;
                const itemIndex = state.employees.findIndex((employee) => employee.userAccountId === userAccountId);
                if (itemIndex !== -1) {
                    state.employees[itemIndex].isManager = isManagerChecked;
                    state.employees[itemIndex].isAdmin = isAdminChecked;
                }
            });
    },
});

export const { actions, reducer } = fortnoxAdministrationSlice;
