import Icon from "components/Icon/Icon";
import useEmblaCarousel from "embla-carousel-react";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { type EmblaCarouselType as CarouselApi } from "embla-carousel";

const Carousel = ({ slides }: { slides: ReactNode[] }) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);
    const onInit = useCallback((emblaApi: CarouselApi) => setScrollSnaps(emblaApi.scrollSnapList()), []);
    const onSelect = useCallback((emblaApi: CarouselApi) => setSelectedIndex(emblaApi.selectedScrollSnap()), []);

    useEffect(() => {
        if (!emblaApi) return;
        onInit(emblaApi);
        onSelect(emblaApi);
        emblaApi.on("reInit", onInit);
        emblaApi.on("reInit", onSelect);
        emblaApi.on("select", onSelect);
    }, [emblaApi, onInit, onSelect]);

    return (
        <div className="h-full flex flex-col justify-between relative">
            <div className="h-full w-8 xs:flex items-center justify-center absolute hidden">
                <button onClick={scrollPrev} className="py-1.5 px-0.5 rounded-sm hover:bg-mhgrey-light">
                    <Icon icon="caretLeft" />
                </button>
            </div>
            <div className="h-full w-8 xs:flex items-center justify-center absolute right-0 hidden">
                <button onClick={scrollNext} className="py-1.5 px-0.5 rounded-sm hover:bg-mhgrey-light">
                    <Icon icon="caretRight" />
                </button>
            </div>
            <div className="mb-auto overflow-hidden scrollbar-hide px-0 xs:px-8">
                <div className="overflow-hidden cursor-default">
                    <div ref={emblaRef}>
                        <div className="flex">
                            {slides.map((item, index) => (
                                <div className="embla-slide" key={index}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center h-10 gap-1">
                {scrollSnaps.map((_, index) => (
                    <div className="hover:cursor-pointer" onClick={() => scrollTo(index)} key={index}>
                        <Icon size={14} icon={selectedIndex === index ? "circleFull" : "circle"} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
