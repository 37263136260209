import * as React from "react";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as Warning } from "icons/warning.svg";

type Status = "info" | "error";
interface IProps {
    children: React.ReactNode;
    status?: Status;
}

const Icon = React.memo(({ status }: Pick<IProps, "status">) => {
    switch (status) {
        case "info": {
            return <InfoIcon width="24" height="24" fill="transparent" data-testid="info-status-infobox" />;
        }
        case "error": {
            return <Warning width="24" height="24" fill="transparent" data-testid="error-status-infobox" />;
        }
        default: {
            return <InfoIcon width="24" height="24" fill="transparent" data-testid="info-status-infobox" />;
        }
    }
});
const Infobox = ({ children, status = "info" }: IProps) => (
    <div className="border-2 border-solid border-background-300 flex">
        <div className="flex items-center justify-center basis-24 bg-info border-r-2">
            <Icon status={status} />
        </div>
        <div className="flex items-center flex-1 p-8">{children}</div>
    </div>
);

export default Infobox;
