import { useAppDispatch, useAppSelector } from "store/hooks";
import Subscriptions from "./Subscriptions";
import {
    getCreateOrUpdateSuccessful,
    getCurrentlyEditingStateAccordion,
    getDeleteSubscriptionId,
    getOrgTreeView,
    getSelectedCompanyId,
    getSelectedCompanyUserEmploymentId,
    getSelectedUserAccountId,
    getStateSubscriptionData,
    getSubscriptionIsLoading,
    getSubscriptionPostModel,
    getSubscriptionProps,
    getSubscriptionTabProps,
    getUsersIsLoading,
    getSearchUserAndCompanyToAddModel,
} from "pages/Administration/redux/administrationSelectors";
import { useCallback, useEffect } from "react";
import { ICheckBoxStateProps, ISubscriptionAccordionStateProps, Tab } from "pages/Administration/types";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import {
    checkExternalSubscription,
    createOrUpdateEventSubscription,
    getCompanyUserInCompanyGroup,
    getOrganizationTreeView,
    getSubscriptionData,
    getSubscriptionTableItems,
    removeSubscription,
    searchUserAndCompanyToAdd,
} from "pages/Administration/redux/administrationAsyncActions";
import { Spinner } from "components/Spinner";
import { useSingleDatepickerProps } from "hooks";
import { RemoveSubscriptions, SubscriptionLookup } from "swagger/employeeattendance";
import { getDeleteSubscriptionRequestModel, getMonitoredIdsFromClickedDepartment } from "pages/Administration/utils";
import { useTranslation } from "react-i18next";
import { UpdateProps } from "pages/Administration/components/Subscriptions/CreateNewSubscription/CreateNewSubscription";
import { useNavigate } from "react-router-dom";

export default function Index() {
    const { t } = useTranslation("administration");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const subscriptionData = useAppSelector(getStateSubscriptionData);
    const subscriptionIsLoading = useAppSelector(getSubscriptionIsLoading);
    const usersIsLoading = useAppSelector(getUsersIsLoading);
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const selectedUserAccountId = useAppSelector(getSelectedUserAccountId);
    const selectedUserEmploymentId = useAppSelector(getSelectedCompanyUserEmploymentId);
    const subscriptionTabProps = useAppSelector(getSubscriptionTabProps);
    const subscriptionsProps = useAppSelector(getSubscriptionProps);
    const deleteSubscriptionId = useAppSelector(getDeleteSubscriptionId);
    const currentlyEditingStateAccordion = useAppSelector(getCurrentlyEditingStateAccordion);
    const subscriptionPostModel = useAppSelector(getSubscriptionPostModel);
    const subCreateOrUpdateSuccessful = useAppSelector(getCreateOrUpdateSuccessful);
    const orgTreeView = useAppSelector(getOrgTreeView);
    const searchUserAndCompanyToAddModel = useAppSelector(getSearchUserAndCompanyToAddModel);

    const startDatepickerProps = useSingleDatepickerProps({
        onChangeDate: (v) => dispatch(administrationActions.setSubscriptionStartdate(v)),
        givenSelectedDate: currentlyEditingStateAccordion.startDate,
        placeholder: "Välj startdatum",
    });
    const endDatepickerProps = useSingleDatepickerProps({
        onChangeDate: (v) => dispatch(administrationActions.setSubscriptionEnddate(v)),
        givenSelectedDate: currentlyEditingStateAccordion.endDate,
        placeholder: "Tillsvidare",
    });

    const handleActiveTab = useCallback(
        (activeTab: Tab) => dispatch(administrationActions.setActiveSubscriptionTab(activeTab)),
        [dispatch],
    );

    const handleAccordionOpen = useCallback(
        (accordion: ISubscriptionAccordionStateProps) => {
            dispatch(administrationActions.setSubscriptionAccordionOpen(accordion));
        },
        [dispatch],
    );

    const handleDeleteSubscriptionId = useCallback(
        (accordionId: number) => {
            dispatch(administrationActions.setDeleteSubscriptionId(accordionId));
            dispatch(administrationActions.setModalHeader(t("removeSubscription")));
            dispatch(administrationActions.setModalOpen());
        },
        [dispatch, t],
    );

    const handleNewSubscription = useCallback(() => {
        dispatch(
            administrationActions.setModalHeader(
                subscriptionTabProps.activeTab === "userSubsOn"
                    ? t("newSubscriptionFor", {
                          name: subscriptionTabProps.selectedUser,
                      })
                    : t("newSubscriberOn", {
                          name: subscriptionTabProps.selectedUser,
                      }),
            ),
        );
        dispatch(administrationActions.setNewSubscriptionInitialProps());
        dispatch(administrationActions.setDeleteSubscriptionId(undefined));
        dispatch(administrationActions.setModalOpen());
    }, [dispatch, subscriptionTabProps.activeTab, subscriptionTabProps.selectedUser, t]);

    const handleOnCheckboxChange = useCallback(
        (checkboxes: ICheckBoxStateProps[]) => dispatch(administrationActions.setNotificationTypeChecked(checkboxes)),
        [dispatch],
    );
    const handleAccordionSave = useCallback(() => {
        dispatch(createOrUpdateEventSubscription(subscriptionPostModel));
    }, [dispatch, subscriptionPostModel]);

    const handleSubscriptionDelete = useCallback(() => {
        const subscriptioAboutToBeDeleted =
            subscriptionTabProps.activeTab === "userSubsOn"
                ? subscriptionData?.userSubsOnTableItems.find((sub) => sub.id === deleteSubscriptionId)
                : subscriptionData?.subsOnUserTableItems[deleteSubscriptionId ?? -1];
        if (subscriptioAboutToBeDeleted) {
            const deleteModel: RemoveSubscriptions = {
                companyId: selectedCompanyId ?? -1,
                subscriptions: getDeleteSubscriptionRequestModel(subscriptioAboutToBeDeleted),
            };
            dispatch(administrationActions.setModalOpen());
            dispatch(removeSubscription(deleteModel));
        }
    }, [
        deleteSubscriptionId,
        dispatch,
        selectedCompanyId,
        subscriptionData?.subsOnUserTableItems,
        subscriptionData?.userSubsOnTableItems,
        subscriptionTabProps.activeTab,
    ]);

    const handleNewSubscriptionOnChange = useCallback(
        (eventName: UpdateProps, value: string | number | SubscriptionLookup) => {
            switch (eventName) {
                case "selectedDepartment":
                    dispatch(
                        administrationActions.setSelectedDepartmentIdsAsMonitoredIds(
                            getMonitoredIdsFromClickedDepartment(
                                Number(value),
                                subscriptionsProps.newSubscriptionStateProps.departmentsDisplayList,
                                subscriptionsProps.currentlyEditingAccordion.monitoredIds,
                            ),
                        ),
                    );
                    break;

                case "newSubSearchQuery":
                    if (value.toString().length > 1) {
                        dispatch(
                            searchUserAndCompanyToAdd({
                                ...searchUserAndCompanyToAddModel,
                                searchString: value.toString(),
                            }),
                        );
                        dispatch(administrationActions.setNewSubscriptionSearchQuery(value.toString()));
                    }
                    break;

                case "checkIfNewExternalUserExists":
                    dispatch(
                        checkExternalSubscription({
                            companyId: selectedCompanyId ?? -1,
                            searchString: value.toString(),
                        }),
                    );
                    break;

                case "selectedSearchResult":
                    //Need to check if searched subscribing user is missing email or phone
                    if (
                        subscriptionTabProps.activeTab === "subsOnUser" &&
                        (value as SubscriptionLookup).lookupType === 1
                    ) {
                        dispatch(
                            getCompanyUserInCompanyGroup({
                                userAccountId: (value as SubscriptionLookup).userAccountId ?? -1,
                                companyId: selectedCompanyId ?? -1,
                            }),
                        );
                    }
                    dispatch(
                        administrationActions.setNewSubscriptionProp({
                            eventName,
                            value,
                        }),
                    );
                    break;

                default:
                    dispatch(
                        administrationActions.setNewSubscriptionProp({
                            eventName,
                            value,
                        }),
                    );
                    break;
            }
        },
        [
            dispatch,
            searchUserAndCompanyToAddModel,
            selectedCompanyId,
            subscriptionTabProps.activeTab,
            subscriptionsProps.currentlyEditingAccordion.monitoredIds,
            subscriptionsProps.newSubscriptionStateProps.departmentsDisplayList,
        ],
    );

    const handleMissingContactKey = useCallback(() => {
        navigate(
            `/administration/users/addUser/user#userAccountId=${currentlyEditingStateAccordion.currentTableItemUserAccountId}`,
        );
    }, [currentlyEditingStateAccordion.currentTableItemUserAccountId, navigate]);

    useEffect(() => {
        if (subCreateOrUpdateSuccessful && selectedCompanyId && selectedUserAccountId && selectedUserEmploymentId) {
            // dispatch(administrationActions.resetSaveOrUpdateIsSuccessful());

            dispatch(
                getSubscriptionTableItems({
                    companyId: selectedCompanyId,
                    userAccountId: selectedUserAccountId,
                    employmentId: selectedUserEmploymentId,
                    //Todo kolla upp
                    lookupType: 1,
                }),
            );
        }
    }, [subCreateOrUpdateSuccessful, dispatch, selectedCompanyId, selectedUserAccountId, selectedUserEmploymentId]);

    useEffect(() => {
        if (subscriptionData === null && selectedUserAccountId && selectedCompanyId && selectedUserEmploymentId) {
            dispatch(
                getSubscriptionData({
                    companyId: selectedCompanyId,
                    userAccountId: selectedUserAccountId,
                    employmentId: selectedUserEmploymentId,
                    lookupType: 1,
                }),
            );
        }
    }, [dispatch, selectedCompanyId, selectedUserAccountId, selectedUserEmploymentId, subscriptionData]);

    useEffect(() => {
        if (!orgTreeView && selectedCompanyId) {
            dispatch(getOrganizationTreeView(selectedCompanyId));
        }
    }, [dispatch, orgTreeView, selectedCompanyId]);

    return usersIsLoading || subscriptionIsLoading ? (
        <Spinner />
    ) : (
        <Subscriptions
            {...subscriptionsProps}
            tabCompProps={{
                ...subscriptionTabProps,
                setActiveTab: handleActiveTab,
            }}
            setAccordionOpen={handleAccordionOpen}
            onCreateNewSubscription={handleNewSubscription}
            setDeleteSubscriptionId={handleDeleteSubscriptionId}
            modalButtonClicked={deleteSubscriptionId !== undefined ? handleSubscriptionDelete : handleAccordionSave}
            accordionSaveClicked={handleAccordionSave}
            onCheckboxChange={handleOnCheckboxChange}
            startDatepickerProps={startDatepickerProps}
            endDatepickerProps={endDatepickerProps}
            newSubscriptionOnChange={handleNewSubscriptionOnChange}
            onMissingContactKeyClicked={handleMissingContactKey}
        />
    );
}
