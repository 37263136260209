import React from "react";
import { useTranslation } from "react-i18next";
import RadioButton from "../RadioButton";
import Select, { ISelectValue } from "../Select";
import { twMerge } from "tailwind-merge";

interface RadioButtonGroupProps {
    id: string;
    values: ISelectValue[];
    clicked: (value: string | undefined) => void;
    selectedValue?: string | null;
    disabled?: boolean;
    isHorizontal?: boolean;
    withPlaceholder?: boolean;
}

const RadioButtonGroup = ({
    id,
    values,
    clicked,
    selectedValue,
    disabled,
    isHorizontal = false,
    withPlaceholder,
}: RadioButtonGroupProps) => {
    const { t } = useTranslation();
    if (!values) return null;

    const renderSelect = () => {
        const valuesWithPlaceholder = [{ value: "", description: t("chooseValue") }, ...values];
        return (
            <Select
                id={id}
                disabled={disabled}
                size="large"
                values={valuesWithPlaceholder!}
                onChange={clicked}
                selectedValue={selectedValue!}
                sortDescriptions
            />
        );
    };

    const renderRadioButtons = () => {
        return values.map((value) => (
            <RadioButton
                key={value.value}
                id={id}
                disabled={disabled}
                values={value}
                selected={selectedValue === value.value}
                clicked={clicked}
            />
        ));
    };

    const buttonsOrSelect = withPlaceholder && values.length > 5 ? renderSelect() : renderRadioButtons();

    return (
        <div
            className={twMerge(
                "flex flex-col gap-y-6 gap-x-16 flex-wrap md:max-h-[20rem]",
                isHorizontal && "xs:flex xs:flex-row",
            )}
        >
            {buttonsOrSelect}
        </div>
    );
};
export default RadioButtonGroup;
