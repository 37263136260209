import { AddRoleSubscriptionConfigration, Channel, CompanySubscriptionConfiguration } from "swagger/messenger";
import RadioButton from "../RadioButton";
import { useTranslation } from "react-i18next";
import { getChannelText } from "pages/Administration/utils";
import Icon from "components/Icon";
import { IRadioButtonProps } from "../RadioButton/RadioButton";
import AdminSelect from "../../AdminSelect";
import { Heading } from "@medhelp/ui";

export interface INewRoleSubscriptionProps {
    newRow: AddRoleSubscriptionConfigration | null;
    availableNotificationTypes: CompanySubscriptionConfiguration[];
    channels: IRadioButtonProps[];
    roles: IRadioButtonProps[];
    notificationSelectOpen: boolean;
    setOpen: (open: boolean) => void;
    onRowChange: (row: AddRoleSubscriptionConfigration) => void;
    deleteClicked: () => void;
}
const RoleSubscriptionNewRow = ({
    newRow,
    availableNotificationTypes,
    channels,
    roles,
    notificationSelectOpen,
    setOpen,
    onRowChange,
    deleteClicked,
}: INewRoleSubscriptionProps) => {
    const { t } = useTranslation("administration");
    return (
        <div className="pb-4 border-b flex flex-col">
            <p className="py-4 font-bold">{t("newSubscription")}</p>
            <div className="w-full px-5 flex flex-col xxs:flex-row">
                <div className="w-full flex flex-col xxs:mr-3 xxs:w-2/5">
                    <Heading heading={t("notificationType")} mandatory />
                    <AdminSelect
                        open={notificationSelectOpen}
                        setOpen={setOpen}
                        newRow={newRow}
                        availableNotificationTypes={availableNotificationTypes}
                        onChange={onRowChange}
                        disabled={availableNotificationTypes.length < 1}
                    />
                </div>
                <div className="w-full flex flex-col xxs:mr-3 xxs:w-1/5">
                    <Heading heading={t("channel")} mandatory />
                    <div className="w-max">
                        {channels.map((channelProps) => (
                            <RadioButton
                                {...channelProps}
                                text={t(getChannelText(Number(channelProps.text)))}
                                setChecked={() =>
                                    onRowChange({
                                        ...newRow,
                                        channel: Number(channelProps.text) as Channel,
                                    })
                                }
                                key={channelProps.text}
                            />
                        ))}
                    </div>
                </div>
                <div className="w-full flex flex-col xxs:mr-3 xxs:w-2/5">
                    <Heading heading={t("recipient")} mandatory />
                    <div className="w-max">
                        {roles.map((roleProps, i) => (
                            <RadioButton
                                {...roleProps}
                                text={roleProps.text ? t(roleProps.text) : ""}
                                setChecked={() =>
                                    onRowChange({
                                        ...newRow,
                                        role: roleProps.text,
                                    })
                                }
                                key={`${roleProps.text}-${i}`}
                            />
                        ))}
                    </div>
                </div>
                <button className="w-10 h-10 flex items-center justify-center" onClick={deleteClicked}>
                    <Icon icon="trashCan" size={22} />
                </button>
            </div>
        </div>
    );
};

export default RoleSubscriptionNewRow;
