import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { updateCaseActivityPerformed as updateCaseActivityPerformedAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const updateCaseActivityPerformed = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(updateCaseActivityPerformedAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.payload?.activityId,
                );
                if (currentActivity) {
                    currentActivity.performed = true;
                    currentActivity.performedBy = action.payload?.userAccountId;
                    currentActivity.status = Status.OK;
                }
            }
        })
        .addCase(updateCaseActivityPerformedAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.meta.arg.activityId,
                );
                if (currentActivity) {
                    currentActivity.status = Status.PENDING;
                }
            }
        })
        .addCase(updateCaseActivityPerformedAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.meta.arg.activityId,
                );
                if (currentActivity) {
                    currentActivity.status = Status.ERROR;
                }
            }
        });

export default updateCaseActivityPerformed;
