import { IViewElements } from "@medhelp/ui/CheckboxFilter";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import DropdownCheckboxFilter from "../DropdownCheckboxFilter";

interface IProps {
    data: IDynamicData[];
    setData: (value: string) => void;
    viewElements: IViewElements;
    keepClose: boolean;
    loading: boolean;
    header: string;
}

const CompaniesDropdown = (props: IProps) => <DropdownCheckboxFilter {...props} />;

export default CompaniesDropdown;
