import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { actions, selectors } from "../../redux";
import { get, isEmpty } from "lodash/fp";
import { findTagByReferenceKey } from "../../utils";
import CaseTypeFilter from "./CaseTypeFilter";

export default function Index() {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const currentFilter = useAppSelector(selectors.getSearchTagByIdSorted("caseType", "asc"));

    const Header = useMemo(() => t("search.searchQuery.filterName.caseType"), [t]);

    const Items = useMemo(() => currentFilter.map((x) => ({ ...x, label: t(x.label) })), [currentFilter, t]);

    const Tags = useMemo(() => {
        const getCheckedItems = currentFilter.filter(get("checked"));
        if (isEmpty(getCheckedItems)) return "";
        return getCheckedItems.map(({ label }) => t(label)).join(", ");
    }, [currentFilter, t]);

    const onChange = useCallback(
        (value: string) => {
            const element = findTagByReferenceKey(value, currentFilter);
            if (!element) return;
            dispatch(
                actions.updateSearchFilter({
                    ...element,
                    checked: !element.checked,
                }),
            );
        },
        [dispatch, currentFilter],
    );
    return <CaseTypeFilter items={Items} header={Header} onChange={onChange} tags={Tags} />;
}
