import Icon from "components/Icon";
import TertiaryButton from "components/TertiaryButton";
import { TailSpin } from "react-loader-spinner";
import ExcelUploadedModal from "../../components/ExcelUploaded";

export interface IProps {
    disabled?: boolean;
    isLoading?: boolean;
}
interface IPropsView extends IProps {
    onSearch: () => void;
    text: string;
    isOpen: boolean;
    excelText: string | null;
    excelHeader: string;
    setOpen: () => void;
    context: string;
}
const ExportSearchExcel = ({
    onSearch,
    text,
    isOpen,
    excelText,
    excelHeader,
    setOpen,
    context,
    disabled,
    isLoading,
}: IPropsView) => {
    return (
        <div className="flex gap-1">
            {isLoading ? (
                <div className="ml-4 flex">
                    <TailSpin color="#3b3b36" height={20} width={20} />
                </div>
            ) : (
                <>
                    <Icon icon="excel" className="h-auto" />
                    <TertiaryButton
                        id="excel-upload-searchfilter"
                        onClick={onSearch}
                        className="h-[20px]"
                        disabled={disabled}
                    >
                        <p className="font-bold">{text}</p>
                    </TertiaryButton>
                    <ExcelUploadedModal
                        isOpen={isOpen}
                        setOpen={setOpen}
                        text={excelText}
                        header={excelHeader}
                        context={context}
                    />
                </>
            )}
        </div>
    );
};

export default ExportSearchExcel;
