import { useAppSelector } from "store/hooks";
import { selectors } from "../../redux";
import { useTranslation } from "react-i18next";
import SortingAndShowFilter from "./SortingAndShowFilter";

export default function Index() {
    const { t } = useTranslation("followup");
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const body = useAppSelector(selectors.getSortingOrBasedOn("sorting", currentSearch));
    return (
        <SortingAndShowFilter
            header={t("mobileFilter.searchHeader")}
            body={t(`mobileFilter.sortingFilters.${body}`)}
            searchType={currentSearch}
        />
    );
}
