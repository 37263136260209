import Icon from "components/Icon";
import PrimaryButton from "components/PrimaryButton";
import { Belonging, EmploymentInfo } from "pages/Administration/components";
import { IEmployementPageProps } from "pages/Administration/types";
import { useTranslation } from "react-i18next";

const Employment = ({
    employmentInfoAtProps,
    belongingPageProps,
    disableSaveButton,
    handleSubmit,
    saveButtonLoading,
}: IEmployementPageProps) => {
    const { t } = useTranslation("administration");

    return (
        <div id="employment-page-container">
            <EmploymentInfo {...employmentInfoAtProps} />
            <Belonging {...belongingPageProps} />
            <div className="p-10 pb-0">
                <PrimaryButton
                    id="add-user-save"
                    text={t("save")}
                    onClick={handleSubmit}
                    disabled={disableSaveButton}
                    isLoading={saveButtonLoading}
                    rightIcon={<Icon icon="caretRight" size={14} />}
                />
            </div>
        </div>
    );
};

export default Employment;
