import { MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter } from "swagger/absencefollowup";
import { OngoingRehabFilter } from "swagger/rehab";
import { TimeSpan } from "../components/Widget/widgetMap";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import {
    MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse,
    MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse,
    MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse,
    MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse,
    MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceDashboardResponse,
    MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse,
} from "swagger/absencefollowup";
import {
    MedHelpHCMWebApiResponseStatusOverviewResponse,
    MedHelpHCMWebApiResponseTodoAndOngoingResponse,
} from "swagger/healthCaseManagement";
import { FilteredLatestRehabResponse, FilteredRehabResponse } from "swagger/rehab";
import { AbsenceWorkRatioForecastResponse, AbsenceWorkRatioResponse } from "swagger/statistics";

export interface ICompany {
    automatedOrganizationalChangeProcess: boolean;
    customerKey: string;
    departmentIds: null;
    id: number;
    industryCode: null;
    name: string;
    newReporting: boolean;
    number: string;
    sizeClass: null;
}

export interface IDynamicData {
    label: string;
    referenceKey: string | number;
    checked: boolean;
    id: string | number;
    children?: IDynamicData[];
    selectable?: boolean;
}

export interface IWidget {
    id: string;
    type: WidgetType;
    order?: number;
    customHeading?: string;
    filter?: any;
    chartData:
        | MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse
        | MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse[]
        | AbsenceWorkRatioResponse
        | MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse
        | MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse[]
        | MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceDashboardResponse
        | FilteredRehabResponse
        | FilteredLatestRehabResponse[]
        | AbsenceWorkRatioForecastResponse
        | MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse
        | MedHelpHCMWebApiResponseStatusOverviewResponse[]
        | MedHelpHCMWebApiResponseTodoAndOngoingResponse
        | null;
    companies: IDynamicData[] | null;
    departments: IDynamicData[] | null;
    employmentGroups: IDynamicData[] | null;
    genders: IDynamicData[];
    absenceTypes: IDynamicData[];
    absencePeriods: IDynamicData[];
    timeInterval: IDynamicData[];
    absenceCauses: IDynamicData[] | null;
    hcmTypes: IDynamicData[] | null;
    status: Status;
}

export type Widget = Pick<IWidget, "id" | "type">;

export interface IUniqueAndOrderedEmployees {
    employeeName: string;
    employmentId: string;
    processType?: string;
    count: number;
}

export interface IEstimatedAbsence {
    day: string;
    estimatedAbsence: number;
    estimatedAbsenceWithPastBackAtWork: number;
    employeeDetails: IUniqueAndOrderedEmployees[];
}

export interface IEmployeeDetails {
    employeeName: string;
    employmentId: string;
    count?: number;
    hasFollowUp?: boolean;
    daysInRehabChain?: number;
    initationDate?: string;
    absences?: number;
    processType?: string;
}

export interface IRecurringAbsence {
    groupName: string;
    employees: IEmployeeDetails[];
}

export interface IWidgetFilter extends MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter {
    absenceCauses?: string;
    hcmTypes?: string[];
}

export interface IWidgetDataFilter {
    companyIds: number[];
    departmentIds: string[] | number[];
    employmentGroupIdentifiers: string[] | number[];
    gender: number | string;
    genders: number[] | string[];
    absenceType: string;
    absenceTypes: string[];
    absencePeriod: string;
    absencePeriods: string[];
    timeInterval: TimeSpan;
    absenceCauses: string;
    hcmTypes: string[];
}

export const WidgetTypes = [
    "ongoing-absence",
    "last-reported-absence",
    "absence-percent-last-6-months",
    "absence-percent-per-month",
    "work-related-absence",
    "estimated-upcoming-absence",
    "recurring-absence-last-12-months",
    "ongoing-rehab",
    "last-activated-rehab",
    "absence-forecast-current-month",
    "absence-cause-proportion",
    "hcm-status-overview",
    "hcm-todo",
] as const;

export type WidgetType = (typeof WidgetTypes)[number];

export interface IReturnValueFromWidgetTransformData {
    data: {
        filter: IWidgetDataFilter;
        type: WidgetType;
    };
}

export type WidgetDropdownType = Pick<
    IWidget,
    | "companies"
    | "departments"
    | "employmentGroups"
    | "genders"
    | "absenceTypes"
    | "absencePeriods"
    | "timeInterval"
    | "absenceCauses"
    | "hcmTypes"
>;

export type WidgetDropdownTypeKeys = keyof WidgetDropdownType;

export const dynamicFilterTypes: WidgetDropdownTypeKeys[] = ["departments", "employmentGroups", "absenceCauses"];

export const isWidgetFollowUpKeys = (widgetFilterKey: WidgetType) =>
    widgetFilterKey === "ongoing-absence" ||
    widgetFilterKey === "last-reported-absence" ||
    widgetFilterKey === "work-related-absence" ||
    widgetFilterKey === "estimated-upcoming-absence" ||
    widgetFilterKey === "recurring-absence-last-12-months";

export const isWidgetRehabKeys = (widgetFilterKey: WidgetType) =>
    widgetFilterKey === "last-activated-rehab" || widgetFilterKey === "ongoing-rehab";

export const isWidgetStatisticsKeys = (widgetFilterKey: WidgetType) =>
    widgetFilterKey === "absence-percent-last-6-months" ||
    widgetFilterKey === "absence-percent-per-month" ||
    widgetFilterKey === "absence-forecast-current-month" ||
    widgetFilterKey === "absence-cause-proportion";

export const isWidgetHCMKeys = (widgetFilterKey: string) =>
    widgetFilterKey === "hcm-status-overview" || widgetFilterKey === "hcm-todo";

export const isWidgetFollowUp = (
    widgetFilter: IWidgetFilter | OngoingRehabFilter,
    widgetFilterKey: WidgetType,
): widgetFilter is IWidgetFilter => {
    return isWidgetFollowUpKeys(widgetFilterKey);
};

export const isWidgetRehab = (
    widgetFilter: IWidgetFilter | OngoingRehabFilter,
    widgetFilterKey: WidgetType,
): widgetFilter is OngoingRehabFilter => isWidgetRehabKeys(widgetFilterKey);

export const isWidgetStatistics = (
    widgetFilter: IWidgetFilter | OngoingRehabFilter,
    widgetFilterKey: WidgetType,
): widgetFilter is IWidgetFilter => isWidgetStatisticsKeys(widgetFilterKey);

export const isWidgetHCM = (
    widgetFilter: IWidgetFilter | OngoingRehabFilter,
    widgetFilterKey: string,
): widgetFilter is IWidgetFilter => isWidgetHCMKeys(widgetFilterKey);

export const isWidgetBase = (widgetFilterKey: WidgetType) => {
    return (
        widgetFilterKey === "last-reported-absence" ||
        widgetFilterKey === "absence-percent-last-6-months" ||
        widgetFilterKey === "absence-percent-per-month" ||
        widgetFilterKey === "recurring-absence-last-12-months" ||
        widgetFilterKey === "hcm-status-overview" ||
        widgetFilterKey === "hcm-todo"
    );
};
