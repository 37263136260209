import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback, useEffect, useState } from "react";
import * as SearchSelectors from "store/searchSelectors";
import * as UserSelectors from "store/userSelectors";
import { getLatestSearches, getPowerbarSearch, ISearchModelExtended } from "store/searchActions";
import { IPowerbarSearch } from "interfaces/ISearch";
import { setOverflow } from "utils/setOverflow";
import { searchActions } from "store/searchSlice";
import PowerbarSearch from "./PowerbarSearch";

export const getSelectedName = (result: IPowerbarSearch): string => {
    const name = result.ssn ? `${result.fullName!} - ${result.companyName}` : result.companyName!;
    return name;
};

export default function Index() {
    const dispatch = useAppDispatch();
    const { isLoading, historySearches, powerbarSearchResult } = useAppSelector(SearchSelectors.getSearchSlice);
    const searchResults = useAppSelector(SearchSelectors.getSearchResults);
    const activeSearchName = useAppSelector(SearchSelectors.getActiveSearchName);
    const userRetailers = useAppSelector(UserSelectors.getUserRetailers) ?? [];
    const [open, setOpen] = useState(false);
    const [hasSearchValue, setHasSearchValue] = useState(false);
    const [inputText, setInputText] = useState("");

    const handleOpenSearch = useCallback(() => {
        if (historySearches.length < 1) {
            dispatch(searchActions.setIsLoading());
            dispatch(getLatestSearches("powerbarSearch"));
        }
        setOpen(true);
    }, [dispatch, historySearches.length]);

    const onChange = useCallback(
        (value: string) => {
            setInputText(value);
            if (value.length > 0) {
                dispatch(searchActions.setIsLoading());
                const searchModel: ISearchModelExtended = {
                    searchViewModel: {
                        searchTerms: value,
                        includeHistory: true,
                        multipleRetailers: userRetailers?.length > 1,
                    },
                    isPhoneNumber: value.substring(0, 1) === "+",
                };
                dispatch(getPowerbarSearch(searchModel));
                setHasSearchValue(true);
                return;
            }
            setHasSearchValue(false);
            dispatch(searchActions.resetPowerbarSearchResult());
        },
        [dispatch, userRetailers?.length],
    );

    useEffect(() => {
        open ? setOverflow("hidden") : setOverflow("auto");
    }, [open]);
    return (
        <PowerbarSearch
            isLoading={isLoading}
            handleOpenSearch={handleOpenSearch}
            powerbarSearchResult={searchResults}
            open={open}
            setOpen={setOpen}
            onChange={onChange}
            activeSearchName={activeSearchName}
            onClose={(value: string) => {
                dispatch(searchActions.resetPowerbarSearchResult());
                setInputText(value);
            }}
            noResult={!isLoading && hasSearchValue && powerbarSearchResult.length < 1}
            inputText={inputText}
        />
    );
}
