import { useTranslation } from "react-i18next";
import { useState } from "react";
import { MedHelpHCMDomainTemplateFormDocument } from "swagger/healthCaseManagement";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { PageTypes } from "../OngoingCaseItem/OngoingCaseItem";
import { ModalCommonFooter } from "@medhelp/ui";
import { CaseTemplateModalContent, PdfViewerModal } from "..";
import ModalFrame from "@medhelp/ui/Modal/ModalFrame";

interface IProps {
    healthCase: IHealthCase;
    modalIsOpen: boolean;
    setModalIsOpen: () => void;
    setPage?: (page: PageTypes) => void;
}

const CaseTemplateModal = (props: IProps) => {
    const { modalIsOpen, healthCase, setModalIsOpen, setPage } = props;
    const { t } = useTranslation("followup");
    const [pdfViewerIsOpen, setPdfViewerIsOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<MedHelpHCMDomainTemplateFormDocument | null>(null);

    return pdfViewerIsOpen ? (
        <PdfViewerModal
            pdfDocumentReferenceKey={selectedTemplate?.pdfDocumentReferenceKey ?? ""}
            documentId={selectedTemplate?.id ?? ""}
            healthCase={healthCase}
            modalIsOpen={modalIsOpen}
            pdfDocumentTitle={selectedTemplate?.title}
            setModalIsOpen={setModalIsOpen}
            setPage={setPage}
        />
    ) : (
        <ModalFrame
            id="case-template-modal"
            header={<h1>{t("selectTemplate")}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            content={
                <CaseTemplateModalContent setSelectedTemplate={setSelectedTemplate} type={healthCase.type ?? ""} />
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    primaryText={t("selectAndEdit")}
                    tertiaryOnClick={setModalIsOpen}
                    primaryOnClick={() => setPdfViewerIsOpen(!pdfViewerIsOpen)}
                    primaryDisabled={!selectedTemplate}
                />
            }
        />
    );
};

export default CaseTemplateModal;
