import { isString } from "lodash/fp";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserRetailer } from "store/userSelectors";
import { thunkActions, selectors } from "../../redux";
import ExportSearchExcel, { IProps } from "./ExportSearchExcel";

export default function Index({ disabled = false, isLoading = false }: IProps) {
    const { t } = useTranslation("followup");
    const [excelUploaded, setExcelUploaded] = useState<string | null>(null);
    const dispatch = useAppDispatch();
    const currentSearch = useAppSelector(selectors.getCurrentSearch);
    const context = useAppSelector(getUserRetailer);

    const onSearch = useCallback(() => {
        if (currentSearch) {
            dispatch(
                thunkActions.exportToExcel({
                    requestType: "excel",
                    type: currentSearch,
                    workBookTitle: t(`excelTitle.${currentSearch}`),
                }),
            ).then(() => {
                setExcelUploaded(t("excelUploadedOkay"));
            });
        }
    }, [currentSearch, dispatch, t]);

    return (
        <ExportSearchExcel
            onSearch={onSearch}
            text={t("export")}
            isOpen={isString(excelUploaded)}
            excelText={excelUploaded}
            excelHeader={t("excelExportHeader")}
            setOpen={() => setExcelUploaded(null)}
            context={context}
            disabled={disabled}
            isLoading={isLoading}
        />
    );
}
