import { useTranslation } from "react-i18next";
import { useParams, Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import {
    getOngoingCases,
    getOngoingAbsenceReports,
    getHasHealthCaseManagement,
    getHasRehabServiceContract,
} from "../../redux/followUpSelectors";
import { getCurrentCertificates } from "packages/medical-certificate/redux/medicalCertificateSelectors";
import { getUserEmploymentId, getHasBaseProduct } from "store/userSelectors";
import { isEmpty } from "lodash/fp";
import { Shadow } from "@medhelp/ui";
import TabMenu from "components/TabMenu";
import { IRoute } from "routes/hooks";
import { CompanyAdministrationAccessRights, DepartmentAdministrationAccessRights } from "pages/FollowUp/accessRights";

const EmployeeMenu = () => {
    const { t } = useTranslation();
    const { routeEmploymentGuid } = useParams();
    const navigate = useNavigate();
    const userEmploymentId = useAppSelector(getUserEmploymentId);
    const ongoingCases = useAppSelector(getOngoingCases);
    const ongoingAbsenceReports = useAppSelector(getOngoingAbsenceReports);
    const ongoingCertificates = useAppSelector(getCurrentCertificates);
    const hasHealthCaseManagement = useAppSelector(getHasHealthCaseManagement);
    const hasRehabServiceContract = useAppSelector(getHasRehabServiceContract);
    const hasBaseProduct = useAppSelector(getHasBaseProduct);

    const Routes: IRoute[] = routeEmploymentGuid
        ? [
              {
                  route: `followup/employee/${routeEmploymentGuid}`,
                  name: "test",
                  indexRoute: "absence",
                  children: [
                      {
                          route: "absence",
                          name: `${t("absence")} (${ongoingAbsenceReports.length})`,
                          children: [],
                      },
                      {
                          route: "case",
                          name: `${t("cases")} (${ongoingCases.length})`,
                          children: [],
                          hidden:
                              routeEmploymentGuid === userEmploymentId ||
                              (hasRehabServiceContract && !hasHealthCaseManagement),
                      },
                      {
                          route: "medical-certificate",
                          name: `${t("medicalCertificate")} (${ongoingCertificates.length})`,
                          children: [],
                      },
                      {
                          route: "subscribe",
                          name: t("subscriptions"),
                          children: [],
                          hidden: hasBaseProduct,
                          whitelist: [...DepartmentAdministrationAccessRights, ...CompanyAdministrationAccessRights],
                      },
                      {
                          route: "profile",
                          name: t("profile"),
                          children: [],
                      },
                  ],
              },
          ]
        : [];

    if (isEmpty(Routes)) {
        navigate("/followup");
        return null;
    }

    return (
        <div>
            <Shadow />
            <TabMenu routes={Routes} subMenuTo={`followup/employee/${routeEmploymentGuid}`}>
                <div className="mt-10">
                    <Outlet />
                </div>
            </TabMenu>
        </div>
    );
};
export default EmployeeMenu;
