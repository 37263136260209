import Icon from "components/Icon";
import { ColumnText, Table, TableCell, TableRow } from "@medhelp/ui";
import { StatusTag } from "@medhelp/ui/Tag";
import { IData } from "../Charts/TableChart";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getDateDisplayValue } from "utils/date";
import { getTagProps } from "pages/FollowUp/utils/case";
import { useSortableData } from "./hooks/useSortableData";
import { MedHelpHCMWebApiResponseSearchHealthCaseResponse } from "swagger/healthCaseManagement";

interface IHCMCaseDetailsTable {
    data: IData | null;
    selectedCase: MedHelpHCMWebApiResponseSearchHealthCaseResponse;
    setSelectedCase: (value: MedHelpHCMWebApiResponseSearchHealthCaseResponse | null) => void;
}

const HCMCaseDetailsTable = ({ data, selectedCase, setSelectedCase }: IHCMCaseDetailsTable) => {
    const navigate = useNavigate();
    const { t } = useTranslation("dashboard");
    const [sortedData, handleSort] = useSortableData(selectedCase.healthCases ?? [], "createdDate");

    const caption = (
        <>
            <button className="flex flex-row items-center mb-8" onClick={() => setSelectedCase(null)}>
                <Icon icon="arrowLeft" />
                <p className="ml-3 font-bold">{t("back")}</p>
            </button>
            <div className="flex justify-between">
                <div className="flex flex-row">
                    <ColumnText header={t("caseManager")} body={selectedCase.name ?? "-"} />
                    <ColumnText header={t("cases")} body={selectedCase.healthCases?.length.toString()} />
                </div>
                <StatusTag
                    id="case-modal-status-tag"
                    text={t(`caseStatus.${data?.status}`)}
                    preset={getTagProps(data?.status)?.color ?? "grey"}
                    className="w-30 text-sm text-center"
                />
            </div>
        </>
    );

    const tableHeaders = [
        { label: t("employee"), accessor: "firstName" },
        { label: t("reason"), accessor: "cause" },
        ...(data?.status === "ongoing"
            ? [
                  { label: t("initiatedRecommended"), accessor: "createdDate" },
                  { label: t("started"), accessor: "startDate" },
              ]
            : [{ label: t("initiated"), accessor: "initiated" }]),
        { label: t("caseManager") },
    ];

    return (
        <Table className="lg:p-10" caption={caption} headers={tableHeaders} handleSort={handleSort}>
            {sortedData.map((item) => (
                <TableRow
                    key={item.employmentId}
                    onClick={() => navigate(`/followup/employee/${item.employmentId}/case`)}
                >
                    <TableCell showOnMobile>{item.fullName ?? "-"}</TableCell>
                    <TableCell>{item.cause ?? "-"}</TableCell>
                    {data?.status === "ongoing" && (
                        <TableCell>
                            {item.startDate ? getDateDisplayValue(item.createdDate, "d MMM yyyy") : ""}
                        </TableCell>
                    )}
                    <TableCell>{item.startDate ? getDateDisplayValue(item.startDate, "d MMM yyyy") : ""}</TableCell>
                    <TableCell>{item.responsibleName ?? "-"}</TableCell>
                </TableRow>
            ))}
        </Table>
    );
};

export default HCMCaseDetailsTable;
