import { useBreakpoints } from "hooks";
import { getColumns, SEARCH_PAGE_SIZE, selectEmployeeSearchColumns } from "pages/FollowUp/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectors, thunkActions, actions } from "store/organization";
import EmployeeSearchFooter from "./EmployeeSearchFooter";
import { useAppDispatch, useAppSelector } from "store/hooks";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const breakpoints = useBreakpoints();

    const pageNumber = useAppSelector(selectors.getCurrentPageNumber);
    const searchCount = useAppSelector(selectors.getCurrentCompaniesDepartmentsTotalCount);
    const checkNextPage = useAppSelector(selectors.getCurrentCompaniesDepartmentsHasMoreWithSetNumber);
    const searchStatus = useAppSelector(selectors.getCurrentCompaniesDepartmentsSearchStatus);
    const requestNextPage = useCallback(
        (value: number) => {
            if (searchStatus === "ok") {
                const searchPageNumber = Math.ceil((value * 10) / SEARCH_PAGE_SIZE);
                if (!checkNextPage(searchPageNumber)) {
                    dispatch(
                        thunkActions.getEmploymentByCompanyIdsOrDepartments({
                            pageNumber: searchPageNumber,
                        }),
                    );
                }
            }
        },
        [checkNextPage, dispatch, searchStatus],
    );

    const setCurrentPageNumber = useCallback(
        (value: number) => {
            requestNextPage(value);
            dispatch(actions.updatePaginatorPageNumber(value));
        },
        [dispatch, requestNextPage],
    );

    const onBackButtonClick = useCallback(
        (value: number) => {
            setCurrentPageNumber(value);
        },
        [setCurrentPageNumber],
    );
    const onForwardButtonClick = useCallback(
        (value: number) => {
            setCurrentPageNumber(value);
        },
        [setCurrentPageNumber],
    );
    const onPageClick = useCallback(
        (value: number) => {
            setCurrentPageNumber(value);
        },
        [setCurrentPageNumber],
    );

    const Columns = useMemo(() => {
        return !breakpoints.isMediumScreen ? selectEmployeeSearchColumns(getColumns(null, "employee")).length : 1;
    }, [breakpoints.isMediumScreen]);

    const firstElement = (pageNumber - 1) * 10;
    const lastPage = firstElement + 10;
    if (!searchCount) return <></>;
    return (
        <EmployeeSearchFooter
            onBackButtonClick={onBackButtonClick}
            onForwardButtonClick={onForwardButtonClick}
            onPageClick={onPageClick}
            activePage={pageNumber}
            pages={Math.ceil(searchCount / 10)}
            columns={Columns}
            leftText={t("search.numberOfSearchReports", {
                showing: `${firstElement + 1}-${lastPage > searchCount ? searchCount : lastPage}`,
                amount: searchCount,
            })}
        />
    );
}
