import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { deleteCaseActivity as deleteCaseActivityAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const deleteCaseActivity = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(deleteCaseActivityAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                const filteredActivities = currentHealthCase.healthCaseActivities.filter(
                    ({ id }) => id !== action.payload?.activityId,
                );
                currentHealthCase.healthCaseActivities = filteredActivities;
            }
        })
        .addCase(deleteCaseActivityAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.meta.arg.activityId,
                );
                if (currentActivity) {
                    currentActivity.status = Status.PENDING;
                }
            }
        })
        .addCase(deleteCaseActivityAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentActivity = currentHealthCase.healthCaseActivities.find(
                    ({ id }) => id === action.meta.arg.activityId,
                );
                if (currentActivity) {
                    currentActivity.status = Status.ERROR;
                }
            }
        });

export default deleteCaseActivity;
