/* tslint:disable */
/* eslint-disable */
/**
 * MedHelp.People.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface MedHelpPeopleDomainContent
 */
export interface MedHelpPeopleDomainContent {
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainContent
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleDomainContent
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleDomainContent
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainContent
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainContent
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainContent
     */
    'link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainContent
     */
    'language'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainContent
     */
    'page'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainContent
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainContent
     */
    'prompt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainContent
     */
    'region'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleDomainLocalSearch
 */
export interface MedHelpPeopleDomainLocalSearch {
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainLocalSearch
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleDomainLocalSearch
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainLocalSearch
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainLocalSearch
     */
    'data'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainLocalSearch
     */
    'employmentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainLocalSearch
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleDomainNews
 */
export interface MedHelpPeopleDomainNews {
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainNews
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleDomainNews
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleDomainNews
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainNews
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainNews
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpPeopleDomainNews
     */
    'published'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainNews
     */
    'publishedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainNews
     */
    'publisher'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleDomainWelcomeMessage
 */
export interface MedHelpPeopleDomainWelcomeMessage {
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWelcomeMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleDomainWelcomeMessage
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWelcomeMessage
     */
    'companyReferenceId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleDomainWelcomeMessage
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWelcomeMessage
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWelcomeMessage
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWelcomeMessage
     */
    'linkText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWelcomeMessage
     */
    'linkUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleDomainWidget
 */
export interface MedHelpPeopleDomainWidget {
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWidget
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleDomainWidget
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWidget
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWidget
     */
    'filter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleDomainWidget
     */
    'customHeading'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleDomainWidget
     */
    'order'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleWebApiModelsCreateContentModel
 */
export interface MedHelpPeopleWebApiModelsCreateContentModel {
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleWebApiModelsCreateContentModel
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleWebApiModelsCreateContentModel
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateContentModel
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateContentModel
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateContentModel
     */
    'page': string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateContentModel
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateContentModel
     */
    'prompt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateContentModel
     */
    'region'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleWebApiModelsCreateEmploymentModel
 */
export interface MedHelpPeopleWebApiModelsCreateEmploymentModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateEmploymentModel
     */
    'companyId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpPeopleWebApiModelsCreateEmploymentModel
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateEmploymentModel
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleWebApiModelsCreateNewsModel
 */
export interface MedHelpPeopleWebApiModelsCreateNewsModel {
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleWebApiModelsCreateNewsModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpPeopleWebApiModelsCreateNewsModel
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateNewsModel
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateNewsModel
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleWebApiModelsCreateUnitModel
 */
export interface MedHelpPeopleWebApiModelsCreateUnitModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateUnitModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateUnitModel
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateUnitModel
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateUnitModel
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsCreateUnitModel
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleWebApiModelsUpdateContentModel
 */
export interface MedHelpPeopleWebApiModelsUpdateContentModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsUpdateContentModel
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsUpdateContentModel
     */
    'prompt'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpPeopleWebApiModelsUpdateNewsModel
 */
export interface MedHelpPeopleWebApiModelsUpdateNewsModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsUpdateNewsModel
     */
    'header'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpPeopleWebApiModelsUpdateNewsModel
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface MicrosoftAspNetCoreMvcOkResult
 */
export interface MicrosoftAspNetCoreMvcOkResult {
    /**
     * 
     * @type {number}
     * @memberof MicrosoftAspNetCoreMvcOkResult
     */
    'statusCode'?: number;
}

/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentIdDelete', 'id', id)
            const localVarPath = `/Content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpPeopleWebApiModelsUpdateContentModel} [medHelpPeopleWebApiModelsUpdateContentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentIdPut: async (id: string, medHelpPeopleWebApiModelsUpdateContentModel?: MedHelpPeopleWebApiModelsUpdateContentModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('contentIdPut', 'id', id)
            const localVarPath = `/Content/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpPeopleWebApiModelsUpdateContentModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentNameLanguageGet: async (name: string, language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('contentNameLanguageGet', 'name', name)
            // verify required parameter 'language' is not null or undefined
            assertParamExists('contentNameLanguageGet', 'language', language)
            const localVarPath = `/Content/{name}/{language}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateContentModel} [medHelpPeopleWebApiModelsCreateContentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentPost: async (medHelpPeopleWebApiModelsCreateContentModel?: MedHelpPeopleWebApiModelsCreateContentModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpPeopleWebApiModelsCreateContentModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [page] 
         * @param {string} [type] 
         * @param {string} [language] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentV2Get: async (page?: string, type?: string, language?: string, region?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Content/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpPeopleWebApiModelsUpdateContentModel} [medHelpPeopleWebApiModelsUpdateContentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentIdPut(id: string, medHelpPeopleWebApiModelsUpdateContentModel?: MedHelpPeopleWebApiModelsUpdateContentModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentIdPut(id, medHelpPeopleWebApiModelsUpdateContentModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentNameLanguageGet(name: string, language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpPeopleDomainContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentNameLanguageGet(name, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateContentModel} [medHelpPeopleWebApiModelsCreateContentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentPost(medHelpPeopleWebApiModelsCreateContentModel?: MedHelpPeopleWebApiModelsCreateContentModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentPost(medHelpPeopleWebApiModelsCreateContentModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [page] 
         * @param {string} [type] 
         * @param {string} [language] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contentV2Get(page?: string, type?: string, language?: string, region?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpPeopleDomainContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contentV2Get(page, type, language, region, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentIdDelete(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.contentIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpPeopleWebApiModelsUpdateContentModel} [medHelpPeopleWebApiModelsUpdateContentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentIdPut(id: string, medHelpPeopleWebApiModelsUpdateContentModel?: MedHelpPeopleWebApiModelsUpdateContentModel, options?: any): AxiosPromise<string> {
            return localVarFp.contentIdPut(id, medHelpPeopleWebApiModelsUpdateContentModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentNameLanguageGet(name: string, language: string, options?: any): AxiosPromise<MedHelpPeopleDomainContent> {
            return localVarFp.contentNameLanguageGet(name, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateContentModel} [medHelpPeopleWebApiModelsCreateContentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentPost(medHelpPeopleWebApiModelsCreateContentModel?: MedHelpPeopleWebApiModelsCreateContentModel, options?: any): AxiosPromise<string> {
            return localVarFp.contentPost(medHelpPeopleWebApiModelsCreateContentModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [page] 
         * @param {string} [type] 
         * @param {string} [language] 
         * @param {string} [region] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contentV2Get(page?: string, type?: string, language?: string, region?: string, options?: any): AxiosPromise<MedHelpPeopleDomainContent> {
            return localVarFp.contentV2Get(page, type, language, region, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentIdDelete(id: string, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MedHelpPeopleWebApiModelsUpdateContentModel} [medHelpPeopleWebApiModelsUpdateContentModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentIdPut(id: string, medHelpPeopleWebApiModelsUpdateContentModel?: MedHelpPeopleWebApiModelsUpdateContentModel, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentIdPut(id, medHelpPeopleWebApiModelsUpdateContentModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentNameLanguageGet(name: string, language: string, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentNameLanguageGet(name, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpPeopleWebApiModelsCreateContentModel} [medHelpPeopleWebApiModelsCreateContentModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentPost(medHelpPeopleWebApiModelsCreateContentModel?: MedHelpPeopleWebApiModelsCreateContentModel, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentPost(medHelpPeopleWebApiModelsCreateContentModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [page] 
     * @param {string} [type] 
     * @param {string} [language] 
     * @param {string} [region] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public contentV2Get(page?: string, type?: string, language?: string, region?: string, options?: AxiosRequestConfig) {
        return ContentApiFp(this.configuration).contentV2Get(page, type, language, region, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmploymentsApi - axios parameter creator
 * @export
 */
export const EmploymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employmentsGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Employments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateEmploymentModel} [medHelpPeopleWebApiModelsCreateEmploymentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employmentsPost: async (medHelpPeopleWebApiModelsCreateEmploymentModel?: MedHelpPeopleWebApiModelsCreateEmploymentModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Employments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpPeopleWebApiModelsCreateEmploymentModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmploymentsApi - functional programming interface
 * @export
 */
export const EmploymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmploymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employmentsGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employmentsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateEmploymentModel} [medHelpPeopleWebApiModelsCreateEmploymentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employmentsPost(medHelpPeopleWebApiModelsCreateEmploymentModel?: MedHelpPeopleWebApiModelsCreateEmploymentModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employmentsPost(medHelpPeopleWebApiModelsCreateEmploymentModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmploymentsApi - factory interface
 * @export
 */
export const EmploymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmploymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employmentsGet(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.employmentsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateEmploymentModel} [medHelpPeopleWebApiModelsCreateEmploymentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employmentsPost(medHelpPeopleWebApiModelsCreateEmploymentModel?: MedHelpPeopleWebApiModelsCreateEmploymentModel, options?: any): AxiosPromise<void> {
            return localVarFp.employmentsPost(medHelpPeopleWebApiModelsCreateEmploymentModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmploymentsApi - object-oriented interface
 * @export
 * @class EmploymentsApi
 * @extends {BaseAPI}
 */
export class EmploymentsApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentsApi
     */
    public employmentsGet(id?: string, options?: AxiosRequestConfig) {
        return EmploymentsApiFp(this.configuration).employmentsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpPeopleWebApiModelsCreateEmploymentModel} [medHelpPeopleWebApiModelsCreateEmploymentModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentsApi
     */
    public employmentsPost(medHelpPeopleWebApiModelsCreateEmploymentModel?: MedHelpPeopleWebApiModelsCreateEmploymentModel, options?: AxiosRequestConfig) {
        return EmploymentsApiFp(this.configuration).employmentsPost(medHelpPeopleWebApiModelsCreateEmploymentModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocalSearchApi - axios parameter creator
 * @export
 */
export const LocalSearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localSearchGet: async (type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/LocalSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [employmentId] 
         * @param {string} [type] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localSearchPost: async (employmentId?: string, type?: string, body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/LocalSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalSearchApi - functional programming interface
 * @export
 */
export const LocalSearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalSearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localSearchGet(type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpPeopleDomainLocalSearch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localSearchGet(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [employmentId] 
         * @param {string} [type] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async localSearchPost(employmentId?: string, type?: string, body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MicrosoftAspNetCoreMvcOkResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.localSearchPost(employmentId, type, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocalSearchApi - factory interface
 * @export
 */
export const LocalSearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalSearchApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localSearchGet(type?: string, options?: any): AxiosPromise<Array<MedHelpPeopleDomainLocalSearch>> {
            return localVarFp.localSearchGet(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [employmentId] 
         * @param {string} [type] 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        localSearchPost(employmentId?: string, type?: string, body?: object, options?: any): AxiosPromise<MicrosoftAspNetCoreMvcOkResult> {
            return localVarFp.localSearchPost(employmentId, type, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalSearchApi - object-oriented interface
 * @export
 * @class LocalSearchApi
 * @extends {BaseAPI}
 */
export class LocalSearchApi extends BaseAPI {
    /**
     * 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalSearchApi
     */
    public localSearchGet(type?: string, options?: AxiosRequestConfig) {
        return LocalSearchApiFp(this.configuration).localSearchGet(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [employmentId] 
     * @param {string} [type] 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalSearchApi
     */
    public localSearchPost(employmentId?: string, type?: string, body?: object, options?: AxiosRequestConfig) {
        return LocalSearchApiFp(this.configuration).localSearchPost(employmentId, type, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsIdDelete', 'id', id)
            const localVarPath = `/News/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdPublishPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsIdPublishPost', 'id', id)
            const localVarPath = `/News/{id}/publish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpPeopleWebApiModelsUpdateNewsModel} [medHelpPeopleWebApiModelsUpdateNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdPut: async (id: string, medHelpPeopleWebApiModelsUpdateNewsModel?: MedHelpPeopleWebApiModelsUpdateNewsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsIdPut', 'id', id)
            const localVarPath = `/News/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpPeopleWebApiModelsUpdateNewsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdUnpublishPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newsIdUnpublishPost', 'id', id)
            const localVarPath = `/News/{id}/unpublish`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsListCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('newsListCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/News/list/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateNewsModel} [medHelpPeopleWebApiModelsCreateNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPost: async (medHelpPeopleWebApiModelsCreateNewsModel?: MedHelpPeopleWebApiModelsCreateNewsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/News`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpPeopleWebApiModelsCreateNewsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIdPublishPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIdPublishPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpPeopleWebApiModelsUpdateNewsModel} [medHelpPeopleWebApiModelsUpdateNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIdPut(id: string, medHelpPeopleWebApiModelsUpdateNewsModel?: MedHelpPeopleWebApiModelsUpdateNewsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIdPut(id, medHelpPeopleWebApiModelsUpdateNewsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIdUnpublishPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIdUnpublishPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsListCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpPeopleDomainNews>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsListCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateNewsModel} [medHelpPeopleWebApiModelsCreateNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsPost(medHelpPeopleWebApiModelsCreateNewsModel?: MedHelpPeopleWebApiModelsCreateNewsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsPost(medHelpPeopleWebApiModelsCreateNewsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdDelete(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.newsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdPublishPost(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.newsIdPublishPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {MedHelpPeopleWebApiModelsUpdateNewsModel} [medHelpPeopleWebApiModelsUpdateNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdPut(id: string, medHelpPeopleWebApiModelsUpdateNewsModel?: MedHelpPeopleWebApiModelsUpdateNewsModel, options?: any): AxiosPromise<string> {
            return localVarFp.newsIdPut(id, medHelpPeopleWebApiModelsUpdateNewsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIdUnpublishPost(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.newsIdUnpublishPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsListCompanyIdGet(companyId: number, options?: any): AxiosPromise<Array<MedHelpPeopleDomainNews>> {
            return localVarFp.newsListCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateNewsModel} [medHelpPeopleWebApiModelsCreateNewsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsPost(medHelpPeopleWebApiModelsCreateNewsModel?: MedHelpPeopleWebApiModelsCreateNewsModel, options?: any): AxiosPromise<string> {
            return localVarFp.newsPost(medHelpPeopleWebApiModelsCreateNewsModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIdDelete(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIdPublishPost(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIdPublishPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {MedHelpPeopleWebApiModelsUpdateNewsModel} [medHelpPeopleWebApiModelsUpdateNewsModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIdPut(id: string, medHelpPeopleWebApiModelsUpdateNewsModel?: MedHelpPeopleWebApiModelsUpdateNewsModel, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIdPut(id, medHelpPeopleWebApiModelsUpdateNewsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIdUnpublishPost(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIdUnpublishPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsListCompanyIdGet(companyId: number, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsListCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpPeopleWebApiModelsCreateNewsModel} [medHelpPeopleWebApiModelsCreateNewsModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsPost(medHelpPeopleWebApiModelsCreateNewsModel?: MedHelpPeopleWebApiModelsCreateNewsModel, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsPost(medHelpPeopleWebApiModelsCreateNewsModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet(options?: any): AxiosPromise<void> {
            return localVarFp.pingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingGet(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).pingGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnitsApi - axios parameter creator
 * @export
 */
export const UnitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitsDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateUnitModel} [medHelpPeopleWebApiModelsCreateUnitModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitsPost: async (medHelpPeopleWebApiModelsCreateUnitModel?: MedHelpPeopleWebApiModelsCreateUnitModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Units`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpPeopleWebApiModelsCreateUnitModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnitsApi - functional programming interface
 * @export
 */
export const UnitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitsDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitsDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateUnitModel} [medHelpPeopleWebApiModelsCreateUnitModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unitsPost(medHelpPeopleWebApiModelsCreateUnitModel?: MedHelpPeopleWebApiModelsCreateUnitModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unitsPost(medHelpPeopleWebApiModelsCreateUnitModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnitsApi - factory interface
 * @export
 */
export const UnitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnitsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitsDelete(id?: string, options?: any): AxiosPromise<string> {
            return localVarFp.unitsDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpPeopleWebApiModelsCreateUnitModel} [medHelpPeopleWebApiModelsCreateUnitModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unitsPost(medHelpPeopleWebApiModelsCreateUnitModel?: MedHelpPeopleWebApiModelsCreateUnitModel, options?: any): AxiosPromise<string> {
            return localVarFp.unitsPost(medHelpPeopleWebApiModelsCreateUnitModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnitsApi - object-oriented interface
 * @export
 * @class UnitsApi
 * @extends {BaseAPI}
 */
export class UnitsApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public unitsDelete(id?: string, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).unitsDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpPeopleWebApiModelsCreateUnitModel} [medHelpPeopleWebApiModelsCreateUnitModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnitsApi
     */
    public unitsPost(medHelpPeopleWebApiModelsCreateUnitModel?: MedHelpPeopleWebApiModelsCreateUnitModel, options?: AxiosRequestConfig) {
        return UnitsApiFp(this.configuration).unitsPost(medHelpPeopleWebApiModelsCreateUnitModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WelcomeMessageApi - axios parameter creator
 * @export
 */
export const WelcomeMessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeMessageGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/WelcomeMessage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WelcomeMessageApi - functional programming interface
 * @export
 */
export const WelcomeMessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WelcomeMessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async welcomeMessageGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpPeopleDomainWelcomeMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.welcomeMessageGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WelcomeMessageApi - factory interface
 * @export
 */
export const WelcomeMessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WelcomeMessageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        welcomeMessageGet(options?: any): AxiosPromise<MedHelpPeopleDomainWelcomeMessage> {
            return localVarFp.welcomeMessageGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WelcomeMessageApi - object-oriented interface
 * @export
 * @class WelcomeMessageApi
 * @extends {BaseAPI}
 */
export class WelcomeMessageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WelcomeMessageApi
     */
    public welcomeMessageGet(options?: AxiosRequestConfig) {
        return WelcomeMessageApiFp(this.configuration).welcomeMessageGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WidgetApi - axios parameter creator
 * @export
 */
export const WidgetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetListGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Widget/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<MedHelpPeopleDomainWidget>} [medHelpPeopleDomainWidget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetPut: async (medHelpPeopleDomainWidget?: Array<MedHelpPeopleDomainWidget>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Widget`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpPeopleDomainWidget, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WidgetApi - functional programming interface
 * @export
 */
export const WidgetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WidgetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetListGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpPeopleDomainWidget>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetListGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<MedHelpPeopleDomainWidget>} [medHelpPeopleDomainWidget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async widgetPut(medHelpPeopleDomainWidget?: Array<MedHelpPeopleDomainWidget>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpPeopleDomainWidget>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.widgetPut(medHelpPeopleDomainWidget, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WidgetApi - factory interface
 * @export
 */
export const WidgetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WidgetApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetListGet(options?: any): AxiosPromise<Array<MedHelpPeopleDomainWidget>> {
            return localVarFp.widgetListGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<MedHelpPeopleDomainWidget>} [medHelpPeopleDomainWidget] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        widgetPut(medHelpPeopleDomainWidget?: Array<MedHelpPeopleDomainWidget>, options?: any): AxiosPromise<Array<MedHelpPeopleDomainWidget>> {
            return localVarFp.widgetPut(medHelpPeopleDomainWidget, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WidgetApi - object-oriented interface
 * @export
 * @class WidgetApi
 * @extends {BaseAPI}
 */
export class WidgetApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetListGet(options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetListGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<MedHelpPeopleDomainWidget>} [medHelpPeopleDomainWidget] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WidgetApi
     */
    public widgetPut(medHelpPeopleDomainWidget?: Array<MedHelpPeopleDomainWidget>, options?: AxiosRequestConfig) {
        return WidgetApiFp(this.configuration).widgetPut(medHelpPeopleDomainWidget, options).then((request) => request(this.axios, this.basePath));
    }
}


