/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, {
    AxiosPromise,
    AxiosInstance,
    AxiosRequestConfig,
} from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from "./common";
// @ts-ignore
import {
    BASE_PATH,
    COLLECTION_FORMATS,
    RequestArgs,
    BaseAPI,
    RequiredError,
} from "./base";

/**
 *
 * @export
 * @enum {string}
 */

export enum AuthenticateServiceType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
}

/**
 *
 * @export
 * @enum {string}
 */

export enum HttpStatusCode {
    NUMBER_100 = 100,
    NUMBER_101 = 101,
    NUMBER_102 = 102,
    NUMBER_103 = 103,
    NUMBER_200 = 200,
    NUMBER_201 = 201,
    NUMBER_202 = 202,
    NUMBER_203 = 203,
    NUMBER_204 = 204,
    NUMBER_205 = 205,
    NUMBER_206 = 206,
    NUMBER_207 = 207,
    NUMBER_208 = 208,
    NUMBER_226 = 226,
    NUMBER_300 = 300,
    NUMBER_301 = 301,
    NUMBER_302 = 302,
    NUMBER_303 = 303,
    NUMBER_304 = 304,
    NUMBER_305 = 305,
    NUMBER_306 = 306,
    NUMBER_307 = 307,
    NUMBER_308 = 308,
    NUMBER_400 = 400,
    NUMBER_401 = 401,
    NUMBER_402 = 402,
    NUMBER_403 = 403,
    NUMBER_404 = 404,
    NUMBER_405 = 405,
    NUMBER_406 = 406,
    NUMBER_407 = 407,
    NUMBER_408 = 408,
    NUMBER_409 = 409,
    NUMBER_410 = 410,
    NUMBER_411 = 411,
    NUMBER_412 = 412,
    NUMBER_413 = 413,
    NUMBER_414 = 414,
    NUMBER_415 = 415,
    NUMBER_416 = 416,
    NUMBER_417 = 417,
    NUMBER_421 = 421,
    NUMBER_422 = 422,
    NUMBER_423 = 423,
    NUMBER_424 = 424,
    NUMBER_426 = 426,
    NUMBER_428 = 428,
    NUMBER_429 = 429,
    NUMBER_431 = 431,
    NUMBER_451 = 451,
    NUMBER_500 = 500,
    NUMBER_501 = 501,
    NUMBER_502 = 502,
    NUMBER_503 = 503,
    NUMBER_504 = 504,
    NUMBER_505 = 505,
    NUMBER_506 = 506,
    NUMBER_507 = 507,
    NUMBER_508 = 508,
    NUMBER_510 = 510,
    NUMBER_511 = 511,
}

/**
 *
 * @export
 * @interface InitLoginModel
 */
export interface InitLoginModel {
    /**
     *
     * @type {AuthenticateServiceType}
     * @memberof InitLoginModel
     */
    authenticateServiceType: AuthenticateServiceType;
    /**
     *
     * @type {string}
     * @memberof InitLoginModel
     */
    socialSecurityNumber?: string;
    /**
     *
     * @type {string}
     * @memberof InitLoginModel
     */
    returnUrl?: string;
    /**
     *
     * @type {boolean}
     * @memberof InitLoginModel
     */
    qrCode: boolean;
}
/**
 *
 * @export
 * @interface InitModel
 */
export interface InitModel {
    /**
     *
     * @type {string}
     * @memberof InitModel
     */
    identifier?: string;
    /**
     *
     * @type {string}
     * @memberof InitModel
     */
    returnUrl?: string;
}
/**
 *
 * @export
 * @interface InitResult
 */
export interface InitResult {
    /**
     *
     * @type {string}
     * @memberof InitResult
     */
    cacheIdentifier?: string;
    /**
     *
     * @type {boolean}
     * @memberof InitResult
     */
    success: boolean;
    /**
     *
     * @type {number}
     * @memberof InitResult
     */
    lockout: number;
}
/**
 *
 * @export
 * @interface InitResult2
 */
export interface InitResult2 {
    /**
     *
     * @type {string}
     * @memberof InitResult2
     */
    sessionGuid?: string;
    /**
     *
     * @type {string}
     * @memberof InitResult2
     */
    autoStartToken?: string;
    /**
     *
     * @type {boolean}
     * @memberof InitResult2
     */
    hasErrors: boolean;
    /**
     *
     * @type {string}
     * @memberof InitResult2
     */
    error?: string;
    /**
     *
     * @type {string}
     * @memberof InitResult2
     */
    qrCode?: string;
}
/**
 *
 * @export
 * @interface LoginModel
 */
export interface LoginModel {
    /**
     *
     * @type {AuthenticateServiceType}
     * @memberof LoginModel
     */
    authenticateServiceType: AuthenticateServiceType;
    /**
     *
     * @type {string}
     * @memberof LoginModel
     */
    returnUrl?: string;
}
/**
 *
 * @export
 * @interface LoginModel2
 */
export interface LoginModel2 {
    /**
     *
     * @type {string}
     * @memberof LoginModel2
     */
    sessionGuid?: string;
    /**
     *
     * @type {number}
     * @memberof LoginModel2
     */
    preferredRetailerId?: number;
}
/**
 *
 * @export
 * @interface LoginModel3
 */
export interface LoginModel3 {
    /**
     *
     * @type {string}
     * @memberof LoginModel3
     */
    cacheIdentifier?: string;
    /**
     *
     * @type {string}
     * @memberof LoginModel3
     */
    passCode?: string;
    /**
     *
     * @type {string}
     * @memberof LoginModel3
     */
    returnUrl?: string;
    /**
     *
     * @type {number}
     * @memberof LoginModel3
     */
    preferredRetailerId?: number;
}
/**
 *
 * @export
 * @interface LoginPasswordModel
 */
export interface LoginPasswordModel {
    /**
     *
     * @type {string}
     * @memberof LoginPasswordModel
     */
    username?: string;
    /**
     *
     * @type {string}
     * @memberof LoginPasswordModel
     */
    password?: string;
    /**
     *
     * @type {string}
     * @memberof LoginPasswordModel
     */
    returnUrl?: string;
    /**
     *
     * @type {number}
     * @memberof LoginPasswordModel
     */
    preferredRetailerId?: number;
    /**
     *
     * @type {number}
     * @memberof LoginPasswordModel
     */
    userAccountId?: number;
}
/**
 *
 * @export
 * @interface LoginTokenModel
 */
export interface LoginTokenModel {
    /**
     *
     * @type {string}
     * @memberof LoginTokenModel
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof LoginTokenModel
     */
    returnUrl?: string;
    /**
     *
     * @type {number}
     * @memberof LoginTokenModel
     */
    preferredRetailerId?: number;
}
/**
 *
 * @export
 * @interface LogoutModel
 */
export interface LogoutModel {
    /**
     *
     * @type {string}
     * @memberof LogoutModel
     */
    token?: string;
}
/**
 *
 * @export
 * @interface ResultBase
 */
export interface ResultBase {
    /**
     *
     * @type {boolean}
     * @memberof ResultBase
     */
    hasErrors: boolean;
    /**
     *
     * @type {string}
     * @memberof ResultBase
     */
    error?: string;
}
/**
 *
 * @export
 * @interface ReturnModel
 */
export interface ReturnModel {
    /**
     *
     * @type {HttpStatusCode}
     * @memberof ReturnModel
     */
    statusCode: HttpStatusCode;
    /**
     *
     * @type {string}
     * @memberof ReturnModel
     */
    returnUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ReturnModel
     */
    accessToken?: string;
    /**
     *
     * @type {boolean}
     * @memberof ReturnModel
     */
    allowCrossSessionToken: boolean;
    /**
     *
     * @type {string}
     * @memberof ReturnModel
     */
    identifier?: string;
    /**
     *
     * @type {boolean}
     * @memberof ReturnModel
     */
    loginFailed: boolean;
}
/**
 *
 * @export
 * @interface StatusResult
 */
export interface StatusResult {
    /**
     *
     * @type {boolean}
     * @memberof StatusResult
     */
    hasErrors: boolean;
    /**
     *
     * @type {string}
     * @memberof StatusResult
     */
    error?: string;
    /**
     *
     * @type {string}
     * @memberof StatusResult
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof StatusResult
     */
    hintCode?: string;
    /**
     *
     * @type {string}
     * @memberof StatusResult
     */
    sessionGuid?: string;
    /**
     *
     * @type {string}
     * @memberof StatusResult
     */
    qrCode?: string;
}
/**
 *
 * @export
 * @interface StatusResultAllOf
 */
export interface StatusResultAllOf {
    /**
     *
     * @type {string}
     * @memberof StatusResultAllOf
     */
    status?: string;
    /**
     *
     * @type {string}
     * @memberof StatusResultAllOf
     */
    hintCode?: string;
    /**
     *
     * @type {string}
     * @memberof StatusResultAllOf
     */
    sessionGuid?: string;
    /**
     *
     * @type {string}
     * @memberof StatusResultAllOf
     */
    qrCode?: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         *
         * @param {string} [sessionGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGetGrandIdNoGuiStatus: async (
            sessionGuid?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/Auth/GetGrandIdNoGuiStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionGuid !== undefined) {
                localVarQueryParameter["sessionGuid"] = sessionGuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [sessionGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGetGrandIdNoGuiStatusQR: async (
            sessionGuid?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/Auth/GetGrandIdNoGuiStatusQR`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionGuid !== undefined) {
                localVarQueryParameter["sessionGuid"] = sessionGuid;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInitGrandIdLogin: async (
            login: LoginModel,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists("authInitGrandIdLogin", "login", login);
            const localVarPath = `/Auth/InitGrandIdLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] =
                "application/json-patch+json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                login,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InitLoginModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInitGrandIdNoGuiLogin: async (
            login: InitLoginModel,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists("authInitGrandIdNoGuiLogin", "login", login);
            const localVarPath = `/Auth/InitGrandIdNoGuiLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] =
                "application/json-patch+json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                login,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InitModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInitOneTimePassLogin: async (
            login: InitModel,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists("authInitOneTimePassLogin", "login", login);
            const localVarPath = `/Auth/InitOneTimePassLogin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] =
                "application/json-patch+json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                login,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginPasswordModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin: async (
            login: LoginPasswordModel,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists("authLogin", "login", login);
            const localVarPath = `/Auth/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] =
                "application/json-patch+json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                login,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginTokenModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginWithGrandId: async (
            login: LoginTokenModel,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists("authLoginWithGrandId", "login", login);
            const localVarPath = `/Auth/LoginWithGrandId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] =
                "application/json-patch+json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                login,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginModel2} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginWithGrandIdNoGui: async (
            login: LoginModel2,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists("authLoginWithGrandIdNoGui", "login", login);
            const localVarPath = `/Auth/LoginWithGrandIdNoGui`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] =
                "application/json-patch+json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                login,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginTokenModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginWithMicrosoftToken: async (
            login: LoginTokenModel,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists("authLoginWithMicrosoftToken", "login", login);
            const localVarPath = `/Auth/LoginWithMicrosoftToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] =
                "application/json-patch+json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                login,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginModel3} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginWithOneTimePass: async (
            login: LoginModel3,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists("authLoginWithOneTimePass", "login", login);
            const localVarPath = `/Auth/LoginWithOneTimePass`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] =
                "application/json-patch+json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                login,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LogoutModel} model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout: async (
            model: LogoutModel,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists("authLogout", "model", model);
            const localVarPath = `/Auth/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "POST",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter["Content-Type"] =
                "application/json-patch+json";

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                model,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} [sessionGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authGetGrandIdNoGuiStatus(
            sessionGuid?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<StatusResult>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authGetGrandIdNoGuiStatus(
                    sessionGuid,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {string} [sessionGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authGetGrandIdNoGuiStatusQR(
            sessionGuid?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<StatusResult>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authGetGrandIdNoGuiStatusQR(
                    sessionGuid,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {LoginModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authInitGrandIdLogin(
            login: LoginModel,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<InitResult>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authInitGrandIdLogin(
                    login,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {InitLoginModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authInitGrandIdNoGuiLogin(
            login: InitLoginModel,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<InitResult2>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authInitGrandIdNoGuiLogin(
                    login,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {InitModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authInitOneTimePassLogin(
            login: InitModel,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<InitResult>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authInitOneTimePassLogin(
                    login,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {LoginPasswordModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogin(
            login: LoginPasswordModel,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ReturnModel>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLogin(
                login,
                options,
            );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {LoginTokenModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginWithGrandId(
            login: LoginTokenModel,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ReturnModel>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authLoginWithGrandId(
                    login,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {LoginModel2} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginWithGrandIdNoGui(
            login: LoginModel2,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ReturnModel>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authLoginWithGrandIdNoGui(
                    login,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {LoginTokenModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginWithMicrosoftToken(
            login: LoginTokenModel,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ReturnModel>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authLoginWithMicrosoftToken(
                    login,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {LoginModel3} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginWithOneTimePass(
            login: LoginModel3,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ReturnModel>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authLoginWithOneTimePass(
                    login,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {LogoutModel} model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLogout(
            model: LogoutModel,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ReturnModel>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.authLogout(model, options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = AuthApiFp(configuration);
    return {
        /**
         *
         * @param {string} [sessionGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGetGrandIdNoGuiStatus(
            sessionGuid?: string,
            options?: any,
        ): AxiosPromise<StatusResult> {
            return localVarFp
                .authGetGrandIdNoGuiStatus(sessionGuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [sessionGuid]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authGetGrandIdNoGuiStatusQR(
            sessionGuid?: string,
            options?: any,
        ): AxiosPromise<StatusResult> {
            return localVarFp
                .authGetGrandIdNoGuiStatusQR(sessionGuid, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LoginModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInitGrandIdLogin(
            login: LoginModel,
            options?: any,
        ): AxiosPromise<InitResult> {
            return localVarFp
                .authInitGrandIdLogin(login, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InitLoginModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInitGrandIdNoGuiLogin(
            login: InitLoginModel,
            options?: any,
        ): AxiosPromise<InitResult2> {
            return localVarFp
                .authInitGrandIdNoGuiLogin(login, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InitModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authInitOneTimePassLogin(
            login: InitModel,
            options?: any,
        ): AxiosPromise<InitResult> {
            return localVarFp
                .authInitOneTimePassLogin(login, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LoginPasswordModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogin(
            login: LoginPasswordModel,
            options?: any,
        ): AxiosPromise<ReturnModel> {
            return localVarFp
                .authLogin(login, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LoginTokenModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginWithGrandId(
            login: LoginTokenModel,
            options?: any,
        ): AxiosPromise<ReturnModel> {
            return localVarFp
                .authLoginWithGrandId(login, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LoginModel2} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginWithGrandIdNoGui(
            login: LoginModel2,
            options?: any,
        ): AxiosPromise<ReturnModel> {
            return localVarFp
                .authLoginWithGrandIdNoGui(login, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LoginTokenModel} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginWithMicrosoftToken(
            login: LoginTokenModel,
            options?: any,
        ): AxiosPromise<ReturnModel> {
            return localVarFp
                .authLoginWithMicrosoftToken(login, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LoginModel3} login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginWithOneTimePass(
            login: LoginModel3,
            options?: any,
        ): AxiosPromise<ReturnModel> {
            return localVarFp
                .authLoginWithOneTimePass(login, options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {LogoutModel} model
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLogout(
            model: LogoutModel,
            options?: any,
        ): AxiosPromise<ReturnModel> {
            return localVarFp
                .authLogout(model, options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     *
     * @param {string} [sessionGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authGetGrandIdNoGuiStatus(
        sessionGuid?: string,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authGetGrandIdNoGuiStatus(sessionGuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} [sessionGuid]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authGetGrandIdNoGuiStatusQR(
        sessionGuid?: string,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authGetGrandIdNoGuiStatusQR(sessionGuid, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LoginModel} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authInitGrandIdLogin(
        login: LoginModel,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authInitGrandIdLogin(login, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {InitLoginModel} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authInitGrandIdNoGuiLogin(
        login: InitLoginModel,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authInitGrandIdNoGuiLogin(login, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {InitModel} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authInitOneTimePassLogin(
        login: InitModel,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authInitOneTimePassLogin(login, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LoginPasswordModel} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogin(login: LoginPasswordModel, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration)
            .authLogin(login, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LoginTokenModel} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLoginWithGrandId(
        login: LoginTokenModel,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authLoginWithGrandId(login, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LoginModel2} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLoginWithGrandIdNoGui(
        login: LoginModel2,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authLoginWithGrandIdNoGui(login, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LoginTokenModel} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLoginWithMicrosoftToken(
        login: LoginTokenModel,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authLoginWithMicrosoftToken(login, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LoginModel3} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLoginWithOneTimePass(
        login: LoginModel3,
        options?: AxiosRequestConfig,
    ) {
        return AuthApiFp(this.configuration)
            .authLoginWithOneTimePass(login, options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {LogoutModel} model
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLogout(model: LogoutModel, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration)
            .authLogout(model, options)
            .then((request) => request(this.axios, this.basePath));
    }
}

/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGetStatusAll: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/Status/GetStatusAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusStatus: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: "GET",
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusGetStatusAll(
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.statusGetStatusAll(options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusStatus(
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.statusStatus(options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = StatusApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGetStatusAll(options?: any): AxiosPromise<any> {
            return localVarFp
                .statusGetStatusAll(options)
                .then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusStatus(options?: any): AxiosPromise<any> {
            return localVarFp
                .statusStatus(options)
                .then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public statusGetStatusAll(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration)
            .statusGetStatusAll(options)
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public statusStatus(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration)
            .statusStatus(options)
            .then((request) => request(this.axios, this.basePath));
    }
}
