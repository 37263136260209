import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { userActions } from "store/userSlice";
import { useAppSelector } from "store/hooks";
import MenuItem from "../MenuItem";
import { IMenuNavigation } from "interfaces/IMenuNavigation";
import { ReactComponent as MhLogo } from "icons/menu/mhlogo.svg";
import { ReactComponent as AvonovaLogo } from "icons/avonovaLogo.svg";
import { ReactComponent as FeelgoodLogo } from "icons/feelgoodLogo.svg";
import { ReactComponent as FalckLogo } from "icons/falckLogo.svg";
import { ReactComponent as MhLogoMobile } from "icons/menu/mhLogoMobile.svg";
import { ReactComponent as AvonovaLogoMobile } from "icons/menu/avonovaLogoMobile.svg";
import { ReactComponent as FeelgoodLogoMobile } from "icons/menu/feelgoodLogoMobile.svg";
import { ReactComponent as FalckLogoMobile } from "icons/menu/falckLogoMobile.svg";
import { ReactComponent as AbsenceReportIcon } from "icons/menu/absenceReport.svg";
import { ReactComponent as AdministrationIcon } from "icons/menu/administration.svg";
import { ReactComponent as CloseIcon } from "icons/menu/close.svg";
import { ReactComponent as ServiceIcon } from "icons/menu/service.svg";
import { ReactComponent as FollowUpIcon } from "icons/menu/followup.svg";
import { ReactComponent as AbsenceBoardIcon } from "icons/menu/absenceBoard.svg";
import { ReactComponent as StatisticsIcon } from "icons/menu/statistics.svg";
import { ReactComponent as SupportIcon } from "icons/menu/support.svg";
import { ReactComponent as ProfileIcon } from "icons/menu/profile.svg";
import { ReactComponent as LogoutIcon } from "icons/menu/logout.svg";
import { ReactComponent as DashboardIcon } from "icons/menu/dashboard.svg";
import { ReactComponent as AboutIcon } from "icons/menu/about.svg";
import { useTranslation } from "react-i18next";
import { setOverflow } from "utils/setOverflow";
import { Config } from "config";
import * as UserSelectors from "store/userSelectors";
import WelcomeMessage from "components/WelcomeMessage";
import Page from "components/Page";
import { getWelcomeMessage } from "store/userActions";
import { newDate } from "utils/date";
import "react-toastify/dist/ReactToastify.css";
import Toast from "components/Toast";
import { fetchUnreadCount } from "pages/Inbox/redux/inboxAsyncActions";
import { getInboxHasUnread } from "pages/Inbox/redux/inboxSelectors";
import Icon from "components/Icon";
import MenuNavBar from "./MenuNavBar";

type MenuProps = {
    children: ReactNode;
};

const Menu = ({ children }: MenuProps) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [sideMenuOpen, setSideMenuOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState<string>("");
    const [menuProps, setMenuProps] = useState<IMenuNavigation[]>([]);
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const welcomeMessage = useAppSelector(UserSelectors.getWelcomeMessage);
    const activeRetailer = useAppSelector(UserSelectors.getUserRetailer);
    const hasFortnoxProduct = useAppSelector(UserSelectors.getHasFortnoxProduct);

    const inboxHasUnread = useAppSelector(getInboxHasUnread);
    const { t } = useTranslation();
    const showWelcomeText = sessionStorage.getItem("hideWelcomeText") !== "true";

    useEffect(() => {
        dispatch(getWelcomeMessage());
        dispatch(fetchUnreadCount());

        const fetchUnReadCountOnInterval = setInterval(() => {
            dispatch(fetchUnreadCount());
        }, 300000);

        return () => {
            clearInterval(fetchUnReadCountOnInterval);
        };
    }, [dispatch]);

    useEffect(() => {
        setMenuProps([
            {
                Name: "Dashboard",
                Url: "/dashboard",
                Icon: <DashboardIcon />,
                Visible:
                    userContext.HasCustomerSupport ||
                    userContext.HasAbsenceView ||
                    userContext.HasAbsenceFollowup ||
                    userContext.HasTopLevelAbsenceStatistics,
                ReactWeb: true,
            },
            {
                Name: t("absenceReport"),
                Url: userContext.HasMedicalAdvisory ? "signedin/absencereporting/search" : "/absencereporting",
                Icon: <AbsenceReportIcon />,
                Visible:
                    (userContext.HasAbsenceReporting ||
                        userContext.HasAbsenceAdministration ||
                        userContext.HasCustomerSupport) &&
                    !hasFortnoxProduct,
                ReactWeb: true,
            },
            {
                Name: t("followUp"),
                Url: "/followup/search",
                Icon: <FollowUpIcon />,
                Visible: userContext.HasAbsenceFollowup || userContext.HasAbsenceView || userContext.HasCustomerSupport,
                ReactWeb: true,
            },
            {
                Name: t("absenceBoard"),
                Url: `${Config.web_notification_url}AbsenceBoard`,
                Icon: <AbsenceBoardIcon />,
                Visible: userContext.HasAbsenceBoard || userContext.HasCustomerSupport,
            },
            {
                Name: t("statistics"),
                Url: `${Config.web_healthPortal_url}Statistics`,
                Icon: <StatisticsIcon />,
                Visible: userContext.HasAbsenceStatistics || userContext.HasCustomerSupport,
            },
            {
                Name: t("administration"),
                // Url: "/administration/",
                Url: Config.web_customerSupport_url,
                Icon: <AdministrationIcon className="fill-none w-5" />,
                Visible:
                    (!hasFortnoxProduct &&
                        (userContext.HasCustomerCompanyAdministration || userContext.HasDepartmentAdministration)) ||
                    userContext.HasCustomerSupport,
            },
            {
                Name: t("administration"),
                Url: "/fortnoxAdministration",
                Icon: <AdministrationIcon className="fill-none w-5" />,
                Visible: hasFortnoxProduct && userContext.HasCustomerCompanyAdministration,
            },
            {
                Name: "Support",
                Url: "/support/helpGuide",
                ReactWeb: true,
                Icon: <SupportIcon className="fill-none w-5" />,
                Visible:
                    userContext.HasAbsenceView ||
                    userContext.HasAbsenceFollowup ||
                    userContext.HasAbsenceAdministration ||
                    userContext.HasCustomerSupport ||
                    userContext.HasCustomerCompanyAdministration ||
                    userContext.HasAbsenceBoard ||
                    userContext.HasRehabDepartmentManagement,
            },
            {
                Name: "Service",
                Url: "/service",
                Icon: <ServiceIcon />,
                Visible: userContext.HasCustomerSupport,
                ReactWeb: true,
            },
            {
                Name: t("aboutOurService"),
                Url: "/about/privacyPolicy",
                Icon: <AboutIcon />,
                Visible: true,
                ReactWeb: true,
            },
        ]);
    }, [userContext, t, hasFortnoxProduct]);

    useEffect(() => {
        const pathName = location.pathname;
        if (pathName?.includes("/dashboard")) {
            setCurrentPage("Dashboard");
        } else if (pathName?.includes("/absencereporting")) {
            setCurrentPage(t("absenceReport"));
        } else if (pathName?.includes("/service")) {
            setCurrentPage("Service");
        } else if (pathName?.includes("/followup")) {
            setCurrentPage(t("followUp"));
        } else if (pathName?.includes("/about")) {
            setCurrentPage(t("aboutOurService"));
        } else if (pathName?.includes("/support")) {
            setCurrentPage(t("support"));
        } else if (pathName?.includes("/userSettings")) {
            setCurrentPage(t("userSettings"));
        } else if (pathName?.includes("/workorder")) {
            setCurrentPage(t("workOrder"));
        } else if (
            pathName?.includes("/administration") ||
            pathName?.includes("/fileimport") ||
            pathName?.includes("/fortnoxAdministration")
        ) {
            setCurrentPage(t("administration"));
        } else if (pathName?.includes("/inbox")) {
            setCurrentPage(t("inbox"));
        }
    }, [location, t]);

    useEffect(() => {
        setOverflow(sideMenuOpen ? "hidden" : "auto");
    }, [sideMenuOpen]);

    const SetLogo = useMemo(() => {
        const logoMap = {
            Avonova: <AvonovaLogo className="inline" />,
            Feelgood: <FeelgoodLogo className="inline" />,
            Falck: <FalckLogo className="inline w-36" />,
            Medhelp: <MhLogo />,
        };
        return logoMap[activeRetailer] || <MhLogo />;
    }, [activeRetailer]);

    const SetLogoMobile = useMemo(() => {
        const logoMap = {
            Avonova: <AvonovaLogoMobile className="inline" />,
            Feelgood: <FeelgoodLogoMobile className="inline" />,
            Falck: <FalckLogoMobile className="inline" />,
            Medhelp: <MhLogoMobile />,
        };
        return logoMap[activeRetailer] || <MhLogoMobile />;
    }, [activeRetailer]);

    return (
        <Page>
            <div
                className={`absolute h-full w-full bg-mhgrey-dark bg-opacity-50 ${
                    sideMenuOpen ? "flex z-10" : "hidden"
                }`}
            />
            <div className="flex flex-col xl:flex-row">
                <div className="min-h-screen flex flex-col w-full flex-1 relative xl:ml-65">
                    <div className="flex flex-row bg-menuNavBg">
                        <div className="w-full self-center xl:self-start pl-5 xl:pl-0 xl:hidden">
                            <div className="flex justify-between items-center xs:pr-4 w-full py-5">
                                <div className="flex items-center">
                                    <button
                                        type="button"
                                        data-testid="mobile-hamburger-icon"
                                        className="rounded-full border h-full xl:hidden bg-menu border-menuBorder text-menuNavColorPrimary"
                                        onClick={() => {
                                            setSideMenuOpen(!sideMenuOpen);
                                        }}
                                    >
                                        <Icon icon="hamburgerIcon" size={52} />
                                    </button>
                                    <div className="h-12 flex justify-between items-center pl-4 text-menuText">
                                        {SetLogoMobile}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MenuNavBar />
                    </div>

                    <div className="flex justify-center">
                        <div className="w-full max-w-screen-xxl xl:py-13 xl:px-14 ">
                            <Toast />
                            {showWelcomeText && welcomeMessage?.value && (
                                <WelcomeMessage
                                    id="welcomeMessage"
                                    message={welcomeMessage.value}
                                    buttonText={t("okClose")}
                                />
                            )}
                            {children}
                        </div>
                    </div>
                </div>
                <div
                    className={`fixed w-79 xs:w-65 flex-col bg-menu text-menuText h-full text-sm xl:flex z-10 overflow-auto ${
                        sideMenuOpen ? "flex" : "hidden"
                    }`}
                >
                    <div className="bg-menu h-screen text-sm!">
                        <div className="h-full grid-flow-row grid">
                            <div className="h-20 pt-1 flex justify-between items-center pl-4 text-menuText">
                                <div>{SetLogo}</div>
                                {sideMenuOpen && (
                                    <button
                                        type="button"
                                        className="mr-4 hover:text-primaryButtonSecondary"
                                        onClick={() => {
                                            setSideMenuOpen(!sideMenuOpen);
                                        }}
                                    >
                                        <CloseIcon />
                                    </button>
                                )}
                            </div>
                            <div className="flex flex-col menu-items-height justify-between">
                                <div>
                                    {menuProps.map((item: IMenuNavigation) => {
                                        if (item.Visible) {
                                            return (
                                                <div
                                                    key={item.Name}
                                                    data-testid={item.Name}
                                                    onClick={() => setSideMenuOpen(false)}
                                                >
                                                    <MenuItem
                                                        current={item.Name === currentPage}
                                                        name={item.Name}
                                                        url={item.Url}
                                                        icon={item.Icon}
                                                        reactWeb={item.ReactWeb}
                                                    />
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                                <div>
                                    <div
                                        onClick={() => setSideMenuOpen(false)}
                                        className="py-3 border-t border-menuBorder"
                                    >
                                        <MenuItem
                                            current={t("inbox") === currentPage}
                                            name={t("inbox")}
                                            url="/inbox"
                                            icon={
                                                <Icon
                                                    icon={inboxHasUnread ? "notificationUnread" : "notificationRead"}
                                                    size={24}
                                                />
                                            }
                                        />
                                        <div onClick={() => setSideMenuOpen(false)}>
                                            <MenuItem
                                                name={userContext?.user?.name!}
                                                company={userContext?.companyEmployment?.primaryCompany?.name!}
                                                url={"/userSettings"}
                                                // url={Config.web_myProfile_url}
                                                icon={<ProfileIcon />}
                                                current={currentPage === t("userSettings")}
                                                reactWeb
                                            />
                                        </div>
                                        <div className="h-12 flex items-center hover:text-menuTextHover text-menuText">
                                            <button
                                                type="button"
                                                className="w-full h-full pl-6 focus:shadow-none"
                                                onClick={() => dispatch(userActions.signOut())}
                                            >
                                                <div className="w-full flex flex-row ">
                                                    <div className="w-6 flex justify-center">
                                                        <LogoutIcon />
                                                    </div>
                                                    <p
                                                        className="pl-4 text-sm font-bold hover:border-menuTextHover"
                                                        data-testid="log-out-button"
                                                    >
                                                        {t("logOut")}
                                                    </p>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="border-t border-menuBorder py-6 pl-6">
                                        <p className="text-xs text-menuTextLight">
                                            {`© ${newDate().getFullYear()} MedHelp Care AB`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Page>
    );
};
export default Menu;
