import Button from "./Button";
import { useState } from "react";
import Icon from "components/Icon";
import Modal from "components/Modal";
import { MiniModal } from "@medhelp/ui/Modals";
import { useTranslation } from "react-i18next";
import { FileImportConfiguration } from "../../../store/rtk-apis/fileImport/fileImportApi";
import { usePostConfigurationAutoimportMutation } from "store/rtk-apis/fileImport/fileImportApiEnhanced";

const FileImportPanel = ({ customerName, customerKey, autoImportEnabled }: FileImportConfiguration) => {
    const { t } = useTranslation("fileImport");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [postAutoImport] = usePostConfigurationAutoimportMutation();

    const handleClick = () => {
        postAutoImport({ customerKey, autoImport: !autoImportEnabled });
        setIsModalOpen(false);
    };

    return (
        <div className="flex flex-col py-7 md:py-16">
            <div className="flex flex-col gap-16">
                <div className="flex flex-col gap-4 md:flex-row md:gap-8">
                    <div>
                        <div className="text-xs font-bold">{t("customer")}:</div>
                        <div>{customerName}</div>
                    </div>
                    <div>
                        <div className="flex items-center gap-1.5">
                            <div className="text-xs font-bold">{t("autoImport")}</div>
                            <MiniModal heading={t("autoImport")} content={t("autoImportText")} />
                        </div>
                        <button
                            data-testid="auto-import-switch"
                            className="flex justify-center items-center focus:shadow-none"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <Icon
                                className={`${autoImportEnabled ? "text-toggled" : "text-untoggled"}`}
                                width={52}
                                height={24}
                                icon={autoImportEnabled ? "switchToggled" : "switchUntoggled"}
                            />
                        </button>
                    </div>
                </div>
            </div>

            <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} className="rounded md:max-w-[528px] mx-4">
                <div className="p-8">
                    <div className="flex justify-between">
                        <p className="font-bold">
                            {autoImportEnabled ? t("autoImportModal.turnOff.title") : t("autoImportModal.turnOn.title")}
                        </p>

                        <button onClick={() => setIsModalOpen(false)}>
                            <Icon icon="close" />
                        </button>
                    </div>
                    <div className="py-8">
                        {autoImportEnabled ? t("autoImportModal.turnOff.text") : t("autoImportModal.turnOn.text")}
                    </div>
                    <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-6 pb-4">
                        <Button text={t("cancel")} onClick={() => setIsModalOpen(false)} preset="secondary" />
                        <Button text={autoImportEnabled ? t("turnOff") : t("turnOn")} onClick={() => handleClick()} />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default FileImportPanel;
