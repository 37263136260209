import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { HealthCaseType } from "pages/FollowUp/types";
import { getHasOngoingCaseByType } from "pages/FollowUp/redux/followUpSelectors";
import ManualInitiationRadioButton from "./ManualInitiationRadioButton";

interface ManualInitiationTypeSelector {
    selectedCaseType: HealthCaseType | undefined;
    setSelectedCaseType: (value: HealthCaseType) => void;
}

const ManualInitiationTypeSelector = ({ selectedCaseType, setSelectedCaseType }: ManualInitiationTypeSelector) => {
    const { t } = useTranslation("followup");
    const hasOngoingRehabCase = useAppSelector(getHasOngoingCaseByType("rehab"));
    const hasOngoingHealthPromotionCase = useAppSelector(getHasOngoingCaseByType("healthpromotion"));

    const handleChange = (type: HealthCaseType) => setSelectedCaseType(type);

    return (
        <div className="flex flex-col gap-8 py-8 border-b">
            <p className="font-bold">{t("selectCaseType")}</p>

            <ManualInitiationRadioButton
                checked={selectedCaseType === "healthpromotion"}
                value="healthpromotion"
                onChange={() => handleChange("healthpromotion")}
                disabled={hasOngoingHealthPromotionCase}
                heading={t("healthpromotion")}
                text={t("chooseHealthPromotion")}
            />

            <ManualInitiationRadioButton
                checked={selectedCaseType === "rehab"}
                value="rehab"
                onChange={() => handleChange("rehab")}
                disabled={hasOngoingRehabCase}
                heading={t("rehabCase")}
                text={t("chooseRehab")}
            />
        </div>
    );
};

export default ManualInitiationTypeSelector;
