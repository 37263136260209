import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    fetchEventMessages,
    fetchUnreadCount,
    updateMessageRead,
    updateEventMessagesReadAll,
    updateSubscriptionMessagesReadAll,
    fetchSubscriptionMessages,
} from "./inboxAsyncActions";
import { IInboxState, ISetExpanded, ISetPageNumber, IUpdateMessageRead, Status } from "../types";
import { newDateString } from "utils/date";

export const initialInboxState: IInboxState = {
    eventMessages: {
        messages: null,
        unreadCount: 0,
        pageNumber: 1,
        status: Status.PENDING,
    },
    subscriptionMessages: {
        messages: null,
        unreadCount: 0,
        pageNumber: 1,
        status: Status.PENDING,
    },
};

const inboxSlice = createSlice({
    name: "inbox",
    initialState: initialInboxState,
    reducers: {
        setMessagesPageNumber: (state, action: PayloadAction<ISetPageNumber>) => {
            state[action.payload.type].pageNumber = action.payload.pageNumber;
        },
        setExpanded: (state, action: PayloadAction<ISetExpanded>) => {
            const message = state[action.payload.type].messages?.inbox
                ?.flatMap((page) => page?.inbox)
                ?.find((message) => message?.id === action.payload.id);
            if (message) {
                message.expanded = !message.expanded;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEventMessages.fulfilled, (state, action) => {
                if (action.payload) {
                    if (state.eventMessages.messages) {
                        state.eventMessages.messages.inbox = state.eventMessages.messages.inbox?.concat(
                            action.payload.inbox,
                        );
                    } else {
                        state.eventMessages.messages = action.payload;
                    }
                }
                state.eventMessages.status = Status.OK;
            })
            .addCase(fetchEventMessages.pending, (state) => {
                state.eventMessages.status = Status.PENDING;
            })
            .addCase(fetchEventMessages.rejected, (state) => {
                state.eventMessages.status = Status.ERROR;
            })
            .addCase(fetchSubscriptionMessages.fulfilled, (state, action) => {
                if (action.payload) {
                    if (state.subscriptionMessages.messages) {
                        state.subscriptionMessages.messages.inbox = state.subscriptionMessages.messages.inbox?.concat(
                            action.payload.inbox,
                        );
                    } else {
                        state.subscriptionMessages.messages = action.payload;
                    }
                }
                state.subscriptionMessages.status = Status.OK;
            })
            .addCase(fetchSubscriptionMessages.pending, (state) => {
                state.subscriptionMessages.status = Status.PENDING;
            })
            .addCase(fetchSubscriptionMessages.rejected, (state) => {
                state.subscriptionMessages.status = Status.ERROR;
            })
            .addCase(fetchUnreadCount.fulfilled, (state, action) => {
                if (action.payload) {
                    state.eventMessages.unreadCount = action.payload.eventMessagesCount ?? 0;
                    state.subscriptionMessages.unreadCount = action.payload.subscriptionMessagesCount ?? 0;
                }
            })
            .addCase(fetchUnreadCount.rejected, () => {
                console.error("Failed to fetch unread count");
            })
            .addCase(updateMessageRead.fulfilled, (state, action: PayloadAction<IUpdateMessageRead>) => {
                const message = state[action.payload.type].messages?.inbox
                    ?.flatMap((page) => page.inbox)
                    .find((message) => message?.id === action.payload.id);
                if (message) {
                    message.readAt = newDateString();
                }
                const unreadCount =
                    state[action.payload.type].unreadCount < 1 ? 0 : state[action.payload.type].unreadCount - 1;
                state[action.payload.type].unreadCount = unreadCount;
            })
            .addCase(updateMessageRead.rejected, () => {
                console.error("Failed to update message read");
            })
            .addCase(updateEventMessagesReadAll.fulfilled, (state) => {
                state.eventMessages.messages?.inbox?.forEach((page) =>
                    page?.inbox?.forEach((message) => (message.readAt = newDateString())),
                );
                state.eventMessages.unreadCount = 0;
            })
            .addCase(updateEventMessagesReadAll.rejected, () => {
                console.error("Failed to update messages read all");
            })
            .addCase(updateSubscriptionMessagesReadAll.fulfilled, (state) => {
                state.subscriptionMessages.messages?.inbox?.forEach((page) =>
                    page?.inbox?.forEach((message) => (message.readAt = newDateString())),
                );
                state.subscriptionMessages.unreadCount = 0;
            })
            .addCase(updateSubscriptionMessagesReadAll.rejected, () => {
                console.error("Failed to update messages read all");
            });
    },
});

export const inboxActions = inboxSlice.actions;

export default inboxSlice.reducer;
