import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { downloadFileWithTemplate as downloadFileWithTemplateAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const downloadFileWithTemplate = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(downloadFileWithTemplateAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                if (action.payload.templateDocumentUrl && action.payload.annotation) {
                    currentHealthCase.pdfUrl = action.payload.templateDocumentUrl;
                    currentHealthCase.annotation = action.payload.annotation;
                    currentHealthCase.healthCaseSubStatus.pdfDocumentTemplate = Status.OK;
                } else {
                    currentHealthCase.healthCaseSubStatus.pdfDocumentTemplate = Status.ERROR;
                }
            }
        })
        .addCase(downloadFileWithTemplateAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.pdfDocumentTemplate = Status.PENDING;
        })
        .addCase(downloadFileWithTemplateAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase) currentHealthCase.healthCaseSubStatus.pdfDocumentTemplate = Status.ERROR;
        });

export default downloadFileWithTemplate;
