import { sortBy } from "lodash";
import { IEmployeeDetails } from "./dashboardTypes";

export const getFilteredEmployees = (employeeDetails: IEmployeeDetails[]) => {
    return employeeDetails.filter(
        (employee, index, employeeDetails) =>
            index ===
            employeeDetails.findIndex(
                ({ employmentId, processType }) =>
                    employmentId === employee.employmentId && processType === employee.processType,
            ),
    );
};

export const getEmployeeAbsenceCount = (employeeDetails: IEmployeeDetails[], currentEmployee: IEmployeeDetails) => {
    const currentEmployeeList = employeeDetails.filter(
        ({ employmentId, processType }) =>
            employmentId === currentEmployee.employmentId && processType === currentEmployee.processType,
    );
    return currentEmployeeList.length;
};

export const getfilteredAndOrderedEmployees = (employeeDetails: IEmployeeDetails[]) => {
    const filteredEmployees = getFilteredEmployees(employeeDetails);
    const filteredAndMappedEmployees = filteredEmployees.map((employee) => ({
        employmentId: employee.employmentId || "",
        employeeName: employee.employeeName,
        processType: employee.processType || "",
        hasFollowUp: employee.hasFollowUp || false,
        count: getEmployeeAbsenceCount(employeeDetails, employee),
        initationDate: employee.initationDate || "",
        daysInRehabChain: employee.daysInRehabChain || 0,
        absences: employee.absences || 0,
    }));
    return sortBy(filteredAndMappedEmployees, "employeeName");
};
