import { IFilterProps } from "pages/FollowUp/types";
import { useMemo } from "react";
import SearchCheckboxFilter from "./SearchCheckboxFilter";

export default function Index({ items, onChange, header, tags }: IFilterProps) {
    const Items = useMemo(
        () =>
            items.map(({ label, referenceKey, checked }) => ({
                label,
                onChange,
                checked,
                name: referenceKey,
            })),
        [items, onChange],
    );
    return <SearchCheckboxFilter items={Items} header={header} body={tags} />;
}
