import { useState } from "react";
import { ModalFrame } from "@medhelp/ui";
import EmployeeSummaryTable from "../Tables/EmployeeSummaryTable";
import { IEmployeeDetails } from "pages/dashboard/utils/dashboardTypes";

interface INumberChart {
    modalHeading: string;
    id: string;
    data: string;
    employeeDetails: IEmployeeDetails[];
    widgetDescription: string;
}

const NumberChart = ({ modalHeading, data, employeeDetails, widgetDescription }: INumberChart) => {
    const [openModal, setOpenModal] = useState(false);

    const [heading, setHeading] = useState("");

    const showData = () => {
        setHeading(modalHeading);
        setOpenModal(true);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    return (
        <div className="flex flex-col h-full items-center">
            {data === "0" ? (
                <div>
                    <p className="font-bold text-8xl xs:font-size-120 text-tertiaryText">{data}</p>
                </div>
            ) : (
                <button type="button" onClick={() => showData()} className="hover:opacity-75">
                    <p className="font-bold text-8xl xs:font-size-120 h-32 text-tertiaryText">{data}</p>
                </button>
            )}
            <p className="text-center px-8 mt-2 leading-5">{widgetDescription}</p>
            <ModalFrame
                id="widgetEmployeeModal"
                open={openModal}
                setOpen={closeModal}
                header={<h3>{heading}</h3>}
                preset="full"
                content={
                    <EmployeeSummaryTable keyName={"ongoing"} filter={employeeDetails.map((x) => x.employmentId)} />
                }
            />
        </div>
    );
};

export default NumberChart;
