import { useEffect } from "react";
import ErrorWidget from "../ErrorWidget";
import { useTranslation } from "react-i18next";
import { getUserContext } from "store/userSelectors";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { ListChart } from "pages/dashboard/components/Charts";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import LastReportedAbsenceWidgetLoader from "./LastReportedAbsenceWidgetLoader";
import { getLastReportedAbsence, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const LastReportedAbsenceWidget = ({ id, type }: Widget) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("dashboard");
    const userContext = useAppSelector(getUserContext);
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getLastReportedAbsence(id));

    useEffect(() => {
        !isDragging && dispatch(fetchWidgetData({ id, type }));
    }, [dispatch, id, type, isDragging]);

    if (status === Status.PENDING) {
        return <LastReportedAbsenceWidgetLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <ListChart
            locale={userContext.user?.userPreferences?.language || "sv"}
            keyHeading={t("employee")}
            dataHeading={t("startDateWidget")}
            hasDate={true}
            hasModal={false}
            color={"secondary"}
            id={"latest-reported-absence"}
            employeeCardButton={true}
            data={
                data?.map((x) => ({
                    heading: x.employeeName || "",
                    modalHeading: "",
                    data: x.eventTime || "",
                    employmentId: x.employmentId,
                    hasFollowUp: x.hasFollowUp,
                    employeeDetails: [],
                })) ?? []
            }
        />
    );
};

export default LastReportedAbsenceWidget;
