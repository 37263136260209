import { MedHelpAbsenceReportingWebApiModelsConfigurationField } from "swagger/absencereportapi";

export const getNursePhoneNumber = (
    configurationFields: MedHelpAbsenceReportingWebApiModelsConfigurationField[] | null,
    getCountryPrefix?: boolean,
) => {
    const nursePhoneNumber = configurationFields?.find((c) => c?.name === "nursePhoneNumber")?.value;
    if (nursePhoneNumber && nursePhoneNumber.length > 0 && getCountryPrefix) {
        // Todo Should be diffrent countryprefix depending on selected country?
        if (nursePhoneNumber.charAt(0) === "0") {
            const prefixNumber = nursePhoneNumber.replace("0", "+46");
            return prefixNumber;
        }
    }
    return nursePhoneNumber;
};
