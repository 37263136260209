import React, { ReactNode, useState } from "react";
import { ReactComponent as CloseIcon } from "icons/close.svg";

interface IAbsenceDegreeWarningProps {
    id: string;
    preset: IAbsenceDegreeWarningPresets;
    className?: string;
    header: string;
    subHeader: string;
    children?: ReactNode;
}

const absenceDegreeWarningPresets = {
    mhBeige: "bg-mhbeige",
    gray: "bg-mhgrey-light",
    white: "bg-white",
    red: "bg-alert-400",
};

export type IAbsenceDegreeWarningPresets = keyof typeof absenceDegreeWarningPresets;

const AbsenceDegreeWarning: React.FunctionComponent<IAbsenceDegreeWarningProps> = (props) => {
    const { children, id, preset, className = "", header, subHeader } = props;
    const [visible, setvisible] = useState(true);
    return (
        <div
            id={id}
            className={`rounded shadow-black table-cell ${
                absenceDegreeWarningPresets[preset]
            } ${className} ${visible ? "" : `hidden`}`}
        >
            <div className="table">
                <div className="flex p-2 absence-degree-card-width">
                    <button type="button" className="table py-1 pl-2 font-bold w-11/12 text-left">
                        {header}
                    </button>
                    <div
                        className="w-10 h-10 flex items-center justify-center hover:bg-mhgrey-medium"
                        onClick={() => setvisible(!visible)}
                        role="button"
                        onKeyDown={() => setvisible(!visible)}
                        tabIndex={0}
                    >
                        <CloseIcon className="text-black w-4" />
                    </div>
                </div>

                <div className="p-4 table-caption caption-side-bottom">{subHeader}</div>
                {children}
            </div>
        </div>
    );
};

export default AbsenceDegreeWarning;
