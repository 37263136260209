import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { times } from "lodash/fp";
import Icon from "components/Icon";

export interface IProps {
    itemsCount: number;
    pageCount: number;
    activePage: number;
    onClick: (value: number) => void;
}

const Paginator = ({ itemsCount, pageCount, activePage, onClick }: IProps) => {
    const { t } = useTranslation();
    const firstElement = (activePage - 1) * 10;
    const lastElement = firstElement + 10;

    const PageNumbers = useMemo(() => {
        return {
            numberOfPages: pageCount,
            dotsPosition: {
                left: activePage - 1 > 2 && pageCount > 6,
                right: pageCount - activePage > 2 && pageCount > 6,
            },
            isFirst: Boolean(activePage === 1),
            isLast: Boolean(activePage === pageCount),
        };
    }, [activePage, pageCount]);

    const NumberOfPages = useMemo(() => {
        if (pageCount < 7) {
            return times((value) => value + 1, pageCount);
        }
        if (PageNumbers.dotsPosition.left && PageNumbers.dotsPosition.right) {
            return [1, activePage - 1, activePage, activePage + 1, pageCount];
        } else if (PageNumbers.dotsPosition.right) {
            return [...times((value) => value + 1, 5), pageCount];
        } else if (PageNumbers.dotsPosition.left) {
            return [1, ...times((value) => pageCount - 5 + (value + 1), 5)];
        }
        return [];
    }, [PageNumbers, activePage, pageCount]);

    return (
        <div className="flex flex-col items-center justify-between px-6 py-7 md:flex-row">
            <div className="pb-4 md:pb-0">
                {t("showingAmount", {
                    showing: `${firstElement + 1}-${lastElement > itemsCount ? itemsCount : lastElement}`,
                    amount: itemsCount,
                })}
            </div>
            <div className="flex items-center gap-2">
                <div
                    role="button"
                    onClick={() => {
                        if (PageNumbers.isFirst) return;
                        const previousPage = activePage - 1;
                        onClick(previousPage);
                    }}
                >
                    <Icon
                        icon="caretLeft"
                        size={12}
                        className={PageNumbers.isFirst ? "text-background-300" : "text-mhdarkgreen-original"}
                    />
                </div>
                <div className="flex ">
                    {NumberOfPages.map((number, index) => {
                        return (
                            <div className="flex" key={`paginator-number-page-${number}-${index}`}>
                                {Boolean(number === pageCount) && PageNumbers.dotsPosition.right && (
                                    <div className="w-[12px]">...</div>
                                )}
                                <div
                                    className={` ${
                                        activePage === number ? "bg-background-300" : "bg-none"
                                    } px-2 w-[26px]`}
                                    role="button"
                                    onClick={() => onClick(number)}
                                >
                                    {number}
                                </div>
                                {Boolean(number === 1) && PageNumbers.dotsPosition.left && <div>...</div>}
                            </div>
                        );
                    })}
                </div>
                <div
                    role="button"
                    onClick={() => {
                        if (PageNumbers.isLast) return;
                        const nextPage = activePage + 1;
                        onClick(nextPage);
                    }}
                >
                    <Icon
                        icon="caretRight"
                        size={12}
                        className={PageNumbers.isLast ? "text-background-300" : "text-mhdarkgreen-original"}
                    />
                </div>
            </div>
            <div />
        </div>
    );
};

export default Paginator;
