import { useEffect } from "react";
import { Error } from "@medhelp/ui";
import { actions } from "pages/service/redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SpinnerPage } from "components/Spinner";
import { Status } from "pages/service/redux/serviceSlice";
import BasicInfo from "pages/service/components/BasicInfo";
import CompanyList from "pages/service/components/CompanyList";
import { getActivePowerbarSearch } from "store/searchSelectors";
import CustomerInfo from "pages/service/components/CustomerInformation";
import DeactivateCompany from "pages/service/components/DeactivateCompany";
import ProductConfiguration from "pages/service/components/ProductConfiguration";
import BillingConfiguration from "pages/service/components/BillingConfiguration";
import { getCustomerData, getStatus } from "pages/service/redux/serviceSelectors";
import BillingPostConfiguration from "pages/service/components/BillingPostConfiguration";
import { fetchCompaniesByCustomerId, fetchCustomerData } from "pages/service/redux/serviceAsyncActions";

const CustomerConfiguration = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const { customerData: status } = useAppSelector(getStatus);
    const activePowerbarSearch = useAppSelector(getActivePowerbarSearch);
    const { customerId, product, customerName, retailerName } = useAppSelector(getCustomerData);

    const basicInfo = [
        { title: t("customerId"), value: customerId },
        { title: t("partner"), value: retailerName },
        { title: t("product"), value: product ? t(product) : t("legacy") },
    ];

    useEffect(() => {
        if (activePowerbarSearch && activePowerbarSearch.customerId && customerId !== activePowerbarSearch.customerId) {
            dispatch(actions.resetSelectedCompany());
            dispatch(fetchCustomerData(activePowerbarSearch.customerId));
            dispatch(fetchCompaniesByCustomerId(activePowerbarSearch.customerId));
        }
    }, [activePowerbarSearch, customerId, dispatch]);

    return (
        <>
            {status === Status.PENDING && <SpinnerPage />}
            {status === Status.ERROR && <Error text={t("errorOccurred")} />}
            {customerId && (
                <>
                    <BasicInfo name={customerName} basicInfo={basicInfo} />
                    <div className="border bg-[#FAF6F0]">
                        <CustomerInfo />
                        <ProductConfiguration />
                        <CompanyList />
                        <BillingConfiguration />
                        <BillingPostConfiguration />
                        <DeactivateCompany />
                    </div>
                </>
            )}
        </>
    );
};

export default CustomerConfiguration;
