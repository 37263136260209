import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect, useMemo, useState } from "react";
import { CustomerOnboarding } from "swagger/businessupport";
import { ServiceInput, ServiceInputContainer } from "../ServiceInput";
import { getCustomerData } from "pages/service/redux/serviceSelectors";
import { saveCustomerData } from "pages/service/redux/serviceAsyncActions";
import { AccordionStringContent, ServiceAccordion } from "../ServiceAccordion";

const CustomerInformation = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const { customerName, customerNumber, customerId } = useAppSelector(getCustomerData);
    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = useMemo(
        () => ({
            customerName,
            customerNumber,
            customerId,
        }),
        [customerName, customerNumber, customerId],
    );
    const {
        reset,
        register,
        handleSubmit,
        formState: { isDirty, isValid },
    } = useForm({ defaultValues });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const onSubmit = (data: CustomerOnboarding) => {
        setIsLoading(true);
        dispatch(saveCustomerData(data))
            .then(unwrapResult)
            .then(() => toast(t("saved")))
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                reset();
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("customerInformation")}
                disabled={!isDirty || !isValid}
                isLoading={isLoading}
                reset={() => reset(defaultValues)}
                content={
                    <div className="font-bold">
                        <div>
                            <AccordionStringContent heading={t("customer")} value={customerName || ""} />
                            <AccordionStringContent heading={t("orgNr")} value={customerNumber || ""} />
                        </div>
                    </div>
                }
            >
                <ServiceInputContainer heading={t("customerInformation")} borderBottom>
                    <ServiceInput
                        label={t("customer")}
                        register={register("customerName", { required: true })}
                        required
                    />
                    <ServiceInput
                        bold
                        required
                        label={t("organizationNumber")}
                        register={register("customerNumber", {
                            required: true,
                        })}
                    />
                </ServiceInputContainer>
            </ServiceAccordion>
        </form>
    );
};

export default CustomerInformation;
