import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getDateDisplayValue } from "utils/date";
import { Table, TableCell, TableRow } from "@medhelp/ui";
import { useSortableData } from "./hooks/useSortableData";
import { MedHelpHCMWebApiResponseTodoAndOngoingRow } from "swagger/healthCaseManagement";

interface IHCMTodoTable {
    data: MedHelpHCMWebApiResponseTodoAndOngoingRow[] | null;
    type: string;
}

const HCMTodoTable = ({ data, type }: IHCMTodoTable) => {
    const navigate = useNavigate();
    const { t } = useTranslation("dashboard");
    const [sortedData, handleSort] = useSortableData(data ?? [], "date");

    const tableHeaders = [
        { label: t("employee"), accessor: "name" },
        { label: type === "hcmTodo" ? t("activity") : t("caseType"), accessor: "type" },
        { label: t("date"), accessor: "date" },
        { label: t("caseManager"), accessor: "responsibleName" },
    ];

    return (
        <Table className="lg:p-10" headers={tableHeaders} handleSort={handleSort}>
            {sortedData.map(({ employmentId, name, type, date, responsibleName }) => (
                <TableRow
                    key={`${employmentId} + ${date}`}
                    onClick={() => navigate(`/followup/employee/${employmentId}/case`)}
                >
                    <TableCell showOnMobile>{name ?? "-"}</TableCell>
                    <TableCell>{type ? t(type) : "-"}</TableCell>
                    <TableCell>{date ? getDateDisplayValue(date, "d MMM yyyy") : ""}</TableCell>
                    <TableCell>{responsibleName || "-"}</TableCell>
                </TableRow>
            ))}
        </Table>
    );
};

export default HCMTodoTable;
