import { Country } from "pages/Administration/types";

export interface IValidSsn {
    isValid: boolean;
    birthYear?: number;
    gender?: "Male" | "Female";
}

const isValidDate = (dateString: string, format: string) => {
    if (!/^\d+$/.test(dateString)) {
        return false;
    }

    if (dateString.length !== format.length) {
        return false;
    }

    try {
        const currentYear = new Date().getFullYear();
        const yearIndex = format.indexOf("YYYY") >= 0 ? format.indexOf("YYYY") : format.indexOf("YY");
        const yearLength = format.indexOf("YYYY") >= 0 ? 4 : 2;
        const monthIndex = format.indexOf("MM");
        const dayIndex = format.indexOf("DD");

        let year =
            yearLength === 4
                ? parseInt(dateString.substring(yearIndex, yearIndex + 4), 10)
                : parseInt(dateString.substring(yearIndex, yearIndex + 2), 10) + 2000;

        // Check if the year is more than 150 years old
        if (currentYear - year > 150) {
            return false;
        }

        const month = parseInt(dateString.substring(monthIndex, monthIndex + 2), 10) - 1; // month is 0-indexed
        const day = parseInt(dateString.substring(dayIndex, dayIndex + 2), 10);

        if (year === null || isNaN(month) || isNaN(day)) return false;

        const date = new Date(year, month, day);
        return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
    } catch (error) {
        return false;
    }
};

const testSwedishDate = (number: string) => {
    let datePart = "";
    if (number.length === 10) {
        datePart = number.slice(0, 6);
    } else if (number.length === 12) {
        datePart = number.slice(0, 8);
    }

    const dateFormat = datePart.length === 6 ? "YYMMDD" : "YYYYMMDD";
    return isValidDate(datePart, dateFormat);
};
const testNorwegianDate = (number: string) => {
    let datePart = "";
    if (number.length === 11) {
        datePart = number.slice(0, 6);
    } else {
        return false;
    }

    const dateFormat = "DDMMYY";
    return isValidDate(datePart, dateFormat);
};
const testDanishDate = (number: string) => {
    let datePart = "";
    if (number.length === 10) {
        datePart = number.slice(0, 6);
    } else {
        return false;
    }

    const dateFormat = "DDMMYY";
    return isValidDate(datePart, dateFormat);
};
const testFinnishDate = (number: string) => {
    let datePart = "";
    if (number.length === 11) {
        datePart = number.slice(0, 6);
    } else {
        return false;
    }

    const dateFormat = "DDMMYY";
    return isValidDate(datePart, dateFormat);
};

const teshLuhn = (number: string) => {
    //convert to 10 digit number
    if (number.length === 12) {
        number = number.slice(2, 12);
    }

    const idNumber = number.replace(/\D/g, "").split("");

    if (idNumber.length !== 10) {
        return false;
    }

    const result = idNumber
        .map((c, idx) => (idx % 2 === 0 ? +c * 2 : +c))
        .map((n) => (n > 9 ? n - 9 : n))
        .reduce((acc, val) => acc + val);

    return result % 10 === 0;
};

const getGender = (ssn: string, country: Country): "Male" | "Female" => {
    switch (country) {
        case "SE":
            //If second last number in ssn is even = female
            return Number(ssn.slice(-2, -1)) % 2 === 0 ? "Female" : "Male";
        case "DK":
            //If last number in ssn is even = female
            return Number(ssn.slice(-1)) % 2 === 0 ? "Female" : "Male";

        case "FI":
            //If last three numbers in ssn is even = female
            return Number(ssn.slice(-4, -1)) % 2 === 0 ? "Female" : "Male";
        case "NO":
            //If third last number in ssn is even = female
            return Number(ssn.slice(-3, -2)) % 2 === 0 ? "Female" : "Male";

        default:
            return "Male";
    }
};

const getBirthYear = (ssn: string) => {
    const year = ssn.length === 10 ? ssn.slice(0, 2) : ssn.slice(0, 4);
    return Number(year);
};

const svPersonalNumber = (number: string): IValidSsn => {
    const isValid = // Length must be 12 digits
        number.length === 12 &&
        // Validate the date of birth
        testSwedishDate(number) &&
        // Validate the check digit (Luhn algorithm)
        teshLuhn(number);
    if (!isValid) return { isValid };
    return {
        isValid,
        birthYear: getBirthYear(number),
        gender: getGender(number, "SE"),
    };
};

const fiPersonalNumber = (number: string): IValidSsn => {
    const checksumTable = "0123456789ABCDEFHJKLMNPRSTUVWXY".split("");
    const SSN_REGEX = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d[-|U-Y]|[012]\d[A-F])\d{3}[\dA-Z]$/;

    if (!SSN_REGEX.test(number)) return { isValid: false };

    const rollingId = number.substring(7, 10);
    const checksum = number.substring(10, 11);
    const checksumBase = parseInt(number.substring(0, 6) + rollingId, 10);
    const isValid = // Validate the check digit
        checksum === checksumTable[checksumBase % 31] &&
        // Validate the date of birth
        testFinnishDate(number);
    return isValid
        ? {
              isValid,
              birthYear: getBirthYear(number),
              gender: getGender(number, "FI"),
          }
        : { isValid };
};

const dkPersonalNumber = (number: string): IValidSsn => {
    const isValid = // Length must be 10 digits
        number.length === 10 &&
        // Validate the date of birth
        testDanishDate(number) &&
        // Validate the check digit (Luhn algorithm)
        teshLuhn(number);
    if (!isValid) return { isValid };
    return {
        isValid,
        birthYear: getBirthYear(number),
        gender: getGender(number, "DK"),
    };
};

const isValidCheckDigit = (staticSequence: number[], elevenDigits: number[]) => {
    const productSum = staticSequence.reduce((acc, value, index) => acc + value * elevenDigits[index], 0);

    return productSum % 11 === 0;
};

const noPersonalNumber = (number: string): IValidSsn => {
    const staticSequenceFirstCheckDigit = [3, 7, 6, 1, 8, 9, 4, 5, 2, 1];
    const staticSequenceSecondCheckDigit = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
    const elevenDigitsArray = number.split("").map(Number);

    const isValidCheckDigits =
        isValidCheckDigit(staticSequenceFirstCheckDigit, elevenDigitsArray) &&
        isValidCheckDigit(staticSequenceSecondCheckDigit, elevenDigitsArray);
    const isValid =
        // Length must be 11 digits
        number.length === 11 &&
        // Validate the date of birth
        testNorwegianDate(number) &&
        // Validate the check digit (Luhn algorithm)
        isValidCheckDigits;
    if (!isValid) return { isValid };
    if (!isValid) return { isValid };
    return {
        isValid,
        birthYear: getBirthYear(number),
        gender: getGender(number, "NO"),
    };
};

const isValidSE = (input: string | number) => {
    if (typeof input === "string") {
        const normalized = input.replace(/\D/g, "");
        return svPersonalNumber(normalized);
    } else if (typeof input === "number") {
        const normalized = "" + input;
        return svPersonalNumber(normalized);
    } else {
        throw new TypeError(`Expected a string or number, got ${typeof input}`);
    }
};
const isValidNO = (input: string | number) => {
    if (typeof input === "string") {
        const normalized = input.replace(/\D/g, "");
        return noPersonalNumber(normalized);
    } else if (typeof input === "number") {
        const normalized = "" + input;
        return noPersonalNumber(normalized);
    } else {
        throw new TypeError(`Expected a string or number, got ${typeof input}`);
    }
};
const isValidDK = (input: string | number) => {
    if (typeof input === "string") {
        const normalized = input.replace(/\D/g, "");
        return dkPersonalNumber(normalized);
    } else if (typeof input === "number") {
        const normalized = "" + input;
        return dkPersonalNumber(normalized);
    } else {
        throw new TypeError(`Expected a string or number, got ${typeof input}`);
    }
};
const isValidFI = (input: string | number) => {
    if (typeof input === "string") {
        const normalized = input.trim();
        return fiPersonalNumber(normalized);
    } else if (typeof input === "number") {
        const normalized = "" + input;
        return fiPersonalNumber(normalized);
    } else {
        throw new TypeError(`Expected a string or number, got ${typeof input}`);
    }
};

export const validateNordicSsn = (ssn: string | number, countryCode: string) => {
    switch (countryCode) {
        case "SE":
            return isValidSE(ssn);
        case "NO":
            return isValidNO(ssn);
        case "DK":
            return isValidDK(ssn);
        case "FI":
            return isValidFI(ssn);
        default:
            throw new Error("Invalid country code. Valid country codes are: SE, NO, DK, FI");
    }
};
