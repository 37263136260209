import { Dropdown } from "@medhelp/ui";
import { isEmpty } from "lodash/fp";
import { useCallback, useMemo } from "react";
import { IRoute } from "routes/hooks";
import { useNavigation } from "../Navigation";
import DefaultDropdown from ".";

export interface IExternalProps {
    routes: IRoute[];
    header: string;
    fixedWidth?: boolean;
    setCurrentRoute?: (route: IRoute) => void;
    subMenu?: boolean;
    subMenuTo?: string;
    children?: React.ReactNode;
    className?: string;
    params?: string;
    layout?: "no-backdrop" | "normal";
    preset?: "primary" | "minimal";
}
export interface IOwnProps {
    activeRoute: string;
}

interface IProps extends IOwnProps, IExternalProps {}

const DropdownMenu = ({
    routes,
    activeRoute,
    subMenuTo,
    params,
    header,
    layout = "normal",
    preset = "primary",
    subMenu,
}: IProps) => {
    const nav = useNavigation(routes, activeRoute, subMenuTo, params);
    const container = useCallback((name: string) => <p className="text-grey-600">{name}</p>, []);
    const navigationLinks = useMemo(
        () =>
            nav.getNavigationRoutes.map((props, index) => {
                const comp = nav.createNavigationLink(props, index, container(props.name));
                if (!isEmpty(props.children)) {
                    return (
                        <DefaultDropdown
                            key={props.name}
                            routes={props.children}
                            subMenuTo={props.name}
                            header={props.name}
                            layout="no-backdrop"
                            subMenu={true}
                        />
                    );
                }
                return comp;
            }),
        [container, nav],
    );
    return (
        <Dropdown heading={header} onDropdownClick={() => {}} layout={layout} preset={preset} subMenu={subMenu}>
            {navigationLinks}
        </Dropdown>
    );
};

export default DropdownMenu;
