import { Card } from "@medhelp/ui";

interface IProps {
    header: string;
    body: string;
}
const Preview = ({ header, body }: IProps) => (
    <Card>
        <div className="flex flex-col max-w-[480px] gap-5 justify-center items-center">
            <p className="text-center text-xl font-serif">{header}</p>
            <div>
                <div className="text-center">{body}</div>
            </div>
        </div>
    </Card>
);

export default Preview;
