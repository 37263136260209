import { IFilterProps } from "pages/FollowUp/types";
import { ChangeEvent } from "react";
import RadioButtonFilter from "../RadioButtonFilter";

interface IPropsView extends IFilterProps {
    delayedText: string;
    showDelayedDaysFilter: boolean;
    delayedDays: string;
    setDelayedDays: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ActivityStatusFilter = ({
    items,
    header,
    tags,
    delayedText,
    showDelayedDaysFilter,
    delayedDays,
    setDelayedDays,
    onChange,
}: IPropsView) => {
    return (
        <RadioButtonFilter items={items} onChange={onChange} header={header} tags={tags}>
            {showDelayedDaysFilter && (
                <div className="ml-8">
                    <p className="text-sm mb-1">{delayedText}</p>
                    <input
                        id="activity-status-filter-delayed-days"
                        className="pl-2 py-1 w-27 border border-mhdarkgreen-original"
                        placeholder="14"
                        value={delayedDays}
                        onChange={setDelayedDays}
                    />
                </div>
            )}
        </RadioButtonFilter>
    );
};
export default ActivityStatusFilter;
