import Box from "./Box";
import { ReactElement } from "react";
import { ProgressBar } from "./ProgressBar";
import { useTranslation } from "react-i18next";
import { FileImportStatus } from "./StatusTag";
import ImportQueueInfo from "./ImportQueueInfo";
import ValidationErrors from "./ValidationErrors";

interface ImportSummaryProps {
    customerKey: number;
    importId?: string;
    status: FileImportStatus;
    importStart: string;
    importEnd?: string;
    children?: ReactElement;
}

const ImportSummary = ({ customerKey, importId, status, importStart, importEnd, children }: ImportSummaryProps) => {
    const { t } = useTranslation("fileImport");
    return (
        <Box>
            <div className="flex flex-col gap-8">
                <h2>{t("importSummary")}</h2>
                <>{children}</>
                <ImportQueueInfo status={status} importStart={importStart} importEnd={importEnd} />
                {status === "processing" && <ProgressBar />}
                {importId && <ValidationErrors customerKey={customerKey} importId={importId} />}
            </div>
        </Box>
    );
};

export default ImportSummary;
