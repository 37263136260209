import { useTranslation } from "react-i18next";
import WizardHeader from "components/WizardHeader";

interface IProps {
    className?: string;
    children?: React.ReactNode;
}

const NotificationConfirmation = (props: IProps) => {
    const { className, children } = props;
    const { t } = useTranslation();

    return (
        <div className="mt-10 lg:w-[624px]">
            <WizardHeader isMandatory={false} heading={t("notificationSent")} />
            <p className={`pt-4 pb-12 ${className}`}>{t("notificationConfirmationText")}</p>
            {children}
        </div>
    );
};

export default NotificationConfirmation;
