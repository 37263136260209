import { useMemo, useState } from "react";

type SortOrder = "asc" | "desc";

export const useSortableData = <T>(items: T[], defaultSortKey: keyof T): [T[], (sortKey: keyof T) => void] => {
    const [sortConfig, setSortConfig] = useState<{
        key: keyof T;
        direction: SortOrder;
    } | null>({
        key: defaultSortKey,
        direction: "asc",
    });

    const sortedItems = useMemo(() => {
        let sortableItems = [...items];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];

                // Check for numbers
                const isANumber = typeof aValue === "number";
                const isBNumber = typeof bValue === "number";

                if (isANumber && isBNumber) {
                    return sortConfig.direction === "asc"
                        ? Number(aValue) - Number(bValue)
                        : Number(bValue) - Number(aValue);
                }

                // Check for strings
                const isAString = typeof aValue === "string";
                const isBString = typeof bValue === "string";

                if (isAString && isBString) {
                    return sortConfig.direction === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }

                // Adding condition to sort booleans
                const isABoolean = typeof aValue === "boolean";
                const isBBoolean = typeof bValue === "boolean";

                if (isABoolean && isBBoolean) {
                    if (sortConfig.direction === "asc") {
                        return aValue === bValue ? 0 : aValue ? -1 : 1;
                    } else {
                        return aValue === bValue ? 0 : aValue ? 1 : -1;
                    }
                }

                // Fallback for different types
                if (!isAString) {
                    if (sortConfig.direction === "asc") return -1;
                    return 1;
                }
                if (!isBString) {
                    if (sortConfig.direction === "asc") return 1;
                    return -1;
                }

                return 0;
            });
        }
        return sortableItems;
    }, [items, sortConfig]);

    const handleSort = (sortKey: keyof T) => {
        let direction: SortOrder = "asc";
        if (sortConfig && sortConfig.key === sortKey && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key: sortKey, direction });
    };

    return [sortedItems, handleSort];
};
