import Timepicker from "components/Timepicker";
import { IDoubleDateTimepicker } from "../types";
import { setDateStringFromArray } from "../utils/util";
import { DateRangePicker } from "components/DatePicker/DateRangePicker";

const DoubleDateTimepicker = ({
    doubleDatepickerProps,
    disableDatesBefore,
    disableDatesAfter,
    selectedDateAsNumbers,
    setselectedDateAsNumbers,
    hideTimepicker,
    timepickerHeading,
    timepickerIsMandatory,
}: IDoubleDateTimepicker) => (
    <div>
        <DateRangePicker
            {...doubleDatepickerProps}
            disableDatesBefore={disableDatesBefore}
            disableDatesAfter={disableDatesAfter}
            edit={true}
        />
        {!hideTimepicker && (
            <Timepicker
                heading={timepickerHeading}
                hourValue={selectedDateAsNumbers[3] ?? ""}
                hourOnChange={(v, valid) => setselectedDateAsNumbers(v, valid, true)}
                minuteValue={selectedDateAsNumbers[4] ?? ""}
                minuteOnChange={setselectedDateAsNumbers}
                disabled={!setDateStringFromArray(selectedDateAsNumbers)}
                mandatory={timepickerIsMandatory}
                className="max-w-[624px]"
            />
        )}
    </div>
);

export default DoubleDateTimepicker;
