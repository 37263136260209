import { useMemo } from "react";
import Icon, { Icons } from "components/Icon";

export interface IProps {
    id: string;
    icon: Icons;
    header: string | JSX.Element;
    searchResultItems: string[];
}
const SearchResult = ({ id, icon, header, searchResultItems }: IProps) => {
    const Header = useMemo(() => {
        if (typeof header === "string") {
            return <p className="text-mhdarkgreen-original">{header}</p>;
        }
        return header;
    }, [header]);
    return (
        <div key={`${id}-search-result`} className="w-full flex items-center mt-3 mb-5">
            <div className="pr-2">
                <div className="bg-mhgrey-light rounded-full h-10 w-10 flex items-center justify-center">
                    <Icon icon={icon} className="w-4 h-4" />
                </div>
            </div>
            <div className="flex flex-col text-left">
                {Header}
                <ul className="text-mhgrey-dark opacity-75 break-all lg:hidden">
                    {searchResultItems.map((item) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
                <p className="text-mhgrey-dark opacity-75 hidden lg:flex">{searchResultItems.join(" | ")}</p>
            </div>
        </div>
    );
};

export default SearchResult;
