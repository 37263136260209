import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { absenceReportsActions } from "./absenceReportsSlice";
import { userActions } from "store/userSlice";
import { getServices } from "api/services";
import { IAbsenceReportEmploymentExtended } from "interfaces/IAbsenceReportEmployment";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import {
    MedHelpAbsenceReportingWebApiModelsAbsenceReportField,
    MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport,
    MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree,
    MedHelpAbsenceReportingDomainFieldTemplate,
    MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport,
    MedHelpAbsenceReportingDomainTemplate,
    MedHelpAbsenceReportingWebApiModelsConfigurationField,
    MedHelpAbsenceReportingWebApiModelsMedicalCertificate,
    MedHelpAbsenceReportingWebApiModelsAbsenceReport,
} from "swagger/absencereportapi";
import { MedHelpPeopleDomainContent } from "swagger/people";
import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import { postMessageToPrincess } from "utils/postToPrincess";
import { AppThunkAction, RootState } from "store";
import { IEmploymentSearchResult } from "interfaces/IEmploymentSearchResult";
import { Config } from "config";
import { createAbsenceReportSubmitModel, isAbsenceReportStatusFinishedBasedOnEndDate } from "../utils/absenceReports";
import { IHistorySearchType } from "interfaces/ISearch";
import * as UserSelectors from "store/userSelectors";
import { newDateString } from "utils/date";

interface IAbsenceReportInit {
    employmentGuid: string;
    locale: string;
    customerId: number;
    companyId: number;
    isManage: boolean;
}

interface IAbsenceReportInitPayload {
    absenceReports: IAbsenceReportExtended[];
    templates: MedHelpAbsenceReportingDomainTemplate[];
    configurationFields: MedHelpAbsenceReportingWebApiModelsConfigurationField[];
    firstDayMedicalCertificate: MedHelpAbsenceReportingWebApiModelsMedicalCertificate[];
}

interface ISendTemporarySubscriptions {
    absenceReportId: string;
    temporaryIds: string[];
}

interface ISubmitSelectedAbsenceReport {
    absenceReport: IAbsenceReportExtended;
    templateId: string;
    resetAbsenceReport?: boolean;
    postToPrincess?: boolean;
    activity?: "new" | "edit" | "recovery";
}

interface IFinishAbsenceReport {
    absenceReport: IAbsenceReportExtended;
    resetAbsenceReport: boolean;
    postToPrincess: boolean;
}

interface IOpenHistoricalAbsenceReport {
    id: string;
    employmentId: string;
}

export const fetchAbsenceReports = createAsyncThunk("fetchAbsenceReports", async (employmentGuid: string) => {
    try {
        const absenceReports = await getServices().clients.absenceReports.absenceReportsGet(employmentGuid);
        return {
            absenceReports: absenceReports.data,
        };
    } catch (error) {
        console.error(error);
        return {
            absenceReports: [],
        };
    }
});

export const getAbsenceReportInit = createAsyncThunk<IAbsenceReportInitPayload, IAbsenceReportInit>(
    "loadAbsenceReport",
    async (absenceReportInit: IAbsenceReportInit, thunkApi) => {
        const templates = getServices().clients.templates.templatesGet(
            absenceReportInit.employmentGuid,
            absenceReportInit.locale,
        );

        const configurationFields = getServices().clients.configurationFields.configurationFieldsGet(
            absenceReportInit.customerId,
            absenceReportInit.companyId,
        );

        const medicalCertificates =
            getServices().clients.firstDayCertificateRequired.medicalCertificatesGetByEmploymentIdGet(
                absenceReportInit.employmentGuid,
            );

        const absenceReports = getServices().clients.absenceReports.absenceReportsGet(absenceReportInit.employmentGuid);

        const promises = await Promise.allSettled([
            templates,
            configurationFields,
            medicalCertificates,
            absenceReports,
        ]);

        if (promises.every((res) => res?.status === "fulfilled")) {
            const values = promises.map((result) => {
                if (result.status === "fulfilled") {
                    return result.value.data;
                } else {
                    return [];
                }
            });

            const reportsResponse = values[3] as MedHelpAbsenceReportingWebApiModelsAbsenceReport[];

            // If report is ongoing, add template fields to report
            const reportsWithTemplates = reportsResponse.map(async (absenceReport) => {
                if (absenceReport.status === "Ongoing") {
                    const res = await getServices().clients.absenceReportFields.absenceReportFieldsGet(
                        absenceReport.id,
                    );
                    const fields = res.data;
                    return { ...absenceReport, fields };
                }
                return absenceReport;
            });

            const reportsWithTemplatesResponse = (await Promise.allSettled(reportsWithTemplates)).map((result) => {
                if (result.status === "fulfilled") {
                    return result.value;
                } else {
                    return [];
                }
            });

            return {
                templates: values[0] as MedHelpAbsenceReportingDomainTemplate[],
                configurationFields: values[1] as MedHelpAbsenceReportingWebApiModelsConfigurationField[],
                firstDayMedicalCertificate: values[2] as MedHelpAbsenceReportingWebApiModelsMedicalCertificate[],
                absenceReports: reportsWithTemplatesResponse as IAbsenceReportExtended[],
            };
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

export const getCompanySpecificAbsenceConfirmation = createAsyncThunk<MedHelpPeopleDomainContent>(
    "getCompanySpecificAbsenceConfirmation",
    async (_, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const region = UserSelectors.getRegion(state);
        const language = state.user.userContextExtended?.user?.userPreferences?.language ?? "en";

        try {
            const { data } = await getServices().clients.content.contentV2Get(
                "absenceReporting",
                "companySpecificAbsenceConfirmation",
                language,
                region,
            );
            return data;
        } catch (error) {
            return thunkApi.rejectWithValue("rejected");
        }
    },
);

export const getEmploymentsByQuery = createAsyncThunk<IEmploymentSearchResult[], string>(
    "getEmploymentsByQuery",
    async (query: string, thunkApi) => {
        const fetchData = async () => {
            const token = getServices().token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.get<IEmploymentSearchResult[]>(
                `${Config.api_servicesBase_url}absencereporting/api/Search/Employments?searchString=${query}`,
                config,
            );
            return data;
        };
        try {
            const employmentSearchResult = await fetchData();
            return employmentSearchResult;
        } catch (error) {
            return thunkApi.rejectWithValue("rejected");
        }
    },
);

export const getEmploymentsBySSN = createAsyncThunk<IEmploymentSearchResult[], string>(
    "getEmploymentsBySSN",
    async (query: string, thunkApi) => {
        const fetchData = async () => {
            const token = getServices().token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data } = await axios.get<IEmploymentSearchResult[]>(
                `${Config.api_servicesBase_url}absencereporting/api/MedicalAdvisory/SearchBySsn?searchString=${query}`,
                config,
            );
            return data;
        };
        try {
            const employmentSearchResult = await fetchData();
            return employmentSearchResult;
        } catch (error: any) {
            return thunkApi.rejectWithValue("rejected");
        }
    },
);

export const getAbsenceReportEmploymentByGuid = createAsyncThunk(
    "getAbsenceReportEmploymentByGuid",
    async (employmentGuid: string, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const fetchData = async (): Promise<IAbsenceReportEmploymentExtended> => {
            const token = state.user.token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data: selectedEmployment } = await axios.get<IAbsenceReportEmploymentExtended>(
                `${Config.api_servicesBase_url}absencereporting/api/absencereporting/GetEmploymentManagerInfoByGuid?referenceKey=${employmentGuid}`,
                config,
            );
            return selectedEmployment;
        };
        try {
            const data = await fetchData();
            thunkApi.dispatch(absenceReportsActions.updateSelectedEmploymnent(data));
            thunkApi.dispatch(absenceReportsActions.updateStatus(true));
        } catch (error) {
            thunkApi.dispatch(absenceReportsActions.updateStatus(false));
            console.error(error);
        }
    },
);

export const getAbsenceReportById = (
    absenceReportId: string,
    isEdit?: boolean,
    fields?: IFieldTemplateExtended[],
): AppThunkAction<Promise<void>> => {
    return async (dispatch) => {
        const fetchData = async () => {
            return await getServices().clients.absenceReports.absenceReportsIdGet(absenceReportId);
        };
        try {
            const { data } = await fetchData();
            const absenceReport: IAbsenceReportExtended = {
                ...data,
                isEdit: isEdit === true,
                fields: fields ?? [],
            };
            dispatch(absenceReportsActions.updateSelectedAbsenceReport(absenceReport));
            dispatch(absenceReportsActions.updateStatus(true));
        } catch (error) {
            dispatch(absenceReportsActions.updateStatus(false));
            console.error(error);
        }
    };
};

export const getAbsenceReportAndFieldsById = (
    absenceReportId: string,
    templateFields: MedHelpAbsenceReportingDomainFieldTemplate[],
): AppThunkAction<Promise<void>> => {
    return async (dispatch) => {
        const fetchAbsenceReportData = async () => {
            return await getServices().clients.absenceReports.absenceReportsIdGet(absenceReportId);
        };
        const fetchAbsenceReportFieldsData = async () => {
            return await getServices().clients.absenceReportFields.absenceReportFieldsGet(absenceReportId);
        };

        try {
            const { data: absenceReport } = await fetchAbsenceReportData();
            const { data: absenceReportFields = [] } = await fetchAbsenceReportFieldsData();
            const absenceReportFieldsExtended: IFieldTemplateExtended[] = templateFields.map(
                (templateField: MedHelpAbsenceReportingDomainFieldTemplate) => {
                    return {
                        ...templateField,
                        value: absenceReportFields.find(
                            (absenceReportField: MedHelpAbsenceReportingWebApiModelsAbsenceReportField) =>
                                absenceReportField.name?.toLowerCase() === templateField.name?.toLowerCase(),
                        )?.value!,
                    };
                },
            );
            const absenceReportWithFields: IAbsenceReportExtended = {
                ...absenceReport,
                fields: absenceReportFieldsExtended,
            };
            dispatch(absenceReportsActions.updateSelectedAbsenceReport(absenceReportWithFields));
            dispatch(absenceReportsActions.updateStatus(true));
        } catch (error) {
            dispatch(absenceReportsActions.updateStatus(false));
            console.error(error);
        }
    };
};

export const getAbsenceReportFields = (
    absenceReportId: string,
    selectedAbsenceReport?: boolean,
): AppThunkAction<Promise<void>> => {
    return async (dispatch) => {
        const fetchData = async () => {
            return await getServices().clients.absenceReportFields.absenceReportFieldsGet(absenceReportId);
        };
        try {
            const { data: absenceReportFields = [] } = await fetchData();
            if (selectedAbsenceReport) {
                dispatch(absenceReportsActions.updateSelectedAbsenceReportWithFields(absenceReportFields));
                dispatch(
                    absenceReportsActions.updateShowAbsenceCauseToEmployer(
                        absenceReportFields?.find((field) => field.name === "AbsenceCause")?.confidential
                            ? "false"
                            : "true",
                    ),
                );
            } else {
                dispatch(
                    absenceReportsActions.updateAbsenceReportWithFields({
                        fields: absenceReportFields,
                        absenceReportId,
                    }),
                );
            }
            dispatch(absenceReportsActions.updateStatus(true));
        } catch (error) {
            dispatch(absenceReportsActions.updateStatus(false));
            console.error(error);
        }
    };
};

export const getAbsenceReportRecentEmploymentSearches = (
    type: keyof IHistorySearchType,
): AppThunkAction<Promise<void>> => {
    return async (dispatch) => {
        const fetchData = async () => {
            return await getServices().clients.search.localSearchGet(type);
        };
        try {
            const { data } = await fetchData();
            dispatch(absenceReportsActions.replaceAbsenceReportRecentEmploymentSearches(data));
            dispatch(absenceReportsActions.updateStatus(true));
        } catch (error) {
            dispatch(absenceReportsActions.updateStatus(false));
            console.error(error);
        }
    };
};

export const getAbsenceReportEmploymentByInteger = (id: number): AppThunkAction<Promise<void>> => {
    return async (dispatch) => {
        const fetchData = async (): Promise<IAbsenceReportEmploymentExtended> => {
            const token = getServices().token;
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            const { data: selectedEmployment } = await axios.get<IAbsenceReportEmploymentExtended>(
                `${Config.api_servicesBase_url}absencereporting/api/Organization/GetReportEmployment?employmentId=${id}`,
                config,
            );
            return selectedEmployment;
        };
        try {
            const data = await fetchData();
            if (data) {
                dispatch(absenceReportsActions.updateSelectedEmploymnent(data));
                dispatch(absenceReportsActions.updateStatus(true));
            } else dispatch(absenceReportsActions.updateStatus(false));
        } catch (error) {
            dispatch(absenceReportsActions.updateStatus(false));
            console.error(error);
        }
    };
};

export const updateFinishedAbsenceReport = (
    finishedAbsenceReport: IAbsenceReportExtended,
): AppThunkAction<Promise<void>> => {
    return async (dispatch) => {
        const postOpen = async () => {
            await getServices().clients.absenceReports.absenceReportsIdOpenPost(finishedAbsenceReport.id!);
        };
        const putDataDates = async (
            absenceReportBaseDatesUpdate: MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport,
        ) => {
            return await getServices().clients.absenceReports.absenceReportsIdPut(
                finishedAbsenceReport.id!,
                absenceReportBaseDatesUpdate,
            );
        };
        const postDataDegree = async (
            absenceReportDegree: MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree,
        ) => {
            return await getServices().clients.absenceReportDegrees.absenceReportDegreesPost(absenceReportDegree);
        };
        const postDataSubmit = async () => {
            return await getServices().clients.absenceReports.absenceReportsIdSubmitPost(finishedAbsenceReport.id!);
        };
        const postDataFinish = async () => {
            return await getServices().clients.absenceReports.absenceReportsIdFinishPost(finishedAbsenceReport.id!, {
                end: finishedAbsenceReport.end!,
                backAtWork: finishedAbsenceReport.backAtWork!,
            });
        };
        try {
            //OPEN -> Update -> Submit -> Finish
            await postOpen();
            const absenceReportBaseDatesUpdate: MedHelpAbsenceReportingWebApiModelsUpdateAbsenceReport = {
                start: finishedAbsenceReport.start,
                backAtWork: finishedAbsenceReport.end,
            };
            await putDataDates(absenceReportBaseDatesUpdate);
            const absenceReportDegree: MedHelpAbsenceReportingWebApiModelsAddAbsenceReportDegree = {
                degree: finishedAbsenceReport.degree,
                absenceReportId: finishedAbsenceReport.id,
                timeStamp: newDateString(),
            };
            await postDataDegree(absenceReportDegree);
            await postDataSubmit();
            await postDataFinish();
            dispatch(absenceReportsActions.updateAbsenceReport(finishedAbsenceReport));
            dispatch(absenceReportsActions.updateEditFinishedAbsenceReportOK(true));
        } catch (error) {
            dispatch(absenceReportsActions.updateEditFinishedAbsenceReportOK(false));
            console.error(error);
        }
    };
};

export const submitSelectedAbsenceReport = (
    submitSelectedAbsenceReport: ISubmitSelectedAbsenceReport,
): AppThunkAction<Promise<void>> => {
    const { absenceReport, templateId, resetAbsenceReport, postToPrincess, activity } = submitSelectedAbsenceReport;
    return async (dispatch) => {
        const absenceReportSubmitModel = createAbsenceReportSubmitModel(absenceReport, templateId);
        const postData = async () => {
            return await getServices().clients.absenceReports.v2AbsenceReportsSubmitPost(absenceReportSubmitModel);
        };
        try {
            dispatch(absenceReportsActions.updateSelectedAbsenceReportIsLoading(true));
            const { data } = await postData();
            dispatch(
                absenceReportsActions.submitSelectedAbsenceReport({
                    ...absenceReport,
                    id: data.id,
                    fields: data.fields,
                    degrees: data.degrees,
                }),
            );
            dispatch(userActions.updateContactInformation(data.fields?.find(({ name }) => name === "Mobile")?.value));
            dispatch(absenceReportsActions.updateSelectedAbsenceReportIsLoading(false));
            if (postToPrincess && activity) {
                postMessageToPrincess("init", activity, data.id ?? "", absenceReport.type ?? "");
                postMessageToPrincess("complete", activity, data.id ?? "", absenceReport.type ?? "");
            }
            if (resetAbsenceReport) {
                dispatch(absenceReportsActions.resetSelectedAbsenceReport());
            }
        } catch (error) {
            dispatch(absenceReportsActions.updateSelectedAbsenceReportIsLoading(false));
            dispatch(absenceReportsActions.updateSelectedAbsenceReportIsError(true));
            console.error(error);
        }
    };
};

export const finishAbsenceReport = (finishAbsenceReport: IFinishAbsenceReport): AppThunkAction<Promise<void>> => {
    const { absenceReport, resetAbsenceReport, postToPrincess } = finishAbsenceReport;
    return async (dispatch) => {
        const postData = async () => {
            return await getServices().clients.absenceReports.absenceReportsIdFinishPost(absenceReport.id!, {
                end: absenceReport.end!,
                backAtWork: absenceReport.backAtWork!,
            });
        };
        try {
            dispatch(
                absenceReportsActions.updateAbsenceReportIsLoading({
                    id: absenceReport.id,
                    isLoading: true,
                }),
            );
            await postData();
            if (postToPrincess) {
                postMessageToPrincess("complete", "recovery", absenceReport?.id, absenceReport?.type!);
            }
            if (isAbsenceReportStatusFinishedBasedOnEndDate(absenceReport.end!)) {
                const absenceReportWithUpdatedStatus: IAbsenceReportExtended = {
                    ...absenceReport,
                    status: "Finished",
                    isLoading: false,
                    isError: false,
                };
                dispatch(absenceReportsActions.finishAbsenceReport(absenceReportWithUpdatedStatus));
            } else {
                const absenceReportWithUpdatedStatus: IAbsenceReportExtended = {
                    ...absenceReport,
                    status: "Ongoing",
                    isLoading: false,
                    isError: false,
                };
                dispatch(absenceReportsActions.finishAbsenceReport(absenceReportWithUpdatedStatus));
            }
            if (resetAbsenceReport) {
                dispatch(absenceReportsActions.resetSelectedAbsenceReport());
            }
        } catch (error) {
            dispatch(
                absenceReportsActions.updateAbsenceReportIsLoading({
                    id: absenceReport.id,
                    isLoading: false,
                }),
            );
            dispatch(
                absenceReportsActions.updateAbsenceReportIsError({
                    id: absenceReport.id,
                    isError: true,
                }),
            );
        }
    };
};

export const finishedAbsenceReport =
    (
        finishedAbsenceReport: MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport,
    ): AppThunkAction<Promise<void>> =>
    async (dispatch) => {
        const postData = async () => {
            return await getServices().clients.historicalAbsenceReport.historicalAbsenceReportPost(
                finishedAbsenceReport,
            );
        };
        try {
            const { data: finishedAbsenceReportId } = await postData();
            const _finishedAbsenceReport: IAbsenceReportExtended = {
                id: finishedAbsenceReportId,
                start: finishedAbsenceReport.start,
                end: finishedAbsenceReport.end,
                employmentId: finishedAbsenceReport.employmentId,
                type: finishedAbsenceReport.type,
                degree: finishedAbsenceReport.degree,
                backAtWork: finishedAbsenceReport.end,
                status: "Finished",
                isPreliminary: false,
            };
            dispatch(absenceReportsActions.addFinishedReport(_finishedAbsenceReport));
            dispatch(absenceReportsActions.updateCreateFinishedAbsenceReportOK(true));
        } catch (error) {
            dispatch(absenceReportsActions.updateCreateFinishedAbsenceReportOK(false));
            console.error(error);
        }
    };

export const deleteAbsenceReport =
    (absenceReport: IAbsenceReportExtended): AppThunkAction<Promise<void>> =>
    async (dispatch) => {
        const deleteData = async () => {
            return await getServices().clients.absenceReports.absenceReportsIdDeleteDelete(absenceReport.id!);
        };
        try {
            dispatch(
                absenceReportsActions.updateAbsenceReportIsLoading({
                    id: absenceReport.id,
                    isLoading: true,
                }),
            );
            await deleteData();
            const absenceReportSuccess: IAbsenceReportExtended = {
                ...absenceReport,
                isLoading: false,
            };
            dispatch(absenceReportsActions.deleteAbsenceReport(absenceReportSuccess));
            dispatch(absenceReportsActions.resetSelectedAbsenceReport());
        } catch (error) {
            dispatch(
                absenceReportsActions.updateAbsenceReportIsLoading({
                    id: absenceReport.id,
                    isLoading: false,
                }),
            );
            dispatch(
                absenceReportsActions.updateAbsenceReportIsError({
                    id: absenceReport.id,
                    isError: true,
                }),
            );
            console.error(error);
        }
    };

export const deleteSelectedAbsenceReportField =
    (absenceReportField: IFieldTemplateExtended): AppThunkAction<Promise<void>> =>
    async (dispatch: any) => {
        const deleteData = async () => {
            return await getServices().clients.absenceReportFields.absenceReportFieldsIdDeleteDelete(
                absenceReportField.id!,
            );
        };
        try {
            if (absenceReportField.id) {
                await deleteData();
            }

            dispatch(absenceReportsActions.deleteSelectedAbsenceReportField(absenceReportField));
        } catch (error) {
            console.error(error);
        }
    };

export const getTemporarySubscriptions = createAsyncThunk(
    "getTemporarySubscriptions",
    async (companyId: number, thunkApi) => {
        const fetchData = async () => {
            return await getServices().clients.temporarySubscription.apiV1TemporarySubscriptionListCompanyIdGet(
                companyId,
            );
        };
        try {
            const { data } = await fetchData();
            return data;
        } catch (error) {
            return thunkApi.rejectWithValue("rejected");
        }
    },
);

export const sendTemporarySubscriptions = createAsyncThunk(
    "sendTemporarySubscriptions",
    async (temporarySubscriptions: ISendTemporarySubscriptions, thunkApi) => {
        const fetchData = async () => {
            return await getServices().clients.temporarySubscription.apiV1TemporarySubscriptionAbsenceReportIdSendPost(
                temporarySubscriptions.absenceReportId,
                temporarySubscriptions.temporaryIds,
            );
        };
        try {
            const { data } = await fetchData();
            return data;
        } catch (error) {
            return thunkApi.rejectWithValue("rejected");
        }
    },
);

export const openHistoricalAbsenceReport = createAsyncThunk(
    "openHistoricalAbsenceReport",
    async (openAbsenceReport: IOpenHistoricalAbsenceReport, { dispatch }) => {
        const { id, employmentId } = openAbsenceReport;
        const fetchData = async () => {
            return await getServices().clients.absenceReports.v2AbsenceReportsOpenPut({
                id,
                employmentId,
            });
        };
        try {
            const { data } = await fetchData();
            dispatch(absenceReportsActions.resetSelectedAbsenceReport());
            return data;
        } catch (error) {
            console.error(error);
        }
    },
);

export const editHistoricalAbsenceReport = createAsyncThunk(
    "editHistoricalAbsenceReport",
    async (editReport: IAbsenceReportExtended, { dispatch }) => {
        const putReport = async () => {
            return await getServices().clients.absenceReports.v2AbsenceReportsHistoricalPut({
                id: editReport.id,
                employmentId: editReport.employmentId,
                start: editReport.start,
                end: editReport.end ?? "",
                backAtWork: editReport.backAtWork ?? "",
                degrees: editReport.degrees,
                suppressNotification: false,
            });
        };
        try {
            dispatch(absenceReportsActions.updateSelectedAbsenceReportIsLoading(true));
            await putReport();
            dispatch(
                absenceReportsActions.updateAbsenceReportIsEdit({
                    id: editReport.id,
                    isEdit: false,
                }),
            );
            dispatch(absenceReportsActions.updateSelectedAbsenceReportIsLoading(false));
            dispatch(absenceReportsActions.resetSelectedAbsenceReport());
            return editReport;
        } catch (error) {
            console.error(error);
        }
    },
);

export const getIsRisk = createAsyncThunk("getIsRisk", async () => {
    try {
        const res = await getServices().clients.risk.employmentCallGet();
        return res.data;
    } catch (error) {
        console.error(error);
    }
});
