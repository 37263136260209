import { useMemo } from "react";
import Secondary, { IProps as ISecondary } from "./Secondary";
import Primary, { IProps as IPrimary } from "./Primary";
import Minimal, { IProps as IMinimal } from "./Minimal";

export type Preset = "primary" | "secondary" | "minimal";
type IProps = (IPrimary | ISecondary | IMinimal) & {
    preset: Preset;
};

const isPrimary = (props: IProps): props is IPrimary & { preset: Preset } => props.preset === "primary";

const isSecondary = (props: IProps): props is ISecondary & { preset: Preset } => props.preset === "secondary";

const isMinimal = (props: IProps): props is ISecondary & { preset: Preset } => props.preset === "minimal";

const Dropdown = (props: IProps) => {
    const Props = useMemo(() => {
        if (!props.preset) {
            const newProps = Object.assign(props, {
                ...props,
                preset: "primary",
            });
            return newProps;
        }
        return props;
    }, [props]);
    const Dropdown = useMemo(() => {
        if (isPrimary(Props)) {
            return <Primary {...Props} />;
        }
        if (isSecondary(Props)) {
            return <Secondary {...Props} />;
        }
        if (isMinimal(Props)) {
            return <Minimal {...Props} />;
        }
        return <></>;
    }, [Props]);
    return Dropdown;
};

export default Dropdown;
