/* tslint:disable */
/* eslint-disable */
/**
 * My Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AbsenceCause = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_13: 13
} as const;

export type AbsenceCause = typeof AbsenceCause[keyof typeof AbsenceCause];


/**
 * 
 * @export
 * @interface AbsenceCostCalculationPostModel
 */
export interface AbsenceCostCalculationPostModel {
    /**
     * 
     * @type {string}
     * @memberof AbsenceCostCalculationPostModel
     */
    'employmentId'?: string;
    /**
     * 
     * @type {Array<AbsencePeriod>}
     * @memberof AbsenceCostCalculationPostModel
     */
    'absencePeriods'?: Array<AbsencePeriod> | null;
}
/**
 * 
 * @export
 * @interface AbsenceDegree
 */
export interface AbsenceDegree {
    /**
     * 
     * @type {number}
     * @memberof AbsenceDegree
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsenceDegree
     */
    'value'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsenceDegree
     */
    'latestAppliedEventId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceDegree
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbsenceDegree
     */
    'absenceCause'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbsenceDegree
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbsenceDegree
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceDegree
     */
    'confidentialAbsenceCause'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AbsenceDegree
     */
    'absenceOccasionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsenceDegree
     */
    'employmentAbsenceId'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AbsenceEventTypeFlags = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_4: 4,
    NUMBER_8: 8,
    NUMBER_16: 16,
    NUMBER_32: 32,
    NUMBER_64: 64,
    NUMBER_128: 128
} as const;

export type AbsenceEventTypeFlags = typeof AbsenceEventTypeFlags[keyof typeof AbsenceEventTypeFlags];


/**
 * 
 * @export
 * @enum {string}
 */

export const AbsencePageRequestType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type AbsencePageRequestType = typeof AbsencePageRequestType[keyof typeof AbsencePageRequestType];


/**
 * 
 * @export
 * @interface AbsencePeriod
 */
export interface AbsencePeriod {
    /**
     * 
     * @type {string}
     * @memberof AbsencePeriod
     */
    'absenceReportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbsencePeriod
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof AbsencePeriod
     */
    'end'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbsencePeriod
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AbsencePeriodStatusFlags = {
    NUMBER_0: 0,
    NUMBER_2: 2,
    NUMBER_4: 4
} as const;

export type AbsencePeriodStatusFlags = typeof AbsencePeriodStatusFlags[keyof typeof AbsencePeriodStatusFlags];


/**
 * 
 * @export
 * @interface AbsenceSearchFilter
 */
export interface AbsenceSearchFilter {
    /**
     * 
     * @type {AbsenceTypeFlags}
     * @memberof AbsenceSearchFilter
     */
    'absenceTypes'?: AbsenceTypeFlags;
    /**
     * 
     * @type {AbsenceEventTypeFlags}
     * @memberof AbsenceSearchFilter
     */
    'absenceEventTypes'?: AbsenceEventTypeFlags;
    /**
     * 
     * @type {number}
     * @memberof AbsenceSearchFilter
     */
    'employmentId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AbsenceSearchFilter
     */
    'employmentIds'?: Array<number> | null;
    /**
     * 
     * @type {AbsenceTranslation}
     * @memberof AbsenceSearchFilter
     */
    'translation'?: AbsenceTranslation | null;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceSearchFilter
     */
    'includeHistoricalDepartments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AbsenceSearchFilter
     */
    'ignoreDepartmentFilter'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AbsenceSearchFilter
     */
    'employmentGroup'?: number | null;
    /**
     * 
     * @type {AbsencePageRequestType}
     * @memberof AbsenceSearchFilter
     */
    'requestType'?: AbsencePageRequestType;
    /**
     * 
     * @type {AbsenceSearchResultGrouping}
     * @memberof AbsenceSearchFilter
     */
    'grouping'?: AbsenceSearchResultGrouping;
    /**
     * 
     * @type {AbsenceSearchResultSorting}
     * @memberof AbsenceSearchFilter
     */
    'sorting'?: AbsenceSearchResultSorting;
    /**
     * 
     * @type {AbsencePeriodStatusFlags}
     * @memberof AbsenceSearchFilter
     */
    'status'?: AbsencePeriodStatusFlags;
    /**
     * 
     * @type {string}
     * @memberof AbsenceSearchFilter
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AbsenceSearchFilter
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceSearchFilter
     */
    'minLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceSearchFilter
     */
    'maxLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceSearchFilter
     */
    'minNoOfOccurrences'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AbsenceSearchFilter
     */
    'departments'?: Array<number> | null;
    /**
     * 
     * @type {Array<DepartmentRecursiveModel>}
     * @memberof AbsenceSearchFilter
     */
    'departmentWithRecursive'?: Array<DepartmentRecursiveModel> | null;
    /**
     * 
     * @type {string}
     * @memberof AbsenceSearchFilter
     */
    'timeZone'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AbsenceSearchFilter
     */
    'companies'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof AbsenceSearchFilter
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof AbsenceSearchFilter
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof AbsenceSearchFilter
     */
    'sortOrder'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AbsenceSearchResultGrouping = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type AbsenceSearchResultGrouping = typeof AbsenceSearchResultGrouping[keyof typeof AbsenceSearchResultGrouping];


/**
 * 
 * @export
 * @enum {string}
 */

export const AbsenceSearchResultSorting = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AbsenceSearchResultSorting = typeof AbsenceSearchResultSorting[keyof typeof AbsenceSearchResultSorting];


/**
 * 
 * @export
 * @interface AbsenceTranslation
 */
export interface AbsenceTranslation {
    /**
     * 
     * @type {string}
     * @memberof AbsenceTranslation
     */
    'workbookTitle'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AbsenceTranslation
     */
    'titles'?: Array<string> | null;
    /**
     * 
     * @type {Array<IdName>}
     * @memberof AbsenceTranslation
     */
    'absenceTypes'?: Array<IdName> | null;
    /**
     * 
     * @type {Array<IdName>}
     * @memberof AbsenceTranslation
     */
    'absenceCauses'?: Array<IdName> | null;
    /**
     * 
     * @type {Array<IdName>}
     * @memberof AbsenceTranslation
     */
    'absenceEventTypes'?: Array<IdName> | null;
    /**
     * 
     * @type {Array<IdName>}
     * @memberof AbsenceTranslation
     */
    'deviantAbsenceTypes'?: Array<IdName> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AbsenceType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type AbsenceType = typeof AbsenceType[keyof typeof AbsenceType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AbsenceTypeFlags = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AbsenceTypeFlags = typeof AbsenceTypeFlags[keyof typeof AbsenceTypeFlags];


/**
 * 
 * @export
 * @interface AccountAccessChangeEventModel
 */
export interface AccountAccessChangeEventModel {
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEventModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessChangeEventModel
     */
    'eventDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessChangeEventModel
     */
    'effectiveDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessChangeEventModel
     */
    'userAccountOperationType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEventModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEventModel
     */
    'performedByUserAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEventModel
     */
    'externalId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessChangeEventModel
     */
    'externalType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEventModel
     */
    'accessRightType'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessChangeEventModel
     */
    'accessRightTypeName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessChangeEventModel
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountAccessChangeEventModel
     */
    'recursive'?: boolean | null;
    /**
     * 
     * @type {Company}
     * @memberof AccountAccessChangeEventModel
     */
    'company'?: Company | null;
    /**
     * 
     * @type {Department}
     * @memberof AccountAccessChangeEventModel
     */
    'department'?: Department | null;
    /**
     * 
     * @type {UserAccountLite}
     * @memberof AccountAccessChangeEventModel
     */
    'performedBy'?: UserAccountLite | null;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessChangeEventModel
     */
    'performedByCompanyName'?: string | null;
}
/**
 * 
 * @export
 * @interface AccountAccessRightSearchFilter
 */
export interface AccountAccessRightSearchFilter {
    /**
     * 
     * @type {number}
     * @memberof AccountAccessRightSearchFilter
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccountAccessRightSearchFilter
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessRightSearchFilter
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountAccessRightSearchFilter
     */
    'toDate'?: string;
}
/**
 * 
 * @export
 * @interface CatalogEntryContract
 */
export interface CatalogEntryContract {
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryContract
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'removalDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryContract
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryContract
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogEntryContract
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogEntryContract
     */
    'inActive'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogEntryContract
     */
    'doNotDelete'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'doNotDeleteMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CatalogEntryContract
     */
    'referenceKey'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Channel = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type Channel = typeof Channel[keyof typeof Channel];


/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'customerKey'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Company
     */
    'departmentIds'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'automatedOrganizationalChangeProcess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'sizeClass'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'industryCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'newReporting'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'referenceKey'?: string;
}
/**
 * 
 * @export
 * @interface CompanyRegion
 */
export interface CompanyRegion {
    /**
     * 
     * @type {number}
     * @memberof CompanyRegion
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegion
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyRegion
     */
    'region'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CompareOperation = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type CompareOperation = typeof CompareOperation[keyof typeof CompareOperation];


/**
 * 
 * @export
 * @interface ContactInformation
 */
export interface ContactInformation {
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'telephoneCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'telephoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'mobileCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'mobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryMobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryMobileCountryPrefix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryMobileNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'homeAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactInformation
     */
    'secondaryEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface CostCalculationBasisPostModel
 */
export interface CostCalculationBasisPostModel {
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'employmentGroupId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CostCalculationBasisPostModel
     */
    'employmentGroupIdentifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'monthlySalaryAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'holidayCompensation'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'socialSecurityContribution'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'hoursDayAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'overheadCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'personnelAndFinanceAdministration'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'occupationalHealthAndWellness'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'unionWork'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'workEnvironment'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'personnelSocialWork'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'management'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'commonSpaces'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'otherPersonnelCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'compensationLevelDayOne'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'compensationLevelTwoToFourteen'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'compensationLevel15To90'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'compensationLevel91To180'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'compensationLevel181To365'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisPostModel
     */
    'monday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisPostModel
     */
    'tuesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisPostModel
     */
    'wednesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisPostModel
     */
    'thursday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisPostModel
     */
    'friday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisPostModel
     */
    'saturday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisPostModel
     */
    'sunday'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisPostModel
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface CostCalculationBasisViewModel
 */
export interface CostCalculationBasisViewModel {
    /**
     * 
     * @type {string}
     * @memberof CostCalculationBasisViewModel
     */
    'employmentGroupName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'employmentGroupId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CostCalculationBasisViewModel
     */
    'employmentGroupIdentifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'monthlySalaryAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'holidayCompensation'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'socialSecurityContribution'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'hoursDayAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'overheadCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'personnelAndFinanceAdministration'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'occupationalHealthAndWellness'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'unionWork'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'workEnvironment'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'personnelSocialWork'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'management'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'commonSpaces'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'otherPersonnelCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'compensationLevelDayOne'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'compensationLevelTwoToFourteen'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'compensationLevel15To90'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'compensationLevel91To180'?: number;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'compensationLevel181To365'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisViewModel
     */
    'monday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisViewModel
     */
    'tuesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisViewModel
     */
    'wednesday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisViewModel
     */
    'thursday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisViewModel
     */
    'friday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisViewModel
     */
    'saturday'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CostCalculationBasisViewModel
     */
    'sunday'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CostCalculationBasisViewModel
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface CostCalculationRequest
 */
export interface CostCalculationRequest {
    /**
     * 
     * @type {number}
     * @memberof CostCalculationRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<CostCalucationModel>}
     * @memberof CostCalculationRequest
     */
    'costCalculation'?: Array<CostCalucationModel> | null;
    /**
     * 
     * @type {string}
     * @memberof CostCalculationRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CostCalculationRequest
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface CostCalucationModel
 */
export interface CostCalucationModel {
    /**
     * 
     * @type {string}
     * @memberof CostCalucationModel
     */
    'employmentGroup'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CostCalucationModel
     */
    'totalDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof CostCalucationModel
     */
    'intervalPeriod'?: string | null;
}
/**
 * 
 * @export
 * @interface DeleteSubscriptionRequest
 */
export interface DeleteSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteSubscriptionRequest
     */
    'receiver'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeleteSubscriptionRequest
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeleteSubscriptionRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteSubscriptionRequest
     */
    'scope'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeleteSubscriptionRequest
     */
    'targetId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteSubscriptionRequest
     */
    'isRoleSubscription'?: boolean;
}
/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'parentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'referenceKey'?: string;
}
/**
 * 
 * @export
 * @interface DepartmentAbsenceUpdateModel
 */
export interface DepartmentAbsenceUpdateModel {
    /**
     * 
     * @type {number}
     * @memberof DepartmentAbsenceUpdateModel
     */
    'employmentAbsenceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentAbsenceUpdateModel
     */
    'departmentAbsenceId'?: number;
    /**
     * 
     * @type {AbsenceCause}
     * @memberof DepartmentAbsenceUpdateModel
     */
    'absenceCause'?: AbsenceCause;
    /**
     * 
     * @type {number}
     * @memberof DepartmentAbsenceUpdateModel
     */
    'performedByUserAccountId'?: number;
}


/**
 * 
 * @export
 * @interface DepartmentRecursiveModel
 */
export interface DepartmentRecursiveModel {
    /**
     * 
     * @type {number}
     * @memberof DepartmentRecursiveModel
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DepartmentRecursiveModel
     */
    'recursive'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviantAbsenceSearchFilter
 */
export interface DeviantAbsenceSearchFilter {
    /**
     * 
     * @type {AbsenceTypeFlags}
     * @memberof DeviantAbsenceSearchFilter
     */
    'absenceTypes'?: AbsenceTypeFlags;
    /**
     * 
     * @type {AbsenceEventTypeFlags}
     * @memberof DeviantAbsenceSearchFilter
     */
    'absenceEventTypes'?: AbsenceEventTypeFlags;
    /**
     * 
     * @type {number}
     * @memberof DeviantAbsenceSearchFilter
     */
    'employmentId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeviantAbsenceSearchFilter
     */
    'employmentIds'?: Array<number> | null;
    /**
     * 
     * @type {AbsenceTranslation}
     * @memberof DeviantAbsenceSearchFilter
     */
    'translation'?: AbsenceTranslation | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeviantAbsenceSearchFilter
     */
    'includeHistoricalDepartments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeviantAbsenceSearchFilter
     */
    'ignoreDepartmentFilter'?: boolean;
    /**
     * 
     * @type {AbsencePageRequestType}
     * @memberof DeviantAbsenceSearchFilter
     */
    'requestType'?: AbsencePageRequestType;
    /**
     * 
     * @type {AbsenceSearchResultGrouping}
     * @memberof DeviantAbsenceSearchFilter
     */
    'grouping'?: AbsenceSearchResultGrouping;
    /**
     * 
     * @type {AbsenceSearchResultSorting}
     * @memberof DeviantAbsenceSearchFilter
     */
    'sorting'?: AbsenceSearchResultSorting;
    /**
     * 
     * @type {AbsencePeriodStatusFlags}
     * @memberof DeviantAbsenceSearchFilter
     */
    'status'?: AbsencePeriodStatusFlags;
    /**
     * 
     * @type {string}
     * @memberof DeviantAbsenceSearchFilter
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviantAbsenceSearchFilter
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeviantAbsenceSearchFilter
     */
    'minLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviantAbsenceSearchFilter
     */
    'maxLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeviantAbsenceSearchFilter
     */
    'minNoOfOccurrences'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeviantAbsenceSearchFilter
     */
    'departments'?: Array<number> | null;
    /**
     * 
     * @type {Array<DepartmentRecursiveModel>}
     * @memberof DeviantAbsenceSearchFilter
     */
    'departmentWithRecursive'?: Array<DepartmentRecursiveModel> | null;
    /**
     * 
     * @type {string}
     * @memberof DeviantAbsenceSearchFilter
     */
    'timeZone'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeviantAbsenceSearchFilter
     */
    'companies'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof DeviantAbsenceSearchFilter
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviantAbsenceSearchFilter
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviantAbsenceSearchFilter
     */
    'sortOrder'?: string | null;
}


/**
 * 
 * @export
 * @interface EmployeeNamesContract
 */
export interface EmployeeNamesContract {
    /**
     * 
     * @type {string}
     * @memberof EmployeeNamesContract
     */
    'referenceKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeNamesContract
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeNamesContract
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface EmployeeSearchFilter
 */
export interface EmployeeSearchFilter {
    /**
     * 
     * @type {Array<number>}
     * @memberof EmployeeSearchFilter
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EmployeeSearchFilter
     */
    'departmentIds'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeSearchFilter
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeSearchFilter
     */
    'pageNumber'?: number;
}
/**
 * 
 * @export
 * @interface Employment
 */
export interface Employment {
    /**
     * 
     * @type {number}
     * @memberof Employment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Employment
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Employment
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {Employment}
     * @memberof Employment
     */
    'mainManager'?: Employment | null;
    /**
     * 
     * @type {Array<Department>}
     * @memberof Employment
     */
    'departments'?: Array<Department> | null;
    /**
     * 
     * @type {Array<EmploymentGroup>}
     * @memberof Employment
     */
    'employmentGroups'?: Array<EmploymentGroup> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Employment
     */
    'inactive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Employment
     */
    'referenceKey'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const EmploymentAbsenceFilter = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type EmploymentAbsenceFilter = typeof EmploymentAbsenceFilter[keyof typeof EmploymentAbsenceFilter];


/**
 * 
 * @export
 * @interface EmploymentAbsenceRetroactiveModel
 */
export interface EmploymentAbsenceRetroactiveModel {
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'backAtWork'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'performedByUserAccountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'suppressNotification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'suppressSocialInsuranceReporting'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'absenceDegree'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'workRelated'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'workPlaceAccident'?: boolean | null;
    /**
     * 
     * @type {AbsenceType}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'absenceType'?: AbsenceType;
    /**
     * 
     * @type {AbsenceCause}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'absenceCause'?: AbsenceCause;
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'contactReason'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'confidentialAbsenceCause'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'childBirthDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'departmentIds'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'absenceOccasionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'costCenter'?: string | null;
    /**
     * 
     * @type {Array<EmploymentGroup>}
     * @memberof EmploymentAbsenceRetroactiveModel
     */
    'employmentGropus'?: Array<EmploymentGroup> | null;
}


/**
 * 
 * @export
 * @interface EmploymentAbsenceUpdateModel
 */
export interface EmploymentAbsenceUpdateModel {
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceUpdateModel
     */
    'employmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceUpdateModel
     */
    'employmentAbsenceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentAbsenceUpdateModel
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentAbsenceUpdateModel
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EmploymentAbsenceUpdateModel
     */
    'backAtWork'?: string;
    /**
     * 
     * @type {number}
     * @memberof EmploymentAbsenceUpdateModel
     */
    'performedByUserAccountId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentAbsenceUpdateModel
     */
    'suppressNotification'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentAbsenceUpdateModel
     */
    'suppressSocialInsuranceReporting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentAbsenceUpdateModel
     */
    'timeInReportDatesRequired'?: boolean;
}
/**
 * 
 * @export
 * @interface EmploymentEraseSettings
 */
export interface EmploymentEraseSettings {
    /**
     * 
     * @type {number}
     * @memberof EmploymentEraseSettings
     */
    'employmentId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EmploymentEraseSettings
     */
    'doNotEraseEmployment'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmploymentEraseSettings
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmploymentEraseSettings
     */
    'performedByUserAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentEraseSettings
     */
    'doNotDeleteText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentEraseSettings
     */
    'performedByName'?: string | null;
}
/**
 * 
 * @export
 * @interface EmploymentGetModel
 */
export interface EmploymentGetModel {
    /**
     * 
     * @type {Array<Employment>}
     * @memberof EmploymentGetModel
     */
    'employments'?: Array<Employment> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EmploymentGetModel
     */
    'employmentIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface EmploymentGroup
 */
export interface EmploymentGroup {
    /**
     * 
     * @type {number}
     * @memberof EmploymentGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EmploymentGroup
     */
    'companyId'?: number | null;
}
/**
 * 
 * @export
 * @interface EmploymentGroupContract
 */
export interface EmploymentGroupContract {
    /**
     * 
     * @type {number}
     * @memberof EmploymentGroupContract
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof EmploymentGroupContract
     */
    'nameIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentGroupContract
     */
    'nameString'?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof EmploymentGroupContract
     */
    'company'?: Company | null;
}
/**
 * 
 * @export
 * @interface EmploymentGroupsPostModel
 */
export interface EmploymentGroupsPostModel {
    /**
     * 
     * @type {number}
     * @memberof EmploymentGroupsPostModel
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EmploymentGroupsPostModel
     */
    'companyIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface EventSubscription
 */
export interface EventSubscription {
    /**
     * 
     * @type {number}
     * @memberof EventSubscription
     */
    'eventSubscriptionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventSubscription
     */
    'subscribingUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'contactKey'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof EventSubscription
     */
    'monitoredId'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {Channel}
     * @memberof EventSubscription
     */
    'channel'?: Channel;
    /**
     * 
     * @type {SubscriptionMonitoringType}
     * @memberof EventSubscription
     */
    'monitoringType'?: SubscriptionMonitoringType;
    /**
     * 
     * @type {SubscriptionTypeModel}
     * @memberof EventSubscription
     */
    'subscriptionType'?: SubscriptionTypeModel | null;
    /**
     * 
     * @type {number}
     * @memberof EventSubscription
     */
    'monitoredDepartmentId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EventSubscription
     */
    'monitoredCompanyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'monitoredDepartmentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'monitoredCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscription
     */
    'targetId'?: string;
}


/**
 * 
 * @export
 * @interface EventSubscriptionHistorySearchFilter
 */
export interface EventSubscriptionHistorySearchFilter {
    /**
     * 
     * @type {number}
     * @memberof EventSubscriptionHistorySearchFilter
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventSubscriptionHistorySearchFilter
     */
    'subscribingUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscriptionHistorySearchFilter
     */
    'contactKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscriptionHistorySearchFilter
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSubscriptionHistorySearchFilter
     */
    'toDate'?: string;
}
/**
 * 
 * @export
 * @interface EventSubscriptionPostItem
 */
export interface EventSubscriptionPostItem {
    /**
     * 
     * @type {number}
     * @memberof EventSubscriptionPostItem
     */
    'eventSubscriptionId'?: number;
    /**
     * 
     * @type {Channel}
     * @memberof EventSubscriptionPostItem
     */
    'channel'?: Channel;
    /**
     * 
     * @type {number}
     * @memberof EventSubscriptionPostItem
     */
    'subscriptionTypeId'?: number;
}


/**
 * 
 * @export
 * @interface EventSubscriptionPostModel
 */
export interface EventSubscriptionPostModel {
    /**
     * 
     * @type {string}
     * @memberof EventSubscriptionPostModel
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof EventSubscriptionPostModel
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {MonitoringType}
     * @memberof EventSubscriptionPostModel
     */
    'monitoringType'?: MonitoringType;
    /**
     * 
     * @type {Array<number>}
     * @memberof EventSubscriptionPostModel
     */
    'monitoredIds'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof EventSubscriptionPostModel
     */
    'subscribingUserAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscriptionPostModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscriptionPostModel
     */
    'oldName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EventSubscriptionPostModel
     */
    'contactKey'?: string | null;
    /**
     * 
     * @type {Array<EventSubscriptionPostItem>}
     * @memberof EventSubscriptionPostModel
     */
    'eventSubscriptionPostItems'?: Array<EventSubscriptionPostItem> | null;
    /**
     * 
     * @type {number}
     * @memberof EventSubscriptionPostModel
     */
    'performedByUserAccountId'?: number | null;
}


/**
 * 
 * @export
 * @interface ExportAbsenceHistory
 */
export interface ExportAbsenceHistory {
    /**
     * 
     * @type {number}
     * @memberof ExportAbsenceHistory
     */
    'months'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExportAbsenceHistory
     */
    'employmentId'?: number;
    /**
     * 
     * @type {AbsenceTranslation}
     * @memberof ExportAbsenceHistory
     */
    'translation'?: AbsenceTranslation | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExportSubscriber = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type ExportSubscriber = typeof ExportSubscriber[keyof typeof ExportSubscriber];


/**
 * 
 * @export
 * @interface ExportSubscriptionModel
 */
export interface ExportSubscriptionModel {
    /**
     * 
     * @type {number}
     * @memberof ExportSubscriptionModel
     */
    'id'?: number;
    /**
     * 
     * @type {SubscriptionLookupType}
     * @memberof ExportSubscriptionModel
     */
    'lookupType'?: SubscriptionLookupType;
    /**
     * 
     * @type {ExportSubscriber}
     * @memberof ExportSubscriptionModel
     */
    'exportType'?: ExportSubscriber;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportSubscriptionModel
     */
    'translations'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExportSubscriptionModel
     */
    'includedChildDepartments'?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ExportSubscriptionModel
     */
    'departmentIds'?: Array<number> | null;
}


/**
 * 
 * @export
 * @interface ExternalSubscriberUpdateDataPostModel
 */
export interface ExternalSubscriberUpdateDataPostModel {
    /**
     * 
     * @type {string}
     * @memberof ExternalSubscriberUpdateDataPostModel
     */
    'oldName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalSubscriberUpdateDataPostModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalSubscriberUpdateDataPostModel
     */
    'contactKey'?: string | null;
}
/**
 * 
 * @export
 * @interface ExternalSubscriptionCompanyDataModel
 */
export interface ExternalSubscriptionCompanyDataModel {
    /**
     * 
     * @type {number}
     * @memberof ExternalSubscriptionCompanyDataModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalSubscriptionCompanyDataModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalSubscriptionCompanyDataModel
     */
    'contactKey'?: string | null;
}
/**
 * 
 * @export
 * @interface IdName
 */
export interface IdName {
    /**
     * 
     * @type {string}
     * @memberof IdName
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdName
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface IdNameMap
 */
export interface IdNameMap {
    /**
     * 
     * @type {number}
     * @memberof IdNameMap
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdNameMap
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface MainManager
 */
export interface MainManager {
    /**
     * 
     * @type {string}
     * @memberof MainManager
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MainManager
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {ContactInformation}
     * @memberof MainManager
     */
    'contactInformation'?: ContactInformation | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MonitoringType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type MonitoringType = typeof MonitoringType[keyof typeof MonitoringType];


/**
 * 
 * @export
 * @interface OrganizationEventModel
 */
export interface OrganizationEventModel {
    /**
     * 
     * @type {number}
     * @memberof OrganizationEventModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationEventModel
     */
    'employmentId'?: number | null;
    /**
     * 
     * @type {CatalogEntryContract}
     * @memberof OrganizationEventModel
     */
    'employment'?: CatalogEntryContract | null;
    /**
     * 
     * @type {Company}
     * @memberof OrganizationEventModel
     */
    'employmentCompany'?: Company | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationEventModel
     */
    'departmentId'?: number | null;
    /**
     * 
     * @type {Department}
     * @memberof OrganizationEventModel
     */
    'department'?: Department | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEventModel
     */
    'organizationEventType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEventModel
     */
    'organizationEventTypeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEventModel
     */
    'eventDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEventModel
     */
    'effectiveDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationEventModel
     */
    'performedByUserAccountId'?: number | null;
    /**
     * 
     * @type {UserAccountLite}
     * @memberof OrganizationEventModel
     */
    'performedBy'?: UserAccountLite | null;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEventModel
     */
    'performedByCompanyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrganizationEventModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationEventModel
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PeriodEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type PeriodEnum = typeof PeriodEnum[keyof typeof PeriodEnum];


/**
 * 
 * @export
 * @interface RemoveSubscriptions
 */
export interface RemoveSubscriptions {
    /**
     * 
     * @type {number}
     * @memberof RemoveSubscriptions
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<DeleteSubscriptionRequest>}
     * @memberof RemoveSubscriptions
     */
    'subscriptions'?: Array<DeleteSubscriptionRequest> | null;
}
/**
 * 
 * @export
 * @interface ReportEmploymentContract
 */
export interface ReportEmploymentContract {
    /**
     * 
     * @type {number}
     * @memberof ReportEmploymentContract
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportEmploymentContract
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportEmploymentContract
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportEmploymentContract
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportEmploymentContract
     */
    'companyId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportEmploymentContract
     */
    'departmentNames'?: Array<string> | null;
    /**
     * 
     * @type {Array<Department>}
     * @memberof ReportEmploymentContract
     */
    'departments'?: Array<Department> | null;
    /**
     * 
     * @type {string}
     * @memberof ReportEmploymentContract
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportEmploymentContract
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportEmploymentContract
     */
    'inActive'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportEmploymentContract
     */
    'archived'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReportEmploymentContract
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportEmploymentContract
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportEmploymentContract
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportEmploymentContract
     */
    'referenceKey'?: string;
}
/**
 * 
 * @export
 * @interface ReportEmploymentContractPage
 */
export interface ReportEmploymentContractPage {
    /**
     * 
     * @type {Array<ReportEmploymentContract>}
     * @memberof ReportEmploymentContractPage
     */
    'reportEmploymentContracts'?: Array<ReportEmploymentContract> | null;
    /**
     * 
     * @type {number}
     * @memberof ReportEmploymentContractPage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportEmploymentContractPage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportEmploymentContractPage
     */
    'totalCount'?: number;
}
/**
 * 
 * @export
 * @interface SearchDepartmentModel
 */
export interface SearchDepartmentModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchDepartmentModel
     */
    'departmentIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface SearchOverviewModel
 */
export interface SearchOverviewModel {
    /**
     * 
     * @type {EmploymentAbsenceFilter}
     * @memberof SearchOverviewModel
     */
    'employmentAbsenceFilter'?: EmploymentAbsenceFilter;
    /**
     * 
     * @type {AbsenceType}
     * @memberof SearchOverviewModel
     */
    'absenceType'?: AbsenceType;
    /**
     * 
     * @type {string}
     * @memberof SearchOverviewModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchOverviewModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchOverviewModel
     */
    'departmentIds'?: Array<number> | null;
}


/**
 * 
 * @export
 * @interface SearchUserAndDepartmentToAddModel
 */
export interface SearchUserAndDepartmentToAddModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchUserAndDepartmentToAddModel
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchUserAndDepartmentToAddModel
     */
    'searchString'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchUserAndDepartmentToAddModel
     */
    'includeExternal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchUserAndDepartmentToAddModel
     */
    'includeCompany'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchUserAndDepartmentToAddModel
     */
    'includeDepartments'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchUserAndDepartmentToAddModel
     */
    'searchNoneEmployeeUsers'?: boolean;
}
/**
 * 
 * @export
 * @interface StatisticsDepartmentModel
 */
export interface StatisticsDepartmentModel {
    /**
     * 
     * @type {number}
     * @memberof StatisticsDepartmentModel
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsDepartmentModel
     */
    'recursive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsDepartmentModel
     */
    'topDepartment'?: boolean;
}
/**
 * 
 * @export
 * @interface StatisticsReportBody
 */
export interface StatisticsReportBody {
    /**
     * 
     * @type {CompareOperation}
     * @memberof StatisticsReportBody
     */
    'compareOperation'?: CompareOperation;
    /**
     * 
     * @type {StatisticsReportType}
     * @memberof StatisticsReportBody
     */
    'statisticsReportType'?: StatisticsReportType;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatisticsReportBody
     */
    'gender'?: Array<string> | null;
    /**
     * 
     * @type {Array<AbsenceCause>}
     * @memberof StatisticsReportBody
     */
    'absenceCause'?: Array<AbsenceCause> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsReportBody
     */
    'areAllDepartmentsSelected'?: boolean;
    /**
     * 
     * @type {AbsenceType}
     * @memberof StatisticsReportBody
     */
    'absenceType'?: AbsenceType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StatisticsReportBody
     */
    'texts'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatisticsReportBody
     */
    'xAxisTitles'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'valueTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBody
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof StatisticsReportBody
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<StatisticsDepartmentModel>}
     * @memberof StatisticsReportBody
     */
    'departments'?: Array<StatisticsDepartmentModel> | null;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'employmentGroupId'?: number | null;
    /**
     * 
     * @type {PeriodEnum}
     * @memberof StatisticsReportBody
     */
    'period'?: PeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'workingDays'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsReportBody
     */
    'includeHistoricalDepartments'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof StatisticsReportBody
     */
    'departmentsForAverigePercentPerCompanies'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsReportBody
     */
    'calculateOnCompanyLevel'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBody
     */
    'maxDepartmentBarsOnTopLevel'?: number | null;
}


/**
 * 
 * @export
 * @interface StatisticsReportBodyMultiCompare
 */
export interface StatisticsReportBodyMultiCompare {
    /**
     * 
     * @type {Array<CompareOperation>}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'compareOperations'?: Array<CompareOperation> | null;
    /**
     * 
     * @type {CompareOperation}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'compareOperation'?: CompareOperation;
    /**
     * 
     * @type {StatisticsReportType}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'statisticsReportType'?: StatisticsReportType;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'gender'?: Array<string> | null;
    /**
     * 
     * @type {Array<AbsenceCause>}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'absenceCause'?: Array<AbsenceCause> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'areAllDepartmentsSelected'?: boolean;
    /**
     * 
     * @type {AbsenceType}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'absenceType'?: AbsenceType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'texts'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'xAxisTitles'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'valueTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<StatisticsDepartmentModel>}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'departments'?: Array<StatisticsDepartmentModel> | null;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'employmentGroupId'?: number | null;
    /**
     * 
     * @type {PeriodEnum}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'period'?: PeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'workingDays'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'includeHistoricalDepartments'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'departmentsForAverigePercentPerCompanies'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'calculateOnCompanyLevel'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof StatisticsReportBodyMultiCompare
     */
    'maxDepartmentBarsOnTopLevel'?: number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const StatisticsReportType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19
} as const;

export type StatisticsReportType = typeof StatisticsReportType[keyof typeof StatisticsReportType];


/**
 * 
 * @export
 * @interface SubscriberTableItem
 */
export interface SubscriberTableItem {
    /**
     * 
     * @type {boolean}
     * @memberof SubscriberTableItem
     */
    'sick'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriberTableItem
     */
    'careOfChild'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriberTableItem
     */
    'deviant'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriberTableItem
     */
    'rehab'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriberTableItem
     */
    'retroactivelyEditedAbsence'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriberTableItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriberTableItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriberTableItem
     */
    'contactKey'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriberTableItem
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriberTableItem
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriberTableItem
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriberTableItem
     */
    'inActive'?: boolean;
    /**
     * 
     * @type {SubscriptionMonitoringType}
     * @memberof SubscriberTableItem
     */
    'monitoringType'?: SubscriptionMonitoringType;
    /**
     * 
     * @type {Array<SubscriptionInformation>}
     * @memberof SubscriberTableItem
     */
    'directSubscriptionInformation'?: Array<SubscriptionInformation> | null;
    /**
     * 
     * @type {Array<SubscriptionInformation>}
     * @memberof SubscriberTableItem
     */
    'indirectSubscriptionInformation'?: Array<SubscriptionInformation> | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberTableItem
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriberTableItem
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriberTableItem
     */
    'departmentName'?: string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionInformation
 */
export interface SubscriptionInformation {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInformation
     */
    'notificationEventType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInformation
     */
    'notificationEventTypeKey'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInformation
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInformation
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {Array<SubscriptionInformationItem>}
     * @memberof SubscriptionInformation
     */
    'subscriptionInformationItems'?: Array<SubscriptionInformationItem> | null;
}
/**
 * 
 * @export
 * @interface SubscriptionInformationItem
 */
export interface SubscriptionInformationItem {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInformationItem
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInformationItem
     */
    'channel'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInformationItem
     */
    'subscriptionTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInformationItem
     */
    'receiver'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInformationItem
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInformationItem
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionInformationItem
     */
    'scope'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionInformationItem
     */
    'targetId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionInformationItem
     */
    'isRoleSubscription'?: boolean;
}
/**
 * 
 * @export
 * @interface SubscriptionLookup
 */
export interface SubscriptionLookup {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLookup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLookup
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionLookup
     */
    'text'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionLookup
     */
    'tags'?: Array<string> | null;
    /**
     * 
     * @type {SubscriptionLookupType}
     * @memberof SubscriptionLookup
     */
    'lookupType'?: SubscriptionLookupType;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionLookup
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {Company}
     * @memberof SubscriptionLookup
     */
    'company'?: Company | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionLookupType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type SubscriptionLookupType = typeof SubscriptionLookupType[keyof typeof SubscriptionLookupType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SubscriptionMonitoringType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type SubscriptionMonitoringType = typeof SubscriptionMonitoringType[keyof typeof SubscriptionMonitoringType];


/**
 * 
 * @export
 * @interface SubscriptionTableItem
 */
export interface SubscriptionTableItem {
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionTableItem
     */
    'sick'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionTableItem
     */
    'careOfChild'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionTableItem
     */
    'deviant'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionTableItem
     */
    'rehab'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionTableItem
     */
    'retroactivelyEditedAbsence'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTableItem
     */
    'monitoredCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTableItem
     */
    'monitoredDepartmentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTableItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubscriptionTableItem
     */
    'contactKey'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTableItem
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTableItem
     */
    'employmentId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTableItem
     */
    'activationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTableItem
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionTableItem
     */
    'inActive'?: boolean;
    /**
     * 
     * @type {SubscriptionMonitoringType}
     * @memberof SubscriptionTableItem
     */
    'monitoringType'?: SubscriptionMonitoringType;
    /**
     * 
     * @type {Channel}
     * @memberof SubscriptionTableItem
     */
    'channel'?: Channel;
    /**
     * 
     * @type {Array<SubscriptionInformation>}
     * @memberof SubscriptionTableItem
     */
    'directSubscriptionInformation'?: Array<SubscriptionInformation> | null;
    /**
     * 
     * @type {Array<SubscriptionInformation>}
     * @memberof SubscriptionTableItem
     */
    'indirectSubscriptionInformation'?: Array<SubscriptionInformation> | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTableItem
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTableItem
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTableItem
     */
    'departmentName'?: string | null;
}


/**
 * 
 * @export
 * @interface SubscriptionType
 */
export interface SubscriptionType {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionType
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionType
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionType
     */
    'notificationEventType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionType
     */
    'notificationEventTypeKey'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionType
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionType
     */
    'allowSms'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionType
     */
    'preselected'?: boolean | null;
}
/**
 * 
 * @export
 * @interface SubscriptionTypeModel
 */
export interface SubscriptionTypeModel {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTypeModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTypeModel
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTypeModel
     */
    'notificationEventType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionTypeModel
     */
    'notificationEventTypeKey'?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionTypeModel
     */
    'eventCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionTypeModel
     */
    'allowSms'?: boolean;
}
/**
 * 
 * @export
 * @interface TreeViewNode
 */
export interface TreeViewNode {
    /**
     * 
     * @type {number}
     * @memberof TreeViewNode
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TreeViewNode
     */
    'referenceKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TreeViewNode
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreeViewNode
     */
    'icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TreeViewNode
     */
    'type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TreeViewNode
     */
    'selectable'?: boolean;
    /**
     * 
     * @type {Array<TreeViewNode>}
     * @memberof TreeViewNode
     */
    'children'?: Array<TreeViewNode> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TreeViewNode
     */
    'top'?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {ContactInformation}
     * @memberof User
     */
    'contactInformation'?: ContactInformation | null;
}
/**
 * 
 * @export
 * @interface UserAccountLite
 */
export interface UserAccountLite {
    /**
     * 
     * @type {number}
     * @memberof UserAccountLite
     */
    'id'?: number;
    /**
     * 
     * @type {User}
     * @memberof UserAccountLite
     */
    'user'?: User | null;
    /**
     * 
     * @type {number}
     * @memberof UserAccountLite
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserAccountLite
     */
    'tenant'?: number;
    /**
     * 
     * @type {IdNameMap}
     * @memberof UserAccountLite
     */
    'retailer'?: IdNameMap | null;
    /**
     * 
     * @type {number}
     * @memberof UserAccountLite
     */
    'customerId'?: number;
}
/**
 * 
 * @export
 * @interface UserEmployment
 */
export interface UserEmployment {
    /**
     * 
     * @type {number}
     * @memberof UserEmployment
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserEmployment
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'name'?: string | null;
    /**
     * 
     * @type {MainManager}
     * @memberof UserEmployment
     */
    'mainManager'?: MainManager | null;
    /**
     * 
     * @type {number}
     * @memberof UserEmployment
     */
    'employmentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {Array<Department>}
     * @memberof UserEmployment
     */
    'departments'?: Array<Department> | null;
    /**
     * 
     * @type {ContactInformation}
     * @memberof UserEmployment
     */
    'contactInformation'?: ContactInformation | null;
    /**
     * 
     * @type {Array<EventSubscription>}
     * @memberof UserEmployment
     */
    'eventSubscriptions'?: Array<EventSubscription> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserEmployment
     */
    'inActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserEmployment
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserEmployment
     */
    'doNotEraseEmployment'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'doNotDeleteMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserEmployment
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface WorkRelatedSearchFilter
 */
export interface WorkRelatedSearchFilter {
    /**
     * 
     * @type {AbsenceTranslation}
     * @memberof WorkRelatedSearchFilter
     */
    'translation'?: AbsenceTranslation | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkRelatedSearchFilter
     */
    'workRelated'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkRelatedSearchFilter
     */
    'workPlaceAccident'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkRelatedSearchFilter
     */
    'includeHistoricalDepartments'?: boolean | null;
    /**
     * 
     * @type {AbsencePageRequestType}
     * @memberof WorkRelatedSearchFilter
     */
    'requestType'?: AbsencePageRequestType;
    /**
     * 
     * @type {AbsenceSearchResultGrouping}
     * @memberof WorkRelatedSearchFilter
     */
    'grouping'?: AbsenceSearchResultGrouping;
    /**
     * 
     * @type {AbsenceSearchResultSorting}
     * @memberof WorkRelatedSearchFilter
     */
    'sorting'?: AbsenceSearchResultSorting;
    /**
     * 
     * @type {AbsencePeriodStatusFlags}
     * @memberof WorkRelatedSearchFilter
     */
    'status'?: AbsencePeriodStatusFlags;
    /**
     * 
     * @type {string}
     * @memberof WorkRelatedSearchFilter
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WorkRelatedSearchFilter
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WorkRelatedSearchFilter
     */
    'minLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkRelatedSearchFilter
     */
    'maxLength'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof WorkRelatedSearchFilter
     */
    'minNoOfOccurrences'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkRelatedSearchFilter
     */
    'departments'?: Array<number> | null;
    /**
     * 
     * @type {Array<DepartmentRecursiveModel>}
     * @memberof WorkRelatedSearchFilter
     */
    'departmentWithRecursive'?: Array<DepartmentRecursiveModel> | null;
    /**
     * 
     * @type {string}
     * @memberof WorkRelatedSearchFilter
     */
    'timeZone'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkRelatedSearchFilter
     */
    'companies'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof WorkRelatedSearchFilter
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkRelatedSearchFilter
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkRelatedSearchFilter
     */
    'sortOrder'?: string | null;
}



/**
 * AbsenceApi - axios parameter creator
 * @export
 */
export const AbsenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeviantAbsenceSearchFilter} [deviantAbsenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelDeviantOngoingPost: async (deviantAbsenceSearchFilter?: DeviantAbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/ExportExcelDeviantOngoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviantAbsenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelEventPost: async (absenceSearchFilter?: AbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/ExportExcelEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelOngoingPost: async (absenceSearchFilter?: AbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/ExportExcelOngoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelPeriodPost: async (absenceSearchFilter?: AbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/ExportExcelPeriod`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkRelatedSearchFilter} [workRelatedSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelWorkRelatedPost: async (workRelatedSearchFilter?: WorkRelatedSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/ExportExcelWorkRelated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workRelatedSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeviantAbsenceSearchFilter} [deviantAbsenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceDeviantOngoingPost: async (deviantAbsenceSearchFilter?: DeviantAbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/GetAbsenceDeviantOngoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deviantAbsenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceEventsPost: async (absenceSearchFilter?: AbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/GetAbsenceEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceOngoingPost: async (absenceSearchFilter?: AbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/GetAbsenceOngoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsencePeriodsPost: async (absenceSearchFilter?: AbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/GetAbsencePeriods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceReportAttributeConfigurationGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/GetAbsenceReportAttributeConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceReportAttributeConfigurationsForCompaniesPost: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/GetAbsenceReportAttributeConfigurationsForCompanies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WorkRelatedSearchFilter} [workRelatedSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceWorkRelatedPost: async (workRelatedSearchFilter?: WorkRelatedSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/GetAbsenceWorkRelated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workRelatedSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmploymentGroupsPostModel} [employmentGroupsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetEmploymentGroupsPost: async (employmentGroupsPostModel?: EmploymentGroupsPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Absence/GetEmploymentGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentGroupsPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbsenceApi - functional programming interface
 * @export
 */
export const AbsenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AbsenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeviantAbsenceSearchFilter} [deviantAbsenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceExportExcelDeviantOngoingPost(deviantAbsenceSearchFilter?: DeviantAbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceExportExcelDeviantOngoingPost(deviantAbsenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceExportExcelEventPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceExportExcelEventPost(absenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceExportExcelOngoingPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceExportExcelOngoingPost(absenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceExportExcelPeriodPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceExportExcelPeriodPost(absenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WorkRelatedSearchFilter} [workRelatedSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceExportExcelWorkRelatedPost(workRelatedSearchFilter?: WorkRelatedSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceExportExcelWorkRelatedPost(workRelatedSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeviantAbsenceSearchFilter} [deviantAbsenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceGetAbsenceDeviantOngoingPost(deviantAbsenceSearchFilter?: DeviantAbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceGetAbsenceDeviantOngoingPost(deviantAbsenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceGetAbsenceEventsPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceGetAbsenceEventsPost(absenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceGetAbsenceOngoingPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceGetAbsenceOngoingPost(absenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceGetAbsencePeriodsPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceGetAbsencePeriodsPost(absenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceGetAbsenceReportAttributeConfigurationGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceGetAbsenceReportAttributeConfigurationGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceGetAbsenceReportAttributeConfigurationsForCompaniesPost(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceGetAbsenceReportAttributeConfigurationsForCompaniesPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {WorkRelatedSearchFilter} [workRelatedSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceGetAbsenceWorkRelatedPost(workRelatedSearchFilter?: WorkRelatedSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceGetAbsenceWorkRelatedPost(workRelatedSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmploymentGroupsPostModel} [employmentGroupsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAbsenceGetEmploymentGroupsPost(employmentGroupsPostModel?: EmploymentGroupsPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAbsenceGetEmploymentGroupsPost(employmentGroupsPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AbsenceApi - factory interface
 * @export
 */
export const AbsenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AbsenceApiFp(configuration)
    return {
        /**
         * 
         * @param {DeviantAbsenceSearchFilter} [deviantAbsenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelDeviantOngoingPost(deviantAbsenceSearchFilter?: DeviantAbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceExportExcelDeviantOngoingPost(deviantAbsenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelEventPost(absenceSearchFilter?: AbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceExportExcelEventPost(absenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelOngoingPost(absenceSearchFilter?: AbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceExportExcelOngoingPost(absenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelPeriodPost(absenceSearchFilter?: AbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceExportExcelPeriodPost(absenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkRelatedSearchFilter} [workRelatedSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceExportExcelWorkRelatedPost(workRelatedSearchFilter?: WorkRelatedSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceExportExcelWorkRelatedPost(workRelatedSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeviantAbsenceSearchFilter} [deviantAbsenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceDeviantOngoingPost(deviantAbsenceSearchFilter?: DeviantAbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceGetAbsenceDeviantOngoingPost(deviantAbsenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceEventsPost(absenceSearchFilter?: AbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceGetAbsenceEventsPost(absenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceOngoingPost(absenceSearchFilter?: AbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceGetAbsenceOngoingPost(absenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsencePeriodsPost(absenceSearchFilter?: AbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceGetAbsencePeriodsPost(absenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceReportAttributeConfigurationGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceGetAbsenceReportAttributeConfigurationGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceReportAttributeConfigurationsForCompaniesPost(requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceGetAbsenceReportAttributeConfigurationsForCompaniesPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WorkRelatedSearchFilter} [workRelatedSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetAbsenceWorkRelatedPost(workRelatedSearchFilter?: WorkRelatedSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceGetAbsenceWorkRelatedPost(workRelatedSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmploymentGroupsPostModel} [employmentGroupsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAbsenceGetEmploymentGroupsPost(employmentGroupsPostModel?: EmploymentGroupsPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAbsenceGetEmploymentGroupsPost(employmentGroupsPostModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AbsenceApi - object-oriented interface
 * @export
 * @class AbsenceApi
 * @extends {BaseAPI}
 */
export class AbsenceApi extends BaseAPI {
    /**
     * 
     * @param {DeviantAbsenceSearchFilter} [deviantAbsenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceExportExcelDeviantOngoingPost(deviantAbsenceSearchFilter?: DeviantAbsenceSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceExportExcelDeviantOngoingPost(deviantAbsenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceSearchFilter} [absenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceExportExcelEventPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceExportExcelEventPost(absenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceSearchFilter} [absenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceExportExcelOngoingPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceExportExcelOngoingPost(absenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceSearchFilter} [absenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceExportExcelPeriodPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceExportExcelPeriodPost(absenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkRelatedSearchFilter} [workRelatedSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceExportExcelWorkRelatedPost(workRelatedSearchFilter?: WorkRelatedSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceExportExcelWorkRelatedPost(workRelatedSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeviantAbsenceSearchFilter} [deviantAbsenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceGetAbsenceDeviantOngoingPost(deviantAbsenceSearchFilter?: DeviantAbsenceSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceGetAbsenceDeviantOngoingPost(deviantAbsenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceSearchFilter} [absenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceGetAbsenceEventsPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceGetAbsenceEventsPost(absenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceSearchFilter} [absenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceGetAbsenceOngoingPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceGetAbsenceOngoingPost(absenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceSearchFilter} [absenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceGetAbsencePeriodsPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceGetAbsencePeriodsPost(absenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceGetAbsenceReportAttributeConfigurationGet(companyId?: number, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceGetAbsenceReportAttributeConfigurationGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceGetAbsenceReportAttributeConfigurationsForCompaniesPost(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceGetAbsenceReportAttributeConfigurationsForCompaniesPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WorkRelatedSearchFilter} [workRelatedSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceGetAbsenceWorkRelatedPost(workRelatedSearchFilter?: WorkRelatedSearchFilter, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceGetAbsenceWorkRelatedPost(workRelatedSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmploymentGroupsPostModel} [employmentGroupsPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public apiAbsenceGetEmploymentGroupsPost(employmentGroupsPostModel?: EmploymentGroupsPostModel, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).apiAbsenceGetEmploymentGroupsPost(employmentGroupsPostModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CostCalculationBasisApi - axios parameter creator
 * @export
 */
export const CostCalculationBasisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {number} [employmentGroupId] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisCompanyIdDelete: async (companyId: number, employmentGroupId?: number, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiCostCalculationBasisCompanyIdDelete', 'companyId', companyId)
            const localVarPath = `/api/CostCalculationBasis/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentGroupId !== undefined) {
                localVarQueryParameter['employmentGroupId'] = employmentGroupId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisCompanyIdYearGet: async (companyId: number, year: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiCostCalculationBasisCompanyIdYearGet', 'companyId', companyId)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('apiCostCalculationBasisCompanyIdYearGet', 'year', year)
            const localVarPath = `/api/CostCalculationBasis/{companyId}/{year}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CostCalculationRequest} [costCalculationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisGetAbsenceCostsFollowUpPost: async (costCalculationRequest?: CostCalculationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CostCalculationBasis/GetAbsenceCostsFollowUp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(costCalculationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceCostCalculationPostModel} [absenceCostCalculationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisGetAbsenceCostsPost: async (absenceCostCalculationPostModel?: AbsenceCostCalculationPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CostCalculationBasis/GetAbsenceCosts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceCostCalculationPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CostCalculationBasisPostModel} [costCalculationBasisPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisPost: async (costCalculationBasisPostModel?: CostCalculationBasisPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CostCalculationBasis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(costCalculationBasisPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CostCalculationBasisApi - functional programming interface
 * @export
 */
export const CostCalculationBasisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CostCalculationBasisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {number} [employmentGroupId] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCostCalculationBasisCompanyIdDelete(companyId: number, employmentGroupId?: number, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCostCalculationBasisCompanyIdDelete(companyId, employmentGroupId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCostCalculationBasisCompanyIdYearGet(companyId: number, year: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CostCalculationBasisViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCostCalculationBasisCompanyIdYearGet(companyId, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CostCalculationRequest} [costCalculationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCostCalculationBasisGetAbsenceCostsFollowUpPost(costCalculationRequest?: CostCalculationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCostCalculationBasisGetAbsenceCostsFollowUpPost(costCalculationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceCostCalculationPostModel} [absenceCostCalculationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCostCalculationBasisGetAbsenceCostsPost(absenceCostCalculationPostModel?: AbsenceCostCalculationPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCostCalculationBasisGetAbsenceCostsPost(absenceCostCalculationPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CostCalculationBasisPostModel} [costCalculationBasisPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCostCalculationBasisPost(costCalculationBasisPostModel?: CostCalculationBasisPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCostCalculationBasisPost(costCalculationBasisPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CostCalculationBasisApi - factory interface
 * @export
 */
export const CostCalculationBasisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CostCalculationBasisApiFp(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {number} [employmentGroupId] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisCompanyIdDelete(companyId: number, employmentGroupId?: number, year?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiCostCalculationBasisCompanyIdDelete(companyId, employmentGroupId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisCompanyIdYearGet(companyId: number, year: number, options?: any): AxiosPromise<Array<CostCalculationBasisViewModel>> {
            return localVarFp.apiCostCalculationBasisCompanyIdYearGet(companyId, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CostCalculationRequest} [costCalculationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisGetAbsenceCostsFollowUpPost(costCalculationRequest?: CostCalculationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiCostCalculationBasisGetAbsenceCostsFollowUpPost(costCalculationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceCostCalculationPostModel} [absenceCostCalculationPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisGetAbsenceCostsPost(absenceCostCalculationPostModel?: AbsenceCostCalculationPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCostCalculationBasisGetAbsenceCostsPost(absenceCostCalculationPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CostCalculationBasisPostModel} [costCalculationBasisPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCostCalculationBasisPost(costCalculationBasisPostModel?: CostCalculationBasisPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCostCalculationBasisPost(costCalculationBasisPostModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CostCalculationBasisApi - object-oriented interface
 * @export
 * @class CostCalculationBasisApi
 * @extends {BaseAPI}
 */
export class CostCalculationBasisApi extends BaseAPI {
    /**
     * 
     * @param {number} companyId 
     * @param {number} [employmentGroupId] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostCalculationBasisApi
     */
    public apiCostCalculationBasisCompanyIdDelete(companyId: number, employmentGroupId?: number, year?: number, options?: AxiosRequestConfig) {
        return CostCalculationBasisApiFp(this.configuration).apiCostCalculationBasisCompanyIdDelete(companyId, employmentGroupId, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostCalculationBasisApi
     */
    public apiCostCalculationBasisCompanyIdYearGet(companyId: number, year: number, options?: AxiosRequestConfig) {
        return CostCalculationBasisApiFp(this.configuration).apiCostCalculationBasisCompanyIdYearGet(companyId, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CostCalculationRequest} [costCalculationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostCalculationBasisApi
     */
    public apiCostCalculationBasisGetAbsenceCostsFollowUpPost(costCalculationRequest?: CostCalculationRequest, options?: AxiosRequestConfig) {
        return CostCalculationBasisApiFp(this.configuration).apiCostCalculationBasisGetAbsenceCostsFollowUpPost(costCalculationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceCostCalculationPostModel} [absenceCostCalculationPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostCalculationBasisApi
     */
    public apiCostCalculationBasisGetAbsenceCostsPost(absenceCostCalculationPostModel?: AbsenceCostCalculationPostModel, options?: AxiosRequestConfig) {
        return CostCalculationBasisApiFp(this.configuration).apiCostCalculationBasisGetAbsenceCostsPost(absenceCostCalculationPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CostCalculationBasisPostModel} [costCalculationBasisPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CostCalculationBasisApi
     */
    public apiCostCalculationBasisPost(costCalculationBasisPostModel?: CostCalculationBasisPostModel, options?: AxiosRequestConfig) {
        return CostCalculationBasisApiFp(this.configuration).apiCostCalculationBasisPost(costCalculationBasisPostModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EmploymentApi - axios parameter creator
 * @export
 */
export const EmploymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EmploymentAbsenceRetroactiveModel} [employmentAbsenceRetroactiveModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentCreateRetroactiveEmploymentAbsencePut: async (employmentAbsenceRetroactiveModel?: EmploymentAbsenceRetroactiveModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/CreateRetroactiveEmploymentAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentAbsenceRetroactiveModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentAbsenceId] 
         * @param {number} [departmentAbsenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentDeleteDepartmentAbsenceDelete: async (employmentAbsenceId?: number, departmentAbsenceId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/DeleteDepartmentAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentAbsenceId !== undefined) {
                localVarQueryParameter['employmentAbsenceId'] = employmentAbsenceId;
            }

            if (departmentAbsenceId !== undefined) {
                localVarQueryParameter['departmentAbsenceId'] = departmentAbsenceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentAbsenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentDeleteEmploymentAbsenceDelete: async (employmentAbsenceId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/DeleteEmploymentAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentAbsenceId !== undefined) {
                localVarQueryParameter['employmentAbsenceId'] = employmentAbsenceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportAbsenceHistory} [exportAbsenceHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentExportAbsenceHistoryPost: async (exportAbsenceHistory?: ExportAbsenceHistory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/ExportAbsenceHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportAbsenceHistory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [months] 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentExportExcelAbsenceHistoryPost: async (months?: number, absenceSearchFilter?: AbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/ExportExcelAbsenceHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (months !== undefined) {
                localVarQueryParameter['months'] = months;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [absenceReportId] 
         * @param {number} [employmentAbsenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetAbsenceEventLogsGet: async (absenceReportId?: number, employmentAbsenceId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetAbsenceEventLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (absenceReportId !== undefined) {
                localVarQueryParameter['absenceReportId'] = absenceReportId;
            }

            if (employmentAbsenceId !== undefined) {
                localVarQueryParameter['employmentAbsenceId'] = employmentAbsenceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetAbsenceEventsPost: async (absenceSearchFilter?: AbsenceSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetAbsenceEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetAbsenceLogsGet: async (absenceReportId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetAbsenceLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (absenceReportId !== undefined) {
                localVarQueryParameter['absenceReportId'] = absenceReportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetActiveDeviantAbsenceEventsByEmploymentIdGet: async (employmentId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetActiveDeviantAbsenceEventsByEmploymentId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [departmentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetDepartmentAbsencesForDepartmentsGet: async (departmentIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetDepartmentAbsencesForDepartments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentIds) {
                localVarQueryParameter['departmentIds'] = departmentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmployeeNamesPost: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmployeeNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesForAbsenceDegreeViewGet: async (employmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmploymentAbsencesForAbsenceDegreeView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [departmentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesForDepartmentsGet: async (departmentIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmploymentAbsencesForDepartments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentIds) {
                localVarQueryParameter['departmentIds'] = departmentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {number} [months] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesForParameterMonthsGet: async (employmentId?: number, months?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmploymentAbsencesForParameterMonths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }

            if (months !== undefined) {
                localVarQueryParameter['months'] = months;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesForSixMonthsGet: async (employmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmploymentAbsencesForSixMonths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesGet: async (employmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmploymentAbsences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesWithDepartmentDescriptionsByEmploymentIdsGet: async (employmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmploymentAbsencesWithDepartmentDescriptionsByEmploymentIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentDepartmentGet: async (employmentId?: number, empId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmploymentDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }

            if (empId !== undefined) {
                localVarQueryParameter['empId'] = empId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchOverviewModel} [searchOverviewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentOverviewsPost: async (searchOverviewModel?: SearchOverviewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmploymentOverviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchOverviewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentWithDepartmentsGet: async (employmentId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetEmploymentWithDepartments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetMessengerAbsenceLogsGet: async (absenceReportId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetMessengerAbsenceLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (absenceReportId !== undefined) {
                localVarQueryParameter['absenceReportId'] = absenceReportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmploymentGetModel} [employmentGetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetStatisticsEmploymentsPost: async (employmentGetModel?: EmploymentGetModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetStatisticsEmployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentGetModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetUserEmploymentGet: async (employmentId?: number, empId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/GetUserEmployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }

            if (empId !== undefined) {
                localVarQueryParameter['empId'] = empId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmploymentEraseSettings} [employmentEraseSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentSaveEmploymentEraseSettingsPost: async (employmentEraseSettings?: EmploymentEraseSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/SaveEmploymentEraseSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentEraseSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {number} [offset] 
         * @param {Array<AbsenceDegree>} [absenceDegree] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentUpdateAbsenceDegreesPost: async (employmentId?: number, offset?: number, absenceDegree?: Array<AbsenceDegree>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/UpdateAbsenceDegrees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(absenceDegree, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DepartmentAbsenceUpdateModel} [departmentAbsenceUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentUpdateDepartmentAbsencePut: async (departmentAbsenceUpdateModel?: DepartmentAbsenceUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/UpdateDepartmentAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentAbsenceUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmploymentAbsenceUpdateModel} [employmentAbsenceUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentUpdateEmploymentAbsenceDatePut: async (employmentAbsenceUpdateModel?: EmploymentAbsenceUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Employment/UpdateEmploymentAbsenceDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentAbsenceUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmploymentApi - functional programming interface
 * @export
 */
export const EmploymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmploymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EmploymentAbsenceRetroactiveModel} [employmentAbsenceRetroactiveModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentCreateRetroactiveEmploymentAbsencePut(employmentAbsenceRetroactiveModel?: EmploymentAbsenceRetroactiveModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentCreateRetroactiveEmploymentAbsencePut(employmentAbsenceRetroactiveModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentAbsenceId] 
         * @param {number} [departmentAbsenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentDeleteDepartmentAbsenceDelete(employmentAbsenceId?: number, departmentAbsenceId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentDeleteDepartmentAbsenceDelete(employmentAbsenceId, departmentAbsenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentAbsenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentDeleteEmploymentAbsenceDelete(employmentAbsenceId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentDeleteEmploymentAbsenceDelete(employmentAbsenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportAbsenceHistory} [exportAbsenceHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentExportAbsenceHistoryPost(exportAbsenceHistory?: ExportAbsenceHistory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentExportAbsenceHistoryPost(exportAbsenceHistory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [months] 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentExportExcelAbsenceHistoryPost(months?: number, absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentExportExcelAbsenceHistoryPost(months, absenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [absenceReportId] 
         * @param {number} [employmentAbsenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetAbsenceEventLogsGet(absenceReportId?: number, employmentAbsenceId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetAbsenceEventLogsGet(absenceReportId, employmentAbsenceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetAbsenceEventsPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetAbsenceEventsPost(absenceSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetAbsenceLogsGet(absenceReportId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetAbsenceLogsGet(absenceReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetActiveDeviantAbsenceEventsByEmploymentIdGet(employmentId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetActiveDeviantAbsenceEventsByEmploymentIdGet(employmentId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [departmentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetDepartmentAbsencesForDepartmentsGet(departmentIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetDepartmentAbsencesForDepartmentsGet(departmentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmployeeNamesPost(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmployeeNamesContract>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmployeeNamesPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmploymentAbsencesForAbsenceDegreeViewGet(employmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmploymentAbsencesForAbsenceDegreeViewGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [departmentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmploymentAbsencesForDepartmentsGet(departmentIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmploymentAbsencesForDepartmentsGet(departmentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {number} [months] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmploymentAbsencesForParameterMonthsGet(employmentId?: number, months?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmploymentAbsencesForParameterMonthsGet(employmentId, months, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmploymentAbsencesForSixMonthsGet(employmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmploymentAbsencesForSixMonthsGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmploymentAbsencesGet(employmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmploymentAbsencesGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmploymentAbsencesWithDepartmentDescriptionsByEmploymentIdsGet(employmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmploymentAbsencesWithDepartmentDescriptionsByEmploymentIdsGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmploymentDepartmentGet(employmentId?: number, empId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Department>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmploymentDepartmentGet(employmentId, empId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchOverviewModel} [searchOverviewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmploymentOverviewsPost(searchOverviewModel?: SearchOverviewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmploymentOverviewsPost(searchOverviewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetEmploymentWithDepartmentsGet(employmentId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetEmploymentWithDepartmentsGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetMessengerAbsenceLogsGet(absenceReportId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetMessengerAbsenceLogsGet(absenceReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmploymentGetModel} [employmentGetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetStatisticsEmploymentsPost(employmentGetModel?: EmploymentGetModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetStatisticsEmploymentsPost(employmentGetModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentGetUserEmploymentGet(employmentId?: number, empId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserEmployment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentGetUserEmploymentGet(employmentId, empId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmploymentEraseSettings} [employmentEraseSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentSaveEmploymentEraseSettingsPost(employmentEraseSettings?: EmploymentEraseSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentSaveEmploymentEraseSettingsPost(employmentEraseSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {number} [offset] 
         * @param {Array<AbsenceDegree>} [absenceDegree] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentUpdateAbsenceDegreesPost(employmentId?: number, offset?: number, absenceDegree?: Array<AbsenceDegree>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentUpdateAbsenceDegreesPost(employmentId, offset, absenceDegree, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DepartmentAbsenceUpdateModel} [departmentAbsenceUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentUpdateDepartmentAbsencePut(departmentAbsenceUpdateModel?: DepartmentAbsenceUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentUpdateDepartmentAbsencePut(departmentAbsenceUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmploymentAbsenceUpdateModel} [employmentAbsenceUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiEmploymentUpdateEmploymentAbsenceDatePut(employmentAbsenceUpdateModel?: EmploymentAbsenceUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiEmploymentUpdateEmploymentAbsenceDatePut(employmentAbsenceUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmploymentApi - factory interface
 * @export
 */
export const EmploymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmploymentApiFp(configuration)
    return {
        /**
         * 
         * @param {EmploymentAbsenceRetroactiveModel} [employmentAbsenceRetroactiveModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentCreateRetroactiveEmploymentAbsencePut(employmentAbsenceRetroactiveModel?: EmploymentAbsenceRetroactiveModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentCreateRetroactiveEmploymentAbsencePut(employmentAbsenceRetroactiveModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentAbsenceId] 
         * @param {number} [departmentAbsenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentDeleteDepartmentAbsenceDelete(employmentAbsenceId?: number, departmentAbsenceId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentDeleteDepartmentAbsenceDelete(employmentAbsenceId, departmentAbsenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentAbsenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentDeleteEmploymentAbsenceDelete(employmentAbsenceId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentDeleteEmploymentAbsenceDelete(employmentAbsenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportAbsenceHistory} [exportAbsenceHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentExportAbsenceHistoryPost(exportAbsenceHistory?: ExportAbsenceHistory, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentExportAbsenceHistoryPost(exportAbsenceHistory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [months] 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentExportExcelAbsenceHistoryPost(months?: number, absenceSearchFilter?: AbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentExportExcelAbsenceHistoryPost(months, absenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [absenceReportId] 
         * @param {number} [employmentAbsenceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetAbsenceEventLogsGet(absenceReportId?: number, employmentAbsenceId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetAbsenceEventLogsGet(absenceReportId, employmentAbsenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AbsenceSearchFilter} [absenceSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetAbsenceEventsPost(absenceSearchFilter?: AbsenceSearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetAbsenceEventsPost(absenceSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetAbsenceLogsGet(absenceReportId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetAbsenceLogsGet(absenceReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetActiveDeviantAbsenceEventsByEmploymentIdGet(employmentId?: number, companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetActiveDeviantAbsenceEventsByEmploymentIdGet(employmentId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [departmentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetDepartmentAbsencesForDepartmentsGet(departmentIds?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetDepartmentAbsencesForDepartmentsGet(departmentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmployeeNamesPost(requestBody?: Array<string>, options?: any): AxiosPromise<Array<EmployeeNamesContract>> {
            return localVarFp.apiEmploymentGetEmployeeNamesPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesForAbsenceDegreeViewGet(employmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetEmploymentAbsencesForAbsenceDegreeViewGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [departmentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesForDepartmentsGet(departmentIds?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetEmploymentAbsencesForDepartmentsGet(departmentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {number} [months] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesForParameterMonthsGet(employmentId?: number, months?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetEmploymentAbsencesForParameterMonthsGet(employmentId, months, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesForSixMonthsGet(employmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetEmploymentAbsencesForSixMonthsGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesGet(employmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetEmploymentAbsencesGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentAbsencesWithDepartmentDescriptionsByEmploymentIdsGet(employmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetEmploymentAbsencesWithDepartmentDescriptionsByEmploymentIdsGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentDepartmentGet(employmentId?: number, empId?: string, options?: any): AxiosPromise<Array<Department>> {
            return localVarFp.apiEmploymentGetEmploymentDepartmentGet(employmentId, empId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchOverviewModel} [searchOverviewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentOverviewsPost(searchOverviewModel?: SearchOverviewModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetEmploymentOverviewsPost(searchOverviewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetEmploymentWithDepartmentsGet(employmentId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetEmploymentWithDepartmentsGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [absenceReportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetMessengerAbsenceLogsGet(absenceReportId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetMessengerAbsenceLogsGet(absenceReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmploymentGetModel} [employmentGetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetStatisticsEmploymentsPost(employmentGetModel?: EmploymentGetModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentGetStatisticsEmploymentsPost(employmentGetModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {string} [empId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentGetUserEmploymentGet(employmentId?: number, empId?: string, options?: any): AxiosPromise<UserEmployment> {
            return localVarFp.apiEmploymentGetUserEmploymentGet(employmentId, empId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmploymentEraseSettings} [employmentEraseSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentSaveEmploymentEraseSettingsPost(employmentEraseSettings?: EmploymentEraseSettings, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentSaveEmploymentEraseSettingsPost(employmentEraseSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [employmentId] 
         * @param {number} [offset] 
         * @param {Array<AbsenceDegree>} [absenceDegree] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentUpdateAbsenceDegreesPost(employmentId?: number, offset?: number, absenceDegree?: Array<AbsenceDegree>, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentUpdateAbsenceDegreesPost(employmentId, offset, absenceDegree, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentAbsenceUpdateModel} [departmentAbsenceUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentUpdateDepartmentAbsencePut(departmentAbsenceUpdateModel?: DepartmentAbsenceUpdateModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentUpdateDepartmentAbsencePut(departmentAbsenceUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmploymentAbsenceUpdateModel} [employmentAbsenceUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiEmploymentUpdateEmploymentAbsenceDatePut(employmentAbsenceUpdateModel?: EmploymentAbsenceUpdateModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiEmploymentUpdateEmploymentAbsenceDatePut(employmentAbsenceUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmploymentApi - object-oriented interface
 * @export
 * @class EmploymentApi
 * @extends {BaseAPI}
 */
export class EmploymentApi extends BaseAPI {
    /**
     * 
     * @param {EmploymentAbsenceRetroactiveModel} [employmentAbsenceRetroactiveModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentCreateRetroactiveEmploymentAbsencePut(employmentAbsenceRetroactiveModel?: EmploymentAbsenceRetroactiveModel, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentCreateRetroactiveEmploymentAbsencePut(employmentAbsenceRetroactiveModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentAbsenceId] 
     * @param {number} [departmentAbsenceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentDeleteDepartmentAbsenceDelete(employmentAbsenceId?: number, departmentAbsenceId?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentDeleteDepartmentAbsenceDelete(employmentAbsenceId, departmentAbsenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentAbsenceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentDeleteEmploymentAbsenceDelete(employmentAbsenceId?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentDeleteEmploymentAbsenceDelete(employmentAbsenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportAbsenceHistory} [exportAbsenceHistory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentExportAbsenceHistoryPost(exportAbsenceHistory?: ExportAbsenceHistory, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentExportAbsenceHistoryPost(exportAbsenceHistory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [months] 
     * @param {AbsenceSearchFilter} [absenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentExportExcelAbsenceHistoryPost(months?: number, absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentExportExcelAbsenceHistoryPost(months, absenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [absenceReportId] 
     * @param {number} [employmentAbsenceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetAbsenceEventLogsGet(absenceReportId?: number, employmentAbsenceId?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetAbsenceEventLogsGet(absenceReportId, employmentAbsenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AbsenceSearchFilter} [absenceSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetAbsenceEventsPost(absenceSearchFilter?: AbsenceSearchFilter, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetAbsenceEventsPost(absenceSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [absenceReportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetAbsenceLogsGet(absenceReportId?: string, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetAbsenceLogsGet(absenceReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetActiveDeviantAbsenceEventsByEmploymentIdGet(employmentId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetActiveDeviantAbsenceEventsByEmploymentIdGet(employmentId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [departmentIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetDepartmentAbsencesForDepartmentsGet(departmentIds?: Array<number>, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetDepartmentAbsencesForDepartmentsGet(departmentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmployeeNamesPost(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmployeeNamesPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmploymentAbsencesForAbsenceDegreeViewGet(employmentId?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmploymentAbsencesForAbsenceDegreeViewGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [departmentIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmploymentAbsencesForDepartmentsGet(departmentIds?: Array<number>, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmploymentAbsencesForDepartmentsGet(departmentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {number} [months] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmploymentAbsencesForParameterMonthsGet(employmentId?: number, months?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmploymentAbsencesForParameterMonthsGet(employmentId, months, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmploymentAbsencesForSixMonthsGet(employmentId?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmploymentAbsencesForSixMonthsGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmploymentAbsencesGet(employmentId?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmploymentAbsencesGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmploymentAbsencesWithDepartmentDescriptionsByEmploymentIdsGet(employmentId?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmploymentAbsencesWithDepartmentDescriptionsByEmploymentIdsGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {string} [empId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmploymentDepartmentGet(employmentId?: number, empId?: string, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmploymentDepartmentGet(employmentId, empId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchOverviewModel} [searchOverviewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmploymentOverviewsPost(searchOverviewModel?: SearchOverviewModel, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmploymentOverviewsPost(searchOverviewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetEmploymentWithDepartmentsGet(employmentId?: number, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetEmploymentWithDepartmentsGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [absenceReportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetMessengerAbsenceLogsGet(absenceReportId?: string, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetMessengerAbsenceLogsGet(absenceReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmploymentGetModel} [employmentGetModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetStatisticsEmploymentsPost(employmentGetModel?: EmploymentGetModel, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetStatisticsEmploymentsPost(employmentGetModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {string} [empId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentGetUserEmploymentGet(employmentId?: number, empId?: string, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentGetUserEmploymentGet(employmentId, empId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmploymentEraseSettings} [employmentEraseSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentSaveEmploymentEraseSettingsPost(employmentEraseSettings?: EmploymentEraseSettings, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentSaveEmploymentEraseSettingsPost(employmentEraseSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [employmentId] 
     * @param {number} [offset] 
     * @param {Array<AbsenceDegree>} [absenceDegree] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentUpdateAbsenceDegreesPost(employmentId?: number, offset?: number, absenceDegree?: Array<AbsenceDegree>, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentUpdateAbsenceDegreesPost(employmentId, offset, absenceDegree, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentAbsenceUpdateModel} [departmentAbsenceUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentUpdateDepartmentAbsencePut(departmentAbsenceUpdateModel?: DepartmentAbsenceUpdateModel, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentUpdateDepartmentAbsencePut(departmentAbsenceUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmploymentAbsenceUpdateModel} [employmentAbsenceUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public apiEmploymentUpdateEmploymentAbsenceDatePut(employmentAbsenceUpdateModel?: EmploymentAbsenceUpdateModel, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).apiEmploymentUpdateEmploymentAbsenceDatePut(employmentAbsenceUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccountAccessRightSearchFilter} [accountAccessRightSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetAccountAccessChangeEventsPost: async (accountAccessRightSearchFilter?: AccountAccessRightSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetAccountAccessChangeEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountAccessRightSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCompaniesForCustomerSupportEmploymentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetCompaniesForCustomerSupportEmployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCompanyByCompanyIdGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetCompanyByCompanyId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCompanyGroupForCompanyIdGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetCompanyGroupForCompanyId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCompanyGroupWithRegionForCompanyIdGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetCompanyGroupWithRegionForCompanyId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCostCentersForCompanyGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetCostCentersForCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetEmploymentGroupsForCompanyGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetEmploymentGroupsForCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetEmploymentGroupsGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetEmploymentGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmployeeSearchFilter} [employeeSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetEmploymentsByCompanyIdsOrDepartmentIdsPost: async (employeeSearchFilter?: EmployeeSearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetEmploymentsByCompanyIdsOrDepartmentIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeSearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetOrganizationEventsGet: async (companyId?: number, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetOrganizationEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [typeOfSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetOrganizationTreeViewGet: async (companyId?: number, typeOfSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetOrganizationTreeView`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (typeOfSearch !== undefined) {
                localVarQueryParameter['typeOfSearch'] = typeOfSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetStatisticsViewCompaniesForUserGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/GetStatisticsViewCompaniesForUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {boolean} [includeHistory] 
         * @param {number} [companyId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationSearchEmploymentsGet: async (searchString?: string, includeHistory?: boolean, companyId?: number, pageSize?: number, pageNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Organization/SearchEmployments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (includeHistory !== undefined) {
                localVarQueryParameter['includeHistory'] = includeHistory;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AccountAccessRightSearchFilter} [accountAccessRightSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetAccountAccessChangeEventsPost(accountAccessRightSearchFilter?: AccountAccessRightSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountAccessChangeEventModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetAccountAccessChangeEventsPost(accountAccessRightSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetCompaniesForCustomerSupportEmploymentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetCompaniesForCustomerSupportEmploymentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetCompanyByCompanyIdGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetCompanyByCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetCompanyGroupForCompanyIdGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdNameMap>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetCompanyGroupForCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetCompanyGroupWithRegionForCompanyIdGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyRegion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetCompanyGroupWithRegionForCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetCostCentersForCompanyGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetCostCentersForCompanyGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetEmploymentGroupsForCompanyGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmploymentGroupContract>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetEmploymentGroupsForCompanyGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetEmploymentGroupsGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmploymentGroupContract>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetEmploymentGroupsGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmployeeSearchFilter} [employeeSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetEmploymentsByCompanyIdsOrDepartmentIdsPost(employeeSearchFilter?: EmployeeSearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportEmploymentContractPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetEmploymentsByCompanyIdsOrDepartmentIdsPost(employeeSearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetOrganizationEventsGet(companyId?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationEventModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetOrganizationEventsGet(companyId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [typeOfSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetOrganizationTreeViewGet(companyId?: number, typeOfSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TreeViewNode>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetOrganizationTreeViewGet(companyId, typeOfSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationGetStatisticsViewCompaniesForUserGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Company>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationGetStatisticsViewCompaniesForUserGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {boolean} [includeHistory] 
         * @param {number} [companyId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganizationSearchEmploymentsGet(searchString?: string, includeHistory?: boolean, companyId?: number, pageSize?: number, pageNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportEmploymentContractPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganizationSearchEmploymentsGet(searchString, includeHistory, companyId, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * 
         * @param {AccountAccessRightSearchFilter} [accountAccessRightSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetAccountAccessChangeEventsPost(accountAccessRightSearchFilter?: AccountAccessRightSearchFilter, options?: any): AxiosPromise<Array<AccountAccessChangeEventModel>> {
            return localVarFp.apiOrganizationGetAccountAccessChangeEventsPost(accountAccessRightSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCompaniesForCustomerSupportEmploymentGet(options?: any): AxiosPromise<Array<Company>> {
            return localVarFp.apiOrganizationGetCompaniesForCustomerSupportEmploymentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCompanyByCompanyIdGet(companyId?: number, options?: any): AxiosPromise<Company> {
            return localVarFp.apiOrganizationGetCompanyByCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCompanyGroupForCompanyIdGet(companyId?: number, options?: any): AxiosPromise<Array<IdNameMap>> {
            return localVarFp.apiOrganizationGetCompanyGroupForCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCompanyGroupWithRegionForCompanyIdGet(companyId?: number, options?: any): AxiosPromise<Array<CompanyRegion>> {
            return localVarFp.apiOrganizationGetCompanyGroupWithRegionForCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetCostCentersForCompanyGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganizationGetCostCentersForCompanyGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetEmploymentGroupsForCompanyGet(companyId?: number, options?: any): AxiosPromise<Array<EmploymentGroupContract>> {
            return localVarFp.apiOrganizationGetEmploymentGroupsForCompanyGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetEmploymentGroupsGet(companyId?: number, options?: any): AxiosPromise<Array<EmploymentGroupContract>> {
            return localVarFp.apiOrganizationGetEmploymentGroupsGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmployeeSearchFilter} [employeeSearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetEmploymentsByCompanyIdsOrDepartmentIdsPost(employeeSearchFilter?: EmployeeSearchFilter, options?: any): AxiosPromise<ReportEmploymentContractPage> {
            return localVarFp.apiOrganizationGetEmploymentsByCompanyIdsOrDepartmentIdsPost(employeeSearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetOrganizationEventsGet(companyId?: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<OrganizationEventModel>> {
            return localVarFp.apiOrganizationGetOrganizationEventsGet(companyId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [typeOfSearch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetOrganizationTreeViewGet(companyId?: number, typeOfSearch?: string, options?: any): AxiosPromise<Array<TreeViewNode>> {
            return localVarFp.apiOrganizationGetOrganizationTreeViewGet(companyId, typeOfSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationGetStatisticsViewCompaniesForUserGet(companyId?: number, options?: any): AxiosPromise<Array<Company>> {
            return localVarFp.apiOrganizationGetStatisticsViewCompaniesForUserGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [searchString] 
         * @param {boolean} [includeHistory] 
         * @param {number} [companyId] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganizationSearchEmploymentsGet(searchString?: string, includeHistory?: boolean, companyId?: number, pageSize?: number, pageNumber?: number, options?: any): AxiosPromise<ReportEmploymentContractPage> {
            return localVarFp.apiOrganizationSearchEmploymentsGet(searchString, includeHistory, companyId, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @param {AccountAccessRightSearchFilter} [accountAccessRightSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetAccountAccessChangeEventsPost(accountAccessRightSearchFilter?: AccountAccessRightSearchFilter, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetAccountAccessChangeEventsPost(accountAccessRightSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetCompaniesForCustomerSupportEmploymentGet(options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetCompaniesForCustomerSupportEmploymentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetCompanyByCompanyIdGet(companyId?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetCompanyByCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetCompanyGroupForCompanyIdGet(companyId?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetCompanyGroupForCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetCompanyGroupWithRegionForCompanyIdGet(companyId?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetCompanyGroupWithRegionForCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetCostCentersForCompanyGet(companyId?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetCostCentersForCompanyGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetEmploymentGroupsForCompanyGet(companyId?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetEmploymentGroupsForCompanyGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetEmploymentGroupsGet(companyId?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetEmploymentGroupsGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmployeeSearchFilter} [employeeSearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetEmploymentsByCompanyIdsOrDepartmentIdsPost(employeeSearchFilter?: EmployeeSearchFilter, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetEmploymentsByCompanyIdsOrDepartmentIdsPost(employeeSearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetOrganizationEventsGet(companyId?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetOrganizationEventsGet(companyId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [typeOfSearch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetOrganizationTreeViewGet(companyId?: number, typeOfSearch?: string, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetOrganizationTreeViewGet(companyId, typeOfSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationGetStatisticsViewCompaniesForUserGet(companyId?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationGetStatisticsViewCompaniesForUserGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [searchString] 
     * @param {boolean} [includeHistory] 
     * @param {number} [companyId] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public apiOrganizationSearchEmploymentsGet(searchString?: string, includeHistory?: boolean, companyId?: number, pageSize?: number, pageNumber?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).apiOrganizationSearchEmploymentsGet(searchString, includeHistory, companyId, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPingGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiPingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public apiPingGet(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).apiPingGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetAbsenceSummaryReportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Statistics/GetAbsenceSummaryReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EmploymentGroupsPostModel} [employmentGroupsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetEmploymentGroupsPost: async (employmentGroupsPostModel?: EmploymentGroupsPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Statistics/GetEmploymentGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employmentGroupsPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatisticsReportBodyMultiCompare} [statisticsReportBodyMultiCompare] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetExcelReportPost: async (statisticsReportBodyMultiCompare?: StatisticsReportBodyMultiCompare, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Statistics/GetExcelReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statisticsReportBodyMultiCompare, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetStatisticsCostLongTermPost: async (statisticsReportBody?: StatisticsReportBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Statistics/GetStatisticsCostLongTerm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statisticsReportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetStatisticsReportPost: async (statisticsReportBody?: StatisticsReportBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Statistics/GetStatisticsReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statisticsReportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatisticsGetAbsenceSummaryReportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatisticsGetAbsenceSummaryReportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EmploymentGroupsPostModel} [employmentGroupsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatisticsGetEmploymentGroupsPost(employmentGroupsPostModel?: EmploymentGroupsPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatisticsGetEmploymentGroupsPost(employmentGroupsPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StatisticsReportBodyMultiCompare} [statisticsReportBodyMultiCompare] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatisticsGetExcelReportPost(statisticsReportBodyMultiCompare?: StatisticsReportBodyMultiCompare, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatisticsGetExcelReportPost(statisticsReportBodyMultiCompare, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatisticsGetStatisticsCostLongTermPost(statisticsReportBody?: StatisticsReportBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatisticsGetStatisticsCostLongTermPost(statisticsReportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatisticsGetStatisticsReportPost(statisticsReportBody?: StatisticsReportBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatisticsGetStatisticsReportPost(statisticsReportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetAbsenceSummaryReportGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatisticsGetAbsenceSummaryReportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmploymentGroupsPostModel} [employmentGroupsPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetEmploymentGroupsPost(employmentGroupsPostModel?: EmploymentGroupsPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiStatisticsGetEmploymentGroupsPost(employmentGroupsPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StatisticsReportBodyMultiCompare} [statisticsReportBodyMultiCompare] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetExcelReportPost(statisticsReportBodyMultiCompare?: StatisticsReportBodyMultiCompare, options?: any): AxiosPromise<void> {
            return localVarFp.apiStatisticsGetExcelReportPost(statisticsReportBodyMultiCompare, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetStatisticsCostLongTermPost(statisticsReportBody?: StatisticsReportBody, options?: any): AxiosPromise<void> {
            return localVarFp.apiStatisticsGetStatisticsCostLongTermPost(statisticsReportBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StatisticsReportBody} [statisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsGetStatisticsReportPost(statisticsReportBody?: StatisticsReportBody, options?: any): AxiosPromise<void> {
            return localVarFp.apiStatisticsGetStatisticsReportPost(statisticsReportBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public apiStatisticsGetAbsenceSummaryReportGet(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).apiStatisticsGetAbsenceSummaryReportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmploymentGroupsPostModel} [employmentGroupsPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public apiStatisticsGetEmploymentGroupsPost(employmentGroupsPostModel?: EmploymentGroupsPostModel, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).apiStatisticsGetEmploymentGroupsPost(employmentGroupsPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsReportBodyMultiCompare} [statisticsReportBodyMultiCompare] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public apiStatisticsGetExcelReportPost(statisticsReportBodyMultiCompare?: StatisticsReportBodyMultiCompare, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).apiStatisticsGetExcelReportPost(statisticsReportBodyMultiCompare, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsReportBody} [statisticsReportBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public apiStatisticsGetStatisticsCostLongTermPost(statisticsReportBody?: StatisticsReportBody, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).apiStatisticsGetStatisticsCostLongTermPost(statisticsReportBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticsReportBody} [statisticsReportBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public apiStatisticsGetStatisticsReportPost(statisticsReportBody?: StatisticsReportBody, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).apiStatisticsGetStatisticsReportPost(statisticsReportBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatusApi - axios parameter creator
 * @export
 */
export const StatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Status/GetStatusAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatusApi - functional programming interface
 * @export
 */
export const StatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStatusGetStatusAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStatusGetStatusAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatusApi - factory interface
 * @export
 */
export const StatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatusApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatusGetStatusAllGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiStatusGetStatusAllGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatusApi - object-oriented interface
 * @export
 * @class StatusApi
 * @extends {BaseAPI}
 */
export class StatusApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatusApi
     */
    public apiStatusGetStatusAllGet(options?: AxiosRequestConfig) {
        return StatusApiFp(this.configuration).apiStatusGetStatusAllGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubscribersApi - axios parameter creator
 * @export
 */
export const SubscribersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [subscriberId] 
         * @param {number} [subscriptionLookupType] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersCanSubscribeOnRehabRemainderPost: async (companyId?: number, subscriberId?: number, subscriptionLookupType?: number, requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/CanSubscribeOnRehabRemainder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (subscriberId !== undefined) {
                localVarQueryParameter['subscriberId'] = subscriberId;
            }

            if (subscriptionLookupType !== undefined) {
                localVarQueryParameter['subscriptionLookupType'] = subscriptionLookupType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersCheckExternalSubscriptionGet: async (companyId?: number, searchString?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/CheckExternalSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EventSubscriptionPostModel} [eventSubscriptionPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersCreateOrUpdateEventSubscriptionPost: async (eventSubscriptionPostModel?: EventSubscriptionPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/CreateOrUpdateEventSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventSubscriptionPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExportSubscriptionModel} [exportSubscriptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersExportSubscriptionsPost: async (exportSubscriptionModel?: ExportSubscriptionModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/ExportSubscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(exportSubscriptionModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetCompanySmsServiceContractGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/GetCompanySmsServiceContract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetDefaultSubscriptionTypesGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/GetDefaultSubscriptionTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetExternalSubscribersGet: async (companyId?: number, searchQuery?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/GetExternalSubscribers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchQuery !== undefined) {
                localVarQueryParameter['searchQuery'] = searchQuery;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EventSubscriptionHistorySearchFilter} [eventSubscriptionHistorySearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetFilteredEventSubscriptionHistoriesPost: async (eventSubscriptionHistorySearchFilter?: EventSubscriptionHistorySearchFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/GetFilteredEventSubscriptionHistories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventSubscriptionHistorySearchFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [userAccountId] 
         * @param {string} [contactKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetSubscriberTableItemsByCompanyAndUserAccountGet: async (companyId?: number, userAccountId?: number, contactKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/GetSubscriberTableItemsByCompanyAndUserAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (userAccountId !== undefined) {
                localVarQueryParameter['userAccountId'] = userAccountId;
            }

            if (contactKey !== undefined) {
                localVarQueryParameter['contactKey'] = contactKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [employmentId] 
         * @param {string} [contactKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetSubscriberTableItemsGet: async (companyId?: number, employmentId?: number, contactKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/GetSubscriberTableItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (employmentId !== undefined) {
                localVarQueryParameter['employmentId'] = employmentId;
            }

            if (contactKey !== undefined) {
                localVarQueryParameter['contactKey'] = contactKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {SubscriptionLookupType} [lookupType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetSubscriptionTableItemsGet: async (id?: number, lookupType?: SubscriptionLookupType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/GetSubscriptionTableItems`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (lookupType !== undefined) {
                localVarQueryParameter['lookupType'] = lookupType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExternalSubscriptionCompanyDataModel} [externalSubscriptionCompanyDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersRemoveAllExternalCompanySubscriptionsByContactPost: async (externalSubscriptionCompanyDataModel?: ExternalSubscriptionCompanyDataModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/RemoveAllExternalCompanySubscriptionsByContact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalSubscriptionCompanyDataModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RemoveSubscriptions} [removeSubscriptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersRemoveSubscriptionsPost: async (removeSubscriptions?: RemoveSubscriptions, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/RemoveSubscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeSubscriptions, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchUserAndDepartmentToAddModel} [searchUserAndDepartmentToAddModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersSearchUserAndCompanyToAddPost: async (searchUserAndDepartmentToAddModel?: SearchUserAndDepartmentToAddModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/SearchUserAndCompanyToAdd`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchUserAndDepartmentToAddModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {boolean} [searchNoneEmployeeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersSearchUserAndDepartmentGet: async (companyId?: number, searchString?: string, searchNoneEmployeeUsers?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/SearchUserAndDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (searchNoneEmployeeUsers !== undefined) {
                localVarQueryParameter['searchNoneEmployeeUsers'] = searchNoneEmployeeUsers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExternalSubscriberUpdateDataPostModel} [externalSubscriberUpdateDataPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersUpdateExternalSubscriberPost: async (externalSubscriberUpdateDataPostModel?: ExternalSubscriberUpdateDataPostModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Subscribers/UpdateExternalSubscriber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(externalSubscriberUpdateDataPostModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscribersApi - functional programming interface
 * @export
 */
export const SubscribersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscribersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [subscriberId] 
         * @param {number} [subscriptionLookupType] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersCanSubscribeOnRehabRemainderPost(companyId?: number, subscriberId?: number, subscriptionLookupType?: number, requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersCanSubscribeOnRehabRemainderPost(companyId, subscriberId, subscriptionLookupType, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersCheckExternalSubscriptionGet(companyId?: number, searchString?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersCheckExternalSubscriptionGet(companyId, searchString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EventSubscriptionPostModel} [eventSubscriptionPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersCreateOrUpdateEventSubscriptionPost(eventSubscriptionPostModel?: EventSubscriptionPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersCreateOrUpdateEventSubscriptionPost(eventSubscriptionPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExportSubscriptionModel} [exportSubscriptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersExportSubscriptionsPost(exportSubscriptionModel?: ExportSubscriptionModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersExportSubscriptionsPost(exportSubscriptionModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersGetCompanySmsServiceContractGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersGetCompanySmsServiceContractGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersGetDefaultSubscriptionTypesGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersGetDefaultSubscriptionTypesGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersGetExternalSubscribersGet(companyId?: number, searchQuery?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersGetExternalSubscribersGet(companyId, searchQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EventSubscriptionHistorySearchFilter} [eventSubscriptionHistorySearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersGetFilteredEventSubscriptionHistoriesPost(eventSubscriptionHistorySearchFilter?: EventSubscriptionHistorySearchFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersGetFilteredEventSubscriptionHistoriesPost(eventSubscriptionHistorySearchFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [userAccountId] 
         * @param {string} [contactKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersGetSubscriberTableItemsByCompanyAndUserAccountGet(companyId?: number, userAccountId?: number, contactKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriberTableItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersGetSubscriberTableItemsByCompanyAndUserAccountGet(companyId, userAccountId, contactKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [employmentId] 
         * @param {string} [contactKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersGetSubscriberTableItemsGet(companyId?: number, employmentId?: number, contactKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersGetSubscriberTableItemsGet(companyId, employmentId, contactKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {SubscriptionLookupType} [lookupType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersGetSubscriptionTableItemsGet(id?: number, lookupType?: SubscriptionLookupType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionTableItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersGetSubscriptionTableItemsGet(id, lookupType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExternalSubscriptionCompanyDataModel} [externalSubscriptionCompanyDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersRemoveAllExternalCompanySubscriptionsByContactPost(externalSubscriptionCompanyDataModel?: ExternalSubscriptionCompanyDataModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersRemoveAllExternalCompanySubscriptionsByContactPost(externalSubscriptionCompanyDataModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RemoveSubscriptions} [removeSubscriptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersRemoveSubscriptionsPost(removeSubscriptions?: RemoveSubscriptions, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersRemoveSubscriptionsPost(removeSubscriptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchUserAndDepartmentToAddModel} [searchUserAndDepartmentToAddModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersSearchUserAndCompanyToAddPost(searchUserAndDepartmentToAddModel?: SearchUserAndDepartmentToAddModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscriptionLookup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersSearchUserAndCompanyToAddPost(searchUserAndDepartmentToAddModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {boolean} [searchNoneEmployeeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersSearchUserAndDepartmentGet(companyId?: number, searchString?: string, searchNoneEmployeeUsers?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersSearchUserAndDepartmentGet(companyId, searchString, searchNoneEmployeeUsers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExternalSubscriberUpdateDataPostModel} [externalSubscriberUpdateDataPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscribersUpdateExternalSubscriberPost(externalSubscriberUpdateDataPostModel?: ExternalSubscriberUpdateDataPostModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSubscribersUpdateExternalSubscriberPost(externalSubscriberUpdateDataPostModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscribersApi - factory interface
 * @export
 */
export const SubscribersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscribersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [subscriberId] 
         * @param {number} [subscriptionLookupType] 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersCanSubscribeOnRehabRemainderPost(companyId?: number, subscriberId?: number, subscriptionLookupType?: number, requestBody?: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersCanSubscribeOnRehabRemainderPost(companyId, subscriberId, subscriptionLookupType, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersCheckExternalSubscriptionGet(companyId?: number, searchString?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersCheckExternalSubscriptionGet(companyId, searchString, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EventSubscriptionPostModel} [eventSubscriptionPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersCreateOrUpdateEventSubscriptionPost(eventSubscriptionPostModel?: EventSubscriptionPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersCreateOrUpdateEventSubscriptionPost(eventSubscriptionPostModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExportSubscriptionModel} [exportSubscriptionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersExportSubscriptionsPost(exportSubscriptionModel?: ExportSubscriptionModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersExportSubscriptionsPost(exportSubscriptionModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetCompanySmsServiceContractGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersGetCompanySmsServiceContractGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetDefaultSubscriptionTypesGet(companyId?: number, options?: any): AxiosPromise<Array<SubscriptionType>> {
            return localVarFp.apiSubscribersGetDefaultSubscriptionTypesGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchQuery] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetExternalSubscribersGet(companyId?: number, searchQuery?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersGetExternalSubscribersGet(companyId, searchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EventSubscriptionHistorySearchFilter} [eventSubscriptionHistorySearchFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetFilteredEventSubscriptionHistoriesPost(eventSubscriptionHistorySearchFilter?: EventSubscriptionHistorySearchFilter, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersGetFilteredEventSubscriptionHistoriesPost(eventSubscriptionHistorySearchFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [userAccountId] 
         * @param {string} [contactKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetSubscriberTableItemsByCompanyAndUserAccountGet(companyId?: number, userAccountId?: number, contactKey?: string, options?: any): AxiosPromise<Array<SubscriberTableItem>> {
            return localVarFp.apiSubscribersGetSubscriberTableItemsByCompanyAndUserAccountGet(companyId, userAccountId, contactKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {number} [employmentId] 
         * @param {string} [contactKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetSubscriberTableItemsGet(companyId?: number, employmentId?: number, contactKey?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersGetSubscriberTableItemsGet(companyId, employmentId, contactKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {SubscriptionLookupType} [lookupType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersGetSubscriptionTableItemsGet(id?: number, lookupType?: SubscriptionLookupType, options?: any): AxiosPromise<Array<SubscriptionTableItem>> {
            return localVarFp.apiSubscribersGetSubscriptionTableItemsGet(id, lookupType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExternalSubscriptionCompanyDataModel} [externalSubscriptionCompanyDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersRemoveAllExternalCompanySubscriptionsByContactPost(externalSubscriptionCompanyDataModel?: ExternalSubscriptionCompanyDataModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersRemoveAllExternalCompanySubscriptionsByContactPost(externalSubscriptionCompanyDataModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RemoveSubscriptions} [removeSubscriptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersRemoveSubscriptionsPost(removeSubscriptions?: RemoveSubscriptions, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersRemoveSubscriptionsPost(removeSubscriptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchUserAndDepartmentToAddModel} [searchUserAndDepartmentToAddModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersSearchUserAndCompanyToAddPost(searchUserAndDepartmentToAddModel?: SearchUserAndDepartmentToAddModel, options?: any): AxiosPromise<Array<SubscriptionLookup>> {
            return localVarFp.apiSubscribersSearchUserAndCompanyToAddPost(searchUserAndDepartmentToAddModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [companyId] 
         * @param {string} [searchString] 
         * @param {boolean} [searchNoneEmployeeUsers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersSearchUserAndDepartmentGet(companyId?: number, searchString?: string, searchNoneEmployeeUsers?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersSearchUserAndDepartmentGet(companyId, searchString, searchNoneEmployeeUsers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExternalSubscriberUpdateDataPostModel} [externalSubscriberUpdateDataPostModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscribersUpdateExternalSubscriberPost(externalSubscriberUpdateDataPostModel?: ExternalSubscriberUpdateDataPostModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiSubscribersUpdateExternalSubscriberPost(externalSubscriberUpdateDataPostModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscribersApi - object-oriented interface
 * @export
 * @class SubscribersApi
 * @extends {BaseAPI}
 */
export class SubscribersApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [subscriberId] 
     * @param {number} [subscriptionLookupType] 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersCanSubscribeOnRehabRemainderPost(companyId?: number, subscriberId?: number, subscriptionLookupType?: number, requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersCanSubscribeOnRehabRemainderPost(companyId, subscriberId, subscriptionLookupType, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [searchString] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersCheckExternalSubscriptionGet(companyId?: number, searchString?: string, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersCheckExternalSubscriptionGet(companyId, searchString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EventSubscriptionPostModel} [eventSubscriptionPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersCreateOrUpdateEventSubscriptionPost(eventSubscriptionPostModel?: EventSubscriptionPostModel, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersCreateOrUpdateEventSubscriptionPost(eventSubscriptionPostModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExportSubscriptionModel} [exportSubscriptionModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersExportSubscriptionsPost(exportSubscriptionModel?: ExportSubscriptionModel, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersExportSubscriptionsPost(exportSubscriptionModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersGetCompanySmsServiceContractGet(companyId?: number, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersGetCompanySmsServiceContractGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersGetDefaultSubscriptionTypesGet(companyId?: number, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersGetDefaultSubscriptionTypesGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [searchQuery] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersGetExternalSubscribersGet(companyId?: number, searchQuery?: string, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersGetExternalSubscribersGet(companyId, searchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EventSubscriptionHistorySearchFilter} [eventSubscriptionHistorySearchFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersGetFilteredEventSubscriptionHistoriesPost(eventSubscriptionHistorySearchFilter?: EventSubscriptionHistorySearchFilter, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersGetFilteredEventSubscriptionHistoriesPost(eventSubscriptionHistorySearchFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [userAccountId] 
     * @param {string} [contactKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersGetSubscriberTableItemsByCompanyAndUserAccountGet(companyId?: number, userAccountId?: number, contactKey?: string, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersGetSubscriberTableItemsByCompanyAndUserAccountGet(companyId, userAccountId, contactKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {number} [employmentId] 
     * @param {string} [contactKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersGetSubscriberTableItemsGet(companyId?: number, employmentId?: number, contactKey?: string, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersGetSubscriberTableItemsGet(companyId, employmentId, contactKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {SubscriptionLookupType} [lookupType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersGetSubscriptionTableItemsGet(id?: number, lookupType?: SubscriptionLookupType, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersGetSubscriptionTableItemsGet(id, lookupType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExternalSubscriptionCompanyDataModel} [externalSubscriptionCompanyDataModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersRemoveAllExternalCompanySubscriptionsByContactPost(externalSubscriptionCompanyDataModel?: ExternalSubscriptionCompanyDataModel, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersRemoveAllExternalCompanySubscriptionsByContactPost(externalSubscriptionCompanyDataModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RemoveSubscriptions} [removeSubscriptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersRemoveSubscriptionsPost(removeSubscriptions?: RemoveSubscriptions, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersRemoveSubscriptionsPost(removeSubscriptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchUserAndDepartmentToAddModel} [searchUserAndDepartmentToAddModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersSearchUserAndCompanyToAddPost(searchUserAndDepartmentToAddModel?: SearchUserAndDepartmentToAddModel, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersSearchUserAndCompanyToAddPost(searchUserAndDepartmentToAddModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [companyId] 
     * @param {string} [searchString] 
     * @param {boolean} [searchNoneEmployeeUsers] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersSearchUserAndDepartmentGet(companyId?: number, searchString?: string, searchNoneEmployeeUsers?: boolean, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersSearchUserAndDepartmentGet(companyId, searchString, searchNoneEmployeeUsers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExternalSubscriberUpdateDataPostModel} [externalSubscriberUpdateDataPostModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscribersApi
     */
    public apiSubscribersUpdateExternalSubscriberPost(externalSubscriberUpdateDataPostModel?: ExternalSubscriberUpdateDataPostModel, options?: AxiosRequestConfig) {
        return SubscribersApiFp(this.configuration).apiSubscribersUpdateExternalSubscriberPost(externalSubscriberUpdateDataPostModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SummaryApi - axios parameter creator
 * @export
 */
export const SummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSummaryGetEmploymentCountGet: async (companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Summary/GetEmploymentCount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchDepartmentModel} [searchDepartmentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSummaryGetSummaryPost: async (searchDepartmentModel?: SearchDepartmentModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Summary/GetSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchDepartmentModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SummaryApi - functional programming interface
 * @export
 */
export const SummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSummaryGetEmploymentCountGet(companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSummaryGetEmploymentCountGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchDepartmentModel} [searchDepartmentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSummaryGetSummaryPost(searchDepartmentModel?: SearchDepartmentModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSummaryGetSummaryPost(searchDepartmentModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SummaryApi - factory interface
 * @export
 */
export const SummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SummaryApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSummaryGetEmploymentCountGet(companyId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiSummaryGetEmploymentCountGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchDepartmentModel} [searchDepartmentModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSummaryGetSummaryPost(searchDepartmentModel?: SearchDepartmentModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiSummaryGetSummaryPost(searchDepartmentModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SummaryApi - object-oriented interface
 * @export
 * @class SummaryApi
 * @extends {BaseAPI}
 */
export class SummaryApi extends BaseAPI {
    /**
     * 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public apiSummaryGetEmploymentCountGet(companyId?: number, options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).apiSummaryGetEmploymentCountGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchDepartmentModel} [searchDepartmentModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummaryApi
     */
    public apiSummaryGetSummaryPost(searchDepartmentModel?: SearchDepartmentModel, options?: AxiosRequestConfig) {
        return SummaryApiFp(this.configuration).apiSummaryGetSummaryPost(searchDepartmentModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRehabaccessPost: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/rehabaccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserRehabaccessPost(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserAccountLite>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserRehabaccessPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserRehabaccessPost(requestBody?: Array<number>, options?: any): AxiosPromise<Array<UserAccountLite>> {
            return localVarFp.apiUserRehabaccessPost(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserRehabaccessPost(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserRehabaccessPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



