import {
    DateRange,
    DayPicker,
    CaptionProps,
    useDayRender,
    useNavigation,
    DayContentProps,
    DayProps,
    Row,
    RowProps,
    DayContent,
    ActiveModifiers,
} from "react-day-picker";
import {
    format,
    Duration,
    addYears,
    addMonths,
    addWeeks,
    addDays,
    addHours,
    addMinutes,
    addSeconds,
    subYears,
    subMonths,
    subWeeks,
    subDays,
    subHours,
    subMinutes,
    subSeconds,
    parse,
    isDate,
    isValid,
    differenceInCalendarDays,
    eachMonthOfInterval,
    startOfYear,
    endOfYear,
    startOfDay,
    startOfMonth,
    eachYearOfInterval,
    eachHourOfInterval,
    eachWeekOfInterval,
    eachDayOfInterval,
    eachMinuteOfInterval,
    startOfWeek,
    startOfHour,
    startOfMinute,
    endOfMonth,
    endOfWeek,
    endOfDay,
    endOfHour,
    endOfMinute,
    getUnixTime,
    differenceInMinutes,
    isSameMinute,
    isSameDay,
    differenceInYears,
    differenceInMonths,
    differenceInWeeks,
    differenceInHours,
    differenceInSeconds,
    differenceInMilliseconds,
    toDate,
} from "date-fns";
import { da, enGB, nb, sv } from "date-fns/locale";
import _ from "lodash";
import { StringDateRange } from "@medhelp/ui/Datepicker/types";

export {
    type DateRange,
    type CaptionProps,
    type DayProps,
    type RowProps,
    type DayContentProps,
    useNavigation,
    Row,
    DayPicker,
    useDayRender,
    DayContent,
    type ActiveModifiers,
    convertDateRangeToStringDateRange,
    getStringDateRangeAndConvertToDateRange,
};

export const newDate = () => new Date();

export const getNoOffsetDate = (date: Date): Date => {
    const offsetInMinutes = date.getTimezoneOffset();
    if (offsetInMinutes === 0) return date;
    const noOffset = offsetInMinutes > 0 ? addMinutes(date, offsetInMinutes) : subMinutes(date, offsetInMinutes);
    return noOffset;
};

export const getNoOffsetDateString = (date: Date): string => getNoOffsetDate(date).toISOString();

export const newDateString = (): string => getNoOffsetDateString(newDate());

export const getStringFromDate = (date: Date): string => getNoOffsetDateString(date);

export const getDateWithoutTzConversion = (date: string): Date => {
    if (!date || typeof date !== "string") {
        console.trace("Not a date string, faulty string:", { date });
        return newDate();
    }
    if (date.includes("Z")) return new Date(date.slice(0, -1));
    else {
        return new Date(date);
    }
};

export const getDateForwardOrBack = (
    timeLength: number,
    timeUnit: keyof Duration,
    forwardInTime: "forward" | "backwards" = "forward",
    dateFrom?: Date | string,
): Date => {
    const date = getDate(dateFrom);
    switch (timeUnit) {
        case "years":
            return forwardInTime === "forward" ? addYears(date, timeLength) : subYears(date, timeLength);
        case "months":
            return forwardInTime === "forward" ? addMonths(date, timeLength) : subMonths(date, timeLength);
        case "weeks":
            return forwardInTime === "forward" ? addWeeks(date, timeLength) : subWeeks(date, timeLength);
        case "days":
            return forwardInTime === "forward" ? addDays(date, timeLength) : subDays(date, timeLength);
        case "hours":
            return forwardInTime === "forward" ? addHours(date, timeLength) : subHours(date, timeLength);
        case "minutes":
            return forwardInTime === "forward" ? addMinutes(date, timeLength) : subMinutes(date, timeLength);
        default:
            return forwardInTime === "forward" ? addSeconds(date, timeLength) : subSeconds(date, timeLength);
    }
};

export const getDateStringForwardOrBack = (
    timeLength: number,
    timeUnit: keyof Duration,
    forwardInTime: "forward" | "backwards" = "forward",
    dateFrom?: Date | string,
): string => {
    const date = getDateForwardOrBack(timeLength, timeUnit, forwardInTime, getDateOrUndefined(dateFrom));
    return getNoOffsetDateString(date);
};

export interface IGetEachIntervalProps {
    timeUnit: keyof Duration;
    fromDate: Date;
    toDate: Date;
}

export const getEachInterval = ({ timeUnit, fromDate, toDate }: IGetEachIntervalProps): Date[] => {
    switch (timeUnit) {
        case "years":
            return eachYearOfInterval({ start: fromDate, end: toDate });
        case "months":
            return eachMonthOfInterval({ start: fromDate, end: toDate });
        case "weeks":
            return eachWeekOfInterval({ start: fromDate, end: toDate });
        case "days":
            return eachDayOfInterval({ start: fromDate, end: toDate });
        case "hours":
            return eachHourOfInterval({ start: fromDate, end: toDate });
        default:
            return eachMinuteOfInterval({ start: fromDate, end: toDate });
    }
};
export const getEachIntervalFormatted = (
    timeUnit: keyof Duration,
    fromDate: Date,
    toDate: Date,
    format: DateFormat,
    locale?: string,
): string[] => {
    const formattedArray = getEachInterval({ timeUnit, fromDate, toDate }).map((date) =>
        getDateDisplayValue(date, format, locale),
    );
    return formattedArray;
};

export const getDiffInTimeUnit = (
    timeUnit: keyof Duration,
    forwardInTime: "forward" | "back" = "forward",
    fromDate: Date | string,
    toDate: Date | string,
): number => {
    //Earlier date
    const dateRight = forwardInTime === "forward" ? getDateFromStringOrDate(fromDate) : getDateFromStringOrDate(toDate);
    //later date
    const dateLeft = forwardInTime === "back" ? getDateFromStringOrDate(fromDate) : getDateFromStringOrDate(toDate);
    switch (timeUnit) {
        case "years":
            return differenceInYears(dateLeft, dateRight);
        case "months":
            return differenceInMonths(dateLeft, dateRight);
        case "weeks":
            return differenceInWeeks(dateLeft, dateRight);
        case "days":
            return differenceInCalendarDays(dateLeft, dateRight);
        case "hours":
            return differenceInHours(dateLeft, dateRight);
        case "minutes":
            return differenceInMinutes(dateLeft, dateRight);
        case "seconds":
            return differenceInSeconds(dateLeft, dateRight);
        default:
            return differenceInMilliseconds(dateLeft, dateRight);
    }
};

export const getStartOfDate = (timeUnit: keyof Duration, startDate?: Date): Date => {
    const date = getDate(startDate);
    switch (timeUnit) {
        case "years":
            return startOfYear(date);
        case "months":
            return startOfMonth(date);
        case "weeks":
            return startOfWeek(date);
        case "days":
            return startOfDay(date);
        case "hours":
            return startOfHour(date);
        default:
            return startOfMinute(date);
    }
};

export const getStringStartOfDateString = (timeUnit: keyof Duration, startDate?: string): string => {
    const date = startDate ? new Date(startDate) : undefined;
    return getStartOfDateString(timeUnit, date);
};

export const getStartOfDateString = (timeUnit: keyof Duration, startDate?: Date): string => {
    return getNoOffsetDateString(getStartOfDate(timeUnit, startDate));
};

export const getEndOfDate = (timeUnit: keyof Duration, endDate?: Date | string): Date => {
    const date = getDate(endDate);
    switch (timeUnit) {
        case "years":
            return endOfYear(date);
        case "months":
            return endOfMonth(date);
        case "weeks":
            return endOfWeek(date);
        case "days":
            return endOfDay(date);
        case "hours":
            return endOfHour(date);
        default:
            return endOfMinute(date);
    }
};
export const getEndOfDateString = (timeUnit: keyof Duration, endDate?: Date | string): string =>
    getNoOffsetDateString(getEndOfDate(timeUnit, endDate));

interface IFormatDate {
    format: string;
    locale: string;
}

const formatDateToString = (date: Date, formatDate: IFormatDate): string => {
    const locale = formatDate.locale ? getDateLocale(formatDate.locale) : undefined;
    try {
        return format(date, formatDate.format, { locale: locale });
    } catch (error) {
        console.trace(`Error trying to format ${date}, with error: ${error}`);
        throw new Error("Error, se console log for more info");
    }
};

const convertDateRangeToStringDateRange = (dateRange: DateRange | undefined): StringDateRange | undefined => {
    if (!dateRange || !dateRange.from || !dateRange.to) return undefined;
    return {
        from: getStringFromDate(dateRange?.from),
        to: getStringFromDate(dateRange?.to),
    };
};

const getStringDateRangeAndConvertToDateRange = (range: StringDateRange | undefined): DateRange | undefined => {
    if (!range) return undefined;
    return {
        from: range.from ? getDateWithoutTzConversion(range?.from) : undefined,
        to: range.to ? getDateWithoutTzConversion(range?.to) : undefined,
    };
};

type DateFormat =
    | "P" // 04/29/1453
    | "Pp" // 04/29/1453, 12:00 AM
    | "dd" // 09
    | "MMM" // Apr
    | "MMMM" // April
    | "yyyy" // 1453
    | "yyyy-MM" // 1453-04
    | "MMM yyyy" // Apr 1453
    | "MMMM yyyy" // April 1453
    | "eee d MMM" // Tue 29 Apr
    | "yyyy-MM-dd" // 1453-04-29
    | "d MMM yyyy" // 9 Apr 1453
    | "d MMMM yyyy" // 9 April 1453
    | "dd MMM yyyy" // 09 Apr 1453
    | "dd MMMM yyyy" // 09 April 1453
    | "d MMM yyyy HH:mm" // 9 Apr 1453 12:00
    | "dd MMM yyyy HH:mm" // 09 Apr 1453 12:00
    | "d MMM yyyy, HH:mm" // 9 Apr 1453, 12:00
    | "dd MMM yyyy, HH:mm" // 09 Apr 1453, 12:00
    | "eee dd MMM yyyy" // Tue 09 Apr 1453
    | "eee d MMM yyyy, HH:mm"; // Tue 9 Apr 1453, 12:00

export const getDateDisplayValue = (date?: string | Date | null, format?: DateFormat, locale?: string): string => {
    const formatProps = {
        format: format ? format : "dd MMM yyyy",
        locale: locale ? locale : "",
    };
    if (!date) return formatDateToString(newDate(), formatProps);

    if (isDate(date)) {
        return formatDateToString(date as Date, formatProps);
    } else {
        try {
            const casted = getDateWithoutTzConversion(date as string);
            if (isValidDate(casted)) return getDateDisplayValue(casted, format, locale);
            else {
                console.trace("getDateDisplayValue failed!", { casted, date });
                throw new Error("getDateDisplayValue failed!");
            }
        } catch (error) {
            console.trace({ error, date });
            return "getDateDisplayValue failed! Check console.log which date failed";
        }
    }
};

export const getDateTimeDisplayValue = (date?: string | Date, format?: DateFormat, locale?: string): string => {
    const stringFormat = format ? format : "dd MMM yyyy HH:mm";
    const formatted = getDateDisplayValue(getDate(date), stringFormat, locale);
    return formatted;
};

const parseDate = (date: string, dateFormat?: string): Date => {
    let parsed = parse(date, dateFormat ?? "dd LLL yyyy", getDateWithoutTzConversion(date));
    return parsed;
};

const parseIsoDate = (date: string, dateFormat?: string): Date => {
    let parsed = parse(getDateDisplayValue(date), dateFormat ?? "dd LLL yyyy", getDateWithoutTzConversion(date));
    return parsed;
};

export const getDate = (date?: string | Date | null | undefined): Date => {
    if (date) {
        if (isValid(date)) return date as Date;
        try {
            return getDateWithoutTzConversion(date as string);
        } catch (error) {
            console.trace(`Error trying to cast ${date}, with error: ${error}`);
            throw new Error("Error, se console log for more info");
        }
    } else return toDate(new Date());
};

export const getDateOrUndefined = (date: string | Date | undefined): Date | undefined => {
    if (!date) return undefined;
    else if (isValid(date)) return date as Date;
    else if (typeof date === "string") {
        try {
            let newDate = getDateWithoutTzConversion(date as string);
            if (!isValid(newDate)) {
                newDate = parseDate(date as string);
                if (!isValid(newDate)) newDate = parseIsoDate(date as string);
            }
            return newDate;
        } catch (error) {
            console.trace(`Error trying to cast ${date}, with error: ${error}`);
            throw new Error("Error, se console log for more info");
        }
    } else return undefined;
};

export const isValidDate = (date: string | Date): boolean => {
    const valid = isDate(date) ? isValid(date) : isValid(getDateOrUndefined(date));
    return valid;
};

export const getDateFromStringOrDate = (date: Date | string): Date => {
    try {
        return isValid(date) ? (date as Date) : getDateWithoutTzConversion(date as string);
    } catch (error) {
        console.trace("Error in getDateFromStringOrDate method, date that failed: ", { date, error });
        throw new Error("error in getDateFromStringOrDate method, se console for more info");
    }
};

export const isSameDateMinute = (dateToCheck: Date | string | undefined, dateSameOrNow?: Date | string) => {
    return dateToCheck
        ? isSameMinute(getDateFromStringOrDate(dateToCheck), getDateOrUndefined(dateSameOrNow) ?? newDate())
        : false;
};

export const isAfterDate = (dateToCheck: Date | string | undefined, dateBeforeOrNow?: Date | string): boolean => {
    return dateToCheck
        ? differenceInMinutes(getDateFromStringOrDate(dateToCheck), getDateOrUndefined(dateBeforeOrNow) ?? newDate()) >
              0
        : false;
};

export const isSameOrAfterDate = (dateToCheck: Date | string, dateBeforeOrNow?: Date | string): boolean => {
    return (
        differenceInMinutes(getDateFromStringOrDate(dateToCheck), getDateOrUndefined(dateBeforeOrNow) ?? newDate()) >= 0
    );
};

export const isBeforeDate = (dateToCheck: Date | string | undefined, dateAfterOrNow?: Date | string): boolean => {
    return dateToCheck
        ? differenceInMinutes(getDateFromStringOrDate(dateToCheck), getDateOrUndefined(dateAfterOrNow) ?? newDate()) < 0
        : false;
};

export const isSameOrBeforeDate = (
    dateToCheck: Date | string | undefined,
    dateAfterOrNow?: Date | string | undefined | null,
): boolean => {
    return dateToCheck
        ? differenceInMinutes(getDateFromStringOrDate(dateToCheck), getDate(dateAfterOrNow)) <= 0
        : false;
};

export const isSameOrBeforeDateDay = (
    dateToCheck: Date | string | undefined,
    dateAfterOrNow?: Date | string | undefined | null,
): boolean => {
    return dateToCheck
        ? differenceInCalendarDays(getDateFromStringOrDate(dateToCheck), getDate(dateAfterOrNow)) <= 0
        : false;
};

export const isSameDateDay = (dateToCheck: Date | string | undefined, dateSameOrNow?: Date | string) => {
    return dateToCheck
        ? isSameDay(getDateFromStringOrDate(dateToCheck), getDateOrUndefined(dateSameOrNow) ?? newDate())
        : false;
};

export const isBeforeToday = (dateToCheck?: Date | string): boolean => {
    return dateToCheck ? differenceInCalendarDays(getDateFromStringOrDate(dateToCheck), newDate()) < 0 : false;
};

export const isSameOrBeforeToday = (dateToCheck: Date | string): boolean => {
    return differenceInCalendarDays(getDateFromStringOrDate(dateToCheck), newDate()) <= 0;
};

export const isSameOrAfterToday = (dateToCheck: Date | string): boolean => {
    return differenceInCalendarDays(getDateFromStringOrDate(dateToCheck), newDate()) >= 0;
};

export const isAfterToday = (dateToCheck?: Date | string): boolean => {
    return dateToCheck ? differenceInCalendarDays(getDateFromStringOrDate(dateToCheck), newDate()) > 0 : false;
};

export const isAfterThisMonth = (dateToCheck: Date | string | undefined, dateBeforeOrNow?: Date | string) => {
    return dateToCheck
        ? differenceInMonths(getDateFromStringOrDate(dateToCheck), getDateOrUndefined(dateBeforeOrNow) ?? newDate()) > 0
        : false;
};

export const isSameOrBeforeThisMonth = (
    dateToCheck: Date | string | undefined,
    dateAfterOrNow: Date | string | undefined,
) => {
    return dateToCheck
        ? differenceInMonths(getDateFromStringOrDate(dateToCheck), getDateOrUndefined(dateAfterOrNow) ?? newDate()) <= 0
        : false;
};
export const isBeforeThisMonth = (
    dateToCheck: Date | string | undefined,
    dateAfterOrNow: Date | string | undefined,
) => {
    return dateToCheck
        ? differenceInMonths(getDateFromStringOrDate(dateToCheck), getDateOrUndefined(dateAfterOrNow) ?? newDate()) < 0
        : false;
};
export const isAfterHour = (dateToCheck?: Date | string, dateBeforeOrNow?: Date | string) => {
    return dateToCheck ? differenceInHours(getDate(dateToCheck), getDate(dateBeforeOrNow)) > 0 : false;
};
export const isBeforeHour = (dateToCheck?: Date | string, dateAfterOrNow?: Date | string) => {
    return dateToCheck ? differenceInHours(getDate(dateToCheck), getDate(dateAfterOrNow)) < 0 : false;
};

export const getDateLocale = (langLocale?: string) => {
    switch (_.toLower(langLocale)) {
        case "dk":
            return da;
        case "no":
            return nb;
        case "en":
            return enGB;
        default:
            return sv;
    }
};

export const getUnix = (date?: Date | string): number => {
    return isDate(date) ? getUnixTime(date as Date) : getUnixTime(getDate(date as string));
};

export const getLocalTime = (date: string) => {
    const timeWithUtcIndicator = date + "Z";
    const convertedToLocalTime = new Date(timeWithUtcIndicator);
    return getDateTimeDisplayValue(convertedToLocalTime);
};
