import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { exportHistoricalAbsenceReport as exportHistoricalAbsenceReportAction } from "../followUpAsyncActions";
import { IState } from "..";

const exportHistoricalAbsenceReport = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(exportHistoricalAbsenceReportAction.fulfilled, () => {})
        .addCase(exportHistoricalAbsenceReportAction.pending, () => {})
        .addCase(exportHistoricalAbsenceReportAction.rejected, () => {});
export default exportHistoricalAbsenceReport;
