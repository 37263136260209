import { includes } from "lodash/fp";
import { AllFiltersType, ExtractFilterTypes, IProperties, SortingTypes, StartEndDatePeriodType } from "../utils";

export interface IUserSubscription {
    sick: boolean;
    careOfChild: boolean;
    deviant: boolean;
    rehab: boolean;
    retroactivelyEditedAbsence: boolean;
    id: number | null;
    name: string | null;
    contactKey: string[];
    userAccountId: number | null;
    activationDate: Date | null;
    deactivationDate: Date | null;
    inActive: boolean;
    monitoringType: number | null;
    directSubscriptionInformation: IDirectSubscriptionInformation[];
    indirectSubscriptionInformation: string | null;
    companyName: string | null;
    companyId: number | null;
    departmentName: string | null;
}

interface IDirectSubscriptionInformation {
    notificationEventType: string;
    name: string | null;
    eventCode: number | null;
    subscriptionInformationItems: ISubscriptionInformationItem[];
}

interface ISubscriptionInformationItem {
    id: number;
    channel: string | null;
    subscriptionTypeId: number;
}

export interface ISearchResult {
    header?: string | null;
    body?: string | null;
}
export interface ISearchProperties {
    profile: ISearchResult;
    type: ISearchResult;
    date: ISearchResult;
    degree: ISearchResult;
    days: ISearchResult;
    departments: {
        text: string[];
    };
}
interface DegreeMap {
    degree?: number | null;
    timeStamp?: string | null;
    endDate?: string | null;
    header?: string;
}
export interface DegreePeriodType {
    degrees: DegreeMap[] | undefined;
    endDate?: string | null | undefined;
}
type Views =
    | "ColumnText"
    | "DepartmentsCell"
    | "Button"
    | "DegreeCell"
    | "ProfileRow"
    | "Empty"
    | "PropertiesCell"
    | "EndStartDate";
export interface ITableResult {
    header?: string | null;
    body?: string | string[] | null;
    texts?: string[] | null;
    degrees?: DegreePeriodType;
    properties?: IProperties;
    endStartDate?: StartEndDatePeriodType | null | undefined;
    id?: string | null;
    type?: Views;
    text?: string;
    employeeNumber?: string | null;
}
export interface SearchTableResult {
    data?: ITableResult | ITableResult[];
    order?: number;
    id?: string | null;
}
export interface ITableProps {
    cells: SearchTableResult[];
    id?: string | null;
    theme?: "archive" | "default";
}
export interface ITable {
    data: ITableProps[];
    numberOfPages: number;
    totalCount: number;
}

type ToArrayNonDist<Type> = [Type] extends [any] ? Type[] : never;

export type ISearchFilterTags = ToArrayNonDist<ITags | IMonth | IDate | INumber>;

export type SearchTypeFilterItem = AllFiltersType[];

export type MonthIds = ExtractFilterTypes<AllFiltersType, "month">;

export type SearchNumberIds = ExtractFilterTypes<
    AllFiltersType,
    "minDays" | "maxDays" | "occurrences" | "daysActivityDelayed"
>;

export type DateIds = ExtractFilterTypes<AllFiltersType, "from" | "to">;

export type TagIds = ExtractFilterTypes<
    AllFiltersType,
    | "reimbursableType"
    | "periodStatus"
    | "absenceType"
    | "workRelated"
    | "absenceStatus"
    | "employmentGroups"
    | "codes"
    | "monthStatus"
    | "absenceImpact"
    | "causes"
    | "caseStatus"
    | "caseType"
    | "activityStatus"
>;

export interface ISearchContent {
    referenceKey: string;
}
export interface IMonth extends ISearchContent {
    year: string | null;
    displayMonth: string | null;
    monthIndex: number | null;
    id: MonthIds;
}

export interface IDate extends ISearchContent {
    date: string | null;
    id: DateIds;
}

export interface INumber extends ISearchContent {
    value: number | null;
    id: SearchNumberIds;
    label?: string;
}

export interface ITags extends ISearchContent {
    label: string;
    checked: boolean;
    id: TagIds;
}

export type IFilter = ITags | IMonth | IDate | INumber;

const TagIncludes: TagIds[] = [
    "reimbursableType",
    "periodStatus",
    "absenceType",
    "workRelated",
    "absenceStatus",
    "monthStatus",
    "employmentGroups",
    "codes",
    "absenceImpact",
    "causes",
    "caseStatus",
    "caseType",
    "activityStatus",
];
export const isTag = (filter: IFilter): filter is ITags => includes(filter.id, TagIncludes);

const MonthIncludes: MonthIds[] = ["month"];
export const isMonth = (filter: IFilter): filter is IMonth => includes(filter.id, MonthIncludes);

const DateIncludes: DateIds[] = ["from", "to"];
export const isDate = (filter: IFilter): filter is IDate => includes(filter.id, DateIncludes);

const SearchNumberIncludes: SearchNumberIds[] = ["maxDays", "minDays", "occurrences", "daysActivityDelayed"];
export const isSearchNumber = (filter: IFilter): filter is INumber => includes(filter.id, SearchNumberIncludes);

export const checkIfTagsAndRunCallback =
    <T>(callback: (value: ITags) => T) =>
    (filter: IFilter) =>
        isTag(filter) ? callback(filter) : filter;

export const checkIfDateAndRunCallback =
    <T>(callback: (value: IDate) => T) =>
    (filter: IFilter) =>
        isDate(filter) ? callback(filter) : filter;

export const checkIfMonthAndRunCallback =
    <T>(callback: (value: IMonth) => T) =>
    (filter: IFilter) =>
        isMonth(filter) ? callback(filter) : filter;

export const checkIfNumberAndRunCallback =
    <T>(callback: (value: INumber) => T) =>
    (filter: IFilter) =>
        isSearchNumber(filter) ? callback(filter) : filter;

export type ShowBasedOn = "employee" | "numberOfAbsenceOccasions" | "numberOfCases" | "numberOfActivities" | null;
export type ShowDepartments = "everyDepartment" | "onlyLatestDepartment";
export type SortingAttributes = SortingTypes;

export interface ISortingFilter {
    showBasedOn: ShowBasedOn;
    showDepartments: ShowDepartments;
    sorting: SortingAttributes;
}
export interface ISearchFilter extends ISortingFilter {
    filters: ISearchFilterTags;
}

export type ISearchFieldValues = "showBasedOn" | "sorting" | "showDepartments";

interface ISearchBasedOn {
    field: ISearchFieldValues;
    value: ShowBasedOn;
}

interface ISearchSorting {
    field: ISearchFieldValues;
    value: SortingAttributes;
}

interface ISearchAffectedDeparment {
    field: ISearchFieldValues;
    value: ShowDepartments;
}

export type ISearchSortingFilter = ISearchBasedOn | ISearchSorting | ISearchAffectedDeparment;

export const isSortingFilter = (payload: ISearchSortingFilter): payload is ISearchSorting =>
    payload.field === "sorting";

export const isSearchBasedOn = (payload: ISearchSortingFilter): payload is ISearchBasedOn =>
    payload.field === "showBasedOn";

export const isSearchAffectedDepartments = (payload: ISearchSortingFilter): payload is ISearchAffectedDeparment =>
    payload.field === "showDepartments";

interface DateSelection {
    startDate: string | undefined;
    endDate: string | undefined;
}

export interface IDateRange {
    startDate: string | undefined;
    endDate: string | undefined;
    onChange: (value: DateSelection) => void;
}

export interface IFilterProps {
    items: ITags[];
    header: string;
    onChange: (value: string) => void;
    tags: string;
}

export interface ISetCaseNotificationOpen {
    healthCaseId: string;
    eventId: string;
}

export type HealthCaseType = "healthpromotion" | "rehab";
