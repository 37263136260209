import { useTranslation } from "react-i18next";
import Popout from "../Popout";
import UploadDoctorCertificate from "../UploadDoctorCertificate";
import DeleteMedicalModal from "../DeleteMedicalModal";
import { selectors, MedicalCertificateModals, actions } from "../../redux";
import { useDoubleDatepickerProps } from "hooks";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback } from "react";

const Modals = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const uploadCertificateModal = useAppSelector(selectors.getModal(MedicalCertificateModals.uploadCertificate));
    const someFilesFailedModal = useAppSelector(selectors.getModal(MedicalCertificateModals.someUploadedFilesFailed));

    const hideModal = useCallback(
        (modal: MedicalCertificateModals) => {
            dispatch(actions.hideModal(modal));
        },
        [dispatch],
    );

    const certificateDatepickerProps = useDoubleDatepickerProps({
        onChangeDateRange: () => {},
        givenDateRange: undefined,
        placeholderFrom: t("startDate"),
        placeholderTo: t("endDate"),
        isMandatory: true,
        className: "!w-full",
    });

    return (
        <>
            {someFilesFailedModal && (
                <Popout
                    open={someFilesFailedModal}
                    heading={t("headingSomeFileFailed")}
                    onClick={() => hideModal(MedicalCertificateModals.someUploadedFilesFailed)}
                    buttonText={t("okClose")}
                >
                    <div>
                        <p>{t("uploadFileSomeFilesFailed")}</p>
                    </div>
                </Popout>
            )}

            <UploadDoctorCertificate
                openModal={uploadCertificateModal}
                setOpen={() => hideModal(MedicalCertificateModals.uploadCertificate)}
                close={() => hideModal(MedicalCertificateModals.uploadCertificate)}
                certificateDatePickerProps={certificateDatepickerProps}
            />

            <DeleteMedicalModal />
        </>
    );
};

export default Modals;
