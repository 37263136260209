import { useEffect } from "react";

/**
 *
 * @param callback triggers whenever you press the selected key
 *
 */
export const useKeyDownEvent = (key: "Escape", callback: any) => {
    useEffect(() => {
        const detectEscape = (event: KeyboardEvent) => {
            if (event.key === key) callback();
        };
        window.addEventListener("keydown", detectEscape);
        return () => {
            window.removeEventListener("keydown", detectEscape);
        };
    }, [key, callback]);
};
