import { useCallback, useMemo } from "react";
import { get, isEmpty } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { useAppDispatch } from "store/hooks";
import { actions, selectors } from "../../redux";
import { sortWorkRelated, findTagByReferenceKey } from "../../utils";
import WorkRelatedFilter from "./WorkRelatedFilter";

export default function Index() {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const filters = useAppSelector(selectors.getSearchTagsById("workRelated"));

    const Header = useMemo(() => t("workRelatedFilter.workRelated"), [t]);
    const Tags = useMemo(() => {
        const getCheckedItems = filters.filter(get("checked"));
        if (isEmpty(getCheckedItems)) return "";
        return sortWorkRelated(getCheckedItems)
            .map((x) => t(x.label))
            .join(", ");
    }, [filters, t]);

    const onChange = useCallback(
        (value: string) => {
            const element = findTagByReferenceKey(value, filters);
            if (!element) return;
            dispatch(
                actions.updateSearchFilter({
                    ...element,
                    checked: !element.checked,
                }),
            );
        },
        [dispatch, filters],
    );
    const Filters = useMemo(() => sortWorkRelated(filters).map((x) => ({ ...x, label: t(x.label) })), [filters, t]);
    return <WorkRelatedFilter onChange={onChange} tags={Tags} header={Header} items={Filters} />;
}
