const filterStringArray = (arrayToFilter: string[], itemToRemove: string): string[] => {
    return arrayToFilter.filter((c) => c !== itemToRemove);
};

export const getPwdMissing = (pwd: string, t: any): string[] => {
    let missing = [
        t("smallLetter"),
        t("capitalLetter"),
        t("number"),
        t("specialChar"),
        t("length", { charsLeft: 9 - pwd.length }),
    ];
    if (new RegExp(/[a-z]/).test(pwd)) missing = filterStringArray(missing, t("smallLetter"));
    if (new RegExp(/[A-Z]/).test(pwd)) missing = filterStringArray(missing, t("capitalLetter"));
    if (new RegExp(/(?=.*?[0-9])/).test(pwd)) missing = filterStringArray(missing, t("number"));
    if (new RegExp(/(?=.*?[#?!@$%^&*-])/).test(pwd)) missing = filterStringArray(missing, t("specialChar"));
    if (new RegExp(/^.{9,}$/i).test(pwd))
        missing = filterStringArray(missing, t("length", { charsLeft: 9 - pwd.length }));
    return missing;
};
