import { useState } from "react";
import RecursiveInput from "./RecursiveInput";
import { IWidgetMap } from "../Widget/widgetMap";
import { IDynamicData, WidgetDropdownTypeKeys } from "pages/dashboard/utils/dashboardTypes";

interface IRecursiveComponent {
    data: IDynamicData;
    handleClick: (id: WidgetDropdownTypeKeys, referenceKey: string) => void;
    translate: boolean;
    id: WidgetDropdownTypeKeys;
    currentWidget: IWidgetMap;
}

const RecursiveComponent = ({ data, handleClick, translate, id, currentWidget }: IRecursiveComponent) => {
    const [showChildren, setShowChildren] = useState<boolean | null>(null);
    const hasChildren = data.children?.length! > 0;

    return (
        <div>
            <RecursiveInput
                id={id}
                data={data}
                handleClick={handleClick}
                hasChildren={hasChildren}
                showChildren={showChildren!}
                setShowChildren={setShowChildren}
                translate={translate}
                currentWidget={currentWidget}
            />
            <div className="pl-4">
                {hasChildren &&
                    showChildren &&
                    data.children?.map((childData) => (
                        <RecursiveComponent
                            id={id}
                            data={childData}
                            key={childData.id}
                            handleClick={handleClick}
                            translate={translate}
                            currentWidget={currentWidget}
                        />
                    ))}
            </div>
        </div>
    );
};

export default RecursiveComponent;
