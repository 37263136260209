import Icon, { Icons } from "components/Icon";

import { ReactNode } from "react";

interface StatusMessageProps {
    title: string;
    text: string;
    icon: Icons;
    button?: ReactNode;
}

const StatusMessage = ({ title, text, icon, button }: StatusMessageProps) => (
    <div className="flex flex-col bg-[#FFECE8] border-l-6 border-mhred-alert3">
        <div className="flex gap-4 p-8 pl-4">
            <div className="text-mhred-alert3">
                <Icon className="stroke-mhred-alert3" size={24} icon={icon} />
            </div>
            <div className="flex flex-col gap-3">
                <div className="font-bold">{title}</div>
                <div>{text}</div>
                {button && button}
            </div>
        </div>
    </div>
);

export default StatusMessage;
