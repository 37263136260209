import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import { getDiffInTimeUnit } from "utils/date";

export const isRiskAbsenceCause = (fields: IFieldTemplateExtended[]): boolean => {
    return fields.some((field) => field.name === "AbsenceCause" && field.value === "MentalSocialHealth");
};

export const isRiskBackAtWorkDate = (selectedAbsenceReport: IAbsenceReportExtended): boolean => {
    if (selectedAbsenceReport.backAtWork && selectedAbsenceReport.start) {
        return getDiffInTimeUnit("days", "forward", selectedAbsenceReport.start, selectedAbsenceReport.backAtWork) > 7;
    } else {
        return true;
    }
};
