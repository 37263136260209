const SpinnerWithOverlay = () => (
    <div className="absolute inset-0 h-full w-full bg-loadingBg opacity-90 cursor-default z-20 min-h-64">
        <div className="flex h-full">
            <div className="lds-ring m-auto">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
);

export default SpinnerWithOverlay;
