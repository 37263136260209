import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    MedHelpAbsenceReportingDomainTemplate,
    MedHelpAbsenceReportingWebApiModelsConfigurationField,
    MedHelpAbsenceReportingWebApiModelsAbsenceReportField,
    MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree,
    MedHelpAbsenceReportingWebApiModelsMedicalCertificate,
    MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel,
    MedHelpAbsenceReportingDomainFieldTemplate,
} from "swagger/absencereportapi";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { IAbsenceReportEmploymentExtended } from "interfaces/IAbsenceReportEmployment";
import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import { IEmploymentSearchResult } from "interfaces/IEmploymentSearchResult";
import { IPrincess } from "interfaces/IPrincess";
import * as Builders from "./builders";
import { flow, isEmpty } from "lodash/fp";
import { TemporarySubscription } from "swagger/messenger";
import { MedHelpPeopleDomainLocalSearch } from "swagger/people";
import {
    createUpdatedAbsenceReportDateModel,
    createUpdatedAbsenceReportFieldModel,
    getAbsenceReportFieldByName,
} from "../utils/absenceReports";
import { filterAndSetFirstDegreeToStartDate, getCurrentDegree } from "utils/degrees";
import { newDateString, isSameDateMinute } from "utils/date";

export interface IAbsenceReportSlice {
    Templates: MedHelpAbsenceReportingDomainTemplate[] | null;
    AbsenceReports: IAbsenceReportExtended[] | null;
    RecentEmploymentSearches: MedHelpPeopleDomainLocalSearch[] | null;
    ConfigurationFields: MedHelpAbsenceReportingWebApiModelsConfigurationField[] | null;
    SelectedEmployee: IAbsenceReportEmploymentExtended | null;
    SelectedAbsenceReport: IAbsenceReportExtended | null;
    IsOK: boolean | null;
    IsSelectedAbsenceReportOK: boolean | null;
    CreateFinishedAbsenceReportOK: boolean | null;
    EditFinishedAbsenceReportOK: boolean | null;
    FirstDayMedicalCertificate: MedHelpAbsenceReportingWebApiModelsMedicalCertificate[] | null;
    Princess: IPrincess;
    SearchResult: IEmploymentSearchResult[];
    CompanySpecificAbsenceConfirmation: string | null;
    TemporarySubscriptions: TemporarySubscription[];
    SendAbsenceNotificationIsError: boolean;
    absenceReportPageNumber: number;
    showAbsenceCauseToEmployer: "true" | "false" | null;
    isRisk: boolean;
}

const initialAbsenceReportsState: IAbsenceReportSlice = {
    Templates: null,
    AbsenceReports: null,
    RecentEmploymentSearches: null,
    ConfigurationFields: null,
    SelectedEmployee: null,
    SelectedAbsenceReport: null,
    IsOK: null,
    IsSelectedAbsenceReportOK: null,
    CreateFinishedAbsenceReportOK: null,
    EditFinishedAbsenceReportOK: null,
    FirstDayMedicalCertificate: null,
    SearchResult: [],
    Princess: { childSsn: "", contactReasonChange: "", noteToEmployer: "" },
    CompanySpecificAbsenceConfirmation: null,
    TemporarySubscriptions: [],
    SendAbsenceNotificationIsError: false,
    absenceReportPageNumber: 1,
    showAbsenceCauseToEmployer: null,
    isRisk: false,
};

interface IUpdateBaseDates {
    fieldName: string;
    value: string;
    endDate: string | undefined;
}

interface IUpdateReportDegrees {
    value: string;
    timeStamp: string | undefined;
}

interface IUpdateReportField {
    fieldName: string;
    value: string;
    templateFields: MedHelpAbsenceReportingDomainFieldTemplate[];
}

export interface IUpdateSelectedAbsenceReportDates {
    startDate: string | undefined;
    backAtWork: string | undefined;
    endDate: string | undefined;
}

const absenceReportsSlice = createSlice({
    name: "absenceReports",
    initialState: initialAbsenceReportsState,
    reducers: {
        replaceAbsenceReportRecentEmploymentSearches(
            state: any,
            action: PayloadAction<MedHelpPeopleDomainLocalSearch[]>,
        ) {
            state.RecentEmploymentSearches = action.payload;
        },
        updateSelectedEmploymnent(state, action: PayloadAction<IAbsenceReportEmploymentExtended | null>) {
            state.SelectedEmployee = action.payload;
        },
        // on load
        updateStatus(state, action: PayloadAction<boolean>) {
            state.IsOK = action.payload;
        },
        updateAbsenceReportWithFields(state: any, action: PayloadAction<any>) {
            const absenceReports = state.AbsenceReports.map((ar: IAbsenceReportExtended) => {
                if (ar.id === action.payload.absenceReportId) {
                    return {
                        ...ar,
                        fields: action.payload.fields,
                    };
                }
                return ar;
            });
            state.AbsenceReports = absenceReports;
        },
        updateAbsenceReportField(
            state: any,
            action: PayloadAction<MedHelpAbsenceReportingWebApiModelsAbsenceReportField>,
        ) {
            state.AbsenceReports = state.AbsenceReports.map((ar: IAbsenceReportExtended) => {
                if (ar.id === action.payload.absenceReportId) {
                    const fieldExists = ar.fields?.find(
                        (field: IFieldTemplateExtended) => field.name === action.payload.name,
                    );
                    if (fieldExists) {
                        const fields = ar.fields?.map((field: IFieldTemplateExtended) => {
                            if (field.name === action.payload.name) {
                                field.value = action.payload.value!;
                                field.confidential = action.payload.confidential!;
                                return field;
                            }
                            return field;
                        });
                        ar.fields = fields;
                    } else {
                        const field: IFieldTemplateExtended = {
                            id: action.payload.id!,
                            templateId: action.payload.templateId,
                            name: action.payload.name,
                            value: action.payload.value!,
                            confidential: action.payload.confidential,
                        };
                        ar.fields = [...ar.fields!, field];
                    }
                }
                return ar;
            });
        },
        updateAbsenceReportBaseDates(state: any, action: PayloadAction<any>) {
            const absenceReports = state.AbsenceReports.map((ar: IAbsenceReportExtended) => {
                if (ar.id === action.payload.absenceReportId) {
                    ar.start = action.payload.absenceReportBaseDatesUpdate.start;
                    ar.backAtWork = action.payload.absenceReportBaseDatesUpdate.backAtWork;
                    return ar;
                }
                return ar;
            });
            state.AbsenceReports = absenceReports;
        },
        updateAbsenceReportDegrees(state: any, action: PayloadAction<any>) {
            const degree: MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree = {
                timeStamp: action.payload.absenceReportDegree.timeStamp,
                degree: action.payload.absenceReportDegree.degree,
                id: action.payload.absenceReportDegree.absenceReportDegreeId,
            };
            const absenceReports = state.AbsenceReports.map((ar: IAbsenceReportExtended) => {
                if (ar.id === action.payload.absenceReportDegree.absenceReportId) {
                    ar.degrees?.push(degree);
                    return ar;
                }
                return ar;
            });
            state.AbsenceReports = absenceReports;
        },
        updateSelectedAbsenceReportWithFields(
            state: any,
            action: PayloadAction<MedHelpAbsenceReportingWebApiModelsAbsenceReportField[]>,
        ) {
            state.SelectedAbsenceReport.fields = action.payload;
        },
        updateSelectedAbsenceReportStatus(state, action: PayloadAction<boolean>) {
            state.IsSelectedAbsenceReportOK = action.payload;
        },
        updateSelectedAbsenceReport(state: any, action: PayloadAction<IAbsenceReportExtended>) {
            state.SelectedAbsenceReport = action.payload;
        },
        updateSelectedAbsenceReportBase(state: any, action: PayloadAction<IAbsenceReportExtended>) {
            state.SelectedAbsenceReport = {
                ...state.SelectedAbsenceReport,
                start: action.payload.start,
                backAtWork: action.payload.end,
                degree: action.payload.degree,
                employmentId: action.payload.employmentId,
                suppressNotification: false,
                degrees: [
                    {
                        degree: action.payload.degree,
                        timeStamp: action.payload.start,
                    },
                ],
            };
        },
        updateSelectedAbsenceReportBaseDates(state, action: PayloadAction<IUpdateBaseDates>) {
            const { fieldName, value, endDate } = action.payload;
            const updateDateModel = createUpdatedAbsenceReportDateModel(fieldName, value, endDate);
            if (
                state.SelectedAbsenceReport?.degrees &&
                !isEmpty(state.SelectedAbsenceReport?.degrees) &&
                updateDateModel.start &&
                updateDateModel.start !== state.SelectedAbsenceReport.start
            ) {
                const degrees = filterAndSetFirstDegreeToStartDate(
                    state.SelectedAbsenceReport.degrees,
                    updateDateModel.start,
                );
                const currentDegree = getCurrentDegree(degrees);
                Object.assign(state.SelectedAbsenceReport, {
                    ...state.SelectedAbsenceReport,
                    start: updateDateModel.start,
                    backAtWork: updateDateModel.backAtWork,
                    degrees: degrees,
                    degree: currentDegree ?? state.SelectedAbsenceReport.degree,
                });
            } else {
                if (state.SelectedAbsenceReport) {
                    Object.assign(state.SelectedAbsenceReport, {
                        ...state.SelectedAbsenceReport,
                        start: updateDateModel.start,
                        backAtWork: updateDateModel.backAtWork,
                    });
                }
            }
        },
        updateSelectedAbsenceReportDates(state, action: PayloadAction<IUpdateSelectedAbsenceReportDates>) {
            if (state.SelectedAbsenceReport) {
                state.SelectedAbsenceReport.start = action.payload.startDate;
                state.SelectedAbsenceReport.backAtWork = action.payload.backAtWork;
                state.SelectedAbsenceReport.end = action.payload.endDate;
            }
        },
        updateSelectedAbsenceReportDegrees(state, action: PayloadAction<IUpdateReportDegrees>) {
            const { value, timeStamp } = action.payload;
            const degree = {
                timeStamp: timeStamp ? timeStamp : newDateString(),
                degree: parseInt(value),
            };

            if (state.SelectedAbsenceReport) {
                const degrees = state.SelectedAbsenceReport.degrees;
                if (degrees && state.SelectedAbsenceReport.start) {
                    const existingDegree = degrees.find((d) => {
                        return isSameDateMinute(d.timeStamp, degree.timeStamp);
                    });
                    if (existingDegree) {
                        Object.assign(existingDegree, degree);
                    } else {
                        const localDegree = degrees.find((x) => !x.id);
                        localDegree ? Object.assign(localDegree, degree) : degrees.push(degree);
                    }

                    const currentDegree = getCurrentDegree(degrees);
                    if (currentDegree) {
                        state.SelectedAbsenceReport.degree = currentDegree;
                    }
                } else {
                    state.SelectedAbsenceReport.degrees = [degree];
                    state.SelectedAbsenceReport.degree = degree.degree;
                }
            }
        },
        updateSelectedAbsenceReportField(state, action: PayloadAction<IUpdateReportField>) {
            const { fieldName, value, templateFields } = action.payload;
            if (state.SelectedAbsenceReport && !isEmpty(templateFields)) {
                const selectedTemplateField = getAbsenceReportFieldByName(
                    state.SelectedAbsenceReport?.fields ?? [],
                    fieldName,
                );
                const field = getAbsenceReportFieldByName(templateFields, fieldName);

                if (
                    fieldName === "RiskMedicalAdvisoryRequest" &&
                    (selectedTemplateField?.value === value || (!selectedTemplateField && value === "false"))
                ) {
                    return;
                }
                if (field?.mandatory && value === "") return;

                const absenceReportField = createUpdatedAbsenceReportFieldModel(
                    state.SelectedAbsenceReport?.id!,
                    selectedTemplateField || field!,
                    value,
                );
                if (!state.SelectedAbsenceReport.fields || isEmpty(state.SelectedAbsenceReport.fields)) {
                    state.SelectedAbsenceReport.fields = [absenceReportField];
                } else {
                    const existingField = state.SelectedAbsenceReport.fields.find(
                        (field) => field.name === absenceReportField.name,
                    );
                    if (existingField) {
                        if (fieldName === "AbsenceCause" && (value === "true" || value === "false")) {
                            state.showAbsenceCauseToEmployer = value;
                            existingField.confidential = value === "true" ? false : true;
                        } else {
                            existingField.value = value;
                            existingField.confidential = absenceReportField.confidential;
                        }
                    } else {
                        state.SelectedAbsenceReport.fields.push(absenceReportField);
                    }
                }
            }
        },
        updateSelectedAbsenceReportIsLoading(state: any, action: PayloadAction<boolean>) {
            state.SelectedAbsenceReport = {
                ...state.SelectedAbsenceReport,
                isLoading: action.payload,
            };
        },
        updateSelectedAbsenceReportIsError(state: any, action: PayloadAction<boolean>) {
            state.SelectedAbsenceReport.isError = action.payload;
        },
        updateSelectedAbsenceType(state, action: PayloadAction<string | undefined>) {
            if (action.payload === "Sick" || action.payload === "CareOfChild") {
                state.SelectedAbsenceReport = {
                    ...state.SelectedAbsenceReport,
                    type: action.payload,
                };
            }
        },
        updateAbsenceReportIsLoading(state: any, action: PayloadAction<any>) {
            state.AbsenceReports = state.AbsenceReports.map((absenceReport: IAbsenceReportExtended) => {
                if (absenceReport.id === action.payload.id) {
                    return {
                        ...absenceReport,
                        isLoading: action.payload.isLoading,
                    };
                }
                return absenceReport;
            });
        },
        updateAbsenceReport(state: any, action: PayloadAction<IAbsenceReportExtended>) {
            const absenceReports = state.AbsenceReports.filter(
                (absenceReport: IAbsenceReportExtended) => absenceReport.id !== action.payload.id,
            );
            state.AbsenceReports = [action.payload, ...absenceReports];
        },
        updateAbsenceReportIsError(state: any, action: PayloadAction<any>) {
            state.AbsenceReports = state.AbsenceReports.map((absenceReport: IAbsenceReportExtended) => {
                if (absenceReport.id === action.payload.id) {
                    return {
                        ...absenceReport,
                        isError: action.payload.isError,
                    };
                }
                return absenceReport;
            });
        },
        updateAbsenceReportIsEdit(state: any, action: PayloadAction<any>) {
            state.AbsenceReports = state.AbsenceReports.map((absenceReport: IAbsenceReportExtended) => {
                if (absenceReport.id === action.payload.id) {
                    if (state.SelectedAbsenceReport && state.SelectedAbsenceReport.isPreliminary) {
                        return {
                            ...state.SelectedAbsenceReport,
                            isEdit: action.payload.isEdit,
                        };
                    }
                    return {
                        ...absenceReport,
                        isEdit: action.payload.isEdit,
                        fields: absenceReport.fields ?? [],
                    };
                }
                return absenceReport;
            });
            state.SelectedAbsenceReport = null;
        },
        updateCreateFinishedAbsenceReportOK(state: any, action: PayloadAction<boolean>) {
            state.CreateFinishedAbsenceReportOK = action.payload;
        },
        updateEditFinishedAbsenceReportOK(state: any, action: PayloadAction<boolean>) {
            state.EditFinishedAbsenceReportOK = action.payload;
        },
        updatePrincessChildSsn(state: any, action: PayloadAction<string>) {
            state.Princess.childSsn = action.payload;
        },
        updatePrincessContactReasonChange(state: any, action: PayloadAction<string>) {
            state.Princess.contactReasonChange = action.payload;
        },
        updatePrincessNoteToEmployer(state: any, action: PayloadAction<string>) {
            state.Princess.noteToEmployer = action.payload;
        },
        updateAbsenceReportPageNumber(state, action: PayloadAction<"next" | "previous">) {
            if (action.payload === "next") {
                state.absenceReportPageNumber = state.absenceReportPageNumber + 1;
            } else {
                state.absenceReportPageNumber = state.absenceReportPageNumber - 1;
            }
        },
        updateShowAbsenceCauseToEmployer(state, action: PayloadAction<"true" | "false">) {
            state.showAbsenceCauseToEmployer = action.payload;
        },
        updateSuppressNotification(state, action: PayloadAction<boolean>) {
            state.SelectedAbsenceReport = {
                ...state.SelectedAbsenceReport,
                suppressNotification: action.payload,
            };
        },
        addFinishedReport(state: any, action: PayloadAction<IAbsenceReportExtended>) {
            const absenceReports = [...state.AbsenceReports, action.payload];
            state.AbsenceReports = absenceReports;
        },
        submitSelectedAbsenceReport(
            state: any,
            action: PayloadAction<MedHelpAbsenceReportingWebApiModelsV2SubmitAbsenceReportModel>,
        ) {
            const absenceReports = state.AbsenceReports.filter(
                (absenceReport: IAbsenceReportExtended) => absenceReport.id !== action.payload.id,
            );

            state.AbsenceReports = [
                ...absenceReports,
                {
                    ...action.payload,
                    end: null,
                    status: "Ongoing",
                    isPreliminary: false,
                    isEdit: false,
                },
            ];
            state.SelectedAbsenceReport = action.payload;
        },
        finishAbsenceReport(state: any, action: PayloadAction<IAbsenceReportExtended>) {
            const absenceReports = state.AbsenceReports.filter(
                (absenceReport: IAbsenceReportExtended) => absenceReport.id !== action.payload.id,
            );
            state.AbsenceReports = [...absenceReports, action.payload];
        },
        deleteAbsenceReport(state: any, action: PayloadAction<IAbsenceReportExtended>) {
            state.AbsenceReports = state.AbsenceReports.filter(
                (absenceReport: IAbsenceReportExtended) => absenceReport.id !== action.payload.id,
            );
        },
        deleteSelectedAbsenceReportField(state: any, action: PayloadAction<IFieldTemplateExtended>) {
            if (state.SelectedAbsenceReport) {
                if (state.SelectedAbsenceReport.fields) {
                    const fields = state.SelectedAbsenceReport.fields.filter(
                        (field: MedHelpAbsenceReportingWebApiModelsAbsenceReportField) =>
                            field.name !== action.payload.name,
                    );
                    state.SelectedAbsenceReport.fields = fields;
                }
            }
        },
        resetSelectedAbsenceReport(state) {
            state.SelectedAbsenceReport = null;
            state.SendAbsenceNotificationIsError = false;
            state.showAbsenceCauseToEmployer = null;
            state.absenceReportPageNumber = 1;
        },
        resetSendAbsenceNotificationIsError(state) {
            state.SendAbsenceNotificationIsError = false;
        },
        reset(state) {
            Object.assign(state, initialAbsenceReportsState);
        },
    },
    extraReducers: (builder) => {
        flow(
            Builders.absenceReportInit,
            Builders.companySpecificAbsenceConfirmation,
            Builders.employmentByQuery,
            Builders.employmentBySSN,
            Builders.absenceReports,
            Builders.temporarySubscriptions,
            Builders.openHistoricalAbsenceReport,
            Builders.editHistoricalAbsenceReport,
            Builders.getIsRisk,
        )(builder);
    },
});

export const absenceReportsActions = absenceReportsSlice.actions;

export default absenceReportsSlice.reducer;
