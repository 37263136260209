import Chart from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree } from "swagger/absencereportapi";

interface IAbsenceDegree {
    date: string;
    degree: number;
}

interface IAbsenceReportDegreeLineChart {
    absenceReport: IAbsenceReportExtended;
    primaryColor: string;
    secondaryColor: string;
}

const AbsenceReportDegreeLineChart = ({ absenceReport, primaryColor }: IAbsenceReportDegreeLineChart) => {
    const chartContainer = useRef(null);
    const [absenceDegrees, setAbsenceDegrees] = useState<IAbsenceDegree[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        let absenceDegreesCollection = absenceReport?.degrees?.map(
            (absenceDegree: MedHelpAbsenceReportingWebApiModelsAbsenceReportDegree) => {
                const date = absenceDegree.timeStamp ? absenceDegree.timeStamp?.substring(0, 10) : "0000-00-00";
                const degree = absenceDegree.degree ? absenceDegree.degree : 0;
                return { date, degree };
            },
        );

        if (absenceDegreesCollection) {
            let orderedCollection = _.orderBy(absenceDegreesCollection, "date", "asc");

            orderedCollection?.push({
                date: absenceReport.backAtWork ? absenceReport.backAtWork.substring(0, 10) : "0000-00-00",
                degree: orderedCollection[orderedCollection.length - 1].degree,
            });
            setAbsenceDegrees(orderedCollection);
        }
    }, [absenceReport]);

    useEffect(() => {
        if (chartContainer && chartContainer.current && absenceDegrees.length > 0) {
            const newChartInstance = new Chart(chartContainer.current, {
                type: "line",
                data: {
                    labels: absenceDegrees.map((degree: IAbsenceDegree) => degree.date),
                    datasets: [
                        {
                            label: t("absenceDegree"),
                            data: absenceDegrees.map((degree: IAbsenceDegree) => degree.degree),
                            fill: true,
                            borderColor: primaryColor,
                            pointRadius: 1,
                            pointHitRadius: 6,
                            pointHoverBorderWidth: 2,
                            pointHoverBackgroundColor: "#FFFFFF",
                            pointHoverBorderColor: primaryColor,
                            borderCapStyle: "round",
                            borderWidth: 3,
                            tension: 0.4,
                        },
                    ],
                },
                options: {
                    animation: {
                        duration: 0,
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    elements: {
                        line: {
                            stepped: true,
                        },
                    },
                    aspectRatio: 1,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (x: any) => `${` ${x.dataset.label}: ${x.formattedValue}`}%`,
                            },
                            bodySpacing: 8,
                            caretPadding: 16,
                            boxWidth: 6,
                            boxHeight: 6,
                            caretSize: 0,
                            usePointStyle: true,
                            mode: "x",
                            intersect: false,
                            titleColor: "#192D05",
                            bodyColor: "#A0A0A0",
                            backgroundColor: "#FFFFFF",
                            cornerRadius: 0,
                            padding: 20,
                            bodyFont: {
                                size: 10,
                            },
                            titleFont: {
                                size: 12,
                            },
                        },
                        legend: {
                            display: false,
                        },
                    },
                    hover: {
                        mode: "x",
                        intersect: false,
                    },
                    scales: {
                        x: {
                            ticks: {
                                font: {
                                    size: 10,
                                    // @ts-ignore
                                    font: "LabGrotesque",
                                },
                                color: "#5A5F4E",
                            },
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            min: 0,
                            ticks: {
                                callback: (value: any) => `${value}%`,
                                stepSize: 5,
                                font: {
                                    size: 10,
                                    // @ts-ignore
                                    font: "LabGrotesque",
                                },
                                color: "#5A5F4E",
                            },
                            grid: {
                                lineWidth: 0.5,
                                drawBorder: false,
                                color: "#8F957F",
                            },
                        },
                    },
                },
            });
            return () => newChartInstance.destroy();
        }
    }, [chartContainer, absenceDegrees, primaryColor, t]);

    return (
        <div className="py-4">
            <div>
                <div className="h-56 pb-2 max-w-2xl">
                    <canvas ref={chartContainer} />
                </div>
            </div>
        </div>
    );
};

export default AbsenceReportDegreeLineChart;
