/* tslint:disable */
/* eslint-disable */
/**
 * MedHelp.Storage
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface FileInfoModel
 */
export interface FileInfoModel {
    /**
     * 
     * @type {number}
     * @memberof FileInfoModel
     */
    'userAccountId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoModel
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileInfoModel
     */
    'fileSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileInfoModel
     */
    'fileType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfoModel
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfoModel
     */
    'bucket'?: string | null;
}
/**
 * 
 * @export
 * @interface MetaData
 */
export interface MetaData {
    /**
     * 
     * @type {string}
     * @memberof MetaData
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MetaData
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface S3FileInfo
 */
export interface S3FileInfo {
    /**
     * 
     * @type {string}
     * @memberof S3FileInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof S3FileInfo
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof S3FileInfo
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof S3FileInfo
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof S3FileInfo
     */
    'groupKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof S3FileInfo
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof S3FileInfo
     */
    'url': string;
    /**
     * 
     * @type {Array<MetaData>}
     * @memberof S3FileInfo
     */
    'metaData'?: Array<MetaData> | null;
    /**
     * 
     * @type {string}
     * @memberof S3FileInfo
     */
    'bucket'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof S3FileInfo
     */
    'userAccountId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof S3FileInfo
     */
    'owner'?: string | null;
}
/**
 * 
 * @export
 * @interface S3FileInfoPagedResponse
 */
export interface S3FileInfoPagedResponse {
    /**
     * 
     * @type {number}
     * @memberof S3FileInfoPagedResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof S3FileInfoPagedResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof S3FileInfoPagedResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<S3FileInfo>}
     * @memberof S3FileInfoPagedResponse
     */
    'page'?: Array<S3FileInfo> | null;
}
/**
 * 
 * @export
 * @interface UpdateMetaDataModel
 */
export interface UpdateMetaDataModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateMetaDataModel
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMetaDataModel
     */
    'groupKey': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMetaDataModel
     */
    'metaData'?: string | null;
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} owner 
         * @param {string} groupKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesDelete: async (owner: string, groupKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'owner' is not null or undefined
            assertParamExists('filesDelete', 'owner', owner)
            // verify required parameter 'groupKey' is not null or undefined
            assertParamExists('filesDelete', 'groupKey', groupKey)
            const localVarPath = `/Files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (groupKey !== undefined) {
                localVarQueryParameter['groupKey'] = groupKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} owner 
         * @param {string} type 
         * @param {string} [groupKey] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGet: async (owner: string, type: string, groupKey?: string, pageNumber?: number, pageSize?: number, sortOrder?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'owner' is not null or undefined
            assertParamExists('filesGet', 'owner', owner)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('filesGet', 'type', type)
            const localVarPath = `/Files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (groupKey !== undefined) {
                localVarQueryParameter['groupKey'] = groupKey;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} owners 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGetFilesFromOwnersGet: async (owners: Array<string>, type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'owners' is not null or undefined
            assertParamExists('filesGetFilesFromOwnersGet', 'owners', owners)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('filesGetFilesFromOwnersGet', 'type', type)
            const localVarPath = `/Files/GetFilesFromOwners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (owners) {
                localVarQueryParameter['owners'] = owners;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [owner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesIdDelete: async (id: string, owner?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesIdDelete', 'id', id)
            const localVarPath = `/Files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesIdDownloadGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesIdDownloadGet', 'id', id)
            const localVarPath = `/Files/{id}/Download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesIdDownloadRawGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesIdDownloadRawGet', 'id', id)
            const localVarPath = `/Files/{id}/DownloadRaw`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesIdGet', 'id', id)
            const localVarPath = `/Files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} owner 
         * @param {string} type 
         * @param {number} [numberOfFiles] 
         * @param {string} [groupKey] 
         * @param {File} [file] 
         * @param {string} [metaData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost: async (owner: string, type: string, numberOfFiles?: number, groupKey?: string, file?: File, metaData?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'owner' is not null or undefined
            assertParamExists('filesPost', 'owner', owner)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('filesPost', 'type', type)
            const localVarPath = `/Files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (owner !== undefined) { 
                localVarFormParams.append('Owner', owner as any);
            }
    
            if (type !== undefined) { 
                localVarFormParams.append('Type', type as any);
            }
    
            if (numberOfFiles !== undefined) { 
                localVarFormParams.append('NumberOfFiles', numberOfFiles as any);
            }
    
            if (groupKey !== undefined) { 
                localVarFormParams.append('GroupKey', groupKey as any);
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
            if (metaData !== undefined) { 
                localVarFormParams.append('MetaData', metaData as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMetaDataModel} [updateMetaDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPut: async (updateMetaDataModel?: UpdateMetaDataModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMetaDataModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FileInfoModel} [fileInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesSaveFileInfoPost: async (fileInfoModel?: FileInfoModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Files/SaveFileInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileInfoModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} owner 
         * @param {string} groupKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesDelete(owner: string, groupKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesDelete(owner, groupKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} owner 
         * @param {string} type 
         * @param {string} [groupKey] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesGet(owner: string, type: string, groupKey?: string, pageNumber?: number, pageSize?: number, sortOrder?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<S3FileInfoPagedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesGet(owner, type, groupKey, pageNumber, pageSize, sortOrder, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} owners 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesGetFilesFromOwnersGet(owners: Array<string>, type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<S3FileInfoPagedResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesGetFilesFromOwnersGet(owners, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [owner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesIdDelete(id: string, owner?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesIdDelete(id, owner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesIdDownloadGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesIdDownloadGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesIdDownloadRawGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesIdDownloadRawGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<S3FileInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} owner 
         * @param {string} type 
         * @param {number} [numberOfFiles] 
         * @param {string} [groupKey] 
         * @param {File} [file] 
         * @param {string} [metaData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesPost(owner: string, type: string, numberOfFiles?: number, groupKey?: string, file?: File, metaData?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesPost(owner, type, numberOfFiles, groupKey, file, metaData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateMetaDataModel} [updateMetaDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesPut(updateMetaDataModel?: UpdateMetaDataModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesPut(updateMetaDataModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FileInfoModel} [fileInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesSaveFileInfoPost(fileInfoModel?: FileInfoModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesSaveFileInfoPost(fileInfoModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} owner 
         * @param {string} groupKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesDelete(owner: string, groupKey: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.filesDelete(owner, groupKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} owner 
         * @param {string} type 
         * @param {string} [groupKey] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGet(owner: string, type: string, groupKey?: string, pageNumber?: number, pageSize?: number, sortOrder?: string, options?: any): AxiosPromise<Array<S3FileInfoPagedResponse>> {
            return localVarFp.filesGet(owner, type, groupKey, pageNumber, pageSize, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} owners 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGetFilesFromOwnersGet(owners: Array<string>, type: string, options?: any): AxiosPromise<Array<S3FileInfoPagedResponse>> {
            return localVarFp.filesGetFilesFromOwnersGet(owners, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [owner] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesIdDelete(id: string, owner?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.filesIdDelete(id, owner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesIdDownloadGet(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.filesIdDownloadGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesIdDownloadRawGet(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.filesIdDownloadRawGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesIdGet(id: string, options?: any): AxiosPromise<S3FileInfo> {
            return localVarFp.filesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} owner 
         * @param {string} type 
         * @param {number} [numberOfFiles] 
         * @param {string} [groupKey] 
         * @param {File} [file] 
         * @param {string} [metaData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPost(owner: string, type: string, numberOfFiles?: number, groupKey?: string, file?: File, metaData?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.filesPost(owner, type, numberOfFiles, groupKey, file, metaData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMetaDataModel} [updateMetaDataModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesPut(updateMetaDataModel?: UpdateMetaDataModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.filesPut(updateMetaDataModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileInfoModel} [fileInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesSaveFileInfoPost(fileInfoModel?: FileInfoModel, options?: any): AxiosPromise<boolean> {
            return localVarFp.filesSaveFileInfoPost(fileInfoModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @param {string} owner 
     * @param {string} groupKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesDelete(owner: string, groupKey: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesDelete(owner, groupKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} owner 
     * @param {string} type 
     * @param {string} [groupKey] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [sortOrder] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesGet(owner: string, type: string, groupKey?: string, pageNumber?: number, pageSize?: number, sortOrder?: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesGet(owner, type, groupKey, pageNumber, pageSize, sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} owners 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesGetFilesFromOwnersGet(owners: Array<string>, type: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesGetFilesFromOwnersGet(owners, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [owner] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesIdDelete(id: string, owner?: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesIdDelete(id, owner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesIdDownloadGet(id: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesIdDownloadGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesIdDownloadRawGet(id: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesIdDownloadRawGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesIdGet(id: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} owner 
     * @param {string} type 
     * @param {number} [numberOfFiles] 
     * @param {string} [groupKey] 
     * @param {File} [file] 
     * @param {string} [metaData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesPost(owner: string, type: string, numberOfFiles?: number, groupKey?: string, file?: File, metaData?: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesPost(owner, type, numberOfFiles, groupKey, file, metaData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateMetaDataModel} [updateMetaDataModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesPut(updateMetaDataModel?: UpdateMetaDataModel, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesPut(updateMetaDataModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileInfoModel} [fileInfoModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesSaveFileInfoPost(fileInfoModel?: FileInfoModel, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).filesSaveFileInfoPost(fileInfoModel, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet(options?: any): AxiosPromise<void> {
            return localVarFp.pingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingGet(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).pingGet(options).then((request) => request(this.axios, this.basePath));
    }
}



