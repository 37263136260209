import { useAppSelector } from "store/hooks";
import { useMemo } from "react";
import { selectors } from "../../redux";
import ShowAffectedBasedOnFilter from "./ShowAffectedBasedOnFilter";

export default function Index() {
    const searchType = useAppSelector(selectors.getCurrentSearch);
    const shouldRender = useMemo(
        () =>
            searchType === "absencePeriod" ||
            searchType === "ongoingAbsence" ||
            searchType === "cases" ||
            searchType === "activities",
        [searchType],
    );
    if (!shouldRender) return <div />;
    return <ShowAffectedBasedOnFilter searchType={searchType} />;
}
