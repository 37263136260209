import Icon from "components/Icon";
import { times } from "lodash/fp";
import { useMemo } from "react";
export interface IProps {
    pages: number;
    activePage: number;
    onPageClick: (value: number) => void;
    onBackButtonClick: (value: number) => void;
    onForwardButtonClick: (value: number) => void;
}

const Paginator = ({ pages, activePage, onPageClick, onBackButtonClick, onForwardButtonClick }: IProps) => {
    const PageNumbers = useMemo(() => {
        return {
            numberOfPages: pages,
            dotsPosition: {
                left: activePage - 1 > 2 && pages > 6,
                right: pages - activePage > 2 && pages > 6,
            },
            isFirst: Boolean(activePage === 1),
            isLast: Boolean(activePage === pages),
        };
    }, [activePage, pages]);
    const NumberOfPages = useMemo(() => {
        if (pages < 7) {
            return times((value) => value + 1, pages);
        }
        if (PageNumbers.dotsPosition.left && PageNumbers.dotsPosition.right) {
            return [1, activePage - 1, activePage, activePage + 1, pages];
        } else if (PageNumbers.dotsPosition.right) {
            return [...times((value) => value + 1, 5), pages];
        } else if (PageNumbers.dotsPosition.left) {
            return [1, ...times((value) => pages - 5 + (value + 1), 5)];
        }
        return [];
    }, [PageNumbers, activePage, pages]);

    return (
        <div className="flex items-center gap-2">
            <div
                role="button"
                onClick={() => {
                    if (PageNumbers.isFirst) return;
                    onBackButtonClick((activePage -= 1));
                }}
            >
                <Icon
                    icon="caretLeft"
                    size={12}
                    className={PageNumbers.isFirst ? "text-background-300" : "text-mhdarkgreen-original"}
                />
            </div>
            <div className="flex ">
                {NumberOfPages.map((number, index) => {
                    return (
                        <div className="flex" key={`paginator-number-page-${number}-${index}`}>
                            {Boolean(number === pages) && PageNumbers.dotsPosition.right && (
                                <div className="w-[12px]">...</div>
                            )}
                            <div
                                className={` ${activePage === number ? "bg-background-300" : "bg-none"} px-2 w-[26px]`}
                                role="button"
                                onClick={() => onPageClick(number)}
                            >
                                {number}
                            </div>
                            {Boolean(number === 1) && PageNumbers.dotsPosition.left && <div>...</div>}
                        </div>
                    );
                })}
            </div>
            <div
                role="button"
                onClick={() => {
                    if (PageNumbers.isLast) return;

                    onForwardButtonClick((activePage += 1));
                }}
            >
                <Icon
                    icon="caretRight"
                    size={12}
                    className={PageNumbers.isLast ? "text-background-300" : "text-mhdarkgreen-original"}
                />
            </div>
        </div>
    );
};

export default Paginator;
