import ChartJS from "chart.js/auto";
import Icon from "components/Icon";
import * as React from "react";

interface IData {
    [key: string]: number | string;
}
interface IDataSets {
    label: string;
    data: IData[];
    parsing: {
        yAxisKey: string;
    };
    backgroundColor: string;
}
interface IProps {
    dataSets: IDataSets[];
    yLabel: (value: string | number) => string;
    labels: string[];
    size?: "sm" | "md" | "auto"; // auto should aut scale
    numberOfData: number;
}

const tooltipStyle = () => ({
    bodySpacing: 8,
    caretPadding: 16,
    boxWidth: 6,
    boxHeight: 6,
    caretSize: 0,
    mode: "x",
    intersect: false,
    titleColor: "#192D05",
    bodyColor: "#A0A0A0",
    backgroundColor: "#FFFFFF",
    cornerRadius: 0,
    padding: 20,
    bodyFont: {
        size: 10,
    },
    titleFont: {
        size: 12,
    },
});
const Chart = ({ dataSets, yLabel, labels, size = "sm", numberOfData }: IProps) => {
    const [canvas, setCanvas] = React.useState<HTMLCanvasElement | null>(null);
    const chart = React.useRef<ChartJS<any> | null>(null);
    const setChartCanvasNode = React.useCallback((node: HTMLCanvasElement | null) => {
        setCanvas(node);
    }, []);
    // Initialize the chart
    React.useEffect(() => {
        if (canvas) {
            const cfg = {
                type: "bar",
                data: {
                    labels,
                    datasets: dataSets,
                },
                options: {
                    layout: {
                        padding: {
                            bottom: 17,
                            left: 7,
                        },
                    },
                    plugins: {
                        tooltip: tooltipStyle(),
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        y: {
                            ticks: {
                                callback: yLabel,
                            },
                        },
                    },
                    categoryPercentage: 0.2 * numberOfData, // here
                    barPercentage: 0.8, // here
                    responsive: true,
                    maintainAspectRatio: false,
                },
            };
            chart.current?.destroy();
            // @ts-expect-error
            chart.current = new ChartJS(canvas, cfg);
        }
    }, [canvas, dataSets, labels, numberOfData, yLabel]);

    return (
        <div className="flex-col">
            <div className="flex flex-wrap text-sm py-4 px-6">
                {dataSets.map((dataset) => (
                    <div key={`${dataset.label}bar`} className="flex mx-2">
                        <Icon icon="roundedSquare" fill={dataset.backgroundColor} />
                        <label htmlFor={dataset.label.toString()}>
                            <p className="text-xs font-sans pl-1 cursor-pointer">{dataset.label}</p>
                        </label>
                    </div>
                ))}
            </div>
            <div className={size === "sm" ? "h-[250px]" : "h-[300px]"}>
                <canvas ref={setChartCanvasNode} />
            </div>
        </div>
    );
};

export default Chart;
