import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { declineHealthCase as declineHealthCaseAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const declineHealthCase = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(declineHealthCaseAction.fulfilled, (state, action) => {
            const currentCase = state.healthCases.find(({ id }) => id === action.payload);
            if (currentCase) {
                currentCase.status = "declined";
                currentCase.healthCaseStatus = Status.OK;
            }
        })
        .addCase(declineHealthCaseAction.pending, (state, action) => {
            const currentCase = state.healthCases.find(({ id }) => id === action.meta.arg.healthCaseId);
            if (currentCase) currentCase.healthCaseStatus = Status.PENDING;
        })
        .addCase(declineHealthCaseAction.rejected, (state, action) => {
            const currentCase = state.healthCases.find(({ id }) => id === action.meta.arg.healthCaseId);
            if (currentCase) currentCase.healthCaseStatus = Status.ERROR;
        });

export default declineHealthCase;
