import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Table, TableCell, TableRow } from "@medhelp/ui";
import { useSortableData } from "./hooks/useSortableData";
import { IEmployeeDetails } from "pages/dashboard/utils/dashboardTypes";

interface IRecurringAbsencesTable {
    data: IEmployeeDetails[];
    selectedNumberOfMonths: number;
}

export const RecurringAbsencesTable = ({ data, selectedNumberOfMonths }: IRecurringAbsencesTable) => {
    const navigate = useNavigate();
    const { t } = useTranslation("dashboard");
    const [sortedData, handleSort] = useSortableData(data, "absences");

    const headers = [
        { label: t("employees"), accessor: "employeeName" },
        { label: t("numberOfAbsenceOccasions"), accessor: "absences" },
    ];

    return (
        <Table
            className="lg:p-10"
            caption={
                <>
                    {data.length} {t("employees")}
                </>
            }
            headers={headers}
            handleSort={handleSort}
        >
            {sortedData?.map(({ employeeName, hasFollowUp, employmentId, absences }) => (
                <TableRow
                    key={employmentId}
                    onClick={
                        hasFollowUp && employmentId
                            ? () => navigate(`/followup/employee/${employmentId}/absence`)
                            : undefined
                    }
                >
                    <TableCell showOnMobile>{employeeName}</TableCell>
                    <TableCell>
                        {absences} {t("inMonths", { months: selectedNumberOfMonths })}
                    </TableCell>
                </TableRow>
            ))}
        </Table>
    );
};

export default RecurringAbsencesTable;
