import { IAdminCompProps } from "./types";
import { AdministrationTopLinks } from "./components";
import { Outlet } from "react-router-dom";

const Administration = ({ companyId, isCoaOrCs }: IAdminCompProps) => {
    return (
        <div id="administration-page-container">
            <AdministrationTopLinks companyId={companyId} isCoaOrCs={isCoaOrCs} />
            <Outlet />
        </div>
    );
};

export default Administration;
