import { useState } from "react";
import { ModalFrame } from "@medhelp/ui";
import { actions } from "pages/service/redux";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { searchActions } from "store/searchSlice";
import PrimaryButton from "components/PrimaryButton";
import { SpinnerWithOverlay } from "components/Spinner";
import { getActiveRetailerId } from "store/userSelectors";
import { Status } from "pages/service/redux/serviceSlice";
import CreateCompanyResults from "../CreateCompanyResults";
import { getCreatedCompanyIds, getStatus } from "pages/service/redux/serviceSelectors";
import CreateCustomerCompany from "../CreateCustomerOrCompany/CreateCustomerOrCompany";
import { fetchCustomersByRetailerId, fetchRegions } from "pages/service/redux/serviceAsyncActions";

const AddCustomerCompany = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const [wizardOpen, setWizardOpen] = useState(false);
    const activeRetailerId = useAppSelector(getActiveRetailerId);
    const [wizardIsLoading, setWizardIsLoading] = useState(false);
    const { createdCompanyApiResults: status } = useAppSelector(getStatus);
    const createdCompanyIds = useAppSelector(getCreatedCompanyIds);

    const handleCreateCompany = async () => {
        setWizardOpen(true);
        dispatch(actions.resetSelectedCompany());
        setWizardIsLoading(true);
        if (activeRetailerId) {
            const response = await Promise.allSettled([
                dispatch(fetchRegions()),
                dispatch(fetchCustomersByRetailerId(activeRetailerId)),
            ]);

            if (
                response[0].status === "fulfilled" &&
                response[0].value &&
                response[1].status === "fulfilled" &&
                response[1].value
            ) {
                setWizardIsLoading(false);
            } else {
                setWizardIsLoading(false);
                setWizardOpen(false);
            }
        }
    };

    const handleClose = () => {
        setWizardOpen(false);
        dispatch(
            searchActions.setActivePowerbarSearch({
                typeOfSearch: "companySearch",
                companyId: createdCompanyIds.companyId,
                customerId: createdCompanyIds.customerId,
                companyName: createdCompanyIds.companyName || "",
            }),
        );
    };

    return (
        <>
            <PrimaryButton
                id="serviceCreate"
                text={t("addNewCustomerOrCompany")}
                onClick={handleCreateCompany}
                className={"flex justify-center w-full sm:w-auto"}
            />

            {wizardOpen && (
                <ModalFrame
                    open={wizardOpen}
                    setOpen={setWizardOpen}
                    content={
                        wizardIsLoading || status === Status.PENDING ? (
                            <SpinnerWithOverlay />
                        ) : status === Status.OK ? (
                            <CreateCompanyResults handleClick={handleClose} />
                        ) : (
                            <CreateCustomerCompany />
                        )
                    }
                />
            )}
        </>
    );
};

export default AddCustomerCompany;
