import { useCallback, useMemo, useState } from "react";
import { useAppSelector } from "store/hooks";
import { useForm } from "react-hook-form";
import { useFileUploader } from "@medhelp/medical-certificate/hooks";
import { Appendix, UploadButton } from "@medhelp/medical-certificate/components";
import { useTranslation } from "react-i18next";
import { getServices } from "api/services";
import { SpinnerPage } from "components/Spinner";
import PrimaryButton from "components/PrimaryButton";
import { getUserContext, getUserRetailer, getRegion } from "store/userSelectors";
import SecondaryButton from "components/SecondaryButton";
import { ReactComponent as Phone } from "icons/phone.svg";
import FormTextInput from "./components/FormTextInput";
import FormSelect from "./components/FormSelect";
import FormImmutableFormTextInput from "./components/FormImmutableFormTextInput";

export interface IPreloadedValues {
    email: string;
    prefix: string;
    phoneNumber: string;
    caseNumber: string;
    role: string;
    supportType: string;
    subject: string;
    details: string;
}

const regionSupportNumbers = {
    Denmark: "+4532253225",
};

const retailerSupportNumbers = {
    Medhelp: "+46101475010",
    Avonova: "+46101475010",
    Feelgood: "+46101475030",
    Falck: "+46101475010",
};

type Region = keyof typeof regionSupportNumbers;

const Support = () => {
    const userContext = useAppSelector(getUserContext);
    const userRetailer = useAppSelector(getUserRetailer);
    const region = useAppSelector(getRegion);
    const { userAccount } = userContext;
    const { t } = useTranslation("support");
    const { getInputProps, getRootProps, files, removeFile, open, clearFiles } = useFileUploader(undefined, [
        ".xlsx",
        ".csv",
        ".txt",
    ]);
    const [caseNumber, setCaseNumber] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const getSupportNumber = () => {
        if (region && region in regionSupportNumbers) {
            return regionSupportNumbers[region as Region];
        } else {
            return retailerSupportNumbers[userRetailer];
        }
    };

    const defaultValues = useMemo(
        () => ({
            email: userAccount?.contactInformation?.email || "",
            prefix: userAccount?.contactInformation?.mobileCountryPrefix || "",
            phoneNumber: userAccount?.contactInformation?.telephoneNumber || "",
            caseNumber: "",
            role: "",
            supportType: "",
            subject: "",
            details: "",
        }),
        [
            userAccount?.contactInformation?.email,
            userAccount?.contactInformation?.mobileCountryPrefix,
            userAccount?.contactInformation?.telephoneNumber,
        ],
    );

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors, isValid },
    } = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
    });

    const CaseOptions = useMemo(
        () => [
            {
                value: 3,
                description: t("adjustmentChange"),
            },
            {
                value: 1,
                description: t("questionInformation"),
            },
            {
                value: 2,
                description: t("errorReport"),
            },
            {
                value: 4,
                description: t("leaveOpinion"),
            },
            {
                value: 5,
                description: t("financeInvoice"),
            },
        ],
        [t],
    );

    const [errorOnSubmit, setErrorOnSubmit] = useState(false);

    const onSubmit = useCallback(
        async (data: IPreloadedValues) => {
            setLoading(true);

            try {
                const res = await getServices().clients.support.apiSupportCreateSupportCasePost(
                    data.email,
                    data.prefix + data.phoneNumber,
                    data.details,
                    Number(data.caseNumber),
                    Number(data.role),
                    Number(data.supportType),
                    data.subject,
                    files.map((f) => f.file),
                );
                reset(defaultValues);
                clearFiles();
                setLoading(false);
                setSuccess(true);
                setCaseNumber(res.data!);
            } catch (e: any) {
                setLoading(false);

                if (
                    e.response?.data?.message === "Case not found" ||
                    e.response?.data?.includes("is not valid for Number")
                ) {
                    setError("caseNumber", {
                        message: "Ärendenumret hittades inte, försök igen eller skapa ett nytt ärende",
                    });
                    return;
                }
                setErrorOnSubmit(true);
            }
        },
        [files, reset, defaultValues, setError, clearFiles],
    );

    if (loading) return <SpinnerPage />;

    return (
        <div className="px-4 flex flex-col w-full items-center">
            {success ? (
                <div className="flex flex-col mt-22">
                    <h1> {t("thanksForCase")} </h1>
                    <p className="my-7">{t("weHaveReceivedCase")}</p>
                    <p>
                        {t("yourCaseNumber")}:<span className="font-bold"> {caseNumber}</span>
                    </p>
                    <div className="flex my-10">
                        <SecondaryButton id="returnToSupport" text={t("newCase")} onClick={() => setSuccess(false)} />
                    </div>
                </div>
            ) : (
                <form className="w-full max-w-3xl mb-16" onSubmit={handleSubmit(onSubmit)}>
                    {errorOnSubmit && (
                        <div className="mb-10">
                            <p className="text-mhred-alert1 font-bold text-lg">{t("genericErrorMessage")}</p>
                        </div>
                    )}
                    <FormImmutableFormTextInput label={t("yourName")} value={userContext.user?.name || ""} />
                    <FormImmutableFormTextInput
                        label={t("company")}
                        value={userContext.companyEmployment?.primaryCompany?.name || ""}
                    />
                    <FormTextInput label={t("email")} value="email" register={register} />
                    <FormTextInput label={t("workPhone")} value="phoneNumber" prefix="prefix" register={register} />
                    <FormSelect
                        label={t("supportType")}
                        value="supportType"
                        options={CaseOptions}
                        required
                        register={register}
                    />
                    <FormTextInput
                        label={t("caseNumber")}
                        value="caseNumber"
                        register={register}
                        caseNumber={errors.caseNumber}
                    />
                    <FormTextInput label={t("caseSubject")} value="subject" required register={register} />
                    <FormTextInput label={t("details")} value="details" required textarea register={register} />
                    <UploadButton
                        text={t("attachFiles")}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        open={open}
                    />
                    <div className="mt-12 mb-10">
                        <Appendix
                            onClick={removeFile}
                            images={files.map((item) => ({
                                fileName: item.name,
                                url: item.preview,
                            }))}
                        />
                    </div>
                    <div className="flex justify-center xl:justify-start">
                        <PrimaryButton
                            id="supportFormSubmit"
                            text={t("send")}
                            type="submit"
                            onClick={() => {}}
                            disabled={!isValid}
                        />
                    </div>
                </form>
            )}
            <div className="flex flex-col w-full items-center xl:items-start border-t mb-10">
                <h1 className="mt-7 text-xl mb-2.5">{t("phoneContact")}</h1>
                <p className="text-sm pb-1">
                    {t("monday")} - {t("friday")}: 08:00 - 17:00
                </p>
                <div className="flex items-center">
                    <div>
                        <Phone className="h-4 text-mhdarkgreen-original" />
                    </div>
                    <a className="text-lg underline pl-2 bg-transparent" href={`tel:${getSupportNumber()}`}>
                        {getSupportNumber()}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Support;
