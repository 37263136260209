import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { updateCaseDocument as updateCaseDocumentAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const updateCaseDocument = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(updateCaseDocumentAction.fulfilled, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.payload?.healthCaseId,
            );
            if (currentHealthCase && action.payload) {
                const caseDocumentToUpdate = currentHealthCase.healthCaseDocuments.find(
                    ({ id }) => id === action.payload?.data.id,
                );
                if (caseDocumentToUpdate) {
                    Object.assign(caseDocumentToUpdate, action.payload.data);
                    caseDocumentToUpdate.status = Status.OK;
                }
            }

            state.status.healthCases = Status.OK;
        })
        .addCase(updateCaseDocumentAction.pending, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentCaseDocument = currentHealthCase.healthCaseDocuments.find(
                    ({ id }) => id === action.meta.arg.updateDocumentModel.id,
                );
                if (currentCaseDocument) {
                    currentCaseDocument.status = Status.PENDING;
                }
            }
        })
        .addCase(updateCaseDocumentAction.rejected, (state, action) => {
            const currentHealthCase = state.healthCases.find(
                (healthCase) => healthCase.id === action.meta.arg.healthCaseId,
            );
            if (currentHealthCase && action.meta) {
                const currentCaseDocument = currentHealthCase.healthCaseDocuments.find(
                    ({ id }) => id === action.meta.arg.updateDocumentModel.id,
                );
                if (currentCaseDocument) {
                    currentCaseDocument.status = Status.ERROR;
                }
            }
        });

export default updateCaseDocument;
