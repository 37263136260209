/** builder for dealing with `async actions` */
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchEmployment } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchSubscribeBuilder = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchEmployment.fulfilled, (state, action) => {
            if (action.payload) {
                state.userEmployment = action.payload;
            }
            state.status.userEmployment = Status.OK;
        })
        .addCase(fetchEmployment.pending, (state) => {
            state.status.userEmployment = Status.PENDING;
        })
        .addCase(fetchEmployment.rejected, (state) => {
            state.status.userEmployment = Status.ERROR;
        });
export default fetchSubscribeBuilder;
