import { IState, Status } from "..";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchCompanyGroup as fetchCompanyGroupAction } from "../followUpAsyncActions";

const fetchCompanyGroup = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchCompanyGroupAction.fulfilled, (state, action) => {
            state.companyGroup.data = action.payload;
            state.costCalculation.status = Status.OK;
        })
        .addCase(fetchCompanyGroupAction.pending, (state) => {
            state.costCalculation.status = Status.PENDING;
        })
        .addCase(fetchCompanyGroupAction.rejected, (state) => {
            state.costCalculation.status = Status.ERROR;
        });

export default fetchCompanyGroup;
