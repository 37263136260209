import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectors as LoginSelectors } from "./redux";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { loginActions } from "pages/login/redux/loginSlice";
import { useTranslation } from "react-i18next";
import LoginPage from "./pages/LoginPage.tsx";
import {
    getGrandIdNoGuiStatus,
    getWarningBannerModel,
    initGrandIdNoGuiLogin,
    loginGrandIdNoGui,
    loginUsingAzureAd,
    loginWithMicrosoftToken,
} from "./redux/loginAsyncActions";
import { InitLoginModel, LoginTokenModel } from "swagger/authentication";
import { ILoginUsingProps, LoginUsing } from "./types";
import { launchBankID } from "./utils";
import { changeUserContextByLogin } from "store/userActions";

export default function Index() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { hash } = useLocation();
    const { i18n } = useTranslation("login");
    const { param } = useParams();
    const [language, setLanguage] = useState("");
    const [timer, setTimer] = useState<number>(0);
    const [totalTime, setTotalTime] = useState<number>(0);
    const [bankIdType, setBankIdType] = useState<string>("");
    const [bankIdOnDeviceLaunched, setBankIdOnDeviceLaunched] = useState<boolean>(false);
    const [bankIdPending, setBankIdPending] = useState(false);
    const [activeMobileBankIdTab, setActiveMobileBankIdTab] = useState<string>("qrCode");
    const [redirectUrl, setRedirectUrl] = useState<string | undefined>(undefined);

    const {
        warningBannerData,
        selectedMarket,
        userAccounts,
        isLoading,
        failedAt,
        countdownTimer,
        bankIdProps,
        oneTimePassProps,
        usernameAndPassProps,
        resetPassProps,
    } = useAppSelector(LoginSelectors.getLoginSlice);

    const resetTimer = useCallback(() => {
        setTimer(-1);
        setTotalTime(0);
    }, []);

    const handleMarketChange = (market: string): void => {
        dispatch(loginActions.setMarket(market));
        navigate(`/login/${market}`);
    };

    const handleBankIdInit = (
        bankIdType: string,
        model: InitLoginModel,
        navigateTo?: string,
        _redirectUrl?: string,
    ): void => {
        setTimer(0);
        setTotalTime(0);
        setBankIdType(bankIdType);
        dispatch(loginActions.resetBankIdData());
        dispatch(initGrandIdNoGuiLogin(model));
        if (navigateTo) navigate(`/login/${navigateTo}`);
        setBankIdPending(bankIdType !== "qrCode");
        setRedirectUrl(_redirectUrl);
    };

    const handleLoginUsing = (loginUsingProps: ILoginUsingProps) => {
        dispatch(loginActions.setIsLoading(true));

        switch (loginUsingProps.loginUsing) {
            case LoginUsing.azure:
                return dispatch(loginUsingAzureAd(loginUsingProps.returnUrl));

            case LoginUsing.bankIdSameDevice:
                handleBankIdInit(
                    "sameDevice",
                    loginUsingProps.bankIdInitGrandId!,
                    "bankIdSameDevice",
                    loginUsingProps.returnUrl,
                );
                break;

            case LoginUsing.mobileBankIdQrCode:
                setActiveMobileBankIdTab("qrCode");
                handleBankIdInit(
                    "qrCode",
                    loginUsingProps.bankIdInitGrandId!,
                    "mobileBankId",
                    loginUsingProps.returnUrl,
                );
                break;

            case LoginUsing.mobileBankIdSsn: {
                const useSameInitToken = bankIdType === "ssn" && totalTime > 30;
                if (useSameInitToken) {
                    setTimer(0);
                    setTotalTime(0);
                    setBankIdPending(true);
                } else handleBankIdInit("ssn", loginUsingProps.bankIdInitGrandId!);
                break;
            }

            case LoginUsing.selectedUserAccount:
                dispatch(changeUserContextByLogin(loginUsingProps.selectedUserAccountId!));
                break;

            default:
                if (loginUsingProps.navigateOrAbort?.resetBankIdData) {
                    setTimer(-1);
                    dispatch(loginActions.resetBankIdData);
                }
                navigate(`/login/${loginUsingProps.navigateOrAbort?.navigateTo}`);
                break;
        }
    };

    useEffect(() => {
        if (!warningBannerData) dispatch(getWarningBannerModel());
    }, [dispatch, warningBannerData]);

    useEffect(() => {
        if (!selectedMarket) {
            const currentParam = param === "dk" || param === "sv" ? param : undefined;
            setLanguage("sv");
            dispatch(loginActions.setMarket(currentParam ?? "sv"));
        }
        if (!language && (param === "sv" || param === "dk")) {
            setLanguage(param === "dk" ? "da" : param);
        }
    }, [dispatch, i18n, language, param, selectedMarket]);

    useEffect(() => {
        if (hash) {
            const parsedHash = new URLSearchParams(hash);
            const paramsPOJO = Object.fromEntries(parsedHash.entries());

            const microsoftToken = paramsPOJO["#id_token"] as string;
            const returnUrl = paramsPOJO.state as string;
            if (microsoftToken) {
                const loginModel: LoginTokenModel = {
                    token: microsoftToken,
                    returnUrl: decodeURIComponent(returnUrl),
                };
                dispatch(loginWithMicrosoftToken(loginModel));
            }
        }
    }, [dispatch, hash]);

    useEffect(() => {
        if (timer > 0) {
            const interval = 1;
            const intervalId = setInterval(() => {
                setTimer((t) => t - interval);
                setTotalTime(totalTime + interval);
            }, interval * 1000);
            return () => clearInterval(intervalId);
        }
    }, [timer, totalTime]);

    useEffect(() => {
        if (bankIdType === "sameDevice" && bankIdProps.autoStartToken && !bankIdOnDeviceLaunched) {
            launchBankID(bankIdProps.autoStartToken);
            setBankIdOnDeviceLaunched(true);
        }
    }, [bankIdType, bankIdProps.autoStartToken, bankIdOnDeviceLaunched]);

    useEffect(() => {
        if (activeMobileBankIdTab === "ssn") resetTimer();
        else if (activeMobileBankIdTab === "qrCode") {
            setTimer(0);
        }
    }, [activeMobileBankIdTab, dispatch, resetTimer]);

    useEffect(() => {
        if (!!bankIdProps.statusResult.sessionGuid && timer === 0 && totalTime < 30) {
            setTimer(2);
            dispatch(
                getGrandIdNoGuiStatus({
                    isQr: bankIdType === "qrCode",
                    sessionGuid: bankIdProps.statusResult.sessionGuid,
                }),
            );
        }

        if (totalTime > 30) setBankIdPending(false);
    }, [bankIdType, bankIdProps.statusResult.sessionGuid, dispatch, timer, totalTime]);

    useEffect(() => {
        if (bankIdProps.bankIdAuthenticated && bankIdProps.loginGrandIdGuid) {
            setTimer(-1);
            setRedirectUrl(redirectUrl);
            dispatch(
                loginGrandIdNoGui({
                    sessionGuid: bankIdProps.loginGrandIdGuid,
                    returnUrl: redirectUrl,
                }),
            );
        }
    }, [bankIdProps.bankIdAuthenticated, bankIdProps.loginGrandIdGuid, redirectUrl, dispatch]);

    useEffect(() => {
        if (bankIdProps.bankIdRejected) resetTimer();
    }, [bankIdProps.bankIdRejected, resetTimer]);

    useEffect(() => {
        if (userAccounts) {
            setBankIdPending(false);
            if (redirectUrl) navigate(`/login/selectCompany?returnUrl=${redirectUrl}`);
            else navigate(`/login/selectCompany`);
        }
    }, [navigate, redirectUrl, userAccounts]);

    return (
        <LoginPage
            loginPageProps={{
                language: language,
                setLanguage: setLanguage,
                handleMarketChange: handleMarketChange,
            }}
            warningBannerData={warningBannerData}
            selectedMarket={selectedMarket ?? ""}
            loginUsing={handleLoginUsing}
            isLoading={isLoading}
            failedAt={failedAt}
            countdownTimer={countdownTimer}
            userAccounts={userAccounts}
            bankIdProps={{
                ...bankIdProps,
                totalTime: totalTime,
                bankIdPending: bankIdPending,
            }}
            activeMobileBankIdTab={activeMobileBankIdTab}
            setActiveMobileBankIdTab={setActiveMobileBankIdTab}
            oneTimePassProps={oneTimePassProps}
            usernameAndPassProps={usernameAndPassProps}
            resetPassProps={resetPassProps}
        />
    );
}
