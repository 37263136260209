import DeviantAbsencesPerMonths from "pages/service/components/DeviantAbsencesPerMonths";
import NotificationConfiguration from "pages/service/components/NotificationConfiguration";
import DeviantAbsenceMaxDaysMonths from "pages/service/components/DeviantAbsenceMaxDaysMonths";
import TemporarySubscriberConfiguration from "pages/service/components/TemporarySubscriberConfiguration";
import DeviantAbsenceMaxLengthRecurring from "pages/service/components/DeviantAbsenceMaxLengthRecurring";

const NotificationsTab = () => (
    <>
        <DeviantAbsencesPerMonths />
        <DeviantAbsenceMaxLengthRecurring />
        <DeviantAbsenceMaxDaysMonths />
        <TemporarySubscriberConfiguration />
        <NotificationConfiguration />
    </>
);

export default NotificationsTab;
