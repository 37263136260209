/* tslint:disable */
/* eslint-disable */
/**
 * MedHelp.AbsenceFollowUp.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpDomainHistoricalAbsence
 */
export interface MedHelpAbsenceFollowUpDomainHistoricalAbsence {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpDomainHistoricalAbsence
     */
    'absenceReportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpDomainHistoricalAbsence
     */
    'absenceEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpDomainHistoricalAbsence
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpDomainHistoricalAbsence
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpDomainHistoricalAbsence
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpDomainHistoricalAbsence
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpDomainHistoricalAbsence
     */
    'socialSecurityNumber'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration
 */
export interface MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration
     */
    'customerId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration
     */
    'socialInsuranceReporting'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiControllersResponse
 */
export interface MedHelpAbsenceFollowUpWebApiControllersResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiControllersResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiControllersResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiControllersResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiControllersResponse
     */
    'totalUniqueEmployments'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiControllersResponse
     */
    'totalUniqueCases'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse>}
     * @memberof MedHelpAbsenceFollowUpWebApiControllersResponse
     */
    'page'?: Array<MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiServicesFilterResponse>}
     * @memberof MedHelpAbsenceFollowUpWebApiControllersResponse
     */
    'filterOptions'?: Array<MedHelpAbsenceFollowUpWebApiServicesFilterResponse> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule
 */
export interface MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule
     */
    'ruleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule
     */
    'interval'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule
     */
    'absenceType'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate
 */
export interface MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate
     */
    'degree'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel
 */
export interface MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel
     */
    'socialInsuranceReporting'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiModelsEmployment
 */
export interface MedHelpAbsenceFollowUpWebApiModelsEmployment {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsEmployment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsEmployment
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsEmployment
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsEmployment
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsEmployment
     */
    'socialSecurityNoToDisplay'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate
 */
export interface MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate
     */
    'degree'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MedHelpAbsenceFollowUpWebApiRequestAbsenceCause = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_13: 13
} as const;

export type MedHelpAbsenceFollowUpWebApiRequestAbsenceCause = typeof MedHelpAbsenceFollowUpWebApiRequestAbsenceCause[keyof typeof MedHelpAbsenceFollowUpWebApiRequestAbsenceCause];


/**
 * 
 * @export
 * @enum {string}
 */

export const MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum = typeof MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum[keyof typeof MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum];


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
 */
export interface MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'toDate'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'departments'?: Array<MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'companiesMeta'?: Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta> | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'workingDays'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestGenderEnum>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'gender'?: Array<MedHelpAbsenceFollowUpWebApiRequestGenderEnum> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestPeriodEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'period'?: MedHelpAbsenceFollowUpWebApiRequestPeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'days'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'employmentGroup'?: string | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceType}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'absenceType'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceType;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'maxDepartmentBarsOnTopLevel'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'employmentGroupIdentifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'quarter'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest
     */
    'costCenters'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
 */
export interface MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'toDate'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'departments'?: Array<MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'companiesMeta'?: Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta> | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'workingDays'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestGenderEnum>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'gender'?: Array<MedHelpAbsenceFollowUpWebApiRequestGenderEnum> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestPeriodEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'period'?: MedHelpAbsenceFollowUpWebApiRequestPeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'days'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'employmentGroup'?: string | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceType}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'absenceType'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceType;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'maxDepartmentBarsOnTopLevel'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'employmentGroupIdentifier'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'quarter'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest
     */
    'costCenters'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
 */
export interface MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'to'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'companiesMeta'?: Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'numbOfDaysWeek'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'gender'?: string | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodSpan}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'absencePeriod'?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodSpan;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'employmentGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'absenceType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'numberOfDepartmentBars'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase
     */
    'statisticsReportType'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
 */
export interface MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'compareOperation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'to'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'companiesMeta'?: Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'numbOfDaysWeek'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'gender'?: string | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodSpan}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'absencePeriod'?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodSpan;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'employmentGroup'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'absenceType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'numberOfDepartmentBars'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase
     */
    'statisticsReportType'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodSpan
 */
export interface MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodSpan {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodSpan
     */
    'absencePeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodSpan
     */
    'days'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MedHelpAbsenceFollowUpWebApiRequestAbsenceType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type MedHelpAbsenceFollowUpWebApiRequestAbsenceType = typeof MedHelpAbsenceFollowUpWebApiRequestAbsenceType[keyof typeof MedHelpAbsenceFollowUpWebApiRequestAbsenceType];


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestCompanyMeta
 */
export interface MedHelpAbsenceFollowUpWebApiRequestCompanyMeta {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestCompanyMeta
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestCompanyMeta
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MedHelpAbsenceFollowUpWebApiRequestCompareOperation = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type MedHelpAbsenceFollowUpWebApiRequestCompareOperation = typeof MedHelpAbsenceFollowUpWebApiRequestCompareOperation[keyof typeof MedHelpAbsenceFollowUpWebApiRequestCompareOperation];


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter
 */
export interface MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter
     */
    'cause'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter
     */
    'absenceTypes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter
     */
    'employmentGroupIdentifiers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter
     */
    'absencePeriods'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter
     */
    'timeInterval'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter
     */
    'departmentIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter
 */
export interface MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter {
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter
     */
    'absenceTypes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter
     */
    'genders'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter
     */
    'employmentGroupIdentifiers'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter
     */
    'absencePeriods'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter
     */
    'timeInterval'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter
     */
    'departmentIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase
 */
export interface MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase {
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase
     */
    'departmentIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory
 */
export interface MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory
     */
    'months'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory
     */
    'employmentId'?: string;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestExcelTranslations}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory
     */
    'translation'?: MedHelpAbsenceFollowUpWebApiRequestExcelTranslations;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
 */
export interface MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest {
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestExcelTranslations}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'translation'?: MedHelpAbsenceFollowUpWebApiRequestExcelTranslations;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'to'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest
 */
export interface MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest
     */
    'fromDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest
     */
    'startInInterval'?: boolean;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestExcelTranslations}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest
     */
    'translation'?: MedHelpAbsenceFollowUpWebApiRequestExcelTranslations;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest
     */
    'companyIds'?: Array<number> | null;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
 */
export interface MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant {
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestExcelTranslations}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'translation'?: MedHelpAbsenceFollowUpWebApiRequestExcelTranslations;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'to'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'codes'?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
 */
export interface MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing {
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestExcelTranslations}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'translation'?: MedHelpAbsenceFollowUpWebApiRequestExcelTranslations;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'minDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'maxDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'showOnlyLastAffectedDepartment'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
 */
export interface MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods {
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestExcelTranslations}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'translation'?: MedHelpAbsenceFollowUpWebApiRequestExcelTranslations;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'groupByEmployment'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'minDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'maxDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'occurrences'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'showOnlyLastAffectedDepartment'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'workAbsenceTypes'?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
 */
export interface MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable {
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestExcelTranslations}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'translation'?: MedHelpAbsenceFollowUpWebApiRequestExcelTranslations;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'groupByEmployment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'sicknessBenefits'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'anySelected'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestExcelTranslations
 */
export interface MedHelpAbsenceFollowUpWebApiRequestExcelTranslations {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelTranslations
     */
    'workbookTitle'?: string | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestNameValue>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelTranslations
     */
    'titles'?: Array<MedHelpAbsenceFollowUpWebApiRequestNameValue> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestNameValue>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelTranslations
     */
    'absenceTypes'?: Array<MedHelpAbsenceFollowUpWebApiRequestNameValue> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestNameValue>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelTranslations
     */
    'absenceCauses'?: Array<MedHelpAbsenceFollowUpWebApiRequestNameValue> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestNameValue>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelTranslations
     */
    'deviantAbsenceTypes'?: Array<MedHelpAbsenceFollowUpWebApiRequestNameValue> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestNameValue>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelTranslations
     */
    'reimbursableTypes'?: Array<MedHelpAbsenceFollowUpWebApiRequestNameValue> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestNameValue>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelTranslations
     */
    'options'?: Array<MedHelpAbsenceFollowUpWebApiRequestNameValue> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestNameValue>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestExcelTranslations
     */
    'booleanValues'?: Array<MedHelpAbsenceFollowUpWebApiRequestNameValue> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MedHelpAbsenceFollowUpWebApiRequestGenderEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MedHelpAbsenceFollowUpWebApiRequestGenderEnum = typeof MedHelpAbsenceFollowUpWebApiRequestGenderEnum[keyof typeof MedHelpAbsenceFollowUpWebApiRequestGenderEnum];


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
 */
export interface MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'to'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestMetricDepartments
 */
export interface MedHelpAbsenceFollowUpWebApiRequestMetricDepartments {
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestMetricDepartments
     */
    'departmentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestNameValue
 */
export interface MedHelpAbsenceFollowUpWebApiRequestNameValue {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestNameValue
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestNameValue
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MedHelpAbsenceFollowUpWebApiRequestPeriodEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type MedHelpAbsenceFollowUpWebApiRequestPeriodEnum = typeof MedHelpAbsenceFollowUpWebApiRequestPeriodEnum[keyof typeof MedHelpAbsenceFollowUpWebApiRequestPeriodEnum];


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestPersonecReport
 */
export interface MedHelpAbsenceFollowUpWebApiRequestPersonecReport {
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestPersonecReport
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestPersonecReport
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestPersonecReport
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestPersonecReport
     */
    'toDate'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField
 */
export interface MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
 */
export interface MedHelpAbsenceFollowUpWebApiRequestSearchDeviant {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'to'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'codes'?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchDeviant
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
 */
export interface MedHelpAbsenceFollowUpWebApiRequestSearchOngoing {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'minDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'maxDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'showOnlyLastAffectedDepartment'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchOngoing
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
 */
export interface MedHelpAbsenceFollowUpWebApiRequestSearchPeriods {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'groupByEmployment'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'minDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'maxDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'occurrences'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'showOnlyLastAffectedDepartment'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'workAbsenceTypes'?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchPeriods
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
 */
export interface MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'to'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'groupByEmployment'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'sicknessBenefits'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'anySelected'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'departments'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'historicalDepartments'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'types'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'descending'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'page'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'fields'?: Array<MedHelpAbsenceFollowUpWebApiRequestSearchAbsenceField> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable
     */
    'absenceImpact'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceImpactEnum;
}


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase
 */
export interface MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase {
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase
     */
    'employmentGroup'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase
 */
export interface MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase
     */
    'compareOperation'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase
     */
    'employmentGroup'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel
 */
export interface MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel
     */
    'id'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentModel
 */
export interface MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentModel {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentModel
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentModel
     */
    'selected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentModel
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
 */
export interface MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody {
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestCompareOperation>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'excelCompareOperation'?: Array<MedHelpAbsenceFollowUpWebApiRequestCompareOperation> | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestCompareOperation}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'compareOperation'?: MedHelpAbsenceFollowUpWebApiRequestCompareOperation;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestStatisticsReportType}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'statisticsReportType'?: MedHelpAbsenceFollowUpWebApiRequestStatisticsReportType;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestGenderEnum>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'gender'?: Array<MedHelpAbsenceFollowUpWebApiRequestGenderEnum> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestAbsenceCause>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'absenceCause'?: Array<MedHelpAbsenceFollowUpWebApiRequestAbsenceCause> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'areAllDepartmentsSelected'?: boolean;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestAbsenceType}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'absenceType'?: MedHelpAbsenceFollowUpWebApiRequestAbsenceType;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'texts'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'xAxisTitles'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'valueTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'quarter'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'costCenters'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'fromDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'toDate'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentModel>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'departments'?: Array<MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentModel> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'companiesMeta'?: Array<MedHelpAbsenceFollowUpWebApiRequestCompanyMeta> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'employmentGroupIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'employmentGroupId'?: string | null;
    /**
     * 
     * @type {MedHelpAbsenceFollowUpWebApiRequestPeriodEnum}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'period'?: MedHelpAbsenceFollowUpWebApiRequestPeriodEnum;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'days'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'workingDays'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'includeHistoricalDepartments'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'departmentsForAverigePercentPerCompanies'?: Array<number> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'departmentsForDepartmentInPercent'?: Array<MedHelpAbsenceFollowUpWebApiRequestStatisticsDepartmentInPercentModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'calculateOnCompanyLevel'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody
     */
    'maxDepartmentBarsOnTopLevel'?: number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MedHelpAbsenceFollowUpWebApiRequestStatisticsReportType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23
} as const;

export type MedHelpAbsenceFollowUpWebApiRequestStatisticsReportType = typeof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportType[keyof typeof MedHelpAbsenceFollowUpWebApiRequestStatisticsReportType];


/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase
 */
export interface MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase
     */
    'to'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase
     */
    'companyIds'?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase
     */
    'departmentIds'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase
     */
    'numbOfDaysWeek'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase
     */
    'gender'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase
     */
    'employmentGroup'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsence
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsence {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'absenceReportId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'backAtWork'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'currentDegree'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'degrees'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceDepartment>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'departments'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceDepartment> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'properties'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsence
     */
    'events'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsenceCountResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsenceCountResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceCountResponse
     */
    'employmentAbsenceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceCountResponse
     */
    'employmentCount'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree
     */
    'degree'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree
     */
    'timeStamp'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsenceDepartment
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsenceDepartment {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceDepartment
     */
    'departmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceDepartment
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceDepartment
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceDepartment
     */
    'to'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent
     */
    'performedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent
     */
    'reportedVia'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse
     */
    'period'?: string | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse
     */
    'absenceTypes'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType> | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse
     */
    'totalDeviants'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse
     */
    'absencePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse
     */
    'bradfordFactor'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType
     */
    'absenceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType
     */
    'absenceDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewType
     */
    'workingDays'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsencePerCauseResponseItem
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsencePerCauseResponseItem {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePerCauseResponseItem
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePerCauseResponseItem
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePerCauseResponseItem
     */
    'ratio'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
     */
    'absenceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
     */
    'totalDays'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseDepartment>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
     */
    'departments'?: Array<MedHelpAbsenceFollowUpWebApiResponseDepartment> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
     */
    'properties'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
     */
    'degrees'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree> | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod
     */
    'backAtWork'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty
     */
    'value'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty
     */
    'confidential'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse {
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsencePerCauseResponseItem>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse
     */
    'mySelection'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsencePerCauseResponseItem> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsencePerCauseResponseItem>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse
     */
    'previousYear'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsencePerCauseResponseItem> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseDashboardAbsenceDegree
 */
export interface MedHelpAbsenceFollowUpWebApiResponseDashboardAbsenceDegree {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardAbsenceDegree
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardAbsenceDegree
     */
    'degree'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardAbsenceDegree
     */
    'timeStamp'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod
 */
export interface MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod
     */
    'absenceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod
     */
    'backAtWork'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod
     */
    'type'?: string | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseDashboardAbsenceDegree>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod
     */
    'degrees'?: Array<MedHelpAbsenceFollowUpWebApiResponseDashboardAbsenceDegree> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseDashboardDepartment
 */
export interface MedHelpAbsenceFollowUpWebApiResponseDashboardDepartment {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardDepartment
     */
    'departmentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDashboardDepartment
     */
    'from'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseDepartment
 */
export interface MedHelpAbsenceFollowUpWebApiResponseDepartment {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDepartment
     */
    'departmentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDepartment
     */
    'from'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseDepartmentAbsenceResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseDepartmentAbsenceResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDepartmentAbsenceResponse
     */
    'sickReportsDepartments'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDepartmentAbsenceResponse
     */
    'careOfChildReportsDepartments'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDepartmentAbsenceResponse
     */
    'sickReportsCompany'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDepartmentAbsenceResponse
     */
    'careOfChildReportsCompany'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDepartmentAbsenceResponse
     */
    'employmentCount'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseDeviant
 */
export interface MedHelpAbsenceFollowUpWebApiResponseDeviant {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviant
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviant
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviant
     */
    'validFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviant
     */
    'validTo'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviant
     */
    'absenceReports'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule
 */
export interface MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule
     */
    'ruleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule
     */
    'interval'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule
     */
    'absenceType'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseEmployee
 */
export interface MedHelpAbsenceFollowUpWebApiResponseEmployee {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEmployee
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEmployee
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEmployee
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEmployee
     */
    'hasFollowUp'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails
 */
export interface MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails
     */
    'hasFollowUp'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse
     */
    'day'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse
     */
    'estimatedAbsence'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse
     */
    'estimatedAbsenceWithPastBackAtWork'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse
     */
    'employeeDetails'?: Array<MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseHistoricalRecord
 */
export interface MedHelpAbsenceFollowUpWebApiResponseHistoricalRecord {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseHistoricalRecord
     */
    'employmentReference'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseHistoricalRecord
     */
    'from'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseHistoricalRecord
     */
    'to'?: string;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse
     */
    'employeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse
     */
    'employmentId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse
     */
    'hasFollowUp'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseMedicalCertificate
 */
export interface MedHelpAbsenceFollowUpWebApiResponseMedicalCertificate {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseMedicalCertificate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseMedicalCertificate
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseMedicalCertificate
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseMedicalCertificate
     */
    'degree'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse
     */
    'ongoingAbsenceDegreeSum'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse
     */
    'ongoingAbsenceEmploymentCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse
     */
    'employmentCount'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse
     */
    'employeeDetails'?: Array<MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse
     */
    'absenceType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse
     */
    'eventTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse
     */
    'employeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse
     */
    'departmentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse
     */
    'eventType'?: string | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseRecoveryReportResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseRecoveryReportResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecoveryReportResponse
     */
    'recoverySickToday'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecoveryReportResponse
     */
    'recoverySickYesterday'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecoveryReportResponse
     */
    'recoveryCareOfChildToday'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecoveryReportResponse
     */
    'recoveryCareOfChildYesterday'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceDashboardResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceDashboardResponse {
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceStatistic>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceDashboardResponse
     */
    'recurringAbsenceStatistics'?: Array<MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceStatistic> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceStatistic
 */
export interface MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceStatistic {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceStatistic
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceStatistic
     */
    'count'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseEmployee>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceStatistic
     */
    'employees'?: Array<MedHelpAbsenceFollowUpWebApiResponseEmployee> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'employmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'socialSecurityNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'archived'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'followUpAccess'?: boolean;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'absencePeriods'?: Array<MedHelpAbsenceFollowUpWebApiResponseAbsencePeriod> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseSickReportResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseSickReportResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSickReportResponse
     */
    'sickToday'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSickReportResponse
     */
    'sickYesterday'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSickReportResponse
     */
    'careOfChildToday'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSickReportResponse
     */
    'careOfChildYesterday'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse
     */
    'totalEmployees'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse
     */
    'ongoing'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse
     */
    'startToday'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse
     */
    'startYday'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse
     */
    'backToday'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse
     */
    'backYday'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse
     */
    'employmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse
     */
    'absencePeriods'?: Array<MedHelpAbsenceFollowUpWebApiResponseDashboardAbsencePeriod> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseDashboardDepartment>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse
     */
    'departments'?: Array<MedHelpAbsenceFollowUpWebApiResponseDashboardDepartment> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse
     */
    'hasFollowUp'?: boolean;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse
     */
    'workRelatedOngoing'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse
     */
    'workPlaceAccidentOngoing'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails>}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse
     */
    'employeeDetails'?: Array<MedHelpAbsenceFollowUpWebApiResponseEmployeeDetails> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceResponse
 */
export interface MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceResponse
     */
    'workRelatedOngoing'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceResponse
     */
    'workRelatedSixMonths'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceResponse
     */
    'workPlaceAccidentOngoing'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceResponse
     */
    'workPlaceAccidentSixMonths'?: number;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiServicesFilterResponse
 */
export interface MedHelpAbsenceFollowUpWebApiServicesFilterResponse {
    /**
     * 
     * @type {string}
     * @memberof MedHelpAbsenceFollowUpWebApiServicesFilterResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedHelpAbsenceFollowUpWebApiServicesFilterResponse
     */
    'values'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
 */
export interface MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse {
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'totalUniqueEmployments'?: number;
    /**
     * 
     * @type {number}
     * @memberof MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'totalUniqueCases'?: number;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse>}
     * @memberof MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'page'?: Array<MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse> | null;
    /**
     * 
     * @type {Array<MedHelpAbsenceFollowUpWebApiServicesFilterResponse>}
     * @memberof MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse
     */
    'filterOptions'?: Array<MedHelpAbsenceFollowUpWebApiServicesFilterResponse> | null;
}

/**
 * AbsenceApi - axios parameter creator
 * @export
 */
export const AbsenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} absenceReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceAbsenceReportIdGet: async (absenceReportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'absenceReportId' is not null or undefined
            assertParamExists('absenceAbsenceReportIdGet', 'absenceReportId', absenceReportId)
            const localVarPath = `/Absence/{absenceReportId}`
                .replace(`{${"absenceReportId"}}`, encodeURIComponent(String(absenceReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} absenceReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceEventsAbsenceReportIdGet: async (absenceReportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'absenceReportId' is not null or undefined
            assertParamExists('absenceEventsAbsenceReportIdGet', 'absenceReportId', absenceReportId)
            const localVarPath = `/Absence/events/{absenceReportId}`
                .replace(`{${"absenceReportId"}}`, encodeURIComponent(String(absenceReportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceHistoricalCompanyCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('absenceHistoricalCompanyCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/Absence/historical/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceListEmploymentIdGet: async (employmentId: string, from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('absenceListEmploymentIdGet', 'employmentId', employmentId)
            const localVarPath = `/Absence/list/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceOverviewEmploymentIdGet: async (employmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('absenceOverviewEmploymentIdGet', 'employmentId', employmentId)
            const localVarPath = `/Absence/overview/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbsenceApi - functional programming interface
 * @export
 */
export const AbsenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AbsenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} absenceReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceAbsenceReportIdGet(absenceReportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceAbsenceReportIdGet(absenceReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} absenceReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceEventsAbsenceReportIdGet(absenceReportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceEventsAbsenceReportIdGet(absenceReportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceHistoricalCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpDomainHistoricalAbsence>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceHistoricalCompanyCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceListEmploymentIdGet(employmentId: string, from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsence>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceListEmploymentIdGet(employmentId, from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async absenceOverviewEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.absenceOverviewEmploymentIdGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AbsenceApi - factory interface
 * @export
 */
export const AbsenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AbsenceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} absenceReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceAbsenceReportIdGet(absenceReportId: string, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsence> {
            return localVarFp.absenceAbsenceReportIdGet(absenceReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} absenceReportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceEventsAbsenceReportIdGet(absenceReportId: string, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsenceEvent> {
            return localVarFp.absenceEventsAbsenceReportIdGet(absenceReportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceHistoricalCompanyCompanyIdGet(companyId: number, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpDomainHistoricalAbsence>> {
            return localVarFp.absenceHistoricalCompanyCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceListEmploymentIdGet(employmentId: string, from?: string, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsence> {
            return localVarFp.absenceListEmploymentIdGet(employmentId, from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        absenceOverviewEmploymentIdGet(employmentId: string, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseAbsenceOverviewResponse>> {
            return localVarFp.absenceOverviewEmploymentIdGet(employmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AbsenceApi - object-oriented interface
 * @export
 * @class AbsenceApi
 * @extends {BaseAPI}
 */
export class AbsenceApi extends BaseAPI {
    /**
     * 
     * @param {string} absenceReportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public absenceAbsenceReportIdGet(absenceReportId: string, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).absenceAbsenceReportIdGet(absenceReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} absenceReportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public absenceEventsAbsenceReportIdGet(absenceReportId: string, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).absenceEventsAbsenceReportIdGet(absenceReportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public absenceHistoricalCompanyCompanyIdGet(companyId: number, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).absenceHistoricalCompanyCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employmentId 
     * @param {string} [from] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public absenceListEmploymentIdGet(employmentId: string, from?: string, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).absenceListEmploymentIdGet(employmentId, from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbsenceApi
     */
    public absenceOverviewEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig) {
        return AbsenceApiFp(this.configuration).absenceOverviewEmploymentIdGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardMetricApi - axios parameter creator
 * @export
 */
export const DashboardMetricApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricAbsencesPerCausePost: async (medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/AbsencesPerCause`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [timeInterval] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricCausesbyTimeIntervalPost: async (timeInterval?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/CausesbyTimeInterval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (timeInterval !== undefined) {
                localVarQueryParameter['timeInterval'] = timeInterval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricEstimatedAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/EstimatedAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricLastReportedAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/LastReportedAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricOngoingAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/OngoingAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricRecurringAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/RecurringAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [type] 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricSummaryAbsenceExtensionPost: async (type?: string, medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/SummaryAbsenceExtension`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricSummaryAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/SummaryAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricWorkRelatedAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/DashboardMetric/WorkRelatedAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardMetricApi - functional programming interface
 * @export
 */
export const DashboardMetricApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardMetricApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricAbsencesPerCausePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricAbsencesPerCausePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [timeInterval] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricCausesbyTimeIntervalPost(timeInterval?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricCausesbyTimeIntervalPost(timeInterval, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricEstimatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricEstimatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricLastReportedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricLastReportedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricOngoingAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricOngoingAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricRecurringAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricRecurringAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [type] 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricSummaryAbsenceExtensionPost(type?: string, medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricSummaryAbsenceExtensionPost(type, medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricSummaryAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricSummaryAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardMetricWorkRelatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardMetricWorkRelatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardMetricApi - factory interface
 * @export
 */
export const DashboardMetricApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardMetricApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricAbsencesPerCausePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsencesPerCauseResponse> {
            return localVarFp.dashboardMetricAbsencesPerCausePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [timeInterval] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricCausesbyTimeIntervalPost(timeInterval?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.dashboardMetricCausesbyTimeIntervalPost(timeInterval, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricEstimatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseEstimatedAbsenceResponse>> {
            return localVarFp.dashboardMetricEstimatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricLastReportedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseLastReportedAbsenceResponse>> {
            return localVarFp.dashboardMetricLastReportedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricOngoingAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseOngoingAbsenceResponse> {
            return localVarFp.dashboardMetricOngoingAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricRecurringAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseRecurringAbsenceDashboardResponse> {
            return localVarFp.dashboardMetricRecurringAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [type] 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricSummaryAbsenceExtensionPost(type?: string, medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseSummaryDetailsResponse>> {
            return localVarFp.dashboardMetricSummaryAbsenceExtensionPost(type, medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricSummaryAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseSummaryCounterResponse> {
            return localVarFp.dashboardMetricSummaryAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardMetricWorkRelatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceDashboardResponse> {
            return localVarFp.dashboardMetricWorkRelatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardMetricApi - object-oriented interface
 * @export
 * @class DashboardMetricApi
 * @extends {BaseAPI}
 */
export class DashboardMetricApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricAbsencesPerCausePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricAbsencesPerCausePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricCauseFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [timeInterval] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricCausesbyTimeIntervalPost(timeInterval?: string, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricCausesbyTimeIntervalPost(timeInterval, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricEstimatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricEstimatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricLastReportedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricLastReportedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricOngoingAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricOngoingAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricRecurringAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricRecurringAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [type] 
     * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricSummaryAbsenceExtensionPost(type?: string, medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricSummaryAbsenceExtensionPost(type, medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricSummaryAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricSummaryAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilterBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter} [medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardMetricApi
     */
    public dashboardMetricWorkRelatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter?: MedHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options?: AxiosRequestConfig) {
        return DashboardMetricApiFp(this.configuration).dashboardMetricWorkRelatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestDashboardMetricFilter, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviantApi - axios parameter creator
 * @export
 */
export const DeviantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantActiveEmploymentIdGet: async (employmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('deviantActiveEmploymentIdGet', 'employmentId', employmentId)
            const localVarPath = `/Deviant/active/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantAllEmploymentIdGet: async (employmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('deviantAllEmploymentIdGet', 'employmentId', employmentId)
            const localVarPath = `/Deviant/all/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantRulesGet: async (customerId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Deviant/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantRulesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deviantRulesIdDelete', 'id', id)
            const localVarPath = `/Deviant/rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule} [medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantRulesPost: async (medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule?: MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Deviant/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviantApi - functional programming interface
 * @export
 */
export const DeviantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviantActiveEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseDeviant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviantActiveEmploymentIdGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviantAllEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseDeviant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviantAllEmploymentIdGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviantRulesGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviantRulesGet(customerId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviantRulesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviantRulesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule} [medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviantRulesPost(medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule?: MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviantRulesPost(medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviantApi - factory interface
 * @export
 */
export const DeviantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviantApiFp(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantActiveEmploymentIdGet(employmentId: string, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseDeviant>> {
            return localVarFp.deviantActiveEmploymentIdGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantAllEmploymentIdGet(employmentId: string, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseDeviant>> {
            return localVarFp.deviantAllEmploymentIdGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantRulesGet(customerId?: number, companyId?: number, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule>> {
            return localVarFp.deviantRulesGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantRulesIdDelete(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.deviantRulesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule} [medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviantRulesPost(medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule?: MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule, options?: any): AxiosPromise<string> {
            return localVarFp.deviantRulesPost(medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviantApi - object-oriented interface
 * @export
 * @class DeviantApi
 * @extends {BaseAPI}
 */
export class DeviantApi extends BaseAPI {
    /**
     * 
     * @param {string} employmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviantApi
     */
    public deviantActiveEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig) {
        return DeviantApiFp(this.configuration).deviantActiveEmploymentIdGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviantApi
     */
    public deviantAllEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig) {
        return DeviantApiFp(this.configuration).deviantAllEmploymentIdGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviantApi
     */
    public deviantRulesGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return DeviantApiFp(this.configuration).deviantRulesGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviantApi
     */
    public deviantRulesIdDelete(id: string, options?: AxiosRequestConfig) {
        return DeviantApiFp(this.configuration).deviantRulesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule} [medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviantApi
     */
    public deviantRulesPost(medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule?: MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule, options?: AxiosRequestConfig) {
        return DeviantApiFp(this.configuration).deviantRulesPost(medHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EmploymentApi - axios parameter creator
 * @export
 */
export const EmploymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} [employmentGuids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employmentGet: async (employmentGuids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Employment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (employmentGuids) {
                localVarQueryParameter['employmentGuids'] = employmentGuids;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmploymentApi - functional programming interface
 * @export
 */
export const EmploymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmploymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [employmentGuids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employmentGet(employmentGuids?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiModelsEmployment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employmentGet(employmentGuids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmploymentApi - factory interface
 * @export
 */
export const EmploymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmploymentApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<string>} [employmentGuids] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employmentGet(employmentGuids?: Array<string>, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiModelsEmployment>> {
            return localVarFp.employmentGet(employmentGuids, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmploymentApi - object-oriented interface
 * @export
 * @class EmploymentApi
 * @extends {BaseAPI}
 */
export class EmploymentApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} [employmentGuids] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmploymentApi
     */
    public employmentGet(employmentGuids?: Array<string>, options?: AxiosRequestConfig) {
        return EmploymentApiFp(this.configuration).employmentGet(employmentGuids, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExcelApi - axios parameter creator
 * @export
 */
export const ExcelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory} [medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelAbsenceHistoryPost: async (medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory?: MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/AbsenceHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelLongtermPost: async (medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/Longterm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest} [medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelMedicalCertPost: async (medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest?: MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/MedicalCert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest} [medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelMonthlyPost: async (medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest?: MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/Monthly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing} [medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelOngoingPost: async (medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/Ongoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods} [medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelPeriodsPost: async (medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/Periods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelRecurringPost: async (medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/Recurring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable} [medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelReimbursablePost: async (medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/Reimbursable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody} [medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelStatisticsPost: async (medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody?: MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Excel/Statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExcelApi - functional programming interface
 * @export
 */
export const ExcelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExcelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory} [medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelAbsenceHistoryPost(medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory?: MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelAbsenceHistoryPost(medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelLongtermPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelLongtermPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest} [medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest?: MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest} [medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelMonthlyPost(medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest?: MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelMonthlyPost(medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing} [medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelOngoingPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelOngoingPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods} [medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelPeriodsPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelPeriodsPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelRecurringPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelRecurringPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable} [medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelReimbursablePost(medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelReimbursablePost(medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody} [medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excelStatisticsPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody?: MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excelStatisticsPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExcelApi - factory interface
 * @export
 */
export const ExcelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExcelApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory} [medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelAbsenceHistoryPost(medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory?: MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory, options?: any): AxiosPromise<void> {
            return localVarFp.excelAbsenceHistoryPost(medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelLongtermPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options?: any): AxiosPromise<void> {
            return localVarFp.excelLongtermPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest} [medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest?: MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.excelMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest} [medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelMonthlyPost(medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest?: MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest, options?: any): AxiosPromise<void> {
            return localVarFp.excelMonthlyPost(medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing} [medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelOngoingPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing, options?: any): AxiosPromise<void> {
            return localVarFp.excelOngoingPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods} [medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelPeriodsPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods, options?: any): AxiosPromise<void> {
            return localVarFp.excelPeriodsPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelRecurringPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options?: any): AxiosPromise<void> {
            return localVarFp.excelRecurringPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable} [medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelReimbursablePost(medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable, options?: any): AxiosPromise<void> {
            return localVarFp.excelReimbursablePost(medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody} [medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excelStatisticsPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody?: MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options?: any): AxiosPromise<void> {
            return localVarFp.excelStatisticsPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExcelApi - object-oriented interface
 * @export
 * @class ExcelApi
 * @extends {BaseAPI}
 */
export class ExcelApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory} [medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelAbsenceHistoryPost(medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory?: MedHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelAbsenceHistoryPost(medHelpAbsenceFollowUpWebApiRequestExcelAbsenceHistory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelLongtermPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelLongtermPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest} [medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest?: MedHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestExcelMedicalCertReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest} [medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelMonthlyPost(medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest?: MedHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelMonthlyPost(medHelpAbsenceFollowUpWebApiRequestExcelMonthlyReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing} [medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelOngoingPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelOngoingPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchOngoing, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods} [medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelPeriodsPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelPeriodsPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchPeriods, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelRecurringPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelRecurringPost(medHelpAbsenceFollowUpWebApiRequestExcelSearchDeviant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable} [medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelReimbursablePost(medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable?: MedHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelReimbursablePost(medHelpAbsenceFollowUpWebApiRequestExcelSearchReimbursable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody} [medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExcelApi
     */
    public excelStatisticsPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody?: MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options?: AxiosRequestConfig) {
        return ExcelApiFp(this.configuration).excelStatisticsPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MedicalCertificatesApi - axios parameter creator
 * @export
 */
export const MedicalCertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesEmploymentEmploymentIdDelete: async (employmentId: string, maxEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('medicalCertificatesEmploymentEmploymentIdDelete', 'employmentId', employmentId)
            const localVarPath = `/MedicalCertificates/employment/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxEnd !== undefined) {
                localVarQueryParameter['maxEnd'] = (maxEnd as any instanceof Date) ?
                    (maxEnd as any).toISOString() :
                    maxEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesEmploymentIdGet: async (employmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('medicalCertificatesEmploymentIdGet', 'employmentId', employmentId)
            const localVarPath = `/MedicalCertificates/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [id] 
         * @param {MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate} [medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesEmploymentIdPut: async (employmentId: string, id?: string, medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate?: MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'employmentId' is not null or undefined
            assertParamExists('medicalCertificatesEmploymentIdPut', 'employmentId', employmentId)
            const localVarPath = `/MedicalCertificates/{employmentId}`
                .replace(`{${"employmentId"}}`, encodeURIComponent(String(employmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesHistoricalCompanyCompanyIdGet: async (companyId: number, maxEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('medicalCertificatesHistoricalCompanyCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/MedicalCertificates/historical/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (maxEnd !== undefined) {
                localVarQueryParameter['maxEnd'] = (maxEnd as any instanceof Date) ?
                    (maxEnd as any).toISOString() :
                    maxEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate} [medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesPost: async (medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate?: MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/MedicalCertificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MedicalCertificatesApi - functional programming interface
 * @export
 */
export const MedicalCertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MedicalCertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesEmploymentEmploymentIdDelete(employmentId: string, maxEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesEmploymentEmploymentIdDelete(employmentId, maxEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseMedicalCertificate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesEmploymentIdGet(employmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [id] 
         * @param {MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate} [medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesEmploymentIdPut(employmentId: string, id?: string, medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate?: MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesEmploymentIdPut(employmentId, id, medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesHistoricalCompanyCompanyIdGet(companyId: number, maxEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseHistoricalRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesHistoricalCompanyCompanyIdGet(companyId, maxEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate} [medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async medicalCertificatesPost(medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate?: MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.medicalCertificatesPost(medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MedicalCertificatesApi - factory interface
 * @export
 */
export const MedicalCertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MedicalCertificatesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesEmploymentEmploymentIdDelete(employmentId: string, maxEnd?: string, options?: any): AxiosPromise<void> {
            return localVarFp.medicalCertificatesEmploymentEmploymentIdDelete(employmentId, maxEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesEmploymentIdGet(employmentId: string, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseMedicalCertificate>> {
            return localVarFp.medicalCertificatesEmploymentIdGet(employmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} employmentId 
         * @param {string} [id] 
         * @param {MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate} [medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesEmploymentIdPut(employmentId: string, id?: string, medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate?: MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate, options?: any): AxiosPromise<void> {
            return localVarFp.medicalCertificatesEmploymentIdPut(employmentId, id, medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} companyId 
         * @param {string} [maxEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesHistoricalCompanyCompanyIdGet(companyId: number, maxEnd?: string, options?: any): AxiosPromise<Array<MedHelpAbsenceFollowUpWebApiResponseHistoricalRecord>> {
            return localVarFp.medicalCertificatesHistoricalCompanyCompanyIdGet(companyId, maxEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate} [medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        medicalCertificatesPost(medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate?: MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate, options?: any): AxiosPromise<void> {
            return localVarFp.medicalCertificatesPost(medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MedicalCertificatesApi - object-oriented interface
 * @export
 * @class MedicalCertificatesApi
 * @extends {BaseAPI}
 */
export class MedicalCertificatesApi extends BaseAPI {
    /**
     * 
     * @param {string} employmentId 
     * @param {string} [maxEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesEmploymentEmploymentIdDelete(employmentId: string, maxEnd?: string, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesEmploymentEmploymentIdDelete(employmentId, maxEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesEmploymentIdGet(employmentId: string, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesEmploymentIdGet(employmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} employmentId 
     * @param {string} [id] 
     * @param {MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate} [medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesEmploymentIdPut(employmentId: string, id?: string, medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate?: MedHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesEmploymentIdPut(employmentId, id, medHelpAbsenceFollowUpWebApiModelsUpdateMedicalCertificate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} companyId 
     * @param {string} [maxEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesHistoricalCompanyCompanyIdGet(companyId: number, maxEnd?: string, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesHistoricalCompanyCompanyIdGet(companyId, maxEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate} [medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MedicalCertificatesApi
     */
    public medicalCertificatesPost(medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate?: MedHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate, options?: AxiosRequestConfig) {
        return MedicalCertificatesApiFp(this.configuration).medicalCertificatesPost(medHelpAbsenceFollowUpWebApiModelsCreateMedicalCertificate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Metrics/Absence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsDepartmentAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Metrics/DepartmentAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsRecentEventsPost: async (medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Metrics/RecentEvents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsRecoveryReportsPost: async (medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Metrics/RecoveryReports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsSickReportsPost: async (medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Metrics/SickReports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsWorkRelatedPost: async (medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Metrics/WorkRelated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsenceCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsDepartmentAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseDepartmentAbsenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsDepartmentAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsRecentEventsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsRecentEventsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsRecoveryReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseRecoveryReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsRecoveryReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsSickReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseSickReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsSickReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsWorkRelatedPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.metricsWorkRelatedPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseAbsenceCountResponse> {
            return localVarFp.metricsAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsDepartmentAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseDepartmentAbsenceResponse> {
            return localVarFp.metricsDepartmentAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsRecentEventsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseRecentEventsResponse> {
            return localVarFp.metricsRecentEventsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsRecoveryReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseRecoveryReportResponse> {
            return localVarFp.metricsRecoveryReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsSickReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseSickReportResponse> {
            return localVarFp.metricsSickReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsWorkRelatedPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiResponseWorkRelatedAbsenceResponse> {
            return localVarFp.metricsWorkRelatedPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).metricsAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsDepartmentAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).metricsDepartmentAbsencePost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsRecentEventsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).metricsRecentEventsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsRecoveryReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).metricsRecoveryReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsSickReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).metricsSickReportsPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestMetricDepartments} [medHelpAbsenceFollowUpWebApiRequestMetricDepartments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public metricsWorkRelatedPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments?: MedHelpAbsenceFollowUpWebApiRequestMetricDepartments, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).metricsWorkRelatedPost(medHelpAbsenceFollowUpWebApiRequestMetricDepartments, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PersonecApi - axios parameter creator
 * @export
 */
export const PersonecApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestPersonecReport} [medHelpAbsenceFollowUpWebApiRequestPersonecReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personecPersonecPost: async (medHelpAbsenceFollowUpWebApiRequestPersonecReport?: MedHelpAbsenceFollowUpWebApiRequestPersonecReport, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Personec/Personec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestPersonecReport, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonecApi - functional programming interface
 * @export
 */
export const PersonecApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonecApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestPersonecReport} [medHelpAbsenceFollowUpWebApiRequestPersonecReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personecPersonecPost(medHelpAbsenceFollowUpWebApiRequestPersonecReport?: MedHelpAbsenceFollowUpWebApiRequestPersonecReport, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personecPersonecPost(medHelpAbsenceFollowUpWebApiRequestPersonecReport, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonecApi - factory interface
 * @export
 */
export const PersonecApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonecApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestPersonecReport} [medHelpAbsenceFollowUpWebApiRequestPersonecReport] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personecPersonecPost(medHelpAbsenceFollowUpWebApiRequestPersonecReport?: MedHelpAbsenceFollowUpWebApiRequestPersonecReport, options?: any): AxiosPromise<void> {
            return localVarFp.personecPersonecPost(medHelpAbsenceFollowUpWebApiRequestPersonecReport, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonecApi - object-oriented interface
 * @export
 * @class PersonecApi
 * @extends {BaseAPI}
 */
export class PersonecApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestPersonecReport} [medHelpAbsenceFollowUpWebApiRequestPersonecReport] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonecApi
     */
    public personecPersonecPost(medHelpAbsenceFollowUpWebApiRequestPersonecReport?: MedHelpAbsenceFollowUpWebApiRequestPersonecReport, options?: AxiosRequestConfig) {
        return PersonecApiFp(this.configuration).personecPersonecPost(medHelpAbsenceFollowUpWebApiRequestPersonecReport, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingGet(options?: any): AxiosPromise<void> {
            return localVarFp.pingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingGet(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).pingGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchAbsenceApi - axios parameter creator
 * @export
 */
export const SearchAbsenceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest} [medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbsenceMedicalCertPost: async (medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest?: MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SearchAbsence/MedicalCert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchOngoing} [medHelpAbsenceFollowUpWebApiRequestSearchOngoing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbsenceOngoingPost: async (medHelpAbsenceFollowUpWebApiRequestSearchOngoing?: MedHelpAbsenceFollowUpWebApiRequestSearchOngoing, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SearchAbsence/Ongoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSearchOngoing, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchPeriods} [medHelpAbsenceFollowUpWebApiRequestSearchPeriods] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbsencePeriodsPost: async (medHelpAbsenceFollowUpWebApiRequestSearchPeriods?: MedHelpAbsenceFollowUpWebApiRequestSearchPeriods, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SearchAbsence/Periods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSearchPeriods, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable} [medHelpAbsenceFollowUpWebApiRequestSearchReimbursable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbsenceReimbursablePost: async (medHelpAbsenceFollowUpWebApiRequestSearchReimbursable?: MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SearchAbsence/Reimbursable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSearchReimbursable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchAbsenceApi - functional programming interface
 * @export
 */
export const SearchAbsenceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchAbsenceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest} [medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAbsenceMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest?: MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiControllersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAbsenceMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchOngoing} [medHelpAbsenceFollowUpWebApiRequestSearchOngoing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAbsenceOngoingPost(medHelpAbsenceFollowUpWebApiRequestSearchOngoing?: MedHelpAbsenceFollowUpWebApiRequestSearchOngoing, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiControllersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAbsenceOngoingPost(medHelpAbsenceFollowUpWebApiRequestSearchOngoing, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchPeriods} [medHelpAbsenceFollowUpWebApiRequestSearchPeriods] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAbsencePeriodsPost(medHelpAbsenceFollowUpWebApiRequestSearchPeriods?: MedHelpAbsenceFollowUpWebApiRequestSearchPeriods, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiControllersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAbsencePeriodsPost(medHelpAbsenceFollowUpWebApiRequestSearchPeriods, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable} [medHelpAbsenceFollowUpWebApiRequestSearchReimbursable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAbsenceReimbursablePost(medHelpAbsenceFollowUpWebApiRequestSearchReimbursable?: MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAbsenceReimbursablePost(medHelpAbsenceFollowUpWebApiRequestSearchReimbursable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchAbsenceApi - factory interface
 * @export
 */
export const SearchAbsenceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchAbsenceApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest} [medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbsenceMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest?: MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiControllersResponse> {
            return localVarFp.searchAbsenceMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchOngoing} [medHelpAbsenceFollowUpWebApiRequestSearchOngoing] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbsenceOngoingPost(medHelpAbsenceFollowUpWebApiRequestSearchOngoing?: MedHelpAbsenceFollowUpWebApiRequestSearchOngoing, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiControllersResponse> {
            return localVarFp.searchAbsenceOngoingPost(medHelpAbsenceFollowUpWebApiRequestSearchOngoing, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchPeriods} [medHelpAbsenceFollowUpWebApiRequestSearchPeriods] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbsencePeriodsPost(medHelpAbsenceFollowUpWebApiRequestSearchPeriods?: MedHelpAbsenceFollowUpWebApiRequestSearchPeriods, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiControllersResponse> {
            return localVarFp.searchAbsencePeriodsPost(medHelpAbsenceFollowUpWebApiRequestSearchPeriods, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable} [medHelpAbsenceFollowUpWebApiRequestSearchReimbursable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAbsenceReimbursablePost(medHelpAbsenceFollowUpWebApiRequestSearchReimbursable?: MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable, options?: any): AxiosPromise<MedHelpAbsenceFollowUpWebApiServicesPageResponse1MedHelpAbsenceFollowUpWebApiResponseSearchAbsenceResponse> {
            return localVarFp.searchAbsenceReimbursablePost(medHelpAbsenceFollowUpWebApiRequestSearchReimbursable, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchAbsenceApi - object-oriented interface
 * @export
 * @class SearchAbsenceApi
 * @extends {BaseAPI}
 */
export class SearchAbsenceApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest} [medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAbsenceApi
     */
    public searchAbsenceMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest?: MedHelpAbsenceFollowUpWebApiRequestMedicalCertRequest, options?: AxiosRequestConfig) {
        return SearchAbsenceApiFp(this.configuration).searchAbsenceMedicalCertPost(medHelpAbsenceFollowUpWebApiRequestMedicalCertRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSearchOngoing} [medHelpAbsenceFollowUpWebApiRequestSearchOngoing] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAbsenceApi
     */
    public searchAbsenceOngoingPost(medHelpAbsenceFollowUpWebApiRequestSearchOngoing?: MedHelpAbsenceFollowUpWebApiRequestSearchOngoing, options?: AxiosRequestConfig) {
        return SearchAbsenceApiFp(this.configuration).searchAbsenceOngoingPost(medHelpAbsenceFollowUpWebApiRequestSearchOngoing, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSearchPeriods} [medHelpAbsenceFollowUpWebApiRequestSearchPeriods] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAbsenceApi
     */
    public searchAbsencePeriodsPost(medHelpAbsenceFollowUpWebApiRequestSearchPeriods?: MedHelpAbsenceFollowUpWebApiRequestSearchPeriods, options?: AxiosRequestConfig) {
        return SearchAbsenceApiFp(this.configuration).searchAbsencePeriodsPost(medHelpAbsenceFollowUpWebApiRequestSearchPeriods, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable} [medHelpAbsenceFollowUpWebApiRequestSearchReimbursable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchAbsenceApi
     */
    public searchAbsenceReimbursablePost(medHelpAbsenceFollowUpWebApiRequestSearchReimbursable?: MedHelpAbsenceFollowUpWebApiRequestSearchReimbursable, options?: AxiosRequestConfig) {
        return SearchAbsenceApiFp(this.configuration).searchAbsenceReimbursablePost(medHelpAbsenceFollowUpWebApiRequestSearchReimbursable, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchDeviantApi - axios parameter creator
 * @export
 */
export const SearchDeviantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDeviantLongtermPost: async (medHelpAbsenceFollowUpWebApiRequestSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestSearchDeviant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SearchDeviant/Longterm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSearchDeviant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDeviantRecurringPost: async (medHelpAbsenceFollowUpWebApiRequestSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestSearchDeviant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SearchDeviant/Recurring`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSearchDeviant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchDeviantApi - functional programming interface
 * @export
 */
export const SearchDeviantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchDeviantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDeviantLongtermPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestSearchDeviant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDeviantLongtermPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDeviantRecurringPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestSearchDeviant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDeviantRecurringPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchDeviantApi - factory interface
 * @export
 */
export const SearchDeviantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchDeviantApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDeviantLongtermPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestSearchDeviant, options?: any): AxiosPromise<void> {
            return localVarFp.searchDeviantLongtermPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestSearchDeviant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDeviantRecurringPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestSearchDeviant, options?: any): AxiosPromise<void> {
            return localVarFp.searchDeviantRecurringPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchDeviantApi - object-oriented interface
 * @export
 * @class SearchDeviantApi
 * @extends {BaseAPI}
 */
export class SearchDeviantApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestSearchDeviant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchDeviantApi
     */
    public searchDeviantLongtermPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestSearchDeviant, options?: AxiosRequestConfig) {
        return SearchDeviantApiFp(this.configuration).searchDeviantLongtermPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSearchDeviant} [medHelpAbsenceFollowUpWebApiRequestSearchDeviant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchDeviantApi
     */
    public searchDeviantRecurringPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant?: MedHelpAbsenceFollowUpWebApiRequestSearchDeviant, options?: AxiosRequestConfig) {
        return SearchDeviantApiFp(this.configuration).searchDeviantRecurringPost(medHelpAbsenceFollowUpWebApiRequestSearchDeviant, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SocialInsuranceApi - axios parameter creator
 * @export
 */
export const SocialInsuranceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialInsuranceConfigurationGet: async (customerId?: number, companyId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SocialInsurance/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel} [medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialInsuranceConfigurationPost: async (medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel?: MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/SocialInsurance/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialInsuranceApi - functional programming interface
 * @export
 */
export const SocialInsuranceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SocialInsuranceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialInsuranceConfigurationGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.socialInsuranceConfigurationGet(customerId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel} [medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async socialInsuranceConfigurationPost(medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel?: MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.socialInsuranceConfigurationPost(medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SocialInsuranceApi - factory interface
 * @export
 */
export const SocialInsuranceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SocialInsuranceApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialInsuranceConfigurationGet(customerId?: number, companyId?: number, options?: any): AxiosPromise<MedHelpAbsenceFollowUpDomainSocialInsuranceSocialInsuranceConfiguration> {
            return localVarFp.socialInsuranceConfigurationGet(customerId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel} [medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        socialInsuranceConfigurationPost(medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel?: MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel, options?: any): AxiosPromise<void> {
            return localVarFp.socialInsuranceConfigurationPost(medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SocialInsuranceApi - object-oriented interface
 * @export
 * @class SocialInsuranceApi
 * @extends {BaseAPI}
 */
export class SocialInsuranceApi extends BaseAPI {
    /**
     * 
     * @param {number} [customerId] 
     * @param {number} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialInsuranceApi
     */
    public socialInsuranceConfigurationGet(customerId?: number, companyId?: number, options?: AxiosRequestConfig) {
        return SocialInsuranceApiFp(this.configuration).socialInsuranceConfigurationGet(customerId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel} [medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialInsuranceApi
     */
    public socialInsuranceConfigurationPost(medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel?: MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel, options?: AxiosRequestConfig) {
        return SocialInsuranceApiFp(this.configuration).socialInsuranceConfigurationPost(medHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsenceCausePost: async (medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/AbsenceCause`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsenceDaysMonthsPost: async (medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/AbsenceDaysMonths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsenceDaysPerMonthEmployeePost: async (medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/AbsenceDaysPerMonthEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsenceInPercentPost: async (medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/AbsenceInPercent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsencePeriodsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/AbsencePeriods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsencesAgeGroupPost: async (medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/AbsencesAgeGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsencesPercentPerDepartmentsPost: async (medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/AbsencesPercentPerDepartments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsCurrentAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/CurrentAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsEmployeesBackAtWorkPost: async (medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/EmployeesBackAtWork`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody} [medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetStatisticsReportPost: async (medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody?: MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/GetStatisticsReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsHealthIndexPost: async (medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/HealthIndex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsNumberOfSickDaysIntervalPost: async (medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/NumberOfSickDaysInterval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyAbsencesPerCostCenterPost: async (medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/QuarterlyAbsencesPerCostCenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyAbsencesPerDepartmentPost: async (medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/QuarterlyAbsencesPerDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyHealthIndexPerCostCenterPost: async (medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/QuarterlyHealthIndexPerCostCenter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyHealthIndexPerDepartmentPost: async (medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/QuarterlyHealthIndexPerDepartment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRepeatedAbsencePost: async (medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/RepeatedAbsence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsReportPerWeekdayPost: async (medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/ReportPerWeekday`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsReportsOverTimePost: async (medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/ReportsOverTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase} [medHelpAbsenceFollowUpWebApiRequestSummaryReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsSummaryReportPost: async (medHelpAbsenceFollowUpWebApiRequestSummaryReportBase?: MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Statistics/SummaryReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(medHelpAbsenceFollowUpWebApiRequestSummaryReportBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsAbsenceCausePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsAbsenceCausePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsAbsenceDaysMonthsPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsAbsenceDaysMonthsPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsAbsenceDaysPerMonthEmployeePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsAbsenceDaysPerMonthEmployeePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsAbsenceInPercentPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsAbsenceInPercentPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsAbsencePeriodsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsAbsencePeriodsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsAbsencesAgeGroupPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsAbsencesAgeGroupPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsAbsencesPercentPerDepartmentsPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsAbsencesPercentPerDepartmentsPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsCurrentAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsCurrentAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsEmployeesBackAtWorkPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsEmployeesBackAtWorkPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody} [medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsGetStatisticsReportPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody?: MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsGetStatisticsReportPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsHealthIndexPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsHealthIndexPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsNumberOfSickDaysIntervalPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsNumberOfSickDaysIntervalPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsQuarterlyAbsencesPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsQuarterlyAbsencesPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsQuarterlyAbsencesPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsQuarterlyAbsencesPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsQuarterlyHealthIndexPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsQuarterlyHealthIndexPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsQuarterlyHealthIndexPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsQuarterlyHealthIndexPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsRepeatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsRepeatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsReportPerWeekdayPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsReportPerWeekdayPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsReportsOverTimePost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsReportsOverTimePost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase} [medHelpAbsenceFollowUpWebApiRequestSummaryReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticsSummaryReportPost(medHelpAbsenceFollowUpWebApiRequestSummaryReportBase?: MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticsSummaryReportPost(medHelpAbsenceFollowUpWebApiRequestSummaryReportBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsenceCausePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsAbsenceCausePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsenceDaysMonthsPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsAbsenceDaysMonthsPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsenceDaysPerMonthEmployeePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsAbsenceDaysPerMonthEmployeePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsenceInPercentPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsAbsenceInPercentPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsencePeriodsPost(options?: any): AxiosPromise<void> {
            return localVarFp.statisticsAbsencePeriodsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsencesAgeGroupPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsAbsencesAgeGroupPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsAbsencesPercentPerDepartmentsPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsAbsencesPercentPerDepartmentsPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsCurrentAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsCurrentAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsEmployeesBackAtWorkPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsEmployeesBackAtWorkPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody} [medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetStatisticsReportPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody?: MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsGetStatisticsReportPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsHealthIndexPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsHealthIndexPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsNumberOfSickDaysIntervalPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsNumberOfSickDaysIntervalPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyAbsencesPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsQuarterlyAbsencesPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyAbsencesPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsQuarterlyAbsencesPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyHealthIndexPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsQuarterlyHealthIndexPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsQuarterlyHealthIndexPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsQuarterlyHealthIndexPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsRepeatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsRepeatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsReportPerWeekdayPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsReportPerWeekdayPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsReportsOverTimePost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsReportsOverTimePost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase} [medHelpAbsenceFollowUpWebApiRequestSummaryReportBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsSummaryReportPost(medHelpAbsenceFollowUpWebApiRequestSummaryReportBase?: MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase, options?: any): AxiosPromise<void> {
            return localVarFp.statisticsSummaryReportPost(medHelpAbsenceFollowUpWebApiRequestSummaryReportBase, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsAbsenceCausePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsAbsenceCausePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsAbsenceDaysMonthsPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsAbsenceDaysMonthsPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsAbsenceDaysPerMonthEmployeePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsAbsenceDaysPerMonthEmployeePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsAbsenceInPercentPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsAbsenceInPercentPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsAbsencePeriodsPost(options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsAbsencePeriodsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsAbsencesAgeGroupPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsAbsencesAgeGroupPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsAbsencesPercentPerDepartmentsPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsAbsencesPercentPerDepartmentsPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsCurrentAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsCurrentAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsEmployeesBackAtWorkPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsEmployeesBackAtWorkPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody} [medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsGetStatisticsReportPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody?: MedHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsGetStatisticsReportPost(medHelpAbsenceFollowUpWebApiRequestStatisticsReportBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsHealthIndexPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsHealthIndexPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsNumberOfSickDaysIntervalPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsNumberOfSickDaysIntervalPost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportCompareBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsQuarterlyAbsencesPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsQuarterlyAbsencesPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsQuarterlyAbsencesPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsQuarterlyAbsencesPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsQuarterlyHealthIndexPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsQuarterlyHealthIndexPerCostCenterPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerCostCenterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest} [medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsQuarterlyHealthIndexPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest?: MedHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsQuarterlyHealthIndexPerDepartmentPost(medHelpAbsenceFollowUpWebApiRequestAbsenceInPercentPerDepartmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase} [medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsRepeatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase?: MedHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsRepeatedAbsencePost(medHelpAbsenceFollowUpWebApiRequestAbsencePeriodReportBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsReportPerWeekdayPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsReportPerWeekdayPost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportCompareBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase} [medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsReportsOverTimePost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase?: MedHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsReportsOverTimePost(medHelpAbsenceFollowUpWebApiRequestSickAndCareReportBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase} [medHelpAbsenceFollowUpWebApiRequestSummaryReportBase] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsSummaryReportPost(medHelpAbsenceFollowUpWebApiRequestSummaryReportBase?: MedHelpAbsenceFollowUpWebApiRequestSummaryReportBase, options?: AxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).statisticsSummaryReportPost(medHelpAbsenceFollowUpWebApiRequestSummaryReportBase, options).then((request) => request(this.axios, this.basePath));
    }
}


