import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { IState, Status } from "..";
import { updateCaseStatus as updateCaseStatusAction } from "../followUpAsyncActions";
import { newDateString } from "utils/date";

const updateCaseStatus = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(updateCaseStatusAction.fulfilled, (state, action) => {
            const { healthCaseId, status } = action.payload;
            const selectedHealthCase = state.healthCases.find(({ id }) => id === healthCaseId);
            if (selectedHealthCase) {
                selectedHealthCase.status = status;
                if (status === "ended") {
                    selectedHealthCase.end = newDateString();
                }
                if (status === "ongoing") {
                    selectedHealthCase.end = "";
                }
            }
            state.status.healthCases = Status.OK;
        })
        .addCase(updateCaseStatusAction.pending, (state) => {
            state.status.healthCases = Status.PENDING;
        })
        .addCase(updateCaseStatusAction.rejected, (state) => {
            // No need to reset state since we are not changing it
            state.status.healthCases = Status.OK;
        });

export default updateCaseStatus;
