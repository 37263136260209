import { ReactComponent as Caret } from "icons/caret.svg";
import { ReactComponent as CaretFull } from "icons/caretFull.svg";

/**
 * Caret container is based on `Up`
 */
interface IProps {
    className?: string;
}
export const CaretLeft = ({ className, ...rest }: IProps) => <Caret {...rest} className={`-rotate-90 ${className}`} />;

export const CaretRight = ({ className, ...rest }: IProps) => <Caret {...rest} className={`rotate-90 ${className}`} />;

export const CaretDown = ({ className, ...rest }: IProps) => <Caret {...rest} className={`rotate-180 ${className}`} />;

export const CaretUp = ({ className, ...rest }: IProps) => <Caret {...rest} className={className} />;

export const CaretFullLeft = ({ className, ...rest }: IProps) => (
    <CaretFull {...rest} className={`rotate-90 ${className}`} />
);

export const CaretFullRight = ({ className, ...rest }: IProps) => (
    <CaretFull {...rest} className={`-rotate-90 ${className}`} />
);

export const CaretFullUp = ({ className, ...rest }: IProps) => (
    <CaretFull {...rest} className={`rotate-180 ${className}`} />
);

export const CaretFullDown = ({ className, ...rest }: IProps) => <CaretFull {...rest} className={className} />;
