import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import ServiceToggle from "../ServiceToggle";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect, useMemo, useState } from "react";
import { FieldType } from "pages/service/utils/serviceTypes";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import {
    saveFieldTemplate,
    deleteFieldTemplate,
    saveAbsenceTemplate,
    deleteAbsenceTemplate,
    saveConfigurationField,
    deleteConfigurationField,
} from "pages/service/redux/serviceAsyncActions";
import {
    getSickTemplateId,
    getHasSickTemplate,
    getAdvisoryService,
    getCareOfChildFields,
    getSickReportingFields,
    getShowReportHealthyTime,
    getCareOfChildTemplateId,
    getHasCareOfChildTemplate,
    getCareOfChildViaIvrAvailable,
} from "pages/service/redux/serviceSelectors";

const DEFAULTTEMPLATEFIELDS = ["startDate", "endDate", "dateForBackAtWork", "absenceDegree"] as const;

interface IChecked<IKey> {
    id?: string;
    key: IKey;
    checked: boolean;
}

interface IAbsenceReportingConfiguration {
    hasSickTemplate: IChecked<string>;
    hasCareOfChildTemplate: IChecked<string>;
    sickReportingFields: IChecked<FieldType>[];
    careOfChildFields: IChecked<FieldType>[];
    showReportHealthyTime: IChecked<string>;
    careOfChildViaIvrAvailable: IChecked<string>;
}

const AbsenceReportingConfiguration = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const [isLoading, setIsLoading] = useState(false);
    const sickTemplateId = useAppSelector(getSickTemplateId);
    const advisoryService = useAppSelector(getAdvisoryService);
    const hasSickTemplate = useAppSelector(getHasSickTemplate);
    const careOfChildFields = useAppSelector(getCareOfChildFields);
    const sickReportingFields = useAppSelector(getSickReportingFields);
    const showReportHealthyTime = useAppSelector(getShowReportHealthyTime);
    const careOfchildTemplateId = useAppSelector(getCareOfChildTemplateId);
    const hasCareOfChildTemplate = useAppSelector(getHasCareOfChildTemplate);
    const careOfChildViaIvrAvailable = useAppSelector(getCareOfChildViaIvrAvailable);

    const defaultValues = useMemo(
        () => ({
            hasSickTemplate,
            careOfChildFields,
            sickReportingFields,
            showReportHealthyTime,
            hasCareOfChildTemplate,
            careOfChildViaIvrAvailable,
        }),
        [
            hasSickTemplate,
            careOfChildFields,
            sickReportingFields,
            showReportHealthyTime,
            hasCareOfChildTemplate,
            careOfChildViaIvrAvailable,
        ],
    );

    const {
        handleSubmit,
        reset,
        resetField,
        register,
        watch,
        setValue,
        formState: { isDirty, dirtyFields },
    } = useForm({
        defaultValues,
    });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const onSubmit = (data: IAbsenceReportingConfiguration) => {
        setIsLoading(true);
        const promises = [];

        if (dirtyFields.hasSickTemplate) {
            if (data.hasSickTemplate.checked) {
                promises.push(
                    dispatch(saveAbsenceTemplate("Sick"))
                        .then(unwrapResult)
                        .then(() => toast(t("saved") + " " + t("ownSickness")))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + t("ownSickness"), { type: "error" });
                            resetField("hasSickTemplate.checked");
                        }),
                );
            } else {
                if (data.hasSickTemplate.id)
                    promises.push(
                        dispatch(deleteAbsenceTemplate(data.hasSickTemplate.id))
                            .then(unwrapResult)
                            .then(() => toast(t("deleted") + " " + t("ownSickness")))
                            .catch((e) => {
                                toast(t("error") + ": " + e.message + " " + t("ownSickness"), { type: "error" });
                                resetField("hasSickTemplate.checked");
                            }),
                    );
            }
        }

        if (dirtyFields.hasCareOfChildTemplate) {
            if (data.hasCareOfChildTemplate.checked) {
                promises.push(
                    dispatch(saveAbsenceTemplate("CareOfChild"))
                        .then(unwrapResult)
                        .then(() => toast(t("saved") + "" + t("careOfChild")))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + t("careOfChild"), { type: "error" });
                            resetField("hasCareOfChildTemplate.checked");
                        }),
                );
            } else {
                if (data.hasCareOfChildTemplate.id)
                    promises.push(
                        dispatch(deleteAbsenceTemplate(data.hasCareOfChildTemplate.id))
                            .then(unwrapResult)
                            .then(() => toast(t("deleted") + " " + t("careOfChild")))
                            .catch((e) => {
                                toast(t("error") + ": " + e.message + " " + t("careOfChild"), { type: "error" });
                                resetField("hasCareOfChildTemplate.checked");
                            }),
                    );
            }
        }

        if (dirtyFields.showReportHealthyTime) {
            if (data.showReportHealthyTime.checked) {
                promises.push(
                    dispatch(saveConfigurationField({ name: "showReportHealthyTime" }))
                        .then(unwrapResult)
                        .then(() => toast(t("saved") + " " + t("showReportHealthyTime")))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + t("showReportHealthyTime"), { type: "error" });
                            resetField("showReportHealthyTime.checked");
                        }),
                );
            } else {
                promises.push(
                    data.showReportHealthyTime.id &&
                        dispatch(deleteConfigurationField(data.showReportHealthyTime.id))
                            .then(unwrapResult)
                            .then(() => toast(t("saved") + " " + "showReportHealthyTime"))
                            .catch((e) => {
                                toast(t("error") + ": " + e.message + " " + t("showReportHealthyTime"), {
                                    type: "error",
                                });
                                resetField("showReportHealthyTime.checked");
                            }),
                );
            }
        }

        if (dirtyFields.careOfChildViaIvrAvailable) {
            if (data.careOfChildViaIvrAvailable.checked) {
                promises.push(
                    dispatch(saveConfigurationField({ name: "careOfChildViaIvrAvailable" }))
                        .then(unwrapResult)
                        .then(() => toast(t("saved") + " " + t("careOfChildViaIvrAvailable")))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + t("careOfChildViaIvrAvailable"), {
                                type: "error",
                            });
                            resetField("careOfChildViaIvrAvailable.checked");
                        }),
                );
            } else {
                promises.push(
                    data.careOfChildViaIvrAvailable.id &&
                        dispatch(deleteConfigurationField(data.careOfChildViaIvrAvailable.id))
                            .then(unwrapResult)
                            .then(() => toast(t("saved") + " " + t("careOfChildViaIvrAvailable")))
                            .catch((e) => {
                                toast(t("error") + ": " + e.message + " " + t("careOfChildViaIvrAvailable"), {
                                    type: "error",
                                });
                                resetField("careOfChildViaIvrAvailable.checked");
                            }),
                );
            }
        }

        data.sickReportingFields.forEach(async (x, index) => {
            if (dirtyFields.sickReportingFields && dirtyFields.sickReportingFields[index] && sickTemplateId) {
                if (x.checked) {
                    await dispatch(saveFieldTemplate({ name: x.key, templateId: sickTemplateId }))
                        .then(unwrapResult)
                        .then(() => toast(t("saved") + ": " + x.key))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + x.key, { type: "error" });
                            resetField(`sickReportingFields.${index}.checked`);
                        });
                } else {
                    await dispatch(deleteFieldTemplate({ templateId: sickTemplateId, id: x.id }))
                        .then(unwrapResult)
                        .then(() => toast(t("deleted") + ": " + x.key))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + x.key, {
                                type: "error",
                            });
                            resetField(`sickReportingFields.${index}.checked`);
                        });
                }
            }
        });
        data.careOfChildFields.forEach(async (x, index) => {
            if (dirtyFields.careOfChildFields && dirtyFields.careOfChildFields[index] && careOfchildTemplateId) {
                if (x.checked && careOfchildTemplateId)
                    await dispatch(
                        saveFieldTemplate({
                            name: x.key,
                            templateId: careOfchildTemplateId,
                        }),
                    )
                        .then(unwrapResult)
                        .then(() => toast(t("saved") + ": " + x.key))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + x.key, { type: "error" });
                            resetField(`careOfChildFields.${index}.checked`);
                        });
                else {
                    x.id &&
                        (await dispatch(deleteFieldTemplate({ templateId: careOfchildTemplateId, id: x.id }))
                            .then(unwrapResult)
                            .then(() => toast(t("deleted") + ": " + x.key))
                            .catch((e) => {
                                toast(t("error") + ": " + e.message + " " + x.key, {
                                    type: "error",
                                });
                                resetField(`careOfChildFields.${index}.checked`);
                            }));
                }
            }
        });

        Promise.allSettled(promises).finally(() => setIsLoading(false));
    };

    const handleDisabled = (key: FieldType) => {
        if (key === "RequestedMedicalAdvisory" || key === "RiskMedicalAdvisoryRequest") return true;
        if (
            (key === "Consent" || key === "Confidential") &&
            !watch("sickReportingFields").find((x) => x.key === "AbsenceCause")?.checked
        ) {
            return true;
        }
        return false;
    };

    const handleChange = (key: FieldType, checked: boolean) => {
        if (key !== "AbsenceCause" && key !== "WorkRelated" && key !== "WorkplaceAccident") return;
        const consentIndex = sickReportingFields.findIndex((x) => x.key === "Consent");
        const confidentialIndex = sickReportingFields.findIndex((x) => x.key === "Confidential");
        const workRelatedIndex = sickReportingFields.findIndex((x) => x.key === "WorkRelated");
        const workplaceAccidentIndex = sickReportingFields.findIndex((x) => x.key === "WorkplaceAccident");

        if (key === "AbsenceCause") {
            setValue(`sickReportingFields.${consentIndex}.checked`, false, { shouldDirty: true });
            setValue(`sickReportingFields.${confidentialIndex}.checked`, false, { shouldDirty: true });
        }

        if (key === "WorkRelated") {
            setValue(`sickReportingFields.${workplaceAccidentIndex}.checked`, checked, { shouldDirty: true });
        }
        if (key === "WorkplaceAccident") {
            setValue(`sickReportingFields.${workRelatedIndex}.checked`, checked, { shouldDirty: true });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("absenceSettings")}
                reset={() => reset(defaultValues)}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <>
                        {sickTemplateId && <AccordionBooleanContent label={t("ownSickness")} />}
                        {careOfchildTemplateId && <AccordionBooleanContent label={t("careOfChild")} />}
                        {showReportHealthyTime.checked && (
                            <AccordionBooleanContent label={t("showReportHealthyTime")} />
                        )}
                        {careOfChildViaIvrAvailable.checked && <AccordionBooleanContent label={t("IVR")} />}
                    </>
                }
            >
                <ServiceCheckboxContainer
                    heading={t("ownSickness")}
                    toggle={
                        <ServiceToggle
                            register={register("hasSickTemplate.checked")}
                            checked={watch("hasSickTemplate.checked") === true}
                        />
                    }
                >
                    {sickTemplateId && (
                        <>
                            {DEFAULTTEMPLATEFIELDS.map((field) => (
                                <ServiceCheckboxRow key={field} label={t(field)} checked disabled />
                            ))}
                            {sickReportingFields.map((field, index) => (
                                <ServiceCheckboxRow
                                    key={field.key}
                                    label={t(field.key)}
                                    register={register(`sickReportingFields.${index}.checked`, {
                                        onChange: () =>
                                            handleChange(field.key, watch(`sickReportingFields.${index}.checked`)),
                                    })}
                                    disabled={handleDisabled(field.key)}
                                />
                            ))}
                        </>
                    )}
                </ServiceCheckboxContainer>
                <ServiceCheckboxContainer
                    heading={t("careOfChild")}
                    toggle={
                        <ServiceToggle
                            register={register("hasCareOfChildTemplate.checked")}
                            checked={watch("hasCareOfChildTemplate.checked") === true}
                        />
                    }
                >
                    {careOfchildTemplateId && (
                        <>
                            {DEFAULTTEMPLATEFIELDS.map((field) => (
                                <ServiceCheckboxRow key={field} label={t(field)} checked disabled />
                            ))}
                            {careOfChildFields.map((field, index) => (
                                <ServiceCheckboxRow
                                    key={field.key}
                                    label={t(field.key)}
                                    register={register(`careOfChildFields.${index}.checked`)}
                                />
                            ))}
                        </>
                    )}
                </ServiceCheckboxContainer>
                <ServiceCheckboxContainer heading={t("appliesToAllAbsenceTypes")}>
                    <ServiceCheckboxRow
                        label={t("showReportHealthyTime")}
                        register={register("showReportHealthyTime.checked")}
                    />
                </ServiceCheckboxContainer>
                <ServiceCheckboxContainer heading={t("IVR")}>
                    <ServiceCheckboxRow
                        label={t("careOfChildViaIvrAvailable")}
                        register={register("careOfChildViaIvrAvailable.checked")}
                        disabled={!watch("careOfChildViaIvrAvailable.checked") && advisoryService !== "callNurse"}
                    />
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default AbsenceReportingConfiguration;
