import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RadioButtonGroup from "../RadioButtonGroup";
import { IDynamicComponent } from "./IDynamicComponent";

const DynamicBoolean = (props: IDynamicComponent<string>) => {
    const { id, value, onChange, disabled, isHorizontal, ...rest } = props;
    const { t } = useTranslation();
    const [fieldValue, setFieldValue] = useState<string | undefined>(value);
    const values = [
        { value: "true", description: t("yes") },
        { value: "false", description: t("no") },
    ];
    useEffect(() => {
        if (value) setFieldValue(value);
        else setFieldValue(undefined);
    }, [value, fieldValue]);
    return (
        <RadioButtonGroup
            id={id}
            values={values}
            clicked={(v) => onChange(v ?? "")}
            selectedValue={fieldValue!}
            isHorizontal={isHorizontal}
            disabled={disabled}
            {...rest}
        />
    );
};
export default DynamicBoolean;
