import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { FieldValues, UseFormResetField } from "react-hook-form";
import {
    deleteAccessRightConfiguration,
    IAccessRightConfiguration,
    saveAccessRightConfiguration,
} from "../redux/serviceAsyncActions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const useSubmitAccessRights = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation("service");

    const submitAccessRights = (
        data: IAccessRightConfiguration[],
        dirtyFields: Partial<Readonly<any>>,
        resetField: UseFormResetField<FieldValues>,
        key: string,
    ) => {
        setIsLoading(true);
        const promises: Promise<any>[] = [];

        data.forEach((accessRight, index: number) => {
            if (accessRight.checked) {
                // save access right if it is checked and either hierarchical or checked is dirty
                if (dirtyFields[key]?.[index]?.hierarchical || dirtyFields[key]?.[index]?.checked) {
                    promises.push(
                        dispatch(saveAccessRightConfiguration(accessRight))
                            .then(unwrapResult)
                            .then(() => toast(t("saved") + " " + t(accessRight.accessRightType)))
                            .catch((e) => {
                                toast(t("error") + ": " + e.message + " " + t(accessRight.accessRightType), {
                                    type: "error",
                                });
                                resetField(`${key}.${index}`);
                            }),
                    );
                }
            }
            if (!accessRight.checked && dirtyFields[key]?.[index]?.checked) {
                promises.push(
                    dispatch(deleteAccessRightConfiguration(accessRight))
                        .then(unwrapResult)
                        .then(() => toast(t("deleted") + " " + t(accessRight.accessRightType)))
                        .catch((e) => {
                            toast(t("error") + ": " + e.message + " " + t(accessRight.accessRightType), {
                                type: "error",
                            });
                            resetField(`${key}.${index}`);
                        }),
                );
            }
        });

        Promise.allSettled(promises).finally(() => setIsLoading(false));
    };

    return [submitAccessRights, isLoading] as const;
};

export default useSubmitAccessRights;
