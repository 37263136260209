import { isEmpty, isArray, isObject, compact } from "lodash/fp";

function childrenGuard<T>(value: T): value is T & { children: T[] } {
    const valueWithChildren = value as T & { children: T[] };
    return isArray(valueWithChildren.children);
}

export const numberOfChildrens = <T>(data: T[], current = 0) =>
    data.reduce((prev: number, current: T): number => {
        if (!childrenGuard(current)) return prev;
        return numberOfChildrens(current.children, prev + 1);
    }, current);
export const findChildren = <T, C>(stop: (data: T) => C, data: Array<T>) =>
    data.reduce<T>((prev, current): T => {
        if (!isEmpty(prev)) return prev;
        if (stop(current)) {
            return current;
        }
        if (childrenGuard(current)) {
            return findChildren(stop, current.children);
        }
        return {} as T;
    }, {} as T);

export const filterChildren = <T, C>(
    predict: (data: T) => C,
    data: Array<T>,
    filterType: "reduce" | "firstMatch" | "onlyMatch" = "onlyMatch",
) =>
    data.reduce((prev: Array<T>, current: T): T[] => {
        let addElement: T | null = null;
        if (predict(current)) {
            addElement = current;
        }
        if (!addElement) {
            if (childrenGuard(current)) {
                return filterType === "reduce"
                    ? prev
                    : [...prev, ...filterChildren(predict, current.children, filterType)];
            }
            return prev;
        }
        if (!childrenGuard(current)) return [...prev, addElement];
        const result =
            filterType === "firstMatch"
                ? [...prev, addElement]
                : [
                      ...prev,
                      {
                          ...addElement,
                          children: filterChildren(predict, current.children, filterType),
                      },
                  ];
        return result;
    }, [] as Array<T>);

export const mapChildren = <T, TResult>(change: (data: T) => TResult, data: T[]) =>
    data.reduce(
        (prev: TResult[], current: T): TResult[] => {
            let addElement = change(current);
            if (isArray(addElement)) {
                return [...prev, ...addElement];
            }
            if (!childrenGuard(current)) {
                return [...prev, addElement];
            }
            if (isObject(addElement)) {
                return [
                    ...prev,
                    {
                        ...addElement,
                        children: mapChildren(change, current.children),
                    },
                ];
            }
            return [...prev, addElement, ...mapChildren(change, current.children)];
        },
        [] as (TResult & { child?: boolean })[],
    );

export const findAllAncestors = <T, C>(predict: (data: T) => C, includeChildren = false, data: T): T | undefined => {
    let value = undefined as T[] | undefined;
    if (predict(data)) {
        let children: T[] = [];
        if (childrenGuard(data)) {
            children = includeChildren ? data.children : [];
        }
        return { ...data, match: true, children: children };
    }
    if (childrenGuard(data)) {
        if (isEmpty(data.children)) {
            return undefined;
        }
        value = compact(data.children.map((x) => findAllAncestors(predict, includeChildren, x)));
    }
    return !isEmpty(value) ? { ...data, children: value } : undefined;
};

export const findAncestorsWithChildrenAndMatch = <T, C>(predict: (data: T) => C, data: T[]): T[] => {
    return compact(data.map((x) => findAllAncestors(predict, true, x)));
};
export const removeParentElement = <T, C>(predict: (data: T) => C, data: T) => {
    if (!childrenGuard(data)) return data;
    if (predict(data)) {
        return data.children;
    }
    return data;
};

export const reduceFilterChildren = <T, C>(predict: (data: T) => C, data: Array<T>) =>
    filterChildren(predict, data, "reduce");

export const firstElementMatch = <T, C>(predict: (data: T) => C, data: Array<T>) =>
    filterChildren(predict, data, "firstMatch");
export const findAllByProperty: <T>(
    property: T extends infer Item ? keyof Item : never,
    data: T[],
) => T[] | undefined = (property, data) => filterChildren((data) => data[property], data);

export const mapChildrenOptimized = <T extends any[], C>(value: (key: T[number]) => C, data: T): C[] => {
    let newData: (C & { children: T })[] = [];
    for (let i = 0; i < data.length; i++) {
        // @ts-expect-error
        newData[i] = value(data[i]);
        if (childrenGuard(newData[i])) {
            // @ts-expect-error
            newData[i] = {
                ...newData[i],
                children: mapChildrenOptimized(value, newData[i].children),
            };
            continue;
        }
    }
    return newData;
};

export const findChildrenOptimized = <T extends any[]>(
    value: (key: T[number]) => boolean,
    data: T,
): T[number] | undefined => {
    let findChild: T[number] | undefined = undefined;
    if (data.length === 0) return undefined;
    for (let i = 0; i < Math.round(data.length / 2); i++) {
        for (let y = data.length - 1; y >= Math.round(data.length / 2); y--) {
            if (value(data[y])) {
                return data[y];
            }
            if (childrenGuard(data[y])) {
                findChild = findChildrenOptimized(value, data[y].children);
                if (!findChild) {
                    continue;
                }
                return findChild;
            }
            return undefined;
        }
        if (value(data[i])) {
            return data[i];
        }
        if (childrenGuard(data[i])) {
            findChild = findChildrenOptimized(value, data[i].children);
            if (!findChild) {
                continue;
            }
            return findChild;
        }
        return undefined;
    }
    return findChild;
};
