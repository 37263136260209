import { Dispatch, SetStateAction } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import { getDate, getDateLocale } from "utils/date";

interface DateRangePickerComponent {
    defaultMonth: Date | undefined;
    setDefaultMonth: Dispatch<SetStateAction<Date | undefined>>;
    disableDatesBefore?: string;
    disableDatesAfter?: string;
    selected: DateRange | undefined;
    handleSelect: (selected: DateRange | undefined) => void;
    handleDayMouseEnter: (day: Date) => void;
    isDateInRange: (day: Date) => boolean;
    language: string | undefined;
    className: string;
    nrOfMonths: number;
}

const DateRangePickerComponent = ({
    defaultMonth,
    setDefaultMonth,
    selected,
    handleSelect,
    handleDayMouseEnter,
    isDateInRange,
    disableDatesBefore,
    disableDatesAfter,
    language,
    className,
    nrOfMonths,
}: DateRangePickerComponent) => {
    const locale = getDateLocale(language);
    return (
        <DayPicker
            month={defaultMonth}
            onMonthChange={setDefaultMonth}
            className={className}
            mode="range"
            locale={locale}
            numberOfMonths={nrOfMonths}
            selected={selected}
            onSelect={(e) => handleSelect(e)}
            onDayMouseEnter={handleDayMouseEnter}
            modifiers={{ hoveredRange: isDateInRange }}
            modifiersClassNames={{ hoveredRange: "rounded-none" }}
            disabled={{
                before: getDate(disableDatesBefore),
                after: getDate(disableDatesAfter),
            }}
            captionLayout="dropdown-buttons"
            fromYear={getDate(disableDatesBefore).getFullYear()}
            toYear={getDate(disableDatesAfter).getFullYear()}
            showWeekNumber
        />
    );
};

export default DateRangePickerComponent;
