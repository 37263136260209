import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchConfigurationFields as fetchConfigurationFieldsAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const fetchConfigurationFields = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(fetchConfigurationFieldsAction.fulfilled, (state, action) => {
            if (action.payload) {
                state.configurationFields = action.payload;
            }
            state.status.configurationFields = Status.OK;
        })
        .addCase(fetchConfigurationFieldsAction.pending, (state) => {
            state.status.configurationFields = Status.PENDING;
        })
        .addCase(fetchConfigurationFieldsAction.rejected, (state) => {
            state.status.configurationFields = Status.ERROR;
        });

export default fetchConfigurationFields;
