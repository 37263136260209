import PrimaryButton from "components/PrimaryButton";
import { getRetailerBg } from "pages/login/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";

export interface IHandleCookies {
    setCookiePolicyOpen: (cookiePolicyOpen: boolean) => void;
}

const checkIfConsentIsSet = (activeRetailer: string) => {
    if (activeRetailer === "Feelgood") {
        return localStorage.getItem("cookieConsentFeelGood");
    }
    return localStorage.getItem("cookieConsent");
};

const HandleCookies = ({ setCookiePolicyOpen }: IHandleCookies) => {
    const { t } = useTranslation("login");
    const activeRetailer = useAppSelector(UserSelectors.getUserRetailer);
    const [open, setOpen] = useState(false);
    const handleAcceptClicked = () => {
        if (activeRetailer === "Feelgood") {
            localStorage.setItem("cookieConsentFeelGood", "true");
        } else {
            localStorage.setItem("cookieConsent", "true");
        }
        setOpen(false);
    };

    useEffect(() => {
        setOpen(checkIfConsentIsSet(activeRetailer) !== "true");
    }, [activeRetailer]);

    return (
        <div data-cy="handle-cookies">
            <ReactModal
                id="loginModal"
                isOpen={open}
                className="absolute w-full px-5 py-8 bottom-0 right-0 bg-pageBg max-w-xl xs:bottom-5 xs:right-5"
                style={{
                    overlay: {
                        zIndex: 10,
                        backgroundColor: "rgba(94, 95, 86, 0.5)",
                    },
                    content: { background: getRetailerBg(activeRetailer) },
                }}
            >
                <div id="handleCookiesContent" className={`${activeRetailer} flex flex-col`}>
                    <h1 className="mb-6">{t("handleCookies")}</h1>
                    <p className="mb-6">
                        {t("handleCookiesText")}
                        <button
                            type="button"
                            className="w-max border-b border-mhdarkgreen-original hover:border-mhneongreen-original"
                            onClick={() => setCookiePolicyOpen(true)}
                        >
                            {t("cookiePolicyLinkText")}
                        </button>
                        .
                    </p>
                    <PrimaryButton
                        id="handleCookiesButton"
                        text={t("acceptCookies")}
                        className="h-12 max-w-max flex justify-center"
                        onClick={handleAcceptClicked}
                    />
                </div>
            </ReactModal>
        </div>
    );
};

export default HandleCookies;
