import Notification from "pages/FollowUp/components/Notification";
import React, { useMemo } from "react";
import { twMerge } from "tailwind-merge";

interface IProps {
    header?: string;
    body?: string | React.ReactElement;
    boldHeader?: boolean;
    Icon?: React.ReactElement;
    notification?: boolean;
    className?: string;
    children?: React.ReactNode;
    noHeight?: boolean;
    showBody?: boolean;
    fontSize?: "xs" | "sm" | "base";
}
const ColumnText = ({
    header,
    body,
    Icon,
    className,
    boldHeader = false,
    children,
    noHeight = true,
    notification = false,
    fontSize = "base",
    showBody = true,
    ...rest
}: IProps) => {
    const headerStyle = boldHeader ? "font-bold" : "text-grey-600";
    const bodyStyle = boldHeader ? "text-grey-600" : "font-bold";
    const FontSize = useMemo(() => {
        switch (fontSize) {
            case "xs": {
                return "text-xs";
            }
            case "sm": {
                return "text-sm";
            }
            case "base": {
                return "text-base";
            }
            default: {
                return "text-base";
            }
        }
    }, [fontSize]);
    const Render = useMemo(
        () =>
            children ? (
                children
            ) : (
                <>
                    <div className="flex gap-1">
                        <p className={`${headerStyle} ${FontSize}`}>{header}</p>
                        {Icon && Icon}
                    </div>
                    {showBody && (
                        <div className={`${bodyStyle} ${FontSize}`}>
                            <div className="flex">
                                {body}
                                {notification && <Notification seen={false} />}
                            </div>
                        </div>
                    )}
                </>
            ),
        [FontSize, Icon, body, bodyStyle, children, header, headerStyle, notification, showBody],
    );
    return (
        <div
            className={twMerge("flex flex-col px-8", body && "justify-center", !noHeight && "h-19", className)}
            {...rest}
        >
            {Render}
        </div>
    );
};

export default ColumnText;
