import { useTranslation } from "react-i18next";

const LastActivatedRehabWidgetLoader = () => {
    const { t } = useTranslation("dashboard");
    return (
        <div className="flex h-full justify-center pt-3 pb-10 animate-pulse">
            <ul className="flex flex-col w-full px-6 text-sm ">
                <li className="flex justify-between h-10 items-center font-bold pb-2">
                    <div>{t("employee")}</div>
                    <div>{t("startDateWidget")}</div>
                </li>
                <li className="flex justify-between items-center h-11 border-t px-2 py-3 border-secondaryBorder">
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-26"></div>
                    </div>
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
                    </div>
                </li>
                <li className="flex justify-between items-center h-11 border-t px-2 py-3 border-secondaryBorder">
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-56"></div>
                    </div>
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
                    </div>
                </li>
                <li className="flex justify-between items-center h-11 border-t px-2 py-3 border-secondaryBorder">
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-42"></div>
                    </div>
                    <div>
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default LastActivatedRehabWidgetLoader;
