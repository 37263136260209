import { debounce } from "lodash/fp";
import { useMemo } from "react";
const useDebounceFunction = <T extends (...args: any[]) => any>(v: T, time: number) =>
    useMemo(() => {
        const debouneFunc = debounce(time, v);
        return (...args: Parameters<typeof v>) => {
            return debouneFunc(...args);
        };
    }, [time, v]);

export default useDebounceFunction;
