import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserStatus } from "store/userSelectors";
import { TailSpin } from "react-loader-spinner";
import LoadPageData, { IProps } from "./LoadPageData";
import { actions } from "../../../redux";
import { useEffect } from "react";
import { Status } from "store/userStateTypes";

export default function Index(props: IProps) {
    const dispatch = useAppDispatch();
    const userLoadng = useAppSelector(getUserStatus);
    useEffect(() => {
        dispatch(actions.initSearchFollowup());
    }, [dispatch]);

    if (userLoadng === Status.PENDING || userLoadng === Status.ERROR) {
        return (
            <div className="flex justify-center">
                <TailSpin color="black" />
            </div>
        );
    }
    return <LoadPageData>{props.children}</LoadPageData>;
}
