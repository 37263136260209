import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { saveCaseTemplate as saveCaseTemplateAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const saveCaseTemplate = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(saveCaseTemplateAction.fulfilled, (state, action) => {
            if (action.payload) {
                state.caseTemplateList.data.push(action.payload);
                state.caseTemplateList.status = Status.OK;
            }
        })
        .addCase(saveCaseTemplateAction.pending, (state) => {
            state.caseTemplateList.status = Status.PENDING;
        })
        .addCase(saveCaseTemplateAction.rejected, (state) => {
            state.caseTemplateList.status = Status.ERROR;
        });

export default saveCaseTemplate;
