import { DragEvent, ReactNode } from "react";

interface IDraggable {
    children: ReactNode;
    handleDragStart: (e: DragEvent<HTMLDivElement>) => void;
    handleDrop: (e: DragEvent<HTMLDivElement>) => void;
    handleDragOver: (e: DragEvent<HTMLDivElement>) => void;
    handleDragLeave: (e: DragEvent<HTMLDivElement>) => void;
    isHovered?: boolean;
    isDragged?: boolean;
    isHoveredStyle?: string;
}

const Draggable = ({
    children,
    handleDragStart,
    handleDrop,
    handleDragOver,
    handleDragLeave,
    isHovered,
    isHoveredStyle,
}: IDraggable) => {
    return (
        <div
            className="cursor-grab"
            draggable={true}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
        >
            {isHovered ? <div className={isHoveredStyle} /> : children}
        </div>
    );
};

export default Draggable;
