import { Trans, useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { thunkActions } from "pages/FollowUp/redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
    getUserFullName,
    getUserContactInfo,
    getUserPrimaryCompany,
    getUserRetailer,
    getUserLanguage,
    getActiveRetailerId,
    getUserAccountId,
} from "store/userSelectors";
import {
    getPartnerContactInformation,
    getPartnerContactInformationStatus,
    getPartnerContactRequestStatus,
    getUserEmployment,
} from "pages/FollowUp/redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { toast } from "react-toastify";
import { Status } from "@medhelp/ui/LoadableComponent";
import { CaseContentContainer } from "pages/FollowUp/components/HealthCaseManagement";
import { MiniModal } from "@medhelp/ui/Modals";
import CaseHealthContactLoading from "./CaseHealthContactLoading";
import PrimaryButton from "components/PrimaryButton";
import { Spinner } from "components/Spinner";

interface IProps {
    healthCase?: IHealthCase;
}

const CaseHealthContact = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const selectedEmployee = useAppSelector(getUserEmployment);
    const retailerId = useAppSelector(getActiveRetailerId);
    const retailerName = useAppSelector(getUserRetailer);
    const userAccountId = useAppSelector(getUserAccountId);
    const language = useAppSelector(getUserLanguage);
    const name = useAppSelector(getUserFullName);
    const contactInformation = useAppSelector(getUserContactInfo);
    const userCompany = useAppSelector(getUserPrimaryCompany);
    const healthContactInformation = useAppSelector(getPartnerContactInformation);
    const informationStatus = useAppSelector(getPartnerContactInformationStatus);
    const requestStatus = useAppSelector(getPartnerContactRequestStatus);
    const [city, setCity] = useState("");

    useEffect(() => {
        if (selectedEmployee?.companyId && retailerId) {
            dispatch(
                thunkActions.fetchPartnerContactInformation({
                    retailerId,
                    language: language ?? "en",
                    companyId: selectedEmployee?.companyId,
                }),
            );
        }
    }, [dispatch, language, retailerId, selectedEmployee?.companyId]);

    const handleSendToHealthContact = () => {
        if (userAccountId && selectedEmployee?.id && selectedEmployee?.companyId && healthCase?.id && retailerId) {
            dispatch(
                thunkActions.sendContactRequest({
                    userAccountId,
                    employmentId: selectedEmployee?.id,
                    companyId: selectedEmployee?.companyId,
                    city,
                    healthCaseId: healthCase?.id,
                    retailerId,
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("contactRequestSent")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("contactRequestNotSent")}`, {
                        type: "error",
                    }),
                );
            setCity("");
        }
    };

    const inputValues = () => {
        return [
            {
                key: "name",
                value: name,
            },
            {
                key: "phone",
                value: contactInformation?.secondaryMobile,
            },
            {
                key: "email",
                value: contactInformation?.email,
            },
            {
                key: "company",
                value: userCompany?.name,
            },
        ];
    };

    const getHealthContactInformation = () => {
        if (informationStatus === Status.PENDING) {
            return <CaseHealthContactLoading />;
        }
        return healthContactInformation;
    };

    return (
        <CaseContentContainer
            heading={`${t("contact")} ${retailerName}`}
            text={getHealthContactInformation()}
            healthCase={healthCase}
        >
            <div className="bg-white m-8 mb-12 px-8 py-12">
                <div
                    className={`border-b border-grey-200 pb-12 ${requestStatus === Status.PENDING ? "opacity-30" : ""}`}
                >
                    <h3 className="pb-5">{t("contactMeRegardingMyEmployee")}</h3>
                    <p className="pb-4 font-bold">{t("yourContactDetails")}</p>
                    {inputValues().map((input) => (
                        <p key={`case-health-contact-${input.key}`}>{input.value}</p>
                    ))}
                </div>
                <div
                    className={`border-b border-grey-200 pt-12 pb-6 ${
                        requestStatus === Status.PENDING ? "opacity-30" : ""
                    }`}
                >
                    <div className="flex flex-col font-bold mb-6 w-90">
                        <div className="flex">
                            <label htmlFor="city-input" className="mb-1.5 pr-2">
                                {`${t("employeesCity")} *`}
                            </label>
                            <MiniModal heading={t("employeesCity")} content={t("employeesCityInfo")}></MiniModal>
                        </div>
                        <div className="flex sm:w-full">
                            <input
                                id="city-input"
                                className="sm:w-full border border-mhdarkgreen-original bg-transparent py-3 px-4 "
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className={`pt-12 ${requestStatus === Status.PENDING ? "opacity-30" : ""}`}>
                    <p className="pb-12">
                        {
                            <Trans
                                i18nKey={t("contactMeInfo")}
                                values={{
                                    retailer: retailerName,
                                }}
                            />
                        }
                    </p>
                    <PrimaryButton
                        id="send-health-contact"
                        text={t("contactMe")}
                        onClick={() => handleSendToHealthContact()}
                        disabled={!city}
                    />
                </div>
                {requestStatus === Status.PENDING && (
                    <div className="absolute inset-0 flex justify-center items-center">
                        <Spinner />
                    </div>
                )}
            </div>
        </CaseContentContainer>
    );
};

export default CaseHealthContact;
