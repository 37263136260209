import { Tab, tabs } from "pages/Administration/types";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

export interface ISubscriptionTabCompProps {
    selectedUser: string;
    noOfSubscriptions: number;
    noOfSubscribers: number;
    activeTab: Tab;
    setActiveTab: (activeTab: Tab) => void;
}

const SubscriptionTabs = ({
    selectedUser,
    noOfSubscriptions,
    noOfSubscribers,
    activeTab,
    setActiveTab,
}: ISubscriptionTabCompProps) => {
    const { t } = useTranslation("administration");
    return (
        <div className="border-b">
            <div className="px-10 gap-8 flex">
                {tabs.map((tab) => (
                    <button className="!shadow-none" key={tab} onClick={() => setActiveTab(tab)}>
                        <p
                            className={twMerge(
                                "h-16 flex items-center",
                                activeTab === tab ? "font-bold border-b-3 border-b-mhdarkgreen-original" : "pb-1",
                            )}
                        >{`${selectedUser} ${t(tab, {
                            number: tab === "userSubsOn" ? noOfSubscriptions : noOfSubscribers,
                        })}`}</p>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SubscriptionTabs;
