import { compact, filter, flow, get, has, includes, isEmpty, isEqual } from "lodash/fp";
import { CompanyRegion } from "swagger/employeeattendance";
import { isNotEmpty } from "utils/functions";
import { Traverser } from ".";
import {
    findAllByProperty,
    findChildren,
    mapChildren,
    mapChildrenOptimized,
    removeParentElement,
} from "./iterateChildren";
import { IDynamicData } from "./types";

/**
 * Check if all selected in dynamic data
 * @returns true if all selected
 */
export const isAllSelected: (data: IDynamicData[]) => boolean = (data) =>
    data.every((current) => {
        const isChecked = current.checked || !current.selectable;
        return current.children && isChecked ? isAllSelected(current.children) : isChecked;
    });

export function isDynmaicData(values: CompanyRegion | IDynamicData): values is IDynamicData {
    if (has("checked", values)) {
        return true;
    }
    return false;
}
export const setCheckedValue = (checked: boolean, selectAll = false, storeData: string[], data: IDynamicData[]) =>
    mapChildrenOptimized((x) => {
        if (!selectAll && !isEmpty(storeData)) {
            let value = includes(x.referenceKey, storeData);
            if (value) {
                return {
                    ...x,
                    checked: value,
                    children: mapChildrenOptimized(
                        (y) => ({
                            ...y,
                            checked: true,
                        }),
                        x.children || [],
                    ),
                };
            }
            return x;
        }
        return {
            ...x,
            selectable: x.selectable && selectAll ? selectAll : x.selectable,
            checked: !x.selectable && !selectAll ? false : checked,
        };
    }, data);

export const getCheckedById = (referenceKey: string, selectAllTreeViewNodes: boolean, data: IDynamicData[]) => {
    const cdata = findChildren(flow(get("referenceKey"), isEqual(referenceKey)), data);
    return isEmpty(cdata) && selectAllTreeViewNodes ? true : cdata?.checked;
};

export const removeTopFields = (data: IDynamicData[]) => filter(({ top }) => !top, data);

export const initalizeDynmaicData = (data: IDynamicData[]) => {
    const removedTopDepartment = mapChildren((x) => removeParentElement((y) => y.top, x), data);
    return mapChildren(
        (x) => ({
            ...x,
            checked: false,
        }),
        removedTopDepartment,
    );
};

export const hasAnyChildren = (data: IDynamicData[]) => data.some(flow(get("children"), isNotEmpty));

const makeOneDynamicDataChecked = (data: IDynamicData[]) => {
    const elements = findAllByProperty("selectable", data);
    return elements?.length === 1
        ? mapChildren((x) => {
              if (x.id === elements?.[0]?.id) {
                  return {
                      ...x,
                      checked: true,
                  };
              }
              return x;
          }, data)
        : data;
};
const addCheckValues =
    (selectAllTreeViewNodes: boolean, selectAll: boolean, storeData: string[]) => (data: IDynamicData[]) =>
        setCheckedValue(selectAllTreeViewNodes, selectAll, storeData, data);
/**
 * Create a new IDynmaicData from `TreeViewNode` -> `departments`
 * Remove top field, add stored and select all `departments`
 */
export const convertTreeViewNodeToDynamicData: (
    selectAllTreeViewNodes: boolean,
    selectAll: boolean,
    data: IDynamicData[],
    storeData: string[],
) => IDynamicData[] = (selectAllTreeViewNodes, selectAll, data, storeData) =>
    flow(
        initalizeDynmaicData,
        addCheckValues(selectAllTreeViewNodes, selectAll, storeData),
        makeOneDynamicDataChecked,
    )(data);

export const getIdFromSelected: (data: IDynamicData[]) => (string | number)[] = flow(
    (data) => Traverser.findAllByProperty("checked", data),
    (data) =>
        Traverser.mapChildren((x) => {
            return x.id;
        }, data || []),
    compact,
);

export const getReferenceKeyFromSelected: (data: IDynamicData[]) => (string | number)[] = flow(
    (data) => Traverser.findAllByProperty("checked", data),
    (data) =>
        Traverser.mapChildren((x) => {
            return x.referenceKey;
        }, data || []),
    compact,
);
