import { MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule } from "swagger/absencefollowup";
import { MedHelpHCMWebApiModelsTemplateRule } from "swagger/healthCaseManagement";

// Deviant Rules
export const socialInsuranceDeviantRuleObject: MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule = {
    ruleName: "AbsenceMaxLengthFK",
    value: "14",
    absenceType: "Sick",
};

export const danishLongTermSickLeaveDeviantRuleObject: MedHelpAbsenceFollowUpWebApiModelsCreateDeviantCustomerRule = {
    ruleName: "AbsenceMaxDaysMonths",
    value: "115",
    interval: "12",
    absenceType: "Sick",
};

// HCM RULES
export const manualInitiationHCMRuleObject: MedHelpHCMWebApiModelsTemplateRule = {
    ruleType: "initiation",
    ruleName: "ManualInitiation",
    params: "",
};

// REHAB SPECIFIC HCM RULES
export const socialInsuranceHCMRuleObject: MedHelpHCMWebApiModelsTemplateRule = {
    ruleType: "initiation",
    ruleName: "DeviantInitiation",
    params: "longterm,14FK",
};

// HEALTH PROMOTION SPECIFIC HCM RULES
export const deviantInitiation3in6HCMRuleObject: MedHelpHCMWebApiModelsTemplateRule = {
    ruleType: "initiation",
    ruleName: "DeviantInitiation",
    params: "recurringperiod,3/6",
};

export const deviantInitiation6in12HCMRuleObject: MedHelpHCMWebApiModelsTemplateRule = {
    ruleType: "initiation",
    ruleName: "DeviantInitiation",
    params: "recurringperiod,6/12",
};

export const healthCaseInactiveHCMRuleObject: MedHelpHCMWebApiModelsTemplateRule = {
    ruleType: "closing",
    ruleName: "HealthCaseInactive",
    params: "6",
};

export const riskInitiationHCMRuleObject: MedHelpHCMWebApiModelsTemplateRule = {
    ruleType: "initiation",
    ruleName: "RiskInitiation",
    params: "larix_v2",
};

export const danishLongTermSickLeaveHCMRuleObject: MedHelpHCMWebApiModelsTemplateRule = {
    ruleType: "initiation",
    ruleName: "AbsenceMaxDaysMonths",
    params: "recurring,115/12",
};
