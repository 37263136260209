import {
    findChildrenOptimized,
    mapChildren,
    mapChildrenOptimized,
    reduceFilterChildren,
} from "domain/ogranization/iterateChildren";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { flow, isEmpty, isNumber, keys, reduce, times } from "lodash/fp";
import * as Builders from "./builders";
import {
    isSortingFilter,
    isSearchBasedOn,
    isSearchAffectedDepartments,
    ISearchSortingFilter,
    ShowBasedOn,
    ShowDepartments,
} from "../types";
import {
    initialEmployeeCardState,
    initialSearchState,
    IState,
    ISeachActionPayloadContent,
    Status,
    ISearchCompanyDepartmentPayload,
    ISearchPayload,
} from ".";
import {
    addToSearchFilters,
    resetSpecificSearchFilter,
    SearchTypes,
    SEARCH_PAGE_SIZE,
    updateRadioFilters,
} from "../utils";
import { IUpdateActivityPayload, IUpdateNotePayload } from "./followUpStateTypes";
import { filtersInitial } from "./initialStateValues";
import { IDynamicData } from "domain/ogranization/types";

export const initialfollowUpState: IState = {
    ...initialSearchState,
    ...initialEmployeeCardState,
    caseTemplateList: {
        data: [],
        status: Status.OK,
    },
    hasRehabServiceContract: {
        data: false,
        status: Status.OK,
    },
    organizationTreeRehabRights: {
        data: [],
        status: Status.PENDING,
    },
};
const followUpSlice = createSlice({
    name: "followup",
    initialState: initialfollowUpState,
    reducers: {
        clearHealthCasePdfInitState(state, action: PayloadAction<string>) {
            const selectedHealthCase = state.healthCases.find(({ id }) => id === action.payload);
            if (selectedHealthCase) {
                selectedHealthCase.pdfUrl = "";
                selectedHealthCase.annotation = "";
                selectedHealthCase.healthCaseSubStatus.pdfDocumentBasedOnTemplateSaved = Status.PENDING;
                selectedHealthCase.healthCaseSubStatus.pdfDocumentTemplate = Status.PENDING;
            }
        },
        clearSearchFilters(state) {
            Object.assign(
                state.searchFilter[state.currentSearch].filters,
                filtersInitial.concat(
                    state.searchFilter[state.currentSearch].filters.filter(
                        (x) => x.id === "codes" || x.id === "employmentGroups",
                    ),
                ),
            );
            Object.assign(
                state.searchFilter[state.currentSearch],
                resetSpecificSearchFilter(state.searchFilter[state.currentSearch]),
            );
        },
        setStoredDepartments(state, action: PayloadAction<IDynamicData[]>) {
            state.searchCompaniesDepartments.departments = action.payload;
        },
        setCurrentSearch(state, action: PayloadAction<SearchTypes>) {
            state.currentSearch = action.payload;
        },
        updateSearchFilter(state, action: PayloadAction<ISeachActionPayloadContent>) {
            state.searchFilter[state.currentSearch].filters = addToSearchFilters(
                action.payload,
                state.searchFilter[state.currentSearch].filters,
            );
        },
        updateRadioSearchFilter(state, action: PayloadAction<ISeachActionPayloadContent>) {
            state.searchFilter[state.currentSearch].filters = updateRadioFilters(
                action.payload,
                state.searchFilter[state.currentSearch].filters,
            );
        },
        updateSearchPageNumber(state, action: PayloadAction<number>) {
            state.pageNumber[state.currentSearch].currentSearchPageNumber = action.payload;
        },
        updateShowBasedOn(state, action: PayloadAction<ShowBasedOn>) {
            state.searchFilter[state.currentSearch].showBasedOn = action.payload;
        },
        updateCurrentSearch(state, action: PayloadAction<SearchTypes>) {
            const type = action.payload;
            state.currentSearch = type;
            state.searchFilter[type].sorting = "employeeFirstNameAscending";
            state.searchCompaniesDepartments.departments = [];
            state.searchCompaniesDepartments.companies = state.searchCompaniesDepartments.companies
                ? mapChildren(
                      (x) => ({
                          ...x,
                          checked: false,
                      }),
                      state.searchCompaniesDepartments.companies,
                  )
                : null;
        },
        resetSearchDepartments(state) {
            state.searchCompaniesDepartments.departments = [];
            state.searchCompaniesDepartments.storedDepartments = [];
        },
        updateCompanyDepartmentSearch(state, action: PayloadAction<ISearchCompanyDepartmentPayload>) {
            if (action.payload.value === "select" || action.payload.value === "deselect") {
                const newSelected = mapChildrenOptimized(
                    (x) => ({
                        ...x,
                        checked: action.payload.value === "select" ? true : false,
                    }),
                    state.searchCompaniesDepartments[action.payload.field] || [],
                );
                if (action.payload.field === "departments") {
                    state.searchCompaniesDepartments.storedDepartments = mapChildrenOptimized(
                        (x) => x.referenceKey,
                        reduceFilterChildren((x) => x.checked, newSelected),
                    );
                }
                state.searchCompaniesDepartments[action.payload.field] = newSelected;
                return;
            }
            let value = findChildrenOptimized(
                (x) => x.referenceKey === action.payload.value,
                state.searchCompaniesDepartments[action.payload.field] || [],
            );
            if (value) {
                value.checked = !value.checked;
                value.children = mapChildrenOptimized(
                    (x) => ({
                        ...x,
                        checked: value?.checked ?? true,
                    }),
                    value?.children || [],
                );
                if (action.payload.field === "departments") {
                    state.searchCompaniesDepartments.storedDepartments = mapChildrenOptimized(
                        (x) => x.referenceKey,
                        reduceFilterChildren(
                            (x) => x.checked,
                            state.searchCompaniesDepartments[action.payload.field] || [],
                        ),
                    );
                }
            }
        },
        updateSearchFilterSorting(state, action: PayloadAction<ISearchSortingFilter>) {
            if (isSortingFilter(action.payload)) {
                state.searchFilter[state.currentSearch].sorting = action.payload.value;
            }
            if (isSearchBasedOn(action.payload)) {
                state.searchFilter[state.currentSearch].showBasedOn = action.payload.value;
            }
            if (isSearchAffectedDepartments(action.payload)) {
                state.searchFilter[state.currentSearch].showDepartments = action.payload.value;
            }
        },
        updatePaginatorPageNumber(state, action: PayloadAction<number>) {
            state.pageNumber[state.currentSearch].paginatorPageNumber = action.payload;
        },
        showDepartments(state, action: PayloadAction<ShowDepartments>) {
            state.searchFilter[state.currentSearch].showDepartments = action.payload;
        },
        updateCurrentUser(state, action: PayloadAction<string>) {
            state.currentUser = action.payload;
        },
        companyDepartmentReady(state) {
            state.status.companiesWithRegion = Status.OK;
            state.status.organizationTree = Status.OK;
            state.status.employmentGroups = Status.OK;
        },
        setUserGuard(state, action: PayloadAction<string>) {
            state.userGuard = action.payload;
        },
        initSearchFollowup(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            _,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<"all" | "companies" | "departments" | undefined>,
        ) {},
        resetFollowUpState(state) {
            Object.assign(state, initialfollowUpState);
        },
        resetEmploymentCard(state) {
            Object.assign(state, initialEmployeeCardState);
        },
        resetSearch(state) {
            Object.assign(state, initialSearchState);
        },
        addSearch(state, action: PayloadAction<ISearchPayload>) {
            const { type, pageNumber, pageSize, totalCount } = action.payload;

            if (!pageNumber) {
                state.pageNumber[state.currentSearch] = {
                    currentSearchPageNumber: 1,
                    paginatorPageNumber: 1,
                };
            }
            if (action.payload && type) {
                if (pageNumber !== undefined && isNumber(totalCount) && isNumber(pageSize)) {
                    // reset data
                    if (totalCount === 0) {
                        state.search[type] = {};
                        state.searchHasMore[type] = {};
                        state.searchStatus[type] = "empty";
                        return;
                    }
                    // add data
                    state.search[type][pageNumber] = action.payload;

                    // initialize pages status
                    // Used when user needs to fetch more data
                    const numberOfPages = Math.ceil(totalCount / SEARCH_PAGE_SIZE);

                    times((value) => {
                        const pageValue = value + 1;
                        const hasValue = state.search[type][pageValue];
                        if (isEmpty(hasValue)) {
                            state.searchHasMore[type][value + 1] = false;
                        }
                    }, numberOfPages);
                    state.searchHasMore[type][pageNumber] = true;
                    let newSearch = reduce(
                        (prev, current) => {
                            const currentAsInt = parseInt(current);
                            if (pageNumber >= currentAsInt) {
                                return {
                                    ...prev,
                                    [current]: state.search[type][currentAsInt],
                                };
                            }
                            return prev;
                        },
                        {},
                        keys(state.search[type]),
                    );
                    let searchHasMore = reduce(
                        (prev, current) => {
                            const currentAsInt = parseInt(current);
                            if (pageNumber >= currentAsInt) {
                                return {
                                    ...prev,
                                    [current]: state.searchHasMore[type][currentAsInt],
                                };
                            }
                            return prev;
                        },
                        {},
                        keys(state.searchHasMore[type]),
                    );
                    state.search[type] = newSearch;
                    state.searchHasMore[type] = searchHasMore;
                }
            }
            if (action.payload.type) state.searchStatus[action.payload.type] = "ok";
        },
        updateSelectedActivityField(state, action: PayloadAction<IUpdateActivityPayload>) {
            const selectedHealthCase = state.healthCases.find(({ id }) => id === action.payload.healthCaseId);
            const selectedActivity = selectedHealthCase?.healthCaseActivities.find(
                ({ id }) => id === action.payload.activityId,
            );
            if (selectedActivity) {
                Object.assign(selectedActivity, {
                    status: Status.OK,
                    [action.payload.field]: action.payload.value,
                });
            } else {
                selectedHealthCase?.healthCaseActivities.push({
                    status: Status.OK,
                    [action.payload.field]: action.payload.value,
                });
            }
        },
        updateSelectedNoteField(state, action: PayloadAction<IUpdateNotePayload>) {
            const selectedHealthCase = state.healthCases.find(({ id }) => id === action.payload.healthCaseId);
            const selectedNote = selectedHealthCase?.healthCaseDocuments.find(({ id }) => id === action.payload.noteId);
            if (selectedNote) {
                Object.assign(selectedNote, {
                    status: Status.OK,
                    [action.payload.field]: action.payload.value,
                });
            } else {
                selectedHealthCase?.healthCaseDocuments.push({
                    status: Status.OK,
                    [action.payload.field]: action.payload.value,
                });
            }
        },
        updateExpandedCase(state, action: PayloadAction<string>) {
            const selectedHealthCase = state.healthCases.find(({ id }) => id === action.payload);
            if (selectedHealthCase) {
                selectedHealthCase.expanded = !selectedHealthCase.expanded;
            }
        },
        updateDocumentsSubstatus(state, action: PayloadAction<string>) {
            const selectedHealthCase = state.healthCases.find(({ id }) => id === action.payload);
            if (selectedHealthCase) {
                selectedHealthCase.healthCaseSubStatus.documents = Status.PENDING;
            }
        },
        updateActivitiesSubstatus(state, action: PayloadAction<string>) {
            const selectedHealthCase = state.healthCases.find(({ id }) => id === action.payload);
            if (selectedHealthCase) {
                selectedHealthCase.healthCaseSubStatus.activities = Status.PENDING;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase("user/signIn", (state) => {
            Object.assign(state, initialfollowUpState);
        });
        flow(
            Builders.fetchAbsenceActivityLogs,
            Builders.fetchAbsenceReports,
            Builders.fetchUserSubscription,
            Builders.fetchUserEmployment,
            Builders.fetchFirstDayMedicalCertificate,
            Builders.saveFirstDayMedicalCertificateRequired,
            Builders.removeFirstDayMedicalCertificateRequired,
            Builders.fetchRehab,
            Builders.exportHistoricalAbsenceReport,
            Builders.fetchSearch,
            Builders.search,
            Builders.saveEmploymentEraseSettings,
            Builders.updateUserProfile,
            Builders.saveHealthCase,
            Builders.saveCaseTemplate,
            Builders.saveCaseActivity,
            Builders.saveCaseDocument,
            Builders.fetchHealthCaseList,
            Builders.fetchCaseTemplateList,
            Builders.fetchCaseActivityList,
            Builders.fetchCaseDocumentList,
            Builders.fetchCaseResponsibleList,
            Builders.fetchCaseNotifications,
            Builders.fetchRehabServiceContract,
            Builders.fetchTemplateDocumentLink,
            Builders.fetchRehabRoutineDocuments,
            Builders.activateHealthCase,
            Builders.declineHealthCase,
            Builders.updateCaseActivity,
            Builders.updateCaseActivityPerformed,
            Builders.updateCaseActivityUndoPerformed,
            Builders.updateCaseDocument,
            Builders.updateCaseResponsible,
            Builders.uploadFileDocument,
            Builders.endHealthCase,
            Builders.deleteHealthCase,
            Builders.deleteCaseActivity,
            Builders.deleteCaseDocument,
            Builders.downloadFileWithTemplate,
            Builders.uploadFileWithTemplate,
            Builders.fetchCostCalculation,
            Builders.fetchCompanyGroup,
            Builders.fetchOrganizaionTreeView,
            Builders.updateCaseStatus,
            Builders.fetchPartnerContactInformation,
            Builders.fetchConfigurationFields,
            Builders.sendContactRequest,
        )(builder);
    },
});

export const followUpActions = followUpSlice.actions;

// presist all the stuf in store
const persistConfig = {
    key: "followup",
    storage: storageSession,
    whitelist: [
        "searchHasMore",
        "search",
        "searchTypeFilters",
        "currentSearch",
        "searchStatus",
        "currentSearchPageNumber",
        "searchFilter",
        "employmentGroups",
        "searchCompaniesDepartments",
        "paginatorPageNumber",
        "userGuard",
    ],
};

export default persistReducer(persistConfig, followUpSlice.reducer);
