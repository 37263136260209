import {
    getServiceSlice,
    getSocialInsuranceDeviantRule,
    getDeviantAbsenceMaxDaysMonths,
    getSocialInsuranceConfiguration,
} from "pages/service/redux/serviceSelectors";
import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import useDispatchWithToast from "pages/service/hooks/useDispatchWithToast";
import useSubmitDeviantRule from "pages/service/hooks/useSubmitDeviantRule";
import { ServiceAccordion, AccordionBooleanContent } from "../ServiceAccordion";
import { ServiceFieldArray, ServiceFieldArrayAppend } from "../ServiceFieldArray";
import { ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import { socialInsuranceDeviantRuleObject } from "pages/service/utils/ruleObjects";
import { deleteDeviantRule, saveDeviantRule } from "pages/service/redux/serviceAsyncActions";
import { MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule } from "swagger/absencefollowup";

interface IDeviantAbsenceMaxDaysMonths {
    deviantAbsenceMaxDaysMonths: MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule[];
    socialInsuranceDeviantRule: {
        hasSocialInsuranceDeviantRule: boolean;
        id: string;
    };
}

const DeviantAbsenceMaxDaysMonths = () => {
    const { t } = useTranslation("service");
    const dispatchWithToast = useDispatchWithToast();
    const { companyId, customerId } = useAppSelector(getServiceSlice);
    const socialInsuranceReporting = useAppSelector(getSocialInsuranceConfiguration);
    const socialInsuranceDeviantRule = useAppSelector(getSocialInsuranceDeviantRule);
    const deviantAbsenceMaxDaysMonths = useAppSelector(getDeviantAbsenceMaxDaysMonths);

    const [submitDeviantRules, isLoading, itemsToDelete, setItemsToDelete] = useSubmitDeviantRule();

    const {
        register,
        control,
        handleSubmit,
        reset,
        getValues,
        resetField,
        formState: { isDirty, dirtyFields },
    } = useForm({
        defaultValues: { deviantAbsenceMaxDaysMonths, socialInsuranceDeviantRule },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "deviantAbsenceMaxDaysMonths",
        // necessary because useFieldArray overwrites the id property
        keyName: "keyName",
    });

    const {
        register: registerInput,
        getValues: getInputValues,
        resetField: resetInputField,
        formState: { isValid: inputIsValid },
    } = useForm({
        defaultValues: { value: undefined, interval: undefined },
    });

    useEffect(() => {
        reset({ deviantAbsenceMaxDaysMonths, socialInsuranceDeviantRule });
    }, [deviantAbsenceMaxDaysMonths, socialInsuranceDeviantRule, reset]);

    const onSubmit = (data: IDeviantAbsenceMaxDaysMonths) => {
        if (dirtyFields.deviantAbsenceMaxDaysMonths) {
            submitDeviantRules(data.deviantAbsenceMaxDaysMonths, () => reset({ deviantAbsenceMaxDaysMonths }));
        }
        if (dirtyFields.socialInsuranceDeviantRule) {
            if (data.socialInsuranceDeviantRule.hasSocialInsuranceDeviantRule) {
                dispatchWithToast(
                    saveDeviantRule(socialInsuranceDeviantRuleObject),
                    t("socialInsuranceDeviantRule"),
                    () => reset({ socialInsuranceDeviantRule }),
                );
            } else {
                dispatchWithToast(
                    deleteDeviantRule(socialInsuranceDeviantRule.id),
                    t("socialInsuranceDeviantRule"),
                    () => reset({ socialInsuranceDeviantRule }),
                );
            }
        }
    };

    const checkForDuplicateValues = (value?: string, interval?: string) =>
        getValues("deviantAbsenceMaxDaysMonths").some((x) => x.value === value && x.interval === interval);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("subscriptionOtherDeviations")}
                reset={() => reset({ deviantAbsenceMaxDaysMonths })}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <>
                        {socialInsuranceDeviantRule.hasSocialInsuranceDeviantRule && (
                            <AccordionBooleanContent label={t("after14IncludingRecurringAbsence")} />
                        )}
                        {fields.map(({ value, interval }) => (
                            <AccordionBooleanContent
                                key={`${value}-${interval}`}
                                label={`${value} ${t(Number(value) === 1 ? "day" : "days")} 
                                ${t("inTotalDuring")} ${interval}
                                ${t(Number(interval) === 1 ? "month" : "months")}`}
                            />
                        ))}
                    </>
                }
            >
                <ServiceCheckboxContainer heading={t("forSocialInsuranceAgency")}>
                    <ServiceCheckboxRow
                        register={register("socialInsuranceDeviantRule.hasSocialInsuranceDeviantRule")}
                        disabled={
                            // should be disabled social insurance exists unless it is out of sync
                            socialInsuranceReporting?.socialInsuranceReporting &&
                            socialInsuranceDeviantRule.hasSocialInsuranceDeviantRule
                        }
                        label={t("after14IncludingRecurringAbsence")}
                    />
                </ServiceCheckboxContainer>
                <ServiceCheckboxContainer heading={t("certainDaysCertainMonths")}>
                    {fields?.map(({ value, interval, id }, index) => (
                        <ServiceFieldArray
                            key={index}
                            index={index}
                            label={`${value} ${t(Number(value) === 1 ? "day" : "days")} 
                                ${t("inTotalDuring")} ${interval}
                                ${t(Number(interval) === 1 ? "month" : "months")}`}
                            onClick={() => {
                                setItemsToDelete([...itemsToDelete, String(id)]);
                                remove(index);
                                resetField("socialInsuranceDeviantRule");
                            }}
                        />
                    ))}
                    <ServiceFieldArrayAppend
                        buttonText={t("addNotification")}
                        onClick={() => {
                            append({
                                absenceType: "Sick",
                                companyId: companyId,
                                customerId: customerId,
                                interval: getInputValues("interval"),
                                ruleName: "AbsenceMaxDaysMonths",
                                value: getInputValues("value"),
                            });
                            resetField("socialInsuranceDeviantRule");
                            resetInputField("value");
                            resetInputField("interval");
                        }}
                        label={t("numberOfDays")}
                        secondaryLabel={t("duringNumberOfMonths")}
                        register={registerInput("value", {
                            required: true,
                            validate: () =>
                                !checkForDuplicateValues(getInputValues("value"), getInputValues("interval")),
                            min: 1,
                            max: 999,
                        })}
                        secondaryRegister={registerInput("interval", {
                            required: true,
                            validate: () =>
                                !checkForDuplicateValues(getInputValues("value"), getInputValues("interval")),
                            min: 1,
                            max: 999,
                        })}
                        disabled={!inputIsValid}
                        type="number"
                    />
                </ServiceCheckboxContainer>
            </ServiceAccordion>
        </form>
    );
};

export default DeviantAbsenceMaxDaysMonths;
