import { useState } from "react";
import { Accordion, NumberField } from "@medhelp/ui";
import FilterHeading, { IProps as IFilterHeading } from "../FilterHeading";
import { isFunction } from "lodash/fp";

export interface IItems {
    value: string;
    setValue: (number: string) => void;
    label: ((value: string) => string) | string;
    name: string;
}
interface IPropsView extends Omit<IFilterHeading, "open"> {
    items: IItems[];
    separator?: JSX.Element;
}

const SearchNumberFieldFilter = ({ items, header, body, separator }: IPropsView) => {
    const [open, setOpen] = useState(false);
    return (
        <Accordion
            heading={<FilterHeading header={header} body={body} open={open} />}
            icon="caretFull"
            setOpen={setOpen}
            open={open}
        >
            <div className={`flex px-6 gap-2 items-center w-full`}>
                {items.map(
                    ({ value, setValue, label }, index) => (
                        <>
                            <NumberField
                                value={value}
                                onChange={setValue}
                                label={isFunction(label) ? label("min") : label}
                            />
                            {separator && index !== items.length - 1 && separator}
                        </>
                    ),
                    [],
                )}
            </div>
        </Accordion>
    );
};

export default SearchNumberFieldFilter;
