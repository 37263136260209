import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { deleteHealthCase as deleteHealthCaseAction } from "../followUpAsyncActions";
import { IState, Status } from "..";

const deleteHealthCase = (builder: ActionReducerMapBuilder<IState>) =>
    builder
        .addCase(deleteHealthCaseAction.fulfilled, (state, action) => {
            const filteredHealthCases = state.healthCases.filter(({ id }) => id !== action.payload);
            state.healthCases = filteredHealthCases;
            state.status.healthCases = Status.OK;
        })
        .addCase(deleteHealthCaseAction.pending, (state, action) => {
            const healthCaseToUpdate = state.healthCases.find(({ id }) => id === action.meta.arg);
            if (healthCaseToUpdate) healthCaseToUpdate.healthCaseStatus = Status.PENDING;
        })
        .addCase(deleteHealthCaseAction.rejected, (state, action) => {
            const healthCaseToUpdate = state.healthCases.find(({ id }) => id === action.meta.arg);
            console.log(action);
            if (healthCaseToUpdate) {
                healthCaseToUpdate.healthCaseStatus = Status.ERROR;
            }
        });

export default deleteHealthCase;
