import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getIsRisk as getIsRiskAction } from "../absenceReportsActions";
import { IAbsenceReportSlice } from "../absenceReportsSlice";

const getIsRisk = (builder: ActionReducerMapBuilder<IAbsenceReportSlice>) =>
    builder
        .addCase(getIsRiskAction.fulfilled, (state, action) => {
            if (action.payload) {
                state.isRisk = action.payload;
                state.IsOK = true;
            }
        })
        .addCase(getIsRiskAction.rejected, (state) => {
            state.IsOK = false;
        });

export default getIsRisk;
