import { upperFirst } from "lodash/fp";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { actions, selectors } from "../../redux";
import MonthDropdown from "./MonthsDropdown";
import { getEachIntervalFormatted, getEndOfDate, getStartOfDate } from "utils/date";

export default function Index() {
    const dispatch = useAppDispatch();
    const monthFilter = useAppSelector(selectors.getSearchMonthById("month"));
    const { t } = useTranslation("followup");
    const [open, setOpenState] = useState(false);
    const months = useMemo(
        () => getEachIntervalFormatted("months", getStartOfDate("years"), getEndOfDate("years"), "MMMM"),
        [],
    );
    const onClickOutside = useCallback(
        (value: boolean) => {
            if (!value && open) {
                setOpenState(false);
            }
        },
        [open],
    );

    const setOpen = useCallback(() => {
        setOpenState(!open);
    }, [open]);
    const Heading = useMemo(
        () => <p>{monthFilter.displayMonth ? upperFirst(monthFilter.displayMonth) : ""}</p>,
        [monthFilter.displayMonth],
    );
    const Items = useMemo(
        () => (
            <>
                {months.map((month, index) => (
                    <div
                        onClick={() => {
                            dispatch(
                                actions.updateSearchFilter({
                                    id: "month",
                                    displayMonth: month,
                                    monthIndex: index,
                                    year: monthFilter.year,
                                    referenceKey: "month",
                                }),
                            );
                            setOpenState(false);
                        }}
                        className="w-full flex items-end hover:bg-mhgrey-light"
                        key={`${month}-${index}`}
                    >
                        <div className="px-2 py-2">
                            <p>{upperFirst(month)}</p>
                        </div>
                    </div>
                ))}
            </>
        ),
        [dispatch, monthFilter.year, months],
    );
    return (
        <MonthDropdown
            open={open}
            setOpen={setOpen}
            items={Items}
            heading={Heading}
            label={t("month")}
            onClickOutside={onClickOutside}
        />
    );
}
