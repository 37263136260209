import { useTranslation } from "react-i18next";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import AbsenceReportEdit from "../../pages/absencereporting/components/AbsenceReportEdit";
import AbsenceReportCard from "../../pages/absencereporting/components/AbsenceReportCard";
import * as AbsenceReportSelectors from "../../pages/absencereporting/redux/absenceReportsSelectors";
import * as Types from "./ongoingAbsenceReports.types";
import { useAppSelector } from "store/hooks";
interface IProps extends Types.ISharedProps {
    item: IAbsenceReportExtended;
    isCallNurse: boolean;
}
const OngoingPreliminaryAbsenceReport = ({
    handleAbsenceReportEdit,
    handleAbsenceReportDeleteModal,
    isCallNurse,
    item,
}: IProps) => {
    const { t } = useTranslation();
    const isEditing = useAppSelector(AbsenceReportSelectors.getIsEditing);
    if (item.isEdit)
        return (
            <AbsenceReportEdit
                absenceReportId={item.id!}
                onCancel={() => handleAbsenceReportEdit(item)}
                key={item.id}
            />
        );

    return (
        <AbsenceReportCard
            absenceReport={item}
            onDeleteButtonClick={() => handleAbsenceReportDeleteModal(item)}
            onEditButtonClick={() => {
                handleAbsenceReportEdit(item);
            }}
            header={`${t("draft")}: ${item.type === "Sick" ? t("sick") : t("careOfChild")}`}
            key={item.id}
            isEditable={!isEditing}
            reportHealthyButtonId={item.id}
            infoTextType={isCallNurse && item.revision && item.revision < 1 ? "nurseComplete" : "incomplete"}
        />
    );
};

export default OngoingPreliminaryAbsenceReport;
