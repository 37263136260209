import { useAppDispatch, useAppSelector } from "store/hooks";
import { useSingleDatepickerProps } from "hooks";
import { administrationActions } from "pages/Administration/redux/administrationSlice";
import { useTranslation } from "react-i18next";
import {
    getActivationDate,
    getAnyErrorMessages,
    getAddUserButtonIsLoading,
    getContactInfo,
    getCountry,
    getDeActivationDate,
    getLanguage,
    getPersonalInfo,
    getSelectedUserCompanyIds,
    getSelectedCompany,
    getUserProps,
    getSsnSearchProps,
    getUsersIsLoading,
    getSaveOrUpdateSuccessful,
    getAddUserIsDirty,
} from "pages/Administration/redux/administrationSelectors";
import { useCallback, useEffect, useMemo } from "react";
import { IErrorMessages, ISetUserNumberProp, ISetUserStringProp } from "pages/Administration/types";
import {
    createOrUpdateUserAccount,
    getCompanyUserFromAccountEdit,
    searchCompanyGroupUserBySsn,
} from "pages/Administration/redux/administrationAsyncActions";
import { CreateOrUpdateUserAccount, SearchCompanyGroupUserBySsn } from "swagger/customercompany";
import { useNavigate } from "react-router-dom";
import User from "./User";
import { Spinner } from "components/Spinner";
import { validateNordicSsn } from "utils/ssn";
import { isValidEmail } from "pages/login/utils";
import { isSameOrBeforeDateDay } from "utils/date";
import { Config } from "config";
import { handlePhoneNumbers } from "pages/Administration/utils";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("administration");

    const userIsLoading = useAppSelector(getUsersIsLoading);
    const buttonIsLoading = useAppSelector(getAddUserButtonIsLoading);
    const userProps = useAppSelector(getUserProps);
    const country = useAppSelector(getCountry);
    const language = useAppSelector(getLanguage);
    const activationDate = useAppSelector(getActivationDate);
    const deActivationDate = useAppSelector(getDeActivationDate);
    const personalInfoProps = useAppSelector(getPersonalInfo);
    const searchProps = useAppSelector(getSsnSearchProps);
    const contactInfoProps = useAppSelector(getContactInfo);
    const noErrorMessages = useAppSelector(getAnyErrorMessages);
    const saveSuccessful = useAppSelector(getSaveOrUpdateSuccessful);
    const selectedUserCompany = useAppSelector(getSelectedCompany);
    const selectedUserCompanyIds = useAppSelector(getSelectedUserCompanyIds);
    const isDirty = useAppSelector(getAddUserIsDirty);
    const navigate = useNavigate();

    const setAddUserErrorMsg = useCallback(
        (propKey: keyof IErrorMessages, errorMessage: string) => {
            dispatch(
                administrationActions.setAddUserErrorMsg({
                    propKey,
                    errorMessage,
                }),
            );
        },
        [dispatch],
    );

    const handleUserPropChange = useCallback(
        (propKey: keyof CreateOrUpdateUserAccount, value: string | number) => {
            if (typeof value === "string") {
                dispatch(
                    administrationActions.setUserStringProp({
                        propKey: propKey,
                        value: value,
                    } as unknown as ISetUserStringProp),
                );
                if (propKey === "country" && country !== "OTH") {
                    const isValidSsn = validateNordicSsn(personalInfoProps.ssn, value);
                    setAddUserErrorMsg("ssnErrorMsg", isValidSsn.isValid ? "" : t("invalidSsn"));
                }
            } else {
                dispatch(
                    administrationActions.setUserNumberProp({
                        propKey: propKey,
                        value: value,
                    } as unknown as ISetUserNumberProp),
                );
            }
        },
        [country, dispatch, personalInfoProps.ssn, setAddUserErrorMsg, t],
    );

    const setSearchOpen = useCallback(
        (open: boolean) => {
            dispatch(administrationActions.setAddUserSsnSearchOpen(open));
        },
        [dispatch],
    );
    const handleUserClick = useCallback(() => {
        dispatch(
            getCompanyUserFromAccountEdit({
                userAccountId: searchProps.searchResults[0].userAccountId ?? 0,
                companyId: selectedUserCompany.id ?? 0,
            }),
        );
    }, [dispatch, searchProps.searchResults, selectedUserCompany.id]);

    const activationDpProps = useSingleDatepickerProps({
        onChangeDate: (v) => handleUserPropChange("periodActivationDate", v ?? ""),
        givenSelectedDate: activationDate,
        placeholder: t("addActivationsDate"),
    });
    const deActivationDpProps = useSingleDatepickerProps({
        onChangeDate: (v) => handleUserPropChange("periodDeactivationDate", v ?? ""),
        givenSelectedDate: deActivationDate ?? undefined,
        placeholder: t("addDeActivationsDate"),
    });

    const handleSaveClicked = useCallback(() => {
        const updatedUserProps: CreateOrUpdateUserAccount = {
            ...handlePhoneNumbers(userProps),
            companyId: selectedUserCompany?.id,
        };
        dispatch(createOrUpdateUserAccount(updatedUserProps));
    }, [dispatch, selectedUserCompany?.id, userProps]);

    const handleValidateSsn = useCallback(
        (propKey: keyof IErrorMessages, value: string) => {
            let errorMessage = "";
            if (country !== "OTH") {
                const isValidSsn = validateNordicSsn(value, country);
                if (!isValidSsn.isValid) errorMessage = t("invalidSsn");
                else {
                    handleUserPropChange("birthYear", isValidSsn.birthYear ?? 0);
                    handleUserPropChange("gender", isValidSsn.gender === "Female" ? 2 : 1);
                }
            }
            if (value && errorMessage === "") {
                const model: SearchCompanyGroupUserBySsn = {
                    companyIds: selectedUserCompanyIds ?? [],
                    countryCode: country,
                    includeAllCodes: true,
                    searchString: value,
                };
                dispatch(searchCompanyGroupUserBySsn(model));
            }
            setAddUserErrorMsg(propKey, errorMessage);
        },
        [country, dispatch, handleUserPropChange, selectedUserCompanyIds, setAddUserErrorMsg, t],
    );

    const handleValidateEmail = useCallback(
        (propKey: keyof IErrorMessages, value: string) => {
            let errorMessage = "";
            if (value.length > 0) errorMessage = isValidEmail(value) ? "" : t("invalidEmail");
            setAddUserErrorMsg(propKey, errorMessage);
        },
        [setAddUserErrorMsg, t],
    );

    const handlePhoneValid = useCallback(
        (propKey: keyof IErrorMessages, isValid: boolean) => {
            setAddUserErrorMsg(propKey, isValid ? "" : "invalid");
        },
        [setAddUserErrorMsg],
    );

    const formIsInValid = useMemo(
        () =>
            !country ||
            !language ||
            !personalInfoProps.firstName ||
            !personalInfoProps.lastName ||
            !personalInfoProps.ssn ||
            !noErrorMessages ||
            !activationDpProps.selectedDate ||
            !isDirty,
        [
            activationDpProps.selectedDate,
            country,
            isDirty,
            language,
            noErrorMessages,
            personalInfoProps.firstName,
            personalInfoProps.lastName,
            personalInfoProps.ssn,
        ],
    );

    useEffect(() => {
        if (saveSuccessful) {
            dispatch(administrationActions.resetSaveOrUpdateIsSuccessful());
            deActivationDate && isSameOrBeforeDateDay(deActivationDate)
                ? (window.location.href = `${Config.web_customerSupport_url}removedusers/${selectedUserCompany.id}`)
                : navigate("/administration/users/addUser/employment");
        }
    }, [deActivationDate, dispatch, navigate, saveSuccessful, selectedUserCompany.id]);

    return userIsLoading ? (
        <Spinner />
    ) : (
        <User
            countryAndLangCompProps={{
                country: country ?? "",
                language: language ?? "",
                onChange: handleUserPropChange,
            }}
            personaInfoCompProps={{
                ...personalInfoProps,
                ssnSearchProps: {
                    ...searchProps,
                    setSearchResultOpen: setSearchOpen,
                    onSelectUserClick: handleUserClick,
                },
                onChange: handleUserPropChange,
                onValidateSsn: handleValidateSsn,
            }}
            periodCompProps={{
                activationDatepickProps: { ...activationDpProps },
                deActivationDatepickProps: { ...deActivationDpProps },
            }}
            contactCompProps={{
                ...contactInfoProps,
                onChange: handleUserPropChange,
                onValidateEmail: handleValidateEmail,
                onValidatePhone: handlePhoneValid,
            }}
            handleSubmit={handleSaveClicked}
            saveButtonDisabled={formIsInValid}
            saveButtonLoading={buttonIsLoading}
        />
    );
}
