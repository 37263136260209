import { createSlice } from "@reduxjs/toolkit";
import {
    MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule,
    MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel,
} from "swagger/absencefollowup";
import {
    MedHelpAbsenceReportingDomainTemplate,
    MedHelpAbsenceReportingWebApiModelsConfigurationField,
    MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel,
} from "swagger/absencereportapi";
import {
    MedHelpHCMDomainTemplate,
    MedHelpHCMWebApiResponseTemplateFormDocumentResponse,
} from "swagger/healthCaseManagement/api.js";
import {
    Region,
    RehabTemplate,
    CustomerModel,
    CustomerCompany,
    BillableErpProduct,
    RehabConfiguration,
    CustomerOnboarding,
    CompanySelectedUser,
    ServiceContractModel,
    WorkOrderConfiguration,
    CustomerCompanyOnboarding,
    CompanySelectOrganization,
    CompanySelectedEmpAbsence,
    OnBoardingManagerAccessRight,
    NotificationConfigurationModel,
} from "swagger/businessupport";
import {
    saveProduct,
    fetchRegions,
    saveNewCompany,
    saveDeviantRule,
    saveCompanyInfo,
    fetchCompanyData,
    saveCustomerData,
    saveFieldTemplate,
    deleteDeviantRule,
    deleteHCMTemplate,
    saveTemplateRules,
    fetchCustomerData,
    deleteTemplateRule,
    saveServiceContract,
    deleteFieldTemplate,
    saveAbsenceTemplate,
    saveEmploymentGroups,
    deleteAbsenceTemplate,
    deleteEmploymentGroups,
    saveCompanyInformation,
    saveConfigurationField,
    saveRehabConfiguration,
    saveLoginConfiguration,
    saveDefaultHCMTemplates,
    updateConfigurationField,
    saveBillingConfiguration,
    deleteConfigurationField,
    saveWorkOrderConfiguration,
    fetchCustomersByRetailerId,
    fetchCompaniesByCustomerId,
    fetchTemplateFormDocuments,
    saveAccessRightConfiguration,
    saveNotificationConfiguration,
    deleteAccessRightConfiguration,
    saveRehabTemplatesConfiguration,
    saveAbsenceFollowUpConfiguration,
    saveSocialInsuranceConfiguration,
    fetchCustomerCompanySearchResults,
    fetchBillableErpProductsByRetailId,
    saveDocumentsTemplatesByTemplateId,
    fetchCustomerCompanyAdvisoryServices,
    saveAndFetchBillingPostConfigurations,
    saveMessengerConfigurationFieldPost,
    saveMessengerConfigurationFieldPut,
} from "./serviceAsyncActions";
import { ConfigurationField } from "swagger/messenger";

export enum Status {
    PENDING,
    ERROR,
    OK,
}

export interface IServiceSlice {
    companyId: number | undefined;
    customerId: number | undefined;
    regions: Region[];
    customers: CustomerModel[];
    companies: CustomerCompany[];
    rehabTemplates: RehabTemplate[];
    configurationFields: MedHelpAbsenceReportingWebApiModelsConfigurationField[];
    deviantAbsenceRules: MedHelpAbsenceFollowUpWebApiResponseDeviantCustomerRule[];
    templateFormDocuments: MedHelpHCMWebApiResponseTemplateFormDocumentResponse[];
    serviceUtilizationInfo: ServiceContractModel[];
    availableTemplateFields: MedHelpAbsenceReportingWebApiModelsAvailableFieldsModel[];
    absenceReportingTemplates: MedHelpAbsenceReportingDomainTemplate[];
    customerCompanySearchResults: CustomerCompanyOnboarding[];
    healthCaseManagementTemplates: MedHelpHCMDomainTemplate[];
    billableErpProductsByRetailId: BillableErpProduct[];
    messengerConfigurationFields: ConfigurationField[];
    customerCompanyAdvisoryServices: { companyId: number; companyName: string; advisoryService: string }[];
    userInfo: CompanySelectedUser;
    customerData: CustomerOnboarding;
    workOrderInfo: WorkOrderConfiguration;
    organizationInfo: CompanySelectOrganization;
    rehabConfiguration: RehabConfiguration;
    employmentAbsenceInfo: CompanySelectedEmpAbsence;
    notificationConfiguration: NotificationConfigurationModel;
    socialInsuranceConfiguration: MedHelpAbsenceFollowUpWebApiModelsCreateSocialInsuranceConfigurationModel;
    fileImportConfig: boolean | undefined;
    createdCompanyApiResults: any[];
    createdCompanyIds: {
        companyId: number;
        customerId: number;
        companyName?: string;
    };
    status: {
        customers: Status | null;
        createdCompanyApiResults: Status | null;
        customerData: Status | null;
        customerCompanyAdvisoryServices: Status | null;
    };
    isOk: boolean;
}

const initialServiceState: IServiceSlice = {
    companyId: undefined,
    customerId: undefined,
    regions: [],
    companies: [],
    customers: [],
    rehabTemplates: [],
    configurationFields: [],
    deviantAbsenceRules: [],
    templateFormDocuments: [],
    serviceUtilizationInfo: [],
    availableTemplateFields: [],
    absenceReportingTemplates: [],
    customerCompanySearchResults: [],
    healthCaseManagementTemplates: [],
    billableErpProductsByRetailId: [],
    messengerConfigurationFields: [],
    customerCompanyAdvisoryServices: [],
    userInfo: {},
    customerData: {},
    workOrderInfo: {},
    organizationInfo: {},
    rehabConfiguration: {},
    employmentAbsenceInfo: {},
    notificationConfiguration: {
        companyId: 0,
        customerId: 0,
    },
    socialInsuranceConfiguration: {},
    fileImportConfig: undefined,
    createdCompanyApiResults: [],
    createdCompanyIds: {
        companyId: 0,
        customerId: 0,
        companyName: "",
    },
    status: {
        customers: null,
        createdCompanyApiResults: null,
        customerData: null,
        customerCompanyAdvisoryServices: null,
    },
    isOk: false,
};

const serviceSlice = createSlice({
    name: "service",
    initialState: initialServiceState,
    reducers: {
        resetSelectedCompany(state) {
            Object.assign(state, initialServiceState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyData.fulfilled, (state, action) => {
                state.companyId = action.payload.companyId;
                state.customerId = action.payload.customerId;
                state.userInfo = action.payload.userInfo;
                state.companies = action.payload.companies;
                state.customerData = action.payload.customerData;
                state.workOrderInfo = action.payload.workOrderInfo;
                state.fileImportConfig = action.payload.fileImportConfig;
                state.organizationInfo = action.payload.organizationInfo;
                state.deviantAbsenceRules = action.payload.deviantAbsenceRules;
                state.configurationFields = action.payload.configurationFields;
                state.employmentAbsenceInfo = action.payload.employmentAbsenceInfo;
                state.serviceUtilizationInfo = action.payload.serviceUtilizationInfo;
                state.availableTemplateFields = action.payload.availableTemplateFields;
                state.absenceReportingTemplates = action.payload.absenceReportingTemplates;
                state.notificationConfiguration = action.payload.notificationConfiguration;
                state.socialInsuranceConfiguration = action.payload.socialInsuranceConfiguration;
                state.healthCaseManagementTemplates = action.payload.healthCaseManagementTemplates;
                state.messengerConfigurationFields = action.payload.messengerConfigurationFields;
                state.isOk = true;
            })
            .addCase(fetchCompanyData.rejected, (state) => {
                state.isOk = false;
            })
            .addCase(fetchRegions.fulfilled, (state, action) => {
                state.regions = action.payload;
            })
            .addCase(fetchCustomersByRetailerId.fulfilled, (state, action) => {
                state.customers = action.payload;
                state.status.customers = Status.OK;
            })
            .addCase(fetchCustomersByRetailerId.pending, (state) => {
                state.status.customers = Status.PENDING;
            })
            .addCase(fetchCustomersByRetailerId.rejected, (state) => {
                state.status.customers = Status.ERROR;
            })
            .addCase(fetchBillableErpProductsByRetailId.fulfilled, (state, action) => {
                state.billableErpProductsByRetailId = action.payload;
            })
            .addCase(saveCompanyInformation.fulfilled, (state, action) => {
                const { companyName, number, region } = action.payload;
                state.organizationInfo.companyName = companyName;
                state.organizationInfo.organizationNumber = number;
                state.organizationInfo.region = region;
            })
            .addCase(saveCompanyInfo.fulfilled, (state, action) => {
                state.employmentAbsenceInfo.industryCode = action.payload.industryCode;
            })
            .addCase(saveEmploymentGroups.fulfilled, (state, action) => {
                state.organizationInfo.employmentGroups = action.payload;
            })
            .addCase(deleteEmploymentGroups.fulfilled, (state, action) => {
                state.organizationInfo.employmentGroups = state.organizationInfo.employmentGroups?.filter(
                    (x) => !action.payload.includes(x.id as number),
                );
            })
            .addCase(saveLoginConfiguration.fulfilled, (state, action) => {
                state.userInfo.loginConfiguration = action.payload;
            })
            .addCase(deleteDeviantRule.fulfilled, (state, action) => {
                state.deviantAbsenceRules = state.deviantAbsenceRules?.filter((x) => x.id !== action.payload);
            })
            .addCase(saveDeviantRule.fulfilled, (state, action) => {
                state.deviantAbsenceRules = [...state.deviantAbsenceRules, action.payload];
            })
            .addCase(saveAbsenceFollowUpConfiguration.fulfilled, (state, action) => {
                state.workOrderInfo = action.payload;
            })
            .addCase(saveWorkOrderConfiguration.fulfilled, (state, action) => {
                state.workOrderInfo = {
                    ...state.workOrderInfo,
                    id: action.payload.id,
                    allowDirectAdvisoryCallback: action.payload.configuration.allowDirectAdvisoryCallback,
                    mandatoryAdvisoryCallback: action.payload.configuration.mandatoryAdvisoryCallback,
                };
            })
            .addCase(saveRehabConfiguration.fulfilled, (state, action) => {
                state.rehabConfiguration = action.payload;
            })
            .addCase(saveRehabTemplatesConfiguration.fulfilled, (state, action) => {
                state.rehabTemplates = action.payload;
            })
            .addCase(saveAccessRightConfiguration.fulfilled, (state, action) => {
                const indexToReplace = state.userInfo.managerAccessRights?.findIndex(
                    (x) =>
                        x.accessRightType === action.payload.accessRightType &&
                        x.managerType ===
                            (action.payload.type === "MainManagerAccessRights"
                                ? "EmploymentManager"
                                : "DepartmentManager"),
                );

                const transformedDataObject = {
                    managerType:
                        action.payload.type === "MainManagerAccessRights" ? "EmploymentManager" : "DepartmentManager",
                    customerConfigurationId: action.payload.companyId,
                    accessRightType: action.payload.accessRightType,
                    hierarchical: action.payload.hierarchical,
                };

                if (state.userInfo.managerAccessRights && indexToReplace !== undefined && indexToReplace > -1) {
                    state.userInfo.managerAccessRights[indexToReplace] = transformedDataObject;
                } else {
                    state.userInfo.managerAccessRights = [
                        ...(state.userInfo.managerAccessRights as OnBoardingManagerAccessRight[]),
                        transformedDataObject,
                    ];
                }
            })
            .addCase(deleteAccessRightConfiguration.fulfilled, (state, action) => {
                state.userInfo.managerAccessRights = state.userInfo.managerAccessRights?.filter(
                    (x) =>
                        x.accessRightType !== action.payload.accessRightType ||
                        x.managerType !==
                            (action.payload.managerType === "MainManagerAccessRights"
                                ? "EmploymentManager"
                                : "DepartmentManager"),
                );
            })
            .addCase(saveNotificationConfiguration.fulfilled, (state, action) => {
                state.notificationConfiguration = action.payload;
            })
            .addCase(saveFieldTemplate.fulfilled, (state, action) => {
                state.absenceReportingTemplates
                    .find((x) => x.id === action.payload.fieldTemplate.templateId)
                    ?.fields?.push({
                        id: action.payload.fieldTemplateId,
                        name: action.payload.fieldTemplate.name,
                    });
            })
            .addCase(saveConfigurationField.fulfilled, (state, action) => {
                state.configurationFields.push(action.payload);
            })
            .addCase(deleteFieldTemplate.fulfilled, (state, action) => {
                state.absenceReportingTemplates = state.absenceReportingTemplates.map((template) => {
                    if (template.fields === undefined) return template;
                    return { ...template, fields: template.fields?.filter((y) => y.id !== action.payload) };
                });
            })
            .addCase(deleteConfigurationField.fulfilled, (state, action) => {
                state.configurationFields = state.configurationFields.filter((x) => x.id !== action.payload);
            })
            .addCase(saveServiceContract.fulfilled, (state, action) => {
                if (action.payload.newContract) {
                    state.serviceUtilizationInfo?.push(action.payload.serviceContract);
                } else {
                    state.serviceUtilizationInfo = state.serviceUtilizationInfo?.map((x) =>
                        x.id === action.payload.serviceContract.id ? action.payload.serviceContract : x,
                    );
                }
            })
            .addCase(saveBillingConfiguration.fulfilled, (state, action) => {
                state.customerData.billingConfiguration = action.payload;
            })
            .addCase(saveAndFetchBillingPostConfigurations.fulfilled, (state, action) => {
                state.customerData.invoicePosts = action.payload;
            })
            .addCase(saveNewCompany.fulfilled, (state, action) => {
                state.status.createdCompanyApiResults = Status.OK;
                if (action.payload) {
                    state.createdCompanyApiResults = action.payload.results;
                    state.createdCompanyIds = {
                        companyId: action.payload.companyId,
                        customerId: action.payload.customerId,
                        companyName: action.payload.companyName || "",
                    };
                }
            })
            .addCase(saveNewCompany.pending, (state) => {
                state.status.createdCompanyApiResults = Status.PENDING;
            })
            .addCase(fetchTemplateFormDocuments.fulfilled, (state, action) => {
                state.templateFormDocuments = action.payload;
            })
            .addCase(saveDocumentsTemplatesByTemplateId.fulfilled, (state, action) => {
                const { id, templateFormDocuments } = action.payload;
                const index = state.healthCaseManagementTemplates.findIndex((x) => x.id === id);
                if (index >= 0) {
                    state.healthCaseManagementTemplates[index].templateFormDocuments = templateFormDocuments;
                }
            })
            .addCase(saveDefaultHCMTemplates.fulfilled, (state, action) => {
                state.healthCaseManagementTemplates = action.payload;
            })
            .addCase(deleteHCMTemplate.fulfilled, (state, action) => {
                state.healthCaseManagementTemplates = state.healthCaseManagementTemplates.filter(
                    (x) => x.id !== action.payload,
                );
            })
            .addCase(updateConfigurationField.fulfilled, (state, action) => {
                const field = state.configurationFields.find((x) => x.name === action.payload.fieldName);
                if (field) {
                    field.value = action.payload.fieldValue;
                }
            })
            .addCase(saveTemplateRules.fulfilled, (state, action) => {
                const index = state.healthCaseManagementTemplates.findIndex((x) => x.id === action.payload.id);
                if (index !== -1) {
                    state.healthCaseManagementTemplates[index] = action.payload;
                }
            })
            .addCase(deleteTemplateRule.fulfilled, (state, action) => {
                const index = state.healthCaseManagementTemplates.findIndex((x) => x.id === action.payload.templateId);
                if (index !== -1) {
                    state.healthCaseManagementTemplates[index].ruleTemplates = state.healthCaseManagementTemplates[
                        index
                    ].ruleTemplates?.filter((x) => x.id !== action.payload.templateRuleId);
                }
            })
            .addCase(fetchCustomerData.fulfilled, (state, action) => {
                state.customerData = action.payload;
                state.status.customerData = Status.OK;
            })
            .addCase(fetchCustomerData.pending, (state) => {
                state.status.customerData = Status.PENDING;
            })
            .addCase(fetchCustomerData.rejected, (state) => {
                state.status.customerData = Status.ERROR;
            })
            .addCase(fetchCustomerCompanySearchResults.fulfilled, (state, action) => {
                state.customerCompanySearchResults = action.payload;
            })
            .addCase(fetchCompaniesByCustomerId.fulfilled, (state, action) => {
                state.companies = action.payload;
            })
            .addCase(fetchCustomerCompanyAdvisoryServices.fulfilled, (state, action) => {
                state.customerCompanyAdvisoryServices = action.payload;
                state.status.customerCompanyAdvisoryServices = Status.OK;
            })
            .addCase(fetchCustomerCompanyAdvisoryServices.pending, (state) => {
                state.status.customerCompanyAdvisoryServices = Status.PENDING;
            })
            .addCase(fetchCustomerCompanyAdvisoryServices.rejected, (state) => {
                state.status.customerCompanyAdvisoryServices = Status.ERROR;
            })
            .addCase(saveAbsenceTemplate.fulfilled, (state, action) => {
                state.absenceReportingTemplates = [...state.absenceReportingTemplates, action.payload];
            })
            .addCase(deleteAbsenceTemplate.fulfilled, (state, action) => {
                state.absenceReportingTemplates = state.absenceReportingTemplates.filter(
                    (x) => x.id !== action.payload,
                );
            })
            .addCase(saveProduct.fulfilled, (state, action) => {
                state.customerData.product = action.payload;
            })
            .addCase(saveCustomerData.fulfilled, (state, action) => {
                state.customerData.customerName = action.payload.customerName;
                state.customerData.customerNumber = action.payload.customerNumber;
            })
            .addCase(saveSocialInsuranceConfiguration.fulfilled, (state, action) => {
                state.socialInsuranceConfiguration = action.payload;
            })
            .addCase(saveMessengerConfigurationFieldPut.fulfilled, (state, action) => {
                const existingConfigIndex = state.messengerConfigurationFields.findIndex(
                    (x) => x.id === action.payload.id,
                );
                if (existingConfigIndex >= 0) {
                    state.messengerConfigurationFields[existingConfigIndex] = action.payload;
                }
            })
            .addCase(saveMessengerConfigurationFieldPost.fulfilled, (state, action) => {
                state.messengerConfigurationFields = [...state.messengerConfigurationFields, action.payload];
            });
    },
});

export const serviceActions = serviceSlice.actions;

export default serviceSlice.reducer;
