import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import Icon, { type Icons } from "components/Icon";
import { ReactComponent as CloseNews } from "icons/closeNews.svg";

interface INewsMessageProps {
    heading?: string;
    icon?: Icons;
    setClose?: () => void;
    leftBg?: "blue" | "red";
    children: ReactNode;
}

const NewsMessage: React.FunctionComponent<INewsMessageProps> = (props) => {
    const { children, heading, setClose, icon = "speakerPhone", leftBg = "blue" } = props;
    const { t } = useTranslation();
    const leftBackground = leftBg === "blue" ? "bg-news" : "bg-mhred-alert3/25";
    return (
        <div className="flex flex-row w-full justify-center">
            <div className={leftBackground}>
                <Icon icon={icon} className="block my-8 mx-4 md:mx-8" size={26} />
            </div>
            <div className="border-background-300 border-2 box-border p-4 w-full flex justify-between">
                <div className="p-4 md:p-0 news-text">
                    {heading && <h2 className="pb-3">{heading}</h2>}
                    <p>{children}</p>
                </div>
                {setClose && (
                    <div>
                        <button
                            type="button"
                            className="flex flex-row items-center "
                            onClick={() => {
                                setClose();
                            }}
                        >
                            <span className="px-2 underline hidden md:block font-bold">{t("close")}</span>
                            <span>
                                <CloseNews />
                            </span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewsMessage;
