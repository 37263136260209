import { Outlet } from "react-router-dom";
import FollowUpTopLinks from "./components/FollowUpTopLinks";
import ProtectedRoute from "routes/ProtectedRoute";
import { AllFollowUpAccess } from "./accessRights";

const FollowUp = () => (
    <ProtectedRoute accessRightData={AllFollowUpAccess}>
        <FollowUpTopLinks />
        <div className="mt-10">
            <Outlet />
        </div>
    </ProtectedRoute>
);

export default FollowUp;
