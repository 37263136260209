import { isEmpty } from "lodash";
import ErrorDetails from "./ErrorDetails";
import { useTranslation } from "react-i18next";
import { groupByCode, severeErrors } from "../utils";
import { useGetValidationErrorQuery } from "../../../store/rtk-apis/fileImport/fileImportApi";

interface ValidationErrorsProps {
    customerKey: number;
    importId: string;
}

const ValidationErrors = ({ customerKey, importId }: ValidationErrorsProps) => {
    const { t } = useTranslation("fileImport");
    const { data } = useGetValidationErrorQuery({ customerKey, importId });

    if (!data?.errors || isEmpty(data.errors) || !data) return <></>;

    const groupedErrors = groupByCode(data.errors);

    return (
        <div className="flex flex-col gap-6">
            {Object.entries(groupedErrors).map(([code, rows], i) => (
                <ErrorDetails
                    key={code + i}
                    title={t(`errorCode.${code}`)}
                    rows={rows}
                    alertType={severeErrors.includes(code) ? "error" : "warning"}
                />
            ))}
        </div>
    );
};

export default ValidationErrors;
