import { useAppDispatch, useAppSelector } from "store/hooks";
import Administration from "./Administration";
import { useCallback, useEffect, useMemo } from "react";
import {
    getPowerbarSelectedCompanyId,
    getAdminIsLoading,
    getSelectedCompanyId,
    getAdminComponentProps,
} from "./redux/administrationSelectors";
import { getCompaniesForUser } from "./redux/administrationAsyncActions";
import { Spinner } from "components/Spinner";
import { useLocation } from "react-router-dom";
import { administrationActions } from "./redux/administrationSlice";

export default function Index() {
    const { hash } = useLocation();
    const dispatch = useAppDispatch();
    const adminIsLoading = useAppSelector(getAdminIsLoading);
    const powerbarSelectedCompanyId = useAppSelector(getPowerbarSelectedCompanyId);
    const stateSelectedCompanyId = useAppSelector(getSelectedCompanyId);
    const adminProps = useAppSelector(getAdminComponentProps);

    const getCompanyIdFromHash = useCallback((): number | null => {
        const parsedHash = new URLSearchParams(hash);
        const paramsPOJO = Object.fromEntries(parsedHash.entries());
        const companyId = paramsPOJO["#companyId"];

        if (companyId && !isNaN(Number(companyId))) return +companyId;
        return null;
    }, [hash]);

    const companyId = useMemo(
        () => powerbarSelectedCompanyId ?? getCompanyIdFromHash() ?? stateSelectedCompanyId,
        [getCompanyIdFromHash, powerbarSelectedCompanyId, stateSelectedCompanyId],
    );

    useEffect(() => {
        dispatch(administrationActions.resetAdminState());
        if (!companyId) return;
        dispatch(administrationActions.setSelectedCompanyId(companyId));
    }, [companyId, dispatch]);

    useEffect(() => {
        if (!stateSelectedCompanyId) return;
        dispatch(
            getCompaniesForUser({
                companyId: stateSelectedCompanyId,
            }),
        );
    }, [stateSelectedCompanyId, dispatch]);

    return adminIsLoading ? <Spinner /> : <Administration companyId={companyId?.toString()} {...adminProps} />;
}
