import ReactModal from "react-modal";
import { useAppSelector } from "store/hooks";
import { ReactComponent as CloseIcon } from "icons/login/close.svg";
import { getUserRetailer } from "store/userSelectors";
export interface DatePickerModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    children?: React.ReactNode;
}
const DatePickerModal = ({ open, setOpen, children }: DatePickerModalProps) => {
    const activeRetailer = useAppSelector(getUserRetailer);

    return (
        <ReactModal
            id="datePickerModal"
            isOpen={open}
            className={`${activeRetailer} w-screen h-screen relative overflow-hidden flex justify-center lg:min-h-xl lg:max-w-4/5 lg:max-h-4/5 z-30 bg-white`}
            onRequestClose={() => setOpen(false)}
            shouldCloseOnEsc
            style={{
                overlay: {
                    zIndex: 30,
                },
            }}
        >
            <div className="fixed top-0 right-0 lg:absolute">
                <button type="button" className="p-5 xl:p-9" onClick={() => setOpen(false)}>
                    <CloseIcon className="h-4" />
                </button>
            </div>
            <div id="datePickerContent" className="w-full max-w-screen-msd pt-12">
                {children}
            </div>
        </ReactModal>
    );
};

export default DatePickerModal;
