import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash/fp";
import TabMenu from "components/TabMenu";

const CaseMenu = () => {
    const { t } = useTranslation("followup");
    const { routeEmploymentGuid, routeCaseId } = useParams();
    const navigate = useNavigate();

    const routes = routeEmploymentGuid
        ? [
              {
                  route: `followup/employee/${routeEmploymentGuid}/case/${routeCaseId}`,
                  name: "case",
                  indexRoute: "details",
                  children: [
                      {
                          route: "details",
                          name: t("details"),
                          children: [],
                      },
                      {
                          route: "activities",
                          name: t("activities"),
                          children: [],
                      },
                      {
                          route: "notes",
                          name: t("notes"),
                          children: [],
                      },
                      {
                          route: "documents",
                          name: t("documentsTemplates"),
                          children: [],
                      },
                      {
                          route: "log",
                          name: t("caseLog"),
                          children: [],
                      },
                  ],
              },
          ]
        : [];

    if (isEmpty(routes)) {
        navigate("/absencereporting");
        return null;
    }

    return (
        <div className="flex w-full">
            <div className="shadow-[0_0px_2px_16px_rgba(144, 148, 140, 0.12)] bg-pageBg h-1" />
            <TabMenu
                routes={routes}
                subMenuTo={`followup/employee/${routeEmploymentGuid}/case/${routeCaseId}`}
                width="full"
                className="sm:px-8 xl:px-8"
            >
                <div className="mt-6">
                    <Outlet />
                </div>
            </TabMenu>
        </div>
    );
};

export default CaseMenu;
