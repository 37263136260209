import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import AbsencePage from "pages/Absence";
import AbsenceReporting from "pages/Absence/pages/absencereporting/pages/AbsenceReporting";
import { MedicalCertificate } from "@medhelp/medical-certificate";
import AbsenceReportingSearchPage from "pages/Absence/pages/absencereporting/pages/AbsenceReportingSearchPage";
import AbsenceReportingMeCreatePage from "pages/Absence/pages/absencereporting/pages/AbsenceReportingMeCreatePage";
import * as FollowUpPages from "pages/FollowUp";
import DashboardPage from "pages/dashboard/DashboardPage";
import ServicePage from "pages/service/ServicePage";
import AboutPage from "pages/about/About";
import Menu from "components/Menu";
import { SpinnerPage } from "components/Spinner";
import { actions as absenceReportsActions } from "pages/Absence/pages/absencereporting/redux";
import { getServices } from "./api/services";
import * as UserSelectors from "store/userSelectors";
import { useTranslation } from "react-i18next";
import { getConvertedAbsenceCause } from "./utils/postToPrincess";
import useEventListener from "@use-it/event-listener";
import { Config } from "config";
import { getUserRetailer } from "store/userSelectors";
import { getIsAuthByLogin } from "./pages/login/redux/loginSelectors";
import ContactSupport from "pages/Support/pages/ContactSupport";
import HelpGuide from "pages/Support/pages/HelpGuide";
import HelpMenu from "components/HelpMenu";
import GlobalMarket from "pages/login/pages/GlobalMarket";
import { capitalize, isUndefined } from "lodash/fp";
import { userActions } from "store/userSlice";
import UserSettings from "pages/UserSettings";
import LoginPage from "pages/login";
import SessionModal from "pages/login/components/SessionModal";
import ProtectedRoute from "routes/ProtectedRoute";
import WorkOrder from "pages/WorkOrder";
import useOrganizationLogin from "hooks/useOrganizationLogin";
import { actions } from "store/route";
import {
    workorderAccess,
    subcontractorAccess,
    caseAccess,
    adminAccess,
    coaAccess,
    fileImportAccess,
    dashboardAccess,
} from "routes";
import { Status } from "store/userStateTypes";
import { setDefaultOptions } from "date-fns/setDefaultOptions";
import { getDateLocale } from "utils/date";
import Support from "pages/Support";
import PrivacyPolicy from "pages/about/pages/PrivacyPolicy";
import CookiePolicy from "pages/about/pages/CookiePolicy";
import Subcontractors from "pages/about/pages/Subcontractors";
import * as AdministrationPages from "pages/Administration/pages";
import Administration from "pages/Administration";
import Inbox from "pages/Inbox";
import { Custom500 } from "pages/500/500";
import CompanyConfiguration from "pages/service/pages/CompanyConfiguration";
import CustomerConfiguration from "pages/service/pages/CustomerConfiguration";
import GeneralTab from "pages/service/components/Tabs/GeneralTab";
import ServicesTab from "pages/service/components/Tabs/ServicesTab";
import RightsTab from "pages/service/components/Tabs/RightsTab";
import NotificationsTab from "pages/service/components/Tabs/NotificationsTab";
import EventMessages from "pages/Inbox/pages/EventMessages";
import SubscriptionMessages from "pages/Inbox/pages/SubscriptionMessages";
import Fortnox from "pages/Fortnox/Fortnox";
import FileImport from "pages/FileImport/FileImport";
import FortnoxWelcomeWizard from "components/FortnoxWelcomeWizard";
import FortnoxAdministration from "pages/FortnoxAdministration";
import ProductSetting from "pages/Administration/components/ProductSettings/ProductSetting";

export const App = () => {
    const isAuthByLogin = useAppSelector(getIsAuthByLogin);
    const dispatch = useAppDispatch();
    const isAuth = useAppSelector(UserSelectors.isAuth);
    const token = useAppSelector(UserSelectors.token);
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const userStatus = useAppSelector(UserSelectors.getUserStatus);
    const ableToAccess = useAppSelector(UserSelectors.ableToAccess);
    const assumedUser = useAppSelector(UserSelectors.getUserAssume);
    const navigate = useNavigate();
    const { i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const context = useAppSelector(getUserRetailer);
    const userPrefLanguage = useAppSelector(UserSelectors.getUserLanguage);
    const hasFortnoxProduct = useAppSelector(UserSelectors.getHasFortnoxProduct);
    const IsAuthAndServiceReady = () => {
        const isServiceDefined = getServices && getServices();
        return !isUndefined(isServiceDefined) && isAuth;
    };
    const { pathname } = useLocation();
    useOrganizationLogin(setIsLoading);
    const [searchParams] = useSearchParams();
    const returnUrl = searchParams.get("returnUrl") ? searchParams.get("returnUrl") : undefined;

    useEffect(() => {
        const allowedReturnDomains = ["medhelp.se"];
        // Important to have this useEffect for Nurse/SSK flow.
        // Should be able to redirect them anywhere
        if (isAuth && returnUrl && returnUrl !== "") {
            // TODO: Migrate this logic to listen use returnUrl from backend instead of using query param value
            // Logic to block open redirects
            const domain = new URL(returnUrl);
            if (allowedReturnDomains.indexOf(domain.hostname) === -1) {
                console.warn("Open redirect blocked");
                return;
            }
            const redirectUrl = returnUrl.startsWith("http") ? returnUrl : `https://${returnUrl}`;
            location.href = redirectUrl;
        }
    }, [isAuth, returnUrl]);

    useEffect(() => {
        dispatch(actions.changeRoute(pathname));
    }, [pathname, dispatch]);
    useEffect(() => {
        if (isAuthByLogin) {
            dispatch(userActions.signIn());
        }
    }, [dispatch, isAuthByLogin]);

    useEffect(() => {
        if (userPrefLanguage && getServices() && userStatus === Status.OK) {
            if (!ableToAccess) {
                window.location.href = Config.web_oldAbsenceReporting_url;
            }
            i18n.changeLanguage(userPrefLanguage);
            setDefaultOptions({ locale: getDateLocale(userPrefLanguage) });
            setIsLoading(false);
        }
    }, [userPrefLanguage, i18n, ableToAccess, navigate, userStatus]);

    useEffect(() => {
        if (window && window.location !== window.parent.location) {
            const message = {
                type: "redirect",
                eventData: {
                    url: window.location.origin,
                },
            };
            window.parent.postMessage(message, Config.princessDomain!);
        }
    }, []);

    useEventListener(
        "message",
        async (event: any) => {
            const { data } = event;
            switch (data.type) {
                case "childSsn": {
                    dispatch(absenceReportsActions.updatePrincessChildSsn(data.eventData.ssn));
                    break;
                }
                case "contactReasonChange": {
                    const res = await getConvertedAbsenceCause(data.eventData.id, token!);
                    dispatch(absenceReportsActions.updatePrincessContactReasonChange(res.label.toString()));
                    break;
                }
                case "notetoemployer": {
                    dispatch(absenceReportsActions.updatePrincessNoteToEmployer(data.eventData.ssn));
                    break;
                }
                default:
                    break;
            }
        },
        window,
    );

    if (isLoading) return <SpinnerPage />;

    if (pathname) {
        const queryItems = pathname.split("/", 2);
        const lowerCaseQuery = queryItems[1].toLowerCase();
        if (["frisklinjen", "avonova", "falck"].includes(lowerCaseQuery)) {
            const queryContext = lowerCaseQuery === "frisklinjen" ? "Feelgood" : capitalize(lowerCaseQuery);
            dispatch(userActions.setUserRetailer(queryContext));
        }
    }

    const removeIcons = (iconName: string) => {
        const icons = document.getElementsByClassName(iconName);
        for (let i = 0; i < icons.length; i++) {
            icons[i].remove();
        }
    };

    const changeMetaThemeColor = (color: string) => {
        const metaThemeColor = window.document.querySelector("meta[name=theme-color]");
        if (!metaThemeColor) return;
        metaThemeColor.setAttribute("content", color);
    };

    const getRetailer = (): string => {
        if (context === "Avonova") {
            removeIcons("medhelpIcon");
            removeIcons("frisklinjenIcon");
            removeIcons("falckIcon");
            changeMetaThemeColor("#FAF7F6");
            return context;
        } else if (context === "Feelgood") {
            removeIcons("medhelpIcon");
            removeIcons("avonovaIcon");
            removeIcons("falckIcon");
            changeMetaThemeColor("#052651");
            return context;
        } else if (context === "Falck") {
            removeIcons("medhelpIcon");
            removeIcons("avonovaIcon");
            removeIcons("frisklinjenIcon");
            changeMetaThemeColor("#F0F0F0");
            return context;
        } else {
            removeIcons("avonovaIcon");
            removeIcons("frisklinjenIcon");
            removeIcons("falckIcon");
            changeMetaThemeColor("#192D05");
            return "Medhelp";
        }
    };

    const getStartRoute = (): string => {
        if (userContext.HasMedicalAdvisory) return "/signedin/absencereporting/search";
        else if (
            userContext.HasCustomerSupport ||
            userContext.HasAbsenceView ||
            userContext.HasAbsenceFollowup ||
            userContext.HasTopLevelAbsenceStatistics
        )
            return "/dashboard";
        else return "/absencereporting";
    };

    return (
        <div className={`${getRetailer()} h-full ${assumedUser ? "border-8 border-red-500" : ""}`}>
            {IsAuthAndServiceReady() && !isLoading ? (
                <div>
                    <HelpMenu />
                    <SessionModal activeRetailer={context} />
                    {hasFortnoxProduct && <FortnoxWelcomeWizard />}
                    <Menu>
                        <Routes>
                            <Route path="/" element={<Navigate replace to={getStartRoute()} />} />
                            <Route path="*" element={<Navigate replace to={getStartRoute()} />} />
                            <Route path="/500" element={<Custom500 />} />
                            <Route
                                path="/dashboard"
                                element={
                                    <ProtectedRoute accessRightData={dashboardAccess}>
                                        <DashboardPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/absencereporting"
                                element={
                                    <ProtectedRoute protectWhenProduct="base">
                                        <AbsenceReporting />
                                    </ProtectedRoute>
                                }
                            >
                                <Route index element={<AbsencePage />} />
                                <Route path="absence" element={<AbsencePage />} />
                                <Route path="absence/:routeEmploymentGuid" element={<AbsencePage />} />
                                <Route path=":routeEmploymentGuid" element={<AbsencePage />} />
                                <Route path="me/create" element={<AbsenceReportingMeCreatePage />} />
                                <Route path="medical-certificate" element={<MedicalCertificate />} />
                                <Route
                                    path="medical-certificate/:routeEmploymentGuid"
                                    element={<MedicalCertificate />}
                                />
                            </Route>
                            <Route path="/followup" element={<FollowUpPages.default />}>
                                <Route path="search" element={<FollowUpPages.SearchReports />}>
                                    <Route path="reports" element={<FollowUpPages.SearchReports />} />
                                </Route>
                                <Route path="search/employee" element={<FollowUpPages.SearchEmployee />} />
                                <Route path="search/resources" element={<FollowUpPages.Resources />}></Route>
                                <Route
                                    path="search/absenceCostCalculation"
                                    element={<FollowUpPages.AbsenceCostCalculation />}
                                />
                                <Route path="employee" element={<FollowUpPages.Employee />}>
                                    <Route path=":routeEmploymentGuid" element={<Navigate to="absence" />} />
                                    <Route path=":routeEmploymentGuid/absence" element={<FollowUpPages.Absence />} />
                                    <Route
                                        path=":routeEmploymentGuid/case"
                                        element={
                                            <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                                <FollowUpPages.Case />
                                            </ProtectedRoute>
                                        }
                                    />
                                    <Route
                                        path=":routeEmploymentGuid/case/:routeCaseId"
                                        element={
                                            <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                                <FollowUpPages.CaseInfo />
                                            </ProtectedRoute>
                                        }
                                    >
                                        <Route
                                            index
                                            element={
                                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                                    <FollowUpPages.CaseDetails />
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="details"
                                            element={
                                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                                    <FollowUpPages.CaseDetails />
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="activities"
                                            element={
                                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                                    <FollowUpPages.CaseActivities />
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="notes"
                                            element={
                                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                                    <FollowUpPages.CaseNotes />
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="documents"
                                            element={
                                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                                    <FollowUpPages.CaseDocuments />
                                                </ProtectedRoute>
                                            }
                                        />
                                        <Route
                                            path="log"
                                            element={
                                                <ProtectedRoute accessRightData={caseAccess} hideIfOwnPage>
                                                    <FollowUpPages.CaseLog />
                                                </ProtectedRoute>
                                            }
                                        />
                                    </Route>
                                    <Route
                                        path=":routeEmploymentGuid/medical-certificate"
                                        element={<FollowUpPages.MedicalCertificate />}
                                    />
                                    <Route path=":routeEmploymentGuid/subscribe" element={<FollowUpPages.Subscribe />}>
                                        <Route index element={<FollowUpPages.Subscriber />} />
                                        <Route path="subscriber" element={<FollowUpPages.Subscriber />} />
                                        <Route path="subscribes" element={<FollowUpPages.SubscribesTo />} />
                                    </Route>
                                    <Route path=":routeEmploymentGuid/profile" element={<FollowUpPages.Profile />} />
                                </Route>
                            </Route>
                            <Route
                                path="/administration"
                                element={
                                    <ProtectedRoute accessRightData={adminAccess}>
                                        <Administration />
                                    </ProtectedRoute>
                                }
                            >
                                <Route path="productSettings" element={<ProductSetting />}></Route>
                                <Route path="users" element={<AdministrationPages.Users />}>
                                    <Route index element={<AdministrationPages.AddUser />} />
                                    <Route path="addUser" element={<AdministrationPages.AddUser />}>
                                        <Route index element={<AdministrationPages.User />} />
                                        <Route path="user" element={<AdministrationPages.User />} />
                                        <Route path="employment" element={<AdministrationPages.Employment />} />
                                        <Route path="subscriptions" element={<AdministrationPages.Subscriptions />} />
                                        <Route path="permissions" element={<AdministrationPages.Permissions />} />
                                        <Route path="logins" element={<AdministrationPages.Logins />} />
                                    </Route>

                                    <Route path="erasureFinished" element={<AdministrationPages.ErasureFinished />}>
                                        <Route
                                            path="settings"
                                            element={<AdministrationPages.ErasureFinishedSettings />}
                                        />
                                    </Route>
                                    <Route path="subscriptions" element={<AdministrationPages.Subscriptions />} />
                                    <Route path="permissions" element={<AdministrationPages.Permissions />} />
                                    <Route
                                        path="erasureActive"
                                        element={
                                            <ProtectedRoute accessRightData={coaAccess}>
                                                <AdministrationPages.ErasureActive />
                                            </ProtectedRoute>
                                        }
                                    >
                                        <Route index element={<AdministrationPages.ErasureActiveSettings />} />
                                        <Route
                                            path="settings"
                                            element={<AdministrationPages.ErasureActiveSettings />}
                                        />
                                    </Route>
                                </Route>
                                <Route path="settings" element={<AdministrationPages.Settings />}>
                                    <Route index element={<AdministrationPages.RoleSubscriptionsSettings />} />
                                    <Route
                                        path="roleSubscriptions"
                                        element={<AdministrationPages.RoleSubscriptionsSettings />}
                                    />
                                </Route>
                            </Route>
                            <Route path="/fortnoxAdministration" element={<FortnoxAdministration />} />

                            {/* Routing for SSK / Medical Advisory Web */}
                            <Route path="/signedin" element={<AbsenceReporting />}>
                                <Route
                                    path="absencereporting/employee/:routeEmploymentGuid"
                                    element={<AbsencePage />}
                                />
                            </Route>
                            <Route path="/signedin/absencereporting/search" element={<AbsenceReportingSearchPage />} />
                            <Route path="service" element={<ServicePage />}>
                                <Route path="" element={<Navigate to="company/general" />} />
                                <Route path="company" element={<CompanyConfiguration />}>
                                    <Route index element={<GeneralTab />} />
                                    <Route path="general" element={<GeneralTab />} />
                                    <Route path="services" element={<ServicesTab />} />
                                    <Route path="services/:customerId/:companyId" element={<ServicesTab />} />
                                    <Route path="accessRights" element={<RightsTab />} />
                                    <Route path="notifications" element={<NotificationsTab />} />
                                </Route>

                                <Route path="customer" element={<CustomerConfiguration />} />
                            </Route>
                            <Route path="/support" element={<Support />}>
                                <Route index element={<HelpGuide />} />
                                <Route path="helpGuide" element={<HelpGuide />} />
                                <Route path="contactSupport" element={<ContactSupport />} />
                            </Route>
                            <Route path="about" element={<AboutPage />}>
                                <Route index element={<PrivacyPolicy />} />
                                <Route path="privacyPolicy" element={<PrivacyPolicy />} />
                                <Route path="cookiePolicy" element={<CookiePolicy />} />
                                <Route
                                    path="subcontractors"
                                    element={
                                        <ProtectedRoute accessRightData={subcontractorAccess}>
                                            <Subcontractors />
                                        </ProtectedRoute>
                                    }
                                />
                            </Route>
                            <Route path="inbox" element={<Inbox />}>
                                <Route index element={<EventMessages />} />
                                <Route path="eventMessages" element={<EventMessages />} />
                                <Route path="subscriptionsMessages" element={<SubscriptionMessages />} />
                            </Route>
                            <Route path="/userSettings" element={<UserSettings />} />
                            <Route
                                path="/workorder"
                                element={
                                    <ProtectedRoute accessRightData={workorderAccess}>
                                        <WorkOrder />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="fileImport"
                                element={
                                    <ProtectedRoute accessRightData={fileImportAccess}>
                                        <FileImport />
                                    </ProtectedRoute>
                                }
                            />
                        </Routes>
                    </Menu>
                </div>
            ) : (
                <div className="bg-pageBg overflow-auto h-screen flex">
                    <Routes>
                        {!isAuth && <Route path="*" element={<Navigate replace to="/login" />} />}
                        <Route path="/login" element={<GlobalMarket />} />
                        <Route path="/frisklinjen/login" element={<GlobalMarket />} />
                        <Route path="/avonova/login" element={<GlobalMarket />} />
                        <Route path="/login/:param" element={<LoginPage />} />
                        <Route path="/frisklinjen/login/:param" element={<LoginPage />} />
                        <Route path="/avonova/login/:param" element={<LoginPage />} />
                        <Route path="fortnox" element={<Fortnox />} />
                    </Routes>
                </div>
            )}
        </div>
    );
};
