import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IListData } from "../Charts/ListChart";
import { getDateDisplayValue } from "utils/date";
import { Table, TableCell, TableRow } from "@medhelp/ui";
import { Trans, useTranslation } from "react-i18next";
import { useSortableData } from "./hooks/useSortableData";

interface IOngoingRehabProcessesTable {
    data: IListData[];
    type: string;
}

const OngoingRehabProcessesTable = ({ data, type }: IOngoingRehabProcessesTable) => {
    const navigate = useNavigate();
    const { t } = useTranslation("dashboard");
    const rehabProcesses = useMemo(() => data?.find((x) => x.type === type)?.employeeDetails, [data, type]);
    const [sortedData, handleSort] = useSortableData(rehabProcesses ?? [], "initationDate");

    const headers = [
        { label: t("employees"), accessor: "employeeName" },
        { label: t("reasonForRehabProcess") },
        { label: t("rehabStartDate"), accessor: "initationDate" },
        ...(type === "LongTerm" ? [{ label: t("dayInRehabChain"), accessor: "daysInRehabChain" }] : []),
    ];

    return (
        <Table
            className="lg:p-10"
            caption={
                <Trans t={t} i18nKey={`rehabProcesses.${type}`} values={{ rehabProcesses: rehabProcesses?.length }} />
            }
            headers={headers}
            handleSort={handleSort}
        >
            {sortedData?.map(({ employmentId, hasFollowUp, employeeName, initationDate, daysInRehabChain }) => (
                <TableRow
                    key={employmentId}
                    onClick={
                        hasFollowUp && employmentId
                            ? () => navigate(`/followup/employee/${employmentId}/absence`)
                            : undefined
                    }
                >
                    <TableCell showOnMobile>{employeeName}</TableCell>
                    <TableCell>{t(type)}</TableCell>
                    <TableCell> {getDateDisplayValue(initationDate)}</TableCell>
                    {type === "LongTerm" && <TableCell>{daysInRehabChain}</TableCell>}
                </TableRow>
            ))}
        </Table>
    );
};

export default OngoingRehabProcessesTable;
