import Icon, { Icons } from "components/Icon";
import { ActivityEvents, IActivityLog } from "../../redux";

//
export interface IProps extends IActivityLog {}

const ActivityIconMap: {
    [key in ActivityEvents]: Icons;
} = {
    absenceEventReportedAbsence: "add",
    absenceEventReportedRecovery: "checkCircle",
    absenceEventAbsenceReportedToSocialInsurance: "checkCircle",
    ReportedRecovery: "chatNotification",
    ReportedVABRecovery: "chatNotification",
    absenceEventReported: "checkCircle",
    EditedVABReport: "chatNotification",
    absenceEventEditedAbsence: "notes",
    AbsenceReminder: "chatNotification",
    ReportedSick: "chatNotification",
    MedicalCertificateMessage: "chatNotification",
    EditedSickReport: "chatNotification",
    ReportedVAB: "chatNotification",
    FollowUpAdvisoryProcessedNotOk: "chatNotification",
    workorderProcessedOk: "phone",
    workorderProcessedNotOk: "phone",
};
export interface IEventType {
    event: ActivityEvents;
}
export const getEventIcon = (event: ActivityEvents): Icons => {
    return ActivityIconMap[event];
};
export const EvenType = ({ event }: IEventType) => {
    const iconFromEvent = getEventIcon(event);
    const icon = iconFromEvent ? iconFromEvent : "default";
    return <Icon icon={icon} size={24} />;
};
