import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Form } from "@medhelp/ui";
import { useTranslation } from "react-i18next";
import { selectors, thunkActions } from "../../redux";
import UserProfileFormHeader from "./UserProfileFormHeader";
import UserProfilePhonePrefix from "./UserProfilePhonePrefix";
import TertiaryButton from "components/TertiaryButton";
import SecondaryButton from "components/SecondaryButton";

/**
 * This is a feature that is not active yet,
 * still deciding if it should be used or not
 */
const EDIT_PROFILE = false;
const UserProfileForm = () => {
    const [expanded, setExpanded] = useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation(["followup", "translation"]);
    const userEmployment = useAppSelector(selectors.getUserEmployment);
    const defaultValues = useMemo(
        () => ({
            email: userEmployment?.contactInformation?.email || "",
            mobileNumber: userEmployment?.contactInformation?.mobileNumber || "",
            secondaryMobileNumber: userEmployment?.contactInformation?.secondaryMobileNumber || "",
            homeAdress: userEmployment?.contactInformation,
            telephoneNumber: userEmployment?.contactInformation?.telephoneNumber,
            mobileCountryPrefix: userEmployment?.contactInformation?.mobileCountryPrefix || "",
            secondaryMobileCountryPrefix: userEmployment?.contactInformation?.secondaryMobileCountryPrefix || "",
            telephoneCountryPrefix: userEmployment?.contactInformation?.telephoneCountryPrefix || "",
        }),
        [userEmployment?.contactInformation],
    );

    const {
        handleSubmit,
        reset,
        // formState: { errors, isValid },
        control,
        setValue,
    } = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
    });

    const setMobilePrefix = useCallback(
        (value: "mobileCountryPrefix" | "secondaryMobileCountryPrefix" | "telephoneCountryPrefix") =>
            (prefix: string) => {
                setValue(value, prefix);
            },
        [setValue],
    );

    const onSubmit = useCallback(
        // @ts-ignore
        (data) => {
            dispatch(
                thunkActions.updateUserProfile({
                    ...data,
                    mobile: data.mobileCountryPrefix.concat(data.mobileNumber),
                    secondaryMobile: data.secondaryMobileCountryPrefix.concat(data.secondaryMobileNumber),
                    telephone: data.telephoneCountryPrefix.concat(data.telephoneNumber),
                }),
            );
        },
        [dispatch],
    );

    const GetExpand = useMemo(() => {
        if (!EDIT_PROFILE) return false;
        return expanded;
    }, [expanded]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);
    return (
        <div className="w-full">
            <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                <div
                    role="button"
                    onClick={() => {
                        if (!expanded) {
                            setExpanded(true);
                        }
                    }}
                    className={`flex w-full justify-between cursor-default px-8 py-6`}
                >
                    <UserProfileFormHeader
                        expanded={GetExpand}
                        setExpanded={setExpanded}
                        // not active yet
                        isActiveFeature={false}
                    />
                </div>
                {GetExpand && (
                    <div className="px-8">
                        <div className="flex flex-col gap-5">
                            <p className="font-bold text-base">{t("work", { ns: "followup" })}</p>
                            <div className="flex flex-col gap-4 sm:flex-row">
                                <Controller
                                    name="mobileNumber"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Form
                                            label={t("mobileWork")}
                                            prefix={
                                                <UserProfilePhonePrefix
                                                    setValue={setMobilePrefix("secondaryMobileCountryPrefix")}
                                                    value={
                                                        userEmployment?.contactInformation
                                                            ?.secondaryMobileCountryPrefix || ""
                                                    }
                                                />
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="email"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Form label={t("emailWork")} className="w-none sm:w-[307px]" {...field} />
                                    )}
                                />
                            </div>
                            <hr />
                        </div>
                        <div className="flex flex-col gap-5 mt-8">
                            <p className="font-bold text-base">{t("private", { ns: "followup" })}</p>
                            <div className="flex flex-col gap-4 sm:flex-row">
                                <Controller
                                    name="secondaryMobileNumber"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Form
                                            label={t("mobilePrivate")}
                                            prefix={
                                                <UserProfilePhonePrefix
                                                    setValue={setMobilePrefix("mobileCountryPrefix")}
                                                    value={
                                                        userEmployment?.contactInformation?.mobileCountryPrefix || ""
                                                    }
                                                />
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                                <Controller
                                    name="telephoneNumber"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <Form
                                            label={t("numberHome")}
                                            prefix={
                                                <UserProfilePhonePrefix
                                                    setValue={setMobilePrefix("telephoneCountryPrefix")}
                                                    value={
                                                        userEmployment?.contactInformation?.telephoneCountryPrefix || ""
                                                    }
                                                />
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className={`flex sm:hidden justify-center gap-2 pb-10  ${expanded ? "block" : "hidden"}`}>
                    <TertiaryButton
                        id="on-profile-form-followup-cancel"
                        text={t("abort", { ns: "translation" })}
                        onClick={() => {
                            setExpanded(false);
                        }}
                        className="mr-2"
                    />
                    <SecondaryButton
                        id="on-profile-form-followup-save"
                        type="submit"
                        text={t("save", { ns: "translation" })}
                        onClick={() => {
                            setExpanded(false);
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

export default UserProfileForm;
