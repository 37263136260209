import { Card, CardAlign } from "@medhelp/ui";

const OngoingCasesLoading = () => {
    const rows = () => {
        const rows = [];
        for (let index = 0; index < 5; index++) {
            rows.push(
                <div className="flex flex-col px-8 justify-center h-12 w-56" key={index}>
                    <div className="flex gap-2">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
                    </div>
                </div>,
            );
        }
        return rows;
    };

    return (
        <Card testId="hcm-loading" align={CardAlign.start} className="p-0 mb-8 animate-pulse">
            <div className="w-full">
                <div className={`flex items-center border-b-1 border-solid border-grey-200 w-full px-8 h-19`}>
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-56"></div>
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-20 ml-5"></div>
                </div>
                <div className={"flex flex-wrap pt-6 pb-8 items-start"}>{rows()}</div>
            </div>
        </Card>
    );
};

export default OngoingCasesLoading;
