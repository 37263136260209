import * as React from "react";
import AbsenceReportEditFinished from "../AbsenceReportEditFinished";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import * as styles from "./AbsenceReportHistoryCard.styles";
import AbsenceHistoryReport from "./AbsenceHistoryReport";

interface IProps {
    sortedFinishedAbsenceReports: IAbsenceReportExtended[];
    preset: styles.IAbsenceReportHistoryCardPresets;
    getMiniMenuItems: (absenceReport: IAbsenceReportExtended) => {
        text: string;
        onClick: () => void;
    }[];
}
const AbsenceHistoryReports = ({ sortedFinishedAbsenceReports, preset, getMiniMenuItems }: IProps) => (
    <>
        {sortedFinishedAbsenceReports.map((finishedAR) => {
            if (finishedAR.isEdit) {
                return (
                    <div key={finishedAR.id}>
                        <AbsenceReportEditFinished absenceReport={finishedAR} preset={preset} id={finishedAR.id!} />
                    </div>
                );
            }

            return (
                <AbsenceHistoryReport getMiniMenuItems={getMiniMenuItems} finishedAR={finishedAR} key={finishedAR.id} />
            );
        })}
    </>
);
export default AbsenceHistoryReports;
