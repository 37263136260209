import { IAccessRightData } from "domain/accessRights";
import { useAppSelector } from "store/hooks";
import { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useStartRoute } from "routes/hooks";
import { hasGivenAccessRights, getUserEmploymentId, getCustomerProduct } from "store/userSelectors";

interface IPropsView {
    children: React.ReactNode;
    accessRightData?: IAccessRightData[] | readonly IAccessRightData[];
    hideIfOwnPage?: boolean;
    protectWhenProduct?: string;
}
const ProtectedRoute = ({ children, accessRightData, hideIfOwnPage, protectWhenProduct }: IPropsView) => {
    const userEmploymentId = useAppSelector(getUserEmploymentId);
    const hasAccess = useAppSelector(hasGivenAccessRights(accessRightData ?? []));
    const customerProduct = useAppSelector(getCustomerProduct);
    const startRoute = useStartRoute();
    const { routeEmploymentGuid } = useParams();
    const isAccessRightProtected = accessRightData && !hasAccess;
    const isProductProtected = protectWhenProduct && customerProduct.product === protectWhenProduct;
    const hideOwnPage = hideIfOwnPage ? routeEmploymentGuid === userEmploymentId : false;

    const Render = useMemo(() => {
        if (isAccessRightProtected || hideOwnPage || isProductProtected) return <Navigate replace to={startRoute} />;
        return <>{children}</>;
    }, [children, hideOwnPage, isAccessRightProtected, isProductProtected, startRoute]);
    return Render;
};
export default ProtectedRoute;
