import { SearchTypes } from "./searchApi";

const sv = [
    {
        name: "FirstName",
        value: "Förnamn",
    },
    {
        name: "LastName",
        value: "Efternamn",
    },
    {
        name: "SSN",
        value: "Personnummer",
    },
    {
        name: "EmploymentNumber",
        value: "Anställningsnummer",
    },
    {
        name: "AbsenceType",
        value: "Frånvarotyp",
    },
    {
        name: "StartDate",
        value: "Startdatum",
    },
    {
        name: "EndDate",
        value: "Slutdatum",
    },
    {
        name: "AbsenceDays",
        value: "Antal frånvarodagar",
    },
    {
        name: "AbsenceCauses",
        value: "Frånvaroorsak",
    },
    {
        name: "AbsenceCause",
        value: "Frånvaroorsak",
    },
    {
        name: "ChildBirthDate",
        value: "Barns bruddatum",
    },
    {
        name: "Departments",
        value: "Avdelningar",
    },
    {
        name: "EstimatedDateBack",
        value: "Uppskattat datum tillbaka",
    },
    {
        name: "CompanyName",
        value: "Företag",
    },
    {
        name: "Degrees",
        value: "Frånvarograd",
    },
    {
        name: "Flexjob",
        value: "Flexjobb",
    },
    {
        name: "Paragraph56",
        value: "§56",
    },
    {
        name: "PregnancyRelated",
        value: "Relaterat till graviditet",
    },
    {
        name: "SicknessBenefits",
        value: "Sjukpenning",
    },
    { name: "Started", value: "Startade" },
    { name: "Created", value: "Skapade" },
    {
        name: "CreateDate",
        value: "Skapad",
    },
    { name: "DaysTotal", value: "Antal dagar" },
    { name: "DaysInInterval", value: "Antal dagar i intervall" },
    { name: "BackAtWork", value: "Uppskattat datum tillbaka" },
    { name: "CreateDate", value: "Skapad" },
    {
        name: "Degree",
        value: "Frånvarograd",
    },
    { name: "ActiveDeviants", value: "Aktuell upprepad frånvaro" },
    { name: "ValidFrom", value: "Inträffat" },
    { name: "ValidTo", value: "Giltig till" },
    { name: "Active", value: "Aktiv" },
    { name: "Archived", value: "Arkiverad" },
    { name: "HealthCaseType", value: "Ärendetyp" },
    { name: "Title", value: "Aktivitet" },
    { name: "Date", value: "Datum" },
    { name: "HealthCaseResponsible", value: "Ärendeansvarig" },
    { name: "Departments", value: "Avdelningar" },
    { name: "Reason", value: "Anledning" },
    { name: "Status", value: "Status" },
];
const en = [
    {
        name: "FirstName",
        value: "First name",
    },
    {
        name: "LastName",
        value: "Last name",
    },
    {
        name: "SSN",
        value: "Social security number",
    },
    {
        name: "EmploymentNumber",
        value: "Employment number",
    },
    {
        name: "AbsenceType",
        value: "Absence type",
    },
    {
        name: "StartDate",
        value: "Start date",
    },
    {
        name: "EndDate",
        value: "End date",
    },
    {
        name: "AbsenceDays",
        value: "Number of absence days",
    },
    {
        name: "AbsenceCauses",
        value: "Absence cause",
    },
    {
        name: "AbsenceCause",
        value: "Absence cause",
    },
    {
        name: "ChildBirthDate",
        value: "Child brithdate",
    },
    {
        name: "Departments",
        value: "Departments",
    },
    {
        name: "EstimatedDateBack",
        value: "Estimated date back",
    },
    {
        name: "CompanyName",
        value: "Company",
    },
    {
        name: "Degrees",
        value: "Absence degree",
    },
    {
        name: "Flexjob",
        value: "Flex job",
    },
    {
        name: "Paragraph56",
        value: "§56",
    },
    {
        name: "PregnancyRelated",
        value: "Related to pregnancy",
    },
    {
        name: "SicknessBenefits",
        value: "Sickness benefits",
    },
    { name: "Started", value: "Started" },
    { name: "Created", value: "Created" },
    { name: "DaysTotal", value: "Number of days" },
    { name: "DaysInInterval", value: "Number of days in interval" },
    { name: "BackAtWork", value: "Estimated back at work" },
    { name: "CreateDate", value: "Created" },
    {
        name: "Degree",
        value: "Degree",
    },
    { name: "ActiveDeviants", value: "Current repeated absence" },
    { name: "ValidFrom", value: "Occurred" },
    { name: "ValidTo", value: "Valid to" },
    { name: "Active", value: "Active" },
    { name: "Archived", value: "Archived" },
    { name: "HealthCaseType", value: "Case type" },
    { name: "Title", value: "Activity" },
    { name: "Date", value: "Date" },
    { name: "HealthCaseResponsible", value: "Health case manager" },
    { name: "Departments", value: "Departments" },
    { name: "Reason", value: "Reason" },
    { name: "Status", value: "Status" },
];

const da = [
    {
        name: "FirstName",
        value: "Fornavn",
    },
    {
        name: "LastName",
        value: "Efternavn",
    },
    {
        name: "SSN",
        value: "cpr-nummer",
    },
    {
        name: "EmploymentNumber",
        value: "Ansættelsesnummer",
    },
    {
        name: "AbsenceType",
        value: "Fraværstype",
    },
    {
        name: "StartDate",
        value: "Startdato",
    },
    {
        name: "EndDate",
        value: "Sluttdato",
    },
    {
        name: "AbsenceDays",
        value: "Antal fraværsdage",
    },
    {
        name: "AbsenceCauses",
        value: "Fraværsårsag",
    },
    {
        name: "AbsenceCause",
        value: "Fraværsårsag",
    },
    {
        name: "ChildBirthDate",
        value: "Børnefødselsdag",
    },
    {
        name: "Departments",
        value: "Afdelinger",
    },
    {
        name: "EstimatedDateBack",
        value: "Estimeret dato tilbage",
    },
    {
        name: "CompanyName",
        value: "Virksomhed",
    },
    {
        name: "Degrees",
        value: "Fraværsgrad",
    },
    {
        name: "Flexjob",
        value: "Flexjob",
    },
    {
        name: "Paragraph56",
        value: "§56",
    },
    {
        name: "PregnancyRelated",
        value: "Relateret til graviditet",
    },
    {
        name: "SicknessBenefits",
        value: "Sygedagpenge",
    },
    { name: "Started", value: "Startede" },
    { name: "Created", value: "Oprettet" },
    { name: "DaysTotal", value: "Antal dage" },
    { name: "DaysInInterval", value: "Antal dage i interval" },
    { name: "BackAtWork", value: "Estimeret tilbage på arbejde" },
    { name: "CreateDate", value: "Oprettet" },
    { name: "Degree", value: "Grad" },
    { name: "ActiveDeviants", value: "Nåværende gjentatt fravær" },
    { name: "ValidFrom", value: "Opstod" },
    { name: "ValidTo", value: "Gyldig til" },
    { name: "Active", value: "Aktiv" },
    { name: "Archived", value: "Arkiverat" },
    { name: "HealthCaseType", value: "Sagstype" },
    { name: "Title", value: "Aktivitet" },
    { name: "Date", value: "Dato" },
    { name: "HealthCaseResponsible", value: "Sagsbehandler" },
    { name: "Departments", value: "Afdelinger" },
    { name: "Reason", value: "Grund" },
    { name: "Status", value: "Status" },
];

const no = [
    {
        name: "FirstName",
        value: "Fornavn",
    },
    {
        name: "LastName",
        value: "Etternavn",
    },
    {
        name: "SSN",
        value: "Fossnr",
    },
    {
        name: "EmploymentNumber",
        value: "Ansettelsesnummer",
    },
    {
        name: "AbsenceType",
        value: "Fraværstype",
    },
    {
        name: "StartDate",
        value: "Startdato",
    },
    {
        name: "EndDate",
        value: "Sluttdato",
    },
    {
        name: "AbsenceDays",
        value: "Antall fraværsdager",
    },
    {
        name: "AbsenceCauses",
        value: "Fraværsårsak",
    },
    {
        name: "AbsenceCause",
        value: "Fraværsårsak",
    },
    {
        name: "ChildBirthDate",
        value: "Barnebursdag",
    },
    {
        name: "Departments",
        value: "Avdelinger",
    },
    {
        name: "EstimatedDateBack",
        value: "Estimert dato tilbake",
    },
    {
        name: "CompanyName",
        value: "Selskap",
    },
    {
        name: "Degrees",
        value: "Fraværsgrad",
    },
    {
        name: "Flexjob",
        value: "Fleksibel jobb",
    },
    {
        name: "Paragraph56",
        value: "§56",
    },
    {
        name: "PregnancyRelated",
        value: "Relatert til graviditet",
    },
    {
        name: "SicknessBenefits",
        value: "Sykepenger",
    },
    { name: "Started", value: "Startet" },
    { name: "Created", value: "Opprettet" },
    { name: "DaysTotal", value: "Antall dager" },
    { name: "DaysInInterval", value: "Antall dager i intervallet" },
    { name: "BackAtWork", value: "Estimert tilbake på jobb" },
    { name: "CreateDate", value: "Opprettet" },
    { name: "Degree", value: "Grad" },
    { name: "ActiveDeviants", value: "Nåværende gjentatt fravær" },
    { name: "ValidFrom", value: "Oppstod" },
    { name: "ValidTo", value: "Gyldig til" },
    { name: "Active", value: "Aktiv" },
    { name: "Archived", value: "Arkivert" },
    { name: "HealthCaseType", value: "Sakstype" },
    { name: "Title", value: "Aktivitet" },
    { name: "Date", value: "Dato" },
    { name: "HealthCaseResponsible", value: "Saksbehandler" },
    { name: "Departments", value: "Avdelinger" },
    { name: "Reason", value: "Årsak" },
    { name: "Status", value: "Status" },
];

export const typesSV = [
    {
        name: "Sick",
        value: "Sjuk",
    },
    {
        name: "CareOfChild",
        value: "Vård av barn",
    },
];
export const typeEN = [
    {
        name: "Sick",
        value: "Sick",
    },
    {
        name: "CareOfChild",
        value: "Care of child",
    },
];
export const typeDA = [
    {
        name: "Sick",
        value: "Egen sygdom",
    },
    {
        name: "CareOfChild",
        value: "Barn syg",
    },
];
export const typeNO = [
    {
        name: "Sick",
        value: "Egen sykdom",
    },
    {
        name: "CareOfChild",
        value: "Omsorg for barn",
    },
];

export const absenceCausesEN = [
    {
        name: "None",
        value: "Unknown",
    },
    {
        name: "Confidential",
        value: "Confidential",
    },
    {
        name: "Confidential",
        value: "Confidential",
    },
    {
        name: "AllergySkin",
        value: "Allergy/Skin",
    },
    {
        name: "ChestHeartVascular",
        value: "Chest/Heart/Vascular",
    },
    {
        name: "HeadacheNeurologicalDisorders",
        value: "Headache/Neurological disorders",
    },
    {
        name: "Infection",
        value: "Infection - Ear/Nose/Throat",
    },
    {
        name: "FluSymptoms",
        value: "Flu symptoms",
    },
    {
        name: "StomachIntestinalAbdomenInconvenience",
        value: "Stomach/Intestinal/Abdomen",
    },
    {
        name: "LowerRespiratoryTract",
        value: "Lower respiratory tract",
    },
    {
        name: "EarNoseThroat",
        value: "Ear/Nose/Throat",
    },
    {
        name: "MentalSocialHealth",
        value: "Mental/Social health",
    },
    {
        name: "Musculo",
        value: "Back/Neck/Shoulders/legs/Arms",
    },
    {
        name: "Other",
        value: "Other",
    },
    {
        name: "Eyes",
        value: "Eyes",
    },
];
export const absenceCausesDA = [
    {
        name: "None",
        value: "Uoplyst",
    },
    {
        name: "Confidential",
        value: "Fortrolig",
    },
    {
        name: "AllergySkin",
        value: "Allergi/Hud",
    },
    {
        name: "ChestHeartVascular",
        value: "Brystkasse/Hjerte/Kar",
    },
    {
        name: "HeadacheNeurologicalDisorders",
        value: "Hovedpine/Nervesystemet",
    },
    {
        name: "Infection",
        value: "Infektion",
    },
    {
        name: "FluSymptoms",
        value: "Influenzasymptomer",
    },
    {
        name: "StomachIntestinalAbdomenInconvenience",
        value: "Mave/Tarm/Lever",
    },
    {
        name: "LowerRespiratoryTract",
        value: "Luftveje/Lunger",
    },
    {
        name: "EarNoseThroat",
        value: "Øre/Næse/Hals",
    },
    {
        name: "MentalSocialHealth",
        value: "Mentalt helbred/Stress",
    },
    {
        name: "Musculo",
        value: "Ryg/Nakke/Skuldre/Ben/Arme",
    },
    {
        name: "Other",
        value: "Øvrigt",
    },
    {
        name: "Eyes",
        value: "Øjne",
    },
];

export const absenceCausesNO = [
    {
        name: "None",
        value: "Ukjent",
    },
    {
        name: "Confidential",
        value: "Konfidensiell",
    },
    {
        name: "AllergySkin",
        value: "Allergi/Hud",
    },
    {
        name: "ChestHeartVascular",
        value: "Bryst/Hjerte/Karsykdommer",
    },
    {
        name: "HeadacheNeurologicalDisorders",
        value: "Hodepine/Neurologiske lidelser",
    },
    {
        name: "Infection",
        value: "Infeksjon - Øre/Nese/Hals",
    },
    {
        name: "FluSymptoms",
        value: "Influensasymptomer",
    },
    {
        name: "StomachIntestinalAbdomenInconvenience",
        value: "Mage/Tarm/Abdominalt",
    },
    {
        name: "LowerRespiratoryTract",
        value: "Nedre luftveier",
    },
    {
        name: "EarNoseThroat",
        value: "Øre/Nese/Hals",
    },
    {
        name: "MentalSocialHealth",
        value: "Mental/Sosial lidelse",
    },
    {
        name: "Musculo",
        value: "Rygg/Nakke/Skuldre/Ben/Arms",
    },
    {
        name: "Other",
        value: "Annet",
    },
    {
        name: "Eyes",
        value: "Øyne",
    },
];
export const absenceCausesSV = [
    {
        name: "None",
        value: "Okänd",
    },
    {
        name: "Confidential",
        value: "Konfidentiell",
    },
    {
        name: "AllergySkin",
        value: "Allergi/Hud",
    },
    {
        name: "ChestHeartVascular",
        value: "Bröstkorg/Hjärta/Kärl",
    },
    {
        name: "HeadacheNeurologicalDisorders",
        value: "Huvudvärk/Neurologiska besvär",
    },
    {
        name: "Infection",
        value: "Infektion - Öron/Näsa/Hals",
    },
    {
        name: "FluSymptoms",
        value: "Influensasymptom",
    },
    {
        name: "StomachIntestinalAbdomenInconvenience",
        value: "Mage/Tarm/Buk",
    },
    {
        name: "LowerRespiratoryTract",
        value: "Nedre luftvägar",
    },
    {
        name: "EarNoseThroat",
        value: "Öron/Näsa/Hals",
    },
    {
        name: "MentalSocialHealth",
        value: "Psykisk-/Social ohälsa",
    },
    {
        name: "Musculo",
        value: "Rygg/Nacke/Axlar/Ben/Armar",
    },
    {
        name: "Other",
        value: "Övrigt",
    },
    {
        name: "Eyes",
        value: "Ögon",
    },
];

export const statusSV = [
    {
        name: "preliminary",
        value: "Ej bekräftad",
    },
    {
        name: "ongoing",
        value: "Pågående",
    },
    {
        name: "paused",
        value: "Pausad",
    },
    {
        name: "declined",
        value: "Avböjd",
    },
    {
        name: "ended",
        value: "Avslutad",
    },
];
export const statusEN = [
    {
        name: "preliminary",
        value: "Not confirmed",
    },
    {
        name: "ongoing",
        value: "Ongoing",
    },
    {
        name: "paused",
        value: "Paused",
    },
    {
        name: "declined",
        value: "Declined",
    },
    {
        name: "ended",
        value: "Ended",
    },
];
export const statusDA = [
    {
        name: "preliminary",
        value: "Ikke bekræftet",
    },
    {
        name: "ongoing",
        value: "Igangværende",
    },
    {
        name: "paused",
        value: "Sat på pause",
    },
    {
        name: "declined",
        value: "Afbøjet",
    },
    {
        name: "ended",
        value: "Afsluttet",
    },
];
export const statusNO = [
    {
        name: "preliminary",
        value: "Ikke bekreftet",
    },
    {
        name: "ongoing",
        value: "Pågående",
    },
    {
        name: "paused",
        value: "Satt på pause",
    },
    {
        name: "declined",
        value: "Avbøyd",
    },
    {
        name: "ended",
        value: "Avsluttet",
    },
];

const HealthCaseTypeSV = [
    {
        name: "rehab",
        value: "Rehabärende",
    },
    {
        name: "healthpromotion",
        value: "Hälsofrämjande ärende",
    },
];
const HealthCaseTypeEN = [
    {
        name: "rehab",
        value: "Rehab case",
    },
    {
        name: "healthpromotion",
        value: "Health promotion case",
    },
];
const HealthCaseTypeDA = [
    {
        name: "rehab",
        value: "Rehabiliteringssag",
    },
    {
        name: "healthpromotion",
        value: "Sundhedsfremmende sag",
    },
];
const HealthCaseTypeNO = [
    {
        name: "rehab",
        value: "Rehabiliteringssak",
    },
    {
        name: "healthpromotion",
        value: "Helsefremmende sak",
    },
];

const acitveDeviants = {
    en: "Current long-term absence",
    sv: "Aktuell långtidsfrånvaro",
    no: "Nåværende langtidsfravær",
    da: "Nuværende langtidsfravær",
};

const translation = {
    sv,
    en,
    da,
    no,
};
const absenceTypes = {
    sv: typesSV,
    en: typeEN,
    da: typeDA,
    no: typeNO,
};
const absenceCause = {
    sv: absenceCausesSV,
    en: absenceCausesEN,
    da: absenceCausesDA,
    no: absenceCausesNO,
};
const status = {
    sv: statusSV,
    en: statusEN,
    da: statusDA,
    no: statusNO,
};
const healthCaseType = {
    sv: HealthCaseTypeSV,
    en: HealthCaseTypeEN,
    da: HealthCaseTypeDA,
    no: HealthCaseTypeNO,
};

export const getTranslation = (countryCode: "sv" | "da" | "no" | "en", searchType?: SearchTypes) => {
    let titles = translation[countryCode];
    if (searchType === "longtermAbsence") {
        titles = titles.map((x) => {
            if (x.name === "ActiveDeviants") {
                return {
                    ...x,
                    value: acitveDeviants[countryCode],
                };
            }
            return x;
        }, []);
    }
    return {
        titles,
        absenceTypes: absenceTypes[countryCode],
        absenceCauses: absenceCause[countryCode],
        status: status[countryCode],
        healthCaseType: healthCaseType[countryCode],
    };
};
