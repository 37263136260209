import { Config } from "config";

// APIS
export const ABSENCE_FOLLOW_UP_API = "https://dev-signedin.medhelp.se/absencefollowup/api/";
export const ABSENCE_REPORT_API = "https://dev-signedin.medhelp.se/absencereporting/api";
export const AUTH_SERVICE_API = "https://dev-services.medhelp.se/authenticationservice/api/auth";
export const BUSINESS_SUPORT_API = "https://dev-services.medhelp.se/businessSupport";
export const CUSTOMER_COMPANY_API = "https://dev-services.medhelp.se/customercompany/api/";
export const DEVIANT_ABSENCE_API = "https://dev-mymedhelp.medhelp.se/deviantabsence/api/";
export const EMPLOYEE_ATTENDANCE_API = "https://dev-signedin.medhelp.se/employeeattendance";
export const NOTIFICATION_API = "https://dev-services.medhelp.se/notification/api/";
export const PEOPLE_API = "https://dev-signedin.medhelp.se/people/api/";
export const REHAB_API = "https://dev-services.medhelp.se/rehab/api/";
export const SERVICE_BASE_URL = "https://dev-services.medhelp.se/";
export const STATISTICS_API = "https://dev-signedin.medhelp.se/statistics/api/";
export const USER_CONTEXT_SERIVCE_API = "https://dev-services.medhelp.se/usercontextservice/";
export const SERVICE_BASE = "https://dev-services.medhelp.se/";
export const MESSENGER_API = "https://dev-signedin.medhelp.se/messenger";
export const MEDICALADVISORY_API = "https://dev-services.medhelp.se/medicaladvisory";
export const STORAGE_API = "https://dev-signedin.medhelp.se/storage/api/";
export const SUPPORT_API = "https://dev-services.medhelp.se/support";
export const RISK_API = "https://dev-signedin.medhelp.se/risk/api";
export const HEALTH_CASE_MANAGEMENT_API = "https://dev-signedin.medhelp.se/hcm/api";
export const FORTNOX_API = "https://accept-signedin.medhelp.se/fortnox/api";

// select the authService
export const authService = () => `${Config.api_servicesBase_url}authenticationservice/api/auth/`;

// select the userContextService
export const userContextService = () => `${Config.api_servicesBase_url}usercontextservice/api/`;
