import { isEmpty } from "lodash/fp";
import * as React from "react";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import PrimaryButton from "components/PrimaryButton";
import { Card } from "@medhelp/ui";
import Select from "components/Select";
import SecondaryButton from "components/SecondaryButton";
import { ReactComponent as PlusIcon } from "icons/plus.svg";
import { selectors as absenceReportsSelectors } from "../../redux";
import * as UserSelectors from "store/userSelectors";

export interface IProps {
    testId: string;
    createIconClicked: () => void;
    createIconDisabled?: boolean;
    onChange: (value: string | undefined) => void;
    maxOngoing?: boolean;
    selectedValue: string;
}
const AbsenceReportButton = ({ testId, createIconClicked, onChange, selectedValue, maxOngoing = false }: IProps) => {
    const { t } = useTranslation();
    const hasAbsenceReports = useAppSelector(absenceReportsSelectors.hasAbsenceReport);
    const isCallNurse = useAppSelector(absenceReportsSelectors.getConfigurationByName("callNurse"));
    const onGoingAbsenceReportsIsPreliminary = useAppSelector(absenceReportsSelectors.getAbsencesBasedOnPreliminary());
    const isEditing = useAppSelector(absenceReportsSelectors.getIsEditing);
    const absenceTemplateTypes = useAppSelector(absenceReportsSelectors.getAbsenceTemplatesDescription(t));
    const isManage = useAppSelector(UserSelectors.getIsAbsenceReportAdmin);
    const isOwnReport = useAppSelector(absenceReportsSelectors.getIsOwnReport);

    const disabled = !isEmpty(onGoingAbsenceReportsIsPreliminary) || (isCallNurse && !isManage) || isEditing;

    const Button = React.useMemo(
        () =>
            hasAbsenceReports ? (
                <SecondaryButton
                    id="createdArUser"
                    data-cy="create-report-button"
                    text={t("createNewAr")}
                    disabled={disabled || maxOngoing}
                    onClick={createIconClicked}
                    className="flex justify-center self-center"
                    leftIcon={<PlusIcon />}
                />
            ) : (
                <PrimaryButton
                    id="createdArUser"
                    data-cy="create-report-button"
                    text={t("createNewAr")}
                    disabled={disabled}
                    onClick={createIconClicked}
                    className="flex justify-center self-center"
                    leftIcon={<PlusIcon />}
                />
            ),
        [createIconClicked, disabled, hasAbsenceReports, maxOngoing, t],
    );

    return (
        <Card testId={testId}>
            {isManage && !isOwnReport ? (
                <Select
                    id="createdArAdmin"
                    values={absenceTemplateTypes}
                    placeholder={t("createNewAr")}
                    onChange={onChange}
                    selectedValue={selectedValue}
                    disabled={disabled || maxOngoing}
                    useCustomSelect
                    hidePlaceHolderInList
                    hasOngoingAbsenceReport={hasAbsenceReports}
                    className="h-12"
                />
            ) : (
                Button
            )}
        </Card>
    );
};

export default AbsenceReportButton;
