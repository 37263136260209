import { isUndefined } from "lodash/fp";
import { SyntheticEvent } from "react";

export interface IProps {
    label?: string;
    required?: boolean;
    prefix?: React.ReactElement;
    textarea?: boolean;
    error?: string;
    className?: string;
    onChange: (v: SyntheticEvent) => void;
    setRef?: <T>(node: T) => void;
}

const Form = ({ label, prefix, required, textarea, error, className, onChange, setRef, ...rest }: IProps) => {
    return (
        <div className="flex flex-col font-bold mb-10">
            {label && (
                <label className="mb-1.5">
                    {label} {required ? "*" : ""}
                </label>
            )}
            {error && <p className="text-mhred-alert1 pb-4">{error}</p>}
            <div className="flex w-full">
                {prefix && prefix}
                {textarea ? (
                    <textarea
                        {...(!isUndefined(setRef)
                            ? {
                                  ref: setRef,
                              }
                            : {})}
                        onChange={onChange}
                        className={`w-full border border-mhdarkgreen-original bg-transparent py-3 px-4 resize-none h-44`}
                        {...rest}
                    />
                ) : (
                    <input
                        {...(!isUndefined(setRef)
                            ? {
                                  ref: setRef,
                              }
                            : {})}
                        onChange={onChange}
                        className={`w-full border border-mhdarkgreen-original bg-transparent py-3 px-4 ${
                            error ? "border-mhred-alert1 border" : ""
                        } ${className}`}
                        {...rest}
                    />
                )}
            </div>
        </div>
    );
};

export default Form;
