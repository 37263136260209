import { createSelector } from "@reduxjs/toolkit";
import { last } from "lodash/fp";
import { checkIfOnFollowUpRoute, FollowUpPages } from "pages/FollowUp/accessRights";
import { RootState } from "store";

export const getCurrentRoute = (state: RootState) => state.route.currentRoute;

const followUpIndexRoutes = (value: string | undefined): FollowUpPages | undefined => {
    const page = value === "search" ? "reports" : value;
    if (checkIfOnFollowUpRoute(page)) {
        return page;
    }
    return undefined;
};
export const checkIfFollowUpRoute = createSelector(getCurrentRoute, (route) => {
    if (route.match(/(\/followup\/search)/)) {
        const routes = route.split("/");
        return followUpIndexRoutes(last(routes)) ?? "reports";
    }
});

export const getFollowUpCurrentRoute = createSelector(checkIfFollowUpRoute, (followUpRoute) => {
    if (checkIfOnFollowUpRoute(followUpRoute)) {
        return followUpRoute;
    }
    return undefined;
});
