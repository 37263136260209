import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { openHistoricalAbsenceReport as openHistoricalAbsenceReportAction } from "../absenceReportsActions";
import { IAbsenceReportSlice } from "../absenceReportsSlice";

const openHistoricalAbsenceReport = (builder: ActionReducerMapBuilder<IAbsenceReportSlice>) =>
    builder
        .addCase(openHistoricalAbsenceReportAction.fulfilled, (state, action) => {
            const absenceReport = state?.AbsenceReports?.find(({ id }) => id === action.payload?.id);
            if (absenceReport) {
                absenceReport.status = "Ongoing";
                absenceReport.end = null;
            }
        })
        .addCase(openHistoricalAbsenceReportAction.rejected, (state) => {
            state.IsOK = false;
        });

export default openHistoricalAbsenceReport;
