import { useState } from "react";
import Icon from "components/Icon";
import { useAppDispatch } from "store/hooks";
import { TailSpin } from "react-loader-spinner";
import Checkbox from "@medhelp/ui/Checkbox";
import { TableCell, TableRow } from "@medhelp/ui";
import { getSsnWithDashWithReturnNull } from "utils/numbers";
import { EmployeeWithAccessRights } from "pages/FortnoxAdministration/redux/fortnoxAdministrationSlice";
import { saveEmployeeAccessRights } from "pages/FortnoxAdministration/redux/fortnoxAdministrationActions";

const Employee = ({ employee }: { employee: EmployeeWithAccessRights }) => {
    const dispatch = useAppDispatch();
    const { isManager, isAdmin } = employee;
    const [isManagerChecked, setIsManagerChecked] = useState(isManager);
    const [isAdminChecked, setIsAdminChecked] = useState(isAdmin);
    const [isLoading, setIsLoading] = useState(false);

    const submitDisabled = isManager === isManagerChecked && isAdmin === isAdminChecked;

    const handleClick = () => {
        if (!employee.userAccountId) return;
        setIsLoading(true);
        dispatch(saveEmployeeAccessRights({ employee, isManagerChecked, isAdminChecked }))
            .unwrap()
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setIsManagerChecked(isManager);
                setIsAdminChecked(isAdmin);
            });
    };

    return (
        <TableRow>
            <TableCell showOnMobile>{employee?.name}</TableCell>
            <TableCell>
                {employee?.socialSecurityNumber ? getSsnWithDashWithReturnNull(employee?.socialSecurityNumber) : ""}
            </TableCell>
            <TableCell showOnMobile>
                <div className="flex">
                    <Checkbox onChange={(e) => setIsManagerChecked(e.target.checked)} checked={isManagerChecked} />
                </div>
            </TableCell>
            <TableCell showOnMobile>
                <div className="flex">
                    <Checkbox onChange={(e) => setIsAdminChecked(e.target.checked)} checked={isAdminChecked} />
                </div>
            </TableCell>
            <TableCell showOnMobile>
                {isLoading ? (
                    <TailSpin color="#3b3b36" height={20} width={20} />
                ) : (
                    <button disabled={submitDisabled} className="hover:cursor-pointer" onClick={handleClick}>
                        <Icon icon="save" className={submitDisabled ? "fill-disabledCheckbox" : ""} />
                    </button>
                )}
            </TableCell>
        </TableRow>
    );
};

export default Employee;
